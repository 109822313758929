import { useCallback } from 'react';
import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import Button from 'components/Button';
import { BaseColumnModel } from 'components/Table';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { Export } from 'features/Export/Export';

type Props = {
	onSave: () => void
	onSetIsActive: (isActive: boolean) => void
	disabled: boolean;
	configureViewKey: string
    tableColumns: BaseColumnModel[],
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
};

const TableButtons = ({
	onSave,
	onSetIsActive,
	disabled,
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction
}: Props) => {

	const setIsActiveFalseCallback = useCallback(
		() => {
			onSetIsActive(false)
		},
		[onSetIsActive]
	)

	const setIsActiveTrueCallback = useCallback(
		() => {
			onSetIsActive(true)
		},
		[onSetIsActive]
	)

	return (
		<div>
			<RowContainer justifyContent='space-between'>
				<RowContainer margin='large'>
					<Button
						text='Save'
						onClick={onSave}
						disabled={disabled}
					/>
					<RowContainer>
						<Button
							text='Include all'
							color='neutral'
							onClick={setIsActiveTrueCallback}
							disabled={disabled}
						/>
						<Button
							text='Exclude all'
							color='neutral'
							onClick={setIsActiveFalseCallback}
							disabled={disabled}
						/>
					</RowContainer>
				</RowContainer>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
					<Export
						filtersModel={filtersModel}
						tableColumns={tableColumns}
						exportAction={exportFunction}
						title='Test plans'
						exportTypeOptions={[ExportDataFileTypeEnum.XLSX, ExportDataFileTypeEnum.PDF]}
					/>
				</RowContainer>
			</RowContainer>
		</div>
	);
};

export default TableButtons;
