import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoCompleteField, Form, MapMultiField, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { CommunicationDashboardFilterRequest, ModuleActivityEnum, MonthEnum, ProjectIsActiveResponse, ProjectIsActiveResponseItemsResponseModel, ProjectTeamMemberResponse, ProjectTeamMemberResponseItemsResponseModel, TaskStatusResponse } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { propertyOf } from 'utils/propertyOf';
import { getProjectTeamsAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Teams/action';
import Button from 'components/Button';
import { monthOptions, yearOptions } from 'containers/TimeTravel/utils';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getAllTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';
import { ProjectMultiSelectField, pmOrSpmOrOumPermission } from 'features/Project';

type Props = {
	filterFormModel?: CommunicationDashboardFilterRequest
	onSubmit: (request: CommunicationDashboardFilterRequest) => Promise<void>
	onSave: (filterFormModel: CommunicationDashboardFilterRequest) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const {
		persistedUser,
		persistedTaskStatus,
		persistedOrganizationalUnit
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const [values, setValues] = useState(new CommunicationDashboardFilterRequest(filterFormModel));

	useEffect(
		() => {
			setValues(new CommunicationDashboardFilterRequest(filterFormModel));
		},
		[filterFormModel]
	)

	const [projectTeams, setProjectTeams] = useState<ProjectIsActiveResponse[]>([]);
	const [fetchingTeams, setFetchingTeams] = useState(false);

	useEffect(
		() => {
			const fetchData = async () => {
				if (!values.projectIds) {
					return
				}

				setFetchingTeams(true);

				const data: Promise<ProjectIsActiveResponseItemsResponseModel>[] = [];
				for (let i = 0; i < values.projectIds?.length; i++) {
					const projectId = values.projectIds[i];
					const bindedAction = getProjectTeamsAction.bind(null, projectId);
					data.push(tryCatchJsonByAction(bindedAction));
				}

				const responses = await Promise.all(data);

				const projectTeamItems: ProjectIsActiveResponse[] = [];
				for (let i = 0; i < responses.length; i++) {
					const response = responses[i];
					if (response.success) {
						projectTeamItems.push(...response.items || []);
					}
				}
				setProjectTeams(projectTeamItems);
				setFetchingTeams(false);
			}

			fetchData();
		},
		[values.projectIds]
	)

	const [projectTeamMembers, setProjectTeamMembers] = useState<ProjectTeamMemberResponse[]>([]);
	const [fetchingUsers, setFetchingUsers] = useState(false);

	useEffect(
		() => {
			const fetchUsersData = async () => {
				if (!values.projectIds) {
					return
				}

				setFetchingUsers(true);

				const data: Promise<ProjectTeamMemberResponseItemsResponseModel>[] = [];
				for (let i = 0; i < values.projectIds?.length; i++) {
					const projectId = values.projectIds[i];
					const bindedAction = getAllTeamMembersAction.bind(null, projectId);
					data.push(tryCatchJsonByAction(bindedAction));
				}

				const responses = await Promise.all(data);

				const projectTeamMembers: ProjectTeamMemberResponse[] = [];
				for (let i = 0; i < responses.length; i++) {
					const response = responses[i];
					if (response.success && response.items) {
						for (let j = 0; j < response.items.length; j++) {
							const teamMember = response.items[j];
							projectTeamMembers.push(teamMember);
						}
					}
				}
				setProjectTeamMembers(projectTeamMembers);
				setFetchingUsers(false);
			}

			fetchUsersData();
		},
		[values.projectIds]
	)

	const userIds = useMemo(() => {
		const allTeamMembers = values.projectTeamIds && values.projectTeamIds.length > 0
			? projectTeamMembers.filter(user => user.projectTeamId && values.projectTeamIds?.find(id => id === user.projectTeamId))
			: projectTeamMembers

		return [...Array.from(new Set(allTeamMembers.map(x => x.userId)))]
	}, [projectTeamMembers, values.projectTeamIds])

	const onProjectsChangeCallback = useCallback(
		(_newProjectIds: number[] | undefined, _oldValues: CommunicationDashboardFilterRequest) => ({
			[propertyOf<CommunicationDashboardFilterRequest>('projectTeamIds')]: undefined,
			[propertyOf<CommunicationDashboardFilterRequest>('userIds')]: undefined,
		}),
		[]
	)

	const onProjectTeamsChangeCallback = useCallback(
		(_newProjectTeamIds: number[] | undefined, _oldValues: CommunicationDashboardFilterRequest) => ({
			[propertyOf<CommunicationDashboardFilterRequest>('userIds')]: undefined,
		}),
		[]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new CommunicationDashboardFilterRequest(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectMultiSelectField
							id={propertyOf<CommunicationDashboardFilterRequest>('projectIds')}
							updateDependants={onProjectsChangeCallback}
							isProjectDashboard
							moduleEnum={ModuleActivityEnum.Communication}
							userRolePermission={pmOrSpmOrOumPermission}
						/>
						<MultiSelectField
							id={propertyOf<CommunicationDashboardFilterRequest>('projectTeamIds')}
							label='Project team'
							items={projectTeams}
							getItemId={(item: ProjectIsActiveResponse) => item.id}
							getItemText={(item: ProjectIsActiveResponse) => item.name}
							loading={fetchingTeams}
							updateDependants={onProjectTeamsChangeCallback}
						/>
						<MultiSelectField
							id={propertyOf<CommunicationDashboardFilterRequest>('userIds')}
							label='Assigned user'
							items={userIds}
							getItemId={(userId: number) => userId}
							getItemText={(userId: number) => getUserFullName(userId)}
							loading={fetchingUsers}
						/>
						<MultiSelectField
							id={propertyOf<CommunicationDashboardFilterRequest>('taskStatusIds')}
							label='Task status'
							items={persistedTaskStatus.items}
							getItemId={(item: TaskStatusResponse) => item.id}
							getItemText={(item: TaskStatusResponse) => item.name}
							loading={persistedTaskStatus.fetching}
						/>
						<MapMultiField
							id={propertyOf<CommunicationDashboardFilterRequest>('organizationalUnitIds')}
							label='Organizational Unit'
							items={convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined)}
							loading={persistedOrganizationalUnit.fetching}
						/>
						<MultiSelectField
							id={propertyOf<CommunicationDashboardFilterRequest>('years')}
							label='Year'
							items={yearOptions}
							getItemId={(item: number) => item}
							getItemText={(item: number) => item.toString()}
						/>
						<MultiSelectField
							id={propertyOf<CommunicationDashboardFilterRequest>('months')}
							label='Month'
							items={monthOptions}
							getItemId={(item: MonthEnum) => item}
							getItemText={(item: MonthEnum) => item}
						/>
						<AutoCompleteField
							id={propertyOf<CommunicationDashboardFilterRequest>('taskIsPrivate')}
							label='Task private'
							items={[true, false]}
							getItemId={(item: boolean) => item ? 1 : 0}
							getItemText={(item: boolean) => item ? 'Yes' : 'No'}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
					disabled={!values.projectIds}
				/>
			)}
		/>
	)
}
