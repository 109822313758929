import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { TextAnswersDashboardResponse, UserModel } from 'services/tenantManagementService';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultColumns = [
	propertyOf<TextAnswersDashboardResponse>('questionGroup'),
	propertyOf<TextAnswersDashboardResponse>('question'),
	propertyOf<TextAnswersDashboardResponse>('userId'),
	propertyOf<TextAnswersDashboardResponse>('textAnswer')
]

export const useTableColumnsMemo = (
	visibleColumns: string[] = defaultColumns,
) => {
	const { persistedUser } = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<TextAnswersDashboardResponse>('questionGroup')]: {
					title: '',
					fieldType: FieldTypeEnum.String,
				},
				[propertyOf<TextAnswersDashboardResponse>('question')]: {
					title: 'Question',
					fieldType: FieldTypeEnum.String,
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<TextAnswersDashboardResponse>('userId')]: {
					title: 'User',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => getUserFullName(option.id),
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<TextAnswersDashboardResponse>('textAnswer')]: {
					title: 'Answer',
					fieldType: FieldTypeEnum.String,
					disableSort: true,
					disableFilter: true,
				}
			}
		},
		[getUserFullName, persistedUser.items]
	)

	return useVisibleColumns(columns, visibleColumns);
}
