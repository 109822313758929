import { getTenantId } from 'features/Tenant/tenantUtils';
import { TestCycleDeltaRequest, TestCycleClient } from 'services/tenantManagementService';

export const getTestCyclesAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new TestCycleClient();
	return client.getAll(tenantId, projectId, undefined, undefined);
}

export const updateTestCyclesAction = (projectId: number, model: TestCycleDeltaRequest ) => {
	const tenantId = getTenantId()!;
	const client = new TestCycleClient();
	return client.update(tenantId, projectId, model);
}

export const getTestCycleAction = (projectId: number, testCycleId: number) => {
	const tenantId = getTenantId()!;
	const client = new TestCycleClient();
	return client.get(tenantId, projectId, testCycleId);
}
