import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import notifications from 'components/Notification/notification';
import { ColumnContainer } from 'components/Layout';
import { TableButtons } from './TicketsTable/TableButtons';
import { useTableColumnsMemo } from './TicketsTable/tableColumns';
import { getAllTicketsAction, exportAction, deleteTicketAction } from './action';
import { GenericFilterModelCollection, PartalTicketRequestEnum, ProjectStatusEnum, SimpleScheduleResponse, TicketResponseGetAll, TicketStatusEnum, TokenTypeEnum } from 'services/tenantManagementService';
import { ContentShell } from 'features/Content/ContentShell';
import { TicketHelp } from '../Help/TicketHelp';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { ListComponentProps } from 'features/Crud'
import { RemoteTable } from 'components/Table';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { getUserInfo } from 'utils/storageUtils';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { useOpenInNewTab } from 'features/Crud/crudNewTabHooks';
import { getAllSimpleSchedulesAction } from 'containers/Schedule/Schedule/action';

const configureViewKey = 'tickets_table';

export const ViewTickets = ({ dataChangedTopic, publishDataChanged }: ListComponentProps) => {
	const {	persistedTicketStatus, persistedProjectStatus, persistedProject } = useSelector((state: RootState) => state);

	const [selectedTicket, setSelectedTicket] = useState(new TicketResponseGetAll());
	const [selectedPartialTicketItems, setSelectedPartialTicketItems] = useState<PartalTicketRequestEnum[]>([])
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());
	const [schedules, setSchedules] = useState<SimpleScheduleResponse[]>([]);

	const tableColumns = useTableColumnsMemo(configureViewKey, schedules, filtersModel);

	const fetchAdditionalCallback = useCallback(async () => {
		const schedulesResponse = await tryCatchJsonByAction(getAllSimpleSchedulesAction);
		if (schedulesResponse.success) {
			setSchedules(schedulesResponse.items || []);
		}
	}, [])

	useEffect(() => {
		fetchAdditionalCallback()
	}, [fetchAdditionalCallback])

	const memoFetchFunction = useMemo(
		() =>  getAllTicketsAction.bind(null, selectedPartialTicketItems),
		[selectedPartialTicketItems]
	)

	const handleRowSelectionChange = useCallback(
		(data: TicketResponseGetAll[]) => {
			setSelectedTicket(data[0] || new TicketResponseGetAll());
		},
		[]
	);

	const openInNewTab = useOpenInNewTab()
	const onRowDoubleClick = useCallback((rowData: TicketResponseGetAll) => openInNewTab(rowData.id), [openInNewTab])

	const deleteCallback = useCallback(
		async () => {
			const bindedAction = deleteTicketAction.bind(null, selectedTicket.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TICKET, selectedTicket.id);
				notifications.success(`Ticket ${id} is deleted.`);
				publishDataChanged();
			}
		},
		[selectedTicket.id, publishDataChanged]
	)

	const isChangeDisabledMemo = useMemo(
		() => isStatusBySemantic(TicketStatusEnum.Closed, selectedTicket.statusId, persistedTicketStatus.itemsMap) ||
			isStatusBySemantic(ProjectStatusEnum.Completed, persistedProject.itemsMap[selectedTicket.projectId]?.statusId, persistedProjectStatus.itemsMap),
		[selectedTicket, persistedTicketStatus, persistedProjectStatus, persistedProject.itemsMap]
	);

	const isDeleteDisabledMemo = useMemo(
		() => {
			const user = getUserInfo();
			return (selectedTicket.createdByUserId !== user.id && user.roleId !== TokenTypeEnum.SiteAdmin) ||
				isStatusBySemantic(ProjectStatusEnum.Completed, persistedProject.itemsMap[selectedTicket.projectId]?.statusId, persistedProjectStatus.itemsMap);
		},
		[selectedTicket, persistedProjectStatus, persistedProject.itemsMap]
	);

	const memoExportFunction = useMemo(
		() => exportAction.bind(null, selectedPartialTicketItems),
		[selectedPartialTicketItems]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='Tickets'
			FloatingHelpComponent={TicketHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedTicket.id}
					changeDisabled={isChangeDisabledMemo}
					deleteDisabled={isDeleteDisabledMemo}
					onDelete={deleteCallback}
					selectedPartialTicketItems={selectedPartialTicketItems}
					setSelectedPartialTicketItems={setSelectedPartialTicketItems}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={dataChangedTopic}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
					rowDoubleClick={onRowDoubleClick}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}
