import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { InsertProjectTeamMemberValidityPeriodRequest, InsertProjectTeamMemberValidityPeriodRequestUpdateProjectTeamMemberValidityPeriodRequestInt32DeltaModel, ProjectTeamMemberResponse, ProjectTeamMemberValidityPeriodResponse, UpdateProjectTeamMemberRequest, UpdateProjectTeamMemberValidityPeriodRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTeamMemberAction, persistTeamMembersAction, updateTeamMemberAction } from '../action';
import AssignTeamMemberModalForm from '../AssignTeamMemberModalForm';
import WithFetch from 'features/Fetch/WithFetch';
import { createDelta, unpackDelta } from 'utils/commonHelper';
import { useMemo } from 'react';
import { UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';

type ParamType = {
	projectId: string
	teamMemberId: string
}

const UpdateTeamMember = ({ publishDataChanged }: UpdateComponentProps) => {
	const [initialValidityPeriods, setInitialValidityPeriods] = useState<ProjectTeamMemberValidityPeriodResponse[]>([])
	const [teamMember, setTeamMember] = useState(new ProjectTeamMemberResponse());
	const goBackFromUpdate = useGoBackFromUpdate();
	const params: ParamType = useParams();
	const projectId = useMemo(
		() => parseInt(params.projectId),
		[params.projectId]
	)

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getTeamMemberAction.bind(null, projectId, parseInt(params.teamMemberId));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setTeamMember(response.value || new ProjectTeamMemberResponse());
				setInitialValidityPeriods(response.value?.validityPeriods || []);
			}
		},
		[projectId, params.teamMemberId]
	)

	const onSubmitCallback = useCallback(
		async (values: ProjectTeamMemberResponse, username: string) => {
			const delta = createDelta<ProjectTeamMemberValidityPeriodResponse>
				(
					initialValidityPeriods|| [],
					values.validityPeriods || [],
					InsertProjectTeamMemberValidityPeriodRequest,
					UpdateProjectTeamMemberValidityPeriodRequest,
					InsertProjectTeamMemberValidityPeriodRequestUpdateProjectTeamMemberValidityPeriodRequestInt32DeltaModel
				);

			const updateTeamMember = new UpdateProjectTeamMemberRequest({
				...values,
				projectRoleId: values.projectRoleId!,
				projectTeamId: values.projectTeamId!,
				statusId: values.statusId!,
				validUntil: values.validUntil!,
				validityPeriods: delta,

			})
			const bindedAction = updateTeamMemberAction.bind(null, projectId, updateTeamMember);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`Team member for user ${username} is updated.`);
				goBackFromUpdate();
				persistTeamMembersAction(projectId)
				publishDataChanged();
			} else {
				const errors = convertResponseErrors(response)
				const [validityPeriodErrors, newValidityPeriods] = unpackDelta(errors?.validityPeriods || {}, delta, values.validityPeriods || [], initialValidityPeriods || []);
				const newTeamMember = new ProjectTeamMemberResponse(values);
				newTeamMember.validityPeriods = newValidityPeriods;
				setTeamMember(newTeamMember);
				return { ...errors, validityPeriods: validityPeriodErrors };
			}
		},
		[goBackFromUpdate, projectId, initialValidityPeriods, publishDataChanged]
	);

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<AssignTeamMemberModalForm
				teamMember={teamMember}
				save={onSubmitCallback}
				cancel={goBackFromUpdate}
				open
			/>
		</WithFetch>
	);
};

export default UpdateTeamMember;
