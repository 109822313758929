import { HelpShell } from 'features/Help/HelpShell';

export const TasksHelp = () => {
	return (
		<HelpShell title='Tasks'>
			<div>
				<p>User tasks can be:</p>
				<ul>
					<li>Created individually (private or public)</li>
					<li>Generated from project schedule</li>
					<li>Generated from meeting minutes</li>
					<li>Generated from tickets</li>
				</ul>
			</div>
			<p>User can see all public project tasks in projects where he is assigned as team member.</p>
			<p>Task statuses are configured on site level by site administrator. Mavles comes with 3 predefined task statuses: open, in progress and closed.</p>
			<p>User can create tasks that are 'public' visible for all team members, or private tasks visible only to user.</p>
			<p>Task can be deleted only by user who created it. <br />
				When generated from schedule, meeting or tickets, changing task status, assigned person or due date is shown in project schedule, meeting or ticket.
			</p>
		</HelpShell>
	)
}
