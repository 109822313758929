import { SmartFormGroup } from 'components/SmartContainer/SmartContainer';
import { ContentShell } from 'features/Content/ContentShell';
import { useCallback, useEffect, useState } from 'react';
import { CheckboxField, Form, MultiSelectField } from 'components/Form';
import { AddUpdateUserAlertConfigurationsRequest, AddUserAlertConfigurationRequest, AlertEntitiesEnum, AlertTriggersEnum, SimpleIdNameModel, UpdateUserAlertConfigurationRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction, convertResponseErrors } from 'utils/fetchUtils';
import { getAllTicketsSimpleAction } from 'containers/Tickets/ViewTickets/action';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { getAllChangeRequestsSimpleAction } from 'containers/Scope/ChangeRequests/action';
import styles from './rulesAndAlerts.module.scss';
import { RowContainer } from 'components/Layout';
import { getRulesAndAlertsAction, updateRulesAndAlertsAction } from './action';
import WithFetch from 'features/Fetch/WithFetch';
import notifications from 'components/Notification/notification';
import { propertyOf } from 'utils/propertyOf';
import { getAllTestPlansSimpleAction } from 'containers/Testing/TestPlans/actions';
import { RulesAndAlertsHelp } from './Help/RulesAndAlertsHepl';

class Model {
	// we store id's from backend, so onSubmit we know whether to add it to addedItems or updatedItems models (specific backend logic)
	idTestCase?: number
	notifyTestCase = false
	emailTestCase = false
	selectedLevels3?: number[]

	idTicket?: number
	notifyTicket = false
	emailTicket = false
	selectedTickets?: number[]

	idChangeRequest?: number
	notifyChangeRequest = false
	emailChangeRequest = false
	selectedChangeRequests?: number[]

	constructor (model?: Model) {
		if (model) {
			this.notifyTestCase = model.notifyTestCase;
			this.emailTestCase = model.emailTestCase;
			this.selectedLevels3 = model.selectedLevels3;
			this.notifyTicket = model.notifyTicket;
			this.emailTicket = model.emailTicket;
			this.selectedTickets = model.selectedTickets;
			this.notifyChangeRequest = model.notifyChangeRequest;
			this.emailChangeRequest = model.emailChangeRequest;
			this.selectedChangeRequests = model.selectedChangeRequests;
		}
	}
}

export const RulesAndAlerts = () => {
	const [values, setValues] = useState(new Model());
	const [initialValues, setInitialValues] = useState(new Model());

	// fetch tickets
	const [tickets, setTickets] = useState<SimpleIdNameModel[]>([]);
	const [isFetchingTickets, setIsFetchingTickets] = useState(false);

	const fetchTicketsCallback = useCallback(
		async () => {
			setIsFetchingTickets(true);

			const response = await tryCatchJsonByAction(getAllTicketsSimpleAction);
			if (response.success) {
				setTickets(response.items || []);
			}

			setIsFetchingTickets(false);
		},
		[]
	)

	// fetch change requests
	const [changeRequestIds, setChangeRequestIds] = useState<number[]>([]);
	const [fetchingChangeRequests, setFetchingChangeRequests] = useState(true);

	const fetchChangeRequestsCallback = useCallback(
		async () => {
			setFetchingChangeRequests(true);
			const response = await tryCatchJsonByAction(getAllChangeRequestsSimpleAction);
			if (response.success) {
				setChangeRequestIds(response.items?.map(cr => cr.id) || []);
			}
			setFetchingChangeRequests(false);
		},
		[]
	)

	// fetch level 3
	const [level3Items, setLevel3Items] = useState<SimpleIdNameModel[]>([]);
	const [fetchingLevel3Items, setFetchingLevel3Items] = useState(false);

	const fetchLevel3ItemsCallback = useCallback(
		async () => {
			setFetchingLevel3Items(true);
			const response = await tryCatchJsonByAction(getAllTestPlansSimpleAction);
			if (response.success) {
				setLevel3Items(response.items || []);
			}

			setFetchingLevel3Items(false);
		},
		[]
	)

	// fetch dropdowns
	useEffect(
		() => {
			fetchTicketsCallback();
			fetchChangeRequestsCallback();
			fetchLevel3ItemsCallback();
		},
		[fetchChangeRequestsCallback, fetchTicketsCallback, fetchLevel3ItemsCallback]
	)

	// fetch values
	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getRulesAndAlertsAction);
			if (response.success) {
				const model = new Model();

				for (let item of response.items || []) {
					const { id, enabled, sendEmail, entityIds } = item;
					switch (item.entity) {
						case AlertEntitiesEnum.TestPlan:
							model.idTestCase = id;
							model.notifyTestCase = enabled;
							model.emailTestCase = sendEmail;
							model.selectedLevels3 = entityIds;
							break;
						case AlertEntitiesEnum.Ticket:
							model.idTicket = id;
							model.notifyTicket = enabled;
							model.emailTicket = sendEmail;
							model.selectedTickets = entityIds;
							break;
						case AlertEntitiesEnum.ChangeRequest:
							model.idChangeRequest = id;
							model.notifyChangeRequest = enabled;
							model.emailChangeRequest = sendEmail;
							model.selectedChangeRequests = entityIds;
							break;
					}
				}

				setValues(model);
				setInitialValues(model);
			}
		},
		[]
	)

	const [refetching, setRefetching] = useState(false);

	const refetchDataCallback = useCallback(
		async () => {
			setRefetching(true);
			await fetchDataCallback();
			setRefetching(false);
		},
		[fetchDataCallback]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const model = new AddUpdateUserAlertConfigurationsRequest();
			model.addedItems = [];
			model.updatedItems = [];

			if (values.idTestCase) {
				model.updatedItems.push(new UpdateUserAlertConfigurationRequest({
					id: values.idTestCase,
					enabled: values.notifyTestCase,
				    sendEmail: values.emailTestCase,
				    entityIds: values.selectedLevels3
				}))
			} else {
				model.addedItems.push(new AddUserAlertConfigurationRequest({
					entity: AlertEntitiesEnum.TestPlan,
					enabled: values.notifyTestCase,
					sendEmail: values.emailTestCase,
				    entityIds: values.selectedLevels3,
					trigger: AlertTriggersEnum.Updated
				}))
			}

			if (values.idTicket) {
				model.updatedItems.push(new UpdateUserAlertConfigurationRequest({
					id: values.idTicket,
					enabled: values.notifyTicket,
					sendEmail: values.emailTicket,
					entityIds: values.selectedTickets
				}))
			} else {
				model.addedItems.push(new AddUserAlertConfigurationRequest({
					entity: AlertEntitiesEnum.Ticket,
					enabled: values.notifyTicket,
					sendEmail: values.emailTicket,
					entityIds: values.selectedTickets,
					trigger: AlertTriggersEnum.Updated
				}))
			}

			if (values.idChangeRequest) {
				model.updatedItems.push(new UpdateUserAlertConfigurationRequest({
					id: values.idChangeRequest,
					enabled: values.notifyChangeRequest,
				    sendEmail: values.emailChangeRequest,
				    entityIds: values.selectedChangeRequests
				}))
			} else {
				model.addedItems.push(new AddUserAlertConfigurationRequest({
					entity: AlertEntitiesEnum.ChangeRequest,
					enabled: values.notifyChangeRequest,
					sendEmail: values.emailChangeRequest,
				    entityIds: values.selectedChangeRequests,
					trigger: AlertTriggersEnum.Updated
				}))
			}

			const bindedAction = updateRulesAndAlertsAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Rules and alerts are updated.');
				refetchDataCallback();
				setInitialValues(values);
			} else {
				return convertResponseErrors(response)
			}
		},
		[values, refetchDataCallback]
	)

	const resetCallback = useCallback(
		() => {
			const newValues = new Model(initialValues);
			setValues(newValues);
		},
		[initialValues]
	)

	return (
		<ContentShell 
			title='Rules and alerts'
			FloatingHelpComponent={RulesAndAlertsHelp}
		>
			<WithFetch fetchFunction={fetchDataCallback} refetching={refetching}>
				<Form
					values={values}
					initialValues={initialValues}
					onChange={setValues}
					onSubmit={onSubmitCallback}
					onCancel={resetCallback}
					cancelButtonText='Reset'
					render={() => (
						<>
							<SmartFormGroup>
								<RowContainer justifyContent='end'>
									<span>Send email:</span>
								</RowContainer>
							</SmartFormGroup>
							<SmartFormGroup>
								<div className={styles.row}>
									<div className={styles.item}>
										<CheckboxField
											id={propertyOf<Model>('notifyTestCase')}
											labelBefore='Notify me if process or step status is changed in test case where I’m assigned as tester'
										/>
									</div>
									<div className={styles.item}>
										<MultiSelectField
											id={propertyOf<Model>('selectedLevels3')}
											label='Level 3 ID'
											items={level3Items}
											getItemId={(item: SimpleIdNameModel) => item.id}
											getItemText={(item: SimpleIdNameModel) => item.name || ''}
											loading={fetchingLevel3Items}
										/>
									</div>
									<div className={styles.send_email}>
										<CheckboxField
											id={propertyOf<Model>('emailTestCase')}
										/>
									</div>
								</div>
							</SmartFormGroup>
							<SmartFormGroup>
								<div className={styles.row}>
									<div className={styles.item}>
										<CheckboxField
											id={propertyOf<Model>('notifyTicket')}
											labelBefore='Notify me if ticket is changed where I’m involved'
										/>
									</div>
									<div className={styles.item}>
										<MultiSelectField
											id={propertyOf<Model>('selectedTickets')}
											label='Ticket ID'
											items={tickets}
											getItemId={(item: SimpleIdNameModel) => item.id}
											getItemText={(item: SimpleIdNameModel) => `${getFormatedId(EntityPrefixEnum.TICKET, item.id)} - ${item.name}`}
											loading={isFetchingTickets}
										/>
									</div>
									<div className={styles.send_email}>
										<CheckboxField
											id={propertyOf<Model>('emailTicket')}
										/>
									</div>
								</div>
							</SmartFormGroup>
							<SmartFormGroup>
								<div className={styles.row}>
									<div className={styles.item}>
										<CheckboxField
											id={propertyOf<Model>('notifyChangeRequest')}
											labelBefore='Notify me if change request is changed where I’m involved'
										/>
									</div>
									<div className={styles.item}>
										<MultiSelectField
											id={propertyOf<Model>('selectedChangeRequests')}
											label='Change Request ID'
											items={changeRequestIds}
											getItemId={(item: number) => item}
											getItemText={(item: number) => getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, item)}
											loading={fetchingChangeRequests}
										/>
									</div>
									<div className={styles.send_email}>
										<CheckboxField
											id={propertyOf<Model>('emailChangeRequest')}
										/>
									</div>
								</div>
							</SmartFormGroup>
						</>
					)}
				/>
			</WithFetch>
		</ContentShell>
	)
}
