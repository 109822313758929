import { Dialog } from 'components/Dialog';
import RejectRequestForm from './RejectRequestForm';

type Props = {
	open: boolean
	onCancel(): void
	onSave(rejectionComment: string): void
	title: string,
	rejectionCommentRequired: boolean
}

const RejectRequestModalForm = ({ open = false, onSave, onCancel, title, rejectionCommentRequired }: Props) => {

	return (
		<Dialog
			open={open}
			size='xlarge'
			title={title}
			onClose={onCancel}
		>
			<RejectRequestForm
				onSave={onSave}
				onCancel={onCancel}
				rejectionCommentRequired={rejectionCommentRequired}
			/>
		</Dialog>
	);
};

export default RejectRequestModalForm;
