import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux';
import styles from './mySurveysCrud.module.scss'
import { ProjectPickerParams } from 'features/Project';
import { useParams } from 'react-router';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { MySurveyQuestionResponse,  ProjectResponse,  SurveyResponse } from 'services/tenantManagementService';
import { ColumnContainer, VerticalSeparator } from 'components/Layout';
import WithFetch from 'features/Fetch/WithFetch';
import { SmartFormGroup } from 'components/SmartContainer/SmartContainer';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { getMySurveyQuestionsAction } from '../action';
import ViewSurveyQuestions from './ViewSurveyQuestions';
import { RootState } from 'base/reducer/reducer';
import Export from '../Export/Export';
import { ContentShell } from 'features/Content/ContentShell';
import { getSurveyAction } from 'containers/Training/Surveys/Crud/action';

type ParamType = ProjectPickerParams & {
	surveyId: string
}

const ViewSurvey = () => {
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId as string);
	const surveyId = parseInt(params.surveyId);
	const { persistedProject } = useSelector((state: RootState) => state)

	const [survey, setSurvey] = useState(new SurveyResponse());
	const [surveyQuestions, setSurveyQuestions] = useState<MySurveyQuestionResponse[]>([]);

	const fetchSurveyQuestionsCallback = useCallback(
		async () => {
			const bindedAction = getMySurveyQuestionsAction.bind(null, projectId, surveyId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.items) {
				setSurveyQuestions(response.items);
			}
		},
		[surveyId, projectId]
	)

	const fetchSurveyCallback = useCallback(
		async () => {
			const bindedAction = getSurveyAction.bind(null, projectId, surveyId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				const surveyResponse = response.value
				setSurvey(surveyResponse);
			}
		},
		[surveyId, projectId]
	)


	const fetchDataCallback = useCallback(
		async () => {
			await Promise.all([ fetchSurveyCallback(), fetchSurveyQuestionsCallback()])
		},
		[fetchSurveyCallback, fetchSurveyQuestionsCallback]
	)

	return (
		<ContentShell title='View survey'>
			<ColumnContainer>
				<Export
					survey={survey}
					surveyQuestions={surveyQuestions}
					project={persistedProject.itemsMap[projectId] || new ProjectResponse()}
				/>
				<WithFetch fetchFunction={fetchDataCallback}>
					{!!survey &&
						<ColumnContainer>
							<>
								<SmartFormGroup label='Project'>
									<div className={styles.form_group_field}>
										<p className={styles.form_group_field_value}>
											{`${getFormatedId(EntityPrefixEnum.PROJECT, projectId)} - ${persistedProject.itemsMap[projectId]?.name}`}
										</p>
									</div>
								</SmartFormGroup>
								<SmartFormGroup label={`Viewing ${survey.surveyType}`}>
									<div className={styles.form_group_field}>
										<p className={styles.form_group_field_value_bold}>
											{`${getFormatedId(EntityPrefixEnum.SURVEY, survey.id)} - ${survey.name}`}
										</p>
									</div>
								</SmartFormGroup>
								{!!survey.description &&
									<>
										<VerticalSeparator />
										<p>{survey.description}</p>
									</>
								}
							</>

							<ViewSurveyQuestions
								surveyQuestions={surveyQuestions}
							/>
						</ColumnContainer>
					}
				</WithFetch>
			</ColumnContainer>
		</ContentShell>
	)
}

export default ViewSurvey;
