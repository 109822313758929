import { useCallback, useState } from 'react';
import notifications from 'components/Notification/notification';
import { UserForm } from 'containers/Configuration/SiteConfiguration/UserAdministration/Users/User/UserForm';
import { persistUsersAction } from 'containers/Configuration/SiteConfiguration/UserAdministration/Users/action';
import { MyProfileUpdateRequestModel, UserModel } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { setUserInfo } from 'utils/storageUtils';
import { getMyProfileAction, updateMyProfileAction} from './action';
import { CrudEnum } from 'features/Crud';
import WithFetch from 'features/Fetch/WithFetch';
import { ContentShell } from 'features/Content/ContentShell';
import { MyProfileHelp } from './Help/MyProfileHelp';

const MyProfile = () => {
	const [user, setUser] = useState(new UserModel());

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getMyProfileAction);
			if (response.success) {
				const userModel = response.value || new UserModel();
				setUser(userModel);
				setUserInfo(userModel);
			} else {
				notifications.warning(`Can't fetch profile, please contact system administrator`);
			}
		},
		[]
	)

	const handleSave = useCallback(
		async (newUserModel: UserModel) => {
			const myProfile = new MyProfileUpdateRequestModel(newUserModel);
			const bindedAction = updateMyProfileAction.bind(null, myProfile);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const userModel = response.value || new UserModel();
				setUser(userModel);
				setUserInfo(userModel);
				persistUsersAction();
				notifications.success('Your profile is updated.');
			} else {
				return convertResponseErrors(response);
			}
		},
		[]
	);

	return (
		<ContentShell
			title='My Profile'
			FloatingHelpComponent={MyProfileHelp}
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<UserForm
					user={user}
					crud={CrudEnum.Update}
					onSave={handleSave}
				/>
			</WithFetch>
		</ContentShell>
	)
}

export default MyProfile;
