import { useSelector } from 'react-redux';
import { CheckboxField, Form, MapMultiField, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import {  GetVacationsCalendarRequest, BusinessPartnerResponseModel } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { propertyOf } from 'utils/propertyOf';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';

type Props = {
	filterModel: GetVacationsCalendarRequest
	onChange(values: any): void
}

export const TeamVacationsFilterForm = ({ filterModel, onChange }: Props) => {
	const {
		persistedBusinessPartner,
		persistedOrganizationalUnit,
	} = useSelector((state: RootState) => state);

	return (
		<Form
			values={filterModel}
			onChange={onChange}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<MultiSelectField
							id={propertyOf<GetVacationsCalendarRequest>('partnerIds')}
							label='Partner'
							disabled={filterModel.onlyCompanyUsers}
							items={persistedBusinessPartner.items}
							getItemId={(item: BusinessPartnerResponseModel) => item.id}
							getItemText={(item: BusinessPartnerResponseModel) => item.name}
							loading={persistedBusinessPartner.fetching}
						/>
						<MapMultiField
							id={propertyOf<GetVacationsCalendarRequest>('organizationalUnitIds')}
							label='Organizational Unit'
							items={convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined)}
							loading={persistedOrganizationalUnit.fetching}
						/>
						<CheckboxField
							id={propertyOf<GetVacationsCalendarRequest>('onlyCompanyUsers')}
							label='Only company users'
						/>
					</SmartItem>
				</SmartContainer>
			)}
			hideButtons
			disableUnsavedChangesGuard
		/>
	)
}
