import { FinanceIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";

// subroutes

export enum FinanceSubRoutesEnum {
	MY_DASHBOARD = 'finance_my_dashboard',
	INVOICES = 'invoices',
	BUDGET = 'budget',
}

export const myDashboardSubRoute: Route = {
	id: FinanceSubRoutesEnum.MY_DASHBOARD,
	url: `/${RootRouteEnum.FINANCE}/${FinanceSubRoutesEnum.MY_DASHBOARD}`,
	text: 'My Dashboard',
}

export const invoicesSubRoute: Route = {
	id: FinanceSubRoutesEnum.INVOICES,
	url: `/${RootRouteEnum.FINANCE}/${FinanceSubRoutesEnum.INVOICES}`,
	text: 'Invoices',
}

export const budgetSubRoute: Route = {
	id: FinanceSubRoutesEnum.BUDGET,
	url: `/${RootRouteEnum.FINANCE}/${FinanceSubRoutesEnum.BUDGET}`,
	text: 'Budget',
}

// root route

export const financeRoute: Route = {
	id: RootRouteEnum.FINANCE,
	url: `/${RootRouteEnum.FINANCE}`,
	text: 'Finance',
	Icon: FinanceIcon,
	children: [
		myDashboardSubRoute,
		invoicesSubRoute,
		budgetSubRoute
	]
}
