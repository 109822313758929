import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getRouteObject, RouterProps } from 'features/Routes';
import { CompanyInfo } from './CompanyInfo/CompanyInfo';
import { UserAdministrationRouter } from './UserAdministration/UserAdministrationRouter';
import { GeneralRouter } from './General/GeneralRouter';
import { ProjectsRouter } from './Projects/ProjectsRouter';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { ModuleRouter } from './Module/ModuleRouter';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { persistBusinessPartnersAction } from './Projects/CreateNewPartner/action';
import { persistOrganizationalUnitsAction } from './General/Company/OrganizationalUnits/action';
import { companyInformationSubRoute } from './routes';
import { userAdministrationRoute } from './UserAdministration/routes';
import { generalRoute } from './General/routes';
import { projectsConfigurationRoute } from './Projects/routes';
import { moduleRoute } from './Module/routes';
import { SiteConfigurationSubRoutesEnum } from './enums';

export const SiteConfigurationRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistBusinessPartnersAction()
			persistOrganizationalUnitsAction()
		},
		[]
	);

	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[SiteConfigurationSubRoutesEnum.COMPANY_INFOMATION] &&
				<Route
					path={companyInformationSubRoute.url}
					render={() => <CompanyInfo />}
				/>
			}
			{routesMap[SiteConfigurationSubRoutesEnum.USER_ADMINISTRATION] &&
				<Route
					path={userAdministrationRoute.url}
					render={
						() => <UserAdministrationRouter route={routesMap[SiteConfigurationSubRoutesEnum.USER_ADMINISTRATION]} />
					}
				/>
			}
			{routesMap[SiteConfigurationSubRoutesEnum.GENERAL] &&
				<Route
					path={generalRoute.url}
					render={() => <GeneralRouter route={routesMap[SiteConfigurationSubRoutesEnum.GENERAL]} />}
				/>
			}
			{routesMap[SiteConfigurationSubRoutesEnum.PROJECTS] &&
				<Route
					path={projectsConfigurationRoute.url}
					render={() => <ProjectsRouter route={routesMap[SiteConfigurationSubRoutesEnum.PROJECTS]} />}
				/>
			}
			{routesMap[SiteConfigurationSubRoutesEnum.MODULE] &&
				<Route
					path={moduleRoute.url}
					render={() => <ModuleRouter route={routesMap[SiteConfigurationSubRoutesEnum.MODULE]} />}
				/>
			}

			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
