import { useCallback, useState } from 'react';
import Button from 'components/Button';
import { Dialog } from 'components/Dialog';
import { HistoryLogTable, HistoryLogType } from './HistoryLogTable';

type Props = {
	title: string
	log?: HistoryLogType[]
	onOpen(): void
}

export const HistoryLogDialog = ({ title, log, onOpen }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModalCallback = useCallback(
		async () => {
			setIsModalOpen(true);
			onOpen();
		},
		[onOpen]
	)

	const closeModalCallback = useCallback(
		() => setIsModalOpen(false),
		[]
	)

	return (
		<>
			<Button
				text='View History Log'
				color='neutral'
				onClick={openModalCallback}
			/>
			<Dialog
				open={isModalOpen}
				title={title}
				onClose={closeModalCallback}
				size='xxxlarge'
			>
				<HistoryLogTable log={log} />
			</Dialog>
		</>
	)
}
