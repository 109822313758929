import { useCallback, useState } from 'react'
import styles from './header.module.scss'
import { AccountIcon } from 'components/icons/icons';
import { UserPanel } from './UserPanel';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { RootRouteEnum } from 'features/Routes';
import { getCssVariableValue } from 'utils/cssVariablesUtils';

export const User = () => {
	const [panelVisible, setPanelVisible] = useState(false);

	const toggleUserPanelCallback = useCallback(
		() => setPanelVisible((state) => !state),
		[]
	)

	const currentRoutes = useSelector((state: RootState) => state.routes.currentRoutes);
	const activeItemId = currentRoutes[0] && currentRoutes[0].id;
	const isActive = activeItemId === RootRouteEnum.ACCOUNT || panelVisible;

	const iconSize = getCssVariableValue('--shell-icon-size');

	return (
		<>
			<div
				className={isActive ? `${styles.item} ${styles.active}` : styles.item}
				onClick={toggleUserPanelCallback}
			>
				<AccountIcon width={iconSize} height={iconSize} fill='currentColor' />
			</div>
			<UserPanel
				visible={panelVisible}
				toggleVisible={toggleUserPanelCallback}
			/>
		</>
	)
}
