import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import { TasksForm } from './TaskForm';
import { TaskResponse, UpdateTaskRequest } from 'services/tenantManagementService';
import { getTaskAction, updateTaskAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import notifications from 'components/Notification/notification';
import { ContentShell } from 'features/Content/ContentShell';
import { Follow } from './Follow/Follow';

type ParamType = {
	taskId: string
}

export const UpdateTask = ({ publishDataChanged }: UpdateComponentProps) => {
	const params: ParamType = useParams();
	const taskId = parseInt(params.taskId as string);
	const goBackFromUpdate = useGoBackFromUpdate(true);

	const [task, setTask] = useState(new TaskResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getTaskAction.bind(null, taskId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setTask(response.value || new TaskResponse());
			}
		},
		[taskId]
	)

	const saveCallback = useCallback(
		async (newTask: TaskResponse) => {
			const model = new UpdateTaskRequest(newTask);
			const bindedAction = updateTaskAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TASK, response.value?.id);
				notifications.success(`Task ${id} is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged]
	)

	const renderFollowCallback = useCallback(
		() => <Follow task={task} publishDataChanged={publishDataChanged}  />,
		[task, publishDataChanged]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell
				title={`Change task - ${getFormatedId(EntityPrefixEnum.TASK, task.id)}`}
				renderAdditionalHeaderContent={renderFollowCallback}
			>
				<TasksForm
					task={task}
					crud={CrudEnum.Update}
					onSave={saveCallback}
					cancel={goBackFromUpdate}
				/>
			</ContentShell>
		</WithFetch>
	)
}
