import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, DateField, AutoCompleteField } from 'components/Form';
import notifications from 'components/Notification/notification';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { InsertProjectReportRequest, ReportStatusResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createReportAction } from '../action';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { CreateComponentProps, CrudSubRoutesEnum, useGoBackFromCreate } from 'features/Crud';
import { ProjectPickerParams } from 'features/Project';
import { ProjectReportHelp } from '../Help/ProjectReportHelp';
import { ContentShell } from 'features/Content/ContentShell';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { getInitialStatus } from 'features/StatusResponse/statusResponse';

export const CreateReport = ({ publishDataChanged }: CreateComponentProps) => {
	const { persistedReportStatus } = useSelector((state: RootState) => state);

	const params: ProjectPickerParams = useParams();
	const projectId = parseInt(params.projectId as string);
	const history = useHistory();

	const [values, setValues] = useState(new InsertProjectReportRequest());
	const [status, setStatus] = useState(new ReportStatusResponse());

	const initialValues = useMemo(
		() => {
			const model = new InsertProjectReportRequest();
			model.statusId = status.id;
			return model;
		},
		[status]
	)

	const goBackFromCreate = useGoBackFromCreate();

	// set initial status and its value
	useEffect(
		() => {
			if (!persistedReportStatus.isInitialized) {
				return;
			}

			const status = getInitialStatus(persistedReportStatus.items) || new ReportStatusResponse();
			setStatus(status);

			setValues((state: any) => {
				return {
					...state,
					statusId: status.id
				}
			})
		},
		[persistedReportStatus]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const bindedAction = createReportAction.bind(null, projectId, values);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const formattedId = getFormatedId(EntityPrefixEnum.REPORT, response.value?.id);
				notifications.success(`Report ${formattedId} is created.`);
				publishDataChanged();
				history.push(`./${CrudSubRoutesEnum.Update}/${response.value?.id}`)
			} else {
				return convertResponseErrors(response);
			}
		},
		[history, values, projectId, publishDataChanged]
	);

	return (
		<ContentShell
			title='Create Project Report'
			FloatingHelpComponent={ProjectReportHelp}
		>
			<Form
				values={values}
				initialValues={initialValues}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={goBackFromCreate}
				render={() => (
					<SmartContainer>
						<SmartItem>
							<DateField
								id='from'
								label='Reporting period from'
								isRequired
							/>
							<DateField
								id='to'
								label='Reporting period to'
								isRequired
							/>
							<AutoCompleteField
								id='statusId'
								label='Report status'
								disabled
								items={[ status ]}
								getItemId={(item: ReportStatusResponse) => item.id}
								getItemText={(item: ReportStatusResponse) => item.name}
								getItemDescription={(item: ReportStatusResponse) => item.description}
								loading={persistedReportStatus.fetching}
							/>
						</SmartItem>
					</SmartContainer>
				)}
			/>
		</ContentShell>
	)
}
