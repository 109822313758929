import Tabs, { TabType } from 'components/Tabs/Tabs'
import { projectTab, projectsGanttTab, utilizationTab, projectUtilizationTab } from './tabs'
import { getIsUserProjectManager, getIsUserManagerAtAnyOrganizationalUnit } from 'utils/storageUtils';
import { useMemo } from 'react';
import { isUserSiteAdmin } from 'utils/userRoleHelper';

export const ScheduleDashboard = () => {
	const tabs = useMemo(
		() => {
			const userTabs: TabType[] = [projectTab, projectsGanttTab];
			const isSiteAdmin = isUserSiteAdmin();
			const isPM = getIsUserProjectManager()
			const isOrganizationalUnitManager = getIsUserManagerAtAnyOrganizationalUnit();

			if (isSiteAdmin || (isPM && isOrganizationalUnitManager)) {
				return [projectTab, projectsGanttTab, utilizationTab, projectUtilizationTab];
			}

			if (isPM && !isOrganizationalUnitManager) {
				return [projectTab, projectsGanttTab, projectUtilizationTab];
			}

			if (!isPM && isOrganizationalUnitManager) {
				return [utilizationTab];
			}

			return userTabs;
		},
		[]
	)

	return (
		<Tabs
			tabs={tabs}
		/>
	)
}
