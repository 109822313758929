import { getTenantId } from "features/Tenant/tenantUtils";
import { AlertsClient } from "services/tenantManagementService";

export const getAlertsAction = () => {
	const tenantId = getTenantId()!;
	const client = new AlertsClient();
	return client.getAll(tenantId);
}

export const setSeenAllAlertsAction = () => {
	const tenantId = getTenantId()!;
	const client = new AlertsClient();
	return client.seenAll(tenantId);
}

export const deleteAlertAction = (alertId: number) => {
	const tenantId = getTenantId()!;
	const client = new AlertsClient();
	return client.delete(tenantId, alertId);
}

export const deleteAlertsAction = () => {
	const tenantId = getTenantId()!;
	const client = new AlertsClient();
	return client.deleteAll(tenantId);
}
