import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import { GenericFilterModelCollection, MultiplyUpdateTrainingPlanStatusRequest, ProjectResponse, TenantIsActiveResponse, UpdateTrainingPlanStatus } from 'services/tenantManagementService';
import { exportAction, getMyTrainingPlanGenericAction, updateMyTrainingPlansAction } from './action';
import TableButtons from './Table/TableButtons';
import { useTableColumnsMemo } from './Table/tableColumns';
import { ContentShell } from 'features/Content/ContentShell';
import { useSelector } from 'react-redux';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { CustomMyTrainingPlanRowModel, mapToCustomMyTrainingPlanRowModel } from './Table/tableModel';
import { persistTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { downloadAttachmentAction } from 'containers/Scope/MaintainScope/action';
import FileSaver from 'file-saver';
import { MyTrainingPlanHelp } from './Help/MyTrainingPlanHelp';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';

type Props = {
	project: ProjectResponse
	disabledEdit?: boolean
	trainingCycle: TenantIsActiveResponse
}

const configureViewKey = 'my_training_plan_table'

export const MyTrainingPlanTable = ({ project, disabledEdit, trainingCycle } : Props) => {
	const {
		persistedConfigureView,
		persistedTeamMember
	} = useSelector((state: RootState) => state);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [updateRequest, setUpdateRequest] = useState(new MultiplyUpdateTrainingPlanStatusRequest());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());
	const stateRef = useRef(new MultiplyUpdateTrainingPlanStatusRequest());
	stateRef.current = updateRequest;

	const downloadAttachmentCallback = useCallback(
		async (id: number) => {
			const bindedAction = downloadAttachmentAction.bind(null, project.id)
			const response = await bindedAction(id);

			if (response.status === 200) {
				FileSaver.saveAs(response.data, response.fileName);
			}
		},
		[project.id]
	)

	const tableColumns = useTableColumnsMemo(
		downloadAttachmentCallback,
		disabledEdit,
		persistedConfigureView.value[configureViewKey],
	);

	useEffect(
		() => {
			if (!persistedTeamMember.projectMap[project.id]) {
				persistTeamMembersAction(project.id);
			}
		},
		[persistedTeamMember, project.id]
	)

	// just a quick fix, memoFetchFunction was changed after project is changed and unneeded request was send
	const [currentProject, setCurrentProject] = useState(new ProjectResponse(project));
	useEffect(
		() => {
			setCurrentProject(new ProjectResponse(project));
		},
		[project]
	)

	const saveCallback = useCallback(
		async () => {
			setIsLoading(true);
			const bindedAction = updateMyTrainingPlansAction.bind(null, project.id, trainingCycle.id, updateRequest);

			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Training plans successfully updated');
				setUpdateRequest(new MultiplyUpdateTrainingPlanStatusRequest());
			}
			setIsLoading(false);
		},
		[updateRequest, project.id, trainingCycle.id]
	)

	const memoFetchFunction = useMemo(
		() => async (genericFilter: GenericFilterModelCollection) => {
			const response = await getMyTrainingPlanGenericAction(currentProject.id, trainingCycle.id, genericFilter)
			setIsLoading(false);
			return response
		},
		[currentProject.id, trainingCycle.id]
	)

	const memoExportFunction = useMemo(
		() => exportAction.bind(null, currentProject.id, trainingCycle.id),
		[currentProject.id, trainingCycle]
	)

	const cellEditedCallback = useCallback(
		(cell: any) => {
			const data: CustomMyTrainingPlanRowModel = cell.getData();
			let trainingPlanStatuses = stateRef.current.trainingPlanStatuses || [];
			let found =  trainingPlanStatuses.find(tp => tp.trainingPlanId === data.id);

			const changedObject = new UpdateTrainingPlanStatus({ trainingPlanId: data.id, statusId: data.statusId });

			if (!found) {
				trainingPlanStatuses = [...trainingPlanStatuses, changedObject];
			} else {
				trainingPlanStatuses = trainingPlanStatuses.map(tp => tp.trainingPlanId === data.id ? changedObject : tp);
			}

			const newUpdateRequest = new MultiplyUpdateTrainingPlanStatusRequest({
				trainingPlanStatuses
			})

			setUpdateRequest(newUpdateRequest);
		},
		[]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='My Training Plan'
			FloatingHelpComponent={MyTrainingPlanHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					disableSave={disabledEdit}
					onSave={saveCallback}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					fetchFunction={memoFetchFunction}
					reorderColumns={reorderColumnsCallback}
					mapResponse={mapToCustomMyTrainingPlanRowModel}
					cellEdited={cellEditedCallback}
					isLoading={isLoading}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}
