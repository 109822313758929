import { useMemo } from 'react';
import { TestPlanResponse, TestStatusEnum } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { FailedTestsForm } from './FailedTestsForm';
import { RootState } from 'base/reducer/reducer';
import { useSelector } from 'react-redux';

type Props = {
	testPlan: TestPlanResponse
	open: boolean
	saveOnly(testPlan: TestPlanResponse): void
	saveAndCreateTicket(testPlan: TestPlanResponse): void
	cancel(): void
}

export const FailedTestModal = ({ testPlan, saveOnly, saveAndCreateTicket, cancel, open }: Props) => {
	const { persistedTestCaseStatus } = useSelector((state: RootState) => state);

	const titleMemo = useMemo(
		() => {
			const failedTests = testPlan.innerTestPlans?.filter(itp => isStatusBySemantic(TestStatusEnum.Failed, itp.stepStatusId, persistedTestCaseStatus.itemsMap))

			const failedTestNames = failedTests && failedTests.length > 0 ?
				failedTests.map(itp => itp.processStepName).join(',') :
				testPlan.level3ProcessName!;

			return `${failedTestNames} -> Failed`;

		},
		[testPlan, persistedTestCaseStatus.itemsMap]
	)

	return (
		<Dialog
			title={titleMemo}
			onClose={cancel}
			open={open}
			size='large'
		>
			<FailedTestsForm
				testPlan={testPlan}
				saveOnly={saveOnly}
				saveAndCreateTicket={saveAndCreateTicket}
				cancel={cancel}
			/>
		</Dialog>
	)
}
