import { MaxLength } from '../../addons';
import { useCallback } from 'react';
import { ControlsCommonProps } from '../../fields';
import styles from './textarea.module.scss';
import { InfoPopup, POSITION } from 'components/Popup';

export type TextareaProps = ControlsCommonProps<string> & {
	placeholder?: string
	explanation?: string
	rows?: number
	maxLength?: number
	focus?: boolean
}

export const Textarea = ({ value, onChange, onBlur, disabled, placeholder, explanation, rows = 5, maxLength = 240, focus }: TextareaProps) => {
	const onChangeCallback = useCallback(
		(e) => onChange && onChange(e.target.value),
		[onChange]
	)

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<textarea
					className={styles.textarea}
					onChange={onChangeCallback}
					onBlur={onBlur}
					placeholder={placeholder}
					disabled={disabled}
					// when value is null or undefined, React threats that as uncontrolled component and doesn't change value
					value={value || ''}
					rows={rows}
					autoFocus={focus}
					maxLength={maxLength}
				/>
				{/* max length */}
				{maxLength &&
					<div className={styles.max_length}>
						<MaxLength
							length={value ? value.length : 0}
							maxLength={maxLength}
						/>
					</div>
				}
			</div>
			{/* explanation */}
			{explanation &&
				<div className={styles.explanation}>
					<InfoPopup
						message={explanation}
						position={POSITION.TOP_CENTER}
					/>
				</div>
			}
		</div>
	)
}
