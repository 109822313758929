import { useCallback, useEffect, useState } from 'react'
import { ScopeChangeRequestsDashboardFilterRequest, ScopeChangeRequestsDashboardResponse } from 'services/tenantManagementService'
import FilteredDashboard from './FilteredDashboard'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getChangeRequestsDashboardAction } from './action'
import { FilterForm, FilterModel } from './FilterForm'
import { DashboardContainer } from 'features/Dashboard/DashboardContainer/DashboardContainer'
import WithFetch from 'features/Fetch/WithFetch'
import { useSaveFilterMemo } from 'features/Dashboard/SaveFilter/useSaveFilterMemo'

const viewType = 'scope_change_requests_dashboard';

export const ChangeRequestsDashboard = () => {
	const [loadingChangeRequests, setLoadingChangeRequests] = useState(false);
	const { savedFilterModel, saveFilterModel, fetchFilterModel, isLoadingFilter } = useSaveFilterMemo<FilterModel>(viewType);

	const [changeRequestDashboard, setChangeRequestDashboard] = useState(new ScopeChangeRequestsDashboardResponse())

	const [currentFilterModel, setCurrentFilterModel] = useState(new FilterModel());

	const fetchDashboardCallback = useCallback(
		async (model: FilterModel) => {
			setLoadingChangeRequests(true);

			setCurrentFilterModel(model);

			const bindedAction = getChangeRequestsDashboardAction.bind(null, model.projectId, new ScopeChangeRequestsDashboardFilterRequest(model))
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setChangeRequestDashboard(response.value);
			}

			setLoadingChangeRequests(false);
		},
		[]
	)

	useEffect(
		() => {
			if (savedFilterModel) {
				fetchDashboardCallback(savedFilterModel);
			}
		},
		[savedFilterModel, fetchDashboardCallback]
	)

	return (
		<WithFetch fetchFunction={fetchFilterModel} refetching={isLoadingFilter}>
			<DashboardContainer
				form={
					<FilterForm
						onSubmit={fetchDashboardCallback}
						filterFormModel={savedFilterModel}
						onSave={saveFilterModel}
					/>
				}
				dashboard={
					<FilteredDashboard
						loading={loadingChangeRequests}
						dashboard={changeRequestDashboard}
						projectId={currentFilterModel.projectId}
						statusId={currentFilterModel.statusId}
					/>
				}
			/>
		</WithFetch>
	)
}
