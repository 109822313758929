import { HelpShell } from 'features/Help/HelpShell';

const MaintainScopeHelp = () => {
	return (
		<HelpShell title='Maintain Scope'>
			<p>Scope, Testing and Training modules are highly integrated.</p>
			<p>Defined scope is prerequisite for Testing and Training execution. Based on scope items testing and training activities in application are executed.</p>
			<div>
				<p>Before you start utilizing these modules, first some basic configuration needs to be completed by:</p>
				<ol>
					<li>Site administrator (basic configuration) and</li>
					<li>Project Manager</li>
				</ol>
			</div>
			<div>
				<p>
					Purpose of scope module is to define and maintain scope items for your project and group them in logical sections. <br />
					We defined groupings of your scope items on 4 levels: 
				</p>
				<ul>
					<li>Level 1 - highest level of process grouping (example: Mavles Scope module)</li>
					<li>Level 2 - sublevel of level 1 (example: Maintain scope)</li>
					<li>Level 3 - unique identification of process (example: Create process in Scope module)</li>
					<li>Level 4 - process steps within process (example: create level 1 group -{'>'} create level 2 group -{'>'} create level 3 process-add process steps)</li>
				</ul>
			</div>
			<p>Level 3 is designated for process and has unique identification and level 4 for process steps of level 3 process.</p>
			<div>
				<p>Scope items on Level 3 are replicated in:</p>
				<ul>
					<li>Training module where you can link processes with training plan and training materials and assign users to training plans.</li>
					<li>Testing module test plan and user test packages.</li>
				</ul>
			</div>
			<p>Processes are always maintained (created/deleted) in scope module and replicated in Testing and Training module.</p>
			<p>Scope Items can be maintained manually, but you can also import scope items from xls in format provided by Mavles.</p>
			<div>
				<p>Structure od spread sheet is following:</p>
				<ol>
					<li>Level 1 ID - CHAR(2) - process group ID</li>
					<li>Level 1 Name - CHAR(40) - process group name</li>
					<li>Level 2 ID  - CHAR(3) - process subgroup ID</li>
					<li>Level 2 ID  - CHAR(80) - process subgroup name</li>
					<li>Level 3 ID  - CHAR(3) - identification of process (example: 001, 002, 003)</li>
					<li>Level 3 Name - CHAR(80) - Process name</li>
					<li>Level 3 Description - CHAR(2000) - Process long description</li>
					<li>Regression relevant - CHAR(1) - if relevant enter x</li>
					<li>Stress test relevant - CHAR(1) - if relevant enter x</li>
					<li>Level 4 ID - CHAR(40) - Process step ID</li>
					<li>Level 4 Description - CHAR (120) - Process step description</li>
					<li>Department - CHAR(40) - Define responsible department</li>
					<li>Step type - CHAR(40) - Define step type (example: transaction, interface etc..)</li>
				</ol>
			</div>
		</HelpShell>
	)
}

export default MaintainScopeHelp;
