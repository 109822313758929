import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import { insertSiteAdminAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { SiteAdminForm } from './SiteAdminForm';
import { Dialog } from 'components/Dialog';

export const CreateSiteAdmin = ({ publishDataChanged }: CreateComponentProps) => {
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (userId: number) => {
			const bindedAction = insertSiteAdminAction.bind(null, userId);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('New site admin is added.');
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	);

	return (
		<Dialog
			open
			title='Add new site admin'
			onClose={goBackFromCreate}
		>
			<SiteAdminForm onSave={handleSave} crud={CrudEnum.Create} />
		</Dialog>
	)
}
