import { useCallback, useState } from 'react';
import { Form, InputField } from 'components/Form';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { LoginRequestModel } from 'services/tenantManagementService';
import { loginAction } from '../action';
import { tryCatchJsonByAction, convertResponseErrors } from 'utils/fetchUtils';
import { getSubdomainFromUrl } from 'features/Tenant/tenantUtils';
import Link from 'components/Link';
import { HOME_ROUTE, ONBOARDING_ROUTE } from 'base/constants/routes';
import { useHistory } from 'react-router-dom';
import { SUB_ROUTES } from '../OnboardingRouter';
import { Title } from '../_components';
import { setIsUserProjectManager, setIsUserManagerAtAnyOrganizationalUnit, setUserInfo, setIsUserResourceManager } from 'utils/storageUtils';
import { isUserProjectManagerAction } from 'features/Project';
import { setIsTenantInitialized, setSubdomainToStorage, setTenantId } from 'features/Tenant/tenantUtils';
import { setToken } from 'features/Token/token';
import { RedirectToLoginState } from '../../PageRouter';
import { getAllManagerAuthorizationsAction } from 'containers/Configuration/SiteConfiguration/General/Company/ManagerAuthorizations/action';

class Model {
	usernameOrEmail!: string;
	password!: string;
}

type Props = {
	state?: RedirectToLoginState
}

const Login = ({ state = {} }: Props) => {
	const [values, setValues] = useState(new Model());

	const history = useHistory();

	const onSubmitCallback = useCallback(
		async () => {
			const isEmail = values.usernameOrEmail.split('@').length > 1;
			const newLoginRequestModel = new LoginRequestModel({
				username: !isEmail ? values.usernameOrEmail: undefined ,
				email: isEmail ? values.usernameOrEmail : undefined,
				password: values.password,
				domian: getSubdomainFromUrl(),
			});

			const bindedAction = loginAction.bind(null, newLoginRequestModel)
			const loginResponse = await tryCatchJsonByAction(bindedAction);

			if (loginResponse.success) {
				const user = loginResponse.user!;
				setToken(loginResponse.token!);
				setUserInfo(user);
				setIsTenantInitialized(loginResponse.isTenantInitialized!);
				setTenantId(user.tenantId || 0);
				setSubdomainToStorage();

				const isUserPMResponse = await tryCatchJsonByAction(isUserProjectManagerAction);
				if (isUserPMResponse.success) {
					setIsUserProjectManager(isUserPMResponse.value!);
				}

				const bindedGetOrganizationalUnitManagersAction = getAllManagerAuthorizationsAction.bind(null, user.id);
                const organizationalUnitManagersResponse = await tryCatchJsonByAction(bindedGetOrganizationalUnitManagersAction);
                if (organizationalUnitManagersResponse.success) {
                    setIsUserManagerAtAnyOrganizationalUnit((organizationalUnitManagersResponse.items?.length || 0) > 0);
                    setIsUserResourceManager((organizationalUnitManagersResponse.items?.filter(x => x.isResourceManager).length || 0) > 0);
                }

				history.push(loginResponse.isTenantInitialized ? state.from || HOME_ROUTE : `${ONBOARDING_ROUTE}/${SUB_ROUTES.FINISH_REGISTRATION}`);
			} else {
				return convertResponseErrors(loginResponse);
			}

		},
		[values, history, state.from]
	);

	return (
		<>
			<Title text='Login to Your Account' />
			<Form
				values={values}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				submitButtonText='Login'
				hideCancelButton
				disableUnsavedChangesGuard
				render={() => (
					<ColumnContainer margin='small'>
						<SmartContainer>
							<SmartItem>
								<InputField
									id='usernameOrEmail'
									label='Username/email'
									multiline
									isRequired
									focus
								/>
								<InputField
									type='password'
									id='password'
									label='Password'
									multiline
									isRequired
								/>
							</SmartItem>
						</SmartContainer>

						<RowContainer justifyContent='flex-end'>
							<Link url={`${ONBOARDING_ROUTE}/${SUB_ROUTES.FORGOT_PASSWORD}`} text='Forgot password?' id='whatever' />
						</RowContainer>
					</ColumnContainer>
				)}
			/>
		</>
	)
};

export default Login;
