import path from 'path'
import { useCallback } from 'react'
import { clearStorage } from 'utils/storageUtils'
import styles from './userPanel.module.scss'
import { Link, useHistory } from 'react-router-dom';
import { ONBOARDING_ROUTE } from 'base/constants/routes';
import { RootRouteEnum } from 'features/Routes';
import { Cover } from 'components/Cover';
import { AccountSubRoutesEnum } from 'containers/Configuration/MyConfiguration/routes';

type Props = {
	visible?: boolean
	toggleVisible(): void
}

export const UserPanel = ({ visible, toggleVisible }: Props) => {
	const history = useHistory();

	const logoutCallback = useCallback(
		() => {
			clearStorage();
			history.push(ONBOARDING_ROUTE);
		},
		[history]
	)

	return (
		<>
			{visible && <Cover onClick={toggleVisible} transparent />}
			<div className={`${styles.container} ${visible ? styles.visible : ''}`}>
				<Link
					children='My Profile'
					className={styles.item}
					onClick={toggleVisible}
					to={path.join('/', RootRouteEnum.ACCOUNT, AccountSubRoutesEnum.PROFILE)}
				/>
				<div className={styles.separator}></div>
				<Link
					children='Rules & Alerts'
					className={styles.item}
					onClick={toggleVisible}
					to={path.join('/', RootRouteEnum.ACCOUNT, AccountSubRoutesEnum.RULES_AND_ALERTS)}
				/>
				<div className={styles.separator}></div>
				<Link
					children='Default Project'
					className={styles.item}
					onClick={toggleVisible}
					to={path.join('/', RootRouteEnum.ACCOUNT, AccountSubRoutesEnum.DEFAULT_PROJECT)}
				/>
				<div className={styles.separator}></div>
				<div className={styles.item} onClick={logoutCallback}>Sign Out</div>
			</div>
		</>
	)
}
