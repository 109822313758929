import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectReportResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { ColumnContainer } from 'components/Layout';
import { getReportAction } from '../action';
import { ProjectIdentification } from '../ReportComponents/ProjectIdentification/ProjectIdentification';
import { ChangeRequest } from '../ReportComponents/ChangeRequest/ChangeRequest';
import { UpdateReportRouter } from './UpdateReportRouter';
import { PerformanceStatus } from '../ReportComponents/PerformanceStatus/PerformanceStatus';
import { MajorIssue as MajorIssues } from '../ReportComponents/MajorIssues/MajorIssues';
import { SubcontractorPerformance } from '../ReportComponents/SubcontractorPerformance/SubcontractorPerformance';
import { RiskManagement } from '../ReportComponents/RiskManagement/RiskManagement';
import { QualityManagement } from '../ReportComponents/QualityManagement/QualityManagement';
import { ProjectFinances } from '../ReportComponents/ProjectFinances/ProjectFinances';
import { PotentialAdditionalBusiness } from '../ReportComponents/PotentialAdditionalBusiness/PotentialAdditionalBusiness';
import { CommentsAndActions } from '../ReportComponents/CommentsAndActions/CommentsAndActions';
import WithFetch from 'features/Fetch/WithFetch';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { RelationshipStatus } from '../ReportComponents/RelationshipStatus/RelationshipStatus';
import { Staffing } from '../ReportComponents/Staffing/Staffing';
import { ProjectStatus } from '../ReportComponents/ProjectStatus/ProjectStatus';
import { reportAreasChangedTopic } from '../reportAreasChanged';
import { AreaStatuses } from '../ReportComponents/ProjectStatus/areaStatusEnum';
import { CrudEnum, UpdateComponentProps } from 'features/Crud';
import { ProjectPickerParams } from 'features/Project';
import { ProjectReportHelp } from '../Help/ProjectReportHelp';
import { ContentShell } from 'features/Content/ContentShell';
import { ExportProjectReport } from './ExportProjectReport';

type ParamType = ProjectPickerParams & {
	reportId: string;
}

export const UpdateReport = ({ publishDataChanged }: UpdateComponentProps) => {
	const [report, setReport] = useState(new ProjectReportResponse());
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId as string);
	const reportId = parseInt(params.reportId);

	const [refetching, setRefetching] = useState(false);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getReportAction.bind(null, projectId, reportId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setReport(response.value || new ProjectReportResponse());
			}
		},
		[projectId, reportId]
	)

	const refetchDataCallback = useCallback(
		async () => {
			setRefetching(true);
			await fetchDataCallback();
			setRefetching(false);
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			const subscription = reportAreasChangedTopic.subscribe(refetchDataCallback);
			return () => {
				subscription.unsubscribe();
			}
		},
		[refetchDataCallback]
	)

	return (
		<ContentShell
			title={`Update Project Report - ${getFormatedId(EntityPrefixEnum.REPORT, report.id)}`}
			FloatingHelpComponent={ProjectReportHelp}
		>
			<WithFetch fetchFunction={fetchDataCallback} refetching={refetching}>
				<ColumnContainer>
					<ExportProjectReport projectId={projectId} id={reportId} />
					<ColumnContainer margin='xlarge'>
						<ProjectIdentification report={report} setReport={setReport} reportCrud={CrudEnum.Update} publishDataChanged={publishDataChanged} />
						<ProjectStatus report={report} setReport={setReport} reportCrud={CrudEnum.Update} />
						{report.areaStatuses[AreaStatuses.RelationshipStatus]?.isActive &&
							<RelationshipStatus report={report} setReport={setReport} reportCrud={CrudEnum.Update} />
						}
						{report.areaStatuses[AreaStatuses.Staffing]?.isActive &&
							<Staffing report={report} setReport={setReport} reportCrud={CrudEnum.Update} />
						}
						{report.areaStatuses[AreaStatuses.PerformanceStatus]?.isActive &&
							<PerformanceStatus report={report} reportCrud={CrudEnum.Update} />
						}
						{report.areaStatuses[AreaStatuses.MajorIssues]?.isActive &&
							<MajorIssues report={report} reportCrud={CrudEnum.Update} />
						}
						{report.areaStatuses[AreaStatuses.ChangeRequest]?.isActive &&
							<ChangeRequest report={report} reportCrud={CrudEnum.Update} />
						}
						{report.areaStatuses[AreaStatuses.SubcontractorPerformance]?.isActive &&
							<SubcontractorPerformance report={report} reportCrud={CrudEnum.Update} />
						}
						{report.areaStatuses[AreaStatuses.RiskManagement]?.isActive &&
							<RiskManagement report={report} reportCrud={CrudEnum.Update} />
						}
						{report.areaStatuses[AreaStatuses.QualityManagement]?.isActive &&
							<QualityManagement report={report} reportCrud={CrudEnum.Update} />
						}
						{report.areaStatuses[AreaStatuses.ProjectFinances]?.isActive &&
							<ProjectFinances report={report} reportCrud={CrudEnum.Update} />
						}
						{report.areaStatuses[AreaStatuses.PotentialAdditionalBusiness]?.isActive &&
							<PotentialAdditionalBusiness report={report} reportCrud={CrudEnum.Update} />
						}
						{report.areaStatuses[AreaStatuses.CommentsAndActions]?.isActive &&
							<CommentsAndActions report={report} reportCrud={CrudEnum.Update} />
						}
					</ColumnContainer>
				</ColumnContainer>
				<UpdateReportRouter />
			</WithFetch>
		</ContentShell>
	)
}
