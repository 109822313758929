import styles from './layout.module.scss';
import { useMemo } from 'react';

type Props = {
	className?: string
	children: any
	// those are also class names
	orientation: 'vertical' | 'horizontal'
	flex?: string
}

export const Layout = ({ className, children, orientation }: Props) => {
	const content = useMemo(
		() => (
			<div className={orientation === 'horizontal' ? 'row' : styles.vertical}>
				{children}
			</div>
		),
		[orientation, children]
	)

	if (className) {
		return (
			<div className={className}>
				{content}
			</div>
		)
	} else {
		return (
			<>{content}</>
		)
	}
}
