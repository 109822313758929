import { useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import path from 'path';
import Button from 'components/Button';
import { RowContainer } from 'components/Layout';
import { SimpleDialog } from 'components/Dialog';
import { CrudSubRoutesEnum } from './CrudEnum';
import { noop } from 'utils/commonHelper';

type Props = {
	selectedId?: string | number
	onDelete?(id: string | number): Promise<void>
	additionalId?: string | number

	// additional state that is passed to CRU routes using history state
	// INFO: state is not good to use, because page reload (or URL copy/paste) will not work
	// if you must use it, please make sure that component works after page reload
	historyState?: any
	prefix?: string

	isCreateable?: boolean
	isViewable?: boolean
	isChangeable?: boolean
	isDeleteable?: boolean

	disabled?: boolean

	labels?: {
		create?: string
		view?: string
		change?: string
		delete?: string
	}

	specificActionDisabled?: {
		create?: boolean
		view?: boolean
		change?: boolean
		delete?: boolean
	}
};

// When CRU button is clicked it will point to new route: /create, /read/:id, /update/:id
// And when D button is clicked, it will call prop.onDelete

const CrudButtons = ({
	selectedId,
	onDelete,
	additionalId,
	historyState,
	prefix = '',
	isCreateable = true,
	isViewable = true,
	isChangeable = true,
	isDeleteable = true,
	disabled,
	labels,
	specificActionDisabled,
}: Props) => {
	const location = useLocation();

	const [deleting, setDeleting] = useState(false);

	const idParamsMemo = useMemo(
		() => {
			let result = selectedId;
			if (additionalId) {
				result += `/${additionalId}`;
			}
			return result;
		},
		[selectedId, additionalId]
	)


	const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

	const openConfirmDeleteModal = useCallback(
		() => setIsConfirmDeleteModalOpen(true),
		[]
	);

	const closeConfirmDeleteModal = useCallback(
		() => setIsConfirmDeleteModalOpen(false),
		[]
	)

	const onDeleteConfirmed = useCallback(
		async () => {
			setDeleting(true);
			closeConfirmDeleteModal();
			if (selectedId) {
				onDelete && await onDelete(selectedId);
			}

			setDeleting(false);
		},
		[closeConfirmDeleteModal, selectedId, onDelete]
	);

	return (
		<>
			<SimpleDialog
				open={isConfirmDeleteModalOpen}
				title='Delete'
				message='Are you sure that you want to delete selected element?'
				onConfirm={onDeleteConfirmed}
				onCancel={closeConfirmDeleteModal}
			/>
			<RowContainer>
				{isCreateable &&
					<Link to={{
						pathname: path.join(location.pathname, prefix, `${CrudSubRoutesEnum.Create}`),
						state: historyState
					}}>
						<Button
							text={labels?.create || 'Create'}
							disabled={disabled || specificActionDisabled?.create}
							onClick={noop}
						/>
					</Link>
				}
				{isViewable &&
					<Link to={{
						pathname: path.join(location.pathname, prefix, `${CrudSubRoutesEnum.Read}/${idParamsMemo}`),
						state: historyState
					}}>
						<Button
							text={labels?.view || 'View'}
							disabled={!selectedId || disabled || specificActionDisabled?.view}
							onClick={noop}
						/>
					</Link>
				}
				{isChangeable &&
					<Link to={{
						pathname: path.join(location.pathname, prefix, `${CrudSubRoutesEnum.Update}/${idParamsMemo}`),
						state: historyState
					}}>
						<Button
							text={labels?.change || 'Change'}
							disabled={!selectedId || disabled || specificActionDisabled?.change}
							onClick={noop}
						/>
					</Link>
				}
				{isDeleteable &&
					<Button
						text={labels?.delete || 'Delete'}
						color='destructive'
						disabled={!selectedId || disabled || specificActionDisabled?.delete}
						onClick={openConfirmDeleteModal}
						isLoading={deleting}
					/>
				}
			</RowContainer>
		</>
	);
};

export default CrudButtons;
