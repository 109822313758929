import Tabs from 'components/Tabs/Tabs';
import { ContentShell } from 'features/Content/ContentShell';
import ApprovalHelp from './Help/ApprovalHelp';
import { approvalTabs } from './Tabs/approvalTabs';

const Approval = () => {
	return (
		<ContentShell
			title='Approval'
			FloatingHelpComponent={ApprovalHelp}
		>
			<Tabs
				tabs={approvalTabs}
			/>
		</ContentShell>
	)
}

export default Approval;
