import { useCallback, useState } from 'react';
import { OverlaySpinner } from 'components/Spinner';
import { ProjectResponse, ScopeItemLevelResponse } from 'services/tenantManagementService';
import styles from 'components/Map/map.module.scss'
import { DeleteItem } from '../Crud/DeleteItem';
import { UpdateItem } from '../Crud/UpdateItem';
import { deleteScopeItemAction } from '../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';

type Props = {
	item: ScopeItemLevelResponse
	items: ScopeItemLevelResponse[]
	onClick(id: number): void
	isSelected: boolean
	deselect(id: number | undefined): void
	project: ProjectResponse
	fetchData(): Promise<void>
	reorder(elementIds: number[]): Promise<void>
	disabledEdit?: boolean
}

export const Item = ({ item, items, onClick, isSelected, deselect, project, fetchData, reorder, disabledEdit }: Props) => {
	const [deleting, setDeleting] = useState(false);

	const onClickCallback = useCallback(
		() => onClick(item.id),
		[item, onClick]
	)

	const onDeleteCallback = useCallback(
		async () => {
			setDeleting(true);

			const bindedAction = deleteScopeItemAction.bind(null, project.id, item.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				// deselect only if deleted item is selected
				if (isSelected) {
					deselect(undefined);
				}

				notifications.success('Scope item is successfully deleted');
				const newElementIds: number[] = [];
				for (const currentItem of items) {
					if (item.id !== currentItem.id) {
						newElementIds.push(currentItem.id);
					}
				}
				reorder(newElementIds);
				// fetchData(); reorder is calling fetch, so no need to call it twice
			}

			setDeleting(false);
		},
		[item.id, project.id, deselect, isSelected, items, reorder]
	)

	const className = `${styles.item} ${isSelected ? styles.selected : ''}`;
	const itemContent = `${item.name} - ${item.description}`

	return (
		<div className={className} onClick={onClickCallback}>
			{item.levelNumber !== 1 && <div className={styles.line_left} />}
			<div className={styles.item_content} title={itemContent}>
				{itemContent}
			</div>
			{!disabledEdit &&
				<>
					{/* Modal dialog for edit/update */}
					<UpdateItem
						items={items}
						item={item}
						project={project}
						fetchData={fetchData}
					/>
					{/* Modal dialog for confirm delete */}
					<DeleteItem
						onDelete={onDeleteCallback}
					/>
					{deleting && <OverlaySpinner size={24} />}
				</>
			}
			{(item.levelNumber < 4 && isSelected) && <div className={styles.line_right} />}
		</div>
	)
}
