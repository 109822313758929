import { ReordableList } from 'components/DragNDrop/ReordableList/ReordableList';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OrganizationalUnitResponse, UpdateOrderRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getAllOrganizationalUnitsAction, reorderOrganizationalUnitsAction } from '../action';
import styles from 'components/Map/map.module.scss'
import { Item } from './Item';
import { CreateItem } from '../Crud/CreateItem';
import { OverlaySpinner } from 'components/Spinner';
import { LevelTitle } from './LevelTitle';

type Props = {
	level: number
	parentId?: number
	parentIndex: number // index of selected element, used for lines to be drawn between levels
}

export const OrganizationalUnitItems = ({ parentId, level, parentIndex }: Props) => {
	const [items, setItems] = useState<OrganizationalUnitResponse[]>([]);
	const [selectedId, setSelectedId] = useState<number>();
	const [fetching, setFetching] = useState(false);
	const [reordering, setReordering] = useState(false);

	const fetchDataCallback = useCallback(
		async () => {
			setFetching(true);
			
			const bindedAction = getAllOrganizationalUnitsAction.bind(null, parentId, level);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setItems(response.items || []);
			}

			setFetching(false);
		},
		[parentId, level]
	);

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			setSelectedId(undefined);
		},
		[parentId]
	)

	const reorderCallback = useCallback(
		async (newElementIds: number[]) => {
			setReordering(true);

			const modelOrders: UpdateOrderRequest[] = [];
			for (let i = 0; i < newElementIds.length; i++) {
				modelOrders.push(new UpdateOrderRequest({
					id: newElementIds[i],
					order: i + 1
				}))
			}

			const bindedAction = reorderOrganizationalUnitsAction.bind(null, modelOrders);
			const response = await tryCatchJsonByAction(bindedAction);

			setReordering(false);
			if (response.success) {
				await fetchDataCallback();
			}
		},
		[fetchDataCallback]
	)

	const renderItemCallback = useCallback(
		(id: number) => {
			const item = items.find((item) => item.id === id);

			// sometimes ReordableList has some old elementIds, and item can be undefined
			if (!item) {
				return;
			}

			return (
				<Item
					key={item.id.toString()}
					items={items}
					item={item}
					onClick={setSelectedId}
					isSelected={selectedId === item.id}
					deselect={setSelectedId}
					fetchData={fetchDataCallback}
					reorder={reorderCallback}
				/>
			)
		},
		[selectedId, items, fetchDataCallback, reorderCallback]
	)

	const elementIds = useMemo(
		() => items.map((item) => item.id),
		[items]
	)

	const prevHeight = parentIndex * (32 + 8); // 32 item height, 8 margin-bottom
	const nextHeight = (items.length - 1) * (32 + 8);
	const lineHeight = Math.max(1, prevHeight, nextHeight);

	return (
		<>
			{1 < level && <div className={styles.title_line_vertical} style={{ height: `${lineHeight}px` }} />}
			<div className={`${styles.container}`}>
				<LevelTitle level={level} />
				{items.length > 0 &&
					<ReordableList
						initialElementIds={elementIds}
						onReorder={reorderCallback}
						renderItem={renderItemCallback}
						disabled={false}
					/>
				}
				{1 < level &&
					<CreateItem
						items={items}
						level={level}
						parentId={parentId}
						fetchData={fetchDataCallback}
					/>
				}
				{(fetching || reordering) && <OverlaySpinner size={60} withBackground />}
			</div>
			{selectedId &&
				<OrganizationalUnitItems
					parentId={selectedId}
					parentIndex={elementIds.indexOf(selectedId)}
					level={level + 1}
				/>
			}
		</>
	)
}

