import { getReportStatusesAction, updateReportStatusesAction } from './action';
import { ReportStatusesHelp } from './ReportStatusesHelp';
import { StatusResponseTable } from 'features/StatusResponse/StatusResponseTable';
import { ContentShell } from 'features/Content/ContentShell';

export const Reports = () => {
	return (
		<ContentShell
			InlineHelpComponent={ReportStatusesHelp}
			showBreadcrumb={false}
		>
			<StatusResponseTable
				getConfigsAction={getReportStatusesAction}
				updateConfigsAction={updateReportStatusesAction}
			/>
		</ContentShell>
	)
};
