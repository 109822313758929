import { useMemo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { AttachmentResponse, TestPlanResponse } from 'services/tenantManagementService'

type Props = {
	testPlan: TestPlanResponse
}

const MyTestAttachments = ({ testPlan }: Props) => {

	const attachmentsMemo = useMemo(
		() => {
			let result: AttachmentResponse[] = [];
			if (testPlan.scopeAttachments) {
				result = [...testPlan.scopeAttachments];
			}

			if (testPlan.testPlanAttachments) {
				result = [...result, ...testPlan.testPlanAttachments];
			}
			return result;

		},
		[testPlan.scopeAttachments, testPlan.testPlanAttachments]
	)

	if (attachmentsMemo.length === 0) {
		return null;
	}

	return (
		<View style={styles.container}>
			<Text style={styles.title}>
				Attachments
			</Text>
			{
				attachmentsMemo.map(attachment => (
					<Text key={attachment.id} style={styles.link}>
						{attachment.name}
					</Text>
					// <Link
					// 	key={attachment.id}
					// 	src={attachment.uri || '#'}
					// 	style={styles.link}
					// >
					// 	{attachment.name}
					// </Link>
				))
			}
		</View>
	);
}

export default MyTestAttachments;

const styles = StyleSheet.create({
	container: {
		paddingTop: 10,
	},
	title: {
		fontSize: 12,
		marginBottom: 2,
	},
	link: {
		fontSize: 10,
	},
});
