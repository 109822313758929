import { HelpShell } from 'features/Help/HelpShell';

export const TestCasesStatusesHelp = () => {
	return (
		<HelpShell title='Test case statuses'>
			<div>
				<p>Mavles Project Management Tool comes with 4 predefined statuses:</p>
				<ul>
					<li>Not Started</li>
					<li>In Progress</li>
					<li>Passed</li>
					<li>Failed</li>
				</ul>
			</div>
			<p>These statuses cannot be changed or deleted, but you can add explanation for each status.</p>
			<p>If needed, additional test statuses can be created by site administrator.<br />
				Statuses are not project related and they will be valid for your site and all your projects.<br />
				In order to view status in any project you need to mark 'active' button next to new status.<br />
				Statuses cannot be deleted if there is any data containing this status. If you decide not to 
				use specific status you need to deactivate it.
			</p>
		</HelpShell>
	)
}
