import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StatusReportResponse, UpdateStatusReportRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import { getStatusReportAction, updateStatusReportAction } from '../action';
import { StatusReportForm } from './StatusReportForm';
import { ProjectOrCategoryPickerParams } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = ProjectOrCategoryPickerParams & {
	statusReportId: string
}

export const UpdateStatusReport = ({ publishDataChanged }: UpdateComponentProps) => {
	const params: ParamType = useParams();
	const projectOrCategoryId = parseInt(params.projectOrCategoryId as string);
	const isProjectConnected = params.isProjectConnected === 'true';
	const statusReportId = parseInt(params.statusReportId as string);
	const goBackFromUpdate = useGoBackFromUpdate();

	const [statusReport, setStatusReport] = useState(new StatusReportResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getStatusReportAction.bind(null, statusReportId, projectOrCategoryId, isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setStatusReport(response.value || new StatusReportResponse());
			}
		},
		[projectOrCategoryId, isProjectConnected, statusReportId]
	)

	const saveCallback = useCallback(
		async (newStatusReport: StatusReportResponse, isRelease: boolean, isAlign: boolean) => {
			const model = new UpdateStatusReportRequest(newStatusReport);

			const bindedAction = updateStatusReportAction.bind(null, isRelease, isAlign, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.STATUS_REPORT, response.value?.id);
				notifications.success(`Status report ${id} is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`Change status report - ${getFormatedId(EntityPrefixEnum.STATUS_REPORT, statusReport.id)}`}>
				<StatusReportForm
					statusReport={statusReport}
					crud={CrudEnum.Update}
					onSave={saveCallback}
					cancel={goBackFromUpdate}
				/>
			</ContentShell>
		</WithFetch>
	)
}
