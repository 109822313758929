import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import { BusinessPartnerResponseModel } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { PartnerForm as PartnerFormContainer } from './PartnerForm';
import { getBusinessPartnerAction, updateBusinessPartnerAction } from '../action';
import { getFormatedId, EntityPrefixEnum } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = {
	partnerId: string
}

export const UpdatePartner = ({ publishDataChanged }: UpdateComponentProps) => {
	const [partner, setPartner] = useState(new BusinessPartnerResponseModel());
	const params: ParamType = useParams();
	const partnerId = parseInt(params.partnerId);
	const goBackFromUpdate = useGoBackFromUpdate();

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getBusinessPartnerAction.bind(null, partnerId);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setPartner(response.value || new BusinessPartnerResponseModel());
			}
		},
		[partnerId]
	)

	const saveCallback = useCallback(
		async (newPartner: BusinessPartnerResponseModel) => {
			const bindedAction = updateBusinessPartnerAction.bind(null, newPartner);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.BUSINESS_PARTNER, response.value?.id);
				notifications.success(`Business partner ${id} is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged]
	)

	return (
		<ContentShell title={`Change Partner - ${getFormatedId(EntityPrefixEnum.BUSINESS_PARTNER, partnerId)}`}>
			<WithFetch fetchFunction={fetchDataCallback}>
				<PartnerFormContainer
					partner={partner}
					onSave={saveCallback}
					crud={CrudEnum.Update}
				/>
			</WithFetch>
		</ContentShell>
	)
}
