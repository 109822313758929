import { useCallback, useEffect, useMemo, useState } from 'react';
import Spinner, { ClipSpinner } from 'components/Spinner';
import styles from './withFetch.module.scss'

type Props = {
	children: any
	fetchFunction(): Promise<any>
	// use when fetch is triggered again, and this boolean must be controlled by parent component
	refetching?: boolean
}

const WithFetch = ({ children, fetchFunction, refetching }: Props) => {
	const [fetching, setFetching] = useState(true);

	const fetchData = useCallback(
		async () => {
			setFetching(true);
			await fetchFunction();
			setFetching(false);
		},
		[fetchFunction]
	)

	useEffect(
		() => {
			fetchData();
		},
		[fetchData]
	)

	const content = useMemo(
		() => {
			if (fetching) {
				return (
					<Spinner>
						<ClipSpinner size={70} />
					</Spinner>
				)
			} else {
				return <>{children}</>
			}
		},
		[fetching, children]
	)

    return (
		<div style={{ position: 'relative' }}>
			{content}
			{(!fetching && refetching) &&
				<div className={styles.container}>
					<Spinner>
						<ClipSpinner size={70} />
					</Spinner>
				</div>
			}
		</div>
	)
}

export default WithFetch;
