import { useCallback, useState } from 'react';
import { ProjectsTable, archiveProjectAction, restoreProjectAction, publishProjectsChanged } from 'features/Project';
import { TableButtons } from './TableButtons';
import { ProjectResponse, } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { BaseColumnModel } from 'components/Table';

const configureViewKey = 'archive_projects_table';

export const ArchiveProject = () => {
	const [selectedProject, setSelectedProject] = useState(new ProjectResponse());
	const [restoring, setRestoring] = useState(false);
	const [archiving, setArchiving] = useState(false);

	const selectedProjectChangeCallback = useCallback(
		(data: ProjectResponse[]) => {
			const newSelectedProject = data.length > 0 ? data[0] : new ProjectResponse();
			setSelectedProject(newSelectedProject)
		},
		[]
	);

	const archiveCallback = useCallback(
		async () => {
			setArchiving(true);
			const bindedAction = archiveProjectAction.bind(null, selectedProject.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success){
				const id = getFormatedId(EntityPrefixEnum.PROJECT, selectedProject.id);
				notifications.success(`Project ${id} is archived.`);
				publishProjectsChanged();
			}
			setArchiving(false);
		},
		[selectedProject]
	);

	const restoreCallback = useCallback(
		async () => {
			setRestoring(true);
			const bindedAction = restoreProjectAction.bind(null, selectedProject.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success){
				const id = getFormatedId(EntityPrefixEnum.PROJECT, selectedProject.id);
				notifications.success(`Project ${id} is restored.`);
				publishProjectsChanged();
			}
			setRestoring(false);
		},
		[selectedProject]
	);

	const renderTableButtonsCallback = useCallback(
		(tableColumns: BaseColumnModel[]) => (
			<TableButtons
				selectedProject={selectedProject}
				archiving={archiving}
				onArchiveClick={archiveCallback}
				restoring={restoring}
				onRestoreClick={restoreCallback}
				tableColumns={tableColumns}
				configureViewKey={configureViewKey}
			/>
		),
		[selectedProject, archiveCallback, restoreCallback, archiving, restoring]
	)

	return (
		<ProjectsTable
			renderTableButtons={renderTableButtonsCallback}
			onSelectedProjectChange={selectedProjectChangeCallback}
			configureViewKey={configureViewKey}
		/>
	)
}
