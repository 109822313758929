import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserModel, TestPlanResponse, TestStatusResponse, InnerTestPlanResponse, UpdateTestPlanRequest, TicketResponseGetAll } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { Form, InputField, DateField, TextareaField, MultiSelectField, MultiSelectLinksField, Input, AttachmentField, Attachment, AutoCompleteField } from 'components/Form';
import { SmartContainer, SmartFormGroup, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ColumnContainer, VerticalSeparator } from 'components/Layout';
import { getSimpleChangeRequestsAction } from 'containers/Scope/ChangeRequests/action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { getAllTicketsAction } from 'containers/Tickets/ViewTickets/action';
import { getUserInfo } from 'utils/storageUtils';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { formatTime } from 'utils/dateTimeUtils';
import { MyTestHistoryLog } from './HistoryLog/MyTestHistoryLog';
import { downloadAttachmentAction as downloadScopeAttachmentAction } from 'containers/Scope/MaintainScope/action';
import { MyTestTable } from './MyTestTable';
import { propertyOf } from 'utils/propertyOf';
import { getReadTicketRoute } from 'containers/Tickets/utils';
import { downloadAttachmentAction, removeAttachmentAction } from './action';
import notifications from 'components/Notification/notification';
import { useStatusCallback } from 'features/TableColumns/persistedHooks';

type Props = {
	projectId: number
	testPlan: TestPlanResponse
	crud: CrudEnum
	onSave?: (testPlan: TestPlanResponse) => void
	isSubmitting?: boolean
}

export const MyTestForm = ({ testPlan, onSave, crud, projectId, isSubmitting }: Props) => {
	const [values, setValues] = useState(testPlan);
	const [changeRequestIds, setChangeRequestIds] = useState<number[]>([]);
	const [tickets, setTickets] = useState<TicketResponseGetAll[]>([]);
	const goBackFromCrud = useGoBackFromCrud(crud);

	const {
		persistedTestCaseStatus,
		persistedUser,
		persistedTicketStatus
	} = useSelector((state: RootState) => state);

	const getTicketStatusName = useStatusCallback(persistedTicketStatus);

	const fetchDataCallback = useCallback(
		async () => {
			const [changeRequestsResponse, ticketsResponse] = await Promise.all(
				[
					tryCatchJsonByAction(getSimpleChangeRequestsAction.bind(null, projectId)),
					tryCatchJsonByAction(getAllTicketsAction),
				]
			)

			if (changeRequestsResponse.success) {
				setChangeRequestIds(changeRequestsResponse.items?.map(cr => cr.id) || []);
			}

			if (ticketsResponse.success) {
				setTickets(ticketsResponse.items || []);
			}
		},
		[projectId]
	)
	useEffect(
		() => {
			setValues(testPlan)
		},
		[testPlan]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const newTestPlan = new TestPlanResponse(values);
			return onSave && await onSave(newTestPlan);
		},
		[onSave, values]
	)

	const tableChangeCallback = useCallback(
		(innerTestPlans: InnerTestPlanResponse[]) => {
			setValues(
				(state: TestPlanResponse) => new TestPlanResponse({
					...state,
					innerTestPlans,
				})
			)
		},
		[]
	)

	const userInfoMemo = useMemo(
		() => {
			return getUserInfo();
		},
		[]
	)

	const downloadScopeAttachmentMemo = useMemo(
		() => downloadScopeAttachmentAction.bind(null, projectId),
		[projectId]
	)

	const downloadTestPlanAttachmentMemo = useMemo(
		() => downloadAttachmentAction.bind(null, projectId, testPlan.testCycleId, testPlan.id),
		[projectId, testPlan]
	)

	const removeTestPlanAttachmentCallback = useCallback(
		async (id: number) => {
			const bindedAction = removeAttachmentAction.bind(null, projectId, testPlan.testCycleId, testPlan.id, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Attachment is successfully deleted');
				setValues((state: TestPlanResponse) =>
					new TestPlanResponse({
						...state,
						testPlanAttachments: state.testPlanAttachments?.filter(att => att.id !== id)
					})
				)
			}
		},
		[projectId, testPlan]
	)

	return (
		<Form
			values={values}
			initialValues={testPlan}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			disableUnsavedChangesGuard
			hideButtons={crud === CrudEnum.Read}
			render={() => (
				<ColumnContainer margin='xlarge'>
					<SmartContainer>
						<SmartItem>
							<DateField
								id={propertyOf<TestPlanResponse>('plannedDate')}
								label='Planned date'
								disabled
							/>
							<SmartFormGroup label='Planned time'>
								<Input
									value={`${formatTime(values.plannedTime)} - ${formatTime(values.plannedTimeTo)}`}
									disabled
								/>
							</SmartFormGroup>
							<SmartFormGroup label='Duration'>
								<Input
									value={`${(values.duration || 0) / 10000 / 1000 / 60} min`}
									disabled
								/>
							</SmartFormGroup>
							<TextareaField
								id={propertyOf<TestPlanResponse>('processDescription')}
								label='Process description'
								rows={4}
								disabled
							/>
							<SmartFormGroup label='Level 1'>
								<SmartInline>
									<InputField
										id={propertyOf<TestPlanResponse>('level1Id')}
										inline
										disabled
									/>
									<InputField
										id={propertyOf<TestPlanResponse>('level1Name')}
										inline
										disabled
									/>
								</SmartInline>
							</SmartFormGroup>
							<SmartFormGroup label='Level 2'>
								<SmartInline>
									<InputField
										id={propertyOf<TestPlanResponse>('level2Id')}
										inline
										disabled
									/>
									<InputField
										id={propertyOf<TestPlanResponse>('level2Name')}
										inline
										disabled
									/>
								</SmartInline>
							</SmartFormGroup>
							<SmartFormGroup label='Level 3'>
								<SmartInline>
									<InputField
										id={propertyOf<TestPlanResponse>('level3ProcessId')}
										inline
										disabled
									/>
									<InputField
										id={propertyOf<TestPlanResponse>('level3ProcessName')}
										inline
										disabled
									/>
								</SmartInline>
							</SmartFormGroup>
							<AttachmentField
								id={propertyOf<UpdateTestPlanRequest>('newAttachments')}
								label='Attachments'
								multiple
								oldAttachments={values.testPlanAttachments}
								removeOldAttachment={removeTestPlanAttachmentCallback}
								downloadOldAttachment={downloadTestPlanAttachmentMemo}
								disabled={crud === CrudEnum.Read}
							/>
							<SmartFormGroup label='Scope attachments'>
								<Attachment
									oldAttachments={testPlan.scopeAttachments}
									downloadOldAttachment={downloadScopeAttachmentMemo}
									disabled
								/>
							</SmartFormGroup>
						</SmartItem>
						<SmartItem>
							<AutoCompleteField
								id={propertyOf<TestPlanResponse>('processTesterUserId')}
								label='Process tester'
								items={persistedUser.items}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								loading={persistedUser.fetching}
								disabled
							/>
							<AutoCompleteField
								id={propertyOf<TestPlanResponse>('processStatusId')}
								label='Status'
								items={persistedTestCaseStatus.items}
								getItemId={(item: TestStatusResponse) => item.id}
								getItemText={(item: TestStatusResponse) => item.name}
								getItemDescription={(item: TestStatusResponse) => item.description}
								loading={persistedTestCaseStatus.fetching}
								disabled={values.processTesterUserId !== userInfoMemo.id || crud === CrudEnum.Read}

							/>
							<TextareaField
								id={propertyOf<TestPlanResponse>('comments')}
								label='Comments'
								rows={5}
								maxLength={2000}
								disabled={values.processTesterUserId !== userInfoMemo.id || crud === CrudEnum.Read}
							/>
							<MultiSelectField
								id={propertyOf<TestPlanResponse>('changeRequestIds')}
								label='Change requests'
								items={changeRequestIds}
								getItemId={(item: number) => item}
								getItemText={(item: number) => getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, item)}
								disabled
							/>
							<VerticalSeparator />
							<MyTestHistoryLog
								projectId={projectId}
								testCycleId={testPlan.testCycleId}
								testPlanId={testPlan.id}
							/>
							<VerticalSeparator />
							<AutoCompleteField
								id={propertyOf<TestPlanResponse>('lastChangedByUserId')}
								label='Last Changed By'
								items={persistedUser.items}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								loading={persistedUser.fetching}
								disabled
							/>
							<DateField
								id={propertyOf<TestPlanResponse>('lastChanged')}
								label='Last Change'
								disabled
							/>
							<MultiSelectLinksField
								id={propertyOf<TestPlanResponse>('ticketIds')}
								label='Ticket ID'
								items={tickets}
								getItemId={(item: TicketResponseGetAll) => item.id}
								getItemText={(item: TicketResponseGetAll) => `${getFormatedId(EntityPrefixEnum.TICKET, item.id)} - ${getTicketStatusName(item.statusId)}`}
								getLinkProps={(itemId: string | number) => ({
									target: '_blank',
									to: getReadTicketRoute(parseInt(`${itemId}`)),
								})}
								disabled={crud === CrudEnum.Read}
							/>
						</SmartItem>
					</SmartContainer>
					<MyTestTable
						innerTestPlans={values.innerTestPlans || []}
						onChange={tableChangeCallback}
						crud={crud}
					/>
				</ColumnContainer>
			)}
		/>
	)
}
