import { RootState } from 'base/reducer/reducer';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';
import { useVisibleColumns, FieldTypeEnum, TableColumnsType } from 'components/Table';
import { useApplyStatusColorCallback, useOrganizationalUnitCallback, useStatusCallback, useTenantIsActiveCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProjectResponse, ProjectStatusResponse, TenantIsActiveResponse, UserModel } from 'services/tenantManagementService';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';

const defaultVisibleColumns = [
	propertyOf<ProjectResponse>('id'),
	propertyOf<ProjectResponse>('name'),
	propertyOf<ProjectResponse>('pmId'),
	propertyOf<ProjectResponse>('statusId'),
	propertyOf<ProjectResponse>('typeId'),
	propertyOf<ProjectResponse>('categoryId'),
	propertyOf<ProjectResponse>('organizationalUnitId'),
]

export const useTableColumnsMemo = (configureViewKey?: string) => {
	const {
		persistedUser,
		persistedProjectStatus,
		persistedProjectCategory,
		persistedProjectType,
		persistedConfigureView,
		persistedOrganizationalUnit
	} = useSelector((state: RootState) => state);

	const visibleColumns = configureViewKey ? persistedConfigureView.value[configureViewKey] : undefined;

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedProjectStatus);
	const getCategory = useTenantIsActiveCallback(persistedProjectCategory);
	const getType = useTenantIsActiveCallback(persistedProjectType);
	const applyStatusColor = useApplyStatusColorCallback(persistedProjectStatus);
	const getOrganizationalUnit = useOrganizationalUnitCallback();

	const organizationalUnits = useMemo(
		() => convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined),
		[persistedOrganizationalUnit]
	)

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectResponse>('id')]: {
					title: 'Project ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.PROJECT
				},
				[propertyOf<ProjectResponse>('name')]: {
					title: 'Project name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectResponse>('pmId')]: {
					title: 'Project manager',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				[propertyOf<ProjectResponse>('statusId')]: {
					title: 'Project status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedProjectStatus.items,
					getItemId: (option: ProjectStatusResponse) => option.id,
					getItemText: (option: ProjectStatusResponse) => option.name
				},
				[propertyOf<ProjectResponse>('typeId')]: {
					title: 'Project type',
					formatter: (cell: any) => getType(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedProjectType.items,
					getItemId: (option: TenantIsActiveResponse) => option.id,
					getItemText: (option: TenantIsActiveResponse) => option.name
				},
				[propertyOf<ProjectResponse>('categoryId')]: {
					title: 'Project category',
					formatter: (cell: any) => getCategory(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedProjectCategory.items,
					getItemId: (option: TenantIsActiveResponse) => option.id,
					getItemText: (option: TenantIsActiveResponse) => option.name
				},
				[propertyOf<ProjectResponse>('description')]: {
					title: 'Project description',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectResponse>('organizationalUnitId')]: {
					title: 'Organizational Unit',
					formatter: (cell: any) => getOrganizationalUnit(cell.getValue(), organizationalUnits),
					fieldType: FieldTypeEnum.MapOption,
					options: organizationalUnits
				}
			}
		},
		[organizationalUnits, persistedProjectType, persistedProjectCategory, persistedProjectStatus, persistedUser, getStatus, getCategory, getType, applyStatusColor, getOrganizationalUnit, getUserFullName]
	)

	return useVisibleColumns(columns, visibleColumns || defaultVisibleColumns);
}
