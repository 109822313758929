import { ColumnDefinition, RowComponent, TabulatorFull as Tabulator} from 'tabulator-tables';
import { ReactTabulator } from 'react-tabulator'
import { ColumnContainer } from 'components/Layout';
import ErrorBoundary from 'features/ErrorBoundary';
import Pagination from './Pagination/Pagination';
import Filter from './Filter/Filter';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FilterModel } from './Filter/Filter';
import { noop } from 'utils/commonHelper';
import { customTitleFormatter } from './HeaderCell/customTitleFormatter';
import styles from './baseTable.module.scss';
import { GenericSortModel } from 'services/tenantManagementService';
import { defineCustomTableCells } from '../helpers/customTableCells';
import { BaseColumnModel, FieldTypeEnum } from './BaseColumnModel';
import 'tabulator-tables/dist/css/tabulator.min.css'
import 'tabulator-tables/dist/css/tabulator_bootstrap3.min.css';
import './tabulator-override.scss';
import WithRefetch from 'features/Fetch/WithRefetch';

defineCustomTableCells()

export const defaultPaginationSize = 20;

const defaultOptions = {
	layout: 'fitDataFill',
	movableColumns: true,
	selectable: 1,
	tooltipsHeader: true,
	tooltips: true
}

const columnDefaults: ColumnDefinition = {
	title: '', // quick fix, TS complains
}

type Props = {
	fetchFunction: () => Promise<any>
	isLoading: boolean
	columns: BaseColumnModel[]
	rowsData: any[]
	pagination?: {
		offset?: number // starting index
		limit?: number // max number of rows
		count: number // length of all items
		onChange: (offset: number) => void
	}

	filters: FilterModel[]
	setFilters: (filters: FilterModel[]) => void
	sort: GenericSortModel
	setSort: (dbFilterFieldPath: string) => void

	reorderColumns?: (newColumns: string[]) => void // when columns are reordered, to notify parent if he wants to store new order
	rowSelectionChanged?: (data: any[], selectedRows: RowComponent[]) => void

	options?: any
	validationFailed?: (cell: any) => void
	cellEditCancelled?: (cell: any) => void
	cellEdited?: (cell: any) => void
	rowDoubleClick?: (rowData: any) => void

	compact?: boolean
}

export const BaseTable = ({
	fetchFunction, isLoading,
	columns, rowsData, pagination, filters, setFilters, sort, setSort, reorderColumns, rowSelectionChanged,
	options, validationFailed, cellEditCancelled, cellEdited, rowDoubleClick,
	compact
}: Props) => {
	let tabulatorRef = useRef<Tabulator>();
	const [isInitialized, setIsInitialized] = useState(false);

	const [activeFilterColumn, setActiveFilterColumn] = useState<BaseColumnModel>();

	const setFiltersCallback = useCallback(
		(newFilters: FilterModel[]) => {
			setFilters(newFilters);
			setActiveFilterColumn(undefined);
		},
		[setFilters]
	)

	const cancelFilterCallback = useCallback(
		() => setActiveFilterColumn(undefined),
		[]
	)

	const onFilterClickCallback = useCallback(
		(field: string) => {
			const selectedColumn = columns.find((column) => column.field === field);
			setActiveFilterColumn(selectedColumn);
		},
		[columns]
	)

	// sort works by only one column
	const onSortClickCallback = useCallback(
		(field: string) => {
			const selectedColumn = columns.find((column) => column.field === field)!;
			setSort(selectedColumn.dbFilterFieldPath!);
		},
		[setSort, columns]
	)

	const rowDblClick = useCallback(
		(e: any, row: any) => {
			if (!rowDoubleClick || !row?._row?.data) {
				return
			}

			e.preventDefault()
			e.stopPropagation()

			rowDoubleClick(row._row.data)
		},
		[rowDoubleClick],
	)

	const columnsConverted = useMemo(
		() => {
			return columns.map(
				(column) => {
					// filter out fields that are not used by tabulator, because it shows thousands of warnings in console
					const {
						fieldType, dbFilterFieldPath, dbExportFieldPath, getItemText, getItemId, options, entityPrefix,
						format, displayNames, decoraterProperties, disableFilter, disableSort, notInModel,
						...tabulatorFields
					} = column;
					const convertedColumn: /*ColumnDefinition*/ any = {
						...tabulatorFields,
						titleFormatter: customTitleFormatter,
						titleFormatterParams: () => {
							return {
								column,
								sort,
								filters,
								onFilterClick: column.fieldType !== FieldTypeEnum.None ? onFilterClickCallback : noop,
								onSortClick: (column.disableSort || column.fieldType === FieldTypeEnum.None) ? noop : onSortClickCallback
							}
						},
						headerClick: noop,
						headerSort: false
					}

					return convertedColumn;
				}
			)
		},
		[columns, filters, sort, onFilterClickCallback, onSortClickCallback]
	)

	useEffect(
		() => {
			if (isInitialized) {
				tabulatorRef.current?.setColumns(columnsConverted);
			}
		},
		[isInitialized, columnsConverted]
	)

	useEffect(
		() => {
			if (isInitialized) {
				tabulatorRef.current?.setData(rowsData);
			}
		},
		[isInitialized, rowsData]
	)

	useEffect(
		() => {
			if (isInitialized) {
				tabulatorRef.current?.on('cellEdited', cellEdited);

				return () => {
					tabulatorRef.current?.off('cellEdited', cellEdited);
				}
			}
		},
		[isInitialized, cellEdited]
	)

	const handleColumnMove = useCallback(
		(column: any, tabulatorColumns: any[]) => {
			if (reorderColumns) {
				const newColumns: BaseColumnModel[] = tabulatorColumns.map((col) => col.getDefinition());
				const fields = newColumns.map((column) => column.field!);
				reorderColumns(fields);
			}
		},
		[reorderColumns]
	)

	const customOptions = useMemo(
		() => {
			return {
				...defaultOptions,
				...options
			}
		},
		[options]
	)

	// after render is finished, we go thru all cells and check if cell is editable at the moment and applies different CSS style
	const renderCompleteCallback = useCallback(
		() => {
			// setIsInitialized(true); // didn't find better event to check if tabulator is initialized

			if (!tabulatorRef.current) {
				return;
			}

			const rows = tabulatorRef.current.getRows();
			for (const row of rows) {
				for (const cell of row.getCells()) {
					const definition = cell.getColumn().getDefinition();
					const cellComponent = cell;

					if (definition.editor) {
						let isEditableByDefinition = true;
						if (typeof definition.editable === 'function') {
							isEditableByDefinition = definition.editable(cellComponent);
						} else if (typeof definition.editable === 'boolean') {
							isEditableByDefinition = definition.editable;
						}

						if (isEditableByDefinition) {
							const color = 'var(--field-color)';
							const background = 'var(--field-background)';

							const container = cellComponent.getElement();
							container.style.color = color;
							container.style.backgroundColor = background;
						}
					}
				}
			}
		},
		[]
	)

	return (
		<ErrorBoundary location='BaseTable'>
			<WithRefetch fetchFunction={fetchFunction} isLoading={isLoading}>
				<div className={styles.container}>
					<ColumnContainer margin='small'>
						<Filter
							filters={filters || []}
							column={activeFilterColumn}
							columns={columns}
							onSave={setFiltersCallback}
							onCancel={cancelFilterCallback}
						/>
						<ReactTabulator
							onRef={(ref) => (tabulatorRef.current = ref.current)}
							columnDefaults={columnDefaults}
							columns={columnsConverted}
							columnMoved={handleColumnMove}
							events={{
								tableBuilt: () => setIsInitialized(true),
								rowSelectionChanged: rowSelectionChanged || noop,
								renderComplete: renderCompleteCallback,
								// cellEdited: cellEdited || noop, // this way changes of hook dependencies are ignored, so moved to on/off. If needed, move other events to on/off also.
								validationFailed: validationFailed || noop,
								cellEditCancelled: cellEditCancelled || noop,
								rowDblClick: rowDblClick,
							}}
							options={customOptions}
						/>
						{pagination &&
							<Pagination
								offset={pagination.offset || 0}
								limit={pagination.limit || defaultPaginationSize}
								count={pagination.count}
								onChange={pagination.onChange}
								compact={compact}
							/>
						}
					</ColumnContainer>
				</div>
			</WithRefetch>
		</ErrorBoundary>
	)
}
