import { RowContainer } from 'components/Layout';
import Button from 'components/Button';
import ConfigureView from 'features/ConfigureView';
import { CrudButtons } from 'features/Crud';
import { useCallback, useState } from 'react';
import { BaseColumnModel } from 'components/Table';

type Props = {
	selectedId?: number
	changeDisabled: boolean
	viewDisabled: boolean
	deleteDisabled: boolean
	releaseDisabled: boolean
	isReleasable: boolean
	disabled: boolean
	onDelete(id: string): Promise<void>
	onRelease(): Promise<void>
	configureViewKey: string
	tableColumns: BaseColumnModel[]
}

const TableButtons = ({
	selectedId,
	changeDisabled,
	viewDisabled,
	deleteDisabled,
	releaseDisabled,
	isReleasable,
	disabled,
	onDelete,
	onRelease,
	configureViewKey,
	tableColumns,
}: Props) => {

	const [isReleasing, setIsReleasing] = useState(false);
	const releaseCallback = useCallback(
		async () => {
			setIsReleasing(true);
			await onRelease()
			setIsReleasing(false);
		},
		[onRelease]
	)
	return (
		<>
			<RowContainer justifyContent='space-between'>
				<RowContainer>
					<CrudButtons
						onDelete={onDelete}
						selectedId={selectedId}
						specificActionDisabled={{
							change: changeDisabled,
							view: viewDisabled,
							delete: deleteDisabled
						}}
						disabled={disabled}
					/>
					{isReleasable && (
						<Button
							onClick={releaseCallback}
							disabled={releaseDisabled || disabled}
							text='Release'
							isLoading={isReleasing}
						/>
					)}
				</RowContainer>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				</RowContainer>
			</RowContainer>
		</>
	)
}

export default TableButtons;
