import { HelpShell } from 'features/Help/HelpShell';

const ChangeRequestsHelp = () => {
	return (
		<HelpShell title='Change Requests'>
			<p>Change requests are extensions of project scope or time.</p>
			<p>Scope module provides Change Request management following procedure of Change Management Process.</p>
			<p>The Change Request Procedure identifies, controls and documents modifications in the project scope. The objective of Change Request Management is to reach a decision as quickly as possible on the requested change, to permit Change Requests to infiltrate the project in a controlled manner.</p>
			<div>
				<p>In scope module, we enabled 5 phases of change management process:</p>
				<ul>
					<li>CR problem report -{'>'}</li>
					<li>CR Analysis -{'>'}</li>
					<li>CR Approval -{'>'}</li>
					<li>CR Realization -{'>'}</li>
					<li>CR monitor and control -{'>'}</li>
				</ul>
			</div>
			<p>
				<b>In first phase</b>, any team member can create problem report and save it (status: created). After problem report is saved, it can still be modified. <br />
				When all needed information is filled in problem report, user can initiate change request process by sending problem report to user responsible for problem analysis (status: initiated). <br />
				Email is sent to responsible user. <br />
				After this step CR problem report cannot be modified.
			</p>
			<div>
				<p><b>In second phase</b>, responsible user can:</p>
				<ul>
					<li>analyze problem, estimate impacts on project and provide effort estimation and assumptions for realization. Analysis can be saved (status: initiated) or</li>
					<li>release (status: released for approval) for further approval. Email is sent to responsible user.</li>
					<li>reject change request (status: rejected) or</li>
					<li>asks for additional information from user who requested change.  In this case, status is reverted to: created and CR problem report can be modified again. Email is sent to responsible user.</li>
				</ul>
			</div>
			<div>
				<p><b>In third phase</b>, change request can be:</p>
				<ul>
					<li>approved (status: approved) or</li>
					<li>rejected for realization (status rejected for realization) or</li>
					<li>additional information is needed. Status is reverted to: initiated and analysis can be modified</li>
				</ul>
				<p>Email is sent to responsible user.</p>
			</div>
			<p>
				<b>In fourth phase</b>, work on change request is realized and it is released for final acceptance (status: released for acceptance). User can change initial effort estimation.
			</p>
			<p>
				<b>Final step</b> is acceptance of realized change and change closure (status: closed). <br />
				Responsible user is accepting realization of CR and CR is closed.
			</p>
		</HelpShell>
	)
}

export default ChangeRequestsHelp;
