import { Layout } from './Layout';

type Props = {
	children: any
	orientation: 'vertical' | 'horizontal'
}

export const Dashboard = ({ children, orientation }: Props) => {
	return (
		<Layout orientation={orientation}>
			{children}
		</Layout>
	)
}
