import notifications from "components/Notification/notification";
import { ContentShell } from "features/Content/ContentShell";
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from "features/Crud";
import { ProjectOrCategoryPickerParams } from "features/Project";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { InsertMeetingRequest, InsertMeetingTopicRequestUpdateMeetingTopicRequestDeleteMeetingTopicRequestDeltaModel, MeetingResponse } from "services/tenantManagementService";
import { EntityPrefixEnum, getFormatedId } from "utils/commonHelper";
import { convertResponseErrors, tryCatchJsonByAction } from "utils/fetchUtils";
import { createMeetingAction } from "../action";
import { createMeetingTopicDelta } from "../topicDeltaHelper";
import { MeetingForm } from "./MeetingForm";

export const CreateMeeting = ({ publishDataChanged }: CreateComponentProps) => {
	const params: ProjectOrCategoryPickerParams = useParams();
	const projectOrCategoryId = parseInt(params.projectOrCategoryId as string);
	const isProjectConnected = params.isProjectConnected === 'true';
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newMeeting: MeetingResponse, isRelease: boolean) => {
			const topicsDelta: InsertMeetingTopicRequestUpdateMeetingTopicRequestDeleteMeetingTopicRequestDeltaModel = createMeetingTopicDelta(
				[],
				newMeeting.topics || [],
			)

			const model = new InsertMeetingRequest({
				...newMeeting,
				topicsDelta
			});
			const bindedAction = createMeetingAction.bind(null, isRelease, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.MEETING, response.value?.id);
				notifications.success(`New meeting ${id} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	);

	const meeting = useMemo(
		() => {
			const model = new MeetingResponse();
			model.isProjectConnected = isProjectConnected;
			model.projectOrCategoryId = projectOrCategoryId;
			return model
		},
		[isProjectConnected, projectOrCategoryId]
	)

	return (
		<ContentShell title='Create meeting'>
			<MeetingForm
				meeting={meeting}
				crud={CrudEnum.Create}
				onSave={handleSave}
				cancel={goBackFromCreate}
			/>
		</ContentShell>
	)
}
