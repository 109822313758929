import { useMemo } from 'react';
import { Route } from 'features/Routes/reducer';
import Item from './Item';
import styles from '../defaultContent.module.scss';

type Props = {
	route: Route
}

export const NodeItem = ({ route }: Props) => {
	const content = useMemo(
		() => {
			if (route.children) {
				return route.children.map((childrenRoute) => (
					<Item key={childrenRoute.id} route={childrenRoute} />
				))
			}
		},
		[route]
	)

	return (
		<div className={styles.node}>
			<div className={styles.header}>{route.text}</div>
			<div className={styles.group}>
				{content}
			</div>
		</div>
	)
}
