import { getTenantId } from 'features/Tenant/tenantUtils';
import { ChangeRequestClient, ScopeChangeRequestsDashboardFilterRequest, ScopeDashboardClient } from 'services/tenantManagementService';

export const getChangeRequestsDashboardAction = (projectId: number, filterRequest: ScopeChangeRequestsDashboardFilterRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScopeDashboardClient();
	return client.getForChangeRequests(tenantId, projectId, filterRequest);
}

export const getChangeRequestsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.getAll(tenantId, projectId);
}
