import { HelpShell } from 'features/Help/HelpShell';

export const TicketHelp = () => {
	return (
		<HelpShell title='Tickets'>
			<p>
				Ticket module enables simplified incident and problem management per project or the entire enterprise. <br />
				It will help to utilize ticketing functionality in project realization but also in maintenance.
			</p>
			<p>
				Ticket module is integrated with Testing module, and it can be used in various testing cycles and defect management. It will boost up testing progress, and you can finally forget about managing spreadsheet defect management. 
			</p>
			<div>
				<p>Before you start utilizing ticketing module, first some basic configuration needs to be completed by:</p>
				<ol>
					<li>Site administrator - configuration.</li>
					<li>Project Manager - Service Level Agreement activation. A service-level agreement (SLA) defines the level of service expected by a customer from a supplier, laying out the metrics by which that service is measured. In our case these are ticket response and resolution time. Usually, SLAs are between companies and external suppliers, but they may also be between two departments within a company.</li>
				</ol>
			</div>
			<p>Tickets are showing all open tickets on projects in which user is assigned to.</p>
			<div>
				<p>Following predefined filters are available:</p>
				<ul>
					<li>Assigned to me (shows only tickets that are currently assigned to user)</li>
					<li>My tickets (open tickets created by user, tickets assigned to user or tickets marked for follow up)</li>
					<li>New tickets (all tickets with status 'new')</li>
					<li>Show closed (all tickets)</li>
				</ul>
			</div>
			<p>Ticket list can be configured based on user preferences by selecting 'Configure view' button. You can export data from the table in xls or pdf format.</p>
			<div>
				<p>When creating new ticket, you can define following:</p>
				<ul>
					<li><b>Assigned group</b>: 
						this information is maintained by site administrator and represents group/team responsible for managing incident. When incident is assigned to group, all email recipients that belongs to this group will receive information about created ticket.
					</li>
					<li><b>Assigned to*</b>: 
						concrete user who is responsible for managing ticket.
					</li>
					<li><b>Project ID*</b>: 
						relation to project to which ticket should be assigned. User can choose all active (released) projects.
					</li>
					<li><b>Reported by*</b>: 
						here you can name user who reported incident.
					</li>
					<li><b>Reported on</b>: 
						here you can enter date when incident is reported.
					</li>
					<li><b>Expected resolution date</b>: 
						here you can enter date when you expect ticket to be resolved on.
					</li>
					<li><b>Type*</b>: 
						this information is maintained by site administrator. By default, Mavles is delivering following ticket types: Question, Incident, Problem, Service Request, Change Request.
					</li>
					<li><b>Category level 1*</b>: 
						this information is maintained by site administrator and represents level 1 grouping of area to which incident is allocated. For example: software or hardware.
					</li>
					<li><b>Category level 2</b>: 
						this information is maintained by site administrator and represents level 2 grouping of area to which incident is allocated. For example: MS office within category level 1.
					</li>
					<li><b>Category level 3</b>: 
						subcategory of category 2. For example: email.
					</li>
					<li><b>Environment</b>: 
						here you can enter impacted environment (for example: Development, Test or Productive).
					</li>
					<li><b>Impact*</b>: 
						impact could be defined as low, medium or high based on number of affected users.
					</li>
					<li><b>Urgency*</b>: 
						urgency could be defined as normal or urgent.
					</li>
					<li><b>Priority</b>: 
						ticket priority is defined based on inputs in Impact and Urgency fields and predefined matrix.
					</li>
					<li><b>Parent ticket</b>: 
						you can relate ticket to some of existing already ticket.
					</li>
					<li><b>External ticket number</b>: 
						used if there is also third-party solution for incident management.
					</li>
					<li><b>Test cycle</b>: 
						you can link ticket to test cycle from test module.
					</li>
					<li><b>Test case ID</b>: 
						you can link ticket to test case ID from test module.
					</li>
					<li><b>Change request ID</b>: 
						if you are using change request from scope module, here you can link your ticket with change request ID.
					</li>
					<li><b>Create change request buton</b>. 
						From ticket you can create change request.
					</li>
					<li><b>Short description*</b>: 
						used to shortly describe issue/ name ticket.
					</li>
					<li><b>Description</b>: 
						used to describe ticket in more details.
					</li>
					<li><b>Attachment</b>: 
						you can add attachment to tickets for detailed explanation, test evidence etc.
					</li>
				</ul>
			</div>
			<div>
				<p>When updating ticket, new fields on ticket form are shown:</p>
				<ul>
					<li><b>Follow</b>: mark this option if you want to follow concrete ticket lifecycle. Followed tickets will remain in 'My tickets' until they are closed.</li>
					<li><b>Created by</b>: shows user who created ticket.</li>
					<li><b>Closed by</b>: shows user who closed ticket.</li>
				</ul>
			</div>
			<p>Also new sections which relates to following:</p>
			<p><b>Conversation</b>: you can communicate with other users about ticket. Comments can be converted as tasks for other users.</p>
			<div>
				<p><b>Closing</b>: if ticket status is changed to closed you will be able to fill in following:</p>
				<ul>
					<li><b>Closing ID</b>: is mandatory entry when closing tickets. Mavles comes with 2 default entries: successfully tested and not relevant. Site administrator can configure Closing ID based on your company needs.</li>
					<li><b>Closing note</b>: additional information related to ticket closure.</li>
					<li><b>Workaround</b>: description of possible workaround that could be applied in ticket resolution.</li>
					<li>Add <b>attachments</b> related to closing such as test evidence, additional information, manuals etc.</li>
				</ul>
			</div>
			<p><b>Effort logs</b> - here users can enter effort estimate and actual work done. If actual time spend on ticket resolution is entered in this section, it will be automatically entered into user monthly timesheet with information about ticket ID.</p>
			<div>
				<p><b>SLA</b> - shows following information:</p>
				<ul>
					<li><b>Created on</b>: shows date and time when ticket is created.</li>
					<li><b>Accepted on</b>: shows date and time when ticket changed status from new to any other status.</li>
					<li><b>Resolved on</b>: shows date and time when ticket changed status to resolved or closed depending on configuration made by responsible project manager.</li>
					<li><b>Response time</b>: shows time needed to provide response on new ticket, but also breach of response time compared to SLA configuration on project.</li>
					<li><b>Resolution time</b>: shows time needed to provide resolution of ticket, but also breach of resolution time compared to SLA configuration on project.</li>
					<li><b>On hold</b>: shows time during which ticket was on On-hold status. If defined so in configuration, during this time response and resolution time will not be calculated.</li>
				</ul>
			</div>
			<p>Report about how long ticket was assigned to each user during ticket resolution based on different ticket statuses.</p>
			<p><b>History section</b> - shows changes done on ticket by users.</p>
			<div>
				<p>Tickets can have 7 statuses:</p>
				<ul>
					<li>New</li>
					<li>Accepted</li>
					<li>In progress</li>
					<li>On-hold</li>
					<li>Resolved</li>
					<li>Rejected</li>
					<li>Closed</li>
				</ul>
			</div>
			<p>
				Status <b>'new'</b> is assigned to every created ticket as initial status. Service Level Agreement (SLA) response time is calculated when ticket changes status from new to any other status. <br />
				Status <b>'accepted'</b> is assigned to ticket to indicate that ticket is acknowledged and should be assigned to responsible person/group for resolution. This status is not mandatory and can be skipped. <br />
				During resolution period ticket has status <b>'in progress'</b>. <br />
				Ticket can be put on <b>'on-hold'</b> status if there is additional information that had to be provided by ticket requestor. It can be configured that during this status resolution time is not calculated. <br />
				When ticket is resolved, responsible person is assigning status <b>'resolved'</b> to ticket and ticket is assigned back to user who created ticket or user that must perform solution testing. <br />
				User can confirm or reject solution by assigning status closed or rejected. <br />
				<b>'Closed'</b> tickets cannot be modified. <br />
				When solution is <b>'rejected'</b>, ticked should be assigned back to responsible user for rework, and full cycle is followed until ticket is closed.
			</p>
		</HelpShell>
	)
}
