import { getTenantId } from "features/Tenant/tenantUtils";
import { BudgetRowClient, BudgetRowDeltaRequest, ExportDataModel } from "services/tenantManagementService";

export const getHeadersAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new BudgetRowClient();
	return client.getHeaders(tenantId, projectId);
}

export const getBudgetRowsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new BudgetRowClient();
	return client.getAll(tenantId, projectId);
}

export const updateBudgetRowsAction = (projectId: number, delta: BudgetRowDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new BudgetRowClient();
	return client.update(tenantId, projectId, delta);
}

export const releaseBudgetRowsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new BudgetRowClient();
	return client.release(tenantId, projectId);
}

export const exportBudgetsAction = (projectId: number, model: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new BudgetRowClient();
	return client.exportAll(tenantId, projectId, model);
}
