import React, { useMemo, useCallback } from 'react';
import useWindowSize from 'utils/useWindowSize';
import FloatingHelp from 'features/Help/FloatingHelp';
import styles from './contentShell.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { Breadcrumb } from 'components/Breadcrumb';
import { RootRouteEnum } from 'features/Routes';

type Props = {
	title?: string
	showBreadcrumb?: boolean
	FloatingHelpComponent?: React.ComponentType
	InlineHelpComponent?: React.ComponentType
	children: React.ReactElement
	renderAdditionalHeaderContent?(): React.ReactElement // this content will be on the right side of header
}

export const ContentShell = ({ title, showBreadcrumb = true, FloatingHelpComponent, InlineHelpComponent, children, renderAdditionalHeaderContent }: Props) => {
	const { currentRoutes } = useSelector((state: RootState) => state.routes);
	const { width } = useWindowSize();

	const additionalHeaderContent = useMemo(
		() => {
			return renderAdditionalHeaderContent && renderAdditionalHeaderContent();
		},
		[renderAdditionalHeaderContent]
	)

	const titleContent = useMemo(
		() => {
			let content: React.ReactElement;
			if (showBreadcrumb) {
				// filter out last one, because we show prop.title instead
				let moduleRoutes = currentRoutes.filter((item, index) => index !== currentRoutes.length - 1);

				// we want to show Configuration in BreadCrumb, but we want to filter out module
				if (currentRoutes.length > 0 && currentRoutes[0].id !== RootRouteEnum.SITE_CONFIGURATION) {
					moduleRoutes.shift();
				}

				content = <Breadcrumb items={moduleRoutes} title={title || ''} />
			} else {
				content = <h1>{title}</h1>;
			}

			return (
				<div style={{ display: 'flex', justifyContent: 'space-between', flex: '1' }}>
					{content}
					{additionalHeaderContent}
				</div>
			)
		},
		[title, currentRoutes, showBreadcrumb, additionalHeaderContent]
	)

	const getInlineHelpContent = useCallback(
		(Component: React.ComponentType) => {
			return (
				<div className={styles.inline_container}>
					<div className={styles.content}>
						{title &&
							<div className={styles.header}>
								{titleContent}
							</div>
						}
						<div className={styles.component}>
							{children}
						</div>
					</div>
					<div className={styles.inline_help}>
						<Component />
					</div>
				</div>
			)
		},
		[children, title, titleContent]
	);

	const getFloatingHelpContent = useCallback(
		(Component: React.ComponentType) => {
			return (
				<div className={styles.floating_container}>
					{(title || Component) &&
						<div className={styles.header}>
							{titleContent}
							<FloatingHelp>
								<Component />
							</FloatingHelp>
						</div>
					}
					{children}
				</div>
			)
		},
		[children, title, titleContent]
	)

	const content = useMemo(
		() => {
			if (InlineHelpComponent) {
				if (width < 1280) {
					// if small screen, show floating help instead
					return getFloatingHelpContent(InlineHelpComponent);
				} else {
					return getInlineHelpContent(InlineHelpComponent);
				}
			} else if (FloatingHelpComponent) {
				return getFloatingHelpContent(FloatingHelpComponent);
			} else {
				return (
					<div className={styles.container}>
						{title &&
							<div className={styles.header}>
								{title && titleContent}
							</div>
						}
						{children}
					</div>
				);
			}
		},
		[width, children, FloatingHelpComponent, InlineHelpComponent, getFloatingHelpContent, getInlineHelpContent, title, titleContent]
	)

	return (
		<>{content}</>
	)
}
