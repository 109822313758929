import Tabs from "components/Tabs/Tabs"
import { SimpleScheduleResponse, TicketResponse } from "services/tenantManagementService"
import { tabs } from "./tabs"

export type TicketFormTabsProps = {
	ticket: TicketResponse
	schedules?: SimpleScheduleResponse[] | undefined
	isRead?: boolean
	addEffortTimesheet?(): void
}

export const TicketFormTabs = (props: TicketFormTabsProps) => {
	return (
		<Tabs
			tabs={tabs}
			tabComponentProps={props}
		/>
	)
}
