
import { HelpShell } from 'features/Help/HelpShell';

export const BudgetStatusesHelp = () => {
	return (
		<HelpShell title='Budget items statuses'>
			<div>
				<p>Budget items can have 2 statuses:</p>
				<ul>
					<li>Created</li>
					<li>Released</li>
				</ul>
			</div>
			<p>
				These statuses can not be changed or deleted, but you can modify explanation and status color.
			</p>
			<p>
				Users can initially <b>create</b> budget items and save it. ​
				If needed users can <b>release</b> these items as initial budget version.
				After release these items can not be changed.
			</p>
			<p>
				New changes to budget can be added as new items with version ID.
			</p>
		</HelpShell>
	)
}
