import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectFinanceResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createFinanceAction } from '../action';
import { FinanceForm } from '../FinanceForm';
import { Dialog } from 'components/Dialog';
import { publishFinanceDataChanged } from '../financeDataChanged';
import { ReportComponentParams } from '../../Table/TableButtons';
import { useGoBackFromCreate } from 'features/Crud';

export const CreateFinance = () => {
	const params: ReportComponentParams = useParams();
	const reportId = parseInt(params.reportId);

	const goBackFromCreate = useGoBackFromCreate(true);

	const onSubmitCallback = useCallback(
		async (finance: ProjectFinanceResponse) => {
			const bindedAction = createFinanceAction.bind(null, reportId, finance);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('New Finance data successfully created');
				goBackFromCreate();
				publishFinanceDataChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, reportId]
	);

	return(
		<Dialog
			title='Create Finance data'
			onClose={goBackFromCreate}
			open
		>
			<FinanceForm save={onSubmitCallback} cancel={goBackFromCreate} />
		</Dialog>
	)
};
