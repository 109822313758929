import Button from 'components/Button';
import { Dialog } from 'components/Dialog';
import { useCallback, useMemo, useState } from 'react';
import { ExportDataFileTypeEnum } from 'services/tenantManagementService';
import ExportForm, { ExportModel } from './ExportForm';

type Props = {
	onExport: (exportModel: ExportModel) => void
	loading?: boolean
	exportTypeOptions?: ExportDataFileTypeEnum[]
	forceApplyFilters?: boolean
	forceApplySort?: boolean
	forceUseOnlyVisible?: boolean
	forceExportOnlyCurrentPage?: boolean
	hideApplyFilters?: boolean
	hideApplySort?: boolean
	hideUseOnlyVisible?: boolean
	hideExportOnlyCurrentPage?: boolean
}

const ExportToFile = ({
	onExport,
	loading,
	exportTypeOptions,
	forceApplyFilters,
	forceApplySort,
	forceUseOnlyVisible,
	forceExportOnlyCurrentPage,
	hideApplyFilters,
	hideApplySort,
	hideUseOnlyVisible,
	hideExportOnlyCurrentPage,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const exportTypeOptionsMemo = useMemo(
		() => {
			const selectedExportTypeOptions = !exportTypeOptions || exportTypeOptions.length === 0 ?
				Object.values(ExportDataFileTypeEnum) :
				exportTypeOptions

			return selectedExportTypeOptions.map(option => {
				return {
					id: option,
					text: option,
				}
			});
		},
		[exportTypeOptions]
	)

	const exportCallback = useCallback(
		(exportModel: ExportModel) => {
			onExport(exportModel);
			setIsOpen(false);
		},
		[onExport]
	)

	const cancelCallback = useCallback(
		() => setIsOpen(false),
		[]
	)

	const openModalCallback = useCallback(
		() => setIsOpen(true),
		[]
	)

	return (
		<>
			<Button
				text='Export'
				color='secondary'
				onClick={openModalCallback}
				isLoading={loading}
			/>
			<Dialog
				title='Export to file'
				onClose={cancelCallback}
				open={isOpen}
			>
				<ExportForm
					save={exportCallback}
					cancel={cancelCallback}
					exportTypeOptions={exportTypeOptionsMemo}
					forceApplyFilters={forceApplyFilters}
					forceApplySort={forceApplySort}
					forceUseOnlyVisible={forceUseOnlyVisible}
					forceExportOnlyCurrentPage={forceExportOnlyCurrentPage}
					hideApplyFilters={hideApplyFilters}
					hideApplySort={hideApplySort}
					hideUseOnlyVisible={hideUseOnlyVisible}
					hideExportOnlyCurrentPage={hideExportOnlyCurrentPage}
				/>
			</Dialog>
		</>
	)
}

export default ExportToFile;
