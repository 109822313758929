import { getProjectTeamsAction, updateProjectTeamsAction } from './action';
import { ProjectResponse } from 'services/tenantManagementService';
import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';

type Props = {
	project: ProjectResponse
};

const Teams = ({ project }: Props) => {
	return (
		<TenantIsActiveTable
			getConfigsAction={getProjectTeamsAction.bind(null, project.id)}
			updateConfigsAction={updateProjectTeamsAction.bind(null, project.id)}
			successMessage='Project teams are updated.'
			label={{ name: 'Team Name', description: 'Team Description' }}
		/>
	)
};

export default Teams;
