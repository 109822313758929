import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectChangeRequestResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createChangeRequestAction } from '../action';
import { publishChangeRequestsChanged } from '../changeRequestChanged';
import { ChangeRequestForm } from '../ChangeRequestForm';
import { Dialog } from 'components/Dialog';
import { ReportComponentParams } from '../../Table/TableButtons';
import { useGoBackFromCreate } from 'features/Crud';

export const CreateChangeRequest = () => {
	const params: ReportComponentParams = useParams();
	const reportId = parseInt(params.reportId);

	const goBackFromCreate = useGoBackFromCreate(true);

	const onSubmitCallback = useCallback(
		async (changeRequest: ProjectChangeRequestResponse) => {
			const bindedAction = createChangeRequestAction.bind(null, reportId, changeRequest);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('New Change request successfully created');
				goBackFromCreate();
				publishChangeRequestsChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, reportId]
	);

	return(
		<Dialog
			title='Create Change Request'
			onClose={goBackFromCreate}
			open
		>
			<ChangeRequestForm save={onSubmitCallback} cancel={goBackFromCreate} />
		</Dialog>
	)
};
