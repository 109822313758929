import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ProjectTimeTravelDashboardFilterRequest, ProjectTimeTravelDashboardResponse, } from 'services/tenantManagementService'
import { Dashboard } from 'features/Dashboard/Dashboard'
import { Layout } from 'features/Dashboard/Layout'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { XYWidget } from 'features/Dashboard/Widget/XYWidget'
import { Widget } from 'features/Dashboard/Widget/Widget'
import { TravelExpenseDashboardTable } from '../../Table/TravelExpense/TravelExpenseDashboardTable'
import { RootState } from 'base/reducer/reducer'
import { getMonthEnumByDate } from 'utils/dateTimeUtils'

type Props = {
	loading: boolean
	dashboard: ProjectTimeTravelDashboardResponse
	filterModel: ProjectTimeTravelDashboardFilterRequest
}

export const TravelDashboard = ({ loading, dashboard, filterModel }: Props) => {

	const { persistedTimeAndTravelStatus } = useSelector((state: RootState) => state);

	const travelRequestsPerStatusDataMemo = useMemo(
		() => {
			const data = persistedTimeAndTravelStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.travelRequests?.travelRequestsPerStatusCounts && dashboard.travelRequests?.travelRequestsPerStatusCounts[status.id]) ? dashboard.travelRequests?.travelRequestsPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTimeAndTravelStatus]
	)

	const travelExpensesPerStatusDataMemo = useMemo(
		() => {
			const data = persistedTimeAndTravelStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.travelExpenses?.travelExpensesPerStatusCounts && dashboard.travelExpenses?.travelExpensesPerStatusCounts[status.id]) ? dashboard.travelExpenses?.travelExpensesPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTimeAndTravelStatus]
	)

	const travelExpensesPerPeriodDataMemo = useMemo(
		() => {
			return Object.keys(dashboard.travelExpenses?.travelExpensesPerPeriod || {}).map(dateInTicks => {
				const date = new Date(parseInt(dateInTicks));
				return  {
					name: `${getMonthEnumByDate(date)} ${date.getFullYear()}`,
					value: dashboard.travelExpenses?.travelExpensesPerPeriod![dateInTicks] || 0
				}
			})
		},
		[dashboard]
	)

	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='horizontal'>
				<PieWidget
					className='col-xl-4 col-md-6'
					title='Travel requests per Status'
					id='travel_requests_per_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={travelRequestsPerStatusDataMemo}
					loading={loading}
				/>
				<PieWidget
					className='col-xl-4 col-md-6'
					title='Travel expenses per Status'
					id='travel_expenses_per_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={travelExpensesPerStatusDataMemo}
					loading={loading}
				/>
			</Layout>
			<XYWidget
				title='Travel expenses per period'
				id='approved_travel_expenses_per_period_xy_chart'
				ytitle='Project hours cost'
				data={travelExpensesPerPeriodDataMemo}
				minValue={0}
				maxValue={Math.max(...travelExpensesPerPeriodDataMemo.map(x => x.value)) || 1}
				loading={loading}
				showValueOnBar
			/>
			<Widget title='Travel expenses' loading={loading}>
				<TravelExpenseDashboardTable
					data={dashboard.travelExpenses}
					filterModel={filterModel}
				/>
			</Widget>
		</Dashboard>
	)
}
