import { CrudButtons } from 'features/Crud';

type Props = {
	selectedId: number
	disabled: boolean
	changeDisabled: boolean
	viewDisabled: boolean
}

const TableButtons = ({
	selectedId,
	disabled,
	changeDisabled,
	viewDisabled,
}: Props) => {
	return (
		<CrudButtons
			selectedId={selectedId}
			isCreateable={false}
			isViewable
			isChangeable
			isDeleteable={false}
			disabled={disabled}
			specificActionDisabled={{
				change: changeDisabled,
				view: viewDisabled,
			}}
			labels={{
				change: 'Take survey',
				view: 'View survey',
			}}
		/>
	)
}

export default TableButtons;
