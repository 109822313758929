import { useCallback, useState } from 'react';
import { RowContainer } from 'components/Layout';
import Button from 'components/Button';
import { ExportDataFileTypeEnum, ExportDataModel, ExportForPeriodTimesheetsRequest, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { Export } from 'features/Export/Export';
import { ExportModel } from 'features/Export/ExportForm';
import { createExportModel } from 'utils/exportUtils';
import { BaseColumnModel } from 'components/Table';

type Props = {
	from: Date | undefined
	to: Date | undefined
	approveDisabled: boolean
	rejectDisabled: boolean
	approvalEnabled: boolean
	onApprove(): Promise<void>
	onReject(): void
	tableColumns: BaseColumnModel[]
	exportFunction(model: ExportDataModel, exportModel?: ExportModel): Promise<FileResponse>
}

const TableButtons = ({
	from,
	to,
	approveDisabled,
	rejectDisabled,
	approvalEnabled,
	onApprove,
	onReject,
	tableColumns,
	exportFunction
}: Props) => {

	const [isApproving, setIsApproving] = useState(false);
	const [isRejecting, setIsRejecting] = useState(false);

	const approveCallback = useCallback(
		async () => {
			setIsApproving(true);
			await onApprove()
			setIsApproving(false);
		},
		[onApprove]
	)

	const rejectCallback = useCallback(
		async () => {
			setIsRejecting(true);
			await onReject()
			setIsRejecting(false);
		},
		[onReject]
	)

	return (
		<RowContainer justifyContent='space-between'>
			{approvalEnabled && (
				<RowContainer>
					<Button
						onClick={approveCallback}
						disabled={approveDisabled}
						text='Approve'
						isLoading={isApproving}
					/>
					<Button
						onClick={rejectCallback}
						disabled={rejectDisabled}
						text='Reject'
						isLoading={isRejecting}
					/>
				</RowContainer>
			)}
			<RowContainer>
				<Export
					filtersModel={new GenericFilterModelCollection()}
					tableColumns={tableColumns}
					exportAction={exportFunction}
					createExportModelFunction={createExportModelCustomFunction.bind(null, from!, to!)}
					exportTypeOptions={[ExportDataFileTypeEnum.XLSX, ExportDataFileTypeEnum.PDF]}
					title='Timesheets'
					hideUseOnlyVisible
					hideApplyFilters
					hideApplySort
					hideExportOnlyCurrentPage
				/>
			</RowContainer>
		</RowContainer>
	)
}

const createExportModelCustomFunction = (from: Date, to: Date, columns: BaseColumnModel[], filtersModel: GenericFilterModelCollection, exportModel: ExportModel) => {
	const exportDataModel = createExportModel(columns, filtersModel, exportModel);
	return new ExportForPeriodTimesheetsRequest({
		...exportDataModel,
		from,
		to
	})

}

export default TableButtons;
