import { HelpShell } from 'features/Help/HelpShell';

export const TestStatusHelp = () => {
	return (
		<HelpShell title='Test Status'>
			<div>
				<p>This section show detail test status per test cycle.
				</p>
				<p>You can choose between 3 reports:
				</p>
				<ol>
					<li>Test status based on processes (level 3)</li>
					<li>Test status based on processes (level 3) and process steps (level 4)</li>
					<li>Test status based on processes (level 3) and assigned tickets to each process. Here you can track ticket status per each process. Please note that
						rows are repeated when multiple tickets are assigned to individual process.
					</li>
				</ol>
			</div>
		</HelpShell>
	)
}
