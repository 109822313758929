import { CrudSubRoutesEnum } from 'features/Crud';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { TravelExpenseItemResponse } from 'services/tenantManagementService';
import CreateTravelExpenseItem from './Crud/CreateTravelExpenseItem';
import ReadTravelExpenseItem from './Crud/ReadTravelExpenseItem';
import UpdateTravelExpenseItem from './Crud/UpdateTravelExpenseItem';

export enum TravelExpenseSubRoutes {
	ExpenseItem = 'expense_item'
}

export const TravelExpenseItemParamName = 'travelExpenseItemId';

type Props = {
	travelExpenseItem?: TravelExpenseItemResponse
	onAdd: (travelExpenseItem: TravelExpenseItemResponse) => void
	onUpdate: (travelExpenseItem: TravelExpenseItemResponse) => void
}

const TravelExpenseItemsCrudRouter = ({ travelExpenseItem, onAdd, onUpdate }: Props) => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${TravelExpenseSubRoutes.ExpenseItem}/${CrudSubRoutesEnum.Update}/:${TravelExpenseItemParamName}`}
				render={() => (
					<UpdateTravelExpenseItem
						travelExpenseItem={travelExpenseItem!}
						onSave={onUpdate}
					/>
				)}
			/>
			<Route
				path={`${path}/${TravelExpenseSubRoutes.ExpenseItem}/${CrudSubRoutesEnum.Read}/:${TravelExpenseItemParamName}`}
				render={() => (
					<ReadTravelExpenseItem
						travelExpenseItem={travelExpenseItem!}
					/>
				)}
			/>
			<Route
				path={`${path}/${TravelExpenseSubRoutes.ExpenseItem}/${CrudSubRoutesEnum.Create}`}
				render={() => (
					<CreateTravelExpenseItem
						onSave={onAdd}
					/>
				)}
			/>
		</Switch>
	)
}

export default TravelExpenseItemsCrudRouter;
