import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { ProjectHoursRowDashboardResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { getMonthEnumByDate } from 'utils/dateTimeUtils';

const defaultColumns = [
	propertyOf<ProjectHoursRowDashboardResponse>('date'),
	propertyOf<ProjectHoursRowDashboardResponse>('hours'),
]

export const useTableColumnsMemo = (columnsDataValue: ProjectHoursRowDashboardResponse[] = []) => {
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectHoursRowDashboardResponse>('date')]: {
					title: 'Month',
					fieldType: FieldTypeEnum.Date,
					formatter: (cell: any) => {
						const data = cell.getValue();
						const fullYear = data.getFullYear();
						const month = getMonthEnumByDate(cell.getValue());
						return `${month} ${fullYear}`
					},
					bottomCalcFormatter: () => 'Total',
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<ProjectHoursRowDashboardResponse>('hours')]: {
					title: 'Hours',
					fieldType: FieldTypeEnum.Number,
					formatter: (cell: any) => cell.getValue().toFixed(2),
					bottomCalc: 'sum',
					bottomCalcFormatter(cell: any) {
						let totalSum = 0;
						for (const sum of columnsDataValue) {
							const total = sum.hours;
							totalSum += total;
						}

						return `∑ ${totalSum.toFixed(2)}`;
					},
					disableSort: true,
					disableFilter: true,
				},
			}
		},
		[columnsDataValue]
	)

	return useVisibleColumns(columns, defaultColumns);
}
