import { HelpShell } from 'features/Help/HelpShell';

export const CreateUserInfoHelp = () => {
	return (
		<HelpShell title='Create new user'>
			<p>In Mavles, users are created only by site administrator or by responsible project manager. <br />
				When user is created, he/she gets email notification about username and password. At that time, he/she will be able to log on site but will not be able to access any data on site until responsible project manager assigns him/her to concrete project and team. After assignment, user receives email notification about assignment, and he can assess Mavles application based on authorization given by project manager.
			</p>
			<p>When creating new user ID, we need to choose if new user is guest user (partner) or company user.  If user is a guest user, he must be assigned to specific Partner. Before creating new guest user, it is necessary to create Partner. Partners are created by site administrators.</p>
			<p>It is advised to create username policy related to creating user names on the site since username is not changeable after saving data. (example: for user Betty Boop, user name could be: BBoop, betty.boop, b.boop…).</p>
			<p>Next to contact information, for company users you can also assign user to organizational unit to which user belongs to. This is applicable only for company users and not guest users. This will allow manager of user to approve Time & Travel requests if defined by site administrator and view reports related to user performance.</p>
		</HelpShell>
	)
}
