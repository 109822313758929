import { getTenantId } from 'features/Tenant/tenantUtils';
import { ExportDataModel, GenericFilterModelCollection, InsertMeetingRequest, MeetingClient, UpdateMeetingRequest } from 'services/tenantManagementService';

export const getAllMeetingsGenericFilterAction = (projectOrCategoryId: number, isProjectConnected: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.getAllGenericFilter(tenantId, projectOrCategoryId, isProjectConnected, genericFilter);
}

export const getAllMeetingsAction = (projectOrCategoryId: number, isProjectConnected: boolean, statusId: number | undefined, notEqualStatusId: number | undefined) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.getAll(tenantId, projectOrCategoryId, isProjectConnected, statusId, notEqualStatusId);
}

export const getAllSimpleMeetingsAction = () => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.getAllSimple(tenantId);
}

export const getMeetingAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.get(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const createMeetingAction = (isRelease: boolean, model: InsertMeetingRequest) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.create(tenantId, isRelease, model);
}

export const updateMeetingAction = (isRelease: boolean | undefined, model: UpdateMeetingRequest) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.update(tenantId, isRelease, model);
}

export const deleteMeetingAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.delete(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const releaseMeetingAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.release(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const cloneMeetingAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.clone(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const resetStatusMeetingAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.resetStatus(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const setIsPrivateMeetingAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean, isPrivate: boolean) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.setIsPrivate(tenantId, id, projectOrCategoryId, isProjectConnected, isPrivate);
}

export const exportMeetingsAction = (projectOrCategoryId: number, isProjectConnected: boolean, model: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.exportAll(tenantId, projectOrCategoryId, isProjectConnected, model)
}

export const downloadAttachmentAction = (meetingId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.download(tenantId, meetingId, id);
}

export const removeAttachmentAction = (meetingId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new MeetingClient();
	return client.removeAttachment(tenantId, meetingId, id);
}
