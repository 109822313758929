import { useCallback, useContext, useEffect } from 'react';
import { Subtract } from 'utility-types';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { IFormContext, FormContext } from '../Form';
import { Table, TableProps } from '../controls';
import { ItemsFieldProps, ItemsControlCommonProps, useItemsField } from '../controls/Table/wrappers/useItemsField';

export const TableField = ({ id, validator, disabled, uniqueFieldNames, ...rest }: ItemsFieldProps & Subtract<TableProps, ItemsControlCommonProps>) => {
	const context = useContext<IFormContext>(FormContext);

	const itemsFieldProps = useItemsField({
		id,
		validator,
		disabled,
		uniqueFieldNames
	} as ItemsFieldProps)

	const tableSpecificProps = rest as Subtract<TableProps, ItemsControlCommonProps>;

	// if a column was dynamically removed, remove validation errors for that column
	useEffect(
		() => {
			const fieldNames = new Set(tableSpecificProps.headers.map(x => x.id).filter(x => !!x));
			if (
				fieldNames.size > 0 && context.setFieldError && context.errors[id] &&
				Array.isArray(context.errors[id]) && context.errors[id].length > 0
			) {
				let changed = false;
				const newErrors = [...context.errors[id]];
				for (let i = 0; i < context.errors[id].length; ++i) {
					if (context.errors[id][i] && Object.keys(context.errors[id][i]).some(y => !(fieldNames.has(y)))) {
						changed = true;
						const newError = Object.keys(context.errors[id][i])
							.filter(x => fieldNames.has(x))
							.reduce((acc, cv) => ({ ...acc, [cv]: context.errors[id][cv] }), {});

						newErrors.splice(i, 1, newError);
					}
				}

				if (changed) {
					context.setFieldError(id, newErrors.filter(x => !!x && Object.keys(x).length > 0));
				}
			}
		},
		[id, tableSpecificProps.headers, context]
	)

	const handleDragEnd = useCallback(
		(result: DropResult) => {
			const { destination, source } = result;
			if (!destination || destination.droppableId !== source.droppableId || destination.index === source.index) {
				return;
			}

			const newTableValues = [...context.values[id]];
			const newErrors = newTableValues.map((_, ind) => (context.errors[id] || [])[ind] || undefined)
			const draggedElementError = newErrors[source.index]

			newTableValues.splice(source.index, 1);
			newTableValues.splice(destination.index, 0, context.values[id][source.index]);

			newErrors.splice(source.index, 1)
			newErrors.splice(destination.index, 0, draggedElementError)

			context.setFieldError && context.setFieldError(id, newErrors);
			context.setFieldValue && context.setFieldValue(id, newTableValues);
		},
		[id, context]
	);

	const TableComponent: JSX.Element = (
		<Table
			{...tableSpecificProps}
			{...itemsFieldProps}
		/>
	)

	if (tableSpecificProps.draggable) {
		return (
			<DragDropContext onDragEnd={handleDragEnd}>
				{TableComponent}
			</DragDropContext>
		)
	}

	return TableComponent;
}
