import { View, StyleSheet } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import { ChangeRequestActivityResponse, ChangeRequestFullResponse, ChangeRequestStatusResponse, IdNameResponse, ProjectResponse, TenantResponseModel, UserModel } from 'services/tenantManagementService';
import { FormField } from 'components/Export';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { PersistItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import { formatDate } from 'utils/dateTimeUtils';
import FormGroup from 'components/Export/FormGroup';
import TextareaFormField from 'components/Export/TextareaFormField';

const sanitizeValue = (value: string | null | undefined) => value || '-'

type Props = {
	changeRequest: ChangeRequestFullResponse
	project: ProjectResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedChangeRequestStatus: PersistItemsReducer<ChangeRequestStatusResponse>
	persistedTenant: PersistValueReducer<TenantResponseModel>
	persistedCurrency: PersistItemsReducer<IdNameResponse>
}

export const Analysis = ({
	changeRequest,
	project,
	persistedUser,
	persistedChangeRequestStatus,
	persistedTenant,
	persistedCurrency,
}: Props) => {
	const estimatedByUser = changeRequest.estimatedByUserId ? persistedUser.itemsMap[changeRequest.estimatedByUserId] : undefined;

	const tableData = changeRequest.activities || [];
	const tableDataWithFooter = [...tableData, new ChangeRequestActivityResponse({
		id: 0,
		name: 'Total',
		effortInHours: tableData.reduce((accSum, a) => accSum + a.effortInHours, 0),
		hourPrice: 0,
		cost: tableData.reduce((accSum, a) => accSum + a.cost, 0),
	})]
	return (
		<>
			<FormField
				label='Project Id'
				value={getFormatedId(EntityPrefixEnum.PROJECT, project.id)}
			/>
			<FormField
				label='Project name'
				value={project.name}
				marginBottom={15}
			/>
			<FormField
				label='Change Request Id'
				value={getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, changeRequest.id)}
			/>
			<FormField
				label='Change Request name'
				value={changeRequest.name}
			/>
			<FormField
				label='Status'
				value={persistedChangeRequestStatus.itemsMap[changeRequest.statusId]?.name}
			/>
			<FormField
				label='Estimated by'
				value={estimatedByUser && `${estimatedByUser.firstName} ${estimatedByUser.lastName}`}
			/>
			<FormField
				label='Estimated on'
				value={formatDate(changeRequest.estimatedOn)}
			/>
			<FormField
				label='Completion on'
				value={formatDate(changeRequest.completionOn)}
			/>
			<FormGroup label='Overall project impact, change to project plan, risks etc' >
				<TextareaFormField
					value={changeRequest.analysisDescription}
				/>
			</FormGroup>
			<FormGroup label='Assumptions' >
				<TextareaFormField
					value={changeRequest.assumptions}
				/>
			</FormGroup>
			<FormGroup label='Costs' >
				<View style={styles.tableContainer} wrap={false}>
					<Table data={tableDataWithFooter}>
						<TableHeader>
							<TableCell style={styles.header}>Activity</TableCell>
							<TableCell style={styles.header}>Consultant</TableCell>
							<TableCell style={styles.header}>Level</TableCell>
							<TableCell style={styles.header}>Expected delivery on</TableCell>
							<TableCell style={styles.header}>Effort (in hours)</TableCell>
							<TableCell style={styles.header}>Hour price</TableCell>
							<TableCell style={styles.header}>Cost</TableCell>
						</TableHeader>
						<TableBody>
							<DataTableCell style={styles.text} getContent={(item: ChangeRequestActivityResponse) => sanitizeValue(item.name)} />
							<DataTableCell style={styles.text} getContent={(item: ChangeRequestActivityResponse) => sanitizeValue(item.consultant)} />
							<DataTableCell style={styles.text} getContent={(item: ChangeRequestActivityResponse) => sanitizeValue(item.level)} />
							<DataTableCell style={styles.text} getContent={(item: ChangeRequestActivityResponse) => sanitizeValue(formatDate(item.expectedDeliveryOn))} />
							<DataTableCell style={styles.text} getContent={(item: ChangeRequestActivityResponse) => sanitizeValue(item.effortInHours.toString())} />
							<DataTableCell style={styles.text} getContent={(item: ChangeRequestActivityResponse) => sanitizeValue(item.hourPrice ? `${item.hourPrice} ${persistedCurrency.itemsMap[persistedTenant.value.currencyId]?.symbol}` : '')} />
							<DataTableCell style={styles.text} getContent={(item: ChangeRequestActivityResponse) => sanitizeValue(`${item.cost} ${persistedCurrency.itemsMap[persistedTenant.value.currencyId]?.symbol}`)} />
						</TableBody>
					</Table>
				</View>
			</FormGroup>
		</>
	);
};


const styles = StyleSheet.create({
	tableContainer: {
		paddingVertical: 10,
	},
	header: {
		flexWrap: 'wrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: 2,
		fontSize: 10,
		fontWeight: 500
	},
	text: {
		flexWrap: 'wrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: 2,
		fontSize: 10,
	}
})
