import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns, linksCellFormatter } from 'components/Table';
import { ExpenseItemResponse, IdNameResponse, CostExpenseTypeResponse, AttachmentResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { downloadAttachmentAction } from 'containers/TimeTravel/MyExpenses/action';
import FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
    propertyOf<ExpenseItemResponse>('expenseTypeId'),
    propertyOf<ExpenseItemResponse>('amountProjectCurrency'),
    propertyOf<ExpenseItemResponse>('amountReceiptCurrency'),
	propertyOf<ExpenseItemResponse>('receiptCurrencyId'),
	propertyOf<ExpenseItemResponse>('attachments'),
	propertyOf<ExpenseItemResponse>('comment')
]

export const useTableColumnsMemo = (
	visibleColumns: string[] = defaultVisibleColumns
) => {
	const { 
		persistedCurrency, 
		persistedTimeTravelExpenseTypes 
	} = useSelector((state: RootState) => state);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ExpenseItemResponse>('expenseTypeId')]: {
					title: 'Expense type',
					formatter: (cell: any) => {
						const id = cell.getValue();
						return persistedTimeTravelExpenseTypes.itemsMap[id]?.name;
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTimeTravelExpenseTypes.items,
					getItemId: (option: CostExpenseTypeResponse) => option.id,
					getItemText: (option: CostExpenseTypeResponse) => option.name,
				},
				[propertyOf<ExpenseItemResponse>('amountProjectCurrency')]: {
					title: 'Amount in project currency',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: 'sum'

				},
				[propertyOf<ExpenseItemResponse>('amountReceiptCurrency')]: {
					title: 'Amount in receipt currency',
					fieldType: FieldTypeEnum.Number,
					bottomCalc: 'sum',
					bottomCalcFormatter: (cell: any) => `∑ ${cell.getValue() || 0}`
				},
				[propertyOf<ExpenseItemResponse>('receiptCurrencyId')]: {
					title: 'Receipt currency',
					formatter: (cell: any) => {
						const id = cell.getValue();
						return persistedCurrency.itemsMap[id]?.symbol;
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedCurrency.items,
					getItemId: (option: IdNameResponse) => option.id,
					getItemText: (option: IdNameResponse) => option.symbol,
				},
				[propertyOf<ExpenseItemResponse>('attachments')]: {
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true,
					title: 'Attachments',
					formatter: (cell: any, formatterParams: any, onRendered: Function) => linksCellFormatter(cell, formatterParams, onRendered, true),
					formatterParams: {
						getLinks: (value: AttachmentResponse[]) => value || [],
						getLinkTitle: (link: AttachmentResponse) => link.name,
						getLinkUrl: () => '#',
						onClickLink: async (e: MouseEvent, link: AttachmentResponse, cell: any) => {
							e.preventDefault();
							const data: ExpenseItemResponse = cell.getData();
							const expenseItemId = data.id;
							const response = await downloadAttachmentAction(expenseItemId, link.id);
							if (response.status === 200) {
								FileSaver.saveAs(response.data, response.fileName);
							};
						},
						linkTarget: null,
					},
				},
				[propertyOf<ExpenseItemResponse>('comment')]: {
					title: 'Comments',
					fieldType: FieldTypeEnum.String
				},
			};
		},
		[persistedCurrency, persistedTimeTravelExpenseTypes]
	)

	return useVisibleColumns(columns, visibleColumns);
}
