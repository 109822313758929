import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { ExportModel } from 'features/Export/ExportForm';
import ExportToFile from 'features/Export/ExportToFile';
import { ProjectPickerParams } from 'features/Project';
import { ExportDataFileTypeEnum } from 'services/tenantManagementService';
import { BaseColumnModel } from 'components/Table';

export type ReportComponentParams = ProjectPickerParams & {
	reportId: string
}

type Props = {
	onExport: (exportModel: ExportModel) => void
	loadingExport?: boolean
	configureViewKey: string
    tableColumns: BaseColumnModel[]
};

const TableButtons = ({
	configureViewKey,
	tableColumns,
	onExport,
	loadingExport
}: Props) => {
	return (
		<div>
			<RowContainer justifyContent='flex-end'>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
					<ExportToFile onExport={onExport} loading={loadingExport} exportTypeOptions={[ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX]} />
				</RowContainer>
			</RowContainer>
		</div>
	);
};

export default TableButtons;
