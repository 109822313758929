import { useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import Button from 'components/Button';
import { createPropertiesForExportModel, tryCatchExportFileByAction } from 'utils/exportUtils';
import { exportAction } from '../action';
import { ExportDataFileTypeEnum, ExportProjectReportDataModel, ProjectChangeRequestCountsResponse, ProjectFinanceCountsResponse, ProjectRiskCountsResponse } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import ExportForm, { ExportModel } from 'features/Export/ExportForm';
import { RootState } from 'base/reducer/reducer';
import { PROJECT_REPORT_CONFIGURE_VIEW_KEYS } from './configureViewKeys';
import { useTableColumnsMemo as useMajorAccomplishmentsColumnsMemo } from '../ReportComponents/PerformanceStatus/MajorAccomplishments/Table/tableColumns';
import { useTableColumnsMemo as useMilestonePlanColumnsMemo } from '../ReportComponents/PerformanceStatus/MilestonePlan/Table/tableColumns';
import { useTableColumnsMemo as useDeliverablePlanColumnsMemo } from '../ReportComponents/PerformanceStatus/DeliverablePlan/Table/tableColumns';
import { useTableColumnsMemo as useMajorIssuesColumnsMemo } from '../ReportComponents/MajorIssues/Table/tableColumns';
import { useTableColumnsMemo as useChangeRequestColumnsMemo } from '../ReportComponents/ChangeRequest/Table/tableColumns';
import { useTableColumnsMemo as useSubcontractorPerformanceColumnsMemo } from '../ReportComponents/SubcontractorPerformance/Table/tableColumns';
import { useTableColumnsMemo as useRiskManagementColumnsMemo } from '../ReportComponents/RiskManagement/Table/tableColumns';
import { useTableColumnsMemo as useQualityManagementColumnsMemo } from '../ReportComponents/QualityManagement/Table/tableColumns';
import { useTableColumnsMemo as useProjectFinancesColumnsMemo } from '../ReportComponents/ProjectFinances/Table/tableColumns';
import { useTableColumnsMemo as usePotentialAdditionalBusinessColumnsMemo } from '../ReportComponents/PotentialAdditionalBusiness/Table/tableColumns';
import { useTableColumnsMemo as useCommentsAndActionsColumnsMemo } from '../ReportComponents/CommentsAndActions/Table/tableColumns';
import { AreaStatuses } from '../ReportComponents/ProjectStatus/areaStatusEnum';

type ExportProjectReportProps = {
	projectId: number;
	id: number;
}

export const ExportProjectReport = ({ projectId, id }: ExportProjectReportProps) => {
	const [loadingExport, setLoadingExport] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const {
		persistedConfigureView,
	} = useSelector((state: RootState) => state);

	const tableColumnsMajorAccomplishments = useMajorAccomplishmentsColumnsMemo(persistedConfigureView.value[PROJECT_REPORT_CONFIGURE_VIEW_KEYS.MAJOR_ACCOMPLISHMENTS]);
	const tableColumnsMilestonePlan = useMilestonePlanColumnsMemo(persistedConfigureView.value[PROJECT_REPORT_CONFIGURE_VIEW_KEYS.MILESTONE_PLAN]);
	const tableColumnsDeliverablePlan = useDeliverablePlanColumnsMemo(persistedConfigureView.value[PROJECT_REPORT_CONFIGURE_VIEW_KEYS.DELIVERABLE_PLAN]);

	const tableColumnsMajorIssues = useMajorIssuesColumnsMemo(persistedConfigureView.value[PROJECT_REPORT_CONFIGURE_VIEW_KEYS.MAJOR_ISSUES]);
	const tableColumnsChangeRequest = useChangeRequestColumnsMemo(new ProjectChangeRequestCountsResponse(), persistedConfigureView.value[PROJECT_REPORT_CONFIGURE_VIEW_KEYS.CHANGE_REQUEST]);
	const tableColumnsSubcontractorPerformance = useSubcontractorPerformanceColumnsMemo(persistedConfigureView.value[PROJECT_REPORT_CONFIGURE_VIEW_KEYS.SUBCONTRACTOR_PERFORMANCE]);
	const tableColumnsRiskManagement = useRiskManagementColumnsMemo(new ProjectRiskCountsResponse(), persistedConfigureView.value[PROJECT_REPORT_CONFIGURE_VIEW_KEYS.RISK_MANAGEMENT]);
	const tableColumnsQualityManagement = useQualityManagementColumnsMemo(persistedConfigureView.value[PROJECT_REPORT_CONFIGURE_VIEW_KEYS.QUALITY_MANAGEMENT]);
	const tableColumnsProjectFinances = useProjectFinancesColumnsMemo(new ProjectFinanceCountsResponse(), persistedConfigureView.value[PROJECT_REPORT_CONFIGURE_VIEW_KEYS.PROJECT_FINANCES]);
	const tableColumnsPotentialAdditionalBusiness = usePotentialAdditionalBusinessColumnsMemo(persistedConfigureView.value[PROJECT_REPORT_CONFIGURE_VIEW_KEYS.POTENTIAL_ADDITIONAL_BUSINESS]);
	const tableColumnsCommentsAndActions = useCommentsAndActionsColumnsMemo(persistedConfigureView.value[PROJECT_REPORT_CONFIGURE_VIEW_KEYS.COMMENTS_AND_ACTIONS]);

	const exportTypeOptionsMemo = useMemo(() => [ExportDataFileTypeEnum.PDF].map(option => ({ id: option, text: option })), [])

	const cancelCallback = useCallback(
		() => setIsOpen(false),
		[]
	)

	const openModalCallback = useCallback(
		() => setIsOpen(true),
		[]
	)

	const exportCallback = useCallback(
		async (exportModel: ExportModel) => {
			setLoadingExport(true);

			const bindedAction = exportAction.bind(null, projectId, id, new ExportProjectReportDataModel({
				title: 'Project Report',
				// TODO: How to know C# time zone time?
				timeZone: 'Central Europe Standard Time',
				//timeZoneOffsetInMunutes: -new Date().getTimezoneOffset(),
				propertiesPerTablePerArea: {
					[AreaStatuses.PerformanceStatus]: [
						createPropertiesForExportModel(tableColumnsMajorAccomplishments, exportModel),
						createPropertiesForExportModel(tableColumnsMilestonePlan, exportModel),
						createPropertiesForExportModel(tableColumnsDeliverablePlan, exportModel),
					],
					[AreaStatuses.MajorIssues]: [createPropertiesForExportModel(tableColumnsMajorIssues, exportModel)],
					[AreaStatuses.ChangeRequest]: [createPropertiesForExportModel(tableColumnsChangeRequest, exportModel)],
					[AreaStatuses.SubcontractorPerformance]: [createPropertiesForExportModel(tableColumnsSubcontractorPerformance, exportModel)],
					[AreaStatuses.RiskManagement]: [createPropertiesForExportModel(tableColumnsRiskManagement, exportModel)],
					[AreaStatuses.QualityManagement]: [createPropertiesForExportModel(tableColumnsQualityManagement, exportModel)],
					[AreaStatuses.ProjectFinances]: [createPropertiesForExportModel(tableColumnsProjectFinances, exportModel)],
					[AreaStatuses.PotentialAdditionalBusiness]: [createPropertiesForExportModel(tableColumnsPotentialAdditionalBusiness, exportModel)],
					[AreaStatuses.CommentsAndActions]: [createPropertiesForExportModel(tableColumnsCommentsAndActions, exportModel)],
				},
			}))

			const response = await tryCatchExportFileByAction(bindedAction);
			if (response.success && response.value) {
				FileSaver.saveAs(response.value.data, response.value.fileName);
			}

			setIsOpen(false);
			setLoadingExport(false);
		},
		[
			projectId, id,
			tableColumnsMajorAccomplishments, tableColumnsMilestonePlan, tableColumnsDeliverablePlan,
			tableColumnsMajorIssues, tableColumnsChangeRequest, tableColumnsSubcontractorPerformance,
			tableColumnsRiskManagement, tableColumnsQualityManagement, tableColumnsProjectFinances,
			tableColumnsPotentialAdditionalBusiness, tableColumnsCommentsAndActions,
		]
	)

	return (
		<>
			<Button
				text='Export'
				color='secondary'
				isLoading={loadingExport}
				onClick={openModalCallback}
			/>
			<Dialog
				title='Export to file'
				onClose={cancelCallback}
				open={isOpen}
			>
				<ExportForm
					save={exportCallback}
					cancel={cancelCallback}
					exportTypeOptions={exportTypeOptionsMemo}
					forceApplyFilters={false}
					forceApplySort={false}
					forceExportOnlyCurrentPage={false}
					hideApplyFilters
					hideApplySort
					hideExportOnlyCurrentPage
				/>
			</Dialog>
		</>
	)
}
