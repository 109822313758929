import { SettingsIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";
import { SiteConfigurationSubRoutesEnum } from "../enums";

const moduleRouteUrl = `/${RootRouteEnum.SITE_CONFIGURATION}/${SiteConfigurationSubRoutesEnum.MODULE}`;

// subroutes

export enum ModuleSubRoutesEnum {
	CONFIGURE_PROJECT = 'configure_project',
	TICKETS = 'tickets',
	TIME = 'time',
	COMMUNICATION = 'communication',
	SCOPE = 'scope',
	TRAINING = 'training',
	FINANCE = 'finance',
	RISK = 'risk'
}

export const configureProjectSubRoute: Route = {
	id: ModuleSubRoutesEnum.CONFIGURE_PROJECT,
	url: `${moduleRouteUrl}/${ModuleSubRoutesEnum.CONFIGURE_PROJECT}`,
	text: 'Configure Projects',
	Icon: SettingsIcon
}

export const ticketsSubRoute: Route = {
	id: ModuleSubRoutesEnum.TICKETS,
	url: `${moduleRouteUrl}/${ModuleSubRoutesEnum.TICKETS}`,
	text: 'Configure Tickets',
	Icon: SettingsIcon
}

export const timeAndTravelSubRoute: Route = {
	id: ModuleSubRoutesEnum.TIME,
	url: `${moduleRouteUrl}/${ModuleSubRoutesEnum.TIME}`,
	text: 'Configure Time & Travel',
	Icon: SettingsIcon
}

export const communicationSubRoute: Route ={
	id: ModuleSubRoutesEnum.COMMUNICATION,
	url: `${moduleRouteUrl}/${ModuleSubRoutesEnum.COMMUNICATION}`,
	text: 'Configure Communication',
	Icon: SettingsIcon
}

export const scopeSubRoute: Route = {
	id: ModuleSubRoutesEnum.SCOPE,
	url: `${moduleRouteUrl}/${ModuleSubRoutesEnum.SCOPE}`,
	text: 'Configure Scope',
	Icon: SettingsIcon
}

export const financeSubRoute: Route = {
	id: ModuleSubRoutesEnum.FINANCE,
	url: `${moduleRouteUrl}/${ModuleSubRoutesEnum.FINANCE}`,
	text: 'Configure Finance',
	Icon: SettingsIcon
}

export const riskSubRoute: Route = {
	id: ModuleSubRoutesEnum.RISK,
	url: `${moduleRouteUrl}/${ModuleSubRoutesEnum.RISK}`,
	text: 'Configure Risk',
	Icon: SettingsIcon
}

// root route

export const moduleRoute: Route = {
	id: SiteConfigurationSubRoutesEnum.MODULE,
	url: moduleRouteUrl,
	text: 'Module',
	children: [
		configureProjectSubRoute,
		ticketsSubRoute,
		timeAndTravelSubRoute,
		communicationSubRoute,
		scopeSubRoute,
		financeSubRoute,
		riskSubRoute,
	]
}
