import { ProjectRiskClient, InsertProjectRiskRequest, UpdateProjectRiskRequest, GenericFilterModelCollection, ExportDataModel } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';
import { getUserTableViewConfigAction, updateUserTableViewConfigAction } from "features/UserTableViewConfig/action";

export const getRisksAction = (reportId: number, copyFromRisk: boolean) => {
	const tenantId = getTenantId()!;
	const client = new ProjectRiskClient();
	return client.getAll(tenantId, reportId, undefined, copyFromRisk, undefined, undefined);
}

export const getRisksGenericAction = (reportId: number, copyFromRisk: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectRiskClient();
	return client.getAllGenericFilter(tenantId, reportId, copyFromRisk, genericFilter);
}

export const getRisksGenericCountsAction = (reportId: number, copyFromRisk: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectRiskClient();
	return client.getAllGenericFilterCounts(tenantId, reportId, copyFromRisk, genericFilter);
}

export const createRiskAction = (reportId: number, model: InsertProjectRiskRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectRiskClient();
	return client.create(tenantId, reportId, model);
}

export const updateRiskAction = (reportId: number, model: UpdateProjectRiskRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectRiskClient();
	return client.update(tenantId, reportId, model);
}

export const getRiskAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectRiskClient();
	return client.get(tenantId, reportId, id);
}

export const deleteRiskAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectRiskClient();
	return client.delete(tenantId, reportId, id);
}

export const exportAction = (reportId: number, copyFromRisk: boolean, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectRiskClient();
	return client.export(tenantId, reportId, copyFromRisk, exportDataModel);
}

export const copyFromRiskAction = (reportId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectRiskClient();
	return client.copyFromRisk(tenantId, reportId);
}

// copy from risk config
const viewType = 'projectReportRisksCopyFromRisk';

export const getCopyFromRiskConfigAction = () => {
	return getUserTableViewConfigAction(viewType);
}

export const updateCopyFromRiskConfigAction = (content: {[key: number]: boolean}) => {
	return updateUserTableViewConfigAction(viewType, content);
}
