import { TabType } from 'components/Tabs/Tabs';
import { Users } from './Tabs/Users/Users';
import { Projects } from './Tabs/Projects/Projects';
import { Tickets } from './Tabs/Tickets/Tickets';
import { Reports } from './Tabs/Reports/Reports';
import { Risks } from './Tabs/Risks/Risks';
import { Invoices } from './Tabs/Invoices/Invoices';
import { ChangeRequest } from './Tabs/ChangeRequest/ChangeRequest';
import { Tasks } from './Tabs/Tasks/Tasks';
import { TimeAndTravel } from './Tabs/TimeAndTravel/TimeAndTravel';
import { MeetingMinutes } from './Tabs/MeetingMinutes/MeetingMinutes';
import { TestCases } from './Tabs/TestCases/TestCases';
import { TrainingStatus } from './Tabs/TrainingStatus/TrainingStatus';
import { Budget } from './Tabs/Budget/Budget';
import { Communication } from './Tabs/Communication/Communication';

export const statusesTabs: TabType[] = [
	{
		id: '1',
		title: 'Users',
		route: 'users',
		component: Users,
	},
	{
		id: '2',
		title: 'Projects',
		route: 'projects',
		component: Projects
	},
	{
		id: '3',
		title: 'Tickets',
		route: 'tickets',
		component: Tickets,
	},
	{
		id: '4',
		title: 'Time and Travel',
		route: 'time_and_travel',
		component: TimeAndTravel
	},
	{
		id: '5',
		title: 'Risks',
		route: 'risks',
		component: Risks
	},
	{
		id: '6',
		title: 'Tasks',
		route: 'tasks',
		component: Tasks
	},
	{
		id: '7',
		title: 'Meeting minutes',
		route: 'meeting_minutes',
		component: MeetingMinutes
	},
	{
		id: '8',
		title: 'Project Report',
		route: 'reports',
		component: Reports
	},
	{
		id: '9',
		title: 'Change Request',
		route: 'change_request',
		component: ChangeRequest
	},
	{
		id: '10',
		title: 'Test Cases',
		route: 'test_cases',
		component: TestCases
	},
	{
		id: '11',
		title: 'Training',
		route: 'training',
		component: TrainingStatus
	},
	{
		id: '12',
		title: 'Invoices',
		route: 'invoices',
		component: Invoices
	},
	{
		id: '13',
		title: 'Budget',
		route: 'budget',
		component: Budget
	},
	{
		id: '14',
		title: 'Communication',
		route: 'communication',
		component: Communication
	}
];
