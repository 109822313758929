import { CrudRouter } from 'features/Crud'
import { CreateSiteAdmin } from './Crud/CreateSiteAdmin'
import { SiteAdminsTable } from './SiteAdminsTable'

export const SiteAdmins = () => {
	return (
		<CrudRouter
			ListComponent={SiteAdminsTable}
			CreateComponent={CreateSiteAdmin}
			paramName=''
			isModal
		/>
	)
}
