import { HelpShell } from 'features/Help/HelpShell';

export const InvoiceStatusesHelp = () => {
	return (
		<HelpShell title='Invoice statuses'>
			<div>
				<p>Mavles Project Management Tool comes with 3 invoice statuses:</p>
				<ul>
					<li>Planned</li>
					<li>Issued/Received</li>
					<li>Payed</li>
				</ul>
			</div>
			<p>These statuses cannot be changed or deleted, but you can add explanation for each status.</p>
			<p>Invoices with status <b>planned</b> are invoices expected to be issued to customers or invoices to be
				received from vendors. These items can be shown in Finance module in budget.<br />

				When invoice is <b>issued to customer or received from vendor</b>, user can adjust invoice status.
				These items are shown in Finance module in reports showing actuals.<br />

				Likewise, user can change invoice status when invoices are payed. These items are also shown in Finance
				module in reports showing actuals.
			</p>
		</HelpShell>
	)
}
