import { HelpShell } from 'features/Help/HelpShell';

export const MyProfileHelp = () => {
	return (
		<HelpShell title='My profile'>
			<p>
				Here you can maintain your profile information and change your password. <br />
				User ID in Mavles is not changeable.
			</p>
			<p>
				Next to contact information, you can also define organizational unit to which user belongs to. This is applicable only for company users and not guest users. Please maintain organizational unit for users if you want managers to be able to see resource related reports. 
			</p>
		</HelpShell>
	)
}

