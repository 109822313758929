import path from 'path'
import { Notification } from "../Notification/Notification"
import { NotificationComponentProps } from "../NotificationsPanel";
import { RootRouteEnum } from 'features/Routes';
import { useFormattedIdUsernameAndTrigger } from './useFormattedIdUsernameAndTrigger';
import styles from '../notificationsPanel.module.scss'
import { EntityPrefixEnum } from 'utils/commonHelper';
import { AlertTriggersEnum } from 'services/tenantManagementService';
import { ExpenseApprovedNotificationAdditional, ExpenseRejectedNotificationAdditional, ExpenseSubmittedNotificationAdditional } from './types';
import { CrudEnum } from 'features/Crud';
import { useMemo } from 'react';
import { ApprovalTabSubroutes } from 'containers/TimeTravel/Approval/Tabs/ApprovalSubroutes';
import { TimeTravelSubRoutesEnum } from 'containers/TimeTravel/routes';

export const ExpenseNotification = ({ notification }: NotificationComponentProps) => {
	const Component = useMemo(
		() => {
			switch (notification.trigger) {
				case AlertTriggersEnum.Submitted:
					return ExpenseSubmittedNotification;
				case AlertTriggersEnum.Approved:
					return ExpenseApprovedNotification;
				case AlertTriggersEnum.Rejected:
					return ExpenseRejectedNotification;
				default:
					return null;
			}
		},
		[notification]
	)

	if (!Component) {
		return <></>
	}

	return (
		<Component notification={notification}/>
	)
}

const ExpenseSubmittedNotification = ({ notification }: NotificationComponentProps) => {
	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as ExpenseSubmittedNotificationAdditional : undefined

	const { formattedId, username, triggerText } = useFormattedIdUsernameAndTrigger(notification, EntityPrefixEnum.TIME_TRAVEL_EXPENSE)

	let subroute!: ApprovalTabSubroutes;
	switch (additionalData?.entityType) {
		case 'TravelRequest':
			subroute = ApprovalTabSubroutes.TRAVEL_REQUESTS;
			break;
		case 'TravelExpense':
			subroute = ApprovalTabSubroutes.TRAVEL_EXPENSES;
			break;
		case 'Expense':
			subroute = ApprovalTabSubroutes.EXPENSES
			break;
	}

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.TIME_TRAVEL,
				TimeTravelSubRoutesEnum.APPROVAL,
				subroute,
				CrudEnum.Read,
				String(notification.entityId))
			}
			content={
				<div>
					<span>Expense: </span>
					<span className={styles.entity}>{formattedId} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}
const ExpenseApprovedNotification = ({ notification }: NotificationComponentProps) => {
	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as ExpenseApprovedNotificationAdditional : undefined

	const { formattedId, username, triggerText } = useFormattedIdUsernameAndTrigger(notification, EntityPrefixEnum.TIME_TRAVEL_EXPENSE)

	let subroute!: TimeTravelSubRoutesEnum;
	switch (additionalData?.entityType) {
		case 'TravelRequest':
			subroute = TimeTravelSubRoutesEnum.MY_TRAVEL_REQUESTS;
			break;
		case 'TravelExpense':
			subroute = TimeTravelSubRoutesEnum.MY_TRAVEL_EXPENSES;
			break;
		case 'Expense':
			subroute = TimeTravelSubRoutesEnum.MY_EXPENSES;
			break;
	}

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.TIME_TRAVEL,
				subroute,
				CrudEnum.Read,
				String(notification.entityId)

			)}
			content={
				<div>
					<span>Expense: </span>
					<span className={styles.entity}>{formattedId} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}
const ExpenseRejectedNotification = ({ notification }: NotificationComponentProps) => {
	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as ExpenseRejectedNotificationAdditional : undefined

	const { formattedId, username, triggerText } = useFormattedIdUsernameAndTrigger(notification, EntityPrefixEnum.TIME_TRAVEL_EXPENSE)

	let subroute!: TimeTravelSubRoutesEnum;
	switch (additionalData?.entityType) {
		case 'TravelRequest':
			subroute = TimeTravelSubRoutesEnum.MY_TRAVEL_REQUESTS;
			break;
		case 'TravelExpense':
			subroute = TimeTravelSubRoutesEnum.MY_TRAVEL_EXPENSES;
			break;
		case 'Expense':
			subroute = TimeTravelSubRoutesEnum.MY_EXPENSES;
			break;
	}

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.TIME_TRAVEL,
				subroute,
				CrudEnum.Read,
				String(notification.entityId)
			)}
			content={
				<div>
					<span>Expense: </span>
					<span className={styles.entity}>{formattedId} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}
