import path from 'path';
import { useHistory } from 'react-router-dom';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import notifications from 'components/Notification/notification';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { ContentShell } from 'features/Content/ContentShell'
import { CrudSubRoutesEnum, ListComponentProps } from 'features/Crud';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { GenericFilterModelCollection, MeetingResponse, MeetingStatusEnum, ModuleActivityEnum } from 'services/tenantManagementService';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { cloneMeetingAction, deleteMeetingAction, exportMeetingsAction, getAllMeetingsGenericFilterAction, releaseMeetingAction, resetStatusMeetingAction } from './action';
import { TableButtons } from './Table/TableButtons';
import { useTableColumnsMemo } from './Table/tableColumns';
import { ProjectMeetingsHelp } from './Help/ProjectMeetingsHelp';
import { getStatusBySemantic, isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { persistProjectTeamsAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Teams/action';
import { WithProjectOrCategoryPicker, ProjectOrCategoryComponentProps } from 'features/Project';

type Props = ProjectOrCategoryComponentProps & ListComponentProps;

const configureViewKey = 'project_meetings_table';

const Meetings = ({ projectOrCategory, disabledEdit, dataChangedTopic, publishDataChanged }: Props) => {
	const { persistedMeetingStatus } = useSelector((state: RootState) => state);

	const [selectedMeeting, setSelectedMeeting] = useState(new MeetingResponse());
	const [releasing, setReleasing] = useState(false);
	const [resetting, setResetting] = useState(false);
	const [copying, setCopying] = useState(false);

	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const history = useHistory();

	const tableColumns = useTableColumnsMemo(configureViewKey);

	const memoFetchFunction = useMemo(
		() => getAllMeetingsGenericFilterAction.bind(null, projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected),
		[projectOrCategory]
	)

	const isRelease = useMemo(
		() => {
			const currentStatus = persistedMeetingStatus.itemsMap[selectedMeeting.statusId];
			const releasedStatus = getStatusBySemantic(MeetingStatusEnum.Released, persistedMeetingStatus.items);

			if (currentStatus && releasedStatus) {
				return currentStatus.nextStates?.includes(releasedStatus.id);
			}

			return false;
		},
		[persistedMeetingStatus, selectedMeeting.statusId]
	)

	const releaseCallback = useCallback(
		async () => {
			setReleasing(true);
			const bindedAction = releaseMeetingAction.bind(null, selectedMeeting.id, projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const formattedId = getFormatedId(EntityPrefixEnum.MEETING, selectedMeeting.id);
				notifications.success(`Meeting ${formattedId} is released.`);
				publishDataChanged();
			}
			setReleasing(false);
		},
		[projectOrCategory, selectedMeeting.id, publishDataChanged]
	)

	const resetStatusMeetingCallback = useCallback(
		async () => {
			setResetting(true);
			const bindedAction = resetStatusMeetingAction.bind(null, selectedMeeting.id, projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const formattedId = getFormatedId(EntityPrefixEnum.MEETING, selectedMeeting.id);
				notifications.success(`Meeting ${formattedId} is reset.`);
				publishDataChanged();
			}
			setResetting(false);
		},
		[projectOrCategory, selectedMeeting.id, publishDataChanged]
	)

	const deleteMeetingCallback = useCallback(
		async (id: number) => {
			const bindedAction = deleteMeetingAction.bind(null, id, projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const formattedId = getFormatedId(EntityPrefixEnum.MEETING, id);
				notifications.success(`Meeting ${formattedId} is successfully canceled.`);
				publishDataChanged();
			}
		},
		[projectOrCategory, publishDataChanged]
	)

	useEffect(
		() => {
			if (projectOrCategory.isProjectConnected) {
				persistProjectTeamsAction(projectOrCategory.projectOrCategoryId);
			}
		},
		[projectOrCategory]
	)

	const handleRowSelectionChange = useCallback(
		(data: MeetingResponse[]) => {
			setSelectedMeeting(data[0] || new MeetingResponse());
		},
		[]
	)

	const memoExportFunction = useMemo(
		() => exportMeetingsAction.bind(null, projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected),
		[projectOrCategory]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	const isDisabledMemo = useMemo(
		() => disabledEdit || isStatusBySemantic(MeetingStatusEnum.Released, selectedMeeting.statusId, persistedMeetingStatus.itemsMap),
		[selectedMeeting, persistedMeetingStatus, disabledEdit]
	);

	const copyCallback = useCallback(
		async () => {
			setCopying(true)
			const bindedAction = cloneMeetingAction.bind(null, selectedMeeting.id, projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			setCopying(false)

			if (response.success) {
				const formattedCopyId = getFormatedId(EntityPrefixEnum.MEETING, response.value?.id);
				const formattedCopiedId = getFormatedId(EntityPrefixEnum.MEETING, selectedMeeting.id);
				notifications.success(`Meeting ${formattedCopyId} is created as copy of ${formattedCopiedId}.`);
				publishDataChanged();
				history.push(path.join(history.location.pathname, CrudSubRoutesEnum.Update, String(response.value?.id)))
			}
		},
		[history, projectOrCategory, selectedMeeting, publishDataChanged]
	)

	return (
		<ContentShell
			title='Meetings'
			FloatingHelpComponent={ProjectMeetingsHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selected={selectedMeeting}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					onDelete={deleteMeetingCallback}
					copying={copying}
					onCopyClick={copyCallback}
					isRelease={isRelease}
					onReleaseClick={releaseCallback}
					releasing={releasing}
					onResetClick={resetStatusMeetingCallback}
					resetting={resetting}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
					disabled={isDisabledMemo}
					createDisabled={disabledEdit}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={dataChangedTopic}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectOrCategoryPicker(Meetings, ModuleActivityEnum.Communication, true);
