import { useMemo } from 'react';
import { ProjectDeliverableResponse } from 'services/tenantManagementService';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';

const defaultVisibleColumns = [
	propertyOf<ProjectDeliverableResponse>('wbs'),
	propertyOf<ProjectDeliverableResponse>('name'),
	propertyOf<ProjectDeliverableResponse>('finish'),
	propertyOf<ProjectDeliverableResponse>('complete'),
	propertyOf<ProjectDeliverableResponse>('achievedDate'),
	propertyOf<ProjectDeliverableResponse>('description')
]

export const useTableColumnsMemo = (visibleColumns: string[] = defaultVisibleColumns) => {
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectDeliverableResponse>('wbs')]: {
					title: 'WBS',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectDeliverableResponse>('name')]: {
					title: 'Task Name',
					formatter: (cell: any) => {
						const deliverablePlan: ProjectDeliverableResponse = cell.getData();
						const wbs = deliverablePlan.wbs;
						return (
							`<span style="padding-left:	${(wbs.split('.').length - 1) * 20}px">
								${deliverablePlan.name}
							</span>`
						);
					},
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectDeliverableResponse>('finish')]: {
					title: 'Finish',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectDeliverableResponse>('complete')]: {
					title: '% complete',
					formatter: (cell: any) => {
						const value = cell.getValue();
						return value ? `${value}%` : '';
					},
					fieldType: FieldTypeEnum.Number
				},
				[propertyOf<ProjectDeliverableResponse>('achievedDate')]: {
					title: 'Achieved date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectDeliverableResponse>('description')]: {
					title: 'Deliverable description',
					fieldType: FieldTypeEnum.String
				}
			}
		},
		[]
	)

	return useVisibleColumns(columns, visibleColumns);
}
