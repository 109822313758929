import { Dashboard } from 'features/Dashboard/Dashboard'
import { Layout } from 'features/Dashboard/Layout'
import { LocalTableWidget } from 'features/Dashboard/Widget/LocalTableWidget'
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget'
import { useMemo } from 'react'
import { ModuleActivityEnum, ProjectResponse } from 'services/tenantManagementService'
import { TabComponentProps } from '../../FilteredDashboard'
import { useTableColumnsMemo } from '../PartnersInvoices/tableColumns'
import { useTableColumnsMemo as useProjectHoursTableColumnsMemo } from './ProjectHours/tableColumns'
import { pmOrSpmOrOumPermission, useProjects } from 'features/Project'

export const TimesheetsDashboard = ({ loading, dashboard, filterModel }: TabComponentProps) => {
	const costsTableColumns = useTableColumnsMemo(dashboard.timesheets?.budgetVsActualTimesheets);
	const projectHoursTableColumns = useProjectHoursTableColumnsMemo(dashboard.timesheets?.projectHoursPerPeriod);

	const { projects } = useProjects({
		isProjectDashboard: true,
		moduleEnum: ModuleActivityEnum.Finance,
		userRolePermission: pmOrSpmOrOumPermission
	});
	let projectsByFilterModel = projects;
	if (filterModel.projectIds) {
		projectsByFilterModel = projects.filter((project) => filterModel.projectIds?.includes(project.id));
	}

	const hoursPerProjectMemo = useMemo(
		() => {
			const data = projectsByFilterModel.map((item: ProjectResponse) => {
				return {
					id: item.id,
					name: item.name,
					count: (dashboard.timesheets?.hoursPerProject && dashboard.timesheets?.hoursPerProject[item.id]) || 0
				}
			});

			return data;
		},
		[dashboard, projectsByFilterModel]
	)

	const columnSeriesFieldsMemo = useMemo(
		() => {
			return [{
				value: 'count',
				name: 'Hours'
			}]
		},
		[]
	)

	// we want pagination to work for years, so only one year is shown per page
	const tableMonthsLimit = filterModel.months?.length || 12;

  	return (
		<Dashboard orientation='vertical'>
			<RotatedColumnSeriesWidget
				className='col-lg-8'
				title='Actual project hours'
				id='travel_expenses_per_project_column_series_chart'
				data={hoursPerProjectMemo}
				fieldCategory='name'
				fieldValues={columnSeriesFieldsMemo}
				loading={loading}
				hideLegend
				showValueOnBar
			/>
			<Layout orientation='horizontal'>
				<LocalTableWidget
					className='col-lg-8'
					title='Timesheet costs per period - plan vs actual'
					columns={costsTableColumns}
					data={dashboard.timesheets?.budgetVsActualTimesheets || []}
					loading={loading}
					hasPagination
					limit={tableMonthsLimit}
				/>
				<LocalTableWidget
					className='col-lg-4'
					title='Project hours per period'
					columns={projectHoursTableColumns}
					data={dashboard.timesheets?.projectHoursPerPeriod || []}
					loading={loading}
					hasPagination
					limit={tableMonthsLimit}
				/>
			</Layout>
		</Dashboard>
  	)
}
