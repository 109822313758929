import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { Export } from 'features/Export/Export';
import { BaseColumnModel } from 'components/Table';

type Props = {
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

export const TableButtons = ({
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction
}: Props) => {
	return (
		<RowContainer justifyContent='flex-end'>
			<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
			<Export
				filtersModel={filtersModel}
				tableColumns={tableColumns}
				exportAction={exportFunction}
				exportTypeOptions={EXPORT_TYPE_OPTIONS}
				title='Decisions'
			/>
		</RowContainer>
	)
}
