import { useEffect, useCallback, useState, useMemo } from 'react'
import { EventInput } from '@fullcalendar/core'
import { CommunicationCalendarResponse, CommunicationCalendarResponseItemsResponseModel, ModuleActivityEnum } from 'services/tenantManagementService'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { WithProjectsOrCategoriesPicker, ProjectsOrCategoriesComponentProps } from 'features/Project';
import { getCalendarMeetingsAction } from './action';
import { Calendar, CalendarViewEnum } from 'components/Calendar';
import { getMeetingRoute } from 'utils/routeUtils';

export type DateRange = {
	startStr: string
	endStr: string
}

const ProjectMeetings = ({ projects, nonProjectCategories }: ProjectsOrCategoriesComponentProps) => {
	const [calendar, setCalendar] = useState<CommunicationCalendarResponse[]>([]);
	const [isFetching, setIsFetching] = useState(true);
	const [dateRange, setDateRange] = useState<DateRange>();

	const fetchDataCallback = useCallback(
		async () => {
			if (!dateRange) {
				return;
			}

			const projectIds = projects.map((item) => item.id);
			const categoryIds = nonProjectCategories.map((item) => item.id);

			setIsFetching(true);
			const bindedAction = getCalendarMeetingsAction.bind(null, projectIds, categoryIds, new Date(dateRange.startStr), new Date(dateRange.endStr));
			const response: CommunicationCalendarResponseItemsResponseModel = await tryCatchJsonByAction(bindedAction);
			setIsFetching(false);
			if (response.success) {
				setCalendar(response.items || []);
			}
		},
		[projects, nonProjectCategories, dateRange]
	)

	useEffect(
		() => {
			fetchDataCallback()
		},
		[fetchDataCallback]
	)

	const events: EventInput[] = useMemo(
		() => {
			return calendar.map(
				(item) => {
					let event: EventInput = {
						id: item.id.toString(),
						title: item.description,
						start: item.from,
						end: item.to,
						url: getMeetingRoute(item.id, item.projectOrCategoryId, item.isProjectConnected!),
						display: 'list-item',
						backgroundColor: 'transparent',
						borderColor: '#03dac6',
						textColor: '#03dac6',
						editable: false
					}

					return event;
				}
			)
		},
		[calendar]
	)

	const setDateRangeCallback = useCallback(
		(newFrom: Date, newTo: Date, newFromStr: string, newToStr: string) => {
			setDateRange({
				startStr: newFromStr,
				endStr: newToStr
			})
		},
		[setDateRange]
	)

	return (
		<Calendar
			events={events}
			setDateRange={setDateRangeCallback}
			loading={isFetching}
			clickableDaysAndWeeks
			initialView={CalendarViewEnum.timeWeekly}
			views={[CalendarViewEnum.timeDaily, CalendarViewEnum.timeWeekly, CalendarViewEnum.dayMonthly]}
		/>
	)
}

export default WithProjectsOrCategoriesPicker(ProjectMeetings, ModuleActivityEnum.Communication, true);
