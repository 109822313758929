import { gantt } from './GanttChart'

export const addGanttConfigLayout = (showResourcesUtilization?: boolean) => {
	gantt.config.layout.rows.push({
		id: showResourcesUtilization ? 'gantt_row_hidden' : undefined,
		cols: [
			{
				width: showResourcesUtilization ? 340 : 440,
				min_width: 300,
				group: 'grids',
				rows:[
					{ view: 'grid', scrollX: 'gridScroll', scrollable: true, scrollY: 'scrollVer' },
					{ view: 'scrollbar', id: 'gridScroll' }
				]
			},
			{ resizer: true, width: 1, group:'vertical' },
			{
				rows:[
					{ view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer', group:'vertical' },
					{ view: 'scrollbar', id: 'scrollHor', group:'vertical' }
				]
			},
			{ view: 'scrollbar', id: 'scrollVer' }
		]
	})
}
