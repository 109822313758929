import { CrudRouter } from "features/Crud"
import { CreateManagerAuthorization } from "./Crud/CreateManagerAuthorization"
import { ManagerAuthorizationsTable } from "./ManagerAuthorizationsTable"
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { CompanyTabSubroutes } from "../companyTabs";
import { UpdateManagerAuthorization } from "./Crud/UpdateManagerAuthorization";

export const ManagerAuthorizationParamName = 'managerAuthorizationId';

export const ManagerAuthorizationsRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${CompanyTabSubroutes.ManagerAuthorizations}`}
				render={() => (
					<CrudRouter
						ListComponent={ManagerAuthorizationsTable}
						CreateComponent={CreateManagerAuthorization}
						UpdateComponent={UpdateManagerAuthorization}
						paramName={ManagerAuthorizationParamName}
						isModal
					/>
				)}
			/>
		</Switch>
	)
}
