import { getTenantId } from 'features/Tenant/tenantUtils';
import { CommunicationConfigClient, UpdateStatusReportGeneralConfigRequest } from 'services/tenantManagementService';

export const getStatusReportGeneralConfigAction = () => {
	const tenantId = getTenantId()!;
	const client = new CommunicationConfigClient();
	return client.getStatusReportGeneralConfig(tenantId);
}

export const updateStatusReportGeneralConfigAction = (model: UpdateStatusReportGeneralConfigRequest ) => {
	const tenantId = getTenantId()!;
	const client = new CommunicationConfigClient();
	return client.updateStatusReportGeneralConfig(tenantId, model);
}
