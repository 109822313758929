import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { CrudButtons } from 'features/Crud';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { Export } from 'features/Export/Export';
import { ExportModel } from 'features/Export/ExportForm';
import { BaseColumnModel } from 'components/Table';

type Props = {
	selectedId?: number
	onDelete(id: number): Promise<void>
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel, exportModel?: ExportModel): Promise<FileResponse>
	disabled?: boolean
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

export const TableButtons = ({
	selectedId,
	onDelete,
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction,
	disabled
}: Props) => {
	return (
		<>
			<RowContainer justifyContent='space-between'>
				<CrudButtons
					selectedId={selectedId}
					onDelete={onDelete}
					specificActionDisabled={{
						create: disabled,
						change: disabled,
						delete: disabled
					}}
				/>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
					<Export
						filtersModel={filtersModel}
						tableColumns={tableColumns}
						exportAction={exportFunction}
						exportTypeOptions={EXPORT_TYPE_OPTIONS}
						title='Invoices'
					/>
				</RowContainer>
			</RowContainer>
		</>
	)
}
