import { useCallback } from 'react';
import { useHistory } from 'react-router';
import path from 'path';
import { WithProjectPicker, pmOrSpmPermission } from 'features/Project';
import { ModuleActivityEnum, ProjectResponse, ScopePermission } from 'services/tenantManagementService';
import Button from 'components/Button';
import { RootRouteEnum } from 'features/Routes';
import { CrudEnum } from 'features/Crud';
import { ChangeRequestSubroutes } from '../ChangeRequests/Crud/Tabs/ChangeRequestSubroutes';
import { ScopeSubRoutesEnum } from '../routes';
import { propertyOf } from 'utils/propertyOf';

type Props = {
	project: ProjectResponse
}

const InitiateChangeRequest = ({ project }: Props) => {

	const history = useHistory();

	const initiateCallback = useCallback(
		() => {
			history.push(path.join('/',RootRouteEnum.SCOPE,  ScopeSubRoutesEnum.CHANGE_REQUESTS, project.id.toString(), CrudEnum.Create, ChangeRequestSubroutes.PROBLEM_REPORT));
		},
		[history, project.id]
	)

	return <Button onClick={initiateCallback} text='Initiate' />
}

export default WithProjectPicker(InitiateChangeRequest, ModuleActivityEnum.Scope, true, propertyOf<ScopePermission>('initiateChangeRequest'), pmOrSpmPermission);
