import { useCallback } from "react"
import { CheckIcon } from "components/icons/icons"
import styles from './buttonGroup.module.scss'

export type ItemType = {
	id: any
	text: string
}

type Props = {
	item: ItemType
	isSelected: boolean
	onClick(id: any): void
	color?: 'primary' | 'neutral'
}

export const Item = ({ item, isSelected, onClick, color }: Props) => {
	const onClickCallback = useCallback(
		() => {
			onClick(item.id)
		},
		[onClick, item]
	)

	return (
		<div className={`${styles.item} ${styles[`item_${color}`]} ${isSelected ? styles.selected : ''}`} onClick={onClickCallback}>
			<div>{item.text}</div>
			<CheckIcon className={styles.icon} width={20} height={20} fill='currentColor' />
		</div>
	)
}
