import { CrudEnum, CrudSubRoutesEnum } from "features/Crud";
import { Route } from "./reducer";

type RouteObject = {
    [key: string]: Route
}

const crudRoutes: Route[] = [
	{
		id: CrudEnum.Create,
		url: CrudSubRoutesEnum.Create,
		text: 'Create'
	}, {
		id: CrudEnum.Read,
		url: CrudSubRoutesEnum.Read,
		text: 'View'
	}, {
		id: CrudEnum.Update,
		url: CrudSubRoutesEnum.Update,
		text: 'Update'
	}
];

export const getRoutesByPathname = (rootRoutes: Route[], pathname: string) => {
	const currentRoutes: Route[] = [];

	const pathnameParts = pathname.split('/');
	pathnameParts.shift();	// first item will be '', so remove it
	let routes = rootRoutes;
	let currentUrl = '';

	let isFound = false;
	for (const part of pathnameParts) {
		currentUrl += `/${part}`

		// if there is a number in pathnameParts, we append it to the previous route (for example :projectId, or Update/:someId, etc.)
		if (!isNaN(parseInt(part)) && currentRoutes.length) {
			currentRoutes[currentRoutes.length - 1].url += `/${part}`;
		}

		for (const route of routes) {
			if (route.url === currentUrl) {
				currentRoutes.push({ ...route });
				routes = route.children || [];
				isFound = true;
				break;
			}
		}

		if (isFound) {
			isFound = false;
			continue;
		}

		for (const route of crudRoutes) {
			if (part.startsWith(route.url)) {
				// INFO: for Create/Update there is ID, append it to route.text += ` ${id}`. The problem is that we don't know the EntityPrefixEnum, so
				// maybe we can use getFormatedId when appending ID in route, and getUnFormatedId when using params from route
				currentRoutes.push({ ...route });
				routes = [];
				break;
			}
		}
	}

	return currentRoutes;
}

export const getRouteObject = (rootRoutes: Route[] = []) => {
    const routesObject: RouteObject = {};

	for (const route of rootRoutes) {
		routesObject[route.id] = route;
    }

    return routesObject;
}
