import { TabType } from 'components/Tabs/Tabs';
import { CalendarTabsSubroutes } from './CalendarTabsSubroutes';
import MyCalendar from './MyCalendar/MyCalendar';
import ProjectMeetings from './ProjectMeetings/ProjectMeetings';
import TeamVacations from './TeamVacations/TeamVacations';

export const calendarTabs: TabType[] = [
	{
		id: '1',
		title: 'My calendar',
		component: MyCalendar,
		route: CalendarTabsSubroutes.MY_CALENDAR
	},
	{
		id: '2',
		title: 'Project Meetings',
		component: ProjectMeetings,
		route: CalendarTabsSubroutes.PROJECT_MEETINGS
	},
	{
		id: '3',
		title: 'Team Vacations',
		component: TeamVacations,
		route: CalendarTabsSubroutes.TEAM_VACATIONS
	}
];
