import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable'
import { getMeetingTypesAction, updateMeetingTypesAction } from './action'

export const MeetingType = () => {
	return (
		<TenantIsActiveTable
			getConfigsAction={getMeetingTypesAction}
			updateConfigsAction={updateMeetingTypesAction}
			successMessage='Meeting types are updated.'
			label={{ name: 'Type name' }}
		/>
	)
}

