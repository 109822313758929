import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterProps, getRouteObject } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import TestCycles from './TestCycles/TestCycles';
import TestPlans from './TestPlans/TestPlans';
import TestStatus from './TestStatus/TestStatus';
import { persistTestCaseStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/TestCases/action';
import { persistTicketStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Tickets/action';
import { TestingDashboard } from './MyDashboard';
import { CrudRouter } from 'features/Crud';
import MyTestPackages from './MyTestPackages/MyTestPackages';
import ReadMyTest from './MyTestPackages/Crud/ReadMyTest';
import UpdateMyTest from './MyTestPackages/Crud/UpdateMyTest';
import { ProjectParamName } from 'features/Project';
import { TestCycleParamName } from 'features/Testing/WithTestCyclePicker';
import { TestingSubRoutesEnum, myDashboardSubRoute, myTestPackagesSubRoute, testCyclesSubRoute, testPlansSubRoute, testStatusSubRoute } from './routes';

export const TestingRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistTestCaseStatusesAction();
			persistTicketStatusesAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[TestingSubRoutesEnum.MY_DASHBOARD] &&
				<Route
					path={myDashboardSubRoute.url}
					render={() => <TestingDashboard />}
				/>
			}
			{routesMap[TestingSubRoutesEnum.MY_TEST_PACKAGES] &&
				<Route
					// order of paths is important
					path={[
						`${myTestPackagesSubRoute.url}/:${ProjectParamName}/:${TestCycleParamName}`,
						`${myTestPackagesSubRoute.url}/:${ProjectParamName}`,
						myTestPackagesSubRoute.url
					]}
					render={() => (
						<CrudRouter
							ListComponent={MyTestPackages}
							ReadComponent={ReadMyTest}
							UpdateComponent={UpdateMyTest}
							paramName='testPlanId'
						/>
					)}
				/>
			}

			{routesMap[TestingSubRoutesEnum.TEST_CYCLES] &&
				<Route
					// order of paths is important
					path={[
						`${testCyclesSubRoute.url}/:${ProjectParamName}`,
						testCyclesSubRoute.url
					]}
					render={() => <TestCycles />}
				/>
			}

			{routesMap[TestingSubRoutesEnum.TEST_PLANS] &&
				<Route
					// order of paths is important
					path={[
						`${testPlansSubRoute}/:${ProjectParamName}/:${TestCycleParamName}`,
						`${testPlansSubRoute}/:${ProjectParamName}`,
						testPlansSubRoute.url
					]}
					render={() => <TestPlans />}
				/>
			}

			{routesMap[TestingSubRoutesEnum.TEST_STATUS] &&
				<Route
					// order of paths is important
					path={[
						`${testStatusSubRoute.url}/:${ProjectParamName}/:${TestCycleParamName}`,
						`${testStatusSubRoute.url}/:${ProjectParamName}`,
						testStatusSubRoute.url
					]}
					render={() => (
						<CrudRouter
							ListComponent={TestStatus}
							ReadComponent={ReadMyTest}
							paramName='testPlanId'
						/>
					)}
				/>
			}

			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
