import { useCallback, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ScopeItemLevelResponse } from 'services/tenantManagementService';
import { Form, InputField } from 'components/Form';
import { propertyOf } from 'utils/propertyOf';
import { useUniqueNameValidatorCallback } from './useUniqueNameValidatorCallback';

type Props = {
	item: ScopeItemLevelResponse
	items: ScopeItemLevelResponse[]
	onSave(item: ScopeItemLevelResponse): void
	onCancel(): void
}

export const Level1andLevel2Form = ({ item, items, onSave, onCancel }: Props) => {
	const [values, setValues] = useState(item || new ScopeItemLevelResponse());

	const uniqueNameValidatorCallback = useUniqueNameValidatorCallback(items, item.name);

	const onSubmitCallback = useCallback(
		async () => await onSave(values),
		[onSave, values]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={onCancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<InputField
							id={propertyOf<ScopeItemLevelResponse>('name')}
							label='ID'
							isRequired
							maxLength={3}
							validator={uniqueNameValidatorCallback}
						/>
						<InputField
							id={propertyOf<ScopeItemLevelResponse>('description')}
							label='Name'
							isRequired
							maxLength={values.levelNumber === 1 ? 40 : 80}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
