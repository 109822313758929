import { Route } from "./reducer";
import { getIsUserProjectManager, getIsUserManagerAtAnyOrganizationalUnit, getUserInfo, getIsUserResourceManager } from 'utils/storageUtils';
import { TokenTypeEnum } from "services/tenantManagementService";
import { myProjectSubRoute, projectRoute } from "containers/Projects/routes";
import { ticketsRoute, viewTicketsSubRoute } from "containers/Tickets/routes";
import { scopeRoute } from "containers/Scope/routes";
import { testingRoute } from "containers/Testing/routes";
import { trainingRoute } from "containers/Training/routes";
import { timeTravelRoute } from "containers/TimeTravel/routes";
import { myDashboardSubRoute, riskListSubRoute, riskRoute } from "containers/Risk/routes";
import { financeRoute } from "containers/Finance/routes";
import { communicationRoute } from "containers/Communication/routes";
import { scheduleRoute, scheduleSubRoute } from "containers/Schedule/routes";
import { myConfigurationRoute } from "containers/Configuration/MyConfiguration/routes";
import { siteConfigurationRoute } from "containers/Configuration/SiteConfiguration/routes";

const allMenuRoutes: Route[] = [
	projectRoute,
	ticketsRoute,
	scopeRoute,
	testingRoute,
	trainingRoute,
	timeTravelRoute,
	riskRoute,
	financeRoute,
	communicationRoute,
	scheduleRoute
]

const allHeaderRoutes: Route[] = [
	siteConfigurationRoute,
	myConfigurationRoute
]

export const getRoutesForUserRole = (roleId: TokenTypeEnum, isUserPm: boolean, isOrganizationalUnitManager: boolean, isUserResourceManager: boolean) => {
	if (roleId === TokenTypeEnum.SiteAdmin) {
		return {
			menuRoutes: allMenuRoutes,
			headerRoutes: allHeaderRoutes
		}
	}

	if (isUserPm) {
		return {
			menuRoutes: allMenuRoutes,
			headerRoutes: [
				{
					...siteConfigurationRoute,
					children: [
						siteConfigurationRoute.children![1]
					]
				},
				myConfigurationRoute
			]
		}
	}

	if (isOrganizationalUnitManager) {
		// if user is resource manager, he see Approval and can do the approve
		const timeTravelChildrens = isUserResourceManager ? timeTravelRoute.children : [
			timeTravelRoute.children![0],
			timeTravelRoute.children![1],
			timeTravelRoute.children![2],
			timeTravelRoute.children![3],
			timeTravelRoute.children![4],
			timeTravelRoute.children![5],
			timeTravelRoute.children![6],
			// timeTravelRoute.children![7],
		]

		const menuRoutes: Route[] = [
			{
				...projectRoute,
				children: [myProjectSubRoute]
			},
			ticketsRoute,
			scopeRoute,
			testingRoute,
			trainingRoute,
			{
				...timeTravelRoute,
				children: timeTravelChildrens
			},
			{
				...riskRoute,
				children: [myDashboardSubRoute, riskListSubRoute]
			},
			{
				...financeRoute,
				children: [financeRoute.children![0]]
			},
			communicationRoute,
			scheduleRoute
		]

		return {
			menuRoutes,
			headerRoutes: [myConfigurationRoute]
		}
	}

	if (roleId === TokenTypeEnum.Guest || roleId === TokenTypeEnum.User) {
		const menuRoutes: Route[] = [
			{
				...projectRoute,
				children: [myProjectSubRoute]
			},
			{
				...ticketsRoute,
				children: [viewTicketsSubRoute]
			},
			scopeRoute,
			testingRoute,
			trainingRoute,
			{
				...timeTravelRoute,
				children: [
					timeTravelRoute.children![0],
					// timeTravelRoute.children![1],
					timeTravelRoute.children![2],
					timeTravelRoute.children![3],
					timeTravelRoute.children![4],
					timeTravelRoute.children![5],
					timeTravelRoute.children![6],
					// timeTravelRoute.children![7],
				]
			},
			{
				...riskRoute,
				children: [riskListSubRoute]
			},
			{
				...communicationRoute,
				children: [
					// communicationRoute.children![0],
					communicationRoute.children![1],
					communicationRoute.children![2],
					communicationRoute.children![3],
					communicationRoute.children![4],
					communicationRoute.children![5],
					communicationRoute.children![6],
					communicationRoute.children![7],
				]
			},
			{
				...scheduleRoute,
				children: [scheduleSubRoute]
			}
		]

		return {
			menuRoutes,
			headerRoutes: [myConfigurationRoute]
		}
	}

	return {};
}

export const getRoutesForCurrentUser = () => {
	const userInfo = getUserInfo();
	const isUserPm = getIsUserProjectManager();
	const isOrganizationalUnitManager = getIsUserManagerAtAnyOrganizationalUnit();
	const isUserResourceManager = getIsUserResourceManager();

	return getRoutesForUserRole(userInfo?.roleId, isUserPm, isOrganizationalUnitManager, isUserResourceManager);
}
