import { useHistory } from 'react-router-dom';
import { DeleteIcon } from 'components/icons/icons';
import Spinner, { ClipSpinner } from 'components/Spinner';
import { useCallback, useState } from 'react';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { deleteAlertAction } from '../../action';
import styles from '../notificationsPanel.module.scss'

type Props = {
	id: number
	route?: string
	content: any
}

export const Notification = ({ id, route = '', content }: Props) => {
	const history = useHistory()

	const [deleting, setDeleting] = useState(false);

	const onOpenCallback = useCallback(
		() => {
			history.push(route)
		},
		[route, history]
	)

	const onDeleteCallback = useCallback(
		async () => {
			setDeleting(true)
			const bindedAction = deleteAlertAction.bind(null, id)
			const response = await tryCatchJsonByAction(bindedAction)
			if (response.success) {
				// notifications.success('Notification is deleted.')
			}
			setDeleting(false)
		},
		[id]
	)

	return (
		<div className={styles.item}>
			<div className={styles.item_delete}>
				<DeleteIcon width={16} height={16} fill='currentColor' onClick={onDeleteCallback} />
			</div>
			<div className={styles.item_content} onClick={onOpenCallback}>
				{content}
			</div>
			{deleting && (
				<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
					<Spinner>
						<ClipSpinner size={24} />
					</Spinner>
				</div>
			)}
		</div>
	)
}
