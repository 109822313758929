import Tabs from 'components/Tabs/Tabs';
import { ContentShell } from 'features/Content/ContentShell';
import { companyTabs } from './companyTabs';
import { CompanyHelp } from './CompanyHelp';

export const Company = () => {
	return (
		<ContentShell 
			title='Company'
			FloatingHelpComponent={CompanyHelp}
		>
			<Tabs tabs={companyTabs} />
		</ContentShell>
	)
}
