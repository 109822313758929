import { HelpShell } from 'features/Help/HelpShell';

export const TrainingStatusesHelp = () => {
	return (
		<HelpShell title='Training statuses'>
			<div>
				<p>Mavles Project Management Tool comes with 3 statuses:</p>
				<ul>
					<li>Not started</li>
					<li>In progress</li>
					<li>Completed</li>
				</ul>
			</div>
			<p>These statuses cannot be changed or deleted, but you can add explanation for each status.</p>
		</HelpShell>
	)
}
