import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { CrudSubRoutesEnum } from 'features/Crud';
import { Export } from 'features/Export/Export';
import { ChangeRequestStatusEnum, ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { useHistory } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { ChangeRequestSubroutes } from '../Crud/Tabs/ChangeRequestSubroutes';
import path from 'path';
import Button from 'components/Button';
import { SimpleDialog } from 'components/Dialog';
import { BaseColumnModel } from 'components/Table';

type Props = {
	selectedId: number
	onDelete: (id: number) => Promise<void>
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	isEditable?: boolean
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
	disableDelete: boolean
	disabled?: boolean
	status?: ChangeRequestStatusEnum
	historyState?: string
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

const TableButtons = ({
	selectedId,
	onDelete,
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction,
	isEditable,
	disableDelete,
	disabled,
	status,
	historyState,
}: Props) => {
	let history = useHistory();

	const [deleting, setDeleting] = useState(false);

	const onCreateHandler = useCallback(
		() => history.push(path.join(history.location.pathname, `${CrudSubRoutesEnum.Create}`), historyState),
		[history, historyState]
	)

	const onViewChangeHandler = useCallback(
		(paramValue: CrudSubRoutesEnum) => {
			if (status === ChangeRequestStatusEnum.ApprovedForRealization) {
				history.push(path.join(history.location.pathname, `${paramValue}/${selectedId}/${ChangeRequestSubroutes.REALIZATION}`), historyState)
			} else if (status === ChangeRequestStatusEnum.Completed || status === ChangeRequestStatusEnum.ReleasedForAcceptance) {
				history.push(path.join(history.location.pathname, `${paramValue}/${selectedId}/${ChangeRequestSubroutes.ACCEPTANCE}`), historyState)
			} else if (status === ChangeRequestStatusEnum.Created) {
				history.push(path.join(history.location.pathname, `${paramValue}/${selectedId}/${ChangeRequestSubroutes.PROBLEM_REPORT}`), historyState)
			} else if (status === ChangeRequestStatusEnum.Initiated || status === ChangeRequestStatusEnum.RejectedAnalysis) {
				history.push(path.join(history.location.pathname, `${paramValue}/${selectedId}/${ChangeRequestSubroutes.ANALYSIS}`), historyState)
			} else if (status === ChangeRequestStatusEnum.RejectedForRealization || status === ChangeRequestStatusEnum.ReleasedForApproval) {
				history.push(path.join(history.location.pathname, `${paramValue}/${selectedId}/${ChangeRequestSubroutes.APPROVAL}`), historyState)
			}
		},
		[history, selectedId, historyState, status]
	)

	const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

	const openConfirmDeleteModal = useCallback(
		() => setIsConfirmDeleteModalOpen(true),
		[]
	);

	const closeConfirmDeleteModal = useCallback(
		() => setIsConfirmDeleteModalOpen(false),
		[]
	)

	const onDeleteConfirmed = useCallback(
		async () => {
			setDeleting(true);
			closeConfirmDeleteModal();
			if (selectedId) {
				onDelete && await onDelete(selectedId);
			}

			setDeleting(false);
		},
		[closeConfirmDeleteModal, selectedId, onDelete]
	);

	return (
		<div>
			<SimpleDialog
				open={isConfirmDeleteModalOpen}
				title='Delete'
				message='Are you sure that you want to delete selected element?'
				onConfirm={onDeleteConfirmed}
				onCancel={closeConfirmDeleteModal}
			/>
			<RowContainer justifyContent='space-between'>
				<RowContainer>
					<Button
						text='Create'
						onClick={onCreateHandler}
						disabled={disabled}
					/>
					<Button
						text='View'
						onClick={() => onViewChangeHandler(CrudSubRoutesEnum.Read)}
						disabled={!selectedId}
					/>
					<Button
						text='Change'
						onClick={() => onViewChangeHandler(CrudSubRoutesEnum.Update)}
						disabled={!selectedId || !isEditable || disabled}
					/>
					<Button
						text='Delete'
						color='destructive'
						disabled={disableDelete || disabled}
						onClick={openConfirmDeleteModal}
						isLoading={deleting}
					/>
				</RowContainer>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
					<Export
						filtersModel={filtersModel}
						tableColumns={tableColumns}
						exportAction={exportFunction}
						exportTypeOptions={EXPORT_TYPE_OPTIONS}
						title='Change Requests'
					/>
				</RowContainer>
			</RowContainer>
		</div>
	)
}

export default TableButtons;
