export const generateId = () => {
	return [
		(new Date()).getTime().toString(36),
		Math.random().toString(36).substring(4),
		Math.random().toString(36).substring(2),
	].join('-')
}

const getRandomInt = (max: number) => {
	return Math.floor(Math.random() * Math.floor(max));
}

export const generateNumberId = () => {
	const id = [
		(new Date()).getTime(),
		getRandomInt(1000000),
		getRandomInt(1000000)
	].join('');

	return parseInt(id);
}
