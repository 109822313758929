import { useCallback, useState, useMemo } from 'react'
import styles from './mySurveysCrud.module.scss'
import { ProjectPickerParams, getProjectAction } from 'features/Project';
import { useParams } from 'react-router';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { InsertSurveyParticipantAnswerRequest, MySurveyQuestionResponse, MySurveyResponse, ProjectResponse, SubmitMySurveyRequest, SurveyQuestionTypeEnum, TrainingStatusEnum } from 'services/tenantManagementService';
import { ColumnContainer, VerticalSeparator } from 'components/Layout';
import WithFetch from 'features/Fetch/WithFetch';
import { SmartFormGroup } from 'components/SmartContainer/SmartContainer';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import TakeSurveyForm from './TakeSurveyForm';
import { getMySurveyAction, getMySurveyQuestionsAction, submitMySurveyAction } from '../action';
import notifications from 'components/Notification/notification';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import ViewSurveyQuestions from './ViewSurveyQuestions';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = ProjectPickerParams & {
	surveyId: string
}

const TakeSurvey = () => {
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId as string);
	const surveyId = parseInt(params.surveyId);

	const [project, setProject] = useState(new ProjectResponse());
	const [survey, setSurvey] = useState(new MySurveyResponse());
	const [surveyQuestions, setSurveyQuestions] = useState<MySurveyQuestionResponse[]>([]);
	const { persistedTrainingStatus } = useSelector((state: RootState) => state);

	const surveyQuestionsMap: {[key: string]: MySurveyQuestionResponse} = useMemo(
		() => surveyQuestions.reduce((acc, cv) => ({ ...acc, [cv.id.toString()]: cv }), {}),
		[surveyQuestions],
	)

	const changeDisabledMemo = useMemo(
		() => {
			if (!survey) {
				return true;
			}

			const status = persistedTrainingStatus.itemsMap[survey.statusId];
			return status?.semantics === TrainingStatusEnum.Completed
		},
		[persistedTrainingStatus.itemsMap, survey]
	)

	const goBackFromCrud = useGoBackFromCrud(CrudEnum.Update);

	const fetchSurveyQuestionsCallback = useCallback(
		async () => {
			const bindedAction = getMySurveyQuestionsAction.bind(null, projectId, surveyId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.items) {
				setSurveyQuestions(response.items);
			}
		},
		[surveyId, projectId]
	)

	const fetchSurveyCallback = useCallback(
		async () => {
			const bindedAction = getMySurveyAction.bind(null, projectId, surveyId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				const surveyResponse = response.value
				setSurvey(surveyResponse);
			}
		},
		[surveyId, projectId]
	)

	const fetchProjectCallback = useCallback(
		async () => {
			const bindedAction = getProjectAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				const projectResponse = response.value
				setProject(projectResponse);
			}
		},
		[projectId]
	)

	const fetchDataCallback = useCallback(
		async () => {
			await Promise.all([fetchProjectCallback(), fetchSurveyCallback(), fetchSurveyQuestionsCallback()])
		},
		[fetchProjectCallback, fetchSurveyCallback, fetchSurveyQuestionsCallback]
	)

	const saveCallback = useCallback(
		async (answersRequest: { [key: string]: any }) => {
			const submitRequestAnswers: { [key: string]: InsertSurveyParticipantAnswerRequest[] } = Object.keys(answersRequest).reduce((acc, questionId) => {
				const question = surveyQuestionsMap[questionId];
				if (!question) {
					return acc;
				}

				const insertAnswerRequests: InsertSurveyParticipantAnswerRequest[] = [];
				const answer = answersRequest[questionId];
				switch (question.surveyType) {
					case SurveyQuestionTypeEnum.Answer: {
						insertAnswerRequests.push(new InsertSurveyParticipantAnswerRequest({ answer }));
						break;
					}
					case SurveyQuestionTypeEnum.Rating: {
						insertAnswerRequests.push(new InsertSurveyParticipantAnswerRequest({ rating: answer }));
						break;
					}
					case SurveyQuestionTypeEnum.SingleChoice: {
						insertAnswerRequests.push(new InsertSurveyParticipantAnswerRequest({ proposedAnswerId: answer }));
						break;
					}
					case SurveyQuestionTypeEnum.MultipleChoice: {
						if (answer instanceof Array) {
							const multipleChoiceAnswers: InsertSurveyParticipantAnswerRequest[] = []
							answer.forEach(x => {
								multipleChoiceAnswers.push(new InsertSurveyParticipantAnswerRequest({ proposedAnswerId: x }))
							})
							insertAnswerRequests.push(
								...multipleChoiceAnswers,
							);
						}
						break;
					}
					default:
						break;
				}

				return insertAnswerRequests.length === 0 ? acc : { ...acc, [questionId]: insertAnswerRequests }
			}, {})

			const submitMySurveyRequest = new SubmitMySurveyRequest({ answers: submitRequestAnswers });
			const bindedAction = submitMySurveyAction.bind(null, project.id, survey.id, submitMySurveyRequest);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Survey submitted.');
				goBackFromCrud();
			}
			else {
				return response.errors;
			}
		},
		[goBackFromCrud, project.id, survey.id, surveyQuestionsMap]
	)

	return (
		<ContentShell title='Take survey'>
			<WithFetch fetchFunction={fetchDataCallback}>
				{!!project && !!survey &&
					<ColumnContainer>
						<>
							<SmartFormGroup label={'Project'}>
								<div className={styles.form_group_field}>
									<p className={styles.form_group_field_value}>
										{project?.id ? `${getFormatedId(EntityPrefixEnum.PROJECT, project.id)} - ${project.name}` : ''}
									</p>
								</div>
							</SmartFormGroup>
							<SmartFormGroup label={`${changeDisabledMemo ? 'Viewing' : 'Taking'} ${survey.surveyType}`}>
								<div className={styles.form_group_field}>
									<p className={styles.form_group_field_value_bold}>
										{`${getFormatedId(EntityPrefixEnum.SURVEY, survey.id)} - ${survey.name}`}
									</p>
								</div>
							</SmartFormGroup>
							{!!survey.description &&
								<>
									<VerticalSeparator />
									<p>{survey.description}</p>
								</>
							}
						</>

						{changeDisabledMemo ? (
								<ViewSurveyQuestions
									surveyQuestions={surveyQuestions}
								/>
							) : (
								<TakeSurveyForm
									surveyQuestions={surveyQuestions}
									save={saveCallback}
									cancel={goBackFromCrud}
								/>
							)
						}
					</ColumnContainer>
				}
			</WithFetch>
		</ContentShell>
	);
}

export default TakeSurvey;
