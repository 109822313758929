import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import { CreateUserInfoHelp } from '../Help/CreateUserInfoHelp';
import { InsertUserRequestModel, UserModel } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { ContentShell } from 'features/Content/ContentShell';
import { createUserAction } from '../action';
import { UserForm } from '../User/UserForm';

export const CreateUser = ({ publishDataChanged }: CreateComponentProps) => {
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (user: UserModel) => {
			const newUser = new InsertUserRequestModel(user);

			const bindedAction = createUserAction.bind(null, newUser);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`User ${response.value?.username} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	)

	return (
		<ContentShell
			title='Create User'
			FloatingHelpComponent={CreateUserInfoHelp}
		>
			<UserForm
				onSave={handleSave}
				crud={CrudEnum.Create}
				showPartner
			/>
		</ContentShell>
	)
}
