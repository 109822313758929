import React, { useCallback, useMemo } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import styles from './table.module.scss';
import RequiredField from 'components/SmartContainer/RequiredField';
import { HamburgerIcon } from 'components/icons/icons';
import { getCssVariableValue } from 'utils/cssVariablesUtils';
import FooterRow from './FooterRow';
import Button from 'components/Button';
import { noop } from 'utils/commonHelper';
import commonStyles from 'assets/styles/common.module.scss'
import { ItemWrapper } from '../wrappers/ItemWrapper';
import TableRow from './TableRow';
import { ItemsControlCommonProps } from '../wrappers/useItemsField';

interface ElementListProps {
	isDraggingOver: boolean
	activeColor: string
}

const ElementList = styled.div<ElementListProps>`
	transition: background-color 0.2s ease;
	background-color: ${(props) => (props.isDraggingOver ? props.activeColor : 'unset')};
	height: 100%;
	min-height: 100%;
`;

interface ElementWrapperProps {
	isDragging: boolean
	borderColor: string
	activeColor: string
}

const ElementWrapper = styled.div<ElementWrapperProps>`
	background-color: ${(props) => (props.isDragging ? props.activeColor : 'unset')};
`;

export type HeaderType = {
	label: string
	size?: number
	isRequired?: boolean
	id?: string
	totalCalc?(value: any[], id: string): any
}

export type TableProps = ItemsControlCommonProps & {
	headers: HeaderType[]
	getRowData(row: any, index: number): { // index: -1 means that is footer total row, container should take care of that itself if it uses index
		isDeletable: boolean
		fields: React.ReactElement[]
	}
	draggable?: boolean
	hasTotal?: boolean
}

export function Table(props: TableProps) {
	const {
		value = [], onChange,
		errors = [], registerValidators, validateField, onDelete,
		headers = [], getRowData,
		disabled,
		draggable,
		hasTotal,
		fieldId,
	} = props;

    const changeCallback = useCallback(
        (index: number, id: string, newValue: any) => {
			onChange && onChange(index, id, newValue);
		},
        [onChange]
	)

	const isAnyRowDeletable = useMemo(
		() => {
			for (const rowValues of value) {
				const rowData = getRowData(rowValues, 0); // sending 0 as index is not of interest here
				if (rowData.isDeletable) {
					return true;
				}
			}
			return false;
		},
		[value, getRowData]
	)

	const headerContent = useMemo(
		() => {
			return (
				<div style={{ display: 'flex' }}>
					{headers.map((header, index) => (
						<div key={index} className={styles.cell} style={{ flex: header.size || 'unset'}}>
							{header.label}
							{header.isRequired &&
								<RequiredField />
							}
						</div>
					))}
					{isAnyRowDeletable &&
						<div className={`${styles.cell} ${commonStyles.hidden_no_height}`}>
							<Button text='x' onClick={noop} />
						</div>
					}
				</div>
			)
		},
		[headers, isAnyRowDeletable]
	)

	const rowsContent = useMemo(
		() => {
			return value.map((rowValues, index) => {
				const rowData = getRowData(rowValues, index);
				const iconSize = getCssVariableValue('--shell-icon-size');

				return (
					<div key={index} className={styles.row}>
						{
							draggable ? (
								<Draggable draggableId={`${index}`} index={index}>
									{(provided, snapshot) => {
										const borderColor = getCssVariableValue('--dragndrop-border-color');
										const activeColor = getCssVariableValue('--dragndrop-active-color');
										return (
											<ElementWrapper
												{...provided.draggableProps}
												ref={provided.innerRef}
												isDragging={snapshot.isDragging}
												borderColor={borderColor}
												activeColor={activeColor}
											>
												<ItemWrapper
													index={index}
													values={rowValues}
													errors={errors[index]}
													onFieldChange={changeCallback}
													registerValidators={registerValidators}
													validateField={validateField}
													disabled={disabled}
												>
													<TableRow
														DraggableHandle={
															<div
																className={styles.cell}
																style={{ display: 'flex', alignItems: 'center' }}
																{...provided.dragHandleProps}
															>
																<HamburgerIcon
																	width={iconSize}
																	height={iconSize}
																	fill='currentColor'
																/>
															</div>
														}
														headers={headers}
														fields={rowData.fields}
														errors={errors}
														isAnyRowDeletable={isAnyRowDeletable}
														rowIsDeletable={rowData.isDeletable}
														index={index}
														onDelete={onDelete}
													/>
												</ItemWrapper>
											</ElementWrapper>
										)
									}}
								</Draggable>
							)
							: (
								<ItemWrapper
									index={index}
									values={rowValues}
									errors={errors[index]}
									onFieldChange={changeCallback}
									registerValidators={registerValidators}
									validateField={validateField}
									disabled={disabled}
								>
									<TableRow
										headers={headers}
										fields={rowData.fields}
										errors={errors}
										isAnyRowDeletable={isAnyRowDeletable}
										rowIsDeletable={rowData.isDeletable}
										index={index}
										onDelete={onDelete}
									/>
								</ItemWrapper>
							)
						}
					</div>
				)
			})
		},
		[value, errors, disabled, draggable, changeCallback, registerValidators, validateField, onDelete, getRowData, headers, isAnyRowDeletable]
	)

	const footerContent = useMemo(
		() => {
			if (!hasTotal) {
				return;
			}

			const totalRowValues: any = {}
			for (let i = 0; i < headers.length; i++) {
				const header = headers[i];
				if (header.totalCalc) {
					totalRowValues[header.id!] = header.totalCalc(value, header.id!);
				} else {
					totalRowValues[i] = 0;
				}
			}

			const rowData = getRowData(totalRowValues, -1);
			const fields = rowData.fields;

			return (
				<div style={{ display: 'flex' }}>
					<FooterRow
						headers={headers}
						fields={fields}
						values={totalRowValues}
					/>
					{isAnyRowDeletable &&
						<div className={`${styles.cell} ${commonStyles.hidden_no_height}`}>
							<Button text='x' onClick={noop} />
						</div>
					}
				</div>
			)
		},
		[hasTotal, value, headers, isAnyRowDeletable, getRowData]
	)

	return draggable ? (
		<Droppable droppableId={`table_rows_${fieldId}`}>
			{(provided, snapshot) => {
				const activeColor = getCssVariableValue('--dragndrop-over-active-color');
				return (
					<div className={styles.container_draggable}>
						{headerContent}
						<hr />
						<ElementList
							ref={provided.innerRef}
							{...provided.droppableProps}
							isDraggingOver={snapshot.isDraggingOver}
							activeColor={activeColor}
						>
							{rowsContent}
							{provided.placeholder}
						</ElementList>
						<hr />
						{footerContent}
					</div>
				)
			}}
		</Droppable>
	)
	: (
		<div className={styles.container}>
			{headerContent}
			<hr />
			{rowsContent}
			<hr />
			{footerContent}
		</div>
	)
}
