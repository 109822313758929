import { RowContainer } from 'components/Layout';
import { CrudButtons } from 'features/Crud';

type Props = {
	selectedId: number
	onDelete: (id: number) => Promise<void>
	disabled: boolean
	historyState: any
};

const TableButtons = ({ selectedId, onDelete, disabled, historyState }: Props) => {
	return (
		<RowContainer>
			<CrudButtons
				selectedId={selectedId}
				onDelete={onDelete}
				isCreateable={!disabled}
				isViewable={disabled}
				isChangeable={!disabled}
				isDeleteable={!disabled}
				historyState={historyState}
			/>
		</RowContainer>
	);
};

export default TableButtons;
