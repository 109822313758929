import { TabType } from 'components/Tabs/Tabs';
import { MeetingGeneral } from './Tabs/MeetingGeneral/MeetingGeneral';
import { MeetingType } from './Tabs/MeetingType/MeetingType';
import { StatusReportGeneral } from './Tabs/StatusReportGeneral/StatusReportGeneral';
import { TaskGeneral } from './Tabs/TaskGeneral/TaskGeneral';

export const configureCommunicationTabs: TabType[] = [
	{
		id: '1',
		title: 'Task',
		route: 'task',
		component: TaskGeneral,
	},
	{
		id: '2',
		title: 'Meeting',
		route: 'meeting',
		component: MeetingGeneral,
	},
	{
		id: '3',
		title: 'Meeting type',
		route: 'meeting_type',
		component: MeetingType,
	},
	{
		id: '4',
		title: 'Status report',
		route: 'status_report',
		component: StatusReportGeneral,
	}
]