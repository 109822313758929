import { useCallback, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { TicketCategoryResponse } from 'services/tenantManagementService';
import { CheckboxField, Form, InputField } from 'components/Form';
import { propertyOf } from 'utils/propertyOf';

type Props = {
	item?: TicketCategoryResponse
	onSave(item: TicketCategoryResponse): void
	onCancel(): void
}

export const CategoryItemForm = ({ item, onSave, onCancel }: Props) => {
	const [values, setValues] = useState(item || new TicketCategoryResponse());

	const onSubmitCallback = useCallback(
		async () => await onSave(values),
		[onSave, values]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={onCancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<CheckboxField
							id={propertyOf<TicketCategoryResponse>('isActive')}
							label='Active'
						/>
						<InputField
							id={propertyOf<TicketCategoryResponse>('name')}
							label={values.levelNumber === 1 ? 'Area lvl 1 name' : values.levelNumber === 2 ? 'Area lvl 2 name' : 'Area lvl 3 name'}
							isRequired
							maxLength={25}
						/>
						<InputField
							id={propertyOf<TicketCategoryResponse>('description')}
							label='Explanation'
							maxLength={40}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
