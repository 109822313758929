import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvoiceForm } from './InvoiceForm';
import { DeleteTenantIndetifyRequest, InsertInvoiceItemRequest, InsertInvoiceItemRequestUpdateInvoiceItemRequestDeleteTenantIndetifyRequestDeltaModel, InvoiceItemResponse, InvoiceResponse, UpdateInvoiceItemRequest, UpdateInvoiceRequest } from 'services/tenantManagementService';
import { getInvoiceAction, updateInvoiceAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import { ProjectPickerParams } from 'features/Project';
import { createDelta } from 'utils/commonHelper';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = ProjectPickerParams & {
	invoiceId: string;
}

export const UpdateInvoice = ({ publishDataChanged }: UpdateComponentProps) => {
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId as string);
	const invoiceId = parseInt(params.invoiceId as string);
	const goBackFromUpdate = useGoBackFromUpdate();

	const [invoice, setInvoice] = useState(new InvoiceResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getInvoiceAction.bind(null, projectId, invoiceId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setInvoice(response.value || new InvoiceResponse())
			}
		},
		[invoiceId, projectId]
	)

	const saveCallback = useCallback(
		async (newInvoice: InvoiceResponse) => {
			const invoiceItemsDelta: InsertInvoiceItemRequestUpdateInvoiceItemRequestDeleteTenantIndetifyRequestDeltaModel = createDelta<InvoiceItemResponse>
			(
				invoice.invoiceItems || [],
				newInvoice.invoiceItems || [],
				InsertInvoiceItemRequest,
				UpdateInvoiceItemRequest,
				InsertInvoiceItemRequestUpdateInvoiceItemRequestDeleteTenantIndetifyRequestDeltaModel,
				DeleteTenantIndetifyRequest
			);

			const invoiceForUpdate = new UpdateInvoiceRequest({
				...newInvoice,
				newAttachments: (newInvoice as UpdateInvoiceRequest).newAttachments,
				invoiceItemsDelta
			});

			const bindedAction = updateInvoiceAction.bind(null, projectId, invoiceForUpdate);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`Invoice ${response.value?.invoiceNumber} is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, projectId, publishDataChanged, invoice]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`Change invoice - ${invoice.invoiceNumber}`}>
				<InvoiceForm
					invoice={invoice}
					projectId={projectId}
					crud={CrudEnum.Update}
					onSave={saveCallback}
					cancel={goBackFromUpdate}
				/>
			</ContentShell>
		</WithFetch>
	)
}
