import { DateField, FormGroup, Input, SimpleColorPicker } from "components/Form"
import { ColumnContainer, RowContainer, VerticalSeparator } from "components/Layout"
import { SmartContainer, SmartFormGroup, SmartInline, SmartItem } from "components/SmartContainer/SmartContainer"
import { useMemo } from "react"
import { TicketResponse } from "services/tenantManagementService"
import { minutesToDhm } from "utils/dateTimeUtils"
import { propertyOf } from "utils/propertyOf"
import { TicketFormTabsProps } from "../Tabs/TicketFormTabs"
import { StatusDurations } from "./StatusDurations/StatusDurations"

export const SLA = ({ ticket }: TicketFormTabsProps) => {
	const {
		isSlaActive,
		responseTimeInMinutes,
		dayDurationInMinutes,
		responseTimeBreachInMinutes,
		resolutionTimeInMinutes,
		resolutionTimeBreachInMinutes,
		onHoldInMinutes
	} = ticket;

	const responseTimeMemo = useMemo(
		() => {
			if (!isSlaActive || responseTimeInMinutes === undefined) {
				return '';
			}

			return minutesToDhm(responseTimeInMinutes, dayDurationInMinutes);
		},
		[responseTimeInMinutes, isSlaActive, dayDurationInMinutes]
	)

	const responseTimeBreachMemo = useMemo(
		() => {
			if (!isSlaActive || responseTimeBreachInMinutes === undefined) {
				return '';
			}

			return minutesToDhm(responseTimeBreachInMinutes, dayDurationInMinutes);
		},
		[responseTimeBreachInMinutes, isSlaActive, dayDurationInMinutes]
	)

	const resolutionTimeMemo = useMemo(
		() => {
			if (!isSlaActive || resolutionTimeInMinutes === undefined) {
				return '';
			}

			return minutesToDhm(resolutionTimeInMinutes, dayDurationInMinutes);
		},
		[resolutionTimeInMinutes, isSlaActive, dayDurationInMinutes]
	)

	const resolutionTimeBreachMemo = useMemo(
		() => {
			if (!isSlaActive || resolutionTimeBreachInMinutes === undefined) {
				return '';
			}

			return minutesToDhm(resolutionTimeBreachInMinutes, dayDurationInMinutes);
		},
		[resolutionTimeBreachInMinutes, isSlaActive, dayDurationInMinutes]
	)

	const onHoldMemo = useMemo(
		() => {
			if (!isSlaActive || onHoldInMinutes === undefined) {
				return '';
			}

			return minutesToDhm(onHoldInMinutes, dayDurationInMinutes);
		},
		[onHoldInMinutes, isSlaActive, dayDurationInMinutes]
	)

	return (
		<ColumnContainer>
			<SmartContainer>
				<SmartItem>
					<DateField
						id={propertyOf<TicketResponse>('createdOn')}
						label='Created on'
						showTime
						disabled
						dateFormat='MM/dd/yyyy h:mm aa'
					/>
					<DateField
						id={propertyOf<TicketResponse>('acceptedOn')}
						label='Accepted on'
						showTime
						disabled
						dateFormat='MM/dd/yyyy h:mm aa'
					/>
					<DateField
						id={propertyOf<TicketResponse>('resolvedOn')}
						label='Resolved on'
						showTime
						disabled
						dateFormat='MM/dd/yyyy h:mm aa'
					/>

					{isSlaActive &&
						<>
							<VerticalSeparator margin='xlarge' />
							<FormGroup title='SLA'>
								<RowContainer justifyContent='right'>
									<div style={{padding: '10px 50px'}}>
										Breach
									</div>
								</RowContainer>
								<SmartFormGroup label='Response time'>
									<SmartInline>
										<SimpleColorPicker
											value={responseTimeBreachInMinutes ? 'red': 'green'}
											disabled
										/>
										<Input
											value={responseTimeMemo}
											disabled
										/>
										<Input
											value={responseTimeBreachMemo}
											disabled
										/>
									</SmartInline>
								</SmartFormGroup>
								<SmartFormGroup label='Resolution time'>
									<SmartInline>
										<SimpleColorPicker
											value={resolutionTimeBreachInMinutes ? 'red': 'green'}
											disabled
										/>
										<Input
											value={resolutionTimeMemo}
											disabled
										/>
										<Input
											value={resolutionTimeBreachMemo}
											disabled
										/>
									</SmartInline>
								</SmartFormGroup>
								<SmartFormGroup label='On hold'>
									<Input
										value={onHoldMemo}
										disabled
									/>
								</SmartFormGroup>
							</FormGroup>
						</>
					}
				</SmartItem>
			</SmartContainer>

            <StatusDurations ticket={ticket} />
		</ColumnContainer>
	)
}
