import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud';
import { CreateDeliverablePlan } from './Crud/CreateDeliverablePlan';
import { UpdateDeliverablePlan } from './Crud/UpdateDeliverablePlan';

export const DeliverablePlanRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/${CrudSubRoutesEnum.Create}`} component={CreateDeliverablePlan} />
			<Route path={`${path}/${CrudSubRoutesEnum.Update}/:deliverablePlanId`} component={UpdateDeliverablePlan} />
		</Switch>
	)
}
