import { useCallback, useEffect, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { IdNameResponse, TenantResponseModel, TenantUpdateRequestModel } from 'services/tenantManagementService';
import {  getTenant, updateTenant } from './action';
import { tryCatchJsonByAction, convertResponseErrors } from 'utils/fetchUtils';
import { useHistory } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { AutoCompleteField, Form, SelectField } from 'components/Form';
import { RootRouteEnum } from 'features/Routes';
import { setIsTenantInitialized } from 'features/Tenant/tenantUtils';
import { AccountSubRoutesEnum } from 'containers/Configuration/MyConfiguration/routes';

type Props = {
	industries: IdNameResponse[];
	countries: IdNameResponse[];
	currencies: IdNameResponse[];
	teamSizes: IdNameResponse[];
}

const FinishRegistrationForm = ({industries, countries, currencies, teamSizes}: Props) => {
	const [values, setValues] = useState(new TenantResponseModel());
	// const [timeZoneIncrement, setTimeZoneIncrement] = useState(0);
	// const [timeZoneName, setTimeZoneName] = useState('');
	// const [numberFormat, setNumberFormat] = useState('');
	// const [dateFormat, setDateFormat] = useState('');
	// const [timeFormat, setTimeFormat] = useState('');

	const history = useHistory();

	useEffect(
		() => {
			const fetchData = async () => {
				const response = await tryCatchJsonByAction(getTenant);

				if (response.success) {
					setValues(response.value || new TenantResponseModel());
				}
			}
			fetchData();
		},
		[]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const tenantId = getTenantId()!;
			const newTenantData = new TenantUpdateRequestModel({
				...values,
				timeZoneIncrement: 0,
				dateFormat: 'empty',
				timeFormat: 'empty',
				numberFormat: 'empty',
				timeZoneName: 'empty',
			});

			const bindedAction = updateTenant.bind(null, tenantId, newTenantData);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Tenant is initialized.');
				setIsTenantInitialized(true);
				history.push(`${RootRouteEnum.ACCOUNT}/${AccountSubRoutesEnum.PROFILE}`);
			} else {
				return convertResponseErrors(response);
			}
		},
		[values, history]
	);

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			submitButtonText='Finish Registration'
			hideCancelButton
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<SelectField
							id='industryId'
							label='Your industry'
							isRequired
							items={industries}
							getItemId={(industry: IdNameResponse) => industry.id}
							getItemText={(industry: IdNameResponse) => industry.name!}
							loading={industries && industries.length === 0}
						/>
						<AutoCompleteField
							id='countryId'
							label='Your country'
							isRequired
							items={countries}
							getItemId={(country: IdNameResponse) => country.id}
							getItemText={(country: IdNameResponse) => country.name!}
							loading={countries && countries.length === 0}
						/>
						<AutoCompleteField
							id='currencyId'
							label='Your currency'
							isRequired
							items={currencies}
							getItemId={(currency: IdNameResponse) => currency.id}
							getItemText={(currency: IdNameResponse) => currency.name!}
							loading={currencies && currencies.length === 0}
						/>
						<SelectField
							id='teamSizeId'
							label='Your team size'
							isRequired
							items={teamSizes}
							getItemId={(teamSize: IdNameResponse) => teamSize.id}
							getItemText={(teamSize: IdNameResponse) => teamSize.name!}
							loading={teamSizes && teamSizes.length === 0}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}

export default FinishRegistrationForm
