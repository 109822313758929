import { useCallback, useState } from 'react';
import notifications from 'components/Notification/notification';
import { ExportDataModel, GenericFilterModelCollection, ProjectResponse } from 'services/tenantManagementService';
import { TableButtons } from '../TableButtons';
import { ProjectsTable } from 'features/Project';
import { deleteProjectAction, publishProjectsChanged } from 'features/Project';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { ColumnContainer } from 'components/Layout';
import { ProjectHelp } from './Help/ProjectHelp';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { ContentShell } from 'features/Content/ContentShell';
import { BaseColumnModel } from 'components/Table';
import { Note } from 'components/Note/Note';

const configureViewKey = 'project_configuration';

export const Projects = () => {
	const [selectedProject, setSelectedProject] = useState<ProjectResponse>(new ProjectResponse());

	const selectedProjectChangeCallback = useCallback(
		(data: ProjectResponse[]) => {
			setSelectedProject(data[0] || new ProjectResponse())
		},
		[]
	);

	const deleteCallback = useCallback(
		async () => {
			const bindedAction = deleteProjectAction.bind(null, selectedProject.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.PROJECT, selectedProject.id);
				notifications.success(`Project ${id} is deleted.`);
				publishProjectsChanged();
			}
		},
		[selectedProject.id]
	)

	const renderTableButtonsCallback = useCallback(
		(tableColumns: BaseColumnModel[], filtersModel: GenericFilterModelCollection, memoExportFunction : (exportDataModel: ExportDataModel) => any) => (
			<TableButtons
				selectedId={selectedProject.id}
				onDeleteClick={deleteCallback}
				configureViewKey={configureViewKey}
				tableColumns={tableColumns}
				filtersModel={filtersModel}
				exportFunction={memoExportFunction}
				exportTitle={'Projects'}
			/>
		),
		[selectedProject.id, deleteCallback]
	)

	return (
		<ContentShell
			title='Create Project'
			FloatingHelpComponent={ProjectHelp}
		>
			<ColumnContainer margin='medium'>
				<Note text='Prior to creating new project, you have to open username for responsible project manager.' />
				<ProjectsTable
					renderTableButtons={renderTableButtonsCallback}
					onSelectedProjectChange={selectedProjectChangeCallback}
					configureViewKey={configureViewKey}
				/>
			</ColumnContainer>
		</ContentShell>
	);
}
