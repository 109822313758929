import { getTenantId } from 'features/Tenant/tenantUtils';
import { TrainingDashboardClient, SurveyDashboardFilterRequest, SurveyClient, SurveyStatusEnum } from 'services/tenantManagementService';

export const getSurveyDashboardAction = (projectId: number, surveyId: number, filterRequest: SurveyDashboardFilterRequest) => {
	const tenantId = getTenantId()!;
	const client = new TrainingDashboardClient();
	return client.get2(tenantId, projectId, surveyId, filterRequest);
}

export const getSurveysAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new SurveyClient();
	return client.getAllReduced(tenantId, projectId, SurveyStatusEnum.Released, undefined, undefined);
}
