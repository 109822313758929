import { Info } from 'components/Form/controls/Info/Info'
import { useCallback, useState } from 'react'
import { UpdateTicketCommentRequest } from 'services/tenantManagementService'
import { UpdateTask } from './Crud/UpdateTask'

type Props = {
	task: UpdateTicketCommentRequest
	updateTask(task: UpdateTicketCommentRequest): any
	removeTask(): void
}

export const Task = ({ task, updateTask, removeTask }: Props) => {
	const [isUpdateOpened, setIsUpdateOpened] = useState(false);

	const openUpdateDialog = useCallback(
		() => setIsUpdateOpened(true),
		[]
	)

	const removeTaskCallback = useCallback(
		() => {
			removeTask();
		},
		[removeTask]
	)

	if (!task.taskDate) {
		return <></>
	}

	return (
		<>
			<Info
				text='New task'
				isDeletable
				onDelete={removeTaskCallback}
				onClick={openUpdateDialog}
			/>
			<UpdateTask
				isOpened={isUpdateOpened}
				setIsOpened={setIsUpdateOpened}
				task={task}
				updateTask={updateTask}
			/>
		</>
	)
}
