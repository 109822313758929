import { IdNameResponse, NonProjectCategoryResponse, ProjectResponse, TenantResponseModel, TimeTravelStatusResponse, TravelRequestResponse, UserModel } from 'services/tenantManagementService';
import { PersistActiveItemsReducer, PersistItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { FormField } from 'components/Export';
import { useFormattedCurrencyValueWithArgsCallback, useFormattedProjectIdNameCallback } from 'features/TableColumns/persistedHooks';
import { formatDate } from 'utils/dateTimeUtils';
import CheckboxFormField from 'components/Export/CheckboxFormField';
import { getTravelByEnumLabel } from 'containers/TimeTravel/utils';

type Props = {
	travelRequest: TravelRequestResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedProject: PersistItemsReducer<ProjectResponse>
	persistedTimeTravelNonProjectCategories: PersistActiveItemsReducer<NonProjectCategoryResponse>
	persistedTimeAndTravelStatus: PersistItemsReducer<TimeTravelStatusResponse>
	persistedCountry: PersistItemsReducer<IdNameResponse>
	persistedCurrency: PersistItemsReducer<IdNameResponse>
	persistedTenant: PersistValueReducer<TenantResponseModel>
}

export const TravelRequestDocumentForm = ({
	travelRequest,
	persistedUser,
	persistedProject,
	persistedTimeTravelNonProjectCategories,
	persistedTimeAndTravelStatus,
	persistedCountry,
	persistedCurrency,
	persistedTenant
}: Props) => {
	const user = persistedUser.itemsMap[travelRequest.userId];
	const status = persistedTimeAndTravelStatus.itemsMap[travelRequest.statusId];
	const approvedByUser = travelRequest.approvedOrRejectedByUserId ? persistedUser.itemsMap[travelRequest.approvedOrRejectedByUserId] : undefined;

	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);
	const formatedProjectOrCategory = travelRequest.isProjectConnected ? formatProjectIdName(travelRequest.projectOrCategoryId) : persistedTimeTravelNonProjectCategories.itemsMap[travelRequest.projectOrCategoryId]?.name;

	const getFormattedCurrencyValue = useFormattedCurrencyValueWithArgsCallback(persistedCurrency, persistedTenant);

	return (
		<>
			<FormField
				label='User'
				value={`${user?.firstName} ${user?.lastName}`}
			/>
			<FormField
				label='Travel request Id'
				value={getFormatedId(EntityPrefixEnum.TRAVEL_REQUEST, travelRequest.id)}
			/>
			<FormField
				label='Status'
				value={status?.name}
				valueBackgroundColor={status?.color}
			/>
			<FormField
				label='Approved by'
				value={approvedByUser ? `${approvedByUser?.firstName} ${approvedByUser?.lastName}` : ''}
			/>
			<FormField
				label='Project or category'
				value={formatedProjectOrCategory}
			/>
			<FormField
				label='Departure'
				value={formatDate(travelRequest.departure)}
			/>
			<FormField
				label='Return'
				value={formatDate(travelRequest.return)}
			/>
			<FormField
				label='Purpose of travel'
				value={travelRequest.description}
			/>
			<FormField
				label='Estimated costs'
				value={getFormattedCurrencyValue(travelRequest.estimatedCosts, true)}
			/>
			<FormField
				label='Advance payment'
				value={getFormattedCurrencyValue(travelRequest.advancePayment, true)}
			/>
			<CheckboxFormField
				label='Free meals'
				value={travelRequest.freeMeals}
			/>
			<CheckboxFormField
				label='Free accommodation'
				value={travelRequest.freeAccommodation}
			/>
			<FormField
				label='Destination country'
				value={persistedCountry.itemsMap[travelRequest.destinationCountryId]?.name}
			/>
			<FormField
				label='Destination city'
				value={travelRequest.destinationCity}
			/>
			<FormField
				label='Travel by'
				value={getTravelByEnumLabel(travelRequest.travelBy)}
			/>
			<FormField
				label='Text 1'
				value={travelRequest.customText1}
			/>
			<FormField
				label='Text 2'
				value={travelRequest.customText2}
			/>
		</>
	);
};
