import { Dialog } from 'components/Dialog';
import { SurveyQuestionResponse, SurveyResponse} from 'services/tenantManagementService';
import QuestionForm from './QuestionForm';
import { CrudEnum } from 'features/Crud';

type Props = {
	question?: SurveyQuestionResponse
	open: boolean
	cancel(): void
	save?(question: SurveyQuestionResponse): void
	crud: CrudEnum
	survey?: SurveyResponse
}

const QuestionModalForm = ({ question, open = false, save, cancel, crud, survey }: Props) => {
	const title = crud === CrudEnum.Create ? 'Create question' : (crud === CrudEnum.Update ? 'Edit question' : 'View question');

	return (
		<Dialog
			open={open}
			size='xxxlarge'
			title={title}
			onClose={cancel}
		>
			<QuestionForm
				survey={survey}
				crud={crud}
				question={question}
				save={save}
				cancel={cancel}
			/>
		</Dialog>
	);
};

export default QuestionModalForm;
