import { getTenantId } from 'features/Tenant/tenantUtils';
import { getUserTableViewConfigAction, updateUserTableViewConfigAction } from 'features/UserTableViewConfig/action';
import { ExportDataModel, GenericFilterModelCollection, MultiplyUpdateTestPlanRequest, TestPlanClient } from 'services/tenantManagementService';

const viewType = 'showProcessSteps';

export const getShowProcessStepsConfigAction = () => {
	return getUserTableViewConfigAction(viewType);
}

export const updateShowProcessStepsConfigAction = (showProcessSteps: boolean) => {
	return updateUserTableViewConfigAction(viewType, showProcessSteps);
}

export const getAllTestPlansSimpleAction = () => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.getAllSimple(tenantId);
}

export const getTestPlansGenericAction = (projectId: number, testCycleId: number, includeLevel4: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.getAllGenericFilter(tenantId, projectId, testCycleId, includeLevel4, genericFilter);
}

export const updateTestPlansAction = (projectId: number, testCycleId: number, updateTestPlansRequest: MultiplyUpdateTestPlanRequest) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.bulkUpdate(tenantId, projectId, testCycleId, updateTestPlansRequest);
}

export const setAllIsActiveAction = (projectId: number, testCycleId: number, isActive: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.setAllIsActive(tenantId, projectId, testCycleId, isActive, genericFilter);
}

export const exportAction = (projectId: number, testCycleId: number, includeLevel4: boolean, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.exportTestPlans(tenantId, projectId, testCycleId, includeLevel4, exportDataModel);
}
