import { EventInput } from '@fullcalendar/core'
import { Calendar, CalendarViewEnum } from 'components/Calendar'
import { useCallback, useMemo, useState } from 'react'
import { MultiCheckbox, MultiCheckboxItemType } from 'components/Form';
import { CommunicationCalendarResponse, CommunicationCalendarResponseItemsResponseModel, EntityTypeEnum, ModuleActivityEnum, UpdateTaskCalendarItemRequest } from 'services/tenantManagementService'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getMyCalendarConfigAction, getMyCalendarsAction, MyCalendarConfig, updateMyCalendarConfigAction, updateTaskDateAction } from './action'
import { WithProjectsOrCategoriesPicker, ProjectsOrCategoriesComponentProps } from 'features/Project';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useEffect } from 'react';
import { getDateWithTimezoneOffset, removeZoneFromDate, formatDate } from 'utils/dateTimeUtils';
import { getMeetingRoute, getReminderRoute, getTaskRoute, getVacationRoute } from 'utils/routeUtils';
import { useStatusColorCallback } from 'features/TableColumns/persistedHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { EventImpl } from '@fullcalendar/core/internal';

type DateRange = {
	startStr: string
	endStr: string
}

const entityTypes: MultiCheckboxItemType[] = [
	{ id: EntityTypeEnum.Meeting, text: 'Meetings' },
	{ id: EntityTypeEnum.Vacation, text: 'Vacations' },
	{ id: EntityTypeEnum.Task, text: 'Tasks' },
	{ id: EntityTypeEnum.Reminder, text: 'Reminders' }
]

const MyCalendar = ({ projects, nonProjectCategories }: ProjectsOrCategoriesComponentProps) => {
	const { persistedTimeAndTravelStatus } = useSelector((state: RootState) => state)

	const [calendar, setCalendar] = useState<CommunicationCalendarResponse[]>([]);
	const [isFetching, setIsFetching] = useState(true);
	const [isFetchingConfig, setIsFetchingConfig] = useState(true); // must be true because of logic in useEffect
	const [selectedEntityTypes, setSelectedEntityTypes] = useState<EntityTypeEnum[]>(
		[EntityTypeEnum.Meeting, EntityTypeEnum.Vacation, EntityTypeEnum.Reminder, EntityTypeEnum.Task]
	);
	const [dateRange, setDateRange] = useState<DateRange>();

	const fetchDataCallback = useCallback(
		async () => {
			if (!dateRange) {
				return;
			}

			setIsFetching(true);
			const projectIds = projects.map(project => project.id);
			const categoryIds = nonProjectCategories.map(item => item.id);
			const bindedAction = (getMyCalendarsAction as any).bind(null, projectIds, categoryIds, selectedEntityTypes, new Date(dateRange.startStr), new Date(dateRange.endStr));
			const response: CommunicationCalendarResponseItemsResponseModel = await tryCatchJsonByAction(bindedAction);
			setIsFetching(false);
			if (response.success) {
				setCalendar(response.items || []);
			}
		},
		[projects, nonProjectCategories, selectedEntityTypes, dateRange]
	)

	const fetchConfigAction = useCallback(
		async () => {
			setIsFetchingConfig(true)
			const response = await tryCatchJsonByAction(getMyCalendarConfigAction);
			if (response.success && response.value) {
				const configContent = response.value.content as MyCalendarConfig;
				setSelectedEntityTypes(configContent.selectedEntityTypes)
			}
			setIsFetchingConfig(false)
		},
		[]
	)

	useEffect(
		() => {
			if (!isFetchingConfig) {
				fetchDataCallback()
			}
		},
		[isFetchingConfig, fetchDataCallback]
	)

	useEffect(
		() => {
			fetchConfigAction()
		},
		[fetchConfigAction]
	)

	const getVacationStatusColor = useStatusColorCallback(persistedTimeAndTravelStatus);

	const calendarData: EventInput[] = useMemo(
		() => {
			return calendar.map(
				(item) => {
					let event: EventInput;

					switch (item.type) {
						case EntityTypeEnum.Meeting:
							event = {
								title: 'Meeting: ' + item.description,
								start: item.from,
								end: item.to,
								url: getMeetingRoute(item.id, item.projectOrCategoryId, item.isProjectConnected),
								display: 'list-item',
								backgroundColor: 'transparent',
								borderColor: '#03dac6',
								textColor: '#03dac6'
							}
							break;
						case EntityTypeEnum.Reminder:
							event = {
								start: item.from,
								end: item.to,
								url: getReminderRoute(item.id, item.projectOrCategoryId, item.isProjectConnected),
								display: 'list-item',
								backgroundColor: '#b33dc6',
								borderColor: '#b33dc6',
								textColor: '#fff'
							}
							break;
						case EntityTypeEnum.Task:
							const allDay = item.to ? false : true;
							event = {
								title: '',
								start: allDay ? removeZoneFromDate(item.from) : item.from,
								end: item.to,
								allDay,
								display: 'list-item',
								url: getTaskRoute(item.id),
								backgroundColor: '#27aeef',
								borderColor: '#27aeef'
							}
							break;
						case EntityTypeEnum.Vacation:
							const color = getVacationStatusColor(item.statusId!);
							event = {
								title: '',
								start: removeZoneFromDate(item.from),
								end: removeZoneFromDate(item.to),
								allDay: true,
								url: getVacationRoute(item.id),
								display: 'background',
								backgroundColor: color
							}
							break;
					}

					const calendarEvents: EventInput = {
						id: item.id.toString(),
						title: item.description,
						editable: item.type === EntityTypeEnum.Task,
						durationEditable: item.type === EntityTypeEnum.Task,
						extendedProps: {
							...item
						},
						...event
					}

					return calendarEvents;
				}
			)
		},
		[calendar, getVacationStatusColor]
	)

	const setSelectedEntityTypesCallback = useCallback(
		async (value: (string | number)[]) => {
			setIsFetchingConfig(true)

			setSelectedEntityTypes(value as EntityTypeEnum[])
			const bindedAction = updateMyCalendarConfigAction.bind(null, {
				selectedEntityTypes: value as EntityTypeEnum[]
			});
			await tryCatchJsonByAction(bindedAction);

			setIsFetchingConfig(false)
		},
		[]
	)

	const setDateRangeCallback = useCallback(
		(newFrom: Date, newTo: Date, newFromStr: string, newToStr: string) => {
			setDateRange({
				startStr: newFromStr,
				endStr: newToStr
			})
		},
		[]
	)

	const updateEventCallback = useCallback(
		async (event: EventImpl, oldEvent: EventImpl) => {
			const { id, start, end, allDay } = event;

			const task = calendar.find((item) => item.id === parseInt(id));

			if (task?.projectOrCategoryId) {
				const model = new UpdateTaskCalendarItemRequest({
					taskId: task.id,
					from: allDay ? getDateWithTimezoneOffset(start!)! : start!,
					to: end!
				})

				const bindedAction = updateTaskDateAction.bind(null, task.projectOrCategoryId, task.isProjectConnected, model);
				await tryCatchJsonByAction(bindedAction);
			}
		},
		[calendar]
	)

	const eventChangedCallback = useCallback(
		(event: EventImpl, oldEvent: EventImpl) => {
			const { start, end, allDay } = event;

			// if Task event is dragged from all day and dropped on some time slot, end is not set but UI seems like duration is 1 hour,
			// so we manually set end = start + 1h so we can later figure out if event fetched from backend is allDay or not (backend model don't have allDay property)
			if (event.extendedProps.type === EntityTypeEnum.Task && !allDay && !end && start) {
				const startPlusOneHour = new Date(start);
				startPlusOneHour.setTime(startPlusOneHour.getTime() + (1 * 60 * 60 * 1000))
				event.setEnd(startPlusOneHour)
			} else { // event.setEnd will cause this method to be triggered again, so we don't want to send request twice
				updateEventCallback(event, oldEvent)
			}
		},
		[updateEventCallback]
	)

	const eventDidMountCallback = useCallback(
		(event: EventImpl, el: HTMLElement, view: CalendarViewEnum) => {
			const titleElement = el.querySelectorAll('.fc-event-title, .fc-list-event-title')[0] as HTMLElement | undefined;
			// add tooltip
			el.title = event.extendedProps.description || '';

			if (!titleElement) {
				return;
			}

			// we need to support HTML, because Task description is HTML from text editor
			// and also we add Due Date
			if (event.extendedProps.type === EntityTypeEnum.Task) {
				const descriptionElement = document.createElement('div')
				descriptionElement.innerHTML = event.extendedProps.description;
				descriptionElement.style.overflow = 'hidden'
				if (view === CalendarViewEnum.dayMonthly) {
					descriptionElement.style.maxHeight = '16px'
				} else if (view === CalendarViewEnum.timeDaily || view === CalendarViewEnum.timeWeekly) {
					titleElement.style.display = 'flex'
					titleElement.style.flexDirection = 'column'
					if (event.allDay) {
						descriptionElement.style.maxHeight = '18px'
					}
				}

				const dueDateElement = document.createElement('div')
				dueDateElement.innerHTML = 'Due on: ' + formatDate(event.extendedProps.dueDate as Date);

				titleElement.innerHTML = ''

				titleElement.append(descriptionElement)
				titleElement.append(dueDateElement)

				// fix tooltip for HTML string
				el.title = descriptionElement.textContent || ''
			} else if (event.extendedProps.type === EntityTypeEnum.Meeting) {

			}
		},
		[]
	)

	return (
		<SmartContainer>
			<SmartItem size='xxsmall'>
				<MultiCheckbox
					value={selectedEntityTypes}
					onChange={setSelectedEntityTypesCallback}
					items={entityTypes}
					disabled={isFetching || isFetchingConfig}
					column
				/>
			</SmartItem>
			<SmartItem size='xxlarge'>
				<Calendar
					events={calendarData}
					setDateRange={setDateRangeCallback}
					eventDidMount={eventDidMountCallback}
					eventChanged={eventChangedCallback}
					loading={isFetching || isFetchingConfig}
					clickableDaysAndWeeks
					initialView={CalendarViewEnum.timeWeekly}
					views={[CalendarViewEnum.timeDaily, CalendarViewEnum.timeWeekly, CalendarViewEnum.dayMonthly, CalendarViewEnum.listWeekly]}
				/>
			</SmartItem>
		</SmartContainer>
	)
}

export default WithProjectsOrCategoriesPicker(MyCalendar, ModuleActivityEnum.Communication, true);
