import { ChangeRequestFullResponse, ChangeRequestStatusResponse, ImpactResponse, PriorityResponse, ProjectResponse, ScopeItemLevelResponse, TenantIsActiveResponse, UrgencyResponse, UserModel } from 'services/tenantManagementService';
import { FormField } from 'components/Export';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { PersistActiveItemsReducer, PersistItemsReducer } from 'features/Persist/reducerTypes';
import { formatDate } from 'utils/dateTimeUtils';
import { getPriority } from 'features/Priority/helper';
import FormGroup from 'components/Export/FormGroup';
import TextareaFormField from 'components/Export/TextareaFormField';

type Props = {
	changeRequest: ChangeRequestFullResponse
	project: ProjectResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedChangeRequestType: PersistActiveItemsReducer<TenantIsActiveResponse>
	persistedTicketImpact: PersistItemsReducer<ImpactResponse>
	persistedTicketUrgency: PersistItemsReducer<UrgencyResponse>
	persistedTicketPriority: PersistItemsReducer<PriorityResponse>
	persistedChangeRequestStatus: PersistItemsReducer<ChangeRequestStatusResponse>
	level3Items: ScopeItemLevelResponse[]
}

export const ProblemReport = ({
	changeRequest,
	project,
	persistedUser,
	persistedChangeRequestType,
	persistedTicketImpact,
	persistedTicketUrgency,
	persistedTicketPriority,
	persistedChangeRequestStatus,
	level3Items
}: Props) => {
	const requestedByUser = persistedUser.itemsMap[changeRequest.requestedByUserId];

	return (
		<>
			<FormField
				label='Project Id'
				value={getFormatedId(EntityPrefixEnum.PROJECT, project.id)}
			/>
			<FormField
				label='Project name'
				value={project.name}
				marginBottom={15}
			/>
			<FormField
				label='Change Request Id'
				value={getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, changeRequest.id)}
			/>
			<FormField
				label='Change Request name'
				value={changeRequest.name}
			/>
			<FormField
				label='Status'
				value={persistedChangeRequestStatus.itemsMap[changeRequest.statusId]?.name}
			/>
			<FormField
				label='Requested by'
				value={requestedByUser && `${requestedByUser.firstName} ${requestedByUser.lastName}`}
			/>
			<FormField
				label='Requested on'
				value={formatDate(changeRequest.requestedOn)}
			/>
			<FormField
				label='Change Request type'
				value={changeRequest.typeId ? persistedChangeRequestType.itemsMap[changeRequest.typeId]?.name : ''}
			/>
			<FormField
				label='Go-live critical'
				value={changeRequest.goLiveCritical ? 'Yes': 'No'}
			/>
			<FormField
				label='Impact'
				value={persistedTicketImpact.itemsMap[changeRequest.impactId]?.name}
			/>
			<FormField
				label='Urgency'
				value={persistedTicketUrgency.itemsMap[changeRequest.urgencyId]?.name}
			/>
			<FormField
				label='Priority'
				value={getPriority(persistedTicketPriority.items, changeRequest.impactId, changeRequest.urgencyId)?.name}
			/>
			<FormField
				label='Expected completion date'
				value={formatDate(changeRequest.expectedCompletionDate)}
			/>
			<FormField
				label='Initiated by'
				value={changeRequest.initiatedBy}
			/>
			<FormField
				label='Scope item link'
				value={changeRequest.scopeItemLevelIds?.map(x => level3Items.find(item => item.id === x)?.fullname).join(',')}
			/>
			<FormGroup label='Description of change' >
				<TextareaFormField
					value={changeRequest.description}
				/>
			</FormGroup>
			<FormGroup label='Reason for change' >
				<TextareaFormField
					value={changeRequest.reasonOfChange}
				/>
			</FormGroup>
			<FormGroup label='Expected benefits' >
				<TextareaFormField
					value={changeRequest.expectedBenefit}
				/>
			</FormGroup>
		</>
	);
};
