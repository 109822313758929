import { getTenantId } from 'features/Tenant/tenantUtils';
import { InsertMeetingCommentRequest, MeetingCommentClient, UpdateMeetingCommentRequest } from 'services/tenantManagementService';

export const createTopicCommentAction = (model: InsertMeetingCommentRequest) => {
	const tenantId = getTenantId()!;
	const client = new MeetingCommentClient();
	return client.create(tenantId, model);
}

export const updateTopicCommentAction = (model: UpdateMeetingCommentRequest) => {
	const tenantId = getTenantId()!;
	const client = new MeetingCommentClient();
	return client.update(tenantId, model);
}

export const deleteTopicCommentAction = (commentId: number) => {
    const tenantId = getTenantId()!;
	const client = new MeetingCommentClient();
    return client.delete(tenantId, commentId);
}
