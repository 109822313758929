import { ProjectTeamMemberResponse, ProjectTeamMemberValidityPeriodResponse, TokenTypeEnum } from 'services/tenantManagementService';

export class TeamMemberCustomModel {
    id!: number;
    userId!: number;
    projectId!: number;
    roleId!: TokenTypeEnum;
    isSubstitutePm!: boolean;
    isPmOrSubstitutePm!: boolean;
    projectRoleId?: number | undefined;
    projectTeamId?: number | undefined;
    statusId!: number;
    validUntil?: Date | undefined;
    readonly isActive!: boolean;
	ticketCheck: boolean = true;
	timeTravelCheck: boolean = true;
	scopeCheck: boolean = true;
    scopeCheckMaintainScope: boolean = false;
    scopeCheckImportScope: boolean = false;
    scopeCheckViewAllChangeRequests: boolean = false;
    testingCheck: boolean = true;
    testingCheckCreateTestCycle: boolean = false;
    testingCheckMaintainTestPlan: boolean = false;
	trainingCheck: boolean = true;
    trainingCheckCreateTrainingCycle: boolean = false;
    trainingCheckMaintainTrainingPlan: boolean = false;
    trainingCheckCreateSurveys: boolean = false;
	scheduleCheck: boolean = true;
	scheduleCheckMaintainSchedule: boolean = false;
    validityPeriods?: ProjectTeamMemberValidityPeriodResponse[] | undefined;
	currentValidityPeriod?: ProjectTeamMemberValidityPeriodResponse;
    readonly rateValidFrom?: Date;
    readonly rateValidTo?: Date;
    readonly lastSalesRate?: number;
    readonly lastCostRate?: number;

	constructor(data?: ProjectTeamMemberResponse | TeamMemberCustomModel) {
        if (data) {
			this.id = data.id
			this.userId = data.userId;
			this.projectId = data.projectId;
			this.roleId = data.roleId;
			this.isSubstitutePm = data.isSubstitutePm;
			this.isPmOrSubstitutePm = data.isPmOrSubstitutePm;
			this.projectRoleId = data.projectRoleId;
			this.projectTeamId = data.projectTeamId;
			this.statusId = data.statusId;
			this.validUntil = data.validUntil;
			this.isActive = data.isActive;
			this.validityPeriods = data.validityPeriods;
			this.currentValidityPeriod = data.currentValidityPeriod;
			this.rateValidFrom = data.rateValidFrom;
			this.rateValidTo = data.rateValidTo;
			this.lastSalesRate = data.lastSalesRate;
			this.lastCostRate = data.lastCostRate;
			if (data instanceof ProjectTeamMemberResponse) {
				this.ticketCheck = data.permissions?.ticketPermission?.dashboard || data.permissions?.ticketPermission?.tickets || false;
				// approver not used
				this.timeTravelCheck = data.permissions?.timeTravelPermission?.dashboard ||
					data.permissions?.timeTravelPermission?.timesheets ||
					data.permissions?.timeTravelPermission?.travelExpenses ||
					data.permissions?.timeTravelPermission?.travelRequests ||
					data.permissions?.timeTravelPermission?.vacationRequests || false;

				this.scopeCheck = data.permissions?.scopePermission?.dashboard ||
					data.permissions?.scopePermission?.initiateChangeRequest ||
					data.permissions?.scopePermission?.viewScope || false;
				this.scopeCheckMaintainScope = data.permissions?.scopePermission?.maintainScope || false;
				this.scopeCheckImportScope = data.permissions?.scopePermission?.importScope || false;
				this.scopeCheckViewAllChangeRequests = data.permissions?.scopePermission?.viewAllChangeRequests || false;

				this.testingCheck = data.permissions?.testingPermission?.dashboard ||
					data.permissions?.testingPermission?.myTestPackages ||
					data.permissions?.testingPermission?.testStatus ||
					data.permissions?.testingPermission?.viewTestPlan || false;
				this.testingCheckCreateTestCycle = data.permissions?.testingPermission?.createTestCycle || false;
				this.testingCheckMaintainTestPlan = data.permissions?.testingPermission?.maintainTestPlan || false;

				// reporting not used
				this.trainingCheck = data.permissions?.trainingPermission?.dashboard ||
					data.permissions?.trainingPermission?.mySurveys ||
					data.permissions?.trainingPermission?.viewTrainingPlan ||
					data.permissions?.trainingPermission?.myTrainingPlan || false;
				this.trainingCheckCreateTrainingCycle = data.permissions?.trainingPermission?.createTrainingCycle || false;
				this.trainingCheckMaintainTrainingPlan = data.permissions?.trainingPermission?.maintainTrainingPlan || false;
				this.trainingCheckCreateSurveys = data.permissions?.trainingPermission?.createSurveys || false;
				this.scheduleCheck = data.permissions?.schedulePermission?.viewSchedule || false;
				this.scheduleCheckMaintainSchedule = data.permissions?.schedulePermission?.maintainSchedule || false;
			} else {
				this.ticketCheck = data.ticketCheck;
				this.timeTravelCheck = data.timeTravelCheck;
				this.scopeCheck = data.scopeCheck;
				this.scopeCheckImportScope = data.scopeCheckImportScope;
				this.scopeCheckMaintainScope = data.scopeCheckMaintainScope;
				this.scopeCheckViewAllChangeRequests = data.scopeCheckViewAllChangeRequests;
				this.testingCheck = data.testingCheck;
				this.testingCheckCreateTestCycle = data.testingCheckCreateTestCycle;
				this.testingCheckMaintainTestPlan = data.testingCheckMaintainTestPlan;
				this.trainingCheck = data.trainingCheck;
				this.trainingCheckCreateSurveys = data.trainingCheckCreateSurveys;
				this.trainingCheckCreateTrainingCycle = data.trainingCheckCreateTrainingCycle;
				this.trainingCheckMaintainTrainingPlan = data.trainingCheckMaintainTrainingPlan;
				this.scheduleCheck = data.scheduleCheck;
				this.scheduleCheckMaintainSchedule = data.scheduleCheckMaintainSchedule;
			}
        }
    }
}
