import { useSelector } from 'react-redux';
import { Route } from 'features/Routes/reducer';
import { RootState } from 'base/reducer/reducer';
import { Item } from './Item';
import styles from './submenu.module.scss';

type Props = {
	opened?: boolean
	items: Route[]
	close(): void
}

export const Submenu = ({ opened, items, close }: Props) => {
	const className = opened ? styles.visible : styles.container;

	const currentRoutes = useSelector((state: RootState) => state.routes.currentRoutes);
	const activeItemId = currentRoutes[1] && currentRoutes[1].id;

	const menuItems = items.map((item) => {
		return (
			<Item
				key={item.id}
				url={item.url}
				text={item.text}
				isActive={item.id === activeItemId}
				Icon={item.Icon}
				closeSubmenu={close}
			/>
		)
	})

	return (
		<div className={className}>
			{menuItems}
		</div>
	)
}
