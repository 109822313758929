import { Widget, WidgetProps } from "./Widget"
import styles from './widget.module.scss'

type Props = WidgetProps & {
	value?: string | number
	color?: string
	toFixed?: boolean
}

export const TextWidget = ({ value, color, toFixed, ...props }: Props) => {
	const formattedValue = (typeof value === 'number' && toFixed) ? value.toFixed(2) : value;

	return (
		<Widget
			{...props}
			height='110px'
		>
			<div className={styles.textWidget} style={{ color }}>
				{formattedValue !== undefined ? formattedValue : '-'}
			</div>
		</Widget>
	)
}
