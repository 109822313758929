import { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChangeRequestCountsResponse, ChangeRequestFullResponse, GenericFilterModelCollection, ModuleActivityEnum } from 'services/tenantManagementService';
import { ColumnContainer } from 'components/Layout';
import { RootState } from 'base/reducer/reducer';
import { TableButtons } from './Table/TableButtons';
import { WithProjectPicker, ComponentProps } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';
import { ListComponentProps } from 'features/Crud';
import { useTableColumnsMemo } from '../ChangeRequests/Table/tableColumns';
import { exportMyAction, getAllMyChangeRequestCountsActionCounts, getMyChangeRequestsAction } from '../ChangeRequests/action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';

const configureViewKey = 'scope_change_requests_table';

type Props = ListComponentProps & ComponentProps;

const MyChangeRequests = ({ project, disabledEdit, dataChangedTopic, publishDataChanged }: Props) => {
	const {
		persistedChangeRequestStatus,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const [selectedChangeRequest, setSelectedChangeRequest] = useState(new ChangeRequestFullResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const [countsResponse, setCountsResponse] = useState(new ChangeRequestCountsResponse());

	const tableColumns = useTableColumnsMemo(
		countsResponse,
		persistedConfigureView.value[configureViewKey]
	);

	const memoFetchFunction = useMemo(
		() => getMyChangeRequestsAction.bind(null, project.id),
		[project.id]
	)

	const memoExportFunction = useMemo(
		() => exportMyAction.bind(null, project.id),
		[project.id]
	)

	const handleRowSelectionChange = useCallback(
		(data: ChangeRequestFullResponse[]) => {
			setSelectedChangeRequest(data[0] || new ChangeRequestFullResponse());
		},
		[]
	);

	const fetchCountsDataCallback = useCallback(
		async () => {
			const bindedAction = getAllMyChangeRequestCountsActionCounts.bind(null, project.id, filtersModel);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setCountsResponse(response.value)
			}
		},
		[filtersModel, project.id]
	)

	useEffect(
		() => {
			fetchCountsDataCallback();
		},
		[fetchCountsDataCallback]
	)

	const statusMemo = useMemo(
		() => persistedChangeRequestStatus.itemsMap[selectedChangeRequest.statusId]?.semantics,
		[selectedChangeRequest, persistedChangeRequestStatus]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='Change Requests'
			// FloatingHelpComponent={ChangeRequestsHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedChangeRequest.id}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					isEditable={!!project.id && !disabledEdit}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
					status={statusMemo}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={dataChangedTopic}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectPicker(MyChangeRequests, ModuleActivityEnum.Scope, true);
