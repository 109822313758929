import { useCallback, useState } from 'react';
import { RadioItemType, Form, RadioField, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ExportDataFileTypeEnum, ModuleActivityEnum, MonthEnum, TimeTravelPermission } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { monthOptions, yearOptions } from 'containers/TimeTravel/utils';
import { ProjectOrCategorySelect, ProjectOrCategoryType, pmOrSpmPermission } from 'features/Project';

class ExportModel {
	years: number[] = []
	months: MonthEnum[] = []
	exportType: ExportDataFileTypeEnum = ExportDataFileTypeEnum.XLSX

	constructor(data?: ExportModel) {
		if (data) {
			this.years = data.years;
			this.months = data.months;
			this.exportType = data.exportType;
		}
	}
}

type Props = {
	save: (nonProjectCategoryId: number | undefined, projectId: number | undefined, years: number[] | undefined, months: MonthEnum[] | undefined, exportType: ExportDataFileTypeEnum) => void
	cancel: () => void
	exportTypeOptions: RadioItemType[]
}

const ExportForm = ({
	save,
	cancel,
	exportTypeOptions,
}: Props) => {
	const [values, setValues] = useState(new ExportModel());
	const [projectOrCategory, setProjectOrCategory] = useState<ProjectOrCategoryType>();

	const submitCallback = useCallback(
		async () => {
			let projectId: number | undefined;
			let nonProjectCategoryId: number | undefined;
			if (projectOrCategory) {
				if (projectOrCategory.isProjectConnected) {
					projectId = projectOrCategory.projectOrCategoryId;
				} else {
					nonProjectCategoryId = projectOrCategory.projectOrCategoryId;
				}
			}

			return save(nonProjectCategoryId, projectId, values.years, values.months, values.exportType);
		},
		[save, values, projectOrCategory]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={cancel}
			submitButtonText='Export'
			render={() => (
				<SmartContainer>
					<SmartItem>
						<RadioField
							id={propertyOf<ExportModel>('exportType')}
							items={exportTypeOptions}
						/>
						<ProjectOrCategorySelect
							value={projectOrCategory}
							onChange={setProjectOrCategory}
							moduleEnum={ModuleActivityEnum.Time}
							teamMemberPermission={propertyOf<TimeTravelPermission>('timesheets')}
							userRolePermission={pmOrSpmPermission}
						/>
						<MultiSelectField
							id={propertyOf<ExportModel>('years')}
							label='Year'
							items={yearOptions}
							getItemId={(item: number) => item}
							getItemText={(item: number) => item.toString()}
						/>
						<MultiSelectField
							id={propertyOf<ExportModel>('months')}
							label='Month'
							items={monthOptions}
							getItemId={(item: MonthEnum) => item}
							getItemText={(item: MonthEnum) => item}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}

export default ExportForm;
