import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { ProjectPickerParams } from 'features/Project';
import { BaseColumnModel } from 'components/Table';
import { Export } from 'features/Export/Export';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';

export type ReportComponentParams = ProjectPickerParams & {
	reportId: string
}

type Props = {
	configureViewKey: string
    tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
};

export const TableButtons = ({
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction
}: Props) => {
	return (
		<div>
			<RowContainer justifyContent='flex-end'>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
					<Export
						filtersModel={filtersModel}
						tableColumns={tableColumns}
						exportAction={exportFunction}
						title='User status per project'
						exportTypeOptions={[ ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX ]}
					/>
				</RowContainer>
			</RowContainer>
		</div>
	);
};
