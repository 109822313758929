import { CompanyInfoIcon, ConfigurationIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";
import { userAdministrationRoute } from "./UserAdministration/routes";
import { generalRoute } from "./General/routes";
import { projectsConfigurationRoute } from "./Projects/routes";
import { moduleRoute } from "./Module/routes";
import { SiteConfigurationSubRoutesEnum } from "./enums";

export const siteConfigurationRouteUrl = `/${RootRouteEnum.SITE_CONFIGURATION}`;

// subroutes

export const companyInformationSubRoute: Route = {
	id: SiteConfigurationSubRoutesEnum.COMPANY_INFOMATION,
	url: `${siteConfigurationRouteUrl}/${SiteConfigurationSubRoutesEnum.COMPANY_INFOMATION}`,
	text: 'Company information',
	Icon: CompanyInfoIcon
}

// root route

export const siteConfigurationRoute: Route = {
	id: RootRouteEnum.SITE_CONFIGURATION,
	url: siteConfigurationRouteUrl,
	text: 'Site Configuration',
	Icon: ConfigurationIcon,
	children: [
		companyInformationSubRoute,
		userAdministrationRoute,
		generalRoute,
		projectsConfigurationRoute,
		moduleRoute
	]
}
