import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectQualityResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getQualityAction, updateQualityAction } from '../action';
import WithFetch from 'features/Fetch/WithFetch';
import { QualityManagementForm as QualityForm } from '../QualityForm';
import { publishQualityManagementChanged } from '../qualityManagementChanged';
import { Dialog } from 'components/Dialog';
import { ReportComponentParams } from '../../Table/TableButtons';
import { useGoBackFromUpdate } from 'features/Crud';

type ParamType = ReportComponentParams & {
	qualityId: string
}

export const UpdateQuality = () => {
	const params: ParamType = useParams();
	const reportId = parseInt(params.reportId);
	const qualityId = parseInt(params.qualityId);

	const [quality, setQuality] = useState(new ProjectQualityResponse ());

	const goBackFromUpdate = useGoBackFromUpdate(true);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getQualityAction.bind(null, reportId, qualityId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setQuality(response.value || new ProjectQualityResponse ());
			}
		},
		[reportId, qualityId]
	)

	const onSubmitCallback = useCallback(
		async (quality: ProjectQualityResponse) => {
			const bindedAction = updateQualityAction.bind(null, reportId, quality);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Quality data successfully updated');
				goBackFromUpdate();
				publishQualityManagementChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, reportId]
	);

	return (
		<Dialog
			title='Update Quality data'
			onClose={goBackFromUpdate}
			open
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<QualityForm quality={quality} save={onSubmitCallback} cancel={goBackFromUpdate} />
			</WithFetch>
		</Dialog>
	)
};
