export const PROJECT_REPORT_CONFIGURE_VIEW_KEYS = {
	MAJOR_ACCOMPLISHMENTS: 'major_accomplishments',
	MILESTONE_PLAN: 'milestone_plan',
	DELIVERABLE_PLAN: 'deliverable_plan',
	MAJOR_ISSUES: 'major_issues',
	CHANGE_REQUEST: 'change_status',
	SUBCONTRACTOR_PERFORMANCE: 'subcontractor_performance',
	RISK_MANAGEMENT: 'risk_management',
	QUALITY_MANAGEMENT: 'quality_management',
	PROJECT_FINANCES: 'project_finances',
	POTENTIAL_ADDITIONAL_BUSINESS: 'potential_additional_business',
	COMMENTS_AND_ACTIONS: 'comments_and_actions',
}
