import { getTenantId } from 'features/Tenant/tenantUtils';
import { ExportDataModel, GenericFilterModelCollection, MultiplyUpdateTrainingPlanStatusRequest, TrainingPlanClient } from 'services/tenantManagementService';

export const getMyTrainingPlanGenericAction = (projectId: number, trainingCycleId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TrainingPlanClient();
	return client.getMy(tenantId, projectId, trainingCycleId, genericFilter);
}

export const updateMyTrainingPlansAction = (projectId: number, trainingCycleId: number, updateMyTrainingPlansRequest: MultiplyUpdateTrainingPlanStatusRequest) => {
	const tenantId = getTenantId()!;
	const client = new TrainingPlanClient();
	return client.bulkUpdateMyStatuses(tenantId, projectId, trainingCycleId, updateMyTrainingPlansRequest);
}

export const exportAction = (projectId: number, trainingCycleId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new TrainingPlanClient();
	return client.exportMyTrainingPlans(tenantId, projectId, trainingCycleId, exportDataModel);
}
