import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud';
import { CreateQuality } from './Crud/CreateQuality';
import { UpdateQuality } from './Crud/UpdateQuality';

export const QualityManagementRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/${CrudSubRoutesEnum.Create}`} component={CreateQuality} />
			<Route path={`${path}/${CrudSubRoutesEnum.Update}/:qualityId`} component={UpdateQuality} />
		</Switch>
	)
}
