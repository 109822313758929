import Tabs from 'components/Tabs/Tabs';
import { ContentShell } from 'features/Content/ContentShell';
import { statusesTabs } from './statusesTabs';

export const Statuses = () => {
	return (
		<ContentShell title='Statuses'>
			<Tabs tabs={statusesTabs} />
		</ContentShell>
	)
}
