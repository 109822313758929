import styles from './content.module.scss'

type Props = {
	title: string
}

const ContentSubtitle = ({ title }: Props) => {
	return (
		<div className={styles.header}>
			<div className={styles.subtitle}>{title}</div>
		</div>
	)
}

export default ContentSubtitle;
