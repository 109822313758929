import { ColumnContainer } from 'components/Layout'
import { RemoteTable } from 'components/Table'
import { setConfigureViewTableAction } from 'features/ConfigureView'
import { ContentShell } from 'features/Content/ContentShell'
import { WithProjectOrCategoryPicker, ProjectOrCategoryComponentProps } from 'features/Project'
import { useCallback, useMemo, useState } from 'react'
import { GenericFilterModelCollection, ModuleActivityEnum } from 'services/tenantManagementService'
import { exportMeetingDecisionsAction, getAllMeetingDecisionsGenericFilterAction } from './action'
import { TableButtons } from './Table/TableButtons'
import { useTableColumnsMemo } from './Table/tableColumns'

const configureViewKey = 'meeting_decisions_table';

const Decisions = ({ projectOrCategory }: ProjectOrCategoryComponentProps) => {
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const tableColumns = useTableColumnsMemo(projectOrCategory, configureViewKey);

	const memoFetchFunction = useMemo(
		() => getAllMeetingDecisionsGenericFilterAction.bind(null, projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected),
		[projectOrCategory]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	const memoExportFunction = useMemo(
		() => exportMeetingDecisionsAction.bind(null, projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected),
		[projectOrCategory]
	)

	return (
		<ContentShell title='Decisions'>
			<ColumnContainer margin='medium'>
				<TableButtons
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					fetchFunction={memoFetchFunction}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectOrCategoryPicker(Decisions, ModuleActivityEnum.Communication, true);
