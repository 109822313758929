import { TabType } from 'components/Tabs/Tabs';
import { ApprovalTabSubroutes } from './ApprovalSubroutes';
import ExpensesApprovalRouter from './Expenses/ExpensesApprovalRouter';
import { ImportTimesheets } from './ImportTimesheets/ImportTimesheets';
import { ImportVacations } from './ImportVacations/ImportVacations';
import TimesheetsApprovalRouter from './Timesheets/TimesheetsApprovalRouter';
import TravelExpensesApprovalRouter from './TravelExpenses/TravelExpensesApprovalRouter';
import TravelRequestsApprovalRouter from './TravelRequests/TravelRequestsApprovalRouter';
import VacationRequestsApprovalRouter from './VacationRequests/VacationRequestsApprovalRouter';

export const approvalTabs: TabType[] = [
	{
		id: '1',
		title: 'Timesheets',
		component: TimesheetsApprovalRouter,
		route: ApprovalTabSubroutes.TIMESHEETS,
	},
	{
		id: '2',
		title: 'Travel requests',
		component: TravelRequestsApprovalRouter,
		route: ApprovalTabSubroutes.TRAVEL_REQUESTS,
	},
	{
		id: '3',
		title: 'Travel expenses',
		component: TravelExpensesApprovalRouter,
		route: ApprovalTabSubroutes.TRAVEL_EXPENSES,
	},
	{
		id: '4',
		title: 'Expenses',
		component: ExpensesApprovalRouter,
		route: ApprovalTabSubroutes.EXPENSES,
	},
	{
		id: '5',
		title: 'Vacation requests',
		component: VacationRequestsApprovalRouter,
		route: ApprovalTabSubroutes.VACATION_REQUESTS,
	},
	{
		id: '6',
		title: 'Upload timesheets',
		component: ImportTimesheets,
		route: ApprovalTabSubroutes.IMPORT_TIMESHEETS,
	},
	{
		id: '7',
		title: 'Upload vacations',
		component: ImportVacations,
		route: ApprovalTabSubroutes.IMPORT_VACATIONS,
	},

];
