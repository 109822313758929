import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { AutoCompleteField, DateField, Form, InputField, TextareaField } from 'components/Form';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { TimeTravelStatusResponse, VacationRequestResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import Button from 'components/Button';
import { RowContainer } from 'components/Layout';

type Props = {
	vacationRequest?: VacationRequestResponse
	crud: CrudEnum
	onSave?: (vacationRequest: VacationRequestResponse, isRelease: boolean) => void
}

const VacationRequestForm = ({
	vacationRequest = new VacationRequestResponse(),
	crud,
	onSave
}: Props) => {
	const {
		persistedTimeAndTravelStatus,
		persistedVacationGeneralConfiguration
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(vacationRequest);

	const isReleaseRef = useRef(false);

	const goBackFromCrud = useGoBackFromCrud(crud);

	const isRead = crud === CrudEnum.Read;
	const isUpdate = crud === CrudEnum.Update;

	const onSubmitCallback = useCallback(
		async () => {
			return onSave && await onSave(values, isReleaseRef.current)
		},
		[onSave, values]
	)

	return (
		<Form
			values={values}
			initialValues={vacationRequest}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			disabled={isRead}
			hideButtons={isRead}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<DateField
							id={propertyOf<VacationRequestResponse>('from')}
							label='From'
							isRequired
						/>
						<DateField
							id={propertyOf<VacationRequestResponse>('to')}
							label='To'
							isRequired
						/>
						{isRead && (
							<InputField
								id={propertyOf<VacationRequestResponse>('numberOfDays')}
								label='Number of days'
								disabled
							/>
						)}
						<TextareaField
							id={propertyOf<VacationRequestResponse>('note')}
							label='Note to approver'
						/>
						{(isRead || isUpdate) && (
							<AutoCompleteField
								id={propertyOf<VacationRequestResponse>('calculatedStatusId')}
								label='Overall status'
								items={persistedTimeAndTravelStatus.items}
								getItemId={(option: TimeTravelStatusResponse) => option.id}
								getItemText={(option: TimeTravelStatusResponse) => option.name!}
								loading={persistedTimeAndTravelStatus.fetching}
								disabled
							/>
						)}
						<InputField
							id={propertyOf<VacationRequestResponse>('customText1')}
							label='Text 1'
						/>
						<InputField
							id={propertyOf<VacationRequestResponse>('customText2')}
							label='Text 2'
						/>
					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={persistedVacationGeneralConfiguration.value.enableApprovalProcess ? (disabled, handleSubmitCallback: () => void, isSubmitting) => (
				<RowContainer>
					<Button
						text='Release for approval'
						disabled={disabled}
						isLoading={isSubmitting && isReleaseRef.current === true}
						onClick={
							() => {
								isReleaseRef.current = true;
								handleSubmitCallback();
							}
						}
					/>
				</RowContainer>
			) : undefined}
		/>
	)
}

export default VacationRequestForm;
