import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import notifications from 'components/Notification/notification';
import { ColumnContainer } from 'components/Layout';
import TableButtons from './Table/TableButtons';
import { useTableColumnsMemo } from './Table/tableColumns';
import { cloneMyExpenseAction, deleteExpenseAction, getAllMyExpensesGenericAction, getAllMyExpensesGenericTotalsAction, releaseMyExpenseAction } from './action';
import { GenericFilterModelCollection, TimeTravelStatusEnum, ExpenseResponse, MonthEnum, ExpenseTotalsResponse } from 'services/tenantManagementService';
import { ContentShell } from 'features/Content/ContentShell';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { ListComponentProps } from 'features/Crud'
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { MyExpensesHelp } from './MyExpensesHelp';
import { getMonthEnumByDate } from 'utils/dateTimeUtils';
import { monthOptions, yearOptions } from '../utils';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { MultiSelect } from 'components/Form';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';

const configureViewKey = 'my_expenses_table';

const MyExpenses = ({ dataChangedTopic, publishDataChanged }: ListComponentProps) => {
	const {	persistedTimeAndTravelStatus, persistedExpenseGeneralConfiguration } = useSelector((state: RootState) => state);

	const [selectedExpense, setSelectedExpense] = useState(new ExpenseResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const [selectedYears, setSelectedYears] = useState<number[]>([new Date().getFullYear()]);
	const [selectedMonths, setSelectedMonths] = useState<MonthEnum[]>([getMonthEnumByDate(new Date())]);

	const [totalsResponse, setTotalsResponse] = useState(new ExpenseTotalsResponse());

	const [isSubmitting, setIsSubmitting] = useState(false);

	const tableColumns = useTableColumnsMemo(totalsResponse, configureViewKey);

	const handleRowSelectionChange = useCallback(
		(data: ExpenseResponse[]) => {
			setSelectedExpense(data[0] || new ExpenseResponse());
		},
		[]
	);

	const deleteExpenseCallback = useCallback(
		async () => {
			setIsSubmitting(true);
			const bindedAction = deleteExpenseAction.bind(null, selectedExpense.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, selectedExpense.id);
				notifications.success(`Expense ${id} is deleted.`);
				setSelectedExpense(new ExpenseResponse());
				publishDataChanged();
			}
			setIsSubmitting(false);
		},
		[publishDataChanged, selectedExpense.id]
	)

	const releaseExpenseCallback = useCallback(
		async () => {
			setIsSubmitting(true);
			const bindedAction = releaseMyExpenseAction.bind(null, selectedExpense.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, selectedExpense.id);
				notifications.success(`Expense ${id} is released.`);
				setSelectedExpense(new ExpenseResponse());
				publishDataChanged();
			}
			setIsSubmitting(false);
		},
		[publishDataChanged, selectedExpense.id]
	)

	const cloneExpenseCallback = useCallback(
		async () => {
			setIsSubmitting(true);
			const bindedAction = cloneMyExpenseAction.bind(null, selectedExpense.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, selectedExpense.id);
				const clonedId = getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, response.value?.id);
				notifications.success(`Expense ${id} is copied into ${clonedId}.`);
				setSelectedExpense(new ExpenseResponse());
				publishDataChanged();
			}
			setIsSubmitting(false);
		},
		[publishDataChanged, selectedExpense.id]
	)

	const isAnyChangeDisabledMemo = useMemo(
		() => !selectedExpense.id ||
			(persistedExpenseGeneralConfiguration.value.enableApprovalProcess && (
				isStatusBySemantic(TimeTravelStatusEnum.Approved, selectedExpense.statusId, persistedTimeAndTravelStatus.itemsMap) ||
				isStatusBySemantic(TimeTravelStatusEnum.Released, selectedExpense.statusId, persistedTimeAndTravelStatus.itemsMap)
			)),
		[selectedExpense, persistedTimeAndTravelStatus, persistedExpenseGeneralConfiguration]
	)

	const fetchTotalsDataCallback = useCallback(
		async () => {
			const bindedAction = getAllMyExpensesGenericTotalsAction.bind(null, selectedYears, selectedMonths, filtersModel);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setTotalsResponse(response.value)
			}
		},
		[filtersModel, selectedYears, selectedMonths]
	)

	const memoFetchFunction = useCallback(
		async (filtersModel: GenericFilterModelCollection) => {
			const [, response] = await Promise.all([
				fetchTotalsDataCallback(),
				getAllMyExpensesGenericAction(selectedYears, selectedMonths, filtersModel)
			])

			return response;
		},
		[selectedYears, selectedMonths, fetchTotalsDataCallback]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='My expenses'
			FloatingHelpComponent={MyExpensesHelp}
		>
			<ColumnContainer margin='medium'>
				<SmartContainer>
					<SmartItem>
						<SmartFormGroup label='Year'>
							<MultiSelect
								value={selectedYears}
								onChange={(newSelectedYears: number[]) => { setSelectedYears(newSelectedYears) }}
								items={yearOptions}
								getItemId={(item: number) => item}
								getItemText={(item: number) => item.toString()}
							/>
						</SmartFormGroup>
						<SmartFormGroup label='Month'>
							<MultiSelect
								value={selectedMonths}
								onChange={(newSelectedMonths: MonthEnum[]) => { setSelectedMonths(newSelectedMonths) }}
								items={monthOptions}
								getItemId={(item: MonthEnum) => item}
								getItemText={(item: MonthEnum) => item}
							/>
						</SmartFormGroup>
					</SmartItem>
				</SmartContainer>
				<TableButtons
					selectedId={selectedExpense.id}
					deleteDisabled={isAnyChangeDisabledMemo}
					changeDisabled={isAnyChangeDisabledMemo}
					releaseDisabled={isAnyChangeDisabledMemo}
					viewDisabled={!selectedExpense.id}
					isReleasable={persistedExpenseGeneralConfiguration.value.enableApprovalProcess}
					disabled={isSubmitting}
					onDelete={deleteExpenseCallback}
					onRelease={releaseExpenseCallback}
					onCopy={cloneExpenseCallback}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={dataChangedTopic}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}
export default MyExpenses;
