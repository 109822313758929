import { useMemo } from 'react';
import { usePartnerCallback, useStatusCallback, useApplyStatusColorCallback, useFormattedCurrencyValueCallback } from 'features/TableColumns/persistedHooks';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { BusinessPartnerResponseModel, InvoiceStatusResponse, ExportDataDecoraterPropertyModel, ProjectFinanceCountsResponse, ProjectFinanceResponse, InvoiceTypeEnum } from 'services/tenantManagementService';
import { incomingOutgoing } from '../FinanceForm';
import { propertyOf } from 'utils/propertyOf';
import { OptionType } from 'components/Form';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
	propertyOf<ProjectFinanceResponse>('invoiceType'),
	propertyOf<ProjectFinanceResponse>('partnerId'),
	propertyOf<ProjectFinanceResponse>('invoiceNumber'),
	propertyOf<ProjectFinanceResponse>('issueDate'),
	propertyOf<ProjectFinanceResponse>('due'),
	propertyOf<ProjectFinanceResponse>('amount'),
	propertyOf<ProjectFinanceResponse>('taxAmount'),
	propertyOf<ProjectFinanceResponse>('totalAmount'),
	propertyOf<ProjectFinanceResponse>('statusId'),
	propertyOf<ProjectFinanceResponse>('name')
]

export const useTableColumnsMemo = (
	countsResponse: ProjectFinanceCountsResponse,
	visibleColumns: string[] = defaultVisibleColumns
) => {
	const {
		persistedBusinessPartner,
		persistedInvoiceStatus
	} = useSelector((state: RootState) => state);

	const getFormattedCurrencyValue = useFormattedCurrencyValueCallback();
	const getPartner = usePartnerCallback(persistedBusinessPartner);
	const getStatus = useStatusCallback(persistedInvoiceStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedInvoiceStatus);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectFinanceResponse>('invoiceNumber')]: {
					title: 'Invoice No.',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectFinanceResponse>('invoiceType')]: {
					title: 'Incoming/Outgoing',
					formatter: (cell: any) =>  {
						const value = cell.getValue()
						const type = incomingOutgoing.find(item => item.id === value)
						return type ? type.text : '';
					},
					fieldType: FieldTypeEnum.Options,
					options: incomingOutgoing,
					getItemId: (item: OptionType) => item.id,
					getItemText: (item: OptionType) => item.text
				},
				[propertyOf<ProjectFinanceResponse>('partnerId')]: {
					title: 'Partner Name',
					formatter: (cell: any) => getPartner(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedBusinessPartner.items,
					getItemId: (option: BusinessPartnerResponseModel) => option.id,
					getItemText: (option: BusinessPartnerResponseModel) => option.name,
					displayNames: ['Partner Name'],
					decoraterProperties : [
						new ExportDataDecoraterPropertyModel({
							name: 'Name',
						})
					]
				},
				[propertyOf<ProjectFinanceResponse>('issueDate')]: {
					title: 'Issue date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectFinanceResponse>('due')]: {
					title: 'Due',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectFinanceResponse>('paymentTerm')]: {
					title: 'Payment Term',
					formatter: (cell: any) => `${cell.getValue()} Day(s)`,
					format: '{0} Day(s)',
					fieldType: FieldTypeEnum.None,
					dbExportFieldPath: null,
					disableFilter: true
				},
				[propertyOf<ProjectFinanceResponse>('amount')]: {
					title: 'Amount (Net)',
					fieldType: FieldTypeEnum.Currency,
					formatter: (cell: any) => {
						let value: number | undefined = cell.getValue();
						const data: ProjectFinanceResponse = cell.getData();
						if (data.invoiceType === InvoiceTypeEnum.Incoming && value)  {
							value *= -1;
						}
						return getFormattedCurrencyValue(value);
					},
					bottomCalc: () => countsResponse.amountSum
				},
				[propertyOf<ProjectFinanceResponse>('taxAmount')]: {
					title: 'Tax amount',
					fieldType: FieldTypeEnum.Currency,
					formatter: (cell: any) => {
						let value: number | undefined = cell.getValue();
						const data: ProjectFinanceResponse = cell.getData();
						if (data.invoiceType === InvoiceTypeEnum.Incoming && value)  {
							value *= -1;
						}
						return getFormattedCurrencyValue(value);
					},
					bottomCalc: () => countsResponse.taxAmountSum
				},
				[propertyOf<ProjectFinanceResponse>('totalAmount')]: {
					title: 'Total amount',
					fieldType: FieldTypeEnum.Currency,
					formatter: (cell: any) => {
						let value: number | undefined = cell.getValue();
						const data: ProjectFinanceResponse = cell.getData();
						if (data.invoiceType === InvoiceTypeEnum.Incoming && value)  {
							value *= -1;
						}
						return getFormattedCurrencyValue(value);
					},
					disableSort: true,
					bottomCalc: () => countsResponse.totalAmountSum
				},
				[propertyOf<ProjectFinanceResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					dbFilterFieldPath: 'StatusRefId',
					dbExportFieldPath: 'Status.Name',
					fieldType: FieldTypeEnum.Options,
					options: persistedInvoiceStatus.items,
					getItemId: (option: InvoiceStatusResponse) => option.id,
					getItemText: (option: InvoiceStatusResponse) => option.name,
					displayNames: ['Status'],
					decoraterProperties : [
						new ExportDataDecoraterPropertyModel({
							name: 'Name',
						})
					]
				},
				[propertyOf<ProjectFinanceResponse>('name')]: {
					title: 'Description',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectFinanceResponse>('description')]: {
					title: 'Comment',
					fieldType: FieldTypeEnum.String
				}
			}
		},
		[getStatus, getPartner, applyStatusColor, persistedInvoiceStatus.items, persistedBusinessPartner.items, countsResponse, getFormattedCurrencyValue]
	)

	return useVisibleColumns(columns, visibleColumns);
}
