import { useCallback,useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectChangeRequestResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getChangeRequestAction, updateChangeRequestAction } from '../action';
import { publishChangeRequestsChanged } from '../changeRequestChanged';
import { Dialog } from 'components/Dialog';
import WithFetch from 'features/Fetch/WithFetch';
import { ChangeRequestForm } from '../ChangeRequestForm';
import { ReportComponentParams } from '../../Table/TableButtons';
import { useGoBackFromUpdate } from 'features/Crud';

type ParamType = ReportComponentParams & {
	changeRequestId: string;
}

export const UpdateChangeRequest = () => {
	const [changeRequest, setChangeRequest] = useState(new ProjectChangeRequestResponse ());
	const params: ParamType = useParams();
	const reportId = parseInt(params.reportId);
	const changeRequestId = parseInt(params.changeRequestId);

	const goBackFromUpdate = useGoBackFromUpdate(true);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getChangeRequestAction.bind(null, reportId, changeRequestId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setChangeRequest(response.value || new ProjectChangeRequestResponse ());
			}
		},
		[reportId, changeRequestId]
	)

	const onSubmitCallback = useCallback(
		async (changeRequest: ProjectChangeRequestResponse) => {
			const bindedAction = updateChangeRequestAction.bind(null, reportId, changeRequest);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Change request successfully updated');
				goBackFromUpdate();
				publishChangeRequestsChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, reportId]
	);

	return (
		<Dialog
			title='Update Change request'
			onClose={goBackFromUpdate}
			open
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<ChangeRequestForm
					changeRequest={changeRequest}
					save={onSubmitCallback}
					cancel={goBackFromUpdate}
				/>
			</WithFetch>
		</Dialog>
	)
};
