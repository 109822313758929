import { getTenantId } from 'features/Tenant/tenantUtils';
import { BudgetConfigurationClient, UpdateBudgetConfigurationRequest } from 'services/tenantManagementService';

export const getBudgetConfigurationAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new BudgetConfigurationClient();
	return client.get(tenantId, projectId);
}

export const updateBudgetConfigurationAction = (projectId: number, model: UpdateBudgetConfigurationRequest ) => {
	const tenantId = getTenantId()!;
	const client = new BudgetConfigurationClient();
	return client.update(tenantId, projectId, model);
}
