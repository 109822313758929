import { HelpShell } from 'features/Help/HelpShell';

export const ProjectReportHelp = () => {
	return (
		<HelpShell title='Project Report'>
			<div>
				<p>
					For each project and reporting period you can generate comprehensive project status report which provides information on multiple project areas:
				</p>
				<ul>
					<li>Relationship (describe client relationship status)</li>
					<li>Staffing (describe resource issues)</li>
					<li>Performance status (describe team performance)</li>
					<li>Major accomplishments from last reporting period</li>
					<li>Milestone plan and status</li>
					<li>Deliverable plan</li>
					<li>Major issues (describe major issues on project, their impact, solution and resolution)</li>
					<li>Change Requests</li>
					<li>Subcontractor performance</li>
					<li>Risks</li>
					<li>Quality (list project quality gates, needed corrective actions and status)</li>
					<li>Finances (list incoming and outgoing invoices and their status)</li>
					<li>Potential Additional Business</li>
					<li>Comments/Actions</li>
				</ul>
			</div>
			<p>
				Report is integrated with other modules and can provide data from scope, scheduling, risks and finance, but also gives possibility to create your own view on all areas shown above regardless of information in other modules. <br />
				You can decide if you would like to report on all areas or only selected ones by including/excluding areas from report. <br />
				Report can be exported as pdf file.
			</p>
			<p>
				Report represent status for defined period and you can <b>copy</b> all information in new report for new reporting period. Copy function will significantly reduce efforts of project report maintenance.
			</p>
		</HelpShell>
	)
}
