import { CrudButtons } from 'features/Crud';
import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { BaseColumnModel } from 'components/Table';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { Export } from 'features/Export/Export';

type Props = {
	selectedId: number | undefined
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	isEditable: boolean
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

const TableButtons = ({ selectedId, configureViewKey, tableColumns, isEditable, filtersModel, exportFunction }: Props) => {
	return (
		<RowContainer justifyContent='space-between'>
			<RowContainer>
				<CrudButtons
					selectedId={selectedId}
					isCreateable={false}
					isViewable={!isEditable}
					isChangeable={isEditable}
					isDeleteable={false}
					labels={{
						change: 'Go to test case'
					}}
				/>
			</RowContainer>
			<RowContainer>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				<Export
					filtersModel={filtersModel}
					tableColumns={tableColumns}
					exportAction={exportFunction}
					exportTypeOptions={EXPORT_TYPE_OPTIONS}
					title='Tasks'
				/>
			</RowContainer>
		</RowContainer>

	)
}

export default TableButtons;
