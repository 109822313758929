import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Spinner, { ClipSpinner } from 'components/Spinner';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { confirmRegistrationAction, confirmRegistrationStatusAction } from './action';
import { TenantRegistrationConfirmStatusResponseModel } from 'services/tenantManagementService';
import { Title, Subtitle } from '../_components';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const INTERVAL_PULL_STATUS = 5000;

const ConfirmRegistration = () => {
	const [isFailed, setIsFailed] = useState(false);
	const [isInProgress, setIsInProgress] = useState(false);
	const [isFinished, setIsFinished] = useState(false);

	const [link, setLink] = useState('');

	const query = useQuery();

	const languageCode = query.get('languageCode');
	const tenantId = parseFloat(query.get('tenantId') as string);
	const token = query.get('token') as string;
	const userId = parseFloat(query.get('userId') as string);

	const confirmRegistrationCallback = useCallback(
		async () => {
			const bindedAction = confirmRegistrationAction.bind(null, token, userId, tenantId, languageCode);
			const response: TenantRegistrationConfirmStatusResponseModel = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setIsInProgress(response.isInProgress);
				setIsFinished(response.isFinished);
				setIsFailed(false);
				setLink(response.link);
			}
			else {
				setIsFailed(true);
			}
		},
		[languageCode, tenantId, token, userId]
	)

	const confirmRegistrationStatusCallback = useCallback(
		async () => {
			const bindedAction = confirmRegistrationStatusAction.bind(null, tenantId, userId);
			const response: TenantRegistrationConfirmStatusResponseModel = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setIsInProgress(response.isInProgress);
				setIsFinished(response.isFinished);
				setIsFailed(false);
				setLink(response.link);
			}
			else {
				setIsFailed(true);
			}
		},
		[tenantId, userId]
	)

	useEffect(
		() => {
			confirmRegistrationCallback();
		},
		[confirmRegistrationCallback]
	)

	useEffect(
		() => {
			const timeHandler = setInterval(
				() => {
					if (isInProgress && !isFailed) {
						confirmRegistrationStatusCallback();
					}
				},
				INTERVAL_PULL_STATUS
			);


			return () => clearInterval(timeHandler);
		},
		[confirmRegistrationStatusCallback, isFailed, isInProgress]
	)

	const content = useMemo(
		() => {
			if (isFailed) {
				return (
					<div>
						Please contact support, we were not able to confirm your registration.
					</div>
				)
			}
			else if (isFinished) {
				return (
					<>
						<Subtitle text='Your subdomain is created. Please finish your registration on your subdomain, by opening next link: ' />
						<a href={link}>{link}</a>
					</>
				)
			}
			else {
				return (
					<Spinner text='Registration of your domain is in progress. It usually takes less than a minute to complete. Please wait...'>
						<ClipSpinner size={80} />
					</Spinner>
				)
			}
		},
		[isFailed, isFinished, link]
	)

	return (
		<>
			<Title text='Confirming Registration' />
			<div style={{ maxHeight: '300px' }}>
				{content}
			</div>
		</>
	)
}

export default ConfirmRegistration;
