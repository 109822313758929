import { TestingIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";

// subroutes

export enum TestingSubRoutesEnum {
	MY_DASHBOARD = 'testing_my_dashboard',
	MY_TEST_PACKAGES = 'my_test_packages',
	TEST_CYCLES = 'test_cycles',
	TEST_PLANS = 'test_plans',
	TEST_STATUS = 'test_status',
}

export const myDashboardSubRoute: Route = {
	id: TestingSubRoutesEnum.MY_DASHBOARD,
	url: `/${RootRouteEnum.TESTING}/${TestingSubRoutesEnum.MY_DASHBOARD}`,
	text: 'My Dashboard',
}

export const myTestPackagesSubRoute: Route = {
	id: TestingSubRoutesEnum.MY_TEST_PACKAGES,
	url: `/${RootRouteEnum.TESTING}/${TestingSubRoutesEnum.MY_TEST_PACKAGES}`,
	text: 'My test packages',
}

export const testCyclesSubRoute: Route = {
	id: TestingSubRoutesEnum.TEST_CYCLES,
	url: `/${RootRouteEnum.TESTING}/${TestingSubRoutesEnum.TEST_CYCLES}`,
	text: 'Test cycles',
}

export const testPlansSubRoute: Route = {
	id: TestingSubRoutesEnum.TEST_PLANS,
	url: `/${RootRouteEnum.TESTING}/${TestingSubRoutesEnum.TEST_PLANS}`,
	text: 'Test plans',
}

export const testStatusSubRoute: Route = {
	id: TestingSubRoutesEnum.TEST_STATUS,
	url: `/${RootRouteEnum.TESTING}/${TestingSubRoutesEnum.TEST_STATUS}`,
	text: 'Test status',
}

// root route

export const testingRoute: Route = {
	id: RootRouteEnum.TESTING,
	url: `/${RootRouteEnum.TESTING}`,
	text: 'Testing',
	Icon: TestingIcon,
	children: [
		myDashboardSubRoute,
		myTestPackagesSubRoute,
		testCyclesSubRoute,
		testPlansSubRoute,
		testStatusSubRoute
	]
}
