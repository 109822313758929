import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';
import { ProjectIssueResponse } from 'services/tenantManagementService';

const defaultVisibleColumns = [
	propertyOf<ProjectIssueResponse>('isActive'),
	propertyOf<ProjectIssueResponse>('name'),
	propertyOf<ProjectIssueResponse>('description'),
	propertyOf<ProjectIssueResponse>('impact'),
	propertyOf<ProjectIssueResponse>('solution'),
	propertyOf<ProjectIssueResponse>('resolution')
]

export const useTableColumnsMemo = (visibleColumns: string[] = defaultVisibleColumns) => {
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectIssueResponse>('isActive')]: {
					title: 'Active',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<ProjectIssueResponse>('name')]: {
					title: 'Name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectIssueResponse>('description')]: {
					title: 'Description',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectIssueResponse>('impact')]: {
					title: 'Impact',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectIssueResponse>('solution')]: {
					title: 'Solution',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectIssueResponse>('resolution')]: {
					title: 'Resolution',
					fieldType: FieldTypeEnum.String
				}
			}
		},
		[]
	)

	return useVisibleColumns(columns, visibleColumns);
}
