import { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "base/reducer/reducer"
import { ThemeToggleIcon } from "components/icons/icons"
import { ThemeEnum, setThemeAction, setThemeLocalStorage } from "features/Theme"
import styles from './header.module.scss'

type Props = {
	iconSize: string
}

export const ThemeToggler = ({ iconSize }: Props) => {
	const dispatch = useDispatch()
	const theme = useSelector((state: RootState) => state.theme)

	const toggleThemeCallback = useCallback(
		() => {
			const nextTheme = theme === ThemeEnum.Dark ? ThemeEnum.Light : ThemeEnum.Dark;
			dispatch(setThemeAction(nextTheme));
			setThemeLocalStorage(nextTheme);
		},
		[theme, dispatch]
	)

	return (
		<div className={styles.item}>
			<ThemeToggleIcon
				width={iconSize}
				height={iconSize}
				fill='currentColor'
				onClick={toggleThemeCallback}
			/>
		</div>
	)
}
