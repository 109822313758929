import { View, Text, StyleSheet } from '@react-pdf/renderer';

type Props = {
	label: string
	value?: string
	valueBackgroundColor?: string
	marginBottom?: number
}

const FormField = ({ label, value, valueBackgroundColor = '#fff', marginBottom }: Props) => (
	<View style={[styles.container, {marginBottom: marginBottom}]}>
		<Text style={styles.label}>
			{label}:
		</Text>
		<View style={styles.valueContainer}>
			<Text style={[styles.value, { backgroundColor: valueBackgroundColor }]}>
				{value || '-'}
			</Text>
		</View>
	</View>
);

export default FormField;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	label: {
		paddingVertical: 2,
		maxWidth: 200,
		minWidth: 200,
		width: 200,
		fontSize: 10,
	},
	valueContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		flex: 1,
	},
	value: {
		paddingHorizontal: 2,
		paddingVertical: 2,
		fontSize: 10,
		overflow: 'hidden',
		// textOverflow: 'ellipsis',
	},
});
