import { View, Text, StyleSheet} from '@react-pdf/renderer';

type Props = {
	label: string
	value: boolean
	valueBackgroundColor?: string
}

const RadioItemFormField = ({ label, value, valueBackgroundColor = '#fff' }: Props) => (
	<View style={styles.container}>
		<View style={styles.valueContainer}>
			{value && (
				<View style={styles.checked} />
			)}
		</View>
		<Text style={styles.label}>
			{label}
		</Text>
	</View>
);


export default RadioItemFormField;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		margin: 4
	},
	label: {
		fontSize: 10,
		paddingLeft: 10
	},
	valueContainer: {
		border: '1px solid #000000',
		borderRadius: '50%',
		width: 16,
		height: 16,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
	checked: {
		width: 8,
		height: 8,
		border: '1px solid #000000',
		borderRadius: '50%',
		backgroundColor: '#000000'
	}
});
