import { getTenantId } from 'features/Tenant/tenantUtils';
import { InsertTicketCommnetRequest, TicketCommentClient, UpdateTicketCommentRequest } from 'services/tenantManagementService';

export const getTicketCommentsAction = (ticketId: number) => {
	const tenantId = getTenantId()!;
	const client = new TicketCommentClient();
	return client.getAll(tenantId, ticketId, undefined, undefined);
}

export const createTicketCommentAction = (ticketId: number, model: InsertTicketCommnetRequest) => {
	const tenantId = getTenantId()!;
	const client = new TicketCommentClient();
	return client.create(tenantId, ticketId, model);
}

export const updateTicketCommentAction = (ticketId: number, model: UpdateTicketCommentRequest) => {
	const tenantId = getTenantId()!;
	const client = new TicketCommentClient();
	return client.update(tenantId, ticketId, model);
}

export const deleteTicketCommentAction = (ticketId: number) => {
    const tenantId = getTenantId()!;
    const client = new TicketCommentClient();
    return client.delete(tenantId, ticketId);
}

export const downloadTicketCommentAttachmentAction = (ticketCommentId: number, attachmentId: number) => {
    const tenantId = getTenantId()!;
    const client = new TicketCommentClient();
    return client.downloadAttachment(tenantId, ticketCommentId, attachmentId);
}
