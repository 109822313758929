import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ModuleActivityEnum, ProjectTimeTravelDashboardResponse, } from 'services/tenantManagementService'
import { Dashboard } from 'features/Dashboard/Dashboard'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { RootState } from 'base/reducer/reducer'
import { Layout } from 'features/Dashboard/Layout'
import { TextWidget } from 'features/Dashboard/Widget/TextWidget'
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget'
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks'
import styles from './teamBillabilityDashboard.module.scss';
import { warningColor, successColor } from 'features/ThemeProvider/amchartCustomTheme'
import { sortByString } from 'utils/stringUtil'
import { pmOrSpmOrOumPermission, useProjects } from 'features/Project'

type Props = {
	loading: boolean
	dashboard: ProjectTimeTravelDashboardResponse
}

export const TeamBillabilityDashboard = ({ loading, dashboard }: Props) => {

	const { persistedTimeTravelNonProjectCategories, persistedUser } = useSelector((state: RootState) => state);

	const getUserFullname = useUserFullNameCallback(persistedUser);

	const { projects } = useProjects({
		isProjectDashboard: true,
		moduleEnum: ModuleActivityEnum.Time,
		userRolePermission: pmOrSpmOrOumPermission,
		isTimeAndTravelProjectDashboard: true
	});

	const hoursPerProjectAndNonProjectCategoryDataMemo = useMemo(
		() => {
			const projectData = Object.keys(dashboard.teamProductivity?.hoursPerProject || {}).map(projectKeyId => {
				const projectId: number = parseInt(projectKeyId);
				return {
					id: `project_${projectId}`,
					name: `${projects.find(x => x.id === projectId)?.name}`,
					hours: dashboard.teamProductivity?.hoursPerProject![projectId],
				};
			});

			const categoryData = Object.keys(dashboard.teamProductivity?.hoursPerNonProjectCategory || {}).map(id => {
				return {
					id: `category_${id}`,
					name: persistedTimeTravelNonProjectCategories.itemsMap[parseInt(id)]?.name || '',
					hours: dashboard.teamProductivity?.hoursPerNonProjectCategory![id],
				};
			});

			return [...projectData, ...categoryData];
		},
		[dashboard, persistedTimeTravelNonProjectCategories, projects]
	)

	const hoursPerBillabilityDataMemo = useMemo(
		() =>  {
			return dashboard.teamProductivity?.hoursPerBillability ?
				[
					{ id: 'True', name: 'Productive', hours: dashboard.teamProductivity?.hoursPerBillability['True'], color: successColor},
					{ id: 'False', name: '', hours: dashboard.teamProductivity?.hoursPerBillability['False'], color: warningColor},
				] :
				[]
		},
		[dashboard]
	)

	const columnSeriesHoursPerUserAndBillabilityDataMemo = useMemo(
		() => {
			const data: any[] = [];

			for (const userId in dashboard.teamProductivity?.hoursPerUserAndBillability) {
				const object = dashboard.teamProductivity?.hoursPerUserAndBillability[userId];
				data.push({
					username: getUserFullname(parseInt(userId)),
					...object
				});
			}

			return sortByString(data, 'username');
		},
		[dashboard, getUserFullname]
	)

	const columnSeriesHoursPerUserAndNotBillableNonProjectCategories = useMemo(
		() => {
			const data: any[] = [];

			for (const userId in dashboard.teamProductivity?.hoursPerUserAndNotBillableNonProjectCategories) {
				const object = dashboard.teamProductivity?.hoursPerUserAndNotBillableNonProjectCategories[userId];
				data.push({
					username: getUserFullname(parseInt(userId)),
					...object
				});
			}

			return sortByString(data, 'username');
		},
		[dashboard, getUserFullname]
	)

	const columnSeriesHoursPerUserAndBillabilityFieldsMemo = useMemo(
		() => {
			return [
				{value: 'True', name: 'Productive', color: successColor},
				{value: 'False', name: 'Not productive', color: warningColor},
			]
		},
		[]
	)

	const columnSeriesHoursPerUserAndNotBillableNonProjectCategoriesFieldsMemo = useMemo(
		() => persistedTimeTravelNonProjectCategories.items.map(x => {
			return {
				value: x.id.toString(),
				name: x.name
			}
		}),
		[persistedTimeTravelNonProjectCategories]
	)

	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='vertical'>
				<Layout
					orientation='horizontal'
					className='col-12'
				>
					<TextWidget
						className='col-2'
						title='Total productive timesheet hours'
						value={dashboard.teamProductivity?.totalBillableTimesheetHours}
						loading={loading}
						toFixed
					/>
					<TextWidget
						className='col-2'
						title='Max number of working hours per selected period'
						value={dashboard.teamProductivity?.totalPotentialProductiveHours}
						loading={loading}
						toFixed
					/>
					<TextWidget
						className='col-2'
						title='Not productive hours (Max hours - productive hours)'
						value={dashboard.teamProductivity?.totalNotProductiveHours}
						loading={loading}
						toFixed
					/>
					<TextWidget
						className='col-2'
						title='Hours on non project categories'
						value={dashboard.teamProductivity?.totalNonBillableTimesheetHours}
						loading={loading}
						toFixed
					/>
					<TextWidget
						className='col-2'
						title='Total timesheet cost rate'
						value={dashboard.teamProductivity?.totalTimesheetCostRate}
						loading={loading}
						toFixed
					/>
					<TextWidget
						className='col-2'
						title='Total timesheet billing rate'
						value={dashboard.teamProductivity?.totalTimesheetBillingRate}
						loading={loading}
						toFixed
					/>
				</Layout>
				<Layout orientation='horizontal'>
					<PieWidget
						className='col-6'
						title='Timesheets per category'
						id='timesheets_per_category_pie_chart'
						fieldValue='hours'
						fieldCategory='name'
						data={hoursPerProjectAndNonProjectCategoryDataMemo}
						loading={loading}
						usePercentAndValueForLegend
					/>
					<PieWidget
						className='col-6'
						title='Overall productivity'
						id='approved_hours_per_billability_pie_chart'
						fieldValue='hours'
						fieldCategory='name'
						data={hoursPerBillabilityDataMemo}
						loading={loading}
						usePercentAndValueForLegend
					/>
				</Layout>
			</Layout>
			<Layout orientation='horizontal'>
				<RotatedColumnSeriesWidget
					className='col-6'
					title='Productivity per user'
					id='hours_per_user_and_billability_series_chart'
					contentClassName={styles.productivityPerUserContent}
					data={columnSeriesHoursPerUserAndBillabilityDataMemo}
					fieldCategory='username'
					stacked
					fieldValues={columnSeriesHoursPerUserAndBillabilityFieldsMemo}
					loading={loading}
					showValueOnBar
				/>
				<RotatedColumnSeriesWidget
					className='col-6'
					title='Not productive hours on non project categories'
					id='hours_per_user_and_non_billable_non_project_categories_series_chart'
					contentClassName={styles.productivityPerUserContent}
					data={columnSeriesHoursPerUserAndNotBillableNonProjectCategories}
					fieldCategory='username'
					stacked
					fieldValues={columnSeriesHoursPerUserAndNotBillableNonProjectCategoriesFieldsMemo}
					loading={loading}
					showValueOnBar
				/>
			</Layout>

		</Dashboard>
	)
}
