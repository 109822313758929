import { HelpShell } from 'features/Help/HelpShell';

export const TrainingPlanHelp = () => {
	return (
		<HelpShell title='Training plan'>
			<p>Training plan is generated for each training cycle.</p>
			<p>All processes from scope module are replicated in training plan.</p>
			<p>Training plan can be created only for level 3 scope items - processes.</p>
			<p>
				First step is creating training scope for specific training cycle by selecting processes that will be included in training plan (mark 'include in training scope'). <br />
				You can filter process ID's, and include or exclude all selected items.
			</p>
			<p>For each process, you can define trainer, participants, planned date, planned time, duration and location and provide links to training documentation.</p>
			<p>When user is assigned as participant and training cycle marked as active, he/she can access his training plan in My Training package section.</p>
		</HelpShell>
	)
}
