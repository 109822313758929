import { ColumnContainer } from 'components/Layout'
import { StatusDashboard } from './StatusDashboard/StatusDashboard'
import { SurveyDashboard } from './SurveyDashboard/SurveyDashboard'

export const TrainingDashboard = () => {
	return (
		<ColumnContainer>
			<StatusDashboard />
			<SurveyDashboard />
		</ColumnContainer>
	)
}
