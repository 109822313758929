import { getTenantId } from 'features/Tenant/tenantUtils';
import { GenericFilterModelCollection, RiskClient, InsertRiskRequest, UpdateRiskRequest, ExportDataModel } from 'services/tenantManagementService';

export const getAllRisksGenericFilterAction = (projectId: number, filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new RiskClient();
	return client.getAllGenericFilter(tenantId, projectId, filter);
}

export const getAllRisksGenericCountsAction = (projectId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new RiskClient();
	return client.getAllGenericFilterCounts(tenantId, projectId, genericFilter);
}

export const getRiskAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new RiskClient();
	return client.get(tenantId, projectId, id);
}

export const getAllRiskAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new RiskClient();
	return client.getAll(tenantId, projectId);
}

export const createRiskAction = (projectId: number, model: InsertRiskRequest) => {
	const tenantId = getTenantId()!;
	const client = new RiskClient();
	return client.create(tenantId, projectId, model);
}

export const updateRiskAction = (projectId: number, model: UpdateRiskRequest) => {
	const tenantId = getTenantId()!;
	const client = new RiskClient();
	return client.update(tenantId, projectId, model);
}

export const deleteRiskAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new RiskClient();
	return client.delete(tenantId, projectId, id);
}

export const exportRisksAction = (projectId: number, filter: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new RiskClient();
	return client.exportAll(tenantId, projectId, filter);
}
