import { useCallback, useMemo, useState } from 'react';
import { InsertOrganizationalUnitRequest, OrganizationalUnitResponse } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { createOrganizationalUnitAction } from '../action';
import { OrganizationalUnitForm } from './OrganizationalUnitForm';
import styles from 'components/Map/map.module.scss'
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

type Props = {
	items: OrganizationalUnitResponse[]
	level: number
	parentId?: number
	fetchData(): Promise<void>
}

export const CreateItem = ({ items, level, parentId, fetchData }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { persistedCountry } = useSelector((state: RootState) => state);

	const openDialogCallback = useCallback(
		() => setIsModalOpen(true),
		[]
	)

	const closeDialogCallback  = useCallback(
		() => setIsModalOpen(false),
		[]
	)

	const saveCallback = useCallback(
		async (newItem: OrganizationalUnitResponse, countryId: number) => {
			const model = new InsertOrganizationalUnitRequest({
				...newItem,
				parentId: parentId!,
				name: level === 2 ? persistedCountry.itemsMap[countryId]?.name! : newItem.name,
			});

			const bindedAction = createOrganizationalUnitAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`Item ${model.name} is created`);
				closeDialogCallback();
				await fetchData();
			} else {
				return convertResponseErrors(response);
			}
		},
		[fetchData, closeDialogCallback, parentId, persistedCountry.itemsMap, level]
	)

	const newItem = useMemo(
		() => {
			const model = new OrganizationalUnitResponse();
			model.levelNumber = level;
			model.parentId = parentId;
			model.order = items.length + 1;
			return model;
		},
		[level, parentId, items]
	)

	return (
		<>
			<div className={styles.item_add} onClick={openDialogCallback}>
				Add
			</div>
			<Dialog
				open={isModalOpen}
				title={level === 2 ? `Create country item` : `Create level ${level - 2} item`}
				onClose={closeDialogCallback}
			>
				<OrganizationalUnitForm
					item={newItem}
					onSave={saveCallback}
					onCancel={closeDialogCallback}
				/>
			</Dialog>
		</>
	)
}
