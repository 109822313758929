import { getTicketTypesAction, updateTicketTypesAction } from './action';
import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';

export const TicketTypes = () => {
	return (
		<TenantIsActiveTable
			getConfigsAction={getTicketTypesAction}
			updateConfigsAction={updateTicketTypesAction}
			successMessage='Ticket types are updated.'
			label={{ name: 'Type Name' }}
		/>
	)
};
