import { getTenantId } from 'features/Tenant/tenantUtils';
import { TrainingCycleClient, TrainingCycleDeltaRequest } from 'services/tenantManagementService';

export const getTrainingCyclesAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new TrainingCycleClient();
	return client.getAll(tenantId, projectId, undefined, undefined);
}

export const updateTrainingCyclesAction = (projectId: number, model: TrainingCycleDeltaRequest ) => {
	const tenantId = getTenantId()!;
	const client = new TrainingCycleClient();
	return client.update(tenantId, projectId, model);
}
