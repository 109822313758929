import { useMemo } from 'react'
import { Dashboard } from 'features/Dashboard/Dashboard'
import { Layout } from 'features/Dashboard/Layout'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { LocalTableWidget } from 'features/Dashboard/Widget/LocalTableWidget'
import { useTableColumnsMemo } from './tableColumns'
import { TabComponentProps } from '../../FilteredDashboard'
import { BudgetVsActualRowDashboardResponse } from 'services/tenantManagementService'

export const BudgetActualDashboard = ({ loading, dashboard, filterModel }: TabComponentProps) => {
	const tableColumnsExpenses = useTableColumnsMemo(
		dashboard.budgetVsActual?.budgetExpense,
		dashboard.budgetVsActual?.actualBudgetExpense,
		dashboard.budgetVsActual?.differenceExpense,
		dashboard.budgetVsActual?.budgetExpense ? dashboard.budgetVsActual.actualBudgetExpense / dashboard.budgetVsActual?.budgetExpense * 100 : 0,
		true
	);

	const tableColumnsRevenues = useTableColumnsMemo(
		dashboard.budgetVsActual?.budgetIncome,
		dashboard.budgetVsActual?.actualBudgetIncome,
		dashboard.budgetVsActual?.differenceIncome,
		dashboard.budgetVsActual?.budgetIncome ? dashboard.budgetVsActual.actualBudgetIncome / dashboard.budgetVsActual?.budgetIncome * 100 : 0,
	);

	const tableColumnsProfit = useTableColumnsMemo(
		dashboard.budgetVsActual?.budgetProfit,
		dashboard.budgetVsActual?.actualBudgetProfit,
		dashboard.budgetVsActual?.differenceProfit,
		dashboard.budgetVsActual?.budgetProfit ? dashboard.budgetVsActual.actualBudgetProfit / dashboard.budgetVsActual?.budgetProfit * 100 : 0,
	);

	const revenuesDataMemo = useMemo(
		() => {
			const data = dashboard.budgetVsActual;
			if (!data) {
				return [];
			}

			return [
				{
					name: 'Budget',
					count: data?.budgetIncome,
					hidden: true,
					legendItemPosition: 1
				},
				{
					name: 'Actual',
					count: data?.actualBudgetIncome,
					hidden: false,
					legendItemPosition: 2
				},
				{
					name: 'Difference',
					count: data?.differenceIncome,
					hidden: (data?.differenceIncome || 0) < 0,
					legendItemPosition: 3
				}
			]
		},
		[dashboard]
	);

	const expensesDataMemo = useMemo(
		() => {
			const data = dashboard.budgetVsActual;
			if (!data) {
				return [];
			}

			return [
				{
					name: 'Budget',
                    count: data?.budgetExpense,
					hidden: true,
					legendItemPosition: 1
				},
				{
					name: 'Actual',
					count: data?.actualBudgetExpense,
					hidden: false,
					legendItemPosition: 2
				},
				{
					name: 'Difference',
					count: data?.differenceExpense,
					hidden: (data?.differenceExpense || 0) < 0,
					legendItemPosition: 3
				}
			]
		},
		[dashboard]
	);

	const profitDataMemo = useMemo(
		() => {
			const data = dashboard.budgetVsActual;
			if (!data) {
				return [];
			}

			return [
				{
					name: 'Budget',
                    count: data?.budgetProfit,
					hidden: true,
					legendItemPosition: 1
				},
				{
					name: 'Actual',
					count: data?.actualBudgetProfit,
					hidden: false,
					legendItemPosition: 2
				},
				{
					name: 'Difference',
					count: data?.differenceProfit,
					hidden: (data?.differenceProfit || 0) < 0,
					legendItemPosition: 3
				}
			]
		},
		[dashboard]
	);

	const budgetVsActualExpenses = useMemo(
		() => {
			const data: BudgetVsActualRowDashboardResponse[] = [];
			for (const item of dashboard.budgetVsActual?.budgetVsActualExpenses || []) {
				data.push(new BudgetVsActualRowDashboardResponse({
					...item,
				    budgetValue: item.budgetValue ? -item.budgetValue : item.budgetValue,
				    actualBudgetValue: item.actualBudgetValue ? -item.actualBudgetValue : item.actualBudgetValue
				}))
			}

			return data;
		},
		[dashboard.budgetVsActual?.budgetVsActualExpenses]
	)

	// we want pagination to work for years, so only one year is shown per page
	const tableMonthsLimit = filterModel.months?.length || 12;

  	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='horizontal'>
				<PieWidget
					className='col-lg-4 col-md-6'
					title='Revenues'
					id='revenues_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={revenuesDataMemo}
					loading={loading}
					useValueForLegend
				/>
				<PieWidget
					className='col-lg-4 col-md-6'
					title='Expenses'
					id='expenses_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={expensesDataMemo}
					loading={loading}
					useValueForLegend
				/>
				<PieWidget
					className='col-lg-4 col-md-6'
					title='Profit'
					id='profit_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={profitDataMemo}
					loading={loading}
					useValueForLegend
				/>
			</Layout>
			<Layout orientation='horizontal'>
				<LocalTableWidget
					className='col-lg-6'
					title='Revenues budget vs actual'
					columns={tableColumnsRevenues}
					data={dashboard.budgetVsActual?.budgetVsActualIncomes || []}
					loading={loading}
					hasPagination
					limit={tableMonthsLimit}
					options={{
						layout: 'fitColumns'
					}}
				/>
				<LocalTableWidget
					className='col-lg-6'
					title='Expenses budget vs actual'
					columns={tableColumnsExpenses}
					data={budgetVsActualExpenses}
					loading={loading}
					hasPagination
					limit={tableMonthsLimit}
					options={{
						layout: 'fitColumns'
					}}
				/>
				<LocalTableWidget
					className='col-lg-6'
					title='Profit budget vs actual'
					columns={tableColumnsProfit}
					data={dashboard.budgetVsActual?.budgetVsActualProfit || []}
					loading={loading}
					hasPagination
					limit={tableMonthsLimit}
					options={{
						layout: 'fitColumns'
					}}
				/>
			</Layout>
		</Dashboard>
  	)
}
