import { useCallback, useState } from 'react';
import { BusinessPartnerResponseModel, GenericFilterModelCollection } from 'services/tenantManagementService';
import { TableButtons } from '../TableButtons';
import { ColumnContainer } from 'components/Layout';
import { useTableColumnsMemo } from './tableColumns';
import { exportAction, getBusinessPartnersGenericAction } from './action';
import { ContentShell } from 'features/Content/ContentShell';
import { ListComponentProps } from 'features/Crud';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { PartnersHelp } from './Help/PartnersHelp';

const configureViewKey='partners_configuration';

export const Partners = ({ dataChangedTopic }: ListComponentProps) => {
	const [selectedPartner, setSelectedPartner] = useState(new BusinessPartnerResponseModel());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const tableColumns = useTableColumnsMemo(configureViewKey);

	const handleRowSelectionChange = useCallback(
        (data: BusinessPartnerResponseModel[]) => setSelectedPartner(data[0] || new BusinessPartnerResponseModel()),
		[]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='Create Partner'
			FloatingHelpComponent={PartnersHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedPartner.id}
					isDeleteable={false}
					configureViewKey={configureViewKey}
					tableColumns={tableColumns}
					filtersModel={filtersModel}
					exportFunction={exportAction}
					exportTitle={'Partners'}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={dataChangedTopic}
					fetchFunction={getBusinessPartnersGenericAction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}
