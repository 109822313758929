import { useCallback, useState } from 'react';
import { OrganizationalUnitResponse, UpdateOrganizationalUnitRequest } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import { EditIcon } from 'components/icons/icons';
import { updateOrganizationalUnitsAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { OrganizationalUnitForm } from './OrganizationalUnitForm';
import styles from 'components/Map/map.module.scss'
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

type Props = {
	item: OrganizationalUnitResponse
	fetchData(): Promise<void>
}

export const UpdateItem = ({ item, fetchData }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { persistedCountry } = useSelector((state: RootState) => state);

	const openDialogCallback = useCallback(
		(e) => {
			e.stopPropagation();
			setIsModalOpen(true);
		},
		[]
	);

	const closeDialogCallback = useCallback(
		() => {
			setIsModalOpen(false);
		},
		[]
	);

	const updateCallback = useCallback(
		async (newItem: OrganizationalUnitResponse, countryId: number) => {
			const model = new UpdateOrganizationalUnitRequest({
				...newItem,
				name: item.levelNumber === 2 ? persistedCountry.itemsMap[countryId]?.name! : newItem.name,
			});
			const bindedAction = updateOrganizationalUnitsAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Organizational unit item is updated');
				closeDialogCallback();
				await fetchData();
			} else {
				return convertResponseErrors(response);
			}
		},
		[fetchData, closeDialogCallback, persistedCountry.itemsMap, item.levelNumber]
	)

	return (
		<div className={styles.item_edit}>
			<EditIcon width={12} height={12} fill='currentColor' onClick={openDialogCallback} />
			<Dialog
				open={isModalOpen}
				title={item.levelNumber === 2 ? `Update country item` : `Update level ${item.levelNumber - 2} item`}
				onClose={closeDialogCallback}
			>
				<OrganizationalUnitForm
					item={item}
					onSave={updateCallback}
					onCancel={closeDialogCallback}
				/>
			</Dialog>
		</div>
	)
}
