import { useCallback, useEffect, useState } from 'react';
import Tabs from 'components/Tabs/Tabs';
import { maintainProjectTabs } from './Tabs/maintainProjectTabs';
import { useParams } from 'react-router-dom';
import { getProjectAction } from 'features/Project';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { ProjectResponse } from 'services/tenantManagementService';
import WithFetch from 'features/Fetch/WithFetch';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { ContentShell } from 'features/Content/ContentShell';
import { MyProjectsHelp } from '../Help/MyProjectsHelp';
import { projectChangedTopic } from './projectChanged';

type ParamType = {
	projectId: string;
}

export const MyProjectsMaintain = () => {
	const [project, setProject] = useState(new ProjectResponse());
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getProjectAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setProject(response.value || new ProjectResponse());
			}
		},
		[projectId]
	)

	useEffect(
		() => {
			const subscription = projectChangedTopic.subscribe(fetchDataCallback);
			return () => {
				subscription.unsubscribe();
			}
		},
		[fetchDataCallback]
	)

	return (
		<ContentShell
			title={`Maintain Project - ${getFormatedId(EntityPrefixEnum.PROJECT, projectId)} ${project.name || ''}`}
			FloatingHelpComponent={MyProjectsHelp}
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<Tabs
					tabs={maintainProjectTabs}
					tabComponentProps={{ project }}
				/>
			</WithFetch>
		</ContentShell>
	)
}
