import { BaseColumnModel } from 'components/Table';
import { ExportModel } from 'features/Export/ExportForm';
import { BaseResponseModel, ExportDataModel, ExportDataPropertyModel, FileResponse, GenericFilterModelCollection, IBaseResponseModel } from 'services/tenantManagementService';
import { fixDateFilters } from './dateTimeUtils';
import { catchError, defaultErrorResponse } from './fetchUtils';

interface IExportFileResponseModel extends IBaseResponseModel {
	value?: FileResponse
}

class ExportFileResponseModel extends BaseResponseModel {
	value?: FileResponse

    constructor(data?: IExportFileResponseModel) {
        if (data) {
			super(data);
			this.value = data.value;
        }
	}
}

export const tryCatchExportFileByAction = async (fetchFunction: (...args: any[]) => Promise<FileResponse>): Promise<ExportFileResponseModel> => {
	const fetchPromise = fetchFunction();

	try {
		const response = await fetchPromise;
		const model = new ExportFileResponseModel({
			success: true,
			value: response
		});
		return response ? model : defaultErrorResponse;
	}
	catch (error: any) {
		return catchError<BaseResponseModel>(error);
	}
}

export const createPropertiesForExportModel = (columns: BaseColumnModel[], exportModel: ExportModel) => {
	let columnsForExport = columns.filter(col => col.dbExportFieldPath);

	if (exportModel.useOnlyVisible) {
		// because of undefined
		columnsForExport = columnsForExport.filter(col => !!col.visible)
	}

	const properties = columnsForExport.map(column =>
		new ExportDataPropertyModel({
			name: column.dbExportFieldPath!,
			displayName: column.title,
			format: column.format,
			displayNames: column.displayNames,
			decoraterProperties: column.decoraterProperties
		})
	)

	return properties
}

export const createExportModel = (columns: BaseColumnModel[], filtersModel: GenericFilterModelCollection, exportModel: ExportModel) => {
	const properties = createPropertiesForExportModel(columns, exportModel)

	const exportDataModel = new ExportDataModel({
		fileType: exportModel.exportType,
		// TODO: How to know C# time zone time?
		timeZone: 'Central Europe Standard Time',
		//timeZoneOffsetInMunutes: -new Date().getTimezoneOffset(),
		properties,
		offset: 0,
		limit: 0
	});

	if (exportModel.applyFilters) {
		exportDataModel.filters = fixDateFilters(filtersModel.filters)
	}

	if (exportModel.applySort) {
		exportDataModel.sorts = filtersModel.sorts
	}

	if (exportModel.exportOnlyCurrentPage) {
		exportDataModel.offset = filtersModel.offset;
		exportDataModel.limit = filtersModel.limit;
	}
	return exportDataModel;
}
