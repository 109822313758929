import { ColumnContainer } from "components/Layout";
import { LocalTable } from "components/Table";
import { useCallback, useEffect, useState } from "react";
import { OrganizationalUnitManagerResponse } from "services/tenantManagementService"
import { deleteManagerAuthorizationsAction, getAllManagerAuthorizationsAction } from "./action";
import { tryCatchJsonByAction } from "utils/fetchUtils";
import WithFetch from "features/Fetch/WithFetch";
import { useTableColumnsMemo } from "./Table/tableColumns";
import { TableButtons } from "./Table/TableButtons";
import notifications from "components/Notification/notification";
import { ListComponentProps } from "features/Crud";

export const ManagerAuthorizationsTable = ({ dataChangedTopic, publishDataChanged }: ListComponentProps) => {
	const [authorizations, setAuthorizations] = useState<OrganizationalUnitManagerResponse[]>([]);
	const [selected, setSelected] = useState(new OrganizationalUnitManagerResponse());

	const tableColumns = useTableColumnsMemo();

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getAllManagerAuthorizationsAction.bind(null);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setAuthorizations(response.items || [])
			}
		},
		[]
	)

	useEffect(
		() => {
			const subscription = dataChangedTopic?.subscribe(fetchDataCallback);
			return () => {
				subscription?.unsubscribe();
			}
		},
		[fetchDataCallback, dataChangedTopic]
	)

	const deleteCallback = useCallback(
		async () => {
			const bindedAction = deleteManagerAuthorizationsAction.bind(null, selected.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`Manager authorization is deleted.`);
				publishDataChanged();
			}
		},
		[publishDataChanged, selected]
	)

	const handleRowSelectionChange = useCallback(
		(data: OrganizationalUnitManagerResponse[]) => {
			setSelected(data[0] || new OrganizationalUnitManagerResponse());
		},
		[]
	);

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ColumnContainer margin='small'>
				<TableButtons
					selectedId={selected?.id}
					onDelete={deleteCallback}
				/>
				<LocalTable
					columns={tableColumns}
					data={authorizations}
					rowSelectionChanged={handleRowSelectionChange}
					hasPagination
					limit={20}
				/>
			</ColumnContainer>
		</WithFetch>
	)
}
