import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectActionResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getActionAction, updateActionAction } from '../action';
import { publishCommentsAndActionsChanged } from '../commentsAndActionsChanged';
import { Dialog } from 'components/Dialog';
import WithFetch from 'features/Fetch/WithFetch';
import { ActionForm } from '../ActionForm';
import { ReportComponentParams } from '../../Table/TableButtons';
import { useGoBackFromUpdate } from 'features/Crud';

type ParamType = ReportComponentParams & {
	actionId: string;
}

export const UpdateAction = () => {
	const [action, setAction] = useState(new ProjectActionResponse());
	const params: ParamType = useParams();
	const reportId = parseInt(params.reportId);
	const actionId = parseInt(params.actionId);

	const goBackFromUpdate = useGoBackFromUpdate(true);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getActionAction.bind(null, reportId, actionId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setAction(response.value || new ProjectActionResponse ());
			}
		},
		[actionId, reportId]
	)

	const onSubmitCallback = useCallback(
		async (action: ProjectActionResponse) => {
			const bindedAction = updateActionAction.bind(null, reportId, action);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Comment/Action successfully updated');
				goBackFromUpdate();
				publishCommentsAndActionsChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, reportId]
	);

	return (
		<Dialog
			title='Update Comment/Action'
			onClose={goBackFromUpdate}
			open
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<ActionForm action={action} save={onSubmitCallback} cancel={goBackFromUpdate} />
			</WithFetch>
		</Dialog>
	)
};
