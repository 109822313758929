import { useCallback, useEffect, useMemo, useState } from 'react';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { useApplyStatusColorCallback, useFormattedProjectIdNameCallback, useStatusCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ProjectResponse, ProjectTeamMemberResponse, TaskPriorityEnum, TaskResponse, TaskStatusResponse, ScopeItemLevelResponse, SimpleIdNameModel, SimpleScheduleResponse } from 'services/tenantManagementService';
import { formatDate } from 'utils/dateTimeUtils';
import { getAllTasksAction } from '../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { persistTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { colorToHex, getContrastingColor } from 'utils/colorHelper';
import { customTextAreaFormatter } from 'components/Table/BaseTable/helpers/customTextAreaFormatter';
import { formatTimeWithoutAmOrPm } from 'utils/dateTimeUtils';
import { getAllSimpleMeetingsAction } from 'containers/Communication/Meetings/action';

const defaultVisibleColumns = [
	propertyOf<TaskResponse>('projectOrCategoryId'),
	propertyOf<TaskResponse>('id'),
	propertyOf<TaskResponse>('description'),
	propertyOf<TaskResponse>('createdByUserId'),
	propertyOf<TaskResponse>('assignedToUserId'),
	propertyOf<TaskResponse>('due'),
	propertyOf<TaskResponse>('statusId'),
]

export const useTableColumnsMemo = (
	configureViewKey: string,
	scopeItemsLevel3: ScopeItemLevelResponse[],
	projects: ProjectResponse[],
	schedules: SimpleScheduleResponse[],
) => {
	const {
		persistedUser,
		persistedConfigureView,
		persistedTaskStatus,
		persistedTeamMember,
		persistedTimeTravelNonProjectCategories,
		persistedProject
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedTaskStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedTaskStatus);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	const [tasks, setTasks] = useState<TaskResponse[]>([]);
	const [meetings, setMeetings] = useState<SimpleIdNameModel[]>([]);

	useEffect(
		() => {
			const fetchTaskIdsData = async () => {
				const projectIds = projects.map(project => project.id);
				const bindedAction = getAllTasksAction.bind(null, projectIds);
				const response = await tryCatchJsonByAction(bindedAction);
				if (response.success) {
					setTasks(response.items || []);
				}
			}

			const fetchMeetingsData = async () => {
				const response = await tryCatchJsonByAction(getAllSimpleMeetingsAction);
				if (response.success) {
					setMeetings(response.items || []);
				}
			}

			fetchMeetingsData();
			fetchTaskIdsData();
		},
		[projects]
	)

	const [projectTeamMember, setProjectTeamMember] = useState<ProjectTeamMemberResponse[]>([]);

	useEffect(
		() => {
			const fetchTeamMemberData = async () => {
				const projectTeamMembers: ProjectTeamMemberResponse[] = [];
				for (let i = 0; i < projects.length; i++) {
					const projectId = projects[i].id;
					const items = persistedTeamMember.projectMap[projectId]?.items;

					if (!items) {
						await persistTeamMembersAction(projectId);
					}

					if (items) {
						for (let j = 0; j < items.length; j++) {
							const teamMember = items[j];
							if (!projectTeamMembers.find(tm => tm.userId === teamMember.userId)) {
								projectTeamMembers.push(teamMember);
							}
						}
					}
				}

				setProjectTeamMember(projectTeamMembers);
			}

			fetchTeamMemberData();
		},
		[projects, persistedTeamMember.projectMap]
	)

	const convertTaskDescription = useCallback(
		(html: string | undefined) => {
			if (html) {
				const parsedString = new DOMParser().parseFromString(html, 'text/html');
				return `- ${parsedString.body.textContent}`;
			}

			return '';
		},
		[]
	)

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<TaskResponse>('id')]: {
					title: 'Task ID',
					formatter: (cell: any) => getFormatedId(EntityPrefixEnum.TASK, cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: tasks,
					getItemId: (item: TaskResponse) => item.id,
					getItemText: (item: TaskResponse) => `${getFormatedId(EntityPrefixEnum.TASK, item.id)} ${convertTaskDescription(item.description)}`,
					format: `${EntityPrefixEnum.TASK}{0:D8}`
				},
				[propertyOf<TaskResponse>('projectOrCategoryId')]: {
					title: 'Project or category',
					formatter: (cell: any) => {
						const isProject = (cell.getData() as TaskResponse).isProjectConnected;
						if (isProject) {
							return formatProjectIdName(cell.getValue());
						}
						return persistedTimeTravelNonProjectCategories.itemsMap[cell.getValue()]?.name || '';
					},
					fieldType: FieldTypeEnum.None
				},
				[propertyOf<TaskResponse>('description')]: {
					title: 'Description',
					fieldType: FieldTypeEnum.String,
					formatter: customTextAreaFormatter
				},
				[propertyOf<TaskResponse>('assignedToUserId')]: {
					title: 'Assigned to',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: projectTeamMember,
					getItemId: (option: ProjectTeamMemberResponse) => option.userId,
					getItemText: (option: ProjectTeamMemberResponse) => getUserFullName(option.userId)
				},
				[propertyOf<TaskResponse>('due')]: {
					title: 'Due date',
					formatter: (cell:any) => {
						const value = cell.getValue();
						const element = cell.getElement();
						if (value < new Date()) {
							const color = '#f44336';
							const style = element.style.backgroundColor = color + 'cc';
							return `<span style=${style}>${formatDate(value)}</span>`;
						} else {
							return formatDate(value);
						}
					},
					tooltip: (e: any, cell: any) => cell.getValue() ? formatDate(cell.getValue()) : '',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TaskResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTaskStatus.items,
					getItemId: (option: TaskStatusResponse) => option.id,
					getItemText: (option: TaskStatusResponse) => option.name,
					dbFilterFieldPath: 'StatusRefId',
					dbExportFieldPath: 'StatusRefId'
				},
				[propertyOf<TaskResponse>('parentTaskId')]: {
					title: 'Related tasks',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.TASK,
					dbFilterFieldPath: 'ParentTaskRefId',
					dbExportFieldPath: 'ParentTaskRefId'
				},
				[propertyOf<TaskResponse>('riskId')]: {
					title: 'Risk ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.RISK,
					dbFilterFieldPath: 'RiskRefId',
					dbExportFieldPath: 'RiskRefId'
				},
				[propertyOf<TaskResponse>('scopeItemLevelId')]: {
					title: 'Process ID',
					formatter: (cell: any) => {
						const id = cell.getValue();
						if (!id) {
							return '';
						}
						const data = scopeItemsLevel3;
						return data.find(item => item.id === id)?.fullname;
					},
					fieldType: FieldTypeEnum.Options,
					options: scopeItemsLevel3,
					getItemId: (option: ScopeItemLevelResponse) => option.id,
					getItemText: (option: ScopeItemLevelResponse) => option.fullname!,
					dbFilterFieldPath: 'ScopeItemLevelRefId',
					dbExportFieldPath: 'ScopeItemLevelRefId'
				},
				[propertyOf<TaskResponse>('changeRequestId')]: {
					title: 'CR ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.CHANGE_REQUEST,
					dbFilterFieldPath: 'ChangeRequestRefId',
					dbExportFieldPath: 'ChangeRequestRefId'
				},
				[propertyOf<TaskResponse>('meetingId')]: {
					title: 'Meeting ID',
					formatter: (cell: any) => getFormatedId(EntityPrefixEnum.MEETING, cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: meetings,
					getItemId: (item: SimpleIdNameModel) => item.id,
					getItemText: (item: SimpleIdNameModel) => getFormatedId(EntityPrefixEnum.MEETING, item.id),
					format: `${EntityPrefixEnum.MEETING}{0:D8}`,
					dbFilterFieldPath: 'MeetingRefId',
					dbExportFieldPath: 'MeetingRefId'
				},
				[propertyOf<TaskResponse>('ticketId')]: {
					title: 'Ticket ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.TICKET,
					dbFilterFieldPath: 'TicketRefId',
					dbExportFieldPath: 'TicketRefId'
				},
				[propertyOf<TaskResponse>('isPrivate')]: {
					title: 'Private',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<TaskResponse>('follow')]: {
					title: 'Follow',
					fieldType: FieldTypeEnum.Boolean,
					disableFilter: true,
					disableSort: true,
					dbExportFieldPath: 'FollowRefId'
				},
				[propertyOf<TaskResponse>('createdByUserId')]: {
					title: 'Created by',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: projectTeamMember,
					getItemId: (option: ProjectTeamMemberResponse) => option.userId,
					getItemText: (option: ProjectTeamMemberResponse) => getUserFullName(option.userId)
				},
				[propertyOf<TaskResponse>('priority')]: {
					title: 'Priority',
					formatter: (cell: any) => {
						const id = cell.getValue();
						const element = cell.getElement();
						const priority = taskPriorities.find(p => p.id === id);
						if (!priority) {
							return ''
						}

						const color = colorToHex(priority.color);
						element.style.backgroundColor = color + 'cc';
						element.style.color = getContrastingColor(color)
						return priority.text;
					},
					fieldType: FieldTypeEnum.Options,
					options: taskPriorities,
					getItemId: (option: any) => option.id,
					getItemText: (option: any) => option.text
				},
				[propertyOf<TaskResponse>('createdOn')]: {
					title: 'Created on',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TaskResponse>('closedByUserId')]: {
					title: 'Closed by',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: projectTeamMember,
					getItemId: (option: ProjectTeamMemberResponse) => option.userId,
					getItemText: (option: ProjectTeamMemberResponse) => getUserFullName(option.userId)
				},
				[propertyOf<TaskResponse>('closedOn')]: {
					title: 'Closed on',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TaskResponse>('totalEffort')]: {
					title: 'Total Effort',
					formatter: (cell: any) => formatTimeWithoutAmOrPm(cell.getValue()),
					fieldType: FieldTypeEnum.Number,
					disableFilter: true,
					disableSort: true,
				},
				[propertyOf<TaskResponse>('scheduleId')]: {
					title: 'WBS',
					formatter: (cell: any) => {
						var schedule = schedules.find(x => x.id === cell.getValue());
						if (!schedule) {
							return '';
						}
						return `${schedule.wbs} - ${schedule.name}`
					},
					fieldType: FieldTypeEnum.Options,
					options: schedules,
					getItemId: (item: SimpleScheduleResponse) => item.id,
					getItemText: (item: SimpleScheduleResponse) => `${item.wbs} - ${item.name}`
				},
			}
		},
		[formatProjectIdName, getUserFullName, persistedTaskStatus, schedules, applyStatusColor, getStatus, scopeItemsLevel3, projectTeamMember, tasks, meetings, persistedTimeTravelNonProjectCategories, convertTaskDescription]
	)

	return useVisibleColumns(columns, visibleColumns);
}


export const taskPriorities = [
	{id: TaskPriorityEnum.Low, text: 'Low', color: 'lightskyblue'},
	{id: TaskPriorityEnum.Medium, text: 'Medium', color: '#ede15b'},
	{id: TaskPriorityEnum.High, text: 'High',  color: '#ff9800'},
	{id: TaskPriorityEnum.VeryHigh, text: 'Very high',  color: '#f44336'},
	{id: TaskPriorityEnum.Critical, text: 'Critical',  color: 'darkred'},
]
