import { RowContainer } from 'components/Layout';
import { CrudButtons } from 'features/Crud';
import ConfigureView from 'features/ConfigureView';
import { ExportModel } from 'features/Export/ExportForm';
import ExportToFile from 'features/Export/ExportToFile';
import { ProjectPickerParams } from 'features/Project';
import { BaseColumnModel } from 'components/Table';

export type ReportComponentParams = ProjectPickerParams & {
	reportId: string
}

type Props = {
	selectedId: number
	onDelete: (id: number) => Promise<void>
	onExport: (exportModel: ExportModel) => void
	loadingExport?: boolean
	configureViewKey: string
    tableColumns: BaseColumnModel[]
	routePrefix?: string
	showCrud?: boolean
};

export const TableButtons = ({
	selectedId,
	onDelete,
	configureViewKey,
	tableColumns,
	routePrefix,
	onExport,
	loadingExport,
	showCrud = true,
}: Props) => {
	return (
		<div>
			<RowContainer justifyContent='space-between'>
				<RowContainer>
					{showCrud &&
						<CrudButtons
							selectedId={selectedId}
							prefix={routePrefix}
							onDelete={onDelete}
							isViewable={false}
						/>
					}
				</RowContainer>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
					<ExportToFile onExport={onExport} loading={loadingExport} />
				</RowContainer>
			</RowContainer>
		</div>
	);
};
