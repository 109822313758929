import { useMemo } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { useApplyStatusColorCallback, useStatusCallback, useTenantIsActiveCallback } from 'features/TableColumns/persistedHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ProjectIsActiveResponse, ProjectTeamMemberUserResponse, UserStatusResponse } from 'services/tenantManagementService';

const defaultVisibleColumns = [
	propertyOf<ProjectTeamMemberUserResponse>('fullName'),
	propertyOf<ProjectTeamMemberUserResponse>('username'),
	propertyOf<ProjectTeamMemberUserResponse>('email'),
	propertyOf<ProjectTeamMemberUserResponse>('mobilePhone'),
	propertyOf<ProjectTeamMemberUserResponse>('partnerId'),
	propertyOf<ProjectTeamMemberUserResponse>('projectTeamId'),
	propertyOf<ProjectTeamMemberUserResponse>('projectRoleId'),
	propertyOf<ProjectTeamMemberUserResponse>('statusId'),
	propertyOf<ProjectTeamMemberUserResponse>('validUntil')
]

export const useTableColumnsMemo = (configureViewKey: string, projectId: number) => {
	const {
		persistedUserStatus,
		persistedBusinessPartner,
		persistedProjectRole,
		persistedProjectTeam,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getStatus = useStatusCallback(persistedUserStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedUserStatus);
	const getProjectTeamName = useTenantIsActiveCallback(persistedProjectTeam);
	const getProjectRoleName = useTenantIsActiveCallback(persistedProjectRole);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectTeamMemberUserResponse>('fullName')]: {
					title: 'Name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectTeamMemberUserResponse>('username')]: {
					title: 'Username',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectTeamMemberUserResponse>('email')]: {
					title: 'User Email',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectTeamMemberUserResponse>('officePhone')]: {
					title: 'Office phone',
					formatter: (cell: any) => {
						const value = cell.getValue();
						return value ? value : '';
					},
					fieldType: FieldTypeEnum.None
				},
				[propertyOf<ProjectTeamMemberUserResponse>('mobilePhone')]: {
					title: 'Mobile phone',
					formatter: (cell: any) => {
						const value = cell.getValue();
						return value ? value : '';
					},
					fieldType: FieldTypeEnum.None
				},
				[propertyOf<ProjectTeamMemberUserResponse>('partnerId')]: {
					title: 'Partner',
					formatter: (cell: any) => {
						const id = cell.getValue();
						const partner = persistedBusinessPartner.itemsMap[id];
						return partner?.name || '';
					},
					fieldType: FieldTypeEnum.None
				},
				[propertyOf<ProjectTeamMemberUserResponse>('projectTeamId')]: {
					title: 'Project Team',
					formatter: (cell: any) => getProjectTeamName(cell.getValue()),
					dbFilterFieldPath: 'ProjectTeamRefId',
					fieldType: FieldTypeEnum.Options,
					options: persistedProjectTeam.items.filter(item => item.projectId === projectId),
					getItemId: (option: ProjectIsActiveResponse) => option.id,
					getItemText: (option: ProjectIsActiveResponse) => getProjectTeamName(option.id)
				},
				[propertyOf<ProjectTeamMemberUserResponse>('projectRoleId')]: {
					title: 'Project Role',
					formatter: (cell: any) => getProjectRoleName(cell.getValue()),
					dbFilterFieldPath: 'ProjectRoleRefId',
					fieldType: FieldTypeEnum.Options,
					options: persistedProjectRole.items.filter(item => item.projectId === projectId),
					getItemId: (option: ProjectIsActiveResponse) => option.id,
					getItemText: (option: ProjectIsActiveResponse) => getProjectRoleName(option.id)
				},
				[propertyOf<ProjectTeamMemberUserResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					dbFilterFieldPath: 'StatusRefId',
					options: persistedUserStatus.items,
					getItemId: (option: UserStatusResponse) => option.id,
					getItemText: (option: UserStatusResponse) => option.name
				},
				[propertyOf<ProjectTeamMemberUserResponse>('validUntil')]: {
					title: 'Valid Until',
					fieldType: FieldTypeEnum.Date
				},
			}
		},
		[getStatus, applyStatusColor, getProjectRoleName, getProjectTeamName, persistedUserStatus, persistedBusinessPartner, persistedProjectRole, persistedProjectTeam, projectId]
	)

	return useVisibleColumns(columns, visibleColumns);
}
