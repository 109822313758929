import { HelpShell } from 'features/Help/HelpShell';

export const PartnersHelp = () => {
	return (
		<HelpShell title='Partners'>
			<p>New partners are always created by site administrator.</p>
			<div>
				<p>Partner information is maintained due to following reasons:</p>
				<ul>
					<li>Assigning guest users for corresponding partners (customers and suppliers)</li>
					<li>Assigning planned invoices to corresponding partners in Finance module</li>
				</ul>
			</div>
			<p>
				Partners should be unique for site and VAT no. should be unique identifier. Mavles is validating if entry in this field is unique. <br />
				Guest users cannot be created without Partner information.
			</p>
		</HelpShell>
	)
}
