import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux'
import { RootState } from 'base/reducer/reducer';
import { TenantResponseModel, TenantUpdateRequestModel } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTenantAction } from './action';
import { SiteSettingsForm } from './SiteSettingsForm';
import { updateTenantAction } from './action';
import notifications from 'components/Notification/notification';
import { SiteSettingsHelp } from './SiteSettingsHelp';
import WithFetch from 'features/Fetch/WithFetch';
import { persistTenantAction } from 'features/Tenant/action';
import { ContentShell } from 'features/Content/ContentShell';
import { SiteAdmins } from './SiteAdmins/SiteAdmins';

export const SiteSettings = () => {
	const {
		persistedCountry,
		persistedCurrency
	} = useSelector((state: RootState) => state);

	const [tenant, setTenant] = useState<TenantResponseModel>();

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getTenantAction);
			if (response.success) {
				setTenant(response.value!);
			}
		},
		[]
	);

	const saveCallback = useCallback(
		async (currencyId: number, countryId: number) => {
			const tenantForUpdate = tenant as TenantUpdateRequestModel;
			const newTenant = new TenantUpdateRequestModel({
				...tenantForUpdate,
				currencyId,
				countryId
			})

			const bindedAction = updateTenantAction.bind(null, newTenant);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Site settings are updated.');
				await persistTenantAction();
			} else {
				return convertResponseErrors(response);
			}
		},
		[tenant]
	)

	return (
		<ContentShell
			title='Site Settings'
			FloatingHelpComponent={SiteSettingsHelp}
		>
			<>
				<WithFetch fetchFunction={fetchDataCallback}>
					<SiteSettingsForm
						persistedCurrency={persistedCurrency}
						currencyId={tenant?.currencyId || 0}
						persistedCountry={persistedCountry}
						countryId={tenant?.countryId || 0}
						onSave={saveCallback}
					/>
				</WithFetch>
				<SiteAdmins />
			</>
		</ContentShell>
	)
}
