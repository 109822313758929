import Tabs from 'components/Tabs/Tabs';
import { configureTimeAndTravelTabs } from './configureTimeAndTravelTabs';
import { ContentShell } from 'features/Content/ContentShell';

export const ConfigureTimeAndTravel = () => {
	return (
		<ContentShell title='Configure Time &amp; Travel'>
			<Tabs tabs={configureTimeAndTravelTabs} />
		</ContentShell>
	)
}
