import { useState, useCallback } from 'react';
import { ColumnContainer } from 'components/Layout';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { SimpleStatusRequest, SimpleStatusRequestUpdateSimpleStatusRequestItems } from 'services/tenantManagementService';
import notifications from 'components/Notification/notification';
import { Form, InputField, ColorField, TableField } from 'components/Form';
import { StatusResponse, StatusResponseItemsResponseModel } from './statusResponse';
import WithFetch from 'features/Fetch/WithFetch';

type TableFieldObject = {
	statuses: StatusResponse[]
}

type Props = {
	getConfigsAction: () => Promise<StatusResponseItemsResponseModel>
	updateConfigsAction: (configsDelta: SimpleStatusRequestUpdateSimpleStatusRequestItems) => Promise<StatusResponseItemsResponseModel>
}

export const StatusResponseTable = ({ getConfigsAction, updateConfigsAction }: Props) => {
	const [values, setValues] = useState<TableFieldObject>({ statuses: [] });
	const [initialValues, setInitialValues] = useState<TableFieldObject>({ statuses: [] });

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getConfigsAction);
			if (response.success) {
				const items = response.items || [];
				setValues({ statuses: items });
				setInitialValues({ statuses: items });
			}
		},
		[getConfigsAction]
	);

	const handleCancel = useCallback(
		() => setValues({ ...initialValues }),
		[initialValues]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const updateModel: SimpleStatusRequest[] = [];

			for (const config of values.statuses) {
				const initialConfig = initialValues.statuses.find((item) => config.id === item.id);
				if (initialConfig) {
					if (config.description !== initialConfig.description || config.color !== initialConfig.color) {
						updateModel.push(new SimpleStatusRequest(config));
					}
				}
			}

			const model = new SimpleStatusRequestUpdateSimpleStatusRequestItems();
			model.statuses = updateModel;

			const bindedAction = updateConfigsAction.bind(null, model)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Statuses are updated.');
				setInitialValues({ statuses: response.items || []});
			} else {
				convertResponseErrors(response)
			}
		},
		[initialValues, values, updateConfigsAction]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<Form
				values={values}
				initialValues={initialValues}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={handleCancel}
				render={() => (
					<ColumnContainer margin='medium'>
						<TableField
							id='statuses'
							headers={[
								{ label: 'Status name', size: 4 },
								{ label: 'Explanation', size: 7 },
								{ label: 'Color' },
							]}
							getRowData={(status: StatusResponse) => {
								return {
									isDeletable: !status.isReadonly,
									fields: [
										<InputField
											id='name'
											maxLength={25}
											disabled={status.isReadonly}
										/>,
										<InputField
											id='description'
											maxLength={80}
										/>,
										<ColorField
											id='color'
											colors={['#808080', 'green', 'yellow', 'red', '#0000ff', 'purple']}
											disabled={status.isReadonly}
										/>
									]
								}
							}}
						/>
					</ColumnContainer>
				)}
			/>
		</WithFetch>
	);
};
