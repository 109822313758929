import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApprovalTimesheetRequest, TimesheetResponse, TimesheetsPeriodRequest, TimeTravelStatusEnum } from 'services/tenantManagementService';
import { ColumnContainer } from 'components/Layout';
import { useTableColumnsMemo } from './tableColumns'
import { RootState } from 'base/reducer/reducer';
import TableButtons from './TableButtons';
import notifications from 'components/Notification/notification';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import WithFetch from 'features/Fetch/WithFetch';
import { approvalTimesheetAction, exportForPeriodTimesheetsAction, getTimesheetsForPeriodAction } from 'containers/TimeTravel/MyTimesheets/action';
import RejectRequestModalForm from 'containers/TimeTravel/Approval/RejectRequest/RejectRequestModalForm';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { useUsernameCallback } from 'features/TableColumns/persistedHooks';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { useGoBackCallback } from 'features/Routes/historyHooks';
import { LocalTable } from 'components/Table';

type Props = {
	projectId: number
	statusId: number
	userId: number
	from: Date
	to: Date
	publishDataChanged(): void
}

const goBackLevel = 6;

const ReadTimesheetsTable = ({ projectId, statusId, userId, from, to, publishDataChanged }: Props) => {
	const [values, setValues] = useState<TimesheetResponse[]>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const {
		persistedTimesheetsGeneralConfiguration,
		persistedUser,
		persistedTimeAndTravelStatus
	} = useSelector((state: RootState) => state);

	const tableColumns = useTableColumnsMemo();
	const getUsername = useUsernameCallback(persistedUser);

	const goBackCallback = useGoBackCallback();

	const fetchDataCallback = useCallback(
		async () => {
			const period = new TimesheetsPeriodRequest({
				from,
				to
			})
			setIsRefetching(true);
			const bindedAction = getTimesheetsForPeriodAction.bind(null, projectId, statusId, userId, period)
			const response = await tryCatchJsonByAction(bindedAction);
			setIsRefetching(false);
			if (response.success && response.items) {
				setValues(response.items)
			}
		},
		[projectId, statusId, userId, from, to]
	)

	const approveCallback = useCallback(
		async () => {
			setIsSubmitting(true)
			const request = new ApprovalTimesheetRequest({
				approve: true,
				userId,
				projectId,
				statusId,
				from,
				to,
			})
			const bindedAction = approvalTimesheetAction.bind(null, request);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`Timesheets is approved`);
				publishDataChanged();
				goBackCallback(goBackLevel);
			}
			setIsSubmitting(false)
		},
		[projectId, userId, statusId, from, to, goBackCallback, publishDataChanged]
	)

	const rejectCallback = useCallback(
		async (rejectionComment?: string) => {
			setIsSubmitting(true)
			const request = new ApprovalTimesheetRequest({
				approve: false,
				rejectionText: rejectionComment,
				userId,
				projectId,
				statusId,
				from,
				to,
			})
			const bindedAction = approvalTimesheetAction.bind(null, request);
			const response = await tryCatchJsonByAction(bindedAction);
			setIsSubmitting(false)
			if (response.success) {
				notifications.success('Timesheet is rejected')
				setIsModalOpen(false);
				publishDataChanged();
				goBackCallback(goBackLevel);
			} else {
				return convertResponseErrors(response);
			}
		},
		[projectId, userId, statusId, from, to, goBackCallback, publishDataChanged]
	)
	const approveDisabledMemo = useMemo(
		() => isStatusBySemantic(TimeTravelStatusEnum.Created, statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Approved, statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[persistedTimeAndTravelStatus.itemsMap, isSubmitting, statusId],
	)

	const rejectDisabledisabledMemo = useMemo(
		() => isStatusBySemantic(TimeTravelStatusEnum.Created, statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Rejected, statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[persistedTimeAndTravelStatus.itemsMap, isSubmitting, statusId]
	)

	const memoExportFunction = useMemo(
		() => exportForPeriodTimesheetsAction.bind(null, projectId, statusId, userId),
		[projectId, statusId, userId]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback} refetching={isRefetching}>
			<ColumnContainer margin='medium'>
				<TableButtons
					from={from}
					to={to}
					approvalEnabled={persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess}
					onApprove={approveCallback}
					onReject={() => { setIsModalOpen(true) }}
					approveDisabled={approveDisabledMemo}
					rejectDisabled={rejectDisabledisabledMemo}
					tableColumns={tableColumns}
					exportFunction={memoExportFunction}
				/>
				<LocalTable
					columns={tableColumns}
					data={values}
				/>
				<RejectRequestModalForm
					title={`Reject timesheets - ${getFormatedId(EntityPrefixEnum.PROJECT, projectId)} for ${getUsername(userId)}`}
					onCancel={() => {setIsModalOpen(false)}}
					onSave={rejectCallback}
					open={isModalOpen}
					rejectionCommentRequired={persistedTimesheetsGeneralConfiguration.value.requireCommentsWhenRejecting}
				/>
			</ColumnContainer>
		</WithFetch>
	)
};

export default ReadTimesheetsTable
