export enum WeekTabSubroutes {
	MONDAY = 'monday',
	TUESDAY = 'tuesday',
	WEDNESDAY = 'wednesday',
	THURSDAY = 'thursday',
	FRIDAY = 'friday',
	SATURDAY = 'saturday',
	SUNDAY = 'sunday',
}

const daysOfWeek = [
	WeekTabSubroutes.SUNDAY,
	WeekTabSubroutes.MONDAY,
	WeekTabSubroutes.TUESDAY,
	WeekTabSubroutes.WEDNESDAY,
	WeekTabSubroutes.THURSDAY,
	WeekTabSubroutes.FRIDAY,
	WeekTabSubroutes.SATURDAY,
];

export const getWeekTabSubrouteByDate = (date: Date) => daysOfWeek[date.getDay()];

export const getDifferenceInDaysFromMonday = (weekSubroute: WeekTabSubroutes) => {
	if (weekSubroute === WeekTabSubroutes.SUNDAY) {
		return 6;
	}
	return daysOfWeek.indexOf(weekSubroute) - 1;
}
