import { getTenantId } from 'features/Tenant/tenantUtils';
import { ExportDataModel, GenericFilterModelCollection, InsertStatusReportRequest, StatusReportClient, UpdateStatusReportRequest } from 'services/tenantManagementService';

export const getAllStatusReportsGenericFilterAction = (projectOrCategoryId: number, isProjectConnected: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new StatusReportClient();
	return client.getAllGenericFilter(tenantId, projectOrCategoryId, isProjectConnected, genericFilter);
}

export const getAllStatusReportsAction = (projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new StatusReportClient();
	return client.getAll(tenantId, projectOrCategoryId, isProjectConnected);
}

export const getStatusReportAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new StatusReportClient();
	return client.get(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const createStatusReportAction = (isRelease: boolean, model: InsertStatusReportRequest) => {
	const tenantId = getTenantId()!;
	const client = new StatusReportClient();
	return client.create(tenantId, isRelease, model);
}

export const updateStatusReportAction = (isRelease: boolean, isAlign: boolean, model: UpdateStatusReportRequest) => {
	const tenantId = getTenantId()!;
	const client = new StatusReportClient();
	return client.update(tenantId, isRelease, isAlign, model);
}

export const deleteStatusReportAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new StatusReportClient();
	return client.delete(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const releaseStatusReportAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new StatusReportClient();
	return client.release(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const alignStatusReportAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new StatusReportClient();
	return client.align(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const cloneStatusReportAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new StatusReportClient();
	return client.clone(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const exportStatusReportsAction = (projectOrCategoryId: number, isProjectConnected: boolean, model: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new StatusReportClient();
	return client.exportAll(tenantId, projectOrCategoryId, isProjectConnected, model);
}
