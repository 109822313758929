import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { CrudButtons } from 'features/Crud';
import { BaseColumnModel } from 'components/Table';
import { Export } from 'features/Export/Export';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';

type Props = {
	selectedId: number
    tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
	configureViewKey: string
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

const TableButtons = ({
	selectedId,
	tableColumns,
	filtersModel,
	exportFunction,
	configureViewKey,
}: Props) => {
	return (
		<div>
			<RowContainer justifyContent='space-between'>
				<RowContainer>
					<CrudButtons
						selectedId={selectedId}
						isDeleteable={false}
						isCreateable={false}
					/>
				</RowContainer>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns || []} />
						<Export
							filtersModel={filtersModel}
							tableColumns={tableColumns}
							exportAction={exportFunction}
							exportTypeOptions={EXPORT_TYPE_OPTIONS}
							title='Risk List'
						/>
				</RowContainer>
			</RowContainer>
		</div>
	)
}

export default TableButtons;
