import { ChangeRequestFullResponse, ChangeRequestStatusEnum, ChangeRequestStatusResponse, IdNameResponse, ProjectResponse, TenantResponseModel, UserModel } from 'services/tenantManagementService';
import { FormField } from 'components/Export';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { PersistItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import { formatDate } from 'utils/dateTimeUtils';
import FormGroup from 'components/Export/FormGroup';
import TextareaFormField from 'components/Export/TextareaFormField';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';

type Props = {
	changeRequest: ChangeRequestFullResponse
	project: ProjectResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedChangeRequestStatus: PersistItemsReducer<ChangeRequestStatusResponse>
	persistedTenant: PersistValueReducer<TenantResponseModel>
	persistedCurrency: PersistItemsReducer<IdNameResponse>
}

export const Approval = ({
	changeRequest,
	project,
	persistedUser,
	persistedChangeRequestStatus,
	persistedTenant,
	persistedCurrency,
}: Props) => {
	const estimatedByUser = changeRequest.estimatedByUserId ? persistedUser.itemsMap[changeRequest.estimatedByUserId] : undefined;
	const approvedOrRejectedByUser = changeRequest.approvedOrRejectedByUserId ? persistedUser.itemsMap[changeRequest.approvedOrRejectedByUserId] : undefined;

	return (
		<>
			<FormField
				label='Project Id'
				value={getFormatedId(EntityPrefixEnum.PROJECT, project.id)}
			/>
			<FormField
				label='Project name'
				value={project.name}
				marginBottom={15}
			/>
			<FormField
				label='Change Request Id'
				value={getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, changeRequest.id)}
			/>
			<FormField
				label='Change Request name'
				value={changeRequest.name}
			/>
			<FormField
				label='Status'
				value={persistedChangeRequestStatus.itemsMap[changeRequest.statusId]?.name}
				marginBottom={15}
			/>
			<FormField
				label='Estimated by'
				value={estimatedByUser && `${estimatedByUser.firstName} ${estimatedByUser.lastName}`}
				marginBottom={15}
			/>
			<FormField
				label='Man hours'
				value={changeRequest.activities?.reduce((accSum, a) => accSum + a.effortInHours, 0)?.toString()}
			/>
			<FormField
				label='Total costs'
				value={`${(changeRequest.activities || []).reduce((accSum, a) => accSum + a.cost, 0)} ${persistedCurrency.itemsMap[persistedTenant.value.currencyId]?.symbol}`}
				marginBottom={15}
			/>
			{isStatusBySemantic(ChangeRequestStatusEnum.RejectedForRealization, changeRequest.statusId, persistedChangeRequestStatus.itemsMap) ? (
				<>
					<FormField
						label='Rejected by'
						value={approvedOrRejectedByUser && `${approvedOrRejectedByUser.firstName} ${approvedOrRejectedByUser.lastName}`}
					/>
					<FormField
						label='Rejected on'
						value={formatDate(changeRequest.approvalActionOn)}
					/>
					<FormGroup label='Reason for rejection' >
						<TextareaFormField
							value={changeRequest.reasonForRejection}
						/>
					</FormGroup>
				</>
			) : (
				<>
					<FormField
						label='Approved by'
						value={approvedOrRejectedByUser && `${approvedOrRejectedByUser.firstName} ${approvedOrRejectedByUser.lastName}`}
					/>
					<FormField
						label='Approved on'
						value={formatDate(changeRequest.approvalActionOn)}
					/>
				</>
			)}
		</>
	);
};
