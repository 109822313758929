import { MyProfileUpdateRequestModel, UserClient, UserModelSimpleResponseModel } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { getUserInfo } from 'utils/storageUtils';

export const getMyProfileAction = () => {
	const state = store.getState();
	const userInfo = getUserInfo();

	if (state.persistedUser.isInitialized && state.persistedUser.itemsMap[userInfo.id]) {
		const response = new UserModelSimpleResponseModel({
			success: true,
			value: state.persistedUser.itemsMap[userInfo.id]
		});
		return Promise.resolve(response);
	}

	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.getMyProfile(tenantId);
};

export const updateMyProfileAction = (myProfile: MyProfileUpdateRequestModel) => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.updateProfile(tenantId, myProfile);
};
