import { getTenantId } from 'features/Tenant/tenantUtils';
import { getUserTableViewConfigAction, updateUserTableViewConfigAction } from 'features/UserTableViewConfig/action';
import { ExportDataModel, ProjectTimeTravelDashboardFilterRequest, TimeTravelDashboardClient } from 'services/tenantManagementService';

const viewType = 'showTimesheetDashboardCostRates';

export const getShowTimesheetCostRatesConfigAction = () => {
	return getUserTableViewConfigAction(viewType);
}

export const updateTimesheetCostRatesConfigAction = (showCostRates: boolean) => {
	return updateUserTableViewConfigAction(viewType, showCostRates);
}

export const exportTimesheetDashboardAction = (filterModel: ProjectTimeTravelDashboardFilterRequest, body: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelDashboardClient();
	return client.exportTime(tenantId, filterModel.projectIds, filterModel.nonProjectCategoryIds, filterModel.userIds, filterModel.partnerIds, filterModel.organizationalUnitIds, filterModel.from, filterModel.to, body);
}
