import { HelpShell } from 'features/Help/HelpShell';

export const ProjectConfigurationHelp = () => {
	return (
		<HelpShell title='Projects'>
			<p>
				You can define here project types and project category based on your company needs. This information can be used for reporting purposes. For each type or category, you can add additional explanation for users. When marked as 'active', project type or category will be shown in selection. Please note that you cannot delete entries if there is data with these entries. You can only uncheck 'active' box and project managers will not be able to select this option.
			</p>
			<p>
				When completed and project information is not needed anymore, projects and project data can be archived. When project is set in this status, users will not be able to search and view projects and project data. Only in project module, project managers could view archived project definition. <br />
				If needed, projects can be restored by site administrator.
			</p>
			<p>
				Project data can be deleted permanently from your Mavles site by site administrator. <br />
				Project data can be exported and saved for purpose of migration on another Mavles site. Services of migration are performed by Mavles experts and charged separately. For migration, please contact Mavles support by email: support@mavles.com.
			</p>
		</HelpShell>
	)
}
