import { getTenantId } from "features/Tenant/tenantUtils";
import { ExportDataModel, GenericFilterModelCollection, ScopeItemLevelClient } from "services/tenantManagementService";

export const getAllReportAction = (projectId: number, filters: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ScopeItemLevelClient();
	return client.getAllReport(tenantId, projectId, filters);
}

export const exportAction = (projectId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ScopeItemLevelClient();
	return client.export(tenantId, projectId, exportDataModel);
}
