import { useCallback, useMemo, useState } from 'react';
import styles from 'components/Map/map.module.scss'
import { InsertScheduleRequest, ProjectResponse, ScheduleDurationEnum, ScheduleResponse } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { createScheduleAction } from '../../action';
import { ScheduleItemForm } from './ScheduleItemForm';
import { ScheduleMapItem } from '../MapView';
import { getDateIncrementByNumberOfDays, getDateIncrementByNumberOfDaysWithoutWeekends, getDateIncrementByNumberOfHours, getDateIncrementByNumberOfMonths, getDateIncrementByNumberOfWeeks } from 'utils/dateTimeUtils';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

type Props = {
	parent: ScheduleMapItem
	project: ProjectResponse
	createItemLocal(item: ScheduleResponse): void
}

export const CreateItem = ({ parent, project, createItemLocal }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { persistedScheduleConfiguration } = useSelector((state: RootState) => state);

	const openDialogCallback = useCallback(
		() => setIsModalOpen(true),
		[]
	)

	const closeDialogCallback  = useCallback(
		() => setIsModalOpen(false),
		[]
	)

	const saveCallback = useCallback(
		async (newItem: ScheduleResponse) => {
			const model = new InsertScheduleRequest();
			model.name = newItem.name;
			model.wbs = newItem.wbs;
			model.parentId = newItem.parentId;

			// parent.id = 0 for root items
			if (parent.id) {
				model.start = parent.start;
				model.finish = parent.finish;
			} else {
				// if we add root item, we need to calculate its start and finish dates to create cascading tasks
				model.start = parent.childrens.at(-1)?.finish || new Date();

				const durationIn = persistedScheduleConfiguration.projectMap[project.id]!.value.durationIn;
				if (durationIn === ScheduleDurationEnum.Hours) {
					model.finish = getDateIncrementByNumberOfHours(model.start, 24);
				} else if (durationIn === ScheduleDurationEnum.Days) {
					model.finish = getDateIncrementByNumberOfDays(model.start, 7);
				} else if (durationIn === ScheduleDurationEnum.Weeks) {
					model.finish = persistedScheduleConfiguration.projectMap[project.id]!.value.skipWeekends ?
					getDateIncrementByNumberOfDaysWithoutWeekends(model.start, 5) : getDateIncrementByNumberOfWeeks(model.start, 1);
				} else if (durationIn === ScheduleDurationEnum.Months) {
					model.finish = getDateIncrementByNumberOfMonths(model.start, 1);
				}
			}

			const bindedAction = createScheduleAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`Schedule item ${model.name} is created`);
				closeDialogCallback();
				createItemLocal(response.value || new ScheduleResponse());
			} else {
				return convertResponseErrors(response);
			}
		},
		[closeDialogCallback, project.id, createItemLocal, parent, persistedScheduleConfiguration]
	)

	const newItem = useMemo(
		() => {
			const model = new ScheduleResponse();
			model.parentId = !!parent.id ? parent.id : undefined;

			if (parent.id && parent.wbs) {
				model.wbs = `${parent.wbs}.${parent.childrens.length + 1}`;
			} else {
				model.wbs = `${parent.childrens.length + 1}`;
			}

			return model;
		},
		[parent]
	)

	return (
		<>
			<div className={styles.item_add} onClick={openDialogCallback}>
				Add
			</div>
			<Dialog
				open={isModalOpen}
				title={'Create schedule item'}
				onClose={closeDialogCallback}
			>
				<ScheduleItemForm
					item={newItem}
					onSave={saveCallback}
					onCancel={closeDialogCallback}
				/>
			</Dialog>
		</>
	)
}
