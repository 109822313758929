import { HelpShell } from 'features/Help/HelpShell';

export const VacationGeneralHelp = () => {
	return (
		<HelpShell title='Vacation General Configuration'>
			<p>
				In Vacation general configuration you can enable approval process. This means that vacations are approved by responsible project managers (or substitutes), but you can define if vacations can be approved also by resource manager. <br />
				Vacations are approved only for projects. <br />
				When vacation request is submitted, approval is required from all projects where user is assigned as team member. <br />
				Only if vacation request is approved by each project manager, vacation request is considered approved.
			</p>
			<p><u>Notifications</u> <br />
				By enabling approval process, you will also automatically enable emails exchange between users and project managers when vacations are released for approval, rejected and approved.
			</p>
			<p>
				If your company would prefer not to use email exchange, you can enable Notifications in Mavles. Each user will receive notification in right upper corner of screen when expenses are released for approval, rejected or approved.
			</p>
		</HelpShell>
	)
}
