import { TaskStatusesHelp } from './TaskStatusesHelp';
import { getTaskStatusesAction, updateTaskStatusesAction } from './action';
import { EditableStatusTable } from 'features/EditableStatusTable/EditableStatusTable';
import { ContentShell } from 'features/Content/ContentShell';

export const Tasks = () => {
	return (
		<ContentShell
			InlineHelpComponent={TaskStatusesHelp}
			showBreadcrumb={false}
		>
			<EditableStatusTable
				getStatusesction={getTaskStatusesAction}
				updateStatusesAction={updateTaskStatusesAction}
				successMessage='Task statuses are updated.'
			/>
		</ContentShell>
	)
}
