import { useMemo } from 'react';
import { BusinessPartnerResponseModel, IdNameResponse, InvoiceCountsResponse, InvoiceResponse, InvoiceStatusResponse, InvoiceTypeEnum, TenantIsActiveResponse } from 'services/tenantManagementService';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { incomingOutgoing } from 'containers/Projects/ProjectReport/ReportComponents/ProjectFinances/FinanceForm';
import { OptionType } from 'components/Form';
import { useCurrencySymbolByIdCallback, useApplyStatusColorCallback, useFormattedCurrencyValueByIdCallback, useStatusCallback, useTenantIsActiveCallback, usePartnerCallback, useFormattedCurrencyValueCallback } from 'features/TableColumns/persistedHooks';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { getNumberOfDaysBetweenDates } from 'utils/dateTimeUtils';

const defaultVisibleColumns = [
	propertyOf<InvoiceResponse>('invoiceType'),
	propertyOf<InvoiceResponse>('partnerId'),
	propertyOf<InvoiceResponse>('invoiceNumber'),
	propertyOf<InvoiceResponse>('issueDate'),
	propertyOf<InvoiceResponse>('due'),
	propertyOf<InvoiceResponse>('amount'),
	propertyOf<InvoiceResponse>('statusId'),
	propertyOf<InvoiceResponse>('financeCategoryId'),
	propertyOf<InvoiceResponse>('excludeFromBudget')
]

export const useTableColumnsMemo = (countsResponse: InvoiceCountsResponse, configureViewKey: string) => {
	const {
		persistedBusinessPartner,
		persistedInvoiceStatus,
		persistedCurrency,
		persistedFinanceCategory,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getFormattedCurrencyValue = useFormattedCurrencyValueCallback();
	const getPartner = usePartnerCallback(persistedBusinessPartner);
	const getStatus = useStatusCallback(persistedInvoiceStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedInvoiceStatus);
	const getFormattedCurrencyValueById = useFormattedCurrencyValueByIdCallback();
	const getCurrencySymbol = useCurrencySymbolByIdCallback(persistedCurrency);
	const getFinanceCategory = useTenantIsActiveCallback(persistedFinanceCategory);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<InvoiceResponse>('invoiceType')]: {
					title: 'Type',
					formatter: (cell: any) =>  {
						const value = cell.getValue()
						const type = incomingOutgoing.find(item => item.id === value)
						return type ? type.text : '';
					},
					fieldType: FieldTypeEnum.Options,
					options: incomingOutgoing,
					getItemId: (item: OptionType) => item.id,
					getItemText: (item: OptionType) => item.text
				},
				[propertyOf<InvoiceResponse>('partnerId')]: {
					title: 'Business Partner',
					formatter: (cell: any) => getPartner(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedBusinessPartner.items,
					getItemId: (option: BusinessPartnerResponseModel) => option.id,
					getItemText: (option: BusinessPartnerResponseModel) => option.name
				},
				[propertyOf<InvoiceResponse>('invoiceNumber')]: {
					title: 'Invoice No',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<InvoiceResponse>('description')]: {
					title: 'Description',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<InvoiceResponse>('issueDate')]: {
					title: 'Inv. date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<InvoiceResponse>('due')]: {
					title: 'Due on',
					fieldType: FieldTypeEnum.Date
				},
				paymentTerm: {
					title: 'Payment term',
					formatter: (cell: any) => {
						const data: InvoiceResponse = cell.getData();
						const numberOfDays = getNumberOfDaysBetweenDates(data.issueDate, data.due);
						return `${numberOfDays} day(s)`;
					},
					fieldType: FieldTypeEnum.Number,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<InvoiceResponse>('changeRequestId')]: {
					title: 'Change Request ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.CHANGE_REQUEST,
					dbFilterFieldPath: 'ChangeRequestRefId',
					dbExportFieldPath: 'ChangeRequestRefId'
				},
				[propertyOf<InvoiceResponse>('financeCategoryId')]: {
					title: 'Budget category',
					formatter: (cell: any) => getFinanceCategory(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					items: persistedFinanceCategory.items,
					getItemId: (item: TenantIsActiveResponse) => item.id,
					getItemText: (item: TenantIsActiveResponse) => item.name,
					dbFilterFieldPath: 'FinanceCategoryRefId',
					dbExportFieldPath: 'FinanceCategoryRefId'
				},
				[propertyOf<InvoiceResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) =>  {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedInvoiceStatus.items,
					getItemId: (item: InvoiceStatusResponse) => item.id,
					getItemText: (item: InvoiceStatusResponse) => item.name,
					dbFilterFieldPath: 'StatusRefId',
					dbExportFieldPath: 'StatusRefId'
				},
				[propertyOf<InvoiceResponse>('overdueDays')]: {
					title: 'Overdue',
					fieldType: FieldTypeEnum.None,
					formatter: (cell: any) =>  {
						const value = cell.getValue();
						return value ? `${value} days` : '';
					},
				},
				[propertyOf<InvoiceResponse>('customText1')]: {
					title: 'Text 1',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<InvoiceResponse>('customText2')]: {
					title: 'Text 2',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<InvoiceResponse>('excludeFromBudget')]: {
					title: 'Exclude from budget',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<InvoiceResponse>('amount')]: {
					title: 'Total net amount',
					fieldType: FieldTypeEnum.Currency,
					formatter: (cell: any) => {
						let value: number | undefined = cell.getValue();
						const data: InvoiceResponse = cell.getData();
						if (data.invoiceType === InvoiceTypeEnum.Incoming && value)  {
							value *= -1;
						}
						return getFormattedCurrencyValue(value);
					},
					bottomCalc: () => countsResponse.amountSum
				},
				[propertyOf<InvoiceResponse>('taxAmount')]: {
					title: 'Total tax',
					fieldType: FieldTypeEnum.Currency,
					formatter: (cell: any) => {
						let value: number | undefined = cell.getValue();
						const data: InvoiceResponse = cell.getData();
						if (data.invoiceType === InvoiceTypeEnum.Incoming && value)  {
							value *= -1;
						}
						return getFormattedCurrencyValue(value);
					},
					bottomCalc: () => countsResponse.taxAmountSum
				},
				[propertyOf<InvoiceResponse>('totalAmount')]: {
					title: 'Total amount',
					fieldType: FieldTypeEnum.Currency,
					formatter: (cell: any) => {
						let value: number | undefined = cell.getValue();
						const data: InvoiceResponse = cell.getData();
						if (data.invoiceType === InvoiceTypeEnum.Incoming && value)  {
							value *= -1;
						}
						return getFormattedCurrencyValue(value);
					},
					bottomCalc: () => countsResponse.totalAmountSum,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<InvoiceResponse>('localCurrencyAmount')]: {
					title: 'Total net amount (local)',
					fieldType: FieldTypeEnum.Currency,
					format: '{0}',
					formatter: (cell: any) => {
						let value: number | undefined = cell.getValue();
						const data: InvoiceResponse = cell.getData();
						if (data.invoiceType === InvoiceTypeEnum.Incoming && value)  {
							value *= -1;
						}
						return getFormattedCurrencyValueById(value, data.localCurrencyId);
					},
					bottomCalc: () => countsResponse.localCurrencyAmountSum
				},
				[propertyOf<InvoiceResponse>('localCurrencyTaxAmount')]: {
					title: 'Total tax (local)',
					fieldType: FieldTypeEnum.Currency,
					format: '{0}',
					formatter: (cell: any) => {
						let value: number | undefined = cell.getValue();
						const data: InvoiceResponse = cell.getData();
						if (data.invoiceType === InvoiceTypeEnum.Incoming && value)  {
							value *= -1;
						}
						return getFormattedCurrencyValueById(value, data.localCurrencyId);
					},
					bottomCalc: () => countsResponse.localCurrencyTaxAmountSum
				},
				[propertyOf<InvoiceResponse>('totalLocalCurrencyAmount')]: {
					title: 'Total amount (local)',
					fieldType: FieldTypeEnum.Currency,
					format: '{0}',
					formatter: (cell: any) => {
						let value: number | undefined = cell.getValue();
						const data: InvoiceResponse = cell.getData();
						if (data.invoiceType === InvoiceTypeEnum.Incoming && value)  {
							value *= -1;
						}
						return getFormattedCurrencyValueById(value, data.localCurrencyId);
					},
					bottomCalc: () => countsResponse.localCurrencyTotalAmountSum,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<InvoiceResponse>('localCurrencyId')]: {
					title: 'Currency (local)',
					formatter: (cell: any) => getCurrencySymbol(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedCurrency.items,
					getItemId: (item: IdNameResponse) => item.id,
					getItemText: (item: IdNameResponse) => item.symbol
				},
				[propertyOf<InvoiceResponse>('comment')]: {
					title: 'Comment',
					fieldType: FieldTypeEnum.String
				},
			}
		},
		[countsResponse, getPartner, persistedBusinessPartner, getStatus, applyStatusColor, getFinanceCategory, persistedFinanceCategory, persistedInvoiceStatus, getCurrencySymbol, getFormattedCurrencyValueById, persistedCurrency, getFormattedCurrencyValue]
	)

	return useVisibleColumns(columns, visibleColumns);
}
