import { HelpShell } from 'features/Help/HelpShell';

export const ActualsHelp = () => {
	return (
		<HelpShell title='Actuals'>
			<p>Depending on configuration set-up in actuals tab you can track project realization.</p>
			<p>
				If you decided to include approved Timesheets in actuals, this cost will be shown In your actuals sheet. <br />
				As mentioned before, for some budgeting categories Mavles cannot provide actuals. These items you need to maintain manually in actuals when they are realized.
			</p>
			<p>Budget and actuals tables, you can export in xls files.</p>
		</HelpShell>
	)
}
