import { useMemo } from 'react';
import { useFormattedCurrencyValueCallback } from 'features/TableColumns/persistedHooks';
import { ChangeRequestActivityResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns, dateEditor, floatNumberEditor } from 'components/Table';

const defaultVisibleColumns = [
    propertyOf<ChangeRequestActivityResponse>('name'),
    propertyOf<ChangeRequestActivityResponse>('consultant'),
    propertyOf<ChangeRequestActivityResponse>('level'),
    propertyOf<ChangeRequestActivityResponse>('expectedDeliveryOn'),
	propertyOf<ChangeRequestActivityResponse>('effortInHours'),
	propertyOf<ChangeRequestActivityResponse>('hourPrice'),
	propertyOf<ChangeRequestActivityResponse>('cost'),
]

export const useTableColumnsMemo = (
	disabled?: boolean,
	visibleColumns: string[] = defaultVisibleColumns
) => {
	const getFormattedCurrencyValue = useFormattedCurrencyValueCallback();

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ChangeRequestActivityResponse>('name')]: {
					title: 'Activity *',
					fieldType: FieldTypeEnum.String,
					editor: !disabled && 'input'
				},
				[propertyOf<ChangeRequestActivityResponse>('consultant')]: {
					title: 'Consultant *',
					fieldType: FieldTypeEnum.String,
					editor: !disabled && 'input'
				},
				[propertyOf<ChangeRequestActivityResponse>('level')]: {
					title: 'Level',
					fieldType: FieldTypeEnum.String,
					editor: !disabled && 'input'
				},
				[propertyOf<ChangeRequestActivityResponse>('expectedDeliveryOn')]: {
					title: 'Expected delivery on',
					fieldType: FieldTypeEnum.Date,
					editor: !disabled && dateEditor
				},
				[propertyOf<ChangeRequestActivityResponse>('effortInHours')]: {
					title: 'Effort (in hours)*',
					fieldType: FieldTypeEnum.Number,
					editor: !disabled && floatNumberEditor
				},
				[propertyOf<ChangeRequestActivityResponse>('hourPrice')]: {
					title: 'Hour price *',
					fieldType: FieldTypeEnum.Currency,
					editor: !disabled && floatNumberEditor
				},
				// because of editor, cost data can be invalid
				[propertyOf<ChangeRequestActivityResponse>('cost')]: {
					disableSort: true,
					disableFilter: true,
					notInModel: true,
					title: 'Cost',
					fieldType: FieldTypeEnum.Currency,
					formatter: (cell: any) => {
						const model: ChangeRequestActivityResponse = cell.getData();
						return getFormattedCurrencyValue((model.hourPrice * model.effortInHours) || 0);
					}
				}
			}
		},
		[getFormattedCurrencyValue, disabled]
	)

	return useVisibleColumns(columns, visibleColumns);
}
