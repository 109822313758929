import AssignTeamMemberForm from './AssignTeamMemberForm';
import { Dialog } from 'components/Dialog';
import { ProjectTeamMemberResponse} from 'services/tenantManagementService';

type Props = {
	teamMember?: ProjectTeamMemberResponse
	open: boolean
	cancel(): void
	save(changeRequest: ProjectTeamMemberResponse, username: string): void
}

const AssignTeamMemberModalForm = ({ teamMember, open = false, save, cancel }: Props) => {
	const title = !teamMember ? 'Assign Team Member' : 'Edit Team Member';

	return (
		<Dialog
			open={open}
			size='xxxlarge'
			title={title}
			onClose={cancel}
		>
			<AssignTeamMemberForm
				teamMember={teamMember}
				save={save}
				cancel={cancel}
			/>
		</Dialog>
	);
};

export default AssignTeamMemberModalForm;
