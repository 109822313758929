
import { getTenantId } from 'features/Tenant/tenantUtils';
import { ApprovalTimeTravelDescriptiveRequest, ExportDataModel, GenericFilterModelCollection, InsertVacationRequestRequest, MonthEnum, UpdateVacationRequestRequest, VacationRequestClient } from 'services/tenantManagementService';

export const getVacationRequestAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new VacationRequestClient();
	return client.get(tenantId, id);
}

export const getAllMyVacationRequestsGenericAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new VacationRequestClient();
	return client.getAllMyGeneric(tenantId, years, months, filter);
}

export const getAllVacationRequestsGenericAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new VacationRequestClient();
	return client.getAllGeneric(tenantId, years, months, filter);
}

export const createVacationRequestAction = (model: InsertVacationRequestRequest) => {
	const tenantId = getTenantId()!;
	const client = new VacationRequestClient();
	return client.create(tenantId, model);
}

export const updateVacationRequestAction = (model: UpdateVacationRequestRequest) => {
	const tenantId = getTenantId()!;
	const client = new VacationRequestClient();
	return client.update(tenantId, model);
}

export const deleteVacationRequestAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new VacationRequestClient();
	return client.delete(tenantId, id);
}

export const releaseMyVacationRequestAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new VacationRequestClient();
	return client.releaseMy(tenantId, id);
}

export const approvalVacationRequestAction = (id: number, vrStatusId: number, model: ApprovalTimeTravelDescriptiveRequest) => {
	const tenantId = getTenantId()!;
	const client = new VacationRequestClient();
	return client.approval(tenantId, id, vrStatusId, model);
}

export const exportVacationRequestsAction = (years: number[], months: MonthEnum[], body: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new VacationRequestClient();
	return client.exportAll(tenantId, years, months, body);
}
