import { HelpShell } from 'features/Help/HelpShell';

export const ScopeHelp = () => {
	return (
		<HelpShell title='Change Request types'>
			<p>Change Request types can be configured to distinguish different classification of change requests. Mavles comes with two default entries: Hardware and Software. You can configure change request types based on your company needs.</p>
			<p>For each entry you are configuring, you can add additional explanation for users. When marked as 'active', entries will be shown in user selection. Please note that you cannot delete entries if there is data with these entries. You can only uncheck 'active' box and users will not be able to select this option.</p>
		</HelpShell>
	)
}
