import { RootState } from "base/reducer/reducer";
import { AutoCompleteField, Input } from "components/Form"
import { SmartFormGroup } from "components/SmartContainer/SmartContainer";
import { usePriorityNameMemo } from "features/Priority/usePriorityNameMemo";
import { useSelector } from "react-redux";
import { ImpactResponse, TicketResponse, UrgencyResponse } from "services/tenantManagementService"
import { propertyOf } from "utils/propertyOf"

type Props = {
	values: TicketResponse
}

export const Priority = ({ values }: Props) => {
	const {
		persistedTicketImpact,
		persistedTicketUrgency,
		persistedTicketPriority
	} = useSelector((state: RootState) => state);

	// calculate priority
	const priorityName = usePriorityNameMemo(persistedTicketPriority, values.impactId, values.urgencyId);
	return (
		<>
			<AutoCompleteField
				id={propertyOf<TicketResponse>('impactId')}
				label='Impact'
				isRequired
				items={persistedTicketImpact.items}
				getItemId={(item: ImpactResponse) => item.id}
				getItemText={(item: ImpactResponse) => item.name}
				getItemDescription={(item: ImpactResponse) => item.description}
				loading={persistedTicketImpact.fetching}
			/>
			<AutoCompleteField
				id={propertyOf<TicketResponse>('urgencyId')}
				label='Urgency'
				isRequired
				items={persistedTicketUrgency.items}
				getItemId={(item: UrgencyResponse) => item.id}
				getItemText={(item: UrgencyResponse) => item.name}
				getItemDescription={(item: UrgencyResponse) => item.description}
				loading={persistedTicketUrgency.fetching}
			/>
			<SmartFormGroup label='Priority'>
				<Input
					value={priorityName}
					disabled
				/>
			</SmartFormGroup>
		</>
	)
}
