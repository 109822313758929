import { useMemo } from 'react';
import { WithProjectPicker, ComponentProps } from 'features/Project';
import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';
import { getTestCyclesAction, updateTestCyclesAction } from './action';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { ContentShell } from 'features/Content/ContentShell';
import { TestCyclesHelp } from './Help/TestCyclesHelp';
import { ModuleActivityEnum } from 'services/tenantManagementService';

const TestCycles = ({ project, disabledEdit }: ComponentProps) => {
	const getCyclesActionMemo = useMemo(
		() => getTestCyclesAction.bind(null, project.id),
		[project.id]
	)

	const updateCyclesActionMemo = useMemo(
		() => updateTestCyclesAction.bind(null, project.id),
		[project.id]
	)

	const disabled = useMemo(
		() => disabledEdit || !(isUserPmorSubstitutePmOrSiteAdmin(project.roleId) || project.permissions?.testingPermission?.createTestCycle),
		[project, disabledEdit]
	)

	return (
		<ContentShell
			title='Test Cycles'
			FloatingHelpComponent={TestCyclesHelp}
		>
			<TenantIsActiveTable
				getConfigsAction={getCyclesActionMemo}
				updateConfigsAction={updateCyclesActionMemo}
				successMessage='Test cycles are updated.'
				label={{ name: 'Cycle name', description: 'Explanation' }}
				disabled={disabled}
			/>
		</ContentShell>
	)
}

export default WithProjectPicker(TestCycles, ModuleActivityEnum.Testing, true);
