import { RowContainer } from 'components/Layout';
import { CrudButtons } from 'features/Crud';
import { CopySurvey } from '../Crud/Copy/CopySurvey';

type Props = {
	selectedId: number;
	disabled: boolean;
	onDelete: (surveyId: number) => Promise<void>
}

const TableButtons = ({
	selectedId,
	disabled,
	onDelete
}: Props) => {
	return (
		<RowContainer>
			<CrudButtons
				selectedId={selectedId}
				isViewable={false}
				disabled={disabled}
				onDelete={onDelete}
			/>
			<CopySurvey disabled={disabled} />
		</RowContainer>
	)
}

export default TableButtons;
