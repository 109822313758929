import { useCallback, useEffect, useState } from 'react'
import { ScheduleGanttDashboardFilterRequest, ScheduleGanttDashboardResponse } from 'services/tenantManagementService'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getScheduleDashboardGanttAction } from '../action'
import { FilterForm } from './FilterForm'
import { DashboardContainer } from 'features/Dashboard/DashboardContainer/DashboardContainer'
import { useSaveFilterMemo } from 'features/Dashboard/SaveFilter/useSaveFilterMemo'
import WithFetch from 'features/Fetch/WithFetch'
import { GanttView } from './GanttView'

const viewType = 'schedule_dashboard_projects_gantt';

export const ProjectsGanttDashboard = () => {
	const [loading, setLoading] = useState(false);
	const { savedFilterModel, saveFilterModel, fetchFilterModel, isLoadingFilter } = useSaveFilterMemo<ScheduleGanttDashboardFilterRequest>(viewType);

	const [dashboard, setDashboard] = useState(new ScheduleGanttDashboardResponse())

	const fetchDashboardCallback = useCallback(
		async (model: ScheduleGanttDashboardFilterRequest) => {
			setLoading(true);

			const bindedAction = getScheduleDashboardGanttAction.bind(null, new ScheduleGanttDashboardFilterRequest(model))
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setDashboard(response.value);
			}

			setLoading(false);
		},
		[]
	)

	useEffect(
		() => {
			if (savedFilterModel) {
				fetchDashboardCallback(savedFilterModel);
			}
		},
		[savedFilterModel, fetchDashboardCallback]
	)

	return (
		<WithFetch fetchFunction={fetchFilterModel} refetching={isLoadingFilter}>
			<DashboardContainer
				form={
					<FilterForm
						onSubmit={fetchDashboardCallback}
						filterFormModel={savedFilterModel}
						onSave={saveFilterModel}
					/>
				}
				dashboard={
					<GanttView
						loading={loading}
						dashboard={dashboard}
					/>
				}
			/>
		</WithFetch>
	)
}
