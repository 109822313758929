import { AlertResponse } from "services/tenantManagementService";
import { SignalRContext } from "./NotificationsProvider";

export const useSignalREffects = (setNotifications: React.Dispatch<React.SetStateAction<AlertResponse[]>>, unseenNotificationsCount: number) => {
	SignalRContext.useSignalREffect(
		"NewAlert",
		(notificationRaw) => {
			const notification = new AlertResponse(notificationRaw)

			setNotifications((state) => {
				if (!state.some(x => x.id === notification.id)) {
					return [notification, ...state]
				} else {
					return state;
				}
			})
		},
		[]
	);

	SignalRContext.useSignalREffect(
		"AllAlertsSeen",
		() => {
			if (unseenNotificationsCount > 0) {
				setNotifications((state) => state.map(x => new AlertResponse({ ...x, seen: true })));
			}
		},
		[unseenNotificationsCount]
	);

	SignalRContext.useSignalREffect(
		"AlertDeleted",
		(notificationId) => {
			setNotifications((state) => state.filter(x => x.id !== notificationId));
		},
		[]
	);

	SignalRContext.useSignalREffect(
		"AllAlertsDeleted",
		() => {
			setNotifications([]);
		},
		[]
	);
}
