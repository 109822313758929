import { AttachmentResponse, MyTrainingPlanResponse } from 'services/tenantManagementService';

export class CustomMyTrainingPlanRowModel {
    id!: number;
    isActive!: boolean;
    plannedDate?: Date | undefined;
    plannedTime?: number | undefined;
    plannedTimeTo?: number | undefined;
	attachments?: AttachmentResponse[] | undefined;
    duration?: number | undefined;
    linkToMaterial?: string | undefined;
    linkToVideo?: string | undefined;
    level3ProcessId?: string | undefined;
    level3ProcessName?: string | undefined;
    statusId!: number;

	constructor(data?: CustomMyTrainingPlanRowModel) {
 		if (data) {
			this.id = data.id;
			this.isActive = data.isActive;
			this.plannedDate = data.plannedDate;
			this.plannedTime = data.plannedTime;
			this.plannedTimeTo = data.plannedTimeTo;
			this.attachments = data.attachments;
			this.duration = data.duration;
			this.linkToMaterial = data.linkToMaterial;
			this.linkToVideo = data.linkToVideo;
			this.level3ProcessId = data.level3ProcessId;
			this.level3ProcessName = data.level3ProcessName;
			this.statusId = data.statusId;
		}
	}
}

export const mapToCustomMyTrainingPlanRowModel = (myTrainingPlans: MyTrainingPlanResponse[]) => {
	return myTrainingPlans.map(myTrainingPlans => new CustomMyTrainingPlanRowModel(myTrainingPlans));
}
