import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { BusinessPartnerResponseModel } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createBusinessPartnerAction } from '../action';
import { PartnerForm as PartnerFormContainer } from './PartnerForm';
import { getFormatedId, EntityPrefixEnum } from 'utils/commonHelper';
import { ContentShell } from 'features/Content/ContentShell';

export const CreatePartner = ({ publishDataChanged }: CreateComponentProps) => {
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newPartner: BusinessPartnerResponseModel) => {
			const bindedAction = createBusinessPartnerAction.bind(null, newPartner);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.BUSINESS_PARTNER, response.value?.id);
				notifications.success(`Business partner ${id} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	);

	return (
		<ContentShell title='Create'>
			<PartnerFormContainer
				crud={CrudEnum.Create}
				onSave={handleSave}
			/>
		</ContentShell>
	)
}
