import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useApplyStatusColorCallback, useStatusCallback } from 'features/TableColumns/persistedHooks';
import { ProjectReportResponse, ReportStatusResponse } from 'services/tenantManagementService';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';

const defaultVisibleColumns = [
	propertyOf<ProjectReportResponse>('id'),
	propertyOf<ProjectReportResponse>('from'),
	propertyOf<ProjectReportResponse>('to'),
	propertyOf<ProjectReportResponse>('statusId')
]

export const useTableColumnsMemo = (configureViewKey: string) => {
	const {
		persistedReportStatus,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getStatus = useStatusCallback(persistedReportStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedReportStatus);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectReportResponse>('id')]: {
					title: 'Report Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.REPORT
				},
				[propertyOf<ProjectReportResponse>('from')]: {
					title: 'Reporting Period From',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectReportResponse>('to')]: {
					title: 'Reporting Period To',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectReportResponse>('statusId')]: {
					title: 'Report Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedReportStatus.items,
					getItemId: (option: ReportStatusResponse) => option.id,
					getItemText: (option: ReportStatusResponse) => option.name
				}
			}
		},
		[getStatus, applyStatusColor, persistedReportStatus]
	)

	return useVisibleColumns(columns, visibleColumns);
}
