import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectMilestoneResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getMilestoneAction, updateMilestoneAction } from '../action';
import WithFetch from 'features/Fetch/WithFetch';
import { MilestonePlanForm } from '../MilestonePlanForm';
import { Dialog } from 'components/Dialog';
import { publishMilestonePlansChanged } from '../milestonePlanChaged';
import { ReportComponentParams } from '../../../Table/TableButtons';
import { useGoBackFromUpdate } from 'features/Crud';

type ParamType = ReportComponentParams & {
	milestonePlanId: string;
}

export const UpdateMilestonePlan = () => {
	const params: ParamType = useParams();
	const reportId = parseInt(params.reportId);
	const milestonePlanId = parseInt(params.milestonePlanId);

	const [milestonePlan, setMilestonePlan] = useState(new ProjectMilestoneResponse ());

	const goBackFromUpdate = useGoBackFromUpdate(true);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getMilestoneAction.bind(null, reportId, milestonePlanId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setMilestonePlan(response.value || new ProjectMilestoneResponse ());
			}
		},
		[reportId, milestonePlanId]
	)

	const onSubmitCallback = useCallback(
		async (milestonePlan: ProjectMilestoneResponse) => {
			const bindedAction = updateMilestoneAction.bind(null, reportId, milestonePlan);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Milestone plan successfully updated');
				goBackFromUpdate();
				publishMilestonePlansChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, reportId]
	);

	return (
		<Dialog
			title='Update Milestone plan'
			onClose={goBackFromUpdate}
			open
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<MilestonePlanForm milestone={milestonePlan} save={onSubmitCallback} cancel={goBackFromUpdate} />
			</WithFetch>
		</Dialog>
	)
};
