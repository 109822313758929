import Button from 'components/Button';
import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { CrudButtons } from 'features/Crud';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { Export } from 'features/Export/Export';
import { BaseColumnModel } from 'components/Table';

type Props = {
	selectedId: number
	onDelete: (id: number) => Promise<void>
	onCopyClick: () => void
	copying: boolean
	isReleaseable?: boolean
	onReleaseClick: () => void
	releasing: boolean
	configureViewKey: string
    tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
	disabled?: boolean
	changeDisabled: boolean
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

export const TableButtons = ({
	selectedId,
	onDelete,
	onCopyClick,
	copying,
	isReleaseable,
	onReleaseClick,
	releasing,
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction,
	disabled,
	changeDisabled
}: Props) => {
	return (
		<div>
			<RowContainer justifyContent='space-between'>
				<RowContainer>
					<CrudButtons
						selectedId={selectedId}
						onDelete={onDelete}
						specificActionDisabled={{
							create: disabled,
							change: changeDisabled,
							delete: disabled
						}}
					/>
					<Button
						text='Copy'
						onClick={onCopyClick}
						isLoading={copying}
						disabled={!selectedId}
					/>
				</RowContainer>
				<RowContainer>
					<Button
						text='Release'
						onClick={onReleaseClick}
						disabled={!isReleaseable}
						isLoading={releasing}
					/>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
					<Export
						filtersModel={filtersModel}
						tableColumns={tableColumns}
						exportAction={exportFunction}
						exportTypeOptions={EXPORT_TYPE_OPTIONS}
						title='Project Reports'
					/>
				</RowContainer>
			</RowContainer>
		</div>
	)
}
