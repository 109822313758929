import { HelpShell } from 'features/Help/HelpShell';

export const TaskStatusesHelp = () => {
	return (
		<HelpShell title='Task Statuses'>
			<div>
				<p>Mavles Project Management Tool comes with 3 predefined statuses:</p>
				<ul>
					<li>Open</li>
					<li>In Progress</li>
					<li>Completed</li>
				</ul>
			</div>
			<p>These predefined statuses cannot be changed or deleted, but you can modify explanation and status color.</p>
			<p>If needed, additional risk statuses can be created by site administrator.<br />
				Statuses are not project related and they will be valid for your site and all your projects.<br />
				In order to view status in any project you need to mark 'active' button next to new status.<br />
				Statuses cannot be deleted if there is any data containing this status. If you decide not to use 
				specific status you need to deactivate it.
			</p>
		</HelpShell>
	)
}
