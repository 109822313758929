import styles from 'components/Map/map.module.scss'
import { DeleteIcon } from 'components/icons/icons'
import { SimpleDialog } from 'components/Dialog';
import { useCallback, useState } from 'react';

type Props = {
	onDelete: () => void
}

export const DeleteItem = ({ onDelete }: Props) => {
	const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

	const openConfirmDeleteDialogCallback = useCallback(
		(e) => {
			e.stopPropagation();
			setIsConfirmDeleteModalOpen(true);
		},
		[]
	);

	const closeConfirmDeleteDialogCallback = useCallback(
		() => setIsConfirmDeleteModalOpen(false),
		[]
	)

	const onDeleteCallback = useCallback(
		() => {
			onDelete();
			setIsConfirmDeleteModalOpen(false);
		},
		[onDelete]
	)

	return (
		<div className={styles.item_delete}>
			<DeleteIcon width={12} height={12} fill='currentColor' onClick={openConfirmDeleteDialogCallback} />
			<SimpleDialog
				open={isConfirmDeleteModalOpen}
				title='Delete'
				message='Are you sure that you want to delete selected item?'
				onConfirm={onDeleteCallback}
				onCancel={closeConfirmDeleteDialogCallback}
			/>
		</div>
	)
}
