import { HelpShell } from 'features/Help/HelpShell';

export const TimeAndTravelStatusesHelp = () => {
	return (
		<HelpShell title='Time and Travel Statuses'>
			<div>
				<p>
					Timesheets, travel requests, travel expenses and vacation requests can have 4 statuses:
				</p>
				<ul>
					<li>Created</li>
					<li>Released</li>
					<li>Approved</li>
					<li>Rejected</li>
				</ul>
			</div>
			<p>
				These statuses can not be changed or deleted, but you can modify explanation.
			</p>
			<p>
				Users can initially <b>create</b> these requests and save them. At that time, these requests are not released for approval and user can modify data.
			</p>
			<p>
				When user <b>releases</b> requests workflow is triggered by and requests are sent to project managers for approval. Request have new status: released and data can not be modified.
			</p>
			<p>
				Project manager can approve or reject requests.
			</p>
			<p>
				If <b>approved</b>, request can not be modified.
			</p>
			<p>
				If <b>rejected</b>, requests are again assigned status: created and users can update them based on information provided in rejection notice and release them again for approval.
			</p>
		</HelpShell>
	)
}
