import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SurveyQuestionResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import WithFetch from 'features/Fetch/WithFetch';
import { useMemo } from 'react';
import { CrudEnum, useGoBackFromUpdate } from 'features/Crud';
import QuestionModalForm from './QuestionModalForm';
import { getSurveyQuestionAction } from '../actions';

type ParamType = {
	projectId: string
	surveyId: string;
	questionId: string
}

const ReadQuestion = () => {
	const [question, setQuestion] = useState(new SurveyQuestionResponse());
	const goBackFromUpdate = useGoBackFromUpdate();

	const params: ParamType = useParams();
	const projectId = useMemo(() => parseInt(params.projectId), [params.projectId])
	const surveyId = useMemo(() => parseInt(params.surveyId), [params.surveyId])
	const questionId = useMemo(() => parseInt(params.questionId), [params.questionId])

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getSurveyQuestionAction.bind(null, projectId, surveyId, questionId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setQuestion(response.value || new SurveyQuestionResponse());
			}
		},
		[projectId, surveyId, questionId]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<QuestionModalForm
				crud={CrudEnum.Read}
				question={question}
				cancel={goBackFromUpdate}
				open
			/>
		</WithFetch>
	);
};

export default ReadQuestion;
