import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectDeliverableResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getDeliverableAction, updateDeliverableAction } from '../action';
import { publishDeliverablePlanChanged } from '../deliverablePlanChanged';
import { DeliverablePlanForm } from '../DeliverablePlanForm';
import WithFetch from 'features/Fetch/WithFetch';
import { Dialog } from 'components/Dialog';
import { ReportComponentParams } from '../../../Table/TableButtons';
import { useGoBackFromUpdate } from 'features/Crud';

type ParamType = ReportComponentParams & {
	deliverablePlanId: string;
}

export const UpdateDeliverablePlan = () => {
	const params: ParamType = useParams();
	const reportId = parseInt(params.reportId);
	const deliverablePlanId = parseInt(params.deliverablePlanId);

	const [deliverablePlan, setDeliverablePlan] = useState(new ProjectDeliverableResponse ());

	const goBackFromUpdate = useGoBackFromUpdate(true);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getDeliverableAction.bind(null, reportId, deliverablePlanId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setDeliverablePlan(response.value || new ProjectDeliverableResponse ());
			}

		},
		[reportId, deliverablePlanId]
	)

	const onSubmitCallback = useCallback(
		async (deliverablePlan: ProjectDeliverableResponse) => {
			const bindedAction = updateDeliverableAction.bind(null, reportId, deliverablePlan);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Deliverable plan successfully updated');
				goBackFromUpdate();
				publishDeliverablePlanChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, reportId]
	);

	return (
		<Dialog
			title='Update Deliverable plan'
			onClose={goBackFromUpdate}
			open
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<DeliverablePlanForm deliverable={deliverablePlan} save={onSubmitCallback} cancel={goBackFromUpdate} />
			</WithFetch>
		</Dialog>
	)
};
