import { WithProjectPicker, ComponentProps, pmOrSpmPermission } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';
import { ScheduleTabs } from './ScheduleTabs';
import { ScheduleHelp } from './Help/ScheduleHelp';
import { Configuration } from './Configuration/Configuration';
import { useEffect, useMemo } from 'react';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { ColumnContainer } from 'components/Layout';
import { persistScheduleConfigurationAction } from './Configuration/action';
import { ModuleActivityEnum, SchedulePermission } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';

const Schedule = ({ project, disabledEdit }: ComponentProps) => {
	useEffect(
		() => {
			persistScheduleConfigurationAction(project.id);
		},
		[project.id]
	)

	const showConfiguration = useMemo(
		() => isUserPmorSubstitutePmOrSiteAdmin(project.roleId),
		[project.roleId]
	)

	return (
		<ContentShell
			title='Schedule'
			FloatingHelpComponent={ScheduleHelp}
		>
			<ColumnContainer margin='medium'>
				{showConfiguration && <Configuration projectId={project.id} />}
				<ScheduleTabs project={project} disabledEdit={disabledEdit} />
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectPicker(Schedule, ModuleActivityEnum.Schedule, true, propertyOf<SchedulePermission>('viewSchedule'), pmOrSpmPermission)
