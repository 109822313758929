import { useCallback, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ProjectDeliverableResponse } from 'services/tenantManagementService';
import { currencyDefaultProps, DateField, Form, InputField, InputNumberField, TextareaField } from 'components/Form';

type Props = {
	deliverable?: ProjectDeliverableResponse
	cancel(): void
	save(deliverable: ProjectDeliverableResponse): void
}

export const DeliverablePlanForm = ({ deliverable, cancel, save}: Props) => {
	const [values, setValues] = useState(deliverable || new ProjectDeliverableResponse());

	const onSubmitCallback = useCallback(
		async () => await save(values),
		[save, values]
	)

	return (
		<Form
			values={values}
			initialValues={deliverable}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<InputField
							id='wbs'
							label='WBS'
							isRequired
							maxLength={10}
							disabled={!!deliverable}
						/>
						<InputField
							id='name'
							label='Task name'
							isRequired
						/>
						<DateField
							id='finish'
							label='Finish'
						/>
						<InputNumberField
							id='complete'
							label='Complete'
							{...currencyDefaultProps}
							min={0}
							max={100}
						/>
						<DateField
							id='achievedDate'
							label='Achieved date'
						/>
						<TextareaField
							id='description'
							label='Deliverable description'
							maxLength={500}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
