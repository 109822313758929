import { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTableColumnsMemo } from './Table/tableColumns';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import { CrudEnum } from 'features/Crud';
import { ProjectRiskResponse, ProjectReportResponse, GenericFilterModelCollection, ProjectRiskCountsResponse } from 'services/tenantManagementService';
import { copyFromRiskAction, deleteRiskAction, exportAction, getCopyFromRiskConfigAction, getRisksGenericAction, getRisksGenericCountsAction, updateCopyFromRiskConfigAction } from './action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { UpdateReportSubRoutes } from '../../Crud/UpdateReportRouter';
import { TableButtons } from '../Table/TableButtons';
import { publishRiskDataChanged, riskDataChangedTopic } from './riskDataChanged';
import { Checkbox } from 'components/Form';
import { ExportModel } from 'features/Export/ExportForm';
import FileSaver from 'file-saver';
import { createExportModel } from 'utils/exportUtils';
import { tryCatchExportFileByAction } from 'utils/exportUtils';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { PROJECT_REPORT_CONFIGURE_VIEW_KEYS } from '../../Crud/configureViewKeys';

type Props = {
	report: ProjectReportResponse
	reportCrud: CrudEnum
};

const configureViewKey = PROJECT_REPORT_CONFIGURE_VIEW_KEYS.RISK_MANAGEMENT;

export const RiskManagement = ({ report, reportCrud }: Props) => {
	const [selectedRisk, setSelectedRisk] = useState(new ProjectRiskResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());
	const [countsResponse, setCountsResponse] = useState(new ProjectRiskCountsResponse());
	const [loadingExport, setLoadingExport] = useState(false);
	const [loadingCopyFromRisk, setLoadingCopyFromRisk] = useState(false);
	const [copyFromRisk, setCopyFromRisk] = useState<{[key: number]: boolean} | undefined>(undefined);

	const { persistedConfigureView } = useSelector((state: RootState) => state);

	const tableColumns = useTableColumnsMemo(
		countsResponse,
		persistedConfigureView.value[configureViewKey]
	);

	const copyFromRiskMemo = useMemo(
		() => !!(copyFromRisk && copyFromRisk[report.id]),
		[copyFromRisk, report.id]
	)

	const fetchCopyFromRiskCallback = useCallback(
		async () => {
			setLoadingCopyFromRisk(true);
			const copyFromRiskResponse = await tryCatchJsonByAction(getCopyFromRiskConfigAction);
			if (copyFromRiskResponse.success && copyFromRiskResponse.value) {
				setCopyFromRisk(copyFromRiskResponse.value.content);
			} else {
				//default value
				setCopyFromRisk({});
			}

			setLoadingCopyFromRisk(false);
			publishRiskDataChanged();
		},
		[]
	)

	useEffect(
		() => {
			fetchCopyFromRiskCallback()
		},
		[fetchCopyFromRiskCallback]
	)

	const setCopyFromRiskCallback = useCallback(
		async (newValue: boolean) => {
			setLoadingCopyFromRisk(true);

			const newCopyFromRisk = {...copyFromRisk, [report.id]: newValue}
			const bindedAction = updateCopyFromRiskConfigAction.bind(null, newCopyFromRisk);

			const setCopyFromRiskResponse = await tryCatchJsonByAction(bindedAction);

			if (setCopyFromRiskResponse.success && newValue) {
				const bindedCopyAction = copyFromRiskAction.bind(null, report.id);
				await tryCatchJsonByAction(bindedCopyAction);
			}

			setCopyFromRisk(newCopyFromRisk);
			setLoadingCopyFromRisk(false);
			publishRiskDataChanged();
		},
		[report.id, copyFromRisk]
	)

	const fetchCountsDataCallback = useCallback(
		async () => {
			const bindedAction = getRisksGenericCountsAction.bind(null, report.id, copyFromRiskMemo, filtersModel);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setCountsResponse(response.value)
			}
		},
		[report.id, filtersModel, copyFromRiskMemo]
	)

	const handleDelete = useCallback(
		async (id: number) => {
			const bindedAction = deleteRiskAction.bind(null, report.id, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Risk is successfully deleted');
				publishRiskDataChanged();
			}
		},
		[report.id]
	);

	const handleRowSelectionChange = useCallback(
		(data: ProjectRiskResponse[]) => {
			setSelectedRisk(data[0] || new ProjectRiskResponse());
		},
		[]
	);

	const memoFetchFunction = useCallback(
		async (filtersModel: GenericFilterModelCollection) => {
			const [, response] = await Promise.all([
				fetchCountsDataCallback(),
				getRisksGenericAction(report.id, copyFromRiskMemo, filtersModel)
			])

			return response
		},
		[report.id, fetchCountsDataCallback, copyFromRiskMemo]
	)

	const exportCallback = useCallback(
		async (exportModel: ExportModel) => {
			const exportDataModel = createExportModel(tableColumns, filtersModel, exportModel);
			exportDataModel.title = 'Risk management';

			setLoadingExport(true);

			const bindedAction = exportAction.bind(null, report.id, copyFromRiskMemo, exportDataModel);
			const response = await tryCatchExportFileByAction(bindedAction);
			if (response.success && response.value) {
				FileSaver.saveAs(response.value.data, response.value.fileName);
			}

			setLoadingExport(false);
		},
		[report.id, filtersModel, tableColumns, copyFromRiskMemo]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<>
			<h2>Risk Management</h2>
			<ColumnContainer margin='medium'>
				<Checkbox
					labelBefore='Copy from risk module'
					value={copyFromRiskMemo}
					onChange={setCopyFromRiskCallback}
					disabled={reportCrud !== CrudEnum.Update || loadingCopyFromRisk}
				/>
				<TableButtons
					selectedId={selectedRisk.id}
					onDelete={handleDelete}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					routePrefix={UpdateReportSubRoutes.RISK_MANAGEMENT}
					onExport={exportCallback}
					loadingExport={loadingExport}
					showCrud={!copyFromRiskMemo && reportCrud !== CrudEnum.Read}
				/>
				{copyFromRisk !== undefined &&
					<RemoteTable
						columns={tableColumns}
						filtersModel={filtersModel}
						filtersModelChanged={setFiltersModel}
						subscriptionTopic={riskDataChangedTopic}
						fetchFunction={memoFetchFunction}
						rowSelectionChanged={handleRowSelectionChange}
						reorderColumns={reorderColumnsCallback}
					/>
				}
			</ColumnContainer>
		</>
	)
}
