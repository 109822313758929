import { useCallback, useState, useMemo } from 'react';
import styles from 'components/Map/map.module.scss';
import { PatchScheduleRequest, ProjectResponse, ScheduleResponse } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import { EditIcon } from 'components/icons/icons';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { patchUpdateScheduleAction } from '../../action';
import { ScheduleItemForm } from './ScheduleItemForm';
import { ScheduleMapItem } from '../MapView';

type Props = {
	item: ScheduleMapItem
	project: ProjectResponse
	updateItemLocal(item: ScheduleResponse): void
}

export const UpdateItem = ({ item, project, updateItemLocal }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openDialogCallback = useCallback(
		(e) => {
			e.stopPropagation();
			setIsModalOpen(true);
		},
		[]
	);

	const closeDialogCallback = useCallback(
		() => {
			setIsModalOpen(false);
		},
		[]
	);

	const updateCallback = useCallback(
		async (newItem: ScheduleResponse) => {
			const model = new PatchScheduleRequest(newItem);

			const bindedAction = patchUpdateScheduleAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Schedule item is updated');
				closeDialogCallback();
				updateItemLocal(newItem)
			} else {
				return convertResponseErrors(response);
			}
		},
		[closeDialogCallback, project.id, updateItemLocal]
	)

	const updateItem = useMemo(
		() => {
			const model = new ScheduleResponse()
			model.id = item.id;
			model.name = item.name;
			model.wbs = item.wbs;
			return model;
		},
		[item]
	)

	return (
		<div className={styles.item_edit}>
			<EditIcon width={12} height={12} fill='currentColor' onClick={openDialogCallback} />
			<Dialog
				open={isModalOpen}
				title={`Update ${item.name} item`}
				onClose={closeDialogCallback}
			>
				<ScheduleItemForm
					item={updateItem}
					onSave={updateCallback}
					onCancel={closeDialogCallback}
				/>
			</Dialog>
		</div>
	)
}
