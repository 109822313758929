import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, MapMultiField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ScheduleUtilizationDashboardFilterRequest } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { propertyOf } from 'utils/propertyOf';
import Button from 'components/Button';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';

type Props = {
	filterFormModel?: ScheduleUtilizationDashboardFilterRequest
	onSubmit: (request: ScheduleUtilizationDashboardFilterRequest) => Promise<void>
	onSave: (filterFormModel: ScheduleUtilizationDashboardFilterRequest) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const { persistedOrganizationalUnit } = useSelector((state: RootState) => state);

	const [values, setValues] = useState(new ScheduleUtilizationDashboardFilterRequest(filterFormModel));

	useEffect(
		() => {
			setValues(new ScheduleUtilizationDashboardFilterRequest(filterFormModel));
		},
		[filterFormModel]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new ScheduleUtilizationDashboardFilterRequest(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<MapMultiField
							id={propertyOf<ScheduleUtilizationDashboardFilterRequest>('organizationalUnitIds')}
							label='Organizational Unit'
							items={convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined)}
							loading={persistedOrganizationalUnit.fetching}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
				/>
			)}
		/>
	)
}
