import { useState, useCallback} from 'react'
import styles from './previewTab.module.scss'
import ViewSurveyQuestions from 'containers/Training/MySurveys/Crud/ViewSurveyQuestions';
import WithFetch from 'features/Fetch/WithFetch';
import { MySurveyQuestionResponse, SurveyParticipantAnswerResponse, SurveyQuestionTypeEnum } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getSurveyQuestionsAction } from '../Questions/actions';
import { UpdateSurveyTabsProps } from '../UpdateSurveyTabs';
import { ColumnContainer, VerticalSeparator } from 'components/Layout';
import { SmartFormGroup } from 'components/SmartContainer/SmartContainer';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';

const PreviewTab = ({ project, survey }: UpdateSurveyTabsProps) => {
	const [questions, setQuestions] = useState<MySurveyQuestionResponse[]>([]);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getSurveyQuestionsAction.bind(null, project.id, survey.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.items) {
				const questionsWithExampleAnswers = response.items.map(x => {
					const answers: SurveyParticipantAnswerResponse[] = []

					switch (x.surveyType) {
						case SurveyQuestionTypeEnum.Answer: {
							answers.push(new SurveyParticipantAnswerResponse({ id: 0, answer: 'Example answer' }));
							break;
						}
						case SurveyQuestionTypeEnum.Rating: {
							answers.push(new SurveyParticipantAnswerResponse({ id: 0, rating: Math.floor(Math.random() * 5) + 1 }));
							break;
						}
						case SurveyQuestionTypeEnum.SingleChoice: {
							const proposedAnswer = x.proposedAnswers && x.proposedAnswers.length > 0
								? x.proposedAnswers[Math.floor(Math.random() * x.proposedAnswers.length)] : undefined
							answers.push(new SurveyParticipantAnswerResponse({ id: 0, proposedAnswerId: proposedAnswer?.id }));
							break;
						}
						case SurveyQuestionTypeEnum.MultipleChoice: {
							const proposedAnswer1 = x.proposedAnswers && x.proposedAnswers.length > 0
								? x.proposedAnswers[Math.floor(Math.random() * x.proposedAnswers.length)] : undefined

							answers.push(new SurveyParticipantAnswerResponse({ id: 0, proposedAnswerId: proposedAnswer1?.id }));

							const proposedAnswer2 = x.proposedAnswers && x.proposedAnswers.length > 0
								? x.proposedAnswers[Math.floor(Math.random() * x.proposedAnswers.length)] : undefined

							if (proposedAnswer1?.id !== proposedAnswer2?.id) {
								answers.push(new SurveyParticipantAnswerResponse({ id: 1, proposedAnswerId: proposedAnswer2?.id }));
							}
							break;
						}
						default:
							break;
					}

					return new MySurveyQuestionResponse({ ...x, answers })
				})

				setQuestions(questionsWithExampleAnswers);
			}
		},
		[project.id, survey.id]
	)

	return (
		<ColumnContainer>
			<ColumnContainer margin='disabled'>
				<SmartFormGroup label={'Project'}>
					<div className={styles.form_group_field}>
						<p className={styles.form_group_field_value}>
							{project?.id ? `${getFormatedId(EntityPrefixEnum.PROJECT, project.id)} - ${project.name}` : ''}
						</p>
					</div>
				</SmartFormGroup>
				<SmartFormGroup label={`Viewing ${survey.surveyType}`}>
					<div className={styles.form_group_field}>
						<p className={styles.form_group_field_value_bold}>
							{`${getFormatedId(EntityPrefixEnum.SURVEY, survey.id)} - ${survey.name}`}
						</p>
					</div>
				</SmartFormGroup>
				{
					!!survey.description &&
						<>
							<VerticalSeparator />
							<p>{survey.description}</p>
						</>
				}
			</ColumnContainer>

			<WithFetch fetchFunction={fetchDataCallback}>
				<ViewSurveyQuestions
					surveyQuestions={questions}
				/>
			</WithFetch>
		</ColumnContainer>
	)
}

export default PreviewTab;
