import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectBusinessResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createBusinessAction } from '../action';
import { PotentialAdditionalBussinesForm as PotentialBusinessForm } from '../PotentialBusinessForm';
import { Dialog } from 'components/Dialog';
import { publishPotentialAdditionalBusinessesChanged } from '../potentialAdditionalBusinessesChanged';
import { useGoBackFromCreate } from 'features/Crud';
import { ReportComponentParams } from '../../Table/TableButtons';

export const CreatePotentialBusiness = () => {
	const params: ReportComponentParams = useParams();
	const reportId = parseInt(params.reportId);

	const goBackFromCreate = useGoBackFromCreate(true);

	const onSubmitCallback = useCallback(
		async (potentialBusiness: ProjectBusinessResponse) => {
			const bindedAction = createBusinessAction.bind(null, reportId, potentialBusiness);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Potential business successfully created');
				goBackFromCreate();
				publishPotentialAdditionalBusinessesChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, reportId]
	);

	return(
		<Dialog
			title='Create Potential Additional Business'
			onClose={goBackFromCreate}
			open
		>
			<PotentialBusinessForm save={onSubmitCallback} cancel={goBackFromCreate} />
		</Dialog>
	)
};
