import MaintainScopeHelp from './Help/MaintainScopeHelp';
import { ContentShell } from 'features/Content/ContentShell';
import { WithProjectPicker, ComponentProps, pmOrSpmPermission } from 'features/Project';
import Tabs from 'components/Tabs/Tabs';
import { MaintainScopeTabComponentProps, maintainScopeTabs } from './maintainScopeTabs';
import { propertyOf } from 'utils/propertyOf';
import { ModuleActivityEnum, ScopePermission } from 'services/tenantManagementService';

const MaintainScope = ({ project, disabledEdit }: ComponentProps) => {
	return (
		<ContentShell
			title='Maintain Scope'
			FloatingHelpComponent={MaintainScopeHelp}
		>
			<Tabs
				tabs={maintainScopeTabs}
				tabComponentProps={{ project, disabledEdit } as MaintainScopeTabComponentProps}
			/>
		</ContentShell>
	);
};

export default WithProjectPicker(MaintainScope, ModuleActivityEnum.Scope, true, propertyOf<ScopePermission>('viewScope'), pmOrSpmPermission)
