import { pdf } from '@react-pdf/renderer';
import FileSaver from 'file-saver';

export const saveLocalDocumentToPDF = (filename: string, document: React.ReactElement): Promise<string | null> => {
	return new Promise(resolve => {
		const pdfDocument = pdf(document);
		const onError = () => resolve('Something went wrong during export to PDF');

		pdfDocument.toBlob()
			.then(
				(blob) => {
					FileSaver.saveAs(blob, `${filename}.pdf`);
					resolve(null);
				},
				onError,
			)
			.catch(onError);
	})
}
