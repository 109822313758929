import { ProjectIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";

// subroutes

export enum ProjectSubRoutesEnum {
	MY_DASHBOARD = 'project_my_dashboard',
	MY_PROJECTS = 'project_my_projects',
	PROJECT_REPORT = 'project_reports',
}

export const myDashboardSubRoute: Route = {
	id: ProjectSubRoutesEnum.MY_DASHBOARD,
	url: `/${RootRouteEnum.PROJECTS}/${ProjectSubRoutesEnum.MY_DASHBOARD}`,
	text: 'My Dashboard',
}

export const myProjectSubRoute: Route = {
	id: ProjectSubRoutesEnum.MY_PROJECTS,
	url: `/${RootRouteEnum.PROJECTS}/${ProjectSubRoutesEnum.MY_PROJECTS}`,
	text: 'My Projects',
}

export const projectReportSubRoute: Route = {
	id: ProjectSubRoutesEnum.PROJECT_REPORT,
	url: `/${RootRouteEnum.PROJECTS}/${ProjectSubRoutesEnum.PROJECT_REPORT}`,
	text: 'Project report'
}

// root route

export const projectRoute: Route = {
	id: RootRouteEnum.PROJECTS,
	url: `/${RootRouteEnum.PROJECTS}`,
	text: 'Projects',
	Icon: ProjectIcon,
	children: [
		myDashboardSubRoute,
		myProjectSubRoute,
		projectReportSubRoute
	]
}
