import { HelpShell } from 'features/Help/HelpShell';

export const TestPlanHelp = () => {
	return (
		<HelpShell title='Test Plan'>
			<p>Test plan has to be generated for each test cycle.</p>
			<p>All scope items from scope module are replicated in test plan.</p>
			<p>You can select if you want to create test plan based on level 3 or level 4 scope items. <br />
				This means that you can plan testing including only process tester (if you decide to plan testing based on level 3) or both process and step testers (if you decide to plan testing based on level 4).
			</p>
			<p>First step is selecting test scope for specific test cycle. This is done by selecting level 3 items that will be included in test plan (mark 'include in test scope').</p>
			<p>For each process and/or process step, you can define process tester, step tester, planned date, planned time, duration and location.</p>
			<p>When user is assigned as tester and test cycle marked as active, he/she can access his test package in My test packages section.</p>
			<p>User will see all scope items where he is assigned as process or step tester.</p>
		</HelpShell>
	)
}
