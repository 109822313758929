import { HelpShell } from 'features/Help/HelpShell';

export const MyTravelRequestsHelp = () => {
	return (
		<HelpShell title='My travel requests'>
			<p>Prior to travel itself, you can create travel request and release it for approval by responsible project or resource manager.</p>
			<p>This functionality is not prerequisite for creating travel expense report.</p>
			<p>
				By creating and releasing travel request, you are obtaining approval from responsible project or resource manager to conduct travel itself. <br />
				You are estimating total travel cost and period of planned trip. <br />
				When all data is specified, you can save your travel request or release it for approval.
			</p>
			<p>When trip is conducted, you can create travel expense report.</p>
			<div>
				<p>On time and travel dashboard page you can see reports related to your travel requests such as:</p>
				<ul>
					<li>Travel requests per status: created, released, approved</li>
					<li>List of not approved travel requests per project</li>
				</ul>
			</div>
		</HelpShell>
	)
}
