import { useCallback, useState } from 'react';
import styles from 'components/Map/map.module.scss';
import notifications from 'components/Notification/notification';
import { OverlaySpinner } from 'components/Spinner';
import { ProjectResponse, ScheduleResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { deleteScheduleAction } from '../../action';
import { DeleteItem } from '../Crud/DeleteItem';
import { UpdateItem } from '../Crud/UpdateItem';
import { ScheduleMapItem } from '../MapView';

type Props = {
	item: ScheduleMapItem
	onClick(id: number): void
	isSelected: boolean
	deselect(id: number | undefined): void
	project: ProjectResponse
	disabledEdit?: boolean
	updateItemLocal(item: ScheduleResponse): void
	deleteItemLocal(id: number): void
}

export const Item = ({ item, onClick, isSelected, deselect, project, disabledEdit, updateItemLocal, deleteItemLocal }: Props) => {
	const [deleting, setDeleting] = useState(false);

	const onClickCallback = useCallback(
		() => onClick(item.id),
		[item, onClick]
	)

	const onDeleteCallback = useCallback(
		async () => {
			setDeleting(true);

			const bindedAction = deleteScheduleAction.bind(null, project.id, item.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				// deselect only if deleted item is selected
				if (isSelected) {
					deselect(undefined);
				}

				notifications.success('Schedule item is successfully deleted');

				await deleteItemLocal(item.id);
			}

			setDeleting(false);
		},
		[item.id, project.id, deselect, isSelected, deleteItemLocal]
	)

	const className = `${styles.item} ${isSelected ? styles.selected : ''}`;
	const itemContent = `${item.name}`

	return (
		<div className={className} onClick={onClickCallback}>
			{item.parentId !== undefined && <div className={styles.line_left} />}
			<div className={styles.item_content} title={itemContent}>
				{itemContent}
			</div>
			{!disabledEdit &&
				<>
					{/* Modal dialog for edit/update */}
					<UpdateItem
						item={item}
						project={project}
						updateItemLocal={updateItemLocal}
					/>
					{/* Modal dialog for confirm delete */}
					<DeleteItem
						onDelete={onDeleteCallback}
					/>
					{deleting && <OverlaySpinner size={24} />}
				</>
			}
			{isSelected && <div className={styles.line_right} />}
		</div>
	)
}
