import { useCallback, useState } from 'react';
import Button from 'components/Button';
import { StatusReportDocument } from './Document/StatusReportDocument';
import { StatusReportResponse } from 'services/tenantManagementService';
import { saveLocalDocumentToPDF } from 'utils/reactPDFUtils';
import notifications from 'components/Notification/notification';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';

type Props = {
	statusReport: StatusReportResponse
}

export const Export = ({ statusReport }: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const {
		persistedCompanyInfo,
		persistedUser,
		persistedProject,
		persistedCommunicationStatusReport,
		persistedProjectTeam,
		persistedTimeTravelNonProjectCategories
	} = useSelector((state: RootState) => state);

	const downloadPDFCallback = useCallback(
		async () => {
			setIsLoading(true);

			const error = await saveLocalDocumentToPDF(
				`Status_report_${getFormatedId(EntityPrefixEnum.STATUS_REPORT, statusReport.id)}`,
				<StatusReportDocument
					statusReport={statusReport}
					persistedUser={persistedUser}
					persistedProject={persistedProject}
					persistedCommunicationStatusReport={persistedCommunicationStatusReport}
					persistedProjectTeam={persistedProjectTeam}
					persistedTimeTravelNonProjectCategories={persistedTimeTravelNonProjectCategories}
					base64Logo={persistedCompanyInfo.value?.logoInPDF}
				/>
			);

			error && notifications.error(error);

			setIsLoading(false);
		},
		[
			statusReport,
			persistedUser,
			persistedCompanyInfo,
			persistedProject,
			persistedCommunicationStatusReport,
			persistedProjectTeam,
			persistedTimeTravelNonProjectCategories
		]
	)

	return (
		<Button
			text='Export to PDF'
			color='secondary'
			onClick={downloadPDFCallback}
			isLoading={isLoading}
		/>
	)
}
