import Tabs from 'components/Tabs/Tabs';
import { configureFinanceTabs } from './configureFinanceTabs';
import { ContentShell } from 'features/Content/ContentShell';

export const ConfigureFinance = () => {
	return (
		<ContentShell
			title='Configure Finance'
		>
			<Tabs tabs={configureFinanceTabs} />
		</ContentShell>
	)
}
