import path from 'path'
import { EntityPrefixEnum } from "utils/commonHelper";
import { Notification } from "../Notification/Notification"
import { NotificationComponentProps } from "../NotificationsPanel"
import { useFormattedIdUsernameAndTrigger } from "./useFormattedIdUsernameAndTrigger";
import styles from '../notificationsPanel.module.scss'
import { RootRouteEnum } from 'features/Routes';
import { CrudEnum } from 'features/Crud';
import { useMemo } from 'react';
import { AlertTriggersEnum } from 'services/tenantManagementService';
import { CommunicationSubRoutesEnum } from 'containers/Communication/routes';

export const TaskNotification = ({ notification }: NotificationComponentProps) => {
	const Component = useMemo(
		() => {
			switch (notification.trigger) {
				case AlertTriggersEnum.Submitted:
					return TaskSubmittedNotification;
				default:
					return;
			}
		},
		[notification]
	)

	if (!Component) {
		return <></>
	}

	return (
		<Component notification={notification}/>
	)
}

const TaskSubmittedNotification = ({ notification }: NotificationComponentProps) => {
	const { formattedId, username, triggerText } = useFormattedIdUsernameAndTrigger(notification, EntityPrefixEnum.TASK)

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.COMMUNICATION,
				CommunicationSubRoutesEnum.TASKS,
				CrudEnum.Read,
				String(notification.entityId),
			)}
			content={
				<div>
					<span>Task: </span>
					<span className={styles.entity}>{formattedId} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>by user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}
