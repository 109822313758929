import { AutoCompleteField, InputNumberField } from "components/Form"
import { SmartContainer, SmartFormGroup, SmartInline, SmartItem } from "components/SmartContainer/SmartContainer"
import { TicketResponse } from "services/tenantManagementService"
import { propertyOf } from "utils/propertyOf"
import { TimeMeasureEnum } from 'services/tenantManagementService';
import { TicketFormTabsProps } from '../Tabs/TicketFormTabs'

const effortTimeMeasures: TimeMeasureEnum[] = [
	TimeMeasureEnum.Minutes,
	TimeMeasureEnum.Hours,
	TimeMeasureEnum.Days
]

export const EffortEstimate = ({ ticket }: TicketFormTabsProps) => {
	return (
		<SmartContainer>
			<SmartItem>
				<SmartFormGroup label='Effort estimate'>
					<SmartInline>
						<InputNumberField
							id={propertyOf<TicketResponse>('effort')}
							inline
						/>
						<AutoCompleteField
							id={propertyOf<TicketResponse>('effortUnit')}
							items={effortTimeMeasures}
							getItemId={(item: TimeMeasureEnum) => item}
							getItemText={(item: TimeMeasureEnum) => item}
							inline
							isRequired={!!ticket.effort}
						/>
					</SmartInline>
				</SmartFormGroup>
			</SmartItem>
		</SmartContainer>
	)
}
