import { HelpShell } from 'features/Help/HelpShell';

export const ProjectStatusesHelp = () => {
	return (
		<HelpShell title='Project statuses'>
			<div>
				<p>Four project statuses are defined for every project:</p>
				<ul>
					<li>Planning</li>
					<li>Released</li>
					<li>Completed</li>
					<li>Archived</li>
				</ul>
			</div>
			<p>These statuses cannot be changed or deleted, but you can add explanation for each status.</p>
			<p><b>Planning</b> - this is default status for newly created project. 
				While project is in status planning, team members cannot view or select the project.
			</p>
			<p>After project is defined by project manager, project is <b>released</b> for users, and module data can be assigned to project.</p>
			<p>When project is completed, project manager can assign status <b>Completed</b> to the project. 
				Project data remains viewable, but new data cannot be created for completed project.
			</p>
			<p>Projects can be <b>archived</b>. This means project information cannot be viewed in Mavles modules. 
				All objects (tickets, testing, schedule, etc.) assigned to archived project are not visible.
			</p>
		</HelpShell>
	)
}
