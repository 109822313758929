import { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import { ColumnContainer, RowContainer } from 'components/Layout';
import ExpenseForm from './ExpenseForm';
import { TimeTravelStatusEnum, ExpenseResponse } from 'services/tenantManagementService';
import { getFormatedId, EntityPrefixEnum } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, CrudSubRoutesEnum, useGoBackFromCrudAndPush } from 'features/Crud';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { cloneMyExpenseAction, getExpenseAction } from '../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import Export from '../Export/Export';
import { ReadComponentProps } from 'features/Crud/CrudRouter';
import notifications from 'components/Notification/notification';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = {
	expenseId: string;
}

const ReadMyExpense = ({ publishDataChanged }: ReadComponentProps) => {
	const params: ParamType = useParams();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [expense, setExpense] = useState(new ExpenseResponse());
	const {
		persistedTimeAndTravelStatus,
		persistedExpenseGeneralConfiguration
	} = useSelector((state: RootState) => state);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getExpenseAction.bind(null, parseInt(params.expenseId));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {

				setExpense(response.value || new ExpenseResponse());
			}
		},
		[params.expenseId]
	)

	const cloneExpenseCallback = useCallback(
		async () => {
			setIsSubmitting(true);
			const bindedAction = cloneMyExpenseAction.bind(null, expense.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, expense.id);
				const clonedId = getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, response.value?.id);
				notifications.success(`Expense ${id} is copied into ${clonedId}.`);
				publishDataChanged();
			}
			setIsSubmitting(false);
		},
		[publishDataChanged, expense.id]
	)

	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read, undefined, undefined);

	const goUpdateCallback = useCallback(
		() => {
			goBackFromCrudAndPush(`${CrudSubRoutesEnum.Update}/${params.expenseId}`);
		},
		[params.expenseId, goBackFromCrudAndPush]
	)

	const showChangeMemo = useMemo(
		() => !persistedExpenseGeneralConfiguration.value.enableApprovalProcess ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, expense.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Rejected, expense.statusId, persistedTimeAndTravelStatus.itemsMap),
		[persistedTimeAndTravelStatus, expense, persistedExpenseGeneralConfiguration]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`View  expense - ${getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, expense.id)}`}>
				<ColumnContainer margin='medium'>
					<RowContainer>
						{showChangeMemo && <Button text='Change' onClick={goUpdateCallback} />}
						<Button text='Copy' disabled={!expense} onClick={cloneExpenseCallback} isLoading={isSubmitting}/>
						<Export expense={expense} />
					</RowContainer>
					<ExpenseForm
						expense={expense}
						crud={CrudEnum.Read}
					/>
				</ColumnContainer>
			</ContentShell>
		</WithFetch>
	)
}

export default ReadMyExpense;
