import { FinanceConfigClient, UpdateFinanceGeneralConfigRequest } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getPlanningIntervalsAction = () => {
	const tenantId = getTenantId()!;
	const client = new FinanceConfigClient();
	return client.getFinanceGeneralConfig(tenantId);
}

export const updatePlanningIntervalsAction = (model: UpdateFinanceGeneralConfigRequest) => {
	const tenantId = getTenantId()!;
	const client = new FinanceConfigClient();
	return client.updateFinanceGeneralConfig(tenantId, model);
}
