import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notifications from 'components/Notification/notification';
import { setDefaultProjectAction, setDefaultProjectReduxAction } from './action';
import Button from 'components/Button';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { ProjectResponse } from 'services/tenantManagementService';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { ProjectsTable } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';
import { persistProjectTypesAction } from 'containers/Configuration/SiteConfiguration/Projects/ConfigureProjects/Tabs/ProjectType/action';
import { persistProjectCategoriesAction } from 'containers/Configuration/SiteConfiguration/Projects/ConfigureProjects/Tabs/ProjectCategory/action';
import { RootState } from 'base/reducer/reducer';
import { DefaultProjectHelp } from './Help/DefaultProjectHelp';


const DefaultProject = () => {
	const [saving, setSaving] = useState(false);
	const [selectedProject, setSelectedProject] = useState(new ProjectResponse());

	const { persistedDefaultProject } = useSelector((state: RootState) => state);

	const dispatch = useDispatch();

	useEffect(
		() => {
			persistProjectTypesAction();
			persistProjectCategoriesAction();
		},
		[]
	)

	const selectProjectCallback = useCallback(
		(data: ProjectResponse[]) => {
			setSelectedProject(data[0] || new ProjectResponse());
		},
		[]
	);

	const handleSaveDefaultProject = useCallback(
		async () => {
			if (selectedProject.id) {
				setSaving(true);

				const bindedAction = setDefaultProjectAction.bind(null, selectedProject.id);
				const response = await tryCatchJsonByAction(bindedAction);
				if (response.success) {
					dispatch(setDefaultProjectReduxAction(response.value!))
					const id = getFormatedId(EntityPrefixEnum.PROJECT, response.value?.projectId);
					notifications.success(`Project ${id} is set as default one.`);
				}

				setSaving(false);
			}
		},
		[selectedProject.id, dispatch]
	);

	const renderTableButtonsCallback = useCallback(
		() => (
			<Button
				text='Set as default project'
				onClick={handleSaveDefaultProject}
				disabled={!selectedProject.id}
				isLoading={saving}
			/>
		),
		[handleSaveDefaultProject, selectedProject.id, saving]
	)

	const title = useMemo(
		() => {
			if (persistedDefaultProject.value.projectId) {
				return 'Default project - ' + getFormatedId(EntityPrefixEnum.PROJECT, persistedDefaultProject.value.projectId)
			} else {
				return 'Default project';
			}
		},
		[persistedDefaultProject.value]
	)

	return (
		<ContentShell
			title={title}
			FloatingHelpComponent={DefaultProjectHelp}
		>
			<ProjectsTable
				renderTableButtons={renderTableButtonsCallback}
				onSelectedProjectChange={selectProjectCallback}
			/>
		</ContentShell>
	)
}

export default DefaultProject;
