import { getTenantId } from 'features/Tenant/tenantUtils';
import { getUserTableViewConfigAction, updateUserTableViewConfigAction } from 'features/UserTableViewConfig/action';
import { CommunicationCalendarClient, EntityTypeEnum, UpdateTaskCalendarItemRequest } from 'services/tenantManagementService';

export const getMyCalendarsAction = (projectIds: number[], nonProjectCategoryIds: number[], types: EntityTypeEnum[] | undefined, from: Date | undefined, to: Date | undefined) => {
	const tenantId = getTenantId()!;
	const client = new CommunicationCalendarClient();
	return client.getAllMy(tenantId, projectIds, nonProjectCategoryIds, types, from, to);
}

export const updateTaskDateAction = (projectOrCategoryId: number, isProjectConnected: boolean, model: UpdateTaskCalendarItemRequest) => {
	const tenantId = getTenantId()!;
	const client = new CommunicationCalendarClient();
	return client.updateTaskDate(tenantId, projectOrCategoryId, isProjectConnected, model);
}

export type MyCalendarConfig = {
	selectedEntityTypes: EntityTypeEnum[]
}

const viewType = 'communicationMyCalendar';

export const getMyCalendarConfigAction = () => {
	return getUserTableViewConfigAction(viewType);
}

export const updateMyCalendarConfigAction = (model: MyCalendarConfig) => {
	return updateUserTableViewConfigAction(viewType, model);
}
