import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterProps, getRouteObject } from 'features/Routes';
import MyProfileContainer from './MyProfile/MyProfile';
import DefaultProjectContainer from './DefaultProject/DefaultProject';
import { RulesAndAlerts } from './RulesAndAlerts/RulesAndAlerts';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { AccountSubRoutesEnum, defaultProjectSubRoute, profileSubRoute, rulesAndAlertsSubRoute } from './routes';
import { persistOrganizationalUnitsAction } from '../SiteConfiguration/General/Company/OrganizationalUnits/action';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';

export const MyConfigurationRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistOrganizationalUnitsAction()
		},
		[]
	);

	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[AccountSubRoutesEnum.PROFILE] &&
				<Route
					path={profileSubRoute.url}
					render={() => <MyProfileContainer />}
				/>
			}
			{routesMap[AccountSubRoutesEnum.RULES_AND_ALERTS] &&
				<Route
					path={rulesAndAlertsSubRoute.url}
					render={() => <RulesAndAlerts />}
				/>
			}
			{routesMap[AccountSubRoutesEnum.DEFAULT_PROJECT] &&
				<Route
					path={defaultProjectSubRoute.url}
					render={() => <DefaultProjectContainer />}
				/>
			}

			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
