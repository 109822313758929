import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import { CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { InsertProjectRequest, ProjectResponse } from 'services/tenantManagementService';
import { createProjectAction, publishProjectsChanged } from 'features/Project';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { ProjectForm } from './ProjectForm';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { ContentShell } from 'features/Content/ContentShell';

export const CreateProject = () => {
	const goBackFromCreate = useGoBackFromCreate();

	const saveCallback = useCallback(
		async (newProject: ProjectResponse) => {
			const projectForAdd = new InsertProjectRequest({
				name: newProject.name || '',
				pmId: newProject.pmId || 0,
				statusId: newProject.statusId || 0
			});
			const bindedAction = createProjectAction.bind(null, projectForAdd);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.PROJECT, response.value?.id);
				notifications.success(`Project ${id} is created.`);
				publishProjectsChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate]
	);

	return (
		<ContentShell title='Create'>
			<ProjectForm
				crud={CrudEnum.Create}
				onSave={saveCallback}
			/>
		</ContentShell>
	)
}
