import { CreateUserIcon, UserListIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { SiteConfigurationSubRoutesEnum } from "../enums";
import { RootRouteEnum } from "features/Routes";

const userAdministrationRouteUrl = `/${RootRouteEnum.SITE_CONFIGURATION}/${SiteConfigurationSubRoutesEnum.USER_ADMINISTRATION}`

// subroutes

export enum UserAdministrationSubRoutesEnum {
	USER_LIST = 'user_list',
	CREATE_USER = 'create_user'
}

export const userListSubRoute: Route = {
	id: UserAdministrationSubRoutesEnum.USER_LIST,
	url: `${userAdministrationRouteUrl}/${UserAdministrationSubRoutesEnum.USER_LIST}`,
	text: 'User list',
	Icon: UserListIcon
}

export const createUserSubRoute: Route = {
	id: UserAdministrationSubRoutesEnum.CREATE_USER,
	url: `${userAdministrationRouteUrl}/${UserAdministrationSubRoutesEnum.CREATE_USER}`,
	text: 'Create User',
	Icon: CreateUserIcon
}

// root route

export const userAdministrationRoute: Route = {
	id: SiteConfigurationSubRoutesEnum.USER_ADMINISTRATION,
	url: userAdministrationRouteUrl,
	text: 'User administration',
	children: [
		userListSubRoute,
		createUserSubRoute
	]
}
