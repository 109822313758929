import { HelpShell } from 'features/Help/HelpShell';

export const CompanyHelp = () => {
	return (
		<HelpShell title='Company'>
			<p>
				Here you can define organizational structure of your company in several levels. First level is your site, second level is related to country and 4 additional levels that could represent roughly your organizational structure. <br />
				Main purpose of this section is to have possibility to assign projects to specific organizational level of your organization and allow managers to review reports related to projects assigned. <br />
				Second purpose is to define resource managers for organizational units which could approve Time &amp; Travel requests. Please notice that these requests are approved by responsible project managers and this enablement is optional.

			</p>
		</HelpShell>
	)
}

