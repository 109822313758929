import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectBusinessResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getBusinessAction, updateBusinessAction } from '../action';
import { PotentialAdditionalBussinesForm as PotentialBusinessForm } from '../PotentialBusinessForm';
import WithFetch from 'features/Fetch/WithFetch';
import { Dialog } from 'components/Dialog';
import { publishPotentialAdditionalBusinessesChanged } from '../potentialAdditionalBusinessesChanged';
import { useGoBackFromUpdate } from 'features/Crud';
import { ReportComponentParams } from '../../Table/TableButtons';

type ParamType = ReportComponentParams &  {
	potentialBusinessId: string;
}

export const UpdatePotentialBusiness = () => {
	const params: ParamType = useParams();
	const reportId = parseInt(params.reportId);
	const potentialBusinessId = parseInt(params.potentialBusinessId);

	const [potentialBusiness, setPotentialBusiness] = useState(new ProjectBusinessResponse ());

	const goBackFromUpdate = useGoBackFromUpdate(true);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getBusinessAction.bind(null, reportId, potentialBusinessId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setPotentialBusiness(response.value || new ProjectBusinessResponse ());
			}
		},
		[reportId, potentialBusinessId]
	)

	const onSubmitCallback = useCallback(
		async (potentialBusiness: ProjectBusinessResponse) => {
			const bindedAction = updateBusinessAction.bind(null, reportId, potentialBusiness);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Potential business successfully updated');
				goBackFromUpdate();
				publishPotentialAdditionalBusinessesChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, reportId]
	);

	return (
		<Dialog
			title='Update Potential Additional Bussines'
			onClose={goBackFromUpdate}
			open
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<PotentialBusinessForm business={potentialBusiness} save={onSubmitCallback} cancel={goBackFromUpdate} />
			</WithFetch>
		</Dialog>
	)
};
