import OnboardingRouter from './OnboardingRouter';
import styles from './onboarding.module.scss';

const OnboardingPage = () => {
	return (
		<div className={styles.container}>
			<div className={styles.image} />
			<div className={styles.content}>
				<div className={styles.content_inner}>
					<div className={styles.logo}>
						<div className={styles.logo_image} />
					</div>
					<OnboardingRouter />
				</div>
			</div>
		</div>
	)
}

export default OnboardingPage;
