import { HelpShell } from 'features/Help/HelpShell'

export const ScheduleHelp = () => {
	return (
		<HelpShell title='Schedule'>
			<p>
				Project schedule is configured by responsible project manager.
			</p>
			<div>
				<p>
					Project Manager can define:
				</p>
				<ul>
					<li>How project duration is measured (Minutes/hours/days/weeks/months)</li>
					<li>If weekends and holidays are calculated in project tasks or not</li>
					<li>Define for project duration calculated in hours if working day is 24h or 8h</li>
					<li>If tasks are autocompleted when all subtasks are completed  (and vice versa)</li>
					<li>If task dates are derived from subtask dates</li>
					<li>To add tasks from schedule on task  list of responsible  users</li>
					<li>To update plan when task is completed by responsible user</li>
					<li>Send email/notification to Project manager when schedule is changed by any user (once a day)</li>
					<li>Send email/notification when schedule is changed  to  all project users (once a day)</li>
				</ul>
			</div>
			<p>
				Project schedule can be copied from other project, created from scratch or imported from xls file. <br />
				There is only one project schedule per each project.
			</p>
			<p>Project schedule can be exported to excel file.</p>
		</HelpShell>
	)
}
