import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InsertMeetingTopicRequestUpdateMeetingTopicRequestDeleteMeetingTopicRequestDeltaModel, MeetingResponse, UpdateMeetingRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import { getMeetingAction, updateMeetingAction } from '../action';
import { MeetingForm } from './MeetingForm';
import { createMeetingTopicDelta } from '../topicDeltaHelper';
import { ProjectOrCategoryPickerParams } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = ProjectOrCategoryPickerParams & {
	meetingId: string
}

export const UpdateMeeting = ({ publishDataChanged }: UpdateComponentProps) => {
	const params: ParamType = useParams();
	const projectOrCategoryId = parseInt(params.projectOrCategoryId as string);
	const isProjectConnected = params.isProjectConnected === 'true';
	const meetingId = parseInt(params.meetingId as string);
	const goBackFromUpdate = useGoBackFromUpdate();

	const [meeting, setMeeting] = useState(new MeetingResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getMeetingAction.bind(null, meetingId, projectOrCategoryId, isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setMeeting(response.value || new MeetingResponse());
			}
		},
		[projectOrCategoryId, isProjectConnected, meetingId]
	)

	const saveCallback = useCallback(
		async (newMeeting: MeetingResponse, isRelease: boolean) => {
			const topicsDelta: InsertMeetingTopicRequestUpdateMeetingTopicRequestDeleteMeetingTopicRequestDeltaModel = createMeetingTopicDelta(
				meeting.topics || [],
				newMeeting.topics || []
			)
			const model = new UpdateMeetingRequest({
				...newMeeting,
				topicsDelta
			});
			const bindedAction = updateMeetingAction.bind(null, isRelease, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.MEETING, response.value?.id);
				notifications.success(`Meeting ${id} is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged, meeting]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`Change meeting - ${getFormatedId(EntityPrefixEnum.MEETING, meeting.id)}`}>
				<MeetingForm
					meeting={meeting}
					onSave={saveCallback}
					cancel={goBackFromUpdate}
					crud={CrudEnum.Update}
				/>
			</ContentShell>
		</WithFetch>
	)
}
