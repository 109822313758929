import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import MeetingDocument from './Document/MeetingDocument';
import { MeetingResponse } from 'services/tenantManagementService';
import { saveLocalDocumentToPDF } from 'utils/reactPDFUtils';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { RootState } from 'base/reducer/reducer';
import Button from 'components/Button';

type Props = {
	meeting: MeetingResponse
}

const Export = ({ meeting }: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const {
		persistedCompanyInfo,
		persistedMeetingType,
		persistedProjectTeam,
		persistedUser,
		persistedMeetingStatus,
		persistedTaskStatus,
		persistedProject,
		persistedTimeTravelNonProjectCategories
	} = useSelector((state: RootState) => state);

	const downloadPDFCallback = useCallback(
		async () => {
			setIsLoading(true);

			const error = await saveLocalDocumentToPDF(
				`Meeting_${getFormatedId(EntityPrefixEnum.MEETING, meeting.id)}`,
				<MeetingDocument
					meeting={meeting}
					persistedMeetingType={persistedMeetingType}
					persistedProjectTeam={persistedProjectTeam}
					persistedUser={persistedUser}
					persistedMeetingStatus={persistedMeetingStatus}
					persistedTaskStatus={persistedTaskStatus}
					persistedProject={persistedProject}
					persistedTimeTravelNonProjectCategories={persistedTimeTravelNonProjectCategories}
					base64Logo={persistedCompanyInfo.value?.logoInPDF}
				/>
			);

			error && notifications.error(error);

			setIsLoading(false);
		},
		[
			meeting,
			persistedCompanyInfo,
			persistedMeetingType,
			persistedProjectTeam,
			persistedUser,
			persistedMeetingStatus,
			persistedTaskStatus,
			persistedProject,
			persistedTimeTravelNonProjectCategories
		]
	)

	return (
		<Button
			text='Export to PDF'
			color='secondary'
			onClick={downloadPDFCallback}
			isLoading={isLoading}
		/>
	)
}

export default Export;
