import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateChangeRequestApprovalAction } from 'containers/Scope/ChangeRequests/action';
import { ChangeRequestApprovalRequest, ChangeRequestApproveEnum, ChangeRequestFullResponse, ChangeRequestStatusEnum, UserModel } from 'services/tenantManagementService';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { AutoCompleteField, DateField, Form, TextareaField } from 'components/Form';
import { ColumnContainer } from 'components/Layout';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { propertyOf } from 'utils/propertyOf';
import { RootState } from 'base/reducer/reducer';
import SendEmail from '../../components/SendEmail';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';

type Props = {
	projectId: number
	changeRequest: ChangeRequestFullResponse
	approveEnum: ChangeRequestApproveEnum
	isOpen: boolean
	close(): void
	crud: CrudEnum
}

const ActionForm = ({ projectId, changeRequest, approveEnum, isOpen, close, crud }: Props) => {
	const {
		persistedUser,
		persistedChangeRequestStatus
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(new ChangeRequestFullResponse(changeRequest));
	const goBackFromCrud = useGoBackFromCrud(crud, false, true);

	const onSubmitCallback = useCallback(
		async () => {
			const model = new ChangeRequestApprovalRequest({
				...values,
				approve: approveEnum,
				sendEmail: values.sendEmailApproval
			});
			const bindedAction = updateChangeRequestApprovalAction.bind(null, projectId, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, response.value?.id);
				notifications.success(`Change request ${id} approval is updated.`);
				goBackFromCrud();
			} else {
				return convertResponseErrors(response);
			}
		},
		[values, goBackFromCrud, projectId, approveEnum]
	)

	const enabled = isStatusBySemantic(ChangeRequestStatusEnum.ReleasedForApproval, values.statusId, persistedChangeRequestStatus.itemsMap);

	return (
		<Form
			values={values}
			initialValues={changeRequest}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={close}
			disabled={!enabled}
			hideButtons={!enabled}
			submitButtonText={approveEnum === ChangeRequestApproveEnum.Approve ? 'Approve' : 'Reject'}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<AutoCompleteField
								id={propertyOf<ChangeRequestFullResponse>('approvalUserId')}
								label={approveEnum === ChangeRequestApproveEnum.Approve ? 'Approved by' : 'Rejected by'}
								items={persistedUser.items}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								loading={persistedUser.fetching}
								disabled
							/>
							<DateField
								id={propertyOf<ChangeRequestFullResponse>('approvalActionOn')}
								label={approveEnum === ChangeRequestApproveEnum.Approve ? 'Approved on' : 'Rejected on'}
							/>
							{approveEnum === ChangeRequestApproveEnum.Reject &&
								<TextareaField
									id={propertyOf<ChangeRequestFullResponse>('reasonForRejection')}
									label='Reason for rejection'
									rows={3}
								/>
							}
						</SmartItem>
					</SmartContainer>
					<SendEmail
						sendEmailId={propertyOf<ChangeRequestFullResponse>('sendEmailApproval')}
						sendEmailLabel='Send email'
						toId={propertyOf<ChangeRequestFullResponse>('realizationUserId')}
						persistedUser={persistedUser}
					/>
				</ColumnContainer>
			)}
		/>
	)
}

export default ActionForm;
