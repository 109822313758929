import { PersistItemsReducer, PersistValueReducer } from "features/Persist/reducerTypes";
import { useMemo } from "react";
import { IdNameResponse, TenantResponseModel } from "services/tenantManagementService";

export const useCurrencySuffixMemo = (persistedCurrency: PersistItemsReducer<IdNameResponse>, persistedTenant: PersistValueReducer<TenantResponseModel>) => {
	return useMemo(
		() => {
			const currencyId = persistedTenant.value.currencyId;
			const idName = persistedCurrency.itemsMap[currencyId];
			return idName && ` ${idName.symbol}`;

		},
		[persistedCurrency.itemsMap, persistedTenant.value.currencyId]
	)
}

export const useCurrencySuffixByIdMemo = (persistedCurrency: PersistItemsReducer<IdNameResponse>, currencyId: number | undefined) => {
	return useMemo(
		() => {
			if (currencyId === undefined) {
				return '';
			}

			const idName = persistedCurrency.itemsMap[currencyId];
			return idName && ` ${idName.symbol}`;

		},
		[persistedCurrency.itemsMap, currencyId]
	)
}
