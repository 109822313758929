import { UserModel, StatusReportResponse, StatusReportStatusResponse, ProjectResponse, ProjectIsActiveResponse, NonProjectCategoryResponse } from 'services/tenantManagementService';
import { PersistActiveItemsReducer, PersistItemsReducer, PersistProjectItemsReducer } from 'features/Persist/reducerTypes';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { FormField, SubtitleField } from 'components/Export';
import { useFormattedProjectIdNameCallback, useTenantIsActiveCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { formatDate } from 'utils/dateTimeUtils';
import FormGroup from 'components/Export/FormGroup';
import CheckboxFormField from 'components/Export/CheckboxFormField';
import TextareaFormField from 'components/Export/TextareaFormField';
import { useMemo } from 'react';

type Props = {
	statusReport: StatusReportResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedProject: PersistItemsReducer<ProjectResponse>
	persistedCommunicationStatusReport: PersistItemsReducer<StatusReportStatusResponse>
	persistedProjectTeam: PersistProjectItemsReducer<ProjectIsActiveResponse>
	persistedTimeTravelNonProjectCategories: PersistActiveItemsReducer<NonProjectCategoryResponse>
}

export const StatusReportDocumentForm = ({
	statusReport,
	persistedUser,
	persistedProject,
	persistedCommunicationStatusReport,
	persistedProjectTeam,
	persistedTimeTravelNonProjectCategories
}: Props) => {
	const getUserFullName = useUserFullNameCallback(persistedUser);
	const status = statusReport.statusId ? persistedCommunicationStatusReport.itemsMap[statusReport.statusId] : undefined;
	const formatProjectIdName = useFormattedProjectIdNameCallback(persistedProject);
	const getProjectTeamName = useTenantIsActiveCallback(persistedProjectTeam);
	const color = statusReport.overallStatus.toLowerCase();

	const projectOrCategoryLabel = useMemo(
		() => {
			const isProject = statusReport.isProjectConnected;
			if (isProject) {
				return formatProjectIdName(statusReport.projectOrCategoryId);
			}
			return persistedTimeTravelNonProjectCategories.itemsMap[statusReport.projectOrCategoryId]?.name
		},
		[statusReport, persistedTimeTravelNonProjectCategories, formatProjectIdName]
	)

	return (
		<>
			<FormField
				label={'Project or category'}
				value={projectOrCategoryLabel}
			/>
			<FormField
				label='Status report Id'
				value={getFormatedId(EntityPrefixEnum.STATUS_REPORT, statusReport.id)}
			/>
			<FormField
				label={'Status'}
				value={status?.name}
				valueBackgroundColor={status?.color}
			/>
			<FormField
				label='Report date'
				value={formatDate(statusReport.reportDate)}
			/>
			<FormGroup label='Report period'>
				<FormField
					label='from'
					value={formatDate(statusReport.from)}
				/>
				<FormField
					label='to'
					value={formatDate(statusReport.to)}
				/>
			</FormGroup>
			<FormField
				label='Project team'
				value={getProjectTeamName(statusReport.projectTeamId)}
			/>
			<FormField
				label='Created by'
				value={getUserFullName(statusReport.createdByUserId)}
			/>
			<FormField
				label='Released by'
				value={getUserFullName(statusReport.releasedByUserId)}
			/>
			<FormField
				label='Aligned by'
				value={getUserFullName(statusReport.alignedByUserId)}
			/>
			<FormField
				label='Responsible user'
				value={getUserFullName(statusReport.responsibleUserId)}
			/>
			<CheckboxFormField
				label='Send email for alignment'
				value={statusReport.notifyResponsibleUser}
			/>
			<FormField
				label='Overall status'
				value={statusReport.overallStatus}
				valueBackgroundColor={color}
			/>
			<SubtitleField title='Overall status and key achievements' />
			<TextareaFormField
				value={statusReport.overallStatusAndKeyAchievements}
			/>
			<SubtitleField title='Open issues and needed decisions' />
			<TextareaFormField
				value={statusReport.openIssues}
			/>
			<SubtitleField title='Key risks' />
			<TextareaFormField
				value={statusReport.keyRisks}
			/>
			<SubtitleField title='Next steps' />
			<TextareaFormField
				value={statusReport.nextSteps}
			/>
		</>
	);
};
