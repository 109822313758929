import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectFinanceResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getFinanceAction, updateFinanceAction } from '../action';
import WithFetch from 'features/Fetch/WithFetch';
import { FinanceForm } from '../FinanceForm';
import { Dialog } from 'components/Dialog';
import { publishFinanceDataChanged } from '../financeDataChanged';
import { ReportComponentParams } from '../../Table/TableButtons';
import { useGoBackFromUpdate } from 'features/Crud';

type ParamType = ReportComponentParams & {
	financeId: string;
}

export const UpdateFinance = () => {
	const params: ParamType = useParams();
	const reportId = parseInt(params.reportId);
	const financeId = parseInt(params.financeId);

	const [finance, setFinance] = useState(new ProjectFinanceResponse ());

	const goBackFromUpdate = useGoBackFromUpdate(true);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getFinanceAction.bind(null, reportId, financeId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setFinance(response.value || new ProjectFinanceResponse ());
			}

		},
		[reportId, financeId]
	)

	const onSubmitCallback = useCallback(
		async (finance: ProjectFinanceResponse) => {
			const bindedAction = updateFinanceAction.bind(null, reportId, finance);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Finance data successfully updated');
				goBackFromUpdate();
				publishFinanceDataChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, reportId]
	);

	return (
		<Dialog
			title='Update Finance data'
			onClose={goBackFromUpdate}
			open
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<FinanceForm finance={finance} save={onSubmitCallback} cancel={goBackFromUpdate} />
			</WithFetch>
		</Dialog>
	)
};
