import { MonthEnum, TravelByEnum } from 'services/tenantManagementService';

export const getTravelByEnumLabel = (travelBy: TravelByEnum) => {
	switch(travelBy) {
		case TravelByEnum.CompanyCar:
			return 'Company car';
		case TravelByEnum.PrivateCar:
			return 'Private car';
		default:
			return travelBy;
	}
}

export const travelByOptions = [
	TravelByEnum.CompanyCar,
	TravelByEnum.Bus,
	TravelByEnum.Train,
	TravelByEnum.Plane,
	TravelByEnum.PrivateCar,
	TravelByEnum.Other,
]

export const monthOptions = [
	MonthEnum.January,
	MonthEnum.February,
	MonthEnum.March,
	MonthEnum.April,
	MonthEnum.May,
	MonthEnum.June,
	MonthEnum.July,
	MonthEnum.August,
	MonthEnum.September,
	MonthEnum.October,
	MonthEnum.November,
	MonthEnum.December,
]
const currentDate = new Date()
const currentYear = currentDate.getFullYear();

export const yearOptions = [
	currentYear - 3,
	currentYear - 2,
	currentYear - 1,
	currentYear,
	currentYear + 1,
	currentYear + 2,
	currentYear + 3,
	currentYear + 4,
	currentYear + 5,
]
