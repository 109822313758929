import { TabType } from 'components/Tabs/Tabs';
import DesignTab from './Design/DesignTab';
import PreviewTab from './Preview/PreviewTab';
import QuestionsTab from './Questions/QuestionsTab';
import { ViewResults } from './ViewResults/ViewResults'
import RecipientsTab from './AddRecipientsAndRelease/RecipientsTab';
import { UpdateSurveySubroutes } from './UpdateSurveySubroutes';

export const maintainSurveyTabs: TabType[] = [
	{
		id: '1',
		title: 'Design',
		route: UpdateSurveySubroutes.DESIGN,
		component: DesignTab,
	},
	{
		id: '2',
		title: 'Questions',
		route: UpdateSurveySubroutes.QUESTIONS,
		component: QuestionsTab,
	},
	{
		id: '3',
		title: 'Preview',
		route: UpdateSurveySubroutes.PREVIEW,
		component: PreviewTab,
	},
	{
		id: '4',
		title: 'Add recipients and release',
		route: UpdateSurveySubroutes.RECIPIENTS,
		component: RecipientsTab,
	},
	{
		id: '5',
		title: 'View Results',
		route: UpdateSurveySubroutes.RESULTS,
		component: ViewResults
	}
];
