import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { CrudButtons } from 'features/Crud';
import { BaseColumnModel } from 'components/Table';
import { Export } from 'features/Export/Export';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection, MeetingResponse, } from 'services/tenantManagementService';
import { getUserInfo } from 'utils/storageUtils';
import { useMemo } from 'react';
import Button from 'components/Button';

type Props = {
	selected: MeetingResponse
    tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
	configureViewKey: string
	onDelete?: (id: number) => Promise<void>
	onCopyClick: () => void
	copying: boolean
	isRelease?: boolean
	onReleaseClick: () => void
	releasing: boolean
	onResetClick: () => void
	resetting: boolean
	disabled?: boolean
	createDisabled?: boolean
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

export const TableButtons = ({
	selected,
	tableColumns,
	filtersModel,
	exportFunction,
	configureViewKey,
	onDelete,
	onResetClick,
	onCopyClick,
	copying,
	isRelease,
	onReleaseClick,
	releasing,
	resetting,
	disabled,
	createDisabled
}: Props) => {

	const userMemo = useMemo(
		() => {
			return getUserInfo();
		},
		[]
	);

	return (
		<RowContainer justifyContent='space-between'>
			<RowContainer>
				<CrudButtons
					selectedId={selected.id}
					onDelete={onDelete}
					labels={{
						delete: 'Cancel meeting',
					}}
					specificActionDisabled={{
						create: createDisabled,
						change: disabled,
						delete: disabled || userMemo.id !== selected.userId
					}}
				/>
				<Button
					text='Copy'
					onClick={onCopyClick}
					isLoading={copying}
					disabled={!selected.id}
				/>
			</RowContainer>
			<RowContainer>
				<Button
					text='Release'
					onClick={onReleaseClick}
					disabled={!isRelease || userMemo.id !== selected.userId}
					isLoading={releasing}
				/>
				<Button
					text='Reset status'
					onClick={onResetClick}
					disabled={!disabled || !selected.id || userMemo.id !== selected.userId}
					isLoading={resetting}
					color='destructive'
				/>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns || []} />
				<Export
					filtersModel={filtersModel}
					tableColumns={tableColumns}
					exportAction={exportFunction}
					exportTypeOptions={EXPORT_TYPE_OPTIONS}
					title='Meetings'
				/>
			</RowContainer>
		</RowContainer>
	)
}
