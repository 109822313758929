import { HelpShell } from "features/Help/HelpShell"

export const ProjectStatusHelp = () => {
	return (
		<HelpShell title='Status report'>
			<p>
				Status reports can be created daily or periodically depending on project needs.
			</p>
			<div>
				<p>
					Report has tree possible statuses:
				</p>
				<ul>
					<li>Created</li>
					<li>Released and</li>
					<li>Aligned</li>
				</ul>
			</div>
			<p>
				We enabled simple workflow for alignment of status report between responsible users. User can create report and send it to another user for alignment. Approver can modify report and confirm that he agrees with report by clicking button 'aligned'. <br />
				Each report can be exported to pdf if needed. <br />
				Users can copy reports from previous period and modify it based on current project status.
			</p>
		</HelpShell>
	)
}
