import { useCallback, useMemo, useState } from 'react';
import { ColumnContainer } from 'components/Layout';
import { Form, TextEditorField, Switch } from 'components/Form';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Comment } from './Comment';
import { InsertMeetingCommentRequest, MeetingCommentResponse, MeetingResponse, MeetingTopicResponse } from 'services/tenantManagementService';
import { createTopicCommentAction, deleteTopicCommentAction } from './action';
import { emptyArray } from 'utils/commonHelper';
import notifications from 'components/Notification/notification';

type Props = {
	meeting: MeetingResponse
	topic: MeetingTopicResponse
	disabled?: boolean
}

export const Comments = ({ meeting, topic, disabled }: Props) => {
	const [values, setValues] = useState(new InsertMeetingCommentRequest());
	const [showComments, setShowComments] = useState<boolean | undefined>(true);
	const [comments, setComments] = useState(topic.comments || emptyArray);
	const [deletingCommentId, setDeletingCommentId] = useState<number>();

	const saveCallback = useCallback(
		async () => {
			const model = new InsertMeetingCommentRequest({
				projectOrCategoryId: meeting.projectOrCategoryId,
				isProjectConnected: meeting.isProjectConnected,
				meetingId: meeting.id,
				comment: values.comment,
				meetingTopicId: topic.id,
				meetingTopicType: topic.type
			})
			const bindedAction = createTopicCommentAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setValues(new InsertMeetingCommentRequest());
				setComments((state) => [
					...state,
					response.value || new MeetingCommentResponse()
				])
			} else {
				return convertResponseErrors(response);
			}
		},
		[values, meeting, topic]
	)

	const onDeleteCallback = useCallback(
		async (commentId: number) => {
			setDeletingCommentId(commentId);
			const newComments = comments.filter(comment => comment.id !== commentId);

			const bindedAction = deleteTopicCommentAction.bind(null, commentId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`Comment is successfully deleted.`);
				setComments(newComments);
			}

			setDeletingCommentId(undefined);
		},
		[comments]
	)

	const commentsMemo = useMemo(
		() => {
			return comments
				.sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime())
				.map((comment) => (
					<Comment
						key={comment.id}
						comment={comment}
						onDelete={onDeleteCallback}
						isDeleting={deletingCommentId === comment.id}
						disabled={disabled}
					/>
				));
		},
		[comments, onDeleteCallback, deletingCommentId, disabled]
	)

	return (
		<ColumnContainer margin='small'>
			<SmartContainer>
				<SmartItem>
					<SmartFormGroup label='Comments'>
						<Switch
							value={showComments}
							onChange={setShowComments}
						/>
					</SmartFormGroup>
				</SmartItem>
			</SmartContainer>
			{showComments &&
				<>
					<Form
						values={values}
						onChange={setValues}
						onSubmit={saveCallback}
						submitButtonText='Save comment'
						hideCancelButton
						render={() => (
							<TextEditorField
								id='comment'
								isRequired
							/>
						)}
					/>
					<ColumnContainer>
						{commentsMemo}
					</ColumnContainer>
				</>
			}
		</ColumnContainer>
	)
}
