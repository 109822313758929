import { RootState } from 'base/reducer/reducer';
import { TableColumnsType, FieldTypeEnum, useVisibleColumns } from 'components/Table';
import { useIdNameCallback } from 'features/TableColumns/persistedHooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BusinessPartnerResponseModel, IdNameResponse } from 'services/tenantManagementService';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';

const defaultVisibleColumns = [
	propertyOf<BusinessPartnerResponseModel>('id'),
	propertyOf<BusinessPartnerResponseModel>('name'),
	propertyOf<BusinessPartnerResponseModel>('city'),
	propertyOf<BusinessPartnerResponseModel>('countryId'),
	propertyOf<BusinessPartnerResponseModel>('vatNo'),
	propertyOf<BusinessPartnerResponseModel>('regNo')
]

export const useTableColumnsMemo = (configureViewKey: string) => {
	const {
		persistedCountry,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getName = useIdNameCallback(persistedCountry);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<BusinessPartnerResponseModel>('name')]: {
					title: 'Partner name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<BusinessPartnerResponseModel>('id')]: {
					title: 'Partner Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.BUSINESS_PARTNER
				},
				[propertyOf<BusinessPartnerResponseModel>('city')]: {
					title: 'City',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<BusinessPartnerResponseModel>('countryId')]: {
					title: 'Country',
					formatter: (cell: any) => getName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedCountry.items,
					getItemId: (option: IdNameResponse) => option.id,
					getItemText: (option: IdNameResponse) => option.name
				},
				[propertyOf<BusinessPartnerResponseModel>('vatNo')]: {
					title: 'VAT No',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<BusinessPartnerResponseModel>('regNo')]: {
					title: 'Registration No',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<BusinessPartnerResponseModel>('address')]: {
					title: 'Address',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<BusinessPartnerResponseModel>('email')]: {
					title: 'E-mail',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<BusinessPartnerResponseModel>('phone')]: {
					title: 'Phone',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<BusinessPartnerResponseModel>('contactPerson')]: {
					title: 'Contact person',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<BusinessPartnerResponseModel>('iban')]: {
					title: 'IBAN',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<BusinessPartnerResponseModel>('swift')]: {
					title: 'SWIFT',
					fieldType: FieldTypeEnum.String
				}
			}
		},
		[getName, persistedCountry]
	)

	return useVisibleColumns(columns, visibleColumns);
}
