import { AutoCompleteField } from 'components/Form';
import { ReportItemStatusEnum } from "services/tenantManagementService";

export const reportItemStatuses = [
	ReportItemStatusEnum.Open,
	ReportItemStatusEnum.InProgres,
	ReportItemStatusEnum.OnHold,
	ReportItemStatusEnum.Rejected,
	ReportItemStatusEnum.Closed
]

type Props = {
	isRequired?: boolean
}

export const ReportItemStatusSelectField = ({ isRequired }: Props) => {
	return (
		<AutoCompleteField
			id='status'
			label='Status'
			items={reportItemStatuses}
			getItemId={(item: ReportItemStatusEnum) => item}
			getItemText={(item: ReportItemStatusEnum) => item}
			isRequired={isRequired}
		/>
	)
}
