import { ChangeRequestFullResponse, ChangeRequestStatusResponse, IdNameResponse, ProjectResponse, ScopeItemLevelResponse, TenantResponseModel, UserModel } from 'services/tenantManagementService';
import { FormField } from 'components/Export';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { PersistItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import { formatDate } from 'utils/dateTimeUtils';
import FormGroup from 'components/Export/FormGroup';
import TextareaFormField from 'components/Export/TextareaFormField';

type Props = {
	changeRequest: ChangeRequestFullResponse
	project: ProjectResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedChangeRequestStatus: PersistItemsReducer<ChangeRequestStatusResponse>
	persistedTenant: PersistValueReducer<TenantResponseModel>
	persistedCurrency: PersistItemsReducer<IdNameResponse>
	level3Items: ScopeItemLevelResponse[]
}

export const Realization = ({
	changeRequest,
	project,
	persistedUser,
	persistedChangeRequestStatus,
	persistedTenant,
	persistedCurrency,
	level3Items
}: Props) => {
	const estimatedByUser = changeRequest.estimatedByUserId ? persistedUser.itemsMap[changeRequest.estimatedByUserId] : undefined;
	const realizationByUser = changeRequest.realizationUserId ? persistedUser.itemsMap[changeRequest.realizationUserId] : undefined;

	return (
		<>
			<FormField
				label='Project Id'
				value={getFormatedId(EntityPrefixEnum.PROJECT, project.id)}
			/>
			<FormField
				label='Project name'
				value={project.name}
				marginBottom={15}
			/>
			<FormField
				label='Change Request Id'
				value={getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, changeRequest.id)}
			/>
			<FormField
				label='Change Request name'
				value={changeRequest.name}
			/>
			<FormField
				label='Status'
				value={persistedChangeRequestStatus.itemsMap[changeRequest.statusId]?.name}
				marginBottom={15}
			/>
			<FormField
				label='Estimated by'
				value={estimatedByUser && `${estimatedByUser.firstName} ${estimatedByUser.lastName}`}
				marginBottom={15}
			/>
			<FormField
				label='Man hours'
				value={changeRequest.activities?.reduce((accSum, a) => accSum + a.effortInHours, 0)?.toString()}
			/>
			<FormField
				label='Total costs'
				value={`${(changeRequest.activities || []).reduce((accSum, a) => accSum + a.cost, 0)} ${persistedCurrency.itemsMap[persistedTenant.value.currencyId]?.symbol}`}
				marginBottom={15}
			/>
			<FormField
				label='Approved by'
				value={realizationByUser && `${realizationByUser.firstName} ${realizationByUser.lastName}`}
			/>
			<FormField
				label='Approved on'
				value={formatDate(changeRequest.approvalActionOn)}
			/>
			<FormField
				label='Scope item link - level 3'
				value={changeRequest.scopeItemLevelIds?.map(x => level3Items.find(item => item.id === x)?.fullname).join(',')}
			/>
			<FormGroup label='Link to test case evidence' >
				<TextareaFormField
					value={changeRequest.linkToEvidence}
				/>
			</FormGroup>
			<FormGroup label='Comments' >
				<TextareaFormField
					value={changeRequest.comment}
				/>
			</FormGroup>
		</>
	);
};
