import { defaultProcessStepColumns } from './tableColumns';
import { CustomTestPlanRowModel } from './tableModel';

export const onCellEdit = (cell: any) => {
	const field = cell.getField();
	const parentId = cell.getData().parentId;
	const table = cell.getTable();
	const tableData = table.getData();
	const value = cell.getValue();
	const newData = tableData.map(
		(data: CustomTestPlanRowModel) => {
			if (data.parentId !== parentId || defaultProcessStepColumns.includes(field)) {
				return data;
			}

			let duration = data.duration;
			let plannedTimeTo = data.plannedTimeTo;
			if (field === 'plannedTime' && plannedTimeTo) {
				duration = value ? plannedTimeTo - value : undefined;
			}
			if (field === 'plannedTimeTo' && data.plannedTime) {
				duration = value ? value - data.plannedTime: undefined;
			}
			if (field === 'duration' && data.plannedTime) {
				plannedTimeTo = value ?  data.plannedTime + value : undefined;
			}

			return new CustomTestPlanRowModel({...data, duration, plannedTimeTo, [field]: value})
		}
	)
	table.setData(newData);
}

export const editableTestPlanCheck = function(cell: any) {
	const data: CustomTestPlanRowModel = cell.getData();
	return !!data.childId;
}
