import { getProjectStatusesAction, updateProjectStatusesAction } from './action';
import { ProjectStatusesHelp } from './ProjectStatusHelp';
import { StatusResponseTable } from 'features/StatusResponse/StatusResponseTable';
import { ContentShell } from 'features/Content/ContentShell';

export const Projects = () => {
	return (
		<ContentShell
			InlineHelpComponent={ProjectStatusesHelp}
			showBreadcrumb={false}
		>
			<StatusResponseTable
				getConfigsAction={getProjectStatusesAction}
				updateConfigsAction={updateProjectStatusesAction}
			/>
		</ContentShell>
	)
}
