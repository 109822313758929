import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ProjectBusinessResponse } from 'services/tenantManagementService';
import { CheckboxField, currencyDefaultProps, Form, InputField, InputNumberField } from 'components/Form';
import { RootState } from 'base/reducer/reducer';
import { useCurrencySuffixMemo } from 'features/Currency/useCurrencySuffixMemo';

type Props = {
	business?: ProjectBusinessResponse
	cancel(): void
	save(business: ProjectBusinessResponse): void
}

export const PotentialAdditionalBussinesForm = ({ business, cancel, save }: Props) => {
	const {
		persistedTenant,
		persistedCurrency
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(business || new ProjectBusinessResponse());

	const onSubmitCallback = useCallback(
		async () => await save(values),
		[save, values]
	)

	const currencySymbolSuffix = useCurrencySuffixMemo(persistedCurrency, persistedTenant);

	return (
		<Form
			values={values}
			initialValues={business}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<CheckboxField
							id='isActive'
							label='Active'
						/>
						<InputField
							id='name'
							label='Potential bussines'
							isRequired
							// disabled={!!business}
						/>
						<InputNumberField
							id='value'
							label='Value'
							suffix={currencySymbolSuffix}
							{...currencyDefaultProps}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
