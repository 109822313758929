import { HelpShell } from 'features/Help/HelpShell';

export const CommunicationStatusesHelp = () => {
	return (
		<HelpShell title='Status report'>
			<div>
				<p>Status reports can have 3 statuses:</p>
				<ul>
					<li>Created</li>
					<li>Released</li>
					<li>Aligned</li>
				</ul>
			</div>
			<p>
				These statuses can not be changed or deleted, but you can add explanation for each status.
			</p>
			<p>
				Team members can initially create status report and save it. At this time status report can be further changed. <br />
				When member releases status report, changes can be made only by user to whom report is send for alignment. Status report has new status: released. <br />
				After modifying report recipient can set status to aligned. Changes are not possible after report is aligned.
			</p>
		</HelpShell>
	)
}
