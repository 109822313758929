import { useCallback, useState } from 'react';
import { RowContainer } from 'components/Layout';
import Button from 'components/Button';
import ConfigureView from 'features/ConfigureView';
import { BaseColumnModel } from 'components/Table';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, MonthEnum } from 'services/tenantManagementService';
import { Export } from '../Export/Export';

type Props = {
	selectedId?: number
	isReleasable: boolean
	releaseDisabled: boolean
	deleteDisabled: boolean
	saveDisabled: boolean
	disabled: boolean
	onAdd(): void
	onDelete(id: number): void
	onSave(): Promise<void>
	onRelease(): Promise<void>
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	exportFunction(nonProjectCategoryId: number | undefined, projectId: number | undefined, years: number[] | undefined, months: MonthEnum[] | undefined, exportModel: ExportDataModel): Promise<FileResponse>
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

const TableButtons = ({
	selectedId,
	isReleasable,
	releaseDisabled,
	deleteDisabled,
	saveDisabled,
	disabled,
	onAdd,
	onDelete,
	onSave,
	onRelease,
	configureViewKey,
	tableColumns,
	exportFunction,
}: Props) => {

	const [isSaving, setIsSaving] = useState(false);
	const saveCallback = useCallback(
		async () => {
			setIsSaving(true);
			await onSave()
			setIsSaving(false);
		},
		[onSave]
	)

	const [isReleasing, setIsReleasing] = useState(false);
	const releaseCallback = useCallback(
		async () => {
			setIsReleasing(true);
			await onRelease()
			setIsReleasing(false);
		},
		[onRelease]
	)
	return (
		<>
			<RowContainer justifyContent='space-between'>
				<RowContainer>
					<Button
						onClick={onAdd}
						disabled={disabled}
						text='Add new'
					/>
					<Button
						text='Delete'
						color='destructive'
						onClick={() => { onDelete(selectedId!) }}
						disabled={!selectedId || disabled || deleteDisabled}
					/>
				</RowContainer>
				<RowContainer>
					<Button
						onClick={saveCallback}
						disabled={disabled || saveDisabled}
						text='Save'
						isLoading={isSaving}
					/>
					{isReleasable && (
						<Button
							onClick={releaseCallback}
							disabled={releaseDisabled || disabled}
							text='Release for approval'
							isLoading={isReleasing}
						/>
					)}
				</RowContainer>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
					<Export
						tableColumns={tableColumns}
						exportAction={exportFunction}
						exportTypeOptions={EXPORT_TYPE_OPTIONS}
						title='Timesheets'
					/>
				</RowContainer>
			</RowContainer>
		</>
	)
}

export default TableButtons;
