import { useCallback, useState } from 'react';
import Button from 'components/Button';
import VacationRequestDocument from './Document/VacationRequestDocument';
import { VacationRequestResponse } from 'services/tenantManagementService';
import { saveLocalDocumentToPDF } from 'utils/reactPDFUtils';
import notifications from 'components/Notification/notification';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';

type Props = {
	vacationRequest: VacationRequestResponse
}

const Export = ({ vacationRequest }: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const {
		persistedCompanyInfo,
		persistedTimeAndTravelStatus,
		persistedUser,
	} = useSelector((state: RootState) => state);

	const downloadPDFCallback = useCallback(
		async () => {
			setIsLoading(true);

			const error = await saveLocalDocumentToPDF(
				`Vacation_request_${getFormatedId(EntityPrefixEnum.VACATION_REQUEST, vacationRequest.id)}`,
				<VacationRequestDocument
					vacationRequest={vacationRequest}
					persistedUser={persistedUser}
					persistedTimeAndTravelStatus={persistedTimeAndTravelStatus}
					base64Logo={persistedCompanyInfo.value?.logoInPDF}
				/>
			);

			error && notifications.error(error);

			setIsLoading(false);
		},
		[
			vacationRequest,
			persistedUser,
			persistedTimeAndTravelStatus,
			persistedCompanyInfo
		]
	)

	return (
		<Button
			text='Export to PDF'
			color='secondary'
			onClick={downloadPDFCallback}
			isLoading={isLoading}
		/>
	)
}

export default Export;
