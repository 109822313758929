import { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import { ColumnContainer } from 'components/Layout';
import { TicketForm } from './TicketForm';
import { ProjectStatusEnum, TicketResponse, TicketStatusEnum } from 'services/tenantManagementService';
import { getFormatedId, EntityPrefixEnum } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, CrudSubRoutesEnum, useGoBackFromCrudAndPush } from 'features/Crud';
import { useFetchTicketMemo } from './helper';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ContentShell } from 'features/Content/ContentShell';
import { Follow } from './Follow/Follow';

type ParamType = {
	ticketId: string;
}

export const ReadTicket = () => {
	const params: ParamType = useParams();
	const ticketId = parseInt(params.ticketId);

	const [ticket, setTicket] = useState(new TicketResponse());
	const { persistedTicketStatus, persistedProject, persistedProjectStatus } = useSelector((state: RootState) => state);

	const fetchDataCallback = useFetchTicketMemo(ticketId, setTicket);
	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read, undefined, true);

	const goUpdateCallback = useCallback(
		() => {
			goBackFromCrudAndPush(`${CrudSubRoutesEnum.Update}/${ticketId}`);
		},
		[ticketId, goBackFromCrudAndPush]
	)

	const showChangeMemo = useMemo(
		() => !isStatusBySemantic(TicketStatusEnum.Closed, ticket.statusId, persistedTicketStatus.itemsMap) &&
			!isStatusBySemantic(ProjectStatusEnum.Completed, persistedProject.itemsMap[ticket.projectId]?.statusId, persistedProjectStatus.itemsMap),
		[persistedTicketStatus.itemsMap, persistedProject, persistedProjectStatus,  ticket]
	)

	const renderFollowCallback = useCallback(
		() => <Follow ticket={ticket} />,
		[ticket]
	)

	return (
		<ContentShell
			title={`View ticket - ${getFormatedId(EntityPrefixEnum.TICKET, ticketId)}`}
			renderAdditionalHeaderContent={renderFollowCallback}
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<ColumnContainer margin='medium'>
					{showChangeMemo && <Button text='Change' onClick={goUpdateCallback} />}
					<TicketForm
						ticket={ticket}
						crud={CrudEnum.Read}
					/>
				</ColumnContainer>
			</WithFetch>
		</ContentShell>
	)
}
