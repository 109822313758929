import path from 'path'
import { RootRouteEnum } from "features/Routes"
import { Notification } from "../Notification/Notification"
import { NotificationComponentProps } from "../NotificationsPanel"
import { TestPlanStatusChangedNotificationAdditional } from "./types"
import { useFormattedIdUsernameAndTrigger } from "./useFormattedIdUsernameAndTrigger"
import styles from '../notificationsPanel.module.scss'
import { CrudEnum } from 'features/Crud';
import { TestingSubRoutesEnum } from 'containers/Testing/routes'

export const TestPlanNotification = ({ notification }: NotificationComponentProps) => {
	// const Component = useMemo(
	// 	() => {
	// 		switch (notification.trigger) {
	// 			case AlertTriggersEnum.Updated:
	// 				return TestPlanStatusChangedNotification;
	// 			default:
	// 				return;
	// 		}
	// 	},
	// 	[notification]
	// )

	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as TestPlanStatusChangedNotificationAdditional : undefined

	const { username, triggerText } = useFormattedIdUsernameAndTrigger(notification)

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.TESTING,
				TestingSubRoutesEnum.MY_TEST_PACKAGES,
				String(additionalData?.projectId),
				String(additionalData?.cycleId),
				CrudEnum.Update,
				String(notification.entityId)
			)}
			content={
				<div>
					<span>Test plan: </span>
					<span className={styles.entity}>{additionalData?.entityName} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}
