import Tabs from 'components/Tabs/Tabs';
import { configureProjectsTabs } from './configureProjectsTabs';
import { ProjectConfigurationHelp } from './ProjectConfigurationHelp';
import { ContentShell } from 'features/Content/ContentShell';

export const ConfigureProjects = () => {
	return (
		<ContentShell
			title='Configure Projects'
			FloatingHelpComponent={ProjectConfigurationHelp}
		>
			<Tabs tabs={configureProjectsTabs} />
		</ContentShell>
	)
};
