import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { BudgetVsActualRowDashboardResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { getMonthEnumByDate } from 'utils/dateTimeUtils';
import { useFormattedCurrencyValueCallback } from 'features/TableColumns/persistedHooks';

const defaultColumns = [
	propertyOf<BudgetVsActualRowDashboardResponse>('date'),
	propertyOf<BudgetVsActualRowDashboardResponse>('budgetValue'),
	propertyOf<BudgetVsActualRowDashboardResponse>('actualBudgetValue'),
	propertyOf<BudgetVsActualRowDashboardResponse>('difference'),
	propertyOf<BudgetVsActualRowDashboardResponse>('percentageOfBudget'),
]

export const useTableColumnsMemo = (
	budgetTotal: number | undefined,
	actualsTotal: number | undefined,
	differenceTotal: number | undefined,
	budgetPercentTotal: number | undefined,
	isExpense?: boolean
) => {
	const getFormattedCurrencyValue = useFormattedCurrencyValueCallback();

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<BudgetVsActualRowDashboardResponse>('date')]: {
					title: 'Month',
					fieldType: FieldTypeEnum.Date,
					formatter: (cell: any) => {
						const data = cell.getValue();
						const fullYear = data.getFullYear();
						const month = getMonthEnumByDate(cell.getValue());
						return `${month} ${fullYear}`
					},
					bottomCalcFormatter: () => 'Total',
					disableSort: true,
					disableFilter: true
				},
				[propertyOf<BudgetVsActualRowDashboardResponse>('budgetValue')]: {
					title: 'Budget',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: () => budgetTotal,
					bottomCalcFormatter: (cell: any) => {
						const formattedValue = getFormattedCurrencyValue(cell.getValue());
						return `∑ ${isExpense ? '-' : ''}${formattedValue}`;
					},
					disableSort: true,
					disableFilter: true
				},
				[propertyOf<BudgetVsActualRowDashboardResponse>('actualBudgetValue')]: {
					title: 'Actuals',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: () => actualsTotal,
					bottomCalcFormatter: (cell: any) => {
						const formattedValue = getFormattedCurrencyValue(cell.getValue());
						return `∑ ${isExpense ? '-' : ''}${formattedValue}`;
					},
					disableSort: true,
					disableFilter: true
				},
				[propertyOf<BudgetVsActualRowDashboardResponse>('difference')]: {
					title: 'Difference',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: () => differenceTotal,
					disableSort: true,
					disableFilter: true
				},
				[propertyOf<BudgetVsActualRowDashboardResponse>('percentageOfBudget')]: {
					title: '% of Budget',
					formatter: (cell: any) => cell.getValue().toFixed(2) + '%',
					fieldType: FieldTypeEnum.Number,
					bottomCalc: () => budgetPercentTotal,
					bottomCalcFormatter: (cell: any) => {
						const value = cell.getValue();
						return value ? `${value.toFixed(2)}%` : value;
					},
					disableSort: true,
					disableFilter: true,
					hozAlign: 'right'
				},

			}
		},
		[budgetTotal, actualsTotal, differenceTotal, budgetPercentTotal, getFormattedCurrencyValue, isExpense]
	)

	return useVisibleColumns(columns, defaultColumns);
}
