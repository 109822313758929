import { RiskIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";

// subroutes

export enum RiskSubRoutesEnum {
	MY_DASHBOARD = 'risk_my_dashboard',
	RISK_PLAN = 'risk_plan',
	RISK_LIST= 'risk_list',
}

export const myDashboardSubRoute: Route = {
	id: RiskSubRoutesEnum.MY_DASHBOARD,
	url: `/${RootRouteEnum.RISK}/${RiskSubRoutesEnum.MY_DASHBOARD}`,
	text: 'My Dashboard',
}

export const riskPlanSubRoute: Route = {
	id: RiskSubRoutesEnum.RISK_PLAN,
	url: `/${RootRouteEnum.RISK}/${RiskSubRoutesEnum.RISK_PLAN}`,
	text: 'Risk plan',
}

export const riskListSubRoute: Route = {
	id: RiskSubRoutesEnum.RISK_LIST,
	url: `/${RootRouteEnum.RISK}/${RiskSubRoutesEnum.RISK_LIST}`,
	text: 'Risk list',
}

// root route

export const riskRoute: Route = {
	id: RootRouteEnum.RISK,
	url: `/${RootRouteEnum.RISK}`,
	text: 'Risk',
	Icon: RiskIcon,
	children: [
		myDashboardSubRoute,
		riskPlanSubRoute,
		riskListSubRoute
	]
}
