import { CrudButtons } from "features/Crud"

type Props = {
	selectedId: number | undefined
	onDelete: (id: number) => Promise<void>
}

export const TableButtons = ({ selectedId, onDelete }: Props) => {
	return (
		<CrudButtons
			selectedId={selectedId}
			onDelete={onDelete}
			isViewable={false}
		/>
	)
}
