import path from 'path';
import { CrudEnum } from 'features/Crud';
import { RootRouteEnum } from 'features/Routes';
import { CommunicationSubRoutesEnum } from 'containers/Communication/routes';
import { TimeTravelSubRoutesEnum } from 'containers/TimeTravel/routes';
import { ScopeSubRoutesEnum } from 'containers/Scope/routes';

export const getChangeRequestRoute = (changeRequestId: number, projectId: number) => path.join('/', RootRouteEnum.SCOPE, ScopeSubRoutesEnum.CHANGE_REQUESTS, String(projectId), CrudEnum.Read, String(changeRequestId))

export const getMeetingRoute = (meetingId: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	return path.join(
		'/',
		RootRouteEnum.COMMUNICATION,
		CommunicationSubRoutesEnum.PROJECT_MEETINGS,
		String(projectOrCategoryId),
		String(isProjectConnected),
		CrudEnum.Read,
		String(meetingId)
	)
}

export const getTaskRoute = (taskId: number) => {
	return path.join(
		'/',
		RootRouteEnum.COMMUNICATION,
		CommunicationSubRoutesEnum.TASKS,
		CrudEnum.Read,
		String(taskId)
	)
}

export const getReminderRoute = (taskId: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	return path.join(
		'/',
		RootRouteEnum.COMMUNICATION,
		CommunicationSubRoutesEnum.REMINDERS,
		String(projectOrCategoryId),
		String(isProjectConnected),
		CrudEnum.Update,
		String(taskId)
	)
}

export const getVacationRoute = (vacationId: number) => {
	return path.join(
		'/',
		RootRouteEnum.TIME_TRAVEL,
		TimeTravelSubRoutesEnum.MY_VACATION_REQUESTS,
		CrudEnum.Read,
		String(vacationId)
	)
}
