import { ProjectSubcontractorClient, InsertProjectSubcontractorRequest, UpdateProjectSubcontractorRequest, GenericFilterModelCollection, ExportDataModel } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getSubcontractorsPerformancesAction = (reportId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectSubcontractorClient();
	return client.getAll(tenantId, reportId, undefined, undefined, undefined, undefined);
}

export const getSubcontractorsPerformancesGenericAction = (reportId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectSubcontractorClient();
	return client.getAllGenericFilter(tenantId, reportId, genericFilter);
}

export const createSubcontractorsPerformanceAction = (reportId: number, model: InsertProjectSubcontractorRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectSubcontractorClient();
	return client.create(tenantId, reportId, model);
}

export const updateSubcontractorsPerformanceAction = (reportId: number, model: UpdateProjectSubcontractorRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectSubcontractorClient();
	return client.update(tenantId, reportId, model);
}

export const getSubcontractorsPerformanceAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectSubcontractorClient();
	return client.get(tenantId, reportId, id);
}

export const deleteSubcontractorsPerformanceAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectSubcontractorClient();
	return client.delete(tenantId, reportId, id);
}

export const exportAction = (reportId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectSubcontractorClient();
	return client.export(tenantId, reportId, exportDataModel);
}
