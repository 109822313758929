import { WithProjectPicker, pmOrSpmPermission } from 'features/Project';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import { ContentShell } from 'features/Content/ContentShell';
import { GenericFilterModelCollection, ModuleActivityEnum, MySurveyResponse, ProjectResponse, TrainingPermission, TrainingStatusEnum } from 'services/tenantManagementService';
import { getAllMySurveysGenericAction } from './action';
import { persistTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { useTableColumnsMemo } from './Table/tableColumns';
import TableButtons from './Table/TableButtons';
import { mySurveysChangedTopic } from './mySurveysChanged';
import { MySurveysHelp } from './Help/MySurveysHelp';
import { RemoteTable } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';

type Props = {
	project: ProjectResponse
}

const MySurveys = ({ project }: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const [selectedSurvey, setSelectedSurvey] = useState(new MySurveyResponse());
	const { persistedTrainingStatus, persistedTeamMember } = useSelector((state: RootState) => state);

	const tableColumns = useTableColumnsMemo();

	useEffect(
		() => {
			if (!persistedTeamMember.projectMap[project.id]) {
				persistTeamMembersAction(project.id);
			}
		},
		[persistedTeamMember, project.id]
	)

	// just a quick fix, memoFetchFunction was changed after project is changed and unneeded request was send
	const [currentProject, setCurrentProject] = useState(new ProjectResponse(project));
	useEffect(
		() => {
			setCurrentProject(new ProjectResponse(project));
		},
		[project]
	)

	const handleRowSelectionChange = useCallback(
		(data: MySurveyResponse[]) => {
			setSelectedSurvey(data[0] || new MySurveyResponse());
		},
		[]
	);

	const memoFetchFunction = useMemo(
		() => async (genericFilter: GenericFilterModelCollection) => {
			setIsLoading(true);
			const response = await getAllMySurveysGenericAction(currentProject.id, genericFilter)
			setIsLoading(false);
			return response
		},
		[currentProject.id]
	)

	const changeDisabledMemo = useMemo(
		() => {
			if (!selectedSurvey) {
				return true;
			}

			const status = persistedTrainingStatus.itemsMap[selectedSurvey.statusId];
			return status?.semantics === TrainingStatusEnum.Completed
		},
		[persistedTrainingStatus.itemsMap, selectedSurvey]
	)

	const viewDisabledMemo = useMemo(
		() => {
			if (!selectedSurvey) {
				return true;
			}

			const status = persistedTrainingStatus.itemsMap[selectedSurvey.statusId];
			return status?.semantics !== TrainingStatusEnum.Completed
		},
		[persistedTrainingStatus.itemsMap, selectedSurvey]
	)

	return (
		<ContentShell
			title='My Surveys'
			FloatingHelpComponent={MySurveysHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedSurvey.id}
					changeDisabled={changeDisabledMemo}
					viewDisabled={viewDisabledMemo}
					disabled={isLoading}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					fetchFunction={memoFetchFunction}
					isLoading={isLoading}
					rowSelectionChanged={handleRowSelectionChange}
					subscriptionTopic={mySurveysChangedTopic}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectPicker(MySurveys, ModuleActivityEnum.Training, true, propertyOf<TrainingPermission>('mySurveys'), pmOrSpmPermission);
