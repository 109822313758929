import { HelpShell } from 'features/Help/HelpShell';

export const TravelCostTypesHelp = () => {
	return (
		<HelpShell title='Configure travel cost types'>
			<p>
				Travel cost types can be configured to distinguish different classification of travel costs. Mavles comes with several default entries: hotel, taxi, train, rent-a-car, airplane, bus, daily allowance, parking, toll, fuel and other costs. You can configure travel cost types based on your company needs. <br />
				Here you can also define if attaching receipts is mandatory when submitting your travel expense.

			</p>
			<p>
				For each entry you are configuring, you can add additional explanation for users. When marked as 'active', entries will be shown in user selection. Please note that you cannot delete entries if there is data with these entries. You can only uncheck 'active' box and users will not be able to select this option.
			</p>
		</HelpShell>
	)
}
