import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns, linksCellFormatter } from 'components/Table';
import { TravelExpenseItemResponse, IdNameResponse, CostExpenseTypeResponse, AttachmentResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { downloadAttachmentAction } from 'containers/TimeTravel/MyTravelExpenses/action';
import FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
    propertyOf<TravelExpenseItemResponse>('costTypeId'),
    propertyOf<TravelExpenseItemResponse>('amountProjectCurrency'),
    propertyOf<TravelExpenseItemResponse>('amountReceiptCurrency'),
	propertyOf<TravelExpenseItemResponse>('receiptCurrencyId'),
	propertyOf<TravelExpenseItemResponse>('attachments')
]

export const useTableColumnsMemo = (
	visibleColumns: string[] = defaultVisibleColumns,
) => {
	const { 
		persistedTravelCostTypes,
		persistedCurrency
	} = useSelector((state: RootState) => state);
	
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<TravelExpenseItemResponse>('costTypeId')]: {
					title: 'Cost type',
					formatter: (cell: any) => {
						const id = cell.getValue();
						return persistedTravelCostTypes.itemsMap[id]?.name;
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTravelCostTypes.items,
					getItemId: (option: CostExpenseTypeResponse) => option.id,
					getItemText: (option: CostExpenseTypeResponse) => option.name
				},
				[propertyOf<TravelExpenseItemResponse>('amountProjectCurrency')]: {
					title: 'Amount in project currency',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: 'sum'

				},
				[propertyOf<TravelExpenseItemResponse>('amountReceiptCurrency')]: {
					title: 'Amount in receipt currency',
					fieldType: FieldTypeEnum.Number,
					bottomCalc: 'sum',
					bottomCalcFormatter: (cell: any) => `∑ ${cell.getValue() || 0}`
				},
				[propertyOf<TravelExpenseItemResponse>('receiptCurrencyId')]: {
					title: 'Receipt currency',
					formatter: (cell: any) => {
						const id = cell.getValue();
						return persistedCurrency.itemsMap[id]?.symbol;
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedCurrency.items,
					getItemId: (option: IdNameResponse) => option.id,
					getItemText: (option: IdNameResponse) => option.symbol
				},
				[propertyOf<TravelExpenseItemResponse>('attachments')]: {
					title: 'Attachments',
					fieldType: FieldTypeEnum.None,
					disableSort: true,
					disableFilter: true,
					formatter: (cell: any, formatterParams: any, onRendered: Function) => linksCellFormatter(cell, formatterParams, onRendered, true),
					formatterParams: {
						getLinks: (value: AttachmentResponse[]) => value || [],
						getLinkTitle: (link: AttachmentResponse) => link.name,
						getLinkUrl: () => '#',
						onClickLink: async (e: MouseEvent, link: AttachmentResponse, cell: any) => {
							e.preventDefault();
							const data: TravelExpenseItemResponse = cell.getData();
							const travelExpenseItemId = data.id;
							const response = await downloadAttachmentAction(travelExpenseItemId, link.id);
							if (response.status === 200) {
								FileSaver.saveAs(response.data, response.fileName);
							};
						},
						linkTarget: null,
					},
					headerFilter: false,
				},
			};
		},
		[persistedCurrency, persistedTravelCostTypes]
	)

	return useVisibleColumns(columns, visibleColumns);
}
