import { RootState } from "base/reducer/reducer";
import { AutoCompleteField } from "components/Form"
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { TicketCategoryResponse, TicketResponse } from "services/tenantManagementService"
import { propertyOf } from "utils/propertyOf"

type Props = {
	values: TicketResponse
}

export const Categories = ({ values }: Props) => {
	const {
		persistedTicketCategory
	} = useSelector((state: RootState) => state);

	const activeCategoryLvl2 = useMemo(
		() => {
			const category = persistedTicketCategory.items.filter(item => item.levelNumber === 2 && item.parentId === values.categoryLevel1Id)
			if (category) {
				return category.filter(cat => cat.isActive);
			}

			return [];
		},
		[values.categoryLevel1Id, persistedTicketCategory]
	)

	const activeCategoryLvl3 = useMemo(
		() => {
			const category = persistedTicketCategory.items.filter(item => item.levelNumber === 3 && item.parentId === values.categoryLevel2Id)
			if (category) {
				return category.filter(cat => cat.isActive);
			}

			return [];
		},
		[values.categoryLevel2Id, persistedTicketCategory]
	)

	const onCategoryLevel1ChangeCallback = useCallback(
		(_newcategoryLevel1Id: number | undefined, _oldValue: TicketResponse) => ({
			[propertyOf<TicketResponse>('categoryLevel2Id')]: undefined,
			[propertyOf<TicketResponse>('categoryLevel3Id')]: undefined
		}),
		[]
	)

	const onCategoryLevel2ChangeCallback = useCallback(
		(_newcategoryLevel2Id: number | undefined, _oldValue: TicketResponse) => ({
			[propertyOf<TicketResponse>('categoryLevel3Id')]: undefined
		}),
		[]
	)

	return (
		<>
			<AutoCompleteField
				id={propertyOf<TicketResponse>('categoryLevel1Id')}
				label='Category lvl 1'
				isRequired
				items={persistedTicketCategory.activeItems.filter(item => item.levelNumber === 1)}
				getItemId={(item: TicketCategoryResponse) => item.id}
				getItemText={(item: TicketCategoryResponse) => item.name}
				getItemDescription={(item: TicketCategoryResponse) => item.description}
				loading={persistedTicketCategory.fetching}
				updateDependants={onCategoryLevel1ChangeCallback}
			/>
			<AutoCompleteField
				id={propertyOf<TicketResponse>('categoryLevel2Id')}
				label='Category lvl 2'
				items={activeCategoryLvl2}
				getItemId={(item: TicketCategoryResponse) => item.id}
				getItemText={(item: TicketCategoryResponse) => item.name}
				getItemDescription={(item: TicketCategoryResponse) => item.description}
				loading={persistedTicketCategory.fetching}
				updateDependants={onCategoryLevel2ChangeCallback}
			/>
			<AutoCompleteField
				id={propertyOf<TicketResponse>('categoryLevel3Id')}
				label='Category lvl 3'
				items={activeCategoryLvl3}
				getItemId={(item: TicketCategoryResponse) => item.id}
				getItemText={(item: TicketCategoryResponse) => item.name}
				getItemDescription={(item: TicketCategoryResponse) => item.description}
				loading={persistedTicketCategory.fetching}
			/>
		</>
	)
}
