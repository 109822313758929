import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { DateField, Form, Input, InputField, TextareaField, CheckboxField, AttachmentField, MultiSelectField, AutoComplete, AutoCompleteField } from 'components/Form';
import { SimpleIdNameModel, ChangeRequestFullResponse, ChangeRequestFullResponseSimpleResponseModel, ChangeRequestStatusEnum, ImpactResponse, InsertChangeRequestRequest, TenantIsActiveResponse, UpdateChangeRequestRequest, UrgencyResponse, UserModel, TicketResponse } from 'services/tenantManagementService';
import Button from 'components/Button';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { propertyOf } from 'utils/propertyOf';
import { usePriorityNameMemo } from 'features/Priority/usePriorityNameMemo';
import { createChangeRequestAction, downloadAttachmentAction, updateChangeRequestAction } from '../../../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import notifications from 'components/Notification/notification';
import { ColumnContainer, RowContainer } from 'components/Layout';
import SendEmail from '../../components/SendEmail';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import ScopeItemsMultiselect from '../../components/ScopeItemsMultiselect';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { TabProps } from '../../CreateChangeRequest';
import { ExportProblemReport } from 'containers/Scope/ChangeRequests/Export/ExportProblemReport';
import { getAllTicketsSimpleAction } from 'containers/Tickets/ViewTickets/action';
import { useHistory } from 'react-router-dom';
import { getReadTicketRoute } from 'containers/Tickets/utils';
import { getNumberArraysDiff } from 'utils/arrayUtil';

type Props = TabProps;

const ProblemReport = ({
	projectId,
	changeRequest,
	crud,
	publishDataChanged
}: Props) => {
	const history = useHistory();
	const ticketThatInitiated = (history.location.state as any | undefined)?.ticket as TicketResponse;
	const isInitiatedFromTicket = !!ticketThatInitiated;

	const {
		persistedTicketImpact,
		persistedTicketUrgency,
		persistedTicketPriority,
		persistedChangeRequestType,
		persistedChangeRequestStatus,
		persistedUser
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(new ChangeRequestFullResponse());
	const goBackFromCrud = useGoBackFromCrud(crud, false, true);
	const isInitiateRef = useRef(false);

	useEffect(
		() => {
			setValues(new ChangeRequestFullResponse(changeRequest));
		},
		[changeRequest]
	)

	const onSubmitCallback = useCallback(
		async () => {
			let bindedAction: () => Promise<ChangeRequestFullResponseSimpleResponseModel>;

			if (crud === CrudEnum.Create) {
				const model = new InsertChangeRequestRequest({
					...values,
					scopeItemLevelIdsToAdd: values.scopeItemLevelIds,
					isInitiate: isInitiateRef.current,
					sendEmail: values.sendEmailInitial,
					ticketIdsToAdd: values.ticketIds
				});

				bindedAction = createChangeRequestAction.bind(null, projectId, model);
			} else {
				const scopeItemLevelIdsToAdd = (values.scopeItemLevelIds || []).filter((item) => (!changeRequest.scopeItemLevelIds || changeRequest.scopeItemLevelIds.includes(item) === false));
				const scopeItemLevelIdsToRemove = (changeRequest.scopeItemLevelIds || []).filter((item) => (!values.scopeItemLevelIds || values.scopeItemLevelIds.includes(item) === false));

				const ticketsDiff = getNumberArraysDiff(changeRequest.ticketIds, values.ticketIds);

				const model = new UpdateChangeRequestRequest({
					...values,
					isInitiate: isInitiateRef.current,
					scopeItemLevelIdsToAdd,
					scopeItemLevelIdsToRemove,
					sendEmail: values.sendEmailInitial,
					ticketIdsToAdd: ticketsDiff.added,
				    ticketIdsToRemove: ticketsDiff.removed
				});

				bindedAction = updateChangeRequestAction.bind(null, projectId, model);
			}

			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, response.value?.id);
				notifications.success(`Change request ${id} is ${isInitiateRef.current ? 'initiated' : 'saved'}.`);
				publishDataChanged();
				// reset to default value
				isInitiateRef.current = false;

				if (isInitiatedFromTicket) {
					// go back to ticketAssignedGroupsReducer
					history.push(getReadTicketRoute(ticketThatInitiated.id));
				} else {
					goBackFromCrud();
				}
			} else {
				// reset to default value
				isInitiateRef.current = false;
				return convertResponseErrors(response);
			}
		},
		[projectId, values, goBackFromCrud, crud, changeRequest, publishDataChanged, isInitiatedFromTicket, ticketThatInitiated, history]
	)

	const downloadAttachmentMemo = useMemo(
		() => downloadAttachmentAction.bind(null, projectId),
		[projectId]
	)

	// Svetlana asked to disable delete on existing attachments
	// const removeAttachmentCallback = useCallback(
	// 	async (id: number) => {
	// 		const bindedAction = removeAttachmentAction.bind(null, projectId, changeRequest.id, id);
	// 		const response = await tryCatchJsonByAction(bindedAction);
	// 		if (response.success) {
	// 			notifications.success('Attachment is successfully deleted');
	// 			setValues((state: ChangeRequestFullResponse) =>
	// 				new ChangeRequestFullResponse({
	// 					...state,
	// 					problemReportAttachments: state.problemReportAttachments?.filter(att => att.id !== id)
	// 				})
	// 			)
	// 		}
	// 	},
	// 	[projectId, changeRequest.id]
	// )

	// fetch tickets
	const [tickets, setTickets] = useState<SimpleIdNameModel[]>([]);
	const [isFetchingTickets, setIsFetchingTickets] = useState(false);

	const fetchTicketsCallback = useCallback(
		async () => {
			setIsFetchingTickets(true);

			const response = await tryCatchJsonByAction(getAllTicketsSimpleAction);
			if (response.success) {
				setTickets(response.items || []);
			}

			setIsFetchingTickets(false);
		},
		[]
	)

	useEffect(
		() => {
			fetchTicketsCallback();
		},
		[fetchTicketsCallback]
	)

	// calculate priority
	const priorityName = usePriorityNameMemo(persistedTicketPriority, values.impactId, values.urgencyId);

	const disabled = useMemo(
		() => (crud !== CrudEnum.Update && crud !== CrudEnum.Create) || !isStatusBySemantic(ChangeRequestStatusEnum.Created, changeRequest.statusId, persistedChangeRequestStatus.itemsMap),
		[crud, persistedChangeRequestStatus.itemsMap, changeRequest.statusId]
	)

	const isRead = crud === CrudEnum.Read;

	return (
		<Form
			values={values}
			initialValues={changeRequest}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			disabled={disabled}
			render={() => (
				<ColumnContainer margin='medium'>
					<SmartContainer>
						<SmartItem>
							<InputField
								id={propertyOf<ChangeRequestFullResponse>('name')}
								label='Change Request Name'
								isRequired
							/>
							<SmartFormGroup label='Requested by'>
								<AutoComplete
									value={changeRequest.requestedByUserId}
									items={persistedUser.items}
									getItemId={(item: UserModel) => item.id}
									getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
									loading={persistedUser.fetching}
									disabled
								/>
							</SmartFormGroup>
							<InputField
								id={propertyOf<ChangeRequestFullResponse>('initiatedBy')}
								label='Initiated by'
							/>
							<DateField
								id={propertyOf<ChangeRequestFullResponse>('requestedOn')}
								label='Requested on'
							/>
							<AutoCompleteField
								id={propertyOf<ChangeRequestFullResponse>('typeId')}
								label='Change request Type'
								items={persistedChangeRequestType.activeItems}
								getItemId={(item: TenantIsActiveResponse) => item.id}
								getItemText={(item: TenantIsActiveResponse) => item.name}
								getItemDescription={(item: TenantIsActiveResponse) => item.description}
								loading={persistedChangeRequestType.fetching}
							/>
							<CheckboxField
								id={propertyOf<ChangeRequestFullResponse>('goLiveCritical')}
								label='Go-live critical'
							/>
							<AutoCompleteField
								id={propertyOf<ChangeRequestFullResponse>('impactId')}
								label='Impact'
								isRequired
								items={persistedTicketImpact.items}
								getItemId={(item: ImpactResponse) => item.id}
								getItemText={(item: ImpactResponse) => item.name}
								getItemDescription={(item: ImpactResponse) => item.description}
								loading={persistedTicketImpact.fetching}
							/>
							<AutoCompleteField
								id={propertyOf<ChangeRequestFullResponse>('urgencyId')}
								label='Urgency'
								isRequired
								items={persistedTicketUrgency.items}
								getItemId={(item: UrgencyResponse) => item.id}
								getItemText={(item: UrgencyResponse) => item.name}
								getItemDescription={(item: UrgencyResponse) => item.description}
								loading={persistedTicketUrgency.fetching}
							/>
							<SmartFormGroup label='Priority'>
								<Input
									value={priorityName}
									disabled
								/>
							</SmartFormGroup>
							<DateField
								id={propertyOf<ChangeRequestFullResponse>('expectedCompletionDate')}
								label='Expected completion date'
							/>
							<ScopeItemsMultiselect
								id={propertyOf<ChangeRequestFullResponse>('scopeItemLevelIds')}
								label='Process ID - level 3'
								projectId={projectId}
							/>
							<MultiSelectField
								id={propertyOf<ChangeRequestFullResponse>('ticketIds')}
								label='Tickets'
								items={tickets}
								getItemId={(item: SimpleIdNameModel) => item.id}
								getItemText={(item: SimpleIdNameModel) => `${getFormatedId(EntityPrefixEnum.TICKET, item.id)} - ${item.name}`}
								loading={isFetchingTickets}
							/>
							<AttachmentField
								id={propertyOf<InsertChangeRequestRequest>('newAttachments')}
								label='Attachments'
								multiple
								disabled={isRead}
								oldAttachments={values.problemReportAttachments}
								// removeOldAttachment={disabled ? undefined : removeAttachmentCallback}
								downloadOldAttachment={downloadAttachmentMemo}
							/>
						</SmartItem>
						<SmartItem>
							<TextareaField
								id={propertyOf<ChangeRequestFullResponse>('description')}
								label='Description of change'
								rows={6}
								maxLength={4000}
							/>
							<TextareaField
								id={propertyOf<ChangeRequestFullResponse>('reasonOfChange')}
								label='Reason of change'
								maxLength={2000}
							/>
							<TextareaField
								id={propertyOf<ChangeRequestFullResponse>('expectedBenefit')}
								label='Expected benefit'
								maxLength={2000}
							/>
						</SmartItem>
					</SmartContainer>
					<SendEmail
						sendEmailId={propertyOf<ChangeRequestFullResponse>('sendEmailInitial')}
						toId={propertyOf<ChangeRequestFullResponse>('analysisUserId')}
						persistedUser={persistedUser}
					/>
				</ColumnContainer>
			)}
			renderAdditionalButtons={(disabled, handleSubmitCallback: () => void, isSubmitting) => (
				<RowContainer>
					<Button
						text='Initiate'
						disabled={disabled}
						isLoading={isSubmitting && isInitiateRef.current === true}
						onClick={
							() => {
								isInitiateRef.current = true;
								handleSubmitCallback();
							}
						}
					/>
					<ExportProblemReport
						changeRequest={changeRequest}
						projectId={projectId}
					/>
				</RowContainer>
			)}
		/>
	)
}

export default ProblemReport;
