import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { ChangeRequestFullResponse, ProjectResponse } from 'services/tenantManagementService';
import { saveLocalDocumentToPDF } from 'utils/reactPDFUtils';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { RootState } from 'base/reducer/reducer';
import { getAllScopeItemsForLevelAction } from 'containers/Scope/MaintainScope/action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { AcceptanceDocument } from './Document/AcceptanceDocument';

type Props = {
	changeRequest: ChangeRequestFullResponse
	projectId: number
}

export const ExportAcceptance = ({ changeRequest,  projectId}: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const {
		persistedProject,
		persistedUser,
		persistedChangeRequestStatus,
		persistedTenant,
		persistedCurrency,
		persistedCompanyInfo,
	}  = useSelector((state: RootState) => state);

	const downloadPDFCallback = useCallback(
		async () => {
			setIsLoading(true);

			const bindedAction = getAllScopeItemsForLevelAction.bind(null, projectId, 3);
			const response = await tryCatchJsonByAction(bindedAction);
			if (!response.success || !response.items) {
				return;
			}

			const error = await saveLocalDocumentToPDF(
				`Change_request_${getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, changeRequest.id)}_acceptance`,
				<AcceptanceDocument
					changeRequest={changeRequest}
					project={persistedProject.itemsMap[projectId] || new ProjectResponse()}
					persistedUser={persistedUser}
					persistedChangeRequestStatus={persistedChangeRequestStatus}
					persistedCurrency={persistedCurrency}
					persistedTenant={persistedTenant}
					level3Items={response.items}
					base64Logo={persistedCompanyInfo.value?.logoInPDF}
				/>
			);

			error && notifications.error(error);

			setIsLoading(false);
		},
		[
			changeRequest,
			projectId,
			persistedUser,
			persistedProject,
			persistedChangeRequestStatus,
			persistedCurrency,
			persistedTenant,
			persistedCompanyInfo
		]
	)

	return (
		<Button
			text='Export to PDF'
			color='secondary'
			onClick={downloadPDFCallback}
			isLoading={isLoading}
		/>
	)
}
