import { TabType } from 'components/Tabs/Tabs';
import { ProjectDashboard } from './ProjectDashboard/ProjectDashboard';
import { ProjectsGanttDashboard } from './ProjectsGanttDashboard/ProjectsGanttDashboard';
import { UtilizationDashboard } from './UtilizationDashboard/UtilizationDashboard';
import { ProjectUtilizationDashboard } from './ProjectUtilizationDashboard/ProjectUtilizationDashboard';

enum DashboardTabSubtotes {
	PROJECT = 'project',
	PROJECTS_GANTT = 'projects_gantt',
	UTILIZATION = 'utilization',
	PROJECT_UTILIZATION = 'project_utilization'
}

export const projectTab: TabType = {
	id: '1',
	title: 'Project',
	component: ProjectDashboard,
	route: DashboardTabSubtotes.PROJECT,
}

export const projectsGanttTab: TabType = {
	id: '2',
	title: 'Portfolio Gantt',
	component: ProjectsGanttDashboard,
	route: DashboardTabSubtotes.PROJECTS_GANTT,
}

export const utilizationTab: TabType = {
	id: '3',
	title: 'Utilization',
	component: UtilizationDashboard,
	route: DashboardTabSubtotes.UTILIZATION,
}

export const projectUtilizationTab: TabType = {
	id: '4',
	title: 'Project Utilization',
	component: ProjectUtilizationDashboard,
	route: DashboardTabSubtotes.PROJECT_UTILIZATION,
}
