import { useCallback } from 'react';
import path from 'path';
import { useLocation } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud'

export const useOpenInNewTab = (
	crudSubRoute = CrudSubRoutesEnum.Read,
	prefix = '',
) => {
	const location = useLocation();

	const openInNewTabCallback = useCallback((id: number, additionalId?: number | undefined) => {
		let idPart = `${id}`;
		if (additionalId) {
			idPart += `/${additionalId}`;
		}

		window.open(path.join(location.pathname, prefix, `${crudSubRoute}/${idPart}`), '_blank');
	}, [crudSubRoute, location.pathname, prefix]);

	return openInNewTabCallback;
}
