import { useCallback, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ProjectIssueResponse } from 'services/tenantManagementService';
import { CheckboxField, Form, InputField } from 'components/Form';
import { TextareaField } from 'components/Form';

type Props = {
	issue?: ProjectIssueResponse
	cancel(): void
	save(issue: ProjectIssueResponse): void
}

export const MajorIssuesForm = ({ issue, cancel, save }: Props) => {
	const [values, setValues] = useState(issue || new ProjectIssueResponse());

	const onSubmitCallback = useCallback(
		async () => await save(values),
		[save, values]
	)

	return (
		<Form
			values={values}
			initialValues={issue}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<CheckboxField
							id='isActive'
							label='Active'
						/>
						<InputField
							id='name'
							label='Name'
							isRequired
						/>
						<TextareaField
							id='description'
							label='Description'
							rows={2}
						/>
						<TextareaField
							id='impact'
							label='Impact'
							rows={2}
						/>
						<TextareaField
							id='solution'
							label='Solution'
							rows={2}
						/>
						<TextareaField
							id='resolution'
							label='Resolution'
							rows={2}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	);
};
