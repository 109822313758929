import { ColumnContainer } from 'components/Layout'
import notifications from 'components/Notification/notification'
import { RemoteTable } from 'components/Table'
import { persistProjectTeamsAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Teams/action'
import { setConfigureViewTableAction } from 'features/ConfigureView'
import { ContentShell } from 'features/Content/ContentShell'
import { ListComponentProps } from 'features/Crud'
import { WithProjectOrCategoryPicker, ProjectOrCategoryComponentProps } from 'features/Project'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { GenericFilterModelCollection, ModuleActivityEnum, ReminderResponse } from 'services/tenantManagementService'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { deleteReminderAction, exportRemindersAction, getAllRemindersGenericFilterAction } from './action'
import { RemindersHelp } from './Help/RemindersHelp'
import { TableButtons } from './Table/TableButtons'
import { useTableColumnsMemo } from './Table/tableColumns'

type Props = ProjectOrCategoryComponentProps & ListComponentProps;

const configureViewKey = 'reminders_table';

const Reminders = ({ projectOrCategory, disabledEdit, dataChangedTopic, publishDataChanged }: Props) => {
	const [selectedReminder, setSelectedReminder] = useState(new ReminderResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const tableColumns = useTableColumnsMemo(configureViewKey);

	const memoFetchFunction = useMemo(
		() => getAllRemindersGenericFilterAction.bind(null, projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected),
		[projectOrCategory]
	)

	useEffect(
		() => {
			if (projectOrCategory.isProjectConnected) {
				persistProjectTeamsAction(projectOrCategory.projectOrCategoryId);
			}
		},
		[projectOrCategory]
	)

	const handleDelete = useCallback(
		async (id: number) => {
			const bindedAction = deleteReminderAction.bind(null, id, projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Reminder is successfully deleted');
				publishDataChanged();
			}
		},
		[projectOrCategory, publishDataChanged]
	);

	const handleRowSelectionChange = useCallback(
		(data: ReminderResponse[]) => {
			setSelectedReminder(data[0] || new ReminderResponse());
		},
		[]
	);

	const memoExportFunction = useMemo(
		() => exportRemindersAction.bind(null, projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected),
		[projectOrCategory]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='Reminders'
			FloatingHelpComponent={RemindersHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedReminder.id}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					onDelete={handleDelete}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
					disabled={disabledEdit}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={dataChangedTopic}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectOrCategoryPicker(Reminders, ModuleActivityEnum.Communication, true);
