import { HelpShell } from 'features/Help/HelpShell';

export const TicketConfigurationHelp = () => {
	return (
		<HelpShell title='Tickets'>
			<div>
				<p>Site Administrator can configure following for Tickets module:</p>
				<ul>
					<li>Ticket types</li>
					<li>Ticket category</li>
					<li>Priority: Urgency and impact description</li>
					<li>Closing ID</li>
					<li>Assigned groups</li>
					<li>Environment</li>
				</ul>
			</div>
			<p>
				For each entry you are configuring, you can add additional explanation for users. When marked as 'active', entries will be shown in user selection. Please note that you cannot delete entries if there is data with these entries. You can only uncheck 'active' box and users will not be able to select this option.
			</p>
			<p>
				<b>Ticket types</b> can be configured to distinguish different classification of tickets. Mavles comes with several default entries: Question, Incident, Problem, Service Request, Change Request. You can configure ticket types based on your company needs.
			</p>
			<div>
				<p><b>Ticket category</b> can be configured in tree levels:</p>
				<ul>
					<li>Level 1 - higher grouping (example - MS office)</li>
					<li>Level 2 - lower grouping node (example - email)</li>
					<li>Level 3 - the lowest grouping</li>
				</ul>
			</div>
			<p>
				<b>Closing ID</b> is mandatory entry when closing tickets. Mavles comes with 2 default entries: successfully tested and not relevant. Site administrator can configure Closing ID based on your company needs.
			</p>
			<p>
				Mavles comes with predefined matrix for defining <b>ticket priority</b>. Site administrator is allowed to configure impact and urgency short description, but matrix statuses cannot be changed.
			</p>
			<p>
				Next to assigned user, ticket can be allocated also to <b>Assigned group</b>. Here we define group name and email recipients. We can define group email and/or individual emails depending on company requirements. When new ticket is created or ticket status changed, email is sent to email addresses defined in this section.
			</p>
			<p>
				<b>Environment</b> refers on system environment for which incident is submitted (example: development, test, productive).
			</p>
		</HelpShell>
	)
}
