import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(
		() => {
			// Handler to call on window resize
			const handleResize = () => {
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}

			window.addEventListener("resize", debounce(handleResize, 500));

			// Call handler right away so state gets updated with initial window size
			handleResize();

			// Remove event listener on cleanup
			return () => window.removeEventListener("resize", handleResize);
		},
		[]
	);

	return windowSize;
}

export default useWindowSize;
