import { useCallback, useEffect, useMemo, useState } from 'react';
import { useVisibleColumns, FieldTypeEnum, TableColumnsType, emptySpaceColumnId, linksCellFormatter } from 'components/Table';
import { getSimpleChangeRequestsAction } from 'containers/Scope/ChangeRequests/action';
import { ProjectResponse, AttachmentResponse, ScopeItemLevelReportResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';

const defaultVisibleColumns = [
	propertyOf<ScopeItemLevelReportResponse>('level1Id'),
    propertyOf<ScopeItemLevelReportResponse>('level1Name'),
    propertyOf<ScopeItemLevelReportResponse>('level2Id'),
    propertyOf<ScopeItemLevelReportResponse>('level2Name'),
    propertyOf<ScopeItemLevelReportResponse>('level3Id'),
    propertyOf<ScopeItemLevelReportResponse>('level3Name'),
	propertyOf<ScopeItemLevelReportResponse>('processId'),
	propertyOf<ScopeItemLevelReportResponse>('processDescription'),
	propertyOf<ScopeItemLevelReportResponse>('level4Id'),
	propertyOf<ScopeItemLevelReportResponse>('level4Name'),
	propertyOf<ScopeItemLevelReportResponse>('changeRequestIds'),
	propertyOf<ScopeItemLevelReportResponse>('attachments'),
	propertyOf<ScopeItemLevelReportResponse>('department'),
	propertyOf<ScopeItemLevelReportResponse>('stepType')
]

export const useTableColumnsMemo = (
	project: ProjectResponse,
	downloadAttachment: (attachmentId: number) => Promise<void>,
	visibleColumns: string[] = defaultVisibleColumns
) => {
	visibleColumns = [emptySpaceColumnId, ...visibleColumns];

	const [changeRequestIds, setChangeRequestIds] = useState<number[]>([]);

	// INFO: Branko potvrdio da brisem, ali je nezgodno sto je changeRequestIds - znaci u koloni moze
	// biti CR000001,CR00002 a FormattedReference proverava samo jednu
	const fetchChangeRequestsCallback = useCallback(
		async () => {
			const bindedAction = getSimpleChangeRequestsAction.bind(null, project.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setChangeRequestIds(response.items?.map(cr => cr.id) || []);
			}
		},
		[project.id]
	)

	useEffect(
		() => {
			fetchChangeRequestsCallback();
		},
		[fetchChangeRequestsCallback]
	)

	// this method is used for display first row in each level 3 groups, and the other rows show only level 4 data
	const firstRowFormatterCallback = useCallback(
		(cell: any) => {
			const value = cell.getValue();
			const row = cell.getRow();
			const rowPosition = row.getPosition();

			const dataId = cell.getData().id;
			const table = cell.getTable();
			const tableData: ScopeItemLevelReportResponse[] = table.getData();
			const filteredData = tableData.filter((item) => item.id === dataId);

			if (rowPosition === 1 || filteredData.length === 1) {
				return value;
			}

			return '';
		},
		[]
	)

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[emptySpaceColumnId]: {
					title: ' ',
					fieldType: FieldTypeEnum.None,
					minWidth: 100
				},
				[propertyOf<ScopeItemLevelReportResponse>('level1Id')]: {
                    title: 'Level 1 ID',
                    fieldType: FieldTypeEnum.String,
					formatter: (cell: any) => firstRowFormatterCallback(cell),
					disableSort: true
                },
                [propertyOf<ScopeItemLevelReportResponse>('level1Name')]: {
                    title: 'Level 1 Name',
                    fieldType: FieldTypeEnum.String,
					formatter: (cell: any) => firstRowFormatterCallback(cell),
					disableSort: true
                },
                [propertyOf<ScopeItemLevelReportResponse>('level2Id')]: {
                    title: 'Level 2 ID',
                    fieldType: FieldTypeEnum.String,
					formatter: (cell: any) => firstRowFormatterCallback(cell),
					disableSort: true
                },
                [propertyOf<ScopeItemLevelReportResponse>('level2Name')]: {
                    title: 'Level 2 Name',
                    fieldType: FieldTypeEnum.String,
					formatter: (cell: any) => firstRowFormatterCallback(cell),
					disableSort: true
                },
                [propertyOf<ScopeItemLevelReportResponse>('level3Id')]: {
                    title: 'Level 3 ID',
                    fieldType: FieldTypeEnum.String,
					formatter: (cell: any) => firstRowFormatterCallback(cell),
					disableSort: true
                },
                [propertyOf<ScopeItemLevelReportResponse>('level3Name')]: {
                    title: 'Level 3 Name',
                    fieldType: FieldTypeEnum.String,
					formatter: (cell: any) => firstRowFormatterCallback(cell),
					disableSort: true
				},
				[propertyOf<ScopeItemLevelReportResponse>('processId')]: {
					title: 'Process ID',
					fieldType: FieldTypeEnum.String,
					formatter: (cell: any) => firstRowFormatterCallback(cell),
					disableSort: true
				},
				[propertyOf<ScopeItemLevelReportResponse>('processDescription')]: {
					title: 'Process description',
					fieldType: FieldTypeEnum.String,
					formatter: (cell: any) => firstRowFormatterCallback(cell),
					disableSort: true
				},
				[propertyOf<ScopeItemLevelReportResponse>('level4Id')]: {
					title: 'Level 4 ID',
					fieldType: FieldTypeEnum.String,
					disableSort: true
				},
				[propertyOf<ScopeItemLevelReportResponse>('level4Name')]: {
					title: 'Level 4 Name',
					fieldType: FieldTypeEnum.String,
					disableSort: true
				},
				[propertyOf<ScopeItemLevelReportResponse>('changeRequestIds')]: {
					title: 'Change Requests',
					disableSort: true,
					fieldType: FieldTypeEnum.Option,
					options: changeRequestIds,
					getItemId: (item: number) => item,
					getItemText: (item: number) => getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, item),
					formatter: (cell: any) => {
						const value: number[] = cell.getValue();
						if (!value) {
							return ''
						}
						return value.map(val => getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, val)).join(', ');
					}
				},
				[propertyOf<ScopeItemLevelReportResponse>('attachments')]: {
					title: 'Attachments',
					fieldType: FieldTypeEnum.None,
					disableSort: true,
					disableFilter: true,
					formatter: (cell: any, formatterParams: any, onRendered: Function) => linksCellFormatter(cell, formatterParams, onRendered, true, 'row'),
					formatterParams: {
						getLinks: (value: AttachmentResponse[]) => value || [],
						getLinkTitle: (link: AttachmentResponse) => link.name,
						getLinkUrl: () => '#',
						onClickLink: async (e: MouseEvent, link: AttachmentResponse) => {
							e.preventDefault();
							await downloadAttachment(link.id);
						},
						linkTarget: null,
					},
					tooltip: () => '',
					dbExportFieldPath: null
				},
				[propertyOf<ScopeItemLevelReportResponse>('department')]: {
					title: 'Department',
					fieldType: FieldTypeEnum.String,
					disableSort: true
				},
				[propertyOf<ScopeItemLevelReportResponse>('stepType')]: {
					title: 'Step type',
					fieldType: FieldTypeEnum.String,
					disableSort: true
				}
			}
		},
		[changeRequestIds, downloadAttachment, firstRowFormatterCallback]
	)

	return useVisibleColumns(columns, visibleColumns);
}
