import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useApplyStatusColorCallback, useFormattedProjectIdNameCallback, useStatusCallback, useUsernameCallback } from 'features/TableColumns/persistedHooks';
import { ModuleActivityEnum, ProjectResponse, TimeTravelStatusResponse, UserModel, VacationRequestStatusResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { RootState } from 'base/reducer/reducer';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { useProjectsForTableFilterCallback } from 'features/Project';

const defaultVisibleColumns = [
    propertyOf<VacationRequestStatusResponse>('projectId'),
    propertyOf<VacationRequestStatusResponse>('projectManagerUserId'),
	propertyOf<VacationRequestStatusResponse>('approvedOrRejectedByUserId'),
	propertyOf<VacationRequestStatusResponse>('statusId'),
]

export const useTableColumnsMemo = ( configureViewKey: string, disabled?: boolean ) => {
	const {
		persistedTimeAndTravelStatus,
		persistedUser,
		persistedConfigureView,
		persistedProject
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUsername = useUsernameCallback(persistedUser);
	const getStatusName = useStatusCallback(persistedTimeAndTravelStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedTimeAndTravelStatus);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);
	const { projects} = useProjectsForTableFilterCallback(ModuleActivityEnum.Time);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<VacationRequestStatusResponse>('projectId')]: {
					title: 'Project Id',
					formatter: (cell: any) => formatProjectIdName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: projects,
					getItemId: (option: ProjectResponse) => option.id,
					getItemText: (option: ProjectResponse) => formatProjectIdName(option.id)
				},
				[propertyOf<VacationRequestStatusResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatusName(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTimeAndTravelStatus.items,
					getItemId: (option: TimeTravelStatusResponse) => option.id,
					getItemText: (option: TimeTravelStatusResponse) => option.name,
				},
				[propertyOf<VacationRequestStatusResponse>('projectManagerUserId')]: {
					title: 'Project manager',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
				[propertyOf<VacationRequestStatusResponse>('approvedOrRejectedByUserId')]: {
					title: 'Approved/Rejected by',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
			};
		},
		[getUsername, applyStatusColor, getStatusName, persistedUser, persistedTimeAndTravelStatus, formatProjectIdName, projects]
	)

	return useVisibleColumns(columns, visibleColumns);
}
