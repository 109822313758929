import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DeleteTenantIndetifyRequest, InsertTimesheetRequest, TaskResponse, SimpleProjectIdNameModel, TimeFrequency, TimesheetDeltaRequest, TimesheetRecordedStatusResponse, TimesheetResponse, TimesheetsOneDayRequest, TimesheetsPeriodRequest, TimeTravelStatusEnum, UpdateTimesheetRequest, ModuleActivityEnum, TimeTravelPermission } from 'services/tenantManagementService';
import { ColumnContainer } from 'components/Layout';
import { useTableColumnsMemo } from './tableColumns'
import { RootState } from 'base/reducer/reducer';
import TableButtons from './TableButtons';
import { generateNumberId } from 'base/id';
import notifications from 'components/Notification/notification';
import { exportMyTimesheetsAction, getAllMyRecoredStatusesForPeriodAction, getAllMyTimesheetsAction, releaseTimesheetsAction, saveTimesheetsAction } from '../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import WithFetch from 'features/Fetch/WithFetch';
import { createDelta } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { getMondayOfWeekByDate } from 'utils/dateTimeUtils';
import { LocalTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { getAllTicketsSimpleAction } from 'containers/Tickets/ViewTickets/action';
import { getAllChangeRequestsSimpleAction } from 'containers/Scope/ChangeRequests/action';
import { getAllTasksAction } from 'containers/Communication/Tasks/action';
import { useProjectsAndCategories } from 'features/Project';

type Props = {
	date: Date
	onChange: () => Promise<void>
}

const configureViewKey = 'my_timesheets_table';
const maxTotalEffort = 24 * 60 * 60 * 1000 * 10000;

const TimesheetsTable = ({ date, onChange }: Props) => {
	const {
		persistedTimesheetsGeneralConfiguration,
		persistedTimeAndTravelStatus,
	} = useSelector((state: RootState) => state);

	const [selectedTimesheet, setSelectedTimesheet] = useState(new TimesheetResponse());

	const [initialValues, setInitialValues] = useState<TimesheetResponse[]>([]);
	const [values, setValues] = useState<TimesheetResponse[]>([]);

	// we need this for release
	const [timesheetStatuses, setTimesheetStatuses] = useState<TimesheetRecordedStatusResponse[]>([]);
	const [releasePeriod, setReleasePeriod] = useState(new TimesheetsPeriodRequest());

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);

	const [tickets, setTickets] = useState<SimpleProjectIdNameModel[]>([]);
	const [changeRequests, setChangeRequests] = useState<SimpleProjectIdNameModel[]>([]);
	const [tasks, setTasks] = useState<TaskResponse[]>([]);

	const { projectsOrCategories } = useProjectsAndCategories({ moduleEnum: ModuleActivityEnum.Time, teamMemberPermission: propertyOf<TimeTravelPermission>('timesheets') });

	const filteredProjects = useMemo(
		() => projectsOrCategories.filter(item => item.isProjectConnected === true),
		[projectsOrCategories]
	)

	const fetchTicketsCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getAllTicketsSimpleAction);
			if (response.success) {
				setTickets(response.items || []);
			}
		},
		[]
	)

	const fetchChangeRequestsCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getAllChangeRequestsSimpleAction);
			if (response.success) {
				setChangeRequests(response.items || []);
			}
		},
		[]
	)

	const fetchTasksCallback = useCallback(
		async () => {
			const projectIds = filteredProjects.map(project => project.projectOrCategoryId);
			const bindedAction = getAllTasksAction.bind(null, projectIds);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setTasks(response.items || []);
			}
		},
		[filteredProjects]
	)

	useEffect(
		() => {
			fetchTicketsCallback();
			fetchChangeRequestsCallback();
			fetchTasksCallback();
		},
		[fetchTicketsCallback, fetchChangeRequestsCallback, fetchTasksCallback]
	)

	const tableColumns = useTableColumnsMemo(configureViewKey, tickets, changeRequests, tasks, projectsOrCategories, isSubmitting);

	const selectCallback = useCallback(
		(data: TimesheetResponse[]) => setSelectedTimesheet(data[0] || new TimesheetResponse()),
		[]
	)

	const addCallback = useCallback(
		() => {
			const newTimesheet = new TimesheetResponse();
			newTimesheet.id = generateNumberId();
			newTimesheet.date = date;

			const newTimesheets = [
				...values,
				newTimesheet
			]
			setValues(newTimesheets);
		},
		[values, date]
	)

	const deleteCallback = useCallback(
		(id: number) => {
			const newTimesheets = values.filter(t => t.id !== id);
			setValues(newTimesheets);
		},
		[values]
	)

	const cellEditedCallback = useCallback(
		(cell: any) => {
			const field = cell.getField();
			const oldValue = cell.getOldValue();
			const value = cell.getValue();
			const data = cell.getData();

			const newTimesheet = new TimesheetResponse({ ...data })

			if (field === propertyOf<TimesheetResponse>('projectOrCategoryId')) {
				// this value is defined in tableColumns as projectOrCategoryId_isProjectConnected
				const valueSplit = value.split('_');
				const projectOrCategoryId = parseInt(valueSplit[0]);
				const isProjectConnected = valueSplit[1] === 'true';
				newTimesheet.projectOrCategoryId = projectOrCategoryId
				newTimesheet.isProjectConnected = isProjectConnected

				// reset/clear field value, when projectOrCategoryId is changed
				if (projectOrCategoryId !== oldValue) {
					newTimesheet.taskId = undefined;
					newTimesheet.ticketId = undefined;
					newTimesheet.changeRequestId = undefined;
				}
			}

			const newTimesheets = values.map(item => item.id === newTimesheet.id ? newTimesheet : item);
			setValues(newTimesheets);
		},
		[values]
	)

	const fetchDataCallback = useCallback(
		async () => {
			setIsRefetching(true);

			const request = new TimesheetsOneDayRequest({date});
			const bindedAction = getAllMyTimesheetsAction.bind(null, request)
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.items) {
				setInitialValues(response.items)
				setValues(response.items)
			}

			const config = persistedTimesheetsGeneralConfiguration.value;
			if (config.enableApprovalProcess) {
				let from: Date = new Date(date);
				let to: Date = new Date(date);
				if (config.approvalFrequency === TimeFrequency.Weekly) {
					from = getMondayOfWeekByDate(from);
					to = new Date(from);
					to.setDate(to.getDate() + 6);
				} else if (config.approvalFrequency === TimeFrequency.Monthly) {
					from = new Date(from.getFullYear(), from.getMonth());
					to = new Date(from);
					to.setMonth(to.getMonth() + 1);
					to.setDate(to.getDate() - 1);
				}
				const request = new TimesheetsPeriodRequest({from, to});
				const statusesBindedAction = getAllMyRecoredStatusesForPeriodAction.bind(null, request)
				const statusResponse = await tryCatchJsonByAction(statusesBindedAction);
				if (statusResponse.success && statusResponse.items) {
					setTimesheetStatuses(statusResponse.items)
					setReleasePeriod(request)
				}
			}

			setIsRefetching(false);
		},
		[date, persistedTimesheetsGeneralConfiguration]
	)

	const saveCallback = useCallback(
		async () => {
			const delta = createDelta<TimesheetResponse>
			(
				initialValues || [],
				values || [],
				InsertTimesheetRequest,
				UpdateTimesheetRequest,
				TimesheetDeltaRequest,
				DeleteTenantIndetifyRequest
			);

			setIsSubmitting(true);

			const bindedAction = saveTimesheetsAction.bind(null, delta)
			const response = await tryCatchJsonByAction(bindedAction);

			setIsSubmitting(false);

			if (response.success) {
				notifications.success('Timesheets saved')
				await fetchDataCallback()
				await onChange()
			}
		},
		[values, initialValues, fetchDataCallback, onChange],
	)

	const releaseCallback = useCallback(
		async () => {
			const request = new TimesheetsPeriodRequest(releasePeriod);

			const bindedAction = releaseTimesheetsAction.bind(null, request)
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Timesheets released')
				await fetchDataCallback();
			}
		},
		[fetchDataCallback, releasePeriod]
	)

	const isReleaseDisabledMemo = useMemo(
		() => {
			if (initialValues !== values) {
				return true;
			}

			for (let i = 0; i < timesheetStatuses.length; i++) {
				const timesheetStatus = timesheetStatuses[i];
				if (!timesheetStatus.isReleaseable) {
					return true;
				}
			}

			return false;
		},
		[initialValues, values, timesheetStatuses]
	)

	const isDeleteDisabledMemo = useMemo(
		() => !selectedTimesheet.id ||
			(persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess && (
				isStatusBySemantic(TimeTravelStatusEnum.Approved, selectedTimesheet.statusId, persistedTimeAndTravelStatus.itemsMap) ||
				isStatusBySemantic(TimeTravelStatusEnum.Released, selectedTimesheet.statusId, persistedTimeAndTravelStatus.itemsMap)
			)),
		[selectedTimesheet, persistedTimeAndTravelStatus, persistedTimesheetsGeneralConfiguration]
	)

	const isSaveDisabledMemo = useMemo(
		() => {
			if (initialValues === values) {
				return true;
			}
			let totalEffort = 0;
			for (let i = 0; i < values.length; i++) {
				if (!values[i].projectOrCategoryId || !values[i].effort) {
					return true;
				}
				totalEffort += values[i].effort;
			}

			if (totalEffort > maxTotalEffort) {
				return true;
			}

			return false;
		},
		[initialValues, values]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback} refetching={isRefetching}>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedTimesheet.id}
					onAdd={addCallback}
					onDelete={deleteCallback}
					onSave={saveCallback}
					onRelease={releaseCallback}
					releaseDisabled={isReleaseDisabledMemo}
					deleteDisabled={isDeleteDisabledMemo}
					saveDisabled={isSaveDisabledMemo}
					disabled={isSubmitting}
					isReleasable={persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess}
					configureViewKey={configureViewKey}
					tableColumns={tableColumns}
					exportFunction={exportMyTimesheetsAction}
				/>
				<LocalTable
					columns={tableColumns}
					data={values}
					rowSelectionChanged={selectCallback}
					cellEdited={cellEditedCallback}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</WithFetch>
	)
};

export default TimesheetsTable
