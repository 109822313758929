import { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { ChangeRequestCountsResponse, ChangeRequestFullResponse, ChangeRequestStatusEnum, GenericFilterModelCollection, ModuleActivityEnum, ScopePermission } from 'services/tenantManagementService';
import { ColumnContainer } from 'components/Layout';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { RootState } from 'base/reducer/reducer';
import { getChangeRequestsGenericAction, deleteChangeRequestAction, exportAction, getAllChangeRequestCountsActionCounts } from './action';
import notifications from 'components/Notification/notification';
import TableButtons from './Table/TableButtons';
import { useTableColumnsMemo } from './Table/tableColumns';
import { WithProjectPicker, ComponentProps, pmOrSpmPermission } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';
import ChangeRequestsHelp from './Help/ChangeRequestsHelp';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { ListComponentProps } from 'features/Crud';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { propertyOf } from 'utils/propertyOf';

const configureViewKey = 'scope_change_requests_table';

type Props = ListComponentProps & ComponentProps;

const ChangeRequests = ({ project, disabledEdit, dataChangedTopic, publishDataChanged }: Props) => {
	const {
		persistedChangeRequestStatus,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const [selectedChangeRequest, setSelectedChangeRequest] = useState(new ChangeRequestFullResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const [countsResponse, setCountsResponse] = useState(new ChangeRequestCountsResponse());

	const tableColumns = useTableColumnsMemo(
		countsResponse,
		persistedConfigureView.value[configureViewKey]
	);

	const fetchCountsDataCallback = useCallback(
		async () => {
			const bindedAction = getAllChangeRequestCountsActionCounts.bind(null, project.id, filtersModel);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setCountsResponse(response.value)
			}
		},
		[filtersModel, project.id]
	)

	const memoFetchFunction = useCallback(
		async (filtersModel: GenericFilterModelCollection) => {
			const [, response] = await Promise.all([
				fetchCountsDataCallback(),
				getChangeRequestsGenericAction(project.id, filtersModel)
			])

			return response;
		},
		[project.id, fetchCountsDataCallback]
	)

	const memoExportFunction = useMemo(
		() => exportAction.bind(null, project.id),
		[project.id]
	)

	const handleRowSelectionChange = useCallback(
		(data: ChangeRequestFullResponse[]) => {
			setSelectedChangeRequest(data[0] || new ChangeRequestFullResponse());
		},
		[]
	)

	const deleteCallback = useCallback(
		async (id: number) => {
			const bindedAction = deleteChangeRequestAction.bind(null, project.id, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const formattedId = getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, id);
				notifications.success(`Change request ${formattedId} is deleted.`);
				publishDataChanged();
			}
		},
		[project.id, publishDataChanged]
	)

	const disabledCrMaintain = useMemo(
		() => disabledEdit || !(isUserPmorSubstitutePmOrSiteAdmin(project.roleId)),
		[project, disabledEdit]
	)

	const disableDeleteMemo = useMemo(
		() =>
			!selectedChangeRequest.statusId ||
			persistedChangeRequestStatus.itemsMap[selectedChangeRequest.statusId]?.semantics !== ChangeRequestStatusEnum.Created,
		[selectedChangeRequest.statusId, persistedChangeRequestStatus.itemsMap]
	)

	const statusMemo = useMemo(
		() => persistedChangeRequestStatus.itemsMap[selectedChangeRequest.statusId]?.semantics,
		[selectedChangeRequest, persistedChangeRequestStatus]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='Change Requests'
			FloatingHelpComponent={ChangeRequestsHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedChangeRequest.id}
					onDelete={deleteCallback}
					disableDelete={disableDeleteMemo}
					disabled={disabledEdit}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					isEditable={!!project.id && !disabledCrMaintain}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
					status={statusMemo}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={dataChangedTopic}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectPicker(ChangeRequests, ModuleActivityEnum.Scope, true, propertyOf<ScopePermission>('viewAllChangeRequests'), pmOrSpmPermission);
