import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';
import { getChangeRequestTypesAction, updateChangeRequestTypesAction } from './action';
import { ScopeHelp } from './ScopeHelp';
import { ContentShell } from 'features/Content/ContentShell';

export const Scope = () => {
	return (
		<ContentShell
			title='Configure Scope'
			FloatingHelpComponent={ScopeHelp}
		>
			<>
				<h5>Change Request types</h5>
				<TenantIsActiveTable
					getConfigsAction={getChangeRequestTypesAction}
					updateConfigsAction={updateChangeRequestTypesAction}
					successMessage='Change request types are updated.'
					label={{ name: 'CR type' }}
				/>
			</>
		</ContentShell>
	)
}
