import { getUserTableViewConfigAction, updateUserTableViewConfigAction } from 'features/UserTableViewConfig/action';

const viewType = 'showUserStatus';

export const getShowUserStatusConfigAction = () => {
	return getUserTableViewConfigAction(viewType);
}

export const updateShowUserStatusConfigAction = (showUserStatus: boolean) => {
	return updateUserTableViewConfigAction(viewType, showUserStatus);
}
