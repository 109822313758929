import { HelpShell } from 'features/Help/HelpShell'

export const ImportVacationsHelp = () => {
	return (
		<HelpShell title='Upload vacations'>
			<p>Project managers can upload user vacations from excel spreadsheets. <br />
				When uploaded, items are by default in status: 'approved'. No workflow for approval process is triggered.
			</p>
			<p>Please note that all users need to be created in Mavles.</p>
			<p>Please use provided template for your data upload.</p>
			<div>
				<p>Structure of spread sheet is following:</p>
				<ol>
					<li>User ID - please use user ID from Mavles application - you can see user ID in Communication -{'>'} Contact list</li>
					<li>From (mm/dd/yyyy) - date when vacation starts</li>
					<li>To (mm/dd/yyyy) - date when vacation ends</li>
					<li>Note - Free text no limitation</li>
					<li>Text 1 - Free text no limitation</li>
					<li>Text 2 - Free text no limitation</li>
				</ol>
			</div>
			<div>
				<p>
					Prior to data upload, please check if users are defined for projects and if users are active on projects. <br />
					If imported data needs to be corrected, following steps needs to be applied:
				</p>
				<ol>
					<li>project managers will need to reject vacation requests</li>
					<li>users will have to delete all entries</li>
				</ol>
			</div>
		</HelpShell>
	)
}
