import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { MitigationTypeEnum, ModuleActivityEnum, ProjectRiskResponse, RiskStatusResponse, UserModel } from 'services/tenantManagementService';
import { DateField, Form, InputField, InputNumber, InputNumberField, TextareaField, currencyDefaultProps, percentDefaultProps, AutoCompleteField } from 'components/Form';
import { RootState } from 'base/reducer/reducer';
import { persistTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { getNetImpact, getNetImpactAfterMitigation, getCostBenefit, getRiskInclusion } from 'containers/Risk/RiskPlan/helper';
import { CrudEnum } from 'features/Crud';
import { useCurrencySuffixMemo } from 'features/Currency/useCurrencySuffixMemo';
import { propertyOf } from 'utils/propertyOf';
import { useActiveTeamMembersMemo } from 'features/TableColumns/persistedHooks';
import { ProjectSelect } from 'features/Project';

export const mitigationTypes = [
	MitigationTypeEnum.None,
	MitigationTypeEnum.Corrective,
	MitigationTypeEnum.Preventive
]

type Props = {
	risk?: ProjectRiskResponse
	projectId: number
	cancel(): void
	save(risk: ProjectRiskResponse): void
	crud: CrudEnum
}

export const RiskForm = ({ risk, projectId, cancel, save, crud }: Props) => {
	const {
		persistedUser,
		persistedTeamMember,
		persistedTenant,
		persistedCurrency,
		persistedRiskStatus
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(risk || new ProjectRiskResponse());

	const memberUsersMemo: UserModel[] = useActiveTeamMembersMemo(persistedTeamMember, persistedUser, projectId)

	useEffect(
		() => {
			if (!persistedTeamMember.projectMap[projectId]) {
				persistTeamMembersAction(projectId);
			}
		},
		[persistedTeamMember, projectId]
	)

	const netImpact = useMemo(
		() => getNetImpact(values.grossImpact, values.probability),
		[values.grossImpact, values.probability]
	)

	const netImpactAfterMitigation = useMemo(
		() => getNetImpactAfterMitigation(netImpact, values.mitigationType, values.grossImpactAfterMitigation, values.probabilityAfterMitigation, values.probability),
		[values.mitigationType, netImpact, values.grossImpactAfterMitigation, values.probabilityAfterMitigation, values.probability]
	)

	const costBenefit = useMemo(
		() => getCostBenefit(netImpact, values.mitigationType, values.costOfMitigation, netImpactAfterMitigation, values.grossImpact),
		[values.mitigationType, values.costOfMitigation, netImpact, netImpactAfterMitigation, values.grossImpact]
	)

	const riskInclusion = useMemo(
		() => getRiskInclusion(netImpact, values.mitigationType, values.costOfMitigation, netImpactAfterMitigation),
		[values.mitigationType, values.costOfMitigation, netImpact, netImpactAfterMitigation]
	)

	const onSubmitCallback = useCallback(
		async () => await save(values),
		[save, values]
	)

	useEffect(
		() => {
			if (values.mitigationType === MitigationTypeEnum.None) {
				setValues((state) => {
					return new ProjectRiskResponse({
						...state,
						costOfMitigation: undefined,
						grossImpactAfterMitigation: undefined,
						probabilityAfterMitigation: undefined
					})
				})
			}
		},
		[values.mitigationType]
	)

	const currencySymbolSuffix = useCurrencySuffixMemo(persistedCurrency, persistedTenant);

	return (
		<Form
			values={values}
			initialValues={risk}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectSelect
							value={projectId}
							disabled
							moduleEnum={ModuleActivityEnum.Project}
						/>
						<InputField
							id={propertyOf<ProjectRiskResponse>('name')}
							label='Risk name'
							isRequired
						/>
						<TextareaField
							id={propertyOf<ProjectRiskResponse>('consequneces')}
							label='Consequences'
							rows={4}
						/>
						<TextareaField
							id={propertyOf<ProjectRiskResponse>('effects')}
							label='Detailed effects'
							rows={4}
						/>
						<AutoCompleteField
							id={propertyOf<ProjectRiskResponse>('responsibleUserId')}
							label='Responsible'
							items={memberUsersMemo}
							getItemId={(item: UserModel) => item.id}
							getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
							loading={persistedUser.fetching}
							sort
						/>
						<AutoCompleteField
							id={propertyOf<ProjectRiskResponse>('statusId')}
							label='Status/DI of mitigation'
							items={persistedRiskStatus.items}
							getItemId={(item: RiskStatusResponse) => item.id}
							getItemText={(item: RiskStatusResponse) => item.name}
							getItemDescription={(item: RiskStatusResponse) => item.description}
							isRequired
							loading={persistedRiskStatus.fetching}
						/>
						<DateField
							id={propertyOf<ProjectRiskResponse>('dueDate')}
							label='Due date'
						/>
						<TextareaField
							id={propertyOf<ProjectRiskResponse>('description')}
							label='Comment'
							rows={4}
						/>
					</SmartItem>
					<SmartItem>
						<InputNumberField
							id={propertyOf<ProjectRiskResponse>('grossImpact')}
							label='Gross impact'
							suffix={currencySymbolSuffix}
							{...currencyDefaultProps}
						/>
						<InputNumberField
							id={propertyOf<ProjectRiskResponse>('probability')}
							label='Probability'
							{...percentDefaultProps}
							min={0}
							max={100}
						/>
						<SmartFormGroup label='Net impact'>
							<InputNumber
								value={netImpact}
								disabled
								suffix={currencySymbolSuffix}
								{...currencyDefaultProps}
							/>
						</SmartFormGroup>
						<InputField
							id={propertyOf<ProjectRiskResponse>('mitigation')}
							label='Mitigation'
							isRequired
						/>
						<AutoCompleteField
							id={propertyOf<ProjectRiskResponse>('mitigationType')}
							label='Mitigation type'
							items={mitigationTypes}
							getItemId={(item: MitigationTypeEnum) => item}
							getItemText={(item: MitigationTypeEnum) => item}
						/>
						<InputNumberField
							id={propertyOf<ProjectRiskResponse>('costOfMitigation')}
							label='Cost of mitigation'
							suffix={currencySymbolSuffix}
							{...currencyDefaultProps}
							disabled={values.mitigationType === MitigationTypeEnum.None}
						/>
						<InputNumberField
							id={propertyOf<ProjectRiskResponse>('grossImpactAfterMitigation')}
							label='Gross impact after mitigation'
							suffix={currencySymbolSuffix}
							{...currencyDefaultProps}
							disabled={values.mitigationType === MitigationTypeEnum.None}
						/>
						<InputNumberField
							id={propertyOf<ProjectRiskResponse>('probabilityAfterMitigation')}
							label='Probability after mitigation'
							{...percentDefaultProps}
							min={0}
							max={100}
							disabled={values.mitigationType === MitigationTypeEnum.None}
						/>
						<SmartFormGroup label='Net impact after mitigation'>
							<InputNumber
								value={netImpactAfterMitigation}
								disabled
								{...currencyDefaultProps}
								suffix={currencySymbolSuffix}
							/>
						</SmartFormGroup>
						<SmartFormGroup label='Cost/Benefit analysis'>
							<InputNumber
								value={costBenefit}
								disabled
								{...currencyDefaultProps}
								suffix={currencySymbolSuffix}
							/>
						</SmartFormGroup>
						<SmartFormGroup label='Risk for inclusion in report'>
							<InputNumber
								value={riskInclusion}
								disabled
								{...currencyDefaultProps}
								suffix={currencySymbolSuffix}
							/>
						</SmartFormGroup>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
