import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useUsernameCallback } from 'features/TableColumns/persistedHooks';
import { TimesheetResponse, UserModel } from 'services/tenantManagementService';
import { formatTimeWithoutAmOrPm } from 'utils/dateTimeUtils';
import { propertyOf } from 'utils/propertyOf';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { RootState } from 'base/reducer/reducer';
import { FieldTypeEnum, useVisibleColumns } from 'components/Table';
import { customTextAreaFormatter } from 'components/Table/BaseTable/helpers/customTextAreaFormatter';

const defaultVisibleColumns = [
    propertyOf<TimesheetResponse>('date'),
    propertyOf<TimesheetResponse>('effort'),
    propertyOf<TimesheetResponse>('description'),
    propertyOf<TimesheetResponse>('changeRequestId'),
    propertyOf<TimesheetResponse>('ticketId'),
	propertyOf<TimesheetResponse>('approvedOrRejectedByUserId'),
	propertyOf<TimesheetResponse>('customText1'),
	propertyOf<TimesheetResponse>('customText2'),
	propertyOf<TimesheetResponse>('customText3'),
]

export const useTableColumnsMemo = () => {
	const { persistedUser } = useSelector((state: RootState) => state);

	const visibleColumns = defaultVisibleColumns;
	const getUsername = useUsernameCallback(persistedUser);

	const columns = useMemo(
		() => {
			return {
				[propertyOf<TimesheetResponse>('date')]: {
					title: 'Date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TimesheetResponse>('effort')]: {
					title: 'Effort',
					fieldType: FieldTypeEnum.Number,
					disableFilter: true,
					formatter: (cell: any) => formatTimeWithoutAmOrPm(cell.getValue()),
					bottomCalc: 'sum',
					bottomCalcFormatter: (cell: any) => `∑ ${formatTimeWithoutAmOrPm(cell.getValue()) || ''}`
				},
				[propertyOf<TimesheetResponse>('description')]: {
					title: 'Description',
					fieldType: FieldTypeEnum.String,
					formatter: customTextAreaFormatter
				},
				[propertyOf<TimesheetResponse>('changeRequestId')]: {
					title: 'Change request Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.CHANGE_REQUEST
				},
				[propertyOf<TimesheetResponse>('ticketId')]: {
					title: 'Ticket Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.TICKET
				},
				[propertyOf<TimesheetResponse>('approvedOrRejectedByUserId')]: {
					title: 'Approved/Rejected by',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
				[propertyOf<TimesheetResponse>('customText1')]: {
					title: 'Text 1',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<TimesheetResponse>('customText2')]: {
					title: 'Text 2',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<TimesheetResponse>('customText3')]: {
					title: 'Text 3',
					fieldType: FieldTypeEnum.String
				},
			};
		},
		[getUsername, persistedUser]
	)

	return useVisibleColumns(columns, visibleColumns);
}
