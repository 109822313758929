import { useCallback, useEffect, useMemo, useState } from 'react';
import { TokenTypeEnum, UserModel } from 'services/tenantManagementService';
import { FormGroup } from 'components/Form';
import { useTableColumnsMemo } from './tableColumns';
import { TableButtons } from './TableButtons';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ListComponentProps } from 'features/Crud';
import { persistUsersAction } from 'containers/Configuration/SiteConfiguration/UserAdministration/Users/action';
import WithFetch from 'features/Fetch/WithFetch';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { removeSiteAdminAction } from './action';
import { ColumnContainer } from 'components/Layout';
import { LocalTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';

const configureViewKey = 'site_admins_table';

type Props = ListComponentProps;

export const SiteAdminsTable = ({ dataChangedTopic }: Props) => {
	const [selectedAdmin, setSelectedAdmin] = useState<UserModel>(new UserModel());
	const [isDeleting, setIsDeleting] = useState(false);

	const tableColumns = useTableColumnsMemo(configureViewKey);

	const { persistedUser }  = useSelector((state: RootState) => state);

	const siteAdminsMemo = useMemo(
		() => persistedUser.items.filter(user => user.roleId === TokenTypeEnum.SiteAdmin),
		[persistedUser.items]
	)

	const adminSelectionChangeCallback = useCallback(
		(data: UserModel[]) =>setSelectedAdmin(data[0] || new UserModel()),
		[]
	)

	const deleteCallback = useCallback(
		async () => {
			setIsDeleting(true);
			const bindedAction = removeSiteAdminAction.bind(null, selectedAdmin.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`User ${selectedAdmin.firstName} ${selectedAdmin.lastName} is removed from list of site administrators.`);
				setSelectedAdmin(new UserModel());
				persistUsersAction();
			}
			setIsDeleting(false);
		},
		[selectedAdmin]
	)

	useEffect(
		() => {
			const subscription = dataChangedTopic?.subscribe(persistUsersAction);
			return () => {
				subscription?.unsubscribe();
			}
		},
		[dataChangedTopic]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<FormGroup title='Site administrators'>
			<WithFetch fetchFunction={persistUsersAction} refetching={persistedUser.fetching || isDeleting}>
				<ColumnContainer margin='medium'>
					<TableButtons
						selectedId={selectedAdmin.id}
						tableColumns={tableColumns}
						configureViewKey={configureViewKey}
						onDelete={deleteCallback}
						disabledDelete={siteAdminsMemo.length <= 1}
						disabled={persistedUser.fetching || isDeleting}
					/>
					<LocalTable
						columns={tableColumns}
						data={siteAdminsMemo}
						rowSelectionChanged={adminSelectionChangeCallback}
						reorderColumns={reorderColumnsCallback}
					/>
				</ColumnContainer>
			</WithFetch>
		</FormGroup>
	)
}
