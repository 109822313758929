import { useMemo, useRef } from "react";
import { GanttChart } from "components/GanttChart";
import { noop } from "utils/commonHelper";
import { useSelector } from "react-redux";
import { RootState } from "base/reducer/reducer";
import { predecessorTypeToLinkMap } from "containers/Schedule/Schedule/GanttView/GanttView";
import { ScheduleGanttDashboardResponse } from "services/tenantManagementService";

const columns = [
    { name: "text", label: "Task name", tree: true, min_width: 200 },
    { name: "start_date", label: "Start time", min_width: 90, align: "center" },
    { name: "end_date", label: "End time", min_width: 90, align: "center" }
]

type Props = {
	loading: boolean
	dashboard: ScheduleGanttDashboardResponse
}

export const GanttView = ({ loading, dashboard }: Props) => {
	const { persistedProject, persistedUser } = useSelector((state: RootState) => state);

	const ganttChartRef = useRef<any>()

	const allProjectsTasks = useMemo(
		() => {
			const tasks: any[] = [];

			for (let projectId in dashboard.schedulesPerProject) {
				const projectSchedules = dashboard.schedulesPerProject[projectId];
				const project = persistedProject.itemsMap[parseInt(projectId)];

				if (project && projectSchedules) {
					// parent task
					const parentTask = {
						id: `project_${projectId}`,
						text: project.name,
						// start_date: item.start,
						// end_date: item.finish,
						workload: 100,
						parent: '0',
						type: undefined,
						open: false
					}

					// tasks from project
					const projectTasks = projectSchedules.map((item) => {
						return {
							id: item.id,
							text: item.name,
							start_date: item.start,
							end_date: item.finish,
							resource: item.responsibleUserIds,
							workload: item.workloadForResponsibleUsers || 0,

							parent: item.parentId || `project_${projectId}`,
							progress: (item.percentOfCompletion || 0) / 100,
							type: item.markAsMilestone ? 'milestone' : undefined,
							open: false
						}
					})

					tasks.push(parentTask, ...projectTasks);
				}
			}

			return tasks;
		},
		[dashboard.schedulesPerProject, persistedProject]
	)

	const allLinks = useMemo(
		() => {
			const links: any[] = [];

			for (let projectId in dashboard.schedulesPredecessorsPerProject) {
				const projectPredecessors = dashboard.schedulesPredecessorsPerProject[projectId];

				if (projectPredecessors) {
					// tasks from project
					const projectLinks = projectPredecessors.map((item) => {
						return {
							id: item.id,
							source: item.predecessorId,
							target: item.descendantId,
							type: predecessorTypeToLinkMap[item.type],
							lag: item.lag
						}
					})

					links.push(...projectLinks);
				}
			}

			return links;
		},
		[dashboard.schedulesPredecessorsPerProject]
	)

	if (loading || persistedProject.fetching || persistedUser.fetching) {
		return <></>
	}

	return (
		<GanttChart
			ref={ganttChartRef}
			columns={columns}
			tasks={allProjectsTasks}
			links={allLinks}
			deriveTaskDatesFromSubtasks={false}

			onCreateTaskClick={noop}
			onUpdateTaskClick={noop}
			onTaskUpdate={noop}
			onTasksUpdate={noop}
			onTaskDelete={noop}
			onTasksReorder={noop}
			onLinkAdd={noop}
			onLinkDelete={noop}
			disabled
		/>
	)
}
