import { ProjectFinanceClient, InsertProjectFinanceRequest, UpdateProjectFinanceRequest, GenericFilterModelCollection, ExportDataModel } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';
import { getUserTableViewConfigAction, updateUserTableViewConfigAction } from "features/UserTableViewConfig/action";

export const getFinancesAction = (reportId: number, copyFromFinance: boolean) => {
	const tenantId = getTenantId()!;
	const client = new ProjectFinanceClient();
	return client.getAll(tenantId, reportId, undefined, copyFromFinance, undefined, undefined);
}

export const getFinancesGenericAction = (reportId: number, copyFromFinance: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectFinanceClient();
	return client.getAllGenericFilter(tenantId, reportId, copyFromFinance, genericFilter);
}

export const getFinancesGenericCountsAction = (reportId: number, copyFromFinance: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectFinanceClient();
	return client.getAllGenericFilterCounts(tenantId, reportId, copyFromFinance, genericFilter);
}

export const createFinanceAction = (reportId: number, model: InsertProjectFinanceRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectFinanceClient();
	return client.create(tenantId, reportId, model);
}

export const updateFinanceAction = (reportId: number, model: UpdateProjectFinanceRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectFinanceClient();
	return client.update(tenantId, reportId, model);
}

export const getFinanceAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectFinanceClient();
	return client.get(tenantId, reportId, id);
}

export const deleteFinanceAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectFinanceClient();
	return client.delete(tenantId, reportId, id);
}

export const exportAction = (reportId: number, copyFromFinance: boolean, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectFinanceClient();
	return client.export(tenantId, reportId, copyFromFinance, exportDataModel);
}

export const copyFromFinanceAction = (reportId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectFinanceClient();
	return client.copyFromFinance(tenantId, reportId);
}

// copy from finance config

const viewType = 'projectReportFinanceCopyFromFinance';

export const getCopyFromFinanceConfigAction = () => {
	return getUserTableViewConfigAction(viewType)
}

export const updateCopyFromFinanceConfigAction = (content: {[key: number]: boolean}) => {
	return updateUserTableViewConfigAction(viewType, content);
}
