import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud';
import { CreateMajorIssue } from './Crud/CreateMajorIssue';
import { UpdateMajorIssue } from './Crud/UpdateMajorIssue';

export const MajorIssueRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/${CrudSubRoutesEnum.Create}`} component={CreateMajorIssue} />
			<Route path={`${path}/${CrudSubRoutesEnum.Update}/:majorIssueId`} component={UpdateMajorIssue} />
		</Switch>
	)
}
