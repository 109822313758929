import { Subtract } from 'utility-types';
import { MultiSelectLinks, MultiSelectLinksProps } from '../controls';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const MultiSelectLinksField = (props: Subtract<MultiSelectLinksProps, ControlsCommonProps<Array<string | number>>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={MultiSelectLinks}
		/>
	)
}
