import { CrudRouter } from 'features/Crud';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ApprovalTabSubroutes } from '../ApprovalSubroutes';
import ReadTimesheets from './Crud/Read/ReadTimesheets';
import TimesheetsTable from './TimesheetsTable';

const TimesheetsApprovalRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${ApprovalTabSubroutes.TIMESHEETS}`}
				render={() => (
					<CrudRouter
						ListComponent={TimesheetsTable}
						ReadComponent={ReadTimesheets}
						paramName={['projectId', 'statusId', 'userId', 'from', 'to']}
					/>
				)}
			/>
		</Switch>
	)
}

export default TimesheetsApprovalRouter;
