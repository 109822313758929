import { TicketsIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";

// subroutes

export enum TicketsSubRoutesEnum {
	MY_DASHBOARD = 'tickets_my_dashboard',
	TICKETS = 'tickets_view_tickets'
}

export const myDashboardSubRoute: Route = {
	id: TicketsSubRoutesEnum.MY_DASHBOARD,
	url: `/${RootRouteEnum.TICKETS}/${TicketsSubRoutesEnum.MY_DASHBOARD}`,
	text: 'My Dashboard',
}

export const viewTicketsSubRoute: Route = {
	id: TicketsSubRoutesEnum.TICKETS,
	url: `/${RootRouteEnum.TICKETS}/${TicketsSubRoutesEnum.TICKETS}`,
	text: 'Tickets',
}

// root route

export const ticketsRoute: Route = {
	id: RootRouteEnum.TICKETS,
	url: `/${RootRouteEnum.TICKETS}`,
	text: 'Tickets',
	Icon: TicketsIcon,
	children: [
		myDashboardSubRoute,
		viewTicketsSubRoute
	]
}
