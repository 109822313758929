import { useCallback, useEffect, useState } from 'react';
import { RadioItemType, CheckboxField, Form, RadioField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ExportDataFileTypeEnum } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';

export class ExportModel {
	applyFilters: boolean = true
	applySort: boolean = true
	useOnlyVisible: boolean = true
	exportOnlyCurrentPage: boolean = false
	exportType: ExportDataFileTypeEnum = ExportDataFileTypeEnum.CSV

	constructor(data?: ExportModel) {
		if (data) {
			this.applyFilters = data.applyFilters;
			this.useOnlyVisible = data.useOnlyVisible;
			this.exportOnlyCurrentPage = data.exportOnlyCurrentPage;
			this.applySort = data.applySort;
			this.exportType = data.exportType;
		}
	}
}

type Props = {
	save: (exportModel: ExportModel) => void
	cancel: () => void
	exportTypeOptions: RadioItemType[]
	forceApplyFilters?: boolean
	forceApplySort?: boolean
	forceUseOnlyVisible?: boolean
	forceExportOnlyCurrentPage?: boolean
	hideApplyFilters?: boolean
	hideApplySort?: boolean
	hideUseOnlyVisible?: boolean
	hideExportOnlyCurrentPage?: boolean
}

const ExportForm = ({
	save,
	cancel,
	exportTypeOptions,
	forceApplyFilters,
	forceApplySort,
	forceUseOnlyVisible,
	forceExportOnlyCurrentPage,
	hideApplyFilters,
	hideApplySort,
	hideUseOnlyVisible,
	hideExportOnlyCurrentPage,
}: Props) => {
	const [values, setValues] = useState(new ExportModel());

	useEffect(
		() => {
			setValues(state => {
				const result = new ExportModel(state);
				result.exportType = exportTypeOptions[0].id as ExportDataFileTypeEnum;
				if (forceApplyFilters !== undefined) {
					result.applyFilters = forceApplyFilters;
				}
				if (forceApplySort !== undefined) {
					result.applySort = forceApplySort;
				}
				if (forceUseOnlyVisible !== undefined) {
					result.useOnlyVisible = forceUseOnlyVisible;
				}
				if (forceExportOnlyCurrentPage !== undefined) {
					result.exportOnlyCurrentPage = forceExportOnlyCurrentPage;
				}
				return result;
			})
		},
		[exportTypeOptions, forceApplyFilters, forceApplySort, forceUseOnlyVisible, forceExportOnlyCurrentPage]
	)

	const submitCallback = useCallback(
		async () => {
			return save(values);
		},
		[save, values]
	)

	useEffect(
		() => {
			if (values.exportOnlyCurrentPage) {
				setValues((state: ExportModel) => {
					const result = new ExportModel(state);
					result.applyFilters = true;
					result.applySort = true;
					return result;
				})
			}
		},
		[values.exportOnlyCurrentPage]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={cancel}
			submitButtonText='Export'
			render={() => (
				<SmartContainer>
					<SmartItem>
						<RadioField
							id={propertyOf<ExportModel>('exportType')}
							items={exportTypeOptions}
						/>
							{!hideApplyFilters && (
								<CheckboxField
									id={propertyOf<ExportModel>('applyFilters')}
									label='Apply current filters'
									disabled={values.exportOnlyCurrentPage || forceApplyFilters !== undefined}
								/>
							)}
							{!hideApplySort && (
								<CheckboxField
									id={propertyOf<ExportModel>('applySort')}
									label='Apply current sort'
									disabled={values.exportOnlyCurrentPage  || forceApplySort !== undefined}
								/>
								)}
							{!hideUseOnlyVisible && (
								<CheckboxField
									id={propertyOf<ExportModel>('useOnlyVisible')}
									label='Use only visible columns'
									disabled={forceUseOnlyVisible !== undefined}
								/>
							)}
							{!hideExportOnlyCurrentPage && (
								<CheckboxField
									id={propertyOf<ExportModel>('exportOnlyCurrentPage')}
									label='Export only current page'
									disabled={forceExportOnlyCurrentPage !== undefined}
								/>
							)}
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}

export default ExportForm;
