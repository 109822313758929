import { ColumnContainer } from 'components/Layout';
import { HelpShell } from 'features/Help/HelpShell';

export const CalendarHelp = () => {
	return (
		<ColumnContainer>
			<HelpShell title='Calendar'>
				<p><b>My calendar</b> shows only user related tasks, meetings, vacation and reminders in one comprehensive view.</p>
				<p>User can select multiple projects where he is assigned to and track and organize his cross project activities.</p>
				<p>
					When initially created all tasks are shown in Calendar on date when they are due and in section unassigned tasks. User can move tasks in order to organize his/hers workload more efficiently. Changes are saved automatically, but moving tasks does not have an influence on task due date.
				</p>
			</HelpShell>
			<HelpShell title='Project meetings'>
				<p>This view shows all project and non-project related meetings.</p>
			</HelpShell>
			<HelpShell title='Team vacations'>
				<p>This view planned and approved vacations of team members.</p>
			</HelpShell>
		</ColumnContainer>
	)
}
