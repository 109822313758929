import styles from 'components/Map/map.module.scss'
import { OrganizationalUnitItems } from './OrganizationItems/OrganizationalUnitItems'

export const OrganizationalUnits = () => {
	return (
		<div className={styles.content}>
			<OrganizationalUnitItems
				level={1}
				parentIndex={0}
			/>
		</div>
	)
}