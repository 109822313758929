import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getRouteObject, RouterProps } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { CrudRouter } from 'features/Crud';
import { MyTimeTravelDashboard } from './MyDashboard/MyTimeTravelDashboard';
import MyTimesheets from './MyTimesheets/MyTimesheets';
import MyExpenses from './MyExpenses/MyExpenses';
import MyTravelExpenses from './MyTravelExpenses/MyTravelExpenses';
import MyTravelRequests from './MyTravelRequests/MyTravelRequests';
import MyVacationRequests from './MyVacationRequests/MyVacationRequests';
import Approval from './Approval/Approval';
import CreateMyTravelRequest from './MyTravelRequests/Crud/CreateMyTravelRequest';
import ReadMyTravelRequest from './MyTravelRequests/Crud/ReadMyTravelRequest';
import UpdateMyTravelRequest from './MyTravelRequests/Crud/UpdateMyTravelRequest';
import { persistNonProjectCategoriesAction } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/NonProjectCategory/action';
import { persistTimeAndTravelStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/TimeAndTravel/action';
import { persistExpenseGeneralConfigurationAction } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/ExpensesGeneral/action';
import { persistTravelRequestsAction } from './MyTravelRequests/action';
import CreateMyTravelExpense from './MyTravelExpenses/Crud/CreateMyTravelExpense';
import ReadMyTravelExpense from './MyTravelExpenses/Crud/ReadMyTravelExpense';
import UpdateMyTravelExpense from './MyTravelExpenses/Crud/UpdateMyTravelExpense';
import { persistExpenseTypesAction } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/ExpenseTypes/action';
import { persistTravelCostTypesAction } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/TravelCostTypes/action';
import CreateMyExpense from './MyExpenses/Crud/CreateMyExpense';
import ReadMyExpense from './MyExpenses/Crud/ReadMyExpense';
import UpdateMyExpense from './MyExpenses/Crud/UpdateMyExpense';
import CreateMyVacationRequest from './MyVacationRequests/Crud/CreateMyVacationRequest';
import ReadMyVacationRequest from './MyVacationRequests/Crud/ReadMyVacationRequest';
import UpdateMyVacationRequest from './MyVacationRequests/Crud/UpdateMyVacationRequest';
import { persistVacationGeneralConfigurationAction } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/VacationGeneral/action';
import { persistTimesheetseneralConfigurationAction } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/TimesheetsGeneral/action';
import { ProjectTimeTravelDashboard } from './ProjectDashboard/ProjectTimeTravelDashboard';
import { persistBusinessPartnersAction } from 'containers/Configuration/SiteConfiguration/Projects/CreateNewPartner/action';
import { persistOrganizationalUnitsAction } from 'containers/Configuration/SiteConfiguration/General/Company/OrganizationalUnits/action';
import { TimeTravelSubRoutesEnum, approvalSubRoute, myDashboardSubRoute, myExpensesSubRoute, myTimesheetsSubRoute, myTravelExpensesSubRoute, myTravelRequestsSubRoute, myVacationRequestsSubRoute, projectDashboardSubRoute } from './routes';

export const TimeTravelRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistTimeAndTravelStatusesAction();
			persistExpenseGeneralConfigurationAction();
			persistVacationGeneralConfigurationAction();
			persistTimesheetseneralConfigurationAction();
			persistNonProjectCategoriesAction();
			persistTravelRequestsAction();
			persistExpenseTypesAction();
			persistTravelCostTypesAction();
			persistBusinessPartnersAction();
			persistOrganizationalUnitsAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[TimeTravelSubRoutesEnum.MY_DASHBOARD] &&
				<Route
					path={myDashboardSubRoute.url}
					render={() => <MyTimeTravelDashboard />}
				/>
			}

			{routesMap[TimeTravelSubRoutesEnum.PROJECT_DASHBOARD] &&
				<Route
					path={projectDashboardSubRoute.url}
					render={() => <ProjectTimeTravelDashboard />}
				/>
			}

			{routesMap[TimeTravelSubRoutesEnum.MY_TIMESHEETS] &&
				<Route
					path={[
						`${myTimesheetsSubRoute.url}/:ticks`,
						myTimesheetsSubRoute.url
					]}
					render={() => <MyTimesheets />}
				/>
			}

			{routesMap[TimeTravelSubRoutesEnum.MY_TRAVEL_REQUESTS] &&
				<Route
					path={myTravelRequestsSubRoute.url}
					render={() => (
						<CrudRouter
							ListComponent={MyTravelRequests}
							CreateComponent={CreateMyTravelRequest}
							ReadComponent={ReadMyTravelRequest}
							UpdateComponent={UpdateMyTravelRequest}
							paramName='travelRequestId'
						/>
					)}
				/>
			}

			{routesMap[TimeTravelSubRoutesEnum.MY_TRAVEL_EXPENSES] &&
				<Route
					path={myTravelExpensesSubRoute.url}
					render={() => (
						<CrudRouter
							ListComponent={MyTravelExpenses}
							CreateComponent={CreateMyTravelExpense}
							ReadComponent={ReadMyTravelExpense}
							UpdateComponent={UpdateMyTravelExpense}
							paramName='travelExpenseId'
						/>
					)}
				/>
			}

			{routesMap[TimeTravelSubRoutesEnum.MY_EXPENSES] &&
				<Route
					path={myExpensesSubRoute.url}
					render={() => (
						<CrudRouter
							ListComponent={MyExpenses}
							CreateComponent={CreateMyExpense}
							ReadComponent={ReadMyExpense}
							UpdateComponent={UpdateMyExpense}
							paramName='expenseId'
						/>
					)}
				/>
			}

			{routesMap[TimeTravelSubRoutesEnum.MY_VACATION_REQUESTS] &&
				<Route
					path={myVacationRequestsSubRoute.url}
					render={() => (
						<CrudRouter
							ListComponent={MyVacationRequests}
							CreateComponent={CreateMyVacationRequest}
							ReadComponent={ReadMyVacationRequest}
							UpdateComponent={UpdateMyVacationRequest}
							paramName='vacationRequestId'
						/>
					)}
				/>
			}

			{routesMap[TimeTravelSubRoutesEnum.APPROVAL] &&
				<Route
					path={approvalSubRoute.url}
					render={() => <Approval />}
				/>
			}

			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
