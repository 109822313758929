import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { ProjectSubcontractorResponse, ReportItemStatusEnum } from 'services/tenantManagementService';
import { reportItemStatuses } from '../../ReportItemStatuses';
import { propertyOf } from 'utils/propertyOf';

const defaultVisibleColumns = [
	propertyOf<ProjectSubcontractorResponse>('isActive'),
	propertyOf<ProjectSubcontractorResponse>('name'),
	propertyOf<ProjectSubcontractorResponse>('deliverable'),
	propertyOf<ProjectSubcontractorResponse>('status'),
	propertyOf<ProjectSubcontractorResponse>('achievedDate'),
	propertyOf<ProjectSubcontractorResponse>('issue'),
	propertyOf<ProjectSubcontractorResponse>('solution'),
	propertyOf<ProjectSubcontractorResponse>('resolution')
]

export const useTableColumnsMemo = (visibleColumns: string[] = defaultVisibleColumns) => {
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectSubcontractorResponse>('isActive')]: {
					title: 'Active',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<ProjectSubcontractorResponse>('name')]: {
					title: 'Subcontractor',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectSubcontractorResponse>('deliverable')]: {
					title: 'Deliverable',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectSubcontractorResponse>('status')]: {
					title: 'Status',
					fieldType: FieldTypeEnum.Options,
					options: reportItemStatuses,
					getItemId: (option: ReportItemStatusEnum) => option.valueOf(),
					getItemText: (option: ReportItemStatusEnum) => option
				},
				[propertyOf<ProjectSubcontractorResponse>('achievedDate')]: {
					title: 'Achieved date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectSubcontractorResponse>('issue')]: {
					title: 'Issue',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectSubcontractorResponse>('solution')]: {
					title: 'Solution',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectSubcontractorResponse>('resolution')]: {
					title: 'Resolution',
					fieldType: FieldTypeEnum.String
				}
			}
		},
		[]
	)

	return useVisibleColumns(columns, visibleColumns);
}
