import { ColumnContainer } from 'components/Layout';
import notifications from 'components/Notification/notification';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { ContentShell } from 'features/Content/ContentShell';
import { ListComponentProps } from 'features/Crud';
import { WithProjectPicker, ComponentProps, pmOrSpmPermission } from 'features/Project'
import { useCallback, useMemo, useState } from 'react';
import { GenericFilterModelCollection, ModuleActivityEnum, RiskCountsResponse, RiskResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getAllRisksGenericFilterAction, deleteRiskAction, exportRisksAction, getAllRisksGenericCountsAction } from './action';
import RiskPlanHelp from './Help/RiskPlanHelp';
import TableButtons from './Table/TableButtons';
import { useTableColumnsMemo } from './Table/tableColumns';

type Props = ComponentProps & ListComponentProps;

const configureViewKey = 'risk_plan_table';

const RiskPlan = ({ project, disabledEdit, dataChangedTopic, publishDataChanged }: Props) => {
	const [selectedRisk, setSelectedRisk] = useState(new RiskResponse());
	const [countsResponse, setCountsResponse] = useState(new RiskCountsResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const tableColumns = useTableColumnsMemo(countsResponse, configureViewKey);

	const fetchCountsDataCallback = useCallback(
		async () => {
			const bindedAction = getAllRisksGenericCountsAction.bind(null, project.id, filtersModel);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setCountsResponse(response.value)
			}
		},
		[project.id, filtersModel]
	)

	const memoFetchFunction = useCallback(
		async (filtersModel: GenericFilterModelCollection) => {
			const [, response] = await Promise.all([
				fetchCountsDataCallback(),
				getAllRisksGenericFilterAction(project.id, filtersModel)
			])

			return response
		},
		[project.id, fetchCountsDataCallback]
	)

	const handleDelete = useCallback(
		async (id: number) => {
			const bindedAction = deleteRiskAction.bind(null, project.id, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Risk is successfully deleted');
				publishDataChanged();
			}
		},
		[project.id, publishDataChanged]
	);

	const handleRowSelectionChange = useCallback(
		(data: RiskResponse[]) => {
			setSelectedRisk(data[0] || new RiskResponse());
		},
		[]
	);

	const memoExportFunction = useMemo(
		() => exportRisksAction.bind(null, project.id),
		[project.id]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='Risk plan'
			FloatingHelpComponent={RiskPlanHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedRisk.id}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					onDelete={handleDelete}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
					disabled={disabledEdit}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={dataChangedTopic}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectPicker(RiskPlan, ModuleActivityEnum.Risk, true, undefined, pmOrSpmPermission);
