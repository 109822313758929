import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { ConfigureViewColumnType } from 'features/ConfigureView/ConfigureView';
import { CrudButtons } from 'features/Crud';

type Props = {
	selectedId: number
	onDelete: (id: number) => Promise<void>
	disabledDelete: boolean
	configureViewKey: string
    tableColumns: ConfigureViewColumnType[]
	disabled: boolean
}

export const TableButtons = ({
	selectedId,
	onDelete,
	disabledDelete,
	configureViewKey,
	tableColumns,
	disabled
}: Props) => {
	return (
		<div>
			<RowContainer justifyContent='space-between'>
				<RowContainer>
					<CrudButtons
						selectedId={selectedId}
						onDelete={onDelete}
						isChangeable={false}
						isViewable={false}
						specificActionDisabled={{
							delete: disabledDelete
						}}
						disabled={disabled}
					/>
				</RowContainer>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				</RowContainer>
			</RowContainer>
		</div>
	)
}
