import { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import { useTableColumnsMemo } from './Table/tableColumns';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import { CrudEnum } from 'features/Crud';
import { GenericFilterModelCollection, ProjectActionResponse, ProjectReportResponse } from 'services/tenantManagementService';
import { deleteActionAction, exportAction, getActionsGenericAction } from './action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { UpdateReportSubRoutes } from 'containers/Projects/ProjectReport/Crud/UpdateReportRouter';
import { TableButtons } from 'containers/Projects/ProjectReport/ReportComponents/Table/TableButtons';
import { commentsAndActionsChangedTopic, publishCommentsAndActionsChanged } from './commentsAndActionsChanged';
import { ExportModel } from 'features/Export/ExportForm';
import { createExportModel } from 'utils/exportUtils';
import { tryCatchExportFileByAction } from 'utils/exportUtils';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { PROJECT_REPORT_CONFIGURE_VIEW_KEYS } from '../../Crud/configureViewKeys';

type Props = {
	report: ProjectReportResponse
	reportCrud: CrudEnum
};

const configureViewKey = PROJECT_REPORT_CONFIGURE_VIEW_KEYS.COMMENTS_AND_ACTIONS;

export const CommentsAndActions = ({ report, reportCrud }: Props) => {
	const [selectedAction, setSelectedAction] = useState(new ProjectActionResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());
	const [loadingExport, setLoadingExport] = useState(false);

	const { persistedConfigureView } = useSelector((state: RootState) => state);

	const tableColumns = useTableColumnsMemo(
		persistedConfigureView.value[configureViewKey]
	);

	const handleDelete = useCallback(
		async (id: number) => {
			const bindedAction = deleteActionAction.bind(null, report.id, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Comment/Action is successfully deleted');
				publishCommentsAndActionsChanged();
			}
		},
		[report.id]
	);

	const handleRowSelectionChange = useCallback(
		(data: ProjectActionResponse[]) => {
			setSelectedAction(data[0] || new ProjectActionResponse());
		},
		[]
	);

	const memoFetchFunction = useMemo(
		() => getActionsGenericAction.bind(null, report.id),
		[report.id]
	)

	const exportCallback = useCallback(
		async (exportModel: ExportModel) => {
			const exportDataModel = createExportModel(tableColumns, filtersModel, exportModel);
			exportDataModel.title = 'Comments/Actions';

			setLoadingExport(true);

			const bindedAction = exportAction.bind(null, report.id, exportDataModel);
			const response = await tryCatchExportFileByAction(bindedAction);
			if (response.success && response.value) {
				FileSaver.saveAs(response.value.data, response.value.fileName);
			}

			setLoadingExport(false);
		},
		[report.id, filtersModel, tableColumns]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<>
			<h2>Comments / Actions</h2>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedAction.id}
					onDelete={handleDelete}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					routePrefix={UpdateReportSubRoutes.COMMENTS_AND_ACTIONS}
					onExport={exportCallback}
					loadingExport={loadingExport}
					showCrud={reportCrud !== CrudEnum.Read}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={commentsAndActionsChangedTopic}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</>
	)
}
