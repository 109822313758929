import {useMemo } from 'react';
import { useApplyStatusColorCallback, useStatusCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { MyTestPlanResponse, TestStatusResponse, UserModel } from 'services/tenantManagementService';
import { formatTime } from 'utils/dateTimeUtils';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
	propertyOf<MyTestPlanResponse>('level3ProcessId'),
	propertyOf<MyTestPlanResponse>('level3ProcessName'),
	propertyOf<MyTestPlanResponse>('plannedDate'),
	propertyOf<MyTestPlanResponse>('processTesterUserId'),
	propertyOf<MyTestPlanResponse>('processStatusId'),
]

export const defaultProcessStepColumns = [
	propertyOf<MyTestPlanResponse>('processStepId'),
	propertyOf<MyTestPlanResponse>('processStepName'),
	propertyOf<MyTestPlanResponse>('stepTesterUserId'),
	propertyOf<MyTestPlanResponse>('stepStatusId'),
]

export const useTableColumnsMemo = (
	showProcessSteps?: boolean,
	visibleColumns: string[] = defaultVisibleColumns
) => {
	const { 
		persistedTestCaseStatus,
		persistedUser
	} = useSelector((state: RootState) => state);

	const getStatusName = useStatusCallback(persistedTestCaseStatus);
	const applyTestStatusColor = useApplyStatusColorCallback(persistedTestCaseStatus);
	const getUserFullName = useUserFullNameCallback(persistedUser);

	const columns: TableColumnsType = useMemo(
		() => {
			let tableColumns: any =  {
				// all test plans are always active, so we do not need isActive field in table
				// isActive
				[propertyOf<MyTestPlanResponse>('level3ProcessId')]: {
					title: 'Level 3 Process ID',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<MyTestPlanResponse>('level3ProcessName')]: {
					title: 'Level 3 Process description',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<MyTestPlanResponse>('plannedDate')]: {
					title: 'Planned date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<MyTestPlanResponse>('plannedTime')]: {
					title: 'Planned time (from)',
					fieldType: FieldTypeEnum.Number,
					formatter: (cell: any) => formatTime(cell.getValue()),
					format: '{0:HH:mm}'
				},
				[propertyOf<MyTestPlanResponse>('duration')]: {
					title: 'Duration',
					formatter: (cell: any) => cell.getValue() ? `${cell.getValue() / 10000 / 1000 / 60} min`: '',
					fieldType: FieldTypeEnum.Number
				},
				[propertyOf<MyTestPlanResponse>('processTesterUserId')]: {
					title: 'Process tester',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				[propertyOf<MyTestPlanResponse>('processStatusId')]: {
					title: 'Process status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyTestStatusColor(id, cell.getElement());
						return getStatusName(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTestCaseStatus.items,
					getItemId: (option: TestStatusResponse) => option.id,
					getItemText: (option: TestStatusResponse) => option.name
				},
				[propertyOf<MyTestPlanResponse>('comments')]: {
					title: 'Comments',
					fieldType: FieldTypeEnum.String,
				},
			};
			if (showProcessSteps) {
				tableColumns = {
					...tableColumns,
					[propertyOf<MyTestPlanResponse>('processStepId')]: {
						title: 'Process step ID',
						fieldType: FieldTypeEnum.String,
						disableSort: true
					},
					[propertyOf<MyTestPlanResponse>('processStepName')]: {
						title: 'Process step name',
						fieldType: FieldTypeEnum.String,
						disableSort: true
					},
					[propertyOf<MyTestPlanResponse>('stepTesterUserId')]: {
						title: 'Step tester',
						formatter: (cell: any) => getUserFullName(cell.getValue()),
						fieldType: FieldTypeEnum.Options,
						options: persistedUser.items,
						getItemId: (option: UserModel) => option.id,
						getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
						disableSort: true
					},
					[propertyOf<MyTestPlanResponse>('stepStatusId')]: {
						title: 'Step status',
						formatter: (cell: any) => {
							const id = cell.getValue();
							applyTestStatusColor(id, cell.getElement());
							return getStatusName(id);
						},
						fieldType: FieldTypeEnum.Options,
						options: persistedTestCaseStatus.items,
						getItemId: (option: TestStatusResponse) => option.id,
						getItemText: (option: TestStatusResponse) => option.name,
						disableSort: true
					},
				}
			}
			return tableColumns;
		},
		[getStatusName, applyTestStatusColor, getUserFullName, persistedTestCaseStatus.items, persistedUser.items, showProcessSteps]
	)

	return useVisibleColumns(columns, visibleColumns);
}
