import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import MySurveyDocument from './Document/MySurveyDocument';
import { MySurveyQuestionResponse, ProjectResponse, SurveyResponse } from 'services/tenantManagementService';
import { saveLocalDocumentToPDF } from 'utils/reactPDFUtils';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { RootState } from 'base/reducer/reducer';

type Props = {
	survey: SurveyResponse
	surveyQuestions: MySurveyQuestionResponse[]
	project: ProjectResponse
}

const Export = ({ survey,  surveyQuestions, project }: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const { persistedCompanyInfo } = useSelector((state: RootState) => state);

	const downloadPDFCallback = useCallback(
		async () => {
			setIsLoading(true);

			const error = await saveLocalDocumentToPDF(
				`My_survey_${getFormatedId(EntityPrefixEnum.SURVEY, survey.id)}`,
				<MySurveyDocument
					survey={survey}
					surveyQuestions={surveyQuestions}
					project={project}
					base64Logo={persistedCompanyInfo.value?.logoInPDF}
				/>
			);

			error && notifications.error(error);

			setIsLoading(false);
		},
		[
			survey,
			surveyQuestions,
			project,
			persistedCompanyInfo
		]
	)

	return (
		<Button
			text='Export to PDF'
			color='secondary'
			onClick={downloadPDFCallback}
			isLoading={isLoading}
		/>
	)
}

export default Export;
