import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { AutoCompleteField, DateField, Form, InputField, TextareaField } from 'components/Form';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { TimeTravelStatusResponse, ExpenseResponse, ExpenseItemResponse, ModuleActivityEnum, TimeTravelPermission } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import Button from 'components/Button';
import { ColumnContainer, RowContainer } from 'components/Layout';
import ExpenseItems from '../ExpenseItems/ExpenseItems';
import { ProjectOrCategorySelect, ProjectOrCategoryType } from 'features/Project';

type Props = {
	expense?: ExpenseResponse
	crud: CrudEnum
	onSave?: (expense: ExpenseResponse, isRelease: boolean, initialExpense: ExpenseResponse) => void
}

const ExpenseForm = ({
	expense = new ExpenseResponse(),
	crud,
	onSave
}: Props) => {
	const {
		persistedTimeAndTravelStatus,
		persistedExpenseGeneralConfiguration
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(expense);
	const [initialValues, setInitialValues] = useState(expense);
	const [projectOrCategoryHasError, setProjectOrCategoryHasError] = useState(false);

	const [projectOrCategory, setProjectOrCategory] = useState<ProjectOrCategoryType | undefined>({
		projectOrCategoryId: values.projectOrCategoryId,
		isProjectConnected: values.isProjectConnected
	});

	const isReleaseRef = useRef(false);

	const goBackFromCrud = useGoBackFromCrud(crud);

	const isRead = crud === CrudEnum.Read;
	const isUpdate = crud === CrudEnum.Update;

	useEffect(
		() => {
			setInitialValues((state: ExpenseResponse) => {
				return new ExpenseResponse({
					...state,
					projectOrCategoryId: projectOrCategory?.projectOrCategoryId!,
					isProjectConnected: projectOrCategory?.isProjectConnected!
				})
			});
		},
		[projectOrCategory]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const newExpense = new ExpenseResponse({
				...values,
				isProjectConnected: projectOrCategory?.isProjectConnected!,
				projectOrCategoryId: projectOrCategory?.projectOrCategoryId!,
			});

			if (!newExpense.projectOrCategoryId) {
				// projectOrCategorySelect is required, so we return message as error if no value in select
				setProjectOrCategoryHasError(true);
			} else {
				return onSave && await onSave(newExpense, isReleaseRef.current, initialValues);
			}
		},
		[onSave, values, initialValues, projectOrCategory]
	)

	const expenseItemsChangedCallback = useCallback(
		(expenseItems: ExpenseItemResponse[]) => {
			setValues((state) => new ExpenseResponse({
				...state,
				expenseItems
			}))
		},
		[]
	)

	const onProjectOrCategoryChangeCallback = useCallback(
		(newProjectOrCategory: ProjectOrCategoryType | undefined) => {
			setProjectOrCategory(newProjectOrCategory);
			setProjectOrCategoryHasError(false);
		},
		[]
	)

	return (
		<Form
			values={values}
			initialValues={initialValues}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			disabled={isRead}
			hideButtons={isRead}
			disableUnsavedChangesGuard
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<ProjectOrCategorySelect
								value={projectOrCategory}
								onChange={onProjectOrCategoryChangeCallback}
								disabled={isRead}
								hasError={projectOrCategoryHasError}
								isRequired
								teamMemberPermission={propertyOf<TimeTravelPermission>('travelExpenses')}
								moduleEnum={ModuleActivityEnum.Time}
							/>
							<DateField
								id={propertyOf<ExpenseResponse>('date')}
								label='Date'
								isRequired
							/>
							<TextareaField
								id={propertyOf<ExpenseResponse>('description')}
								label='Description'
								maxLength={2000}
								rows={5}
								isRequired
							/>
						</SmartItem>
						<SmartItem>
							<InputField
								id={propertyOf<ExpenseResponse>('customText1')}
								label='Text 1'
							/>
							<InputField
								id={propertyOf<ExpenseResponse>('customText2')}
								label='Text 2'
							/>
							{(isRead || isUpdate) && (
								<AutoCompleteField
									id={propertyOf<ExpenseResponse>('statusId')}
									label='Status'
									items={persistedTimeAndTravelStatus.items}
									getItemId={(option: TimeTravelStatusResponse) => option.id}
									getItemText={(option: TimeTravelStatusResponse) => option.name!}
									loading={persistedTimeAndTravelStatus.fetching}
									disabled
								/>
							)}
						</SmartItem>
					</SmartContainer>
					<ExpenseItems
						expenseItems={values.expenseItems || []}
						onChange={expenseItemsChangedCallback}
						disabled={isRead}
					/>
				</ColumnContainer>
			)}
			renderAdditionalButtons={persistedExpenseGeneralConfiguration.value.enableApprovalProcess ? (disabled, handleSubmitCallback: () => void, isSubmitting) => (
				<RowContainer>
					<Button
						text='Release for approval'
						disabled={disabled}
						isLoading={isSubmitting && isReleaseRef.current === true}
						onClick={
							() => {
								isReleaseRef.current = true;
								handleSubmitCallback();
							}
						}
					/>
				</RowContainer>
			) : undefined}
		/>
	)
}

export default ExpenseForm;
