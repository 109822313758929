import { HelpShell } from 'features/Help/HelpShell';

export const MyExpensesHelp = () => {
	return (
		<HelpShell title='My Expense Report'>
			<p>
				Expenses like restaurant bills and similar project related costs can be submitted for approval to project managers.
			</p>
			<p>
				Please note that expenses need to be supported by appropriate receipt and that receipt attachment could be mandatory. This can be configured by your site administrator. For each receipt you are entering amount in project currency, but there is also possibility to enter amount in receipt currency. Conversion from receipt currency to project currency is done by user. Application is not using exchange rates and it will not convert amounts in different currencies.
			</p>
			<p>
				When all information is specified, you can save your expense report or release it for approval by responsible project manager. <br />
				Project manager is approving or rejecting each expense. <br />
				If expense is rejected, user can make modifications to expense and send it for approval again.
			</p>
			<p>
				Expenses can be exported to pdf files if needed. In pdf export also all receipts are attached. You can use jpg, png and pdf files when attaching receipts.
			</p>
			<p>On time and travel dashboard page you can see reports related to your expenses such as:</p>
			<div>
				<ul>
					<li>Expenses per status: created, released, approved</li>
					<li>List of not approved expenses per project</li>
				</ul>
			</div>
		</HelpShell>
	)
}
