import { useMemo } from 'react'
import { TrainingDashboardResponse } from 'services/tenantManagementService'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { Dashboard } from 'features/Dashboard/Dashboard';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { TextWidget } from 'features/Dashboard/Widget/TextWidget';
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';

type Props = {
	loading: boolean
	dashboard: TrainingDashboardResponse
}

export const FilteredDashboard = ({ loading, dashboard }: Props) => {
	const { persistedTrainingStatus, persistedUser } = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const statusDataMemo = useMemo(
		() => {
			const data = persistedTrainingStatus.items.map(item => {
				return {
					id: item.id.toString(),
					name: item.name,
					count: (dashboard.trainingsPerStatusCounts && dashboard.trainingsPerStatusCounts[item.id]) ? dashboard.trainingsPerStatusCounts[item.id] : 0
				}
			});

			return data;
		},
		[dashboard, persistedTrainingStatus]
	)

	const columnSeriesDataMemo = useMemo(
		() => {
			const data: any[] = [];

			for (const participantId in dashboard.trainingsPerParticipantsAndStatusCounts) {
				const object = dashboard.trainingsPerParticipantsAndStatusCounts[participantId];
				data.push({
					username: getUserFullName(parseInt(participantId)),
					...object
				});
			}

			return data;
		},
		[dashboard, getUserFullName]
	)

	const columnSeriesFieldsMemo = useMemo(
		() => persistedTrainingStatus.items.map(status => {
			return {
				value: status.id.toString(),
				name: status.name,
				color: status.color
			}

		}),
		[persistedTrainingStatus]
	)

	return (
		<Dashboard orientation='horizontal'>
			<PieWidget
				className='col-xl-4 col-md-6'
				title='Training Status'
				id='training_status_pie_chart'
				fieldValue='count'
				fieldCategory='name'
				data={statusDataMemo}
				loading={loading}
			/>
			<TextWidget
				className='col-2'
				title='Total Number of Participants'
				value={dashboard.participantsCount}
				loading={loading}
			/>
			<RotatedColumnSeriesWidget
				className='col-xl-6 col-12'
				title='Training Status per Participant'
				id='training_column_series_chart'
				data={columnSeriesDataMemo}
				fieldCategory='username'
				stacked
				fieldValues={columnSeriesFieldsMemo}
				loading={loading}
				showValueOnBar
			/>
		</Dashboard>
	)
}
