import { RootState } from 'base/reducer/reducer'
import { AutoCompleteField, currencyDefaultProps, DateField, Form, Input, InputField, InputNumber, InputNumberField, percentDefaultProps, TextareaField } from 'components/Form'
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer'
import { CrudEnum } from 'features/Crud'
import { useCurrencySuffixMemo } from 'features/Currency/useCurrencySuffixMemo'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { MitigationTypeEnum, ModuleActivityEnum, RiskImpactEnum, RiskProbabilityEnum, RiskResponse, RiskStatusResponse, TenantIsActiveResponse, UserModel } from 'services/tenantManagementService'
import { propertyOf } from 'utils/propertyOf'
import { getCostBenefit, getNetImpact, getNetImpactAfterMitigation, getRiskInclusion } from '../helper'
import { VerticalSeparator } from 'components/Layout'
import { ProjectSelect } from 'features/Project'

export const mitigationTypes = [
	MitigationTypeEnum.None,
	MitigationTypeEnum.Corrective,
	MitigationTypeEnum.Preventive
]

type Props = {
	risk?: RiskResponse
	projectId?: number
	crud: CrudEnum
	onSave?: (newRisk: RiskResponse) => void
	cancel?(): void
}

export const RiskForm = ({
	risk,
	projectId,
	crud,
	onSave,
	cancel
}: Props) => {
	const {
		persistedUser,
		persistedRiskStatus,
		persistedRiskType,
		persistedCurrency,
		persistedTenant
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(risk || new RiskResponse());
	const [initialValues, setInitialValues] = useState(risk || new RiskResponse());

	const netImpact = useMemo(
		() => getNetImpact(values.grossImpact, values.probability),
		[values.grossImpact, values.probability]
	)

	const netImpactAfterMitigation = useMemo(
		() => getNetImpactAfterMitigation(netImpact, values.mitigationType, values.grossImpactAfterMitigation, values.probabilityAfterMitigation, values.probability),
		[values.mitigationType, netImpact, values.grossImpactAfterMitigation, values.probabilityAfterMitigation, values.probability]
	)

	const costBenefit = useMemo(
		() => getCostBenefit(netImpact, values.mitigationType, values.mitigationCost, netImpactAfterMitigation, values.grossImpact),
		[values.mitigationType, values.mitigationCost, netImpact, netImpactAfterMitigation, values.grossImpact]
	)

	const riskInclusion = useMemo(
		() => getRiskInclusion(netImpact, values.mitigationType, values.mitigationCost, netImpactAfterMitigation),
		[values.mitigationType, values.mitigationCost, netImpact, netImpactAfterMitigation]
	)

	useEffect(
		() => {
			if (values.mitigationType === MitigationTypeEnum.None) {
				setValues((state) => {
					return new RiskResponse({
						...state,
						mitigationCost: undefined,
						grossImpactAfterMitigation: undefined,
						probabilityAfterMitigation: undefined
					})
				});

				setInitialValues((state) => {
					return new RiskResponse({
						...state,
						mitigationCost: undefined,
						grossImpactAfterMitigation: undefined,
						probabilityAfterMitigation: undefined
					})
				});
			}
		},
		[values.mitigationType]
	)

	const onSubmitCallback = useCallback(
		async () => await onSave!(values),
		[onSave, values]
	)

	const isRead = crud === CrudEnum.Read;

	const currencySymbolSuffix = useCurrencySuffixMemo(persistedCurrency, persistedTenant);

	return (
		<Form
			values={values}
			initialValues={initialValues}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			disabled={isRead}
			hideButtons={isRead}
			render={() => (
				<>
					<SmartContainer>
						<SmartItem>
							<ProjectSelect
								value={projectId}
								disabled
								showCompleted
								moduleEnum={ModuleActivityEnum.Risk}
							/>
							<InputField
								id={propertyOf<RiskResponse>('name')}
								label='Risk name'
								isRequired
							/>
							<AutoCompleteField
								id={propertyOf<RiskResponse>('typeId')}
								label='Risk Type'
								items={persistedRiskType.items}
								getItemId={(item: TenantIsActiveResponse) => item.id}
								getItemText={(item: TenantIsActiveResponse) => item.name}
								getItemDescription={(item: TenantIsActiveResponse) => item.description}
								loading={persistedRiskType.fetching}
							/>
							<AutoCompleteField
								id={propertyOf<RiskResponse>('responsibleUserId')}
								label='Responsible'
								items={persistedUser.items}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								getItemDescription={() => 'Person responsible for managing that risk'}
								loading={persistedUser.fetching}
								isRequired
								sort
							/>
							<DateField
								id={propertyOf<RiskResponse>('dueDate')}
								label='Due date'
								isRequired
							/>
							<AutoCompleteField
								id={propertyOf<RiskResponse>('statusId')}
								label='Mitigation status'
								items={persistedRiskStatus.items}
								getItemId={(item: RiskStatusResponse) => item.id}
								getItemText={(item: RiskStatusResponse) => item.name}
								getItemDescription={(item: RiskStatusResponse) => item.description}
								isRequired
								loading={persistedRiskStatus.fetching}
							/>
						</SmartItem>
						<SmartItem>
							<TextareaField
								id={propertyOf<RiskResponse>('consequneces')}
								label='Consequence'
								explanation='Brief description/classification/ of the associated consequences, e.g. "project closure delay of 10 days"'
								rows={5}
								maxLength={2000}
								multiline
							/>
							<TextareaField
								id={propertyOf<RiskResponse>('effects')}
								label='Detail effect'
								explanation='More detailed description of the main effects'
								rows={5}
								maxLength={2000}
								multiline
							/>
						</SmartItem>
						<SmartItem>
							<AutoCompleteField
								id={propertyOf<RiskResponse>('probabilityDescriptive')}
								label='Probability (descriptive)'
								items={[RiskProbabilityEnum.Low, RiskProbabilityEnum.Medium, RiskProbabilityEnum.High]}
								getItemId={(item: RiskProbabilityEnum) => item}
								getItemText={(item: RiskProbabilityEnum) => item}
							/>
							<AutoCompleteField
								id={propertyOf<RiskResponse>('impactDescriptive')}
								label='Impact (descriptive)'
								items={[RiskImpactEnum.Low, RiskImpactEnum.Medium, RiskImpactEnum.High]}
								getItemId={(item: RiskImpactEnum) => item}
								getItemText={(item: RiskImpactEnum) => item}
							/>
							<VerticalSeparator margin='medium' />
							<TextareaField
								id={propertyOf<RiskResponse>('comment')}
								label='Comment'
								rows={5}
								maxLength={2000}
								multiline
							/>
						</SmartItem>
					</SmartContainer>

					<VerticalSeparator margin='xlarge' />

					<SmartContainer>
						<SmartItem>
							<SmartFormGroup label='Currency'>
								<Input
									value={currencySymbolSuffix}
									disabled
								/>
							</SmartFormGroup>
							<InputNumberField
								id={propertyOf<RiskResponse>('grossImpact')}
								label='Gross impact'
								explanation='Estimated Gross Impact of the possible effects'
								suffix={currencySymbolSuffix}
								{...currencyDefaultProps}
							/>
							<InputNumberField
								id={propertyOf<RiskResponse>('probability')}
								label='Probability (%)'
								explanation='Expected probability that risk will occur during project'
								min={0}
								max={100}
								{...percentDefaultProps}
							/>
							<SmartFormGroup label='Net impact'>
								<InputNumber
									value={netImpact}
									explanation='(Calculated) Net Impact of identified risk'
									disabled
									suffix={currencySymbolSuffix}
									{...currencyDefaultProps}
								/>
							</SmartFormGroup>
						</SmartItem>
						<SmartItem>
							<InputField
								id={propertyOf<RiskResponse>('mitigation')}
								label='Mitigation'
								explanation='Options or tasks to mitigate the risk'
							/>
							<AutoCompleteField
								id={propertyOf<RiskResponse>('mitigationType')}
								label='Mitigation type'
								items={mitigationTypes}
								getItemId={(item: MitigationTypeEnum) => item}
								getItemText={(item: MitigationTypeEnum) => item}
								getItemDescription={(item: MitigationTypeEnum) => 'Type of mitigation action choose, i.e. none, preventive, corrective.'}
								isRequired
							/>
							<InputNumberField
								id={propertyOf<RiskResponse>('mitigationCost')}
								label='Cost of mitigation'
								explanation='The actual cost to carry out the required mitigation activities'
								{...currencyDefaultProps}
								suffix={currencySymbolSuffix}
								disabled={values.mitigationType === MitigationTypeEnum.None}
							/>
							<InputNumberField
								id={propertyOf<RiskResponse>('grossImpactAfterMitigation')}
								label='Gross impact after mitigation'
								explanation='The gross impact after the mitigation activities have been carried out'
								{...currencyDefaultProps}
								suffix={currencySymbolSuffix}
								disabled={values.mitigationType === MitigationTypeEnum.None}
							/>
						</SmartItem>
						<SmartItem>
							<InputNumberField
								id={propertyOf<RiskResponse>('probabilityAfterMitigation')}
								label='Probability after mitigation (%)'
								explanation='The likelihood that the risk will occur after mitigation activities were carried out'
								{...percentDefaultProps}
								min={0}
								max={100}
								disabled={values.mitigationType === MitigationTypeEnum.None}
							/>
							<SmartFormGroup label='Net impact after mitigation'>
								<InputNumber
									value={netImpactAfterMitigation}
									explanation='(Calculated) The net impact after mitigation activities were performed'
									{...currencyDefaultProps}
									disabled
									suffix={currencySymbolSuffix}
								/>
							</SmartFormGroup>
							<SmartFormGroup label='Cost Benefit analysis'>
								<InputNumber
									value={costBenefit}
									explanation='(Calculated) Cost Benefit analysis based on entered gross impacts, probabilities and selected mitigation type'
									{...currencyDefaultProps}
									disabled
									suffix={currencySymbolSuffix}
								/>
							</SmartFormGroup>
							<SmartFormGroup label='Risk Inclusion in budget'>
								<InputNumber
									value={riskInclusion}
									explanation='(Calculated) Amount to be included in budget for that specific risk. Calculated based on probabilities, impacts and mitigation type'
									{...currencyDefaultProps}
									disabled
									suffix={currencySymbolSuffix}
								/>
							</SmartFormGroup>
						</SmartItem>
					</SmartContainer>
				</>
			)}
		/>
	)
}
