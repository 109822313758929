import { useCallback, useState } from 'react';
import { DeleteTenantIndetifyRequest, InsertTaskTimesheetRequest, TaskTimesheetDeltaRequest, TaskTimesheetResponse, UpdateTaskTimesheetRequest, UserModel } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { generateNumberId } from 'base/id';
import { createDelta, unpackDelta } from 'utils/commonHelper';
import notifications from 'components/Notification/notification';
import WithFetch from 'features/Fetch/WithFetch';
import { TableField, Form, DateField, TimeSpanField, AutoCompleteField } from 'components/Form';
import { ColumnContainer } from 'components/Layout';
import Button from 'components/Button';
import { propertyOf } from 'utils/propertyOf';
import { getTimesheetsTaskAction, updateTimesheetsTaskAction } from './action';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { timeSpanTotalCalculator } from 'components/Form/controls/Table/Table/totalCalculators';
import { getUserInfo } from 'utils/storageUtils';
import { TaskTabComponentProps } from '../taskTabs';

type TableFieldObject = {
	taskTimesheet: TaskTimesheetResponse[]
}

export const Effort = ({ projectOrCategoryId, isProjectConnected, taskId, isRead}: TaskTabComponentProps) => {
	const [values, setValues] = useState<TableFieldObject>({ taskTimesheet: [] });
	const [initialValues, setInitialValues] = useState<TableFieldObject>({ taskTimesheet: [] });
	const [refetching, setRefetching] = useState(false);

	const { persistedUser } = useSelector((state: RootState) => state);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getTimesheetsTaskAction.bind(null, taskId, projectOrCategoryId, isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const items = response.items || [];
				const values = { taskTimesheet: items };
				setValues(values);
				setInitialValues(values);
			}
		},
		[projectOrCategoryId, isProjectConnected, taskId]
	);

	const refetchDataCallback = useCallback(
		async () => {
			setRefetching(true);
			await fetchDataCallback();
			setRefetching(false);
		},
		[fetchDataCallback]
	);

	const addCallback = useCallback(
		() => {
			const id = generateNumberId();
			const user = getUserInfo();

			setValues((state: TableFieldObject) => {
				const newModel = new TaskTimesheetResponse();
				newModel.id = id;
				newModel.userId = user.id

				const newState = { ...state };
				newState.taskTimesheet = [...newState.taskTimesheet, newModel];

				return newState;
			})
		},
		[]
	);

	const handleCancel = useCallback(
		() => setValues(initialValues),
		[initialValues]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const delta = createDelta<TaskTimesheetResponse>
			(
				initialValues.taskTimesheet,
				values.taskTimesheet,
				InsertTaskTimesheetRequest,
				UpdateTaskTimesheetRequest,
				TaskTimesheetDeltaRequest,
				DeleteTenantIndetifyRequest
			);

			const bindedAction = updateTimesheetsTaskAction.bind(null, taskId, projectOrCategoryId, isProjectConnected, delta)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Task and My timesheets are updated.');
				refetchDataCallback();
				setInitialValues(values);
			} else {
				const errors = convertResponseErrors(response);
				const [taskTimesheetErrors, taskTimesheetGroups] = unpackDelta(errors, delta, values.taskTimesheet, initialValues.taskTimesheet);
				setValues({...values, taskTimesheet: taskTimesheetGroups});
				return { groups: taskTimesheetErrors };
			}
		},
		[initialValues, values, projectOrCategoryId, isProjectConnected, taskId, refetchDataCallback]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback} refetching={refetching}>
			<Form
				values={values}
				initialValues={initialValues}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={handleCancel}
				disabled={isRead}
				hideButtons={isRead}
				render={() => (
					<ColumnContainer margin='medium'>
						<Button
							onClick={addCallback}
							text='Add user'
							disabled={isRead}
						/>
						<TableField
							id={propertyOf<TableFieldObject>('taskTimesheet')}
							headers={[
								{ id: propertyOf<TaskTimesheetResponse>('userId'), label: 'User', size: 4, isRequired: true },
								{ id: propertyOf<TaskTimesheetResponse>('estimatedTime'), label: 'Estimated effort', size: 3, totalCalc: timeSpanTotalCalculator },
								{ id: propertyOf<TaskTimesheetResponse>('date'), label: 'Actual date', size: 2 },
								{ id: propertyOf<TaskTimesheetResponse>('effort'), label: 'Actual effort', size: 3, totalCalc: timeSpanTotalCalculator }
							]}
							hasTotal
							getRowData={() => {
								return {
									isDeletable: !isRead,
									fields: [
										<AutoCompleteField
											id={propertyOf<TaskTimesheetResponse>('userId')}
											items={persistedUser.items}
											getItemId={(item: UserModel) => item.id}
											getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
											loading={persistedUser.fetching}
											isRequired
											disabled
										/>,
										<TimeSpanField
											id={propertyOf<TaskTimesheetResponse>('estimatedTime')}
										/>,
										<DateField
											id={propertyOf<TaskTimesheetResponse>('date')}
											maxDate={new Date()}
										/>,
										<TimeSpanField
											id={propertyOf<TaskTimesheetResponse>('effort')}
										/>
									]
								}
							}}
						/>
					</ColumnContainer>
				)}
			/>
		</WithFetch>
	)
}
