import { ChangeRequestClient, ExportDataModel, GenericFilterModelCollection, InsertTicketRequest, PartalTicketRequestEnum, TicketClient, TicketStatusClient, UpdateTicketRequest } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getTicketAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new TicketClient();
	return client.get(tenantId, id);
}

export const getAllTicketsAction = (partalRequest: PartalTicketRequestEnum[] | undefined, genericFilter: GenericFilterModelCollection | undefined) => {
	const tenantId = getTenantId()!;
	const client = new TicketClient();
	return client.getAll(tenantId, partalRequest, genericFilter || new GenericFilterModelCollection());
}

export const getAllTicketsSimpleAction = () => {
	const tenantId = getTenantId()!;
	const client = new TicketClient();
	return client.getAllSimple(tenantId);
}

export const getAllByProjectIdTicketsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new TicketClient();
	return client.getAllByProjectId(tenantId, projectId);
}

export const createTicketAction = (model: InsertTicketRequest) => {
	const tenantId = getTenantId()!;
	const client = new TicketClient();
	return client.create(tenantId, model);
}

export const updateTicketAction = (model: UpdateTicketRequest) => {
	const tenantId = getTenantId()!;
	const client = new TicketClient();
	return client.update(tenantId, model);
}

export const deleteTicketAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new TicketClient();
	return client.delete(tenantId, id);
}

export const getStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new TicketStatusClient();
	return client.getAll(tenantId);
}

export const getChangeRequestsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.getAll(tenantId, projectId);
}

export const donwloadAttachmentAction = (ticketId: number, attachmentId: number) => {
	const tenantId = getTenantId()!;
	const client = new TicketClient();
	return client.downloadAttachment(tenantId, ticketId, attachmentId);
}

export const removeAttachmentAction = (ticketId: number, attachmentId: number) => {
	const tenantId = getTenantId()!;
	const client = new TicketClient();
	return client.removeAttachment(tenantId, ticketId, attachmentId);
}

export const getHistoryAction = (ticketId: number) => {
	const tenantId = getTenantId()!;
	const client = new TicketClient();
	return client.getHistoryFromTo(tenantId, ticketId, undefined, undefined);
}

export const exportAction = (partalRequest: PartalTicketRequestEnum[] | undefined, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new TicketClient();
	return client.export(tenantId, partalRequest, exportDataModel);
}
