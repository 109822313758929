import { useMemo } from 'react';
import styles from './historyLog.module.scss';
import { formatDateTime } from 'utils/dateTimeUtils';

export type HistoryLogType = {
	who: string
	when: Date
	changes: Array<{
		title: string
		old?: string
		new?: string
	}>
}

type Props = {
	log?: HistoryLogType[]
}

export const HistoryLogTable = ({ log }: Props) => {
	const content = useMemo(
		() => {
			return log && log.map((item, index) => (
				<div key={index} className={styles.row}>
					<div className={styles.cell}>{formatDateTime(item.when)}</div>
					<div className={styles.cell}>{item.who}</div>
					<div className={styles.column}>
						{item.changes.map((change, index) => (
							<div className={styles.row} >
								<div className={styles.cell}>{change.title || ''}</div>
								<div className={styles.cell}>{change.old || ''}</div>
								<div className={styles.cell}>{change.new || ''}</div>
							</div>
						))}
					</div>
				</div>
			))
		},
		[log]
	)

	return (
		<>
			<div className={styles.row}>
				<div className={styles.header_cell}>When</div>
				<div className={styles.header_cell}>Who</div>
				<div className={styles.column}>
					<div className={styles.row}>
						<div className={styles.header_cell}>What</div>
						<div className={styles.header_cell}>Old value</div>
						<div className={styles.header_cell}>New value</div>
					</div>
				</div>
			</div>
			{content}
		</>
	)
}
