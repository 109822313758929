import { Dialog } from "components/Dialog";
import { useCallback, useMemo } from "react";
import { InsertScheduleRequest, ProjectResponse, ScheduleResponse } from "services/tenantManagementService";
import { convertResponseErrors, tryCatchJsonByAction } from "utils/fetchUtils";
import { createScheduleAction } from "../../action";
import { CreateTaskForm } from './CreateTaskForm';

type Props = {
	isOpen: boolean
	setIsOpen(isOpen: boolean): void
	afterBackendSave(task: ScheduleResponse): void
	ganttChartRef: React.MutableRefObject<any>
	project: ProjectResponse
}

export const CreateTask = ({ isOpen, setIsOpen, afterBackendSave, ganttChartRef, project }: Props) => {
	const closeDialogCallback = useCallback(
		() => setIsOpen(false),
		[setIsOpen]
	);

	const onSaveCallback = useCallback(
		async (values: ScheduleResponse) => {
			const model = new InsertScheduleRequest(values);

			const bindedAction = createScheduleAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				afterBackendSave(response.value || new ScheduleResponse());
				closeDialogCallback();
			} else {
				return convertResponseErrors(response);
			}
		},
		[closeDialogCallback, project.id, afterBackendSave]
	)

	const newTask = useMemo(
		() => {
			const parentTask = ganttChartRef.current.getSelectedTask();
			let wbsSufix;
			let wbsPrefix;

			if (parentTask) {
				wbsPrefix = `${parentTask.$wbs}.`
				const childrens = ganttChartRef.current.getTaskChildrens(parentTask.id);
				wbsSufix = childrens.length + 1;
			} else {
				wbsPrefix = ''
				const childrens = ganttChartRef.current.getTaskChildrens(undefined);
				wbsSufix = childrens.length + 1;
			}

			const task = new ScheduleResponse();
			task.wbs = `${wbsPrefix}${wbsSufix}`;
			if (parentTask) {
				task.parentId = parseInt(parentTask.id);
			}
			return task;
		},
		[ganttChartRef]
	)

	return (
		<Dialog
			open={isOpen}
			title='Add task'
			onClose={closeDialogCallback}
		>
			<CreateTaskForm
				task={newTask}
				onSave={onSaveCallback}
				onCancel={closeDialogCallback}
			/>
		</Dialog>
	)
}
