import { HelpShell } from 'features/Help/HelpShell';

export const TrainingCyclesHelp = () => {
	return (
		<HelpShell title='Training cycle'>
			<p>You can define various training cycles for your project within training module.</p>
			<p>For each cycle training plan needs to be defined.</p>
			<p>In order to release training plan for users you need to mark training cycle as 'active'.</p>
			<p>Training plans will not be shown in user's training plan if not marked as active.</p>
		</HelpShell>
	)
}
