import Button from "components/Button";
import { RowContainer } from "components/Layout";
import { useCallback } from "react";
import { gantt } from "../GanttChart";

export const ZoomButtons = () => {
	const zoomIn = useCallback(
		() => {
			gantt.ext.zoom.zoomIn()
		},
		[]
	)

	const zoomOut = useCallback(
		() => {
			gantt.ext.zoom.zoomOut()
		},
		[]
	)

	const fullscreen = useCallback(
		() => {
			gantt.ext.fullscreen.toggle();
		},
		[]
	)

	return (
		<RowContainer>
			<div>
				<Button
					text='Zoom In'
					color='neutral'
					onClick={zoomIn}
				/>
				<Button
					text='Zoom Out'
					color='neutral'
					onClick={zoomOut}
				/>
			</div>
			<Button
				text='Fullscreen'
				color='neutral'
				onClick={fullscreen}
			/>
		</RowContainer>
	)
}
