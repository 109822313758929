import { HelpShell } from 'features/Help/HelpShell';

export const MyVacationRequestsHelp = () => {
	return (
		<HelpShell title='My Vacation Requests'>
			<p>Vacation requests enable evidence of your absence. <br />
				You can create vacation requests which are approved by project manager on each project on which you are assigned to.
			</p>
			<p>Vacation request is in status released until it is approved by each project manager. <br />
				For approved vacation period, you do not need to record time in your timesheets.
			</p>
			<p>On time and travel dashboard page you can see reports related to your vacation such as:</p>
			<div>
				<ul>
					<li>Vacation requests per status: created, released, approved</li>
					<li>List of not approved vacation requests per project</li>
					<li>Number of approved vacation days for selected period</li>
				</ul>
			</div>
		</HelpShell>
	)
}
