import { ProjectTimeTravelDashboardFilterRequest, ProjectTimeTravelDashboardResponse } from 'services/tenantManagementService'
import Tabs from 'components/Tabs/Tabs'
import { dashboardTabs } from './Tabs/dashboardTabs'

type Props = {
	loading: boolean
	dashboard: ProjectTimeTravelDashboardResponse
	filterModel: ProjectTimeTravelDashboardFilterRequest
}

const FilteredDashboard = ({ loading, dashboard, filterModel }: Props) => {
	return (
		<Tabs
			tabs={dashboardTabs}
			tabComponentProps={{ loading, dashboard, filterModel }}
		/>
	)
}

export default FilteredDashboard
