import { getTicketStatusesAction, updateTicketStatusesAction } from './action';
import { TicketStatusesHelp } from './TicketStatusesHelp';
import { StatusResponseTable } from 'features/StatusResponse/StatusResponseTable';
import { ContentShell } from 'features/Content/ContentShell';

export const Tickets = () => {
	return (
		<ContentShell
			InlineHelpComponent={TicketStatusesHelp}
			showBreadcrumb={false}
		>
			<StatusResponseTable
				getConfigsAction={getTicketStatusesAction}
				updateConfigsAction={updateTicketStatusesAction}
			/>
		</ContentShell>
	)
}
