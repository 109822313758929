import { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import { ColumnContainer, RowContainer } from 'components/Layout';
import TravelExpenseForm from './TravelExpenseForm';
import { TimeTravelStatusEnum, TravelExpenseResponse } from 'services/tenantManagementService';
import { getFormatedId, EntityPrefixEnum } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, CrudSubRoutesEnum, useGoBackFromCrudAndPush } from 'features/Crud';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { cloneMyTravelExpenseAction, getTravelExpenseAction } from '../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import Export from '../Export/Export';
import notifications from 'components/Notification/notification';
import { ReadComponentProps } from 'features/Crud/CrudRouter';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = {
	travelExpenseId: string;
}

const ReadMyTravelExpense = ({ publishDataChanged }: ReadComponentProps) => {
	const params: ParamType = useParams();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [travelExpense, setTravelExpense] = useState(new TravelExpenseResponse());
	const {
		persistedTimeAndTravelStatus,
		persistedExpenseGeneralConfiguration
	} = useSelector((state: RootState) => state);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getTravelExpenseAction.bind(null, parseInt(params.travelExpenseId));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {

				setTravelExpense(response.value || new TravelExpenseResponse());
			}
		},
		[params.travelExpenseId]
	)

	const cloneTravelExpenseCallback = useCallback(
		async () => {
			setIsSubmitting(true);
			const bindedAction = cloneMyTravelExpenseAction.bind(null, travelExpense.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TRAVEL_EXPENSE, travelExpense.id);
				const clonedId = getFormatedId(EntityPrefixEnum.TRAVEL_EXPENSE, response.value?.id);
				notifications.success(`Travel expense ${id} is copied into ${clonedId}.`);
				publishDataChanged();
			}
			setIsSubmitting(false);
		},
		[publishDataChanged, travelExpense.id]
	)

	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read, undefined, undefined);

	const goUpdateCallback = useCallback(
		() => {
			goBackFromCrudAndPush(`${CrudSubRoutesEnum.Update}/${params.travelExpenseId}`);
		},
		[params.travelExpenseId, goBackFromCrudAndPush]
	)

	const showChangeMemo = useMemo(
		() => !persistedExpenseGeneralConfiguration.value.enableApprovalProcess ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, travelExpense.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Rejected, travelExpense.statusId, persistedTimeAndTravelStatus.itemsMap),
		[persistedTimeAndTravelStatus, travelExpense, persistedExpenseGeneralConfiguration]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`View travel expense - ${getFormatedId(EntityPrefixEnum.TRAVEL_EXPENSE, travelExpense.id)}`}>
				<ColumnContainer margin='medium'>
					<RowContainer>
						{showChangeMemo && <Button text='Change' onClick={goUpdateCallback} />}
						<Button text='Copy' disabled={!travelExpense} onClick={cloneTravelExpenseCallback} isLoading={isSubmitting}/>
						<Export travelExpense={travelExpense}/>
					</RowContainer>
					<TravelExpenseForm
						travelExpense={travelExpense}
						crud={CrudEnum.Read}
					/>
				</ColumnContainer>
			</ContentShell>
		</WithFetch>
	)
}

export default ReadMyTravelExpense;
