import { RootState } from 'base/reducer/reducer';
import Button from 'components/Button/Button';
import notifications from 'components/Notification/notification';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { TravelRequestResponse } from 'services/tenantManagementService'
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { saveLocalDocumentToPDF } from 'utils/reactPDFUtils';
import { TravelRequestDocument } from './Document/TravelRequestDocument';

type Props = {
	travelRequest: TravelRequestResponse
}

export const Export = ({ travelRequest }: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const {
		persistedUser,
		persistedProject,
		persistedTimeTravelNonProjectCategories,
		persistedTimeAndTravelStatus,
		persistedCountry,
		persistedCurrency,
		persistedTenant,
		persistedCompanyInfo
	} = useSelector((state: RootState) => state);

	const downloadPDFCallback = useCallback(
		async () => {
			setIsLoading(true);

			const error = await saveLocalDocumentToPDF(
				`Travel_request_${getFormatedId(EntityPrefixEnum.TRAVEL_REQUEST, travelRequest.id)}`,
				<TravelRequestDocument
					travelRequest={travelRequest}
					persistedUser={persistedUser}
					persistedProject={persistedProject}
					persistedTimeTravelNonProjectCategories={persistedTimeTravelNonProjectCategories}
					persistedTimeAndTravelStatus={persistedTimeAndTravelStatus}
					persistedCountry={persistedCountry}
					persistedCurrency={persistedCurrency}
					persistedTenant={persistedTenant}
					base64Logo={persistedCompanyInfo.value?.logoInPDF}
				/>
			);

			error && notifications.error(error);

			setIsLoading(false);
		},
		[travelRequest,	persistedUser, persistedProject, persistedTimeTravelNonProjectCategories, persistedTimeAndTravelStatus, persistedCountry, persistedCurrency, persistedTenant, persistedCompanyInfo]
	)

	return (
		<Button
			text='Export to PDF'
			color='secondary'
			onClick={downloadPDFCallback}
			isLoading={isLoading}
		/>
	)
}
