import { useMemo } from 'react';
import { useActiveTeamMembersMemo, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns, linksCellFormatter, linksCellValidator, dateEditor, minutesDurationEditor, timeEditor } from 'components/Table';
import { TrainingPlanRowResponse, UserModel } from 'services/tenantManagementService';
import { formatTime } from 'utils/dateTimeUtils';
import { sortByString } from 'utils/stringUtil';
import Tabulator from 'tabulator-tables';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
	propertyOf<TrainingPlanRowResponse>('isActive'),
	propertyOf<TrainingPlanRowResponse>('level3ProcessId'),
	propertyOf<TrainingPlanRowResponse>('level3ProcessName'),
	propertyOf<TrainingPlanRowResponse>('plannedDate'),
	propertyOf<TrainingPlanRowResponse>('participants'),
	propertyOf<TrainingPlanRowResponse>('linkToMaterial'),
	propertyOf<TrainingPlanRowResponse>('linkToVideo')
]

export const useTableColumnsMemo = (
	projectId: number,
	visibleColumns?: string[],
	disableEditor?: boolean
) => {
	const {
		persistedTeamMember,
		persistedUser
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const memberUsersMemo: UserModel[] = useActiveTeamMembersMemo(persistedTeamMember, persistedUser, projectId)

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<TrainingPlanRowResponse>('isActive')]: {
					title: 'Include in training scope',
					fieldType: FieldTypeEnum.Boolean,
					editor: "tickCross"
				},
				[propertyOf<TrainingPlanRowResponse>('level3ProcessId')]: {
					title: 'Level 3 Process ID',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<TrainingPlanRowResponse>('level3ProcessName')]: {
					title: 'Level 3 Process Name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<TrainingPlanRowResponse>('trainerUserId')]: {
					title: 'Trainer',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					editor: !disableEditor && 'list',
					editorParams: {
						values: sortByString(
							memberUsersMemo.map(user => {
								return { label: `${user.firstName} ${user.lastName}`, value: user.id };
							}),
							'label'
						),
						autocomplete: true,
						listOnEmpty: true,
						allowEmpty: true,
						placeholderEmpty: 'No options'
					},
					fieldType: FieldTypeEnum.Options,
					options: memberUsersMemo,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				[propertyOf<TrainingPlanRowResponse>('participants')]: {
					title: 'Participants',
					formatter: (cell: any) => (cell.getValue() || []).map((userId: number | undefined) => getUserFullName(userId)).join(', '),
					disableSort: true,
					editor: !disableEditor && 'list',
					editorParams: {
						multiselect: true,
						values: sortByString(
							memberUsersMemo.map(user => {
								return { label: `${user.firstName} ${user.lastName}`, value: user.id };
							}),
							'label'
						)
					},
					fieldType: FieldTypeEnum.Options,
					options: memberUsersMemo,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				[propertyOf<TrainingPlanRowResponse>('plannedDate')]: {
					title: 'Planned date',
					fieldType: FieldTypeEnum.Date,
					editor: !disableEditor && dateEditor
				},
				[propertyOf<TrainingPlanRowResponse>('plannedTime')]: {
					title: 'Planned time (from)',
					fieldType: FieldTypeEnum.Number,
					formatter: (cell: any) => formatTime(cell.getValue()),
					format: '{0:HH:mm}',
					editor: !disableEditor && timeEditor
				},
				[propertyOf<TrainingPlanRowResponse>('plannedTimeTo')]: {
					title: 'Planned time (to)',
					fieldType: FieldTypeEnum.Number,
					formatter: (cell: any) => formatTime(cell.getValue()),
					format: '{0:HH:mm}',
					editor: !disableEditor && timeEditor
				},
				[propertyOf<TrainingPlanRowResponse>('location')]: {
					title: 'Location',
					fieldType: FieldTypeEnum.String,
					editor: !disableEditor && 'input'
				},
				[propertyOf<TrainingPlanRowResponse>('duration')]: {
					title: 'Duration',
					formatter: (cell: any) => cell.getValue() ? `${cell.getValue() / 10000 / 1000 / 60} min`: '',
					fieldType: FieldTypeEnum.Number,
					editor: !disableEditor && minutesDurationEditor
				},
				[propertyOf<TrainingPlanRowResponse>('linkToMaterial')]: {
					title: 'Link to training material',
					fieldType: FieldTypeEnum.None,
					editor: !disableEditor && ('customLinks' as Tabulator.Editor),
					formatter: (cell: any, formatterParams: any, onRendered: Function) => linksCellFormatter(cell, formatterParams, onRendered, disableEditor),
					tooltip: () => '',
					validator: [{
						type: linksCellValidator,
					}],
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<TrainingPlanRowResponse>('linkToVideo')]: {
					title: 'Link to training video',
					editor: !disableEditor && ('customLinks' as Tabulator.Editor),
					formatter: (cell: any, formatterParams: any, onRendered: Function) => linksCellFormatter(cell, formatterParams, onRendered, disableEditor),
					tooltip: () => '',
					validator: [{
						type: linksCellValidator,
					}],
					fieldType: FieldTypeEnum.None,
					disableFilter: true,
					disableSort: true
				}
			}
		},
		[getUserFullName, disableEditor, memberUsersMemo]
	)

	return useVisibleColumns(columns, visibleColumns || defaultVisibleColumns);
}
