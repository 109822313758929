import { getTenantId } from 'features/Tenant/tenantUtils';
import { ExportDataModel, GenericFilterModelCollection, MultiplyUpdateTrainingPlanRequest, TrainingPlanClient } from 'services/tenantManagementService';

export const getTrainingPlansGenericAction = (projectId: number, trainingCycleId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TrainingPlanClient();
	return client.getAllGenericFilter(tenantId, projectId, trainingCycleId, genericFilter);
}

export const updateTrainingPlansAction = (projectId: number, trainingCycleId: number, updateTrainingPlansRequest: MultiplyUpdateTrainingPlanRequest) => {
	const tenantId = getTenantId()!;
	const client = new TrainingPlanClient();
	return client.bulkUpdate(tenantId, projectId, trainingCycleId, updateTrainingPlansRequest);
}

export const setAllIsActiveAction = (projectId: number, trainingCycleId: number, isActive: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TrainingPlanClient();
	return client.setAllIsActive(tenantId, projectId, trainingCycleId, isActive, genericFilter);
}

export const exportAction = (projectId: number, trainingCycleId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new TrainingPlanClient();
	return client.exportTrainingPlans(tenantId, projectId, trainingCycleId, exportDataModel);
}
