import { ProjectDeliverableClient, InsertProjectDeliverableRequest, UpdateProjectDeliverableRequest, GenericFilterModelCollection, ExportDataModel } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getDeliverablesAction = (reportId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectDeliverableClient();
	return client.getAll(tenantId, reportId, undefined, undefined);
}

export const getDeliverablesGenericAction = (reportId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectDeliverableClient();
	return client.getAllGenericFilter(tenantId, reportId, genericFilter);
}

export const createDeliverableAction = (reportId: number, model: InsertProjectDeliverableRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectDeliverableClient();
	return client.create(tenantId, reportId, model);
}

export const updateDeliverableAction = (reportId: number, model: UpdateProjectDeliverableRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectDeliverableClient();
	return client.update(tenantId, reportId, model);
}

export const getDeliverableAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectDeliverableClient();
	return client.get(tenantId, reportId, id);
}

export const deleteDeliverableAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectDeliverableClient();
	return client.delete(tenantId, reportId, id);
}

export const exportAction = (reportId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectDeliverableClient();
	return client.export(tenantId, reportId, exportDataModel);
}
