import { NavLink } from 'react-router-dom';
import styles from './link.module.scss';

export type LinkType = {
	id: number | string
	url: string
	text: string
	icon?: string
}

const Link = ({ id, url, text, icon }: LinkType) => {
	return (
		<NavLink className={styles.container} to={url}>
			{/* <i className={`${styles.icon} feather ${icon}`}></i> */}
			{text}
		</NavLink>
	)
};

export default Link;
