import { useMemo } from 'react';
import { UserModel, IdNameResponse, BusinessPartnerResponseModel, UserActivityStatusEnum } from 'services/tenantManagementService';
import { useCountryCallback, usePartnerCallback, useOrganizationalUnitCallback } from 'features/TableColumns/persistedHooks';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useVisibleColumns, TableColumnsType, FieldTypeEnum } from 'components/Table';
import { OptionType } from 'components/Form';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';

const userActivityStatuses: OptionType[] = [
	{ id: UserActivityStatusEnum.Active, text: 'Active', },
	{ id: UserActivityStatusEnum.Blocked, text: 'Blocked' },
	{ id: UserActivityStatusEnum.Inactive, text: 'Inactive' }
]

const defaultVisibleColumns = [
	propertyOf<UserModel>('username'),
	'fullName',
	propertyOf<UserModel>('email'),
	propertyOf<UserModel>('officePhone'),
	propertyOf<UserModel>('mobilePhone')
]

export const useTableColumnsMemo = (configureViewKey: string) => {
	const {
		persistedBusinessPartner,
		persistedCountry,
		persistedConfigureView,
		persistedOrganizationalUnit,
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getPartner = usePartnerCallback(persistedBusinessPartner);
	const getCountry = useCountryCallback(persistedCountry);
	const getOrganizationalUnit = useOrganizationalUnitCallback();

	const organizationalUnits = useMemo(
		() => convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined),
		[persistedOrganizationalUnit]
	)

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<UserModel>('activityStatus')]: {
					title: 'Status',
					formatter: (cell: any) => {
						// calculated status, so we have to hard code colors
						const id: UserActivityStatusEnum = cell.getValue();
						const status = userActivityStatuses.find((item) => item.id === id);
						if (!status) {
							return '';
						}
						const element = cell.getElement();
						if (element) {
							if (status.id === UserActivityStatusEnum.Active) {
								element.style.color = '#87BC45';
							} else if (status.id === UserActivityStatusEnum.Inactive) {
								element.style.color = '#808080';
							} else {
								element.style.color = '#F44336';
							}

							element.style.fontWeight = 'bold';
						}
						return status.text;
					},
					fieldType: FieldTypeEnum.Options,
					options: userActivityStatuses,
					getItemId: (item: OptionType) => item.id,
					getItemText: (item: OptionType) => item.text
				},
				fullName: {
					title: 'Full name',
					formatter: (cell: any) => {
						const value = cell.getData() as UserModel;
						return `${value.firstName} ${value.lastName}`
					},
					fieldType: FieldTypeEnum.None
				},
				// filter type should be string, because field in form is string, because username is created in that form
				[propertyOf<UserModel>('username')]: {
					title: 'Username',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('firstName')]: {
					title: 'First name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('lastName')]: {
					title: 'Last name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('birthDate')]: {
					title: 'Birth date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<UserModel>('email')]: {
					title: 'Email',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('officePhone')]: {
					title: 'Office phone',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('mobilePhone')]: {
					title: 'Mobile phone',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('address')]: {
					title: 'Address',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('city')]: {
					title: 'City',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('countryId')]: {
					title: 'Country',
					formatter: (cell: any) => getCountry(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedCountry.items,
					getItemId: (option: IdNameResponse) => option.id,
					getItemText: (option: IdNameResponse) => option.name
				},
				[propertyOf<UserModel>('department')]: {
					title: 'Department',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('organizationalUnitId')]: {
					title: 'Organizational Unit',
					formatter: (cell: any) => getOrganizationalUnit(cell.getValue(), organizationalUnits),
					fieldType: FieldTypeEnum.MapOption,
					options: organizationalUnits
				},
				[propertyOf<UserModel>('partnerId')]: {
					title: 'Business Partner',
					formatter: (cell: any) => getPartner(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedBusinessPartner.items,
					getItemId: (option: BusinessPartnerResponseModel) => option.id,
					getItemText: (option: BusinessPartnerResponseModel) => option.name
				}
			}
		},
		[persistedCountry, persistedBusinessPartner, organizationalUnits, getPartner, getCountry, getOrganizationalUnit]
	)

	return useVisibleColumns(columns, visibleColumns);
}
