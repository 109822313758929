import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { Radio } from 'components/Form';
import { Export } from 'features/Export/Export';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { BaseColumnModel } from 'components/Table';

type Props = {
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	onShowTimesheetCostRateChange: (value: boolean) => void
	showTimesheetCostRates: boolean
	isLoading: boolean
	exportFunction(model: ExportDataModel): Promise<FileResponse>
}

const TableButtons = ({
	configureViewKey,
	tableColumns,
	onShowTimesheetCostRateChange,
	showTimesheetCostRates,
	isLoading,
	exportFunction
}: Props) => {
	return (
		<RowContainer justifyContent='space-between'>
			<Radio
				value={showTimesheetCostRates}
				disabled={isLoading}
				items={[
					{ id: true, text: 'Cost rate' },
					{ id: false, text: 'Billing rate' },
				]}
				onChange={onShowTimesheetCostRateChange}
			/>
			<RowContainer>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				<Export
					title='Time'
					filtersModel={new GenericFilterModelCollection()}
					tableColumns={tableColumns}
					exportTypeOptions={[ExportDataFileTypeEnum.XLSX, ExportDataFileTypeEnum.PDF]}
					exportAction={exportFunction}
					forceApplyFilters
					hideApplySort
					hideExportOnlyCurrentPage
				/>
			</RowContainer>
		</RowContainer>
	)
}

export default TableButtons;
