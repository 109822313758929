import { useMemo } from 'react';
import { TicketStatusDurationResponse, TicketStatusResponse, UserModel } from 'services/tenantManagementService';
import { useApplyStatusColorCallback, useStatusCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { minutesToDhm } from 'utils/dateTimeUtils';

const defaultVisibleColumns = [
	propertyOf<TicketStatusDurationResponse>('userId'),
	propertyOf<TicketStatusDurationResponse>('statusId'),
	propertyOf<TicketStatusDurationResponse>('duration'),
]

export const useTableColumnsMemo = (onHoldTotalDuration: number, configureViewKey: string) => {
	const {
		persistedUser,
		persistedTicketStatus,
		persistedConfigureView,
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedTicketStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedTicketStatus);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<TicketStatusDurationResponse>('userId')]: {
					title: 'User',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
					bottomCalc: () => 'Total'
				},
				[propertyOf<TicketStatusDurationResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) =>  {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketStatus.items,
					getItemId: (item: TicketStatusResponse) => item.id,
					getItemText: (item: TicketStatusResponse) => item.name,
					bottomCalc: () => 'On Hold'
				},
				[propertyOf<TicketStatusDurationResponse>('duration')]: {
					title: 'Duration',
					formatter: (cell: any) =>  {
						const duration = cell.getValue();
						return minutesToDhm(duration);
					},
					fieldType: FieldTypeEnum.Number,
					bottomCalc: () => onHoldTotalDuration,
					bottomCalcFormatter: (cell: any) => {
						const duration = cell.getValue();
						return minutesToDhm(duration);
					},
				}
			}
		},
		[getUserFullName, persistedUser, persistedTicketStatus, getStatus, applyStatusColor, onHoldTotalDuration]
	)

	return useVisibleColumns(columns, visibleColumns);
}
