import path from 'path'
import { CrudEnum } from "features/Crud";
import { RootRouteEnum } from "features/Routes";
import { Notification } from "../Notification/Notification"
import { NotificationComponentProps } from "../NotificationsPanel"
import { TicketNotificationAdditional } from "./types";
import { useFormattedIdUsernameAndTrigger } from './useFormattedIdUsernameAndTrigger';
import styles from '../notificationsPanel.module.scss'
import { EntityPrefixEnum } from 'utils/commonHelper';
import { AlertTriggersEnum } from 'services/tenantManagementService';
import { TicketsSubRoutesEnum } from 'containers/Tickets/routes';

export const TicketNotification = ({ notification }: NotificationComponentProps) => {
	// const Component = useMemo(
	// 		() => {
	// 		switch (notification.trigger) {
	// 			case AlertTriggersEnum.Created:
	// 			case AlertTriggersEnum.Updated:
	// 			case AlertTriggersEnum.Deleted:
	// 				return TicketTriggerNotification;
	// 			default:
	// 				return;
	// 		}
	// 	},
	// 	[notification]
	// )

	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as TicketNotificationAdditional : undefined

	const { formattedId, username, triggerText } = useFormattedIdUsernameAndTrigger(notification, EntityPrefixEnum.TICKET)

	const route = path.join('/', RootRouteEnum.TICKETS, TicketsSubRoutesEnum.TICKETS, CrudEnum.Read, String(notification.entityId));

	return (
		<Notification
			id={notification.id}
			route={notification.trigger === AlertTriggersEnum.Deleted ? undefined : route}
			content={
				<div>
					<span>Ticket: </span>
					<span className={styles.entity}>{formattedId} - {additionalData?.entityName} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}
