import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserModel, UpdateUserDataRequestModel, TokenTypeEnum } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import { getUserAction, updateUserAction } from '../action';
import { UserForm } from '../User/UserForm';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = {
	userId: string;
}

export const UpdateUser = ({ publishDataChanged }: UpdateComponentProps) => {
	const [user, setUser] = useState(new UserModel());
	const params: ParamType = useParams();
	const userId = parseInt(params.userId);
	const goBackFromUpdate = useGoBackFromUpdate();

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getUserAction.bind(null, userId);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setUser(response.value || new UserModel());
			}
		},
		[userId]
	)

	const saveCallback = useCallback(
		async (user: UserModel) => {
			const newUser = new UpdateUserDataRequestModel(user);

			const bindedAction = updateUserAction.bind(null, newUser);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`User ${response.value?.username} is updated.`);
				publishDataChanged();
				goBackFromUpdate()
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged]
	)

	const title = `Change User - ${user.username ? user.username : ''}`

	return (
		<ContentShell title={title}>
			<WithFetch fetchFunction={fetchDataCallback}>
				<UserForm
					user={user}
					crud={CrudEnum.Update}
					onSave={saveCallback}
					showPartner={user.roleId !== TokenTypeEnum.SiteAdmin}
				/>
			</WithFetch>
		</ContentShell>
	)
}
