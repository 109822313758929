import { TabType } from 'components/Tabs/Tabs';
import { TicketAssignedGroups } from './Tabs/TicketAssignedGroup/TicketAssignedGroups';
import { TicketCategory } from './Tabs/TicketCategory/TicketCategory';
import { TicketClosingIds } from './Tabs/TicketClosingId/TicketClosingIds';
import { TicketPriority } from './Tabs/TicketPriority/TicketPriority';
import { TicketTypes } from './Tabs/TicketType/TicketTypes';
import { TicketEnvironments } from './Tabs/TicketEnvironment/TicketEnvironments';

export const configureTicketsTabs: TabType[] = [
	{
		id: '1',
		title: 'Ticket type',
		route: 'type',
		component: TicketTypes,
	},
	{
		id: '2',
		title: 'Ticket category',
		route: 'map',
		component: TicketCategory,
	},
	{
		id: '3',
		title: 'Ticket priority',
		route: 'priority',
		component: TicketPriority,
	},
	{
		id: '4',
		title: 'Ticket closing ID',
		route: 'closing_id',
		component: TicketClosingIds,
	},
	{
		id: '5',
		title: 'Assigned groups',
		route: 'assigned_groups',
		component: TicketAssignedGroups,
	},
	{
		id: '6',
		title: 'Ticket environment',
		route: 'environment',
		component: TicketEnvironments,
	},
]
