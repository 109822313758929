import { getTicketClosingIdsAction, updateTicketClosingIdsAction } from './action';
import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';

export const TicketClosingIds = () => {
	return (
		<TenantIsActiveTable
			getConfigsAction={getTicketClosingIdsAction}
			updateConfigsAction={updateTicketClosingIdsAction}
			successMessage='Ticket closing ids are updated.'
			label={{ name: 'Closing ID' }}
		/>
	)
};
