import { useMemo } from 'react';
import { useApplyStatusColorCallback, useFormattedCurrencyValueCallback, useStatusCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { RiskStatusResponse, UserModel, ProjectRiskResponse, MitigationTypeEnum, ExportDataDecoraterPropertyModel, ProjectRiskCountsResponse } from 'services/tenantManagementService';
import { getCostBenefit, getNetImpact, getNetImpactAfterMitigation, getRiskInclusion } from 'containers/Risk/RiskPlan/helper';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { mitigationTypes } from '../RiskForm';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
	propertyOf<ProjectRiskResponse>('id'),
	propertyOf<ProjectRiskResponse>('consequneces'),
	propertyOf<ProjectRiskResponse>('grossImpact'),
	propertyOf<ProjectRiskResponse>('probability'),
	'netImpact',
	propertyOf<ProjectRiskResponse>('mitigation'),
	propertyOf<ProjectRiskResponse>('mitigationType'),
	propertyOf<ProjectRiskResponse>('costOfMitigation'),
	propertyOf<ProjectRiskResponse>('grossImpactAfterMitigation'),
	propertyOf<ProjectRiskResponse>('probabilityAfterMitigation'),
	'netImpactAfterMitigation',
	'costBenefitAnalysis',
	'riskForInclusionInReport',
	'responsibleMemberId',
	propertyOf<ProjectRiskResponse>('statusId')
]

export const useTableColumnsMemo = (
	countsResponse: ProjectRiskCountsResponse,
	visibleColumns: string[] = defaultVisibleColumns
) => {
	const {
		persistedUser,
		persistedRiskStatus
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedRiskStatus);
	const getFormattedCurrencyValue = useFormattedCurrencyValueCallback();
	const applyStatusColor = useApplyStatusColorCallback(persistedRiskStatus);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectRiskResponse>('visibleId')]: {
					title: 'Risk ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.RISK
				},
				[propertyOf<ProjectRiskResponse>('name')]: {
					title: 'Risk Name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectRiskResponse>('consequneces')]: {
					title: 'Consequences',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectRiskResponse>('effects')]: {
					title: 'Detailed Effectes',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectRiskResponse>('grossImpact')]: {
					title: 'Gross Impact',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: () => countsResponse.grossImpactSum
				},
				[propertyOf<ProjectRiskResponse>('probability')]: {
					title: 'Probability [%]',
					formatter: (cell: any) => {
						const value = cell.getValue();
						return value ? `${value}%` : '';
					},
					format: '{0} %',
					fieldType: FieldTypeEnum.Number
				},
				netImpact: {
					title: 'Net Impact',
					fieldType: FieldTypeEnum.Currency,
					formatter: (cell: any) => {
						// unfortunattely for version tabulator-tables 4.8.1 (now updated to 4.9.3 where this is solved, and bottomCalcFormatter is used),
						// after bottomCalc is called, formatter is called for that cell, and I didn't found better way to solve this issue
						// const row = cell.getRow();
						// if (row && row._row && row._row.type === 'calc') {
						// 	return cell.getValue();
						// }

						const values: ProjectRiskResponse = cell.getData();
						const netImpact = getNetImpact(values.grossImpact, values.probability);
						return getFormattedCurrencyValue(netImpact);
					},
					notInModel: true,
					disableFilter: true,
					disableSort: true,
					bottomCalc: () => countsResponse.netImpactSum
				},
				[propertyOf<ProjectRiskResponse>('mitigation')]: {
					title: 'Mitigation',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectRiskResponse>('mitigationType')]: {
					title: 'Mitigation on Type',
					fieldType: FieldTypeEnum.Options,
					options: mitigationTypes,
					getItemId: (option: MitigationTypeEnum) => option.valueOf(),
					getItemText: (option: MitigationTypeEnum) => option
				},
				[propertyOf<ProjectRiskResponse>('costOfMitigation')]: {
					title: 'Cost of Mitigation',
					dbFilterFieldPath: 'MitigationCost',
					dbExportFieldPath: 'MitigationCost',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: () => countsResponse.mitigationCostSum
				},
				[propertyOf<ProjectRiskResponse>('grossImpactAfterMitigation')]: {
					title: 'Gross Impact After Mitigation',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: () => countsResponse.grossImpactAfterMitigationSum
				},
				[propertyOf<ProjectRiskResponse>('probabilityAfterMitigation')]: {
					title: 'Probability After Mitigation',
					formatter: (cell: any) => {
						const value = cell.getValue();
						return value ? `${value}%` : '';
					},
					format: '{0} %',
					fieldType: FieldTypeEnum.Number
				},
				netImpactAfterMitigation: {
					title: 'Net Impact After Mitigation',
					formatter: (cell: any) => {
						const values: ProjectRiskResponse = cell.getData();
						const netImpact = getNetImpact(values.grossImpact, values.probability);
						const netImpactAfterMitigation = getNetImpactAfterMitigation(netImpact, values.mitigationType, values.grossImpactAfterMitigation, values.probabilityAfterMitigation, values.probability);
						return getFormattedCurrencyValue(netImpactAfterMitigation);
					},
					fieldType: FieldTypeEnum.Currency,
					notInModel: true,
					disableFilter: true,
					disableSort: true,
					bottomCalc: () => countsResponse.netImpactAfterMitigationSum
				},
				costBenefitAnalysis: {
					title: 'Cost/Benefit Analysis',
					formatter: (cell: any) => {
						const values: ProjectRiskResponse = cell.getData();
						const netImpact = getNetImpact(values.grossImpact, values.probability);
						const netImpactAfterMitigation = getNetImpactAfterMitigation(netImpact, values.mitigationType, values.grossImpactAfterMitigation, values.probabilityAfterMitigation, values.probability);
						const costBenefit = getCostBenefit(netImpact, values.mitigationType, values.costOfMitigation, netImpactAfterMitigation, values.grossImpact);
						return getFormattedCurrencyValue(costBenefit);
					},
					fieldType: FieldTypeEnum.Currency,
					notInModel: true,
					disableFilter: true,
					disableSort: true,
					bottomCalc: () => countsResponse.costBenefitSum
				},
				riskForInclusionInReport: {
					title: 'Risk For Inclusion In Report',
					formatter: (cell: any) => {
						const values: ProjectRiskResponse = cell.getData();
						const netImpact = getNetImpact(values.grossImpact, values.probability);
						const netImpactAfterMitigation = getNetImpactAfterMitigation(netImpact, values.mitigationType, values.grossImpactAfterMitigation, values.probabilityAfterMitigation, values.probability);
						const riskInclusion = getRiskInclusion(netImpact, values.mitigationType, values.costOfMitigation, netImpactAfterMitigation);
						return getFormattedCurrencyValue(riskInclusion);
					},
					fieldType: FieldTypeEnum.Currency,
					notInModel: true,
					disableFilter: true,
					disableSort: true,
					bottomCalc: () => countsResponse.riskInclusionSum
				},
				[propertyOf<ProjectRiskResponse>('responsibleUserId')]: {
					title: 'Responsible',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => getUserFullName(option.id)
				},
				[propertyOf<ProjectRiskResponse>('statusId')]: {
					title: 'Status/DI of mitigation',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					dbFilterFieldPath: 'StatusRefId',
					dbExportFieldPath: 'Status.Name',
					fieldType: FieldTypeEnum.Options,
					options: persistedRiskStatus.items,
					getItemId: (option: RiskStatusResponse) => option.id,
					getItemText: (option: RiskStatusResponse) => option.name,
					displayNames: ['Status'],
					decoraterProperties : [
						new ExportDataDecoraterPropertyModel({
							name: 'Name',
						})
					]
				},
				[propertyOf<ProjectRiskResponse>('description')]: {
					title: 'Comment',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectRiskResponse>('dueDate')]: {
					title: 'Date',
					fieldType: FieldTypeEnum.Date
				}
			}
		},
		[getUserFullName, getStatus, getFormattedCurrencyValue, applyStatusColor, persistedRiskStatus.items, countsResponse, persistedUser]
	)

	return useVisibleColumns(columns, visibleColumns);
}
