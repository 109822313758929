import { useApplyStatusColorCallback, useFormattedCurrencyValueCallback, useStatusCallback, useTenantIsActiveCallback, useTicketImpactsCallback, useTicketPriorityCallback, useTicketUrgenciesCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { useMemo } from 'react';
import { ChangeRequestFullResponse, ChangeRequestStatusEnum, ChangeRequestStatusResponse, TenantIsActiveResponse, UrgencyResponse, UserModel, ExportDataDecoraterPropertyModel, ChangeRequestCountsResponse, ImpactResponse } from 'services/tenantManagementService';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';
import { formatDate } from 'utils/dateTimeUtils';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
	propertyOf<ChangeRequestFullResponse>('id'),
	propertyOf<ChangeRequestFullResponse>('name'),
	propertyOf<ChangeRequestFullResponse>('initiatedBy'),
	propertyOf<ChangeRequestFullResponse>('requestedOn'),
	propertyOf<ChangeRequestFullResponse>('goLiveCritical'),
	'priority',
	propertyOf<ChangeRequestFullResponse>('expectedCompletionDate'),
	propertyOf<ChangeRequestFullResponse>('statusId'),
	'totalCost'
]

export const useTableColumnsMemo = (
	countsResponse: ChangeRequestCountsResponse,
	visibleColumns: string[] = defaultVisibleColumns
) => {
	const {
		persistedUser,
		persistedChangeRequestType,
		persistedTicketImpact,
		persistedTicketUrgency,
		persistedTicketPriority,
		persistedChangeRequestStatus,
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getType = useTenantIsActiveCallback(persistedChangeRequestType);
	const getImpact = useTicketImpactsCallback(persistedTicketImpact);
	const getUrgency = useTicketUrgenciesCallback(persistedTicketUrgency);
	const getPriority = useTicketPriorityCallback(persistedTicketPriority);
	const getStatus = useStatusCallback(persistedChangeRequestStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedChangeRequestStatus);
	const getFormattedCurrencyValue = useFormattedCurrencyValueCallback();

	const columns: TableColumnsType = useMemo(
		() => {
			return {
			 	[propertyOf<ChangeRequestFullResponse>('id')]: {
					title: 'CR ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.CHANGE_REQUEST
				},
				[propertyOf<ChangeRequestFullResponse>('name')]: {
					title: 'CR name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ChangeRequestFullResponse>('initiatedBy')]: {
					title: 'Initiated by',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ChangeRequestFullResponse>('requestedOn')]: {
					title: 'Requested on',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ChangeRequestFullResponse>('typeId')]: {
					title: 'Type',
					formatter: (cell: any) => getType(cell.getValue()),
					dbFilterFieldPath: 'TypeRefId',
					dbExportFieldPath: 'TypeRefId',
					fieldType: FieldTypeEnum.Options,
					options: persistedChangeRequestType.items,
					getItemId: (option: TenantIsActiveResponse) => option.id,
					getItemText: (option: TenantIsActiveResponse) => option.name
				},
				[propertyOf<ChangeRequestFullResponse>('goLiveCritical')]: {
					title: 'Go-live critical',
					formatter: (cell: any) => {
						const goLiveCritical: boolean = cell.getValue();
						return goLiveCritical ? 'yes' : 'no';
					},
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<ChangeRequestFullResponse>('impactId')]: {
					title: 'Impact',
					formatter: (cell: any) => getImpact(cell.getValue()),
					dbFilterFieldPath: 'ImpactRefId',
					dbExportFieldPath: 'ImpactRefId',
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketImpact.items,
					getItemId: (option: ImpactResponse) => option.id,
					getItemText: (option: ImpactResponse) => option.name
				},
				[propertyOf<ChangeRequestFullResponse>('urgencyId')]: {
					title: 'Urgency',
					formatter: (cell: any) => getUrgency(cell.getValue()),
					dbFilterFieldPath: 'UrgencyRefId',
					dbExportFieldPath: 'UrgencyRefId',
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketUrgency.items,
					getItemId: (option: UrgencyResponse) => option.id,
					getItemText: (option: UrgencyResponse) => option.name
				},
				priority: {
					title: 'Priority',
					formatter: (cell: any) => {
						const model: ChangeRequestFullResponse = cell.getData();
						return getPriority(model.impactId, model.urgencyId);
					},
					fieldType: FieldTypeEnum.None,
					notInModel: true
				},
				[propertyOf<ChangeRequestFullResponse>('expectedCompletionDate')]: {
					title: 'Expected completion date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ChangeRequestFullResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					dbFilterFieldPath: 'StatusRefId',
					dbExportFieldPath: 'StatusRefId',
					fieldType: FieldTypeEnum.Options,
					options: persistedChangeRequestStatus.items,
					getItemId: (option: ChangeRequestStatusResponse) => option.id,
					getItemText: (option: ChangeRequestStatusResponse) => option.name
				},
				[propertyOf<ChangeRequestFullResponse>('estimatedByUserId')]: {
					title: 'Estimated by',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
					decoraterProperties : [
						new ExportDataDecoraterPropertyModel({
							name: 'Username',
						})
					]
				},
				[propertyOf<ChangeRequestFullResponse>('estimatedOn')]: {
					title: 'Estimated on',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ChangeRequestFullResponse>('completionOn')]: {
					title: 'Completion on',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ChangeRequestFullResponse>('approvedOrRejectedByUserId')]: {
					title: 'Approved by',
					formatter: (cell: any) => {
						const model: ChangeRequestFullResponse = cell.getData();
						if (isStatusBySemantic(ChangeRequestStatusEnum.RejectedForRealization, model.statusId, persistedChangeRequestStatus.itemsMap)) {
							return '';
						}

						return getUserFullName(cell.getValue())
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
					decoraterProperties : [
						new ExportDataDecoraterPropertyModel({
							name: 'Username',
						})
					]
				},
				[propertyOf<ChangeRequestFullResponse>('approvalActionOn')]: {
					title: 'Approved on',
					formatter: (cell: any) => {
						const model: ChangeRequestFullResponse = cell.getData();
						if (isStatusBySemantic(ChangeRequestStatusEnum.RejectedForRealization, model.statusId, persistedChangeRequestStatus.itemsMap)) {
							return '';
						}

						return formatDate(cell.getValue())
					},
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ChangeRequestFullResponse>('acceptedByUserId')]: {
					title: 'Accepted by',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
					decoraterProperties : [
						new ExportDataDecoraterPropertyModel({
							name: 'Username'
						})
					]
				},
				[propertyOf<ChangeRequestFullResponse>('acceptedOn')]: {
					title: 'Accepted on',
					fieldType: FieldTypeEnum.Date
				},
				effort: {
					title: 'Effort (in hours)',
					formatter: (cell: any) => {
						const model: ChangeRequestFullResponse = cell.getData();
						let effort = 0;
						for (const activity of model.activities || []) {
							effort += activity.effortInHours;
						}

						return String(effort);
					},
					fieldType: FieldTypeEnum.None,
					notInModel: true,
					bottomCalc: () => countsResponse.totalEffortSum,
					bottomCalcFormatter: (cell: any) => `∑ ${cell.getValue() || ''}`
				},
				totalCost: {
					title: 'Total cost',
					formatter: (cell: any) => {
						const model: ChangeRequestFullResponse = cell.getData();
						let totalCost = 0;
						for (const activity of model.activities || []) {
							totalCost += activity.effortInHours * activity.hourPrice;
						}

						return getFormattedCurrencyValue(totalCost);
					},
					fieldType: FieldTypeEnum.Currency,
					notInModel: true,
					disableFilter: true,
					disableSort: true,
					bottomCalc: () => countsResponse.totalCostSum
				}
			}
		},
		[
			persistedChangeRequestStatus,
			persistedChangeRequestType,
			persistedTicketImpact,
			persistedTicketUrgency,
			persistedUser,
			getImpact,
			getUrgency,
			getUserFullName,
			getType,
			getPriority,
			getStatus,
			getFormattedCurrencyValue,
			applyStatusColor,
			countsResponse
		]
	)

	return useVisibleColumns(columns, visibleColumns);
}
