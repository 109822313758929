import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StatusReportResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum } from 'features/Crud';
import { ColumnContainer } from 'components/Layout';
import { getStatusReportAction } from '../action';
import { StatusReportForm } from './StatusReportForm';
import { Export } from '../Export/Export';
import { ProjectOrCategoryPickerParams } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = ProjectOrCategoryPickerParams & {
	statusReportId: string
}

export const ReadStatusReport = () => {
	const params: ParamType = useParams();
	const projectOrCategoryId = parseInt(params.projectOrCategoryId as string);
	const isProjectConnected = params.isProjectConnected === 'true';
	const statusReportId = parseInt(params.statusReportId as string);

	const [statusReport, setStatusReport] = useState(new StatusReportResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getStatusReportAction.bind(null, statusReportId, projectOrCategoryId, isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setStatusReport(response.value || new StatusReportResponse());
			}
		},
		[projectOrCategoryId, isProjectConnected, statusReportId]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`View status report - ${getFormatedId(EntityPrefixEnum.STATUS_REPORT, statusReport.id)}`}>
				<ColumnContainer margin='medium'>
					<Export statusReport={statusReport} />
					<StatusReportForm
						statusReport={statusReport}
						crud={CrudEnum.Read}
					/>
				</ColumnContainer>
			</ContentShell>
		</WithFetch>
	)
}
