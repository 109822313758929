import { getTenantId } from 'features/Tenant/tenantUtils';
import { ExportDataModel, GenericFilterModelCollection, MeetingDecisionClient } from 'services/tenantManagementService';

export const getAllMeetingDecisionsGenericFilterAction = (projectOrCategoryId: number, isProjectConnected: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new MeetingDecisionClient();
	return client.getAllGenericFilter(tenantId, projectOrCategoryId, isProjectConnected, genericFilter);
}

export const exportMeetingDecisionsAction = (projectOrCategoryId: number, isProjectConnected: boolean, model: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new MeetingDecisionClient();
	return client.exportAll(tenantId, projectOrCategoryId, isProjectConnected, model)
}
