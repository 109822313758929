import { getMeetingMinutesStatusesAction, updateMeetingMinutesStatusesAction } from './action';
import { StatusResponseTable } from 'features/StatusResponse/StatusResponseTable';
import { MeetingMinutestatusesHelp } from './MeetingMinutesStatusesHelp';
import { ContentShell } from 'features/Content/ContentShell';

export const MeetingMinutes = () => {
	return (
		<ContentShell
			InlineHelpComponent={MeetingMinutestatusesHelp}
			showBreadcrumb={false}
		>
			<StatusResponseTable
				getConfigsAction={getMeetingMinutesStatusesAction}
				updateConfigsAction={updateMeetingMinutesStatusesAction}
			/>
		</ContentShell>
	)
}
