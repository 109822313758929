import { DivergentChart, DivergentChartProps } from "features/Charts"
import { Widget, WidgetProps } from "./Widget"

type Props = WidgetProps & DivergentChartProps;

export const DivergentWidget = (props: Props) => {
	return (
		<Widget
			{...props}
		>
			<DivergentChart {...props} />
		</Widget>
	)
}
