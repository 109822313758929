import { useCallback, useState } from "react"
import { Form, OptionType, SelectField } from "components/Form"
import notifications from "components/Notification/notification";
import { SmartContainer, SmartItem } from "components/SmartContainer/SmartContainer";
import WithFetch from "features/Fetch/WithFetch";
import { FinancePlaningIntervalEnum, UpdateFinanceGeneralConfigRequest, FinanceGeneralConfigResponse } from "services/tenantManagementService"
import { convertResponseErrors, tryCatchJsonByAction } from "utils/fetchUtils";
import { propertyOf } from 'utils/propertyOf';
import { getPlanningIntervalsAction, updatePlanningIntervalsAction } from "./action";
import { ContentShell } from "features/Content/ContentShell";
import { PlanningIntervalsHelp } from "./PlanningIntervalsHelp";

const planningIntervalsArray: OptionType[] = [
	{ id: FinancePlaningIntervalEnum.Weekly, text: 'Weekly' },
	{ id: FinancePlaningIntervalEnum.Monthly, text: 'Monthly' },
	{ id: FinancePlaningIntervalEnum.Quarterly, text: 'Quarterly' },
	{ id: FinancePlaningIntervalEnum.Yearly, text: 'Yearly' }
]

export const PlanningIntervals = () => {
	const [values, setValues] = useState(new FinanceGeneralConfigResponse());
	const [initialValues, setInitialValues] = useState(new FinanceGeneralConfigResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getPlanningIntervalsAction);
			if (response.success) {
				const value = response.value || new FinanceGeneralConfigResponse();
				setInitialValues(value);
				setValues(value);
			}
		},
		[]
	)

	const cancelCallback = useCallback(
		() => setValues(initialValues),
		[initialValues]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new UpdateFinanceGeneralConfigRequest(values);
			const bindedAction = updatePlanningIntervalsAction.bind(null, model)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Finance planning intervals is updated');
				setInitialValues(values);
			} else {
				return convertResponseErrors(response);
			}
		},
		[values]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell
				InlineHelpComponent={PlanningIntervalsHelp}
				showBreadcrumb={false}
			>
				<Form
					values={values}
					initialValues={initialValues}
					onChange={setValues}
					onSubmit={onSubmitCallback}
					onCancel={cancelCallback}
					render={() => (
						<SmartContainer>
							<SmartItem size='small'>
								<SelectField
									id={propertyOf<FinanceGeneralConfigResponse>('planingInterval')}
									label='Planning intervals'
									isRequired
									items={planningIntervalsArray}
									getItemId={(item: OptionType) => item.id}
									getItemText={(item: OptionType) => item.text}
								/>
							</SmartItem>
						</SmartContainer>
					)}
				/>
			</ContentShell>
		</WithFetch>
	)
}
