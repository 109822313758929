import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeleteTenantIndetifyRequest, InsertExpenseItemRequest, InsertExpenseItemRequestUpdateExpenseItemRequestDeleteTenantIndetifyRequestDeltaModel, ExpenseItemResponse, ExpenseResponse, UpdateExpenseItemRequest, UpdateExpenseRequest } from 'services/tenantManagementService';
import { getExpenseAction,  updateExpenseAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { createDelta, EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import ExpenseForm from './ExpenseForm';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = {
	expenseId: string
}

const UpdateMyExpense = ({ publishDataChanged }: UpdateComponentProps) => {
	const params: ParamType = useParams();
	const [expense, setExpense] = useState(new ExpenseResponse());
	const goBackFromUpdate = useGoBackFromUpdate();

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getExpenseAction.bind(null, parseInt(params.expenseId));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {

				setExpense(response.value || new ExpenseResponse());
			}
		},
		[params.expenseId]
	)

	const saveCallback = useCallback(
		async (newtExpense: ExpenseResponse, isRelease: boolean, initialExpense: ExpenseResponse) => {

			const expenseItemsDelta: InsertExpenseItemRequestUpdateExpenseItemRequestDeleteTenantIndetifyRequestDeltaModel = createDelta<ExpenseItemResponse>
			(
				initialExpense.expenseItems || [],
				newtExpense.expenseItems || [],
				InsertExpenseItemRequest,
				UpdateExpenseItemRequest,
				InsertExpenseItemRequestUpdateExpenseItemRequestDeleteTenantIndetifyRequestDeltaModel,
				DeleteTenantIndetifyRequest
			);

			// fix removed attachments
			expenseItemsDelta.update = expenseItemsDelta.update?.map(tei => {
				const initialAttachementIds = initialExpense.expenseItems?.find(t => t.id === tei.id)?.attachments?.map(att => att.id);
				const currentAttachmentIds = newtExpense.expenseItems?.find(t => t.id === tei.id)?.attachments?.map(att => att.id);
				return new UpdateExpenseItemRequest({
					...tei,
					removedAttachments: initialAttachementIds?.filter(att => !currentAttachmentIds?.includes(att)),
				})
			});

			const expenseForUpdate = new UpdateExpenseRequest({
				...newtExpense,
				isRelease,
				expenseItemsDelta
			});

			const bindedAction = updateExpenseAction.bind(null, expenseForUpdate);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, response.value?.id);
				notifications.success(`Expense ${id} is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`Change expense - ${getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, expense.id)}`}>
				<ExpenseForm
					expense={expense}
					crud={CrudEnum.Update}
					onSave={saveCallback}
				/>
			</ContentShell>
		</WithFetch>
	)
}

export default UpdateMyExpense;
