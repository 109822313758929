import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { ExportModel } from 'features/Export/ExportForm';
import ExportToFile from 'features/Export/ExportToFile';
import { ExportDataFileTypeEnum, ShowTestStatusEnum } from 'services/tenantManagementService';
import { BaseColumnModel } from 'components/Table';
import { ButtonGroup } from 'components/ButtonGroup';
import Button from 'components/Button/Button';
import { useCallback, useState } from 'react';
import { Dialog } from 'components/Dialog';
import { ViewTestCase } from './ViewTestCase';

const showTestStatusItems = [
	{ id: ShowTestStatusEnum.ShowTestStatus, text: 'Show test status' },
	{ id: ShowTestStatusEnum.ShowProcessSteps, text: 'Show test status with process steps' },
	{ id: ShowTestStatusEnum.ShowMultipleTickets, text: 'Show tests with multiple tickets' }
]

type Props = {
	selectedId: number
	projectId: number
	testCycleId: number
	selectedShowTestStatusItems: ShowTestStatusEnum[]
	setSelectedShowTestStatusItems(enums: ShowTestStatusEnum[]): void
	onExport: (exportModel: ExportModel) => void
	loadingExport?: boolean
	configureViewKey: string
    tableColumns: BaseColumnModel[]
}

const TableButtons = ({
	selectedId,
	projectId,
	testCycleId,
	selectedShowTestStatusItems,
	setSelectedShowTestStatusItems,
	onExport,
	configureViewKey,
	tableColumns,
	loadingExport
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const openModalCallback = useCallback(
		() => setIsOpen(true),
		[]
	)

	const cancelCallback = useCallback(
		() => setIsOpen(false),
		[]
	)

	return (
		<RowContainer justifyContent='space-between'>
			<Button
				text='View test case'
				onClick={openModalCallback}
				disabled={!selectedId}
			/>
			<Dialog
				title='View selected test case'
				open={isOpen}
				onClose={cancelCallback}
				size='xxxlarge'
			>
				<ViewTestCase
					projectId={projectId}
					testCycleId={testCycleId}
					testPlanId={selectedId}
				/>
			</Dialog>
			<RowContainer>
				<ButtonGroup
					items={showTestStatusItems}
					onChange={setSelectedShowTestStatusItems}
					selectedIds={selectedShowTestStatusItems}
					single
				/>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				<ExportToFile onExport={onExport} loading={loadingExport} exportTypeOptions={[ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX]} />
			</RowContainer>
		</RowContainer>
	)
}

export default TableButtons;
