import { CrudSubRoutesEnum } from 'features/Crud';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import TakeSurvey from './Crud/TakeSurvey';
import ViewSurvey from './Crud/ViewSurvey';
import MySurveys from './MySurveys';

const MySurveysRouter = () => {
	let { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}/:projectId/${CrudSubRoutesEnum.Update}/:surveyId`} component={TakeSurvey} />
			<Route path={`${path}/:projectId/${CrudSubRoutesEnum.Read}/:surveyId`} component={ViewSurvey} />

			<Route path={`${path}/:projectId`} component={MySurveys} />
			<Route path={path} component={MySurveys} />
		</Switch>
	)
}

export default MySurveysRouter;
