import { CalendarIcon, SettingsIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";
import { SiteConfigurationSubRoutesEnum } from "../enums";

const generalRouteUrl = `/${RootRouteEnum.SITE_CONFIGURATION}/${SiteConfigurationSubRoutesEnum.GENERAL}`

// subroutes

export enum GeneralSubRoutesEnum {
	SITE_SETTINGS = 'site_settings',
	STATUSES = 'statuses',
	COMPANY = 'general_company',
	CALENDAR = 'general_calendar',
}

export const siteSettingsSubRoute: Route = {
	id: GeneralSubRoutesEnum.SITE_SETTINGS,
	url: `${generalRouteUrl}/${GeneralSubRoutesEnum.SITE_SETTINGS}`,
	text: 'Site settings',
	Icon: SettingsIcon
}

export const statusesSubRoute: Route = {
	id: GeneralSubRoutesEnum.STATUSES,
	url: `${generalRouteUrl}/${GeneralSubRoutesEnum.STATUSES}`,
	text: 'Statuses',
	Icon: SettingsIcon
}

export const companySubRoute: Route = {
	id: GeneralSubRoutesEnum.COMPANY,
	url: `${generalRouteUrl}/${GeneralSubRoutesEnum.COMPANY}`,
	text: 'Company',
	Icon: SettingsIcon
}

export const calendarSubRoute: Route = {
	id: GeneralSubRoutesEnum.CALENDAR,
	url: `${generalRouteUrl}/${GeneralSubRoutesEnum.CALENDAR}`,
	text: 'Calendar',
	Icon: CalendarIcon
}

// root route

export const generalRoute: Route = {
	id: SiteConfigurationSubRoutesEnum.GENERAL,
	url: generalRouteUrl,
	text: 'General',
	children: [
		siteSettingsSubRoute,
		statusesSubRoute,
		companySubRoute,
		calendarSubRoute
	]
}
