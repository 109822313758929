import { updateChangeRequestAcceptanceAction } from 'containers/Scope/ChangeRequests/action';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChangeRequestAcceptanceRequest, ChangeRequestFullResponse, ChangeRequestStatusEnum, UserModel } from 'services/tenantManagementService';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { AutoCompleteField, DateField, Form, TextareaField } from 'components/Form';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { propertyOf } from 'utils/propertyOf';
import { RootState } from 'base/reducer/reducer';
import { getUserInfo } from 'utils/storageUtils';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { TabProps } from '../../CreateChangeRequest';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { ExportAcceptance } from 'containers/Scope/ChangeRequests/Export/ExportAcceptance';

type Props = TabProps;

const Acceptance = ({ projectId, changeRequest, crud, publishDataChanged }: Props) => {
	const {
		persistedUser,
		persistedChangeRequestStatus,
		persistedProject
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(new ChangeRequestFullResponse(changeRequest));
	const [initialValues, setInitialValues] = useState(new ChangeRequestFullResponse(changeRequest));

	const goBackFromCrud = useGoBackFromCrud(crud, false, true);

	useEffect(
		() => {
			const userInfo = getUserInfo();

			setValues((state: ChangeRequestFullResponse) => {
				return new ChangeRequestFullResponse({
					...state,
					acceptedByUserId: userInfo.id
				})
			})

			setInitialValues((state: ChangeRequestFullResponse) => {
				return new ChangeRequestFullResponse({
					...state,
					acceptedByUserId: userInfo.id
				})
			})
		},
		[]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new ChangeRequestAcceptanceRequest(values);
			const bindedAction = updateChangeRequestAcceptanceAction.bind(null, projectId, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, response.value?.id);
				notifications.success(`Change request ${id} acceptance is updated.`);
				publishDataChanged();
				goBackFromCrud();
			} else {
				return convertResponseErrors(response);
			}
		},
		[values, goBackFromCrud, projectId, publishDataChanged]
	)

	const disabledMemo = useMemo(
		() => crud !== CrudEnum.Update
		|| !isStatusBySemantic(ChangeRequestStatusEnum.ReleasedForAcceptance, values.statusId, persistedChangeRequestStatus.itemsMap)
		|| (!(isUserPmorSubstitutePmOrSiteAdmin(persistedProject.itemsMap[projectId]?.roleId) || values.acceptanceUserId === getUserInfo().id)),
		[crud, persistedChangeRequestStatus.itemsMap, persistedProject.itemsMap, values.acceptanceUserId, values.statusId, projectId]
	)

	return (
		<Form
			values={values}
			initialValues={initialValues}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			disabled={disabledMemo}
			submitButtonText='Accept'
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<AutoCompleteField
								id={propertyOf<ChangeRequestFullResponse>('acceptedByUserId')}
								label='Accepted by'
								items={persistedUser.items}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								loading={persistedUser.fetching}
								disabled
							/>
							<DateField
								id={propertyOf<ChangeRequestFullResponse>('acceptedOn')}
								label='Accepted on'
							/>
							<TextareaField
								id={propertyOf<ChangeRequestFullResponse>('comment')}
								label='Comment'
							/>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
			renderAdditionalButtons={() => (
				<RowContainer>
					<ExportAcceptance
						changeRequest={changeRequest}
						projectId={projectId}
					/>
				</RowContainer>
			)}
		/>
	)
}

export default Acceptance;
