import { HelpShell } from 'features/Help/HelpShell';

export const TestCyclesHelp = () => {
	return (
		<HelpShell title='Test Cycles'>
			<p>Before testing can begin, project manager needs to configure test cycles.<br />
				For example: unit test, integration test, user acceptance test, unit test company A, unit test, company B, development tests,
				authorization test etc.
			</p>
			<p>For each test cycle you can define test scope and test plan.
			</p>
			<p>In order to release test plan for users you need to mark test cycle as 'active'.<br />
				Test plans will not show in user's test package if not marked as active.
			</p>
		</HelpShell>
	)
}
