import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getRouteObject, RouterProps } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { CrudRouter } from 'features/Crud';
import Tasks from './Tasks/Tasks';
import { CreateTask } from './Tasks/Crud/CreateTask';
import { ReadTask } from './Tasks/Crud/ReadTask';
import { UpdateTask } from './Tasks/Crud/UpdateTask';
import ContactList from './ContactList/ContactList';
import Reminders from './Reminders/Reminders';
import { CreateReminder } from './Reminders/Crud/CreateReminder';
import { UpdateReminder } from './Reminders/Crud/UpdateReminder';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { persistBusinessPartnersAction } from 'containers/Configuration/SiteConfiguration/Projects/CreateNewPartner/action';
import { persistUserStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Users/action';
import { persistCommunicationStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Communication/action';
import { StatusReportsRouter } from './StatusReports/StatusReportsRouter';
import { persistTaskStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Tasks/action';
import Meetings from './Meetings/Meetings';
import { CreateMeeting } from './Meetings/Crud/CreateMeeting';
import { ReadMeeting } from './Meetings/Crud/ReadMeeting';
import { UpdateMeeting } from './Meetings/Crud/UpdateMeeting';
import { persistMeetingStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/MeetingMinutes/action';
import { persistMeetingTypesAction } from 'containers/Configuration/SiteConfiguration/Module/Communication/Tabs/MeetingType/action';
import Decisions from './Decisions/Decisions';
import { CommunicationDashboard } from './Dashboard/CommunicationDashboard';
import { Calendar } from './Calendar/Calendar';
import { persistNonProjectCategoriesAction } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/NonProjectCategory/action';
import { persistTimeAndTravelStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/TimeAndTravel/action';
import { IsProjectConnectedParamName, ProjectOrCategoryParamName } from 'features/Project';
import { CommunicationSubRoutesEnum, calendarSubRoute, contactListSubRoute, myDashboardSubRoute, projectDecisionsSubRoute, projectMeetingsSubRoute, remindersSubRoute, statusReportsSubRoute, tasksSubRoute } from './routes';
import { persistOrganizationalUnitsAction } from 'containers/Configuration/SiteConfiguration/General/Company/OrganizationalUnits/action';

export const CommunicationRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistUserStatusesAction();
			persistTimeAndTravelStatusesAction();
			persistBusinessPartnersAction();
			persistCommunicationStatusesAction();
			persistTaskStatusesAction();
			persistMeetingStatusesAction();
			persistMeetingTypesAction();
			persistNonProjectCategoriesAction();
			persistOrganizationalUnitsAction();
		},
		[]
	)
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[CommunicationSubRoutesEnum.MY_DASHBOARD] &&
				<Route
					path={myDashboardSubRoute.url}
					render={() => <CommunicationDashboard />}
				/>
			}
			{routesMap[CommunicationSubRoutesEnum.CONTACT_LIST] &&
				<Route
					path={contactListSubRoute.url}
					render={() => <ContactList />}
				/>
			}
			{routesMap[CommunicationSubRoutesEnum.CALENDAR] &&
				<Route
					path={calendarSubRoute.url}
					render={() => <Calendar />}
				/>
			}
			{routesMap[CommunicationSubRoutesEnum.TASKS] &&
				<Route
					path={tasksSubRoute.url}
					render={() => (
						<CrudRouter
							ListComponent={Tasks}
							CreateComponent={CreateTask}
							ReadComponent={ReadTask}
							UpdateComponent={UpdateTask}
							paramName='taskId'
						/>
					)}
				/>
			}
			{routesMap[CommunicationSubRoutesEnum.PROJECT_MEETINGS] &&
				<Route
					path={[
						`${projectMeetingsSubRoute.url}/:${ProjectOrCategoryParamName}/:${IsProjectConnectedParamName}`,
						projectMeetingsSubRoute.url
					]}
					render={() => (
						<CrudRouter
							ListComponent={Meetings}
							CreateComponent={CreateMeeting}
							ReadComponent={ReadMeeting}
							UpdateComponent={UpdateMeeting}
							paramName='meetingId'
						/>
					)}
				/>
			}
			{routesMap[CommunicationSubRoutesEnum.PROJECT_DECISIONS] &&
				<Route
					path={[
						`${projectDecisionsSubRoute.url}/:${ProjectOrCategoryParamName}/:${IsProjectConnectedParamName}`,
						projectDecisionsSubRoute.url
					]}
					render={() => <Decisions />}
				/>
			}
			{routesMap[CommunicationSubRoutesEnum.STATUS_REPORTS] &&
				<Route
					path={[
						`${statusReportsSubRoute.url}/:${ProjectOrCategoryParamName}/:${IsProjectConnectedParamName}`,
						statusReportsSubRoute.url,
					]}
					render={() => <StatusReportsRouter />}
				/>
			}
			{routesMap[CommunicationSubRoutesEnum.REMINDERS] &&
				<Route
					path={[
						`${remindersSubRoute.url}/:${ProjectOrCategoryParamName}/:${IsProjectConnectedParamName}`,
						remindersSubRoute.url,
					]}
					render={() => (
						<CrudRouter
							ListComponent={Reminders}
							CreateComponent={CreateReminder}
							UpdateComponent={UpdateReminder}
							paramName='reminderId'
						/>
					)}
				/>
			}

			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
