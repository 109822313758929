import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'base/reducer/reducer'
import { AutoCompleteField, DateField, Form, InputField, TextareaField } from 'components/Form'
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer'
import { CrudEnum } from 'features/Crud'
import { MitigationTypeEnum, ModuleActivityEnum, RiskImpactEnum, RiskProbabilityEnum, RiskResponse, RiskStatusResponse, UserModel } from 'services/tenantManagementService'
import { propertyOf } from 'utils/propertyOf'
import { ProjectSelect } from 'features/Project'

export const mitigationTypes = [
	MitigationTypeEnum.None,
	MitigationTypeEnum.Corrective,
	MitigationTypeEnum.Preventive
]

type Props = {
	risk?: RiskResponse
	projectId?: number
	crud: CrudEnum
	onSave?: (newRisk: RiskResponse) => void
	cancel?(): void
}

export const ReducedRiskForm = ({
	risk,
	projectId,
	crud,
	onSave,
	cancel
}: Props) => {
	const {
		persistedUser,
		persistedRiskStatus,
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(risk || new RiskResponse());

	const onSubmitCallback = useCallback(
		async () => await onSave!(values),
		[onSave, values]
	)

	const isRead = crud === CrudEnum.Read;

	return (
		<Form
			values={values}
			initialValues={risk}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			disabled={isRead}
			hideButtons={isRead}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectSelect
							value={projectId}
							disabled
							showCompleted
							moduleEnum={ModuleActivityEnum.Risk}
						/>
						<InputField
							id={propertyOf<RiskResponse>('name')}
							label='Risk name'
							isRequired
						/>
						<AutoCompleteField
							id={propertyOf<RiskResponse>('responsibleUserId')}
							label='Responsible'
							items={persistedUser.items}
							getItemId={(item: UserModel) => item.id}
							getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
							getItemDescription={() => 'Person responsible for managing that risk'}
							loading={persistedUser.fetching}
							isRequired
							sort
						/>
						<AutoCompleteField
							id={propertyOf<RiskResponse>('statusId')}
							label='Mitigation status'
							items={persistedRiskStatus.items}
							getItemId={(item: RiskStatusResponse) => item.id}
							getItemText={(item: RiskStatusResponse) => item.name}
							getItemDescription={(item: RiskStatusResponse) => item.description}
							isRequired
							loading={persistedRiskStatus.fetching}
						/>
						<AutoCompleteField
							id={propertyOf<RiskResponse>('probabilityDescriptive')}
							label='Probability (descriptive)'
							items={[RiskProbabilityEnum.Low, RiskProbabilityEnum.Medium, RiskProbabilityEnum.High]}
							getItemId={(item: RiskProbabilityEnum) => item}
							getItemText={(item: RiskProbabilityEnum) => item}
						/>
						<AutoCompleteField
							id={propertyOf<RiskResponse>('impactDescriptive')}
							label='Impact (descriptive)'
							items={[RiskImpactEnum.Low, RiskImpactEnum.Medium, RiskImpactEnum.High]}
							getItemId={(item: RiskImpactEnum) => item}
							getItemText={(item: RiskImpactEnum) => item}
						/>
						<DateField
							id={propertyOf<RiskResponse>('dueDate')}
							label='Due date'
							isRequired
						/>
						<TextareaField
							id={propertyOf<RiskResponse>('comment')}
							label='Comment'
							rows={4}
						/>
					</SmartItem>
					<SmartItem>
						<TextareaField
							id={propertyOf<RiskResponse>('mitigation')}
							label='Mitigation'
							explanation='Options or tasks to mitigate the risk'
						/>
						<AutoCompleteField
							id={propertyOf<RiskResponse>('mitigationType')}
							label='Mitigation type'
							items={mitigationTypes}
							getItemId={(item: MitigationTypeEnum) => item}
							getItemText={(item: MitigationTypeEnum) => item}
							getItemDescription={(item: MitigationTypeEnum) => 'Type of mitigation action choose, i.e. none, preventive, corrective.'}
							isRequired
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
