import { TabType } from 'components/Tabs/Tabs';
import { Closing } from '../Closing/Closing';
import { Conversation } from '../Conversation/Conversation';
import { Effort } from '../Effort/Effort';
import { SLA } from '../SLA/SLA';
import { History } from '../History/History';

export const tabs: TabType[] = [
	{
		id: '1',
		title: 'Conversation',
		route: 'conversation',
		component: Conversation
	},
	{
		id: '2',
		title: 'Closing',
		route: 'closing',
		component: Closing
	},
	{
		id: '3',
		title: 'Effort',
		route: 'effort',
		component: Effort
	},
	{
		id: '4',
		title: 'SLA',
		route: 'sla',
		component: SLA
	},
	{
		id: '5',
		title: 'History',
		route: 'history',
		component: History
	}
]
