import { Radio, MultiCheckbox, Textarea } from 'components/Form';
import { MySurveyQuestionResponse, SurveyQuestionTypeEnum } from 'services/tenantManagementService';
import { ColumnContainer } from 'components/Layout';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { SURVEY_RATING_OPTIONS } from '../mySurveyConstants';

type ViewSurveyQuestionsProps = {
	surveyQuestions: MySurveyQuestionResponse[]
	disabled?: boolean
	save?(answers: {}): void
	cancel?(): void
}

const ViewSurveyQuestions = ({ surveyQuestions }: ViewSurveyQuestionsProps) => {
	return (
		<ColumnContainer>
			<SmartContainer>
				<SmartItem>
					{
						surveyQuestions.length > 0 && surveyQuestions.map((question: MySurveyQuestionResponse, index: number) => {
							return (
								<SmartFormGroup key={`${question.id}`} label={`${index + 1}. ${question.questionGroup} - ${question.question}`} multiline>
									<>
										{
											question.surveyType === SurveyQuestionTypeEnum.Answer &&
											<Textarea
												value={question.answers && question.answers.length > 0 ? (question.answers[0].answer || '') : ''}
												disabled
											/>
										}
										{
											question.surveyType === SurveyQuestionTypeEnum.SingleChoice &&
											<Radio
												value={question.answers && question.answers.length > 0 ? question.answers[0].proposedAnswerId : undefined}
												items={(question.proposedAnswers || []).map(x => ({ id: x.id, text: x.answer }))}
												column
												disabled
											/>
										}
										{
											question.surveyType === SurveyQuestionTypeEnum.MultipleChoice &&
											<MultiCheckbox
												value={
													question.answers
														? question.answers.filter(x => !!x.proposedAnswerId).map(x => x.proposedAnswerId!)
														: undefined
												}
												items={(question.proposedAnswers || []).map(x => ({ id: x.id, text: x.answer }))}
												column
												disabled
											/>
										}
										{
											question.surveyType === SurveyQuestionTypeEnum.Rating &&
											<Radio
												value={question.answers && question.answers.length > 0 ? question.answers[0].rating : undefined}
												items={SURVEY_RATING_OPTIONS}
												disabled
											/>
										}
									</>
								</SmartFormGroup>
							)
						})
					}
				</SmartItem>
			</SmartContainer>
		</ColumnContainer>
	)
}

export default ViewSurveyQuestions;
