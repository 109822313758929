import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { InsertProjectTeamMemberRequest, InsertProjectTeamMemberValidityPeriodRequest, InsertProjectTeamMemberValidityPeriodRequestUpdateProjectTeamMemberValidityPeriodRequestInt32DeltaModel, ProjectTeamMemberResponse, ProjectTeamMemberValidityPeriodResponse, UpdateProjectTeamMemberValidityPeriodRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createTeamMemberAction, persistTeamMembersAction } from '../action';
import AssignTeamMemberModalForm from '../AssignTeamMemberModalForm';
import { createDelta, unpackDelta } from 'utils/commonHelper';
import { CreateComponentProps, useGoBackFromCreate } from 'features/Crud';

type ParamType = {
	projectId: string;
}

const CreateTeamMember = ({ publishDataChanged }: CreateComponentProps) => {
	const params: ParamType = useParams();
	const goBackFromCreate = useGoBackFromCreate();

	const onSubmitCallback = useCallback(
		async (values: ProjectTeamMemberResponse, username: string) => {
			const delta = createDelta<ProjectTeamMemberValidityPeriodResponse>
				(
					[],
					values.validityPeriods || [],
					InsertProjectTeamMemberValidityPeriodRequest,
					UpdateProjectTeamMemberValidityPeriodRequest,
					InsertProjectTeamMemberValidityPeriodRequestUpdateProjectTeamMemberValidityPeriodRequestInt32DeltaModel
				);

			const insertTeamMember = new InsertProjectTeamMemberRequest({
				...values,
				projectRoleId: values.projectRoleId!,
				projectTeamId: values.projectTeamId!,
				statusId: values.statusId!,
				validUntil: values.validUntil!,
				validityPeriods: delta,

			})
			const projectId = parseInt(params.projectId)
			const bindedAction = createTeamMemberAction.bind(null, projectId, insertTeamMember);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`User ${username} is added as a team member.`);
				goBackFromCreate();
				persistTeamMembersAction(projectId)
				publishDataChanged();
			} else {
				const errors = convertResponseErrors(response)
				const [validityPeriodErrors] = unpackDelta(errors?.validityPeriods || {}, delta, values.validityPeriods || [], []);

				return { ...errors, validityPeriods: validityPeriodErrors };
			}
		},
		[goBackFromCreate, params.projectId, publishDataChanged]
	);

	return (
		<AssignTeamMemberModalForm
			save={onSubmitCallback}
			cancel={goBackFromCreate}
			open
		/>
	)
};

export default CreateTeamMember;
