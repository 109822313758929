import { MultiSelectField } from 'components/Form';
import { getAllScopeItemsForLevelAction } from 'containers/Scope/MaintainScope/action';
import { useCallback, useEffect, useState } from 'react';
import { ScopeItemLevelResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

type Props = {
	id: string
	label: string
	projectId: number
};

const ScopeItemsMultiselect = ({ id, label, projectId }: Props) => {
	const [fetchingScopeItems, setFetchingScopeItems] = useState(true);
	const [scopeItems, setScopeItems] = useState<ScopeItemLevelResponse[]>([]);

	const fetchScopeItemsCallback = useCallback(
		async () => {
			const bindedAction = getAllScopeItemsForLevelAction.bind(null, projectId, 3);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setScopeItems(response.items || []);
			}
			setFetchingScopeItems(false);
		},
		[projectId]
	)

	useEffect(
		() => {
			fetchScopeItemsCallback();
		},
		[fetchScopeItemsCallback]
	)

	return (
		<MultiSelectField
			id={id}
			label={label}
			items={scopeItems}
			getItemId={(item: ScopeItemLevelResponse) => item.id}
			getItemText={(item: ScopeItemLevelResponse) => `${item.fullname} ${item.description}`}
			loading={fetchingScopeItems}
		/>
	)
}

export default ScopeItemsMultiselect;
