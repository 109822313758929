import { Document, Font, Page, StyleSheet, Image } from '@react-pdf/renderer';
import { TimeTravelStatusResponse, UserModel, VacationRequestResponse } from 'services/tenantManagementService';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { TitleField } from 'components/Export';
import VacationRequestDocumentTable from './VacationRequestDocumentTable';
import VacationRequestDocumentForm from './VacationRequestDocumentForm';

type Props = {
	vacationRequest: VacationRequestResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedTimeAndTravelStatus: PersistItemsReducer<TimeTravelStatusResponse>
	base64Logo?: string
}

const VacationRequestDocument = ({
	vacationRequest,
	persistedUser,
	persistedTimeAndTravelStatus,
	base64Logo
}: Props) => {
	return (
		<Document>
			<Page wrap size="A4" style={styles.page}>
				{base64Logo && <Image style={{ marginLeft: 'auto', maxWidth:'100px', height: 'auto'}} src={base64Logo} /> }
				<TitleField title='Vacation request' />
				<VacationRequestDocumentForm
					vacationRequest={vacationRequest}
					persistedUser={persistedUser}
				/>
				<VacationRequestDocumentTable
					vacationRequest={vacationRequest}
					persistedTimeAndTravelStatus={persistedTimeAndTravelStatus}
					persistedUser={persistedUser}
				/>
			</Page>
		</Document>
	);
};

export default VacationRequestDocument;

Font.register({
  family: 'Open Sans',
  fonts: [
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const styles = StyleSheet.create({
	page: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingHorizontal: 10,
		fontFamily: 'Open Sans'
	},
});
