import { TabType } from 'components/Tabs/Tabs';
import { ArchiveProject } from './Tabs/ArchiveProject/ArchiveProject';
import { ProjectCategories } from './Tabs/ProjectCategory/ProjectCategories';
import { ProjectTypes } from './Tabs/ProjectType/ProjectTypes';

export const configureProjectsTabs: TabType[] = [
	{
		id: '1',
		title: 'Project type',
		route: 'type',
		component: ProjectTypes,
	},
	{
		id: '2',
		title: 'Project category',
		route: 'category',
		component: ProjectCategories,
	},
	{
		id: '3',
		title: 'Archive project',
		route: 'archive',
		component: ArchiveProject,
	},
	// {
	// 	id: '4',
	// 	title: 'Delete project',
	// 	route: 'delete',
	// 	component: () =>  '',
	// },
	// {
	// 	id: '5',
	// 	title: 'Migrate project',
	// 	route: 'migrate',
	// 	component: () =>  '',
	// },
]
