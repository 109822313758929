export enum AreaStatuses {
	RelationshipStatus = 0,
	Staffing = 1,
	PerformanceStatus = 2,
	MajorIssues = 3,
	ChangeRequest = 4,
	SubcontractorPerformance = 5,
	RiskManagement = 6,
	QualityManagement = 7,
	ProjectFinances = 8,
	PotentialAdditionalBusiness = 9,
	CommentsAndActions = 10
}
