import React, { useCallback, useMemo, useState } from 'react';
import styles from 'components/Map/map.module.scss'
import { InsertAttachmentRequest, InsertScopeItemLevelRequest, ProjectResponse, ScopeItemLevelResponse } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import { Level1andLevel2Form } from './Level1andLevel2Form';
import { Level3andLevel4Form } from './Level3andLevel4Form';
import { createScopeItemAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';

type Props = {
	items: ScopeItemLevelResponse[]
	level: number
	parentId?: number
	project: ProjectResponse
	fetchData(): Promise<void>
}

export const CreateItem = ({ items, level, parentId, project, fetchData }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openDialogCallback = useCallback(
		() => setIsModalOpen(true),
		[]
	)

	const closeDialogCallback  = useCallback(
		() => setIsModalOpen(false),
		[]
	)

	const saveCallback = useCallback(
		async (newScopeItem: ScopeItemLevelResponse, newAttachments?: InsertAttachmentRequest[]) => {
			const model = new InsertScopeItemLevelRequest(newScopeItem);
			model.insertedChangeRequestIds = newScopeItem.changeRequestIds;
			model.newAttachments = newAttachments;
			const bindedAction = createScopeItemAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`Item ${model.name} ${model.description} is created`);
				closeDialogCallback();
				await fetchData();
			} else {
				return convertResponseErrors(response);
			}
		},
		[fetchData, closeDialogCallback, project.id]
	)

	const FormComponent = useMemo(
		() => {
			switch (level) {
				case 1:
				case 2: return Level1andLevel2Form;
				case 3:
				case 4: return Level3andLevel4Form;
				default:
					return React.Fragment;
			}
		},
		[level]
	)

	const newItem = useMemo(
		() => {
			const model = new ScopeItemLevelResponse();
			model.levelNumber = level;
			model.parentId = parentId;
			model.order = items.length + 1;
			return model;
		},
		[level, parentId, items]
	)

	return (
		<>
			<div className={styles.item_add} onClick={openDialogCallback}>
				Add
			</div>
			<Dialog
				open={isModalOpen}
				title={`Create level ${level} item`}
				onClose={closeDialogCallback}
			>
				<FormComponent
					item={newItem}
					items={items}
					onSave={saveCallback}
					onCancel={closeDialogCallback}
					project={project}
				/>
			</Dialog>
		</>
	)
}
