import { UserModel, VacationRequestResponse } from 'services/tenantManagementService';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { formatDate } from 'utils/dateTimeUtils';
import { FormField } from 'components/Export';

type Props = {
	vacationRequest: VacationRequestResponse
	persistedUser: PersistItemsReducer<UserModel>
}

const VacationRequestDocumentForm = ({
	vacationRequest,
	persistedUser,
}: Props) => {
	const user = persistedUser.itemsMap[vacationRequest.userId];

	return (
		<>
			<FormField
				label='User'
				value={`${user?.firstName} ${user?.lastName}`}
			/>
			<FormField
				label='Vacation request Id'
				value={getFormatedId(EntityPrefixEnum.VACATION_REQUEST, vacationRequest.id)}
			/>
			<FormField
				label='Period'
				value={`${formatDate(vacationRequest.from)} - ${formatDate(vacationRequest.to)}`}
			/>
			<FormField
				label='Number of days'
				value={vacationRequest.numberOfDays.toString()}
			/>
		</>
	);
};

export default VacationRequestDocumentForm;
