import { useCallback } from 'react';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { persistCommunicationStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Communication/action';
import StatusReports from './StatusReports';
import { CreateStatusReport } from './Crud/CreateStatusReport';
import { UpdateStatusReport } from './Crud/UpdateStatusReport';
import { ReadStatusReport } from './Crud/ReadStatusReport';
import { CrudRouter } from 'features/Crud';

export const StatusReportsRouter = () => {
	const persistFunction = useCallback(
		() => {
			persistCommunicationStatusesAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	return (
		<CrudRouter
			ListComponent={StatusReports}
			CreateComponent={CreateStatusReport}
			UpdateComponent={UpdateStatusReport}
			ReadComponent={ReadStatusReport}
			paramName='statusReportId'
		/>
	)
}
