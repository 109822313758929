import styles from 'components/Map/map.module.scss'
import React, { useCallback, useMemo, useState } from 'react';
import { InsertAttachmentRequest, ProjectResponse, ScopeItemLevelResponse, UpdateScopeItemLevelRequest } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import { Level1andLevel2Form } from './Level1andLevel2Form';
import { Level3andLevel4Form } from './Level3andLevel4Form';
import { EditIcon } from 'components/icons/icons';
import { updateScopeItemsAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';

type Props = {
	item: ScopeItemLevelResponse
	items: ScopeItemLevelResponse[]
	project: ProjectResponse
	fetchData(): Promise<void>
}

export const UpdateItem = ({ item, items, project, fetchData }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openDialogCallback = useCallback(
		(e) => {
			e.stopPropagation();
			setIsModalOpen(true);
		},
		[]
	);

	const closeDialogCallback = useCallback(
		() => {
			setIsModalOpen(false);
		},
		[]
	);

	const updateCallback = useCallback(
		async (newItem: ScopeItemLevelResponse, newAttachments?: InsertAttachmentRequest[]) => {
			const oldChangeRequestIds = item.changeRequestIds || [];
			const newChangeRequestIds = newItem.changeRequestIds;
			const model = new UpdateScopeItemLevelRequest(newItem);
			model.newAttachments = newAttachments;

			if (item.levelNumber === 3) {
				model.insertedChangeRequestIds = newChangeRequestIds?.filter(crId => !oldChangeRequestIds.includes(crId));
				model.removedChangeRequestIds = oldChangeRequestIds.filter(crId => !newChangeRequestIds?.includes(crId));
			}

			const bindedAction = updateScopeItemsAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Scope item is updated');
				closeDialogCallback();
				await fetchData();
			} else {
				return convertResponseErrors(response);
			}
		},
		[fetchData, closeDialogCallback, project.id, item]
	)

	const FormComponent = useMemo(
		() => {
			switch (item.levelNumber) {
				case 1:
				case 2: return Level1andLevel2Form;
				case 3:
				case 4: return Level3andLevel4Form;
				default:
					return React.Fragment;
			}
		},
		[item.levelNumber]
	)

	return (
		<div className={styles.item_edit}>
			<EditIcon width={12} height={12} fill='currentColor' onClick={openDialogCallback} />
			<Dialog
				open={isModalOpen}
				title={`Update level ${item.levelNumber} item`}
				onClose={closeDialogCallback}
			>
				<FormComponent
					item={item}
					items={items}
					onSave={updateCallback}
					onCancel={closeDialogCallback}
					project={project}
				/>
			</Dialog>
		</div>
	)
}
