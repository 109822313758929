
import { useCallback,  useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChangeRequestFullResponse, ChangeRequestAnalysisRequest, ChangeRequestApproveEnum, ChangeRequestStatusEnum, InsertChangeRequestActivityRequestUpdateChangeRequestActivityRequestInt32DeltaModel, UserModel, InsertChangeRequestActivityRequest, UpdateChangeRequestActivityRequest, ChangeRequestActivityResponse } from 'services/tenantManagementService';
import { createDelta, EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { DateField, Form, TextareaField, AttachmentField, AutoCompleteField } from 'components/Form';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { propertyOf } from 'utils/propertyOf';
import { RootState } from 'base/reducer/reducer';
import Button from 'components/Button';
import SendEmail from '../../components/SendEmail';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import ActivitiesTable from './ActivitiesTable';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { TabProps } from '../../CreateChangeRequest';
import { downloadAttachmentAction, updateChangeRequestAnalysisAction } from 'containers/Scope/ChangeRequests/action';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { getUserInfo } from 'utils/storageUtils';
import { ExportAnalysis } from 'containers/Scope/ChangeRequests/Export/ExportAnalysis';

type Props = TabProps;

const Analysis = ({ projectId, changeRequest, crud, publishDataChanged }: Props) => {
	const {
		persistedUser,
		persistedChangeRequestStatus,
		persistedProject
	} = useSelector((state: RootState) => state);

	const goBackFromCrud = useGoBackFromCrud(crud, false, true);
	const [values, setValues] = useState(new ChangeRequestFullResponse(changeRequest));
	const approveRef = useRef(ChangeRequestApproveEnum.None);

	const activitiesChangeCallback = useCallback(
		(activities: ChangeRequestActivityResponse[]) => {
			setValues((state) => new ChangeRequestFullResponse({
				...state,
				activities
			}))
		},
		[]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const delta = createDelta<ChangeRequestActivityResponse>
			(
				changeRequest.activities || [],
				values.activities || [],
				InsertChangeRequestActivityRequest,
				UpdateChangeRequestActivityRequest,
				InsertChangeRequestActivityRequestUpdateChangeRequestActivityRequestInt32DeltaModel
			);

			const model = new ChangeRequestAnalysisRequest({
				...values,
				estimatedByUserId: values.estimatedByUserId!,
				completionOn: values.completionOn!,
				analysisDescription: values.analysisDescription!,
				assumptions: values.assumptions!,
				approve: approveRef.current,
				activities: delta,
				sendEmail: values.sendEmailAnalysis
			});

			const bindedAction = updateChangeRequestAnalysisAction.bind(null, projectId, model);
			const response = await tryCatchJsonByAction(bindedAction);

			// reset to default value
			approveRef.current = ChangeRequestApproveEnum.None;

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, response.value?.id);
				notifications.success(`Change request ${id} analysis is updated.`);
				publishDataChanged();
				goBackFromCrud();
			} else {
				return convertResponseErrors(response);
			}
		},
		[values, goBackFromCrud, projectId, changeRequest.activities, publishDataChanged]
	)

	const downloadAttachmentMemo = useMemo(
		() => downloadAttachmentAction.bind(null, projectId),
		[projectId]
	)

	// Svetlana asked to disable delete on existing attachments
	// const removeAttachmentCallback = useCallback(
	// 	async (id: number) => {
	// 		const bindedAction = removeAttachmentAction.bind(null, projectId, changeRequest.id, id);
	// 		const response = await tryCatchJsonByAction(bindedAction);
	// 		if (response.success) {
	// 			notifications.success('Attachment is successfully deleted');
	// 			setValues((state: ChangeRequestFullResponse) =>
	// 				new ChangeRequestFullResponse({
	// 					...state,
	// 					analysisAttachments: state.analysisAttachments?.filter(att => att.id !== id)
	// 				})
	// 			)
	// 		}
	// 	},
	// 	[projectId, changeRequest.id]
	// )

	const disabledMemo = useMemo(
		() => crud !== CrudEnum.Update
			|| !isStatusBySemantic(ChangeRequestStatusEnum.Initiated, values.statusId, persistedChangeRequestStatus.itemsMap)
			|| (!(isUserPmorSubstitutePmOrSiteAdmin(persistedProject.itemsMap[projectId]?.roleId) || values.analysisUserId === getUserInfo().id)),
		[crud, persistedChangeRequestStatus.itemsMap, values.statusId, values.analysisUserId, persistedProject.itemsMap, projectId]
	)

	return (
		<Form
			values={values}
			initialValues={changeRequest}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			disabled={disabledMemo}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<AutoCompleteField
								id={propertyOf<ChangeRequestFullResponse>('estimatedByUserId')}
								label='Estimated by'
								items={persistedUser.items}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								loading={persistedUser.fetching}
								isRequired
								sort
							/>
							<DateField
								id={propertyOf<ChangeRequestFullResponse>('estimatedOn')}
								label='Estimated on'
							/>
							<DateField
								id={propertyOf<ChangeRequestFullResponse>('completionOn')}
								label='Completion on'
								isRequired
							/>
							<TextareaField
								id={propertyOf<ChangeRequestFullResponse>('analysisDescription')}
								label='Overall project impact, change to project plan, risks etc'
								isRequired
								maxLength={2000}
							/>
							<TextareaField
								id={propertyOf<ChangeRequestFullResponse>('assumptions')}
								label='Assumptions'
								isRequired
								maxLength={2000}
							/>
						</SmartItem>
						<SmartItem>
							<AttachmentField
								id={propertyOf<ChangeRequestAnalysisRequest>('newAttachments')}
								label='Attachments'
								multiple
								oldAttachments={values.analysisAttachments}
								// removeOldAttachment={!disabledMemo ? removeAttachmentCallback : undefined}
								downloadOldAttachment={downloadAttachmentMemo}
							/>
						</SmartItem>
					</SmartContainer>
					<ActivitiesTable
						activities={values.activities || []}
						onChange={activitiesChangeCallback}
						disabled={disabledMemo}
					/>
					<SendEmail
						sendEmailId={propertyOf<ChangeRequestFullResponse>('sendEmailAnalysis')}
						toId={propertyOf<ChangeRequestFullResponse>('approvalUserId')}
						persistedUser={persistedUser}
					/>
				</ColumnContainer>
			)}
			renderAdditionalButtons={(disabled, handleSubmitCallback: () => void, isSubmitting) => (
				<RowContainer>
					<Button
						text='Additional info needed'
						disabled={disabled}
						isLoading={isSubmitting && approveRef.current === ChangeRequestApproveEnum.AdditionalInfoNeeded}
						onClick={
							() => {
								approveRef.current = ChangeRequestApproveEnum.AdditionalInfoNeeded;
								handleSubmitCallback();
							}
						}
					/>
					<Button
						text='Release for approval'
						disabled={disabled}
						isLoading={isSubmitting && approveRef.current === ChangeRequestApproveEnum.Approve}
						onClick={
							() => {
								approveRef.current = ChangeRequestApproveEnum.Approve;
								handleSubmitCallback();
							}
						}
					/>
					<Button
						text='Reject'
						disabled={disabled}
						isLoading={isSubmitting && approveRef.current === ChangeRequestApproveEnum.Reject}
						onClick={
							() => {
								approveRef.current = ChangeRequestApproveEnum.Reject;
								handleSubmitCallback();
							}
						}
					/>
					<ExportAnalysis
						changeRequest={changeRequest}
						projectId={projectId}
					/>
				</RowContainer>
			)}
		/>
	)
}

export default Analysis;
