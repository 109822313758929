import { useCallback, useState, useMemo, useEffect } from 'react'
import { CommunicationCalendarResponse, CommunicationCalendarResponseItemsResponseModel, GetVacationsCalendarRequest } from 'services/tenantManagementService'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getCalendarVacationsAction } from './action';
import { EventInput } from '@fullcalendar/core'
import { Calendar, CalendarViewEnum } from 'components/Calendar'
import { useStatusColorCallback } from 'features/TableColumns/persistedHooks';
import { removeZoneFromDate } from 'utils/dateTimeUtils';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { getVacationRoute } from 'utils/routeUtils';
import { ResourceSourceInput } from '@fullcalendar/resource';
import { DateRange } from '../ProjectMeetings/ProjectMeetings';
import { emptyArray } from 'utils/commonHelper';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { TeamVacationsFilterForm } from './TeamVacationsFilterForm';

const TeamVacations = () => {
	const { persistedTimeAndTravelStatus, persistedUser } = useSelector((state: RootState) => state)
	const getVacationStatusColor = useStatusColorCallback(persistedTimeAndTravelStatus);
	const [filter, setFilter] = useState(new GetVacationsCalendarRequest());

	const [calendar, setCalendar] = useState<CommunicationCalendarResponse[]>([]);
	const [isFetching, setIsFetching] = useState(false);
	const [dateRange, setDateRange] = useState<DateRange>();

	const fetchDataCallback = useCallback(
		async () => {
			if (!dateRange) {
				return;
			}

			setIsFetching(true);
			const bindedAction = getCalendarVacationsAction.bind(null, new Date(dateRange.startStr), new Date(dateRange.endStr), filter);
			const response: CommunicationCalendarResponseItemsResponseModel = await tryCatchJsonByAction(bindedAction);
			setIsFetching(false);
			if (response.success) {
				setCalendar(response.items || []);
			}
		},
		[dateRange, filter]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			if (filter.onlyCompanyUsers) {
				setFilter(state => new GetVacationsCalendarRequest({
					...state,
					partnerIds: []
				}))
			}
		},
		[filter.onlyCompanyUsers]
	)

	const resources: ResourceSourceInput = useMemo(
		() => {
			const userIds = [...Array.from(new Set(calendar.map(x => x.userId)))]
			const users: ResourceSourceInput = userIds.map(userId => {
				const user = persistedUser.itemsMap[userId];
				return {
					id: String(userId),
					title: `${user?.firstName} ${user?.lastName}`
				}
			})

			return users;
		},
		[persistedUser, calendar]
	)

	const events: EventInput[] = useMemo(
		() => {
			return calendar.map(
				(item) => {
					let event: EventInput = {
						id: item.id.toString(),
						// title: item.description,
						title: '',
						start: removeZoneFromDate(item.from),
						end: removeZoneFromDate(item.to),
						allDay: true,
						url: getVacationRoute(item.id),
						display: 'background',
						backgroundColor: getVacationStatusColor(item.statusId!),
						editable: false,
						resourceId: String(item.userId)
					}

					return event;
				}
			)
		},
		[calendar, getVacationStatusColor]
	)

	const setDateRangeCallback = useCallback(
		(newFrom: Date, newTo: Date, newFromStr: string, newToStr: string) => {
			setDateRange({
				startStr: newFromStr,
				endStr: newToStr
			})
		},
		[setDateRange]
	)

	return (
		<>
			<SmartContainer>
				<SmartItem>
					<TeamVacationsFilterForm
						filterModel={filter}
						onChange={setFilter}
					/>
				</SmartItem>
			</SmartContainer>
			<Calendar
				events={events}
				resources={resources}
				setDateRange={setDateRangeCallback}
				loading={isFetching || persistedUser.fetching}
				initialView={CalendarViewEnum.resourceTimelineMonthly}
				views={emptyArray}
			/>
		</>
	)
}

export default TeamVacations
