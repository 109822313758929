import { HelpShell } from 'features/Help/HelpShell';

export const MeetingMinutestatusesHelp = () => {
	return (
		<HelpShell title='Meeting minutes'>
			<div>
				<p>Meeting minutes can have 2 statuses:</p>
				<ul>
					<li>Created</li>
					<li>Released</li>
				</ul>
			</div>
			<p>
				Users can initially <b>reate</b>c meeting minutes and save them.
				At this time meeting minutes are not sent to participants.
			</p>
			<p>
				When user <b>releases</b> meeting minutes they are send to meeting participants.
				Meeting minutes have new status: released.
			</p>
			<p>
				Participants can enter changes and save them. Changes after release are saved in History.
			</p>
		</HelpShell>
	)
}
