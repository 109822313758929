import { RowContainer } from 'components/Layout';
import { CrudButtons } from 'features/Crud';
import { ExpenseSubRoutes } from '../ExpenseItemsCrudRouter';

type Props = {
	selectedId?: number
	onDelete: (id: number) => Promise<void>
	disabled?: boolean
};

const TableButtons = ({
	selectedId,
	onDelete,
	disabled
}: Props) => {
	return (
		<RowContainer>
			<CrudButtons
				selectedId={selectedId}
				onDelete={onDelete}
				prefix={ExpenseSubRoutes.ExpenseItem}
				specificActionDisabled={{
					change: disabled,
					create: disabled,
					delete: disabled
				}}
			/>
		</RowContainer>
	);
};

export default TableButtons;
