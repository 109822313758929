import { getTenantId } from 'features/Tenant/tenantUtils';
import { CommunicationConfigClient, UpdateMeetingGeneralConfigRequest } from 'services/tenantManagementService';

export const getMeetingGeneralConfigAction = () => {
	const tenantId = getTenantId()!;
	const client = new CommunicationConfigClient();
	return client.getMeetingGeneralConfig(tenantId);
}

export const updateMeetingGeneralConfigAction = (model: UpdateMeetingGeneralConfigRequest ) => {
	const tenantId = getTenantId()!;
	const client = new CommunicationConfigClient();
	return client.updateMeetingGeneralConfig(tenantId, model);
}
