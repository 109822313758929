import { StatusDeltaRequest, TestStatusClient, TestStatusResponse } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

// api

export const getTestCaseStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new TestStatusClient();
	return client.getAll(tenantId);
}

export const updateTestCaseSStatusesAction = (model: StatusDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new TestStatusClient();
	return client.update(tenantId, model);
}

export const persistTestCaseStatusesAction = async () => {
	const state = store.getState();

	if (state.persistedTestCaseStatus.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const response = await tryCatchJsonByAction(getTestCaseStatusesAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const TEST_CASE_STATUSES_SET = 'TEST_CASE_STATUSES_SET';
export const TEST_CASE_STATUSES_FETCHING = 'TEST_CASE_STATUSES_FETCHING';

const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TEST_CASE_STATUSES_FETCHING,
		fetching
	}
}

const setAction = (items: TestStatusResponse[]): PersistItemsActionType<TestStatusResponse> =>  {
	return {
		type: TEST_CASE_STATUSES_SET,
		items
	}
}
