import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useCallback, useMemo, useState } from 'react';
import { IdNameResponse } from 'services/tenantManagementService';
import { AutoCompleteField, Form } from 'components/Form';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { useGoBackCallback } from 'features/Routes/historyHooks';

type Props = {
	persistedCountry: PersistItemsReducer<IdNameResponse>
	persistedCurrency: PersistItemsReducer<IdNameResponse>
	countryId: number
	currencyId: number
	onSave: (currency: number, country: number) => void
}

export const SiteSettingsForm = ({ persistedCountry, persistedCurrency, currencyId, countryId, onSave }: Props) => {

	const goBackCallback = useGoBackCallback();

	const goBack = useCallback(
		() => {
			goBackCallback(2)
		},
		[goBackCallback]
	)

	const [values, setValues] = useState({
		currencyId,
		countryId
	});

	const initialValues = useMemo(
		() => {
			return {
				currencyId,
				countryId
			}
		},
		[currencyId, countryId]
	)

	const onSubmitCallback = useCallback(
 		async () => await onSave(values.currencyId, values.countryId),
		[values.currencyId, values.countryId, onSave]
	);

	return (
		<Form
			values={values}
			initialValues={initialValues}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBack}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<AutoCompleteField
							id='currencyId'
							label='Site currency'
							isRequired
							items={persistedCurrency.items}
							getItemId={(currency: IdNameResponse) => currency.id}
							getItemText={(currency: IdNameResponse) => currency.name!}
							loading={persistedCurrency.fetching}
						/>
						<AutoCompleteField
							id='countryId'
							label='Site country'
							isRequired
							items={persistedCountry.items}
							getItemId={(country: IdNameResponse) => country.id}
							getItemText={(country: IdNameResponse) => country.name!}
							loading={persistedCountry.fetching}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
