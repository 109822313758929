// TODO:gantt resources odnosno usere dodaj u task-ove
// TODO:gantt konfiguracija

import { useCallback, useMemo, useRef, useState } from 'react';
import { GanttChart, LinkTypeEnum } from 'components/GanttChart';
import WithFetch from 'features/Fetch/WithFetch';
import { ComponentProps } from 'features/Project';
import { InsertSchedulePredecessorRequest, PatchScheduleRequest, ScheduleConfigurationResponse, SchedulePredecessorResponse, SchedulePredecessorTypeEnum, ScheduleResponse, UpdateWbsRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { deleteScheduleAction, getAllSchedulesAction, patchUpdateScheduleAction, reorderSchedulesAction, updateSchedulesAction } from '../action';
import { createPredecessorAction, deletePredecessorAction, getPredecessorsAction } from './action';
import { getScheduleConfigurationAction } from '../Configuration/action';
import { CreateTask } from './Crud/CreateTask';
import { UpdateTask } from './Crud/UpdateTask';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';

const columns = [
    { name: 'text', label: 'Task name', tree: true, min_width: 200 },
    { name: 'start_date', label: 'Start time', min_width: 90, align: 'center' },
    { name: 'end_date', label: 'End time', min_width: 90, align: 'center' }
]

export const predecessorTypeToLinkMap: { [key in SchedulePredecessorTypeEnum ]: LinkTypeEnum } = {
	[SchedulePredecessorTypeEnum.StartToStart]: LinkTypeEnum.startToStart,
	[SchedulePredecessorTypeEnum.StartToFinish]: LinkTypeEnum.startToFinish,
	[SchedulePredecessorTypeEnum.FinishToStart]: LinkTypeEnum.finishToStart,
	[SchedulePredecessorTypeEnum.FinishToFinish]: LinkTypeEnum.finishToFinish
}

const linkTypeToPredecessorMap: { [key in LinkTypeEnum ]: SchedulePredecessorTypeEnum } = {
	[LinkTypeEnum.startToStart]: SchedulePredecessorTypeEnum.StartToStart,
	[LinkTypeEnum.startToFinish]: SchedulePredecessorTypeEnum.StartToFinish,
	[LinkTypeEnum.finishToStart]: SchedulePredecessorTypeEnum.FinishToStart,
	[LinkTypeEnum.finishToFinish]: SchedulePredecessorTypeEnum.FinishToFinish
}

export const GanttView = ({ project, disabledEdit }: ComponentProps) => {
	const [schedules, setSchedules] = useState<ScheduleResponse[]>([]);
	const [predecessors, setPredecessors] = useState<SchedulePredecessorResponse[]>([]);
	const [configuration, setConfiguration] = useState(new ScheduleConfigurationResponse());
	const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
	const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

	const openCreateDialogCallback = useCallback(
		() => setIsCreateDialogOpen(true),
		[]
	)

	const openUpdateDialogCallback = useCallback(
		() => setIsUpdateDialogOpen(true),
		[]
	)

	const ganttChartRef = useRef<any>()

	const fetchSchedulesCallback = useCallback(
		async () => {
			const bindedAction = getAllSchedulesAction.bind(null, project.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setSchedules(response.items || []);
			}
		},
		[project.id]
	)

	const fetchPredecessorsCallback = useCallback(
		async () => {
			const bindedAction = getPredecessorsAction.bind(null, project.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setPredecessors(response.items || []);
			}
		},
		[project.id]
	)

	const fetchConfigurationCallback = useCallback(
		async () => {
			const bindedAction = getScheduleConfigurationAction.bind(null, project.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setConfiguration(response.value);
			}
		},
		[project.id]
	);

	const fetchCallback = useCallback(
		async () => {
			await Promise.all([
				fetchSchedulesCallback(),
				fetchPredecessorsCallback(),
				fetchConfigurationCallback()
			])
		},
		[fetchSchedulesCallback, fetchPredecessorsCallback, fetchConfigurationCallback]
	)

	const tasks = useMemo(
		() => schedules.map((item) => {
			return {
				id: item.id,
				text: item.name,
				start_date: item.start,
				end_date: item.finish,

				parent: item.parentId || '0',
				progress: (item.percentOfCompletion || 0) / 100,
				type: item.markAsMilestone ? 'milestone' : undefined,
				open: false
			}
		}),
		[schedules]
	)

	const links = useMemo(
		() => predecessors.map((item) => {
			return {
				id: item.id,
				source: item.predecessorId,
				target: item.descendantId,
				type: predecessorTypeToLinkMap[item.type],
				lag: item.lag
			}
		}),
		[predecessors]
	)

	// update by resize/move/progress/reorder(to change parentId), not by update form
	const updateTaskCallback = useCallback(
		async (item: any) => {
			const model = new PatchScheduleRequest({
				id: item.id,
				name: item.text,
				start: item.start_date,
				finish: item.end_date,
				percentOfCompletion: Math.ceil(item.progress * 100),
				// eslint-disable-next-line eqeqeq
				parentId: item.parent != '0' ? item.parent : 0
			})

			const bindedAction = patchUpdateScheduleAction.bind(null, project.id, model);
			await tryCatchJsonByAction(bindedAction);
		},
		[project.id]
	)

	const updateTasksCallback = useCallback(
		async (items: any[]) => {
			if (items.length === 0) {
				return;
			}

			const model = items.map((item) => new PatchScheduleRequest({
				id: item.id,
				start: item.start_date,
				finish: item.end_date,
				// percentOfCompletion: Math.ceil(item.progress * 100),
			}));

			const bindedAction = updateSchedulesAction.bind(null, project.id, model);
			await tryCatchJsonByAction(bindedAction);
		},
		[project.id]
	)

	const deleteTaskCallback = useCallback(
		async (id: number) => {
			const bindedAction = deleteScheduleAction.bind(null, project.id, id);
			await tryCatchJsonByAction(bindedAction);
		},
		[project.id]
	)

	const reorderTasksCallback = useCallback(
		async (items: any[]) => {
			const bindedAction = reorderSchedulesAction.bind(null, project.id, items as UpdateWbsRequest[]);
			await tryCatchJsonByAction(bindedAction);
		},
		[project.id]
	)

	const addLinkCallback = useCallback(
		async (item: any) => {
			const model = new InsertSchedulePredecessorRequest({
				predecessorId: item.source,
				descendantId: item.target,
				type: linkTypeToPredecessorMap[item.type]
			});
			const bindedAction = createPredecessorAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				return response.value?.id;
			}
		},
		[project.id]
	)

	const deleteLinkCallback = useCallback(
		async (id: number) => {
			const bindedAction = deletePredecessorAction.bind(null, project.id, id);
			await tryCatchJsonByAction(bindedAction);
		},
		[project.id]
	)

	const afterBackendCreatedTaskCallback = useCallback(
		(task: ScheduleResponse) => {
			ganttChartRef.current.createTask({
				id: task.id,
				text: task.name,
				start_date: task.start,
				end_date: task.finish,

				parent: task.parentId || '0',
				progress: (task.percentOfCompletion || 0) / 100,
				type: task.markAsMilestone ? 'milestone' : undefined,
				open: false
			})
		},
		[]
	)

	const afterBackendUpdatedTaskCallback = useCallback(
		(task: ScheduleResponse) => {
			ganttChartRef.current.updateTask({
				id: task.id,
				text: task.name,
				start_date: task.start,
				end_date: task.finish,

				parent: task.parentId || '0',
				progress: (task.percentOfCompletion || 0) / 100,
				type: task.markAsMilestone ? 'milestone' : undefined
			})
		},
		[]
	)

	const disabled = useMemo(
		() => {
			return disabledEdit || !(isUserPmorSubstitutePmOrSiteAdmin(project.roleId) || project.permissions?.schedulePermission?.maintainSchedule);
		},
		[disabledEdit, project]
	)

	return (
		<WithFetch fetchFunction={fetchCallback}>
			<GanttChart
				ref={ganttChartRef}
				columns={columns}
				tasks={tasks}
				links={links}
				deriveTaskDatesFromSubtasks={configuration.deriveTaskDatesFromSubtasks}

				onCreateTaskClick={openCreateDialogCallback}
				onUpdateTaskClick={openUpdateDialogCallback}
				onTaskUpdate={updateTaskCallback}
				onTasksUpdate={updateTasksCallback}
				onTaskDelete={deleteTaskCallback}
				onTasksReorder={reorderTasksCallback}
				onLinkAdd={addLinkCallback}
				onLinkDelete={deleteLinkCallback}
				disabled={disabled}
			/>
			{/* ganttChartRef is not triggering re-render, so this is quick fix to be sure ganttChartRef.current is defined */}
			{isCreateDialogOpen &&
				<CreateTask
					isOpen={isCreateDialogOpen}
					setIsOpen={setIsCreateDialogOpen}
					afterBackendSave={afterBackendCreatedTaskCallback}
					ganttChartRef={ganttChartRef}
					project={project}
				/>
			}
			{/* ganttChartRef is not triggering re-render, so this is quick fix to be sure ganttChartRef.current is defined */}
			{isUpdateDialogOpen &&
				<UpdateTask
					isOpen={isUpdateDialogOpen}
					setIsOpen={setIsUpdateDialogOpen}
					afterBackendSave={afterBackendUpdatedTaskCallback}
					ganttChartRef={ganttChartRef}
					project={project}
				/>
			}
		</WithFetch>
	)
}
