import { Document, Font, Page, StyleSheet, Image } from '@react-pdf/renderer';
import { NonProjectCategoryResponse, ProjectIsActiveResponse, ProjectResponse, StatusReportResponse, StatusReportStatusResponse, UserModel } from 'services/tenantManagementService';
import { PersistActiveItemsReducer, PersistItemsReducer, PersistProjectItemsReducer } from 'features/Persist/reducerTypes';
import { TitleField } from 'components/Export';
import { StatusReportDocumentForm } from './StatusReportDocumentForm';

type Props = {
	statusReport: StatusReportResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedProject: PersistItemsReducer<ProjectResponse>
	persistedCommunicationStatusReport: PersistItemsReducer<StatusReportStatusResponse>
	persistedProjectTeam: PersistProjectItemsReducer<ProjectIsActiveResponse>
	persistedTimeTravelNonProjectCategories: PersistActiveItemsReducer<NonProjectCategoryResponse>
	base64Logo?: string
}

export const StatusReportDocument = ({
	statusReport,
	persistedUser,
	persistedProject,
	persistedCommunicationStatusReport,
	persistedProjectTeam,
	persistedTimeTravelNonProjectCategories,
	base64Logo
}: Props) => {
	return (
		<Document>
			<Page wrap size="A4" style={styles.page}>
				{base64Logo && <Image style={{ marginLeft: 'auto', maxWidth:'100px', height: 'auto'}} src={base64Logo} /> }
				<TitleField title='Status report' />
				<StatusReportDocumentForm
					statusReport={statusReport}
					persistedUser={persistedUser}
					persistedProject={persistedProject}
					persistedCommunicationStatusReport={persistedCommunicationStatusReport}
					persistedProjectTeam={persistedProjectTeam}
					persistedTimeTravelNonProjectCategories={persistedTimeTravelNonProjectCategories}
				/>
			</Page>
		</Document>
	);
}

Font.register({
  family: 'Open Sans',
  fonts: [
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const styles = StyleSheet.create({
	page: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingHorizontal: 10,
		fontFamily: 'Open Sans'
	},
});
