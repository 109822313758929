import { View, Text, StyleSheet } from '@react-pdf/renderer';

type Props = {
	value?: string
}

const TextareaFormField = ({ value}: Props) => (
	<View style={styles.container}>
		<Text style={styles.value}>
			{value || '-'}
		</Text>
	</View>
);

export default TextareaFormField;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		// flex: 1,
		flexGrow: 1,
		border: '1px solid #000000',
		margin: 4,
	},
	value: {
		padding: 4,
		fontSize: 10,
	},
});
