import { Document, Font, Page, StyleSheet, Image } from '@react-pdf/renderer';
import { CostExpenseTypeResponse, FileResponse, IdNameResponse, NonProjectCategoryResponse, ProjectResponse, TenantResponseModel, TimeTravelStatusResponse, TravelExpenseResponse, UserModel } from 'services/tenantManagementService';
import { PersistActiveItemsReducer, PersistItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import TravelExpenseDocumentForm from './TravelExpenseDocumentForm';
import { TitleField } from 'components/Export';
import TravelExpenseDocumentTable from './TravelExpenseDocumentTable';
import TravelExpenseDocumentAttachments from './TravelExpenseDocumentAttachments';

type Props = {
	travelExpense: TravelExpenseResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedTimeAndTravelStatus: PersistItemsReducer<TimeTravelStatusResponse>
	persistedProject: PersistItemsReducer<ProjectResponse>
	persistedTimeTravelNonProjectCategories: PersistActiveItemsReducer<NonProjectCategoryResponse>
	persistedCountry: PersistItemsReducer<IdNameResponse>
	persistedTravelCostTypes: PersistItemsReducer<CostExpenseTypeResponse>
	persistedTenant: PersistValueReducer<TenantResponseModel>,
	persistedCurrency: PersistItemsReducer<IdNameResponse>,
	attachments: FileResponse[] | undefined
	base64Logo?: string
}

const TravelExpenseDocument = ({
	travelExpense,
	persistedUser,
	persistedProject,
	persistedTimeTravelNonProjectCategories,
	persistedTimeAndTravelStatus,
	persistedCountry,
	persistedTenant,
	persistedCurrency,
	persistedTravelCostTypes,
	attachments,
	base64Logo
}: Props) => {
	// INFO: Svetlana je trenutno trazila samo za srbiju prevode prilikom exporta
	const isSerbiaCountry = persistedUser.itemsMap[travelExpense.userId]?.countryId === 153;

	return (
		<Document>
			<Page wrap size="A4" style={styles.page}>
				{base64Logo && <Image style={{ marginLeft: 'auto', maxWidth:'100px', height: 'auto'}} src={base64Logo} /> }
				<TitleField title={isSerbiaCountry ? 'Obračun putnog naloga' : 'Expense report'} />
				<TravelExpenseDocumentForm
					travelExpense={travelExpense}
					persistedUser={persistedUser}
					persistedProject={persistedProject}
					persistedTimeTravelNonProjectCategories={persistedTimeTravelNonProjectCategories}
					persistedTimeAndTravelStatus={persistedTimeAndTravelStatus}
					persistedCountry={persistedCountry}
					persistedTenant={persistedTenant}
					persistedCurrency={persistedCurrency}
					isSerbiaCountry={isSerbiaCountry}
				/>
				<TravelExpenseDocumentTable
					travelExpense={travelExpense}
					persistedTenant={persistedTenant}
					persistedCurrency={persistedCurrency}
					persistedTravelCostTypes={persistedTravelCostTypes}
					isSerbiaCountry={isSerbiaCountry}
				/>
				<TravelExpenseDocumentAttachments
					attachments={attachments}
					isSerbiaCountry={isSerbiaCountry}
				/>
			</Page>
		</Document>
	);
};

export default TravelExpenseDocument;

Font.register({
  family: 'Open Sans',
  fonts: [
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const styles = StyleSheet.create({
	page: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingHorizontal: 10,
		fontFamily: 'Open Sans'
	},
});
