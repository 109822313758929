import { HelpShell } from 'features/Help/HelpShell'

const ApprovalHelp = () => {
	return (
		<HelpShell title='Approval'>
			<p><b>Approve timesheets/travel requests/travel expenses/expenses/vacation requests</b></p>
			<p>
				Project managers and/or resource managers can Approve timesheets/travel requests/travel expenses/expenses/vacation requests (Items) if approval process is enabled.
			</p>
			<p>
				Each tab is showing list of all items (created, released and approved) for selected period per each project where you are assigned as project manager, substitute project manager or resource manager and per each user managing his/hers entries in Time &amp; Travel module.
			</p>
			<p>
				You can view details for each user and approve their items or reject it.
			</p>
			<p>
				If item is rejected, you need to enter reason for rejection and hit 'reject' button. At that time, email is sent to user stating reason for rejection. Item status is reverted to 'created' and user can update his/hers item.
			</p>
			<p>
				When approved, items cannot be modified by user unless, project manager rejects approved item.
			</p>
			<p>
				Please note, timesheets are approved based on your company/site settings. For example, if in configuration timesheets are approved on monthly basis, and minimum required number of recorded hours per day is 8, users will have to fill in timesheet for each working day in month with total daily sum of 8 hours before they will be able to release timesheet for approval. Application will validate if entries are in accordance with settings.
			</p>
		</HelpShell>
	)
}

export default ApprovalHelp
