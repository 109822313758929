import { getTenantId } from "features/Tenant/tenantUtils";
import { ActualBudgetRowClient, BudgetRowDeltaRequest, ExportDataModel } from "services/tenantManagementService";

export const getHeadersAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ActualBudgetRowClient();
	return client.getHeaders(tenantId, projectId);
}

export const getActualRowsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ActualBudgetRowClient();
	return client.getAll(tenantId, projectId);
}

export const updateActualRowsAction = (projectId: number, delta: BudgetRowDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new ActualBudgetRowClient();
	return client.update(tenantId, projectId, delta);
}

export const exportActualsAction = (projectId: number, model: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ActualBudgetRowClient();
	return client.exportAll(tenantId, projectId, model);
}
