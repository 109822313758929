import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { MyTimeTravelDashboardResponse } from 'services/tenantManagementService'
import { RootState } from 'base/reducer/reducer'
import { Layout } from 'features/Dashboard/Layout'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { Dashboard } from 'features/Dashboard/Dashboard';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper'
import { useFormattedProjectIdNameCallback } from 'features/TableColumns/persistedHooks'
import { TextWidget } from 'features/Dashboard/Widget/TextWidget'
import { formatDate } from 'utils/dateTimeUtils'
import { LocalTableWidget } from 'features/Dashboard/Widget/LocalTableWidget'
import { useTableColumnsTimesheetsMemo } from './tableColumnsTimesheets'
import { useTableColumnsMemo } from './tableColumns'
import { useTableColumnsVacationRequestsMemo } from './tableColumnsVacationRequests'
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget'

type Props = {
	loading: boolean
	dashboard: MyTimeTravelDashboardResponse
}

const FilteredDashboard = ({ loading, dashboard }: Props) => {
	const {
		persistedTimeAndTravelStatus,
		persistedProject,
		persistedTimeTravelNonProjectCategories,
	} = useSelector((state: RootState) => state);


	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	const tableColumnsTimesheets = useTableColumnsTimesheetsMemo();
	const estimatedAmountTableColumns = useTableColumnsMemo(true);
	const tableColumns = useTableColumnsMemo(false);
	const tableColumnsVacationRequests = useTableColumnsVacationRequestsMemo();

	const timesheetsPerStatusDataMemo = useMemo(
		() => {
			const data = persistedTimeAndTravelStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.timesheets?.timesheetsPerStatusCounts && dashboard.timesheets?.timesheetsPerStatusCounts[status.id]) ? dashboard.timesheets?.timesheetsPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTimeAndTravelStatus]
	)

	const notApprovedTimesheetsDataMemo = useMemo(
		() => {
			const data = dashboard.timesheets?.notApprovedTimesheets?.map(item => {
				return {
					projectOrCategoryId: item.isProjectConnected ?
						formatProjectIdName(item.projectOrCategoryId) :
						persistedTimeTravelNonProjectCategories.itemsMap[item.projectOrCategoryId]?.name || '',
					year: item.year.toString(),
					month: item.month,
					startDate: formatDate(item.startDate),
				}
			})

			return data || [];
		},
		[dashboard, formatProjectIdName, persistedTimeTravelNonProjectCategories]
	)

	const travelRequestsPerStatusDataMemo = useMemo(
		() => {
			const data = persistedTimeAndTravelStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.travelRequests?.travelRequestsPerStatusCounts && dashboard.travelRequests?.travelRequestsPerStatusCounts[status.id]) ? dashboard.travelRequests?.travelRequestsPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTimeAndTravelStatus]
	)

	const notApprovedTravelRequestsDataMemo = useMemo(
		() => {
			const data = dashboard.travelRequests?.notApprovedTravelRequests?.map(item => {
				return {
					id: getFormatedId(EntityPrefixEnum.TRAVEL_REQUEST, item.id),
					projectOrCategoryId: item.isProjectConnected ?
						formatProjectIdName(item.projectOrCategoryId) :
						persistedTimeTravelNonProjectCategories.itemsMap[item.projectOrCategoryId]?.name || '',
					year: item.year.toString(),
					month: item.month,
					amount: item.amount,
				}
			});

			return data || [];
		},
		[dashboard, formatProjectIdName, persistedTimeTravelNonProjectCategories]
	)

	const travelExpensesPerStatusDataMemo = useMemo(
		() => {
			const data = persistedTimeAndTravelStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.travelExpenses?.travelExpensesPerStatusCounts && dashboard.travelExpenses?.travelExpensesPerStatusCounts[status.id]) ? dashboard.travelExpenses?.travelExpensesPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTimeAndTravelStatus]
	)

	const notApprovedTravelExpensesDataMemo = useMemo(
		() => {
			const data = dashboard.travelExpenses?.notApprovedTravelExpenses?.map(item => {
				return {
					id: getFormatedId(EntityPrefixEnum.TRAVEL_EXPENSE, item.id),
					projectOrCategoryId: item.isProjectConnected ?
						formatProjectIdName(item.projectOrCategoryId) :
						persistedTimeTravelNonProjectCategories.itemsMap[item.projectOrCategoryId]?.name || '',
					year: item.year.toString(),
					month: item.month,
					amount: item.amount
				}
			});

			return data || [];
		},
		[dashboard, formatProjectIdName, persistedTimeTravelNonProjectCategories]
	)

	const expensesPerStatusDataMemo = useMemo(
		() => {
			const data = persistedTimeAndTravelStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.expenses?.expensesPerStatusCounts && dashboard.expenses?.expensesPerStatusCounts[status.id]) ? dashboard.expenses?.expensesPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTimeAndTravelStatus]
	)

	const notApprovedExpensesDataMemo = useMemo(
		() => {
			const data = dashboard.expenses?.notApprovedExpenses?.map(item => {
				return {
					id: getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, item.id),
					projectOrCategoryId: item.isProjectConnected ?
						formatProjectIdName(item.projectOrCategoryId) :
						persistedTimeTravelNonProjectCategories.itemsMap[item.projectOrCategoryId]?.name || '',
					year: item.year.toString(),
					month: item.month,
					amount: item.amount
				}
			});

			return data || [];
		},
		[dashboard, formatProjectIdName, persistedTimeTravelNonProjectCategories]
	)

	const vacationRequestsPerStatusDataMemo = useMemo(
		() => {
			const data = persistedTimeAndTravelStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.vacationRequests?.vacationRequestsPerStatusCounts && dashboard.vacationRequests?.vacationRequestsPerStatusCounts[status.id]) ? dashboard.vacationRequests?.vacationRequestsPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTimeAndTravelStatus]
	)

	const notApprovedVacationRequestsDataMemo = useMemo(
		() => {
			const data = dashboard.vacationRequests?.notApprovedVacationRequests?.map(item => {
				return {
					projectOrCategoryId: item.isProjectConnected ?
						formatProjectIdName(item.projectOrCategoryId) :
						persistedTimeTravelNonProjectCategories.itemsMap[item.projectOrCategoryId]?.name || '',
					year: item.year.toString(),
					month: item.month,
				}
			});

			return data || [];
		},
		[dashboard, formatProjectIdName, persistedTimeTravelNonProjectCategories]
	)

	const timesheetOverviewDataMemo = useMemo(
		() => {
			const perProject = Object.keys(dashboard.timesheets?.timesheetHoursPerProject || {}).map(projectId => {
				const value = dashboard.timesheets?.timesheetHoursPerProject![projectId]
				return  {
					name: `${persistedProject.itemsMap[projectId]?.name}`,
					value,
				}
			})

			const perNonProjectCategory = Object.keys(dashboard.timesheets?.timesheetHoursPerNonProjectCategory || {}).map(nonProjectCategoryId => {
				const value = dashboard.timesheets?.timesheetHoursPerNonProjectCategory![nonProjectCategoryId]
				return  {
					name: persistedTimeTravelNonProjectCategories.itemsMap[parseInt(nonProjectCategoryId)]?.name || '',
					value,
				}
			})

			return [...perProject, ...perNonProjectCategory];
		},
		[dashboard, persistedTimeTravelNonProjectCategories, persistedProject]
	)

	const approvedHoursPerProductivityDataMemo = useMemo(
		() =>  {
			return dashboard.timesheets?.approvedHoursPerBillability ?
				[
					{ id: 'True', name: 'Productive', hours: dashboard.timesheets?.approvedHoursPerBillability['True']},
					{ id: 'False', name: '', hours: dashboard.timesheets?.approvedHoursPerBillability['False']},
				] :
				[]
		},
		[dashboard]
	)

	const columnSeriesFieldsMemo = useMemo(
		() => {
			return [{
				name: 'Number of hours',
				value: 'value'
			}]
		},
		[]
	)

	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='horizontal'>
				<Layout
					orientation='vertical'
					className='col-xl-6 col-sm-12'
				>
					<Layout orientation='horizontal'>
						<Layout
							orientation='vertical'
							className='col-4'
						>
							<TextWidget
								title='Total number of hours'
								value={dashboard.timesheets?.totalTimesheetHours}
								loading={loading}
								toFixed
							/>
							<TextWidget
								title='Number of approved vacation days for selected period'
								value={dashboard.vacationRequests?.totalVacationDays}
								loading={loading}
							/>
						</Layout>
						<PieWidget
							className='col-8'
							title='Timesheet overview'
							id='timesheets_hours_per_project_and_non_project_category_pie_chart'
							fieldValue='value'
							fieldCategory='name'
							data={timesheetOverviewDataMemo}
							loading={loading}
						/>
					</Layout>
					<Layout orientation='horizontal'>
						<Layout
							orientation='vertical'
							className='col-4'
						>
							<TextWidget
								title='Productive hours'
								value={dashboard.timesheets?.totalProductiveTimesheetHours.toFixed(2) ?? 0}
								loading={loading}
								toFixed
							/>
							<TextWidget
								title='Non-productive hours'
								value={dashboard.timesheets?.totalNotProductiveTimesheetHours?.toFixed(2) ?? 0}
								loading={loading}
								toFixed
							/>
						</Layout>
						<PieWidget
							className='col-8'
							title='Productivity'
							id='approved__my_hours_per_productivity_pie_chart'
							fieldValue='hours'
							fieldCategory='name'
							data={approvedHoursPerProductivityDataMemo}
							loading={loading}
						/>
					</Layout>
				</Layout>
				<RotatedColumnSeriesWidget
					className='col-xl-6 col-md-12'
					title='Timesheets per project/category for selected period'
					id='timesheets_per_project_category_for_selected_period_rotated_column_series_chart'
					data={timesheetOverviewDataMemo}
					fieldCategory='name'
					fieldValues={columnSeriesFieldsMemo}
					loading={loading}
					showValueOnBar
				/>
			</Layout>
			<Layout orientation='horizontal'>
				<PieWidget
					className='col-xl-4 col-md-6'
					title='Timesheets per Status'
					id='timesheets_per_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={timesheetsPerStatusDataMemo}
					loading={loading}
				/>
				<LocalTableWidget
					className='col-xl-8'
					title='Not approved timesheets'
					loading={loading}
					columns={tableColumnsTimesheets}
					data={notApprovedTimesheetsDataMemo}
					hasPagination
					limit={4}
				/>
			</Layout>
			<Layout orientation='horizontal'>
				<PieWidget
					className='col-xl-4 col-md-6'
					title='Travel requests per Status'
					id='travel_requests_per_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={travelRequestsPerStatusDataMemo}
					loading={loading}
				/>
				<LocalTableWidget
					className='col-xl-8'
					title='Not approved travel requests'
					loading={loading}
					columns={estimatedAmountTableColumns}
					data={notApprovedTravelRequestsDataMemo}
					hasPagination
					limit={4}
				/>
			</Layout>
			<Layout orientation='horizontal'>
				<PieWidget
					className='col-xl-4 col-md-6'
					title='Travel expenses per Status'
					id='travel_expenses_per_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={travelExpensesPerStatusDataMemo}
					loading={loading}

				/>
				<LocalTableWidget
					className='col-xl-8'
					title='Not approved travel expenses'
					loading={loading}
					columns={tableColumns}
					data={notApprovedTravelExpensesDataMemo}
					hasPagination
					limit={4}
				/>
			</Layout>
			<Layout orientation='horizontal'>
				<PieWidget
					className='col-xl-4 col-md-6'
					title='Expenses per Status'
					id='expenses_per_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={expensesPerStatusDataMemo}
					loading={loading}
				/>
				<LocalTableWidget
					className='col-xl-8'
					title='Not approved expenses'
					loading={loading}
					columns={tableColumns}
					data={notApprovedExpensesDataMemo}
					hasPagination
					limit={4}
				/>
			</Layout>
			<Layout orientation='horizontal'>
				<PieWidget
					className='col-xl-4 col-md-6'
					title='Vacation requests per Status'
					id='vacation_requests_per_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={vacationRequestsPerStatusDataMemo}
					loading={loading}
				/>
				<LocalTableWidget
					className='col-xl-8'
					title='Not approved released vacation requests'
					loading={loading}
					columns={tableColumnsVacationRequests}
					data={notApprovedVacationRequestsDataMemo}
					hasPagination
					limit={4}
				/>
			</Layout>
		</Dashboard>
	)
}

export default FilteredDashboard
