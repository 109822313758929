import { useCallback, useEffect, useState } from 'react';
import { Form, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ModuleActivityEnum, MonthEnum, RiskDashboardFilterRequest } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import Button from 'components/Button';
import { monthOptions, yearOptions } from 'containers/TimeTravel/utils';
import { ProjectSelectField, pmOrSpmOrOumPermission } from 'features/Project';

type Props = {
	filterFormModel?: RiskDashboardFilterRequest
	onSubmit: (request: RiskDashboardFilterRequest) => Promise<void>
	onSave: (filterFormModel: RiskDashboardFilterRequest) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const [values, setValues] = useState(new RiskDashboardFilterRequest(filterFormModel));

	useEffect(
		() => {
			setValues(new RiskDashboardFilterRequest(filterFormModel));
		},
		[filterFormModel]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new RiskDashboardFilterRequest(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectSelectField
							id={propertyOf<RiskDashboardFilterRequest>('projectId')}
							isRequired
							isProjectDashboard
							moduleEnum={ModuleActivityEnum.Risk}
							userRolePermission={pmOrSpmOrOumPermission}
						/>
						<MultiSelectField
							id={propertyOf<RiskDashboardFilterRequest>('years')}
							label='Year'
							items={yearOptions}
							getItemId={(item: number) => item}
							getItemText={(item: number) => item.toString()}
						/>
						<MultiSelectField
							id={propertyOf<RiskDashboardFilterRequest>('months')}
							label='Month'
							items={monthOptions}
							getItemId={(item: MonthEnum) => item}
							getItemText={(item: MonthEnum) => item}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
				/>
			)}
		/>
	)
}
