import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'base/reducer/reducer'
import { ThemeEnum } from "features/Theme";
import { createLegend } from './helpers/legend'

type FieldType = {
	value: string
	name: string
	color?: string
}

export type LineSeriesChartProps = {
	id: string
	data: any[]
	fieldValues: FieldType[]
	fieldCategory: string
	stacked?: boolean
	categoryTitle?: string
	opacity?: number
	style?: React.CSSProperties
	showValueOnBar?: boolean
}

export const LineSeriesChart = ({ id, data, fieldCategory, fieldValues, categoryTitle, style, stacked = false, opacity = 0.7, showValueOnBar = false }: LineSeriesChartProps) => {
	const theme = useSelector((state: RootState) => state.theme);

	useEffect(
		() => {
			let chart = am4core.create(id, am4charts.XYChart);
			chart.data = data;

			let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = fieldCategory;
			categoryAxis.renderer.labels.template.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
			categoryAxis.renderer.minGridDistance = 10;
			categoryAxis.renderer.grid.template.disabled = true;
			categoryAxis.fontSize = 11;

			if (categoryTitle) {
				categoryAxis.title.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
				categoryAxis.title.text = categoryTitle;
			}

			let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis.renderer.labels.template.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
			valueAxis.renderer.grid.template.disabled = true;
			valueAxis.fontSize = 11;

			fieldValues.forEach(
				(fv) => {
					let series = chart.series.push(new am4charts.LineSeries());
					series.dataFields.valueY = fv.value;
					series.dataFields.categoryX = fieldCategory;
					series.name = fv.name;
					series.stacked = stacked;
					if (fv.color) {
						series.fill = am4core.color(fv.color);
					}
					// series.columns.template.fillOpacity = opacity;
					// series.columns.template.strokeWidth = 0;
					// series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";

					if (showValueOnBar) {
						let valueLabel = series.bullets.push(new am4charts.LabelBullet());
						valueLabel.label.text = '{valueY.formatNumber("#,###.")}';
						valueLabel.label.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
						valueLabel.label.fontSize = 11;
						valueLabel.label.verticalCenter = 'bottom';
						valueLabel.locationY = 0;
						valueLabel.dy = 0;
						valueLabel.label.truncate = false;
						valueLabel.label.hideOversized = stacked ? true : false;
						valueLabel.tooltipText = "{categoryX}: [bold]{valueY.formatNumber('#,###.##')}[/]";

						valueLabel.label.adapter.add('verticalCenter', function(x, target) {
							if (!target.dataItem) {
								return x;
							}
							let values = target.dataItem.values;

							if (valueAxis.max && (values.valueY.value / valueAxis.max) * 100 >= 90) {
								return 'top';
							} else {
								return 'bottom';
							}
						});
					}
				}
			)

			chart.legend = createLegend(theme)

			// ne vide se dobro sve vrijednosti kada je cursor ukljucen, dolazi do preklapanja
			// chart.cursor = new am4charts.XYCursor();
			// chart.cursor.lineX.stroke= am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
			// chart.cursor.lineY.stroke= am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
		},
		[id, data, fieldCategory, fieldValues, stacked, theme, categoryTitle, opacity, showValueOnBar]
	)

	return <div id={id} style={style}></div>
}
