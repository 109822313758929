import Tabs from 'components/Tabs/Tabs';
import { ContentShell } from 'features/Content/ContentShell';
import { configureCommunicationTabs } from './configureCommunicationTabs';

export const ConfigureCommunication  = () => {
	return (
		<ContentShell
			title='Configure Communication'
		>
			<Tabs tabs={configureCommunicationTabs} />
		</ContentShell>
	)
}
