import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import { ThemeEnum } from "features/Theme";
import { getCssVariableValue } from 'utils/cssVariablesUtils';

export const createLegend = (theme: ThemeEnum, fixedWidth?: boolean) => {
	const legend = new am4charts.Legend();
	legend.position = 'right';
	if (fixedWidth) {
		legend.width = 220;
	} else {
		legend.maxWidth = 220;
	}

	legend.useDefaultMarker = true;
	legend.markers.template.width = 10;
	legend.markers.template.height = 10;

	legend.labels.template.fontSize = 11;
	legend.labels.template.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
	// for color of legendSettings.valueText
	legend.valueLabels.template.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
	// legend.labels.template.text = "Series: [bold {color}]{name}[/]";
	// series.legendSettings.labelText = "Series: [bold {color}]{name}[/]"; // zadaje se u samom chart-u ako treba da bude specificni slucaj

	const as = legend.labels.template.states.getKey("active");
	const color = getCssVariableValue('--legend-inactive');
	as!.properties.fill = am4core.color(color);
	// as!.properties.textDecoration = "line-through";

	legend.scrollable = true;
	legend.scrollbar.minWidth = 8;
	legend.scrollbar.width = 8;
	legend.scrollbar.background.fillOpacity = 0.1;
	legend.scrollbar.thumb.background.fill = am4core.color(getCssVariableValue('--scroll-bar-background'));
	// when theme is changed, for some reason a stroke is added around the scrollbar, so we set the stroke opacity to 0
	legend.scrollbar.thumb.background.strokeOpacity = 0;

	legend.itemContainers.template.paddingTop = 2;
	legend.itemContainers.template.paddingBottom = 2;

	// in situations where we want to custom sort legend items, we need to set legendItemPosition to numbers 1,2,3... (Scrollbar is at position 0)
	legend.events.on("ready", function(ev) {
		ev.target.itemContainers.each(function(item) {
			if (item?.dataItem) {
			  item.zIndex = ((item.dataItem.dataContext as any)?.dataContext as any)?.legendItemPosition
			  ev.target.children.moveValue(item, item.zIndex);
			}
		});
	  });

	return legend;
}
