import { useCallback, useMemo } from "react";
import { ScopeItemLevelResponse } from "services/tenantManagementService";

const uniqueNameValidator = (existingNames: string[], newName: string) => {
	if (!newName) {
		return '';
	}

	if (existingNames.includes(newName)) {
		return 'ID must be unique';
	}

	return '';
}

export const useUniqueNameValidatorCallback = (items: ScopeItemLevelResponse[], oldName: string) => {
	const existingNames = useMemo(
		() => {
			const names = items.map(item => item.name);
			const index = names.indexOf(oldName);
			if (index !== -1) {
				names.splice(index, 1)
			}

			return names;
		},
		[items, oldName]
	)

	return useCallback(
		(newName: string) => uniqueNameValidator(existingNames, newName),
		[existingNames]
	)
}
