import { HelpShell } from 'features/Help/HelpShell';

export const MyTestPackagesHelp = () => {
	return (
		<HelpShell title='My Test Packages'>
			<p>On this page, based on test plan, each user assigned as process or step tester, can see list of all test cases (processes) which he/she needs to test.</p>
			<p>To assess test package, user needs to select project and test cycle.</p>
			<p>User can select check box to show process steps. <br />
				List can be configured based on user preferences by selecting 'Configure view' button.
			</p>
			<p>In order to register test results, user can select Go to test case.</p>
			<div>
				<p>We differentiate between two tester roles:</p>
				<ul>
					<li>Process tester (responsible for overall test execution and only they can update process status and step statuses), and</li>
					<li>Step tester (responsible for execution process steps and they can update only step statuses)</li>
				</ul>
			</div>
			<p>When process or step is marked as failed, user has possibility to create ticket related to that process or step error. Ticket ID is automatically linked to test scenario.</p>
			<p>Each test case can be exported in pdf file.</p>
		</HelpShell>
	)
}
