import { CrudRouter } from 'features/Crud/CrudRouter';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ApprovalTabSubroutes } from '../ApprovalSubroutes';
import ReadTravelExpense from './Crud/ReadTravelExpense';
import TravelExpensesTable from './TravelExpensesTable';

const TravelExpensesApprovalRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${ApprovalTabSubroutes.TRAVEL_EXPENSES}`}
				render={() => (
					<CrudRouter
						ListComponent={TravelExpensesTable}
						ReadComponent={ReadTravelExpense}
						paramName='travelExpenseId'
					/>
				)}
			/>
		</Switch>
	)
}

export default TravelExpensesApprovalRouter;
