import { Link } from 'react-router-dom';
import { IconType } from 'components/icons/icons';
import styles from './submenu.module.scss';
import { getCssVariableValue } from 'utils/cssVariablesUtils';

type Props = {
    url: string
    text: string
    isActive?: boolean
	Icon?: IconType
	closeSubmenu(): void
}

export const Item = ({ url, text, isActive, Icon, closeSubmenu }: Props) => {
	const className = `${styles.item} ${isActive ? styles.active : ''}`
	const iconSize = getCssVariableValue('--shell-icon-size');

    return (
		<Link
			className={className}
			onClick={closeSubmenu}
			to={url}
		>
			{Icon && <Icon className={styles.icon} width={iconSize} height={iconSize} fill='currentColor' />}
			<span className={styles.text}>{text}</span>
		</Link>
    )
}
