import { useMemo } from 'react';
import { useVisibleColumns, TableColumnsType, FieldTypeEnum } from 'components/Table';
import { reportItemStatuses } from '../../ReportItemStatuses';
import { ProjectActionResponse, ReportItemStatusEnum } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';

const defaultVisibleColumns = [
	propertyOf<ProjectActionResponse>('isActive'),
	propertyOf<ProjectActionResponse>('name'),
	propertyOf<ProjectActionResponse>('status')
]

export const useTableColumnsMemo = (
	visibleColumns: string[] = defaultVisibleColumns
) => {

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectActionResponse>('isActive')]: {
					title: 'Active',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<ProjectActionResponse>('name')]: {
					title: 'Comment/Action',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectActionResponse>('status')]: {
					title: 'Status',
					fieldType: FieldTypeEnum.Options,
					options: reportItemStatuses,
					getItemId: (option: ReportItemStatusEnum) => option.valueOf(),
					getItemText: (option: ReportItemStatusEnum) => option
				}
			}
		},
		[]
	)

	return useVisibleColumns(columns, visibleColumns);
}
