import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { BusinessPartnerResponseModel, IdNameResponse } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { AutoCompleteField, Form, InputField } from 'components/Form';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { ibanValidator, swiftValidator, taxVATNumberAlreadyExistsValidator } from 'utils/validators';

type Props = {
	partner?: BusinessPartnerResponseModel
	crud: CrudEnum
	onSave: (partner: BusinessPartnerResponseModel) => void
}

export const PartnerForm = ({ partner, crud, onSave }: Props) => {
	const { persistedCountry, persistedBusinessPartner } = useSelector((state: RootState) => state);

	const [values, setValues] = useState(partner || new BusinessPartnerResponseModel());
	const goBackFromCrud = useGoBackFromCrud(crud);

	const onSubmitCallback = useCallback(
		async () => onSave && await onSave(values),
		[onSave, values]
	)

	const existingPartnerVATsMemo = useMemo(
		() => {
			const existingPartners = partner
				? persistedBusinessPartner.items.filter(x => x.id !== partner.id)
				: [...persistedBusinessPartner.items]

			return existingPartners.map(partner => partner.vatNo)
		},
		[partner, persistedBusinessPartner],
	)

	const taxVATNumberAlreadyExistsValidatorCallback = useCallback(
		(value: number) => taxVATNumberAlreadyExistsValidator(existingPartnerVATsMemo, value),
		[existingPartnerVATsMemo]
	)

	return (
		<Form
			values={values}
			initialValues={partner}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<InputField
							id='name'
							label='Partner name'
							isRequired
						/>
						<InputField
							id='address'
							label='Address'
						/>
						<InputField
							id='city'
							label='City'
							isRequired
						/>
						<AutoCompleteField
							id='countryId'
							label='Country'
							isRequired
							items={persistedCountry.items}
							getItemId={(item: IdNameResponse) => item.id}
							getItemText={(item: IdNameResponse) => item.name!}
							loading={persistedCountry.fetching}
							sort
						/>
						<InputField
							id='contactPerson'
							label='Contact person'
						/>
						<InputField
							id='email'
							label='E-mail'
							isEmail
						/>
						<InputField
							id='phone'
							label='Phone'
						/>
					</SmartItem>
					<SmartItem>
						<InputField
							id='regNo'
							label='Registration No'
						/>
						<InputField
							id='vatNo'
							label='VAT no'
							isRequired
							validator={taxVATNumberAlreadyExistsValidatorCallback}
						/>
						<InputField
							id='iban'
							label='IBAN'
							validator={ibanValidator}
						/>
						<InputField
							id='swift'
							label='SWIFT'
							validator={swiftValidator}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
