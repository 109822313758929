import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { Export } from 'features/Export/Export';
import { ChangeRequestStatusEnum, ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { CrudSubRoutesEnum } from 'features/Crud';
import path from 'path';
import { ChangeRequestSubroutes } from 'containers/Scope/ChangeRequests/Crud/Tabs/ChangeRequestSubroutes';
import { BaseColumnModel } from 'components/Table';

type Props = {
	selectedId: number
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	isEditable?: boolean
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
	historyState?: string
	status?: ChangeRequestStatusEnum
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

export const TableButtons = ({
	selectedId,
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction,
	isEditable,
	historyState,
	status
}: Props) => {
	let history = useHistory();

	const onViewChangeHandler = useCallback(
		(paramValue: CrudSubRoutesEnum) => {
			if (status === ChangeRequestStatusEnum.ApprovedForRealization) {
				history.push(path.join(history.location.pathname, `${paramValue}/${selectedId}/${ChangeRequestSubroutes.REALIZATION}`), historyState)
			} else if (status === ChangeRequestStatusEnum.Completed || status === ChangeRequestStatusEnum.ReleasedForAcceptance) {
				history.push(path.join(history.location.pathname, `${paramValue}/${selectedId}/${ChangeRequestSubroutes.ACCEPTANCE}`), historyState)
			} else if (status === ChangeRequestStatusEnum.Created) {
				history.push(path.join(history.location.pathname, `${paramValue}/${selectedId}/${ChangeRequestSubroutes.PROBLEM_REPORT}`), historyState)
			} else if (status === ChangeRequestStatusEnum.Initiated || status === ChangeRequestStatusEnum.RejectedAnalysis) {
				history.push(path.join(history.location.pathname, `${paramValue}/${selectedId}/${ChangeRequestSubroutes.ANALYSIS}`), historyState)
			} else if (status === ChangeRequestStatusEnum.RejectedForRealization || status === ChangeRequestStatusEnum.ReleasedForApproval) {
				history.push(path.join(history.location.pathname, `${paramValue}/${selectedId}/${ChangeRequestSubroutes.APPROVAL}`), historyState)
			}
		},
		[history, selectedId, historyState, status]
	)

	return (
		<RowContainer justifyContent='space-between'>
			<RowContainer>
				<Button
					text='View'
					onClick={() => onViewChangeHandler(CrudSubRoutesEnum.Read)}
					disabled={!selectedId}
				/>
				<Button
					text='Change'
					onClick={() => onViewChangeHandler(CrudSubRoutesEnum.Update)}
					disabled={!selectedId || !isEditable}
				/>
			</RowContainer>
			<RowContainer>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				<Export
					filtersModel={filtersModel}
					tableColumns={tableColumns}
					exportAction={exportFunction}
					exportTypeOptions={EXPORT_TYPE_OPTIONS}
					title='My change Requests'
				/>
			</RowContainer>
		</RowContainer>
	)
}
