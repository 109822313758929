import { getTenantId } from "features/Tenant/tenantUtils";
import { ChangeRequestClient, InsertChangeRequestRequest, UpdateChangeRequestRequest, ChangeRequestAnalysisRequest, ChangeRequestAcceptanceRequest, ChangeRequestApprovalRequest, ChangeRequestRealizationRequest, GenericFilterModelCollection, ExportDataModel } from 'services/tenantManagementService';

export const getChangeRequestsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	const filter = new GenericFilterModelCollection();
	filter.includes = ['Activities'];
	return client.getAll2(tenantId, projectId, filter);
}

export const getChangeRequestsGenericAction = (projectId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	genericFilter.includes = ['Activities'];
	return client.getAll2(tenantId, projectId, genericFilter);
}

export const getMyChangeRequestsAction = (projectId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	genericFilter.includes = ['Activities'];
	return client.getMyChangeRequests(tenantId, projectId, genericFilter);
}

export const getAllChangeRequestCountsActionCounts = (projectId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	genericFilter.includes = ['Activities'];
	return client.getAllGenericFilteringCounts(tenantId, projectId, genericFilter);
}

export const getAllMyChangeRequestCountsActionCounts = (projectId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	genericFilter.includes = ['Activities'];
	return client.getAllMyGenericFilteringCounts(tenantId, projectId, genericFilter);
}

export const getSimpleChangeRequestsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.getAllSimple(tenantId, projectId, undefined, undefined);
}

export const getAllChangeRequestsSimpleAction = () => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.getAllSimple2(tenantId);
}

export const getChangeRequestAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.get(tenantId, projectId, id);
}

export const createChangeRequestAction = (projectId: number, model: InsertChangeRequestRequest) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.create(tenantId, projectId, model);
}

export const updateChangeRequestAction = (projectId: number, model: UpdateChangeRequestRequest) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.update(tenantId, projectId, model);
}

export const deleteChangeRequestAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.delete(tenantId, projectId, id);
}

export const updateChangeRequestAnalysisAction = (projectId: number, model: ChangeRequestAnalysisRequest) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.crAnalysis(tenantId, projectId, model);
}

export const updateChangeRequestApprovalAction = (projectId: number, model: ChangeRequestApprovalRequest) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.crApproval(tenantId, projectId, model);
}

export const updateChangeRequestRealizationAction = (projectId: number, model: ChangeRequestRealizationRequest) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.crRealization(tenantId, projectId, model);
}

export const updateChangeRequestAcceptanceAction = (projectId: number, model: ChangeRequestAcceptanceRequest) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.crAcceptance(tenantId, projectId, model);
}

export const downloadAttachmentAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.download(tenantId, projectId, id);
}

export const removeAttachmentAction = (projectId: number, changeRequestId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.removeAttachment(tenantId, projectId, changeRequestId, id);
}

export const exportAction = (projectId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.export(tenantId, projectId, exportDataModel);
}

export const exportMyAction = (projectId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestClient();
	return client.exportMy(tenantId, projectId, exportDataModel);
}
