import { View, Text, StyleSheet, Svg, Path } from '@react-pdf/renderer';

type Props = {
	label: string
	value: boolean
	valueBackgroundColor?: string
}

// Added after updating React from 17 to 18 because children is not declared as prop and library is not maintained
const SvgAny = Svg as any;

const CheckboxFormField = ({ label, value, valueBackgroundColor = '#fff' }: Props) => (
	<View style={styles.container}>
		<View style={styles.valueContainer}>
			{value && (
				<SvgAny width="16" height="16" viewBox="0 0 1024 1024">
					<Path
						d='M 806.398438 308.480469 C 794.382812 296.363281 778.023438 289.550781 760.960938 289.550781 C 743.894531 289.550781 727.535156 296.363281 715.519531 308.480469 L 444.160156 579.839844 L 308.480469 444.160156 C 296.464844 432.042969 280.105469 425.230469 263.039062 425.230469 C 245.976562 425.230469 229.617188 432.042969 217.601562 444.160156 C 192.785156 469.121094 192.785156 509.4375 217.601562 534.398438 L 398.71875 715.519531 C 410.738281 727.636719 427.09375 734.449219 444.160156 734.449219 C 461.226562 734.449219 477.582031 727.636719 489.601562 715.519531 L 806.398438 398.71875 C 831.214844 373.757812 831.214844 333.441406 806.398438 308.480469 Z M 806.398438 308.480469 '
						fill='rgb(0, 0, 0)'
					/>
				</SvgAny>
			)}
		</View>
		<Text style={styles.label}>
			{label}
		</Text>
	</View>
);


export default CheckboxFormField;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		margin: 4
	},
	label: {
		fontSize: 10,
		paddingLeft: 10
	},
	valueContainer: {
		border: '1px solid #000000',
		width: 16,
		height: 16
	},
});
