export const taxVATNumberAlreadyExistsValidator = (existingValues: string[], value: number) => {
	if (!value) {
		return '';
	}

	const existingValuesSet = new Set(existingValues);
	if (existingValuesSet.has(value.toString())) {
		return 'A partner already exists with this Tax/VAT No.';
	}

	return '';
}

// INFO: for now we do not validate by country
export const ibanValidator = (value: string) => {
	if (!value) {
		return '';
	}
	const minIbanLengthConstant = 18;
	const maxIbanLengthConstant = 32;


	if (value.length < minIbanLengthConstant || value.length > maxIbanLengthConstant) {
		return `IBAN must be between ${minIbanLengthConstant} and ${maxIbanLengthConstant} characters long`;
	}

	const regex = /^[A-Za-z]{2}/;

	if (!regex.test(value)) {
		return 'Invalid IBAN number';
	}

	return '';
}

export const swiftValidator = (value: string) => {
	if (!value) {
		return '';
	}
	const minSwiftLengthConstant = 8;
	const maxSwiftLengthConstant = 11;


	if (value.length < minSwiftLengthConstant || value.length > maxSwiftLengthConstant) {
		return `SWIFT must be between ${minSwiftLengthConstant} and ${maxSwiftLengthConstant} characters long`;
	}

	const regex = /^[A-Za-z]{6}[A-Za-z0-9]{2}([A-ZA-Za-z0-9]{3})?$/

	if (!regex.test(value)) {
		return 'Invalid SWIFT code';
	}

	return '';
}
