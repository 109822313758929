import { ScheduleDashboardResponse } from 'services/tenantManagementService'
import { Layout } from 'features/Dashboard/Layout'
import { Dashboard } from 'features/Dashboard/Dashboard';
import { TextWidget } from 'features/Dashboard/Widget/TextWidget';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget';

type Props = {
	loading: boolean
	dashboard: ScheduleDashboardResponse
}

const FilteredDashboard = ({ loading, dashboard }: Props) => {
	const { persistedUser } = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const getDurationInDaysWeeksMonths = useCallback(
		(durationInDays: number) => {
			if (durationInDays === undefined) {
				return '-';
			}

			const days = durationInDays;
			const weeks = Math.floor(days / 7);
			const months = Math.floor(days / 30);

			const daySuffix = days > 1 ? 'days' : 'day';
			const weekSuffix = weeks > 1 ? 'weeks' : 'week';
			const monthSuffix = months > 1 ? 'months' : 'month';

			return `
				${days} ${daySuffix} /
				${weeks} ${weekSuffix} /
				${months} ${monthSuffix}
			`;
		},
		[]
	)

	const projectDuration = useMemo(
		() => getDurationInDaysWeeksMonths(dashboard.projectDurationInDays),
		[dashboard.projectDurationInDays, getDurationInDaysWeeksMonths]
	)

	const daysPerPlannedActualAndUserDataMemo = useMemo(
		() => {
			const data: any[] = [];

			for (const name in dashboard.daysPerPlannedActualAndUser) {
				const object = dashboard.daysPerPlannedActualAndUser[name];

				for (const userId in object) {
					data.push({
						username: getUserFullName(parseInt(userId)),
						[name]: object[userId] || 0
					});
				}
			}

			return data;
		},
		[dashboard, getUserFullName]
	)

	const daysPerPlannedActualAndUserFieldsMemo = useMemo(
		() => {
			return [
				{ value: 'actual', name: 'Actual' },
				{ value: 'planned', name: 'Planned' }
			]
		},
		[]
	)

	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='horizontal'>
				<Layout
					orientation='vertical'
					className='col-xl-2 col-sm-4'
				>
					<TextWidget
						title='Project duration'
						value={projectDuration}
						loading={loading}
					/>
					<TextWidget
						title='Percent of completion (%)'
						value={dashboard.percentOfCompletion ? dashboard.percentOfCompletion + '%' : '-'}
						loading={loading}
					/>
				</Layout>
				<Layout
					orientation='vertical'
					className='col-xl-2 col-sm-4'
				>
					<TextWidget
						title='Planned number of man days'
						value={dashboard.plannedNumberOfManDays}
						loading={loading}
						toFixed
					/>
					<TextWidget
						title='Actual number of man days'
						value={dashboard.actualNumberOfManDays}
						loading={loading}
						toFixed
					/>
				</Layout>
				<RotatedColumnSeriesWidget
					className='col-xl-8 col-md-12'
					title='Planned and actual per User'
					id='days_per_planned_actual_and_user_column_series_chart'
					data={daysPerPlannedActualAndUserDataMemo}
					fieldCategory='username'
					fieldValues={daysPerPlannedActualAndUserFieldsMemo}
					loading={loading}
					showValueOnBar
					showAxisGrid
				/>
			</Layout>
		</Dashboard>
	)
}

export default FilteredDashboard
