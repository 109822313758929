import { useMemo } from 'react';
import { ProjectBusinessResponse } from 'services/tenantManagementService';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';

const defaultVisibleColumns = [
	propertyOf<ProjectBusinessResponse>('isActive'),
	propertyOf<ProjectBusinessResponse>('name'),
	propertyOf<ProjectBusinessResponse>('value')
]

export const useTableColumnsMemo = (
	visibleColumns: string[] = defaultVisibleColumns
) => {
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectBusinessResponse>('isActive')]: {
					title: 'Active',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<ProjectBusinessResponse>('name')]: {
					title: 'Potential Business',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectBusinessResponse>('value')]: {
					title: 'Value',
					fieldType: FieldTypeEnum.Currency
				},
			}
		},
		[]
	)

	return useVisibleColumns(columns, visibleColumns);
}
