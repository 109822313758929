import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectRiskResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createRiskAction } from '../action';
import { RiskForm } from '../RiskForm';
import { Dialog } from 'components/Dialog';
import { publishRiskDataChanged } from '../riskDataChanged';
import { ReportComponentParams } from '../../Table/TableButtons';
import { CrudEnum, useGoBackFromCreate } from 'features/Crud';

export const CreateRisk = () => {
	const params: ReportComponentParams = useParams();
	const projectId = parseInt(params.projectId as string);
	const reportId = parseInt(params.reportId);

	const goBackFromCreate = useGoBackFromCreate(true);

	const onSubmitCallback = useCallback(
		async (risk: ProjectRiskResponse) => {
			const bindedAction = createRiskAction.bind(null, reportId, risk);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('New Risk successfully created');
				goBackFromCreate();
				publishRiskDataChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, reportId]
	);

	return(
		<Dialog
			title='Create Risk'
			onClose={goBackFromCreate}
			size='xxxlarge'
			open
		>
			<RiskForm
				projectId={projectId}
				save={onSubmitCallback}
				cancel={goBackFromCreate}
				crud={CrudEnum.Create}
			/>
		</Dialog>
	)
};
