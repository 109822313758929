import { RootState } from "base/reducer/reducer";
import { useSelector } from "react-redux";
import { AlertResponse, AlertTriggersEnum } from "services/tenantManagementService";
import { EntityPrefixEnum, getFormatedId } from "utils/commonHelper";

export const useFormattedIdUsernameAndTrigger = (notification: AlertResponse, entityPrefix?: EntityPrefixEnum) => {
	const { persistedUser } = useSelector((state: RootState) => state);
	const username = persistedUser.itemsMap[notification.initiatorUserId]?.username;

	let triggerText;
	switch (notification.trigger) {
		case AlertTriggersEnum.Created:
			triggerText = 'created by';
			break;
		case AlertTriggersEnum.Updated:
			triggerText = 'updated by';
			break;
		case AlertTriggersEnum.Deleted:
			triggerText = 'deleted by';
			break;
		case AlertTriggersEnum.Submitted:
			triggerText = 'submitted by';
			break;
		case AlertTriggersEnum.Approved:
			triggerText = 'approved by';
			break;
		case AlertTriggersEnum.Rejected:
			triggerText = 'rejected by';
			break;
		case AlertTriggersEnum.Released:
			triggerText = 'released by';
			break;
		case AlertTriggersEnum.Aligned:
			triggerText = 'aligned by';
		break;
	}

	const formattedId = entityPrefix && getFormatedId(entityPrefix, notification.entityId)

	return {
		username,
		triggerText,
		formattedId
	}
}
