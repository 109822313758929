import { WithProjectPicker, pmOrSpmPermission } from 'features/Project';
import { MyTrainingPlanTable } from './MyTrainingPlanTable';
import { TrainingCycleComponentProps, WithTrainingCyclePicker } from 'features/Training/WithTrainingCyclePicker';
import { propertyOf } from 'utils/propertyOf';
import { ModuleActivityEnum, TrainingPermission } from 'services/tenantManagementService';

const MyTrainingPlan = ({ trainingCycle, project, disabledEdit }: TrainingCycleComponentProps) => {
	return (
		<MyTrainingPlanTable
			project={project}
			disabledEdit={disabledEdit}
			trainingCycle={trainingCycle}
		/>
	)
}

const withTrainingCycle = WithTrainingCyclePicker(MyTrainingPlan);
const withProject = WithProjectPicker(withTrainingCycle, ModuleActivityEnum.Training, false, propertyOf<TrainingPermission>('myTrainingPlan'), pmOrSpmPermission);

export default withProject;
