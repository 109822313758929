import { useCallback, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { DateField, Form, InputField, InputNumberField, percentDefaultProps, TextareaField } from 'components/Form';
import { ProjectMilestoneResponse } from 'services/tenantManagementService';
import { getNumberOfDaysBetweenDates } from 'utils/dateTimeUtils';

type Props = {
	milestone?: ProjectMilestoneResponse
	cancel(): void
	save(milestone: ProjectMilestoneResponse): void
}

export const MilestonePlanForm = ({ milestone, cancel, save}: Props) => {
	const [values, setValues] = useState(milestone || new ProjectMilestoneResponse());

	const onSubmitCallback = useCallback(
		async () => await save(values),
		[save, values]
	)

	const onChangeCallback = useCallback(
		(newValues: ProjectMilestoneResponse) => {
			if (newValues.start && newValues.finish) {
				newValues.duration = getNumberOfDaysBetweenDates(newValues.start, newValues.finish)
			} else {
				newValues.duration = undefined;
			}
			setValues(newValues);
		},
		[]
	)

	return (
		<Form
			values={values}
			initialValues={milestone}
			onChange={onChangeCallback}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<InputField
							id='wbs'
							label='WBS'
							isRequired
							maxLength={10}
						/>
						<InputField
							id='name'
							label='Task name'
							isRequired
							maxLength={80}
						/>
						<DateField
							id='start'
							label='Start'
							isRequired
							maxDate={values.finish}
						/>
						<DateField
							id='finish'
							label='Finish'
							isRequired
							minDate={values.start}
						/>
						<InputNumberField
							id='duration'
							label='Duration'
							suffix=' Day(s)'
							disabled
						/>
						<InputNumberField
							id='complete'
							label='Complete'
							{...percentDefaultProps}
							min={0}
							max={100}
						/>
						<DateField
							id='achievedDate'
							label='Achieved date'
						/>
						<TextareaField
							id='milestoneDeviation'
							label='Milestone deviation'
							maxLength={120}
							rows={3}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
