import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserModel } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum } from 'features/Crud';
import { getUserAction } from '../action';
import { UserForm } from '../User/UserForm';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = {
	userId: string;
}

export const ReadUser = () => {
	const [user, setUser] = useState(new UserModel());
	const params: ParamType = useParams();
	const userId = parseInt(params.userId);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getUserAction.bind(null, userId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setUser(response.value || new UserModel());
			}
		},
		[userId]
	)

	const title = `View User - ${user.username ? user.username : ''}`

	return (
		<ContentShell title={title}>
			<WithFetch fetchFunction={fetchDataCallback}>
				<UserForm
					user={user}
					crud={CrudEnum.Read}
					showPartner
				/>
			</WithFetch>
		</ContentShell>
	)
}
