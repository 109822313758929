import { useState, useCallback, useMemo } from 'react';
import { ExportDataModel, GenericFilterModelCollection, ProjectIdRequest, ProjectResponse, ProjectStatusEnum } from 'services/tenantManagementService';
import { ProjectsTable, publishProjectsChanged } from 'features/Project';
import { TableButtons } from './Table/TableButtons';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { getStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { releaseProjectAction } from './action';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { MyProjectsHelp } from './Help/MyProjectsHelp';
import { ContentShell } from 'features/Content/ContentShell';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { BaseColumnModel } from 'components/Table';

const configureViewKey = 'my_projects_table';

export const MyProjects = () => {
	const [selectedProject, setSelectedProject] = useState(new ProjectResponse());
	const [releasing, setReleasing] = useState(false);

	const { persistedProjectStatus } = useSelector((state: RootState) => state);

	const selectProjectCallback = useCallback(
		(data: ProjectResponse[] = []) => setSelectedProject(data[0] || new ProjectResponse()),
		[]
	);

	const isReleaseable = useMemo(
		() => {
			if (!selectedProject.id) {
				return false;
			}
			const currentStatus = persistedProjectStatus.itemsMap[selectedProject.statusId];
			const releasedStatus = getStatusBySemantic(ProjectStatusEnum.Released, persistedProjectStatus.items);

			if (currentStatus && releasedStatus) {
				return currentStatus.nextStates?.includes(releasedStatus.id);
			}

			return false;
		},
		[persistedProjectStatus, selectedProject.statusId, selectedProject.id]
	)

	const isMaintainable = useMemo(
		() => {
			if (!selectedProject.id) {
				return false;
			}
			return isUserPmorSubstitutePmOrSiteAdmin(selectedProject.roleId);
		},
		[selectedProject.id, selectedProject.roleId]
	)

	const releaseCallback = useCallback(
		async () => {
			setReleasing(true);
			const model = new ProjectIdRequest({ projectId: selectedProject.id })
			const bindedAction = releaseProjectAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.PROJECT, selectedProject.id);
				notifications.success(`Project ${id} is released.`)
				publishProjectsChanged();
			}
			setReleasing(false);
		},
		[selectedProject.id]
	)

	const renderTableButtonsCallback = useCallback(
		(tableColumns: BaseColumnModel[], filtersModel: GenericFilterModelCollection, memoExportFunction : (exportDataModel: ExportDataModel) => any) => (
			<TableButtons
				selectedId={selectedProject.id}
				isMaintainable={isMaintainable}
				releasing={releasing}
				isReleaseable={isReleaseable}
				onReleaseClick={releaseCallback}
				tableColumns={tableColumns}
				configureViewKey={configureViewKey}
				filtersModel={filtersModel}
				exportFunction={memoExportFunction}
			/>
		),
		[isMaintainable, isReleaseable, releaseCallback, selectedProject.id, releasing]
	)

	return (
		<ContentShell
			title='Projects'
			FloatingHelpComponent={MyProjectsHelp}
		>
			<ProjectsTable
				onSelectedProjectChange={selectProjectCallback}
				configureViewKey={configureViewKey}
				renderTableButtons={renderTableButtonsCallback}
			/>
		</ContentShell>
	);
};
