import { MenuExpanderIcon } from "components/icons/icons"
import { useCallback, useMemo } from "react";
import styles from './menu.module.scss'

type Props = {
	expanded: boolean
	setExpanded(expanded: boolean): void
}

export const MenuToggler = ({ expanded, setExpanded }: Props) => {
	const toggleMenuExpandedCallback = useCallback(
		() => setExpanded(!expanded),
		[expanded, setExpanded]
	)

	const menuTogglerClassName = useMemo(
		() => expanded ? styles.toggler_expanded : styles.toggler_collapsed,
		[expanded]
	)

	return (
		<div className={styles.toggler_item} onClick={toggleMenuExpandedCallback}>
			<MenuExpanderIcon
				width={16}
				height={16}
				fill='currentColor'
				className={menuTogglerClassName}
			/>
		</div>
	)
}
