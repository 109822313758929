import { RowContainer } from 'components/Layout';
import { CrudButtons } from 'features/Crud';
import ConfigureView from 'features/ConfigureView';
import { Export } from 'features/Export/Export';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection, UserModel } from 'services/tenantManagementService';
import { BaseColumnModel } from 'components/Table';
import Button from 'components/Button';

type Props = {
	selectedUser: UserModel
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
	isUpdatingActivity: boolean
	updateActivity(): Promise<void>
}

export const TableButtons = ({
	selectedUser,
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction,
	isUpdatingActivity,
	updateActivity
}: Props) => {
	return (
		<div>
			<RowContainer justifyContent='space-between'>
				<CrudButtons
					selectedId={selectedUser.id}
					isDeleteable={false}
				/>
				<Button
					text={selectedUser.isActive ? 'Block user' : 'Activate user'}
					onClick={updateActivity}
					isLoading={isUpdatingActivity}
					disabled={!selectedUser.id}
				/>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
					<Export
						filtersModel={filtersModel}
						tableColumns={tableColumns}
						exportAction={exportFunction}
						exportTypeOptions={[ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX]}
						title='Users'
					/>
				</RowContainer>
			</RowContainer>
		</div>
	)
}
