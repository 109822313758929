import { useCallback, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { CheckboxField, DateField, Form, InputField, TextareaField } from 'components/Form';
import { ProjectSubcontractorResponse } from 'services/tenantManagementService';
import { ReportItemStatusSelectField } from '../ReportItemStatuses';

type Props = {
	performance?: ProjectSubcontractorResponse
	cancel(): void
	save(performance: ProjectSubcontractorResponse): void
}

export const SubcontractorPerformanceForm = ({ performance, cancel, save }: Props) => {
	const [values, setValues] = useState(performance || new ProjectSubcontractorResponse());

	const onSubmitCallback = useCallback(
		async () => await save(values),
		[save, values]
	)

	return (
		<Form
			values={values}
			initialValues={performance}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<CheckboxField
							id='isActive'
							label='Active'
						/>
						<InputField
							id='name'
							label='Subcontractor'
							isRequired
							disabled={!!performance}
							maxLength={40}
						/>
						<TextareaField
							id='deliverable'
							label='Deliverable'
							isRequired
							rows={2}
						/>
						<ReportItemStatusSelectField isRequired />
						<DateField
							id='achievedDate'
							label='Achieved date'
						/>
						<TextareaField
							id='issue'
							label='Issue'
							isRequired
							rows={2}
						/>
						<TextareaField
							id='solution'
							label='Solution'
							rows={2}
						/>
						<TextareaField
							id='resolution'
							label='Resolution'
							rows={2}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
