import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useApplyStatusColorCallback, useFormattedProjectIdNameCallback, useStatusCallback, useUsernameCallback } from 'features/TableColumns/persistedHooks';
import { TaskResponse, SimpleProjectIdNameModel, TimesheetResponse, TimeTravelStatusEnum, TimeTravelStatusResponse, UserModel } from 'services/tenantManagementService';
import { formatTimeWithoutAmOrPm } from 'utils/dateTimeUtils';
import { propertyOf } from 'utils/propertyOf';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { RootState } from 'base/reducer/reducer';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { timeSpanEditor } from 'components/Table/helpers/TimeSpanEditor';
import { IProjectOrCategory } from 'features/Project';

const defaultVisibleColumns = [
    propertyOf<TimesheetResponse>('projectOrCategoryId'),
    propertyOf<TimesheetResponse>('effort'),
    propertyOf<TimesheetResponse>('description'),
	propertyOf<TimesheetResponse>('statusId'),
	propertyOf<TimesheetResponse>('approvedOrRejectedByUserId'),
]

export const useTableColumnsMemo = ( configureViewKey: string, tickets: SimpleProjectIdNameModel[], changeRequests: SimpleProjectIdNameModel[], tasks: TaskResponse[], projectsOrCategories: IProjectOrCategory[], disabled?: boolean ) => {
	const {
		persistedTimeTravelNonProjectCategories,
		persistedProject,
		persistedTimeAndTravelStatus,
		persistedTimesheetsGeneralConfiguration,
		persistedUser,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUsername = useUsernameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedTimeAndTravelStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedTimeAndTravelStatus);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<TimesheetResponse>('projectOrCategoryId')]: {
					title: 'Project or Category Id *',
					fieldType: FieldTypeEnum.Number,
					minWidth: 250,
					disableFilter: true,
					disableSort: true,
					formatter: (cell: any) : string => {
						const data: TimesheetResponse = cell.getData();
						if (data.isProjectConnected) {
							return formatProjectIdName(data.projectOrCategoryId);
						} else {
							return persistedTimeTravelNonProjectCategories.itemsMap[data.projectOrCategoryId]?.name || '';
						}
					},
					editor: 'list',
					editable: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						return !disabled &&
							(!persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess ||
								(!isStatusBySemantic(TimeTravelStatusEnum.Approved, data.statusId, persistedTimeAndTravelStatus.itemsMap) &&
								!isStatusBySemantic(TimeTravelStatusEnum.Released, data.statusId, persistedTimeAndTravelStatus.itemsMap)))
					},
					editorParams: {
						values: projectsOrCategories.map(item => {
							return {
								label: item.label,
								// projectOrCategoryId is not unique, isProjectConnected must be used somehow
								// if you change this value, change parsing inside TimesheetsTable cellEditedCallback
								value: `${item.projectOrCategoryId}_${item.isProjectConnected}`
							}
						}),
						autocomplete: true,
						listOnEmpty: true,
						allowEmpty: true,
						placeholderEmpty: 'No options'
					}
				},
				[propertyOf<TimesheetResponse>('effort')]: {
					title: 'Effort *',
					fieldType: FieldTypeEnum.Number,
					disableFilter: true,
					editor: timeSpanEditor,
					editable: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						return !disabled &&
							(!persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess ||
								(!isStatusBySemantic(TimeTravelStatusEnum.Approved, data.statusId, persistedTimeAndTravelStatus.itemsMap) &&
								!isStatusBySemantic(TimeTravelStatusEnum.Released, data.statusId, persistedTimeAndTravelStatus.itemsMap)))
					},
					formatter: (cell: any) => formatTimeWithoutAmOrPm(cell.getValue()),
					bottomCalc: 'sum',
					bottomCalcFormatter: (cell: any) => `∑ ${formatTimeWithoutAmOrPm(cell.getValue()) || ''}`
				},
				[propertyOf<TimesheetResponse>('description')]: {
					title: 'Description',
					fieldType: FieldTypeEnum.String,
					minWidth: 450,
					editor: 'input',
					editable: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						return !disabled &&
							(!persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess ||
								(!isStatusBySemantic(TimeTravelStatusEnum.Approved, data.statusId, persistedTimeAndTravelStatus.itemsMap) &&
								!isStatusBySemantic(TimeTravelStatusEnum.Released, data.statusId, persistedTimeAndTravelStatus.itemsMap)))
					},
				},
				[propertyOf<TimesheetResponse>('customText1')]: {
					title: 'Text 1',
					fieldType: FieldTypeEnum.String,
					minWidth: 150,
					editor: 'input',
					editable: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						return !disabled &&
							(!persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess ||
								(!isStatusBySemantic(TimeTravelStatusEnum.Approved, data.statusId, persistedTimeAndTravelStatus.itemsMap) &&
								!isStatusBySemantic(TimeTravelStatusEnum.Released, data.statusId, persistedTimeAndTravelStatus.itemsMap)))
					},
				},
				[propertyOf<TimesheetResponse>('customText2')]: {
					title: 'Text 2',
					fieldType: FieldTypeEnum.String,
					minWidth: 150,
					editor: 'input',
					editable: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						return !disabled &&
							(!persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess ||
								(!isStatusBySemantic(TimeTravelStatusEnum.Approved, data.statusId, persistedTimeAndTravelStatus.itemsMap) &&
								!isStatusBySemantic(TimeTravelStatusEnum.Released, data.statusId, persistedTimeAndTravelStatus.itemsMap)))
					},
				},
				[propertyOf<TimesheetResponse>('customText3')]: {
					title: 'Text 3',
					fieldType: FieldTypeEnum.String,
					minWidth: 150,
					editor: 'input',
					editable: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						return !disabled &&
							(!persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess ||
								(!isStatusBySemantic(TimeTravelStatusEnum.Approved, data.statusId, persistedTimeAndTravelStatus.itemsMap) &&
								!isStatusBySemantic(TimeTravelStatusEnum.Released, data.statusId, persistedTimeAndTravelStatus.itemsMap)))
					},
				},
				[propertyOf<TimesheetResponse>('taskId')]: {
					title: 'Task Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.TASK,
					minWidth: 100,
					editor: 'list',
					editable: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						return !disabled &&
							(!persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess ||
								(!isStatusBySemantic(TimeTravelStatusEnum.Approved, data.statusId, persistedTimeAndTravelStatus.itemsMap) &&
								!isStatusBySemantic(TimeTravelStatusEnum.Released, data.statusId, persistedTimeAndTravelStatus.itemsMap)))
					},
					editorParams: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						if (data.isProjectConnected) {
							const filteredTasks = tasks.filter(task => task.projectOrCategoryId === data.projectOrCategoryId);
							const values = filteredTasks.map(task => {
								return {
									label: getFormatedId(EntityPrefixEnum.TASK, task.id),
									value: task.id
								}
							})

							return {
								values: values,
								autocomplete: true,
								listOnEmpty: true,
								allowEmpty: true,
								placeholderEmpty: 'No options'
							}
						} else {
							return {
								values: [],
								placeholderEmpty: 'Empty for Non project categories'
							}
						}
					}
				},
				[propertyOf<TimesheetResponse>('changeRequestId')]: {
					title: 'Change request Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.CHANGE_REQUEST,
					minWidth: 100,
					editor: 'list',
					editable: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						return !disabled &&
							(!persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess ||
								(!isStatusBySemantic(TimeTravelStatusEnum.Approved, data.statusId, persistedTimeAndTravelStatus.itemsMap) &&
								!isStatusBySemantic(TimeTravelStatusEnum.Released, data.statusId, persistedTimeAndTravelStatus.itemsMap)))
					},
					editorParams: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						if (data.isProjectConnected) {
							const filteredChangeRequests = changeRequests.filter(cr => cr.projectId === data.projectOrCategoryId);
							const values = filteredChangeRequests.map(cr => {
								return {
									label: `${getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, cr.id)} - ${cr.name} `,
									value: cr.id
								}
							})

							return {
								values: values,
								autocomplete: true,
								listOnEmpty: true,
								allowEmpty: true,
								placeholderEmpty: 'No options'
							}
						} else {
							return {
								values: [],
								placeholderEmpty: 'Empty for Non project categories'
							}
						}
					}
				},
				[propertyOf<TimesheetResponse>('ticketId')]: {
					title: 'Ticket Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.TICKET,
					minWidth: 100,
					editor: 'list',
					editable: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						return !disabled &&
							(!persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess ||
								(!isStatusBySemantic(TimeTravelStatusEnum.Approved, data.statusId, persistedTimeAndTravelStatus.itemsMap) &&
								!isStatusBySemantic(TimeTravelStatusEnum.Released, data.statusId, persistedTimeAndTravelStatus.itemsMap)))
					},
					editorParams: (cell: any) => {
						const data: TimesheetResponse = cell.getRow().getData();
						if (data.isProjectConnected) {
							const filteredTickets = tickets.filter(ticket => ticket.projectId === data.projectOrCategoryId);
							const values = filteredTickets.map(ticket => {
								return {
									label: `${getFormatedId(EntityPrefixEnum.TICKET, ticket.id)} - ${ticket.name} `,
									value: ticket.id
								}
							});

							return {
								values: values,
								autocomplete: true,
								listOnEmpty: true,
								allowEmpty: true,
								placeholderEmpty: 'No options'
							}
						} else {
							return {
								values: [],
								placeholderEmpty: 'Empty for Non project categories'
							}
						}
					}
				},
				[propertyOf<TimesheetResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTimeAndTravelStatus.items,
					getItemId: (option: TimeTravelStatusResponse) => option.id,
					getItemText: (option: TimeTravelStatusResponse) => option.name,
				},
				[propertyOf<TimesheetResponse>('approvedOrRejectedByUserId')]: {
					title: 'Approved/Rejected by',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
			};
		},
		[persistedUser, tickets, changeRequests, tasks, getUsername, applyStatusColor, getStatus, formatProjectIdName, disabled, persistedTimeTravelNonProjectCategories, persistedTimeAndTravelStatus, persistedTimesheetsGeneralConfiguration, projectsOrCategories]
	)

	return useVisibleColumns(columns, visibleColumns);
}
