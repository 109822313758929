import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoCompleteField, Form, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { TenantIsActiveResponse, TrainingDashboardFilterRequest, ProjectTeamMemberResponse, TrainingPermission, ModuleActivityEnum } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { propertyOf } from 'utils/propertyOf';
import { getTrainingCyclesAction } from 'containers/Training/TrainingCycles/actions';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getProjectTeamsAction } from '../../../Projects/MyProjects/MaintainProject/Tabs/Teams/action';
import { getProjectRolesAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Roles/action';
import { getAllTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import Button from 'components/Button';
import { ProjectSelectField, pmOrSpmOrOumPermission } from 'features/Project';

export class FilterModel extends TrainingDashboardFilterRequest {
	projectId!: number
	cycleId!: number

	constructor(data?: FilterModel) {
		super(data);
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
    }
}

type Props = {
	onSubmit: (model: FilterModel) => Promise<void>
	filterFormModel?: FilterModel
	onSave: (filterFormModel: FilterModel) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const { persistedUser } = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const [values, setValues] = useState(new FilterModel(filterFormModel));

	useEffect(
		() => {
			setValues(new FilterModel(filterFormModel));
		},
		[filterFormModel]
	)

	const [cycles, setCycles] = useState<TenantIsActiveResponse[]>([]);
	const [fetchingCycles, setFetchingCycles] = useState(false);

	const fetchCyclesCallback = useCallback(
		async (projectId: number) => {
			if (!projectId) {
				return;
			}
			setFetchingCycles(true);

			const bindedAction = getTrainingCyclesAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.items) {
				const activeItems = response.items.filter(item => item.isActive);
				setCycles(activeItems);
			}

			setFetchingCycles(false);
		},
		[]
	)

	useEffect(
		() => {
			fetchCyclesCallback(values.projectId);
		},
		[values.projectId, fetchCyclesCallback]
	)

	const [participants, setParticipants] = useState<ProjectTeamMemberResponse[]>([]);
	const [fetchingParticipants, setFetchingParticipants] = useState(false);

	const fetchParticipantsCallback = useCallback(
		async (projectId: number) => {
			if (!projectId) {
				return;
			}
			setFetchingParticipants(true);

			const bindedAction = getAllTeamMembersAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setParticipants(response.items || []);
			}

			setFetchingParticipants(false);
		},
		[]
	)

	useEffect(
		() => {
			fetchParticipantsCallback(values.projectId);
		},
		[values.projectId, fetchParticipantsCallback]
	)

	const [teams, setTeams] = useState<TenantIsActiveResponse[]>([]);
	const [fetchingTeams, setFetchingTeams] = useState(false);

	const fetchTeamsCallback = useCallback(
		async (projectId: number) => {
			if (!projectId) {
				return;
			}
			setFetchingTeams(true);

			const bindedAction = getProjectTeamsAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setTeams(response.items || []);
			}

			setFetchingTeams(false);
		},
		[]
	)

	useEffect(
		() => {
			fetchTeamsCallback(values.projectId);
		},
		[values.projectId, fetchTeamsCallback]
	)

	const [roles, setRoles] = useState<TenantIsActiveResponse[]>([]);
	const [fetchingRoles, setFetchingRoles] = useState(false);

	const fetchRolesCallback = useCallback(
		async (projectId: number) => {
			if (!projectId) {
				return;
			}
			setFetchingRoles(true);

			const bindedAction = getProjectRolesAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setRoles(response.items || []);
			}

			setFetchingRoles(false);
		},
		[]
	)

	useEffect(
		() => {
			fetchRolesCallback(values.projectId);
		},
		[values.projectId, fetchRolesCallback]
	)

	const onProjectChangeCallback = useCallback(
		(_newProjectId: number | undefined, _oldValues: FilterModel) => ({
			[propertyOf<FilterModel>('cycleId')]: undefined,
			[propertyOf<FilterModel>('participants')]: undefined,
			[propertyOf<FilterModel>('projectTeamIds')]: undefined,
			[propertyOf<FilterModel>('projectRoleIds')]: undefined
		}),
		[]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new FilterModel(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectSelectField
							id={propertyOf<FilterModel>('projectId')}
							isRequired
							updateDependants={onProjectChangeCallback}
							isProjectDashboard
							moduleEnum={ModuleActivityEnum.Training}
							teamMemberPermission={propertyOf<TrainingPermission>('dashboard')}
							userRolePermission={pmOrSpmOrOumPermission}
						/>
						<AutoCompleteField
							id={propertyOf<FilterModel>('cycleId')}
							label='Training cycle'
							items={cycles}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							disabled={!values.projectId}
							loading={fetchingCycles}
							isRequired
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('participants')}
							label='Participant'
							items={participants}
							getItemId={(item: ProjectTeamMemberResponse) => item.id}
							getItemText={(item: ProjectTeamMemberResponse) => getUserFullName(item.userId)}
							disabled={!values.projectId}
							loading={fetchingParticipants}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('projectTeamIds')}
							label='Project team'
							items={teams}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							disabled={!values.projectId}
							loading={fetchingTeams}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('projectRoleIds')}
							label='Project role'
							items={roles}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							disabled={!values.projectId}
							loading={fetchingRoles}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
					disabled={!values.projectId || !values.cycleId}
				/>
			)}
		/>
	)
}
