import Button from "components/Button";
import { RowContainer } from "components/Layout";
import { useCallback } from "react";
import { gantt } from "../GanttChart";

export const CommandButtons = () => {
	const undo = useCallback(
		() => {
			gantt.undo();
		},
		[]
	)

	const redo = useCallback(
		() => {
			gantt.redo()
		},
		[]
	)

	return (
		<RowContainer>
			<Button
				text='Undo'
				color='neutral'
				onClick={undo}
			/>
			<Button
				text='Redo'
				color='neutral'
				onClick={redo}
			/>
		</RowContainer>
	)
}
