import { TabType } from 'components/Tabs/Tabs';
import { RiskTypes } from './Tabs/RiskType/RiskTypes';

export const configureRiskTabs: TabType[] = [
	{
		id: '1',
		title: 'Risk type',
		route: 'type',
		component: RiskTypes,
	},
]
