import { useCallback, useState } from 'react';
import { Form, InputField, InputNumberField, FormGroup, FileInput } from 'components/Form';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { CompanyRequestModel, CompanyResponseModel, InsertAttachmentRequest } from 'services/tenantManagementService';
import { ibanValidator, swiftValidator } from 'utils/validators';
import { useGoBackCallback } from 'features/Routes/historyHooks';
import { propertyOf } from 'utils/propertyOf';
import { LogoImage } from 'features/Logo/LogoImage';

type Props = {
	companyInfo: CompanyResponseModel;
	onSave: (companyInfo: CompanyRequestModel) => void;
}

export const CompanyInfoForm = ({ companyInfo, onSave }: Props) => {
	const [values, setValues] = useState(companyInfo || new CompanyResponseModel());
	const [headerLogo, setHeaderLogo] = useState<string | undefined>(values.logoInHeader);
	const [pdfLogo, setPdfLogo] = useState<string | undefined>(values.logoInPDF);
	const goBackCallback = useGoBackCallback();

	const setHeaderLogoCallback = useCallback(
		(attachments: InsertAttachmentRequest[]) => {
			if (attachments && attachments.length === 1) {
				setHeaderLogo(attachments[0].base64EncodedFile);
			} else {
				setHeaderLogo(undefined)
			}
		},
		[]
	)

	const setPdfLogoCallback = useCallback(
		(attachments: InsertAttachmentRequest[]) => {
			if (attachments && attachments.length === 1) {
				setPdfLogo(attachments[0].base64EncodedFile);
			} else {
				setPdfLogo(undefined)
			}
		},
		[]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const newCompanyInfo = new CompanyRequestModel({
				...values,
				logoInHeader: headerLogo,
				logoInPDF: pdfLogo
			});
			return onSave && await onSave(newCompanyInfo);
		},
		[onSave, values, headerLogo, pdfLogo]
	)

	return (
		<Form
			values={values}
			initialValues={companyInfo}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<FormGroup title='Company'>
							<InputField
								id={propertyOf<CompanyResponseModel>('companyName')}
								label='Company Name'
								isRequired
							/>
							<InputField
								id={propertyOf<CompanyResponseModel>('address')}
								label='Address'
								isRequired
							/>
							<InputField
								id={propertyOf<CompanyResponseModel>('postalCode')}
								label='Postal code'
								isRequired
							/>
							<InputField
								id={propertyOf<CompanyResponseModel>('city')}
								label='City'
								isRequired
							/>
							<InputField
								id={propertyOf<CompanyResponseModel>('phone')}
								label='Phone'
							/>
							<InputField
								id={propertyOf<CompanyResponseModel>('webSite')}
								label='Web site'
							/>
							<InputField
								id={propertyOf<CompanyResponseModel>('email')}
								label='E-mail'
								isEmail
							/>
							<InputField
								id={propertyOf<CompanyResponseModel>('regNumber')}
								label='Reg.no.'
							/>
							<InputNumberField
								id={propertyOf<CompanyResponseModel>('taxVATNumber')}
								label='Tax/VAT No.'
								isRequired
							/>
						</FormGroup>
					</SmartItem>
					<SmartItem>
						<FormGroup title='Bank'>
							<InputField
								id={propertyOf<CompanyResponseModel>('bankName')}
								label='Bank name'
							/>
							<InputField
								id={propertyOf<CompanyResponseModel>('iban')}
								label='IBAN'
								validator={ibanValidator}
							/>
							<InputField
								id={propertyOf<CompanyResponseModel>('swift')}
								label='SWIFT'
								validator={swiftValidator}
							/>
							<InputField
								id={propertyOf<CompanyResponseModel>('bankAccountNumber')}
								label='Bank account no'
							/>
						</FormGroup>
					</SmartItem>
					<SmartItem>
						<FormGroup title='Logo'>
							<SmartFormGroup label='Header Logo'>
								<FileInput
									onChange={setHeaderLogoCallback}
									display={<LogoImage src={headerLogo} />}
								/>
							</SmartFormGroup>
							<SmartFormGroup label='PDF Logo'>
								<FileInput
									onChange={setPdfLogoCallback}
									display={<LogoImage src={pdfLogo} />}
								/>
							</SmartFormGroup>
						</FormGroup>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
