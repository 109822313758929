import { useState, useCallback, useMemo } from 'react';
import { generateNumberId } from 'base/id';
import { ColumnContainer } from 'components/Layout';
import Button from 'components/Button';
import { TableField, CheckboxField, Form, InputField } from 'components/Form';
import notifications from 'components/Notification/notification';
import { DeleteTenantIndetifyRequest, InsertTicketAssignedGroupRequest, TicketAssignedGroupDeltaRequest, TicketAssignedGroupsResponse, UpdateTicketAssignedGroupRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTicketAssignedGroupsAction, updateTicketAssignedGroupsAction } from './action';
import WithFetch from 'features/Fetch/WithFetch';
import { createDelta, unpackDelta } from 'utils/commonHelper';

type TableFieldObject = {
	groups: TicketAssignedGroupsResponse[]
}

export const TicketAssignedGroups = () => {
	const [values, setValues] = useState<TableFieldObject>({ groups: [] });
	const [initialValues, setInitialValues] = useState<TableFieldObject>({ groups: [] });
	const [refetching, setRefetching] = useState(false);

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getTicketAssignedGroupsAction);
			if (response.success) {
				const items = response.items || [];
				const values = { groups: items };
				setValues(values);
				setInitialValues(values);
			}
		},
		[]
	);

	const refetchDataCallback = useCallback(
		async () => {
			setRefetching(true);
			await fetchDataCallback();
			setRefetching(false);
		},
		[fetchDataCallback]
	);

	const addCallback = useCallback(
		() => {
			const id = generateNumberId();

			setValues((state: TableFieldObject) => {
				const newModel = new TicketAssignedGroupsResponse({
					id,
				    name: '',
				    description: '',
					isActive: true,
					emails: ''
				});

				const newState = { ...state };
				newState.groups = [...newState.groups, newModel];

				return newState;
			})
		},
		[]
	);

	const handleCancel = useCallback(
		() => setValues(initialValues),
		[initialValues]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const delta = createDelta<TicketAssignedGroupsResponse>
			(
				initialValues.groups,
				values.groups,
				InsertTicketAssignedGroupRequest,
				UpdateTicketAssignedGroupRequest,
				TicketAssignedGroupDeltaRequest,
				DeleteTenantIndetifyRequest
			);

			const bindedAction = updateTicketAssignedGroupsAction.bind(null, delta)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Ticket assigned groups are updated.');
				refetchDataCallback();
				setInitialValues(values);
			} else {
				const errors = convertResponseErrors(response)
				const [groupErrors, newGroups] = unpackDelta(errors, delta, values.groups, initialValues.groups);
				setValues({...values, groups: newGroups});
				return { groups: groupErrors };
			}
		},
		[initialValues, values, refetchDataCallback]
	)

	const uniqueFieldNames = useMemo(
		() => [{id: 'name', label: 'Assigned group'}],
		[]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback} refetching={refetching}>
			<Form
				values={values}
				initialValues={initialValues}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={handleCancel}
				render={() => (
					<ColumnContainer margin='medium'>
						<Button
							onClick={addCallback}
							text='Add'
						/>
						<TableField
							id='groups'
							uniqueFieldNames={uniqueFieldNames}
							headers={[
								{ label: 'Active', size: 1 },
								{ label: 'Assigned group', size: 2, isRequired: true },
								{ label: 'Explanation', size: 3 },
								{ label: 'Email recipients', size: 5 }
							]}
							getRowData={() => {
								return {
									isDeletable: true,
									fields: [
										<CheckboxField
											id='isActive'
										/>,
										<InputField
											id='name'
											isRequired
											maxLength={25}
										/>,
										<InputField
											id='description'
										/>,
										<InputField
											id='emails'
											isRequired
											maxLength={240}
										/>
									]
								}
							}}
						/>
					</ColumnContainer>
				)}
			/>
		</WithFetch>
	)
};
