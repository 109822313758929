import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectRiskResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getRiskAction, updateRiskAction } from '../action';
import { RiskForm } from '../RiskForm';
import WithFetch from 'features/Fetch/WithFetch';
import { Dialog } from 'components/Dialog';
import { publishRiskDataChanged } from '../riskDataChanged';
import { getFormatedId, EntityPrefixEnum } from 'utils/commonHelper';
import { CrudEnum, useGoBackFromUpdate } from 'features/Crud';
import { ReportComponentParams } from '../../Table/TableButtons';

type ParamType = ReportComponentParams & {
	riskId: string;
}

export const UpdateRisk = () => {
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId as string);
	const reportId = parseInt(params.reportId);
	const riskId = parseInt(params.riskId);

	const [risk, setRisk] = useState(new ProjectRiskResponse ());

	const goBackFromUpdate = useGoBackFromUpdate(true);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getRiskAction.bind(null, reportId, riskId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setRisk(response.value || new ProjectRiskResponse ());
			}
		},
		[reportId, riskId]
	)

	const onSubmitCallback = useCallback(
		async (risk: ProjectRiskResponse) => {
			const bindedAction = updateRiskAction.bind(null, reportId, risk);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Risk successfully updated');
				goBackFromUpdate();
				publishRiskDataChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, reportId]
	);

	return (
		<Dialog
			title={`Update Risk: ${getFormatedId(EntityPrefixEnum.RISK, riskId)}`}
			onClose={goBackFromUpdate}
			size='xxxlarge'
			open
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<RiskForm
					risk={risk}
					projectId={projectId}
					save={onSubmitCallback}
					cancel={goBackFromUpdate}
					crud={CrudEnum.Update}
				/>
			</WithFetch>
		</Dialog>
	)
};
