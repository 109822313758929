import { Link } from 'react-router-dom';
import { HOME_ROUTE } from 'base/constants/routes';
import { LogoImage } from './LogoImage';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

export const Logo = () => {
	const { persistedCompanyInfo } = useSelector((state: RootState) => state);

	return (
		<Link to={HOME_ROUTE}>
			{persistedCompanyInfo.value?.logoInHeader && <LogoImage src={persistedCompanyInfo.value.logoInHeader} />}
		</Link>
	)
}
