import { ColumnContainer } from 'components/Layout'
import { LocalTable } from 'components/Table'
import { persistProjectRolesAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Roles/action'
import { persistProjectTeamsAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Teams/action'
import { setConfigureViewTableAction } from 'features/ConfigureView'
import { ContentShell } from 'features/Content/ContentShell'
import { WithProjectPicker, ComponentProps } from 'features/Project'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ModuleActivityEnum, ProjectTeamMemberUserResponse } from 'services/tenantManagementService'
import { exportProjectTeamMembersByProjectAction, getAllTeamMemberUsersAction } from './action'
import { TableButtons } from './Table/TableButtons'
import { useTableColumnsMemo } from './Table/tableColumns'
import { tryCatchJsonByAction } from 'utils/fetchUtils'

const configureViewKey = 'communication_contact_list_table';

const ContactList = ({ project }: ComponentProps) => {
	const [projectTeamMemberUsers, setProjectTeamMemberUsers] = useState<ProjectTeamMemberUserResponse[]>([]);
	const [isFetching, setIsFetching] = useState(false);

	const tableColumns = useTableColumnsMemo(configureViewKey, project.id);

	const fetchDataCallback = useCallback(
		async () => {
			setIsFetching(true);

			const bindedAction = getAllTeamMemberUsersAction.bind(null, project.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setProjectTeamMemberUsers(response.items || [])
			}

			setIsFetching(false);
		},
		[project.id]
	)

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			if (project.id) {
				persistProjectTeamsAction(project.id);
				persistProjectRolesAction(project.id);
			}
		},
		[project.id]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	const memoExportFunction = useMemo(
		() => exportProjectTeamMembersByProjectAction.bind(null, project.id),
		[project.id]
	)

	return (
		<ContentShell title='Contact list'>
			<ColumnContainer margin='medium'>
				<TableButtons
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					exportFunction={memoExportFunction}
				/>
				<LocalTable
					columns={tableColumns}
					data={projectTeamMemberUsers}
					reorderColumns={reorderColumnsCallback}
					hasPagination
					isLoading={isFetching}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectPicker(ContactList, ModuleActivityEnum.Communication, true);
