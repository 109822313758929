import { RootState } from "base/reducer/reducer";
import { AutoCompleteField, TextareaField } from "components/Form"
import { SmartContainer, SmartItem } from "components/SmartContainer/SmartContainer";
import { isStatusBySemantic } from "features/StatusResponse/statusResponse";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { TenantIsActiveResponse, TicketResponse, TicketStatusEnum } from "services/tenantManagementService"
import { propertyOf } from "utils/propertyOf"
import { TicketFormTabsProps } from "../Tabs/TicketFormTabs";

export const Closing = ({ ticket }: TicketFormTabsProps) => {
	const {
		statusId,
	} = ticket;

	const {
		persistedTicketStatus,
		persistedTicketClosingId
	} = useSelector((state: RootState) => state);

	const isStatusClosedMemo = useMemo(
		() => isStatusBySemantic(TicketStatusEnum.Closed, statusId, persistedTicketStatus.itemsMap),
		[statusId, persistedTicketStatus.itemsMap]
	)

	return (
		<SmartContainer>
			<SmartItem>
				{isStatusClosedMemo &&
					<>
						<AutoCompleteField
							id={propertyOf<TicketResponse>('closingId')}
							label='Closing ID'
							items={persistedTicketClosingId.activeItems}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							getItemDescription={(item: TenantIsActiveResponse) => item.description}
							loading={persistedTicketClosingId.fetching}
						/>
						<TextareaField
							id={propertyOf<TicketResponse>('closingNote')}
							label='Closing note'
							rows={3}
						/>
					</>
				}
				<TextareaField
					id={propertyOf<TicketResponse>('workaround')}
					label='Workaround'
					maxLength={1000}
					rows={8}
				/>
			</SmartItem>
		</SmartContainer>
	)
}
