import path from 'path';
import { CrudEnum } from 'features/Crud';
import { RootRouteEnum } from 'features/Routes';
import { TicketsSubRoutesEnum } from './routes';

export const getReadTicketRoute = (ticketId: number) => path.join('/', RootRouteEnum.TICKETS, TicketsSubRoutesEnum.TICKETS, CrudEnum.Read, String(ticketId))

export const getCreateTicketRoute = () => path.join('/', RootRouteEnum.TICKETS, TicketsSubRoutesEnum.TICKETS, CrudEnum.Create)

export const getUpdateTicketRoute = (ticketId: number) => path.join('/', RootRouteEnum.TICKETS, TicketsSubRoutesEnum.TICKETS, CrudEnum.Update, String(ticketId))
