import { getTenantId } from 'features/Tenant/tenantUtils';
import { UserClient } from 'services/tenantManagementService';

export const getAllSiteAdminsAction = () => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.getAllSiteAdmins(tenantId);
}

export const insertSiteAdminAction = (userId: number) => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.setUserAsSiteAdmin(tenantId, userId);
}

export const removeSiteAdminAction = (userId: number) => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.setSiteAdminAsCompanyUser(tenantId, userId);
}
