// TODO:gantt expand/collapse po nivoima

import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Gantt, GanttStatic, gantt as ganttLibraryInstance } from 'dhtmlx-gantt'
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import './gantt.override.scss';
import { RowContainer, VerticalSeparator } from 'components/Layout';
import { ZoomButtons } from './Toolbar/ZoomButtons';
import { CommandButtons } from './Toolbar/CommandButtons';
import { CrudButtons } from './Toolbar/CrudButtons';
import { zoomConfig } from './scales';
import { ActionButtons } from './Toolbar/ActionButtons';
import { addResourcesConfigLayout, createResourcesDatastore } from './resourcesHelper';
import { addGanttConfigLayout } from './ganttHelper';
import { ScheduleUtilizationRowDashboardResponse } from 'services/tenantManagementService';

export enum LinkTypeEnum {
	startToStart = ganttLibraryInstance.config.links.start_to_start,
	startToFinish = ganttLibraryInstance.config.links.start_to_finish,
	finishToStart = ganttLibraryInstance.config.links.finish_to_start,
	finishToFinish = ganttLibraryInstance.config.links.finish_to_finish
}

export let gantt: GanttStatic;
export let resourcesUtilizationGlobal: ScheduleUtilizationRowDashboardResponse[] = [];

let markerTodayInterval: NodeJS.Timer | undefined;

// gantt.plugins({ marker: true }) is added somewhere bellow
const addMarkerToday = (gantt: GanttStatic) => {
	const dateToStr = gantt.date.date_to_str(gantt.config.task_date);
	const todayMarkerId = gantt.addMarker({
		start_date: new Date(),
		css: 'today',
		text: 'Now',
		title: dateToStr(new Date())
	});
	markerTodayInterval = setInterval(() => {
		const today = gantt.getMarker(todayMarkerId);
		today.start_date = new Date();
		today.title = dateToStr(today.start_date);
		gantt.updateMarker(todayMarkerId);
	}, 1000 * 60);
}

export const getTaskParents = (gantt: GanttStatic, taskId: number) => {
	const task = gantt.getTask(taskId);
	let parentId = task.parent;

	const parents: any[] = [];
	// dhtmlx sets task.parent = '0' when there is no parent
	// eslint-disable-next-line eqeqeq
	while (parentId && parentId != '0') {
		let currentTask = gantt.getTask(parentId);
		parents.push(currentTask);

		parentId = currentTask.parent;
	}

	return parents;
}

type Props = {
	columns: any[]
	tasks: any[]
	links: any[]
	resources?: { id: number | string, label: string, parent: number | undefined }[]
	resourcesUtilization?: ScheduleUtilizationRowDashboardResponse[]
	resourceColumns?: any[]
	hideGantt?: boolean
	showResourcesUtilization?: boolean
	deriveTaskDatesFromSubtasks?: boolean
	onCreateTaskClick(parentId: string): void
	onUpdateTaskClick(taskId: string): void
	onTaskUpdate(item: any): void
	onTasksUpdate(items: any[]): void
	onTaskDelete(id: number): void
	onTasksReorder(items: any[]): void
	onLinkAdd(link: any): Promise<number | undefined> // return value is database link id
	onLinkDelete(id: number): void
	disabled: boolean
}

const GanttChartForwarded = ({
	columns, tasks, links, resources = [], resourcesUtilization, resourceColumns = [], hideGantt, showResourcesUtilization,
	deriveTaskDatesFromSubtasks = false,
	onCreateTaskClick, onUpdateTaskClick, onTaskUpdate, onTasksUpdate, onTaskDelete, onTasksReorder,
	onLinkAdd, onLinkDelete, disabled
}: Props, ref) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [selectedTask, setSelectedTask] = useState<any>()

	resourcesUtilizationGlobal = resourcesUtilization || [];

	const createTaskCallback = useCallback(
		(task: any) => {
			gantt.addTask(task, task.parent);
			gantt.autoSchedule(task.id);

			// update parents
			if (deriveTaskDatesFromSubtasks) {
				const parentTasks = getTaskParents(gantt, task.id);
				onTasksUpdate(parentTasks);
			}
		},
		[deriveTaskDatesFromSubtasks, onTasksUpdate]
	)

	const updateTaskCallback = useCallback(
		(task: any) => {
			gantt.updateTask(task.id, task);
			gantt.autoSchedule(task.id);

			// update parents
			if (deriveTaskDatesFromSubtasks) {
				const parentTasks = getTaskParents(gantt, task.id);
				onTasksUpdate(parentTasks);
			}
		},
		[deriveTaskDatesFromSubtasks, onTasksUpdate]
	)

	const getSelectedTaskCallback = useCallback(
		() => {
			const taskId = gantt.getSelectedId();
			if (taskId) {
				return gantt.getTask(taskId);
			}
			return undefined;
		},
		[]
	)

	const getTaskChildrensCallback = useCallback(
		(taskId: number | undefined) => {
			return gantt.getChildren(taskId ? taskId : '0'); // '0' will return all root tasks
		},
		[]
	)

	useImperativeHandle(ref, () => ({
		createTask: createTaskCallback,
		updateTask: updateTaskCallback,
		getSelectedTask: getSelectedTaskCallback,
		getTaskChildrens: getTaskChildrensCallback
	}), [createTaskCallback, updateTaskCallback, getSelectedTaskCallback, getTaskChildrensCallback]);

	const onTaskDeleteCallback = useCallback(
		(id: number) => {
			// update parents
			if (deriveTaskDatesFromSubtasks) {
				const parentTasks = getTaskParents(gantt, id);
				onTasksUpdate(parentTasks);
			}

			onTaskDelete(id);
			gantt.deleteTask(id)
		},
		[deriveTaskDatesFromSubtasks, onTasksUpdate, onTaskDelete]
	)

	useEffect(
		() => {
			if (containerRef.current) {
				// https://forum.dhtmlx.com/t/cannot-read-property-reset-of-undefined/68230/4
				// posto se podaci ne obrisu, morao sam da iskoristim ovu metodu
				// ukoliko ne budem mogao da je koristim, treba da pozovem gantt.clearAll() kao clean funkciju za useEffect
				// posto destructor ne radi
				gantt = Gantt.getGanttInstance()

				// plugins
				gantt.plugins({
					auto_scheduling: true,
					critical_path: true,
					drag_timeline: true,
					fullscreen: true,
					marker: true,
					undo: true
				});

				// grid
				(gantt.config as any).grid_elastic_columns = 'min_width';

				// grid rows reorder
				gantt.config.order_branch = 'marker'
				gantt.config.order_branch_free = true

				// grid columns
				gantt.config.columns = [
					{ name: 'wbs', label: 'WBS', min_width: 60, max_width: 100, template: gantt.getWBSCode },
					...columns
				]

				// grid - remove folder/file icons
				gantt.templates.grid_folder = function() { return '<div style="width: 4px"></div>' };
				gantt.templates.grid_file = function() { return '<div style="width: 4px"></div>' };
				gantt.templates.grid_date_format = function(date: Date) {
					return date.toLocaleDateString();
				};

				// header weekend class is set in scales
				gantt.templates.timeline_cell_class = function (item, date) {
					const scale = gantt.getScale();
					const unit = scale.unit;

					if (unit === 'day' && (date.getDay() === 0 || date.getDay() === 6)) {
						return 'gantt_cell_weekend'
					}

					return undefined as any
				}

				// grid horizontal scrollbar
				gantt.config.layout = { rows: [] };
				gantt.config.layout.css = 'gantt_container';
				addGanttConfigLayout(showResourcesUtilization);

				if (!hideGantt && showResourcesUtilization) {
					gantt.config.layout.rows.push({resizer: true, width: 1});
				}
				if (showResourcesUtilization) {
					gantt.config.row_height = 3 * 24;
					addResourcesConfigLayout(resourceColumns);
				}

				gantt.config.resource_property = 'resource';

				// resources
				if (showResourcesUtilization) {
					createResourcesDatastore();
				}

				// auto scheduling
				gantt.config.auto_scheduling = true;
				gantt.config.auto_scheduling_initial = true;
				gantt.config.auto_scheduling_descendant_links = true;

				// zoom and scales
				gantt.ext.zoom.init(zoomConfig);

				// fullscreen
				gantt.ext.fullscreen.getFullscreenElement = function() {
					return containerRef.current!;
				}

				addMarkerToday(gantt);

				gantt.config.auto_types = deriveTaskDatesFromSubtasks;

				gantt.config.readonly = disabled;

				gantt.init(containerRef.current);

				// events - attached after gantt.init, so they don't trigger initially

				gantt.attachEvent('onAfterAutoSchedule', async (taskId: number, updatedTaskIds: any[]) => {
					// taskId is already handled depending on interaction, and also its parents
					// so we need to handle updatedTaskIds and all of theirs parents

					const tasks = updatedTaskIds.map((id) => gantt.getTask(id));
					onTasksUpdate(tasks);

					// update parents
					const allParents: any[] = [];
					for (let updatedTaskId of updatedTaskIds) {
						const taskParents = getTaskParents(gantt, updatedTaskId);
						for (let taskParent of taskParents) {
							if (allParents.some((item) => item.id === taskParent.id)) {
								continue;
							}

							allParents.push(taskParent);
						}
					}
					onTasksUpdate(allParents);
				}, undefined);

				gantt.attachEvent('onAfterTaskDrag', async (id: number, mode: string) => {
					const task = gantt.getTask(id);
					onTaskUpdate(task);

					var modes = gantt.config.drag_mode;
					switch (mode) {
						case modes.progress:
							// TODO:gantt configuration autocomplete parent kada su deca complete
							// i mozda kada se bilo sta vezano za progress promeni kod dece
							break;
						case modes.move:
						case modes.resize:
							// update parents
							if (deriveTaskDatesFromSubtasks) {
								const parentTasks = getTaskParents(gantt, id);
								onTasksUpdate(parentTasks);
							}
							break;
					}
				}, undefined);

				// links
				gantt.attachEvent('onAfterLinkAdd', async (id: number, item: any) => {
					const backendLinkId = await onLinkAdd(item);
					if (backendLinkId) {
						gantt.changeLinkId(id, backendLinkId)
					}
				}, undefined);
				gantt.attachEvent('onAfterLinkDelete', async (id: number) => {
					onLinkDelete(id);
				}, undefined);

				// grid
				gantt.attachEvent('onRowDragStart', async (taskId: number) => {
					const task = gantt.getTask(taskId);
					task.$oldParentId = task.parent;

					return true;
				}, undefined);

				gantt.attachEvent('onRowDragEnd', async (taskId: number) => {
					gantt.autoSchedule(taskId);

					const task = gantt.getTask(taskId);
					const tasks = gantt.getTaskByTime();
					const reorderTasksModel = tasks.map((item) => {
						return {
							wbs: item.$wbs,
							id: item.id
						}
					})
					onTasksReorder(reorderTasksModel);
					onTaskUpdate(task);

					// update old and new parents after reorder
					let oldParents: any[] = [];
					// eslint-disable-next-line eqeqeq
					if (task.$oldParentId != '0') {
						const oldParent = gantt.getTask(task.$oldParentId)
						oldParents = getTaskParents(gantt, task.$oldParentId);
						oldParents.push(oldParent);
					}
					const newParents = getTaskParents(gantt, taskId);
					const allParents: any = [...newParents];
					for (const oldParent of oldParents) {
						if (allParents.some((item) => item.id === oldParent.id)) {
							continue;
						}

						allParents.push(oldParent)
					}
					onTasksUpdate(allParents)

					task.$oldParentId = undefined;
				}, undefined);

				gantt.attachEvent('onTaskSelected', function(id) {
					setSelectedTask(id);
				}, undefined);

				// use our CRUD logic and modal dialogs
				gantt.attachEvent('onBeforeLightbox', function() {
					return false;
				}, undefined);
			}

			return () => {
				clearInterval(markerTodayInterval)
			}
		},
		// eslint-disable-next-line
		[]
	)

	// re-create tasks and links
	useEffect(
		() => {
			clearInterval(markerTodayInterval);
			// also removes markers and other additional elements, so need to take care of them
			gantt.clearAll()
			addMarkerToday(gantt);

			gantt.parse({
				tasks,
				links
			})
		},
		[tasks, links]
	)

	// re-create resources
	useEffect(
		() => {
			const resourcesStore = gantt.getDatastore(gantt.config.resource_store);
			resourcesStore?.clearAll();
			resourcesStore?.parse(resources);
		},
		[resources]
	)

	return (
		<div>
			<RowContainer justifyContent='space-between'>
				{!disabled &&
					<CrudButtons
						selectedTask={selectedTask}
						onCreateTaskClick={onCreateTaskClick}
						onUpdateTaskClick={onUpdateTaskClick}
						onTaskDelete={onTaskDeleteCallback}
					/>
				}
				{!disabled && <CommandButtons />}
				{!showResourcesUtilization && <ActionButtons />}
				{showResourcesUtilization && <></>} {/* push ZoomButtons on the right */}
				<ZoomButtons />
			</RowContainer>
			<VerticalSeparator margin='medium' />
			<div ref={containerRef} style={{ height: '700px' }}></div>
		</div>
	)
}

export const GanttChart = forwardRef(GanttChartForwarded)
