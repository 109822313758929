import path from 'path';
import { ColumnContainer } from 'components/Layout'
import notifications from 'components/Notification/notification'
import { RemoteTable } from 'components/Table'
import { getAllScopeItemsForLevelAction } from 'containers/Scope/MaintainScope/action'
import { setConfigureViewTableAction } from 'features/ConfigureView'
import { ContentShell } from 'features/Content/ContentShell'
import { ListComponentProps } from 'features/Crud'
import { WithProjectsOrCategoriesPicker, ProjectsOrCategoriesComponentProps } from 'features/Project'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TaskResponseItemsResponseModel, GenericFilterModelCollection, PartialTaskRequestEnum, TaskResponse, ScopeItemLevelResponseItemsResponseModel, ScopeItemLevelResponse, SimpleScheduleResponse, ProjectStatusEnum, ModuleActivityEnum } from 'services/tenantManagementService'
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getUserInfo } from 'utils/storageUtils'
import { CalendarTabsSubroutes } from '../Calendar/Tabs/CalendarTabsSubroutes'
import { deleteTaskAction, exportTasksAction, getAllTasksGenericFilterAction } from './action'
import { TasksHelp } from './Help/TasksHelp'
import { TableButtons } from './Table/TableButtons'
import { useTableColumnsMemo } from './Table/tableColumns'
import { CommunicationSubRoutesEnum } from '../routes';
import { getAllSimpleSchedulesAction } from 'containers/Schedule/Schedule/action';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

type Props = ProjectsOrCategoriesComponentProps & ListComponentProps;

const configureViewKey = 'communication_tasks_table';

const Tasks = ({ projects, nonProjectCategories, dataChangedTopic, publishDataChanged }: Props) => {
	const history = useHistory();
	const { persistedProject, persistedProjectStatus } = useSelector((state: RootState) => state);

	const [selectedTask, setSelectedTask] = useState(new TaskResponse());
	const [selectedPartialTaskItems, setSelectedPartialTaskItems] = useState<PartialTaskRequestEnum[]>([])
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());
	const [scopeItemsLevel3, setScopeItemsLevel3] = useState<ScopeItemLevelResponse[]>([]);
	const [schedules, setSchedules] = useState<SimpleScheduleResponse[]>([]);

	useEffect(
		() => {
			const fetchData = async () => {
				const promises: Promise<ScopeItemLevelResponseItemsResponseModel>[] = [];
				for (let i = 0; i < projects.length; i++) {
					const projectId = projects[i].id;
					const bindedAction = getAllScopeItemsForLevelAction.bind(null, projectId, 3);
					promises.push(tryCatchJsonByAction(bindedAction));
				}

				const responses = await Promise.all(promises);

				const scopeItems: ScopeItemLevelResponse[] = [];
				for (let i = 0; i < responses.length; i++) {
					const response = responses[i];
					if (response.success) {
						scopeItems.push(...response.items || []);
					}
				}

				setScopeItemsLevel3(scopeItems);

				const schedulesResponse = await tryCatchJsonByAction(getAllSimpleSchedulesAction);

				if (schedulesResponse.success) {
					setSchedules(schedulesResponse.items || [])
				}
			}

			fetchData();
		},
		[projects]
	)

	const tableColumns = useTableColumnsMemo(configureViewKey, scopeItemsLevel3, projects, schedules);

	const memoFetchFunction = useMemo(
		() => {
			if (projects.length === 0 && nonProjectCategories.length === 0) {
				return () => {
					return Promise.resolve(new TaskResponseItemsResponseModel({
						success: true,
						count: 0,
						items: []
					}))
				}
			}
			const projectIds = projects.map(project => project.id);
			const nonProjectCategoryIds = nonProjectCategories.map(project => project.id);
			return getAllTasksGenericFilterAction.bind(null, projectIds, nonProjectCategoryIds, selectedPartialTaskItems);
		},
		[projects, nonProjectCategories, selectedPartialTaskItems]
	)

	const deleteCallback = useCallback(
		async () => {
			const bindedAction = deleteTaskAction.bind(null, selectedTask.id, selectedTask.projectOrCategoryId, selectedTask.isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TASK, selectedTask.id);
				notifications.success(`Task ${id} is deleted.`);
				publishDataChanged();
			}
		},
		[selectedTask, publishDataChanged]
	)

	const handleRowSelectionChange = useCallback(
		(data: TaskResponse[]) => {
			setSelectedTask(data[0] || new TaskResponse());
		},
		[]
	);

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	const handleMyCalendarClick = useCallback(
		() => {
			let url = path.join(
				history.location.pathname,
				`../${CommunicationSubRoutesEnum.CALENDAR}`,
				`${CalendarTabsSubroutes.MY_CALENDAR}?`,
			);
			if (projects.length) {
				url += `projectIds=${projects.map(x => x.id).join(',')}`
			}
			if (nonProjectCategories.length) {
				if (projects.length) {
					url += '&'
				}
				url += `categoryIds=${nonProjectCategories.map(x => x.id).join(',')}`
			}
			history.push(url);
		},
		[history, projects, nonProjectCategories]
	);

	const memoExportFunction = useMemo(
		() => {
			const projectIds = projects.map(project => project.id);
			const nonProjectCategoryIds = nonProjectCategories.map((item) => item.id);
			return exportTasksAction.bind(null, projectIds, nonProjectCategoryIds, selectedPartialTaskItems)
		},
		[projects, nonProjectCategories, selectedPartialTaskItems]
	)

	const isDeleteDisabled = useMemo(
		() => selectedTask.createdByUserId !== getUserInfo().id,
		[selectedTask.createdByUserId]
	)

	const isChangeDisabled = useMemo(
		() => {
			const selectedProjectStatusId = selectedTask.isProjectConnected === true ? persistedProject.itemsMap[selectedTask.projectOrCategoryId]?.statusId : undefined;

			return isStatusBySemantic(ProjectStatusEnum.Completed, selectedProjectStatusId, persistedProjectStatus.itemsMap);
		},
		[selectedTask, persistedProject.itemsMap, persistedProjectStatus.itemsMap]
	)

	return (
		<ContentShell
			title='Tasks'
			FloatingHelpComponent={TasksHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedTask.id}
					onDelete={deleteCallback}
					setSelectedPartialTaskItems={setSelectedPartialTaskItems}
					selectedPartialTaskItems={selectedPartialTaskItems}
					onMyCalendarClick={handleMyCalendarClick}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
					disabledDelete={isDeleteDisabled}
					disabledChange={isChangeDisabled}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={dataChangedTopic}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectsOrCategoriesPicker(Tasks, ModuleActivityEnum.Communication, true);
