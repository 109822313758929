import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReordableList } from 'components/DragNDrop/ReordableList/ReordableList';
import { ProjectResponse, ScheduleResponse, UpdateWbsRequest } from 'services/tenantManagementService';
import { Item } from './Item';
import { OverlaySpinner } from 'components/Spinner';
import { CreateItem } from '../Crud/CreateItem';
import { ScheduleMapItem } from '../MapView';
import { reorderSchedulesAction } from '../../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import styles from 'components/Map/map.module.scss'
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';

type Props = {
	project: ProjectResponse
	disabledEdit?: boolean
	parent: ScheduleMapItem
	setParent(parent: ScheduleMapItem): void
	parentIndex: number // index of selected element, used for lines to be drawn between items
}

const updateWbsForSubtree = (parents: ScheduleMapItem[]) => {
	let updatedChildrensFlat: ScheduleMapItem[] = [];

	for (let i = 0; i < parents.length; i++) {
		const parent = parents[i];
		const childrens = parent.childrens;

		for (let j = 0; j < childrens.length; j++) {
			const children = childrens[j];
			children.wbs = parent.wbs ? `${parent.wbs}.${j + 1}` : `${j + 1}`;
			updatedChildrensFlat.push(children);

			const updatedChildrens = updateWbsForSubtree(childrens);
			updatedChildrensFlat = updatedChildrensFlat.concat(updatedChildrens);

		}
	}

	return updatedChildrensFlat;
}

export const ScheduleItems = ({ project, disabledEdit, parent, setParent, parentIndex }: Props) => {
	const childrens = parent.childrens;

	const [selectedItem, setSelectedItem] = useState<ScheduleMapItem | undefined>();
	const [reordering, setReordering] = useState(false);

	useEffect(
		() => {
			setSelectedItem(undefined);
		},
		[parent.id]
	)

	const setItemsCallback = useCallback(
		(newChildrens: ScheduleMapItem[]) => {
			// this is not the best way to change the model, but it is the easiest one because model is not flat array
			parent.childrens = newChildrens;
			// and we need to change selectedItem in parent container, so we get new reference both to parent and parent.childrens
			setParent({
				...parent,
				childrens: newChildrens
			});
		},
		[parent, setParent]
	)

	const setSelectedItemCallback = useCallback(
		(id: number | undefined) => {
			const item = childrens.find((item) => item.id === id);
			setSelectedItem(item);
		},
		[childrens]
	)

	const reorderBackendCallback = useCallback(
		async (newItems: ScheduleMapItem[], newChildrenItems: ScheduleMapItem[]) => {
			const items = newItems.concat(newChildrenItems);

			const model: UpdateWbsRequest[] = items.map((item) => new UpdateWbsRequest({
				id: item.id,
				wbs: item.wbs
			}))

			const bindedAction = reorderSchedulesAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);

			return response.success;
		},
		[project.id]
	)

	const reorderCallback = useCallback(
		async (newElementIds: number[]) => {
			setReordering(true);
			setSelectedItem(undefined);

			const newItems = newElementIds.map((id, index) => {
				let newItem = childrens.find((item) => item.id === id)!;
				newItem = {
					...newItem,
					wbs: newItem.wbs.substring(0, newItem.wbs.length - 1) + (index + 1)
				}
				return newItem;
			});

			const newChildrensFlat = updateWbsForSubtree(newItems);
			const success = await reorderBackendCallback(newItems, newChildrensFlat);
			if (success) {
				setItemsCallback(newItems);
			}

			setReordering(false);
		},
		[childrens, reorderBackendCallback, setItemsCallback]
	)

	const createItemLocalCallback = useCallback(
		(item: ScheduleResponse) => {
			const newState = [...childrens];
			newState.push({
				id: item.id,
				name: item.name,
				wbs: item.wbs,
				start: item.start,
				finish: item.finish,
				parentId: item.parentId,
				childrens: []
			})
			setItemsCallback(newState)
		},
		[childrens, setItemsCallback]
	)

	const updateItemLocalCallback = useCallback(
		(updatedItem: ScheduleResponse) => {
			const newState = [...childrens];
			const index = newState.findIndex(((item) => item.id === updatedItem.id));
			newState[index] = {
				...newState[index],
				name: updatedItem.name
			}
			setItemsCallback(newState)
		},
		[childrens, setItemsCallback]
	)

	const deleteItemLocalCallback = useCallback(
		async (id: number) => {
			setSelectedItem(undefined);

			// delete item
			let newState = [...childrens];
			const index = newState.findIndex(((item) => item.id === id));
			newState.splice(index, 1);

			// update wbs on other items
			const newItems = newState.map((item, index) => {
				return {
					...item,
					wbs: item.wbs.substring(0, item.wbs.length - 1) + (index + 1)
				}
			})

			const newChildrensFlat = updateWbsForSubtree(newItems);
			const success = await reorderBackendCallback(newItems, newChildrensFlat);
			if (success) {
				setItemsCallback(newItems);
			}
		},
		[childrens, reorderBackendCallback, setItemsCallback]
	)

	const disabled = useMemo(
		() => {
			return disabledEdit || !(isUserPmorSubstitutePmOrSiteAdmin(project.roleId) || project.permissions?.schedulePermission?.maintainSchedule);
		},
		[disabledEdit, project]
	)

	const renderItemCallback = useCallback(
		(id: number) => {
			const item = childrens.find((item) => item.id === id);

			// sometimes ReordableList has some old elementIds, and item can be undefined
			if (!item) {
				return;
			}

			return (
				<Item
					key={item.id.toString()}
					disabledEdit={disabled}
					item={item}
					onClick={setSelectedItemCallback}
					isSelected={selectedItem?.id === item.id}
					deselect={setSelectedItemCallback}
					project={project}
					updateItemLocal={updateItemLocalCallback}
					deleteItemLocal={deleteItemLocalCallback}
				/>
			)
		},
		[childrens, selectedItem, updateItemLocalCallback, deleteItemLocalCallback, disabled, project, setSelectedItemCallback]
	)

	const elementIds = useMemo(
		() => childrens.map((item) => item.id),
		[childrens]
	)

	const prevHeight = parentIndex * (32 + 8); // 32 item height, 8 margin-bottom
	const nextHeight = (childrens.length - 1) * (32 + 8);
	const lineHeight = Math.max(1, prevHeight, nextHeight);

	return (
		<>
			{!!parent.id && <div className={styles.line_vertical} style={{ height: `${lineHeight}px` }} />}
			<div className={styles.container}>
				{elementIds.length > 0 &&
					<ReordableList
						initialElementIds={elementIds}
						onReorder={reorderCallback}
						renderItem={renderItemCallback}
						disabled={disabled}
					/>
				}
				{!disabled &&
					<CreateItem
						parent={parent}
						project={project}
						createItemLocal={createItemLocalCallback}
					/>
				}
				{reordering && <OverlaySpinner size={60} withBackground />}
			</div>
			{selectedItem &&
				<ScheduleItems
					project={project}
					parent={selectedItem}
					setParent={setSelectedItem}
					parentIndex={childrens.indexOf(selectedItem)}
					disabledEdit={disabled}
				/>
			}
		</>
	)
}
