import { RootState } from "base/reducer/reducer";
import { HistoryLogTable, HistoryLogType } from "features/HistoryLog"
import { useUserFullNameCallback } from "features/TableColumns/persistedHooks";
import { useCallback, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { DateTimeNewOld, TicketHistoryResponse } from "services/tenantManagementService";
import { tryCatchJsonByAction } from "utils/fetchUtils";
import { propertyOf } from "utils/propertyOf";
import { useColumnsObjectMemo } from "../../TicketsTable/tableColumns";
import { formatDate } from 'utils/dateTimeUtils';
import { noop } from "utils/commonHelper";
import { getHistoryAction } from "../../action";
import { TicketFormTabsProps } from "../Tabs/TicketFormTabs";
import WithFetch from "features/Fetch/WithFetch";

export const History = ({ ticket, schedules }: TicketFormTabsProps) => {
	const { persistedUser } = useSelector((state: RootState) => state);

	const schedulesMemo = useMemo(() => schedules || [], [schedules])

	const columns = useColumnsObjectMemo(schedulesMemo)
	const getUserFullName = useUserFullNameCallback(persistedUser);

	const [log, setLog] = useState<HistoryLogType[]>([]);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getHistoryAction.bind(null, ticket.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				let newLog: HistoryLogType[] = [];

				const items = response.items || [];
				for (const item of items) {
					const logItem: HistoryLogType = {
						when: item.updatedOn,
						who: getUserFullName(item.userId),
						changes: []
					}

					for (const key of Object.keys(item)) {
						const value: any = (item as any)[key];

						if (!value) {
							continue;
						}

						let title;
						let oldValue;
						let newValue;

						switch (key) {
							case propertyOf<TicketHistoryResponse>('ticketId'):
							case propertyOf<TicketHistoryResponse>('userId'):
							case propertyOf<TicketHistoryResponse>('updatedOn'):
								continue;
							case propertyOf<TicketHistoryResponse>('numberExt'):
								title = 'Ticket number ext';
								break;
							case propertyOf<TicketHistoryResponse>('effortUnit'):
								title = 'Effort Unit';
								break;
							case propertyOf<TicketHistoryResponse>('closingNote'):
								title = 'Closing Note';
								break;
							case propertyOf<TicketHistoryResponse>('effort'):
								// effort formatter is specific, so we don't want to be called
								// INFO: we are not so easily aware of effortUnit for current value, so adding "in minutes"
								title = (columns as any)[key].title + ' (in minutes)';
								break;
							default:
								title = (columns as any)[key].title;

								if (value instanceof DateTimeNewOld) {
									oldValue = formatDate(value.oldValue);
									newValue = formatDate(value.newValue);
								} else if ((columns as any)[key].formatter) {
									const cellOldValue = {
										getValue: () => value.oldValue,
										getData: () => { return {} },
										getElement: noop
									}
									const cellNewValue = {
										...cellOldValue,
										getValue: () => value.newValue
									}
									oldValue = (columns as any)[key].formatter(cellOldValue);
									newValue = (columns as any)[key].formatter(cellNewValue);
								}
						}

						logItem.changes.push({
							title,
							old: oldValue || value.oldValue,
							new: newValue || value.newValue
						});
					}

					// add log only if there are changes
					if (logItem.changes.length > 0) {
						newLog.push(logItem);
					}
				}

				setLog(newLog.reverse());
			}
		},
		[ticket.id, columns, getUserFullName]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<HistoryLogTable log={log} />
		</WithFetch>
	)
}
