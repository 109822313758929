import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnContainer } from 'components/Layout';
import { totalFieldName, useTableColumnsMemo } from './Table/tableColumns';
import { TableButtons } from './Table/TableButtons';
import { generateNumberId } from 'base/id';
import notifications from 'components/Notification/notification';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import WithFetch from 'features/Fetch/WithFetch';
import { createDelta, emptyArray } from 'utils/commonHelper';
import { LocalTable } from 'components/Table';
import { BudgetDateValueResponse, BudgetDateValueHeaderResponse, BudgetRowDeltaRequest, BudgetRowResponse, DeleteTenantIndetifyRequest, IncomeExpenseEnum, InsertBudgetRowRequest, UpdateBudgetRowRequest, BudgetRowResponseItemsResponseModel, BaseResponseModel, BudgetDateValueHeaderResponseItemsResponseModel, ExportDataModel, FileResponse } from 'services/tenantManagementService';
import { ProjectPickerParams } from 'features/Project';
import { OptionType } from 'components/Form';
import { formatServerDate } from 'utils/dateTimeUtils';
import { propertyOf } from 'utils/propertyOf';
import Tabulator from 'tabulator-tables';

export const incomeExpenseOptions: OptionType[] = [
	{ id: IncomeExpenseEnum.Income, text: 'Income' },
	{ id: IncomeExpenseEnum.Expense, text: 'Expense' }
]

type Props = {
	getRowsAction: (projectId: number) => Promise<BudgetRowResponseItemsResponseModel>
	updateRowsAction: (projectId: number, delta: BudgetRowDeltaRequest) => Promise<BaseResponseModel>
	getHeadersAction: (projectId: number) => Promise<BudgetDateValueHeaderResponseItemsResponseModel>
	exportAction: (projectId: number, model: ExportDataModel) => Promise<FileResponse>
	releaseRowsAction?: (projectId: number) => Promise<BaseResponseModel>
	disabled?: boolean
}

export const BudgetAndActualsCommon = ({ getRowsAction, updateRowsAction, getHeadersAction, exportAction, releaseRowsAction, disabled }: Props) => {
	const params: ProjectPickerParams = useParams();
	const projectId = parseInt(params.projectId!);

	const [selectedBudget, setSelectedBudget] = useState(new BudgetRowResponse());
	const [headers, setHeaders] = useState<BudgetDateValueHeaderResponse[]>([]);
	const [initialValues, setInitialValues] = useState<BudgetRowResponse[]>(emptyArray);
	const [values, setValues] = useState<BudgetRowResponse[]>(emptyArray);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);

	const tableColumns = useTableColumnsMemo(headers, projectId);

	const selectCallback = useCallback(
		(data: BudgetRowResponse[], selectedRows: Tabulator.RowComponent[]) => {
			if (data[0] && data[0].id !== -1) {
				setSelectedBudget(data[0]);
				return;
			}

			if (data[0] && data[0].id === -1) {
				selectedRows[0].deselect();
			}

			setSelectedBudget(new BudgetRowResponse())
		},
		[]
	)

	const addCallback = useCallback(
		() => {
			const newBudgetRow = new BudgetRowResponse({
				id: generateNumberId(),
				isChangeable: true,
				incomeExpense: undefined as any,
				financeCategoryId: undefined as any,
				isIncomeExpenseChangeable: true
			})

			const newBudgetRows = [
				...values,
				newBudgetRow
			]
			setValues(newBudgetRows);
		},
		[values]
	)

	const deleteCallback = useCallback(
		(id: number) => {
			const newBudgetRows = values.filter(row => row.id !== id);
			setValues(newBudgetRows);
		},
		[values]
	)

	const fetchHeadersCallback = useCallback(
		async () => {
			const bindedAction = getHeadersAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.items) {
				setHeaders(response.items);
				return response.items;
			}

			return [];
		},
		[getHeadersAction, projectId]
	)

	const fetchDataCallback = useCallback(
		async () => {
			setIsRefetching(true);

			const bindedAction = getRowsAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);

			setIsRefetching(false);

			if (response.success && response.items) {
				return response.items;
			}

			return [];
		},
		[getRowsAction, projectId]
	)

	const fetchHeadersAndDataCallback = useCallback(
		async () => {
			const fetchedHeaders = await fetchHeadersCallback();
			const fetchedData = await fetchDataCallback();

			for (const header of fetchedHeaders) {
				const field = formatServerDate(header.date);

				for (const rowValues of fetchedData) {
					for (const dateValue of rowValues.budgetDateValues || []) {
						const dateString = formatServerDate(dateValue.date);

						if (field === dateString) {
							(rowValues as any)[field] = dateValue.value;
						}
					}
				}
			}

			setInitialValues(fetchedData);
			setValues(fetchedData);
		},
		[fetchDataCallback, fetchHeadersCallback]
	)

	const cellEditedCallback = useCallback(
		(cell: any) => {
			const budget = new BudgetRowResponse(cell.getData());

			setValues((state) => {
				return state.map((item) => item.id === budget.id ? budget : item);
			});
		},
		[]
	)

	const saveCallback = useCallback(
		async () => {
			for (const rowValues of values) {
				rowValues.budgetDateValues = [];
			}

			for (const header of headers) {
				const field = formatServerDate(header.date);

				for (const rowValues of values) {
					const fieldValue = (rowValues as any)[field];
					const dateValue = {
						date: header.date,
						value: fieldValue || 0
					};

					rowValues.budgetDateValues!.push(new BudgetDateValueResponse(dateValue))
				}
			}

			const delta = createDelta<BudgetRowResponse>
			(
				initialValues || [],
				values || [],
				InsertBudgetRowRequest,
				UpdateBudgetRowRequest,
				BudgetRowDeltaRequest,
				DeleteTenantIndetifyRequest
			);

			setIsSubmitting(true);

			const bindedAction = updateRowsAction.bind(null, projectId, delta)
			const response = await tryCatchJsonByAction(bindedAction);

			setIsSubmitting(false);

			if (response.success) {
				notifications.success('Budget saved');
				await fetchHeadersAndDataCallback();
			}
		},
		[values, initialValues, fetchHeadersAndDataCallback, projectId, headers, updateRowsAction],
	)

	const releaseCallback = useCallback(
		async () => {
			if (!releaseRowsAction) {
				return;
			}

			const bindedAction = releaseRowsAction.bind(null, projectId)
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Budget released')
				await fetchHeadersAndDataCallback();
			}
		},
		[fetchHeadersAndDataCallback, releaseRowsAction, projectId]
	)

	// if there is a row with versionId === undefined, then we enable Release button
	const isReleaseDisabled = useMemo(
		() => {
			for (const value of initialValues) {
				if (value.versionId === undefined) {
					return false;
				}
			}

			return true;
		},
		[initialValues]
	)

	const mergedValues = useMemo(
		() => {
			// first we calculate 'total' column for regular rows
			for (const rowValues of values) {
				(rowValues as any)[totalFieldName] = 0;

				for (const header of headers) {
					const field = formatServerDate(header.date);
					const value = (rowValues as any)[field];
					if (value) {
						(rowValues as any)[totalFieldName] += value;
					}
				}
			}

			// here we will store column totals for dateValues and 'total' column
			const totalIncome: { [key: string]: number }  = {};
			const totalExpense: { [key: string]: number }  = {};

			// calculate total income and expense for dateValue columns
			for (const header of headers) {
				const field = formatServerDate(header.date);
				totalIncome[field] = 0;
				totalExpense[field] = 0;

				for (const rowValues of values) {
					const value = (rowValues as any)[field];
					if (value === undefined) {
						continue;
					}
					if (rowValues.incomeExpense === IncomeExpenseEnum.Income) {
						totalIncome[field] += value;
					} else if (rowValues.incomeExpense === IncomeExpenseEnum.Expense) {
						totalExpense[field] += value;
					}
				}
			}

			// calculate total income and expense for 'total' column
			totalIncome[totalFieldName] = 0;
			totalExpense[totalFieldName] = 0;

			for (const rowValues of values) {
				if (rowValues.incomeExpense === IncomeExpenseEnum.Income) {
					totalIncome[totalFieldName] += (rowValues as any)[totalFieldName];
				} else if (rowValues.incomeExpense === IncomeExpenseEnum.Expense) {
					totalExpense[totalFieldName] += (rowValues as any)[totalFieldName];
				}
			}

			// calculate profit only for 'total' column
			const profit = totalIncome[totalFieldName] - totalExpense[totalFieldName];
			// calculate profit percent only for 'total' column
			let profitInPercent: number;
			if (totalIncome[totalFieldName] === 0) {
				if (totalExpense[totalFieldName] === 0) {
					profitInPercent = 0;
				} else {
					profitInPercent = -100;
				}
			} else {
				profitInPercent = profit / totalIncome[totalFieldName] * 100;
			}
			const profitInPercentString = profitInPercent.toFixed(2) + '%'

			// convert to rows
			const totalIncomeFooter = { [propertyOf<BudgetRowResponse>('customField')]: 'Total Income', ...totalIncome };
			const totalExpenseFooter = { [propertyOf<BudgetRowResponse>('customField')]: 'Total Expenses', ...totalExpense };
			const profitFooter = { [propertyOf<BudgetRowResponse>('customField')]: 'Profit', [totalFieldName]: profit };
			const profitInPercentFooter = { [propertyOf<BudgetRowResponse>('customField')]: 'Profit %', [totalFieldName]: profitInPercentString };

			return [
				...values,
				totalIncomeFooter,
				totalExpenseFooter,
				profitFooter,
				profitInPercentFooter
			]
		},
		[values, headers]
	)

	const memoExportFunction = useMemo(
		() => exportAction.bind(null, projectId),
		[exportAction, projectId]
	)

	return (
		<WithFetch fetchFunction={fetchHeadersAndDataCallback} refetching={isRefetching}>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedBudget.id}
					disabled={disabled || isSubmitting}
					onAdd={addCallback}
					deleteDisabled={disabled || !selectedBudget.isChangeable}
					onDelete={deleteCallback}
					saveDisabled={initialValues === values}
					onSave={saveCallback}
					releaseDisabled={isReleaseDisabled}
					onRelease={releaseCallback}
					tableColumns={tableColumns}
					exportFunction={memoExportFunction}
					isReleaseable={!!releaseRowsAction}
				/>
				<LocalTable
					columns={tableColumns}
					data={mergedValues}
					rowSelectionChanged={selectCallback}
					cellEdited={cellEditedCallback}
				/>
			</ColumnContainer>
		</WithFetch>
	)
}
