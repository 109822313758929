import { TrainingIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";

// subroutes

export enum TrainingSubRoutesEnum {
	MY_DASHBOARD = 'training_my_dashboard',
	MY_TRAINING_PLAN = 'my_training_plan',
	MY_SURVEY = 'my_survey',
	TRAINING_CYCLES = 'training_cycles',
	TRAINING_PLAN = 'training_plan',
	SURVEY_QUIZ = 'survey_quiz'
}

export const myDashboardSubRoute: Route = {
	id: TrainingSubRoutesEnum.MY_DASHBOARD,
	url: `/${RootRouteEnum.TRAINING}/${TrainingSubRoutesEnum.MY_DASHBOARD}`,
	text: 'My Dashboard',
}

export const myTrainingPlanSubRoute: Route = {
	id: TrainingSubRoutesEnum.MY_TRAINING_PLAN,
	url: `/${RootRouteEnum.TRAINING}/${TrainingSubRoutesEnum.MY_TRAINING_PLAN}`,
	text: 'My training plan',
}

export const mySurveySubRoute: Route = {
	id: TrainingSubRoutesEnum.MY_SURVEY,
	url: `/${RootRouteEnum.TRAINING}/${TrainingSubRoutesEnum.MY_SURVEY}`,
	text: 'My survey',
}

export const trainingCyclesSubRoute: Route = {
	id: TrainingSubRoutesEnum.TRAINING_CYCLES,
	url: `/${RootRouteEnum.TRAINING}/${TrainingSubRoutesEnum.TRAINING_CYCLES}`,
	text: 'Training cycles',
}

export const trainingPlanSubRoute: Route = {
	id: TrainingSubRoutesEnum.TRAINING_PLAN,
	url: `/${RootRouteEnum.TRAINING}/${TrainingSubRoutesEnum.TRAINING_PLAN}`,
	text: 'Training plan',
}

export const surveyQuizSubRoute: Route = {
	id: TrainingSubRoutesEnum.SURVEY_QUIZ,
	url: `/${RootRouteEnum.TRAINING}/${TrainingSubRoutesEnum.SURVEY_QUIZ}`,
	text: 'Survey and Quiz',
}

// root route

export const trainingRoute: Route = {
	id: RootRouteEnum.TRAINING,
	url: `/${RootRouteEnum.TRAINING}`,
	text: 'Training',
	Icon: TrainingIcon,
	children: [
		myDashboardSubRoute,
		myTrainingPlanSubRoute,
		mySurveySubRoute,
		trainingCyclesSubRoute,
		trainingPlanSubRoute,
		surveyQuizSubRoute
	]
}
