import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { MySurveyResponse, SurveyTypeEnum, TrainingStatusResponse } from 'services/tenantManagementService';
import { useApplyStatusColorCallback, useStatusCallback } from 'features/TableColumns/persistedHooks';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultColumns = [
	propertyOf<MySurveyResponse>('id'),
	propertyOf<MySurveyResponse>('name'),
	propertyOf<MySurveyResponse>('surveyType'),
	propertyOf<MySurveyResponse>('statusId')
]

export const useTableColumnsMemo = () => {
	const { persistedTrainingStatus } = useSelector((state: RootState) => state);

	const getStatusName = useStatusCallback(persistedTrainingStatus);
	const applyTestStatusColor = useApplyStatusColorCallback(persistedTrainingStatus);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<MySurveyResponse>('id')]: {
					title: 'Survey ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.SURVEY
				},
				[propertyOf<MySurveyResponse>('name')]: {
					title: 'Survey Name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<MySurveyResponse>('surveyType')]: {
					title: 'Survey Type',
					fieldType: FieldTypeEnum.Options,
					options: Object.values(SurveyTypeEnum),
					getItemId: (option: SurveyTypeEnum) => option,
					getItemText: (option: SurveyTypeEnum) => option,
				},
				[propertyOf<MySurveyResponse>('statusId')]: {
					title: 'Status',
					fieldType: FieldTypeEnum.Options,
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyTestStatusColor(id, cell.getElement());
						return getStatusName(id);
					},
					options: persistedTrainingStatus.items,
					getItemId: (option: TrainingStatusResponse) => option.id,
					getItemText: (option: TrainingStatusResponse) => option.name,
				}
			}
		},
		[persistedTrainingStatus.items, applyTestStatusColor, getStatusName]
	)

	return useVisibleColumns(columns, defaultColumns);
}
