import { Document, Font, Page, StyleSheet, Image } from '@react-pdf/renderer';
import { MySurveyQuestionResponse, ProjectResponse, SurveyResponse } from 'services/tenantManagementService';
import MySurveyDocumentForm from './MySurveyDocumentForm';
import { TitleField } from 'components/Export';

type Props = {
	survey: SurveyResponse
	surveyQuestions: MySurveyQuestionResponse[]
	project: ProjectResponse
	base64Logo?: string
}

const MySurveyDocument = ({
	survey,
	surveyQuestions,
	project,
	base64Logo
}: Props) => {
	return (
		<Document>
			<Page wrap size="A4" style={styles.page}>
				{base64Logo && <Image style={{ marginLeft: 'auto', maxWidth:'100px', height: 'auto'}} src={base64Logo} /> }
				<TitleField title='My survey' />
				<MySurveyDocumentForm
					survey={survey}
					surveyQuestions={surveyQuestions}
					project={project}
				/>
			</Page>
		</Document>
	);
};

export default MySurveyDocument;

Font.register({
  family: 'Open Sans',
  fonts: [
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const styles = StyleSheet.create({
	page: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingHorizontal: 10,
		fontFamily: 'Open Sans'
	},
});
