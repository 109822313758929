import { RootState } from "base/reducer/reducer"
import Button from "components/Button"
import { AutoCompleteField, DateField, TableField, TimeSpanField } from "components/Form"
import { ColumnContainer } from "components/Layout"
import { getStatusBySemantic } from "features/StatusResponse/statusResponse"
import { useSelector } from "react-redux"
import { TicketResponse, TicketTimesheetResponse, TimeTravelStatusEnum, UserModel } from "services/tenantManagementService"
import { propertyOf } from "utils/propertyOf"
import { TicketFormTabsProps } from "../Tabs/TicketFormTabs"

export const EffortTimesheets = ({ isRead, addEffortTimesheet, ticket }: TicketFormTabsProps) => {
	const { persistedUser, persistedTimeAndTravelStatus, persistedTimesheetsGeneralConfiguration } = useSelector((state: RootState) => state);

	return (
		<ColumnContainer margin='medium'>
			<Button
				onClick={addEffortTimesheet!}
				text='Add user'
				disabled={isRead}
			/>
			<TableField
				id={propertyOf<TicketResponse>('timesheets')}
				headers={[
					{ id: propertyOf<TicketTimesheetResponse>('userId'), label: 'User', size: 4, isRequired: true },
					{ id: propertyOf<TicketTimesheetResponse>('date'), label: 'Actual date', size: 2 },
					{ id: propertyOf<TicketTimesheetResponse>('effort'), label: 'Actual effort', size: 3 }
				]}
				hasTotal
				getRowData={(row: TicketTimesheetResponse) => {
					const createdStatus = getStatusBySemantic(TimeTravelStatusEnum.Created, persistedTimeAndTravelStatus.items);
					const rejectedStatus = getStatusBySemantic(TimeTravelStatusEnum.Rejected, persistedTimeAndTravelStatus.items);
					const enabled = persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess || row.statusId === createdStatus?.id || row.statusId === rejectedStatus?.id;

					return {
						isDeletable: !isRead && enabled,
						fields: [
							<AutoCompleteField
								id={propertyOf<TicketTimesheetResponse>('userId')}
								items={persistedUser.items}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								loading={persistedUser.fetching}
								isRequired
								disabled
							/>,
							<DateField
								id={propertyOf<TicketTimesheetResponse>('date')}
								maxDate={new Date()}
								disabled={!enabled}
							/>,
							<TimeSpanField
								id={propertyOf<TicketTimesheetResponse>('effort')}
								disabled={!enabled}
							/>
						]
					}
				}}
			/>
		</ColumnContainer>
	)
}
