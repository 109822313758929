import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud';
import { CreateChangeRequest } from './Crud/CreateChangeRequest';
import { UpdateChangeRequest } from './Crud/UpdateChangeRequest';

export const ChangeRequestRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/${CrudSubRoutesEnum.Create}`} component={CreateChangeRequest} />
			<Route path={`${path}/${CrudSubRoutesEnum.Update}/:changeRequestId`} component={UpdateChangeRequest} />
		</Switch>
	)
}
