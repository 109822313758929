import { useCallback, useEffect, useMemo, useState } from 'react'
import { ChangeRequestResponse, ScopeChangeRequestsDashboardResponse } from 'services/tenantManagementService'
import { useSelector } from 'react-redux'
import { RootState } from 'base/reducer/reducer'
import { Layout } from 'features/Dashboard/Layout'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { Dashboard } from 'features/Dashboard/Dashboard';
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget'
import { LocalTableWidget } from 'features/Dashboard/Widget/LocalTableWidget'
import { useTableColumnsMemo } from './tableColumns'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getChangeRequestsAction } from './action'

type Props = {
	loading: boolean
	dashboard: ScopeChangeRequestsDashboardResponse
	projectId: number
	statusId?: number
}

const FilteredDashboard = ({ loading, dashboard, projectId, statusId }: Props) => {
	const {
		persistedChangeRequestStatus,
		persistedChangeRequestType,
		persistedTicketPriority
	} = useSelector((state: RootState) => state);

	const [changeRequests, setChangeRequests] = useState<ChangeRequestResponse[]>([]);

	const tableColumns = useTableColumnsMemo(changeRequests.map(item => item.id));

	const fetchChangeRequestsCallback = useCallback(
		async () => {
			if (!projectId) {
				setChangeRequests([]);
				return;
			}

			const bindedAction = getChangeRequestsAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const changeRequestItems = statusId ? response.items?.filter(cr => cr.statusId === statusId) : response.items;
				setChangeRequests(changeRequestItems || []);
			}
		},
		[projectId, statusId]
	)

	useEffect(
		() => {
			fetchChangeRequestsCallback();
		},
		[fetchChangeRequestsCallback]
	)

	const perStatusDataMemo = useMemo(
		() => {
			const data = persistedChangeRequestStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.countPerStatus && dashboard.countPerStatus[status.id]) ? dashboard.countPerStatus[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedChangeRequestStatus.items]
	)

	const perTypeDataMemo = useMemo(
		() => {
			const data = persistedChangeRequestType.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.countPerType && dashboard.countPerType[status.id]) ? dashboard.countPerType[status.id] : 0,
					// color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedChangeRequestType.items]
	)

	const perPriorityDataMemo = useMemo(
		() => {
			const data: { priority?: string, count: number}[] = [];

			for (const priorityId in dashboard.countPerPriority) {
				data.push({
					priority: persistedTicketPriority.itemsMap[parseInt(priorityId)]?.name,
					count: dashboard.countPerPriority[priorityId]
				})
			}

			return data;
		},
		[dashboard, persistedTicketPriority]
	)

	const perCriticalityDataMemo = useMemo(
		() => {
			const data: { critical: string, count: number }[] = [];

			for (const criticalId in dashboard.countPerCriticality) {
				data.push({
					critical: criticalId === 'False' ? 'yes' : 'no',
					count: dashboard.countPerCriticality[criticalId]
				})
			}

			return data;
		},
		[dashboard]
	)

	const columnSeriesFieldsMemo = useMemo(
		() => {
			return [{
				value: 'count',
				name: 'CR count'
			}]
		},
		[]
	)

	return (
		<Dashboard orientation='horizontal'>
			<Layout
				orientation='horizontal'
				className='col-xl-12'
			>
				<PieWidget
					className='col-xl-4 col-md-6'
					title='Change Requests per Status'
					id='cr_per_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={perStatusDataMemo}
					loading={loading}
					usePercentAndValueForLegend
				/>
				<LocalTableWidget
					className='col-xl-8 col-md-12'
					title='Change Requests List'
					columns={tableColumns}
					data={changeRequests}
					loading={loading}
					hasPagination
					limit={4}
				/>
			</Layout>
			<Layout
				orientation='horizontal'
				className='col-xl-12'
			>
				<RotatedColumnSeriesWidget
					className='col-xl-4 col-md-6'
					title='Change Requests per Priority'
					id='cr_per_priority_column_series_chart'
					data={perPriorityDataMemo}
					fieldCategory='priority'
					categoryTitle=''
					stacked
					fieldValues={columnSeriesFieldsMemo}
					loading={loading}
					hideLegend
					showValueOnBar
				/>
				<RotatedColumnSeriesWidget
					className='col-xl-4 col-md-6'
					title='Change Requests per Criticality'
					id='cr_per_criticality_column_series_chart'
					data={perCriticalityDataMemo}
					fieldCategory='critical'
					categoryTitle=''
					stacked
					fieldValues={columnSeriesFieldsMemo}
					loading={loading}
					hideLegend
					showValueOnBar
				/>
				<PieWidget
					className='col-xl-4 col-md-6'
					title='Change Requests per Type'
					id='cr_per_type_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={perTypeDataMemo}
					loading={loading}
					usePercentAndValueForLegend
				/>
			</Layout>
		</Dashboard>
	)
}

export default FilteredDashboard
