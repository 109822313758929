import { CategoryItems } from './CategoryItems/CategoryItems'
import styles from 'components/Map/map.module.scss'

export const TicketCategory = () => {
	return (
		<>
			<div className={styles.header}>
				<h5 className={styles.container}>Category lvl 1</h5>
				<div className={styles.line_vertical} />
				<h5 className={styles.container}>Category lvl 2</h5>
				<div className={styles.line_vertical} />
				<h5 className={styles.container}>Category lvl 3</h5>
			</div>
			<div className={styles.content}>
				<CategoryItems
					level={1}
					parentIndex={0}
				/>
			</div>
		</>
	)
}
