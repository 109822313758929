import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RiskResponse, UpdateRiskRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import { getRiskAction, updateRiskAction } from '../action';
import { ProjectPickerParams } from 'features/Project';
import { RiskForm } from './RiskForm';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = ProjectPickerParams & {
	riskId: string
}

export const UpdateRisk = ({ publishDataChanged }: UpdateComponentProps) => {
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId as string);
	const riskId = parseInt(params.riskId as string);
	const goBackFromUpdate = useGoBackFromUpdate();

	const [risk, setRisk] = useState(new RiskResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getRiskAction.bind(null, projectId, riskId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setRisk(response.value || new RiskResponse());
			}
		},
		[projectId, riskId]
	)

	const saveCallback = useCallback(
		async (newRisk: RiskResponse) => {
			const model = new UpdateRiskRequest(newRisk);

			const bindedAction = updateRiskAction.bind(null, projectId, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.RISK, response.value?.id);
				notifications.success(`Risk ${id} is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged, projectId]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`Change risk - ${getFormatedId(EntityPrefixEnum.RISK, risk.id)}`}>
				<RiskForm
					risk={risk}
					projectId={projectId}
					crud={CrudEnum.Update}
					onSave={saveCallback}
					cancel={goBackFromUpdate}
				/>
			</ContentShell>
		</WithFetch>
	)
}
