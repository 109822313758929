import { ProjectResponse, TenantIsActiveResponse, TestPlanResponse, TestStatusResponse, UserModel } from 'services/tenantManagementService';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { formatDate, formatTime } from 'utils/dateTimeUtils';
import { FormField } from 'components/Export';

type Props = {
	persistedUser: PersistItemsReducer<UserModel>
	persistedTestCaseStatus: PersistItemsReducer<TestStatusResponse>
	project: ProjectResponse
	testCycle: TenantIsActiveResponse
	testPlan: TestPlanResponse
}

const MyTestForm = ({ persistedUser, persistedTestCaseStatus, project, testCycle, testPlan }: Props) => {
	const processTester = testPlan.processTesterUserId ? persistedUser.itemsMap[testPlan.processTesterUserId] : undefined;
	const lastChangedByUser = testPlan.lastChangedByUserId ? persistedUser.itemsMap[testPlan.lastChangedByUserId] : undefined;
	const processStatus = persistedTestCaseStatus.itemsMap[testPlan.processStatusId];
	return (
		<>
			<FormField
				label={'Project'}
				value={`${getFormatedId(EntityPrefixEnum.PROJECT, project.id)} - ${project.name}`}
			/>
			<FormField
				label={'Test Cycle'}
				value={testCycle.name}
			/>
			<FormField
				label={'Test Plan Id'}
				value={testPlan.id.toString()}
			/>

			<FormField
				label={'Planned date'}
				value={testPlan.plannedDate ? formatDate(testPlan.plannedDate) : ''}
			/>
			<FormField
				label={'Planned time'}
				value={testPlan.plannedTime || testPlan.plannedTimeTo ? `${formatTime(testPlan.plannedTime)} - ${formatTime(testPlan.plannedTimeTo)}` : ''}
			/>
			<FormField
				label={'Duration'}
				value={testPlan.duration ? `${(testPlan.duration || 0) / 10000 / 1000 / 60} min` : ''}
			/>
			<FormField
				label={'Process description'}
				value={testPlan.processDescription?.toString()}
			/>
			<FormField
				label={'Level 1'}
				value={`${testPlan.level1Id?.toString()} ${testPlan.level1Name?.toString()}`}
			/>
			<FormField
				label={'Level 2'}
				value={`${testPlan.level2Id?.toString()} ${testPlan.level2Name?.toString()}`}
			/>
			<FormField
				label={'Level 3'}
				value={`${testPlan.level3ProcessId?.toString()} ${testPlan.level3ProcessName?.toString()}`}
			/>
			<FormField
				label={'Process tester'}
				value={`${processTester?.firstName?.toString()} ${processTester?.lastName?.toString()}`}
			/>
			<FormField
				label={'Status'}
				value={processStatus?.name?.toString()}
				valueBackgroundColor={processStatus?.color}
			/>
			<FormField
				label={'Comments'}
				value={testPlan.comments?.toString()}
			/>
			<FormField
				label={'Change requests'}
				value={(testPlan.changeRequestIds || []).map(x => getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, x)).join(', ')}
			/>
			<FormField
				label={'Last Changed By'}
				value={`${lastChangedByUser?.firstName?.toString()} ${lastChangedByUser?.lastName.toString()}`}
			/>
			<FormField
				label={'Last Change'}
				value={testPlan.lastChanged ? formatDate(testPlan.lastChanged) : ''}
			/>
			<FormField
				label={'Ticket ID'}
				value={(testPlan.ticketIds || []).map(x => getFormatedId(EntityPrefixEnum.TICKET, x)).join(', ')}
			/>
		</>
	);
};

export default MyTestForm;
