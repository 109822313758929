import {useMemo } from 'react';
import { ProjectDeliverableResponse, ProjectMilestoneResponse } from 'services/tenantManagementService';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';

const defaultVisibleColumns = [
	propertyOf<ProjectMilestoneResponse>('wbs'),
	propertyOf<ProjectMilestoneResponse>('name'),
	propertyOf<ProjectMilestoneResponse>('duration'),
	propertyOf<ProjectMilestoneResponse>('start'),
	propertyOf<ProjectMilestoneResponse>('finish'),
	propertyOf<ProjectMilestoneResponse>('complete'),
	propertyOf<ProjectMilestoneResponse>('achievedDate'),
	propertyOf<ProjectMilestoneResponse>('milestoneDeviation')
]

export const useTableColumnsMemo = (visibleColumns: string[] = defaultVisibleColumns) => {
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectMilestoneResponse>('wbs')]: {
					title: 'WBS',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectMilestoneResponse>('name')]: {
					title: 'Task Name',
					formatter: (cell: any) => {
						const deliverablePlan: ProjectDeliverableResponse = cell.getData();
						const wbs = deliverablePlan.wbs;
						return (
							`<span style="padding-left:	${(wbs.split('.').length - 1) * 20}px">
								${deliverablePlan.name}
							</span>`
						);
					},
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectMilestoneResponse>('duration')]: {
					title: 'Duration',
					fieldType: FieldTypeEnum.Number
				},
				[propertyOf<ProjectMilestoneResponse>('start')]: {
					title: 'Start',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectMilestoneResponse>('finish')]: {
					title: 'Finish',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectMilestoneResponse>('complete')]: {
					title: '% complete',
					formatter: (cell: any) => {
						const value = cell.getValue();
						return value ? `${cell.getValue()}%` : '';
					},
					fieldType: FieldTypeEnum.Number
				},
				[propertyOf<ProjectMilestoneResponse>('achievedDate')]: {
					title: 'Achieved date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectMilestoneResponse>('milestoneDeviation')]: {
					title: 'Milestone deviation',
					dbFilterFieldPath: 'Deviation',
					dbExportFieldPath: 'Deviation',
					fieldType: FieldTypeEnum.String
				}
			}
		},
		[]
	)

	return useVisibleColumns(columns, visibleColumns);
}
