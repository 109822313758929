import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { TenantIsActiveResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { AutoComplete } from 'components/Form';
import { Subtract } from 'utility-types';
import { getTestCyclesAction } from 'containers/Testing/TestCycles/action';
import { ComponentProps, ProjectParamName, ProjectPickerParams } from 'features/Project';
import { ColumnContainer, VerticalSeparator } from 'components/Layout';

type TestCycleHocProps = {
	testCycle: TenantIsActiveResponse
}

export type TestCycleComponentProps = TestCycleHocProps & ComponentProps;

export const TestCycleParamName = 'testCycleId';

export type TestCyclePickerParams = ProjectPickerParams & {
	[TestCycleParamName]?: string
}

export const WithTestCyclePicker = <P extends TestCycleComponentProps>(Component: React.ComponentType<P>) => {

	const HighOrderComponent = (props: Subtract<P, TestCycleHocProps>) => {
		const history = useHistory();
		const routematch = useRouteMatch();
		const { testCycleId }: TestCyclePickerParams = useParams();

		const [fetchingTestCycles, setFetchingTestCycles] = useState(false);
		const [testCycles, setTestCycles] = useState<TenantIsActiveResponse[]>([]);
		const [selectedTestCycleId, setSelectedTestCycleId] = useState<string | number>();

		useEffect(
			() => {
				setSelectedTestCycleId(undefined);
			},
			[props.project.id]
		)

		const selectedTestCycle = useMemo(
			() => {
				const id = selectedTestCycleId as number || (testCycleId ? parseInt(testCycleId) : undefined);
				if (!id) {
					return new TenantIsActiveResponse();
				}

				let testCycle = testCycles.find(item => item.id === id);
				if (!testCycle) {
					testCycle = new TenantIsActiveResponse();
				}

				return testCycle;
			},
			[testCycles, testCycleId, selectedTestCycleId]
		)

		const fetchTestCyclesCallback = useCallback(
			async () => {
				if (!props.project.id) {
					setTestCycles([]);
					return;
				}

				setFetchingTestCycles(true);

				const bindedAction = getTestCyclesAction.bind(null, props.project.id);
				const testCyclesResponse = await tryCatchJsonByAction(bindedAction);

				if (testCyclesResponse.success && testCyclesResponse.items) {
					const activeItems = testCyclesResponse.items.filter(tc => tc.isActive);
					setTestCycles(activeItems);
				}

				setFetchingTestCycles(false);
			},
			[props.project.id]
		)

		useEffect(
			() => {
				fetchTestCyclesCallback();
			},
			[fetchTestCyclesCallback]
		)

		const url = useMemo(
			() => {
				if (selectedTestCycleId) {
					let url = routematch.path.split(`/:${TestCycleParamName}`)[0] + '/' + selectedTestCycleId;
					url = url.replace(':'  + ProjectParamName, String(props.project.id));
					return url;
				}
			},
			[routematch.path, selectedTestCycleId, props.project.id]
		)

		useEffect(
			() => {
				if (url) {
					history.replace(url);
				}
			},
			[history, url]
		)

		const componentPropsMemo = useMemo(
			() => {
				return {
					...props,
					testCycle: selectedTestCycle
				} as P
			},
			[props, selectedTestCycle]
		)

		return (
			<>
				<VerticalSeparator margin='small' />
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<SmartFormGroup label='Test Cycle'>
								<AutoComplete
									value={selectedTestCycle.id}
									onChange={setSelectedTestCycleId}
									items={testCycles}
									getItemId={(item: TenantIsActiveResponse) => item.id}
									getItemText={(item: TenantIsActiveResponse) => item.name}
									loading={fetchingTestCycles}
								/>
							</SmartFormGroup>
						</SmartItem>
					</SmartContainer>
					{selectedTestCycle.id && <Component {...componentPropsMemo} />}
				</ColumnContainer>
			</>
		)
	}

	return HighOrderComponent;
}
