import { IdNameResponse, NonProjectCategoryResponse, ProjectResponse, TenantResponseModel, TimeTravelStatusResponse, TravelExpenseResponse, UserModel } from 'services/tenantManagementService';
import { PersistActiveItemsReducer, PersistItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { formatDate, formatTime, minutesToDhm } from 'utils/dateTimeUtils';
import { getTravelByEnumLabel } from 'containers/TimeTravel/utils';
import { FormField, SubtitleField } from 'components/Export';
import { useFormattedCurrencyValueWithArgsCallback, useFormattedProjectIdNameCallback } from 'features/TableColumns/persistedHooks';

type Props = {
	travelExpense: TravelExpenseResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedProject: PersistItemsReducer<ProjectResponse>
	persistedTimeTravelNonProjectCategories: PersistActiveItemsReducer<NonProjectCategoryResponse>
	persistedTimeAndTravelStatus: PersistItemsReducer<TimeTravelStatusResponse>
	persistedCountry: PersistItemsReducer<IdNameResponse>
	persistedTenant: PersistValueReducer<TenantResponseModel>,
	persistedCurrency: PersistItemsReducer<IdNameResponse>,
	isSerbiaCountry: boolean
}

const TravelExpenseDocumentForm = ({
	travelExpense,
	persistedUser,
	persistedProject,
	persistedTimeTravelNonProjectCategories,
	persistedTimeAndTravelStatus,
	persistedCountry,
	persistedTenant,
	persistedCurrency,
	isSerbiaCountry
}: Props) => {
	const user = persistedUser.itemsMap[travelExpense.userId];
	const approvedByUser = travelExpense.approvedOrRejectedByUserId ? persistedUser.itemsMap[travelExpense.approvedOrRejectedByUserId] : undefined;
	const status = travelExpense.statusId ? persistedTimeAndTravelStatus.itemsMap[travelExpense.statusId] : undefined;
	const formatProjectIdName = useFormattedProjectIdNameCallback(persistedProject);
	const formatedProjectOrCategory = travelExpense.isProjectConnected ? formatProjectIdName(travelExpense.projectOrCategoryId) : persistedTimeTravelNonProjectCategories.itemsMap[travelExpense.projectOrCategoryId]?.name

	const getFormattedCurrencyValue = useFormattedCurrencyValueWithArgsCallback(persistedCurrency, persistedTenant);

	return (
		<>
			<FormField
				label={isSerbiaCountry ? 'Ime i prezime zaposlenog' : 'User'}
				value={`${user?.firstName} ${user?.lastName}`}
			/>
			<FormField
				label={'Status'}
				value={status?.name}
				valueBackgroundColor={status?.color}
			/>
			<FormField
				label={isSerbiaCountry ? 'Odobrio' : 'Approved by'}
				value={approvedByUser ? `${approvedByUser?.firstName} ${approvedByUser?.lastName}` : ''}
			/>
			<FormField
				label={isSerbiaCountry ? 'Odobreno dana' : 'Approved on'}
				value={formatDate(travelExpense.approvedOrRejectedOn)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Broj putnog zahteva' : 'Travel request Id'}
				value={getFormatedId(EntityPrefixEnum.TRAVEL_REQUEST, travelExpense.travelRequestId)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Broj putnog naloga' : 'Travel expense Id'}
				value={getFormatedId(EntityPrefixEnum.TRAVEL_EXPENSE, travelExpense.id)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Projekat ili kategorija' : 'Project or category'}
				value={formatedProjectOrCategory}
			/>
			<FormField
				label={isSerbiaCountry ? 'Cilj putovanja' : 'Purpose of travel'}
				value={travelExpense.description}
			/>
			{!isSerbiaCountry && (
				<FormField
					label={'Estimated costs'}
					value={getFormattedCurrencyValue(travelExpense.estimatedCosts)}
				/>
			)}
			<FormField
				label={isSerbiaCountry ? 'Akontacija' : 'Advance payment'}
				value={getFormattedCurrencyValue(travelExpense.advancePayment)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Besplatna hrana na putu' : 'Free meals'}
				value={travelExpense.freeMeals ? isSerbiaCountry ? 'Da' : 'Yes' : isSerbiaCountry ? 'Ne': 'No'}
			/>
			<FormField
				label={isSerbiaCountry ? 'Besplatan smeštaj' : 'Free accommodation'}
				value={travelExpense.freeAccommodation ? isSerbiaCountry ? 'Da' : 'Yes' : isSerbiaCountry ? 'Ne': 'No'}
			/>
			<FormField
				label={isSerbiaCountry ? 'Naziv države' : 'Destination country'}
				value={persistedCountry.itemsMap[travelExpense.destinationCountryId]?.name}
			/>
			<FormField
				label={isSerbiaCountry ? 'Grad' : 'Destination city'}
				value={travelExpense.destinationCity}
			/>
			<FormField
				label={isSerbiaCountry ? 'Prevozno sredstvo' : 'Travel by'}
				value={getTravelByEnumLabel(travelExpense.travelBy)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Registarski broj' : 'Reg. number'}
				value={travelExpense.regNumber}
			/>
			<FormField
				label={isSerbiaCountry ? 'Udaljenost' : 'Distance'}
				value={travelExpense.distance}
			/>
			<FormField
				label={isSerbiaCountry ? 'Početna kilometraža' : 'Start mile/km'}
				value={travelExpense.startKilemeter}
			/>
			<FormField
				label={isSerbiaCountry ? 'Krajnja kilometraža' : 'End mile/km'}
				value={travelExpense.endKilometer}
			/>
			<FormField
				label={isSerbiaCountry ? 'Informacija 1' : 'Text 1'}
				value={travelExpense.customText1}
			/>
			<FormField
				label={isSerbiaCountry ? 'Informacija 2' : 'Text 2'}
				value={travelExpense.customText2}
			/>
			<SubtitleField
				title={isSerbiaCountry ? 'Odlazak' : 'Departure'}
			/>
			<FormField
				label={isSerbiaCountry ? 'Datum početka putovanja' : 'Date'}
				value={formatDate(travelExpense.leaveDepartureDate)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Vreme početka putovanja' : 'Time'}
				value={formatTime(travelExpense.leaveDepartureTime)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Datum prelaska granice u odlasku' : 'Border cross date'}
				value={formatDate(travelExpense.leaveBorderCrossDate)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Vreme prelaska granice u odlasku' : 'Border cross time'}
				value={formatTime(travelExpense.leaveBorderCrossTime)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Datum dolaska na destinaciju' : 'Arrival date'}
				value={formatDate(travelExpense.leaveArrivalDate)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Vreme  dolaska na destinaciju' : 'Arrival time'}
				value={formatTime(travelExpense.leaveArrivalTime)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Vreme provedeno na putu u odlasku' : 'Travel time'}
				value={travelExpense.leaveTravelTimeInMinutes ? minutesToDhm(travelExpense.leaveTravelTimeInMinutes, 60*24) : ''}
			/>
			<SubtitleField
				title={isSerbiaCountry ? 'Povratak' : 'Return'}
			/>
			<FormField
				label={isSerbiaCountry ? 'Datum početka povratka sa putovanja' : 'Date'}
				value={formatDate(travelExpense.returnDepartureDate)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Vreme početka povratka sa putovanja' : 'Time'}
				value={formatTime(travelExpense.returnDepartureTime)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Datum prelaska granice u povratku' : 'Border cross date'}
				value={formatDate(travelExpense.returnBorderCrossDate)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Vreme prelaska granice u povratku' : 'Border cross time'}
				value={formatTime(travelExpense.returnBorderCrossTime)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Datum završetka putovanja' : 'Arrival date'}
				value={formatDate(travelExpense.returnArrivalDate)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Vreme završetka putovanja' : 'Arrival time'}
				value={formatTime(travelExpense.returnArrivalTime)}
			/>
			<FormField
				label={isSerbiaCountry ? 'Vreme provedenu u putu u povratku' : 'Travel time'}
				value={travelExpense.returnTravelTimeInMinutes ? minutesToDhm(travelExpense.returnTravelTimeInMinutes, 60*24) : ''}
			/>
			<FormField
				label={isSerbiaCountry ? 'Ukupno vreme provedeno na putu' : 'Total travel duration'}
				value={travelExpense.totalTravelDurationInMinutes ? minutesToDhm(travelExpense.totalTravelDurationInMinutes, 60*24) : ''}
			/>
		</>
	);
};

export default TravelExpenseDocumentForm;
