import { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { ContentTitle } from 'features/Content';
import { ApprovalTimeTravelDescriptiveRequest, TimeTravelStatusEnum, TimeTravelStatusResponse, VacationRequestResponse, VacationRequestStatusResponse } from 'services/tenantManagementService';
import { getFormatedId, EntityPrefixEnum } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, useGoBackFromRead } from 'features/Crud';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import RejectRequestModalForm from 'containers/TimeTravel/Approval/RejectRequest/RejectRequestModalForm';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { RootState } from 'base/reducer/reducer';
import { approvalVacationRequestAction, getVacationRequestAction } from 'containers/TimeTravel/MyVacationRequests/action';
import VacationRequestForm from 'containers/TimeTravel/MyVacationRequests/Crud/VacationRequestForm';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Select } from 'components/Form';
import { ReadComponentProps } from 'features/Crud/CrudRouter';

type ParamType = {
	vacationRequestId: string;
	vacationRequestStatusId: string;
}

const ReadVacationRequest = ({publishDataChanged}: ReadComponentProps) => {
	const params: ParamType = useParams();
	const [vacationRequest, setVacationRequest] = useState(new VacationRequestResponse());
	const [isSubmitting, setIsSubmitting] = useState(false);
	const goBackFromRead = useGoBackFromRead(true);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const {	persistedTimeAndTravelStatus, persistedVacationGeneralConfiguration } = useSelector((state: RootState) => state);

	const vrStatusMemo = useMemo(
		() => vacationRequest.statuses?.find(status => status.id === parseInt(params.vacationRequestStatusId)) || new VacationRequestStatusResponse(),
		[vacationRequest, params]
	)

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getVacationRequestAction.bind(null, parseInt(params.vacationRequestId));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {

				setVacationRequest(response.value || new VacationRequestResponse());
			}
		},
		[params]
	)

	const approveCallback = useCallback(
		async () => {
			const request = new ApprovalTimeTravelDescriptiveRequest({
				approve: true
			})
			setIsSubmitting(true);
			const bindedAction = approvalVacationRequestAction.bind(null, vacationRequest.id, vrStatusMemo.id, request);
			const response = await tryCatchJsonByAction(bindedAction);
			setIsSubmitting(false);

			if (response.success) {
				notifications.success(`${getFormatedId(EntityPrefixEnum.VACATION_REQUEST, vacationRequest.id)} is approved`)
				publishDataChanged();
				goBackFromRead();
			}
		},
		[vacationRequest, vrStatusMemo, goBackFromRead, publishDataChanged]
	)

	const rejectCallback = useCallback(
		async (rejectionComment?: string) => {
			const request = new ApprovalTimeTravelDescriptiveRequest({
				approve: false,
				rejectionText: rejectionComment
			})
			setIsSubmitting(true);
			const bindedAction = approvalVacationRequestAction.bind(null, vacationRequest.id, vrStatusMemo.id, request);
			const response = await tryCatchJsonByAction(bindedAction);
			setIsSubmitting(false);

			if (response.success) {
				notifications.success(`${getFormatedId(EntityPrefixEnum.VACATION_REQUEST, vacationRequest.id)} is rejected`)
				publishDataChanged();
				goBackFromRead();
			} else {
				return convertResponseErrors(response);
			}
		},
		[vacationRequest, vrStatusMemo, goBackFromRead, publishDataChanged]
	)

	const approveDisabledMemo = useMemo(
		() => !vrStatusMemo.id ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, vrStatusMemo.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Approved, vrStatusMemo.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[vrStatusMemo, persistedTimeAndTravelStatus.itemsMap, isSubmitting]
	)

	const rejectDisabledisabledMemo = useMemo(
		() => !vrStatusMemo.id ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, vrStatusMemo.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Rejected, vrStatusMemo.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[vrStatusMemo, persistedTimeAndTravelStatus.itemsMap, isSubmitting]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentTitle title={`View Vacation request details - ${getFormatedId(EntityPrefixEnum.VACATION_REQUEST, vacationRequest.id)}`} />
			<ColumnContainer margin='medium'>
				<RowContainer>
					<Button text='Back' disabled={isSubmitting} onClick={goBackFromRead} />
					{persistedVacationGeneralConfiguration.value.enableApprovalProcess && (
						<RowContainer>
							<Button text='Approve' disabled={approveDisabledMemo} onClick={approveCallback} isLoading={isSubmitting} />
							<Button text='Reject' disabled={rejectDisabledisabledMemo} onClick={() => { setIsModalOpen(true) }} isLoading={isSubmitting} />
						</RowContainer>
					)}

				</RowContainer>
				<VacationRequestForm
					vacationRequest={vacationRequest}
					crud={CrudEnum.Read}
				/>
				<SmartContainer>
					<SmartItem>
						<SmartFormGroup label='Status'>
							<Select
								value={vrStatusMemo.statusId}
								items={persistedTimeAndTravelStatus.items}
								getItemId={(item: TimeTravelStatusResponse) => item.id}
								getItemText={(item: TimeTravelStatusResponse) => item.name}
								loading={persistedTimeAndTravelStatus.fetching}
								disabled
							/>
						</SmartFormGroup>
					</SmartItem>
				</SmartContainer>
				<RejectRequestModalForm
					title={`Reject vacation request - ${getFormatedId(EntityPrefixEnum.VACATION_REQUEST, vacationRequest.id)}`}
					onCancel={() => {setIsModalOpen(false)}}
					onSave={rejectCallback}
					open={isModalOpen}
					rejectionCommentRequired={persistedVacationGeneralConfiguration.value.requireCommentsWhenRejecting}
				/>
			</ColumnContainer>
		</WithFetch>
	)
}

export default ReadVacationRequest;
