import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectSubcontractorResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createSubcontractorsPerformanceAction } from '../action';
import { SubcontractorPerformanceForm } from '../SubcontractorPerformanceForm';
import { Dialog } from 'components/Dialog';
import { publishSubcontractorPerformancesChanged } from '../subcontractorPerformanceChanged';
import { ReportComponentParams } from '../../Table/TableButtons';
import { useGoBackFromCreate } from 'features/Crud';

export const CreateSubcontractorPerformance = () => {
	const params: ReportComponentParams = useParams();
	const reportId = parseInt(params.reportId);

	const goBackFromCreate = useGoBackFromCreate(true);

	const onSubmitCallback = useCallback(
		async (subcontractorPerformance: ProjectSubcontractorResponse) => {
			const bindedAction = createSubcontractorsPerformanceAction.bind(null, reportId, subcontractorPerformance);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Subcontractor\'s performance successfully created');
				goBackFromCreate();
				publishSubcontractorPerformancesChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, reportId]
	);

	return(
		<Dialog
			title='Create Subcontractor Performance'
			onClose={goBackFromCreate}
			open
		>
			<SubcontractorPerformanceForm save={onSubmitCallback} cancel={goBackFromCreate} />
		</Dialog>
	)
};
