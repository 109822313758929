import { LocalTable } from "components/Table"
import { TicketResponse, TicketStatusEnum } from "services/tenantManagementService"
import { useTableColumnsMemo } from './Table/tableColumns';
import { useMemo } from 'react';
import { emptyArray } from "utils/commonHelper";
import { getStatusBySemantic } from "features/StatusResponse/statusResponse";
import { useSelector } from "react-redux";
import { RootState } from "base/reducer/reducer";

const configureViewKey = 'ticket_sla_status_durations_table';

type Props = {
	ticket: TicketResponse
}

export const StatusDurations = ({ ticket }: Props) => {
	const { persistedTicketStatus } = useSelector((state: RootState) => state);

	const statusDurations = ticket.statusDurations || emptyArray;

	const onHoldTotalDuration = useMemo(
		() => {
			let total = 0;
			const onHoldStatus = getStatusBySemantic(TicketStatusEnum.OnHold, persistedTicketStatus.items);
			for (let item of statusDurations) {
				if (item.statusId === onHoldStatus?.id) {
					total += item.duration;
				}
			}

			return total;
		},
		[statusDurations, persistedTicketStatus]
	)

	const tableColumns = useTableColumnsMemo(onHoldTotalDuration, configureViewKey,);

	return (
		<LocalTable
			columns={tableColumns}
			data={statusDurations}
		/>
	)
}
