import { HelpShell } from 'features/Help/HelpShell';

export const DefaultProjectHelp = () => {
	return (
		<HelpShell title='Default project'>
			<p>
				For your convenience, you can set up default project. <br />
				This will fill in selected project in selection screens.
			</p>
		</HelpShell>
	)
}

