import { TrainingPlanRowResponse } from 'services/tenantManagementService';

export class CustomTrainingPlanRowModel {
    id!: number;
    isActive!: boolean;
    trainerUserId?: number | undefined;
    location?: string | undefined;
    plannedDate?: Date | undefined;
    plannedTime?: number | undefined;
    plannedTimeTo?: number | undefined;
    duration?: number | undefined;
    linkToMaterial?: string | undefined;
    linkToVideo?: string | undefined;
    level3ProcessId?: string | undefined;
    level3ProcessName?: string | undefined;
    participants?: number[] | undefined;

	constructor(data?: CustomTrainingPlanRowModel) {
 		if (data) {
			this.id = data.id;
			this.isActive = data.isActive;
			this.trainerUserId = data.trainerUserId;
			this.location = data.location;
			this.plannedDate = data.plannedDate;
			this.plannedTime = data.plannedTime;
			this.plannedTimeTo = data.plannedTimeTo;
			this.duration = data.duration;
			this.linkToMaterial = data.linkToMaterial;
			this.linkToVideo = data.linkToVideo;
			this.level3ProcessId = data.level3ProcessId;
			this.level3ProcessName = data.level3ProcessName;
			this.participants = data.participants;
		}
	}
}

export const mapToCustomTrainingPlanRowModel = (trainingPlans: TrainingPlanRowResponse[]) => {
	return trainingPlans.map(trainingPlan => new CustomTrainingPlanRowModel({ ...trainingPlan, participants: trainingPlan.participants?.map(x => x.userId) }));
}
