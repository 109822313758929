import { VerticalSeparator } from 'components/Layout';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { TimeField, FormGroup, AutoCompleteField } from 'components/Form';
import { propertyOf } from 'utils/propertyOf';
import { ProjectSLAResponse, WeekDayEnum } from 'services/tenantManagementService';

const daysOfWeek = Object.values(WeekDayEnum);

export const Calendar = () => {
	return (
		<FormGroup title='Working Time'>
			<SmartContainer>
				<SmartItem size='xsmall'>
					<AutoCompleteField
						id={propertyOf<ProjectSLAResponse>('workWeekFrom')}
						label='Work week'
						items={daysOfWeek}
						getItemId={(item: WeekDayEnum) => item}
						getItemText={(item: WeekDayEnum) => item}
					/>
				</SmartItem>
				<SmartItem size='xsmall'>
					<AutoCompleteField
						id={propertyOf<ProjectSLAResponse>('workWeekTo')}
						label='to'
						items={daysOfWeek}
						getItemId={(item: WeekDayEnum) => item}
						getItemText={(item: WeekDayEnum) => item}
					/>
				</SmartItem>
			</SmartContainer>
			<VerticalSeparator margin='small' />
			<SmartContainer>
				<SmartItem size='xsmall'>
					<TimeField
						id={propertyOf<ProjectSLAResponse>('workTimeFrom')}
						label='Work time'
					/>
				</SmartItem>
				<SmartItem size='xsmall'>
					<TimeField
						id={propertyOf<ProjectSLAResponse>('workTimeTo')}
						label='to'
					/>
				</SmartItem>
			</SmartContainer>
		</FormGroup>
	)
}
