import { Dialog } from 'components/Dialog';
import { CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { TravelExpenseItemResponse } from 'services/tenantManagementService';
import TravelExpenseItemForm from './TravelExpenseItemForm';

type Props = {
	onSave: (travelExpenseItem: TravelExpenseItemResponse) => void
}

const CreateTravelExpenseItem = ({ onSave }: Props) => {

	const goBackFromCreate = useGoBackFromCreate(true);

	return (
		<Dialog
			title='Create new expense'
			open
			onClose={goBackFromCreate}
			size='large'
		>
			<TravelExpenseItemForm
				crud={CrudEnum.Create}
				onSave={onSave}
			/>
		</Dialog>
	)
}

export default CreateTravelExpenseItem;
