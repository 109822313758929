import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getRouteObject, RouterProps } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { CrudRouter } from 'features/Crud';
import { noop } from 'utils/commonHelper';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { persistUserStatusesAction } from '../General/Statuses/Tabs/Users/action';
import { persistAllProjectRolesAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Roles/action';
import { persistAllProjectTeamsAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Teams/action';
import { UserList } from './UserList/UserList';
import { CreateUser } from './Users/Crud/CreateUser';
import { ReadUser } from './Users/Crud/ReadUser';
import { UpdateUser } from './Users/Crud/UpdateUser';
import { UserAdministrationSubRoutesEnum, createUserSubRoute, userListSubRoute } from './routes';

export const UserAdministrationRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistUserStatusesAction();
			persistAllProjectRolesAction();
			persistAllProjectTeamsAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[UserAdministrationSubRoutesEnum.USER_LIST] &&
				<Route
					path={userListSubRoute.url}
					render={() => (
						<CrudRouter
							ListComponent={UserList}
							CreateComponent={CreateUser}
							ReadComponent={ReadUser}
							UpdateComponent={UpdateUser}
							paramName='userId'
						/>
					)}
				/>
			}
			{routesMap[UserAdministrationSubRoutesEnum.CREATE_USER] &&
				<Route
					path={createUserSubRoute.url}
					render={() => <CreateUser publishDataChanged={noop} />}
				/>
			}
			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
