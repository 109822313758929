import { useCallback, useState } from 'react';
import { TaskGeneralConfigResponse, UpdateTaskGeneralConfigRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTaskGeneralConfigAction, updateTaskGeneralConfigAction } from './action';
import notifications from 'components/Notification/notification';
import { CheckboxField, Form, FormGroup } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import WithFetch from 'features/Fetch/WithFetch';
import { propertyOf } from 'utils/propertyOf';

export const TaskGeneral = () => {
	const [values, setValues] = useState(new TaskGeneralConfigResponse());
	const [initialValues, setInitialValues] = useState(new TaskGeneralConfigResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getTaskGeneralConfigAction);
			if (response.success && response.value) {
				setValues(new TaskGeneralConfigResponse(response.value));
				setInitialValues(new TaskGeneralConfigResponse(response.value));
			}
		},
		[]
	);

	const handleCancel = useCallback(
		() => setValues(new TaskGeneralConfigResponse(initialValues)),
		[initialValues]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const model = new UpdateTaskGeneralConfigRequest(values);

			const bindedAction = updateTaskGeneralConfigAction.bind(null, model)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setInitialValues(new TaskGeneralConfigResponse(values));
				notifications.success('Task general configuration is updated.');
			} else {
				return convertResponseErrors(response);
			}
		},
		[values]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback} >
			<Form
				values={values}
				initialValues={initialValues}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={handleCancel}
				render={() => (
					<SmartContainer>
						<SmartItem>
							<FormGroup title='Notifications'>
								<CheckboxField
									id={propertyOf<TaskGeneralConfigResponse>('enableEmailNotifications')}
									labelBefore='Enable email notifications'
								/>
								<CheckboxField
									id={propertyOf<TaskGeneralConfigResponse>('notifyWhenAssignedToUser')}
									labelBefore='Notify when assigned to user'
								/>
							</FormGroup>
						</SmartItem>
					</SmartContainer>
				)}
			/>
		</WithFetch>
	)
}
