import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Form, InputNumberField, TextareaField, currencyDefaultProps, AttachmentField, AutoCompleteField } from 'components/Form';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { IdNameResponse, ExpenseItemResponse, CostExpenseTypeResponse, InsertExpenseItemRequest } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { useCurrencySuffixByIdMemo, useCurrencySuffixMemo } from 'features/Currency/useCurrencySuffixMemo';
import { downloadAttachmentAction } from '../../action';

type Props = {
	expenseItem?: ExpenseItemResponse
	crud: CrudEnum
	onSave?: (expenseItem: ExpenseItemResponse) => void
}

const ExpenseItemForm = ({
	expenseItem = new ExpenseItemResponse(),
	crud,
	onSave
}: Props) => {
	const {
		persistedTenant,
		persistedCurrency,
		persistedTimeTravelExpenseTypes,
	} = useSelector((state: RootState) => state);

	const isRead = crud === CrudEnum.Read;

	const [values, setValues] = useState(expenseItem);

	const currencySymbolSuffix = useCurrencySuffixMemo(persistedCurrency, persistedTenant);
	const receiptCurrencySymbolSuffix = useCurrencySuffixByIdMemo(persistedCurrency, values.receiptCurrencyId);

	const goBackFromCrud = useGoBackFromCrud(crud, true);

	const onSubmitCallback = useCallback(
		async () => {
			onSave && onSave(values)
			goBackFromCrud();
		},
		[onSave, values, goBackFromCrud]
	)

	// Svetlana asked to disable delete on existing attachments
	// const removeAttachmentCallback = useCallback(
	// 	async (id: number) => {
	// 		setValues((state: ExpenseItemResponse) =>
	// 			new ExpenseItemResponse({
	// 				...state,
	// 				attachments: state.attachments?.filter(att => att.id !== id)
	// 			})
	// 		)
	// 	},
	// 	[]
	// )

	const downloadAttachmentMemo = useMemo(
		() => downloadAttachmentAction.bind(null, expenseItem.id),
		[expenseItem.id]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			disabled={isRead}
			hideButtons={isRead}
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<AutoCompleteField
							id={propertyOf<ExpenseItemResponse>('expenseTypeId')}
							label='Cost type'
							items={persistedTimeTravelExpenseTypes.items}
							getItemId={(option: CostExpenseTypeResponse) => option.id}
							getItemText={(option: CostExpenseTypeResponse) => option.name}
							loading={persistedTimeTravelExpenseTypes.fetching}
							isRequired
						/>
						<InputNumberField
							id={propertyOf<ExpenseItemResponse>('amountProjectCurrency')}
							label='Amount in project currency'
							{...currencyDefaultProps}
							suffix={currencySymbolSuffix}
							isRequired
						/>
						<InputNumberField
							id={propertyOf<ExpenseItemResponse>('amountReceiptCurrency')}
							label='Amount in receipt currency'
							{...currencyDefaultProps}
							suffix={receiptCurrencySymbolSuffix}
						/>
						<AutoCompleteField
							id={propertyOf<ExpenseItemResponse>('receiptCurrencyId')}
							label='Receipt currency'
							items={persistedCurrency.items}
							getItemId={(option: IdNameResponse) => option.id}
							getItemText={(option: IdNameResponse) => option.symbol}
							loading={persistedCurrency.fetching}
						/>
						<TextareaField
							id={propertyOf<ExpenseItemResponse>('comment')}
							label='Comment'
							maxLength={2000}
							rows={5}
							isRequired
						/>
						<AttachmentField
							id={propertyOf<InsertExpenseItemRequest>('newAttachments')}
							label='Attachments'
							multiple
							isRequired={
								persistedTimeTravelExpenseTypes.itemsMap[values.expenseTypeId]?.mandatoryAttachments &&
								(!values.attachments || values.attachments.length === 0)
							}
							accept='image/png, image/jpeg, .pdf'
							oldAttachments={values.attachments}
							downloadOldAttachment={downloadAttachmentMemo}
							// removeOldAttachment={removeAttachmentCallback}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}

export default ExpenseItemForm;
