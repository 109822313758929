import { useCallback, useMemo } from "react";
import { BaseColumnModel, FieldTypeEnum, TableColumnsType } from "components/Table";
import { getFormatedId, noop } from "utils/commonHelper";
import { formatDate } from "utils/dateTimeUtils";
import { capitalizeFirstLetter } from "utils/stringUtil";
import { useFormattedCurrencyValueCallback } from "features/TableColumns/persistedHooks";
import '../../assets/icomoon/style.css'
import { CellComponent } from "tabulator-tables";

const mergeWithDefaultValues = (column: BaseColumnModel) => {
	if (column.fieldType === FieldTypeEnum.Boolean && !column.formatter) {
		column.formatter = 'tickCross';
		column.formatterParams = {
			tickElement: "<i class='table_icons_check'></i>",
			crossElement: "<i></i>",
		}
	}

	if (column.fieldType === FieldTypeEnum.Date && !column.formatter) {
		column.formatter = (cell: CellComponent) => formatDate(cell.getValue());
	}

	if (column.fieldType === FieldTypeEnum.Date && !column.format) {
		column.format = '{0:MM/dd/yyyy}'; // for backend
	}

	if (column.fieldType === FieldTypeEnum.Number && !column.format) {
		column.format = '{0:0.00}'; // for backend
	}

	if (column.fieldType === FieldTypeEnum.FormattedReference && column.entityPrefix && !column.formatter) {
		column.formatter = (cell: CellComponent) => getFormatedId(column.entityPrefix, cell.getValue());
	}

	if (column.fieldType === FieldTypeEnum.FormattedReference && column.entityPrefix && !column.format) {
		column.format = `${column.entityPrefix}{0:D8}`
	}

	if (column.dbExportFieldPath === undefined) {
		column.dbExportFieldPath = capitalizeFirstLetter(column.field);
	}

	if (column.visible) {
		// if tooltip is not set, but there is formatter use formatter with all needed params
		if (!column.tooltip && column.formatter && typeof column.formatter === 'function') {
			column.tooltip = function(...args: any[]) {
				return (column.formatter && typeof column.formatter === 'function') ? column.formatter(args[1], column.formatterParams, noop) : '';
			}
		}
	}
}

const useMergeWithCurrencyDefaultValues = () => {
	const getFormattedCurrencyValue = useFormattedCurrencyValueCallback();

	return useCallback(
		(column: BaseColumnModel) => {
			if (column.fieldType === FieldTypeEnum.Currency) {
				if (!column.formatter) {
					column.formatter = (cell: CellComponent) => getFormattedCurrencyValue(cell.getValue());
				}
				if (!column.format) {
					// Svetlana je trazila da format ne sadrzi valutu
					// column.format = `{0:0.00} ${persistedCurrency.itemsMap[persistedTenant.value.currencyId]?.symbol}`;
					column.format = '{0:0.00}';
				}
				if (!column.hozAlign) {
					column.hozAlign = 'right';
				}

				if (column.bottomCalc && !column.bottomCalcFormatter) {
					column.bottomCalcFormatter = (cell: CellComponent) => {
						const formattedValue = getFormattedCurrencyValue(cell.getValue());
						return `∑ ${formattedValue}`;
					}
				}
			}
		},
		[getFormattedCurrencyValue]
	)
}

const mergeWithWidthDefaultValues = (column: any) => {
	let minWidth: number = 64;
	let maxInitialWidth: number | undefined;

	switch (column.fieldType) {
		case FieldTypeEnum.Boolean:
		case FieldTypeEnum.Semaphore:
		case FieldTypeEnum.Number:
		case FieldTypeEnum.Currency:
		case FieldTypeEnum.Date:
		case FieldTypeEnum.FormattedReference:
			maxInitialWidth = 150;
			break;
		case FieldTypeEnum.Options:
		case FieldTypeEnum.Option:
			maxInitialWidth = 250;
			break;
		case FieldTypeEnum.MapOption:
			maxInitialWidth = 300;
			break;
		case FieldTypeEnum.String:
			maxInitialWidth = 550;
			break;
	}

	if (minWidth) {
		column.minWidth = column.minWidth || minWidth;
	}
	if (maxInitialWidth) {
		column.maxInitialWidth = column.maxInitialWidth || maxInitialWidth;
	}
}

export const useVisibleColumns = (columns: TableColumnsType, visibleColumns: string[]): BaseColumnModel[] => {
	const mergeWithCurrencyDefaultValues = useMergeWithCurrencyDefaultValues();

	return useMemo(
		() => {
			const hiddenColumns = Object.keys(columns).reduce(
				(result: BaseColumnModel[], field) => {
					if (!visibleColumns.includes(field)) {
						const convertedColumn: BaseColumnModel = {
							...columns[field],
							field,
							visible: false
						}

						mergeWithCurrencyDefaultValues(convertedColumn);
						mergeWithDefaultValues(convertedColumn);
						mergeWithWidthDefaultValues(convertedColumn);

						result.push(convertedColumn);
					}

					return result;
				},
				[]
			)

			const result = visibleColumns
				.filter(field => !!columns[field])
				.map(field => {
					const convertedColumn: BaseColumnModel = {
						...columns[field],
						field,
						visible: true
					}

					mergeWithCurrencyDefaultValues(convertedColumn);
					mergeWithDefaultValues(convertedColumn);
					mergeWithWidthDefaultValues(convertedColumn);

					return convertedColumn;
				})

			return [...result, ...hiddenColumns]
		},
		[columns, visibleColumns, mergeWithCurrencyDefaultValues]
	)
}
