import { HelpShell } from 'features/Help/HelpShell';

export const UserListHelp = () => {
	return (
		<HelpShell title='User List'>
			<div>
				<p>In Mavles we have 2 types of users:</p>
				<ul>
					<li>Company users</li>
					<li>Guests (Partner companies: suppliers and customers)</li>
				</ul>
			</div>
			<div>
				<p>Company users are further divided to:</p>
				<ul>
					<li>Site administrators</li>
					<li>Project Managers</li>
					<li>Project Manager substitutes</li>
					<li>Managers</li>
					<li>Users</li>
				</ul>
			</div>
			<p><b>Site administrators</b> - configure Mavles based on company needs. They have authorizations to perform all activities in Mavles and manage licensing. Site administrators are creating new partners and projects and assigning project managers.</p>
			<p><b>Project Managers and their substitutes</b> - manage projects for which they are responsible for. They create users for their projects, define project teams and assigns project users (company and guests) to their projects. Project managers have authorizations for all activities on their projects. Only company users can be defined as project managers.</p>
			<p><b>User</b> - are project team members. User authorizations are defined by responsible Project Manager. In general, users are not allowed to access Project and Finance module.</p>
			<p><b>Managers</b> - are executives defined by site administrator and assigned to organizational unit for which they are responsible for. They can view dashboards of projects which are assigned to same organizational unit or units below. Managers can also be allowed to approve Time &amp; Travel requests for users belonging to organizational unit.</p>
			<p><b>Guest</b> - are project team members that are not company's employees. Usually, they are company's vendors or customers assigned to project. Guest users cannot be defined as project managers and their activities can be limited. Guest users are also created and assigned to project by responsible project manager, but it is mandatory to enter information about Partner when creating them.</p>
			<div>
				<p>User list provides two reports:</p>
				<ol>
					<li>List of all users created in Mavles with their contact data and also status if user is active in at least one project.</li>
					<li>User status per project shows list of projects for which user is created as well as validity per project and last billing and cost rates.</li>
				</ol>
			</div>
		</HelpShell>
	)
}
