import Button from "components/Button";
import { RowContainer } from "components/Layout";
import { useCallback } from "react";
import { gantt } from "../GanttChart";

export const ActionButtons = () => {
	const criticalPath = useCallback(
		() => {
			gantt.config.highlight_critical_path = !gantt.config.highlight_critical_path;
			gantt.render();
		},
		[]
	)

	return (
		<RowContainer>
			<Button
				text='Critical Path'
				color='neutral'
				onClick={criticalPath}
			/>
		</RowContainer>
	)
}
