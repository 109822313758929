import { DeleteMeetingTopicRequest, InsertMeetingTopicRequest, InsertMeetingTopicRequestUpdateMeetingTopicRequestDeleteMeetingTopicRequestDeltaModel, MeetingTopicResponse, UpdateMeetingTopicRequest } from "services/tenantManagementService";

class ItemsMap {
	[key: string]: any
}

export function createMeetingTopicDelta(
	initialItems: MeetingTopicResponse[],
	items: MeetingTopicResponse[],
) {
	// create maps
	const initialItemMap: ItemsMap = initialItems.reduce((currentMap, item) => {
		return { ...currentMap, [item.generatedId!.toString()]: item };
	}, {})

	const itemMap: ItemsMap = items.reduce((currentMap, item) => {
		return { ...currentMap, [item.generatedId!]: item };
	}, {})

	const result = new InsertMeetingTopicRequestUpdateMeetingTopicRequestDeleteMeetingTopicRequestDeltaModel();

	Object.keys(itemMap).forEach((generatedId, index) => {

		const currentItem : MeetingTopicResponse = itemMap[generatedId];
		const initialItem : MeetingTopicResponse = initialItemMap[generatedId];

		// insert
		if (!initialItem) {
			const insertItem = new InsertMeetingTopicRequest(currentItem);
			if (!result.insert) result.insert = [];
			result.insert.push(insertItem);
			return;
		}

		// update
		if (initialItem !== currentItem) {
			const updateItem = new UpdateMeetingTopicRequest({
				...currentItem,
				initialType: initialItem.type
			});
			if (!result.update) result.update = [];
			result.update.push(updateItem);
		}
	});

	// delete
	Object.keys(initialItemMap).forEach(generatedId => {
		if (!itemMap[generatedId]) {
			if (!result.delete) result.delete = [];
			const item: MeetingTopicResponse = initialItemMap[generatedId]
			const deleteObject = new DeleteMeetingTopicRequest({
				id: item.id,
				type: item.type
			});
			result.delete.push(deleteObject);
		}
	});

	return result;
}
