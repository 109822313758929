import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import path from 'path';
import { ColumnContainer } from 'components/Layout';
import Tabs from 'components/Tabs/Tabs';
import { WeekPicker } from './WeekPicker';
import { getWeekTabs } from './weekTabs';
import { getDifferenceInDaysFromMonday, getWeekTabSubrouteByDate, WeekTabSubroutes } from './WeekSubroutes';
import { getMondayOfWeekByDate } from 'utils/dateTimeUtils';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getAllMyRecoredStatusesForPeriodAction } from './action';
import { TimesheetRecordedStatusResponse, TimesheetsPeriodRequest } from 'services/tenantManagementService';
import WithFetch from 'features/Fetch/WithFetch';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ContentShell } from 'features/Content/ContentShell';
import MyTimesheetsHelp from './Help/MyTimesheetsHelp';

type ParamType = {
	ticks: string;
}

const MyTimesheets = () => {
	const [statusRecords, setStatusRecords] = useState<TimesheetRecordedStatusResponse[]>([]);

	const params: ParamType = useParams();
	const routematch = useRouteMatch();
	const history = useHistory();

	const { persistedTimesheetsGeneralConfiguration } = useSelector((state: RootState) => state)

	useEffect(
		() => {
			if (!params.ticks) {
				const currentDate = new Date();
				const monday = getMondayOfWeekByDate(currentDate);
				history.push(path.join(history.location.pathname, `${monday.getTime()}`, getWeekTabSubrouteByDate(currentDate)));
			} else {
				const urlDate = new Date(parseInt(params.ticks));
				const weekDay = urlDate.getDay();
				// if it is not monday, change ticks to monday
				if (weekDay !== 1) {
					const monday = getMondayOfWeekByDate(urlDate);
					const newPath = generatePath(routematch.path, {ticks: monday.getTime()})

					history.push(path.join(newPath, getWeekTabSubrouteByDate(urlDate)));
				}
			}
		},
		[params.ticks, history, routematch]
	)

	const mondayOfCurrentWeekMemo = useMemo(
		() => {
			if (!params.ticks) {
				return null;
			}
			return new Date(parseInt(params.ticks));
		},
		[params.ticks]
	)

	const selectedDayMemo = useMemo(
		() => {
			if (!mondayOfCurrentWeekMemo){
				return null;
			}
			const result = new Date(mondayOfCurrentWeekMemo);
			const pathname = history.location.pathname;
			const weekDaySubroute = pathname.substring(pathname.lastIndexOf('/') + 1) as WeekTabSubroutes
			const difference = getDifferenceInDaysFromMonday(weekDaySubroute);
			result.setDate(result.getDate() + difference);

			return result;
		},
		[mondayOfCurrentWeekMemo, history.location]
	)

	const fetchDataCallback = useCallback(
		async () => {
			if (!mondayOfCurrentWeekMemo) {
				return;
			}

			const sundayOfCurrentWeek = new Date(mondayOfCurrentWeekMemo);
			sundayOfCurrentWeek.setDate(sundayOfCurrentWeek.getDate() + 6);

			const period = new TimesheetsPeriodRequest({
				from: mondayOfCurrentWeekMemo,
				to: sundayOfCurrentWeek
			});

			const bindedAction = getAllMyRecoredStatusesForPeriodAction.bind(null, period)
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.items) {
				setStatusRecords(response.items)
			}
		},
		[mondayOfCurrentWeekMemo]
	)

	const changeCallback = useCallback(
		(newDate: Date) => {
			const newPath = generatePath(routematch.path, {ticks: newDate.getTime()})
			history.push(newPath);
		},
		[history, routematch]
	)

	return (
		<ContentShell
			title='My timesheets'
			FloatingHelpComponent={MyTimesheetsHelp}
		>
			<ColumnContainer>
				<WeekPicker date={mondayOfCurrentWeekMemo!} onChange={changeCallback} />
				<WithFetch fetchFunction={fetchDataCallback}>
					{params.ticks && statusRecords.length === 7 && (
						<Tabs
							tabs={getWeekTabs(statusRecords, persistedTimesheetsGeneralConfiguration.value.allowOvertimeWeekendHolidayWork)}
							tabComponentProps={{ date: selectedDayMemo, onChange: fetchDataCallback }}
						/>
					)}
				</WithFetch>
			</ColumnContainer>
		</ContentShell>
	)
}

export default MyTimesheets;
