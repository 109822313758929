import styles from 'components/Map/map.module.scss';
import { useMemo } from 'react';

type Props = {
	level: number
}

export const LevelTitle = ({ level }: Props) => {
	const titleContent = useMemo(
		() => {
			if (level === 1) {
				return 'Mavles site';
			} else if (level === 2) {
				return 'Country';
			} else {
				return `Level ${level - 2}`
			}
		},
		[level]
	)

	return (
		<div className={styles.header}>
			<h5 className={styles.container}>
				{titleContent}
			</h5>
		</div>
	);
};
