import { getTenantId } from 'features/Tenant/tenantUtils';
import { GenericFilterModelCollection, SubmitMySurveyRequest, SurveyClient, SurveyQuestionClient } from 'services/tenantManagementService';

export const getAllMySurveysGenericAction = (projectId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new SurveyClient();
	return client.getAllMy(tenantId, projectId, genericFilter.offset, genericFilter.limit);
}

export const getMySurveyQuestionsAction = (projectId: number, surveyId: number) => {
	const tenantId = getTenantId()!;
	const client = new SurveyQuestionClient();
	return client.getMy(tenantId, surveyId, undefined, undefined, projectId?.toString());
}

export const getMySurveyAction = (projectId: number, surveyId: number) => {
	const tenantId = getTenantId()!;
	const client = new SurveyClient();
	return client.getMy(tenantId, projectId, surveyId);
}

export const submitMySurveyAction = (projectId: number, surveyId: number, submitMySurveyRequest: SubmitMySurveyRequest) => {
	const tenantId = getTenantId()!;
	const client = new SurveyClient();
	return client.submitMy(tenantId, surveyId, projectId?.toString(), submitMySurveyRequest);
}
