import { RootState } from 'base/reducer/reducer'
import { ColumnContainer } from 'components/Layout'
import { Note } from 'components/Note/Note'
import { Dashboard } from 'features/Dashboard/Dashboard'
import { Layout } from 'features/Dashboard/Layout'
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CostExpenseTypeResponse, ModuleActivityEnum, ProjectResponse } from 'services/tenantManagementService'
import { TabComponentProps } from '../../FilteredDashboard'
import { pmOrSpmOrOumPermission, useProjects } from 'features/Project'

export const TravelExpensesDashboard = ({ loading, dashboard, filterModel }: TabComponentProps) => {
	const { persistedTimeTravelExpenseTypes, persistedTravelCostTypes } = useSelector((state: RootState) => state);

	const { projects } = useProjects({
		isProjectDashboard: true,
		moduleEnum: ModuleActivityEnum.Finance,
		userRolePermission: pmOrSpmOrOumPermission
	});
	let projectsByFilterModel = projects;
	if (filterModel.projectIds) {
		projectsByFilterModel = projects.filter((project) => filterModel.projectIds?.includes(project.id));
	}

	const travelExpensesPerTypeMemo = useMemo(
		() => {
			const data = persistedTravelCostTypes.items.map((item: CostExpenseTypeResponse) => {
				return {
					id: item.id,
					name: item.name,
					count: (dashboard.travelExpenses?.travelExpensesPerCostType && dashboard.travelExpenses?.travelExpensesPerCostType[item.id]) || 0
				}
			});

			return data;
		},
		[dashboard, persistedTravelCostTypes]
	)

	const expensesPerTypeMemo = useMemo(
		() => {
			const data = persistedTimeTravelExpenseTypes.items.map((item: CostExpenseTypeResponse) => {
				return {
					id: item.id,
					name: item.name,
					count: (dashboard.travelExpenses?.expensesPerCostType && dashboard.travelExpenses?.expensesPerCostType[item.id]) || 0
				}
			});

			return data;
		},
		[dashboard, persistedTimeTravelExpenseTypes]
	)

	const travelExpensesPerProjectMemo = useMemo(
		() => {
			const data = projectsByFilterModel.map((item: ProjectResponse) => {
				return {
					id: item.id,
					name: item.name,
					count: (dashboard.travelExpenses?.travelExpensesPerProject && dashboard.travelExpenses?.travelExpensesPerProject[item.id]) || 0
				}
			});

			return data;
		},
		[dashboard, projectsByFilterModel]
	)

	const expensesPerProjectMemo = useMemo(
		() => {
			const data = projectsByFilterModel.map((item: ProjectResponse) => {
				return {
					id: item.id,
					name: item.name,
					count: (dashboard.travelExpenses?.expensesPerProject && dashboard.travelExpenses?.expensesPerProject[item.id]) || 0
				}
			});

			return data;
		},
		[dashboard, projectsByFilterModel]
	)

	const columnSeriesFieldsMemo = useMemo(
		() => {
			return [{
				value: 'count',
				name: 'Expenses sum'
			}]
		},
		[]
	)

  	return (
		  <ColumnContainer margin='small'>
			<Note text='Reports from time and travel module' />
			<Dashboard orientation='vertical'>
				<Layout orientation='horizontal'>
					<RotatedColumnSeriesWidget
						className='col-lg-6'
						title='Travel expenses per cost type'
						id='travel_expenses_per_type'
						data={travelExpensesPerTypeMemo}
						fieldCategory='name'
						fieldValues={columnSeriesFieldsMemo}
						loading={loading}
						hideLegend
						showValueOnBar
					/>
					<RotatedColumnSeriesWidget
						className='col-lg-6'
						title='Expenses per cost type'
						id='expenses_per_type'
						data={expensesPerTypeMemo}
						fieldCategory='name'
						fieldValues={columnSeriesFieldsMemo}
						loading={loading}
						hideLegend
						showValueOnBar
					/>
				</Layout>
				<Layout orientation='horizontal'>
					<RotatedColumnSeriesWidget
						className='col-lg-6'
						title='Travel expenses per project'
						id='travel_expenses_per_project'
						data={travelExpensesPerProjectMemo}
						fieldCategory='name'
						fieldValues={columnSeriesFieldsMemo}
						loading={loading}
						hideLegend
						showValueOnBar
					/>
					<RotatedColumnSeriesWidget
						className='col-lg-6'
						title='Expenses per project'
						id='expenses_per_project'
						data={expensesPerProjectMemo}
						fieldCategory='name'
						fieldValues={columnSeriesFieldsMemo}
						loading={loading}
						hideLegend
						showValueOnBar
					/>
				</Layout>
			</Dashboard>
		</ColumnContainer>
  	)
}
