import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { DeleteSurveyProposedAnswerRequest, InsertSurveyProposedAnswerRequest, SurveyProposedAnswerDeltaRequest, SurveyProposedAnswerResponse, SurveyQuestionResponse, SurveyResponse, UpdateSurveyProposedAnswerRequest, UpdateSurveyQuestionRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import WithFetch from 'features/Fetch/WithFetch';
import { createDelta, unpackDelta } from 'utils/commonHelper';
import { useMemo } from 'react';
import { CrudEnum, useGoBackFromUpdate } from 'features/Crud';
import QuestionModalForm from './QuestionModalForm';
import { getSurveyQuestionAction, updateSurveyQuestionAction } from '../actions';
import { publishSurveyQuestionsChanged } from '../surveyQuestionsChanged';
import { getSurveyAction } from '../../../action';

type ParamType = {
	projectId: string
	surveyId: string;
	questionId: string
}

const UpdateQuestion = () => {
	const [survey, setSurvey] = useState(new SurveyResponse());
	const [question, setQuestion] = useState(new SurveyQuestionResponse());
	const goBackFromUpdate = useGoBackFromUpdate();

	const params: ParamType = useParams();
	const projectId = useMemo(() => parseInt(params.projectId), [params.projectId])
	const surveyId = useMemo(() => parseInt(params.surveyId), [params.surveyId])
	const questionId = useMemo(() => parseInt(params.questionId), [params.questionId])

	const fetchQuestionCallback = useCallback(
		async () => {
			const bindedAction = getSurveyQuestionAction.bind(null, projectId, surveyId, questionId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setQuestion(response.value || new SurveyQuestionResponse());
			}
		},
		[projectId, surveyId, questionId]
	)

	const fetchSurveyCallback = useCallback(
		async () => {
			const bindedAction = getSurveyAction.bind(null, projectId, surveyId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setSurvey(response.value || new SurveyResponse());
			}
		},
		[projectId, surveyId]
	)

	const fetchDataCallback = useCallback(
		async () => {
			await Promise.all([fetchSurveyCallback(), fetchQuestionCallback()])
		},
		[fetchSurveyCallback, fetchQuestionCallback]
	)

	const onSubmitCallback = useCallback(
		async (values: SurveyQuestionResponse) => {
			const delta = createDelta<SurveyProposedAnswerResponse>
				(
					question.proposedAnswers || [],
					values.proposedAnswers || [],
					InsertSurveyProposedAnswerRequest,
					UpdateSurveyProposedAnswerRequest,
					SurveyProposedAnswerDeltaRequest,
					DeleteSurveyProposedAnswerRequest
				);

			const updateQuestion = new UpdateSurveyQuestionRequest({
				...values,
				proposedAnswersDelta: delta
			})
			const bindedAction = updateSurveyQuestionAction.bind(null, projectId, surveyId, updateQuestion);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`Question updated.`);
				goBackFromUpdate();
				publishSurveyQuestionsChanged()
			} else {
				const errors = convertResponseErrors(response)
				const [proposedAnswersErrors, newProposedAnswers] = unpackDelta(errors?.proposedAnswers || {}, delta, values.proposedAnswers || [], question.proposedAnswers || []);
				const newQuestion = new SurveyQuestionResponse(values);
				newQuestion.proposedAnswers = newProposedAnswers;
				setQuestion(newQuestion);
				return { ...errors, proposedAnswers: proposedAnswersErrors };
			}
		},
		[goBackFromUpdate, projectId, surveyId, question.proposedAnswers]
	);

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<QuestionModalForm
				survey={survey}
				crud={CrudEnum.Update}
				question={question}
				save={onSubmitCallback}
				cancel={goBackFromUpdate}
				open
			/>
		</WithFetch>
	);
};

export default UpdateQuestion;
