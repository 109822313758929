import { Document, Font, Page, StyleSheet, Image } from '@react-pdf/renderer';
import { TitleField } from 'components/Export';
import { PersistItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import { ChangeRequestFullResponse, ChangeRequestStatusResponse, IdNameResponse, ProjectResponse, ScopeItemLevelResponse, TenantResponseModel, UserModel } from 'services/tenantManagementService';
import { Realization } from '../Page/Realization';

type Props = {
	changeRequest: ChangeRequestFullResponse
	project: ProjectResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedChangeRequestStatus: PersistItemsReducer<ChangeRequestStatusResponse>
	persistedTenant: PersistValueReducer<TenantResponseModel>
	persistedCurrency: PersistItemsReducer<IdNameResponse>
	level3Items: ScopeItemLevelResponse[]
	base64Logo?: string
}

export const RealizationDocument = ({
	changeRequest,
	project,
	persistedUser,
	persistedChangeRequestStatus,
	persistedTenant,
	persistedCurrency,
	level3Items,
	base64Logo
}: Props) => {
	return (
		<Document>
			<Page wrap size="A4" style={styles.page}>
				{base64Logo && <Image style={{ marginLeft: 'auto', maxWidth:'80px', height: 'auto'}} src={base64Logo} /> }
				<TitleField title='Change Request Realization' />
				<Realization
					changeRequest={changeRequest}
					project={project}
					persistedUser={persistedUser}
					persistedChangeRequestStatus={persistedChangeRequestStatus}
					persistedTenant={persistedTenant}
					persistedCurrency={persistedCurrency}
					level3Items={level3Items}
				/>
			</Page>
		</Document>
	);
};

Font.register({
  family: 'Open Sans',
  fonts: [
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const styles = StyleSheet.create({
	page: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingHorizontal: 30,
		fontFamily: 'Open Sans'
	},
});
