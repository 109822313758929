import { useCallback, useState } from 'react';
import notifications from 'components/Notification/notification';
import { CreateComponentProps, CrudEnum, useGoBackFromUpdate } from 'features/Crud';
import { OrganizationalUnitManagerResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getManagerAuthorizationsAction, updateManagerAuthorizationsAction } from '../action';
import { ManagerAuthorizationForm } from './ManagerAuthorizationForm';
import { Dialog } from 'components/Dialog';
import { ManagerAuthorizationParamName } from '../ManagerAuthorizationsRouter';
import { useParams } from 'react-router-dom';
import WithFetch from 'features/Fetch/WithFetch';

type ParamType = {
	[ManagerAuthorizationParamName]?: string
}

export const UpdateManagerAuthorization = ({ publishDataChanged }: CreateComponentProps) => {
	const params: ParamType = useParams();
	const managerAuthorizationId = parseInt(params[ManagerAuthorizationParamName] as string);
	const goBackFromUpdate = useGoBackFromUpdate();

	const [managerAuthorization, setManagerAuthorization] = useState(new OrganizationalUnitManagerResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getManagerAuthorizationsAction.bind(null, managerAuthorizationId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setManagerAuthorization(response.value || new OrganizationalUnitManagerResponse());
			}
		},
		[managerAuthorizationId]
	)

	const handleSave = useCallback(
		async (newValue: OrganizationalUnitManagerResponse) => {
			const bindedAction = updateManagerAuthorizationsAction.bind(null, newValue);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`Manager authorization is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged]
	);

	return (
		<Dialog
			open
			title='Update manager authorization'
			onClose={goBackFromUpdate}
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<ManagerAuthorizationForm
					managerAuthorization={managerAuthorization}
					crud={CrudEnum.Update}
					onSave={handleSave}
				/>
			</WithFetch>
		</Dialog>
	)
}
