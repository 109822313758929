import { UserModel } from 'services/tenantManagementService';
import { SmartInline } from 'components/SmartContainer/SmartContainer';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { CheckboxField, SelectField } from 'components/Form';
import { RowContainer } from 'components/Layout';

type Props = {
	sendEmailId: string
	sendEmailLabel?: string
	toId: string
	persistedUser: PersistItemsReducer<UserModel>
};

const SendEmail = ({ sendEmailId, sendEmailLabel, toId, persistedUser }: Props) => {
	return (
		<RowContainer justifyContent='flex-end' >
			<SmartInline flex='unset' alignItems='baseline'>
				<label>{`${sendEmailLabel || 'Send email for approval'}:`}</label>
				<CheckboxField
					id={sendEmailId}
					// label={sendEmailLabel || 'Send email for approval'}
					inline
				/>
				<label>To:</label>
				{/* TODO: treba da bude AutoCompleteField, samo je problem sto nema prostora da se otvori na dole, mozda napraviti prop position: 'top' | 'bottom' */}
				<SelectField
					id={toId}
					// label='To'
					items={persistedUser.items}
					getItemId={(item: UserModel) => item.id}
					getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
					loading={persistedUser.fetching}
					inline
				/>
			</SmartInline>
		</RowContainer>
	)
}

export default SendEmail;
