import { RootState } from "base/reducer/reducer"
import { Form, MapMultiField, MultiSelectField } from "components/Form"
import { SmartContainer, SmartItem } from "components/SmartContainer/SmartContainer"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { BusinessPartnerResponseModel, FinanceDashboardFilterRequest, ModuleActivityEnum, MonthEnum, ProjectStatusResponse, TenantIsActiveResponse } from "services/tenantManagementService"
import Button from 'components/Button';
import { monthsOfYear } from 'utils/dateTimeUtils'
import { yearOptions } from "containers/TimeTravel/utils"
import { propertyOf } from "utils/propertyOf"
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers'
import { ProjectMultiSelectField, pmOrSpmOrOumPermission } from "features/Project"
import { useUserFullNameCallback } from "features/TableColumns/persistedHooks"
import { persistTeamMembersAction } from "containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action"

type Props = {
	filterFormModel?: FinanceDashboardFilterRequest
	onSubmit: (request: FinanceDashboardFilterRequest) => Promise<void>
	onSave: (filterFormModel: FinanceDashboardFilterRequest) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const {
		persistedUser,
		persistedFinanceCategory,
		persistedProjectStatus,
		persistedProjectType,
		persistedProjectCategory,
		persistedBusinessPartner,
		persistedOrganizationalUnit,
		persistedTeamMember
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(new FinanceDashboardFilterRequest(filterFormModel));

	const getUserFullName = useUserFullNameCallback(persistedUser);

	useEffect(
		() => {
			setValues(new FinanceDashboardFilterRequest(filterFormModel));
		},
		[filterFormModel]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new FinanceDashboardFilterRequest(values)
			await onSubmit(model)
		},
		[onSubmit, values]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	const pmOrSubstitutePmUserIdsMemo = useMemo(
		() => {
			const projectIds = values.projectIds || [];
			let userIds: number[] = [];

			for (const projectId of projectIds) {
				if (!persistedTeamMember.projectMap[projectId]) {
					persistTeamMembersAction(projectId);
				}

				const projectUserIds = persistedTeamMember.projectMap[projectId]?.items.filter(tm => tm.isPmOrSubstitutePm).map(tm => tm.userId) || [];
				userIds = [...userIds, ...projectUserIds];
			}

			return userIds = [...Array.from(new Set(userIds))];
		},
		[persistedTeamMember.projectMap, values.projectIds]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectMultiSelectField
							id={propertyOf<FinanceDashboardFilterRequest>('projectIds')}
							isRequired
							isProjectDashboard
							moduleEnum={ModuleActivityEnum.Finance}
							userRolePermission={pmOrSpmOrOumPermission}
						/>
						<MultiSelectField
							id={propertyOf<FinanceDashboardFilterRequest>('years')}
							label='Year'
							items={yearOptions}
							getItemId={(item: number) => item}
							getItemText={(item: number) => item.toString()}
						/>
						<MultiSelectField
							id={propertyOf<FinanceDashboardFilterRequest>('months')}
							label='Month'
							items={monthsOfYear}
							getItemId={(item: MonthEnum) => item}
							getItemText={(item: MonthEnum) => item}
						/>
						<MultiSelectField
							id={propertyOf<FinanceDashboardFilterRequest>('partnerIds')}
							label='Partner'
							items={persistedBusinessPartner.items}
							getItemId={(item: BusinessPartnerResponseModel) => item.id}
							getItemText={(item: BusinessPartnerResponseModel) => item.name}
							loading={persistedBusinessPartner.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FinanceDashboardFilterRequest>('pmUserIds')}
							label='Project Manager'
							items={pmOrSubstitutePmUserIdsMemo}
							getItemId={(item: number) => item}
							getItemText={(item: number) => getUserFullName(item)}
							loading={persistedTeamMember.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FinanceDashboardFilterRequest>('projectTypeIds')}
							label='Project Type'
							items={persistedProjectType.activeItems}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							loading={persistedProjectType.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FinanceDashboardFilterRequest>('projectCategoryIds')}
							label='Project Category'
							items={persistedProjectCategory.activeItems}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							loading={persistedProjectCategory.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FinanceDashboardFilterRequest>('projectStatusIds')}
							label='Project status'
							items={persistedProjectStatus.items}
							getItemId={(status: ProjectStatusResponse) => status.id}
							getItemText={(status: ProjectStatusResponse) => status.name}
							loading={persistedProjectStatus.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FinanceDashboardFilterRequest>('financeCategoryIds')}
							label='Budget category'
							items={persistedFinanceCategory.activeItems}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							loading={persistedFinanceCategory.fetching}
						/>
						<MapMultiField
							id={propertyOf<FinanceDashboardFilterRequest>('organizationalUnitIds')}
							label='Organizational Unit'
							items={convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined)}
							loading={persistedOrganizationalUnit.fetching}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
				/>
			)}
		/>
	)
}
