import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { OrganizationalUnitManagerResponse, UserModel } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { applyColor, useOrganizationalUnitCallback, useUsernameCallback } from 'features/TableColumns/persistedHooks';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';
import { formatDate } from 'utils/dateTimeUtils';
import { errorColor } from 'base/colors/colors';

const defaultColumns = [
	propertyOf<OrganizationalUnitManagerResponse>('userId'),
	'fullName',
	propertyOf<OrganizationalUnitManagerResponse>('organizationalUnitId'),
	propertyOf<OrganizationalUnitManagerResponse>('isResourceManager'),
	propertyOf<OrganizationalUnitManagerResponse>('validUntil')
]

export const useTableColumnsMemo = () => {
	const {
		persistedUser,
		persistedOrganizationalUnit
	} = useSelector((state: RootState) => state);

	const getUsername = useUsernameCallback(persistedUser);
	const getOrganizationalUnit = useOrganizationalUnitCallback();

	const organizationalUnits = useMemo(
		() => convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined),
		[persistedOrganizationalUnit]
	)

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<OrganizationalUnitManagerResponse>('userId')]: {
					title: 'Username',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
				fullName: {
					title: 'Full Name',
					formatter: (cell: any) => {
						const data: OrganizationalUnitManagerResponse = cell.getData();
						const user = persistedUser.itemsMap[data.userId];
						return user ? `${user.firstName} ${user.lastName}` : '';
					},
					fieldType: FieldTypeEnum.None,
					disableSort: true
				},
				[propertyOf<OrganizationalUnitManagerResponse>('organizationalUnitId')]: {
					title: 'Organizational Unit',
					formatter: (cell: any) => getOrganizationalUnit(cell.getValue(), organizationalUnits),
					fieldType: FieldTypeEnum.MapOption,
					options: organizationalUnits
				},
				[propertyOf<OrganizationalUnitManagerResponse>('isResourceManager')]: {
					title: 'Resource manager',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<OrganizationalUnitManagerResponse>('validUntil')]: {
					title: 'Valid Until',
					formatter: (cell: any) => {
						const value = cell.getValue();
						const element = cell.getElement();
						if (value < new Date()) {
							const color = errorColor;
							applyColor(element, color)
						}

						return formatDate(value);
					},
					fieldType: FieldTypeEnum.Date
				},
			}
		},
		[persistedUser, getUsername, getOrganizationalUnit, organizationalUnits]
	)

	return useVisibleColumns(columns, defaultColumns);
}
