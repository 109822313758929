import { useCallback, useState } from 'react';
import { HistoryLogTable, HistoryLogType } from "features/HistoryLog";
import { getHistoryAction } from '../../action';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useStatusCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { formatDate } from 'utils/dateTimeUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { TaskTabComponentProps } from '../taskTabs';

export const TaskHistoryLog = ({ projectOrCategoryId, isProjectConnected, taskId, scopeItemsLevel3 }: TaskTabComponentProps) => {
	const {
		persistedUser,
		persistedTaskStatus
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedTaskStatus);

	const [log, setLog] = useState<HistoryLogType[]>([]);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getHistoryAction.bind(null, taskId, projectOrCategoryId, isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				let newLog: HistoryLogType[] = [];

				const items = response.items || [];
				for (const item of items) {
					const logItem: HistoryLogType = {
						when: item.updatedOn,
						who: getUserFullName(item.userId),
						changes: []
					}

					if (item.assignedToUserId) {
						logItem.changes.push({
							title: 'Assigned to',
							old: getUserFullName(item.assignedToUserId.oldValue as any),
							new: getUserFullName(item.assignedToUserId.newValue as any)
						});
					}
					if (item.createdByUserId) {
						logItem.changes.push({
							title: 'Created by',
							old: getUserFullName(item.createdByUserId.oldValue as any),
							new: getUserFullName(item.createdByUserId.newValue as any)
						});
					}
					if (item.closedByUserId) {
						logItem.changes.push({
							title: 'Closed by',
							old: getUserFullName(item.closedByUserId.oldValue as any),
							new: getUserFullName(item.closedByUserId.newValue as any)
						});
					}
					if (item.due) {
						logItem.changes.push({
							title: 'Due date',
							old: formatDate(item.due.oldValue as any),
							new: formatDate(item.due.newValue as any)
						});
					}
					if (item.isPrivate.oldValue !== item.isPrivate.newValue) {
						logItem.changes.push({
							title: 'Private task',
							old: (item.isPrivate.oldValue) ? 'Private' : 'Not private',
							new: (item.isPrivate.newValue) ? 'Private' : 'Not private'
						});
					}
					if (item.statusId) {
						logItem.changes.push({
							title: 'Status',
							old: getStatus(item.statusId.oldValue as any),
							new: getStatus(item.statusId.newValue as any)
						});
					}
					if (item.parentTaskId) {
						logItem.changes.push({
							title: 'Related tasks',
							old: getFormatedId(EntityPrefixEnum.TASK, item.parentTaskId.oldValue as any),
							new: getFormatedId(EntityPrefixEnum.TASK, item.parentTaskId.newValue as any)
						});
					}
					if (item.riskId) {
						logItem.changes.push({
							title: 'Risk Id',
							old: getFormatedId(EntityPrefixEnum.RISK, item.riskId.oldValue as any),
							new: getFormatedId(EntityPrefixEnum.RISK, item.riskId.newValue as any)
						});
					}
					if (item.scopeItemLevelId) {
						logItem.changes.push({
							title: 'Process Id',
							old: scopeItemsLevel3.find(scopeItem => scopeItem.id === item.scopeItemLevelId?.oldValue as any)?.fullname,
							new: scopeItemsLevel3.find(scopeItem => scopeItem.id === item.scopeItemLevelId?.newValue as any)?.fullname
						});
					}
					if (item.changeRequestId) {
						logItem.changes.push({
							title: 'Change request Id',
							old: getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, item.changeRequestId.oldValue as any),
							new: getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, item.changeRequestId.newValue as any)
						});
					}
					if (item.ticketId) {
						logItem.changes.push({
							title: 'Ticket Id',
							old: getFormatedId(EntityPrefixEnum.TICKET, item.ticketId.oldValue as any),
							new: getFormatedId(EntityPrefixEnum.TICKET, item.ticketId.newValue as any)
						});
					}

					if (logItem.changes.length > 0) {
						newLog.push(logItem);
					}
				}

				setLog(newLog.reverse());
			}
		},
		[getUserFullName, projectOrCategoryId, isProjectConnected, taskId, getStatus, scopeItemsLevel3]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<HistoryLogTable
				log={log}
			/>
		</WithFetch>
	)
}
