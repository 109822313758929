import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormattedCurrencyValueCallback, useFormattedProjectIdNameCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { CostExpenseTypeResponse, TimesheetResponse, TravelExpenseResponse, UserModel } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { RootState } from 'base/reducer/reducer';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';

export const useTableColumnsMemo = ( configureViewKey: string, showAll: boolean) => {
	const {
		persistedTravelCostTypes,
		persistedUser,
		persistedTimeTravelNonProjectCategories,
		persistedProject,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	let defaultVisibleColumns = [
		propertyOf<TravelExpenseResponse>('userId'),
		propertyOf<TravelExpenseResponse>('projectOrCategoryId'),

		...Object.keys(persistedTravelCostTypes.itemsMap),
		propertyOf<TravelExpenseResponse>('amount'),
	];

	if (showAll) {
		defaultVisibleColumns = [
			...defaultVisibleColumns,
			propertyOf<TravelExpenseResponse>('leaveDepartureDate'),
			propertyOf<TravelExpenseResponse>('returnArrivalDate')
		]
	}

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getFormattedCurrencyValue = useFormattedCurrencyValueCallback();
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);


	const columns: TableColumnsType = useMemo(
		() => {
			let commonColumns: TableColumnsType = {
				[propertyOf<TravelExpenseResponse>('userId')]: {
					title: 'User',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				[propertyOf<TimesheetResponse>('projectOrCategoryId')]: {
					title: 'Project or Category',
					fieldType: FieldTypeEnum.None,
					disableFilter: true,
					disableSort: true,
					formatter: (cell: any) : string => {
						const data: TimesheetResponse = cell.getData();
						if (data.isProjectConnected) {
							return formatProjectIdName(data.projectOrCategoryId)
						} else {
							return persistedTimeTravelNonProjectCategories.itemsMap[data.projectOrCategoryId]?.name || ''
						}
					}
				},

				[propertyOf<TravelExpenseResponse>('amount')]: {
					title: 'Total cost',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: 'sum'
				}
			};

			if (showAll) {
				commonColumns = {
					...commonColumns,
					[propertyOf<TravelExpenseResponse>('leaveDepartureDate')]: {
						title: 'Date from',
						fieldType: FieldTypeEnum.Date
					},
					[propertyOf<TravelExpenseResponse>('returnArrivalDate')]: {
						title: 'Date to',
						fieldType: FieldTypeEnum.Date
					},
				}
			}

			// create columns from travel cost types
			const costColumns = persistedTravelCostTypes.items.reduce((accumulator: TableColumnsType, currentCostType: CostExpenseTypeResponse) => {
				const newValue = {
					title: currentCostType.name,
					fieldType: FieldTypeEnum.Currency,
					disableSort: true,
					disableFilter: true,
					formatter: (cell: any) => {
						const data: TravelExpenseResponse = cell.getData();
						const values = data.travelExpenseItems?.filter(tei => tei.costTypeId === currentCostType.id) || [];
						const sum = values.reduce((accSum, a) => accSum + a.amountProjectCurrency, 0);
						return getFormattedCurrencyValue(sum);
					},
					// sorter: (emptyAValue: any, emptyBValue: any, aRow: any, bRow: any) => {
					// 	const a: TravelExpenseResponse = aRow.getData();
					// 	const aValues = a.travelExpenseItems?.filter(tei => tei.costTypeId === currentCostType.id) || [];
					// 	const aSum = aValues.reduce((accSum, item) => accSum + item.amountProjectCurrency, 0);

					// 	const b: TravelExpenseResponse = bRow.getData();
					// 	const bValues = b.travelExpenseItems?.filter(tei => tei.costTypeId === currentCostType.id) || [];
					// 	const bSum = bValues.reduce((accSum, item) => accSum + item.amountProjectCurrency, 0)

					// 	return aSum - bSum;
					// },
					bottomCalc: (values: any, data: TravelExpenseResponse[]) => {
						return data.reduce((totalSum, te) => {
							const values = te.travelExpenseItems?.filter(tei => tei.costTypeId === currentCostType.id) || [];
							const sum = values.reduce((accSum, a) => accSum + a.amountProjectCurrency, 0)
							return totalSum + sum
						}, 0)
					}
				};

				return {
					...accumulator,
					[currentCostType.id]: newValue
				}
			}, {})

			return {...commonColumns, ...costColumns};
		},
		[getUserFullName, getFormattedCurrencyValue, formatProjectIdName, persistedTimeTravelNonProjectCategories, persistedTravelCostTypes, persistedUser, showAll]
	)

	return useVisibleColumns(columns, visibleColumns);
}
