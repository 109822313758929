import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer, RowContainer } from 'components/Layout';
import Button from 'components/Button';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { ChangeRequestApprovalRequest, ChangeRequestApproveEnum, ChangeRequestStatusEnum } from 'services/tenantManagementService';
import ActionModal from './ActionModal';
import ActionForm from './ActionForm';
import { updateChangeRequestApprovalAction } from 'containers/Scope/ChangeRequests/action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { TabProps } from '../../CreateChangeRequest';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { getUserInfo } from 'utils/storageUtils';
import { ExportApproval } from 'containers/Scope/ChangeRequests/Export/ExportApproval';

type Props = TabProps;

const Approval = ({ projectId, changeRequest, crud, publishDataChanged }: Props) => {
	const { persistedChangeRequestStatus, persistedProject } = useSelector((state: RootState) => state);
	const goBackFromCrud = useGoBackFromCrud(crud);
	const [approveEnum, setApproveEnum] = useState(ChangeRequestApproveEnum.None);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const closeModalCallback = useCallback(
		() => {
			setApproveEnum(ChangeRequestApproveEnum.None);
			setIsModalOpen(false);
		},
		[]
	)
	const additionalInfoCallback = useCallback(
		async () => {
			setLoading(true);

			const model = new ChangeRequestApprovalRequest({
				id: changeRequest.id,
				sendEmail: false,
				approve: ChangeRequestApproveEnum.AdditionalInfoNeeded
			});
			const bindedAction = updateChangeRequestApprovalAction.bind(null, projectId, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, response.value?.id);
				notifications.success(`Change request ${id} approval is updated.`);
				publishDataChanged();
				goBackFromCrud();
			}

			setLoading(false);
		},
		[changeRequest.id, goBackFromCrud, projectId, publishDataChanged]
	)

	const approveCallback = useCallback(
		() => {
			setApproveEnum(ChangeRequestApproveEnum.Approve);
			setIsModalOpen(true);
		},
		[]
	)

	const rejectCallback = useCallback(
		() => {
			setApproveEnum(ChangeRequestApproveEnum.Reject);
			setIsModalOpen(true);
		},
		[]
	)

	const disabledMemo = useMemo(
		() => crud !== CrudEnum.Update
		|| !isStatusBySemantic(ChangeRequestStatusEnum.ReleasedForApproval, changeRequest.statusId, persistedChangeRequestStatus.itemsMap)
		|| (!(isUserPmorSubstitutePmOrSiteAdmin(persistedProject.itemsMap[projectId]?.roleId) || changeRequest.approvalUserId === getUserInfo().id)),
		[crud, persistedChangeRequestStatus.itemsMap, changeRequest.statusId, changeRequest.approvalUserId, projectId, persistedProject.itemsMap]
	)

	const isAfterApprovalContent = useMemo(
		() => {
			let forcedApproveEnum: ChangeRequestApproveEnum | undefined = undefined;
			if (isStatusBySemantic(ChangeRequestStatusEnum.RejectedForRealization, changeRequest.statusId, persistedChangeRequestStatus.itemsMap)) {
				forcedApproveEnum = ChangeRequestApproveEnum.Reject;
			} else if (
				isStatusBySemantic(ChangeRequestStatusEnum.ApprovedForRealization, changeRequest.statusId, persistedChangeRequestStatus.itemsMap)
				|| isStatusBySemantic(ChangeRequestStatusEnum.ReleasedForAcceptance, changeRequest.statusId, persistedChangeRequestStatus.itemsMap)
				|| isStatusBySemantic(ChangeRequestStatusEnum.Completed, changeRequest.statusId, persistedChangeRequestStatus.itemsMap)
			) {
				forcedApproveEnum = ChangeRequestApproveEnum.Approve;
			}

			if (forcedApproveEnum) {
				return (
					<ActionForm
						projectId={projectId}
						changeRequest={changeRequest}
						approveEnum={forcedApproveEnum}
						isOpen={isModalOpen}
						close={closeModalCallback}
						crud={crud}
					/>
				)
			}
		},
		[changeRequest, crud, closeModalCallback, isModalOpen, projectId, persistedChangeRequestStatus]
	)

	return (
		<ColumnContainer>
			{isAfterApprovalContent}
			<RowContainer justifyContent='flex-end'>
				<Button
					text='Approve'
					disabled={disabledMemo || loading}
					onClick={approveCallback}
				/>
				<Button
					text='Additional info needed'
					disabled={disabledMemo || loading}
					onClick={additionalInfoCallback}
					isLoading={loading}
				/>
				<Button
					text='Reject'
					disabled={disabledMemo || loading}
					onClick={rejectCallback}
				/>
				<ExportApproval
					changeRequest={changeRequest}
					projectId={projectId}
				/>
				<Button
					text='Cancel'
					color='neutral'
					disabled={disabledMemo || loading}
					onClick={goBackFromCrud}
				/>
				<ActionModal
					projectId={projectId}
					changeRequest={changeRequest}
					approveEnum={approveEnum}
					isOpen={isModalOpen}
					close={closeModalCallback}
					crud={crud}
				/>
			</RowContainer>
		</ColumnContainer>
	)
}

export default Approval;
