import { useCallback } from "react"
import { ModuleActivityEnum, TicketPermission, TicketResponse } from "services/tenantManagementService"
import { propertyOf } from "utils/propertyOf"
import { ProjectSelectField, pmOrSpmPermission } from "features/Project"

type Props = {
	initialValues: TicketResponse
}

export const Project = ({ initialValues }: Props) => {
	const onProjectChangeCallback = useCallback(
		(_newProjectId: number | undefined, _oldValue: TicketResponse) => ({
			[propertyOf<TicketResponse>('changeRequestIds')]: undefined,
			[propertyOf<TicketResponse>('scheduleId')]: undefined,
		}),
		[]
	)

	return (
		<ProjectSelectField
			id={propertyOf<TicketResponse>('projectId')}
			isRequired
			updateDependants={onProjectChangeCallback}
			initialProjectId={initialValues.projectId}
			moduleEnum={ModuleActivityEnum.Ticket}
			teamMemberPermission={propertyOf<TicketPermission>('tickets')}
			userRolePermission={pmOrSpmPermission}
		/>
	)
}
