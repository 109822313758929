import { getTrainingStatusesAction, updateTrainingStatusesAction } from './action';
import { TrainingStatusesHelp } from './TrainingStatusesHelp';
import { StatusResponseTable } from 'features/StatusResponse/StatusResponseTable';
import { ContentShell } from 'features/Content/ContentShell';

export const TrainingStatus = () => {
	return (
		<ContentShell
			InlineHelpComponent={TrainingStatusesHelp}
			showBreadcrumb={false}
		>
			<StatusResponseTable
				getConfigsAction={getTrainingStatusesAction}
				updateConfigsAction={updateTrainingStatusesAction}
			/>
		</ContentShell>
	)
}
