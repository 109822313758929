import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import { ProjectAccomplishmentResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createAccomplishmentAction } from '../action';
import { Dialog } from 'components/Dialog';
import { MajorAccomplishmentForm } from '../MajorAccomplishmentsForm';
import { publishMajorAccomplishmentsChanged } from '../majorAccomplishmentsChanged';
import { ReportComponentParams } from '../../../Table/TableButtons';
import { useGoBackFromCreate } from 'features/Crud';
import { useParams } from 'react-router-dom';

export const CreateMajorAccomplishment = () => {
	const params: ReportComponentParams = useParams();
	const reportId = parseInt(params.reportId);

	const goBackFromCreate = useGoBackFromCreate(true);

	const onSubmitCallback = useCallback(
		async (majorAccomplishment: ProjectAccomplishmentResponse) => {
			const bindedAction = createAccomplishmentAction.bind(null, reportId, majorAccomplishment);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Major accomplishment successfully created');
				goBackFromCreate();
				publishMajorAccomplishmentsChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, reportId]
	);

	return(
		<Dialog
			title='Create Major Accomplishment'
			onClose={goBackFromCreate}
			open
		>
			<MajorAccomplishmentForm save={onSubmitCallback} cancel={goBackFromCreate} />
		</Dialog>
	)
};
