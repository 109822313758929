import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import { ColumnContainer } from 'components/Layout';
import { CrudEnum, CrudSubRoutesEnum, useGoBackFromCrudAndPush } from 'features/Crud';
import { TasksForm } from './TaskForm';
import WithFetch from 'features/Fetch/WithFetch';
import { ProjectStatusEnum, TaskResponse, TaskStatusEnum } from 'services/tenantManagementService';
import { getTaskAction } from '../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ContentShell } from 'features/Content/ContentShell';
import { Follow } from './Follow/Follow';
import { ReadComponentProps } from 'features/Crud/CrudRouter';

type ParamType = {
	taskId: string
}

export const ReadTask = ({ publishDataChanged }: ReadComponentProps) => {
	const params: ParamType = useParams();
	const taskId = parseInt(params.taskId as string);

	const [task, setTask] = useState(new TaskResponse());

	const { persistedTaskStatus, persistedProject, persistedProjectStatus } = useSelector((state: RootState) => state);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getTaskAction.bind(null, taskId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setTask(response.value || new TaskResponse());
			}
		},
		[taskId]
	)

	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read, undefined, true);

	const goUpdateCallback = useCallback(
		() => {
			goBackFromCrudAndPush(`${CrudSubRoutesEnum.Update}/${taskId}`);
		},
		[taskId, goBackFromCrudAndPush]
	)

	const showChangeMemo = useMemo(
		() => {
			const selectedProjectStatusId = task.isProjectConnected === true ? persistedProject.itemsMap[task.projectOrCategoryId]?.statusId : undefined;

			return isStatusBySemantic(ProjectStatusEnum.Released, selectedProjectStatusId, persistedProjectStatus.itemsMap) &&
				!isStatusBySemantic(TaskStatusEnum.Completed, task.statusId, persistedTaskStatus.itemsMap);
		},
		[persistedTaskStatus.itemsMap, task, persistedProjectStatus.itemsMap, persistedProject.itemsMap]
	)

	const renderFollowCallback = useCallback(
		() => <Follow task={task} publishDataChanged={publishDataChanged} />,
		[task, publishDataChanged]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell
				title={`View task - ${getFormatedId(EntityPrefixEnum.TASK, task.id)}`}
				renderAdditionalHeaderContent={renderFollowCallback}
			>
				<ColumnContainer margin='medium'>
					{showChangeMemo && <Button text='Change' onClick={goUpdateCallback} />}
					<TasksForm
						task={task}
						crud={CrudEnum.Read}
					/>
				</ColumnContainer>
			</ContentShell>
		</WithFetch>
	)
}
