import { useCallback, useState } from 'react';
import { ColumnContainer } from 'components/Layout';
import {  getShowUserStatusConfigAction, updateShowUserStatusConfigAction } from './action';
import { ContentShell } from 'features/Content/ContentShell';
import { ListComponentProps } from 'features/Crud';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import WithFetch from 'features/Fetch/WithFetch';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Radio } from 'components/Form';
import { UserListHelp } from './UserListHelp';
import { Users } from '../Users/Users';
import { UserStatusPerProject } from '../UserStatusPerProject/UserStatusPerProject';

export const UserList = (props: ListComponentProps) => {
	const [showUserStatus, setShowUserStatus] = useState<boolean | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const fetchShowProcessStepsCallback = useCallback(
		async () => {
			const showUserStatusResponse = await tryCatchJsonByAction(getShowUserStatusConfigAction);
			setIsLoading(false);
			if (showUserStatusResponse.success && showUserStatusResponse.value) {
				setShowUserStatus(showUserStatusResponse.value.content);
			} else {
				//default value
				setShowUserStatus(false);
			}
		},
		[]
	)

	const showUserStatusChangedCallback = useCallback(
		async (newValue: boolean) => {
			setIsLoading(true);

			// update show user status configuration
			setShowUserStatus(newValue);
			const bindedAction = updateShowUserStatusConfigAction.bind(null, newValue);

			await tryCatchJsonByAction(bindedAction);
			setIsLoading(false);
		},
		[]
	)

	return (
		<ContentShell
			title='User List'
			FloatingHelpComponent={UserListHelp}
		>
			<WithFetch fetchFunction={fetchShowProcessStepsCallback}>
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<SmartFormGroup>
								<Radio
									value={showUserStatus}
									disabled={isLoading}
									items={[
										{ id: false, text: 'User list' },
										{ id: true, text: 'User status per project' },
									]}
									onChange={showUserStatusChangedCallback}
								/>
							</SmartFormGroup>
						</SmartItem>
					</SmartContainer>
					{showUserStatus ? (
						<UserStatusPerProject />
					) : (
						<Users {...props} />
					)}
				</ColumnContainer>
			</WithFetch>
		</ContentShell>
	)
}
