import { useMemo } from 'react'
import { TestDashboardResponse } from 'services/tenantManagementService'
import { useSelector } from 'react-redux'
import { RootState } from 'base/reducer/reducer'
import { Layout } from 'features/Dashboard/Layout'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget'
import { TextWidget } from 'features/Dashboard/Widget/TextWidget'
import { Dashboard } from 'features/Dashboard/Dashboard';
import { FilterModel } from './FilterModel'
import { Widget } from 'features/Dashboard/Widget/Widget'
import { TestStatusTable } from './Table/TestStatusTable'
import styles from './testingDashboard.module.scss'

type Props = {
	loading: boolean
	dashboard: TestDashboardResponse
	filterModel: FilterModel
}

const FilteredDashboard = ({ loading, dashboard, filterModel }: Props) => {
	const persistedTestCaseStatus = useSelector((state: RootState) => state.persistedTestCaseStatus);

	const pieChartDataMemo = useMemo(
		() => {
			const data = persistedTestCaseStatus.items.map(testStatus => {
				return {
					id: testStatus.id.toString(),
					name: testStatus.name,
					count: 0,
					color: testStatus.color
				}
			});

			dashboard.statuses?.forEach(status => {
				if (!status.statusCounts) {
					return;
				}

				Object.keys(status.statusCounts).forEach(statusCountKey => {
					const current = data.find(testStatus => testStatus.id === statusCountKey);
					if (!current) {
						return;
					}
					current.count += status.statusCounts![statusCountKey] || 0;
				})
			});
			return data;
		},
		[dashboard, persistedTestCaseStatus.items]
	)

	const columnSeriesDataMemo = useMemo(
		() => dashboard.statuses?.map(status => {
			return {
				level1Id: status.level1Name,
				...status.statusCounts
			}
		}) || [],
		[dashboard]
	)

	const columnSeriesFieldsMemo = useMemo(
		() => persistedTestCaseStatus.items.map(testStatus => {
			return {
				value: testStatus.id.toString(),
				name: testStatus.name,
				color: testStatus.color
			}

		}),
		[persistedTestCaseStatus.items]
	)

	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='horizontal'>
				<Layout
					orientation='vertical'
					className='col-xl-4 col-md-4 col-sm-8'
				>
					<Layout orientation='horizontal'>
						<Layout
							orientation='vertical'
							className='col-6'
						>
							<TextWidget
								title='Total Number of Test Cases'
								value={dashboard.testCasesCount}
								loading={loading}
							/>
							<TextWidget
								title='Total Number of Tickets Assigned to Test Cycle'
								value={dashboard.ticketsCount}
								loading={loading}
							/>
						</Layout>
						<Layout
							orientation='vertical'
							className='col-6'
						>
							<TextWidget
								title='Total Number of Step / Process Testers'
								value={`${dashboard.stepTestersCount === undefined ? '-' : dashboard.stepTestersCount} / ${dashboard.processTestersCount === undefined ? '-' : dashboard.processTestersCount}`}
								loading={loading}
							/>
							<TextWidget
								title='Planned vs Actual to Date'
								value={`${dashboard.plannedDateCount === undefined ? '-' : dashboard.plannedDateCount} / ${dashboard.actualDateCount === undefined ? '-' : dashboard.actualDateCount}`}
								loading={loading}
							/>
						</Layout>
					</Layout>
					<Layout orientation='horizontal'>
						<PieWidget
							className='col-12'
							title='Test Status'
							id='test_pie_chart'
							fieldValue='count'
							fieldCategory='name'
							data={pieChartDataMemo}
							loading={loading}
							usePercentAndValueForLegend
						/>
					</Layout>
				</Layout>
				<RotatedColumnSeriesWidget
					className='col-xl-8 col-md-8 col-sm-12'
					title='Test Status per Level 1 grouping'
					id='test_column_series_chart'
					contentClassName={styles.testStatusPerLevel1GroupingContent}
					data={columnSeriesDataMemo}
					fieldCategory='level1Id'
					categoryTitle='Level 1 ID'
					stacked
					fieldValues={columnSeriesFieldsMemo}
					loading={loading}
					showValueOnBar
				/>
			</Layout>
			<Widget title='Detail Test Report' loading={loading}>
				<TestStatusTable
					projectId={filterModel.projectId}
					testCycleId={filterModel.testCycleId}
				/>
			</Widget>
		</Dashboard>
	)
}

export default FilteredDashboard
