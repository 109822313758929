import { getTenantId } from 'features/Tenant/tenantUtils';
import { ExportDataModel, ProjectTeamMemberClient } from 'services/tenantManagementService';

export const getAllTeamMemberUsersAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamMemberClient();
	return client.getAllTeamMemberUsers(tenantId, projectId);
}

export const exportProjectTeamMembersByProjectAction = (projectId: number, model: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamMemberClient();
	return client.export(tenantId, projectId, model);
}
