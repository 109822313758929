import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import ExpenseForm from './ExpenseForm';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { ExpenseResponse, InsertExpenseItemRequest, InsertExpenseRequest } from 'services/tenantManagementService';
import { createExpenseAction } from '../action';
import { ContentShell } from 'features/Content/ContentShell';

const CreateMyExpense = ({ publishDataChanged }: CreateComponentProps) => {
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newExpense: ExpenseResponse, isRelease: boolean) => {
			const model = new InsertExpenseRequest({
				...newExpense,
				isRelease,
				newExpenseItems: newExpense.expenseItems?.map(ei => new InsertExpenseItemRequest({
					...ei,
					newAttachments: (ei as InsertExpenseItemRequest).newAttachments
				}))
			});
			const bindedAction = createExpenseAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, response.value?.id);
				notifications.success(`New expense ${id} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	);

	return (
		<ContentShell title='Create expense'>
			<ExpenseForm
				crud={CrudEnum.Create}
				onSave={handleSave}
			/>
		</ContentShell>
	)
}

export default CreateMyExpense;
