import { LineSeriesChart, LineSeriesChartProps } from "features/Charts"
import { Widget, WidgetProps } from "./Widget"

type Props = WidgetProps & LineSeriesChartProps;

export const LineSeriesWidget = ({ ...props }: Props) => {
	return (
		<Widget
			{...props}
		>
			<LineSeriesChart {...props} />
		</Widget>
	)
}
