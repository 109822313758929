import { Dialog } from 'components/Dialog';
import { CrudEnum, useGoBackFromRead } from 'features/Crud';
import { TravelExpenseItemResponse } from 'services/tenantManagementService';
import TravelExpenseItemForm from './TravelExpenseItemForm';

type Props = {
	travelExpenseItem: TravelExpenseItemResponse
}

const ReadTravelExpenseItem = ({ travelExpenseItem }: Props) => {

	const goBackFromRead = useGoBackFromRead(true);

	return (
		<Dialog
			title='Read expense'
			open
			onClose={goBackFromRead}
			size='large'
		>
			<TravelExpenseItemForm
				travelExpenseItem={travelExpenseItem}
				crud={CrudEnum.Read}
			/>
		</Dialog>
	)
}

export default ReadTravelExpenseItem;
