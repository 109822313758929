import { useEffect, useState } from 'react';
import { IdNameResponse, IdNameResponseItemsResponseModel } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import FinishRegistrationForm from './FinishRegistrationForm';
import { getCountries, getCurrencies, getIndustries, getTeamSizes } from './action';
import { Title, Subtitle } from '../_components';

class FetchedData {
	industries: IdNameResponse[] = [];
	countries: IdNameResponse[] = [];
	currencies: IdNameResponse[] = [];
	teamSizes: IdNameResponse[] = [];
}

const FinishRegistration = () => {
	const [fetchedData, setFetchedData] = useState<FetchedData>(new FetchedData());

	useEffect(
		() => {
			const fetchData = async () => {
				const [
					industriesResponse, countriesResponse, currenciesResponse, teamSizesResponse
				] = await Promise.all<IdNameResponseItemsResponseModel>(
					[
						tryCatchJsonByAction(getIndustries),
						tryCatchJsonByAction(getCountries),
						tryCatchJsonByAction(getCurrencies),
						tryCatchJsonByAction(getTeamSizes)
					]
				);

				setFetchedData({
					industries: industriesResponse.items || [],
					countries: countriesResponse.items || [],
					currencies: currenciesResponse.items || [],
					teamSizes: teamSizesResponse.items || []
				})
			}
			fetchData();
		},
		[]
	);

	return (
		<>
			<Title text='Finish Registration' />
			<Subtitle text='Welcome to Mavles Project Management tool. Please finish your registration.' />
			<FinishRegistrationForm {...fetchedData} />
		</>
	);
}

export default FinishRegistration;
