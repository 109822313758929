import { getCssVariableValue } from 'utils/cssVariablesUtils';
import { getLinks, linksCellEditorValidator, oneLinkHeight, setCellHeight, linksCellEditorElementsIdPrefix } from './utils';

const color = getCssVariableValue('--field-color');

const createContainer = () => {
	const container = document.createElement("div");
	container.id = `${linksCellEditorElementsIdPrefix}_container`;
	container.setAttribute('style', 'width: 100%; height: 100%; display: flex; flex-direction: column;');
	return container;
}

const createInput = (id, value) => {
	const input = document.createElement("input");

	input.type = 'text';
	input.title = '';
	input.value = value;
	input.id = id;
	input.setAttribute('style', `width: 100%; height: ${oneLinkHeight}px; padding: 4px; color: ${color};`);

	return input;
}

const setInputListeners = (input, newListenersMap) => {
	Object.keys(newListenersMap).forEach(listenerType => {
		input.addEventListener(listenerType, newListenersMap[listenerType], false);
	});
}

const shouldSave = (event) => {
	if(!event?.relatedTarget) {
		return true;
	}

	const relatedTarget = event.relatedTarget;
	return !relatedTarget.id || !relatedTarget.id.startsWith(linksCellEditorElementsIdPrefix);
}

export const createLinksCellEditor = (cell, onRendered, success, cancel, editorParams) => {
	const container = createContainer();

	let existingLinkInputs = getLinks(cell.getValue()).map((link, ind) => {
		const input = createInput(`${linksCellEditorElementsIdPrefix}_link_${ind}`, link);
		return input;
	});

	let existingLinkListenersMap = {};
	let newLinkListenersMap = {};

	let newLinkInput = createInput(`${linksCellEditorElementsIdPrefix}_link_new`, '');

	existingLinkInputs.forEach(input => {
		container.appendChild(input);
	});

	container.appendChild(newLinkInput);

	onRendered(function() {
		setCellHeight(cell, existingLinkInputs.length + 1);
		setTimeout(() => {
			const inputs = [...existingLinkInputs, newLinkInput]
			if (!inputs.some(x => x === document.activeElement)) {
				newLinkInput.focus({ preventScroll: true });
			}
		}, 200);
	});

	const save = () => {
		const newLinksValue = [...existingLinkInputs, newLinkInput]
			.map(input => input.value)
			.filter(x => !!x)
			.join(';');

		setCellHeight(cell, Math.max(existingLinkInputs.filter(x => !!x.value).length, 1));
		success(newLinksValue);
	};

	const validate = () => {
		return linksCellEditorValidator([...existingLinkInputs, newLinkInput]);
	};

	const onBlur = (event) => {
		if(shouldSave(event)) {
			save();
		}
	};

	const onChange = (event) => {
		save();
	};

	const onKeydown = (event) => {
		switch(event.key){
			case 'Enter': {
				const validationFailed = !validate();
				if (validationFailed) {
					event.preventDefault();
				}
				else {
					save();
				}

				break;
			}
			case 'Escape':
				cancel();
			break;

			case 'Home':
			case 'End':
				event.stopPropagation();
			break;
			default:
			break;
		}
	};

	const onMouseDown = (event) => {
		if (!event.target || event.target !== document.activeElement) {
			const validationFailed = !validate();
			if (validationFailed) {
				event.preventDefault();
			}
		}
	};

	const onInput = (event) => {
		if (event.target) {
			event.target.style.borderColor = '1px solid #000';
			event.target.style.color = `${color}`;
			event.target.title = '';
		}
	};

	existingLinkListenersMap = {
		'change': onChange,
		'blur': onBlur,
		'keydown': onKeydown,
		'mousedown': onMouseDown,
		'input': onInput,
	};

	newLinkListenersMap = {
		'change': onChange,
		'blur': onBlur,
		'keydown': onKeydown,
		'mousedown': onMouseDown,
		'input': onInput,
	};

	existingLinkInputs.forEach(input => {
		setInputListeners(input, existingLinkListenersMap);
	});

	setInputListeners(newLinkInput, newLinkListenersMap);

	return container;
}
