import { HelpShell } from 'features/Help/HelpShell';

export const PlanningIntervalsHelp = () => {
	return (
		<HelpShell title='Planning intervals'>
			<p>
				For all your project you can define planning intervals based on your company needs (weekly, monthly, quarterly, yearly). <br />
				By default monthly planning intervals are defined.
			</p>
		</HelpShell>
	)
}
