import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud';
import { CreateMilestonePlan } from './Crud/CreateMilestonePlan';
import { UpdateMilestonePlan } from './Crud/UpdateMilestonePlan';

export const MilestonePlanRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/${CrudSubRoutesEnum.Create}`} component={CreateMilestonePlan} />
			<Route path={`${path}/${CrudSubRoutesEnum.Update}/:milestonePlanId`} component={UpdateMilestonePlan} />
		</Switch>
	)
}
