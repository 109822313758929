import { RootState } from 'base/reducer/reducer';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SurveyAnswerResponse, SurveyQuestionTypeEnum, UserModel } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';

const questionTypes = [
	SurveyQuestionTypeEnum.Rating,
    SurveyQuestionTypeEnum.MultipleChoice,
    SurveyQuestionTypeEnum.SingleChoice,
    SurveyQuestionTypeEnum.Answer
]

const defaultVisibleColumns = [
	propertyOf<SurveyAnswerResponse>('userId'),
	propertyOf<SurveyAnswerResponse>('questionGroup'),
	propertyOf<SurveyAnswerResponse>('questionType'),
	propertyOf<SurveyAnswerResponse>('question'),
	propertyOf<SurveyAnswerResponse>('answer'),
	propertyOf<SurveyAnswerResponse>('score')
]

export const useTableColumnsMemo = (configureViewKey: string) => {
	const {
		persistedUser,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<SurveyAnswerResponse>('userId')]: {
					title: 'User',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				[propertyOf<SurveyAnswerResponse>('questionGroup')]: {
					title: 'Question Gruop',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<SurveyAnswerResponse>('questionType')]: {
					title: 'Question Type',
					fieldType: FieldTypeEnum.Options,
					options: questionTypes,
					getItemId: (option: SurveyQuestionTypeEnum) => option.valueOf(),
					getItemText: (option: SurveyQuestionTypeEnum) => option
				},
				[propertyOf<SurveyAnswerResponse>('question')]: {
					title: 'Question',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<SurveyAnswerResponse>('answer')]: {
					title: 'Answer',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<SurveyAnswerResponse>('score')]: {
					title: 'Score',
					fieldType: FieldTypeEnum.Number
				}
			}
		},
		[getUserFullName, persistedUser]
	)

	return useVisibleColumns(columns, visibleColumns);
}
