import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { RatingPerQuestionDashboardResponse, SurveyDashboardResponse, SurveyTypeEnum } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';

const defaultColumns = [
	propertyOf<RatingPerQuestionDashboardResponse>('questionGroup'),
	propertyOf<RatingPerQuestionDashboardResponse>('question'),
	propertyOf<RatingPerQuestionDashboardResponse>('averageRating'),
	propertyOf<RatingPerQuestionDashboardResponse>('ratingSum')
]


export const useTableColumnsRatingsMemo = (
	dashboard: SurveyDashboardResponse,
) => {
	const isQuiz = dashboard.surveyType === SurveyTypeEnum.Quiz;

	const columns: TableColumnsType = useMemo(
		() => {
			let result: TableColumnsType = {
				[propertyOf<RatingPerQuestionDashboardResponse>('questionGroup')]: {
					title: '',
					fieldType: FieldTypeEnum.String,
					bottomCalcFormatter: () => 'Total',
				},
				[propertyOf<RatingPerQuestionDashboardResponse>('question')]: {
					title: 'Question',
					fieldType: FieldTypeEnum.String,
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<RatingPerQuestionDashboardResponse>('averageRating')]: {
					title: isQuiz ? 'Average score' : 'Average rating',
					formatter: (cell: any) => {
						const averageRating = cell.getValue();
						return isQuiz ? `${(averageRating * 100).toFixed(2)}%` : `${averageRating}`
					}, 
					fieldType: FieldTypeEnum.Number,
					disableSort: true,
					disableFilter: true,
				}				
			}

			if(isQuiz) {
				result = {
					...result,
					[propertyOf<RatingPerQuestionDashboardResponse>('ratingSum')]: {
						title: 'Points',
						fieldType: FieldTypeEnum.Number,
						bottomCalc: 'sum',
						bottomCalcFormatter: (cell: any) => {
							let totalPoints = 0;
							for (const rating of dashboard.averageRatings || []) {
								const points = rating.ratingSum;
								totalPoints += points;
							}
							return totalPoints;
						},
						disableSort: true,
						disableFilter: true,
					}
				}
			}
			return result;
		},
		[isQuiz, dashboard.averageRatings]
	)

	return useVisibleColumns(columns, defaultColumns);
}
