import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ChangeRequestRouter } from '../ReportComponents/ChangeRequest/ChangeRequestRouter';
import { ActionRouter } from '../ReportComponents/CommentsAndActions/ActionRouter';
import { MajorIssueRouter } from '../ReportComponents/MajorIssues/MajorIssueRouter';
import { DeliverablePlanRouter } from '../ReportComponents/PerformanceStatus/DeliverablePlan/DeliverablePlanRouter';
import { MajorAccomplishmentRouter } from '../ReportComponents/PerformanceStatus/MajorAccomplishments/MajorAccomplishmentRouter';
import { MilestonePlanRouter } from '../ReportComponents/PerformanceStatus/MilestonePlan/MilestonePlanRouter';
import { PotentialBusinessRouter } from '../ReportComponents/PotentialAdditionalBusiness/PotentialBusinessRouter';
import { ProjectFinancesRouter } from '../ReportComponents/ProjectFinances/ProjectFinancesRouter';
import { QualityManagementRouter } from '../ReportComponents/QualityManagement/QualityManagementRouter';
import { RiskManagementRouter } from '../ReportComponents/RiskManagement/RiskManagementRouter';
import { SubcontractorPerformanceRouter } from '../ReportComponents/SubcontractorPerformance/SubocontractorPerformanceRouter';

export enum UpdateReportSubRoutes {
	MAJOR_ACCOMPLISHMENT = 'major_accomplishment',
	MILESTONE_PLAN = 'milestone_plan',
	DELIVERABLE_PLAN = 'deliverable_plan',
	MAJOR_ISSUE = 'major_issue',
	CHANGE_REQUEST = 'change_request',
	SUBCONTRACTOR_PERFORMANCE = 'subcontractor_performance',
	RISK_MANAGEMENT = 'risk_management',
	QUALITY_MANAGEMENT = 'quality_management',
	PROJECT_FINANCES = 'project_finances',
	POTENTIAL_ADDITIONAL_BUSINESS = 'potential_additional_business',
	COMMENTS_AND_ACTIONS = 'comments_and_actions'
}

export const UpdateReportRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/${UpdateReportSubRoutes.MAJOR_ACCOMPLISHMENT}`} component={MajorAccomplishmentRouter} />
			<Route path={`${path}/${UpdateReportSubRoutes.MILESTONE_PLAN}`} component={MilestonePlanRouter} />
			<Route path={`${path}/${UpdateReportSubRoutes.DELIVERABLE_PLAN}`} component={DeliverablePlanRouter} />
			<Route path={`${path}/${UpdateReportSubRoutes.MAJOR_ISSUE}`} component={MajorIssueRouter} />
			<Route path={`${path}/${UpdateReportSubRoutes.CHANGE_REQUEST}`} component={ChangeRequestRouter} />
			<Route path={`${path}/${UpdateReportSubRoutes.SUBCONTRACTOR_PERFORMANCE}`} component={SubcontractorPerformanceRouter} />
			<Route path={`${path}/${UpdateReportSubRoutes.RISK_MANAGEMENT}`} component={RiskManagementRouter} />
			<Route path={`${path}/${UpdateReportSubRoutes.QUALITY_MANAGEMENT}`} component={QualityManagementRouter} />
			<Route path={`${path}/${UpdateReportSubRoutes.PROJECT_FINANCES}`} component={ProjectFinancesRouter} />
			<Route path={`${path}/${UpdateReportSubRoutes.POTENTIAL_ADDITIONAL_BUSINESS}`} component={PotentialBusinessRouter} />
			<Route path={`${path}/${UpdateReportSubRoutes.COMMENTS_AND_ACTIONS}`} component={ActionRouter} />
		</Switch>
	)
}
