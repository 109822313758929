import { ContentShell } from 'features/Content/ContentShell';
import Tabs from 'components/Tabs/Tabs';
import { calendarTabs } from './Tabs/calendarTabs';
import { CalendarHelp } from './Help/CalendarHelp';

export const Calendar = () => {
	return (
		<ContentShell
			title='Calendar'
			FloatingHelpComponent={CalendarHelp}
		>
			<Tabs
				tabs={calendarTabs}
			/>
		</ContentShell>
	)
}
