import { HelpShell } from 'features/Help/HelpShell';

export const MyTravelExpensesHelp = () => {
	return (
		<HelpShell title='My Travel Expense Report'>
			<p>
				When your trip is conducted, you can create travel expense report and link it with your travel request. This link will copy all information from travel request to your travel expense report.
			</p>
			<div>
				<p>In report you can specify all information about travel such as:</p>
				<ul>
					<li>
						Information about exact departure date and time, as well arrival date and time. When traveling abroad additional information can be entered such as departure and return border cross. Based on entered information, application is calculating travel time in departure, travel time in return and total travel time of your trip.
					</li>
					<li>
						Travel Expenses such as plane tickets, hotel bills and daily allowances. Please note that expenses need to be supported by appropriate receipt and that receipt attachment could be mandatory. This will be configured by your site administrator. For each receipt you are entering amount in project currency, but there is also possibility to enter amount in receipt currency if needed. Conversion from receipt currency to project currency is done by user. Application is not using exchange rates and it will not convert amounts in different currencies.
					</li>
					<li>
						If you travel by company or private car, additional fields will show up such as: registration number, distance, start and end miles/km.
					</li>
				</ul>
			</div>
			<p>
				When all information is specified, you can save your travel expense report or release it for approval by responsible project manager. <br />
				Project or resource manager is approving or rejecting each travel expense. <br />
				If travel expense is rejected, user can make modifications to travel expense and send it for approval again.
			</p>
			<p>
				Travel expenses can be exported to pdf files if needed. Please note that also all attached receipts in <u>jpg or png</u> format will be exported to pdf file.
			</p>
			<div>
				<p>On time and travel dashboard page you can see reports related to your travel expenses such as:</p>
				<ul>
					<li>Travel expenses per status: created, released, approved</li>
					<li>List of not approved travel expenses per project</li>
				</ul>
			</div>
		</HelpShell>
	)
}
