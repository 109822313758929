import { useCallback, useMemo, useState } from 'react';
import { Cover } from 'components/Cover';
import { ContentTitle } from 'features/Content';
import { AlertEntitiesEnum, AlertResponse } from 'services/tenantManagementService';
import { TestPlanNotification } from './Entity/TestPlanNotification';
import { TicketNotification } from './Entity/TicketNotification';
import { ChangeRequestNotification } from './Entity/ChangeRequestNotification';
import { TimesheetsNotification } from './Entity/TimesheetsNotification';
import { ExpenseNotification } from './Entity/ExpenseNotification';
import { VacationNotification } from './Entity/VacationNotification';
import { MeetingNotification } from './Entity/MeetingNotification';
import { StatusReportNotification } from './Entity/StatusReportNotification';
import { TaskNotification } from './Entity/TaskNotification';
import styles from './notificationsPanel.module.scss'
import Button from 'components/Button/Button';
import { RowContainer } from 'components/Layout';
import { deleteAlertsAction } from '../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import Spinner, { ClipSpinner } from 'components/Spinner';

export type NotificationComponentProps = {
	notification: AlertResponse
}

const getComponent = (entity: AlertEntitiesEnum) => {
	switch (entity) {
		case AlertEntitiesEnum.TestPlan:
			return TestPlanNotification;
		case AlertEntitiesEnum.Ticket:
			return TicketNotification;
		case AlertEntitiesEnum.ChangeRequest:
			return ChangeRequestNotification;
		case AlertEntitiesEnum.TimeSheets:
			return TimesheetsNotification;
		case AlertEntitiesEnum.Expense:
			return ExpenseNotification;
		case AlertEntitiesEnum.Vacation:
			return VacationNotification;
		case AlertEntitiesEnum.Meeting:
			return MeetingNotification;
		case AlertEntitiesEnum.StatusReport:
			return StatusReportNotification;
		case AlertEntitiesEnum.Task:
			return TaskNotification;
		default:
			return undefined;
}}

type Props = {
	visible?: boolean
	toggleVisible(): void,
	notifications: AlertResponse[],
}

export const NotificationsPanel = ({ visible, toggleVisible, notifications }: Props) => {
	const [deleting, setDeleting] = useState(false);

	const onDeleteCallback = useCallback(
		async () => {
			setDeleting(true)
			const response = await tryCatchJsonByAction(deleteAlertsAction)
			if (response.success) {
				// notifications.success('Notifications are deleted.')
			}
			setDeleting(false)
		},
		[]
	)

	const itemsContent = useMemo(
		() => {
			return notifications.map((notification) => {
				const Component = getComponent(notification.entity);

				if (Component) {
					return <Component key={notification.id} notification={notification}/>
				}

				return undefined;
			})
		},
		[notifications]
	)

	return (
		<>
			{visible && <Cover onClick={toggleVisible} />}
			<div className={`${styles.container} ${visible ? styles.visible : ''}`}>
				<div className={styles.inner}>
					<div className={styles.header}>
						<RowContainer justifyContent='space-between'>
							<ContentTitle title='Notifications' />
							{notifications.length > 0 && (
								<Button
									text='Clear all'
									onClick={onDeleteCallback}
									disabled={deleting}
								/>
							)}
						</RowContainer>
					</div>
					{itemsContent}
					{notifications.length === 0 && (
						<RowContainer justifyContent='center'>
							No data
						</RowContainer>
					)}
				</div>
				{deleting && (
					<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
						<Spinner>
							<ClipSpinner size={24} />
						</Spinner>
					</div>
				)}
			</div>
		</>
	)
}
