import { MitigationTypeEnum } from 'services/tenantManagementService';

type NumUnd = number | undefined;

// probability is in %
export const getNetImpact = (grossImpact: NumUnd, probability: NumUnd) => {
	return grossImpact && probability && grossImpact * probability / 100;
}

// probabilityAfterMitigation is in %
export const getNetImpactAfterMitigation = (netImpact: NumUnd, mitigationType: MitigationTypeEnum, grossImpactAfterMitigation: NumUnd, probabilityAfterMitigation: NumUnd, probability: NumUnd) => {
	switch (mitigationType) {
		case MitigationTypeEnum.None:
			return netImpact;
		case MitigationTypeEnum.Preventive:
			return grossImpactAfterMitigation && probabilityAfterMitigation && grossImpactAfterMitigation * probabilityAfterMitigation / 100;
		case MitigationTypeEnum.Corrective:
			return grossImpactAfterMitigation && probability && grossImpactAfterMitigation * probability / 100;
	}
}

export const getCostBenefit = (netImpact: NumUnd, mitigationType: MitigationTypeEnum, mitigationCost: NumUnd, netImpactAfterMitigation: NumUnd, grossImpact: NumUnd) => {
	switch (mitigationType) {
		case MitigationTypeEnum.None:
			return undefined;
		case MitigationTypeEnum.Preventive:
			return netImpact && mitigationCost && netImpactAfterMitigation && (netImpact - mitigationCost - netImpactAfterMitigation);
		case MitigationTypeEnum.Corrective:
			return grossImpact && mitigationCost && netImpactAfterMitigation && (grossImpact - mitigationCost - netImpactAfterMitigation);
	}
}

export const getRiskInclusion = (netImpact: NumUnd, mitigationType: MitigationTypeEnum, mitigationCost: NumUnd, netImpactAfterMitigation: NumUnd) => {
	switch (mitigationType) {
		case MitigationTypeEnum.None:
			return netImpact;
		case MitigationTypeEnum.Preventive:
		case MitigationTypeEnum.Corrective:
			return netImpactAfterMitigation && mitigationCost && (netImpactAfterMitigation + mitigationCost);
	}
}
