import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { GenericFilterModelCollection } from 'services/tenantManagementService';
import { exportAllProjectTeamMembersAction, getAllTeamMembersGenericFilteringAction } from './action';
import { TableButtons } from './Table/TableButtons';
import { useTableColumnsMemo } from './Table/tableColumns';

const configureViewKey  = 'user_status_per_project_table';

export const UserStatusPerProject = () => {
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const { persistedConfigureView } = useSelector((state: RootState) => state);

	const tableColumns = useTableColumnsMemo(
		persistedConfigureView.value[configureViewKey]
	);

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ColumnContainer margin='medium'>
			<TableButtons
				tableColumns={tableColumns}
				filtersModel={filtersModel}
				exportFunction={exportAllProjectTeamMembersAction}
				configureViewKey={configureViewKey}
			/>
			<RemoteTable
				columns={tableColumns}
				filtersModel={filtersModel}
				filtersModelChanged={setFiltersModel}
				fetchFunction={getAllTeamMembersGenericFilteringAction}
				reorderColumns={reorderColumnsCallback}
			/>
		</ColumnContainer>
	)
}
