import Button from 'components/Button';
import { Input } from 'components/Form/controls';
import { RowContainer } from 'components/Layout';
import { useCallback, useMemo} from 'react';
import { formatDate } from 'utils/dateTimeUtils';

type Props = {
	date: Date,
	onChange: (date: Date) => void
}

export const WeekPicker = ({ date, onChange }: Props) => {
	const nextWeekCallback = useCallback(
		() => {
			const newValues = new Date(date);
			newValues.setDate(newValues.getDate() + 7);
			onChange(newValues);
		},
		[date, onChange]
	)

	const prevWeekCallback = useCallback(
		() => {
			const newValues = new Date(date);
			newValues.setDate(newValues.getDate() - 7);
			onChange(newValues);
		},
		[date, onChange]
	)

	const formatedPeriodMemo = useMemo(
		() => {
			const sunday = new Date(date);
			sunday.setDate(sunday.getDate() + 6);
			return `${formatDate(date)} - ${formatDate(sunday)}`;
		},
		[date]
	)

	return (
		<RowContainer>
			<Button
				text='Previous'
				color='neutral'
				onClick={prevWeekCallback}
			/>
			<div style={{ width: '170px' }}>
				<Input
					value={formatedPeriodMemo}
					disabled
					hideMaxLength
				/>
			</div>
			<Button
				text='Next'
				color='neutral'
				onClick={nextWeekCallback}
			/>
		</RowContainer>
	)
}
