import { Document, Font, Page, StyleSheet, Image } from '@react-pdf/renderer';
import { ProjectResponse, MeetingResponse, ProjectIsActiveResponse, TenantIsActiveResponse, UserModel, MeetingStatusResponse, TaskStatusResponse, NonProjectCategoryResponse } from 'services/tenantManagementService';
import MeetingDocumentForm from './MeetingDocumentForm';
import { TitleField } from 'components/Export';
import { PersistActiveItemsReducer, PersistItemsReducer, PersistProjectItemsReducer } from 'features/Persist/reducerTypes';

type Props = {
	meeting: MeetingResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedMeetingType: PersistActiveItemsReducer<TenantIsActiveResponse>
	persistedMeetingStatus: PersistItemsReducer<MeetingStatusResponse>
	persistedProjectTeam: PersistProjectItemsReducer<ProjectIsActiveResponse>
	persistedTaskStatus: PersistItemsReducer<TaskStatusResponse>
	persistedProject: PersistItemsReducer<ProjectResponse>
	persistedTimeTravelNonProjectCategories: PersistActiveItemsReducer<NonProjectCategoryResponse>
	base64Logo?: string
}

const MeetingDocument = ({
	meeting,
	persistedUser,
	persistedMeetingType,
	persistedMeetingStatus,
	persistedProjectTeam,
	persistedTaskStatus,
	persistedProject,
	persistedTimeTravelNonProjectCategories,
	base64Logo
}: Props) => {
	return (
		<Document>
			<Page wrap size="A4" style={styles.page}>
				{base64Logo && <Image style={{ marginLeft: 'auto', maxWidth:'100px', height: 'auto'}} src={base64Logo} /> }
				<TitleField title='Project meeting' />
				<MeetingDocumentForm
					meeting={meeting}
					persistedMeetingType={persistedMeetingType}
					persistedProjectTeam={persistedProjectTeam}
					persistedMeetingStatus={persistedMeetingStatus}
					persistedUser={persistedUser}
					persistedTaskStatus={persistedTaskStatus}
					persistedProject={persistedProject}
					persistedTimeTravelNonProjectCategories={persistedTimeTravelNonProjectCategories}
				/>
			</Page>
		</Document>
	);
};

export default MeetingDocument;

Font.register({
  family: 'Open Sans',
  fonts: [
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const styles = StyleSheet.create({
	page: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingHorizontal: 10,
		fontFamily: 'Open Sans'
	},
});
