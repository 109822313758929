import { useCallback, useState } from 'react';
import { StatusReportGeneralConfigResponse, UpdateStatusReportGeneralConfigRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getStatusReportGeneralConfigAction, updateStatusReportGeneralConfigAction } from './action';
import notifications from 'components/Notification/notification';
import { CheckboxField, Form, FormGroup } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import WithFetch from 'features/Fetch/WithFetch';
import { propertyOf } from 'utils/propertyOf';

export const StatusReportGeneral = () => {
	const [values, setValues] = useState(new StatusReportGeneralConfigResponse());
	const [initialValues, setInitialValues] = useState(new StatusReportGeneralConfigResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getStatusReportGeneralConfigAction);
			if (response.success && response.value) {
				setValues(new StatusReportGeneralConfigResponse(response.value));
				setInitialValues(new StatusReportGeneralConfigResponse(response.value));
			}
		},
		[]
	);

	const handleCancel = useCallback(
		() => setValues(new StatusReportGeneralConfigResponse(initialValues)),
		[initialValues]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const model = new UpdateStatusReportGeneralConfigRequest(values);

			const bindedAction = updateStatusReportGeneralConfigAction.bind(null, model)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setInitialValues(new StatusReportGeneralConfigResponse(values));
				notifications.success('Status report general configuration is updated.');
			} else {
				return convertResponseErrors(response);
			}
		},
		[values]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback} >
			<Form
				values={values}
				initialValues={initialValues}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={handleCancel}
				render={() => (
					<SmartContainer>
						<SmartItem>
							<FormGroup title='Notifications'>
								<CheckboxField
									id={propertyOf<StatusReportGeneralConfigResponse>('enableNotifications')}
									labelBefore='Enable notifications'
								/>
							</FormGroup>
						</SmartItem>
					</SmartContainer>
				)}
			/>
		</WithFetch>
	)
}
