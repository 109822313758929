import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { ModuleActivityEnum, ProjectResponse, TimeTravelStatusResponse, UserModel, VacationRequestStatusFullResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { RootState } from 'base/reducer/reducer';
import { useApplyStatusColorCallback, useFormattedProjectIdNameCallback, useStatusCallback, useUsernameCallback } from 'features/TableColumns/persistedHooks';
import { useProjectsForTableFilterCallback } from 'features/Project';

const defaultVisibleColumns = [
	propertyOf<VacationRequestStatusFullResponse>('projectId'),
	propertyOf<VacationRequestStatusFullResponse>('vacationRequestId'),
	propertyOf<VacationRequestStatusFullResponse>('userId'),
	propertyOf<VacationRequestStatusFullResponse>('from'),
	propertyOf<VacationRequestStatusFullResponse>('to'),
	propertyOf<VacationRequestStatusFullResponse>('statusId'),
]

export const useTableColumnsMemo = (configureViewKey: string) => {
	const {
		persistedProject,
		persistedTimeAndTravelStatus,
		persistedUser,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUsername = useUsernameCallback(persistedUser);
	const getStatusName = useStatusCallback(persistedTimeAndTravelStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedTimeAndTravelStatus);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);
	const { projects} = useProjectsForTableFilterCallback(ModuleActivityEnum.Time);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<VacationRequestStatusFullResponse>('vacationRequestId')]: {
					title: 'Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.VACATION_REQUEST
				},
				[propertyOf<VacationRequestStatusFullResponse>('userId')]: {
					title: 'Created by',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
				[propertyOf<VacationRequestStatusFullResponse>('from')]: {
					title: 'From',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<VacationRequestStatusFullResponse>('to')]: {
					title: 'To',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<VacationRequestStatusFullResponse>('projectId')]: {
					title: 'Project Id',
					formatter: (cell: any) => formatProjectIdName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: projects,
					getItemId: (option: ProjectResponse) => option.id,
					getItemText: (option: ProjectResponse) => formatProjectIdName(option.id)
				},
				[propertyOf<VacationRequestStatusFullResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatusName(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTimeAndTravelStatus.items,
					getItemId: (option: TimeTravelStatusResponse) => option.id,
					getItemText: (option: TimeTravelStatusResponse) => option.name,
					dbFilterFieldPath: 'TimeTravelStatusId'
				},
				[propertyOf<VacationRequestStatusFullResponse>('projectManagerUserId')]: {
					title: 'Project manager',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
				[propertyOf<VacationRequestStatusFullResponse>('approvedOrRejectedByUserId')]: {
					title: 'Approved/Rejected by',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
				[propertyOf<VacationRequestStatusFullResponse>('note')]: {
					title: 'Note to approver',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<VacationRequestStatusFullResponse>('customText1')]: {
					title: 'Text 1',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<VacationRequestStatusFullResponse>('customText2')]: {
					title: 'Text 2',
					fieldType: FieldTypeEnum.String
				}
			}
		},
		[projects, getStatusName, applyStatusColor, getUsername, formatProjectIdName, persistedTimeAndTravelStatus.items, persistedUser]
	)

	return useVisibleColumns(columns, visibleColumns);
}
