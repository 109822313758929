import { ContentShell } from 'features/Content/ContentShell';
import { getCategoriesAction, updateCategoriesAction } from './action';
import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';
import { useCallback } from 'react';
import { FinanceCategoryResponse } from 'services/tenantManagementService';
import { CategoriesHelp } from './CategoriesHelp';

export const Categories = () => {
	const isConfigReadonlyCallback = useCallback(
		(config: FinanceCategoryResponse) => {
			return config.isReadonly;
		},
		[]
	)

	return (
		<ContentShell
			InlineHelpComponent={CategoriesHelp}
			showBreadcrumb={false}
		>
			<TenantIsActiveTable
				getConfigsAction={getCategoriesAction}
				updateConfigsAction={updateCategoriesAction}
				successMessage='Finance categories are updated.'
				label={{ name: 'Category name' }}
				isConfigReadonly={isConfigReadonlyCallback}
			/>
		</ContentShell>
	)
}
