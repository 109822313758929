import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectAccomplishmentResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getAccomplishmentAction, updateAccomplishmentAction } from '../action';
import { MajorAccomplishmentForm } from '../MajorAccomplishmentsForm';
import { Dialog } from 'components/Dialog';
import WithFetch from 'features/Fetch/WithFetch';
import { publishMajorAccomplishmentsChanged } from '../majorAccomplishmentsChanged';
import { ReportComponentParams } from '../../../Table/TableButtons';
import { useGoBackFromUpdate } from 'features/Crud';

type ParamType = ReportComponentParams & {
	majorAccomplishmentId: string;
}

export const UpdateMajorAccomplishment = () => {
	const params: ParamType = useParams();
	const reportId = parseInt(params.reportId);
	const majorAccomplishmentId = parseInt(params.majorAccomplishmentId);

	const [majorAccomplishment, setMajorAccomplishment] = useState(new ProjectAccomplishmentResponse ());

	const goBackFromUpdate = useGoBackFromUpdate(true);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getAccomplishmentAction.bind(null, reportId, majorAccomplishmentId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setMajorAccomplishment(response.value || new ProjectAccomplishmentResponse ());
			}
		},
		[reportId, majorAccomplishmentId]
	)

	const onSubmitCallback = useCallback(
		async (majorAccomplishment: ProjectAccomplishmentResponse) => {
			const bindedAction = updateAccomplishmentAction.bind(null, reportId, majorAccomplishment);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Major accomplishment successfully updated');
				goBackFromUpdate();
				publishMajorAccomplishmentsChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, reportId]
	);

	return (
		<Dialog
			title='Update Major accomplishment'
			onClose={goBackFromUpdate}
			open
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<MajorAccomplishmentForm accomplishment={majorAccomplishment} save={onSubmitCallback} cancel={goBackFromUpdate} />
			</WithFetch>
		</Dialog>
	)
};
