import { HelpShell } from "features/Help/HelpShell"

export const ProjectMeetingsHelp = () => {
	return (
		<HelpShell title='Meetings'>
			<p>
				In Mavles you can create Meeting, define participants and agenda. 
			</p>
			<p>
				During or after meeting you can create meeting minutes and identify topics as tasks, information or decision. <br />
				When topic is defined as task, you will need to enter responsible user and due date and they will be shown in user's task list.
			</p>
			<p>
				When meeting is in status <b>created</b>, users can make changes to the meeting.
			</p>
			<p>
				When meeting minutes are finalized, user who created meeting can <b>release</b> it. At that moment email/notification is sent to all meeting participants informing them that meeting minutes are released and they can review it. When meeting is in this status, it can not be changed, but meeting participants can enter comments related to each topic. If needed, meeting status can be <b>reset</b> by user who created meeting and changes entered.
			</p>
		</HelpShell>
	)
}
