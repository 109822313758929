import React, { useCallback, useMemo, useState } from 'react';
import styles from './floatingHelp.module.scss';
import { HelpIcon } from 'components/icons/icons';
import { Cover } from 'components/Cover';

type Props = {
	children: React.ReactElement
}

const FloatingHelp = ({ children }: Props) => {
	const [opened, setOpened] = useState(false);

	const toggleCallback = useCallback(
		() => setOpened((state) => !state),
		[]
	)

	const closeCallback = useCallback(
		() => setOpened(false),
		[]
	)

	const className = useMemo(
		() => {
			if (opened) {
				return `${styles.container} ${styles.opened}`;
			} else {
				return styles.container;
			}
		},
		[opened]
	)

	return (
		<>
			<div className={`${className}`}>
				{opened && <Cover onClick={closeCallback} transparent />}
				<div className={styles.content}>
					{children}
				</div>
			</div>
			<div className={styles.toggler} onClick={toggleCallback}>
				<HelpIcon width={18} height={18} />
			</div>
		</>
	)
}

export default FloatingHelp;
