import { AutoCompleteField } from "components/Form"
import { useEffect, useState } from "react"
import { TicketResponse, TicketResponseGetAll } from "services/tenantManagementService"
import { EntityPrefixEnum, getFormatedId } from "utils/commonHelper"
import { tryCatchJsonByAction } from "utils/fetchUtils"
import { propertyOf } from "utils/propertyOf"
import { getAllTicketsAction } from "../../action"

export const ParentTicket = () => {
	const [loadingTickets, setLoadingTickets] = useState(true);
	const [tickets, setTickets] = useState<TicketResponseGetAll[]>([]);

	useEffect(
		() => {
			const fetchData = async () => {
				setLoadingTickets(true);

				const response = await tryCatchJsonByAction(getAllTicketsAction);
				if (response.success) {
					setTickets(response.items || []);
				}

				setLoadingTickets(false);
			}

			fetchData();
		},
		[]
	)

	return (
		<AutoCompleteField
			id={propertyOf<TicketResponse>('parentTicketId')}
			label='Parent ticket'
			items={tickets}
			getItemId={(item: TicketResponseGetAll) => item.id}
			getItemText={(item: TicketResponseGetAll) => `${getFormatedId(EntityPrefixEnum.TICKET, item.id)} - ${item.name}`}
			loading={loadingTickets}
		/>
	)
}
