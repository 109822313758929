import { HelpShell } from 'features/Help/HelpShell';

export const MyProjectsHelp = () => {
	return (
		<HelpShell title='Projects'>
			<p>In projects module you can see all projects where you are assigned as a team member and view some basic information posted by Project manager on Wiki page.</p>
			<p>Here, project managers are defining projects and assigning team members.</p>
			<div>
				<p>You can maintain:</p>
				<ul>
					<li>General Project information, status and modules for which project is activate</li>
					<li>Project teams</li>
					<li>Project roles</li>
					<li>Activate Service Level Agreement (SLA) response and resolution time</li>
					<li>Activate Change request process</li>
				</ul>
			</div>

			<p><u><b>Project Data</b></u></p>
			<p>All projects are initially created by site administrator. Site administrator is assigning project manager to a project. Only fter this step, project manager is allowed to maintain project for which he is responsible.</p>
			<p>
				When new project is created project status is set to <b>planning</b>. While project is in planning status, project is not visible for users and users cannot assign their activities to this project. Project manager needs to <b>release</b> the project in order to activate it and make it visible for users.  When all project activities are finalized and it is not expected to assign any new objects to project, project managers can change project status to <b>completed</b>. At this point, all project data can be viewed but not modified. Finally, projects and project data can be <b>archived</b> by project manager, and they will not be visible for users. If needed, project managers can restore archived projects. <br />
				Projects can be <b>deleted</b> only by site administrator.
			</p>
			<p>For each project, you can define <b>project type and category</b> based on configuration made by site administrator.</p>
			<div>
				<p>Projects can be assigned to <b>organizational unit</b> of your company which responsible for project execution. For each organizational unit, site administrator can define responsible manager. This assignment can have 2 functions:</p>
				<ol>
					<li>allow managers to view all project related reports.</li>
					<li>allow unit managers to approve Time &amp; Travel requests.</li>
				</ol>
			</div>
			<p>Please notice that managers of organizational unit can also see reports for lower organizational levels. For example: Country manager can see reports related to all projects assigned to county x. Manager of organizational unit on 2nd level can see all reports on project assigned to levels below.</p>
			<p>In project data tab you can attach documents (contracts and other relevant project documentation).</p>
			<p>For each project you can define for which modules your project will be used. If checkboxes next to modules are not active (not checked), your project could not be selected in those modules.</p>

			<p><u><b>Project teams and roles</b></u></p> 
			<p>Project manager can define project teams and project roles based on project organization. This definition doesn't affect user authorizations in Mavles, and it is  used for reporting purposes only. You need to define at least one project team and one project role in every project.</p>

			<p><u><b>Assign team member</b></u></p>
			<p>After project information and project teams and roles are defined, project manager can assign users to his project. Prerequisite for this action is that users are created in Mavles. Please note that if you do not see new users you created in the dropdown list, you need to refresh page.</p>
			<p>For each user on project, project manager can define his validity period for the project. After this date is expired, user will not be able to perform activities related to this project. Validity can be extended any time by project manager.</p>
			<p>Project manager can assign substitute project manager by marking this option. Substitute project manager will have all authorizations same as project manager.</p>
			<p>Finally, users are assigned to modules and activities which they can access and use for this project. By default, Mavles defines user authorizations for basic module functionalities. If you want to permit more user rights, you can mark checkboxes for additional activities.</p>
			<p>For each user you can define hourly rates, both billing and cost. These rates are used when calculating project cost and revenues (budget and actual) in Finance module. You can also define different rates for different periods. Please note that periods can not overlap, and rates can be defined in any time during project execution.</p>

			<p><u><b>SLA</b></u></p>
			<div>
				<p>
					Project Manager can activate Service Level Agreement (SLA) - calculation of response and resolution time for your project tickets. These settings are defined for each project. <br />
					Project Manager defines:
				</p>
				<ul>
					<li>If response and resolution time is measured in minutes, hours or days.</li>
					<li>If response and resolution time is calculated only during business hours or 24h a day.</li>
					<li>If resolution time is calculated until solution is delivered for testing (ticket: status resolved) or only when ticket is closed by requestor.</li>
					<li>If response and resolution time is measured while ticket is in on-hold status.</li>
				</ul>
			</div>
			<p>
				Finally, project manager maintains response and resolution times based on ticket priority. <br />
				SLA can be activated in any time during project execution.
			</p>

			<p><u><b>Change Request process</b></u></p>
			<p>For each project, project manager can enable change request approval process. He/she can define if all team members can initiate change request or only selected users. In each phase of change request process, project manager is able to define which users can be responsible for each step: initiation, analysis, approval for realization, realization and acceptance.</p>
		</HelpShell>
	)
}
