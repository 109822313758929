import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReminderResponse, UpdateReminderRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import { getReminderAction, updateReminderAction } from '../action';
import { clearRepeat, ReminderForm } from './ReminderForm';
import { ProjectOrCategoryPickerParams } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = ProjectOrCategoryPickerParams & {
	reminderId: string
}

export const UpdateReminder = ({ publishDataChanged }: UpdateComponentProps) => {
	const params: ParamType = useParams();
	const projectOrCategoryId = parseInt(params.projectOrCategoryId as string);
	const isProjectConnected = params.isProjectConnected === 'true';
	const reminderId = parseInt(params.reminderId as string);
	const goBackFromUpdate = useGoBackFromUpdate();

	const [reminder, setReminder] = useState(new ReminderResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getReminderAction.bind(null, reminderId, projectOrCategoryId, isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const value = response.value || new ReminderResponse();
				if (value.isPrivate) {
					// at the moment this change doesn't make sence
					// but earlier we let { isPrivate: true, recepientUserIds: [] } to be saved
					// and later we let { isPrivate: true, recepientUserIds: undefined } to be saved
					// so if there are already some data in database with recepientUserIds = []
					// set it here to undefined for "Unsaved Changes" dialog to work as expected
					value.recipientUserIds = undefined
				}
				if (!value.isRepeat) {
					value.repeatInterval = undefined as any;
					clearRepeat(value);
				}
				setReminder(value);
			}
		},
		[projectOrCategoryId, isProjectConnected, reminderId]
	)

	const saveCallback = useCallback(
		async (newReminder: ReminderResponse) => {
			const model = new UpdateReminderRequest(newReminder);

			const bindedAction = updateReminderAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.REMINDER, response.value?.id);
				notifications.success(`Reminder ${id} is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`Change reminder - ${getFormatedId(EntityPrefixEnum.REMINDER, reminder.id)}`}>
				<ReminderForm
					reminder={reminder}
					onSave={saveCallback}
					cancel={goBackFromUpdate}
				/>
			</ContentShell>
		</WithFetch>
	)
}
