import { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { ContentTitle } from 'features/Content';
import { ApprovalTimeTravelDescriptiveRequest, ExpenseResponse, TimeTravelStatusEnum } from 'services/tenantManagementService';
import { getFormatedId, EntityPrefixEnum } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, useGoBackFromRead } from 'features/Crud';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import RejectRequestModalForm from 'containers/TimeTravel/Approval/RejectRequest/RejectRequestModalForm';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { RootState } from 'base/reducer/reducer';
import { approvalExpenseAction, getExpenseAction } from 'containers/TimeTravel/MyExpenses/action';
import ExpenseForm from 'containers/TimeTravel/MyExpenses/Crud/ExpenseForm';
import Export from 'containers/TimeTravel/MyExpenses/Export/Export';
import { ReadComponentProps } from 'features/Crud/CrudRouter';

type ParamType = {
	expenseId: string;
}

const ReadExpense = ({publishDataChanged}: ReadComponentProps) => {
	const params: ParamType = useParams();
	const [expense, setExpense] = useState(new ExpenseResponse());
	const [isSubmitting, setIsSubmitting] = useState(false);
	const goBackFromRead = useGoBackFromRead();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const {	persistedTimeAndTravelStatus, persistedExpenseGeneralConfiguration } = useSelector((state: RootState) => state);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getExpenseAction.bind(null, parseInt(params.expenseId));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {

				setExpense(response.value || new ExpenseResponse());
			}
		},
		[params.expenseId]
	)

	const approveCallback = useCallback(
		async () => {
			const request = new ApprovalTimeTravelDescriptiveRequest({
				approve: true
			})
			setIsSubmitting(true);
			const bindedAction = approvalExpenseAction.bind(null, expense.id, request);
			const response = await tryCatchJsonByAction(bindedAction);
			setIsSubmitting(false);

			if (response.success) {
				notifications.success(`${getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, expense.id)} is approved`)
				publishDataChanged();
				goBackFromRead();
			}
		},
		[expense.id, goBackFromRead, publishDataChanged]
	)

	const rejectCallback = useCallback(
		async (rejectionComment?: string) => {
			const request = new ApprovalTimeTravelDescriptiveRequest({
				approve: false,
				rejectionText: rejectionComment
			})
			setIsSubmitting(true);
			const bindedAction = approvalExpenseAction.bind(null, expense.id, request);
			const response = await tryCatchJsonByAction(bindedAction);
			setIsSubmitting(false);

			if (response.success) {
				notifications.success(`${getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, expense.id)} is rejected`)
				publishDataChanged();
				goBackFromRead();
			} else {
				return convertResponseErrors(response);
			}
		},
		[expense.id, goBackFromRead, publishDataChanged]
	)

	const approveDisabledMemo = useMemo(
		() => !expense.id ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, expense.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Approved, expense.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[expense, persistedTimeAndTravelStatus.itemsMap, isSubmitting]
	)

	const rejectDisabledisabledMemo = useMemo(
		() => !expense.id ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, expense.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Rejected, expense.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[expense, persistedTimeAndTravelStatus.itemsMap, isSubmitting]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentTitle title={`View expense details - ${getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, expense.id)}`} />
			<ColumnContainer margin='medium'>
				<RowContainer>
					<Button text='Back' disabled={isSubmitting} onClick={goBackFromRead} />
					{persistedExpenseGeneralConfiguration.value.enableApprovalProcess && (
						<RowContainer>
							<Button text='Approve' disabled={approveDisabledMemo} onClick={approveCallback} isLoading={isSubmitting} />
							<Button text='Reject' disabled={rejectDisabledisabledMemo} onClick={() => { setIsModalOpen(true) }} isLoading={isSubmitting} />
						</RowContainer>
					)}
					<Export expense={expense} />
				</RowContainer>
				<ExpenseForm
					expense={expense}
					crud={CrudEnum.Read}
				/>
				<RejectRequestModalForm
					title={`Reject expense - ${getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, expense.id)}`}
					onCancel={() => {setIsModalOpen(false)}}
					onSave={rejectCallback}
					open={isModalOpen}
					rejectionCommentRequired={persistedExpenseGeneralConfiguration.value.requireCommentsWhenRejecting}
				/>
			</ColumnContainer>
		</WithFetch>
	)
}

export default ReadExpense;
