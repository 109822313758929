import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { SmartContainer, SmartFormGroup, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { AutoCompleteField, CheckboxField, currencyDefaultProps, DateField, Form, InputField, InputNumberField, TextareaField } from 'components/Form';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { TravelRequestResponse, IdNameResponse, TimeTravelStatusResponse, TravelByEnum, TimeTravelPermission, ModuleActivityEnum } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { useCurrencySuffixMemo } from 'features/Currency/useCurrencySuffixMemo';
import Button from 'components/Button';
import { RowContainer } from 'components/Layout';
import { getTravelByEnumLabel, travelByOptions } from 'containers/TimeTravel/utils';
import { ProjectOrCategorySelect, ProjectOrCategoryType, pmOrSpmPermission } from 'features/Project';

type Props = {
	travelRequest?: TravelRequestResponse
	crud: CrudEnum
	onSave?: (travelRequest: TravelRequestResponse, isRelease: boolean) => void
}

const TravelRequestForm = ({
	travelRequest = new TravelRequestResponse(),
	crud,
	onSave
}: Props) => {
	const {
		persistedCountry,
		persistedTenant,
		persistedCurrency,
		persistedTimeAndTravelStatus,
		persistedExpenseGeneralConfiguration
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(travelRequest);
	const [initialValues, setInitialValues] = useState(travelRequest);
	const [projectOrCategoryHasError, setProjectOrCategoryHasError] = useState(false);

	const [projectOrCategory, setProjectOrCategory] = useState<ProjectOrCategoryType | undefined>({
		projectOrCategoryId: values.projectOrCategoryId,
		isProjectConnected: values.isProjectConnected
	});

	const isReleaseRef = useRef(false);

	const currencySymbolSuffix = useCurrencySuffixMemo(persistedCurrency, persistedTenant);

	const goBackFromCrud = useGoBackFromCrud(crud);

	const isRead = crud === CrudEnum.Read;
	const isUpdate = crud === CrudEnum.Update;

	useEffect(
		() => {
			setInitialValues((state: TravelRequestResponse) => {
				return new TravelRequestResponse({
					...state,
					projectOrCategoryId: projectOrCategory?.projectOrCategoryId!,
					isProjectConnected: projectOrCategory?.isProjectConnected!
				})
			});
		},
		[projectOrCategory]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const newTravelRequest = new TravelRequestResponse({
				...values,
				isProjectConnected: projectOrCategory?.isProjectConnected!,
				projectOrCategoryId: projectOrCategory?.projectOrCategoryId!,
			});

			if (!newTravelRequest.projectOrCategoryId) {
				// projectOrCategorySelect is required, so we return message as error if no value in select
				setProjectOrCategoryHasError(true);
			} else {
				return onSave && await onSave(newTravelRequest, isReleaseRef.current);
			}
		},
		[onSave, values, projectOrCategory]
	)

	const onProjectOrCategoryChangeCallback = useCallback(
		(newProjectOrCategory: ProjectOrCategoryType | undefined) => {
			setProjectOrCategory(newProjectOrCategory);
			setProjectOrCategoryHasError(false);
		},
		[]
	)

	return (
		<Form
			values={values}
			initialValues={initialValues}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			disabled={isRead}
			hideButtons={isRead}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectOrCategorySelect
							value={projectOrCategory}
							onChange={onProjectOrCategoryChangeCallback}
							disabled={isRead}
							hasError={projectOrCategoryHasError}
							isRequired
							moduleEnum={ModuleActivityEnum.Time}
							teamMemberPermission={propertyOf<TimeTravelPermission>('travelRequests')}
							userRolePermission={pmOrSpmPermission}
						/>
						<DateField
							id={propertyOf<TravelRequestResponse>('departure')}
							label='Departure'
							isRequired
						/>
						<DateField
							id={propertyOf<TravelRequestResponse>('return')}
							label='Return'
							isRequired
						/>
						<TextareaField
							id={propertyOf<TravelRequestResponse>('description')}
							label='Purpose of travel'
							maxLength={2000}
							rows={5}
							isRequired
						/>
						<InputNumberField
							id={propertyOf<TravelRequestResponse>('estimatedCosts')}
							label='Estimated costs'
							{...currencyDefaultProps}
							isRequired
							suffix={currencySymbolSuffix}
						/>
						<InputNumberField
							id={propertyOf<TravelRequestResponse>('advancePayment')}
							label='Advance payment'
							{...currencyDefaultProps}
							suffix={currencySymbolSuffix}
						/>
						<SmartFormGroup>
							<SmartInline>
								<CheckboxField
									id={propertyOf<TravelRequestResponse>('freeMeals')}
									labelBefore='Free Meals'
								/>
								<CheckboxField
									id={propertyOf<TravelRequestResponse>('freeAccommodation')}
									labelBefore='Free Accommodation'
								/>
							</SmartInline>
						</SmartFormGroup>
						{(isRead || isUpdate) && (
							<AutoCompleteField
								id={propertyOf<TravelRequestResponse>('statusId')}
								label='Status'
								items={persistedTimeAndTravelStatus.items}
								getItemId={(option: TimeTravelStatusResponse) => option.id}
								getItemText={(option: TimeTravelStatusResponse) => option.name!}
								loading={persistedTimeAndTravelStatus.fetching}
								disabled
							/>
						)}
					</SmartItem>
					<SmartItem>
						<AutoCompleteField
							id={propertyOf<TravelRequestResponse>('destinationCountryId')}
							label='Destination country'
							items={persistedCountry.items}
							getItemId={(country: IdNameResponse) => country.id}
							getItemText={(country: IdNameResponse) => country.name!}
							loading={persistedCountry.fetching}
							isRequired
						/>
						<InputField
							id={propertyOf<TravelRequestResponse>('destinationCity')}
							label='Destination city'
							isRequired
						/>
						<AutoCompleteField
							id={propertyOf<TravelRequestResponse>('travelBy')}
							label='Travel by'
							items={travelByOptions}
							getItemId={(option: TravelByEnum) => option}
							getItemText={(option: TravelByEnum) => getTravelByEnumLabel(option)}
							isRequired
						/>
						<InputField
							id={propertyOf<TravelRequestResponse>('customText1')}
							label='Text 1'
						/>
						<InputField
							id={propertyOf<TravelRequestResponse>('customText2')}
							label='Text 2'
						/>

					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={persistedExpenseGeneralConfiguration.value.enableApprovalProcess ? (disabled, handleSubmitCallback: () => void, isSubmitting) => (
				<RowContainer>
					<Button
						text='Release for approval'
						disabled={disabled}
						isLoading={isSubmitting && isReleaseRef.current === true}
						onClick={
							() => {
								isReleaseRef.current = true;
								handleSubmitCallback();
							}
						}
					/>
				</RowContainer>
			) : undefined}
		/>
	)
}

export default TravelRequestForm;
