import { ContentShell } from "features/Content/ContentShell"
import { BudgetAndActualsCommon } from "../BudgetAndActualsCommon"
import { exportBudgetsAction, getBudgetRowsAction, getHeadersAction, releaseBudgetRowsAction, updateBudgetRowsAction } from "./action"
import { BudgetHelp } from "./Help/BudgetHelp"
import { ComponentProps } from "features/Project"

export const Budget = ({ disabledEdit }: ComponentProps) => {
	return (
		<ContentShell
			FloatingHelpComponent={BudgetHelp}
		>
			<BudgetAndActualsCommon
				getRowsAction={getBudgetRowsAction}
				updateRowsAction={updateBudgetRowsAction}
				getHeadersAction={getHeadersAction}
				releaseRowsAction={releaseBudgetRowsAction}
				exportAction={exportBudgetsAction}
				disabled={disabledEdit}
			/>
		</ContentShell>
	)
}
