import { TabType } from 'components/Tabs/Tabs';
import { BudgetActualDashboard } from './BudgetActual/BudgetActualDashboard';
import { DashboardTabSubroutes } from './DashboardSubroutes';
import { PartnersInvoicesDashboard } from './PartnersInvoices/PartnersInvoicesDashboard';
import { ProjectsDashboard } from './Projects/ProjectsDashboard';
import { TimesheetsDashboard } from './Timesheets/TimesheetsDashboard';
import { TravelExpensesDashboard } from './TravelExpenses/TravelExpensesDashboard';

export const dashboardTabs: TabType[] = [
	{
		id: '1',
		title: 'Budget vs Actual',
		component: BudgetActualDashboard,
		route: DashboardTabSubroutes.BUDGET_ACTUAL,
	},
	{
		id: '2',
		title: 'Partners / Invoices',
		component: PartnersInvoicesDashboard,
		route: DashboardTabSubroutes.PARTNERS_INVOICES,
	},
	{
		id: '3',
		title: 'Projects',
		component: ProjectsDashboard,
		route: DashboardTabSubroutes.PROJECTS,
	},
	{
		id: '4',
		title: 'Timesheets',
		component: TimesheetsDashboard,
		route: DashboardTabSubroutes.TIMESHEETS,
	},
	{
		id: '5',
		title: 'Expenses',
		component: TravelExpensesDashboard,
		route: DashboardTabSubroutes.TRAVEL_EXPENSES,
	},
];
