import { ColumnContainer } from 'components/Layout';
import { TableMatrix } from 'components/Table';
import { SmartItem, SmartContainer } from 'components/SmartContainer/SmartContainer';
import { ImpactEnum, ImpactResponse, PriorityEnum, PriorityResponse, UrgencyEnum, UrgencyResponse } from 'services/tenantManagementService';
import { getNameBySemantic } from 'features/Semantics/semanticsHelper';

type Props = {
	impacts: ImpactResponse[]
	urgencies: UrgencyResponse[]
	priorities: PriorityResponse[]
}

const PriorityInfoTable = ({ impacts, urgencies, priorities }: Props) => {
	return (
		<SmartContainer>
			<SmartItem size='small'>
				<ColumnContainer margin='medium'>
					<p>Mavles comes with predefined matrix for defining ticket priority.</p>
					<p>User is allowed to change impact and urgency description.</p>
				</ColumnContainer>
			</SmartItem>
			<SmartItem size='medium'>
				<TableMatrix
					headers={[
						'Impact',
						getNameBySemantic(ImpactEnum.High, impacts) || 'High',
						getNameBySemantic(ImpactEnum.Medium, impacts) || 'Medium',
						getNameBySemantic(ImpactEnum.Low, impacts) || 'Low'
					]}
					rowHeaders={[
						'Urgency',
						getNameBySemantic(UrgencyEnum.Urgent, urgencies) || 'Urgent',
						getNameBySemantic(UrgencyEnum.Normal, urgencies) || 'Normal'
					]}
					data={[
						[
							getNameBySemantic(PriorityEnum.Critical, priorities) || 'Critical',
							getNameBySemantic(PriorityEnum.High, priorities) || 'High',
							getNameBySemantic(PriorityEnum.Medium, priorities) || 'Medium'
						],
						[
							getNameBySemantic(PriorityEnum.High, priorities) || 'High',
							getNameBySemantic(PriorityEnum.Medium, priorities) || 'Medium',
							getNameBySemantic(PriorityEnum.Low, priorities) || 'Low'
						]
					]}
				/>
			</SmartItem>
		</SmartContainer>
	)
}

export default PriorityInfoTable;
