import { useCallback, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { changeMyPasswordAction } from '../../action';
import notifications from 'components/Notification/notification';
import { Form, InputField } from 'components/Form';
import { ChangePasswordRequestModel } from 'services/tenantManagementService';

class Model {
	oldPassword!: string
	newPassword!: string
	confirmPassword?: string
}

type Props = {
	cancel(): void
}

const ChangePasswordForm = ({ cancel }: Props) => {
	const [values, setValues] = useState(new Model());

	const onSubmitCallback = useCallback(
		async () => {
			const model = new ChangePasswordRequestModel(values);
			const bindedAction = changeMyPasswordAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Password is changed.');
				cancel();
			} else {
				return convertResponseErrors(response);
			}
		},
		[cancel, values]
	);

	const validator = useCallback(
		(value: string, currentValues: Model, id: string) => {
			if (value !== currentValues.newPassword) {
				return 'Passwords are not the same';
			} else {
				return '';
			}
		},
		[]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<InputField
							type='password'
							id='oldPassword'
							label='Old password'
							isRequired
						/>
						<InputField
							type='password'
							id='newPassword'
							label='New password'
							isRequired
						/>
						<InputField
							type='password'
							id='confirmPassword'
							label='Confirm new password'
							isRequired
							validator={validator}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	);
};

export default ChangePasswordForm;
