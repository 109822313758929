import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud';
import CreateTeamMember from './Crud/CreateTeamMember';
import UpdateTeamMember from './Crud/UpdateTeamMember';
import { MaintainProjectSubRoutes } from '../../MyProjectsMaintainSubroutes';

type Props = {
	publishDataChanged: () => void
}

const AssignTeamMemberRouter = ({ publishDataChanged }: Props) => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${MaintainProjectSubRoutes.TEAM_MEMBERS}/${CrudSubRoutesEnum.Create}`}
				render={() => <CreateTeamMember publishDataChanged={publishDataChanged} /> }
			/>
			<Route
				path={`${path}/${MaintainProjectSubRoutes.TEAM_MEMBERS}/${CrudSubRoutesEnum.Update}/:teamMemberId`}
				render={() => <UpdateTeamMember publishDataChanged={publishDataChanged} />}
			/>
		</Switch>
	)
}

export default AssignTeamMemberRouter;
