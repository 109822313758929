import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTableColumnsMemo } from './Table/tableColumns';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import { ProjectTeamMemberUserResponse, TenantIsActiveResponse, TenantIsActiveResponseItemsResponseModel } from 'services/tenantManagementService';
import { deleteTeamMemberAction, exportProjectTeamMembersByProjectAction, persistTeamMembersAction } from './action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import TableButtons from './Table/TableButtons';
import notifications from 'components/Notification/notification';
import AssignTeamMemberRouter from './AssignTeamMemberRouter';
import { getProjectTeamsAction } from '../Teams/action';
import { getProjectRolesAction } from '../Roles/action';
import { useMemo } from 'react';
import { ProjectPickerParams } from 'features/Project';
import WithFetch from 'features/Fetch/WithFetch';
import { LocalTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { useSubscriptionMemo } from 'features/Crud/CrudRouter';
import { Note } from 'components/Note/Note';
import { getAllTeamMemberUsersAction } from 'containers/Communication/ContactList/action';

const configureViewKey = 'assign_team_members';

const AssignTeamMembers = () => {
	const [selectedTeamMember, setSelectedTeamMember] = useState(new ProjectTeamMemberUserResponse());
	const [teamMemberUsers, setTeamMemberUsers] = useState<ProjectTeamMemberUserResponse[]>([]);
	const [refetching, setRefetching] = useState(false);

	const { dataChangedTopic, publishDataChanged } = useSubscriptionMemo();

	const [isDeleting, setIsDeleting] = useState(false);

	const [teams, setTeams] = useState<TenantIsActiveResponse[]>([]);
	const [roles, setRoles] = useState<TenantIsActiveResponse[]>([]);

	const params: ProjectPickerParams = useParams();
	const projectId = parseInt(params.projectId as string);

	const { persistedConfigureView } = useSelector((state: RootState) => state);

	const tableColumns = useTableColumnsMemo(teams, roles, persistedConfigureView.value[configureViewKey]);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getAllTeamMemberUsersAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setTeamMemberUsers(response.items || [])
			}
		},
		[projectId]
	)

	const refetchDataCallback = useCallback(
		async () => {
			setRefetching(true);
			await fetchDataCallback();
			setRefetching(false);
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			const subscription = dataChangedTopic?.subscribe(refetchDataCallback);
			return () => {
				subscription.unsubscribe();
			}
		},
		[refetchDataCallback, dataChangedTopic]
	)

	useEffect(
		() => {
			const fetchData = async () => {
				const [teamsResponse, rolesResponse] = await Promise.all<TenantIsActiveResponseItemsResponseModel>(
					[
						tryCatchJsonByAction(getProjectTeamsAction.bind(null, projectId)),
						tryCatchJsonByAction(getProjectRolesAction.bind(null, projectId))
					]
				)

				if (teamsResponse.success) {
					setTeams(teamsResponse.items?.filter(item => item.isActive) || []);
				}
				if (rolesResponse.success) {
					setRoles(rolesResponse.items?.filter(item => item.isActive) || []);
				}
			}

			fetchData();
		},
		[projectId]
	)

	const handleDelete = useCallback(
		async (id: number) => {
			setIsDeleting(true);
			const bindedAction = deleteTeamMemberAction.bind(null, projectId, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`User ${selectedTeamMember.fullName} is removed from list of team members.`);
				persistTeamMembersAction(projectId);
				publishDataChanged();
			}
			setIsDeleting(false);
		},
		[projectId, selectedTeamMember.fullName, publishDataChanged]
	);

	const handleRowSelectionChange = useCallback(
		(data: ProjectTeamMemberUserResponse[]) => {
			setSelectedTeamMember(data[0] || new ProjectTeamMemberUserResponse());
		},
		[]
	);

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	const memoExportFunction = useMemo(
		() => exportProjectTeamMembersByProjectAction.bind(null, projectId),
		[projectId]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback} refetching={refetching || isDeleting}>
			<ColumnContainer margin='medium'>
				<Note text='Prior to this steps, users have to be created.' />
				<TableButtons
					selectedId={selectedTeamMember.id}
					onDelete={handleDelete}
					tableColumns={tableColumns}
					exportFunction={memoExportFunction}
					configureViewKey={configureViewKey}
				/>
				<LocalTable
					columns={tableColumns}
					data={teamMemberUsers}
					reorderColumns={reorderColumnsCallback}
					rowSelectionChanged={handleRowSelectionChange}
					hasPagination
				/>
			</ColumnContainer>
			<AssignTeamMemberRouter publishDataChanged={publishDataChanged} />
		</WithFetch>
	);
};

export default AssignTeamMembers;
