import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ColumnContainer } from 'components/Layout';
import WithFetch from 'features/Fetch/WithFetch';
import { TestPlanResponse } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import Export from 'containers/Testing/MyTestPackages/MyTest/Export/Export';
import { MyTestForm } from 'containers/Testing/MyTestPackages/MyTest/MyTestForm';
import { CrudEnum } from 'features/Crud';
import { getTestPlanAction } from 'containers/Testing/MyTestPackages/MyTest/action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

type Props = {
	projectId: number
	testCycleId: number
	testPlanId: number
}

export const ViewTestCase = ({ projectId, testCycleId, testPlanId }: Props) => {
	const [testPlan, setTestPlan] = useState(new TestPlanResponse());

	const { persistedUser, persistedTestCaseStatus } = useSelector((state: RootState) => state);

	const fetchTestPlanCallback = useCallback(
		async () => {
			const bindedAction = getTestPlanAction.bind(null, projectId, testCycleId, testPlanId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				const testPlanResponse = response.value;
				setTestPlan(testPlanResponse);
			}
		},
		[testPlanId, projectId, testCycleId]
	)

	return (
		<WithFetch fetchFunction={fetchTestPlanCallback}>
			<ColumnContainer>
				<Export
					persistedUser={persistedUser}
					persistedTestCaseStatus={persistedTestCaseStatus}
					projectId={projectId}
					testCycleId={testCycleId}
					testPlan={testPlan}
				/>
				<MyTestForm
					projectId={projectId}
					testPlan={testPlan}
					crud={CrudEnum.Read}
				/>
			</ColumnContainer>
		</WithFetch>
	)
}

