import { useCallback } from 'react';
import { Dialog } from 'components/Dialog';
import { ColumnContainer, RowContainer } from 'components/Layout';
import Button from 'components/Button';

type Props = {
	value: number | undefined
	onConfirm: (value: number) => void
	onCancel: () => void
}

export const TravelRequestChangeGuard = ({ value, onConfirm, onCancel }: Props) => {
	const handleConfirmCallback = useCallback(
		() => {
			onConfirm(value!)
		},
		[value, onConfirm]
	)

	return (
		<>
			<Dialog
				title='Travel request changed'
				onClose={onCancel}
				open={!!value}
			>
				<ColumnContainer>
					<div>There are some changes that may be lost. Are you sure you want to change travel request?</div>
					<RowContainer justifyContent='flex-end'>
						<Button
							text='Yes'
							onClick={handleConfirmCallback}
						/>
						<Button
							text='No'
							onClick={onCancel}
						/>
					</RowContainer>
				</ColumnContainer>
			</Dialog>
		</>
	)
}
