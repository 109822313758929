import { useCallback, useState } from 'react';
import styles from 'components/Map/map.module.scss';
import { TicketCategoryResponse, UpdateTicketCategoryRequest } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import { EditIcon } from 'components/icons/icons';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { updateTicketCategoryAction } from '../action';
import { CategoryItemForm } from './CategoryItemForm';

type Props = {
	item: TicketCategoryResponse
	fetchData(): Promise<void>
}

export const UpdateItem = ({ item, fetchData }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openDialogCallback = useCallback(
		(e) => {
			e.stopPropagation();
			setIsModalOpen(true);
		},
		[]
	);

	const closeDialogCallback = useCallback(
		() => {
			setIsModalOpen(false);
		},
		[]
	);

	const updateCallback = useCallback(
		async (newItem: TicketCategoryResponse) => {
			const model = new UpdateTicketCategoryRequest(newItem);

			const bindedAction = updateTicketCategoryAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Ticket category item is updated');
				closeDialogCallback();
				await fetchData();
			} else {
				return convertResponseErrors(response);
			}
		},
		[fetchData, closeDialogCallback]
	)

	return (
		<div className={styles.item_edit}>
			<EditIcon width={12} height={12} fill='currentColor' onClick={openDialogCallback} />
			<Dialog
				open={isModalOpen}
				title={`Update ${item.name} item`}
				onClose={closeDialogCallback}
			>
				<CategoryItemForm
					item={item}
					onSave={updateCallback}
					onCancel={closeDialogCallback}
				/>
			</Dialog>
		</div>
	)
}
