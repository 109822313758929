import { RegisterClient, TeamSizeClient, TenantRegistrationRequestModel } from 'services/tenantManagementService';

export const getTeamSizeAction = () => {
	const client = new TeamSizeClient();
	return client.getTeamSizes();
}

export const registerAction = (tenant: TenantRegistrationRequestModel ) => {
	const client = new RegisterClient();
	return client.registerTenant(tenant);
}
