import { getFilterFormModelConfigAction, updateFilterFormModelConfigAction } from './action'
import notifications from 'components/Notification/notification'
import { useCallback, useState } from 'react'
import { tryCatchJsonByAction } from 'utils/fetchUtils'

export const useSaveFilterMemo = <P extends Object>(viewType: string, jsConverter?: (obj: any) => P ) => {
	const [isLoading, setIsLoading] = useState(false);
	const [filterModel, setFilterModel] = useState<P>();

	const saveFilterModelCallback = useCallback(
		async (newFilterModel: P) => {
			setIsLoading(true);

			const bindedAction = updateFilterFormModelConfigAction.bind(null, viewType, newFilterModel);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				const model = jsConverter ? jsConverter(response.value.content) : response.value.content;
				setFilterModel(model);
				notifications.success('Filters successfully saved');
			}

			setIsLoading(false);
		},
		[viewType, jsConverter]
	)

	const fetchFilterModelCallback = useCallback(
		async () => {
			const bindedAction = getFilterFormModelConfigAction.bind(null, viewType);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				const model = jsConverter ? jsConverter(response.value.content) : response.value.content;
				setFilterModel(model);
			}
			return undefined;
		},
		[viewType, jsConverter]
	)

	return {
		savedFilterModel: filterModel,
		saveFilterModel: saveFilterModelCallback,
		fetchFilterModel: fetchFilterModelCallback,
		isLoadingFilter: isLoading
	}
}
