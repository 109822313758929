import { getTenantId } from 'features/Tenant/tenantUtils';
import { CommunicationConfigClient, UpdateTaskGeneralConfigRequest } from 'services/tenantManagementService';

export const getTaskGeneralConfigAction = () => {
	const tenantId = getTenantId()!;
	const client = new CommunicationConfigClient();
	return client.getTaskGeneralConfig(tenantId);
}

export const updateTaskGeneralConfigAction = (model: UpdateTaskGeneralConfigRequest) => {
	const tenantId = getTenantId()!;
	const client = new CommunicationConfigClient();
	return client.updateTaskGeneralConfig(tenantId, model);
}
