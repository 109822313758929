import { getTenantId } from 'features/Tenant/tenantUtils';
import { ScheduleDashboardClient, ScheduleDashboardFilterRequest, ScheduleGanttDashboardFilterRequest, ScheduleProjectUtilizationDashboardFilterRequest, ScheduleUtilizationDashboardFilterRequest } from 'services/tenantManagementService';

export const getScheduleDashboardProjectAction = (filterRequest: ScheduleDashboardFilterRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleDashboardClient();
	return client.get(tenantId, filterRequest);
}

export const getScheduleDashboardGanttAction = (filterRequest: ScheduleGanttDashboardFilterRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleDashboardClient();
	return client.getGantt(tenantId, filterRequest);
}

export const getScheduleDashboardUtilizationAction = (filterRequest: ScheduleUtilizationDashboardFilterRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleDashboardClient();
	return client.getUtilization(tenantId, filterRequest);
}

export const getScheduleDashboardProjectUtilizationAction = (filterRequest: ScheduleProjectUtilizationDashboardFilterRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleDashboardClient();
	return client.getProjectUtilization(tenantId, filterRequest);
}
