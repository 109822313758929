import { useMemo } from 'react';
import { ProjectIsActiveResponse, StatusReportResponse, StatusReportStatusResponse, UserModel } from 'services/tenantManagementService';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { useApplyStatusColorCallback, useFormattedProjectIdNameCallback, useStatusCallback, useTenantIsActiveCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { getContrastingColor } from 'utils/colorHelper';
import { overalStatusColors } from '../Crud/StatusReportForm';

const defaultVisibleColumns = [
	propertyOf<StatusReportResponse>('id'),
	propertyOf<StatusReportResponse>('projectTeamId'),
	propertyOf<StatusReportResponse>('reportDate'),
	propertyOf<StatusReportResponse>('createdByUserId'),
	propertyOf<StatusReportResponse>('statusId'),
	propertyOf<StatusReportResponse>('alignedByUserId'),
	propertyOf<StatusReportResponse>('overallStatus'),
]

export const useTableColumnsMemo = (configureViewKey: string) => {
	const {
		persistedUser,
		persistedConfigureView,
		persistedProjectTeam,
		persistedCommunicationStatusReport,
		persistedTimeTravelNonProjectCategories,
		persistedProject
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getProjectTeamName = useTenantIsActiveCallback(persistedProjectTeam);
	const getStatus = useStatusCallback(persistedCommunicationStatusReport);
	const applyStatusColor = useApplyStatusColorCallback(persistedCommunicationStatusReport);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<StatusReportResponse>('projectOrCategoryId')]: {
					title: 'Project or category',
					formatter: (cell: any) => {
						const isProject = (cell.getData() as StatusReportResponse).isProjectConnected;
						if (isProject) {
							return formatProjectIdName(cell.getValue());
						}
						return persistedTimeTravelNonProjectCategories.itemsMap[cell.getValue()]?.name || '';
					},
					fieldType: FieldTypeEnum.None
				},
				[propertyOf<StatusReportResponse>('projectTeamId')]: {
					title: 'Project Team',
					formatter: (cell: any) => getProjectTeamName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedProjectTeam.items,
					getItemId: (option: ProjectIsActiveResponse) => option.id,
					getItemText: (option: ProjectIsActiveResponse) => getProjectTeamName(option.id),
					dbFilterFieldPath: 'ProjectTeamRefId',
					dbExportFieldPath: 'ProjectTeamRefId',
				},
				[propertyOf<StatusReportResponse>('reportDate')]: {
					title: 'Reporting date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<StatusReportResponse>('responsibleUserId')]: {
					title: 'Approver',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
				},
				[propertyOf<StatusReportResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedCommunicationStatusReport.items,
					getItemId: (option: StatusReportStatusResponse) => option.id,
					getItemText: (option: StatusReportStatusResponse) => option.name,
					dbFilterFieldPath: 'StatusRefId',
					dbExportFieldPath: 'StatusRefId'
				},
				[propertyOf<StatusReportResponse>('releasedByUserId')]: {
					title: 'Released by',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
				},
				[propertyOf<StatusReportResponse>('alignedByUserId')]: {
					title: 'Aligned by',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
				},
				[propertyOf<StatusReportResponse>('createdByUserId')]: {
					title: 'Created by',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
				},
				[propertyOf<StatusReportResponse>('overallStatus')]: {
					title: 'Overall status',
					formatter: (cell:any) => {
						const value = cell.getValue();
						const element = cell.getElement();

						const overalStatusColor = overalStatusColors.find((item) => item.id === value);
						const color = overalStatusColor?.color;
						element.style.backgroundColor = color + 'cc';
						element.style.color = getContrastingColor(color);
						return '';
					},
					fieldType: FieldTypeEnum.Semaphore,
					options: overalStatusColors
				},
				[propertyOf<StatusReportResponse>('id')]: {
					title: 'Report Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.STATUS_REPORT
				},
			}
		},
		[persistedProjectTeam, persistedUser, formatProjectIdName, getUserFullName, getProjectTeamName, persistedCommunicationStatusReport, getStatus, applyStatusColor, persistedTimeTravelNonProjectCategories]
	)

	return useVisibleColumns(columns, visibleColumns);
}
