import { AutoCompleteField, Form, TextareaField } from "components/Form"
import { useCallback, useState } from "react"
import { TenantIsActiveResponse, TicketResponse } from "services/tenantManagementService"
import { SmartContainer, SmartItem } from "components/SmartContainer/SmartContainer"
import { useSelector } from "react-redux"
import { RootState } from "base/reducer/reducer"
import { propertyOf } from "utils/propertyOf"

type Props = {
	ticket: TicketResponse
	onSave?: (ticket: TicketResponse, isInitiateChangeRequest?: boolean) => void
	onCancel(): void
}

export const ClosingForm = ({ ticket, onSave, onCancel }: Props) => {
	const [values, setValues] = useState(ticket);

	const {	persistedTicketClosingId } = useSelector((state: RootState) => state);

	const onSubmitCallback = useCallback(
		async () => {
			const newValue = new TicketResponse({
				...ticket,
				closingId: values.closingId,
				closingNote: values.closingNote,
				workaround: values.workaround
			});

			return onSave && await onSave(newValue, false);
		},
		[onSave, values, ticket]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={onCancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<AutoCompleteField
							id={propertyOf<TicketResponse>('closingId')}
							label='Closing ID'
							items={persistedTicketClosingId.activeItems}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							getItemDescription={(item: TenantIsActiveResponse) => item.description}
							loading={persistedTicketClosingId.fetching}
							isRequired
						/>
						<TextareaField
							id={propertyOf<TicketResponse>('closingNote')}
							label='Closing note'
							rows={3}
						/>
						<TextareaField
							id={propertyOf<TicketResponse>('workaround')}
							label='Workaround'
							maxLength={1000}
							rows={8}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
