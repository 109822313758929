import { getProjectRolesAction, updateProjectRolesAction } from './action';
import { ProjectResponse } from 'services/tenantManagementService';
import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';

type Props = {
	project: ProjectResponse
};

const Roles = ({ project }: Props) => {
	return (
		<TenantIsActiveTable
			getConfigsAction={getProjectRolesAction.bind(null, project.id)}
			updateConfigsAction={updateProjectRolesAction.bind(null, project.id)}
			successMessage='Project roles are updated.'
			label={{ name: 'Role Name', description: 'Role Description' }}
		/>
	)
};

export default Roles;
