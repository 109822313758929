import { getTenantId } from 'features/Tenant/tenantUtils';
import { CalendarClient, InsertOrUpdateCalendarRequest } from 'services/tenantManagementService';

export const getCalendarAction = (countryId: number) => {
	const tenantId = getTenantId()!;
	const client = new CalendarClient();
	return client.get(tenantId, countryId);
}

export const updateCalendarAction = (model: InsertOrUpdateCalendarRequest) => {
	const tenantId = getTenantId()!;
	const client = new CalendarClient();
	return client.update(tenantId, model);
}
