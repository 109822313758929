import { useMemo } from 'react';
import { WithProjectPicker, ComponentProps } from 'features/Project';
import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { getTrainingCyclesAction, updateTrainingCyclesAction } from './actions';
import { ContentShell } from 'features/Content/ContentShell';
import { TrainingCyclesHelp } from './Help/TrainingCyclesHelp';
import { ModuleActivityEnum } from 'services/tenantManagementService';

const TrainingCycles = ({ project, disabledEdit }: ComponentProps) => {
	const getCyclesActionMemo = useMemo(
		() => getTrainingCyclesAction.bind(null, project.id),
		[project.id]
	)

	const updateCyclesActionMemo = useMemo(
		() => updateTrainingCyclesAction.bind(null, project.id),
		[project.id]
	)

	const disabledUpdateMemo = useMemo(
		() => {
			return disabledEdit || !(isUserPmorSubstitutePmOrSiteAdmin(project.roleId) || project.permissions?.trainingPermission?.createTrainingCycle);
		},
		[project, disabledEdit]
	)

	return (
		<ContentShell
			title='Training Cycles'
			FloatingHelpComponent={TrainingCyclesHelp}
		>
			<TenantIsActiveTable
				getConfigsAction={getCyclesActionMemo}
				updateConfigsAction={updateCyclesActionMemo}
				successMessage='Training cycles are updated.'
				label={{ name: 'Cycle name', description: 'Explanation' }}
				disabled={disabledUpdateMemo}
			/>
		</ContentShell>
	)
}

export default WithProjectPicker(TrainingCycles, ModuleActivityEnum.Training, true);
