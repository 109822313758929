const privacyPolicyUrl = 'https://mavles.com/privacy-policy/'
const termsAndConditionsUrl = 'https://mavles.com/terms/'

export const TermsOfUseAndPrivacyPolicy = () => {
	return (
		<>
			<span style={{alignSelf: 'center'}}>
				I agree with Mavles&nbsp;
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a
					href={termsAndConditionsUrl}
					target='_blank'
					rel='noreferrer'
					style={{ color: '#007bff' }}
				>
					Terms and Conditions
				</a>
				&nbsp;and confirm that I familiarized myself with&nbsp;
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a
					href={privacyPolicyUrl}
					target='_blank'
					rel='noreferrer'
					style={{ color: '#007bff' }}
				>
					Privacy Policy
				</a>
			</span>
		</>
	)
}
