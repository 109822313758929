import { LocalTable, LocalTableProps } from "components/Table";
import { Widget, WidgetProps } from "./Widget"

type Props = WidgetProps & LocalTableProps;

export const LocalTableWidget = (props: Props) => {
	return (
		<Widget
			{...props}
		>
			<LocalTable {...props} compact />
		</Widget>
	)
}
