import { useCallback, useState } from 'react';
import { ColumnContainer, RowContainer } from 'components/Layout';
import Button from 'components/Button';
import { Form,  TextareaField } from 'components/Form';
import { TestPlanResponse } from 'services/tenantManagementService';

type Props = {
	testPlan: TestPlanResponse
	saveOnly(testPlan: TestPlanResponse): void
	saveAndCreateTicket(testPlan: TestPlanResponse): void
	cancel(): void
}

export const FailedTestsForm = ({
	testPlan,
	saveOnly,
	saveAndCreateTicket,
	cancel
}: Props) => {
	const [values, setValues] = useState(testPlan || new TestPlanResponse());

	const saveAndCreateTicketCallback = useCallback(
		() => {
			saveAndCreateTicket(values)
		},
		[saveAndCreateTicket, values]
	)

	const saveOnlyCallback = useCallback(
		() => {
			saveOnly(values)
		},
		[saveOnly, values]
	)

	return (
		<Form
			values={values}
			initialValues={testPlan}
			onChange={setValues}
			onCancel={cancel}
			hideSubmitButton
			disableUnsavedChangesGuard
			render={() => (
				<ColumnContainer margin='medium'>
					<p>Please enter comment for failed test cases:</p>
					<TextareaField
						id='comments'
						isRequired
						maxLength={1000}
					/>
				</ColumnContainer>
			)}
			renderAdditionalButtons={(disabled: boolean, handleSubmitCallback: () => void, isSubmitting) => (
				<RowContainer>
					<Button
						text='Save and create ticket'
						disabled={disabled}
						isLoading={isSubmitting}
						onClick={saveAndCreateTicketCallback}
					/>
					<Button
						text='Save only'
						disabled={disabled}
						isLoading={isSubmitting}
						onClick={saveOnlyCallback}
					/>
				</RowContainer>
			)}
		/>
	)
}
