import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { BaseColumnModel } from 'components/Table';
import { Export } from 'features/Export/Export';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';

type Props = {
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	exportFunction: (model: ExportDataModel) => Promise<FileResponse>
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

export const TableButtons = ({ configureViewKey, tableColumns, exportFunction }: Props) => {
	return (
		<RowContainer justifyContent='flex-end'>
			<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
			<Export
					tableColumns={tableColumns}
					exportAction={exportFunction}
					exportTypeOptions={EXPORT_TYPE_OPTIONS}
					title='Schedule'
					filtersModel={new GenericFilterModelCollection()}
					hideApplyFilters
					hideApplySort
					hideUseOnlyVisible
					hideExportOnlyCurrentPage
				/>
		</RowContainer>
	)
}
