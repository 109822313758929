import { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import { useSelector } from 'react-redux'
import { RootState } from 'base/reducer/reducer'
import { ThemeEnum } from 'features/Theme'
import { colorsPallete, errorColor } from 'features/ThemeProvider/amchartCustomTheme'
import { createLegend } from './helpers/legend'

export type PieChartProps = {
	id: string
	fieldValue: string
	fieldCategory: string
	data: any[]
	showLegend?: boolean
	useValueForLegend?: boolean
	usePercentAndValueForLegend?: boolean
}

const legendValueFormatter = "{value.value.formatNumber('#,###.00')}";
const legendPercentFormatter = "{value.percent.formatNumber('#.0')}%";
const legendPercentAndValueFormatter = `${legendPercentFormatter} ({value.value.formatNumber('#,###.')})`;

export const PieChart = ({ id, fieldValue, fieldCategory, data, showLegend = true, useValueForLegend, usePercentAndValueForLegend }: PieChartProps) => {
	const theme = useSelector((state: RootState) => state.theme);

	useEffect(
		() => {
			const sortedData = data.sort((a, b) => b[fieldValue] - a[fieldValue])
			for (let i = 0; i < sortedData.length; i++) {
				sortedData[i].color = sortedData[i].color || colorsPallete[i]
			}

			let chart = am4core.create(id, am4charts.PieChart);
			chart.data = sortedData;
			if (showLegend) {
				chart.radius = am4core.percent(90);
				chart.legend = createLegend(theme, true);
			} else {
				chart.radius = am4core.percent(80);
			}
			// donut
			chart.innerRadius = am4core.percent(65);

			let pieSeries = chart.series.push(new am4charts.PieSeries());
			pieSeries.slices.template.strokeWidth = 0;
			pieSeries.slices.template.cornerRadius = 0;

			// data fields
			pieSeries.dataFields.value = fieldValue;
			pieSeries.dataFields.category = fieldCategory;
			pieSeries.dataFields.hidden = 'hidden';

			// tooltip
			pieSeries.slices.template.tooltipText = "{category}: [bold]{value.percent.formatNumber('#.0')}% ({value.value.formatNumber('#,###.##')})";

			if (showLegend) {
				pieSeries.labels.template.disabled = true;
				pieSeries.legendSettings.valueText = useValueForLegend ? legendValueFormatter : usePercentAndValueForLegend ? legendPercentAndValueFormatter : legendPercentFormatter;
			} else {
				pieSeries.labels.template.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
				pieSeries.labels.template.fontSize = 11;
				pieSeries.labels.template.maxWidth = 110;
				pieSeries.labels.template.wrap = true;
				pieSeries.labels.template.text = `{name} [bold {color}]${useValueForLegend ? legendValueFormatter : usePercentAndValueForLegend ? legendPercentAndValueFormatter : legendPercentFormatter}%`;

				pieSeries.labels.template.events.on("ready", hideSmall);
				pieSeries.labels.template.events.on("visibilitychanged", hideSmall);
			}

			pieSeries.slices.template.adapter.add("hidden", hideSmallAdapter);

			pieSeries.hiddenState.properties.endAngle = -90;
			pieSeries.defaultState.transitionDuration = 1000;

			function hideSmallAdapter(hidden: any, target: any) {
				if (target.dataItem.values.value.value < 0) {
					target.dataItem.legendSettings = {
						...target.dataItem.legendSettings,
						labelText: `[${errorColor}]{name}[/]`,
						valueText: `[${errorColor}]${useValueForLegend ? legendValueFormatter : usePercentAndValueForLegend ? legendPercentAndValueFormatter : legendPercentFormatter}[/]`
					}
				}

				return target.dataItem.values.value.percent <= 0 ? true : false;
			}

			function hideSmall(ev: any) {
				if (ev.target.dataItem && (ev.target.dataItem.values.value.percent <= 0)) {
					ev.target.hide();
				}
				else {
					ev.target.show();
				}
			}

			// color statuses by status colors
			pieSeries.slices.template.propertyFields.fill = 'color';
			pieSeries.ticks.template.disabled = true;
		},
		[id, fieldValue, fieldCategory, data, theme, showLegend, useValueForLegend, usePercentAndValueForLegend]
	);

	return <div key={id} id={id} style={{ flex: 1 }} />
}
