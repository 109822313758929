import { useCallback, useEffect, useMemo, useState } from 'react';
import { ContentShell } from 'features/Content/ContentShell'
import { TimeFrequency, TimesheetsConfigResponse, UpdateTimesheetsConfigRequest } from 'services/tenantManagementService';
import { TimesheetsGeneralHelp } from './TimesheetsGeneralHelp'
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTimesheetsGeneralConfigAction, updateTimesheetsGeneralConfigAction } from './action';
import notifications from 'components/Notification/notification';
import { CheckboxField, Form, FormGroup, SelectField, TimeSpanField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import WithFetch from 'features/Fetch/WithFetch';
import { propertyOf } from 'utils/propertyOf';

const approvalFrequencyOptions = [
	TimeFrequency.Daily,
	TimeFrequency.Weekly,
	TimeFrequency.Monthly,
]

export const TimesheetsGeneral = () => {
	const [values, setValues] = useState<TimesheetsConfigResponse>(new TimesheetsConfigResponse());
	const [initialValues, setInitialValues] = useState<TimesheetsConfigResponse>(new TimesheetsConfigResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getTimesheetsGeneralConfigAction);
			if (response.success && response.value) {
				setValues(new TimesheetsConfigResponse(response.value));
				setInitialValues(new TimesheetsConfigResponse(response.value));
			}
		},
		[]
	);

	useEffect(
		() => {
			if (!values.enableApprovalProcess) {
				setValues((state: any) => {
					return new TimesheetsConfigResponse({
						...state,
						enableEmailNotifications: false,
						notifyWhenSubmitted: false,
						notifyWhenApproved: false,
						notifyWhenRejected: false,
						requireCommentsWhenRejecting: false
					});
				});
			} else if (!values.notifyWhenApproved && !values.notifyWhenRejected && !values.notifyWhenSubmitted) {
				setValues((state: any) => {
					return new TimesheetsConfigResponse({
						...state,
						enableEmailNotifications: false
					});
				});
			} else {
				setValues((state: any) => {
					return new TimesheetsConfigResponse({ ...state });
				});
			}
		},
		[values.enableApprovalProcess, values.notifyWhenApproved, values.notifyWhenRejected, values.notifyWhenSubmitted]
	)

	const isDisabledMemo = useMemo(
		() => values.enableApprovalProcess === false,
		[values.enableApprovalProcess]
	);

	const isDisabledEmailNotificationsMemo = useMemo(
		() => {
			if (!values.notifyWhenApproved && !values.notifyWhenRejected && !values.notifyWhenSubmitted) {
				return true;
			} else {
				return false;
			}
		},
		[values.notifyWhenApproved, values.notifyWhenRejected, values.notifyWhenSubmitted]
	);

	const handleCancel = useCallback(
		() => setValues(new TimesheetsConfigResponse(initialValues)),
		[initialValues]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const updateRequest = new UpdateTimesheetsConfigRequest(values);

			const bindedAction = updateTimesheetsGeneralConfigAction.bind(null, updateRequest)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setInitialValues(new TimesheetsConfigResponse(values));
				notifications.success('Timesheets general configuration is updated.');
			} else {
				return convertResponseErrors(response);
			}
		},
		[values]
	)

	return (
		<ContentShell
			InlineHelpComponent={TimesheetsGeneralHelp}
			showBreadcrumb={false}
		>
			<WithFetch fetchFunction={fetchDataCallback} >
				<Form
					values={values}
					initialValues={initialValues}
					onChange={setValues}
					onSubmit={onSubmitCallback}
					onCancel={handleCancel}
					render={() => (
						<SmartContainer>
							<SmartItem>
								<FormGroup title='Approval'>
									<CheckboxField
										id={propertyOf<TimesheetsConfigResponse>('enableApprovalProcess')}
										labelBefore='Enable approval process'
									/>
									<SelectField
										id={propertyOf<TimesheetsConfigResponse>('approvalFrequency')}
										containsEmpty={false}
										label='Approval frequency'
										items={approvalFrequencyOptions}
										getItemId={(item: TimeFrequency) => item}
										getItemText={(item: TimeFrequency) => item}
									/>
									<TimeSpanField
										id={propertyOf<TimesheetsConfigResponse>('minimumRecord')}
										label='Minimum record'
									/>
									<TimeSpanField
										id={propertyOf<TimesheetsConfigResponse>('minimumWorkingHoursPerDay')}
										label='Minimum working hours per day'
									/>
									<CheckboxField
										id={propertyOf<TimesheetsConfigResponse>('allowOvertimeWeekendHolidayWork')}
										labelBefore='Allow overtime and weekend and holidays time recording (note: this will impact on your project costs)'
									/>
								</FormGroup>
							</SmartItem>
							<SmartItem>
								<FormGroup title='Notifications'>
									<CheckboxField
										id={propertyOf<TimesheetsConfigResponse>('enableEmailNotifications')}
										labelBefore='Enable email notifications in approval process'
										disabled={isDisabledMemo || isDisabledEmailNotificationsMemo}
									/>
									<CheckboxField
										id={propertyOf<TimesheetsConfigResponse>('notifyWhenSubmitted')}
										labelBefore='Notify Project Managers when timesheets are submitted'
										disabled={isDisabledMemo}
									/>
									<CheckboxField
										id={propertyOf<TimesheetsConfigResponse>('notifyWhenApproved')}
										labelBefore='Notify users when timesheets are approved'
										disabled={isDisabledMemo}
									/>
									<CheckboxField
										id={propertyOf<TimesheetsConfigResponse>('notifyWhenRejected')}
										labelBefore='Notify users when timesheets are rejected'
										disabled={isDisabledMemo}
									/>
									<CheckboxField
										id={propertyOf<TimesheetsConfigResponse>('requireCommentsWhenRejecting')}
										labelBefore='Require comments when rejecting timesheet'
										disabled={isDisabledMemo}
									/>
								</FormGroup>
							</SmartItem>
						</SmartContainer>
					)}
				/>
			</WithFetch>
		</ContentShell>
	)
}
