import { HelpShell } from 'features/Help/HelpShell';

const MyTimesheetsHelp = () => {
	return (
		<HelpShell title='My timesheets'>
			<p>Timesheets enables evidence of your working time on projects but also on non-project activities.</p>
			<p>
				Based on configuration made by your Site administrator, timesheets are approved by responsible project manager or by resource manager. <br />
				Timesheets are approved only for project activities. If users time was allocated on several projects, timesheets will be distributed to each project manager(substitute) for approval.
			</p>

			<div>
				<p>Based on configuration:</p>
				<ul>
					<li>Timesheets can be approved monthly, weekly or daily</li>
					<li>Minimum working hours per day can be required (timesheets that do not have minimum required hours per day cannot be released for approval)</li>
					<li>Overtime, weekend and holiday work can be allowed in time recording</li>
				</ul>
			</div>

			<p><b>Non-project categories</b></p>
			<p>
				You can record time related to non project activities, such as: administration tasks, presales, sick leave, vacation, etc. These are configured by your site administrator and time recorded on those activities is not subject of approval. <br />
				Vacations are recorded in timesheets upon approval from all project managers on projects where user is assigned and in status 'active'.
			</p>

			<p><b>Process flow:</b></p>
			<div>
				<ol>
					<li>
						Entries in timesheet are maintained daily. When time is entered, timesheets can be released for approval. For example, if in configuration, timesheets are approved on monthly basis, and minimum required number of recorded hours per day is 8, users will have to fill in timesheet for each working day in month with total daily sum of 8 hours before they will be able to release timesheet for approval. Application will validate if entries are in accordance with these settings. Date with entries below required number of hours will be coloured in red.
					</li>
					<li>After release, timesheets cannot be modified.</li>
					<li>Project or resource manager is approving or rejecting timesheet per user and per project.</li>
					<li>If timesheet is rejected, user can make modifications to timesheet and release it for approval again.</li>
				</ol>
			</div>

			<p>Timesheets can be exported in xls files for selected period if needed.</p>

			<div>
				<p>On time and travel dashboard page you can see reports related to your timesheets such as:</p>
				<ul>
					<li>Timesheet per status: created, released, approved</li>
					<li>Timesheet overview: number of hours recorded on each project</li>
					<li>List of not approved timesheets per project</li>
				</ul>
			</div>
		</HelpShell>
	)
}

export default MyTimesheetsHelp
