import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserModel, IdNameResponse, BusinessPartnerResponseModel } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { Form, FormGroup, InputField, DateField, RadioField, Input, MapSingleField, AutoCompleteField } from 'components/Form';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import ChangePassword from './ChangePassword/ChangePassword';
import { ColumnContainer } from 'components/Layout';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { propertyOf } from 'utils/propertyOf';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';
import { getUserInfo } from 'utils/storageUtils';

type Props = {
	user?: UserModel
	onSave?: (user: UserModel) => void
	crud: CrudEnum
	showPartner?: boolean
}

export const UserForm = ({ user, onSave, crud, showPartner }: Props) => {
	const {
		persistedCountry,
		persistedBusinessPartner,
		persistedOrganizationalUnit,
	} = useSelector((state: RootState) => state);

	const [initialValues, setInitialValues] = useState(user || new UserModel());
	const [values, setValues] = useState(user || new UserModel());
	const [initials, setInitials] = useState('');
	const goBackFromCrud = useGoBackFromCrud(crud);

	const onSubmitCallback = useCallback(
		async () => {
			const newUser = new UserModel(values);
			return onSave && await onSave(newUser);
		},
		[onSave, values]
	)

	useEffect(
		() => {
			if (user) {
				setValues(user);
				setInitialValues(user);
			}
		},
		[user]
	)

	useEffect(
		() => {
			const firstLetter = (values.firstName && values.firstName[0]) || '';
			const lastLetter = (values.lastName && values.lastName[0]) || '';
			const initials = `${firstLetter}${lastLetter}`;

			setInitials(initials);
		},
		[values.firstName, values.lastName]
	)

	useEffect(
		() => {
			if (values.isGuest) {
				setValues((state: any) => {
					return new UserModel({ ...state, organizationalUnitId: undefined });
				});
			} else {
				setValues((state: any) => {
					return new UserModel({ ...state, partnerId: undefined });
				});
			}
		},
		[values.isGuest]
	)

	return (
		<Form
			values={values}
			initialValues={initialValues}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			disabled={crud === CrudEnum.Read}
			hideButtons={crud === CrudEnum.Read}
			render={() => (
				<ColumnContainer>
					{showPartner &&
						<SmartContainer>
							<SmartItem>
								<RadioField
									id={propertyOf<UserModel>('isGuest')}
									label='User type'
									items={[
										{ id: false, text: 'Company user' },
										{ id: true, text: 'Guest' }
									]}
								/>
								{values.isGuest &&
									<AutoCompleteField
										id={propertyOf<UserModel>('partnerId')}
										label='Partner'
										isRequired
										items={persistedBusinessPartner.items}
										getItemId={(item: BusinessPartnerResponseModel) => item.id}
										getItemText={(item: BusinessPartnerResponseModel) => item.name}
										loading={persistedBusinessPartner.fetching}
									/>
								}
							</SmartItem>
						</SmartContainer>
					}
					<SmartContainer>
						<SmartItem>
							<FormGroup title='General'>
								<InputField
									id={propertyOf<UserModel>('firstName')}
									label='First Name'
									isRequired
								/>
								<InputField
									id={propertyOf<UserModel>('lastName')}
									label='Last Name'
									isRequired
								/>
								<SmartFormGroup label='Initials'>
									<Input
										value={initials}
										disabled
									/>
								</SmartFormGroup>
								<DateField
									id={propertyOf<UserModel>('birthDate')}
									label='Birth Date'
								/>
								{/* allow change of password only for currently logged user */}
								{crud === CrudEnum.Update && user?.id === getUserInfo().id &&
									<SmartFormGroup label='Password'>
										<ChangePassword />
									</SmartFormGroup>
								}
								<InputField
									id={propertyOf<UserModel>('username')}
									label='Username'
									disabled={crud === CrudEnum.Update}
									isRequired
								/>
							</FormGroup>
						</SmartItem>
						<SmartItem>
							<FormGroup title='Address'>
								<InputField
									id={propertyOf<UserModel>('address')}
									label='Address'
								/>
								<InputField
									id={propertyOf<UserModel>('city')}
									label='City'
								/>
								<AutoCompleteField
									id={propertyOf<UserModel>('countryId')}
									label='Country'
									isRequired
									items={persistedCountry.items}
									getItemId={(item: IdNameResponse) => item.id}
									getItemText={(item: IdNameResponse) => item.name || ''}
									loading={persistedCountry.fetching}
								/>
							</FormGroup>
						</SmartItem>
						<SmartItem>
							<FormGroup title='Contact'>
								<InputField
									id={propertyOf<UserModel>('email')}
									label='E-mail'
									isRequired
									isEmail
								/>
								<InputField
									id={propertyOf<UserModel>('officePhone')}
									label='Office Phone'
								/>
								<InputField
									id={propertyOf<UserModel>('mobilePhone')}
									label='Mobile Phone'
								/>
							</FormGroup>
						</SmartItem>
						{!values.isGuest &&
							<SmartItem>
								<FormGroup title='Organization'>
									<MapSingleField
										id={propertyOf<UserModel>('organizationalUnitId')}
										label='Organizational Unit'
										items={convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined)}
										loading={persistedOrganizationalUnit.fetching}
										isRequired
									/>
								</FormGroup>
							</SmartItem>
						}
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}
