import { View, Text, StyleSheet } from '@react-pdf/renderer';

type Props = {
	text: string
}

const ParagraphField = ({ text }: Props) => (
	<View style={styles.container}>
		<Text style={styles.text}>
			{text}
		</Text>
	</View>
);

export default ParagraphField;

const styles = StyleSheet.create({
	container: {
		paddingVertical: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	text: {
		fontSize: 11,
	}
});
