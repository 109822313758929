import { useCallback, useState, useMemo, useEffect } from 'react'
import { BellIcon } from 'components/icons/icons';
import { getCssVariableValue } from 'utils/cssVariablesUtils';
import { Badge } from 'components/Badge';
import { NotificationsPanel } from './Panel/NotificationsPanel';
import { AlertResponse } from 'services/tenantManagementService';
import { getAlertsAction, setSeenAllAlertsAction } from './action';
import { NotificationsProvider } from './SignalR/NotificationsProvider';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { useSignalREffects } from './SignalR/useSignalREffects';

type Props = {
	itemClassName: string
	activeClassName: string
}

export const Notifications = ({ itemClassName, activeClassName }: Props) => {
	const [panelVisible, setPanelVisible] = useState(false);
	const [notifications, setNotifications] = useState<AlertResponse[]>([]);

	const unseenNotificationsCount = useMemo(
		() => notifications.filter(x => !x.seen).length,
		[notifications]
	);

	useSignalREffects(setNotifications, unseenNotificationsCount);

	const fetchNotificationsCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getAlertsAction);
			if (response.success) {
				setNotifications(response.items || [])
			}
		},
		[]
	);

	useEffect(
		() => {
			fetchNotificationsCallback();
		},
		[fetchNotificationsCallback]
	);

	const toggleUserPanelCallback = useCallback(
		async () => {
			setPanelVisible((state) => {
				const isVisible = !state;
				if (isVisible && unseenNotificationsCount > 0) {
					tryCatchJsonByAction(setSeenAllAlertsAction)
				}

				return isVisible
			});
		},
		[unseenNotificationsCount]
	)

	const iconSize = getCssVariableValue('--shell-icon-size');

	return (
		<NotificationsProvider>
			<div
				className={panelVisible ? `${itemClassName} ${activeClassName}` : itemClassName}
				onClick={toggleUserPanelCallback}
			>
				<Badge count={unseenNotificationsCount}>
					<BellIcon width={iconSize} height={iconSize} fill='currentColor' />
				</Badge>
			</div>
			<NotificationsPanel
				visible={panelVisible}
				toggleVisible={toggleUserPanelCallback}
				notifications={notifications}
			/>
		</NotificationsProvider>
	)
}
