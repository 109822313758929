import { getTenantId } from 'features/Tenant/tenantUtils';
import { ProjectClient, ProjectIdRequest } from 'services/tenantManagementService';

export const getProjectAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.get(tenantId, id);
}

export const releaseProjectAction = (model: ProjectIdRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.releas(tenantId, model);
}

export const downloadAttachmentAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.downloadAttachment(tenantId, projectId, id);
}

export const removeAttachmentAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.removeAttachment(tenantId, projectId, id);
}
