import { ProjectActionClient, InsertProjectActionRequest, UpdateProjectActionRequest, GenericFilterModelCollection, ExportDataModel } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getActionsAction = (reportId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectActionClient();
	return client.getAll(tenantId, reportId, undefined, undefined, undefined, undefined);
}

export const getActionsGenericAction = (reportId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectActionClient();
	return client.getAllGenericFilterExample(tenantId, reportId, genericFilter);
}

export const createActionAction = (reportId: number, model: InsertProjectActionRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectActionClient();
	return client.create(tenantId, reportId, model);
}

export const updateActionAction = (reportId: number, model: UpdateProjectActionRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectActionClient();
	return client.update(tenantId, reportId, model);
}

export const getActionAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectActionClient();
	return client.get(tenantId, reportId, id);
}

export const deleteActionAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectActionClient();
	return client.delete(tenantId, reportId, id);
}

export const exportAction = (reportId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectActionClient();
	return client.export(tenantId, reportId, exportDataModel);
}
