import { RootState } from "base/reducer/reducer"
import { CheckboxField, DateField, Form, Input, TextEditorField, AttachmentField, AutoCompleteField } from "components/Form"
import { ColumnContainer, VerticalSeparator } from "components/Layout"
import notifications from "components/Notification/notification"
import { SmartContainer, SmartFormGroup, SmartItem } from "components/SmartContainer/SmartContainer"
import Tabs from "components/Tabs/Tabs"
import { getAllMeetingsAction } from "containers/Communication/Meetings/action"
import { getAllTeamMembersAction, persistTeamMembersAction } from "containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action"
import { persistProjectTeamsAction } from "containers/Projects/MyProjects/MaintainProject/Tabs/Teams/action"
import { getAllRiskAction } from "containers/Risk/RiskPlan/action"
import { getSimpleChangeRequestsAction } from "containers/Scope/ChangeRequests/action"
import { getAllScopeItemsForLevelAction } from "containers/Scope/MaintainScope/action"
import { getAllByProjectIdTicketsAction } from "containers/Tickets/ViewTickets/action"
import { CrudEnum } from "features/Crud"
import { getInitialStatus, getNextStatuses } from "features/StatusResponse/statusResponse"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { ChangeRequestResponse, InsertTaskRequest, MeetingResponse, ModuleActivityEnum, RiskResponse, ScopeItemLevelResponse, SimpleIdNameModel, SimpleScheduleResponse, TaskResponse, TaskStatusResponse, TicketResponse, UserModel } from "services/tenantManagementService"
import { EntityPrefixEnum, getFormatedId } from "utils/commonHelper"
import { tryCatchJsonByAction } from "utils/fetchUtils"
import { propertyOf } from "utils/propertyOf"
import { downloadAttachmentAction, getAllTasksAction, removeAttachmentAction } from "../action"
import { taskPriorities } from '../Table/tableColumns'
import { TaskTabComponentProps, taskTabs } from "../Tabs/taskTabs"
import { getAllSimpleSchedulesAction } from 'containers/Schedule/Schedule/action'
import { ProjectOrCategorySelect, ProjectOrCategoryType } from "features/Project"

type Props = {
	task?: TaskResponse
	crud: CrudEnum
	onSave?: (newTask: TaskResponse) => void
	cancel?(): void
}
export const TasksForm = ({
	task = new TaskResponse(),
	crud,
	onSave,
	cancel
}: Props) => {
	const {
		persistedUser,
		persistedProjectTeam,
		persistedTaskStatus,
		persistedTeamMember
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(task || new TaskResponse());
	const [initialValues, setInitialValues] = useState(task || new TaskResponse());

	const [projectOrCategory, setProjectOrCategory] = useState<ProjectOrCategoryType | undefined>({
		projectOrCategoryId: values.projectOrCategoryId,
		isProjectConnected: values.isProjectConnected
	});

	const [tickets, setTickets] = useState<TicketResponse[]>([]);
	const [changeRequests, setChangeRequests] = useState<SimpleIdNameModel[]>([]);
	const [risks, setRisks] = useState<RiskResponse[]>([]);
	const [tasks, setTasks] = useState<TaskResponse[]>([]);
	const [scopeItemsLevel3, setScopeItemsLevel3] = useState<ScopeItemLevelResponse[]>([]);
	const [meetings, setMeetings] = useState<MeetingResponse[]>([]);
	const [schedules, setSchedules] = useState<SimpleScheduleResponse[]>([]);
	const [loadingFetchData, setLoadingFetchData] = useState(true);
	const [projectOrCategoryHasError, setProjectOrCategoryHasError] = useState(false);

	useEffect(
		() => {
			const fetchData = async () => {
				if (!values.projectOrCategoryId) {
					setLoadingFetchData(false);
					return
				}

				setLoadingFetchData(true);

				const meetingsPromise = tryCatchJsonByAction(getAllMeetingsAction.bind(null, values.projectOrCategoryId, values.isProjectConnected));

				if (values.isProjectConnected) {
					const projectId = values.projectOrCategoryId;

					const [ticketsResponse, changeRequestResponse, risksResponse, tasksResponse, scopeResponse, meetingsResponse, schedulesResponse] = await Promise.all(
						[
							tryCatchJsonByAction(getAllByProjectIdTicketsAction.bind(null, projectId)),
							tryCatchJsonByAction(getSimpleChangeRequestsAction.bind(null, projectId)),
							tryCatchJsonByAction(getAllRiskAction.bind(null, projectId)),
							tryCatchJsonByAction(getAllTasksAction.bind(null, [projectId])),
							tryCatchJsonByAction(getAllScopeItemsForLevelAction.bind(null, projectId, 3)),
							meetingsPromise,
							tryCatchJsonByAction(getAllSimpleSchedulesAction.bind(null, projectId)),
						]
					)

					if (ticketsResponse.success) {
						setTickets(ticketsResponse.items || [])
					}

					if (changeRequestResponse.success) {
						setChangeRequests(changeRequestResponse.items || [])
					}

					if (risksResponse.success) {
						setRisks(risksResponse.items || [])
					}

					if (tasksResponse.success) {
						setTasks(tasksResponse.items || [])
					}

					if (scopeResponse.success) {
						setScopeItemsLevel3(scopeResponse.items || [])
					}

					if (meetingsResponse.success) {
						setMeetings(meetingsResponse.items || [])
					}
					if (schedulesResponse.success) {
						setSchedules(schedulesResponse.items || [])
					}
				} else {
					const [tasksResponse, meetingsResponse] = await Promise.all(
						[
							tryCatchJsonByAction(getAllTasksAction.bind(null, [], [values.projectOrCategoryId])),
							meetingsPromise
						]
					)

					if (tasksResponse.success) {
						setTasks(tasksResponse.items || [])
					}

					if (meetingsResponse.success) {
						setMeetings(meetingsResponse.items || [])
					}
				}

				setLoadingFetchData(false);
			}

			fetchData();
		},
		[values.isProjectConnected, values.projectOrCategoryId]
	)

	const [users, setUsers] = useState<UserModel[]>([]);
	const [fetchingUsers, setFetchingUsers] = useState(false);

	const fetchUsersCallback = useCallback(
		async () => {
			if (!persistedUser.isInitialized) {
				return;
			}

			if (!values.isProjectConnected) {
				setUsers(persistedUser.items)
				return;
			}
			setFetchingUsers(true);

			const bindedAction = getAllTeamMembersAction.bind(null, values.projectOrCategoryId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const teamMembers = response.items || [];
				const activeTeamMembers = teamMembers.filter((item) => item.isActive);
				const activeTeamMemberUsers = activeTeamMembers.map((item) => persistedUser.itemsMap[item.userId]!)
				setUsers(activeTeamMemberUsers)
			}

			setFetchingUsers(false);
		},
		[values.projectOrCategoryId, values.isProjectConnected, persistedUser]
	)

	useEffect(
		() => {
			fetchUsersCallback();
		},
		[fetchUsersCallback]
	)

	const isRead = crud === CrudEnum.Read
	const isCreate = crud === CrudEnum.Create
	const isUpdate = crud === CrudEnum.Update

	useEffect(
		() => {
			if (isCreate) {
				const status = getInitialStatus(persistedTaskStatus.items);

				if (status) {
					setInitialValues((state: TaskResponse) => new TaskResponse({
						...state,
						statusId: status.id
					}));

					setValues((state: TaskResponse) => new TaskResponse({
						...state,
						statusId: status.id
					}));
				}
			}
		},
		[persistedTaskStatus, isCreate]
	)

	useEffect(
		() => {
			if (values.isProjectConnected) {
				const projectId = values.projectOrCategoryId;

				if (!persistedTeamMember.projectMap[projectId]) {
					persistTeamMembersAction(projectId);
				}

				if (!persistedProjectTeam.projectMap[projectId]) {
					persistProjectTeamsAction(projectId);
				}
			}
		},
		[persistedTeamMember, persistedProjectTeam, values.isProjectConnected, values.projectOrCategoryId]
	)

	const nextStatuses = useMemo(
		() => {
			if (isUpdate) {
				return getNextStatuses(task.statusId, persistedTaskStatus.items);
			} else {
				return persistedTaskStatus.items;
			}
		},
		[task.statusId, persistedTaskStatus.items, isUpdate]
	)

	const projectTeamMemo = useMemo(
		() => {
			if (!values.isProjectConnected) {
				return;
			}

			if (!values.assignedToUserId) {
				return;
			}

			const teamMember = persistedTeamMember.projectMap[values.projectOrCategoryId]?.items.find(teamM => teamM.userId === values.assignedToUserId)

			if (!teamMember || !teamMember.projectTeamId) {
				return '';
			}
			return persistedProjectTeam.itemsMap[teamMember.projectTeamId]?.name;

		},
		[persistedTeamMember.projectMap, persistedProjectTeam, values.assignedToUserId, values.isProjectConnected, values.projectOrCategoryId]
	)

	const downloadAttachmentMemo = useMemo(
		() => downloadAttachmentAction.bind(null, task.id, task.projectOrCategoryId, task.isProjectConnected),
		[task.projectOrCategoryId, task.isProjectConnected, task.id]
	)

	const removeAttachmentCallback = useCallback(
		async (id: number) => {
			const bindedAction = removeAttachmentAction.bind(null, task.id, id, task.projectOrCategoryId, task.isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Attachment is successfully deleted');
				setValues((state: TaskResponse) =>
					new TaskResponse({
						...state,
						attachments: state.attachments?.filter(att => att.id !== id)
					})
				)
			}
		},
		[task.projectOrCategoryId, task.isProjectConnected, task.id]
	)

	const onSubmitCallback = useCallback(
		async () => {
			if (!values.projectOrCategoryId) {
				// projectOrCategorySelect is required, so we return message as error if no value in select
				setProjectOrCategoryHasError(true);
			} else {
				return await onSave!(values);
			}
		},
		[onSave, values]
	)

	const onProjectOrCategoryChangeCallback = useCallback(
		(newProjectOrCategory: ProjectOrCategoryType | undefined) => {
			setProjectOrCategory(newProjectOrCategory);
			setProjectOrCategoryHasError(false);

			setValues((state: TaskResponse) =>
				new TaskResponse({
					...state,
					projectOrCategoryId: newProjectOrCategory ? newProjectOrCategory.projectOrCategoryId : undefined as any,
					isProjectConnected: newProjectOrCategory ? newProjectOrCategory.isProjectConnected : undefined as any,
					assignedToUserId: undefined as any,
					parentTaskId: undefined,
					riskId: undefined,
					scopeItemLevelId: undefined,
					changeRequestId: undefined,
					meetingId: undefined,
					ticketId: undefined
				})
			)
		},
		[]
	)

	const createdByUserItems = useMemo(
		() => {
			const user = persistedUser.itemsMap[values.createdByUserId];
			if (user) {
				return [user];
			}
			return [];
		},
		[persistedUser, values.createdByUserId]
	)

	const closedByUserItems = useMemo(
		() => {
			if (!values.closedByUserId) {
				return [];
			}

			const user = persistedUser.itemsMap[values.closedByUserId];
			if (user) {
				return [user];
			}
			return [];
		},
		[persistedUser, values.closedByUserId]
	)

	return (
		<>
			<Form
				values={values}
				initialValues={initialValues}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={cancel}
				disabled={isRead}
				hideButtons={isRead}
				render={() => (
					<ColumnContainer>
						<SmartContainer>
							<SmartItem>
								<ProjectOrCategorySelect
									value={projectOrCategory}
									onChange={onProjectOrCategoryChangeCallback}
									disabled={isRead || isUpdate}
									moduleEnum={ModuleActivityEnum.Communication}
									showCompleted={isRead}
									hasError={projectOrCategoryHasError}
									isRequired
								/>
								<AutoCompleteField
									id={propertyOf<TaskResponse>('assignedToUserId')}
									label='Assigned to'
									items={users}
									getItemId={(item: UserModel) => item.id}
									getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
									loading={fetchingUsers}
									isRequired
									sort
									disabled={values.isScheduleGenerated}
								/>
								<SmartFormGroup label='Project Team'>
									<Input
										value={projectTeamMemo}
										disabled
									/>
								</SmartFormGroup>
								<DateField
									id={propertyOf<TaskResponse>('due')}
									label='Due date'
									isRequired
									disabled={values.isScheduleGenerated}
								/>
								<AutoCompleteField
									id={propertyOf<TaskResponse>('statusId')}
									label='Status'
									isRequired
									items={nextStatuses}
									getItemId={(item: TaskStatusResponse) => item.id}
									getItemText={(item: TaskStatusResponse) => item.name}
									loading={persistedTaskStatus.fetching}
									disabled={!isUpdate}
								/>
								<AutoCompleteField
									id={propertyOf<TaskResponse>('priority')}
									label='Priority'
									items={taskPriorities}
									getItemId={(item: any) => item.id}
									getItemText={(item: any) => item.text}
								/>
								<AutoCompleteField
									id={propertyOf<TaskResponse>('parentTaskId')}
									label='Related tasks'
									items={tasks}
									getItemId={(item: TaskResponse) => item.id}
									getItemText={(item: TaskResponse) => `${getFormatedId(EntityPrefixEnum.TASK, item.id)}`}
									loading={loadingFetchData}
									disabled={!values.projectOrCategoryId}
								/>
								<CheckboxField
									id={propertyOf<TaskResponse>('isPrivate')}
									label='Private task'
								/>
							</SmartItem>
							<SmartItem>
								<AutoCompleteField
									id={propertyOf<TaskResponse>('riskId')}
									label='Risk ID'
									items={risks}
									getItemId={(item: RiskResponse) => item.id}
									getItemText={(item: RiskResponse) => `${getFormatedId(EntityPrefixEnum.RISK, item.id)} - ${item.name}`}
									loading={loadingFetchData}
									disabled={!values.isProjectConnected || !values.projectOrCategoryId}
								/>
								<AutoCompleteField
									id={propertyOf<TaskResponse>('scopeItemLevelId')}
									label='Process ID'
									items={scopeItemsLevel3}
									getItemId={(item: ScopeItemLevelResponse) => item.id}
									getItemText={(item: ScopeItemLevelResponse) => `${item.fullname} - ${item.description}`}
									loading={loadingFetchData}
									disabled={!values.isProjectConnected || !values.projectOrCategoryId}
								/>
								<AutoCompleteField
									id={propertyOf<TaskResponse>('changeRequestId')}
									label='CR ID'
									items={changeRequests}
									getItemId={(item: ChangeRequestResponse) => item.id}
									getItemText={(item: ChangeRequestResponse) => `${getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, item.id)}  - ${item.name}`}
									loading={loadingFetchData}
									disabled={!values.isProjectConnected || !values.projectOrCategoryId}
								/>
								<AutoCompleteField
									id={propertyOf<TaskResponse>('meetingId')}
									label='Meeting ID'
									items={meetings}
									getItemId={(item: MeetingResponse) => item.id}
									getItemText={(item: MeetingResponse) => `${getFormatedId(EntityPrefixEnum.MEETING, item.id)}  - ${item.subject}`}
									loading={loadingFetchData}
									disabled={!values.projectOrCategoryId || values.isMeetingGenerated}
								/>
								<AutoCompleteField
									id={propertyOf<TaskResponse>('ticketId')}
									label='Ticket ID'
									items={tickets}
									getItemId={(item: TicketResponse) => item.id}
									getItemText={(item: TicketResponse) => `${getFormatedId(EntityPrefixEnum.TICKET, item.id)}  - ${item.name}`}
									loading={loadingFetchData}
									disabled={!values.isProjectConnected || !values.projectOrCategoryId}
								/>
								<AutoCompleteField
									id={propertyOf<TaskResponse>('scheduleId')}
									label='WBS'
									items={schedules}
									getItemId={(item: SimpleScheduleResponse) => item.id}
									getItemText={(item: SimpleScheduleResponse) => `${item.wbs} - ${item.name}`}
									loading={loadingFetchData}
									disabled={!values.isProjectConnected || !values.projectOrCategoryId || values.isScheduleGenerated}
								/>
							</SmartItem>
							{(isUpdate || isRead) &&
								<SmartItem>
									<AutoCompleteField
										id={propertyOf<TaskResponse>('createdByUserId')}
										label='Created by'
										items={createdByUserItems}
										getItemId={(item: UserModel) => item.id}
										getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
										disabled
									/>
									<DateField
										id={propertyOf<TaskResponse>('createdOn')}
										label='Created on'
										showTime
										disabled
										dateFormat='MM/dd/yyyy h:mm aa'
									/>
									<AutoCompleteField
										id={propertyOf<TaskResponse>('closedByUserId')}
										label='Closed by'
										items={closedByUserItems}
										getItemId={(item: UserModel) => item.id}
										getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
										disabled
									/>
									<DateField
										id={propertyOf<TaskResponse>('closedOn')}
										label='Closed on'
										showTime
										disabled
										dateFormat='MM/dd/yyyy h:mm aa'
									/>
								</SmartItem>
							}
						</SmartContainer>
						<SmartContainer>
							<SmartItem size='large'>
								<TextEditorField
									id={propertyOf<TaskResponse>('description')}
									label='Description'
									isRequired
								/>
							</SmartItem>
						</SmartContainer>
						<SmartContainer>
							<SmartItem>
								<AttachmentField
									id={propertyOf<InsertTaskRequest>('newAttachments')}
									label='Attachments'
									multiple
									disabled={isRead}
									oldAttachments={values.attachments}
									removeOldAttachment={removeAttachmentCallback}
									downloadOldAttachment={downloadAttachmentMemo}
								/>
							</SmartItem>
						</SmartContainer>
					</ColumnContainer>
				)}
			/>
			<VerticalSeparator />
			{!isCreate &&
				<Tabs
					tabs={taskTabs}
					tabComponentProps={{
						projectOrCategoryId: task.projectOrCategoryId,
						isProjectConnected: task.isProjectConnected,
						taskId: task.id,
						isRead,
						scopeItemsLevel3
					} as TaskTabComponentProps}
				/>
			}
		</>
	)
}
