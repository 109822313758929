import { TestCasesStatusesHelp } from './TestCasesStatusesHelp';
import { getTestCaseStatusesAction, updateTestCaseSStatusesAction } from './action';
import { EditableStatusTable } from 'features/EditableStatusTable/EditableStatusTable';
import { ContentShell } from 'features/Content/ContentShell';

export const TestCases = () => {
	return (
		<ContentShell
			InlineHelpComponent={TestCasesStatusesHelp}
			showBreadcrumb={false}
		>
			<EditableStatusTable
				getStatusesction={getTestCaseStatusesAction}
				updateStatusesAction={updateTestCaseSStatusesAction}
				successMessage='Test case statuses are updated.'
			/>
		</ContentShell>
	)
}
