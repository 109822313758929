import { Dialog } from "components/Dialog";
import { CrudEnum } from "features/Crud";
import { useCallback } from "react";
import { UpdateTicketCommentRequest } from "services/tenantManagementService";
import { TaskForm } from "./TaskForm";

type Props = {
	isOpened: boolean
	setIsOpened(isOpened: boolean): void
	task: UpdateTicketCommentRequest
	updateTask(task: UpdateTicketCommentRequest): Promise<any>
}

export const UpdateTask = ({ isOpened, setIsOpened, task, updateTask }: Props) => {
	const closeDialogCallback = useCallback(
		() => setIsOpened(false),
		[setIsOpened]
	)

	const updateTaskCallback = useCallback(
		async (values: UpdateTicketCommentRequest) => {
			const response = await updateTask(values);
			closeDialogCallback()
			return response;
		},
		[updateTask, closeDialogCallback]
	)

	return (
		<Dialog
			title='Update task'
			onClose={closeDialogCallback}
			open={isOpened}
		>
			<TaskForm
				task={task}
				onSave={updateTaskCallback}
				onCancel={closeDialogCallback}
				crud={CrudEnum.Update}
			/>
		</Dialog>
	)
}
