import { ProjectClient, InsertProjectRequest, UpdateProjectRequest, ProjectIdRequest, UpdateProjectDataRequest, ExportDataModel, GenericFilterModelCollection, ProjectResponse } from 'services/tenantManagementService'
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

import store from 'base/reducer/store';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getProjectsAction = () => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.getAll(tenantId);
}

export const getProjectsGenericAction = (genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.getAllGenericFilter(tenantId, genericFilter);
}

export const getProjectsForOrgUnitManagerUsersAction = () => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.getAllForOrgUnitManagerUsers(tenantId);
}

export const getProjectsForOrgUnitManagerAction = () => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.getAllForOrgUnitManager(tenantId);
}

export const getProjectAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.get(tenantId, id);
}

export const createProjectAction = (project: InsertProjectRequest ) => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.create(tenantId, project);
}

export const updateProjectAction = (project: UpdateProjectRequest ) => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.update(tenantId, project);
}

export const deleteProjectAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.delete(tenantId, id);
}

export const updateProjectDataAction = (project: UpdateProjectDataRequest ) => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.updateProjectData(tenantId, project);
}

export const archiveProjectAction = (id: number) => {
	const model = new ProjectIdRequest({ projectId: id });
	const tenantId = getTenantId()!;

	const client = new ProjectClient();
	return client.achive(tenantId, model);
}

export const restoreProjectAction = (id: number) => {
	const model = new ProjectIdRequest({ projectId: id });
	const tenantId = getTenantId()!;

	const client = new ProjectClient();
	return client.restore(tenantId, model);
}

export const isUserProjectManagerAction = () => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.isUserPM(tenantId);
}

export const exportAction = (exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectClient();
	return client.export(tenantId, exportDataModel);
}

export const persistProjectsAction = async () => {
	const state = store.getState();

	if (state.persistedProject.fetching) {
		return;
	}

	store.dispatch(setProjectsFetchingAction(true));

	const response = await tryCatchJsonByAction(getProjectsAction);
	if (response.success) {
		store.dispatch(setProjectsAction(response.items || []));
	} else {
		store.dispatch(setProjectsFetchingAction(false));
	}
}

export const persistOrganizationalUnitManagerProjectsAction = async () => {
	const state = store.getState();

	if (state.persistedOrganizationalUnitManagerProject.fetching) {
		return;
	}

	store.dispatch(setOrganizationalManagerProjectsFetchingAction(true));

	const response = await tryCatchJsonByAction(getProjectsForOrgUnitManagerAction);
	if (response.success) {
		store.dispatch(setOrganizationalUnitProjectsAction(response.items || []));
	} else {
		store.dispatch(setOrganizationalManagerProjectsFetchingAction(false));
	}
}

// redux

export const PROJECTS_FETCHING = 'PROJECTS_FETCHING';
export const PROJECTS_SET = 'PROJECTS_SET';

const setProjectsFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: PROJECTS_FETCHING,
		fetching
	}
}

const setProjectsAction = (items: ProjectResponse[]): PersistItemsActionType<ProjectResponse> =>  {
	return {
		type: PROJECTS_SET,
		items
	}
}

export const ORGANIZATIONAL_UNIT_MANAGER_PROJECTS_FETCHING = 'ORGANIZATIONAL_UNIT_MANAGER_PROJECTS_FETCHING';
export const ORGANIZATIONAL_UNIT_MANAGER_PROJECTS_SET = 'ORGANIZATIONAL_UNIT_MANAGER_PROJECTS_SET';

const setOrganizationalManagerProjectsFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: ORGANIZATIONAL_UNIT_MANAGER_PROJECTS_FETCHING,
		fetching
	}
}

const setOrganizationalUnitProjectsAction = (items: ProjectResponse[]): PersistItemsActionType<ProjectResponse> =>  {
	return {
		type: ORGANIZATIONAL_UNIT_MANAGER_PROJECTS_SET,
		items
	}
}
