import { getTenantId } from "features/Tenant/tenantUtils";
import { CalendarClient, InsertSchedulePredecessorRequest, SchedulePredecessorClient } from "services/tenantManagementService";

export const getPredecessorsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new SchedulePredecessorClient();
	return client.getAll(tenantId, projectId);
}

export const createPredecessorAction = (projectId: number, model: InsertSchedulePredecessorRequest) => {
	const tenantId = getTenantId()!;
	const client = new SchedulePredecessorClient();
	return client.create(tenantId, projectId, model);
}

export const deletePredecessorAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new SchedulePredecessorClient();
	return client.delete(tenantId, projectId, id);
}

export const gedDateDifferenceAction = (projectId: number, from: Date, to: Date ) => {
	const tenantId = getTenantId()!;

	const client = new CalendarClient();
	return client.getDifferenceInSpecificMeasurement(tenantId, projectId, from, to);
}
