import path from 'path'
import { EntityPrefixEnum } from "utils/commonHelper";
import { Notification } from "../Notification/Notification"
import { NotificationComponentProps } from "../NotificationsPanel"
import { useFormattedIdUsernameAndTrigger } from "./useFormattedIdUsernameAndTrigger";
import styles from '../notificationsPanel.module.scss'
import { RootRouteEnum } from 'features/Routes';
import { CrudEnum } from 'features/Crud';
import { useMemo } from 'react';
import { AlertTriggersEnum } from 'services/tenantManagementService';
import { MeetingReleasedNotificationAdditional } from './types';
import { CommunicationSubRoutesEnum } from 'containers/Communication/routes';

export const MeetingNotification = ({ notification }: NotificationComponentProps) => {
	const Component = useMemo(
		() => {
			switch (notification.trigger) {
				case AlertTriggersEnum.Released:
					return MeetingReleasedNotification;
				default:
					return;
			}
		},
		[notification]
	)

	if (!Component) {
		return <></>
	}

	return (
		<Component notification={notification}/>
	)
}

const MeetingReleasedNotification = ({ notification }: NotificationComponentProps) => {
	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as MeetingReleasedNotificationAdditional : undefined
	const { formattedId, username, triggerText } = useFormattedIdUsernameAndTrigger(notification, EntityPrefixEnum.MEETING)

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.COMMUNICATION,
				CommunicationSubRoutesEnum.PROJECT_MEETINGS,
				String(additionalData?.projectOrCategoryId),
				String(additionalData?.isProjectConnected),
				CrudEnum.Read,
				String(notification.entityId),
			)}
			content={
				<div>
					<span>Meeting: </span>
					<span className={styles.entity}>{formattedId} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>by user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}
