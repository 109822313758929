import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'base/reducer/reducer'
import { ThemeEnum } from "features/Theme";

export type DataType = {
	value: number
	name: string
	color?: string
}

export type XYChartProps = {
	id: string
	data: DataType[]
	xTitle?: string
	ytitle?: string
	maxValue?: number
	minValue?: number
	style?: React.CSSProperties
	isPercent?: boolean // if percent it multiplies by 100 and adds suffix '%'
	showValueOnBar?: boolean
	hasPagination?: boolean
}

export const XYChart = ({ id, data, style, xTitle, ytitle, minValue, maxValue, showValueOnBar, hasPagination, isPercent }: XYChartProps) => {
	const theme = useSelector((state: RootState) => state.theme);

	useEffect(
		() => {
			let chart = am4core.create(id, am4charts.XYChart);
			chart.data = data;

			let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = 'name';
			categoryAxis.renderer.labels.template.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
			categoryAxis.renderer.minGridDistance = 10;
			categoryAxis.renderer.grid.template.disabled = true;
			categoryAxis.fontSize = 11;

			if (xTitle) {
				categoryAxis.title.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
				categoryAxis.title.text = xTitle;
			}

			let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis.renderer.labels.template.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
			valueAxis.renderer.grid.template.disabled = true;
			valueAxis.fontSize = 11;
			valueAxis.min = minValue;
			valueAxis.max = maxValue;

			if (ytitle) {
				valueAxis.title.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
				valueAxis.title.text = ytitle;
			}

			let series = chart.series.push(new am4charts.ColumnSeries());
			series.dataFields.valueY = 'value';
			series.dataFields.categoryX = 'name';
			series.columns.template.tooltipText = "{name}: [bold]{valueY.formatNumber('#,###.##')} [/]";
			series.columns.template.propertyFields.fill = "barColor";
			series.columns.template.propertyFields.stroke = "barColor";

			if (isPercent) {
				series.numberFormatter.numberFormat = "#,###.#%"
			}

			chart.cursor = new am4charts.XYCursor();
			chart.cursor.lineX.stroke= am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
			chart.cursor.lineY.stroke= am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');

			if (showValueOnBar) {
				let valueLabel = series.bullets.push(new am4charts.LabelBullet());
				valueLabel.label.text = '{value.formatNumber("#,###.")}';
				valueLabel.label.fontSize = 11;
				valueLabel.label.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
				valueLabel.label.verticalCenter = 'bottom'
				valueLabel.locationY = 0;
				valueLabel.label.hideOversized = false;

				valueLabel.label.adapter.add('verticalCenter', function(x, target) {
					if (!target.dataItem) {
						return x;
					}
					let values = target.dataItem.values;

					if (valueAxis.max && (values.valueY.value / valueAxis.max) * 100 >= 90) {
						return 'top';
					} else {
						return 'bottom';
					}
				});
			}

			if (hasPagination) {
				let firstYear: number;
				let lastYear: number;
				let currentYear: number;

				const getDataPerYear = () => {
					let dataPerYear: DataType[] = [];

					for (let i = 0; i < data.length; i++) {
						let date = new Date(data[i].name);
						let y = date.getFullYear();
						lastYear = y;
						if (!currentYear) {
							currentYear = y;
							firstYear = y;
						}
						if (currentYear === y) {
							dataPerYear.push(data[i]);
						}
					}

					title.text = currentYear ? `${currentYear}` : '';

					isFirstPage();
					isLastPage();

					return dataPerYear;
				}

				const prevPage = () => {
					if (!isFirstPage()) {
						currentYear--;
						chart.data = getDataPerYear();
					}
				}

				const nextPage = () => {
					if (!isLastPage()) {
						currentYear++;
						chart.data = getDataPerYear();
					}
				}

				const isFirstPage = () => {
					if (currentYear > firstYear) {
						prev.disabled = false;
						return false;
					} else {
						prev.disabled = true;
						return true;
					}
				}

				const isLastPage = () => {
					if (currentYear < lastYear) {
						next.disabled = false;
						return false;
					} else {
						next.disabled = true;
						return true;
					}
				}

				// Title
				let title = chart.titles.create();
				title.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
				title.fontSize = 16;
				title.marginBottom = 10;

				// Add pagination buttons
				let arrow = 'M604.7 759.2l61.8-61.8L481.1 512l185.4-185.4-61.8-61.8L357.5 512z';
				let prev = chart.plotContainer.createChild(am4core.Button);
				prev.dy = -40;
				prev.icon = new am4core.Sprite();
				prev.icon.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
				prev.icon.path = arrow;
				prev.icon.scale = 0.02;
				prev.events.on('hit', prevPage);

				let next = chart.plotContainer.createChild(am4core.Button);
				next.dy = -40;
				next.align = 'right';
				next.icon = new am4core.Sprite();
				next.icon.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
				next.icon.rotation = 180;
				next.icon.path = arrow;
				next.icon.scale = 0.02;
				next.events.on('hit', nextPage);

				chart.data = getDataPerYear();
			}
		},
		[id, data, theme, xTitle, ytitle, minValue, maxValue, showValueOnBar, hasPagination, isPercent]
	)

	return <div id={id} style={style}></div>
}
