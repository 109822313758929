import { TabType } from 'components/Tabs/Tabs';
import TimesheetTabTitle from './TimesheetTabTitle';
import TimesheetsTable from './Table/TimesheetsTable';
import { WeekTabSubroutes } from './WeekSubroutes';
import { TimesheetRecordedStatusResponse } from 'services/tenantManagementService';

export const getWeekTabs = (records: TimesheetRecordedStatusResponse[], allowOvertime: boolean): TabType[] =>  {
	return [
		{
			id: '1',
			title: (
				<TimesheetTabTitle recordStatus={records[0]} />
			),
			route: WeekTabSubroutes.MONDAY,
			component: TimesheetsTable,
			disabled: !records[0].isWorkingDay && !allowOvertime
		},
		{
			id: '2',
			title: (
				<TimesheetTabTitle recordStatus={records[1]} />
			),
			route: WeekTabSubroutes.TUESDAY,
			component: TimesheetsTable,
			disabled: !records[1].isWorkingDay && !allowOvertime
		},
		{
			id: '3',
			title: (
				<TimesheetTabTitle recordStatus={records[2]} />
			),
			route: WeekTabSubroutes.WEDNESDAY,
			component: TimesheetsTable,
			disabled: !records[2].isWorkingDay && !allowOvertime
		},
		{
			id: '4',
			title: (
				<TimesheetTabTitle recordStatus={records[3]} />
			),
			route: WeekTabSubroutes.THURSDAY,
			component: TimesheetsTable,
			disabled: !records[3].isWorkingDay && !allowOvertime
		},
		{
			id: '5',
			title: (
				<TimesheetTabTitle recordStatus={records[4]} />
			),
			route: WeekTabSubroutes.FRIDAY,
			component: TimesheetsTable,
			disabled: !records[4].isWorkingDay && !allowOvertime
		},
		{
			id: '6',
			title: (
				<TimesheetTabTitle recordStatus={records[5]} />
			),
			route: WeekTabSubroutes.SATURDAY,
			component: TimesheetsTable,
			disabled: !records[5].isWorkingDay && !allowOvertime
		},
		{
			id: '7',
			title: (
				<TimesheetTabTitle recordStatus={records[6]} />
			),
			route: WeekTabSubroutes.SUNDAY,
			component: TimesheetsTable,
			disabled: !records[6].isWorkingDay && !allowOvertime
		},

	]
}
