import { AccountIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";

// subroutes

export enum AccountSubRoutesEnum {
	PROFILE = 'profile',
	RULES_AND_ALERTS = 'rules_and_alerts',
	DEFAULT_PROJECT = 'default_project',
}

export const profileSubRoute: Route = {
	id: AccountSubRoutesEnum.PROFILE,
	url: `/${RootRouteEnum.ACCOUNT}/${AccountSubRoutesEnum.PROFILE}`,
	text: 'My profile',
}

export const rulesAndAlertsSubRoute: Route = {
	id: AccountSubRoutesEnum.RULES_AND_ALERTS,
	url: `/${RootRouteEnum.ACCOUNT}/${AccountSubRoutesEnum.RULES_AND_ALERTS}`,
	text: 'Rules and alerts',
}

export const defaultProjectSubRoute: Route = {
	id: AccountSubRoutesEnum.DEFAULT_PROJECT,
	url: `/${RootRouteEnum.ACCOUNT}/${AccountSubRoutesEnum.DEFAULT_PROJECT}`,
	text: 'Default project',
}

// root route

export const myConfigurationRoute: Route = {
	id: RootRouteEnum.ACCOUNT,
	url: `/${RootRouteEnum.ACCOUNT}`,
	text: 'Account',
	Icon: AccountIcon,
	children: [
		profileSubRoute,
		rulesAndAlertsSubRoute,
		defaultProjectSubRoute
	]
}
