import { useCallback, useMemo, useState } from 'react';
import { ColumnContainer } from 'components/Layout';
import { ExportModel } from 'features/Export/ExportForm';
import { GenericFilterModelCollection } from 'services/tenantManagementService';
import { createExportModel } from 'utils/exportUtils';
import TableButtons from './TableButtons';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useTableColumnsMemo } from './tableColumns';
import { getAllReportAction, exportAction } from './action';
import FileSaver from 'file-saver';
import { tryCatchExportFileByAction } from 'utils/exportUtils';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { MaintainScopeTabComponentProps } from '../maintainScopeTabs';
import { downloadAttachmentAction } from '../action';

const configureViewKey = 'scope_level_items';

const genericFilterModelCollection = new GenericFilterModelCollection();
genericFilterModelCollection.limit = 10000;

const ListTable = ({ project }: MaintainScopeTabComponentProps) => {
	const [filtersModel, setFiltersModel] = useState(genericFilterModelCollection);
	const [loadingExport, setLoadingExport] = useState(false);

	const { persistedConfigureView } = useSelector((state: RootState) => state);

	const downloadAttachmentCallback = useCallback(
		async (id: number) => {
			const bindedAction = downloadAttachmentAction.bind(null, project.id)
			const response = await bindedAction(id);

			if (response.status === 200) {
				FileSaver.saveAs(response.data, response.fileName);
			}
		},
		[project.id]
	)

	const tableColumns = useTableColumnsMemo(
		project,
		downloadAttachmentCallback,
		persistedConfigureView.value[configureViewKey]
	);

	const exportCallback = useCallback(
		async (exportModel: ExportModel) => {
			const exportDataModel = createExportModel(tableColumns, filtersModel, exportModel);
			exportDataModel.title = 'Scope level items';

			setLoadingExport(true);

			const bindedAction = exportAction.bind(null, project.id, exportDataModel);
			const response = await tryCatchExportFileByAction(bindedAction);
			if (response.success && response.value) {
				FileSaver.saveAs(response.value.data, response.value.fileName);
			}

			setLoadingExport(false);
		},
		[filtersModel, tableColumns, project.id]
	)

	const memoFetchFunction = useMemo(
		() => getAllReportAction.bind(null, project.id),
		[project.id]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ColumnContainer>
			<TableButtons
				tableColumns={tableColumns}
				configureViewKey={configureViewKey}
				onExport={exportCallback}
				loadingExport={loadingExport}
			/>
			<RemoteTable
				columns={tableColumns}
				filtersModel={filtersModel}
				filtersModelChanged={setFiltersModel}
				fetchFunction={memoFetchFunction}
				reorderColumns={reorderColumnsCallback}
				hidePagination
				options={{
					groupBy: [
						(data) => data.level1Id + ": " + data.level1Name,
						(data) => data.level2Id + ": " + data.level2Name,
						(data) => data.level1Id + "-" + data.level2Id + "-" + data.level3Id + ": " + data.level3Name
					],
					groupHeader: [
						function(value, count, data) {
							return "<span style='color:var(--brand-color); margin-right:5px;'>Level 1: </span>" + value + "<span style='margin-left:10px;'>(" + count + " item)</span>";
						},
						function(value, count, data) {
							return "<span style='color:#0dd; margin-right:5px;'>Level 2: </span>" + value + "<span style='margin-left:10px;'>(" + count + " item)</span>";
						},
						function(value, count, data) {
							return "<span style='color:#00D020; margin-right:5px;'>Level 3: </span>" + value + "<span style='margin-left:10px;'>(" + count + " item)</span>";
						}
					],
					groupStartOpen: [false, false, false],
					groupToggleElement: "header"
				}}
			/>
		</ColumnContainer>
	)
}

export default ListTable;
