import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getRouteObject, RouterProps } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { CrudRouter } from 'features/Crud';
import { RiskDashboard } from './Dashboard/RiskDashboard';
import { ProjectParamName } from 'features/Project';
import { CreateRisk } from './RiskPlan/Crud/CreateRisk';
import { ReadRisk } from './RiskPlan/Crud/ReadRisk';
import { UpdateRisk } from './RiskPlan/Crud/UpdateRisk';
import ViewRiskList from './ViewRiskList/ViewRiskList';
import RiskPlan from './RiskPlan/RiskPlan';
import { persistRiskStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Risks/action';
import { ReadReducedRisk } from './ViewRiskList/Crud/ReadReducedRisk';
import { UpdateReducedRisk } from './ViewRiskList/Crud/UpdateReducedRisk';
import { RiskSubRoutesEnum, myDashboardSubRoute, riskListSubRoute, riskPlanSubRoute } from './routes';
import { persistRiskTypesAction } from 'containers/Configuration/SiteConfiguration/Module/Risk/Tabs/RiskType/action';

export const RiskRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistRiskStatusesAction();
			persistRiskTypesAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[RiskSubRoutesEnum.MY_DASHBOARD] &&
				<Route
					path={myDashboardSubRoute.url}
					render={() => <RiskDashboard />}
				/>
			}
			{routesMap[RiskSubRoutesEnum.RISK_PLAN] &&
				<Route
					path={[
						`${riskPlanSubRoute.url}/:${ProjectParamName}`,
						riskPlanSubRoute.url,
					]}
					render={() => (
						<CrudRouter
							ListComponent={RiskPlan}
							CreateComponent={CreateRisk}
							ReadComponent={ReadRisk}
							UpdateComponent={UpdateRisk}
							paramName='riskId'
						/>
					)}
				/>
			}

			{routesMap[RiskSubRoutesEnum.RISK_LIST] &&
				<Route
					path={[
						`${riskListSubRoute.url}/:${ProjectParamName}`,
						riskListSubRoute.url,
					]}
					render={() => (
						<CrudRouter
							ListComponent={ViewRiskList}
							ReadComponent={ReadReducedRisk}
							UpdateComponent={UpdateReducedRisk}
							paramName='riskId'
						/>
					)}
				/>
			}
			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
