import { CustomTrainingPlanRowModel } from './tableModel';

export const onCellEdit = (cell: any) => {
	const field = cell.getField();
	const id = cell.getData().id;
	const table = cell.getTable();
	const tableData = table.getData();
	const newData = tableData.map(
		(data: CustomTrainingPlanRowModel) => {
			if (data.id !== id) {
				return data;
			}

			let duration = data.duration;
			let plannedTimeTo = data.plannedTimeTo;
			if (field === 'plannedTime' && plannedTimeTo) {
				duration = plannedTimeTo - cell.getValue();
			}
			if (field === 'plannedTimeTo' && data.plannedTime) {
				duration = cell.getValue() - data.plannedTime;
			}
			if (field === 'duration' && data.plannedTime) {
				plannedTimeTo = data.plannedTime + cell.getValue();
			}

			return new CustomTrainingPlanRowModel({...data, [field]: cell.getValue(), duration, plannedTimeTo})
		}
	)
	table.setData(newData);
}
