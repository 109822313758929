import { useCallback, useMemo, useState } from 'react';
import { CrudEnum } from 'features/Crud';
import { ChangeRequestFullResponse } from 'services/tenantManagementService';
import { useParams } from 'react-router-dom';
import { phasesTabs } from './Tabs/phasesTabs';
import Tabs from 'components/Tabs/Tabs';
import WithFetch from 'features/Fetch/WithFetch';
import { getChangeRequestAction } from '../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { ProjectPickerParams } from 'features/Project'
import { TabProps } from './CreateChangeRequest';
import { ContentShell } from 'features/Content/ContentShell';
import ChangeRequestsHelp from '../Help/ChangeRequestsHelp';
import { ContentSubtitle } from 'features/Content';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { InfoPopup, POSITION } from 'components/Popup';
import { ExportChangeRequest } from '../Export/ExportChangeRequest';

type ParamType = ProjectPickerParams & {
	changeRequestId: string
}

const ReadChangeRequest = () => {
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId as string);
	const changeRequestId = parseInt(params.changeRequestId);

	const persistedChangeRequestStatus = useSelector((state: RootState) => state.persistedChangeRequestStatus);

	const [changeRequest, setChangeRequest] = useState(new ChangeRequestFullResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getChangeRequestAction.bind(null, projectId, changeRequestId);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setChangeRequest(response.value || new ChangeRequestFullResponse());
			}
		},
		[projectId, changeRequestId]
	)

	const status = useMemo(
		() => {
			if (persistedChangeRequestStatus.isInitialized) {
				return persistedChangeRequestStatus.itemsMap[changeRequest.statusId];
			}
		},
		[changeRequest, persistedChangeRequestStatus]
	)

	return (
		<ColumnContainer margin='disabled'>
			<ContentSubtitle title={`Project - ${getFormatedId(EntityPrefixEnum.PROJECT, projectId)}`} />
			<ContentShell
				title={`Change Change Request - ${getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, changeRequestId)}`}
				FloatingHelpComponent={ChangeRequestsHelp}
			>
				<WithFetch fetchFunction={fetchDataCallback}>
					<ColumnContainer margin='small'>
						<RowContainer justifyContent='space-between'>
							{status &&
								<RowContainer>
									<div>Status:</div>
									<h5>{status.name}</h5>
									<InfoPopup
										message={status.description}
										position={POSITION.TOP_CENTER}
									/>
								</RowContainer>
							}
							<ExportChangeRequest
								changeRequest={changeRequest}
								projectId={projectId}
							/>
						</RowContainer>
						<Tabs
							tabs={phasesTabs}
							tabComponentProps={{
								projectId,
								changeRequest,
								crud: CrudEnum.Read
							} as TabProps}
						/>
					</ColumnContainer>
				</WithFetch>
			</ContentShell>
		</ColumnContainer>
	)
}

export default ReadChangeRequest;
