import styles from './badge.module.scss'

type Props = {
	count: number
	showZero?: boolean
	max?: number
	children: any
}

export const Badge = ({ count, showZero = false, max = 99, children }: Props) => {
	const content = count > max ? `${max}+` : count;

	if (!showZero && count === 0) {
		return children;
	}

	return (
		<div className={styles.wrapper}>
			{children}
			<span className={styles.container}>
				{content}
			</span>
		</div>
	)
}
