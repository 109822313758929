import styles from './content.module.scss';
import { ContentRouter } from './ContentRouter';
import ErrorBoundary from 'features/ErrorBoundary';

type Props = {
	rootRoutes: any
}

export const ContentContainer = ({ rootRoutes }: Props) => {
	return (
		<div className={styles.container}>
			<div className={styles.inner}>
				<ErrorBoundary location='ContentContainer'>
					<ContentRouter rootRoutes={rootRoutes} />
				</ErrorBoundary>
			</div>
		</div>
	)
}
