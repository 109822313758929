import { getTenantId } from 'features/Tenant/tenantUtils';
import { ProjectSLAClient, UpdateProjectSLARequest } from 'services/tenantManagementService';

export const getProjectSLAAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectSLAClient();
	return client.get(tenantId, projectId);
}

export const updateProjectSLAAction = (projectId: number, model: UpdateProjectSLARequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectSLAClient();
	return client.update(tenantId, projectId, model);
}
