import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnContainer } from 'components/Layout';
import notifications from 'components/Notification/notification';
import { SurveyReducedResponse, CloneSurveyRequest, ProjectResponse, ModuleActivityEnum } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { cloneSurveyAction, getSurveysForClonePickerAction } from '../action';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { RootState } from 'base/reducer/reducer';
import { useSelector } from 'react-redux';
import { AutoComplete } from 'components/Form'
import { ProjectSelect, ProjectPickerParams } from 'features/Project';
import { CopyModalForm } from './CopyModalForm';
import { publishSurveysChanged } from '../../surveysChanged';
import Button from 'components/Button/Button';

type Props = {
	disabled?: boolean
}

export const CopySurvey = ({ disabled }: Props) => {
	const params: ProjectPickerParams = useParams();
	const projectId = parseInt(params.projectId as string);

	const { persistedProject } = useSelector((state: RootState) => state);
	const [selectedProject, setSelectedProject] = useState<ProjectResponse | undefined>();

	const [surveysPickerLoading, setSurveysPickerLoading] = useState(false);
	const [surveys, setSurveys] = useState<SurveyReducedResponse[]>([]);
	const [selectedSurvey, setSelectedSurvey] = useState<SurveyReducedResponse | undefined>();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModalCallback = useCallback(
		() => setIsModalOpen(true),
		[]
	);

	const closeModalCallback = useCallback(
		() => {
			setIsModalOpen(false);
			setSelectedSurvey(undefined);
		},
		[]
	);

	useEffect(
		() => {
			const project = persistedProject.isInitialized && !persistedProject.fetching && persistedProject.itemsMap[projectId]
			if (project) {
				setSelectedProject(project)
			}
		},
		[persistedProject, projectId]
	)

	const selectedProjectChangedCallback = useCallback(
		(projectId: number) => {
			const project = persistedProject.itemsMap[projectId]
			setSelectedProject(project)
		},
		[persistedProject.itemsMap]
	)

	const selectedSurveyChangedCallback = useCallback(
		(surveyId: number) => {
			const survey = surveys?.find(x => x.id === surveyId)
			setSelectedSurvey(survey)
		},
		[surveys]
	)

	const fetchSurveysCallback = useCallback(
		async () => {
			if (!selectedProject?.id) {
				setSurveys([]);
				return;
			}

			setSurveysPickerLoading(true);
			const bindedAction = getSurveysForClonePickerAction.bind(null, selectedProject.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setSurveys(response.items || []);
			}
			setSurveysPickerLoading(false);
		},
		[selectedProject?.id]
	)

	useEffect(
		() => {
			fetchSurveysCallback();
		},
		[fetchSurveysCallback]
	)

	const saveCallback = useCallback(
		async (survey: SurveyReducedResponse) => {
			const cloneSurveyRequest = new CloneSurveyRequest({
				...survey,
				copyFromId: survey.id
			});
			const bindedAction = cloneSurveyAction.bind(null, projectId, cloneSurveyRequest);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const formattedCopyId = getFormatedId(EntityPrefixEnum.SURVEY, response.value?.id);
				const formattedCopiedId = getFormatedId(EntityPrefixEnum.SURVEY, selectedSurvey?.id);
				notifications.success(`Survey ${formattedCopyId} is created as copy of ${formattedCopiedId}.`);
				publishSurveysChanged();
				closeModalCallback();
				// clear modal field after submit
				setSelectedSurvey(undefined);
			}
		},
		[projectId, selectedSurvey, closeModalCallback]
	)

	const renderClonePickerCallback = useCallback(
		() => (
			<ColumnContainer>
				<div>Select a survey to copy from</div>
				<SmartContainer>
					<SmartItem>
						<ProjectSelect
							value={selectedProject?.id}
							onChange={selectedProjectChangedCallback}
							showCompleted
							moduleEnum={ModuleActivityEnum.Training}
						/>
						<SmartFormGroup label='Survey'>
							<AutoComplete
								disabled={!selectedProject?.id}
								value={selectedSurvey?.id}
								onChange={selectedSurveyChangedCallback}
								items={surveys || []}
								getItemId={(item: SurveyReducedResponse) => item.id}
								getItemText={(item: SurveyReducedResponse) => `${getFormatedId(EntityPrefixEnum.SURVEY, item.id)} - ${item.name}`}
								loading={persistedProject.fetching || surveysPickerLoading}
							/>
						</SmartFormGroup>
					</SmartItem>
				</SmartContainer>
				<div></div> {/* empty div just for next ColumnContainer Item to have margin-top */}
			</ColumnContainer>
		),
		[selectedProject?.id, persistedProject.fetching, selectedProjectChangedCallback, surveysPickerLoading, selectedSurveyChangedCallback, surveys, selectedSurvey]
	)

	return (
		<>
			<Button
				text='Copy'
				onClick={openModalCallback}
				disabled={disabled}
			/>
			<CopyModalForm
				open={isModalOpen}
				cancel={closeModalCallback}
				surveyToClone={selectedSurvey}
				onSave={saveCallback}
				renderClonePicker={renderClonePickerCallback}
			/>
		</>
	)
}
