import { RootState } from 'base/reducer/reducer'
import { Dashboard } from 'features/Dashboard/Dashboard'
import { Layout } from 'features/Dashboard/Layout'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget'
import { TextWidget } from 'features/Dashboard/Widget/TextWidget'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TabComponentProps } from '../../FilteredDashboard'
import { InvoicesPlanVsActualWidget } from './InvoicesPlanVsActual/InvoicesPlanVsActualWidget'
import { useFormattedCurrencyValueCallback } from 'features/TableColumns/persistedHooks';

export const PartnersInvoicesDashboard = ({ loading, dashboard, filterModel }: TabComponentProps) => {
	const { persistedBusinessPartner } = useSelector((state: RootState) => state);

	const useFormattedCurrencyValue = useFormattedCurrencyValueCallback();

	const totalOutgoingPerPlannedAndActualDataMemo = useMemo(
		() => {
			return Object.keys(dashboard.partnersInvoices?.outgoingInvoicesPerPlannedActual || {}).map(item => {
				return  {
					name: item,
					count: dashboard.partnersInvoices?.outgoingInvoicesPerPlannedActual![item],
				}
			})
		},
		[dashboard]
	);

	const totalIncomingPerPlannedAndActualDataMemo = useMemo(
		() => {
				return Object.keys(dashboard.partnersInvoices?.incomingInvoicesPerPlannedActual || {}).map(item => {
					return  {
						name: item,
						count: dashboard.partnersInvoices?.incomingInvoicesPerPlannedActual![item],
					}
				})
			},
		[dashboard]
	);

	const rotatedPlannedColumnSeriesDataMemo = useMemo(
		() => {
			const data: any[] = [];

			for (const businessPartnerId in dashboard.partnersInvoices?.plannedIncomingOutgoingPerPartner) {
				const object = dashboard.partnersInvoices?.plannedIncomingOutgoingPerPartner[businessPartnerId];
				data.push({
					name: persistedBusinessPartner.itemsMap[parseInt(businessPartnerId)]?.name,
					incoming: object?.incoming ? -object.incoming : undefined,
					outgoing: object?.outgoing
				});
			}

			return data;
		},
		[dashboard, persistedBusinessPartner]
	);

	const rotatedActualColumnSeriesDataMemo = useMemo(
		() => {
			const data: any[] = [];

			for (const businessPartnerId in dashboard.partnersInvoices?.actualIncomingOutgoingPerPartner) {
				const object = dashboard.partnersInvoices?.actualIncomingOutgoingPerPartner[businessPartnerId];
				data.push({
					name: persistedBusinessPartner.itemsMap[parseInt(businessPartnerId)]?.name,
					incoming: object?.incoming ? -object.incoming : undefined,
					outgoing: object?.outgoing
				});
			}

			return data;
		},
		[dashboard, persistedBusinessPartner]
	);

	const rotatedColumnSeriesFieldsMemo = useMemo(
		() => {
			return [
				{ value: 'incoming', name: 'Incoming' },
				{ value: 'outgoing', name: 'Outgoing' }
			]
		},
		[]
	);

  	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='horizontal'>
				<TextWidget
					className='col-lg-2 col-4'
					title='Total incoming (plan)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.totalIncomingPlanned && -dashboard.partnersInvoices?.totalIncomingPlanned)}
					loading={loading}
					toFixed
				/>
				<TextWidget
					className='col-lg-2 col-4'
					title='Total incoming (actual)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.totalIncomingActual && -dashboard.partnersInvoices?.totalIncomingActual)}
					loading={loading}
					toFixed
				/>
				<TextWidget
					className='col-lg-2 col-4'
					title='Overdue (1-30 days)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.overdue1DayIncoming && -dashboard.partnersInvoices?.overdue1DayIncoming)}
					loading={loading}
					toFixed
				/>
				<TextWidget
					className='col-lg-2 col-4'
					title='Overdue (31-60 days)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.overdue30DaysIncoming && -dashboard.partnersInvoices?.overdue30DaysIncoming)}
					loading={loading}
					toFixed
				/>
				<TextWidget
					className='col-lg-2 col-4'
					title='Overdue (61-90 days)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.overdue60DaysIncoming && -dashboard.partnersInvoices?.overdue60DaysIncoming)}
					loading={loading}
					toFixed
				/>
				<TextWidget
					className='col-lg-2 col-4'
					title='Overdue (>90 days)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.overdue90DaysIncoming && -dashboard.partnersInvoices?.overdue90DaysIncoming)}
					loading={loading}
					toFixed
				/>
			</Layout>
			<Layout orientation='horizontal'>
				<TextWidget
					className='col-lg-2 col-4'
					title='Total outgoing (plan)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.totalOutgoingPlanned)}
					loading={loading}
					toFixed
				/>
				<TextWidget
					className='col-lg-2 col-4'
					title='Total outgoing (actual)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.totalOutgoingActual)}
					loading={loading}
					toFixed
				/>
				<TextWidget
					className='col-lg-2 col-4'
					title='Overdue (1-30 days)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.overdue1DayOutgoing)}
					loading={loading}
					toFixed
				/>
				<TextWidget
					className='col-lg-2 col-4'
					title='Overdue (31-60 days)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.overdue30DaysOutgoing)}
					loading={loading}
					toFixed
				/>
				<TextWidget
					className='col-lg-2 col-4'
					title='Overdue (61-90 days)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.overdue60DaysOutgoing)}
					loading={loading}
					toFixed
				/>
				<TextWidget
					className='col-lg-2 col-4'
					title='Overdue (>90 days)'
					value={useFormattedCurrencyValue(dashboard.partnersInvoices?.overdue90DaysOutgoing)}
					loading={loading}
					toFixed
				/>
			</Layout>
			<Layout orientation='horizontal'>
					<RotatedColumnSeriesWidget
						className='col-lg-6'
						title='Planned Outgoing/Incoming per partner'
						id='planned_outgoging_incoming_per_partners_column_series_chart'
						data={rotatedPlannedColumnSeriesDataMemo}
						fieldCategory='name'
						categoryTitle=''
						stacked
						fieldValues={rotatedColumnSeriesFieldsMemo}
						loading={loading}
						showValueOnBar
					/>
					<RotatedColumnSeriesWidget
						className='col-lg-6'
						title='Actual Outgoing/Incoming per partner'
						id='actual_outgoging_incoming_per_partners_column_series_chart'
						data={rotatedActualColumnSeriesDataMemo}
						fieldCategory='name'
						categoryTitle=''
						stacked
						fieldValues={rotatedColumnSeriesFieldsMemo}
						loading={loading}
						showValueOnBar
					/>
			</Layout>
			<Layout orientation='horizontal'>
				<PieWidget
					className='col-lg-6'
					title='Total Outgoing (planned vs actual)'
					id='total_outgoing_per_invoice_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={totalOutgoingPerPlannedAndActualDataMemo || []}
					loading={loading}
				/>
				<PieWidget
					className='col-lg-6'
					title='Total Incoming (planned vs actual)'
					id='total_incoming_per_invoice_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={totalIncomingPerPlannedAndActualDataMemo || []}
					loading={loading}
				/>
			</Layout>
			<Layout orientation='horizontal'>
				<InvoicesPlanVsActualWidget
					budgetVsActualIncomingInvoices={dashboard.partnersInvoices?.budgetVsActualIncomingInvoices || []}
					budgetVsActualOutgoingInvoices={dashboard.partnersInvoices?.budgetVsActualOutgoingInvoices || []}
					filterModel={filterModel}
					loading={loading}
				/>
			</Layout>
		</Dashboard>
  	)
}
