import { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterProps, getRouteObject } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { Scope } from './Scope/Scope';
import { ConfigureTickets } from './Tickets/ConfigureTickets';
import { ConfigureProjects } from '../Projects/ConfigureProjects/ConfigureProjects';
import { ConfigureTimeAndTravel } from './TimeAndTravel/ConfigureTimeAndTravel';
import { ConfigureFinance } from './Finance/ConfigureFinance';
import { ConfigureCommunication } from './Communication/ConfigureCommunication ';
import { ModuleSubRoutesEnum, communicationSubRoute, configureProjectSubRoute, financeSubRoute, riskSubRoute, scopeSubRoute, ticketsSubRoute, timeAndTravelSubRoute } from './routes';
import { ConfigureRisk } from './Risk/ConfigureRisk';

export const ModuleRouter = ({ route }: RouterProps) => {
	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[ModuleSubRoutesEnum.CONFIGURE_PROJECT] &&
				<Route
					path={configureProjectSubRoute.url}
					render={() => <ConfigureProjects />}
				/>
			}
			{routesMap[ModuleSubRoutesEnum.TICKETS] &&
				<Route
					path={ticketsSubRoute.url}
					render={() => <ConfigureTickets />}
				/>
			}
			{routesMap[ModuleSubRoutesEnum.TIME] &&
				<Route
					path={timeAndTravelSubRoute.url}
					render={() => <ConfigureTimeAndTravel />}
				/>
			}
			{routesMap[ModuleSubRoutesEnum.COMMUNICATION] &&
				<Route
					path={communicationSubRoute.url}
					render={() => <ConfigureCommunication />}
				/>
			}
			{routesMap[ModuleSubRoutesEnum.SCOPE] &&
				<Route
					path={scopeSubRoute.url}
					render={() => <Scope />}
				/>
			}
			{routesMap[ModuleSubRoutesEnum.FINANCE] &&
				<Route
					path={financeSubRoute.url}
					render={() => <ConfigureFinance />}
				/>
			}
			{routesMap[ModuleSubRoutesEnum.RISK] &&
				<Route
					path={riskSubRoute.url}
					render={() => <ConfigureRisk />}
				/>
			}

			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
