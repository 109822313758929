import { HelpShell } from 'features/Help/HelpShell';

const RiskPlanHelp = () => {
	return (
		<HelpShell title='Risks'>
			<p>
				Risk Plan is the central collection of all identified project risks. It is used over the entire project lifecycle. The project risk register is filled out by the project manager or another appointed user who is knowledgeable in the areas of risk identification, risk planning, and risk mitigation.
			</p>
			<p>
				Risk plan can be used as a simple list with descriptive information about potential risks and mitigation actions. In case you decided to manage your risk in simple manner, you can use only <u>risk register</u>. When assigning project team members, you can allow other users to access this simplified information from risk plan.
			</p>
			<p>
				In case that you want to measure risks and calculate costs that you would like to include in project budget, you can analyze risks by estimating gross impact and probability, mitigation type, cost of mitigation, Gross impact after mitigation and probability after mitigation. Based on these inputs, following is calculated:
			</p>
			<div>
				<ol>
					<li>
						<u>Net impact</u>: The net impact of a risk is calculated based on the entered Gross Impact and the corresponding likelihood for the risk to actually occur during the course of the project. The calculation is done as a simple multiplication of gross impact and probability.
					</li>
					<li>
						<u>Net impact after mitigation</u>: The net impact after mitigation describes the weighted impact of the risk and considers the selected mitigation type. If 'none' has been selected as mitigation type, then the project managers decided that no mitigation actions shall be taken. Hence the Net impact after mitigation will equal the product of Gross Impact and Probability (before mitigation). If 'preventive' has been selected as mitigation type, then the project manager decided to take preventive action in form of the specified mitigation actions. Therefore, the likelihood of occurrence as well as the gross impact of that risk might have changed. The Net Impact After Mitigation is therefore equal to the product of Gross Impact After Mitigation and Probability After Mitigation. If 'corrective' has been selected as mitigation type, then the project manager has no influence on the likelihood anymore. However, the risk can still be mitigated by executing the specified mitigation actions. Therefore, the gross impact after mitigation should be less than the original gross impact. The Net Impact after Mitigation is then simply the product of original probability and gross impact after mitigation.
					</li>
					<li>
						<u>Cost benefit Analysis</u>: The Cost Benefit Analysis provides an indicator if the chosen mitigation type is wise. If the Cost Benefit Analysis is zero, it doesn't make a difference between mitigation and no mitigation since the net impact before mitigation equals the cost of mitigation plus the net impact after mitigation. If the cost benefit analysis is positive, then the chosen mitigation type has a financially positive effect. This is often the case if risks actually occur in a project. If the cost benefit analysis is negative then the chosen mitigation type is not efficient. This might occur if mitigation activities are too expensive.
					</li>
					<li>
						<u>Risk to be included in budget</u>: The amount of money that need to be included in (risk) reports and project budget. If mitigation type was 'none', then the original net impact needs to be included. The project manager chose to take the risk. If mitigation type was 'preventive' or 'corrective', then the risk to be included in budget equals the cost of mitigation plus the calculated net impact after mitigation for that specific risk.
					</li>
				</ol>
			</div>
		</HelpShell>
	)
}

export default RiskPlanHelp;
