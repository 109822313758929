import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoCompleteField, CheckboxField, Form } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ChangeRequestStatusResponse, ModuleActivityEnum, PriorityResponse, ScopeChangeRequestsDashboardFilterRequest, ScopePermission, TenantIsActiveResponse } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { propertyOf } from 'utils/propertyOf';
import Button from 'components/Button';
import { ProjectSelectField, pmOrSpmOrOumPermission } from 'features/Project';

export class FilterModel extends ScopeChangeRequestsDashboardFilterRequest {
	projectId!: number

	constructor(data?: FilterModel) {
		super(data);
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
    }
}

type Props = {
	onSubmit: (request: FilterModel) => Promise<void>
	filterFormModel?: FilterModel
	onSave: (filterFormModel: FilterModel) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const {
		persistedChangeRequestStatus,
		persistedChangeRequestType,
		persistedTicketPriority
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(new FilterModel(filterFormModel));

	useEffect(
		() => {
			setValues(new FilterModel(filterFormModel));
		},
		[filterFormModel]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new FilterModel(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectSelectField
							id={propertyOf<FilterModel>('projectId')}
							isRequired
							isProjectDashboard
							moduleEnum={ModuleActivityEnum.Scope}
							teamMemberPermission={propertyOf<ScopePermission>('dashboard')}
							userRolePermission={pmOrSpmOrOumPermission}
						/>
						<AutoCompleteField
							id={propertyOf<FilterModel>('statusId')}
							label='CR status'
							items={persistedChangeRequestStatus.items}
							getItemId={(item: ChangeRequestStatusResponse) => item.id}
							getItemText={(item: ChangeRequestStatusResponse) => item.name}
							loading={persistedChangeRequestStatus.fetching}
						/>
						<AutoCompleteField
							id={propertyOf<FilterModel>('typeId')}
							label='CR type'
							items={persistedChangeRequestType.items}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							loading={persistedChangeRequestType.fetching}
						/>
						<CheckboxField
							id={propertyOf<FilterModel>('goLiveCritical')}
							label='Go-live critical'
						/>
						<AutoCompleteField
							id={propertyOf<FilterModel>('priorityId')}
							label='Priority'
							items={persistedTicketPriority.items}
							getItemId={(item: PriorityResponse) => item.id}
							getItemText={(item: PriorityResponse) => item.name}
							loading={persistedTicketPriority.fetching}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
					disabled={!values.projectId}
				/>
			)}
		/>
	)
}
