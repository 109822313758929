import Button from "components/Button"
import { useCallback, useState } from "react"
import { InsertTicketCommnetRequest } from "services/tenantManagementService";
import { CreateTask } from "./Crud/CreateTask";

type Props = {
	disabled: boolean
	addTask(task: InsertTicketCommnetRequest): void
}

export const AddTaskButton = ({ disabled, addTask }: Props) => {
	const [isCreateOpened, setIsCreateOpened] = useState(false);

	const openDialogCallback = useCallback(
		() => setIsCreateOpened(true),
		[setIsCreateOpened]
	)

	return (
		<>
			<Button
				text='Add task'
				color='neutral'
				disabled={disabled}
				onClick={openDialogCallback}
			/>
			<CreateTask
				isOpened={isCreateOpened}
				setIsOpened={setIsCreateOpened}
				addTask={addTask}
			/>
		</>
	)
}
