import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud';
import { CreateAction } from './Crud/CreateAction';
import { UpdateAction } from './Crud/UpdateAction';

export const ActionRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/${CrudSubRoutesEnum.Create}`} component={CreateAction} />
			<Route path={`${path}/${CrudSubRoutesEnum.Update}/:actionId`} component={UpdateAction} />
		</Switch>
	)
}
