import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnContainer } from 'components/Layout';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { WithProjectPicker, pmOrSpmPermission } from 'features/Project';
import { TestCycleComponentProps, WithTestCyclePicker } from 'features/Testing/WithTestCyclePicker';
import TableButtons from './Table/TableButtons';
import { RemoteTable } from 'components/Table/Remote/RemoteTable';
import { ContentShell } from 'features/Content/ContentShell';
import { GenericFilterModelCollection, ModuleActivityEnum, MultiplyUpdateTestPlanRequest, ProjectResponse, TestingPermission, UpdateLevel3TestPlan, UpdateLevel4TestPlan } from 'services/tenantManagementService';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { useTableColumnsMemo, defaultProcessStepColumns } from './Table/tableColumns';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { Checkbox } from 'components/Form';
import { CustomTestPlanRowModel, mapToCustomTestPlanRowModel } from './Table/tableModel';
import { onCellEdit } from './Table/tableEditorHelper';
import { exportAction, getShowProcessStepsConfigAction, getTestPlansGenericAction, setAllIsActiveAction, updateShowProcessStepsConfigAction, updateTestPlansAction } from './actions';
import notifications from 'components/Notification/notification';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { persistTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { TestPlanHelp } from './Help/TestPlanHelp';
import { propertyOf } from 'utils/propertyOf';

const configureViewKey = 'test_plans';

const TestPlans = ({ testCycle, project, disabledEdit }: TestCycleComponentProps) => {
	const { persistedTeamMember, persistedConfigureView} = useSelector((state: RootState) => state);

	const [showProcessSteps, setShowProcessSteps] = useState<boolean | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const [updateRequest, setUpdateRequest] = useState(new MultiplyUpdateTestPlanRequest());
	const stateRef = useRef(new MultiplyUpdateTestPlanRequest());
	stateRef.current = updateRequest;

	const disabledTestPlanMaintain = useMemo(
		() => {
			return disabledEdit || !(isUserPmorSubstitutePmOrSiteAdmin(project.roleId) || project.permissions?.testingPermission?.maintainTestPlan);
		},
		[project, disabledEdit]
	)

	const tableColumns = useTableColumnsMemo(
		project.id,
		showProcessSteps,
		persistedConfigureView.value[configureViewKey],
		disabledTestPlanMaintain
	)

	useEffect(
		() => {
			if (!persistedTeamMember.projectMap[project.id]) {
				persistTeamMembersAction(project.id);
			}
		},
		[persistedTeamMember, project.id]
	)

	const fetchShowProcessStepsCallback = useCallback(
		async () => {
			const showProcessStepsResponse = await tryCatchJsonByAction(getShowProcessStepsConfigAction);
			setIsLoading(false);
			if (showProcessStepsResponse.success && showProcessStepsResponse.value) {
				setShowProcessSteps(showProcessStepsResponse.value.content);
			} else {
				//default value
				setShowProcessSteps(false);
			}
		},
		[]
	)

	const showProcessStepsChangedCallback = useCallback(
		async (newValue: boolean) => {
			setFiltersModel(new GenericFilterModelCollection());
			setIsLoading(true);

			// change configure view for table on checkbox change
			const visibleColumns = tableColumns.filter(column => column.visible);
			let columnFields: string[] = visibleColumns.map(column => column.field);
			if (newValue) {
				columnFields = [...columnFields, ...defaultProcessStepColumns];
			} else {
				columnFields = columnFields.filter(cf => !defaultProcessStepColumns.includes(cf as keyof CustomTestPlanRowModel));
			}

			setConfigureViewTableAction(configureViewKey, columnFields);

			// update show process steps configuration
			setShowProcessSteps(newValue);
			const bindedAction = updateShowProcessStepsConfigAction.bind(null, newValue);

			await tryCatchJsonByAction(bindedAction);
			setIsLoading(false);
		},
		[tableColumns]
	)

	useEffect(
		() => {
			fetchShowProcessStepsCallback();
		},
		[fetchShowProcessStepsCallback]
	)

	const saveCallback = useCallback(
		async () => {
			setIsLoading(true);
			const bindedAction = updateTestPlansAction.bind(null, project.id, testCycle.id, updateRequest);

			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Test plans successfully updated');
				setUpdateRequest(new MultiplyUpdateTestPlanRequest());
			}
			setIsLoading(false);
		},
		[updateRequest, project.id, testCycle.id]
	)

	// just a quick fix, memoFetchFunction was changed after project is changed and unneeded request was send
	const [currentProject, setCurrentProject] = useState(new ProjectResponse(project));
	useEffect(
		() => {
			setCurrentProject(new ProjectResponse(project));
		},
		[project]
	)

	const memoFetchFunction = useMemo(
		() => getTestPlansGenericAction.bind(null, currentProject.id, testCycle.id, !!showProcessSteps),
		[currentProject.id, testCycle, showProcessSteps]
	)

	const mapResponseMemo = useMemo(
		() => mapToCustomTestPlanRowModel.bind(null, showProcessSteps),
		[showProcessSteps]
	)

	const memoExportFunction = useMemo(
		() => exportAction.bind(null, currentProject.id, testCycle.id, !!showProcessSteps),
		[currentProject.id, testCycle, showProcessSteps]
	)

	const cellEditedCallback = useCallback(
		(cell: any) => {
			onCellEdit(cell);
			const property = cell.getField();
			const data: CustomTestPlanRowModel = cell.getData();
			const value = cell.getValue();
			// if duration is changed, change plannedTimeTo in updateRequest
			if (property === 'duration' && data.plannedTime) {
				data.plannedTimeTo = value ?  data.plannedTime + value : undefined;
			}
			let level3TestPlans = stateRef.current.level3TestPlans || [];
			let level4TestPlans = stateRef.current.level4TestPlans || [];
			if (defaultProcessStepColumns.includes(property)) {
				const found = level4TestPlans.find(l4tp => l4tp.id === data.childId);
				const changedObject = new UpdateLevel4TestPlan({...data, id: data.childId!});
				if (!found) {
					level4TestPlans = [...level4TestPlans, changedObject];
				} else {
					level4TestPlans = level4TestPlans.map(l4tp => l4tp.id === data.childId ? changedObject : l4tp);
				}
			} else {
				let found =  level3TestPlans.find(l3tp => l3tp.id === data.parentId);
				const changedObject = new UpdateLevel3TestPlan({...data, id: data.parentId!});
				if (!found) {
					level3TestPlans = [...level3TestPlans, changedObject];
				} else {
					level3TestPlans = level3TestPlans.map(l3tp => l3tp.id === data.parentId ? changedObject : l3tp);
				}
			}

			const newUpdateRequest = new MultiplyUpdateTestPlanRequest({
				level4TestPlans,
				level3TestPlans
			})
			setUpdateRequest(newUpdateRequest);
		},
		[]
	)

	const setIsActiveCallback = useCallback(
        async (isActive: boolean) => {
            setIsLoading(true);
            const bindedAction = setAllIsActiveAction.bind(null, currentProject.id, testCycle.id, isActive, filtersModel);
            const response = await tryCatchJsonByAction(bindedAction);
            if (response.success) {
                // triggers new fetch. Stupid i know...
                setFiltersModel(new GenericFilterModelCollection(filtersModel));
            }
            setIsLoading(false);
        },
        [currentProject.id, testCycle.id, filtersModel]
    )

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='Test plans'
			FloatingHelpComponent={TestPlanHelp}
		>
			<>
				{showProcessSteps !== undefined &&
					<ColumnContainer margin='medium'>
						<SmartContainer>
							<SmartItem>
								<SmartFormGroup label='Show process steps'>
									<Checkbox
										value={showProcessSteps}
										onChange={showProcessStepsChangedCallback}
										disabled={isLoading}
									/>
								</SmartFormGroup>
							</SmartItem>
						</SmartContainer>
						<TableButtons
							disabled={disabledTestPlanMaintain}
							onSave={saveCallback}
							onSetIsActive={setIsActiveCallback}
							tableColumns={tableColumns}
							configureViewKey={configureViewKey}
							filtersModel={filtersModel}
							exportFunction={memoExportFunction}
						/>
						<RemoteTable
							columns={tableColumns}
							filtersModel={filtersModel}
							filtersModelChanged={setFiltersModel}
							fetchFunction={memoFetchFunction}
							reorderColumns={reorderColumnsCallback}
							mapResponse={mapResponseMemo}
							cellEdited={cellEditedCallback}
							isLoading={isLoading}
						/>
					</ColumnContainer>
				}
			</>
		</ContentShell>
	)
}


const withTestCycle = WithTestCyclePicker(TestPlans);
const withProject = WithProjectPicker(withTestCycle, ModuleActivityEnum.Testing, false, propertyOf<TestingPermission>('viewTestPlan'), pmOrSpmPermission);

export default withProject;
