import Tabs from 'components/Tabs/Tabs';
import { configureRiskTabs } from './configureRiskTabs';
import { ContentShell } from 'features/Content/ContentShell';

export const ConfigureRisk = () => {
	return (
		<ContentShell
			title='Configure Risk'
		>
			<Tabs tabs={configureRiskTabs} />
		</ContentShell>
	)
}
