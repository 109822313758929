import { useState, useCallback } from 'react';
import FileSaver from 'file-saver';
import { ExportModel } from 'features/Export/ExportForm';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { createExportModel } from 'utils/exportUtils';
import ExportToFile from './ExportToFile';
import { tryCatchExportFileByAction } from 'utils/exportUtils';
import { BaseColumnModel } from 'components/Table';

type Props = {
	filtersModel: GenericFilterModelCollection
	tableColumns: BaseColumnModel[]
	exportAction(exportDataModel: ExportDataModel, exportModel?: ExportModel): Promise<FileResponse>
	title: string
	exportTypeOptions?: ExportDataFileTypeEnum[]
	forceApplyFilters?: boolean
	forceApplySort?: boolean
	forceUseOnlyVisible?: boolean
	forceExportOnlyCurrentPage?: boolean
	hideApplyFilters?: boolean
	hideApplySort?: boolean
	hideUseOnlyVisible?: boolean
	hideExportOnlyCurrentPage?: boolean
	createExportModelFunction?: (columns: BaseColumnModel[], filtersModel: GenericFilterModelCollection, exportModel: ExportModel) => ExportDataModel
}

export const Export = ({
	filtersModel,
	tableColumns,
	exportAction,
	title,
	exportTypeOptions,
	forceApplyFilters,
	forceApplySort,
	forceUseOnlyVisible,
	forceExportOnlyCurrentPage,
	hideApplyFilters,
	hideApplySort,
	hideUseOnlyVisible,
	hideExportOnlyCurrentPage,
	createExportModelFunction = createExportModel
}: Props) => {
	const [loadingExport, setLoadingExport] = useState(false);

	const exportCallback = useCallback(
		async (exportModel: ExportModel) => {
			const exportDataModel = createExportModelFunction(tableColumns, filtersModel, exportModel);
			exportDataModel.title = title;

			setLoadingExport(true);

			const bindedAction = exportAction.bind(null, exportDataModel, exportModel);
			const response = await tryCatchExportFileByAction(bindedAction);
			if (response.success && response.value) {
				FileSaver.saveAs(response.value.data, response.value.fileName);
			}

			setLoadingExport(false);
		},
		[filtersModel, tableColumns, exportAction, title, createExportModelFunction]
	)

	return (
		<ExportToFile
			onExport={exportCallback}
			loading={loadingExport}
			exportTypeOptions={exportTypeOptions}
			forceApplyFilters={forceApplyFilters}
			forceApplySort={forceApplySort}
			forceUseOnlyVisible={forceUseOnlyVisible}
			forceExportOnlyCurrentPage={forceExportOnlyCurrentPage}
			hideApplyFilters={hideApplyFilters}
			hideApplySort={hideApplySort}
			hideUseOnlyVisible={hideUseOnlyVisible}
			hideExportOnlyCurrentPage={hideExportOnlyCurrentPage}
		/>
	)
}
