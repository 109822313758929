import { HelpShell } from 'features/Help/HelpShell';

export const TicketStatusesHelp = () => {
	return (
		<HelpShell title='Ticket statuses'>
			<div>
				<p>Tickets can have 7 statuses:</p>
				<ul>
					<li>New</li>
					<li>Accepted</li>
					<li>In progress</li>
					<li>On-hold</li>
					<li>Resolved</li>
					<li>Rejected</li>
					<li>Closed</li>
				</ul>
			</div>
			<p>These statuses cannot be changed or deleted, but you can add explanation for each status.</p>
			<p>Status <b><u>'new'</u></b> is assigned to every created ticket as initial status. SLA response time is calculated until 
				ticket changes status from new to any other status.<br />

				Status <b><u>'accepted'</u></b> is assigned to ticket to indicate that ticket is acknowledged and should be assigned to 
				responsible person/group for resolution. This status is not mandatory and can be skipped.<br />

				During resolution period ticket has status <b><u>'in progress'</u></b>.<br />

				Ticket can be put on <b><u>'on-hold'</u></b> status if there is additional information that had to be provided by ticket 
				requestor. It can be configured that during this status response and resolution time is not calculated.<br />

				When ticket is <b><u>'resolved'</u></b>, responsible person is assigning status 'resolved' to ticket and ticket is 
				assigned back to user who created ticket or user that must perform solution testing.<br />

				User can confirm or reject solution by assigning status closed or rejected.
			</p>
			<ul>
				<li><b><u>'Closed'</u></b> tickets cannot be modified.</li>
				<li>When solution is <b><u>'rejected'</u></b>, ticked should be assigned back to responsible user for rework, 
				and full cycle is followed until ticket is closed.
				</li>
			</ul>
		</HelpShell>
	)
}
