import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeleteTenantIndetifyRequest, InsertTravelExpenseItemRequest, InsertTravelExpenseItemRequestUpdateTravelExpenseItemRequestDeleteTenantIndetifyRequestDeltaModel, TravelExpenseItemResponse, TravelExpenseResponse, UpdateTravelExpenseItemRequest, UpdateTravelExpenseRequest } from 'services/tenantManagementService';
import { getTravelExpenseAction,  updateTravelExpenseAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { createDelta, EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import TravelExpenseForm from './TravelExpenseForm';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = {
	travelExpenseId: string
}

const UpdateMyTravelExpense = ({ publishDataChanged }: UpdateComponentProps) => {
	const params: ParamType = useParams();
	const [travelExpense, setTravelExpense] = useState(new TravelExpenseResponse());
	const goBackFromUpdate = useGoBackFromUpdate();

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getTravelExpenseAction.bind(null, parseInt(params.travelExpenseId));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {

				setTravelExpense(response.value || new TravelExpenseResponse());
			}
		},
		[params.travelExpenseId]
	)

	const saveCallback = useCallback(
		async (newtTravelExpense: TravelExpenseResponse, isRelease: boolean, initialTravelExpense: TravelExpenseResponse) => {

			const travelExpenseItemsDelta: InsertTravelExpenseItemRequestUpdateTravelExpenseItemRequestDeleteTenantIndetifyRequestDeltaModel = createDelta<TravelExpenseItemResponse>
			(
				initialTravelExpense.travelExpenseItems || [],
				newtTravelExpense.travelExpenseItems || [],
				InsertTravelExpenseItemRequest,
				UpdateTravelExpenseItemRequest,
				InsertTravelExpenseItemRequestUpdateTravelExpenseItemRequestDeleteTenantIndetifyRequestDeltaModel,
				DeleteTenantIndetifyRequest
			);

			// fix removed attachments
			travelExpenseItemsDelta.update = travelExpenseItemsDelta.update?.map(tei => {
				const initialAttachementIds = initialTravelExpense.travelExpenseItems?.find(t => t.id === tei.id)?.attachments?.map(att => att.id);
				const currentAttachmentIds = newtTravelExpense.travelExpenseItems?.find(t => t.id === tei.id)?.attachments?.map(att => att.id);
				return new UpdateTravelExpenseItemRequest({
					...tei,
					removedAttachments: initialAttachementIds?.filter(att => !currentAttachmentIds?.includes(att)),
				})
			});

			const travelExpenseForUpdate = new UpdateTravelExpenseRequest({
				...newtTravelExpense,
				isRelease,
				travelExpenseItemsDelta
			});

			const bindedAction = updateTravelExpenseAction.bind(null, travelExpenseForUpdate);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TRAVEL_EXPENSE, response.value?.id);
				notifications.success(`Travel expense ${id} is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`Change travel expense - ${getFormatedId(EntityPrefixEnum.TRAVEL_EXPENSE, travelExpense.id)}`}>
				<TravelExpenseForm
					travelExpense={travelExpense}
					crud={CrudEnum.Update}
					onSave={saveCallback}
				/>
			</ContentShell>
		</WithFetch>
	)
}

export default UpdateMyTravelExpense;
