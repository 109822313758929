import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Form, MapMultiField, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ProjectTimeTravelDashboardFilterRequest, BusinessPartnerResponseModel, UserModel, ModuleActivityEnum } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { propertyOf } from 'utils/propertyOf';
import Button from 'components/Button';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';
import { ProjectOrCategoryMultiSelect, ProjectOrCategoryType, pmOrSpmPermission } from 'features/Project';
import { RangeMonthDateType, RangeMonthPicker } from 'components/Form/controls/DatePicker/RangeMonthPicker';

type Props = {
	filterFormModel?: ProjectTimeTravelDashboardFilterRequest
	onSubmit: (request: ProjectTimeTravelDashboardFilterRequest) => Promise<void>
	onSave: (filterFormModel: ProjectTimeTravelDashboardFilterRequest) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const [values, setValues] = useState(new ProjectTimeTravelDashboardFilterRequest(filterFormModel));
	const [onlyCompanyUsers, setOnlyCompanyUsers] = useState(false);
	const [projectOrCategories, setProjectOrCategories] = useState<ProjectOrCategoryType[]>();

	const {
		persistedUser,
		persistedBusinessPartner,
		persistedOrganizationalUnit,
	} = useSelector((state: RootState) => state);

	useEffect(
		() => {
			setValues(new ProjectTimeTravelDashboardFilterRequest(filterFormModel));

			const savedProjectOrCategories: ProjectOrCategoryType[] = [];
			for (let projectId of filterFormModel?.projectIds || []) {
				savedProjectOrCategories.push({
					projectOrCategoryId: projectId,
					isProjectConnected: true
				})
			}
			for (let categoryId of filterFormModel?.nonProjectCategoryIds || []) {
				savedProjectOrCategories.push({
					projectOrCategoryId: categoryId,
					isProjectConnected: false
				})
			}
			setProjectOrCategories(savedProjectOrCategories)
		},
		[filterFormModel]
	)

	useEffect(
		() => {
			if (onlyCompanyUsers) {
				setValues((state: ProjectTimeTravelDashboardFilterRequest) => new ProjectTimeTravelDashboardFilterRequest({
					...state,
					userIds: state.userIds?.filter((userId: number) => !persistedUser.itemsMap[userId]?.isGuest) || persistedUser.items.filter(user => !user.isGuest).map(user => user.id),
					partnerIds: []
				}))
			}
		},
		[onlyCompanyUsers, persistedUser, persistedBusinessPartner.itemsMap]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new ProjectTimeTravelDashboardFilterRequest(values);
			model.projectIds = []
			model.nonProjectCategoryIds = []

			for (let { isProjectConnected, projectOrCategoryId } of projectOrCategories || []) {
				if (isProjectConnected) {
					model.projectIds.push(projectOrCategoryId);
				} else {
					model.nonProjectCategoryIds.push(projectOrCategoryId);
				}
			}
			await onSubmit(model)
		},
		[values, onSubmit, projectOrCategories]
	)

	const onSaveCallback = useCallback(
		async () => {
			const model = new ProjectTimeTravelDashboardFilterRequest(values);
			model.projectIds = []
			model.nonProjectCategoryIds = []

			for (let { isProjectConnected, projectOrCategoryId } of projectOrCategories || []) {
				if (isProjectConnected) {
					model.projectIds.push(projectOrCategoryId);
				} else {
					model.nonProjectCategoryIds.push(projectOrCategoryId);
				}
			}
			await onSave(model)
		},
		[values, onSave, projectOrCategories]
	)

	const userOptionsMemo = useMemo(
		() => onlyCompanyUsers ? persistedUser.items.filter(user => !user.isGuest) : persistedUser.items,
		[onlyCompanyUsers, persistedUser.items]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectOrCategoryMultiSelect
							value={projectOrCategories}
							onChange={setProjectOrCategories}
							moduleEnum={ModuleActivityEnum.Time}
							isTimeAndTravelProjectDashboard
							isProjectDashboard
							userRolePermission={pmOrSpmPermission}
						/>
						<SmartFormGroup label='Period' isRequired>
							<RangeMonthPicker
								value={{startDate: values.from, endDate: values.to}}
								onChange={(newPeriod: RangeMonthDateType) => {
									setValues(new ProjectTimeTravelDashboardFilterRequest({
										...values,
										from: newPeriod.startDate!,
										to: newPeriod.endDate!
									}))
								}}
							/>
						</SmartFormGroup>
						<MultiSelectField
							id={propertyOf<ProjectTimeTravelDashboardFilterRequest>('userIds')}
							label='User'
							items={userOptionsMemo}
							getItemId={(item: UserModel) => item.id}
							getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
							loading={persistedUser.fetching}
						/>
						<MultiSelectField
							id={propertyOf<ProjectTimeTravelDashboardFilterRequest>('partnerIds')}
							label='Partner'
							disabled={onlyCompanyUsers}
							items={persistedBusinessPartner.items}
							getItemId={(item: BusinessPartnerResponseModel) => item.id}
							getItemText={(item: BusinessPartnerResponseModel) => item.name}
							loading={persistedBusinessPartner.fetching}
						/>
						<MapMultiField
							id={propertyOf<ProjectTimeTravelDashboardFilterRequest>('organizationalUnitIds')}
							label='Organizational Unit'
							items={convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined)}
							loading={persistedOrganizationalUnit.fetching}
						/>
						<SmartFormGroup>
							<Checkbox
								labelBefore='Only company users'
								value={onlyCompanyUsers}
								onChange={(newValue: boolean) => setOnlyCompanyUsers(newValue)}
							/>
						</SmartFormGroup>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
				/>
			)}
		/>
	)
}
