import { Document, Font, Page, StyleSheet, Image } from '@react-pdf/renderer';
import { IdNameResponse, NonProjectCategoryResponse, ProjectResponse, TenantResponseModel, TimeTravelStatusResponse, TravelRequestResponse, UserModel } from 'services/tenantManagementService';
import { PersistActiveItemsReducer, PersistItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import { TitleField } from 'components/Export';
import { TravelRequestDocumentForm } from './TravelRequestDocumentForm';

type Props = {
	travelRequest: TravelRequestResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedProject: PersistItemsReducer<ProjectResponse>
	persistedTimeTravelNonProjectCategories: PersistActiveItemsReducer<NonProjectCategoryResponse>
	persistedTimeAndTravelStatus: PersistItemsReducer<TimeTravelStatusResponse>
	persistedCountry: PersistItemsReducer<IdNameResponse>
	persistedCurrency: PersistItemsReducer<IdNameResponse>
	persistedTenant: PersistValueReducer<TenantResponseModel>
	base64Logo?: string
}

export const TravelRequestDocument = ({
	travelRequest,
	persistedUser,
	persistedProject,
	persistedTimeTravelNonProjectCategories,
	persistedTimeAndTravelStatus,
	persistedCountry,
	persistedCurrency,
	persistedTenant,
	base64Logo
}: Props) => {
	return (
		<Document>
			<Page wrap size="A4" style={styles.page}>
				{base64Logo && <Image style={{ marginLeft: 'auto', maxWidth:'100px', height: 'auto'}} src={base64Logo} /> }
				<TitleField title='Travel request' />
				<TravelRequestDocumentForm
					travelRequest={travelRequest}
					persistedUser={persistedUser}
					persistedProject={persistedProject}
					persistedTimeTravelNonProjectCategories={persistedTimeTravelNonProjectCategories}
					persistedTimeAndTravelStatus={persistedTimeAndTravelStatus}
					persistedCountry={persistedCountry}
					persistedCurrency={persistedCurrency}
					persistedTenant={persistedTenant}
				/>
			</Page>
		</Document>
	);
};

Font.register({
  family: 'Open Sans',
  fonts: [
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const styles = StyleSheet.create({
	page: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingHorizontal: 10,
		fontFamily: 'Open Sans'
	},
});



