import { useMemo } from "react";
import { useApplyStatusColorCallback, useFormattedProjectIdNameCallback, useStatusCallback, useTenantIsActiveCallback, useUsernameCallback } from "features/TableColumns/persistedHooks";
import { UserStatusResponse, UserModel, ProjectTeamMemberResponse, ProjectResponse, ProjectIsActiveResponse, ModuleActivityEnum } from "services/tenantManagementService";
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from "components/Table";
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useProjectsForTableFilterCallback } from "features/Project";

const defaultVisibleColumns = [
	propertyOf<ProjectTeamMemberResponse>('userId'),
	'fullName',
	'email',
	'partnerId',
	propertyOf<ProjectTeamMemberResponse>('projectId'),
	propertyOf<ProjectTeamMemberResponse>('projectTeamId'),
	propertyOf<ProjectTeamMemberResponse>('projectRoleId'),
	propertyOf<ProjectTeamMemberResponse>('statusId'),
	propertyOf<ProjectTeamMemberResponse>('validUntil')
]

export const useTableColumnsMemo = (
	visibleColumns: string[] = defaultVisibleColumns
) => {
	const {
		persistedUser,
		persistedUserStatus,
		persistedBusinessPartner,
		persistedProject,
		persistedProjectRole,
		persistedProjectTeam,
	} = useSelector((state: RootState) => state);

	const getUsername = useUsernameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedUserStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedUserStatus);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);
	const getProjectTeamName = useTenantIsActiveCallback(persistedProjectTeam);
	const getProjectRoleName = useTenantIsActiveCallback(persistedProjectRole);
	const { projects } = useProjectsForTableFilterCallback(ModuleActivityEnum.Project);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectTeamMemberResponse>('userId')]: {
					title: 'Username',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
				fullName: {
					title: 'Full Name',
					formatter: (cell: any) => {
						const data: ProjectTeamMemberResponse = cell.getData();
						const user = persistedUser.itemsMap[data.userId];
						return user ? `${user.firstName} ${user.lastName}` : '';
					},
					fieldType: FieldTypeEnum.None,
					disableSort: true
				},
				email: {
					title: 'Email',
					formatter: (cell: any) => {
						const teamMember: ProjectTeamMemberResponse = cell.getData();
						const user = persistedUser.itemsMap[teamMember.userId];
						return user?.email || '';
					},
					fieldType: FieldTypeEnum.None,
					disableSort: true
				},
				partnerId: {
					title: 'Partner',
					formatter: (cell: any) => {
						const teamMember: ProjectTeamMemberResponse = cell.getData();
						const user = persistedUser.itemsMap[teamMember.userId];
						if (!user || !user.partnerId) {
							return '';
						}
						const partner = persistedBusinessPartner.itemsMap[user.partnerId];
						return partner?.name || '';
					},
					fieldType: FieldTypeEnum.None,
					disableSort: true
				},
				[propertyOf<ProjectTeamMemberResponse>('projectId')]: {
					title: 'Project ID',
					formatter: (cell: any) => formatProjectIdName(cell.getValue()),
					dbFilterFieldPath: 'ProjectRefId',
					fieldType: FieldTypeEnum.Options,
					options: projects,
					getItemId: (option: ProjectResponse) => option.id,
					getItemText: (option: ProjectResponse) => formatProjectIdName(option.id)
				},
				[propertyOf<ProjectTeamMemberResponse>('projectTeamId')]: {
					title: 'Project Team',
					formatter: (cell: any) => getProjectTeamName(cell.getValue()),
					dbFilterFieldPath: 'ProjectTeamRefId',
					fieldType: FieldTypeEnum.Options,
					options: persistedProjectTeam.items,
					getItemId: (option: ProjectIsActiveResponse) => option.id,
					getItemText: (option: ProjectIsActiveResponse) => getProjectTeamName(option.id)
				},
				[propertyOf<ProjectTeamMemberResponse>('projectRoleId')]: {
					title: 'Project Role',
					formatter: (cell: any) => getProjectRoleName(cell.getValue()),
					dbFilterFieldPath: 'ProjectRoleRefId',
					fieldType: FieldTypeEnum.Options,
					options: persistedProjectRole.items,
					getItemId: (option: ProjectIsActiveResponse) => option.id,
					getItemText: (option: ProjectIsActiveResponse) => getProjectRoleName(option.id)
				},
				[propertyOf<ProjectTeamMemberResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					dbFilterFieldPath: 'StatusRefId',
					options: persistedUserStatus.items,
					getItemId: (option: UserStatusResponse) => option.id,
					getItemText: (option: UserStatusResponse) => option.name
				},
				[propertyOf<ProjectTeamMemberResponse>('validUntil')]: {
					title: 'Valid Until',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectTeamMemberResponse>('lastCostRate')]: {
					title: 'Last cost rate',
					fieldType: FieldTypeEnum.Currency,
					disableSort: true,
					disableFilter: true
				},
				[propertyOf<ProjectTeamMemberResponse>('lastSalesRate')]: {
					title: 'Last billing rate',
					fieldType: FieldTypeEnum.Currency,
					disableSort: true,
					disableFilter: true
				},
				[propertyOf<ProjectTeamMemberResponse>('rateValidFrom')]: {
					title: 'Rates valid from',
					fieldType: FieldTypeEnum.Date,
					disableSort: true,
					disableFilter: true
				},
				[propertyOf<ProjectTeamMemberResponse>('rateValidTo')]: {
					title: 'Rates valid to',
					fieldType: FieldTypeEnum.Date,
					disableSort: true,
					disableFilter: true
				},
				[propertyOf<ProjectTeamMemberResponse>('isSubstitutePm')]: {
					title: 'Substitute project manager',
					fieldType: FieldTypeEnum.Boolean,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ProjectTeamMemberResponse>('isPmOrSubstitutePm')]: {
					title: 'Project manager/Substitute',
					fieldType: FieldTypeEnum.None
				}
			}
		},
		[
			getUsername,
			getStatus,
			applyStatusColor,
			formatProjectIdName,
			getProjectRoleName,
			getProjectTeamName,
			persistedUser,
			persistedUserStatus,
			persistedBusinessPartner,
			persistedProjectRole,
			persistedProjectTeam,
			projects
		]
	)

	return useVisibleColumns(columns, visibleColumns);
}
