import { getTimeAndTravelStatusesAction, updateTimeAndTravelStatusesAction } from './action';
import { StatusResponseTable } from 'features/StatusResponse/StatusResponseTable';
import { TimeAndTravelStatusesHelp } from './TImeAndTravelStatusesHelp';
import { ContentShell } from 'features/Content/ContentShell';

export const TimeAndTravel = () => {
	return (
		<ContentShell
			InlineHelpComponent={TimeAndTravelStatusesHelp}
			showBreadcrumb={false}
		>
			<StatusResponseTable
				getConfigsAction={getTimeAndTravelStatusesAction}
				updateConfigsAction={updateTimeAndTravelStatusesAction}
			/>
		</ContentShell>
	)
}
