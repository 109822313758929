import { AutoCompleteField, TableField } from "components/Form"
import { VerticalSeparator } from "components/Layout"
import { GenericFilterModelCollection, TenantIsActiveResponse, TestPlanRowResponse, TicketResponse, TicketTestPlansResponse } from "services/tenantManagementService"
import { propertyOf } from "utils/propertyOf"
import { sortByString } from "utils/stringUtil"
import Button from 'components/Button';
import { useCallback, useEffect, useState } from "react"
import { getTestCyclesAction } from "containers/Testing/TestCycles/action"
import { tryCatchJsonByAction } from "utils/fetchUtils"
import { getTestPlansGenericAction } from "containers/Testing/TestPlans/actions"

type TestCycleMap = {
	[testCycleId: number]: TestPlanRowResponse[]
}

type Props = {
	values: TicketResponse
	setValues(values: TicketResponse): void
	isRead: boolean
}

export const TestPlans = ({ values, setValues, isRead }: Props) => {
	const [testCycles, setTestCycles] = useState<TenantIsActiveResponse[]>([]);
	const [testCycleMap, setTestCycleMap] = useState<TestCycleMap>({});

	useEffect(
		() => {
			const fetchData = async () => {
				const bindedAction = getTestCyclesAction.bind(null, values.projectId)
				const response = await tryCatchJsonByAction(bindedAction);
				if (response.success && response.items) {
					const activeItems = response.items.filter(item => item.isActive);
					setTestCycles(activeItems);
				}
			}

			// TODO: Reset test plans  on projectId change, but keep them after first render
			// if (!state?.projectId) {
			// 	setValues((state: TicketResponse) => {
			// 		return new TicketResponse({
			// 			...state,
			// 			testPlans: []
			// 		})
			// 	})
			// }

			if (!values.projectId) {
				setTestCycles([]);
			} else {
				fetchData();
			}

		},
		[values.projectId]
	)

	useEffect(
		() => {
			const fetchData = async (nonExistingCycle: TicketTestPlansResponse) => {
				const genericFilter = new GenericFilterModelCollection({
					offset: 0,
					limit: 1000,
				})
				const bindedAction = getTestPlansGenericAction.bind(null, values.projectId, nonExistingCycle.testCycleId, false, genericFilter);
				const response = await tryCatchJsonByAction(bindedAction);
				if (response.success && response.items) {
					const testPlansResponse = response.items.filter(item => item.isActive)
					setTestCycleMap((state: TestCycleMap) => {
						return {
							...state,
							[nonExistingCycle.testCycleId]: testPlansResponse
						}
					})
				}
			}

			const nonExistingCycle = values.testPlans?.find(testPlan => !testCycleMap[testPlan.testCycleId]);

			if (values.projectId && nonExistingCycle && nonExistingCycle.testCycleId) {
				fetchData(nonExistingCycle);
			}

		},
		[testCycleMap, values.projectId, values.testPlans]
	)

	const addTestCaseCallback = useCallback(
		() => {
			const testPlans = values.testPlans || [];
			const newValues = new TicketResponse({
				...values,
				testPlans: [...testPlans, new TicketTestPlansResponse()]
			})
			setValues(newValues);
		},
		[values, setValues]
	)

	const showAddButton = !isRead;
	const showTableField = !!values.testPlans?.length;

	return (
		<>
			{showAddButton &&
				<Button
					text='Add test case'
					onClick={addTestCaseCallback}
					color='neutral'
				/>
			}
			{(showAddButton && showTableField) && <VerticalSeparator margin='medium' />}
			{showTableField &&
				<TableField
					headers={[
						{ label: 'Test cycle', size: 6, isRequired: true },
						{ label: 'Test case ID', size: 6, isRequired: true }
					]}
					id={propertyOf<TicketResponse>('testPlans')}
					getRowData={(row: TicketTestPlansResponse) => {
						return {
							isDeletable: !isRead,
							fields: [
								<AutoCompleteField
									id='testCycleId'
									items={testCycles}
									getItemId={(item: TenantIsActiveResponse) => item.id}
									getItemText={(item: TenantIsActiveResponse) => item.name}
									getItemDescription={(item: TenantIsActiveResponse) => item.description}
									isRequired
								/>,
								<AutoCompleteField
									id='testPlanId'
									items={sortByString(testCycleMap[row.testCycleId] || [], 'level3ProcessId')}
									getItemId={(item: TestPlanRowResponse) => item.id}
									getItemText={(item: TestPlanRowResponse) => `${item.level3ProcessId} ${item.level3ProcessName}` || ''}
									isRequired
								/>,
							]
						}
					}}
				/>
			}
		</>
	)
}
