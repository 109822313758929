import { useCallback, useEffect, useState } from 'react';
import { CheckboxField, Form, InputField, SelectField, globalErrorKey } from 'components/Form';
import { ColumnContainer } from 'components/Layout';
import { SmartContainer, SmartFormGroup, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { IdNameResponse, TenantRegistrationRequestModel } from 'services/tenantManagementService';
import { TermsOfUseAndPrivacyPolicy } from 'containers/PrivacyPolicy/TermsOfUseAndPrivacyPolicy';
import { getTeamSizeAction, registerAction } from './action';
import { tryCatchJsonByAction, convertResponseErrors } from 'utils/fetchUtils';

type Props = {
	onSuccess: (registeredEmail: string) => void;
}

class Model extends TenantRegistrationRequestModel {
	agreeTermsOfUse: boolean = false;
}

const RegistrationForm = ({ onSuccess }: Props) => {
	const [values, setValues] = useState<Model>(new Model());
	const [teamSizes, setTeamSizes] = useState<IdNameResponse[]>([]);

	useEffect(
		() => {
			const fetchData = async () => {
				const response = await tryCatchJsonByAction(getTeamSizeAction);
				if (response.success) {
					setTeamSizes(response.items!);
				}
			}
			fetchData();
		},
		[]
	);

	const onSubmitCallback = useCallback(
		async () => {
			if (values.agreeTermsOfUse !== true) {
				return Promise.resolve({[globalErrorKey]: 'Please accept Mavles Terms and Conditions'});
			}

			const bindedAction = registerAction.bind(null, values);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				onSuccess(values.username);
			} else {
				return convertResponseErrors(response);
			}
		},
		[values, onSuccess]
	);

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			submitButtonText='Register'
			hideCancelButton
			disableUnsavedChangesGuard
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<SmartFormGroup label='Site address' isRequired>
								<SmartInline flex='unset'>
									<InputField
										id='domain'
										isRequired
										inline
										focus
									/>
									<span>.mavles.com</span>
								</SmartInline>
							</SmartFormGroup>
							<InputField
								id='email'
								label='Your work e-mail'
								isRequired
								isEmail
							/>
							<InputField
								id='phone'
								label='Phone number'
							/>
						</SmartItem>
						<SmartItem>
							<InputField
								id='username'
								label='Username'
								isRequired
							/>
							<InputField
								id='password'
								type='password'
								label='Password'
								isRequired
							/>
							<SelectField
								id='teamSizeId'
								label='Team size'
								isRequired
								items={teamSizes}
								getItemId={(currentTeamSize: IdNameResponse) => currentTeamSize.id}
								getItemText={(currentTeamSize: IdNameResponse) => currentTeamSize.name!}
								loading={teamSizes.length === 0}
							/>
						</SmartItem>
					</SmartContainer>
					<SmartInline flex='unset'>
						<CheckboxField
							id='agreeTermsOfUse'
							isRequired
						/>
						<TermsOfUseAndPrivacyPolicy />
					</SmartInline>
				</ColumnContainer>
			)}
		/>
	)
}

export default RegistrationForm;
