import { useCallback, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ProjectAccomplishmentResponse } from 'services/tenantManagementService';
import { CheckboxField, DateField, Form, InputField } from 'components/Form';
import { ReportItemStatusSelectField } from '../../ReportItemStatuses';

type Props = {
	accomplishment?: ProjectAccomplishmentResponse
	cancel(): void
	save(accomplishment: ProjectAccomplishmentResponse): void
}

export const MajorAccomplishmentForm = ({ accomplishment, cancel, save }: Props) => {
	const [values, setValues] = useState(accomplishment || new ProjectAccomplishmentResponse());

	const onSubmitCallback = useCallback(
		async () => await save(values),
		[save, values]
	)

	return (
		<Form
			values={values}
			initialValues={accomplishment}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<CheckboxField
							id='isActive'
							label='Active'
						/>
						<InputField
							id='name'
							label='Accomplishment'
							isRequired
						/>
						<DateField
							id='plannedDate'
							label='Planned date'
						/>
						<ReportItemStatusSelectField isRequired />
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
