import { XYChart, XYChartProps } from "features/Charts"
import { Widget, WidgetProps } from "./Widget"

type Props = WidgetProps & XYChartProps;

export const XYWidget = ({ ...props }: Props) => {
	return (
		<Widget
			{...props}
		>
			<XYChart {...props} />
		</Widget>
	)
}
