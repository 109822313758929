import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import { ProjectDeliverableResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createDeliverableAction } from '../action';
import { publishDeliverablePlanChanged } from '../deliverablePlanChanged';
import { DeliverablePlanForm } from '../DeliverablePlanForm';
import { Dialog } from 'components/Dialog';
import { ReportComponentParams } from '../../../Table/TableButtons';
import { useGoBackFromCreate } from 'features/Crud';
import { useParams } from 'react-router-dom';

export const CreateDeliverablePlan = () => {
	const params: ReportComponentParams = useParams();
	const reportId = parseInt(params.reportId);

	const goBackFromCreate = useGoBackFromCreate(true);

	const onSubmitCallback = useCallback(
		async (deliverable: ProjectDeliverableResponse) => {
			const bindedAction = createDeliverableAction.bind(null, reportId, deliverable);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('New Deliverable plan successfully created');
				goBackFromCreate();
				publishDeliverablePlanChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, reportId]
	);

	return(
		<Dialog
			title='Create Deliverable Plan'
			onClose={goBackFromCreate}
			open
		>
			<DeliverablePlanForm  save={onSubmitCallback} cancel={goBackFromCreate} />
		</Dialog>
	)
};
