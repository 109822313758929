import styles from './content.module.scss'

type Props = {
	title: string
}

const ContentTitle = ({ title }: Props) => {
	return (
		<div className={styles.header}>
			<h1>{title}</h1>
		</div>
	)
}

export default ContentTitle;
