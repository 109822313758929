import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { TenantIsActiveResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { AutoComplete } from 'components/Form';
import { Subtract } from 'utility-types';
import { ComponentProps, ProjectParamName, ProjectPickerParams } from 'features/Project';
import { ColumnContainer, VerticalSeparator } from 'components/Layout';
import { getTrainingCyclesAction } from 'containers/Training/TrainingCycles/actions';

type TrainingCycleHocProps = {
	trainingCycle: TenantIsActiveResponse
}

export type TrainingCycleComponentProps = TrainingCycleHocProps & ComponentProps;

export const TrainingCycleParamName = 'trainingCycleId';

export type TrainingCyclePickerParams = ProjectPickerParams & {
	[TrainingCycleParamName]?: string
}

export const WithTrainingCyclePicker = <P extends TrainingCycleComponentProps>(Component: React.ComponentType<P>) => {

	const HighOrderComponent = (props: Subtract<P, TrainingCycleHocProps>) => {
		const history = useHistory();
		const routematch = useRouteMatch();
		const { trainingCycleId }: TrainingCyclePickerParams = useParams();

		const [fetchingTrainingCycles, setFetchingTrainingCycles] = useState(false);
		const [trainingCycles, setTrainingCycles] = useState<TenantIsActiveResponse[]>([]);
		const [selectedTrainingCycleId, setSelectedTrainingCycleId] = useState<string | number>();

		useEffect(
			() => {
				setSelectedTrainingCycleId(undefined);
			},
			[props.project.id]
		)

		const selectedTrainingCycle = useMemo(
			() => {
				const id = selectedTrainingCycleId as number || (trainingCycleId ? parseInt(trainingCycleId) : undefined);
				if (!id) {
					return new TenantIsActiveResponse();
				}

				let trainingCycle = trainingCycles.find(item => item.id === id);
				if (!trainingCycle) {
					trainingCycle = new TenantIsActiveResponse();
				}

				return trainingCycle;
			},
			[trainingCycles, trainingCycleId, selectedTrainingCycleId]
		)

		const fetchTrainingCyclesCallback = useCallback(
			async () => {
				if (!props.project.id) {
					setTrainingCycles([]);
					return;
				}

				setFetchingTrainingCycles(true);

				const bindedAction = getTrainingCyclesAction.bind(null, props.project.id);
				const trainingCyclesResponse = await tryCatchJsonByAction(bindedAction);

				if (trainingCyclesResponse.success && trainingCyclesResponse.items) {
					const activeItems = trainingCyclesResponse.items.filter(tc => tc.isActive);
					setTrainingCycles(activeItems);
				}

				setFetchingTrainingCycles(false);
			},
			[props.project.id]
		)

		useEffect(
			() => {
				fetchTrainingCyclesCallback();
			},
			[fetchTrainingCyclesCallback]
		)

		const url = useMemo(
			() => {
				if (selectedTrainingCycleId) {
					let url = routematch.path.split(`/:${TrainingCycleParamName}`)[0] + '/' + selectedTrainingCycleId;
					url = url.replace(':'  + ProjectParamName, String(props.project.id));
					return url;
				}
			},
			[routematch.path, selectedTrainingCycleId, props.project.id]
		)

		useEffect(
			() => {
				if (url) {
					history.replace(url);
				}
			},
			[history, url]
		)

		const componentPropsMemo = useMemo(
			() => {
				return {
					...props,
					trainingCycle: selectedTrainingCycle
				} as P
			},
			[props, selectedTrainingCycle]
		)

		return (
			<>
				<VerticalSeparator margin='small' />

				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<SmartFormGroup label='Training cycle'>
								<AutoComplete
									value={selectedTrainingCycle.id}
									onChange={setSelectedTrainingCycleId}
									items={trainingCycles}
									getItemId={(item: TenantIsActiveResponse) => item.id}
									getItemText={(item: TenantIsActiveResponse) => item.name}
									loading={fetchingTrainingCycles}
								/>
							</SmartFormGroup>
						</SmartItem>
					</SmartContainer>
					{selectedTrainingCycle.id && <Component {...componentPropsMemo} />}
				</ColumnContainer>
			</>
		)
	}

	return HighOrderComponent;
}
