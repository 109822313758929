import { View, Image, StyleSheet } from '@react-pdf/renderer';
import { SubtitleField } from 'components/Export';
import { FileResponse } from 'services/tenantManagementService'

type Props = {
	attachments: FileResponse[] | undefined,
}

const ExpenseDocumentAttachments = ({ attachments }: Props) => {
	if (!attachments || attachments.length === 0) {
		return null;
	}

	console.log(attachments.map(x => URL.createObjectURL(x.data)))

	return (
		<View style={styles.container}>
			<SubtitleField title='Attachments' />
			{
				attachments.map((attachment, index) => (
					<Image
						key={index}
						style={styles.image}
						src={URL.createObjectURL(attachment.data)}
					/>
				))
			}
		</View>
	);
}

export default ExpenseDocumentAttachments;

const styles = StyleSheet.create({
	container: {
		paddingTop: 10,
	},
	image: {
		padding: 10,
		maxWidth:'300px',
		height: 'auto'
	}
});
