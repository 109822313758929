import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CreateTicket } from './ViewTickets/Crud/CreateTicket';
import { getRouteObject, RouterProps } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { persistTicketTypesAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketType/action';
import { persistTicketAssignedGroupsAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketAssignedGroup/action';
import { persistTicketCategoryAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketCategory/action';
import { persistTicketStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Tickets/action';
import { persistTicketImpactsAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/Impact/action';
import { persistTicketPriorityAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/action';
import { persistTicketUrgencyAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/Urgency/action';
import { persistTicketClosingIdAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketClosingId/action';
import { ViewTickets } from './ViewTickets/ViewTickets';
import { CrudRouter } from 'features/Crud';
import { ReadTicket } from './ViewTickets/Crud/ReadTicket';
import { UpdateTicket } from './ViewTickets/Crud/UpdateTicket';
import { TicketsDashboard } from './MyDashboard';
import { persistTimeAndTravelStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/TimeAndTravel/action';
import { persistTimesheetseneralConfigurationAction } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/TimesheetsGeneral/action';
import { persistTaskStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Tasks/action';
import { persistChangeRequestStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/ChangeRequest/action';
import { TicketsSubRoutesEnum, myDashboardSubRoute, viewTicketsSubRoute } from './routes';
import { persistOrganizationalUnitsAction } from 'containers/Configuration/SiteConfiguration/General/Company/OrganizationalUnits/action';
import { persistTicketEnvironmentsAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketEnvironment/action';

export const TicketsRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistTicketTypesAction();
			persistTicketEnvironmentsAction();
			persistTicketAssignedGroupsAction();
			persistTicketCategoryAction();
			persistTicketStatusesAction();
			persistTicketImpactsAction();
			persistTicketUrgencyAction();
			persistTicketPriorityAction();
			persistTicketClosingIdAction();

			persistTimesheetseneralConfigurationAction();
			persistTimeAndTravelStatusesAction();
			persistTaskStatusesAction();
			persistChangeRequestStatusesAction();
			persistOrganizationalUnitsAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<>
			<Switch>
				{routesMap[TicketsSubRoutesEnum.MY_DASHBOARD] &&
					<Route
						path={myDashboardSubRoute.url}
						render={() => <TicketsDashboard />}
					/>
				}
				{routesMap[TicketsSubRoutesEnum.TICKETS] &&
					<Route
						path={viewTicketsSubRoute.url}
						render={() => (
							<CrudRouter
								ListComponent={ViewTickets}
								CreateComponent={CreateTicket}
								ReadComponent={ReadTicket}
								UpdateComponent={UpdateTicket}
								paramName='ticketId'
							/>
						)}
					/>
				}

				<Route
					render={() => {
						return <DefaultContent route={route} />
					}}
				/>
			</Switch>
		</>
	)
}
