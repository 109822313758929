import { ProjectChangeRequestClient, InsertProjectChangeRequestRequest , UpdateProjectChangeRequestRequest, GenericFilterModelCollection, ExportDataModel } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';
import { getUserTableViewConfigAction, updateUserTableViewConfigAction } from "features/UserTableViewConfig/action";

export const getChangeRequestsAction = (reportId: number, copyFromScope: boolean) => {
	const tenantId = getTenantId()!;
	const client = new ProjectChangeRequestClient();
	return client.getAll(tenantId, reportId, undefined, copyFromScope, undefined, undefined);
}

export const getChangeRequestsGenericAction = (reportId: number, copyFromScope: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectChangeRequestClient();
	return client.getAllGenericFilter(tenantId, reportId, copyFromScope, genericFilter);
}

export const getChangeRequestsGenericCountsAction = (reportId: number, copyFromScope: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectChangeRequestClient();
	return client.getAllGenericFilterCounts(tenantId, reportId, copyFromScope, genericFilter);
}

export const createChangeRequestAction = (reportId: number, model: InsertProjectChangeRequestRequest ) => {
	const tenantId = getTenantId()!;
	const client = new ProjectChangeRequestClient();
	return client.create(tenantId, reportId, model);
}

export const updateChangeRequestAction = (reportId: number, model: UpdateProjectChangeRequestRequest ) => {
	const tenantId = getTenantId()!;
	const client = new ProjectChangeRequestClient();
	return client.update(tenantId, reportId, model);
}

export const getChangeRequestAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectChangeRequestClient();
	return client.get(tenantId, reportId, id);
}

export const deleteChangeRequestAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectChangeRequestClient();
	return client.delete(tenantId, reportId, id);
}

export const exportAction = (reportId: number, copyFromScope: boolean, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectChangeRequestClient();
	return client.export(tenantId, reportId, copyFromScope, exportDataModel);
}

export const copyFromScopeAction = (reportId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectChangeRequestClient();
	return client.copyFromScope(tenantId, reportId);
}

// copy from scope config
const viewType = 'projectReportChangeRequestsCopyFromScope';

export const getCopyFromScopeChangeRequestConfigAction = () => {
	return getUserTableViewConfigAction(viewType)
}

export const updateCopyFromScopeChangeRequestConfigAction = (content: {[key: number]: boolean}) => {
	return updateUserTableViewConfigAction(viewType, content);
}
