import { useCallback, useMemo, useState } from 'react'
import { ColumnContainer, RowContainer } from 'components/Layout'
import { useTableColumnsMemo } from './tableColumns'
import { ProjectTimeTravelDashboardFilterRequest, ProjectTravelExpenseDashboardResponse } from 'services/tenantManagementService'
import TableButtons from './TableButtons'
import { exportTravelExpenseDashboardAction } from './action'
import { LocalTable } from 'components/Table'
import { setConfigureViewTableAction } from 'features/ConfigureView'
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer'
import { Radio } from 'components/Form'

type Props = {
	data: ProjectTravelExpenseDashboardResponse | undefined
	filterModel: ProjectTimeTravelDashboardFilterRequest
}

const configureViewKey = 'travel_expenses_dashboard_table';

export const TravelExpenseDashboardTable = ({ data, filterModel }: Props) => {
	const [showAll, setShowAll] = useState(false);

	const tableColumns = useTableColumnsMemo(configureViewKey, showAll);

	const memoExportFunction = useMemo(
		() => exportTravelExpenseDashboardAction.bind(null, !showAll, filterModel),
		[filterModel, showAll]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ColumnContainer margin='small'>
			<RowContainer>
				<SmartContainer>
					<SmartItem>
						<SmartFormGroup>
							<Radio
								value={showAll}
								items={[
									{ id: false, text: 'By user and project' },
									{ id: true, text: 'Expand all' },
								]}
								onChange={() => {setShowAll((state: boolean) => !state)}}
							/>
						</SmartFormGroup>
					</SmartItem>
				</SmartContainer>
			</RowContainer>
			<TableButtons
				tableColumns={tableColumns}
				configureViewKey={configureViewKey}
				exportFunction={memoExportFunction}
			/>
			<LocalTable
				columns={tableColumns}
				data={(showAll ? data?.travelExpenses : data?.groupedTravelExpenses) || []}
				hasPagination
				limit={10}
				reorderColumns={reorderColumnsCallback}
			/>
		</ColumnContainer>
	)
}
