import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoCompleteField, Form, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { TenantIsActiveResponse, SurveyDashboardFilterRequest, SurveyReducedResponse, ProjectTeamMemberResponse, TrainingPermission, ModuleActivityEnum } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { propertyOf } from 'utils/propertyOf';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { persistProjectTeamsAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Teams/action';
import { persistProjectRolesAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Roles/action';
import { getSurveysAction } from './action';
import { getAllTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import Button from 'components/Button';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { ProjectSelectField, pmOrSpmOrOumPermission } from 'features/Project';

export class FilterModel extends SurveyDashboardFilterRequest {
	projectId!: number
	surveyId!: number

	constructor(data?: FilterModel) {
		super(data);
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
    }
}

type Props = {
	onSubmit: (model: FilterModel) => Promise<void>
	filterFormModel?: FilterModel
	onSave: (filterFormModel: FilterModel) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const {
		persistedUser,
		persistedProjectTeam,
		persistedProjectRole
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const [values, setValues] = useState(new FilterModel(filterFormModel));

	useEffect(
		() => {
			setValues(new FilterModel(filterFormModel));
		},
		[filterFormModel]
	)

	useEffect(
		() => {
			const projectId = values.projectId;
			if (projectId) {
				persistProjectTeamsAction(projectId);
				persistProjectRolesAction(projectId);
			}
		},
		[values.projectId]
	)

	const [surveys, setSurveys] = useState<SurveyReducedResponse[]>([]);
	const [fetchingSurveys, setFetchingSurveys] = useState(false);

	const fetchSurveysCallback = useCallback(
		async (projectId: number) => {
			if (!projectId) {
				return;
			}
			setFetchingSurveys(true);

			const bindedAction = getSurveysAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setSurveys(response.items || []);
			}

			setFetchingSurveys(false);
		},
		[]
	)

	useEffect(
		() => {
			fetchSurveysCallback(values.projectId);
		},
		[values.projectId, fetchSurveysCallback]
	)

	const [users, setUsers] = useState<ProjectTeamMemberResponse[]>([]);
	const [fetchingUsers, setFetchingUsers] = useState(false);

	const fetchUsersCallback = useCallback(
		async (projectId: number) => {
			if (!projectId) {
				return;
			}
			setFetchingUsers(true);

			const bindedAction = getAllTeamMembersAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setUsers(response.items || []);
			}

			setFetchingUsers(false);
		},
		[]
	)

	useEffect(
		() => {
			fetchUsersCallback(values.projectId);
		},
		[values.projectId, fetchUsersCallback]
	)

	const onProjectChangeCallback = useCallback(
		(_newProjectId: number | undefined, _oldValues: FilterModel) => ({
			[propertyOf<FilterModel>('surveyId')]: undefined,
			[propertyOf<FilterModel>('userIds')]: undefined,
			[propertyOf<FilterModel>('projectTeamIds')]: undefined,
			[propertyOf<FilterModel>('projectRoleIds')]: undefined
		}),
		[]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new FilterModel(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectSelectField
							id={propertyOf<FilterModel>('projectId')}
							isRequired
							updateDependants={onProjectChangeCallback}
							isProjectDashboard
							moduleEnum={ModuleActivityEnum.Training}
							teamMemberPermission={propertyOf<TrainingPermission>('dashboard')}
							userRolePermission={pmOrSpmOrOumPermission}
						/>
						<AutoCompleteField
							id={propertyOf<FilterModel>('surveyId')}
							label='Survey ID'
							items={surveys}
							getItemId={(item: SurveyReducedResponse) => item.id}
							getItemText={(item: SurveyReducedResponse) => `${getFormatedId(EntityPrefixEnum.SURVEY, item.id)} - ${item.name}`}
							disabled={!values.projectId}
							loading={fetchingSurveys}
							isRequired
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('userIds')}
							label='User'
							items={users}
							getItemId={(item: ProjectTeamMemberResponse) => item.userId}
							getItemText={(item: ProjectTeamMemberResponse) => getUserFullName(item.userId)}
							disabled={!values.projectId}
							loading={fetchingUsers}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('projectTeamIds')}
							label='Project team'
							items={persistedProjectTeam.projectMap[values.projectId]?.items || []}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							disabled={!values.projectId}
							loading={persistedProjectTeam.projectMap[values.projectId]?.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('projectRoleIds')}
							label='Project role'
							items={persistedProjectRole.projectMap[values.projectId]?.items || []}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							disabled={!values.projectId}
							loading={persistedProjectRole.projectMap[values.projectId]?.fetching}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
					disabled={!values.projectId || !values.surveyId}
				/>
			)}
		/>
	)
}
