import { ExpenseResponse, NonProjectCategoryResponse, ProjectResponse, TimeTravelStatusResponse, UserModel } from 'services/tenantManagementService';
import { PersistActiveItemsReducer, PersistItemsReducer } from 'features/Persist/reducerTypes';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { FormField } from 'components/Export';
import { useFormattedProjectIdNameCallback } from 'features/TableColumns/persistedHooks';

type Props = {
	expense: ExpenseResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedProject: PersistItemsReducer<ProjectResponse>
	persistedTimeTravelNonProjectCategories: PersistActiveItemsReducer<NonProjectCategoryResponse>
	persistedTimeAndTravelStatus: PersistItemsReducer<TimeTravelStatusResponse>
}

const ExpenseDocumentForm = ({
	expense,
	persistedUser,
	persistedProject,
	persistedTimeTravelNonProjectCategories,
	persistedTimeAndTravelStatus,
}: Props) => {
	const user = persistedUser.itemsMap[expense.userId];
	const approvedByUser = expense.approvedOrRejectedByUserId ? persistedUser.itemsMap[expense.approvedOrRejectedByUserId] : undefined;
	const status = expense.statusId ? persistedTimeAndTravelStatus.itemsMap[expense.statusId] : undefined;
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	const formatedProjectOrCategory = expense.isProjectConnected ? formatProjectIdName(expense.projectOrCategoryId) : persistedTimeTravelNonProjectCategories.itemsMap[expense.projectOrCategoryId]?.name

	return (
		<>
			<FormField
				label='User'
				value={`${user?.firstName} ${user?.lastName}`}
			/>
			<FormField
				label={'Status'}
				value={status?.name}
				valueBackgroundColor={status?.color}
			/>
			<FormField
				label='Approved by'
				value={approvedByUser ? `${approvedByUser?.firstName} ${approvedByUser?.lastName}` : ''}
			/>
			<FormField
				label='Expense Id'
				value={getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, expense.id)}
			/>
			<FormField
				label='Project or category'
				value={formatedProjectOrCategory}
			/>
			<FormField
				label='Description'
				value={expense.description}
			/>
			<FormField
				label='Text 1'
				value={expense.customText1}
			/>
			<FormField
				label='Text 2'
				value={expense.customText2}
			/>
		</>
	);
};

export default ExpenseDocumentForm;
