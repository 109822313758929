import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { CrudEnum, CrudSubRoutesEnum, useGoBackFromCrudAndPush } from 'features/Crud';
import { InsertSurveyRequest, SurveyReducedResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { createSurveyAction } from './action';
import { useGoBackFromCrud } from 'features/Crud';
import CreateSurveyForm from './CreateSurveyForm';
import { ContentShell } from 'features/Content/ContentShell';
import { ProjectPickerParams } from 'features/Project';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { publishSurveysChanged } from '../surveysChanged';

const CreateSurvey = () => {
	const params: ProjectPickerParams = useParams();
	const projectId = parseInt(params.projectId as string);

	const goBackFromCrud = useGoBackFromCrud(CrudEnum.Create);
	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Create, undefined, undefined);

	const saveCallback = useCallback(
		async (survey: SurveyReducedResponse) => {
			const insertSurveyRequest = new InsertSurveyRequest(survey);
			const bindedAction = createSurveyAction.bind(null, projectId, insertSurveyRequest);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const formattedId = getFormatedId(EntityPrefixEnum.SURVEY, response.value?.id);
				notifications.success(`Survey ${formattedId} is created.`);
				publishSurveysChanged();
				if (response.value?.id) {
					goBackFromCrudAndPush(`${CrudSubRoutesEnum.Update}/${response.value?.id}`);
				} else {
					goBackFromCrud();
				}
			}
		},
		[projectId, goBackFromCrud, goBackFromCrudAndPush]
	)


	return (
		<ContentShell title='Create survey'>
			<CreateSurveyForm
				onSave={saveCallback}
				cancel={goBackFromCrud}
			/>
		</ContentShell>
	)
}

export default CreateSurvey;
