import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterProps, getRouteObject } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import MyTrainingPlan from './MyTrainingPlan/MyTrainingPlan';
import TrainingCycles from './TrainingCycles/TrainingCycles';
import TrainingPlan from './TrainingPlan/TrainingPlan';
import { persistTrainingCaseStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/TrainingStatus/action';
import MySurveysRouter from './MySurveys/MySurveysRouter';
import { TrainingDashboard } from './MyDashboard/TrainingDashboard';
import { CrudRouter } from 'features/Crud';
import Surveys from './Surveys/Surveys';
import CreateSurvey from './Surveys/Crud/CreateSurvey';
import UpdateSurvey from './Surveys/Crud/UpdateSurvey';
import { ProjectParamName } from 'features/Project';
import { TrainingCycleParamName } from 'features/Training/WithTrainingCyclePicker';
import { TrainingSubRoutesEnum, myDashboardSubRoute, mySurveySubRoute, myTrainingPlanSubRoute, surveyQuizSubRoute, trainingCyclesSubRoute, trainingPlanSubRoute } from './routes';

export const TrainingRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistTrainingCaseStatusesAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[TrainingSubRoutesEnum.MY_DASHBOARD] &&
				<Route
					path={myDashboardSubRoute.url}
					render={() => <TrainingDashboard />}
				/>
			}
			{routesMap[TrainingSubRoutesEnum.MY_TRAINING_PLAN] &&
				<Route
					// order of paths is important
					path={[
						`${myTrainingPlanSubRoute.url}/:${ProjectParamName}/:${TrainingCycleParamName}`,
						`${myTrainingPlanSubRoute.url}/:${ProjectParamName}`,
						myTrainingPlanSubRoute.url
					]}
					render={() => <MyTrainingPlan />}
				/>
			}

			{routesMap[TrainingSubRoutesEnum.MY_SURVEY] &&
				<Route
					path={mySurveySubRoute.url}
					render={() => <MySurveysRouter />}
				/>
			}

			{routesMap[TrainingSubRoutesEnum.TRAINING_CYCLES] &&
				<Route
					path={trainingCyclesSubRoute.url}
					render={() => <TrainingCycles />}
				/>
			}

			{routesMap[TrainingSubRoutesEnum.TRAINING_PLAN] &&
				<Route
					// order of paths is important
					path={[
						`${trainingPlanSubRoute.url}/:${ProjectParamName}/:${TrainingCycleParamName}`,
						`${trainingPlanSubRoute.url}/:${ProjectParamName}`,
						trainingPlanSubRoute.url
					]}
					render={() => <TrainingPlan />}
				/>
			}

			{routesMap[TrainingSubRoutesEnum.SURVEY_QUIZ] &&
				<Route
					path={[
						`${surveyQuizSubRoute.url}/:${ProjectParamName}`,
						surveyQuizSubRoute.url
					]}
					render={() => (
						<CrudRouter
							ListComponent={Surveys}
							CreateComponent={CreateSurvey}
							UpdateComponent={UpdateSurvey}
							paramName='surveyId'
						/>
					)}
				/>
			}

			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
