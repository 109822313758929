import { View, Text, StyleSheet } from '@react-pdf/renderer';

type Props = {
	label: string
	children: any
}

const FormGroup = ({ label, children }: Props) => (
	<View style={styles.container}>
		<Text style={styles.label}>
			{label}:
		</Text>
		{children}
	</View>
);

export default FormGroup;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		marginVertical: 5
	},
	label: {
		marginBottom: 4,
		fontSize: 10,
	},
});
