import { useCallback, useEffect, useState } from 'react';
import { AutoCompleteField, Form, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ModuleActivityEnum, ProjectDashboardFilterRequest, TenantIsActiveResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import Button from 'components/Button';
import { ProjectSelectField, pmOrSpmOrOumPermission } from 'features/Project';
import { getTestCyclesAction } from 'containers/Testing/TestCycles/action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTrainingCyclesAction } from 'containers/Training/TrainingCycles/actions';

type Props = {
	filterFormModel?: ProjectDashboardFilterRequest
	onSubmit: (request: ProjectDashboardFilterRequest) => Promise<void>
	onSave: (filterFormModel: ProjectDashboardFilterRequest) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const [values, setValues] = useState(new ProjectDashboardFilterRequest(filterFormModel));

	const [testCycles, setTestCycles] = useState<TenantIsActiveResponse[]>([]);
	const [isFetchingTestCycles, setIsFetchingTestCycles] = useState(false);

	const [trainingCycles, setTrainingCycles] = useState<TenantIsActiveResponse[]>([]);
	const [fetchingTrainingCycles, setFetchingTrainingCycles] = useState(false);

	const fetchTestCyclesDataCallback = useCallback(
		async () => {
			if (!values.projectId) {
				setTestCycles([]);
				return;
			}
			setIsFetchingTestCycles(true);

			const bindedAction = getTestCyclesAction.bind(null, values.projectId);
			const testCyclesResponse = await tryCatchJsonByAction(bindedAction);

			setIsFetchingTestCycles(false);

			if (testCyclesResponse.success && testCyclesResponse.items) {
				const activeItems = testCyclesResponse.items.filter(tc => tc.isActive);
				setTestCycles(activeItems);
			}
		},
		[values.projectId]
	)

	const fetchTrainingCyclesCallback = useCallback(
		async (projectId: number) => {
			if (!projectId) {
				return;
			}
			setFetchingTrainingCycles(true);

			const bindedAction = getTrainingCyclesAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.items) {
				const activeItems = response.items.filter(item => item.isActive);
				setTrainingCycles(activeItems);
			}

			setFetchingTrainingCycles(false);
		},
		[]
	)

	useEffect(
		() => {
			fetchTestCyclesDataCallback();
		},
		[fetchTestCyclesDataCallback]
	)

	useEffect(
		() => {
			fetchTrainingCyclesCallback(values.projectId);
		},
		[values.projectId, fetchTrainingCyclesCallback]
	)

	useEffect(
		() => {
			setValues(new ProjectDashboardFilterRequest(filterFormModel));
		},
		[filterFormModel]
	)

	const projectChangedCallback = useCallback(() => ({
		[propertyOf<ProjectDashboardFilterRequest>('trainingCycleIds')]: undefined,
		[propertyOf<ProjectDashboardFilterRequest>('testCycleIds')]: undefined,
	}), [])

	const onSubmitCallback = useCallback(
		async () => {
			const model = new ProjectDashboardFilterRequest(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectSelectField
							id={propertyOf<ProjectDashboardFilterRequest>('projectId')}
							isRequired
							isProjectDashboard
							moduleEnum={ModuleActivityEnum.Project}
							userRolePermission={pmOrSpmOrOumPermission}
							updateDependants={projectChangedCallback}
						/>
						<MultiSelectField
							id={propertyOf<ProjectDashboardFilterRequest>('testCycleIds')}
							label='Test cycles'
							items={testCycles}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							loading={isFetchingTestCycles}
							disabled={!values.projectId}
						/>
						<MultiSelectField
							id={propertyOf<ProjectDashboardFilterRequest>('trainingCycleIds')}
							label='Training cycles'
							items={trainingCycles}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							loading={fetchingTrainingCycles}
							disabled={!values.projectId}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
				/>
			)}
		/>
	)
}
