import { BudgetVsActualRowDashboardResponse, FinanceDashboardFilterRequest } from "services/tenantManagementService";
import { useTableColumnsMemo } from '../tableColumns'
import { useMemo } from "react";
import { LocalTableWidget } from "features/Dashboard/Widget/LocalTableWidget";

type Props = {
	budgetVsActualIncomingInvoices: BudgetVsActualRowDashboardResponse[]
	budgetVsActualOutgoingInvoices: BudgetVsActualRowDashboardResponse[]
	filterModel: FinanceDashboardFilterRequest
	loading: boolean
}

export const InvoicesPlanVsActualWidget = ({ budgetVsActualIncomingInvoices, budgetVsActualOutgoingInvoices, filterModel, loading }: Props) => {
	const incomingData = useMemo(
		() => {
			const data: BudgetVsActualRowDashboardResponse[] = [];
			for (const item of budgetVsActualIncomingInvoices) {
				data.push(new BudgetVsActualRowDashboardResponse({
					...item,
				    budgetValue: item.budgetValue ? -item.budgetValue : item.budgetValue,
				    actualBudgetValue: item.actualBudgetValue ? -item.actualBudgetValue : item.actualBudgetValue
				}))
			}

			return data;
		},
		[budgetVsActualIncomingInvoices]
	)

	const incomingTableColumns = useTableColumnsMemo(incomingData);
	const outgoingTableColumns = useTableColumnsMemo(budgetVsActualOutgoingInvoices);

	// we want pagination to work for years, so only one year is shown per page
	const tableMonthsLimit = filterModel.months?.length || 12;

	return (
		<>
			<LocalTableWidget
				title='Incoming invoices plan vs actual'
				className='col-lg-6'
				columns={incomingTableColumns}
				data={incomingData}
				hasPagination
				limit={tableMonthsLimit}
				loading={loading}
				compact
				options={{
					layout: 'fitColumns'
				}}
			/>
			<LocalTableWidget
				title='Outgoing invoices plan vs actual'
				className='col-lg-6'
				columns={outgoingTableColumns}
				data={budgetVsActualOutgoingInvoices}
				hasPagination
				limit={tableMonthsLimit}
				loading={loading}
				compact
				options={{
					layout: 'fitColumns'
				}}
			/>
		</>
	)
}
