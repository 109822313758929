import { useCallback, useEffect, useState } from 'react';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { OrganizationalUnitResponse } from 'services/tenantManagementService';
import { AutoComplete, Form, InputField } from 'components/Form';
import { propertyOf } from 'utils/propertyOf';
import { IdNameResponse } from 'services/tenantManagementService';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

type Props = {
	item?: OrganizationalUnitResponse
	onSave(item: any, countryId: number | string): void
	onCancel(): void
}

export const OrganizationalUnitForm = ({ item, onSave, onCancel }: Props) => {
	const [values, setValues] = useState(item || new OrganizationalUnitResponse());
	const [countryId, setCountryId] = useState<number | string>();

	const { persistedCountry } = useSelector((state: RootState) => state);

	useEffect(
		() => {
			setCountryId(persistedCountry.items.find(country => country.name === item?.name)?.id)
		},
		[persistedCountry.items, item]
	)

	const onSubmitCallback = useCallback(
		async () => await onSave(values, countryId as number),
		[onSave, values, countryId]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={onCancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						{values.levelNumber === 2 ?
							(
								<SmartFormGroup label='Country'>
									<AutoComplete
										value={countryId}
										onChange={setCountryId}
										items={persistedCountry.items}
										getItemId={(item: IdNameResponse) => item.id}
										getItemText={(item: IdNameResponse) => item.name}
										loading={persistedCountry.fetching}
									/>
								</SmartFormGroup>
							) : (
								<InputField
									id={propertyOf<OrganizationalUnitResponse>('name')}
									label='Name'
									isRequired
									focus
								/>
							)
						}
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
