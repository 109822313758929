import { HelpShell } from 'features/Help/HelpShell';

export const CreateSurveysHelp = () => {
	return (
		<HelpShell title='Surveys/Quiz'>
			<p>During project execution, you can define various quizzes and surveys in order to obtain feedback from project teams.</p>
			<p>You can copy surveys/quizzes from other projects or create your own.</p>
			<p>Surveys can help project managers to obtain individual views and experiences. When done well, surveys provide hard numbers on people's opinions and behaviors that can be used to make important decisions and improve execution of your project.</p>
			<div>
				<p>In <b>surveys</b>, questions are always rated with following options:</p>
				<ul>
					<li>Not satisfied (score 1)</li>
					<li>Room for improvement (score 2)</li>
					<li>Neutral (score 3)</li>
					<li>Satisfied (score 4)</li>
					<li>Very satisfied (score 5)</li>
				</ul>
			</div>
			<p>You can add questions that requires answer in free form. These are not scored.</p>
			<div>
				<p>In <b>quizzes</b> you can define 3 types of questions:</p>
				<ul>
					<li>Question with Multiple choice</li>
					<li>Question with Single choice</li>
					<li>Question which requires an Answer</li>
				</ul>
			</div>
			<p>You can also define if question is mandatory to be answered, and you can score them. <br />
				Multiple and single choices can be scored with points from 0 to 5. <br />
				Textual answers are not scored.
			</p>
			<p>Surveys/quizzes reports can be filtered based on following criteria: Project ID, Survey ID, User, Project team an project role. Selections in the filters can be saved for every user.</p>
			<div>
				<p>Filter is applied on all reports provided on Dashboard:</p>
				<ol>
					<li>Survey / Quiz Response - shows percentage of users completed survey or quiz</li>
					<li>Average score - shows average score of completed surveys or quiz</li>
					<li>Survey/quiz per project team - shows average score of completed surveys or quiz per project team</li>
					<li>Survey/quiz per project role - shows average score of completed surveys or quiz - per project role</li>
					<li>Score per question</li>
					<li>Text questions - shows user comments/explanations on text questions</li>
				</ol>
			</div>
		</HelpShell>
	)
}
