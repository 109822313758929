import { useCallback } from 'react';
import ProjectDataForm from './ProjectDataForm';
import notifications from 'components/Notification/notification';
import { ProjectResponse, ProjectResponseSimpleResponseModel, UpdateProjectDataRequest } from 'services/tenantManagementService';
import { updateProjectDataAction } from 'features/Project';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { publishProjectChanged } from '../../projectChanged';
import { Note } from 'components/Note/Note';
import { ColumnContainer } from 'components/Layout';

type Props = {
	project: ProjectResponse;
};

const ProjectData = ({ project }: Props) => {
	const saveCallback = useCallback(
		async (newProject: UpdateProjectDataRequest) => {
			const bindedAction = updateProjectDataAction.bind(null, newProject);
			const response : ProjectResponseSimpleResponseModel = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Project data is updated.');
				publishProjectChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[]
	);

	return (
		<ColumnContainer>
			<Note text='Note: project ID is created by site administrator.' />
			<ProjectDataForm
				project={project}
				onSave={saveCallback}
			/>
		</ColumnContainer>
	)
}

export default ProjectData;
