import { InvoiceResponse } from "services/tenantManagementService";

export const invoiceValidator = (values: InvoiceResponse) => {
	const invoiceItems = values.invoiceItems || [];
	let totalAmount = 0;
	let totalTaxAmount = 0;
	let totalLocalCurrencyAmount = 0;
	let totalLocalCurrencyTaxAmount = 0;

	let isLocalAdded = false;

	for (let i = 0; i < invoiceItems.length; i++) {
		const invoiceItem = invoiceItems[i];
		totalAmount += invoiceItem.amount;
		totalTaxAmount += invoiceItem.taxAmount;
		totalLocalCurrencyAmount  += invoiceItem.localCurrencyAmount || 0;
		totalLocalCurrencyTaxAmount += invoiceItem.localCurrencyTaxAmount || 0;
		
		if (invoiceItem.localCurrencyAmount || invoiceItem.localCurrencyTaxAmount) {
			isLocalAdded = true;
		}
	}

	if (isLocalAdded) {
		if (totalLocalCurrencyAmount !== values.localCurrencyAmount) {
			return 'Sum of Local amount in Invoice items must be equal with Total net amount in Amount in Local currency.';
		} else if (totalLocalCurrencyTaxAmount !== values.localCurrencyTaxAmount) {
			return 'Sum of Local tax in Invoice items must be equal with Total tax in Amount in Local currency.';
		}
	} else {
		if (totalAmount !== values.amount) {
			return 'Sum of Net amount in Invoice items must be equal with Total net amount in Amount in Project currency.';
		} else if (totalTaxAmount !== values.taxAmount) {
			return 'Sum of Tax in Invoice items must be equal with Total tax in Amount in Project currency';
		}
	}
	return '';
}
