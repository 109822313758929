import { HelpShell } from 'features/Help/HelpShell';

export const TimesheetsGeneralHelp = () => {
	return (
		<HelpShell title='Time General Configuration'>
			<p>
				In Timesheets General configuration you can enable approval process. This means that timesheets are approved by responsible project managers/s, but you can define if timesheets can be approved also by resource manager. <br />
				Timesheets are approved only for projects. If users time was allocated on several projects, timesheets will be distributed to all responsible project managers (or substitutes) for approval. 

			</p>
			<div>
				<p>In general configuration, you can also define:</p>
				<ul>
					<li>Approval frequency: timesheets are approved monthly, weekly or daily.</li>
					<li>Minimum record: minimum time that can be entered in timesheet.</li>
					<li>Minimum required hours per day (timesheets that do not have minimum required hours per day cannot be released for approval).</li>
					<li>
						Allow overtime, weekend and holiday work (this configuration will allow users to record time over minimum working hours per day, time during holidays defined in calendar and time during weekends). Please note that this could have impact on your project costs and user productivity.
						<ul>
							<li>Calculate productivity based on max working hours per day/week/month.</li>
						</ul>
					</li>
					<li>Require comments when rejecting timesheets.</li>
				</ul>
			</div>
			<p><u>Notifications</u> <br />
				By enabling approval process, you will also automatically enable emails exchange between users and project managers when timesheets are released for approval, rejected and approved.
			</p>
			<p>
				If your company would prefer not to use email exchange, you can enable Notifications in Mavles. Each user will receive notification in right upper corner of screen when timesheets are released for approval, rejected or approved.
			</p>
		</HelpShell>
	)
}
