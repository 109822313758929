import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useApplyStatusColorCallback, useFormattedProjectIdNameCallback, useStatusCallback, useUsernameCallback } from 'features/TableColumns/persistedHooks';
import { ModuleActivityEnum, ProjectResponse, TimesheetMergedResponse, TimeTravelStatusResponse, UserModel,} from 'services/tenantManagementService';
import { formatTimeWithoutAmOrPm } from 'utils/dateTimeUtils';
import { propertyOf } from 'utils/propertyOf';
import { RootState } from 'base/reducer/reducer';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { useProjectsForTableFilterCallback } from 'features/Project';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { findActiveModule } from 'features/Project';

const defaultVisibleColumns = [
    propertyOf<TimesheetMergedResponse>('statusId'),
    propertyOf<TimesheetMergedResponse>('userId'),
    propertyOf<TimesheetMergedResponse>('projectId'),
    propertyOf<TimesheetMergedResponse>('totalEffort'),
]

export const useTableColumnsMemo = ( configureViewKey: string) => {
	const {
		persistedTimeAndTravelStatus,
		persistedUser,
		persistedProject,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUsername = useUsernameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedTimeAndTravelStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedTimeAndTravelStatus);
	const { projects } = useProjectsForTableFilterCallback(ModuleActivityEnum.Time);

	const formatProjectIdName = useFormattedProjectIdNameCallback(persistedProject);

	const filteredProjects = useMemo(
		() => {
			return projects.filter(project => {
				const module = findActiveModule(ModuleActivityEnum.Time, project.activeModules);
				return module?.isActive && (isUserPmorSubstitutePmOrSiteAdmin(project.roleId) || project.permissions?.timeTravelPermission?.timesheets);
			});
		},
		[projects]
	)

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<TimesheetMergedResponse>('projectId')]: {
					title: 'Project Id',
					formatter: (cell: any) => formatProjectIdName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: filteredProjects,
					getItemId: (item: ProjectResponse) => item.id,
					getItemText: (item: ProjectResponse) => `${getFormatedId(EntityPrefixEnum.PROJECT, item.id)} - ${item.name}`
				},
				[propertyOf<TimesheetMergedResponse>('totalEffort')]: {
					title: 'Total hours',
					fieldType: FieldTypeEnum.Number,
					disableFilter: true,
					formatter: (cell: any) => formatTimeWithoutAmOrPm(cell.getValue()),
					bottomCalc: 'sum',
					bottomCalcFormatter: (cell: any) => `∑ ${formatTimeWithoutAmOrPm(cell.getValue()) || ''}`
				},
				[propertyOf<TimesheetMergedResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTimeAndTravelStatus.items,
					getItemId: (option: TimeTravelStatusResponse) => option.id,
					getItemText: (option: TimeTravelStatusResponse) => option.name
				},
				[propertyOf<TimesheetMergedResponse>('userId')]: {
					title: 'Created by',
					headerFilter: false,
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
			};
		},
		[getUsername, applyStatusColor, getStatus, formatProjectIdName, persistedTimeAndTravelStatus, persistedUser, filteredProjects]
	)

	return useVisibleColumns(columns, visibleColumns);
}
