import { MajorAccomplishment as MajorAccomplishments } from './MajorAccomplishments/MajorAccomplishments';
import { MilestonePlan } from './MilestonePlan/MilestonePlan';
import { DeliverablePlan } from './DeliverablePlan/DeliverablePlan';
import { ColumnContainer } from 'components/Layout';
import { CrudEnum } from 'features/Crud';
import { ProjectReportResponse } from 'services/tenantManagementService';

type Props = {
	report: ProjectReportResponse
	reportCrud: CrudEnum
}

export const PerformanceStatus = ({ report, reportCrud }: Props) => {
	return (
		<>
			<h2>Performance Status</h2>
			<ColumnContainer margin='large'>
				<MajorAccomplishments report={report} reportCrud={reportCrud} />
				<MilestonePlan report={report} reportCrud={reportCrud} />
				<DeliverablePlan report={report} reportCrud={reportCrud} />
			</ColumnContainer>
		</>
	)
}
