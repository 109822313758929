import { useCallback, useState } from 'react';
import { Dialog } from 'components/Dialog';
import ChangePasswordForm from './ChangePasswordForm';
import Button from 'components/Button';

type Props = {
	disabled?: boolean
}

const ChangePassword = ({ disabled }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const changePasswordCallback = useCallback(
		() => setIsModalOpen(true),
		[]
	);

	const closeModalCallback = useCallback(
		() => setIsModalOpen(false),
		[]
	);

	return (
		<>
			<Button
				text='Change password'
				onClick={changePasswordCallback}
				disabled={disabled}
			/>
			<Dialog
				open={isModalOpen}
				title='Change password'
				onClose={closeModalCallback}
			>
				<ChangePasswordForm cancel={closeModalCallback} />
			</Dialog>
		</>
	);
};

export default ChangePassword;
