import { HelpShell } from 'features/Help/HelpShell';

export const UserStatusesHelp = () => {
	return (
		<HelpShell title='User statuses'>
			<div>
				<p>Users are defined as:</p>
				<ul>
					<li>Inactive</li>
					<li>Active</li>
				</ul>
			</div>
			<p>These statuses cannot be changed or deleted, but you can add explanation for each status.</p>
			<p><b>Inactive</b> status is assigned for every new created user by default.<br />
				In order to activate user, it is necessary to assign him to specific project and define validity period for user.<br />
				Inactive users can not access modules and preform any changes within Mavles Project Management Tool.<br />
				When user is <b>activated</b> by responsible project manager, he can access modules and data for that project. 
				User remains active for specific project until expiration of validity date. Validity date can be extended by project manager.<br />
				Only active users are counted for licensing.
			</p>
		</HelpShell>
	)
}
