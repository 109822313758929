import { TicketResponse } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { ClosingForm } from './ClosingForm';

type Props = {
	ticket: TicketResponse;
	open: boolean
	onSave?: (ticket: TicketResponse, isInitiateChangeRequest?: boolean) => void
	cancel(): void
}

export const ClosingModal = ({ ticket, open, onSave, cancel }: Props) => {
	return (
		<Dialog
			title={`Closing Ticket - ${getFormatedId(EntityPrefixEnum.TICKET, ticket.id)}`}
			onClose={cancel}
			open={open}
			size='large'
		>
			<ClosingForm
				ticket={ticket}
				onSave={onSave}
				onCancel={cancel}
			/>
		</Dialog>
	)
}
