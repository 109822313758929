import { ButtonGroup } from "components/ButtonGroup"
import { useCallback, useState } from "react"
import { TaskResponse } from "services/tenantManagementService"
import { tryCatchJsonByAction } from "utils/fetchUtils"
import { followAction } from "./action"

const followId = 'follow';

const followItems = [
	{ id: followId, text: 'Followed' }
]

const unfollowItems = [
	{ id: followId, text: 'Unfollowed' }
]

type Props = {
	task: TaskResponse
	publishDataChanged(): void
}

export const Follow = ({ task, publishDataChanged }: Props) => {
	const [selectedIds, setSelectedIds] = useState<any[]>(task.follow ? [followId] : []);
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState(task.follow ? followItems : unfollowItems);

	const onChangeCallback = useCallback(
		async (ids: string[]) => {
			setLoading(true);

			const follow = ids.includes(followId);
			const bindedAction = followAction.bind(null, task.id, task.projectOrCategoryId, task.isProjectConnected, follow);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setSelectedIds(follow ? [followId] : []);
				setItems(follow ? followItems : unfollowItems)
				publishDataChanged();
			}

			setLoading(false);
		},
		[task, publishDataChanged]
	)

	return (
		<ButtonGroup
			items={items}
			selectedIds={selectedIds}
			onChange={onChangeCallback}
			isLoading={loading || !task.id}
			color='primary'
		/>
	)
}
