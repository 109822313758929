import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import notifications from 'components/Notification/notification';
import { ColumnContainer } from 'components/Layout';
import TableButtons from './Table/TableButtons';
import { ApprovalTimeTravelDescriptiveRequest, GenericFilterModelCollection, MonthEnum, TimeTravelStatusEnum, VacationRequestStatusFullResponse } from 'services/tenantManagementService';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { ListComponentProps } from 'features/Crud'
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import RejectRequestModalForm from '../../RejectRequest/RejectRequestModalForm';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { approvalVacationRequestAction, exportVacationRequestsAction, getAllVacationRequestsGenericAction } from 'containers/TimeTravel/MyVacationRequests/action';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { MultiSelect } from 'components/Form';
import { getMonthEnumByDate } from 'utils/dateTimeUtils';
import { monthOptions, yearOptions } from 'containers/TimeTravel/utils';
import { useTableColumnsMemo } from './Table/tableColumns';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';

const configureViewKey = 'vacation_requests_for_approval_table';

const VacationRequestsTable = ({ dataChangedTopic, publishDataChanged }: ListComponentProps) => {
	const {	persistedTimeAndTravelStatus, persistedVacationGeneralConfiguration } = useSelector((state: RootState) => state);

	const [selectedVacationRequestStatus, setSelectedVacationRequestStatus] = useState(new VacationRequestStatusFullResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const [selectedYears, setSelectedYears] = useState<number[]>([new Date().getFullYear()]);
	const [selectedMonths, setSelectedMonths] = useState<MonthEnum[]>([getMonthEnumByDate(new Date())]);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const tableColumns = useTableColumnsMemo(configureViewKey);

	const handleRowSelectionChange = useCallback(
		(data: VacationRequestStatusFullResponse[]) => {
			setSelectedVacationRequestStatus(data[0] || new VacationRequestStatusFullResponse());
		},
		[]
	);

	const fetchFunctionMemo = useMemo(
		() => {
			return getAllVacationRequestsGenericAction.bind(null, selectedYears, selectedMonths);
		},
		[selectedYears, selectedMonths]
	)

	const approveDisabledMemo = useMemo(
		() => !selectedVacationRequestStatus.id ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, selectedVacationRequestStatus.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Approved, selectedVacationRequestStatus.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[selectedVacationRequestStatus, persistedTimeAndTravelStatus.itemsMap, isSubmitting]
	)

	const rejectDisabledisabledMemo = useMemo(
		() => !selectedVacationRequestStatus.id ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, selectedVacationRequestStatus.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Rejected, selectedVacationRequestStatus.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[selectedVacationRequestStatus, persistedTimeAndTravelStatus.itemsMap, isSubmitting]
	)

	const approveCallback = useCallback(
		async () => {
			setIsSubmitting(true)
			const request = new ApprovalTimeTravelDescriptiveRequest({
				approve: true
			})
			const bindedAction = approvalVacationRequestAction.bind(null, selectedVacationRequestStatus.vacationRequestId, selectedVacationRequestStatus.id, request);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`${getFormatedId(EntityPrefixEnum.VACATION_REQUEST, selectedVacationRequestStatus.vacationRequestId)} is approved`)
				publishDataChanged();
			}
			setIsSubmitting(false)
		},
		[selectedVacationRequestStatus, publishDataChanged]
	)

	const rejectCallback = useCallback(
		async (rejectionComment?: string) => {
			setIsSubmitting(true)
			const request = new ApprovalTimeTravelDescriptiveRequest({
				approve: false,
				rejectionText: rejectionComment
			})
			const bindedAction = approvalVacationRequestAction.bind(null, selectedVacationRequestStatus.vacationRequestId, selectedVacationRequestStatus.id, request);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`${getFormatedId(EntityPrefixEnum.VACATION_REQUEST, selectedVacationRequestStatus.vacationRequestId)} is rejected`)
				setIsModalOpen(false);
				publishDataChanged();
			} else {
				return convertResponseErrors(response);
			}
			setIsSubmitting(false)
		},
		[selectedVacationRequestStatus, publishDataChanged]
	)

	const memoExportFunction = useMemo(
		() => exportVacationRequestsAction.bind(null, selectedYears, selectedMonths),
		[selectedYears, selectedMonths]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ColumnContainer margin='medium'>
			<SmartContainer>
				<SmartItem>
					<SmartFormGroup label='Year'>
						<MultiSelect
							value={selectedYears}
							onChange={(newSelectedYears: number[]) => { setSelectedYears(newSelectedYears) }}
							items={yearOptions}
							getItemId={(item: number) => item}
							getItemText={(item: number) => item.toString()}
						/>
					</SmartFormGroup>
					<SmartFormGroup label='Month'>
						<MultiSelect
							value={selectedMonths}
							onChange={(newSelectedMonths: MonthEnum[]) => { setSelectedMonths(newSelectedMonths) }}
							items={monthOptions}
							getItemId={(item: MonthEnum) => item}
							getItemText={(item: MonthEnum) => item}
						/>
					</SmartFormGroup>
				</SmartItem>
			</SmartContainer>
			<TableButtons
				selectedId={selectedVacationRequestStatus.vacationRequestId}
				additionalId={selectedVacationRequestStatus.id}
				approveDisabled={approveDisabledMemo}
				rejectDisabled={rejectDisabledisabledMemo}
				approvalEnabled={persistedVacationGeneralConfiguration.value.enableApprovalProcess}
				onApprove={approveCallback}
				onReject={() => {setIsModalOpen(true)}}
				tableColumns={tableColumns}
				configureViewKey={configureViewKey}
				filtersModel={filtersModel}
				exportFunction={memoExportFunction}
			/>
			<RemoteTable
				columns={tableColumns}
				filtersModel={filtersModel}
				filtersModelChanged={setFiltersModel}
				subscriptionTopic={dataChangedTopic}
				fetchFunction={fetchFunctionMemo}
				rowSelectionChanged={handleRowSelectionChange}
				reorderColumns={reorderColumnsCallback}
			/>
			<RejectRequestModalForm
				title={`Reject vacation request - ${getFormatedId(EntityPrefixEnum.VACATION_REQUEST, selectedVacationRequestStatus.id)}`}
				onCancel={() => {setIsModalOpen(false)}}
				onSave={rejectCallback}
				open={isModalOpen}
				rejectionCommentRequired={persistedVacationGeneralConfiguration.value.requireCommentsWhenRejecting}
			/>
		</ColumnContainer>
	)
}
export default VacationRequestsTable;
