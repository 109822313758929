import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { TokenTypeEnum, UserModel } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { AutoCompleteField, Form } from 'components/Form';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { propertyOf } from 'utils/propertyOf';

class SiteAdminModel {
	userId!: number
}

type Props = {
	siteAdmin?: SiteAdminModel
	crud: CrudEnum
	onSave: (userId: number) => void
}

export const SiteAdminForm = ({ siteAdmin, crud, onSave }: Props) => {
	const [values, setValues] = useState(siteAdmin || new SiteAdminModel());

	const { persistedUser } = useSelector((state: RootState) => state);

	const goBackFromCrud = useGoBackFromCrud(crud);

	const onSubmitCallback = useCallback(
		async () => onSave && await onSave(values.userId),
		[onSave, values]
	)

	const usersMemo = useMemo(
		() => persistedUser.items.filter(user => user.roleId === TokenTypeEnum.User),
		[persistedUser.items]
	)

	return (
		<Form
			values={values}
			initialValues={siteAdmin}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<AutoCompleteField
							id={propertyOf<SiteAdminModel>('userId')}
							label='User'
							isRequired
							items={usersMemo}
							getItemId={(user: UserModel) => user.id}
							getItemText={(user: UserModel) => `${user.firstName} ${user.lastName}`}
							loading={persistedUser.fetching}
							sort
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
