import { getTenantId } from 'features/Tenant/tenantUtils';
import { ApprovalTimesheetRequest, ExportDataModel, ExportForPeriodTimesheetsRequest, MonthEnum, TimesheetClient, TimesheetDeltaRequest, TimesheetsOneDayRequest, TimesheetsPeriodRequest } from 'services/tenantManagementService';

export const getAllMyRecoredStatusesForPeriodAction = (period: TimesheetsPeriodRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetClient();
	return client.getAllMyRecoredStatusesForPeriod(tenantId, period);
}

export const getAllMyTimesheetsAction = (day: TimesheetsOneDayRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetClient();
	return client.getAllMy(tenantId, day);
}

export const saveTimesheetsAction = (delta: TimesheetDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetClient();
	return client.update(tenantId, delta);
}

export const releaseTimesheetsAction = (period: TimesheetsPeriodRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetClient();
	return client.release(tenantId, period);
}

export const getAllMergedTimesheetsAction = (period: TimesheetsPeriodRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetClient();
	return client.getAllMerged(tenantId, period);
}

export const getTimesheetsForPeriodAction = (projectId: number, statusId: number, userId: number, period: TimesheetsPeriodRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetClient();
	return client.getForPeriod(tenantId, projectId, statusId, userId, period);
}

export const approvalTimesheetAction = (request: ApprovalTimesheetRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetClient();
	return client.approval(tenantId, request);
}

export const exportMyTimesheetsAction = (nonProjectCategoryId: number | undefined, projectId: number | undefined, years: number[] | undefined, months: MonthEnum[] | undefined, exportModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetClient();
	return client.exportMy(tenantId, nonProjectCategoryId, projectId, years, months, exportModel);
}

export const exportMergedTimesheetsAction = (body: ExportForPeriodTimesheetsRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetClient();
	return client.exportMerged(tenantId,  body);
}

export const exportForPeriodTimesheetsAction = (projectId: number, statusId: number, userId: number, body: ExportForPeriodTimesheetsRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetClient();
	return client.exportForPeriod(tenantId, projectId, statusId, userId,  body);
}
