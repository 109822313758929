import { TabType } from 'components/Tabs/Tabs';
import ProblemReport from './ProblemReport/ProblemReport';
import Analysis from './Analysis/Analysis';
import Approval from './Approval/Approval';
import Realization from './Realization/Realization';
import Acceptance from './Acceptance/Acceptance';
import { ChangeRequestSubroutes } from './ChangeRequestSubroutes';

export const phasesTabs: TabType[] = [
	{
		id: '1',
		title: 'CR Problem report',
		route: ChangeRequestSubroutes.PROBLEM_REPORT,
		component: ProblemReport,
	},
	{
		id: '2',
		title: 'CR Analysis',
		route: ChangeRequestSubroutes.ANALYSIS,
		component: Analysis
	},
	{
		id: '3',
		title: 'CR Approval',
		route: ChangeRequestSubroutes.APPROVAL,
		component: Approval,
	},
	{
		id: '4',
		title: 'CR Realization',
		route: ChangeRequestSubroutes.REALIZATION,
		component: Realization
	},
	{
		id: '5',
		title: 'CR Acceptance',
		route: ChangeRequestSubroutes.ACCEPTANCE,
		component: Acceptance
	}
];
