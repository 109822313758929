import { Dialog } from "components/Dialog";
import { ConfigurationIcon } from "components/icons/icons";
import { useCallback, useState } from "react";
import { ConfigurationForm } from "./ConfigurationForm";

type Props = {
	projectId: number
}

export const Configuration = ({ projectId }: Props) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const openDialogCallback = useCallback(
		() => {
			setIsDialogOpen(true)
		},
		[]
	);

	const closeDialogCallback = useCallback(
		() => setIsDialogOpen(false),
		[]
	);

	return (
		<>
			<ConfigurationIcon
				width={20}
				height={20}
				fill='currentColor'
				onClick={openDialogCallback}
				style={{cursor: 'pointer'}}
			/>
			<Dialog
				open={isDialogOpen}
				title='Schedule configuration'
				onClose={closeDialogCallback}
				size='large'
			>
				<ConfigurationForm
					projectId={projectId}
					onClose={closeDialogCallback}
				/>
			</Dialog>
		</>
	)
}
