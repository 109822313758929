import { CrudEnum } from "features/Crud"
import { useSelector } from "react-redux";
import { BusinessPartnerResponseModel, IdNameResponse, InsertInvoiceRequest, InvoiceItemResponse, InvoiceResponse, InvoiceStatusEnum, InvoiceStatusResponse, InvoiceTypeEnum, ModuleActivityEnum, SimpleIdNameModel, TenantIsActiveResponse } from "services/tenantManagementService"
import { RootState } from 'base/reducer/reducer';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCurrencySuffixByIdMemo, useCurrencySuffixMemo } from "features/Currency/useCurrencySuffixMemo";
import { CheckboxField, Input, DateField, Form, FormGroup, InputField, InputNumberField, OptionType, TextareaField, InputNumber, currencyDefaultProps, globalErrorKey, AttachmentField } from "components/Form";
import { SmartContainer, SmartFormGroup, SmartItem } from "components/SmartContainer/SmartContainer";
import { propertyOf } from "utils/propertyOf";
import { EntityPrefixEnum, getFormatedId } from "utils/commonHelper";
import { getSimpleChangeRequestsAction } from "containers/Scope/ChangeRequests/action";
import { tryCatchJsonByAction } from "utils/fetchUtils";
import { incomingOutgoing } from 'containers/Projects/ProjectReport/ReportComponents/ProjectFinances/FinanceForm';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { getDateIncrementByNumberOfDays, getNumberOfDaysBetweenDates } from 'utils/dateTimeUtils';
import { donwloadAttachmentAction, removeAttachmentAction } from '../action';
import notifications from 'components/Notification/notification';
import { ColumnContainer, VerticalSeparator } from "components/Layout";
import Button from 'components/Button';
import { AutoCompleteField, TableField } from 'components/Form/fields';
import { generateNumberId } from "base/id";
import { invoiceValidator } from "./validators";
import { ProjectSelectField, pmOrSpmPermission } from "features/Project";

type Props = {
	invoice?: InvoiceResponse
	projectId: number
	crud: CrudEnum
	onSave?: (newInvoice: InvoiceResponse) => void
	cancel?(): void
}

export const InvoiceForm = ({
	invoice,
	projectId,
	crud,
	onSave,
	cancel
}: Props) => {
	const {
		persistedBusinessPartner,
		persistedInvoiceStatus,
		persistedCurrency,
		persistedTenant,
		persistedFinanceCategory
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(invoice || new InvoiceResponse());
	const [duration, setDuration] = useState<number>();

	const onSubmitCallback = useCallback(
		async () => {
			if (!values.invoiceItems?.length) {
				return await onSave!(values);
			} else {
				const errorMessage: string = invoiceValidator(values);
				if (errorMessage) {
					return Promise.resolve({[globalErrorKey]: errorMessage});
				} else {
					return await onSave!(values);
				};
			}
		},
		[onSave, values]
	)

	const currencySymbolSuffix = useCurrencySuffixMemo(persistedCurrency, persistedTenant);
	const localCurrencySymbolSuffix = useCurrencySuffixByIdMemo(persistedCurrency, values?.localCurrencyId);

	const isRead = crud === CrudEnum.Read;
	const isCreate = crud === CrudEnum.Create;

	// fetch change requests
	const [changeRequests, setChangeRequests] = useState<SimpleIdNameModel[]>([]);
	const [fetchingChangeRequests, setFetchingChangeRequests] = useState(true);

	const fetchChangeRequestsCallback = useCallback(
		async () => {
			setFetchingChangeRequests(true);
			const bindedAction = getSimpleChangeRequestsAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setChangeRequests(response.items || []);
			}
			setFetchingChangeRequests(false);
		},
		[projectId]
	)

	useEffect(
		() => {
			fetchChangeRequestsCallback()
		},
		[fetchChangeRequestsCallback]
	)

	useEffect(
		() => {
			let newOverdue: number | undefined;
			const isIssuedReceived = isStatusBySemantic(InvoiceStatusEnum.IssuedReceived, values.statusId, persistedInvoiceStatus.itemsMap)
			if (isIssuedReceived && values.due !== undefined) {
				const daysBetween = getNumberOfDaysBetweenDates(values.due, new Date());
				newOverdue = daysBetween > 0 ? daysBetween : undefined;
			}

			setValues((state) => {
				return new InvoiceResponse({
					...state,
					overdueDays: newOverdue
				})
			})
		},
		[persistedInvoiceStatus, values.statusId, values.due]
	)

	useEffect(
		() => {
			let total: number | undefined;
			if (values.amount !== undefined || values.taxAmount !== undefined) {
				total = (values.amount || 0) + (values.taxAmount || 0);
			}

			setValues((state) => {
				return new InvoiceResponse({
					...state,
					totalAmount: total!
				})
			})
		},
		[persistedInvoiceStatus, values.amount, values.taxAmount]
	)

	useEffect(
		() => {
			let total: number | undefined;
			if (values.localCurrencyAmount !== undefined || values.localCurrencyTaxAmount !== undefined) {
				total = (values.localCurrencyAmount || 0) + (values.localCurrencyTaxAmount || 0);
			}

			setValues((state) => {
				return new InvoiceResponse({
					...state,
					totalLocalCurrencyAmount: total
				})
			})
		},
		[persistedInvoiceStatus, values.localCurrencyAmount, values.localCurrencyTaxAmount]
	)

	useEffect(
		() => {
			if (!duration) {
				return;
			}

			setValues((state: InvoiceResponse) => {
				if (!state.issueDate) {
					return state;
				}

				const newDueDate = getDateIncrementByNumberOfDays(state.issueDate, duration);

				// if Update or Read, issue date and due date are already existing,
				// so we return the state to prevent the "Unsaved Changes" dialog from triggered unnecessarily
				if (!isCreate) {
					if (state.due.valueOf() === newDueDate.valueOf()) {
						return state;
					}
				}

				return new InvoiceResponse({
					...state,
					due: newDueDate,
				})
			});
		},
		[duration, isCreate]
	)

	useEffect(
		() => {
			if (!values.issueDate || !values.due) {
				setDuration(undefined);
				return;
			}

			return setDuration(getNumberOfDaysBetweenDates(values.issueDate, values.due));
		},
		[values.issueDate, values.due]
	)

	const incomingOutgoingPrefix = values.invoiceType === InvoiceTypeEnum.Incoming ? '-' : '';

	const downloadAttachmentMemo = useMemo(
		() => donwloadAttachmentAction.bind(null, projectId),
		[projectId]
	)

	const removeAttachmentCallback = useCallback(
		async (id: number) => {
			const bindedAction = removeAttachmentAction.bind(null, projectId, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Attachment is successfully deleted');
				setValues((state: InvoiceResponse) =>
					new InvoiceResponse({
						...state,
						attachments: state.attachments?.filter(att => att.id !== id)
					})
				)
			}
		},
		[projectId]
	)

	const addInvoiceItemCallback = useCallback(
		() => {
			const id = generateNumberId();

			setValues((state) => {
				const newModel = new InvoiceItemResponse();
				newModel.id = id;

				const newState = new InvoiceResponse(state);
				if (newState.invoiceItems) {
					newState.invoiceItems = [...newState.invoiceItems, newModel];
				} else {
					newState.invoiceItems = [newModel];
				}

				return newState;
			})
		},
		[]
	)

	const invoiceItemsContent = useMemo(
		() => (
			<>
				<h5>Invoice items</h5>
				<ColumnContainer margin='medium'>
					<Button
						text='Add'
						onClick={addInvoiceItemCallback}
						disabled={isRead}
					/>
					<TableField
						id={propertyOf<InvoiceResponse>('invoiceItems')}
						headers={[
							{ label: '#', size: 1 },
							{ label: 'Item description', size: 6, isRequired: true },
							{ label: 'Net amount', size: 3, isRequired: true },
							{ label: 'Tax', size: 3, isRequired: true },
							{ label: 'Total amount', size: 3 },
							{ label: 'Local amount', size: 3 },
							{ label: 'Local tax', size: 3 },
							{ label: 'Total local amount', size: 3 }
						]}
						getRowData={(row: InvoiceItemResponse, index: number) => {
							return {
								isDeletable: !isRead,
								fields: [
									<span>{index + 1}</span>,
									<InputField
										id={propertyOf<InvoiceItemResponse>('description')}
										isRequired
									/>,
									<InputNumberField
										id={propertyOf<InvoiceItemResponse>('amount')}
										prefix={incomingOutgoingPrefix}
										suffix={currencySymbolSuffix}
										{...currencyDefaultProps}
										allowNegative={false}
										isRequired
									/>,
									<InputNumberField
										id={propertyOf<InvoiceItemResponse>('taxAmount')}
										prefix={incomingOutgoingPrefix}
										suffix={currencySymbolSuffix}
										{...currencyDefaultProps}
										allowNegative={false}
										isRequired
									/>,
									<InputNumber
										value={(row.amount || 0) + (row.taxAmount || 0)}
										prefix={incomingOutgoingPrefix}
										suffix={currencySymbolSuffix}
										{...currencyDefaultProps}
										allowNegative={false}
										disabled
									/>,
									<InputNumberField
										id={propertyOf<InvoiceItemResponse>('localCurrencyAmount')}
										suffix={localCurrencySymbolSuffix}
										{...currencyDefaultProps}
										allowNegative={false}
									/>,
									<InputNumberField
										id={propertyOf<InvoiceItemResponse>('localCurrencyTaxAmount')}
										suffix={localCurrencySymbolSuffix}
										{...currencyDefaultProps}
										allowNegative={false}
									/>,
									<InputNumber
										value={(row.localCurrencyAmount || 0) + (row.localCurrencyTaxAmount || 0)}
										suffix={localCurrencySymbolSuffix}
										{...currencyDefaultProps}
										allowNegative={false}
										disabled
									/>
								]
							}
						}}
					/>
				</ColumnContainer>
			</>
		),
		[addInvoiceItemCallback, currencySymbolSuffix, localCurrencySymbolSuffix, isRead, incomingOutgoingPrefix]
	)

	return (
		<Form
			values={values}
			initialValues={invoice}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			disabled={isRead}
			hideButtons={isRead}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<AutoCompleteField
								id={propertyOf<InvoiceResponse>('invoiceType')}
								label='Type'
								items={incomingOutgoing}
								getItemId={(item: OptionType) => item.id}
								getItemText={(item: OptionType) => item.text}
								isRequired
							/>
							<AutoCompleteField
								id={propertyOf<InvoiceResponse>('partnerId')}
								label='Partner ID'
								items={persistedBusinessPartner.items}
								getItemId={(item: BusinessPartnerResponseModel) => item.id}
								getItemText={(item: BusinessPartnerResponseModel) => item.name}
								loading={persistedBusinessPartner.fetching}
								isRequired
								sort
							/>
							<ProjectSelectField
								id={propertyOf<InvoiceResponse>('projectId')}
								initialProjectId={values.projectId}
								isRequired
								disabled
								moduleEnum={ModuleActivityEnum.Finance}
								userRolePermission={pmOrSpmPermission}
							/>
							<InputField
								id={propertyOf<InvoiceResponse>('invoiceNumber')}
								label='Invoice No'
								isRequired
							/>
							<InputField
								id={propertyOf<InvoiceResponse>('description')}
								label='Description'
							/>
							<DateField
								id={propertyOf<InvoiceResponse>('issueDate')}
								label='Inv. date'
								isRequired
							/>
							<SmartFormGroup label='Payment term'>
								<InputNumber
									value={duration}
									suffix=' day(s)'
									onChange={setDuration}
									disabled={isRead}
								/>
							</SmartFormGroup>
							<DateField
								id={propertyOf<InvoiceResponse>('due')}
								label='Due on'
								isRequired
							/>
							<AutoCompleteField
								id={propertyOf<InvoiceResponse>('changeRequestId')}
								label='Change Request ID'
								items={changeRequests}
								getItemId={(item: SimpleIdNameModel) => item.id}
								getItemText={(item: SimpleIdNameModel) => `${getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, item.id)} - ${item.name}`}
								loading={fetchingChangeRequests}
							/>
							<AutoCompleteField
								id={propertyOf<InvoiceResponse>('financeCategoryId')}
								label='Budget category'
								items={persistedFinanceCategory.activeItems}
								getItemId={(item: TenantIsActiveResponse) => item.id}
								getItemText={(item: TenantIsActiveResponse) => item.name}
								loading={persistedFinanceCategory.fetching}
								isRequired
							/>
							<CheckboxField
								id={propertyOf<InvoiceResponse>('excludeFromBudget')}
								labelBefore='Exclude from budget'
							/>
						</SmartItem>

						<SmartItem>
							<AutoCompleteField
								id={propertyOf<InvoiceResponse>('statusId')}
								label='Status'
								items={persistedInvoiceStatus.items}
								getItemId={(item: InvoiceStatusResponse) => item.id}
								getItemText={(item: InvoiceStatusResponse) => item.name}
								loading={persistedInvoiceStatus.fetching}
								isRequired
								disabled={isCreate}
							/>
							<InputNumberField
								id={propertyOf<InvoiceResponse>('overdueDays')}
								label='Overdue'
								min={0}
								suffix=' days'
								disabled
							/>
							<InputField
								id={propertyOf<InvoiceResponse>('customText1')}
								label='Text 1'
							/>
							<InputField
								id={propertyOf<InvoiceResponse>('customText2')}
								label='Text 2'
							/>
							<TextareaField
								id={propertyOf<InvoiceResponse>('comment')}
								label='Comment'
								rows={4}
							/>
							<VerticalSeparator margin='medium' />
							<AttachmentField
								id={propertyOf<InsertInvoiceRequest>('newAttachments')}
								label='Attachments'
								multiple
								disabled={isRead}
								oldAttachments={values.attachments}
								removeOldAttachment={removeAttachmentCallback}
								downloadOldAttachment={downloadAttachmentMemo}
							/>
						</SmartItem>

						<SmartItem>
							<FormGroup title='Amount in Project currency'>
								<InputNumberField
									id={propertyOf<InvoiceResponse>('amount')}
									label='Total net amount'
									prefix={incomingOutgoingPrefix}
									suffix={currencySymbolSuffix}
									{...currencyDefaultProps}
									allowNegative={false}
									isRequired
								/>
								<InputNumberField
									id={propertyOf<InvoiceResponse>('taxAmount')}
									label='Total tax'
									prefix={incomingOutgoingPrefix}
									suffix={currencySymbolSuffix}
									{...currencyDefaultProps}
									allowNegative={false}
									isRequired
								/>
								<InputNumberField
									id={propertyOf<InvoiceResponse>('totalAmount')}
									label='Total amount'
									prefix={incomingOutgoingPrefix}
									suffix={currencySymbolSuffix}
									{...currencyDefaultProps}
									allowNegative={false}
									disabled
								/>
								<SmartFormGroup label='Currency'>
									<Input
										value={currencySymbolSuffix?.trim()}
										disabled
									/>
								</SmartFormGroup>
							</FormGroup>
							<VerticalSeparator />
							<FormGroup title='Amount in Local currency'>
								<InputNumberField
									id={propertyOf<InvoiceResponse>('localCurrencyAmount')}
									label='Total net amount'
									prefix={incomingOutgoingPrefix}
									suffix={localCurrencySymbolSuffix}
									{...currencyDefaultProps}
									allowNegative={false}
								/>
								<InputNumberField
									id={propertyOf<InvoiceResponse>('localCurrencyTaxAmount')}
									label='Total tax'
									prefix={incomingOutgoingPrefix}
									suffix={localCurrencySymbolSuffix}
									{...currencyDefaultProps}
									allowNegative={false}
								/>
								<InputNumberField
									id={propertyOf<InvoiceResponse>('totalLocalCurrencyAmount')}
									label='Total amount'
									prefix={incomingOutgoingPrefix}
									suffix={localCurrencySymbolSuffix}
									{...currencyDefaultProps}
									allowNegative={false}
									disabled
								/>
								<AutoCompleteField
									id={propertyOf<InvoiceResponse>('localCurrencyId')}
									label='Currency'
									items={persistedCurrency.items}
									getItemId={(currency: IdNameResponse) => currency.id}
									getItemText={(currency: IdNameResponse) => currency.symbol}
									loading={persistedCurrency.fetching}
								/>
							</FormGroup>
						</SmartItem>
					</SmartContainer>
					<VerticalSeparator margin='medium' />
					{invoiceItemsContent}
				</ColumnContainer>
			)}
		/>
	)
}
