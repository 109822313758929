import { ScheduleDayUtilizationDashboardResponse } from 'services/tenantManagementService';
import { gantt, resourcesUtilizationGlobal } from './GanttChart';
import { getWeekdaysInMonth } from 'utils/dateTimeUtils';

type ResourceCellValue =  {
	actual: number,
	planned: number,
	difference: number
}

const calculateResourceLoadUtilizationDashboard = (gantt, daysHours: ScheduleDayUtilizationDashboardResponse[], projectId: number | undefined, scale) => {
	var step = scale.unit;
	var timegrid: { [timestamp: number]: ResourceCellValue} = {};

	for (let item of daysHours) {
		const ganntDate = gantt.date[step + '_start'](new Date(item.date)); // clone item.date, because it is changed inside of a function

		var timestamp = ganntDate.valueOf();
		if (!timegrid[timestamp]) {
			timegrid[timestamp] = {
				actual: 0,
				planned: 0,
				difference: 0
			}
		}

		let actualHours = 0;
		let plannedHours = 0;
		let difference = 0;

		if (projectId && item.utilizationPerProject) {
			const projectValue = item.utilizationPerProject[projectId];
			if (projectValue) {
				actualHours = projectValue.actualHours;
				plannedHours = projectValue.plannedHours;
				difference = projectValue.difference;
			}
		}

		if (!projectId) {
			actualHours = item.actualHours;
			plannedHours = item.plannedHours;
			difference = item.difference;
		}

		timegrid[timestamp].actual += actualHours;
		timegrid[timestamp].planned += plannedHours;
		timegrid[timestamp].difference += difference;
	}

	const timetable: Array<{ start: Date, end: Date, value: ResourceCellValue }> = [];
	for (let timestamp in timegrid) {
		const start = new Date(timestamp as any * 1);
		const end = gantt.date.add(start, 1, step);
		timetable.push({
			start,
			end,
			value: timegrid[timestamp]
		});
	}

	return timetable;
}

const renderResourceLineUtilizationDashboard = (resource, timeline) => {
	const daysHours = resourcesUtilizationGlobal.find((item) => item.userId === resource.userId)?.daysHours || [];
	const scale = timeline.getScale();
	const step = scale.unit;
	const timetable = calculateResourceLoadUtilizationDashboard(gantt, daysHours, resource.projectId, scale);

	let daysMultiplier = 0;
	if (step === 'day') {
		daysMultiplier = 1;
	}
	if (step === 'week') {
		daysMultiplier = 5;
	}

	const row = document.createElement('div');

	for (let i = 0; i < timetable.length; i++) {
		const day = timetable[i];

		if (step === 'month') {
			const date = day.start;
			daysMultiplier = getWeekdaysInMonth(date.getMonth(), date.getFullYear())
		}

		const sizes = timeline.getItemPosition(resource, day.start, day.end);
		const cell = document.createElement('div');
		cell.className = 'gantt_resource_marker'

		cell.style.cssText = [
			'left:' + sizes.left + 'px',
			'width:' + sizes.width + 'px',
			'position:absolute',
			'height:' + gantt.config.row_height +  'px',
			'top:' + sizes.top + 'px'
		].join(';');

		const { planned, actual, difference } = day.value;

		const valueStyle = [
			'height:' + gantt.config.row_height / 3 + 'px',
			'padding:6px 0',
			'font-size: 12px'
		].join(';');

		const plannedElement = document.createElement('div');
		plannedElement.innerHTML = planned.toFixed(0);
		plannedElement.title = 'Planned'
		let plannedColor = '';
		if (planned <= 6.4 * daysMultiplier) {
			plannedColor = '#87bc45';
		} else if (planned <= 8 * daysMultiplier) {
			plannedColor = '#ff9800'
		} else {
			plannedColor = '#f44336';
		}
		plannedElement.style.cssText = valueStyle;
		plannedElement.style.background = daysMultiplier !== 0 ? plannedColor : '';
		cell.appendChild(plannedElement);

		const actualElement = document.createElement('div');
		actualElement.innerHTML = actual.toFixed(0);
		actualElement.title = 'Actual'
		let actualColor = '';
		if (actual <= 6.4 * daysMultiplier) {
			actualColor = '#87bc45';
		} else if (actual <= 8 * daysMultiplier) {
			actualColor = '#ff9800'
		} else {
			actualColor = '#f44336';
		}
		actualElement.style.cssText = valueStyle;
		actualElement.style.background = daysMultiplier !== 0 ? actualColor : '';
		cell.appendChild(actualElement);

		const differenceElement = document.createElement('div');
		differenceElement.innerHTML = difference.toFixed(0);
		differenceElement.title = 'Difference'
		let differenceColor = '';
		if (difference >= 0) {
			differenceColor = '#87bc45';
		} else {
			differenceColor = '#f44336';
		}
		differenceElement.style.cssText = valueStyle;
		differenceElement.style.background = daysMultiplier !== 0 ? differenceColor : '';;
		cell.appendChild(differenceElement);

		row.appendChild(cell);
	}
	return row;
}

export const createResourcesDatastore = () => {
	gantt.createDatastore({
		name: gantt.config.resource_store,
		type: 'treeDataStore',
        fetchTasks: true,
		initItem: function (item) {
			item.parent = item.parent || gantt.config.root_id;
			item[gantt.config.resource_property] = item.parent;
			item.open = false;
			return item;
		}
	});
	const resourcesStore = gantt.getDatastore(gantt.config.resource_store);
	const tasksStore = gantt.getDatastore('task');
	tasksStore.attachEvent('onStoreUpdated', function (id, item, mode) {
		resourcesStore.refresh();
	})
}

export const addResourcesConfigLayout = (resourceColumns: any[]) => {
	gantt.config.layout.rows.push({
		height: 700,
		config: {
			columns: [
				{ name: 'name', label: 'Name', width: 180, tree: true, template: (resource) => resource.label },
				...resourceColumns
			]
		},
		cols: [
			{
				width: 340,
				min_width: 300,
				view: 'grid',
				id: 'resourceGrid',
				group:'grids',
				bind: gantt.config.resource_store,
				scrollY: 'resourceVScroll'
			},
			{ resizer: true, width: 1, group:'vertical' },
			{
				view: 'timeline',
				id: 'resourceTimeline',
				bind: gantt.config.resource_store,
				bindLinks: null,
				layers: [
					renderResourceLineUtilizationDashboard,
					'taskBg'
				],
				scrollX: 'scrollHor',
				scrollY: 'resourceVScroll'
			},
			{ view: 'scrollbar', id: 'resourceVScroll', group:'vertical' }
		]
	})

	gantt.config.layout.rows.push({ view: 'scrollbar', id: 'scrollHor' });
}
