import { View, Text, StyleSheet } from '@react-pdf/renderer';

type Props = {
	title: string
}

const SubtitleField = ({ title }: Props) => (
	<View style={styles.container}>
		<Text style={styles.title}>
			{title}
		</Text>
	</View>
);

export default SubtitleField;

const styles = StyleSheet.create({
	container: {
		paddingVertical: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	title: {
		fontWeight: 600,
		fontSize: 12,
	}
});
