import { useCallback, useEffect, useMemo, useState } from 'react';
import { generateNumberId } from 'base/id';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ColumnContainer } from 'components/Layout';
import Button from 'components/Button';
import { Form, SelectField, InputNumberField, CheckboxField, InputField, TextareaField, TableField } from 'components/Form';
import { SurveyProposedAnswerResponse, SurveyQuestionResponse, SurveyQuestionTypeEnum, SurveyResponse, SurveyTypeEnum } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { CrudEnum } from 'features/Crud';

type Props = {
	question?: SurveyQuestionResponse
	cancel(): void
	save?(question: SurveyQuestionResponse): void
	crud: CrudEnum
	survey?: SurveyResponse
}

const QuestionForm = ({ question, save, cancel, crud, survey }: Props) => {

	const [initialValues, setInitialValues] = useState(new SurveyQuestionResponse(question));
	const [values, setValues] = useState(new SurveyQuestionResponse(question));

	// score wuestion and answer required should be true by default
	useEffect(
		() => {
			if (!question) {
				setInitialValues((state: SurveyQuestionResponse) => new SurveyQuestionResponse({
					...state,
					scoreQuestion: true,
					answerRequired: true
				}));
				setValues((state: SurveyQuestionResponse) => new SurveyQuestionResponse({
					...state,
					scoreQuestion: true,
					answerRequired: true
				}));
			}
		},
		[question]
	)

	const disabledMemo = useMemo(() => crud === CrudEnum.Read, [crud]);
	const questionTypesMemo = useMemo(
		() => {
			if (!survey) {
				return Object.values(SurveyQuestionTypeEnum);
			}

			return survey.surveyType === SurveyTypeEnum.Survey
				? [SurveyQuestionTypeEnum.Rating, SurveyQuestionTypeEnum.Answer]
				: [SurveyQuestionTypeEnum.MultipleChoice, SurveyQuestionTypeEnum.SingleChoice, SurveyQuestionTypeEnum.Answer]
		},
		[survey]
	);

	const scoreQuestionMemo = useMemo(() => values.scoreQuestion, [values]);
	const questionTypeMemo = useMemo(() => values.surveyType, [values]);
	const hasTableMemo = useMemo(
		() => {
			return questionTypeMemo === SurveyQuestionTypeEnum.SingleChoice || questionTypeMemo === SurveyQuestionTypeEnum.MultipleChoice
		},
		[questionTypeMemo]
	);

	const onSubmitCallback = useCallback(
		async () => {
			if (save) {
				return await save(new SurveyQuestionResponse(values))
			}
		},
		[save, values]
	);

	const proposedAnswersColumns = useMemo(
		() => {
			const columns = [
				{
					header: { id: 'isCorrect', label: `Select correct answer${questionTypeMemo === SurveyQuestionTypeEnum.MultipleChoice ? 's' : '' }`, size: 1 },
					field: (
						<CheckboxField
							id='isCorrect'
							disabled={disabledMemo}
						/>
					)
				},
				{
					header: { id: 'answer', label: 'Text', size: 3 },
					field: (
						<InputField
							id='answer'
							disabled={disabledMemo}
							maxLength={400}
							isRequired
						/>
					)
				},
			];

			if (scoreQuestionMemo) {
				columns.push({
					header: { id: 'points', label: 'Points', size: 1 },
					field: (
						<InputNumberField
							id='points'
							disabled={disabledMemo}
							isRequired
						/>
					)
				});
			}

			return columns;
		},
		[questionTypeMemo, scoreQuestionMemo, disabledMemo]
	);

	const addProposedAnswerCallback = useCallback(
		() => {
			const id = generateNumberId();

			setValues((state: SurveyQuestionResponse) => {
				const newModel = new SurveyProposedAnswerResponse();
				newModel.id = id;

				const newState = new SurveyQuestionResponse({ ...state });
				if (newState.proposedAnswers) {
					newState.proposedAnswers = [...newState.proposedAnswers, newModel];
				} else {
					newState.proposedAnswers = [newModel];
				}

				return newState;
			})
		},
		[]
	);

	const proposedAnswersContent = useMemo(
		() => (
			<>
				<h5>Proposed answers</h5>
				<ColumnContainer margin='medium'>
					{!disabledMemo &&
						<Button
							text='Add'
							onClick={addProposedAnswerCallback}
						/>
					}
					<TableField
						id='proposedAnswers'
						headers={proposedAnswersColumns.map(x => x.header)}
						getRowData={() => {
							return {
								isDeletable: !disabledMemo,
								fields: proposedAnswersColumns.map(x => x.field)
							}
						}}
					/>
				</ColumnContainer>
			</>
		),
		[addProposedAnswerCallback, proposedAnswersColumns, disabledMemo]
	);

	return (
		<Form
			values={values}
			initialValues={initialValues}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			cancelButtonText={disabledMemo ? 'Close' : 'Cancel'}
			hideSubmitButton={disabledMemo}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<InputField
								id={propertyOf<SurveyQuestionResponse>('questionGroup')}
								label='Question group'
								disabled={disabledMemo}
							/>
							<TextareaField
								id={propertyOf<SurveyQuestionResponse>('question')}
								label='Question'
								disabled={disabledMemo}
								isRequired
							/>
							<SelectField
								id={propertyOf<SurveyQuestionResponse>('surveyType')}
								label='Question Type'
								disabled={disabledMemo}
								items={questionTypesMemo}
								getItemId={(item: SurveyQuestionTypeEnum) => item}
								getItemText={(item: SurveyQuestionTypeEnum) => {
									if (item === SurveyQuestionTypeEnum.MultipleChoice) {
										return 'Multiple Choice'
									}

									return item === SurveyQuestionTypeEnum.SingleChoice ? 'Single Choice' : item;
								}}
								isRequired
							/>
							<CheckboxField
								id={propertyOf<SurveyQuestionResponse>('answerRequired')}
								label='Require an answer'
								disabled={disabledMemo}
							/>
							{
								!!questionTypeMemo && questionTypeMemo !== SurveyQuestionTypeEnum.Answer && questionTypeMemo !== SurveyQuestionTypeEnum.Rating &&
								<CheckboxField
									id={propertyOf<SurveyQuestionResponse>('scoreQuestion')}
									label='Score question'
									disabled={disabledMemo}
								/>
							}
						</SmartItem>
					</SmartContainer>
					{hasTableMemo && proposedAnswersContent}
				</ColumnContainer>
			)}
		/>
	)
}

export default QuestionForm;
