import { TabType } from 'components/Tabs/Tabs';
import { Categories } from './Tabs/Categories/Categories';
import { PlanningIntervals } from './Tabs/PlanningIntervals/PlanningIntervals';

export const configureFinanceTabs: TabType[] = [
	{
		id: '1',
		title: 'Categories',
		route: 'categories',
		component: Categories,
	},
	{
		id: '2',
		title: 'Planning intervals',
		route: 'planning_intervals',
		component: PlanningIntervals,
	}
]
