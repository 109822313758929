import { downloadAttachmentAction, updateChangeRequestRealizationAction } from 'containers/Scope/ChangeRequests/action';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChangeRequestApproveEnum, ChangeRequestFullResponse, ChangeRequestRealizationRequest, ChangeRequestStatusEnum } from 'services/tenantManagementService';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { Form, TextareaField, AttachmentField } from 'components/Form';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { propertyOf } from 'utils/propertyOf';
import Button from 'components/Button';
import { RootState } from 'base/reducer/reducer';
import SendEmail from '../../components/SendEmail';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import ScopeItemsMultiselect from '../../components/ScopeItemsMultiselect';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { TabProps } from '../../CreateChangeRequest';
import { getUserInfo } from 'utils/storageUtils';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { ExportRealization } from 'containers/Scope/ChangeRequests/Export/ExportRealization';

type Props = TabProps;

const Realization = ({ projectId, changeRequest, crud, publishDataChanged}: Props) => {
	const {
		persistedUser,
		persistedChangeRequestStatus,
		persistedProject
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(new ChangeRequestFullResponse(changeRequest));
	const goBackFromCrud = useGoBackFromCrud(crud, false, true);
	const approveRef = useRef(ChangeRequestApproveEnum.None);

	const onSubmitCallback = useCallback(
		async () => {
			const scopeItemLevelIdsToAdd = (values.scopeItemLevelIds || []).filter((item) => (!changeRequest.scopeItemLevelIds || changeRequest.scopeItemLevelIds.includes(item) === false));
			const scopeItemLevelIdsToRemove = (changeRequest.scopeItemLevelIds || []).filter((item) => (!values.scopeItemLevelIds || values.scopeItemLevelIds.includes(item) === false));

			const model = new ChangeRequestRealizationRequest({
				...values,
				scopeItemLevelIdsToAdd,
				scopeItemLevelIdsToRemove,
				approve: approveRef.current,
				sendEmail: values.sendEmailRealization
			});

			const bindedAction = updateChangeRequestRealizationAction.bind(null, projectId, model);
			const response = await tryCatchJsonByAction(bindedAction);

			// reset to default value
			approveRef.current = ChangeRequestApproveEnum.None;

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, response.value?.id);
				notifications.success(`Change request ${id} realization is updated.`);
				publishDataChanged();
				goBackFromCrud();
			} else {
				return convertResponseErrors(response);
			}
		},
		[values, goBackFromCrud, projectId, changeRequest, publishDataChanged]
	)

	const downloadAttachmentMemo = useMemo(
		() => downloadAttachmentAction.bind(null, projectId),
		[projectId]
	)

	const disabledMemo = useMemo(
		() => crud !== CrudEnum.Update
		|| isStatusBySemantic(ChangeRequestStatusEnum.ApprovedForRealization, values.statusId, persistedChangeRequestStatus.itemsMap) === false
		|| (!(isUserPmorSubstitutePmOrSiteAdmin(persistedProject.itemsMap[projectId]?.roleId) || changeRequest.realizationUserId === getUserInfo().id)),
		[crud, values.statusId, persistedChangeRequestStatus.itemsMap, persistedProject.itemsMap, projectId, changeRequest.realizationUserId ]
	)

	// Svetlana asked to disable delete on existing attachments
	// const removeAttachmentCallback = useCallback(
	// 	async (id: number) => {
	// 		const bindedAction = removeAttachmentAction.bind(null, projectId, changeRequest.id, id);
	// 		const response = await tryCatchJsonByAction(bindedAction);
	// 		if (response.success) {
	// 			notifications.success('Attachment is successfully deleted');
	// 			setValues((state: ChangeRequestFullResponse) =>
	// 				new ChangeRequestFullResponse({
	// 					...state,
	// 					realizationAttachments: state.realizationAttachments?.filter(att => att.id !== id)
	// 				})
	// 			)
	// 		}
	// 	},
	// 	[projectId, changeRequest.id]
	// )

	return (
		<Form
			values={values}
			initialValues={changeRequest}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			disabled={disabledMemo}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<TextareaField
								id={propertyOf<ChangeRequestFullResponse>('linkToEvidence')}
								label='Link to evidence'
								rows={3}
							/>
							<ScopeItemsMultiselect
								id={propertyOf<ChangeRequestFullResponse>('scopeItemLevelIds')}
								label='Process ID - level 3'
								projectId={projectId}
							/>
							{/* MODULE: Scope item training link */}
						</SmartItem>
						<SmartItem>
							<AttachmentField
								id={propertyOf<ChangeRequestRealizationRequest>('newAttachments')}
								label='Attachments'
								multiple
								oldAttachments={values.realizationAttachments}
								// removeOldAttachment={disabledMemo ? undefined : removeAttachmentCallback}
								downloadOldAttachment={downloadAttachmentMemo}
							/>
						</SmartItem>
					</SmartContainer>
					<SendEmail
						sendEmailId={propertyOf<ChangeRequestFullResponse>('sendEmailRealization')}
						sendEmailLabel='Send email'
						toId={propertyOf<ChangeRequestFullResponse>('acceptanceUserId')}
						persistedUser={persistedUser}
					/>
				</ColumnContainer>
			)}
			renderAdditionalButtons={(disabled, handleSubmitCallback: () => void, isSubmitting) => (
				<RowContainer>
					<Button
						text='Release for acceptance'
						disabled={disabled}
						isLoading={isSubmitting && approveRef.current === ChangeRequestApproveEnum.Approve}
						onClick={
							() => {
								approveRef.current = ChangeRequestApproveEnum.Approve;
								handleSubmitCallback();
							}
						}
					/>
					<ExportRealization
						changeRequest={changeRequest}
						projectId={projectId}
					/>
				</RowContainer>
			)}
		/>
	)
}

export default Realization;
