import ReactHtmlParser from "react-html-parser";
import { sanitize } from "dompurify";

type Props = {
	html: string
}

export const HtmlSanitized = ({ html }: Props) => {
	const sanitized = sanitize(html);

	return (
		<>{ReactHtmlParser(sanitized)}</>
	)
}
