import { RootState } from "base/reducer/reducer"
import { AutoCompleteField, DateField, Form } from "components/Form"
import { CrudEnum } from "features/Crud"
import { getNextStatuses } from "features/StatusResponse/statusResponse"
import { useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { InsertTicketCommnetRequest, TaskStatusResponse, UserModel } from "services/tenantManagementService"
import { propertyOf } from "utils/propertyOf"

type Props = {
	task: InsertTicketCommnetRequest
	onSave(values: InsertTicketCommnetRequest): Promise<any>
	onCancel(): void
	crud: CrudEnum
}

export const TaskForm = ({ task, onSave, onCancel, crud }: Props) => {
	const { persistedUser, persistedTaskStatus } = useSelector((state: RootState) => state);

	const [values, setValues] = useState(task);

	const onSubmitCallback = useCallback(
		() => {
			return onSave(values);
		},
		[onSave, values]
	)

	const nextStatuses = useMemo(
		() => {
			if (task.taskStatusId) {
				const statuses = getNextStatuses(task.taskStatusId, persistedTaskStatus.items);
				return statuses;
			} else {
				return persistedTaskStatus.items;
			}
		},
		[persistedTaskStatus, task.taskStatusId]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={onCancel}
			render={() => (
				<>
					<AutoCompleteField
						id={propertyOf<InsertTicketCommnetRequest>('taskUserId')}
						label='User'
						isRequired
						items={persistedUser.items}
						getItemId={(item: UserModel) => item.id}
						getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
						loading={persistedUser.fetching}
						sort
					/>
					<AutoCompleteField
						id={propertyOf<InsertTicketCommnetRequest>('taskStatusId')}
						label='Status'
						isRequired
						items={nextStatuses}
						getItemId={(item: TaskStatusResponse) => item.id}
						getItemText={(item: TaskStatusResponse) => item.name}
						loading={persistedTaskStatus.fetching}
						disabled={crud === CrudEnum.Create}
					/>
					<DateField
						id={propertyOf<InsertTicketCommnetRequest>('taskDate')}
						label='Due date'
						isRequired
					/>
				</>
			)}
		/>
	)
}
