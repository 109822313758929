import WithFetch from 'features/Fetch/WithFetch';
import { ComponentProps } from 'features/Project'
import { useCallback, useState } from 'react';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getAllSchedulesAction } from '../action';
import styles from 'components/Map/map.module.scss'
import { ScheduleItems } from './ScheduleItems/ScheduleItems'
import { ScheduleResponse } from 'services/tenantManagementService';

// this field are part of ScheduleResponse, so types must match
export type ScheduleMapItem = {
	id: number
	name: string
	wbs: string
	start: Date
	finish: Date
	parentId?: number
	childrens: ScheduleMapItem[]
}

const convertToChildrens = (allItems: ScheduleResponse[], childrens: ScheduleMapItem[], parentId: number | undefined): ScheduleMapItem[] => {
	for (const item of allItems) {
		if (item.parentId === parentId) {
			childrens.push({
				id: item.id,
				name: item.name,
				wbs: item.wbs,
				start: item.start,
				finish: item.finish,
				parentId: item.parentId,
				childrens: []
			} as ScheduleMapItem)
		}
	}

	for (const item of childrens) {
		item.childrens = convertToChildrens(allItems, item.childrens, item.id);
	}

	return childrens;
}

export const MapView = ({ project, disabledEdit }: ComponentProps) => {
	const [parent, setParent] = useState<ScheduleMapItem>();

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getAllSchedulesAction.bind(null, project.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const childrensModel = convertToChildrens(response.items || [], [], undefined);
				setParent({
					id: 0, // 0 by purpose, some conditions are depending on value 0
					name: '',
					wbs: '',
					start: new Date(),
					finish: new Date(),
					parentId: undefined,
					childrens: childrensModel
				});
			}
		},
		[project.id]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<div className={styles.content}>
				<ScheduleItems
					project={project}
					disabledEdit={disabledEdit}
					parent={parent!}
					setParent={setParent}
					parentIndex={0}
				/>
			</div>
		</WithFetch>
	)
}
