import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import path from 'path';
import { RowContainer } from 'components/Layout';
import Button from 'components/Button';
import ConfigureView from 'features/ConfigureView';
import { ExportDataFileTypeEnum, ExportDataModel, ExportForPeriodTimesheetsRequest, FileResponse, GenericFilterModelCollection, TimesheetMergedResponse } from 'services/tenantManagementService';
import { CrudSubRoutesEnum } from 'features/Crud';
import { Export } from 'features/Export/Export';
import { ExportModel } from 'features/Export/ExportForm';
import { createExportModel } from 'utils/exportUtils';
import { BaseColumnModel } from 'components/Table';

type Props = {
	selectedTimesheet: TimesheetMergedResponse
	from: Date | undefined
	to: Date | undefined
	approveDisabled: boolean
	rejectDisabled: boolean
	approvalEnabled: boolean
	onApprove(): Promise<void>
	onReject(): void
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	exportFunction(model: ExportDataModel): Promise<FileResponse>
}

const TableButtons = ({
	selectedTimesheet,
	from,
	to,
	approveDisabled,
	rejectDisabled,
	approvalEnabled,
	onApprove,
	onReject,
	configureViewKey,
	tableColumns,
	exportFunction
}: Props) => {

	const [isApproving, setIsApproving] = useState(false);
	const [isRejecting, setIsRejecting] = useState(false);

	let history = useHistory();

	const approveCallback = useCallback(
		async () => {
			setIsApproving(true);
			await onApprove()
			setIsApproving(false);
		},
		[onApprove]
	)

	const rejectCallback = useCallback(
		async () => {
			setIsRejecting(true);
			await onReject()
			setIsRejecting(false);
		},
		[onReject]
	)

	const viewDetailsCallback = useCallback(
		() => {
			if (!from || !to) {
				return;
			}

			const newPath = path.join(
				history.location.pathname,
				CrudSubRoutesEnum.Read,
				selectedTimesheet.projectId.toString(),
				selectedTimesheet.statusId.toString(),
				selectedTimesheet.userId.toString(),
				from!.getTime().toString(),
				to!.getTime().toString()
			)
			history.push(newPath)
		},
		[history, selectedTimesheet, from, to]
	)
	return (
		<RowContainer justifyContent='space-between'>
			{approvalEnabled && (
				<RowContainer>
					<Button
						onClick={approveCallback}
						disabled={approveDisabled}
						text='Approve'
						isLoading={isApproving}
					/>
					<Button
						onClick={rejectCallback}
						disabled={rejectDisabled}
						text='Reject'
						isLoading={isRejecting}
					/>
				</RowContainer>
			)}
			<Button
				disabled={!selectedTimesheet.statusId || !from || !to}
				text='View details'
				onClick={viewDetailsCallback}
			/>
			<RowContainer>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				<Export
					title='Timesheets approval'
					filtersModel={new GenericFilterModelCollection()}
					tableColumns={tableColumns}
					exportTypeOptions={[ExportDataFileTypeEnum.XLSX, ExportDataFileTypeEnum.PDF]}
					exportAction={exportFunction}
					createExportModelFunction={createExportModelCustomFunction.bind(null, from!, to!)}
					hideApplyFilters
					hideApplySort
					hideExportOnlyCurrentPage
				/>
			</RowContainer>
		</RowContainer>
	)
}

const createExportModelCustomFunction = (from: Date, to: Date, columns: BaseColumnModel[], filtersModel: GenericFilterModelCollection, exportModel: ExportModel) => {
	const exportDataModel = createExportModel(columns, filtersModel, exportModel);
	return new ExportForPeriodTimesheetsRequest({
		...exportDataModel,
		from,
		to
	})
}

export default TableButtons;
