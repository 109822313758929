import { getTicketEnvironmentsAction, updateTicketEnvironmentsAction } from './action';
import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';

export const TicketEnvironments = () => {
	return (
		<TenantIsActiveTable
			getConfigsAction={getTicketEnvironmentsAction}
			updateConfigsAction={updateTicketEnvironmentsAction}
			successMessage='Ticket environments are updated.'
			label={{ name: 'Environment Name' }}
		/>
	)
};
