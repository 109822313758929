import { getRiskStatusesAction, updateRiskStatusesAction } from './action';
import { RiskStatusesHelp } from './RiskStatusesHelp';
import { EditableStatusTable } from 'features/EditableStatusTable/EditableStatusTable';
import { ContentShell } from 'features/Content/ContentShell';

export const Risks = () => {
	return (
		<ContentShell
			InlineHelpComponent={RiskStatusesHelp}
			showBreadcrumb={false}
		>
			<EditableStatusTable
				getStatusesction={getRiskStatusesAction}
				updateStatusesAction={updateRiskStatusesAction}
				successMessage='Risk statuses are updated.'
			/>
		</ContentShell>
	)
}
