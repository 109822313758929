import { HelpShell } from 'features/Help/HelpShell';

export const ExpenseTypesHelp = () => {
	return (
		<HelpShell title='Configure expense types'>
			<p>
				Expense types can be configured to distinguish different classification of project expenses. Mavles comes with several default entries: Business lunch/dinner, taxi, fuel, parking and other. You can configure expense types based on your company needs. <br />
				Here you can also define if attaching receipts is mandatory when submitting your expense.

			</p>
			<p>
				For each entry you are configuring, you can add additional explanation for users. When marked as 'active', entries will be shown in user selection. Please note that you cannot delete entries if there is data with these entries. You can only uncheck 'active' box and users will not be able to select this option.
			</p>
		</HelpShell>
	)
}
