import { useCallback, useState } from 'react';
import Button from 'components/Button';
import MyTestDocument from './Document/MyTestDocument';
import { ProjectResponse, TenantIsActiveResponse, TestPlanResponse, TestStatusResponse, UserModel } from 'services/tenantManagementService';
import { saveLocalDocumentToPDF } from 'utils/reactPDFUtils';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import notifications from 'components/Notification/notification';
import { getProjectAction } from 'features/Project';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTestCycleAction } from 'containers/Testing/TestCycles/action';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

type Props = {
	persistedUser: PersistItemsReducer<UserModel>
	persistedTestCaseStatus: PersistItemsReducer<TestStatusResponse>
	projectId: number
	testCycleId: number
	testPlan: TestPlanResponse
	loading?: boolean
}

const Export = ({ persistedUser, persistedTestCaseStatus, projectId, testCycleId, testPlan, loading }: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const { persistedCompanyInfo } = useSelector((state: RootState) => state);

	const downloadPDFCallback = useCallback(
		async () => {
			setIsLoading(true);

			const [projectResponse, testCycleResponse] = await Promise.all(
				[
					tryCatchJsonByAction(getProjectAction.bind(null, projectId)),
					tryCatchJsonByAction(getTestCycleAction.bind(null, projectId, testCycleId))
				]
			)

			if (!projectResponse.success || !testCycleResponse.success) {
				// if there is error while fetching, notification is triggered from tryCatchJson, so I didn't add it here
				return;
			}

			const project = projectResponse.value || new ProjectResponse();
			const testCycle = testCycleResponse.value || new TenantIsActiveResponse();

			const error = await saveLocalDocumentToPDF(
				`Test_Plan_${testPlan.id}_(${testCycle.name.trim().replace(/\s/g, '_')})`,
				<MyTestDocument
					persistedUser={persistedUser}
					persistedTestCaseStatus={persistedTestCaseStatus}
					project={project}
					testCycle={testCycle}
					testPlan={testPlan}
					base64Logo={persistedCompanyInfo.value?.logoInPDF}
				/>
			);

			error && notifications.error(error);

			setIsLoading(false);
		},
		[persistedUser, persistedTestCaseStatus, testPlan, projectId, testCycleId, persistedCompanyInfo]
	)

	return (
		<Button
			text='Export to PDF'
			color='secondary'
			onClick={downloadPDFCallback}
			isLoading={loading || isLoading}
		/>
	)
}

export default Export;
