import { ButtonGroup } from "components/ButtonGroup"
import { useCallback, useState } from "react"
import { MeetingResponse } from "services/tenantManagementService"
import { tryCatchJsonByAction } from "utils/fetchUtils"
import { setIsPrivateMeetingAction } from "../../action"
import { useGoBackFromRead } from "features/Crud"
import { EntityPrefixEnum, getFormatedId } from "utils/commonHelper"
import notifications from "components/Notification/notification"

const privateId = 'private';

const privateItems = [
	{ id: privateId, text: 'Private' }
]

const publicItems = [
	{ id: privateId, text: 'Public' }
]

type Props = {
	meeting: MeetingResponse
	projectOrCategoryId: number
	isProjectConnected: boolean
	publishDataChanged(): void
}

export const PrivateButton = ({ meeting, projectOrCategoryId, isProjectConnected, publishDataChanged }: Props) => {
	const goBackFromRead = useGoBackFromRead();

	const [selectedIds, ] = useState<any[]>(meeting.isPrivate ? [privateId] : []);
	const [loading, setLoading] = useState(false);
	const [items, ] = useState(meeting.isPrivate ? privateItems : publicItems);

	const onChangeCallback = useCallback(
		async (ids: string[]) => {
			setLoading(true);

			const isPrivate = ids.includes(privateId);
			const bindedAction = setIsPrivateMeetingAction.bind(null, meeting.id, projectOrCategoryId, isProjectConnected, isPrivate);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const formattedId = getFormatedId(EntityPrefixEnum.MEETING, meeting.id);
				notifications.success(`Meeting ${formattedId} is updated.`);
				publishDataChanged();
				goBackFromRead(); // user admin or PM can set meeting as private and then they don't have permission to see it, so go back...
			}

			setLoading(false);
		},
		[meeting.id, projectOrCategoryId, isProjectConnected, publishDataChanged, goBackFromRead]
	)

	return (
		<ButtonGroup
			items={items}
			selectedIds={selectedIds}
			onChange={onChangeCallback}
			isLoading={loading || !meeting.id}
			color='primary'
		/>
	)
}
