import { useCallback, useEffect, useState } from 'react';
import { SurveyReducedResponse, SurveyTypeEnum } from 'services/tenantManagementService';
import { Form, InputField, TextareaField, AutoCompleteField } from 'components/Form';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ColumnContainer } from 'components/Layout';

type Props = {
	surveyToClone?: SurveyReducedResponse | undefined
	onSave?: (survey: SurveyReducedResponse) => void
	renderClonePicker?: () => JSX.Element,
	cancel(): void
};

const CreateSurveyForm = ({ surveyToClone, onSave, renderClonePicker, cancel }: Props) => {
	const [initialValue, setInitialValue] = useState(surveyToClone || new SurveyReducedResponse());
	const [value, setValue] = useState(surveyToClone || new SurveyReducedResponse());

	useEffect(
		() => {
			setInitialValue(surveyToClone || new SurveyReducedResponse());
			setValue(surveyToClone || new SurveyReducedResponse());
		},
		[surveyToClone],
	)

	const onSubmitCallback = useCallback(
		async () => {
			if (value) {
				const survey = new SurveyReducedResponse(value);
				return onSave && await onSave(survey);
			}
		},
		[onSave, value]
	)

	return (
		<Form
			values={value}
			initialValues={initialValue}
			onChange={setValue}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			hideButtons={false}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							{renderClonePicker && renderClonePicker()}
							{(!renderClonePicker || surveyToClone) &&
								<>
									<AutoCompleteField
										id='surveyType'
										label='Type'
										items={Object.values(SurveyTypeEnum)}
										getItemId={(type: SurveyTypeEnum) => type}
										getItemText={(type: SurveyTypeEnum) => type}
										isRequired
										disabled={!!surveyToClone}
									/>
									<SmartFormGroup
										label='Name'
										isRequired
									>
										<InputField
											id='name'
											maxLength={240}
											inline
											isRequired
										/>
									</SmartFormGroup>
									<TextareaField
										id='description'
										label='Description (email text)'
										maxLength={2000}
										rows={10}
									/>
								</>
							}
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}
export default CreateSurveyForm;
