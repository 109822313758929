import { CheckboxField, DateField, Form, FormGroup } from 'components/Form';
import { VerticalSeparator } from 'components/Layout';
import notifications from 'components/Notification/notification';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import WithFetch from 'features/Fetch/WithFetch';
import { useCallback, useState } from 'react';
import { BudgetConfigurationResponse, ProjectResponse, UpdateBudgetConfigurationRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { propertyOf } from 'utils/propertyOf';
import { getBudgetConfigurationAction, updateBudgetConfigurationAction } from './action';
import { ContentShell } from 'features/Content/ContentShell';
import { BudgetConfigurationHelp } from './Help/BudgetConfigurationHelp';

type Props = {
	project: ProjectResponse
};

export const BudgetConfiguration = ({project}: Props) => {
	const [initialValues, setInitialValues] = useState(new BudgetConfigurationResponse())
	const [values, setValues] = useState(new BudgetConfigurationResponse())

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getBudgetConfigurationAction.bind(null, project.id);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setValues(response.value || new BudgetConfigurationResponse());
				setInitialValues(response.value || new BudgetConfigurationResponse());
			}
		},
		[project.id]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new UpdateBudgetConfigurationRequest(values);
			const bindedAction = updateBudgetConfigurationAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setValues(response.value || new BudgetConfigurationResponse());
				setInitialValues(response.value || new BudgetConfigurationResponse());
				notifications.success('Configuration is updated');
			} else {
				return convertResponseErrors(response);
			}
		},
		[values, project.id]
	)

	const cancelCallback = useCallback(
		() => {
			setValues(initialValues);
		},
		[initialValues]
	)

	return (
		<ContentShell
			FloatingHelpComponent={BudgetConfigurationHelp}
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<Form
					values={values}
					initialValues={initialValues}
					onChange={setValues}
					onSubmit={onSubmitCallback}
					onCancel={cancelCallback}
					cancelButtonText='Reset'
					render={() => (
						<SmartContainer>
							<SmartItem>
								<FormGroup title='Planning period'>
									<DateField
										id={propertyOf<BudgetConfigurationResponse>('planFrom')}
										label='From'
									/>
									<DateField
										id={propertyOf<BudgetConfigurationResponse>('planTo')}
										label='To'
									/>
								</FormGroup>
							</SmartItem>
							<SmartItem>
								<FormGroup title='Budget'>
									<CheckboxField
										id={propertyOf<BudgetConfigurationResponse>('includeRiskInBudget')}
										labelBefore='Include risk in budget'
									/>
									<CheckboxField
										id={propertyOf<BudgetConfigurationResponse>('includePlannedInvoicesInBudget')}
										labelBefore='Include planned invoices in budget'
									/>
									<CheckboxField
										id={propertyOf<BudgetConfigurationResponse>('includeApprovedChangeRequestsInBudget')}
										labelBefore='Include approved change requests in budget'
										labelBeforeAdditional='(note: If you select this option, and you expect to receive or issue invoice for change request, you need to exclude invoice from budget by selecting checkbox in invoice)'
									/>
								</FormGroup>
							</SmartItem>
							<SmartItem>
								<FormGroup title='Actuals'>
									<CheckboxField
										id={propertyOf<BudgetConfigurationResponse>('includeCompletedChangeRequests')}
										labelBefore='Include completed change requests'
										labelBeforeAdditional='(note: If you will issue or receive invoice for change requests, you should not select this option)'
									/>
									<CheckboxField
										id={propertyOf<BudgetConfigurationResponse>('includeApprovedTimesheetsInActual')}
										labelBefore='Include timesheets in actual'
										labelBeforeAdditional='(company users)'
									/>
									<CheckboxField
										id={propertyOf<BudgetConfigurationResponse>('includeApprovedTravelExpensesInActual')}
										labelBefore='Include expenses in actual'
									/>
									<CheckboxField
										id={propertyOf<BudgetConfigurationResponse>('includeIssuedReceivedAndPayedInvoicesInActual')}
										labelBefore='Include Issued/Received and payed invoices in actual'
									/>
								</FormGroup>
								<VerticalSeparator />
								<FormGroup title='Show details'>
									<CheckboxField
										id={propertyOf<BudgetConfigurationResponse>('actualsTimesheetsPerUser')}
										labelBefore='Actuals timesheets per user'
									/>
									<CheckboxField
										id={propertyOf<BudgetConfigurationResponse>('actualsTravelExpensesPerUser')}
										labelBefore='Actuals expenses per user'
									/>
									<CheckboxField
										id={propertyOf<BudgetConfigurationResponse>('actualsInvoicesPerPartner')}
										labelBefore='Actuals invoices per partner'
									/>
								</FormGroup>
							</SmartItem>
						</SmartContainer>
					)}
				/>
			</WithFetch>
		</ContentShell>
	)
}
