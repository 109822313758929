import { useCallback, useRef, useState } from 'react';
import { ChangeRequestActivityResponse } from 'services/tenantManagementService';
import { ColumnContainer } from 'components/Layout';
import { useTableColumnsMemo } from './Table/tableColumns'
import TableButtons from './Table/TableButtons';
import { generateNumberId } from 'base/id';
import { LocalTable } from 'components/Table';

type Props = {
	activities: ChangeRequestActivityResponse[]
	onChange: (activities: ChangeRequestActivityResponse[]) => void
	disabled: boolean
}

const ActivitiesTable = ({ activities, onChange, disabled }: Props) => {
	const [selectedActivity, setSelectedActivity] = useState(new ChangeRequestActivityResponse());

	const tableColumns = useTableColumnsMemo(disabled);

	const activitiesRef = useRef<ChangeRequestActivityResponse[]>([]);
	activitiesRef.current = activities;

	const selectCallback = useCallback(
		(data: ChangeRequestActivityResponse[]) => setSelectedActivity(data[0] || new ChangeRequestActivityResponse()),
		[]
	)

	const addCallback = useCallback(
		() => {
			const newActivity = new ChangeRequestActivityResponse();
			newActivity.id = generateNumberId();

			const newActivities = [
				...activities,
				newActivity
			]
			onChange(newActivities);
		},
		[activities, onChange]
	)

	const deleteCallback = useCallback(
		(id: number) => {
			const newActivities = activities.filter(act => act.id !== id);
			onChange(newActivities);
		},
		[activities, onChange]
	)

	const cellEditedCallback = useCallback(
		(cell: any) => {
			const newActivity = new ChangeRequestActivityResponse(cell.getData());
			const newActivities = activitiesRef.current.map(activity => activity.id === newActivity.id ? newActivity : activity);
			onChange(newActivities);
		},
		[onChange]
	)

	return (
		<ColumnContainer margin='medium'>
			<TableButtons
				selectedId={selectedActivity.id}
				onAddNew={addCallback}
				onDelete={deleteCallback}
				disabled={disabled}
			/>
			<LocalTable
				columns={tableColumns}
				data={activities}
				rowSelectionChanged={selectCallback}
				cellEdited={cellEditedCallback}
			/>
		</ColumnContainer>
	)
}

export default ActivitiesTable
