import { TabType } from 'components/Tabs/Tabs';
import { ScopeItemLevelResponse } from 'services/tenantManagementService';
import { TaskCommunication } from './Communication/TaskCommunication';
import { Effort } from './Effort/Effort';
import { TaskHistoryLog } from './HistoryLog/TaskHistoryLog';
import { TaskTabSubroutes } from './TaskTabSubroutes';

export type TaskTabComponentProps = {
	projectOrCategoryId: number
	isProjectConnected: boolean
	taskId: number
	isRead: boolean
	scopeItemsLevel3: ScopeItemLevelResponse[]
}

export const taskTabs: TabType[] = [
	{
		id: '1',
		title: 'Communication',
		component: TaskCommunication,
		route: TaskTabSubroutes.COMMUNICATION,
	},
	{
		id: '2',
		title: 'Effort',
		component: Effort,
		route: TaskTabSubroutes.EFFORT,
	},
	{
		id: '3',
		title: 'History',
		component: TaskHistoryLog,
		route: TaskTabSubroutes.HISTORY,
	}

];
