import { getTenantId } from 'features/Tenant/tenantUtils';
import { GenericFilterModelCollection, InsertExpenseRequest, ExpenseClient, UpdateExpenseRequest, ApprovalTimeTravelDescriptiveRequest, MonthEnum, ExportDataModel } from 'services/tenantManagementService';

export const getExpenseAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.get(tenantId, id);
}

export const getAllMyExpensesGenericAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.getAllMy(tenantId, years, months, filter);
}

export const getAllMyExpensesGenericTotalsAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.getAllMyGenericTotals(tenantId, years, months, filter);
}

export const getAllExpensesGenericAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.getAllGeneric(tenantId, years, months, filter);
}

export const getAllExpensesGenericTotalsAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.getAllGenericTotals(tenantId, years, months, filter);
}

export const createExpenseAction = (model: InsertExpenseRequest) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.create(tenantId, model);
}

export const updateExpenseAction = (model: UpdateExpenseRequest) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.update(tenantId, model);
}

export const deleteExpenseAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.delete(tenantId, id);
}

export const releaseMyExpenseAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.releaseMy(tenantId, id);
}

export const cloneMyExpenseAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.clone(tenantId, id);
}

export const approvalExpenseAction = (id: number, model: ApprovalTimeTravelDescriptiveRequest ) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.approval(tenantId, id, model);
}

export const downloadAttachmentAction = (travelExpenseItemId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.downloadAttachment(tenantId, travelExpenseItemId, id);
}

export const exportExpensesAction = (years: number[], months: MonthEnum[], body: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ExpenseClient();
	return client.exportAll(tenantId, years, months, body);
}
