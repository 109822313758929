import { getTenantId } from 'features/Tenant/tenantUtils';
import { CloneSurveyRequest, InsertSurveyRequest, SurveyClient, SurveyParticipantClient, UpdateSurveyParticipantsRequest, UpdateSurveyRequest } from 'services/tenantManagementService';

export const getSurveysForClonePickerAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new SurveyClient();
	return client.getAllReduced(tenantId, projectId, undefined, undefined, undefined);
}

export const getSurveyAction = (projectId: number, surveyId: number) => {
	const tenantId = getTenantId()!;
	const client = new SurveyClient();
	return client.get(tenantId, projectId, surveyId);
}

export const cloneSurveyAction = (projectId: number, cloneSurveyRequest: CloneSurveyRequest) => {
	const tenantId = getTenantId()!;
	const client = new SurveyClient();
	return client.clone(tenantId, projectId, cloneSurveyRequest);
}

export const createSurveyAction = (projectId: number, insertSurveyRequest: InsertSurveyRequest) => {
	const tenantId = getTenantId()!;
	const client = new SurveyClient();
	return client.create(tenantId, projectId, insertSurveyRequest);
}

export const updateSurveyAction = (projectId: number, updateSurveyRequest: UpdateSurveyRequest) => {
	const tenantId = getTenantId()!;
	const client = new SurveyClient();
	return client.update(tenantId, projectId, updateSurveyRequest);
}

export const getSurveyParticipantsAction = (projectId: number, surveyId: number) => {
	const tenantId = getTenantId()!;
	const client = new SurveyParticipantClient();
	return client.getAll(tenantId, surveyId, undefined, undefined, projectId?.toString());
}

export const updateSurveyParticipantsAction = (projectId: number, surveyId: number, updateParticipantsRequest: UpdateSurveyParticipantsRequest) => {
	const tenantId = getTenantId()!;
	const client = new SurveyParticipantClient();
	return client.updateSurveyParticipants(tenantId, projectId, surveyId, updateParticipantsRequest);
}

export const releaseAndSendToSurveyParticipantsAction = (projectId: number, surveyId: number) => {
	const tenantId = getTenantId()!;
	const client = new SurveyParticipantClient();
	return client.releaseAndSendToSurveyParticipants(tenantId, surveyId, projectId?.toString());
}
