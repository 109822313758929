import { TabType } from 'components/Tabs/Tabs';
import { ExpenseTypes } from './Tabs/ExpenseTypes/ExpenseTypes';
import { NonProjectCategory } from './Tabs/NonProjectCategory/NonProjectCategory';
import { TimesheetsGeneral } from './Tabs/TimesheetsGeneral/TimesheetsGeneral';
import { TravelCostTypes } from './Tabs/TravelCostTypes/TravelCostTypes';
import { ExpensesGeneral } from './Tabs/ExpensesGeneral/ExpensesGeneral';
import { VacationGeneral } from './Tabs/VacationGeneral/VacationGeneral';

export const configureTimeAndTravelTabs: TabType[] = [
	{
		id: '1',
		title: 'Non project category',
		route: 'set_up_category',
		component: NonProjectCategory,
		note: 'Note: time registered on non project category is not subject of approval process.'
	},
	{
		id: '2',
		title: 'Timesheets',
		route: 'timesheets_general',
		component: TimesheetsGeneral,
	},
	{
		id: '3',
		title: 'Expenses',
		route: 'expenses_general',
		component: ExpensesGeneral,
	},
	{
		id: '4',
		title: 'Travel cost types',
		route: 'travel_cost_types',
		component: TravelCostTypes,
	},
	{
		id: '5',
		title: 'Expense types',
		route: 'expense_types',
		component: ExpenseTypes,
	},
	{
		id: '6',
		title: 'Vacation',
		route: 'vacation_general',
		component: VacationGeneral,
	}
]
