import { ArrowDownIcon } from 'components/icons/icons';
import { useCallback, useMemo } from 'react';
import { MultiSelect as MultiSelectLib } from 'react-multi-select-component';
import { ControlsCommonProps } from '../../fields';
import styles from './multiSelect.module.scss';
import Spinner, { ClipSpinner } from 'components/Spinner';

export type MultiSelectProps = ControlsCommonProps<Array<string | number>> & {
	items?: any[]
	getItemId(item: any): string | number
	getItemText(item: any): string
	// placeholder?: string // not supported by current library
	loading?: boolean
	onlyOneSelectable?: boolean
	hasSelectAll?: boolean
};

export const MultiSelect = (props: MultiSelectProps) => {
	const {
		value = [], onChange,/* onBlur,*/ disabled,
		items = [], getItemId, getItemText, loading,
		onlyOneSelectable, hasSelectAll = true
	} = props;

	const onChangeCallback = useCallback(
		(dataItems: any[]) => {
			let newItems = dataItems.map((item) => item.value);
			if (onlyOneSelectable && newItems.length > 1) {
				newItems = [newItems[newItems.length - 1]];
			}
			onChange && onChange(newItems);
		},
		[onChange, onlyOneSelectable]
	);

	const itemsToOptionArray = useMemo(
		() => {
			return items.map((item) => {
				return {
					value: getItemId(item),
					label: getItemText(item)
				}
			})
		},
		[items, getItemId, getItemText]
	)

	const valuesToOptionArray = useMemo(
		() => {
			return itemsToOptionArray.filter(option => value.includes(option.value));
		},
		[value, itemsToOptionArray]
	)

	const customValueRenderer = useCallback(
		(selected, _options) => {
			if (selected.length === 1) {
				return selected[0].label;
			} else if (selected.length < _options.length) {
				return `Selected ${selected.length} items.`
			} else {
				return 'All items are selected.'
			}
		},
		[]
	)

	return (
		<div className={`${styles.container} ${disabled || loading ? styles.disabled : ''}`}>
			<MultiSelectLib
				value={valuesToOptionArray}
				options={itemsToOptionArray}
				onChange={onChangeCallback}
				disabled={disabled || loading}
				disableSearch={false}
				labelledBy=''
				hasSelectAll={hasSelectAll && !onlyOneSelectable}
				valueRenderer={customValueRenderer}
			/>
			{/* arrow */}
			<div className={styles.arrow}>
				<ArrowDownIcon width={8} height={8} fill='currentColor' />
			</div>
			{/* loading */}
			{loading &&
				<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
					<Spinner>
						<ClipSpinner size={20} />
					</Spinner>
				</div>
			}
		</div>
	)
}
