import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './createSurveysCrud.module.scss'
import { ColumnContainer } from 'components/Layout';
import { ProjectResponse, SurveyResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getSurveyAction } from './action';
import { ProjectPickerParams, getProjectAction } from 'features/Project';
import WithFetch from 'features/Fetch/WithFetch';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { SmartFormGroup } from 'components/SmartContainer/SmartContainer';
import UpdateSurveyTabs from './Update/UpdateSurveyTabs';
import { updateSurveyChangedTopic } from './Update/updateSurveyChanged';
import { RootState } from 'base/reducer/reducer';
import { useSelector } from 'react-redux';
import { persistTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = ProjectPickerParams & {
	surveyId: string
}

const UpdateSurvey = () => {
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId as string);
	const surveyId = parseInt(params.surveyId);
	const persistedTeamMember = useSelector((state: RootState) => state.persistedTeamMember);

	const [project, setProject] = useState(new ProjectResponse());
	const [survey, setSurvey] = useState(new SurveyResponse());

	const fetchSurveyCallback = useCallback(
		async () => {
			const bindedAction = getSurveyAction.bind(null, projectId, surveyId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				const surveyResponse = response.value
				setSurvey(surveyResponse);
			}
		},
		[surveyId, projectId]
	)

	const fetchProjectCallback = useCallback(
		async () => {
			const bindedAction = getProjectAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				const projectResponse = response.value
				setProject(projectResponse);
			}
		},
		[projectId]
	)

	const fetchDataCallback = useCallback(
		async () => {
			await Promise.all([fetchProjectCallback(), fetchSurveyCallback()])
		},
		[fetchProjectCallback, fetchSurveyCallback]
	)

	useEffect(
		() => {
			const subscription = updateSurveyChangedTopic?.subscribe(fetchSurveyCallback);
			return () => {
				subscription?.unsubscribe();
			}
		},
		[fetchSurveyCallback]
	)

	useEffect(
		() => {
			if (!persistedTeamMember.projectMap[projectId]) {
				persistTeamMembersAction(projectId);
			}
		},
		[persistedTeamMember, projectId]
	)

	return (
		<ContentShell title='Change survey'>
			<WithFetch fetchFunction={fetchDataCallback}>
				<ColumnContainer>
					<ColumnContainer margin='disabled'>
						<SmartFormGroup label={'Project'}>
							<div className={styles.form_group_field}>
								<p className={styles.form_group_field_value}>
									{project?.id ? `${getFormatedId(EntityPrefixEnum.PROJECT, project.id)} - ${project.name}` : ''}
								</p>
							</div>
						</SmartFormGroup>
						{
							!!survey &&
							<SmartFormGroup label={survey.surveyType}>
								<div className={styles.form_group_field}>
									<p className={styles.form_group_field_value}>
										{`${getFormatedId(EntityPrefixEnum.SURVEY, survey.id)} - ${survey.name}`}
									</p>
								</div>
							</SmartFormGroup>
						}
					</ColumnContainer>

					{!!project && !!survey && <UpdateSurveyTabs project={project} survey={survey}/>}
				</ColumnContainer>
			</WithFetch>
		</ContentShell>
	)
}

export default UpdateSurvey;
