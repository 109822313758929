import { DashboardContainer } from 'features/Dashboard/DashboardContainer/DashboardContainer';
import { FilterForm } from './FilterForm';
import { FilteredDashboard } from './FilteredDashboard';
import { FinanceDashboardFilterRequest, FinanceDashboardResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getFinanceDashboardAction } from './action';
import { useCallback, useEffect, useState } from 'react';
import WithFetch from 'features/Fetch/WithFetch';
import { useSaveFilterMemo } from 'features/Dashboard/SaveFilter/useSaveFilterMemo';

const viewType = 'finance_dashboard';

export const FinanceDashboard = () => {
	const [loading, setLoading] = useState(false);
	const { savedFilterModel, saveFilterModel, fetchFilterModel, isLoadingFilter } = useSaveFilterMemo<FinanceDashboardFilterRequest>(viewType);

	const [currentFilterModel, setCurrentFilterModel] = useState(new FinanceDashboardFilterRequest());

	const [dashboardResponse, setDashboardResponse] = useState<FinanceDashboardResponse>(new FinanceDashboardResponse());

	const fetchDashboardCallback = useCallback(
		async (model: FinanceDashboardFilterRequest) => {
			setLoading(true);

			setCurrentFilterModel(new FinanceDashboardFilterRequest(model));

			const bindedAction = getFinanceDashboardAction.bind(null, new FinanceDashboardFilterRequest(model))
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setDashboardResponse(response.value);
			}

			setLoading(false);
		},
		[]
	)

	useEffect(
		() => {
			if (savedFilterModel) {
				fetchDashboardCallback(savedFilterModel);
			}
		},
		[savedFilterModel, fetchDashboardCallback]
	)

	return (
		<WithFetch fetchFunction={fetchFilterModel} refetching={isLoadingFilter}>
			<DashboardContainer
				form={
					<FilterForm
						onSubmit={fetchDashboardCallback}
						filterFormModel={savedFilterModel}
						onSave={saveFilterModel}
					/>
				}
				dashboard={
					<FilteredDashboard
						loading={loading}
						dashboard={dashboardResponse}
						filterModel={currentFilterModel}
					/>
				}
			/>
		</WithFetch>
	)
}
