import { LoginClient, RefreshTokenWithTokenRequestModel } from "services/tenantManagementService";

export const getRefreshTokenAction = () => {
	const client = new LoginClient();
	return client.getRefreshToken();
}

export const refreshTokenWithTokenAction = (refreshToken: string) => {
	const model = new RefreshTokenWithTokenRequestModel({ refreshToken })
	const client = new LoginClient();
	return client.refreshTokenWithToken(model);
}
