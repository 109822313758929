import { TabType } from 'components/Tabs/Tabs';
import { Actuals } from './Actuals/Actuals';
import { Budget } from './Budget/Budget';
import { BudgetConfiguration } from './BudgetConfiguration/BudgetConfiguration';
import { BudgetTabSubroutes } from './BudgetSubroutes';

export const budgetTabs: TabType[] = [
	{
		id: '1',
		title: 'Configuration',
		component: BudgetConfiguration,
		route: BudgetTabSubroutes.BUDGET_CONFIGURATION,
	},
	{
		id: '2',
		title: 'Budget',
		component: Budget,
		route: BudgetTabSubroutes.BUDGET,
	},
	{
		id: '3',
		title: 'Actuals',
		component: Actuals,
		route: BudgetTabSubroutes.ACTUALS,
	},
];
