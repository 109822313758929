import { useMemo } from "react";
import { SurveyQuestionResponse, SurveyQuestionTypeEnum } from "services/tenantManagementService";
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from "components/Table";
import { OptionType } from "components/Form";

const surveyQuestionTypes: OptionType[] = [
	{ id: SurveyQuestionTypeEnum.Answer, text: 'Answer' },
	{ id: SurveyQuestionTypeEnum.MultipleChoice, text: 'Multiple Choice' },
	{ id: SurveyQuestionTypeEnum.Rating, text: 'Rating' },
	{ id: SurveyQuestionTypeEnum.SingleChoice, text: 'Single Choice' }
]

const defaultVisibleColumns = [
	propertyOf<SurveyQuestionResponse>('questionGroup'),
	propertyOf<SurveyQuestionResponse>('question'),
	propertyOf<SurveyQuestionResponse>('surveyType'),
	propertyOf<SurveyQuestionResponse>('answerRequired'),
	propertyOf<SurveyQuestionResponse>('scoreQuestion'),
]

export const useTableColumnsMemo = (
	visibleColumns: string[] = defaultVisibleColumns
) => {

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<SurveyQuestionResponse>('questionGroup')]: {
					title: 'Question group',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<SurveyQuestionResponse>('question')]: {
					title: 'Question',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<SurveyQuestionResponse>('surveyType')]: {
					title: 'Question Type',
					formatter: (cell: any) =>  {
						const value = cell.getValue()
						const type = surveyQuestionTypes.find(item => item.id === value)
						return type ? type.text : '';
					},
					fieldType: FieldTypeEnum.Options,
					options: surveyQuestionTypes,
					getItemId: (item: OptionType) => item.id,
					getItemText: (item: OptionType) => item.text,
				},
				[propertyOf<SurveyQuestionResponse>('answerRequired')]: {
					title: 'Require an answer',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<SurveyQuestionResponse>('scoreQuestion')]: {
					title: 'Score question',
					fieldType: FieldTypeEnum.Boolean
				},
			};
		},
		[]
	)

	return useVisibleColumns(columns, visibleColumns);
}
