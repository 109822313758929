import { RegistrationConfirmClient, TenantRegistrationConfirmRequestModel } from 'services/tenantManagementService';

export const confirmRegistrationAction = (token: string, userId: number, tenantId: number, languageCode: string | null) => {
	const client = new RegistrationConfirmClient();
	return client.registerConfirm(new TenantRegistrationConfirmRequestModel({
		token,
		userId,
		tenantId,
		languageCode: languageCode || '',
	}));
}

export const confirmRegistrationStatusAction = (tenantId: number, userId: number) => {
	const client = new RegistrationConfirmClient();
	return client.registerConfirmStatus(tenantId, userId);
}
