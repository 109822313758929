import { RootState } from 'base/reducer/reducer';
import { Select } from 'components/Form'
import { ColumnContainer } from 'components/Layout'
import notifications from 'components/Notification/notification';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer'
import { LocalTable } from 'components/Table';
import { approvalTimesheetAction, exportMergedTimesheetsAction, getAllMergedTimesheetsAction } from 'containers/TimeTravel/MyTimesheets/action';
import { monthOptions, yearOptions } from 'containers/TimeTravel/utils';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { ListComponentProps } from 'features/Crud';
import WithFetch from 'features/Fetch/WithFetch';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { useUsernameCallback } from 'features/TableColumns/persistedHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApprovalTimesheetRequest, MonthEnum, TimeFrequency, TimesheetMergedResponse, TimesheetsPeriodRequest, TimeTravelStatusEnum, WeekDayEnum } from 'services/tenantManagementService';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { formatDate, getDaysOfWeekSorted, getDifferenceInDaysFromMonday, getMondayOfWeekByDate, getMonthEnumByDate, getMonthIndexByMonthEnum, getWeekDayEnumByDate } from 'utils/dateTimeUtils';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import RejectRequestModalForm from '../../RejectRequest/RejectRequestModalForm';
import TableButtons from './Table/TableButtons';
import { useTableColumnsMemo } from './Table/tableColumns';

const configureViewKey = 'timesheets_for_approval_table';

const TimesheetsTable = ({ dataChangedTopic, publishDataChanged }: ListComponentProps) => {
	const [timesheets, setTimesheets] = useState<TimesheetMergedResponse[]>([]);
	const [selectedTimesheet, setSelectedTimesheet] = useState(new TimesheetMergedResponse());
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
	const [selectedMonth, setSelectedMonth] = useState<MonthEnum>(getMonthEnumByDate(new Date()));
	// store monday
	const [selectedWeek, setSelectedWeek] = useState<Date | undefined>(undefined);
	const [selectedDay, setSelectedDay] = useState<WeekDayEnum | undefined>(undefined);

	const {
		persistedTimesheetsGeneralConfiguration,
		persistedUser,
		persistedTimeAndTravelStatus
	} = useSelector((state: RootState) => state);

	const getUsername = useUsernameCallback(persistedUser);
	const tableColumns = useTableColumnsMemo(configureViewKey);
	const approvalFreq = persistedTimesheetsGeneralConfiguration.value.approvalFrequency;

	// set week/day depending on configuration
	useEffect(
		() => {
			const approvalFrequency = persistedTimesheetsGeneralConfiguration.value.approvalFrequency;
			const currentDate = new Date();

			if (approvalFrequency === TimeFrequency.Daily) {
				setSelectedDay(getWeekDayEnumByDate(currentDate));
			}

			if (approvalFrequency === TimeFrequency.Weekly || approvalFrequency === TimeFrequency.Daily) {
				setSelectedWeek(getMondayOfWeekByDate(currentDate));
			}
		},
		[persistedTimesheetsGeneralConfiguration]
	)

	const selectedDatesMemo = useMemo(
		() => {
			const monthIndex = getMonthIndexByMonthEnum(selectedMonth);

			let from: Date | undefined = undefined;
			let to: Date | undefined = undefined;

			if (approvalFreq === TimeFrequency.Monthly) {
				from = new Date(selectedYear, monthIndex);
				to = new Date(selectedYear, monthIndex);
				to.setMonth(to.getMonth() + 1)
				to.setDate(to.getDate() - 1)
			} else if (approvalFreq === TimeFrequency.Weekly && selectedWeek) {
				from = new Date(selectedWeek)
				to = new Date(selectedWeek)
				to.setDate(to.getDate() + 6);
			} else if (approvalFreq === TimeFrequency.Daily && selectedWeek && selectedDay) {
				from = new Date(selectedWeek)
				const addDays = getDifferenceInDaysFromMonday(selectedDay)
				from.setDate(from.getDate() + addDays);
				to = new Date(from)
			}

			return { from, to };
		},
		[selectedDay, selectedYear, selectedWeek, selectedMonth, approvalFreq]
	)

	const fetchDataCallback = useCallback(
		async () => {
			const { from, to } = selectedDatesMemo;

			if (!from || !to) {
				setTimesheets([]);
				return;
			}

			const request = new TimesheetsPeriodRequest({
				from,
				to,
			})
			const bindedAction = getAllMergedTimesheetsAction.bind(null, request)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.items) {
				setTimesheets(response.items)
			}
		},
		[selectedDatesMemo]
	)

	useEffect(
		() => {
			const subscription = dataChangedTopic?.subscribe(fetchDataCallback);
			return () => {
				subscription?.unsubscribe();
			}
		},
		[fetchDataCallback, dataChangedTopic]
	)

	const weekOptionsMemo = useMemo(
		() => {
			const approvalFrequency = persistedTimesheetsGeneralConfiguration.value.approvalFrequency;
			if (approvalFrequency !== TimeFrequency.Weekly && approvalFrequency !== TimeFrequency.Daily) {
				return [];
			}
			const result: Date[] = [];

			const monthIndex = getMonthIndexByMonthEnum(selectedMonth)
			const curentMonthDate = new Date(selectedYear, monthIndex);
			const firstMondayMonthDate = getMondayOfWeekByDate(curentMonthDate);

			const currentMonday = new Date(firstMondayMonthDate);

			for (let i = 0; i < 6; i++) {
				const newDate = new Date(currentMonday);
				result.push(newDate);
				currentMonday.setDate(currentMonday.getDate() + 7);
			}

			return result;

		},
		[persistedTimesheetsGeneralConfiguration, selectedMonth, selectedYear]
	)

	// set monday as first
	const daysOfWeekOptionsMemo = useMemo(
		() => getDaysOfWeekSorted(),
		[]
	)

	const selectCallback = useCallback(
		(data: TimesheetMergedResponse[]) => setSelectedTimesheet(data[0] || new TimesheetMergedResponse()),
		[]
	)

	const approveCallback = useCallback(
		async () => {
			const { from, to } = selectedDatesMemo;

			if (!from || !to) {
				return;
			}

			setIsSubmitting(true)
			const request = new ApprovalTimesheetRequest({
				approve: true,
				userId: selectedTimesheet.userId,
				projectId: selectedTimesheet.projectId,
				statusId: selectedTimesheet.statusId,
				from,
				to,
			})
			const bindedAction = approvalTimesheetAction.bind(null, request);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`Timesheets is approved`)
				publishDataChanged()
			}
			setIsSubmitting(false)
		},
		[selectedTimesheet, publishDataChanged, selectedDatesMemo]
	)

	const rejectCallback = useCallback(
		async (rejectionComment?: string) => {
			const { from, to } = selectedDatesMemo;

			if (!from || !to) {
				return;
			}

			setIsSubmitting(true)
			const request = new ApprovalTimesheetRequest({
				approve: false,
				rejectionText: rejectionComment,
				userId: selectedTimesheet.userId,
				projectId: selectedTimesheet.projectId,
				statusId: selectedTimesheet.statusId,
				from,
				to,
			})
			const bindedAction = approvalTimesheetAction.bind(null, request);
			const response = await tryCatchJsonByAction(bindedAction);
			setIsSubmitting(false)
			if (response.success) {
				notifications.success('Timesheet is rejected')
				setIsModalOpen(false);
				publishDataChanged()
			} else {
				return convertResponseErrors(response);
			}

		},
		[selectedTimesheet, selectedDatesMemo, publishDataChanged]
	)

	const approveDisabledMemo = useMemo(
		() => !selectedTimesheet.statusId ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, selectedTimesheet.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Approved, selectedTimesheet.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[selectedTimesheet, persistedTimeAndTravelStatus.itemsMap, isSubmitting]
	)

	const rejectDisabledisabledMemo = useMemo(
		() => !selectedTimesheet.statusId ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, selectedTimesheet.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Rejected, selectedTimesheet.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[selectedTimesheet, persistedTimeAndTravelStatus.itemsMap, isSubmitting]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ColumnContainer margin='medium'>
			<SmartContainer>
				<SmartItem>
					<SmartFormGroup label='Year'>
						<Select
							value={selectedYear}
							onChange={(newSelectedYear: number) => {
								setSelectedYear(newSelectedYear);
								setSelectedWeek(undefined);
								setSelectedDay(undefined);
							}}
							items={yearOptions}
							getItemId={(item: number) => item}
							getItemText={(item: number) => item.toString()}
							containsEmpty={false}
						/>
					</SmartFormGroup>
					<SmartFormGroup label='Month'>
						<Select
							value={selectedMonth}
							onChange={(newSelectedMonth: MonthEnum) => {
								setSelectedMonth(newSelectedMonth);
								setSelectedWeek(undefined);
								setSelectedDay(undefined);
							}}
							items={monthOptions}
							getItemId={(item: MonthEnum) => item}
							getItemText={(item: MonthEnum) => item}
							containsEmpty={false}
						/>
					</SmartFormGroup>
				</SmartItem>
				<SmartItem>
					{(approvalFreq === TimeFrequency.Weekly || approvalFreq === TimeFrequency.Daily) && (
						<SmartFormGroup label='Week'>
							<Select
								value={selectedWeek?.getTime()}
								onChange={(newWeekInTicks: number) => { setSelectedWeek(new Date(newWeekInTicks)) }}
								items={weekOptionsMemo}
								getItemId={(item: Date) => item.getTime()}
								getItemText={(item: Date) => {
									const endOfWeek = new Date(item);
									endOfWeek.setDate(endOfWeek.getDate() + 6);
									return `${formatDate(item)} - ${formatDate(endOfWeek)}`
								}}
								containsEmpty={false}
							/>
						</SmartFormGroup>
					)}
					{approvalFreq === TimeFrequency.Daily && (
						<SmartFormGroup label='Day'>
							<Select
								value={selectedDay}
								onChange={(newSelectedDay: WeekDayEnum) => { setSelectedDay(newSelectedDay) }}
								items={daysOfWeekOptionsMemo}
								getItemId={(item: WeekDayEnum) => item}
								getItemText={(item: WeekDayEnum) => item}
								containsEmpty={false}
							/>
						</SmartFormGroup>
					)}
				</SmartItem>
			</SmartContainer>
			<TableButtons
				selectedTimesheet={selectedTimesheet}
				from={selectedDatesMemo.from}
				to={selectedDatesMemo.to}
				approveDisabled={approveDisabledMemo}
				rejectDisabled={rejectDisabledisabledMemo}
				approvalEnabled={persistedTimesheetsGeneralConfiguration.value.enableApprovalProcess}
				onApprove={approveCallback}
				onReject={() => {setIsModalOpen(true)}}
				tableColumns={tableColumns}
				exportFunction={exportMergedTimesheetsAction}
				configureViewKey={configureViewKey}
			/>
			<WithFetch fetchFunction={fetchDataCallback}>
				<LocalTable
					columns={tableColumns}
					data={timesheets}
					rowSelectionChanged={selectCallback}
					reorderColumns={reorderColumnsCallback}
				/>
			</WithFetch>
			<RejectRequestModalForm
				title={`Reject timesheets - ${getFormatedId(EntityPrefixEnum.PROJECT, selectedTimesheet.projectId)} for ${getUsername(selectedTimesheet.userId)}`}
				onCancel={() => {setIsModalOpen(false)}}
				onSave={rejectCallback}
				open={isModalOpen}
				rejectionCommentRequired={persistedTimesheetsGeneralConfiguration.value.requireCommentsWhenRejecting}
			/>
		</ColumnContainer>
	)
}

export default TimesheetsTable;
