import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { usePartnerCallback, useFormattedCurrencyValueCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { BudgetDateValueHeaderResponse, BudgetRowResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { RootState } from 'base/reducer/reducer';
import { FieldTypeEnum, TableColumnsType, TableColumnType, useVisibleColumns } from 'components/Table';
import { incomeExpenseOptions } from '../BudgetAndActualsCommon';
import { formatServerDate } from 'utils/dateTimeUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { getChangeRequestRoute } from 'utils/routeUtils';

export const totalFieldName = 'total';

const defaultVisibleColumns: string[] = [
    propertyOf<BudgetRowResponse>('financeCategoryId'),
    propertyOf<BudgetRowResponse>('versionId'),
    propertyOf<BudgetRowResponse>('userId'),
    propertyOf<BudgetRowResponse>('partnerId'),
    propertyOf<BudgetRowResponse>('changeRequestId'),
    propertyOf<BudgetRowResponse>('incomeExpense'),
	propertyOf<BudgetRowResponse>('customField')
]

export const useTableColumnsMemo = (
	headers: BudgetDateValueHeaderResponse[],
	projectId: number
) => {
	const {
		persistedUser,
		persistedBusinessPartner,
		persistedFinanceCategory
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getPartner = usePartnerCallback(persistedBusinessPartner);
	const getFormattedCurrencyValue = useFormattedCurrencyValueCallback();

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<BudgetRowResponse>('financeCategoryId')]: {
					title: 'Category',
					frozen: true,
					formatter: (cell: any) => {
						const value = cell.getValue();
						return persistedFinanceCategory.itemsMap[value]?.name;
					},
					fieldType: FieldTypeEnum.None,
					editor: 'list',
					editable: (cell: any) => {
						const budget: BudgetRowResponse = cell.getData();
						return budget.isChangeable;
					},
					editorParams: {
						values: persistedFinanceCategory.items.map(item => {
							return {
								label: item.name,
								value: item.id
							}
						}),
						autocomplete: true,
						listOnEmpty: true,
						allowEmpty: true,
						placeholderEmpty: 'No options'

					}
				},
				[propertyOf<BudgetRowResponse>('versionId')]: {
					title: 'Version ID',
					frozen: true,
					fieldType: FieldTypeEnum.None
				},
				[propertyOf<BudgetRowResponse>('userId')]: {
					title: 'User',
					frozen: true,
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.None,
					editor: 'list',
					editable: (cell: any) => {
						const budget: BudgetRowResponse = cell.getData();
						return budget.isChangeable;
					},
					editorParams: {
						values: persistedUser.items.map(item => {
							return {
								label: `${item.firstName} ${item.lastName}`,
								value: item.id
							}
						}),
						autocomplete: true,
						listOnEmpty: true,
						allowEmpty: true,
						placeholderEmpty: 'No options'
					}
				},
				[propertyOf<BudgetRowResponse>('partnerId')]: {
					title: 'Partner',
					frozen: true,
					formatter: (cell: any) => getPartner(cell.getValue()),
					fieldType: FieldTypeEnum.None,
					editor: 'list',
					editable: (cell: any) => {
						const budget: BudgetRowResponse = cell.getData();
						return budget.isChangeable;
					},
					editorParams: {
						values: persistedBusinessPartner.items.map(item => {
							return {
								label: item.name,
								value: item.id
							}
						}),
						autocomplete: true,
						listOnEmpty: true,
						allowEmpty: true,
						placeholderEmpty: 'No options'
					}
				},
				[propertyOf<BudgetRowResponse>('changeRequestId')]: {
					title: 'Change Request',
					frozen: true,
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.CHANGE_REQUEST,
					formatter: (cell: any) => {
						const value: number = cell.getValue();
						if (!value) {
							return ''
						}
						return (
							`<a href="${window.location.protocol}//${window.location.host}${getChangeRequestRoute(value, projectId)}" target="_blank">${getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, value)}</a>`
						);
					},
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<BudgetRowResponse>('incomeExpense')]: {
					title: 'Income/Expense',
					frozen: true,
					formatter: (cell: any) => {
						const value = cell.getValue();
						const option = incomeExpenseOptions.find((item) => item.id === value);
						return option?.text;
					},
					fieldType: FieldTypeEnum.None,
					editor: 'list',
					editable: (cell: any) => {
						const budget: BudgetRowResponse = cell.getData();
						return budget.isIncomeExpenseChangeable;
					},
					editorParams: {
						values: incomeExpenseOptions.map(item => {
							return {
								label: item.text,
								value: item.id
							}
						})
					}
				},
				[propertyOf<BudgetRowResponse>('customField')]: {
					title: 'Custom field',
					frozen: true,
					fieldType: FieldTypeEnum.None,
					editor: 'input',
					editable: (cell: any) => {
						const budget: BudgetRowResponse = cell.getData();
						return budget.isChangeable;
					}
				}
			}
		},
		[getUserFullName, getPartner, persistedFinanceCategory, persistedBusinessPartner, persistedUser, projectId]
	)

	const totalColumn: TableColumnType = useMemo(
		() => {
			return {
				title: 'Total',
				frozen: true,
				fieldType: FieldTypeEnum.Currency,
				formatter: (cell: any) => {
					const value = cell.getValue();
					if (typeof value === 'number') {
						return getFormattedCurrencyValue(value);
					} else {
						// it is string with percent suffix (%)
						return value;
					}
				},
				disableFilter: true,
				disableSort: true
			}
		},
		[getFormattedCurrencyValue]
	)

	const {
		mergedColumns,
		mergedDefaultVisibleColumns
	} = useMemo(
		() => {
			const mergedColumns: TableColumnsType = { ...columns };
			const mergedDefaultVisibleColumns = [...defaultVisibleColumns];

			for (const header of headers) {
				const field = formatServerDate(header.date);
				mergedColumns[field] = {
					title: header.title,
					fieldType: FieldTypeEnum.Currency,
					editor: 'number',
					editable: (cell: any) => {
						const budget: BudgetRowResponse = cell.getData();
						return budget.isChangeable;
					},
					disableSort: true,
					disableFilter: true
				}
				mergedDefaultVisibleColumns.push(field);
			}

			mergedColumns[totalFieldName] = totalColumn;
			mergedDefaultVisibleColumns.push(totalFieldName);

			return {
				mergedColumns,
				mergedDefaultVisibleColumns
			}
		},
		[columns, headers, totalColumn]
	)

	return useVisibleColumns(mergedColumns, mergedDefaultVisibleColumns);
}
