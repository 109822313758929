import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import VacationRequestForm from './VacationRequestForm';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { VacationRequestResponse, InsertVacationRequestRequest } from 'services/tenantManagementService';
import { createVacationRequestAction } from '../action';
import { ContentShell } from 'features/Content/ContentShell';

const CreateMyVacationRequest = ({ publishDataChanged }: CreateComponentProps) => {
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newVacationRequest: VacationRequestResponse, isRelease: boolean) => {
			const model = new InsertVacationRequestRequest({
				...newVacationRequest,
				isRelease,
			});
			const bindedAction = createVacationRequestAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.VACATION_REQUEST, response.value?.id);
				notifications.success(`Vacation request ${id} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	);

	return (
		<ContentShell title='Create vacation request'>
			<VacationRequestForm
				crud={CrudEnum.Create}
				onSave={handleSave}
			/>
		</ContentShell>
	)
}

export default CreateMyVacationRequest;
