import { HelpShell } from 'features/Help/HelpShell';

export const NonProjectCategoryHelp = () => {
	return (
		<HelpShell title='Non-project categories'>
			<p>
				In Mavles you can record working time related to project activities, but also you can configure Non-Project Categories such as: administration tasks, presales, sick leave, holidays, etc.
			</p>
			<p>
				These categories are not part of timesheet approval process, and time recorded on these categories is not subject of approval. <br />
				Non project categories can be marked as productive if they have impact on team productivity. <br />
				Mavles comes with defaulted entry for Vacation since this is integrated with approved vacation requests. <br />
				You can configure non-project categories based on your company needs.
			</p>
		</HelpShell>
	)
}
