import { HelpShell } from 'features/Help/HelpShell';

export const ReportStatusesHelp = () => {
	return (
		<HelpShell title='Project report statuses'>
			<div>
				<p>Project report can have 2 statuses:</p>
				<ul>
					<li>Created</li>
					<li>Released</li>
				</ul>
			</div>
			<p>These statuses cannot be changed or deleted, but you can add explanation for each status.</p>
			<p>Project managers can initially <b>create</b> project report and save it. At this time status report can be further changed.<br />
				When project manager <b>releases</b> project report, changes are no longer possible and new report needs to be created 
				by copying last report.<br />
				Project report has new status: released.
			</p>
		</HelpShell>
	)
}
