import { HelpShell } from 'features/Help/HelpShell'

export const ImportTimesheetsHelp = () => {
	return (
		<HelpShell title='Upload timesheets'>
			<p>Project managers can upload user timesheets from xls files.</p>
			<p>When uploaded, items are by default in status: 'approved'. No workflow for approval process is triggered.</p>
			<p>Please note that prior to upload, all users have to be created in Mavles.</p>
			<p>Please use provided template for your data upload.</p>
			<div>
				<p>Structure of spread sheet is following:</p>
				<ol>
					<li>User ID - please use user ID from Mavles application - you can see user ID in Communication -{'>'} Contact list</li>
					<li>Date - please use format mm/dd/yyyy</li>
					<li>Project ID - please use project ID from Mavles application - example: P00000023</li>
					<li>Effort - Please enter time in format 00:00</li>
					<li>Activity description - Free text no limitation</li>
					<li>Change request ID - Please use change request ID provided in Mavles application</li>
					<li>Ticket ID - Please use change request ID provided in Mavles application</li>
					<li>Text 1 - Free text no limitation</li>
					<li>Text 2 - Free text no limitation</li>
					<li>Text 3 - Free text no limitation</li>
				</ol>
			</div>
			<div>
				<p>
					Prior to data upload, please check if project is in status released, if users are defined for projects, if users are active on projects, if project ID is entered correctly. <br />
					If imported data needs to be corrected, following steps needs to be applied:
				</p>
				<ol>
					<li>project managers will need to reject timesheets</li>
					<li>users will have to delete all entries</li>
				</ol>
			</div>
		</HelpShell>
	)
}
