import { useMemo } from 'react';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { PriorityResponse } from 'services/tenantManagementService';
import { getPriority } from './helper';

export const usePriorityNameMemo = (persistedTicketPriority: PersistItemsReducer<PriorityResponse>, impactId: number, urgencyId: number) => {
	return useMemo(
		() => {
			const priority = getPriority(persistedTicketPriority.items, impactId, urgencyId);
			if (priority) {
				return priority.name;
			} else {
				return ''
			}
		},
		[persistedTicketPriority.items, impactId, urgencyId]
	)
}
