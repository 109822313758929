import { ColumnContainer } from 'components/Layout';
import { useTableColumnsMemo } from './tableColumns'
import { VacationRequestStatusResponse } from 'services/tenantManagementService';
import { LocalTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { useCallback } from 'react';

type Props = {
	statuses: VacationRequestStatusResponse[]
}

const configureViewKey = 'my_vacation_request_statuses_read_table';

const VacationRequestStatusTable = ({ statuses }: Props) => {
	const tableColumns = useTableColumnsMemo(configureViewKey);

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ColumnContainer margin='medium'>
			<LocalTable
				columns={tableColumns}
				data={statuses}
				reorderColumns={reorderColumnsCallback}
			/>
		</ColumnContainer>
	)
};

export default VacationRequestStatusTable
