import { useCallback, useMemo, useState } from 'react';
import Button from 'components/Button';
import { Dialog } from 'components/Dialog';
import { ExportDataFileTypeEnum, MonthEnum } from 'services/tenantManagementService';
import ExportForm from './ExportForm';

type Props = {
	onExport: (nonProjectCategoryId: number | undefined, projectId: number | undefined, years: number[] | undefined, months: MonthEnum[] | undefined, exportType: ExportDataFileTypeEnum) => void
	loading?: boolean
	exportTypeOptions?: ExportDataFileTypeEnum[]
}

const ExportToFile = ({
	onExport,
	loading,
	exportTypeOptions,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const exportTypeOptionsMemo = useMemo(
		() => {
			const selectedExportTypeOptions = !exportTypeOptions || exportTypeOptions.length === 0 ?
				Object.values(ExportDataFileTypeEnum) :
				exportTypeOptions

			return selectedExportTypeOptions.map(option => {
				return {
					id: option,
					text: option,
				}
			});
		},
		[exportTypeOptions]
	)

	const exportCallback = useCallback(
		(nonProjectCategoryId: number | undefined, projectId: number | undefined, years: number[] | undefined, months: MonthEnum[] | undefined, exportType: ExportDataFileTypeEnum) => {
			onExport(nonProjectCategoryId, projectId, years, months, exportType);
			setIsOpen(false);
		},
		[onExport]
	)

	const cancelCallback = useCallback(
		() => setIsOpen(false),
		[]
	)

	const openModalCallback = useCallback(
		() => setIsOpen(true),
		[]
	)

	return (
		<>
			<Button
				text='Export'
				color='secondary'
				onClick={openModalCallback}
				isLoading={loading}
			/>
			<Dialog
				title='Export to file'
				onClose={cancelCallback}
				open={isOpen}
			>
				<ExportForm
					save={exportCallback}
					cancel={cancelCallback}
					exportTypeOptions={exportTypeOptionsMemo}
				/>
			</Dialog>
		</>
	)
}

export default ExportToFile;
