import { gantt } from 'dhtmlx-gantt'

const formatQuarters = (date: Date) => {
	const month = date.getMonth();
	let q_num;

	if (month >= 9) {
		q_num = 4;
	} else if (month >= 6) {
		q_num = 3;
	} else if (month >= 3) {
		q_num = 2;
	} else {
		q_num = 1;
	}

	return `Q${q_num}`;
}

const formatQuartersWithYear = (date: Date) => {
	const year = date.getFullYear();
	const formattedQuarters = formatQuarters(date);
	return `${formattedQuarters} ${year}`;
}

const formatWeek = (date: Date) => {
	const dateToStr = gantt.date.date_to_str('%d %M');
	const endDate = gantt.date.add(date, 6, 'day');
	const weekNum = gantt.date.date_to_str('%W')(date);
	const year = gantt.date.date_to_str('%Y')(date);
	return 'Week ' + weekNum + ', ' + dateToStr(date) + ' - ' + dateToStr(endDate) + ', ' + year;
}

const getWeekendClassName = (date: Date) => {
	if (date.getDay() === 0 || date.getDay() === 6) {
		return 'gantt_cell_weekend'
	}

	return undefined as any
}

export const zoomConfig = {
	activeLevelIndex: 3, // quarter_month
	levels: [
		{
			name: 'day',
			scale_height: 50,
			min_column_width: 80,
			scales:[
				{ unit: 'month', step: 1, format: '%F, %Y' },
				{ unit: 'day', step: 1, format: '%d %M', css: getWeekendClassName }
			]
		},
		{
			name: 'week_day',
			scale_height: 50,
			min_column_width: 50,
			scales: [
				{ unit: 'week', step: 1, format: formatWeek },
				{ unit: 'day', step: 1, format: '%j %D', css: getWeekendClassName }
			]
		},
		{
			name: 'month_week',
			scale_height: 50,
			min_column_width: 120,
			scales: [
				{ unit: 'month', step: 1, format: '%F, %Y' },
				{ unit: 'week', step: 1, format: 'Week %W' }
			]
		},
		{
			name: 'quarter_month',
			scale_height: 50,
			min_column_width: 90,
			scales: [
				{ unit: 'month', step: 1, format: '%M' },
				{ unit: 'quarter', step: 1, format: formatQuartersWithYear }
			]
		},
		{
			name: 'year_quarter',
			scale_height: 50,
			min_column_width: 90,
			scales: [
				{ unit: 'quarter', step: 1, format: formatQuarters },
				{ unit: 'year', step: 1, format: '%Y' }
			]
		},
		{
			name: 'year',
			scale_height: 50,
			min_column_width: 200,
			scales:[
				{ unit: 'year', step: 1, format: '%Y' }
			]
		}
	]
}
