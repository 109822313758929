import { FileInputField, Form } from 'components/Form';
import { ColumnContainer } from 'components/Layout';
import notifications from 'components/Notification/notification';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ContentShell } from 'features/Content/ContentShell';
import { useCallback, useState } from 'react';
import { UploadExcelFileRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { propertyOf } from 'utils/propertyOf';
import { importVacationsAction } from './action';
import { ImportVacationsHelp } from './ImportVacationsHelp';

export const ImportVacations = () => {
	const [values, setValues] = useState(new UploadExcelFileRequest());

	const onSubmitCallback = useCallback(
		async () => {
			const bindedAction = importVacationsAction.bind(null, new UploadExcelFileRequest(values))
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Vacations successfully imported');
			}
		},
		[values]
	)

	return (
		<ContentShell
			InlineHelpComponent={ImportVacationsHelp}
		>
			<Form
				values={values}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				render={() => (
					<SmartContainer>
						<SmartItem>
							<ColumnContainer>
								<FileInputField
									id={propertyOf<UploadExcelFileRequest>('excelAttachments')}
									label='Select file'
									accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
									isRequired
								/>
								<a href='/ImportTemplates/Vacations_upload_template.xlsx'>Click to download template</a>
							</ColumnContainer>
						</SmartItem>
					</SmartContainer>
				)}
				submitButtonText='Upload from excel'
				hideCancelButton
				disableUnsavedChangesGuard
			/>
		</ContentShell>
	)
};
