import { Document, Font, Page, StyleSheet, Image } from '@react-pdf/renderer';
import { CostExpenseTypeResponse, ExpenseResponse, FileResponse, IdNameResponse, NonProjectCategoryResponse, ProjectResponse, TenantResponseModel, TimeTravelStatusResponse, UserModel } from 'services/tenantManagementService';
import { PersistActiveItemsReducer, PersistItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import { TitleField } from 'components/Export';
import ExpenseDocumentForm from './ExpenseDocumentForm';
import ExpenseDocumentTable from './ExpenseDocumentTable';
import ExpenseDocumentAttachments from './ExpenseDocumentAttachments';

type Props = {
	expense: ExpenseResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedTimeAndTravelStatus: PersistItemsReducer<TimeTravelStatusResponse>
	persistedProject: PersistItemsReducer<ProjectResponse>
	persistedTimeTravelNonProjectCategories: PersistActiveItemsReducer<NonProjectCategoryResponse>
	persistedTimeTravelExpenseTypes: PersistItemsReducer<CostExpenseTypeResponse>
	persistedTenant: PersistValueReducer<TenantResponseModel>,
	persistedCurrency: PersistItemsReducer<IdNameResponse>,
	attachments: FileResponse[] | undefined
	base64Logo?: string
}

const ExpenseDocument = ({
	expense,
	persistedUser,
	persistedProject,
	persistedTimeTravelNonProjectCategories,
	persistedTimeAndTravelStatus,
	persistedTenant,
	persistedCurrency,
	persistedTimeTravelExpenseTypes,
	attachments,
	base64Logo
}: Props) => {
	return (
		<Document>
			<Page wrap size="A4" style={styles.page}>
				{base64Logo && <Image style={{ marginLeft: 'auto', maxWidth:'100px', height: 'auto'}} src={base64Logo} /> }
				<TitleField title='Expense report' />
				<ExpenseDocumentForm
					expense={expense}
					persistedUser={persistedUser}
					persistedProject={persistedProject}
					persistedTimeTravelNonProjectCategories={persistedTimeTravelNonProjectCategories}
					persistedTimeAndTravelStatus={persistedTimeAndTravelStatus}
				/>
				<ExpenseDocumentTable
					expense={expense}
					persistedTenant={persistedTenant}
					persistedCurrency={persistedCurrency}
					persistedTimeTravelExpenseTypes={persistedTimeTravelExpenseTypes}
				/>
				<ExpenseDocumentAttachments
					attachments={attachments}
				/>
			</Page>
		</Document>
	);
};

export default ExpenseDocument;

Font.register({
  family: 'Open Sans',
  fonts: [
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
	{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const styles = StyleSheet.create({
	page: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingHorizontal: 10,
		fontFamily: 'Open Sans'
	},
});
