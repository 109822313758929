import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'base/reducer/reducer';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Form, InputField, DateField, TextEditorField, AttachmentField, AutoCompleteField } from 'components/Form';
import { VerticalSeparator } from 'components/Layout';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { TicketResponse, TimeMeasureEnum, TicketAssignedGroupsResponse, TenantIsActiveResponse,	TicketStatusResponse, UserModel, TicketTestPlansResponse, InsertTicketRequest, TicketTimesheetResponse, SimpleScheduleResponse, TicketStatusEnum } from 'services/tenantManagementService';
import { donwloadAttachmentAction } from '../action';
import { getInitialStatus, getNextStatuses, isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { propertyOf } from 'utils/propertyOf';
import { TicketFormTabs } from './Tabs/TicketFormTabs';
import { TestPlans } from './TestPlans/TestPlans';
import { Priority } from './Priority/Priority';
import { Categories } from './Categories/Categories';
import { ChangeRequest } from './ChangeRequest/ChangeRequest';
import { ParentTicket } from './ParentTicket/ParentTicket';
import { Project } from './Project/Project';
import { generateNumberId } from 'base/id';
import { getUserInfo } from 'utils/storageUtils';
import Button from 'components/Button';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getAllSimpleSchedulesAction } from 'containers/Schedule/Schedule/action';
import { ClosingModal } from './Closing/ClosingModal/ClosingModal';

type HistoryStateType = {
	failedTestPlans: TicketTestPlansResponse[]
	projectId: string
}

type LocationType = {
	state?: HistoryStateType
}

type Props = {
	ticket?: TicketResponse
	crud: CrudEnum
	onSave?: (ticket: TicketResponse, isInitiateChangeRequest?: boolean) => void
}

export const TicketForm = ({
	ticket = new TicketResponse(),
	crud,
	onSave
}: Props) => {
	const {
		persistedUser,
		persistedTicketAssignedGroup,
		persistedTicketType,
		persistedTicketEnvironment,
		persistedTicketStatus,
	} = useSelector((state: RootState) => state);

	// state is used only when clicking button in application (MyTest - save and create ticket), so it is safe to use
	const { state }: LocationType = useLocation();

	const [values, setValues] = useState(ticket);
	const [initialValues, setInitialValues] = useState(ticket);
	const [schedules, setSchedules] = useState<SimpleScheduleResponse[]>([]);
	const [loadingSchedules, setLoadingSchedules] = useState<boolean>(false);

	const isRead = crud === CrudEnum.Read;
	const isUpdate = crud === CrudEnum.Update;
	const isCreate = crud === CrudEnum.Create;

	const goBackFromCrud = useGoBackFromCrud(crud, false, isUpdate)

	const isInitiateChangeRequestRef = useRef(false);

	const nextStatuses = useMemo(
		() => {
			if (isUpdate) {
				return getNextStatuses(ticket.statusId, persistedTicketStatus.items);
			} else {
				return persistedTicketStatus.items;
			}
		},
		[ticket.statusId, persistedTicketStatus, isUpdate]
	)

	const schedulesPerProjectMap = useMemo(() => schedules.reduce((map: {[id: number]: SimpleScheduleResponse[]}, item) => {
		let schedulesPerProject = map[item.projectId];
		if (!schedulesPerProject) {
			map[item.projectId] = [item];
		}
		else {
			schedulesPerProject.push(item);
		}

		return map;
	}, {}), [schedules])

	const schedulesPerProject = useMemo(
		() => values.projectId ? schedulesPerProjectMap[values.projectId] || [] : [],
		[values, schedulesPerProjectMap],
	)

	const fetchAdditionalCallback = useCallback(async () => {
		setLoadingSchedules(true);
		const schedulesResponse = await tryCatchJsonByAction(getAllSimpleSchedulesAction);
		if (schedulesResponse.success) {
			setSchedules(schedulesResponse.items || []);
		}
		setLoadingSchedules(false);
	}, [])

	useEffect(() => {
		fetchAdditionalCallback()
	}, [fetchAdditionalCallback])

	useEffect(
		() => {
			setInitialValues((currentState: TicketResponse) => {
				return new TicketResponse({
					...currentState,
					projectId: (state && state.projectId && parseInt(state.projectId)) || currentState.projectId,
					testPlans: state?.failedTestPlans || currentState.testPlans
				})
			})

			setValues((currentState: TicketResponse) => {
				return new TicketResponse({
					...currentState,
					projectId: (state && state.projectId && parseInt(state.projectId)) || currentState.projectId,
					testPlans: state?.failedTestPlans || currentState.testPlans
				})
			})
		},
		[state]
	)

	useEffect(
		() => {
			// if CRUD.Create, we need to set initial status
			if (isCreate) {
				const status = getInitialStatus(persistedTicketStatus.items);

				setInitialValues((state: any) => {
					return {
						...state,
						statusId: status && status.id
					}
				})

				setValues((state: any) => {
					return {
						...state,
						statusId: status && status.id
					}
				})
			}
		},
		[isCreate, persistedTicketStatus.items]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const ticket = new TicketResponse(values);
			if (ticket.effortUnit === TimeMeasureEnum.Hours) {
				ticket.effort = (ticket.effort || 0) * 60;
			}

			if (ticket.effortUnit === TimeMeasureEnum.Days) {
				ticket.effort = (ticket.effort || 0) * 60 * 24;
			}

			const response = onSave && await onSave(ticket, isInitiateChangeRequestRef.current)
			isInitiateChangeRequestRef.current = false;
			return response;
		},
		[onSave, values]
	)

	const createdByUserItems = useMemo(
		() => {
			const user = persistedUser.itemsMap[values.createdByUserId];
			if (user) {
				return [user];
			}
			return [];
		},
		[persistedUser, values.createdByUserId]
	)

	const donwloadAttachmentMemo = useMemo(
		() => donwloadAttachmentAction.bind(null, ticket.id),
		[ticket.id]
	)

	// Svetlana asked to disable delete on existing attachments
	// const removeAttachmentCallback = useCallback(
	// 	async (id: number) => {
	// 		const bindedAction = removeAttachmentAction.bind(null, ticket.id, id);
	// 		const response = await tryCatchJsonByAction(bindedAction);
	// 		if (response.success) {
	// 			notifications.success('Attachment is successfully deleted');
	// 			setValues((state: TicketResponse) =>
	// 				new TicketResponse({
	// 					...state,
	// 					attachments: state.attachments?.filter(att => att.id !== id)
	// 				})
	// 			)
	// 		}
	// 	},
	// 	[ticket.id]
	// )

	const addEffortTimesheetCallback = useCallback(
		() => {
			const id = generateNumberId();
			const user = getUserInfo();

			setValues((state: TicketResponse) => {
				const newModel = new TicketTimesheetResponse();
				newModel.id = id;
				newModel.userId = user.id

				const newState = new TicketResponse({
					...state,
					timesheets: [...(state.timesheets || []), newModel]
				});

				return newState;
			})
		},
		[]
	);

	const isStatusClosed = useMemo(
		() => isStatusBySemantic(TicketStatusEnum.Closed, values.statusId, persistedTicketStatus.itemsMap),
		[values.statusId, persistedTicketStatus.itemsMap]
	);

	const closeModalCallback = useCallback(
		() => {
			setValues((state: TicketResponse) => new TicketResponse({
				...state,
				statusId: initialValues.statusId
			}));
		},
		[initialValues.statusId]
	)

	return (
		<Form
			values={values}
			initialValues={initialValues}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			disabled={isRead}
			hideButtons={isRead}
			renderAdditionalButtons={(disabled: boolean, handleSubmitCallback: () => void, isSubmitting: boolean) => (
				<Button
					text='Save and initiate change request'
					onClick={async () => {
						isInitiateChangeRequestRef.current = true;
						handleSubmitCallback();
					}}
					color='neutral'
					disabled={disabled}
					isLoading={isSubmitting && isInitiateChangeRequestRef.current === true}
				/>
			)}
			render={() => (
				<>
					<SmartContainer>
						<SmartItem>
							<AutoCompleteField
								id={propertyOf<TicketResponse>('statusId')}
								label='Status'
								isRequired
								disabled={!isUpdate}
								items={nextStatuses}
								getItemId={(item: TicketStatusResponse) => item.id}
								getItemText={(item: TicketStatusResponse) => item.name}
								getItemDescription={(item: TicketStatusResponse) => item.description}
								loading={persistedTicketStatus.fetching}
							/>
				 			<AutoCompleteField
								id={propertyOf<TicketResponse>('assignedGroupId')}
								label='Assigned group'
								items={persistedTicketAssignedGroup.activeItems}
								getItemId={(item: TicketAssignedGroupsResponse) => item.id}
								getItemText={(item: TicketAssignedGroupsResponse) => item.name}
								loading={persistedTicketAssignedGroup.fetching}
							/>
							<AutoCompleteField
								id={propertyOf<TicketResponse>('assignedToUserId')}
								label='Assigned to'
								isRequired
								items={persistedUser.items}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								loading={persistedUser.fetching}
								sort
							/>
							<Project
								initialValues={initialValues}
							/>
							<InputField
								id={propertyOf<TicketResponse>('reportedBy')}
								label='Reported by'
								isRequired
							/>
							<DateField
								id={propertyOf<TicketResponse>('reportedOn')}
								label='Reported on'
							/>
						</SmartItem>
						<SmartItem>
							<DateField
								id={propertyOf<TicketResponse>('expectedResolutionDate')}
								label='Expected resolution'
							/>
							<AutoCompleteField
								id={propertyOf<TicketResponse>('typeId')}
								label='Type'
								isRequired
								items={persistedTicketType.activeItems}
								getItemId={(item: TenantIsActiveResponse) => item.id}
								getItemText={(item: TenantIsActiveResponse) => item.name}
								getItemDescription={(item: TenantIsActiveResponse) => item.description}
								loading={persistedTicketType.fetching}
							/>
							<Categories
								values={values}
							/>
							<AutoCompleteField
								id={propertyOf<TicketResponse>('environmentId')}
								label='Environment'
								items={persistedTicketEnvironment.activeItems}
								getItemId={(item: TenantIsActiveResponse) => item.id}
								getItemText={(item: TenantIsActiveResponse) => item.name}
								getItemDescription={(item: TenantIsActiveResponse) => item.description}
								loading={persistedTicketEnvironment.fetching}
							/>
						</SmartItem>
						<SmartItem>
							<AutoCompleteField
								id={propertyOf<TicketResponse>('scheduleId')}
								label='WBS'
								items={schedulesPerProject}
								getItemId={(item: SimpleScheduleResponse) => item.id}
								getItemText={(item: SimpleScheduleResponse) => `${item.wbs} - ${item.name}`}
								loading={loadingSchedules}
							/>

							<Priority values={values} />
							<ParentTicket />
							<InputField
								id={propertyOf<TicketResponse>('ticketNumberExt')}
								label='Ticket number ext'
							/>
						</SmartItem>
					</SmartContainer>
					<VerticalSeparator />

					<SmartContainer>
						<SmartItem size='xlarge'>
							<SmartFormGroup label='Test cases'>
								<TestPlans
									values={values}
									setValues={setValues}
									isRead={isRead}
								/>
							</SmartFormGroup>
						</SmartItem>
						<SmartItem size='small'>
							<ChangeRequest
								ticket={values}
							/>
						</SmartItem>
					</SmartContainer>

					<VerticalSeparator margin='large' />

					<AttachmentField
						id={propertyOf<InsertTicketRequest>('newAttachments')}
						label='Attachments'
						multiple
						disabled={isRead}
						oldAttachments={values.attachments}
						// removeOldAttachment={removeAttachmentCallback}
						downloadOldAttachment={donwloadAttachmentMemo}
					/>

		 			<InputField
						id={propertyOf<TicketResponse>('name')}
						label='Short description'
						isRequired
					/>
					<TextEditorField
						id={propertyOf<TicketResponse>('description')}
						label='Description'
						disabled={isUpdate}
					/>

					<VerticalSeparator margin='large' />

					<SmartContainer>
						<SmartItem>
				 			<AutoCompleteField
				 				id={propertyOf<TicketResponse>('createdByUserId')}
								label='Created by'
								items={createdByUserItems}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								disabled
							/>
							<AutoCompleteField
								id={propertyOf<TicketResponse>('closedByUserId')}
								label='Closed by'
								items={persistedUser.items}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								loading={persistedUser.fetching}
								disabled
							/>
						</SmartItem>
					</SmartContainer>

					{(isRead || isUpdate) && (
						<>
							<VerticalSeparator />
							<TicketFormTabs
								ticket={values}
								schedules={schedules}
								isRead={isRead}
								addEffortTimesheet={addEffortTimesheetCallback}
							/>
						</>
					)}

					{isUpdate &&
						<ClosingModal
							open={isStatusClosed}
							ticket={values}
							onSave={onSave}
							cancel={closeModalCallback}
						/>
					}
				</>
			)}
		/>
	)
}
