import { useState, useCallback, useMemo } from 'react';
import { ColumnContainer } from 'components/Layout';
import Button from 'components/Button';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { CheckboxField, Form, InputField, TableField, TextareaField, globalErrorKey } from 'components/Form';
import { TenantIsActiveDeltaRequest, TenantIsActiveResponse, TenantIsActiveResponseItemsResponseModel, UpdateTenantIsActiveRequest, DeleteTenantIndetifyRequest, InsertTenantIsActiveRequest } from 'services/tenantManagementService';
import { generateNumberId } from 'base/id';
import WithFetch from 'features/Fetch/WithFetch';
import { unpackDelta, createDelta } from 'utils/commonHelper';

type TableFieldObject = {
	configs: TenantIsActiveResponse[]
}

type Props = {
	getConfigsAction: () => Promise<TenantIsActiveResponseItemsResponseModel>
	updateConfigsAction: (configsDelta: TenantIsActiveDeltaRequest) => Promise<TenantIsActiveResponseItemsResponseModel>
	successMessage: string
	label?: {
		active?: string
		name?: string
		description?: string
	}
	disabled?: boolean
	isConfigReadonly?(config: TenantIsActiveResponse): boolean
	biggerDescription?: boolean
}

const TenantIsActiveTable = ({ getConfigsAction, updateConfigsAction, successMessage, disabled, isConfigReadonly, label = {}, biggerDescription }: Props) => {
	const [initialConfigs, setInitialConfigs] = useState<TableFieldObject>({ configs: [] });
	const [values, setValues] = useState<TableFieldObject>({ configs: [] });
	const [refethcing, setRefetching] = useState(false);

	const fetchData = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getConfigsAction);
			if (response.success) {
				setValues({ configs: response.items || [] });
				setInitialConfigs({ configs: response.items || [] });
			}
		},
		[getConfigsAction]
	);

	const refetchDataCallback = useCallback(
		async () => {
			setRefetching(true);
			await fetchData();
			setRefetching(false);
		},
		[fetchData]
	);

	const addCallback = useCallback(
		() => {
			const id = generateNumberId();

			setValues((state) => {
				const newModel = new TenantIsActiveResponse({
					id,
					name: '',
					description: '',
					isActive: true
				});

				const newState = { ...state }
				newState.configs = [...newState.configs, newModel];

				return newState;
			})
		},
		[]
	);

	const cancelCallback = useCallback(
		() => setValues({ ...initialConfigs }),
		[initialConfigs]
	)

	const onSubmitCallback = useCallback(
		async () => {
			if (!values.configs.length) {
				return Promise.resolve({ [globalErrorKey]: 'At least one item should be created' })
			}
			const configsDelta = createDelta(
				initialConfigs.configs,
				values.configs,
				InsertTenantIsActiveRequest,
				UpdateTenantIsActiveRequest,
				TenantIsActiveDeltaRequest,
				DeleteTenantIndetifyRequest
			)
			const bindedAction = updateConfigsAction.bind(null, configsDelta);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(successMessage);
				refetchDataCallback();
			} else {
				const errors = convertResponseErrors(response)
				const [configErrors, newConfigs] = unpackDelta(errors, configsDelta, values.configs, initialConfigs.configs);
				setValues({...values, configs: newConfigs});
				return { configs: configErrors };
			}
		},
		[initialConfigs, values, updateConfigsAction, refetchDataCallback, successMessage]
	)

	const uniqueFieldNames = useMemo(
		() => [{ id: 'name', label: label.name || 'Name' }],
		[label.name]
	)

	return (
		<WithFetch fetchFunction={fetchData} refetching={refethcing}>
			<ColumnContainer margin='medium'>
				<Button
					onClick={addCallback}
					text='Add'
					disabled={disabled}
				/>
				<Form
					values={values}
					initialValues={initialConfigs}
					onChange={setValues}
					onSubmit={onSubmitCallback}
					onCancel={cancelCallback}
					disabled={disabled}
					hideButtons={disabled}
					render={() => (
						<TableField
							id='configs'
							uniqueFieldNames={uniqueFieldNames}
							headers={[
								{ label: label.active || 'Active', size: 1 },
								{ label: label.name || 'Name', size: 4, isRequired: true },
								{ label: label.description || 'Explanation', size: 6 },
							]}
							getRowData={(row: TenantIsActiveResponse) => {
								const isReadonly = isConfigReadonly ? isConfigReadonly(row) : false;
								return {
									isDeletable: !disabled && !isReadonly,
									fields: [
										<CheckboxField id='isActive' />,
										<InputField
											id='name'
											isRequired
											maxLength={25}
											disabled={isReadonly}
										/>,
										biggerDescription ? <TextareaField id='description' maxLength={2000}/> : <InputField id='description' />
									]
								}
							}}
						/>
					)}
				/>
			</ColumnContainer>
		</WithFetch>
	)
}

export default TenantIsActiveTable;
