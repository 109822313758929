import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { ExpenseResponse, ExpenseTotalsResponse, ModuleActivityEnum, TimeTravelStatusResponse, UserModel } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useApplyStatusColorCallback, useFormattedProjectIdNameCallback, useStatusCallback, useUsernameCallback } from 'features/TableColumns/persistedHooks';
import { IProjectOrCategory, useProjectsAndCategoriesForTableFilterCallback } from 'features/Project';

const defaultVisibleColumns = [
	propertyOf<ExpenseResponse>('projectOrCategoryId'),
	propertyOf<ExpenseResponse>('id'),
	propertyOf<ExpenseResponse>('date'),
	propertyOf<ExpenseResponse>('description'),
	propertyOf<ExpenseResponse>('statusId'),
	propertyOf<ExpenseResponse>('amount')
]

export const useTableColumnsMemo = (expenseTotals: ExpenseTotalsResponse, configureViewKey: string) => {
	const {
		persistedTimeTravelNonProjectCategories,
		persistedTimeAndTravelStatus,
		persistedUser,
		persistedProject,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUsername = useUsernameCallback(persistedUser);
	const getStatusName = useStatusCallback(persistedTimeAndTravelStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedTimeAndTravelStatus);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	const { projectsOrCategories } = useProjectsAndCategoriesForTableFilterCallback(ModuleActivityEnum.Time);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ExpenseResponse>('id')]: {
					title: 'Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.TIME_TRAVEL_EXPENSE
				},
				[propertyOf<ExpenseResponse>('projectOrCategoryId')]: {
					title: 'Project Id or category',
					formatter: (cell: any) => {
						const isProject = (cell.getData() as ExpenseResponse).isProjectConnected;
						if (isProject) {
							return formatProjectIdName(cell.getValue());
						}
						return persistedTimeTravelNonProjectCategories.itemsMap[cell.getValue()]?.name || '';
					},
					fieldType: FieldTypeEnum.Options,
					options: projectsOrCategories,
					getItemId: (option: IProjectOrCategory) => option.projectOrCategoryId,
					getItemText: (option: IProjectOrCategory) => option.label,
					dbFilterFieldPath: 'ProjectRefId',
				},
				[propertyOf<ExpenseResponse>('date')]: {
					title: 'Date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ExpenseResponse>('userId')]: {
					title: 'Created by',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username,
					dbExportFieldPath: null
				},
				[propertyOf<ExpenseResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatusName(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTimeAndTravelStatus.items,
					getItemId: (option: TimeTravelStatusResponse) => option.id,
					getItemText: (option: TimeTravelStatusResponse) => option.name,
					dbFilterFieldPath: 'StatusRefId'
				},
				[propertyOf<ExpenseResponse>('customText1')]: {
					title: 'Text 1',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ExpenseResponse>('customText2')]: {
					title: 'Text 2',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ExpenseResponse>('amount')]: {
					title: 'Total cost',
					disableSort: true,
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: () => expenseTotals.totalAmounts
				},
				[propertyOf<ExpenseResponse>('description')]: {
					title: 'Description',
					fieldType: FieldTypeEnum.String
				},
			}
		},
		[
			getUsername,
			getStatusName,
			applyStatusColor,
			formatProjectIdName,
			expenseTotals,
			projectsOrCategories,
			persistedUser.items,
			persistedTimeAndTravelStatus.items,
			persistedTimeTravelNonProjectCategories
		]
	)

	return useVisibleColumns(columns, visibleColumns);
}
