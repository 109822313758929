import { RootState } from "base/reducer/reducer"
import Button from "components/Button/Button"
import { HtmlSanitized } from "components/HtmlSanitized/HtmlSanitized"
import { VerticalSeparator } from "components/Layout"
import { SmartFormGroup, SmartItem } from "components/SmartContainer/SmartContainer"
import { useUserFullNameCallback } from "features/TableColumns/persistedHooks"
import { useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import { MeetingCommentResponse } from "services/tenantManagementService"
import { formatDateTime } from "utils/dateTimeUtils"
import { getUserInfo } from "utils/storageUtils"

type Props = {
	comment: MeetingCommentResponse
	onDelete(id: number): void
	isDeleting?: boolean
	disabled?: boolean
}

export const Comment = ({ comment, onDelete, isDeleting, disabled }: Props) => {
	const { persistedUser } = useSelector((state: RootState) => state);
	const getUserFullName = useUserFullNameCallback(persistedUser);

	const onDeleteCallback = useCallback(
		() => {
			onDelete(comment.id);
		},
		[onDelete, comment.id]
	)

	const showDeleteButtonMemo = useMemo(
		() => {
			const user = getUserInfo();
			return user.id === comment.userId;
		},
		[comment.userId]
	)

	return (
		<div style={{ display: 'flex', marginLeft: '-24px', marginBottom: '-32px' }}>
			<SmartItem>
				<SmartFormGroup label={getUserFullName(comment.userId)}>
					<strong>
						<HtmlSanitized html={comment.comment || ''} />
					</strong>
					<VerticalSeparator margin='xsmall' />
					<small>{formatDateTime(comment.createdOn)}</small>
					<VerticalSeparator margin='xsmall' />
				</SmartFormGroup>
			</SmartItem>
			{(showDeleteButtonMemo && !disabled) &&
				<div style={{ marginTop: '16px' }}>
					<Button
						text='x'
						color='destructive'
						onClick={onDeleteCallback}
						isLoading={isDeleting}
					/>
				</div>
			}
		</div>
	)
}
