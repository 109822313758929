import { generateNumberId } from "base/id"
import { RootState } from "base/reducer/reducer"
import Button from "components/Button"
import { DateField, Form, InputField, MultiSelectField, RadioField, RadioItemType, TextareaField, TextEditorField, AttachmentField, ItemsField, CheckboxField, AutoCompleteField } from "components/Form"
import { ColumnContainer, VerticalSeparator } from "components/Layout"
import { SmartContainer, SmartFormGroup, SmartItem } from "components/SmartContainer/SmartContainer"
import { getAllTeamMembersAction } from "containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action"
import { CrudEnum } from "features/Crud"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { InsertMeetingRequest, MeetingResponse, MeetingStatusResponse, MeetingTopicResponse, MeetingTopicTypeEnum, ModuleActivityEnum, ProjectIsActiveResponse, TaskStatusResponse, TenantIsActiveResponse, UserModel } from "services/tenantManagementService"
import { tryCatchJsonByAction } from "utils/fetchUtils"
import { propertyOf } from "utils/propertyOf"
import { downloadAttachmentAction, removeAttachmentAction } from "../action"
import { getDateIncrementByNumberOfMinutes, getNumberOfMinutesBetweenDates } from "utils/dateTimeUtils"
import { Comments } from "./Comments/Comments"
import notifications from 'components/Notification/notification'
import { getUserInfo } from 'utils/storageUtils'
import { ProjectOrCategorySelect, ProjectOrCategoryType } from "features/Project"

const meetingTopicType: RadioItemType[] = [
	{ id: MeetingTopicTypeEnum.Task, text: 'Task' },
	{ id: MeetingTopicTypeEnum.Information, text: 'Information' },
	{ id: MeetingTopicTypeEnum.Decision, text: 'Decision' }
]

const meetingDuration: number[] = [
	5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60,
	65, 70, 75, 80, 85, 90, 95, 100, 110, 120, 150,	180, 210, 240
]

type Props = {
	meeting: MeetingResponse
	crud?: CrudEnum
	onSave?: (newMeeting: MeetingResponse, isRelease: boolean) => void
	cancel?(): void
}

export const MeetingForm = ({
	meeting,
	crud,
	onSave,
	cancel
}: Props) => {
	const {
		persistedUser,
		persistedProjectTeam,
		persistedMeetingStatus,
		persistedMeetingType,
		persistedTaskStatus
	} = useSelector((state: RootState) => state);

	const isReleaseRef = useRef(false);
	const [values, setValues] = useState(meeting || new MeetingResponse());
	const [users, setUsers] = useState<UserModel[]>([]);
	const [fetchingUsers, setFetchingUsers] = useState(false);

	const [projectOrCategory, setProjectOrCategory] = useState<ProjectOrCategoryType | undefined>({
		projectOrCategoryId: values.projectOrCategoryId,
		isProjectConnected: values.isProjectConnected
	});

	const fetchUsersCallback = useCallback(
		async () => {
			if (!persistedUser.isInitialized) {
				return;
			}

			if (!values.isProjectConnected) {
				setUsers(persistedUser.items.sort((a, b) => {
					return `${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}` ? -1 : 1;
				}))
				return;
			}
			setFetchingUsers(true);

			const bindedAction = getAllTeamMembersAction.bind(null, values.projectOrCategoryId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const teamMembers = response.items || [];
				const activeTeamMembers = teamMembers.filter((item) => item.isActive);
				const activeTeamMemberUsers = activeTeamMembers.map((item) => persistedUser.itemsMap[item.userId]!)
				setUsers(activeTeamMemberUsers.sort((a, b) => {
					return `${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}` ? -1 : 1;
				}))
			}

			setFetchingUsers(false);
		},
		[values.projectOrCategoryId, values.isProjectConnected, persistedUser]
	)

	useEffect(
		() => {
			fetchUsersCallback();
		},
		[fetchUsersCallback]
	)

	const projectTeams = useMemo(
		() => {
			if (values.isProjectConnected) {
				return persistedProjectTeam.projectMap[values.projectOrCategoryId]?.items || []
			}

			return [];
		},
		[values.isProjectConnected, values.projectOrCategoryId, persistedProjectTeam]
	)

	const onSubmitCallback = useCallback(
		async () => await onSave!(values, isReleaseRef.current),
		[onSave, values]
	)

	const downloadAttachmentMemo = useMemo(
		() => downloadAttachmentAction.bind(null, values?.id),
		[values.id]
	)

	const removeAttachmentCallback = useCallback(
		async (id: number) => {
			const bindedAction = removeAttachmentAction.bind(null, values.id, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Attachment is successfully deleted');
				setValues((state: MeetingResponse) =>
					new MeetingResponse({
						...state,
						attachments: state.attachments?.filter(att => att.id !== id)
					})
				)
			}
		},
		[values.id]
	)

	const isRead = crud === CrudEnum.Read;
	const isCreate = crud === CrudEnum.Create;

	const addTopicItemCallback = useCallback(
		() => {
			const id = generateNumberId();

			setValues((state) => {
				const lastTopic = state.topics?.slice(-1).pop()

				const newModel = new MeetingTopicResponse();
				newModel.id = id;
				newModel.generatedId = id.toString();
				newModel.order = 0;
				if (lastTopic) {
					newModel.order = lastTopic.order + 1;
				}

				const newState = new MeetingResponse(state);
				if (newState.topics) {
					newState.topics = [...newState.topics, newModel];
				} else {
					newState.topics = [newModel];
				}

				return newState;
			})
		},
		[]
	)

	const topicsContent = useMemo(
		() => (
			<ColumnContainer margin='medium'>
				<SmartFormGroup label='Topics'>
					<Button
						text='Add topic'
						onClick={addTopicItemCallback}
						disabled={isRead}
					/>
				</SmartFormGroup>

				<ItemsField
					id={propertyOf<MeetingResponse>('topics')}
					uniqueValueKey={propertyOf<MeetingTopicResponse>('generatedId')}
					renderItem={(topic: MeetingTopicResponse) => {
						const isTask: boolean = topic.type === MeetingTopicTypeEnum.Task;
						const isTopicUpdateOrRead = meeting.topics?.find((item) => item.id === topic.id) !== undefined

						return (
							<SmartContainer>
								<SmartItem size='xlarge'>
									<TextEditorField
										id={propertyOf<MeetingTopicResponse>('description')}
										label='Description'
										multiline
										isRequired
									/>
								</SmartItem>
								<SmartItem>
									<RadioField
										id={propertyOf<MeetingTopicResponse>('type')}
										items={meetingTopicType}
									/>
									{isTask &&
										<>
											<AutoCompleteField
												id={propertyOf<MeetingTopicResponse>('userId')}
												label='Assigned to'
												items={users}
												getItemId={(item: UserModel) => item.id}
												getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
												loading={fetchingUsers}
												disabled={!isTask}
												isRequired
												sort
											/>
											<DateField
												id={propertyOf<MeetingTopicResponse>('due')}
												label='Due date'
												disabled={!isTask}
												isRequired
											/>
											<AutoCompleteField
												id={propertyOf<MeetingTopicResponse>('statusId')}
												label='Status'
												items={persistedTaskStatus.items}
												getItemId={(item: TaskStatusResponse) => item.id}
												getItemText={(item: TaskStatusResponse) => item.name}
												loading={persistedTaskStatus.fetching}
												disabled={!isTask}
												isRequired
											/>
										</>
									}
									<VerticalSeparator margin='xlarge' />
									{isTopicUpdateOrRead &&
										<Comments
											meeting={meeting}
											topic={topic}
											disabled={isRead}
										/>
									}
								</SmartItem>
							</SmartContainer>
						)
					}}
				/>
			</ColumnContainer>
		),
		[addTopicItemCallback, isRead, persistedTaskStatus, fetchingUsers, users, meeting]
	)

	useEffect(
		() => {
			const invalidTopics = values.topics?.filter(topic => topic.type !== MeetingTopicTypeEnum.Task && (topic.userId || topic.due || topic.statusId))
			if (invalidTopics && invalidTopics.length > 0) {
				setValues((state) => {
					const newState = new MeetingResponse(state);
					newState.topics = state.topics?.map(topic => topic.type === MeetingTopicTypeEnum.Task ? topic : new MeetingTopicResponse({
						...topic,
						userId: undefined,
						due: undefined,
						statusId: undefined
					}));

					return newState;
				})
			}
		},
		[values.topics]
	)

	const onStartsChangeCallback = useCallback(
		(newStarts: Date | undefined, oldValues: MeetingResponse) => {
			let duration = oldValues.duration;
			let ends: Date | undefined = oldValues.ends;

			if (!newStarts) {
				ends = undefined;
			} else if (newStarts && oldValues.duration) {
				ends = getDateIncrementByNumberOfMinutes(newStarts, oldValues.duration);
			} else if (newStarts && oldValues.ends) {
				duration = getNumberOfMinutesBetweenDates(newStarts, oldValues.ends);
			}

			return {
				[propertyOf<MeetingResponse>('duration')]: duration,
				[propertyOf<MeetingResponse>('ends')]: ends
			}
		},
		[]
	)

	const onDurationChangeCallback = useCallback(
		(newDuration: number | undefined, oldValues: MeetingResponse) => {
			let ends: Date | undefined;
			if (oldValues.starts && newDuration) {
				ends = getDateIncrementByNumberOfMinutes(oldValues.starts, newDuration);
			}

			return {
				[propertyOf<MeetingResponse>('ends')]: ends
			}
		},
		[]
	)

	const onEndsChangeCallback = useCallback(
		(newEnds: Date | undefined, oldValues: MeetingResponse) => {
			let duration: number | undefined;
			if (oldValues.starts && newEnds) {
				duration = getNumberOfMinutesBetweenDates(oldValues.starts, newEnds);
			}

			return {
				[propertyOf<MeetingResponse>('duration')]: duration
			}
		},
		[]
	)

	const isReleaseAllowed = useMemo(
		() => {
			const user = getUserInfo();
			return isCreate || user.id === values.userId;
		},
		[values.userId, isCreate]
	);

	return (
		<Form
			values={values}
			initialValues={meeting}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			disabled={isRead}
			hideButtons={isRead}
			render={() => (
				<>
					<SmartContainer>
						<SmartItem>
							<ProjectOrCategorySelect
								value={projectOrCategory}
								onChange={setProjectOrCategory}
								disabled
								moduleEnum={ModuleActivityEnum.Communication}
								showCompleted={isRead}
								isRequired
							/>
							<DateField
								id={propertyOf<MeetingResponse>('starts')}
								label='Starts'
								showTime
								dateFormat='MM/dd/yyyy h:mm aa'
								isRequired
								updateDependants={onStartsChangeCallback}
							/>
							<AutoCompleteField
								id={propertyOf<MeetingResponse>('duration')}
								label='Duration'
								items={meetingDuration}
								getItemId={(item: number) => item}
								getItemText={(item: number) => `${item.toString()} min`}
								updateDependants={onDurationChangeCallback}
							/>
							<DateField
								id={propertyOf<MeetingResponse>('ends')}
								label='Ends'
								showTime
								dateFormat='MM/dd/yyyy h:mm aa'
								updateDependants={onEndsChangeCallback}
								isRequired
							/>
							<CheckboxField
								id={propertyOf<MeetingResponse>('isPrivate')}
								label='Private for participants'
							/>
							{isRead &&
								<AutoCompleteField
									id={propertyOf<MeetingResponse>('statusId')}
									label='Status'
									isRequired
									items={persistedMeetingStatus.items}
									getItemId={(item: MeetingStatusResponse) => item.id}
									getItemText={(item: MeetingStatusResponse) => item.name}
									loading={persistedMeetingStatus.fetching}
								/>
							}
							<AttachmentField
								id={propertyOf<InsertMeetingRequest>('newAttachments')}
								label='Attachments'
								multiple
								disabled={isRead}
								oldAttachments={values.attachments}
								downloadOldAttachment={downloadAttachmentMemo}
								removeOldAttachment={removeAttachmentCallback}
							/>
						</SmartItem>
						<SmartItem>
							<InputField
								id={propertyOf<MeetingResponse>('subject')}
								label='Subject'
								maxLength={100}
								isRequired
							/>
							<MultiSelectField
								id={propertyOf<MeetingResponse>('participantUserIds')}
								label='Participants'
								items={users}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								loading={fetchingUsers}
								isRequired
							/>
							<AutoCompleteField
								id={propertyOf<MeetingResponse>('projectTeamId')}
								label='Project team'
								items={projectTeams}
								getItemId={(item: ProjectIsActiveResponse) => item.id}
								getItemText={(item: ProjectIsActiveResponse) => item.name}
								loading={values.isProjectConnected && persistedProjectTeam.projectMap[values.projectOrCategoryId]?.fetching}
							/>
							<AutoCompleteField
								id={propertyOf<MeetingResponse>('meetingTypeId')}
								label='Meeting type'
								items={persistedMeetingType.items}
								getItemId={(item: TenantIsActiveResponse) => item.id}
								getItemText={(item: TenantIsActiveResponse) => item.name}
								loading={persistedMeetingType.fetching}
							/>
							<InputField
								id={propertyOf<MeetingResponse>('location')}
								label='Location'
							/>
						</SmartItem>
						<SmartItem>
							<TextareaField
								id={propertyOf<MeetingResponse>('agenda')}
								label='Meeting Agenda'
								maxLength={2000}
								rows={11}
								isRequired
							/>
						</SmartItem>
					</SmartContainer>
					<VerticalSeparator />
					{topicsContent}
				</>
			)}
			renderAdditionalButtons={(disabled, handleSubmitCallback: () => void, isSubmitting) => (
				isReleaseAllowed && (
					<Button
						text='Release'
						disabled={disabled}
						isLoading={isSubmitting && isReleaseRef.current === true}
						onClick={
							() => {
								isReleaseRef.current = true;
								handleSubmitCallback();
							}
						}
					/>
				)

			)}
		/>
	)
}
