import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud';
import { CreateMajorAccomplishment } from './Crud/CreateMajorAccomplishment';
import { UpdateMajorAccomplishment } from './Crud/UpdateMajorAccomplishment';

export const MajorAccomplishmentRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/${CrudSubRoutesEnum.Create}`} component={CreateMajorAccomplishment} />
			<Route path={`${path}/${CrudSubRoutesEnum.Update}/:majorAccomplishmentId`} component={UpdateMajorAccomplishment} />
		</Switch>
	)
}
