import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { TimeTravelDashboardRowResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';

const defaultColumns = [
	propertyOf<TimeTravelDashboardRowResponse>('id'),
	propertyOf<TimeTravelDashboardRowResponse>('projectOrCategoryId'),
	propertyOf<TimeTravelDashboardRowResponse>('year'),
	propertyOf<TimeTravelDashboardRowResponse>('month'),
	propertyOf<TimeTravelDashboardRowResponse>('amount')
]

export const useTableColumnsMemo = (isEstimatedAmount: boolean = false) => {
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<TimeTravelDashboardRowResponse>('id')]: {
					title: 'Id',
					fieldType: FieldTypeEnum.Number,
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<TimeTravelDashboardRowResponse>('projectOrCategoryId')]: {
					title: 'Project Id',
					fieldType: FieldTypeEnum.Number,
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<TimeTravelDashboardRowResponse>('year')]: {
					title: 'Year',
					fieldType: FieldTypeEnum.Number,
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<TimeTravelDashboardRowResponse>('month')]: {
					title: 'Month',
					fieldType: FieldTypeEnum.String,
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<TimeTravelDashboardRowResponse>('amount')]: {
					title: isEstimatedAmount ? 'Estimated amount' : 'Amount',
					fieldType: FieldTypeEnum.Currency,
					disableSort: true,
					disableFilter: true,
				}
			}
		},
		[isEstimatedAmount]
	)

	return useVisibleColumns(columns, defaultColumns);
}
