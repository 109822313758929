import { useMemo } from 'react'
import { ScopeProcessesDashboardResponse, ScopeItemLevelResponse } from 'services/tenantManagementService'
import { Layout } from 'features/Dashboard/Layout'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { Dashboard } from 'features/Dashboard/Dashboard';
import { TextWidget } from 'features/Dashboard/Widget/TextWidget'

type Props = {
	loading: boolean
	dashboard: ScopeProcessesDashboardResponse
	level1Items: ScopeItemLevelResponse[]
}

const FilteredDashboard = ({ loading, dashboard, level1Items }: Props) => {
	const perLevel1DataMemo = useMemo(
		() => {
			const data = level1Items.map(item => {
				return {
					id: item.id.toString(),
					name: item.name,
					count: (dashboard.processesPerLvl1 && dashboard.processesPerLvl1[item.id]) ? dashboard.processesPerLvl1[item.id] : 0
				}
			});

			return data;
		},
		[dashboard, level1Items]
	)

	return (
		<Dashboard orientation='horizontal'>
			<Layout
				orientation='vertical'
				className='col-xl-4 col-md-6'
			>
				<Layout orientation='horizontal'>
					<TextWidget
						className='col-6'
						title='Total Number of Processes (level 3)'
						value={dashboard.lvl3sCount}
						loading={loading}
					/>
					<TextWidget
						className='col-6'
						title='Total Number of Processes Steps (level 4)'
						value={dashboard.lvl4sCount}
						loading={loading}
					/>
				</Layout>
				<Layout orientation='horizontal'>
					<TextWidget
						className='col-6'
						title={`Total Number of Level 1 ID's`}
						value={dashboard.lvl1sCount}
						loading={loading}
					/>
					<TextWidget
						className='col-6'
						title={`Total Number of Level 2 ID's`}
						value={dashboard.lvl2sCount}
						loading={loading}
					/>
				</Layout>
			</Layout>
			<PieWidget
				className='col-xl-4 col-md-6'
				title='Processes per Level 1 Grouping'
				id='processes_per_level1_pie_chart'
				fieldValue='count'
				fieldCategory='name'
				data={perLevel1DataMemo}
				loading={loading}
				usePercentAndValueForLegend
			/>
		</Dashboard>
	)
}

export default FilteredDashboard
