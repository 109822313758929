import { useMemo } from 'react';
import { ProjectIsActiveResponse, ReminderResponse, UserModel } from 'services/tenantManagementService';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { useFormattedProjectIdNameCallback, useTenantIsActiveCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { formatDateTime } from 'utils/dateTimeUtils';

const defaultVisibleColumns = [
	propertyOf<ReminderResponse>('id'),
	propertyOf<ReminderResponse>('subject'),
	propertyOf<ReminderResponse>('startDate'),
	propertyOf<ReminderResponse>('endDate'),
	propertyOf<ReminderResponse>('isRepeat'),
	propertyOf<ReminderResponse>('projectTeamId'),
	propertyOf<ReminderResponse>('recipientUserIds'),
]

export const useTableColumnsMemo = (configureViewKey: string) => {
	const {
		persistedUser,
		persistedConfigureView,
		persistedProjectTeam,
		persistedTimeTravelNonProjectCategories,
		persistedProject
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getProjectTeamName = useTenantIsActiveCallback(persistedProjectTeam);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ReminderResponse>('projectOrCategoryId')]: {
					title: 'Project or category',
					formatter: (cell: any) => {
						const isProject = (cell.getData() as ReminderResponse).isProjectConnected;
						if (isProject) {
							return formatProjectIdName(cell.getValue());
						}
						return persistedTimeTravelNonProjectCategories.itemsMap[cell.getValue()]?.name || '';
					},
					fieldType: FieldTypeEnum.None
				},
				[propertyOf<ReminderResponse>('subject')]: {
					title: 'Subject',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ReminderResponse>('startDate')]: {
					title: 'Starts',
					fieldType: FieldTypeEnum.Date,
					formatter: (cell: any) => formatDateTime(cell.getValue()),
				},
				[propertyOf<ReminderResponse>('endDate')]: {
					title: 'Ends',
					fieldType: FieldTypeEnum.Date,
					formatter: (cell: any) => formatDateTime(cell.getValue()),
				},
				[propertyOf<ReminderResponse>('isRepeat')]: {
					title: 'Recurring',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<ReminderResponse>('id')]: {
					title: 'Reminder Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.REMINDER
				},
				[propertyOf<ReminderResponse>('projectTeamId')]: {
					title: 'Project Team',
					formatter: (cell: any) => getProjectTeamName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedProjectTeam.items,
					getItemId: (option: ProjectIsActiveResponse) => option.id,
					getItemText: (option: ProjectIsActiveResponse) => getProjectTeamName(option.id)
				},
				[propertyOf<ReminderResponse>('recipientUserIds')]: {
					title: 'Recipients',
					formatter: (cell: any) => {
						const userIds: number[] = cell.getValue();
						return userIds.map(userId => getUserFullName(userId)).join(', ');
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
					disableFilter: true,
					disableSort: true,
				}
			}
		},
		[persistedProjectTeam, persistedUser, formatProjectIdName, getUserFullName, getProjectTeamName, persistedTimeTravelNonProjectCategories]
	)

	return useVisibleColumns(columns, visibleColumns);
}
