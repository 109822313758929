import { TabType } from 'components/Tabs/Tabs';
import { OrganizationalUnits } from './OrganizationalUnits/OrganizationalUnits';
import { ManagerAuthorizationsRouter } from './ManagerAuthorizations/ManagerAuthorizationsRouter';

export enum CompanyTabSubroutes {
	OrganizationalUnits = 'organizational_units',
	ManagerAuthorizations = 'manager_authorizations'
}

export const companyTabs: TabType[] = [
	{
		id: '1',
		title: 'Organizational Units',
		route: CompanyTabSubroutes.OrganizationalUnits,
		component: OrganizationalUnits,
	},
	{
		id: '2',
		title: 'Manager authorizations',
		route: CompanyTabSubroutes.ManagerAuthorizations,
		component: ManagerAuthorizationsRouter
	}
]
