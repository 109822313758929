import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { ProjectReportResponse, ProjectReportStatusEnum, ProjectStatusEnum } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getReportAction } from '../action';
import { PerformanceStatus } from '../ReportComponents/PerformanceStatus/PerformanceStatus';
import { MajorIssue as MajorIssues } from '../ReportComponents/MajorIssues/MajorIssues';
import { ChangeRequest } from '../ReportComponents/ChangeRequest/ChangeRequest';
import { SubcontractorPerformance } from '../ReportComponents/SubcontractorPerformance/SubcontractorPerformance';
import { QualityManagement } from '../ReportComponents/QualityManagement/QualityManagement';
import { PotentialAdditionalBusiness } from '../ReportComponents/PotentialAdditionalBusiness/PotentialAdditionalBusiness';
import { CommentsAndActions } from '../ReportComponents/CommentsAndActions/CommentsAndActions';
import { RiskManagement } from '../ReportComponents/RiskManagement/RiskManagement';
import { ProjectFinances } from '../ReportComponents/ProjectFinances/ProjectFinances';
import { ProjectIdentification } from '../ReportComponents/ProjectIdentification/ProjectIdentification';
import WithFetch from 'features/Fetch/WithFetch';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { AreaStatuses } from '../ReportComponents/ProjectStatus/areaStatusEnum';
import { RelationshipStatus } from '../ReportComponents/RelationshipStatus/RelationshipStatus';
import { ProjectStatus } from '../ReportComponents/ProjectStatus/ProjectStatus';
import { Staffing } from '../ReportComponents/Staffing/Staffing';
import { CrudEnum, CrudSubRoutesEnum, useGoBackFromCrudAndPush } from 'features/Crud';
import { ProjectPickerParams } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';
import { ProjectReportHelp } from '../Help/ProjectReportHelp';
import { ExportProjectReport } from './ExportProjectReport';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

type ParamType = ProjectPickerParams & {
	reportId: string;
}

export const ReadReport = () => {
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId as string);
	const reportId = parseInt(params.reportId);
	const [report, setReport] = useState(new ProjectReportResponse());
	const { persistedReportStatus, persistedProject, persistedProjectStatus } = useSelector((state: RootState) => state);
	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read, undefined, undefined);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getReportAction.bind(null, projectId, reportId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setReport(response.value || new ProjectReportResponse());
			}
		},
		[projectId, reportId]
	)

	const goUpdateCallback = useCallback(
		() => {
			goBackFromCrudAndPush(`${CrudSubRoutesEnum.Update}/${params.reportId}`);
		},
		[params.reportId, goBackFromCrudAndPush]
	);

	const showChangeButtonMemo = useMemo(
		() => isStatusBySemantic(ProjectStatusEnum.Released, persistedProject.itemsMap[projectId]?.statusId, persistedProjectStatus.itemsMap) &&
			isStatusBySemantic(ProjectReportStatusEnum.Created, report.statusId, persistedReportStatus.itemsMap),
		[report, persistedReportStatus, persistedProject.itemsMap, persistedProjectStatus.itemsMap, projectId]
	);

	return (
		<ContentShell
			title={`View Project Report - ${getFormatedId(EntityPrefixEnum.REPORT, report.id)}`}
			FloatingHelpComponent={ProjectReportHelp}
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<ColumnContainer>
					<RowContainer>
						{showChangeButtonMemo && <Button text='Change' onClick={goUpdateCallback} />}
						<ExportProjectReport projectId={projectId} id={reportId} />
					</RowContainer>
					<ColumnContainer margin='xlarge'>
						<ProjectIdentification report={report} reportCrud={CrudEnum.Read} />
						<ProjectStatus report={report} reportCrud={CrudEnum.Read} />
						{report.areaStatuses[AreaStatuses.RelationshipStatus]?.isActive &&
							<RelationshipStatus report={report} reportCrud={CrudEnum.Read} />
						}
						{report.areaStatuses[AreaStatuses.Staffing]?.isActive &&
							<Staffing report={report} reportCrud={CrudEnum.Read} />
						}
						{report.areaStatuses[AreaStatuses.PerformanceStatus]?.isActive &&
							<PerformanceStatus report={report} reportCrud={CrudEnum.Read} />
						}
						{report.areaStatuses[AreaStatuses.MajorIssues]?.isActive &&
							<MajorIssues report={report} reportCrud={CrudEnum.Read} />
						}
						{report.areaStatuses[AreaStatuses.ChangeRequest]?.isActive &&
							<ChangeRequest report={report} reportCrud={CrudEnum.Read} />
						}
						{report.areaStatuses[AreaStatuses.SubcontractorPerformance]?.isActive &&
							<SubcontractorPerformance report={report} reportCrud={CrudEnum.Read} />
						}
						{report.areaStatuses[AreaStatuses.RiskManagement]?.isActive &&
							<RiskManagement report={report} reportCrud={CrudEnum.Read} />
						}
						{report.areaStatuses[AreaStatuses.QualityManagement]?.isActive &&
							<QualityManagement report={report} reportCrud={CrudEnum.Read} />
						}
						{report.areaStatuses[AreaStatuses.ProjectFinances]?.isActive &&
							<ProjectFinances report={report} reportCrud={CrudEnum.Read} />
						}
						{report.areaStatuses[AreaStatuses.PotentialAdditionalBusiness]?.isActive &&
							<PotentialAdditionalBusiness report={report} reportCrud={CrudEnum.Read} />
						}
						{report.areaStatuses[AreaStatuses.CommentsAndActions]?.isActive &&
							<CommentsAndActions report={report} reportCrud={CrudEnum.Read} />
						}
					</ColumnContainer>
				</ColumnContainer>
			</WithFetch>
		</ContentShell>
	)
}
