import { Dialog } from 'components/Dialog';
import { CrudEnum, useGoBackFromUpdate } from 'features/Crud';
import { TravelExpenseItemResponse } from 'services/tenantManagementService';
import TravelExpenseItemForm from './TravelExpenseItemForm';

type Props = {
	travelExpenseItem: TravelExpenseItemResponse
	onSave: (travelExpenseItem: TravelExpenseItemResponse) => void
}

const UpdateTravelExpenseItem = ({ travelExpenseItem, onSave }: Props) => {

	const goBackFromUpdate = useGoBackFromUpdate(true);

	return (
		<Dialog
			title='Change expense'
			open
			onClose={goBackFromUpdate}
			size='large'
		>
			<TravelExpenseItemForm
				travelExpenseItem={travelExpenseItem}
				crud={CrudEnum.Update}
				onSave={onSave}
			/>
		</Dialog>
	)
}

export default UpdateTravelExpenseItem;
