import { useCallback, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ProjectQualityResponse } from 'services/tenantManagementService';
import { DateField, Form, InputField, TextareaField } from 'components/Form';
import { ReportItemStatusSelectField } from '../ReportItemStatuses';

type Props = {
	quality?: ProjectQualityResponse
	cancel(): void
	save(quality: ProjectQualityResponse): void
}

export const QualityManagementForm = ({ quality, cancel, save }: Props) => {
	const [values, setValues] = useState(quality || new ProjectQualityResponse());

	const onSubmitCallback = useCallback(
		async () => await save(values),
		[save, values]
	)

	return (
		<Form
			values={values}
			initialValues={quality}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<InputField
							id='wbs'
							label='WBS'
							isRequired
							disabled={!!quality}
							maxLength={10}
						/>
						<InputField
							id='name'
							label='Task name'
							isRequired
						/>
						<DateField
							id='finish'
							label='Finish'
						/>
						<DateField
							id='achievedData'
							label='Achieved date'
						/>
						<TextareaField
							id='actions'
							label='Corrective action'
							maxLength={120}
							rows={2}
						/>
						<ReportItemStatusSelectField isRequired />
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
