import { getRiskTypesAction, updateRiskTypesAction } from './action';
import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';

export const RiskTypes = () => {
	return (
		<TenantIsActiveTable
			getConfigsAction={getRiskTypesAction}
			updateConfigsAction={updateRiskTypesAction}
			successMessage='Risk types are updated.'
			label={{ name: 'Type Name' }}
			biggerDescription
		/>
	)
};
