import { getTenantId } from 'features/Tenant/tenantUtils';
import { InsertScheduleRequest, PatchScheduleRequest, ScheduleClient, UpdateScheduleRequest, UpdateWbsRequest, ExportDataModel } from 'services/tenantManagementService';

export const getAllSchedulesAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleClient();
	return client.getAll(tenantId, projectId);
}

export const getAllSimpleSchedulesAction = (projectId: number | undefined, parentId: number | undefined) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleClient();
	return client.getAllSimple(tenantId, projectId, parentId, undefined, undefined);
}

export const getScheduleAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleClient();
	return client.get(tenantId, projectId, id);
}

export const createScheduleAction = (projectId: number, model: InsertScheduleRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleClient();
	return client.create(tenantId, projectId, model);
}

export const updateScheduleAction = (projectId: number, model: UpdateScheduleRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleClient();
	return client.update(tenantId, projectId, model);
}

export const updateSchedulesAction = (projectId: number, model: PatchScheduleRequest[]) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleClient();
	return client.bulkPatch(tenantId, projectId, model);
}

export const patchUpdateScheduleAction = (projectId: number, model: PatchScheduleRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleClient();
	return client.patch(tenantId, projectId, model);
}

export const reorderSchedulesAction = (projectId: number, model: UpdateWbsRequest[]) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleClient();
	return client.updateWbs(tenantId, projectId, model);
}

export const deleteScheduleAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleClient();
	return client.delete(tenantId, projectId, id);
}

export const exportSchedulesAction = (projectId: number, model: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleClient();
	return client.exportAll(tenantId, projectId, model);
}
