import { HelpShell } from 'features/Help/HelpShell';

export const CalendarHelp = () => {
	return (
		<HelpShell title='Calendar'>
			<p>For each country in which your company operates, you can define calendar.</p>
			<div>
				<p>In calendar you specify:</p>
				<ol>
					<li>working time: work week and work hours,</li>
					<li>public holidays.</li>
				</ol>
			</div>
			<p>
				This information is used in user's timesheet records. <br />
				For public holidays it will not be required to enter time in your timesheets. <br />
				If calendar is not maintained in site configuration, users will have to enter manually hours on public holidays (as non-project category). 
			</p>
			<div>
				<p>Next to timesheets, calendar is also used in:</p>
				<ul>
					<li>project schedule,</li>
					<li>Service Level Agreements (SLA) configuration.</li>
				</ul>
			</div>
		</HelpShell>
	)
}

