import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { BudgetVsActualRowDashboardResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { getMonthEnumByDate } from 'utils/dateTimeUtils';
import { useFormattedCurrencyValueCallback } from 'features/TableColumns/persistedHooks';

const defaultColumns = [
	propertyOf<BudgetVsActualRowDashboardResponse>('date'),
	propertyOf<BudgetVsActualRowDashboardResponse>('budgetValue'),
	propertyOf<BudgetVsActualRowDashboardResponse>('actualBudgetValue'),
	propertyOf<BudgetVsActualRowDashboardResponse>('difference'),
	propertyOf<BudgetVsActualRowDashboardResponse>('percentageOfBudget'),
]

export const useTableColumnsMemo = (columnsDataValue: BudgetVsActualRowDashboardResponse[] = []) => {
	const getFormattedCurrencyValue = useFormattedCurrencyValueCallback();

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<BudgetVsActualRowDashboardResponse>('date')]: {
					title: 'Month',
					fieldType: FieldTypeEnum.Date,
					formatter: (cell: any) => {
						const data = cell.getValue();
						const fullYear = data.getFullYear();
						const month = getMonthEnumByDate(cell.getValue());
						return `${month} ${fullYear}`
					},
					bottomCalcFormatter: () => 'Total',
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<BudgetVsActualRowDashboardResponse>('budgetValue')]: {
					title: 'Plan',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: 'sum',
					bottomCalcFormatter(cell: any) {
						let totalSum = 0;
						for (const sum of columnsDataValue) {
							const total = sum.budgetValue;
							totalSum += total;
						}

						const formattedValue = getFormattedCurrencyValue(totalSum);
						return `∑ ${formattedValue}`;
					},
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<BudgetVsActualRowDashboardResponse>('actualBudgetValue')]: {
					title: 'Actuals',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: 'sum',
					bottomCalcFormatter(cell: any) {
						let totalSum = 0;
						for (const sum of columnsDataValue) {
							const total = sum.actualBudgetValue;
							totalSum += total;
						}

						const formattedValue = getFormattedCurrencyValue(totalSum);
						return `∑ ${formattedValue}`;
					},
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<BudgetVsActualRowDashboardResponse>('difference')]: {
					title: 'Difference',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: 'sum',
					bottomCalcFormatter(cell: any) {
						let totalSum = 0;
						for (const sum of columnsDataValue) {
							const total = sum.difference;
							totalSum += total;
						}

						const formattedValue = getFormattedCurrencyValue(totalSum);
						return `∑ ${formattedValue}`;
					},
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<BudgetVsActualRowDashboardResponse>('percentageOfBudget')]: {
					title: 'Actual %',
					formatter: (cell: any) => cell.getValue()?.toFixed(2) + '%',
					fieldType: FieldTypeEnum.Number,
					bottomCalc: 'sum',
					bottomCalcFormatter(cell: any) {
						let plan = 0;
						let actual = 0;
						for (const item of columnsDataValue) {
							plan += item.budgetValue;
							actual += item.actualBudgetValue;
						}
						return `${(plan !== 0 ? actual / plan * 100 : 0).toFixed(2)}%`;
					},
					disableSort: true,
					disableFilter: true,
					hozAlign: 'right'
				}
			}
		},
		[columnsDataValue, getFormattedCurrencyValue]
	)

	return useVisibleColumns(columns, defaultColumns);
}
