import { ExportDataModel, GenericFilterModelCollection, InsertProjectAccomplishmentRequest, ProjectAccomplishmentClient, UpdateProjectAccomplishmentRequest } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getAccomplishmentsAction = (reportId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectAccomplishmentClient();
	return client.getAll(tenantId, reportId, undefined, undefined, undefined, undefined);
}

export const getAccomplishmentsGenericAction = (reportId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectAccomplishmentClient();
	return client.getAllGenericFilter(tenantId, reportId, genericFilter);
}

export const createAccomplishmentAction = (reportId: number, model: InsertProjectAccomplishmentRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectAccomplishmentClient();
	return client.create(tenantId, reportId, model);
}

export const updateAccomplishmentAction = (reportId: number, model: UpdateProjectAccomplishmentRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectAccomplishmentClient();
	return client.update(tenantId, reportId, model);
}

export const getAccomplishmentAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectAccomplishmentClient();
	return client.get(tenantId, reportId, id);
}

export const deleteAccomplishmentAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectAccomplishmentClient();
	return client.delete(tenantId, reportId, id);
}

export const exportAction = (reportId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectAccomplishmentClient();
	return client.export(tenantId, reportId, exportDataModel);
}
