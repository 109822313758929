import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux';
import { Form, Input, MultiSelectField } from 'components/Form';
import { ColumnContainer } from 'components/Layout';
import notifications from 'components/Notification/notification';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { UpdateSurveyParticipantsRequest, UserModel } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getSurveyParticipantsAction, releaseAndSendToSurveyParticipantsAction, updateSurveyParticipantsAction } from '../../action';
import { publishUpdateSurveyChanged } from '../updateSurveyChanged';
import { UpdateSurveyTabsProps } from '../UpdateSurveyTabs';
import Button from 'components/Button';
import { RootState } from 'base/reducer/reducer';
import { useActiveTeamMembersMemo } from 'features/TableColumns/persistedHooks';
import { sortByString } from 'utils/stringUtil';
import { propertyOf } from 'utils/propertyOf';

function createDelta(
	initialItems: number[],
	items: number[],
) {
	const initialItemSet = new Set(initialItems)
	const itemSet = new Set(items)
	return {
		insertUserIds: items.filter(x => !initialItemSet.has(x)),
		deleteUserIds: initialItems.filter(x => !itemSet.has(x)),
	}
}

class ValueClass { constructor(public participants: number[]) {} }

const RecipientsTab = ({ project, survey }: UpdateSurveyTabsProps) => {
	const [initialValue, setInitialValue] = useState<ValueClass>(new ValueClass([]));
	const [value, setValue] = useState<ValueClass>(new ValueClass([]));
	const [isReleasing, setIsReleasing] = useState(false);
	const {
		persistedUser,
		persistedTeamMember,
	} = useSelector((state: RootState) => state);

	const memberUsersMemo: UserModel[] = useActiveTeamMembersMemo(persistedTeamMember, persistedUser, project.id);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getSurveyParticipantsAction.bind(null, project.id, survey.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const initValue = new ValueClass((response.items || []).map(x => x.participantUserId))
				setInitialValue(initValue);
				setValue(initValue);
			}
		},
		[project.id, survey.id]
	);

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback],
	);

	const onCancelCallback = useCallback(
		() => {
			setValue(initialValue);
		},
		[initialValue]
	)

	const releaseAndSendToParticipantsCallback = useCallback(
		async () => {
			setIsReleasing(true);
			const bindedAction = releaseAndSendToSurveyParticipantsAction.bind(null, project.id, survey.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Survey released and sent to participants.');
				publishUpdateSurveyChanged();
			}
			setIsReleasing(false);
		},
		[project.id, survey.id]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const updateSurveyParticipantsRequest = new UpdateSurveyParticipantsRequest(createDelta(initialValue.participants, value.participants));
			const bindedAction = updateSurveyParticipantsAction.bind(null, project.id, survey.id, updateSurveyParticipantsRequest);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Survey participants updated.');
				const initValue = new ValueClass((response.items || []).map(x => x.participantUserId))
				setInitialValue(initValue);
				setValue(initValue);
			}
			else {
				const errors = response.errors && Object.keys(response.errors)
					.reduce((acc, cv) => ({
						...acc,
						[cv.startsWith('insertUserIds') ? cv.replace('insertUserIds', 'participants') : cv]: response.errors![cv],
					}), {});
				return errors;
			}
		},
		[initialValue, value, project.id, survey.id]
	)

	return (
		<Form
			values={value}
			initialValues={initialValue}
			onChange={setValue}
			onSubmit={onSubmitCallback}
			onCancel={onCancelCallback}
			disabled={isReleasing}
			renderAdditionalButtons={
				(disabled?: boolean, handleSubmitCallback?: () => void, isSubmitting?: boolean) => {
					return (
						<Button
							text='Release and send to recipients'
							onClick={releaseAndSendToParticipantsCallback}
							disabled={disabled || isSubmitting || initialValue !== value}
							isLoading={isReleasing}
						/>
					)
				}
			}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<MultiSelectField
								id='participants'
								label='Participants'
								items={sortByString(memberUsersMemo, String(propertyOf<UserModel>('firstName')))}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								loading={persistedUser.fetching || persistedTeamMember.fetching}
							/>
							<SmartFormGroup label='Status'>
								<Input
									value={survey.status}
									disabled
								/>
							</SmartFormGroup>
						</SmartItem>
					</SmartContainer>
					{/* added height because multiSelect dropdown on Form is not completely visible */}
					<div style={{ height: '180px' }}></div>
				</ColumnContainer>
			)}
		/>
	)
}

export default RecipientsTab;
