import { useCallback } from "react";
import Button from "components/Button"
import { RowContainer } from "components/Layout";
import { gantt } from "../GanttChart";

type Props = {
	selectedTask: any
	onCreateTaskClick(parentId: string): void
	onUpdateTaskClick(taskId: string): void
	onTaskDelete(id: number): void
	disabled?: boolean
}

export const CrudButtons = ({ selectedTask, onCreateTaskClick, onUpdateTaskClick, onTaskDelete }: Props) => {
	const deleteTaskCallback = useCallback(
		() => {
			const selectedTaskId = gantt.getSelectedId()
			const id = parseInt(selectedTaskId)
			onTaskDelete(id);
		},
		[onTaskDelete]
	)

	const onCreateTaskClickCallback = useCallback(
		() => {
			const selectedTaskId = gantt.getSelectedId()
			onCreateTaskClick(selectedTaskId);
		},
		[onCreateTaskClick]
	)

	const onUpdateTaskClickCallback = useCallback(
		() => {
			const selectedTaskId = gantt.getSelectedId()
			onUpdateTaskClick(selectedTaskId);
		},
		[onUpdateTaskClick]
	)

	return (
		<>
			<RowContainer>
				<Button
					text='Add'
					onClick={onCreateTaskClickCallback}
				/>
				<Button
					text='Change'
					onClick={onUpdateTaskClickCallback}
					disabled={!selectedTask}
				/>
				<Button
					text='Delete'
					color='destructive'
					onClick={deleteTaskCallback}
					disabled={!selectedTask}
				/>
			</RowContainer>
		</>
	)
}
