import { getChangeRequestStatusesAction, updateChangeRequestStatusesAction } from './action';
import { ChangeRequestStatusesHelp } from './ChangeRequestStatusesHelp';
import { StatusResponseTable } from 'features/StatusResponse/StatusResponseTable';
import { ContentShell } from 'features/Content/ContentShell';

export const ChangeRequest = () => {
	return (
		<ContentShell
			InlineHelpComponent={ChangeRequestStatusesHelp}
			showBreadcrumb={false}
		>
			<StatusResponseTable
				getConfigsAction={getChangeRequestStatusesAction}
				updateConfigsAction={updateChangeRequestStatusesAction}
			/>
		</ContentShell>
	)
}
