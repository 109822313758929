import { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import { ColumnContainer } from 'components/Layout';
import { InvoiceForm } from './InvoiceForm';
import { InvoiceResponse, ProjectStatusEnum } from 'services/tenantManagementService';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, CrudSubRoutesEnum, useGoBackFromCrudAndPush } from 'features/Crud';
import { ProjectPickerParams } from 'features/Project';
import { getInvoiceAction } from '../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { ContentShell } from 'features/Content/ContentShell';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';

type ParamType = ProjectPickerParams & {
	invoiceId: string;
}

export const ReadInvoice = () => {
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId as string);
	const invoiceId = parseInt(params.invoiceId as string);

	const { persistedProjectStatus, persistedProject } = useSelector((state: RootState) => state);

	const [invoice, setInvoice] = useState(new InvoiceResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getInvoiceAction.bind(null, projectId, invoiceId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setInvoice(response.value || new InvoiceResponse())
			}
		},
		[invoiceId, projectId]
	)

	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read, undefined, undefined);

	const goUpdateCallback = useCallback(
		() => {
			goBackFromCrudAndPush(`${CrudSubRoutesEnum.Update}/${invoiceId}`);
		},
		[invoiceId, goBackFromCrudAndPush]
	)

	const showChangeMemo = useMemo(
		() => isStatusBySemantic(ProjectStatusEnum.Released, persistedProject.itemsMap[projectId]?.statusId, persistedProjectStatus.itemsMap),
		[persistedProjectStatus.itemsMap, persistedProject.itemsMap, projectId]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`View invoice - ${invoice.invoiceNumber}`}>
				<ColumnContainer margin='medium'>
					{showChangeMemo && <Button text='Change' onClick={goUpdateCallback} />}
					<InvoiceForm
						invoice={invoice}
						projectId={projectId}
						crud={CrudEnum.Read}
					/>
				</ColumnContainer>
			</ContentShell>
		</WithFetch>
	)
}
