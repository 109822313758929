import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MeetingResponse, MeetingStatusEnum, ProjectStatusEnum } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, CrudSubRoutesEnum, useGoBackFromCrudAndPush, useGoBackFromRead } from 'features/Crud';
import { ColumnContainer, RowContainer } from 'components/Layout';
import Button from 'components/Button';
import { cloneMeetingAction, getMeetingAction, resetStatusMeetingAction } from '../action';
import { MeetingForm } from './MeetingForm';
import notifications from 'components/Notification/notification';
import { ReadComponentProps } from 'features/Crud/CrudRouter';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import Export from '../Export/Export';
import { ProjectOrCategoryPickerParams } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';
import { PrivateButton } from './PrivateButton/PrivateButton';
import { getUserInfo } from 'utils/storageUtils';

type ParamType = ProjectOrCategoryPickerParams & {
	meetingId: string
}

export const ReadMeeting = ({ publishDataChanged }: ReadComponentProps) => {
	const params: ParamType = useParams();
	const projectOrCategoryId = parseInt(params.projectOrCategoryId as string);
	const isProjectConnected = params.isProjectConnected === 'true';
	const meetingId = parseInt(params.meetingId as string);
	const goBackFromRead = useGoBackFromRead();

	const [meeting, setMeeting] = useState(new MeetingResponse());
	const [resetting, setResetting] = useState(false);
	const [copying, setCopying] = useState(false);

	const { persistedMeetingStatus, persistedProject, persistedProjectStatus } = useSelector((state: RootState) => state);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getMeetingAction.bind(null, meetingId, projectOrCategoryId, isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setMeeting(response.value || new MeetingResponse());
			}
		},
		[projectOrCategoryId, isProjectConnected, meetingId]
	)

	const resetStatusMeetingCallback = useCallback(
		async () => {
			setResetting(true);
			const bindedAction = resetStatusMeetingAction.bind(null, meeting.id, projectOrCategoryId, isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const formattedId = getFormatedId(EntityPrefixEnum.MEETING, meeting.id);
				notifications.success(`Meeting ${formattedId} is reset.`);
				publishDataChanged();
				goBackFromRead();
			}
			setResetting(false);
		},
		[projectOrCategoryId, isProjectConnected, meeting.id, publishDataChanged, goBackFromRead]
	)

	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read, undefined, undefined);

	const copyCallback = useCallback(
		async () => {
			setCopying(true)
			const bindedAction = cloneMeetingAction.bind(null, meetingId, projectOrCategoryId, isProjectConnected);
			const response = await tryCatchJsonByAction(bindedAction);
			setCopying(false)

			if (response.success) {
				const formattedCopyId = getFormatedId(EntityPrefixEnum.MEETING, response.value?.id);
				const formattedCopiedId = getFormatedId(EntityPrefixEnum.MEETING, meetingId);
				notifications.success(`Meeting ${formattedCopyId} is created as copy of ${formattedCopiedId}.`);
				publishDataChanged();
				goBackFromCrudAndPush(`${CrudSubRoutesEnum.Update}/${response.value?.id}`)
			}
		},
		[projectOrCategoryId, isProjectConnected, meetingId, publishDataChanged, goBackFromCrudAndPush]
	)

	const goUpdateCallback = useCallback(
		() => {
			goBackFromCrudAndPush(`${CrudSubRoutesEnum.Update}/${meetingId}`);
		},
		[meetingId, goBackFromCrudAndPush]
	)

	const isReleasedMemo = useMemo(
		() => isStatusBySemantic(MeetingStatusEnum.Released, meeting.statusId, persistedMeetingStatus.itemsMap),
		[meeting.statusId, persistedMeetingStatus]
	)

	const isResetDisabledMemo = useMemo(
		() => {
			const user = getUserInfo();
			return !isReleasedMemo || user.id !== meeting.userId
		},
		[isReleasedMemo, meeting.userId]
	)

	const showChangeMemo = useMemo(
		() => {
			const selectedProjectStatusId = meeting.isProjectConnected === true ? persistedProject.itemsMap[meeting.projectOrCategoryId]?.statusId : undefined;

			return !isReleasedMemo && isStatusBySemantic(ProjectStatusEnum.Released, selectedProjectStatusId, persistedProjectStatus.itemsMap);
		},
		[isReleasedMemo, persistedProjectStatus.itemsMap, persistedProject.itemsMap, meeting]
	)

	const renderPrivateButtonCallback = useCallback(
		() => (
			<PrivateButton
				meeting={meeting}
				isProjectConnected={isProjectConnected}
				projectOrCategoryId={projectOrCategoryId}
				publishDataChanged={publishDataChanged}
			/>
		),
		[meeting, isProjectConnected, projectOrCategoryId, publishDataChanged]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell
				title={`View meeting - ${getFormatedId(EntityPrefixEnum.MEETING, meeting.id)}`}
				renderAdditionalHeaderContent={renderPrivateButtonCallback}
			>
				<ColumnContainer margin='medium'>
					<RowContainer>
						{showChangeMemo &&
							<Button
								text='Change'
								onClick={goUpdateCallback}
							/>
						}
						<Button
							text='Copy'
							isLoading={copying}
							onClick={copyCallback}
						/>
						<Button
							text='Reset Status'
							isLoading={resetting}
							onClick={resetStatusMeetingCallback}
							color='destructive'
							disabled={isResetDisabledMemo}
						/>
						<Export meeting={meeting}/>
					</RowContainer>
					<MeetingForm
						meeting={meeting}
						crud={CrudEnum.Read}
					/>
				</ColumnContainer>
			</ContentShell>
		</WithFetch>
	)
}
