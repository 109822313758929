import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import TravelRequestForm from './TravelRequestForm';
import { InsertTravelRequestRequest, TravelRequestResponse } from 'services/tenantManagementService';
import { createTravelRequestAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { ContentShell } from 'features/Content/ContentShell';

const CreateMyTravelRequest = ({ publishDataChanged }: CreateComponentProps) => {
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newTravelRequest: TravelRequestResponse, isRelease: boolean) => {
			const model = new InsertTravelRequestRequest({...newTravelRequest, isRelease});
			const bindedAction = createTravelRequestAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TRAVEL_REQUEST, response.value?.id);
				notifications.success(`Travel request ${id} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	);

	return (
		<ContentShell title='Create travel request'>
			<TravelRequestForm
				crud={CrudEnum.Create}
				onSave={handleSave}
			/>
		</ContentShell>
	)
}

export default CreateMyTravelRequest;
