import { TabType } from 'components/Tabs/Tabs';
import ProjectData from './ProjectData';
import Teams from './Teams';
import Roles from './Roles';
import AssignTeamMembers from './AssignTeamMembers';
import SLA from './SLA/SLA';
import { MaintainProjectSubRoutes } from '../MyProjectsMaintainSubroutes';

export const maintainProjectTabs: TabType[] = [
	{
		id: '1',
		title: 'Project data',
		component: ProjectData,
		route: MaintainProjectSubRoutes.PROJECT_DATA
	},
	{
		id: '2',
		title: 'Teams',
		component: Teams,
		route: MaintainProjectSubRoutes.TEAMS
	},
	{
		id: '3',
		title: 'Project roles',
		component: Roles,
		route: MaintainProjectSubRoutes.ROLES
	},
	{
		id: '5',
		title: 'Assign team members',
		component: AssignTeamMembers,
		route: MaintainProjectSubRoutes.TEAM_MEMBERS
	},
	{
		id: '6',
		title: 'SLA',
		component: SLA,
		route: MaintainProjectSubRoutes.SLA
	},
];
