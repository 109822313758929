import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterProps, getRouteObject } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { CreatePartner } from './CreateNewPartner/Crud/CreatePartner';
import { CrudRouter } from 'features/Crud';
import { UpdatePartner } from './CreateNewPartner/Crud/UpdatePartner';
import { Partners } from './CreateNewPartner/Partners';
import { persistProjectTypesAction } from './ConfigureProjects/Tabs/ProjectType/action';
import { persistProjectCategoriesAction } from './ConfigureProjects/Tabs/ProjectCategory/action';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { Projects } from './CreateNewProject/Projects';
import { CreateProject } from './CreateNewProject/Crud/CreateProject';
import { UpdateProject } from './CreateNewProject/Crud/UpdateProject';
import { ProjectsSubRoutesEnum, createPartnerSubRoute, createProjectSubRoute } from './routes';


export const ProjectsRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistProjectTypesAction();
			persistProjectCategoriesAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[ProjectsSubRoutesEnum.CREATE_PROJECT] &&
				<Route
					path={createProjectSubRoute.url}
					render={() => (
						<CrudRouter
							ListComponent={Projects}
							CreateComponent={CreateProject}
							UpdateComponent={UpdateProject}
							paramName='projectId'
						/>
					)}
				/>
			}
			{routesMap[ProjectsSubRoutesEnum.CREATE_PARTNER] &&
				<Route
					path={createPartnerSubRoute.url}
					render={() => (
						<CrudRouter
							ListComponent={Partners}
							CreateComponent={CreatePartner}
							UpdateComponent={UpdatePartner}
							paramName='partnerId'
						/>
					)}
				/>
			}

			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
