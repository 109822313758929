import { RowContainer } from "components/Layout";
import { useMemo } from "react"
import { Link } from 'react-router-dom';
import styles from './breadcrumb.module.scss'

type BreadCrumbItem = {
	id: string
	url: string
	text: string
}

type Props = {
	items: BreadCrumbItem[]
	title: string
}

export const Breadcrumb = ({ items, title }: Props) => {
	const content = useMemo(
		() => {
			const content: JSX.Element[] = [];
			for (let route of items) {
				content.push(
					<h1 key={route.id} className={styles.link}>
						<Link to={route.url}>{route.text}</Link>
					</h1>
				)
				content.push(<h1 key={content.length} className={styles.separator}>/</h1>);
			}

			content.push(<h1 key={content.length} className={styles.title}>{title}</h1>);

			return content;
		},
		[items, title]
	)

	return (
		<RowContainer>
			{content}
		</RowContainer>
	)
}
