import { HelpShell } from 'features/Help/HelpShell';

export const BudgetHelp = () => {
	return (
		<HelpShell title='Budget plan'>
			<p>After configuration set-up you can start budgeting your project per planning intervals defined for your company (usually monthly periods).</p>
			<p>Depending on your configuration entry fields are enabled or disabled. For example, if you decided to include planned invoices in your budget, data will be prefilled with information entered in invoices.</p>
			<p>When budget is <u><b>saved, changes are still possible</b></u>. If you want to track changes in budget during project execution, you need to release the budget, and enter modifications with information about new budget version.</p>
			<p>You can add some budgeting categories for which Mavles cannot provide actuals, such as for example project team incentives. These items you need to maintain manually in budget and in actuals when they are realized.</p>
			<p>Budget and actuals tables, you can export in xls files.</p>
		</HelpShell>
	)
}
