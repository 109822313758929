import { useCallback, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Form, TextareaField } from 'components/Form';
import { propertyOf } from 'utils/propertyOf';

type RejectFormType = {
	rejectionComment: string
}

type Props = {
	onSave: (string: string) => void
	onCancel: () => void
	rejectionCommentRequired: boolean
}

const RejectRequestForm = ({ onSave, onCancel, rejectionCommentRequired }: Props) => {
	const [values, setValues] = useState<RejectFormType>({rejectionComment: ''});

	const onSubmitCallback = useCallback(
		async () => onSave && await onSave(values.rejectionComment),
		[onSave, values]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={onCancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<TextareaField
							id={propertyOf<RejectFormType>('rejectionComment')}
							label='Rejection comment'
							isRequired={rejectionCommentRequired}
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}

export default RejectRequestForm;
