import { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { TicketsRouter } from 'containers/Tickets/TicketsRouter';
import { Route as RouteType } from 'features/Routes/reducer';
import { RootRouteEnum, getRouteObject } from 'features/Routes';
import { ProjectsRouter } from 'containers/Projects/ProjectsRouter';
import { ScopeRouter } from 'containers/Scope/ScopeRouter';
import { TestingRouter } from 'containers/Testing/TestingRouter';
import { SiteConfigurationRouter } from 'containers/Configuration/SiteConfiguration/SiteConfigurationRouter';
import { MyConfigurationRouter } from 'containers/Configuration/MyConfiguration/MyConfigurationRouter';
import { TrainingRouter}  from 'containers/Training/TrainingRouter';
import { TimeTravelRouter } from 'containers/TimeTravel/TimeTravelRouter';
import { RiskRouter } from 'containers/Risk/RiskRouter';
import { FinanceRouter } from 'containers/Finance/FinanceRouter';
import { CommunicationRouter } from 'containers/Communication/CommunicationRouter';
import { ScheduleRouter } from 'containers/Schedule/ScheduleRouter';
import { ticketsRoute } from 'containers/Tickets/routes';
import { projectRoute } from 'containers/Projects/routes';
import { scopeRoute } from 'containers/Scope/routes';
import { testingRoute } from 'containers/Testing/routes';
import { trainingRoute } from 'containers/Training/routes';
import { timeTravelRoute } from 'containers/TimeTravel/routes';
import { riskRoute } from 'containers/Risk/routes';
import { financeRoute } from 'containers/Finance/routes';
import { communicationRoute } from 'containers/Communication/routes';
import { scheduleRoute } from 'containers/Schedule/routes';
import { siteConfigurationRoute } from 'containers/Configuration/SiteConfiguration/routes';
import { myConfigurationRoute } from 'containers/Configuration/MyConfiguration/routes';

type Props = {
	rootRoutes: RouteType[]
}

export const ContentRouter = ({ rootRoutes }: Props) => {
	const routesMap = useMemo(
		() => getRouteObject(rootRoutes),
		[rootRoutes]
	)

	return (
		<Switch>
			{/* This is added so we do not have urls that ends with / , because it can happen that after history.push, we have // somewhere in URL, and router is not working */}
            {/* INFO: this makes to many mess, so it is removed. For example close of modal dialog (goBackCallback) is refreshing whole screen instead of just closing the modal. */}
			{/* <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} /> */}

			{routesMap[RootRouteEnum.TICKETS] &&
				<Route
					path={ticketsRoute.url}
					render={
						() => <TicketsRouter route={routesMap[RootRouteEnum.TICKETS]} />
					}
				/>
			}
			{routesMap[RootRouteEnum.PROJECTS] &&
				<Route
					path={projectRoute.url}
					render={
						() => <ProjectsRouter route={routesMap[RootRouteEnum.PROJECTS]} />
					}
				/>
			}
			{routesMap[RootRouteEnum.SCOPE] &&
				<Route
					path={scopeRoute.url}
					render={
						() => <ScopeRouter route={routesMap[RootRouteEnum.SCOPE]} />
					}
				/>
			}
			{routesMap[RootRouteEnum.TESTING] &&
				<Route
					path={testingRoute.url}
					render={
						() => <TestingRouter route={routesMap[RootRouteEnum.TESTING]} />
					}
				/>
			}
			{routesMap[RootRouteEnum.TRAINING] &&
				<Route
					path={trainingRoute.url}
					render={
						() => <TrainingRouter route={routesMap[RootRouteEnum.TRAINING]} />
					}
				/>
			}
			{routesMap[RootRouteEnum.TIME_TRAVEL] &&
				<Route
					path={timeTravelRoute.url}
					render={
						() => <TimeTravelRouter route={routesMap[RootRouteEnum.TIME_TRAVEL]} />
					}
				/>
			}
			{routesMap[RootRouteEnum.RISK] &&
				<Route
					path={riskRoute.url}
					render={
						() => <RiskRouter route={routesMap[RootRouteEnum.RISK]} />
					}
				/>
			}
			{routesMap[RootRouteEnum.FINANCE] &&
				<Route
					path={financeRoute.url}
					render={
						() => <FinanceRouter route={routesMap[RootRouteEnum.FINANCE]} />
					}
				/>
			}
			{routesMap[RootRouteEnum.COMMUNICATION] &&
				<Route
					path={communicationRoute.url}
					render={
						() => <CommunicationRouter route={routesMap[RootRouteEnum.COMMUNICATION]} />
					}
				/>
			}
			{routesMap[RootRouteEnum.SCHEDULE] &&
				<Route
					path={scheduleRoute.url}
					render={
						() => <ScheduleRouter route={routesMap[RootRouteEnum.SCHEDULE]} />
					}
				/>
			}
			{routesMap[RootRouteEnum.SITE_CONFIGURATION] &&
				<Route
					path={siteConfigurationRoute.url}
					render={
						() => <SiteConfigurationRouter route={routesMap[RootRouteEnum.SITE_CONFIGURATION]} />
					}
				/>
			}
			{routesMap[RootRouteEnum.ACCOUNT] &&
				<Route
					path={myConfigurationRoute.url}
					render={
						() => <MyConfigurationRouter route={routesMap[RootRouteEnum.ACCOUNT]} />
					}
				/>
			}
		</Switch>
	)
}
