import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import {  ProjectMilestoneResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createMilestoneAction } from '../action';
import { MilestonePlanForm } from '../MilestonePlanForm';
import { Dialog } from 'components/Dialog';
import { publishMilestonePlansChanged } from '../milestonePlanChaged';
import { useGoBackFromCreate } from 'features/Crud';
import { ReportComponentParams } from '../../../Table/TableButtons';
import { useParams } from 'react-router-dom';

export const CreateMilestonePlan = () => {
	const params: ReportComponentParams = useParams();
	const reportId = parseInt(params.reportId);

	const goBackFromCreate = useGoBackFromCreate(true);

	const onSubmitCallback = useCallback(
		async (milestone: ProjectMilestoneResponse) => {
			const bindedAction = createMilestoneAction.bind(null, reportId, milestone);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('New Milestone plan successfully created');
				goBackFromCreate();
				publishMilestonePlansChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, reportId]
	);

	return(
		<Dialog
			title='Create Milestone Plan'
			onClose={goBackFromCreate}
			open
		>
			<MilestonePlanForm save={onSubmitCallback} cancel={goBackFromCreate} />
		</Dialog>
	)
};
