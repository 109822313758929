import { ContentShell } from "features/Content/ContentShell"
import { BudgetAndActualsCommon } from "../BudgetAndActualsCommon"
import { exportActualsAction, getActualRowsAction, getHeadersAction, updateActualRowsAction } from "./action"
import { ActualsHelp } from "./Help/ActualsHelp"
import { ComponentProps } from "features/Project";

export const Actuals = ({ disabledEdit }: ComponentProps) => {
	return (
		<ContentShell
			FloatingHelpComponent={ActualsHelp}
		>
			<BudgetAndActualsCommon
				getRowsAction={getActualRowsAction}
				updateRowsAction={updateActualRowsAction}
				getHeadersAction={getHeadersAction}
				exportAction={exportActualsAction}
				disabled={disabledEdit}
			/>
		</ContentShell>
	)
}
