import { useMemo } from 'react';
import { useApplyStatusColorCallback, useStatusCallback } from 'features/TableColumns/persistedHooks';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns, linksCellFormatter } from 'components/Table';
import { AttachmentResponse, MyTrainingPlanResponse, TrainingStatusResponse } from 'services/tenantManagementService';
import { formatTime } from 'utils/dateTimeUtils';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
	propertyOf<MyTrainingPlanResponse>('isActive'),
	propertyOf<MyTrainingPlanResponse>('level3ProcessId'),
	propertyOf<MyTrainingPlanResponse>('level3ProcessName'),
	propertyOf<MyTrainingPlanResponse>('attachments'),
	propertyOf<MyTrainingPlanResponse>('linkToMaterial'),
	propertyOf<MyTrainingPlanResponse>('linkToVideo'),
	propertyOf<MyTrainingPlanResponse>('statusId')
]

export const useTableColumnsMemo = (
	downloadAttachment: (attachmentId: number) => Promise<void>,
	disableEditor?: boolean,
	visibleColumns: string[] = defaultVisibleColumns
) => {
	const {
		persistedTrainingStatus
	} = useSelector((state: RootState) => state);

	const getStatusName = useStatusCallback(persistedTrainingStatus);
	const applyTestStatusColor = useApplyStatusColorCallback(persistedTrainingStatus);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<MyTrainingPlanResponse>('isActive')]: {
					title: 'Include in training scope',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<MyTrainingPlanResponse>('level3ProcessId')]: {
					title: 'Level 3 Process ID',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<MyTrainingPlanResponse>('level3ProcessName')]: {
					title: 'Level 3 Process Name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<MyTrainingPlanResponse>('plannedDate')]: {
					title: 'Planned date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<MyTrainingPlanResponse>('plannedTime')]: {
					title: 'Planned time (from)',
					formatter: (cell: any) => formatTime(cell.getValue()),
					fieldType: FieldTypeEnum.Number,
					format: '{0:HH:mm}'
				},
				[propertyOf<MyTrainingPlanResponse>('plannedTimeTo')]: {
					title: 'Planned time (to)',
					formatter: (cell: any) => formatTime(cell.getValue()),
					fieldType: FieldTypeEnum.Number,
					format: '{0:HH:mm}'
				},
				[propertyOf<MyTrainingPlanResponse>('duration')]: {
					title: 'Duration',
					fieldType: FieldTypeEnum.Number,
					formatter: (cell: any) => cell.getValue() ? `${cell.getValue() / 10000 / 1000 / 60} min`: ''
				},
				[propertyOf<MyTrainingPlanResponse>('attachments')]: {
					title: 'Attachments',
					disableSort: true,
					fieldType: FieldTypeEnum.None,
					formatter: (cell: any, formatterParams: any, onRendered: Function) => linksCellFormatter(cell, formatterParams, onRendered, true, 'row'),
					formatterParams: {
						getLinks: (value: AttachmentResponse[]) => value || [],
						getLinkTitle: (link: AttachmentResponse) => link.name,
						getLinkUrl: () => '#',
						onClickLink: async (e: MouseEvent, link: AttachmentResponse) => {
							e.preventDefault();
							await downloadAttachment(link.id);
						},
						linkTarget: null,
					},
					tooltip: () => '',
					dbExportFieldPath: null
				},
				[propertyOf<MyTrainingPlanResponse>('linkToMaterial')]: {
					title: 'Link to training material',
					fieldType: FieldTypeEnum.None,
					formatter: (cell: any, formatterParams: any, onRendered: Function) => linksCellFormatter(cell, formatterParams, onRendered, true),
					tooltip: () => ''
				},
				[propertyOf<MyTrainingPlanResponse>('linkToVideo')]: {
					title: 'Link to training video',
					fieldType: FieldTypeEnum.None,
					formatter: (cell: any, formatterParams: any, onRendered: Function) => linksCellFormatter(cell, formatterParams, onRendered, true),
					tooltip: () => ''
				},
				[propertyOf<MyTrainingPlanResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyTestStatusColor(id, cell.getElement());
						return getStatusName(id);
					},
					editor: !disableEditor && 'list',
					editorParams: {
						values: persistedTrainingStatus.items.map(status => {
							return { label: getStatusName(status.id), value: status.id };
						}),
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTrainingStatus.items,
					getItemId: (option: TrainingStatusResponse) => option.id,
					getItemText: (option: TrainingStatusResponse) => option.name,
				}
			}
		},
		[getStatusName, applyTestStatusColor, downloadAttachment, persistedTrainingStatus.items, disableEditor]
	)

	return useVisibleColumns(columns, visibleColumns);
}
