import { useCallback, useEffect, useMemo, useState } from 'react';
import { ContentShell } from 'features/Content/ContentShell'
import { TimeTravelConfigResponse, UpdateTimeTravelConfigRequest } from 'services/tenantManagementService';
import { VacationGeneralHelp } from './VacationGeneralHelp'
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getVacationGeneralConfigAction, updateVacationGeneralConfigAction } from './action';
import notifications from 'components/Notification/notification';
import { CheckboxField, Form, FormGroup } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import WithFetch from 'features/Fetch/WithFetch';
import { propertyOf } from 'utils/propertyOf';

export const VacationGeneral = () => {
	const [values, setValues] = useState<TimeTravelConfigResponse>(new TimeTravelConfigResponse());
	const [initialValues, setInitialValues] = useState<TimeTravelConfigResponse>(new TimeTravelConfigResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getVacationGeneralConfigAction);
			if (response.success && response.value) {
				setValues(new TimeTravelConfigResponse(response.value));
				setInitialValues(new TimeTravelConfigResponse(response.value));
			}
		},
		[]
	);

	useEffect(
		() => {
			if (!values.enableApprovalProcess) {
				setValues((state: any) => {
					return new TimeTravelConfigResponse({
						...state,
						enableEmailNotifications: false,
						notifyWhenSubmitted: false,
						notifyWhenApproved: false,
						notifyWhenRejected: false,
						requireCommentsWhenRejecting: false
					});
				});
			} else if (!values.notifyWhenApproved && !values.notifyWhenRejected && !values.notifyWhenSubmitted) {
				setValues((state: any) => {
					return new TimeTravelConfigResponse({
						...state,
						enableEmailNotifications: false
					});
				});
			} else {
				setValues((state: any) => {
					return new TimeTravelConfigResponse({ ...state });
				});
			}
		},
		[values.enableApprovalProcess, values.notifyWhenApproved, values.notifyWhenRejected, values.notifyWhenSubmitted]
	)

	const isDisabledMemo = useMemo(
		() => values.enableApprovalProcess === false,
		[values.enableApprovalProcess]
	);

	const isDisabledEmailNotificationsMemo = useMemo(
		() => {
			if (!values.notifyWhenApproved && !values.notifyWhenRejected && !values.notifyWhenSubmitted) {
				return true;
			} else {
				return false;
			}
		},
		[values.notifyWhenApproved, values.notifyWhenRejected, values.notifyWhenSubmitted]
	);

	const handleCancel = useCallback(
		() => setValues(new TimeTravelConfigResponse(initialValues)),
		[initialValues]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const updateRequest = new UpdateTimeTravelConfigRequest(values);

			const bindedAction = updateVacationGeneralConfigAction.bind(null, updateRequest)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setInitialValues(new TimeTravelConfigResponse(values));
				notifications.success('Vacation general configuration is updated.');
			} else {
				return convertResponseErrors(response);
			}
		},
		[values]
	)

	return (
		<ContentShell
			InlineHelpComponent={VacationGeneralHelp}
			showBreadcrumb={false}
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<Form
					values={values}
					initialValues={initialValues}
					onChange={setValues}
					onSubmit={onSubmitCallback}
					onCancel={handleCancel}
					render={() => (
						<SmartContainer>
							<SmartItem>
								<FormGroup title='Approval'>
									<CheckboxField
										id={propertyOf<TimeTravelConfigResponse>('enableApprovalProcess')}
										labelBefore='Enable approval process'
									/>
								</FormGroup>
							</SmartItem>
							<SmartItem>
								<FormGroup title='Notifications'>
									<CheckboxField
										id={propertyOf<TimeTravelConfigResponse>('enableEmailNotifications')}
										labelBefore='Enable email notifications in approval process'
										disabled={isDisabledMemo || isDisabledEmailNotificationsMemo}
									/>
									<CheckboxField
										id={propertyOf<TimeTravelConfigResponse>('notifyWhenSubmitted')}
										labelBefore='Notify Project Managers when vacation requests are submitted'
										disabled={isDisabledMemo}
									/>
									<CheckboxField
										id={propertyOf<TimeTravelConfigResponse>('notifyWhenApproved')}
										labelBefore='Notify users when vacation requests are approved'
										disabled={isDisabledMemo}
									/>
									<CheckboxField
										id={propertyOf<TimeTravelConfigResponse>('notifyWhenRejected')}
										labelBefore='Notify users when vacation requests are rejected'
										disabled={isDisabledMemo}
									/>
									<CheckboxField
										id={propertyOf<TimeTravelConfigResponse>('requireCommentsWhenRejecting')}
										labelBefore='Require comments when rejecting vacation requests'
										disabled={isDisabledMemo}
									/>
								</FormGroup>
							</SmartItem>
						</SmartContainer>
					)}
				/>
			</WithFetch>
		</ContentShell>
	)
}
