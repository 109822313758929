import { useCallback, useMemo } from 'react';
import { OverlaySpinner } from 'components/Spinner';
import { Item, ItemType } from './Item';
import styles from './buttonGroup.module.scss'

type Props = {
	items: ItemType[]
	onChange(selectedIds: any[]): void
	selectedIds: any[]
	isLoading?: boolean
	// disabled?: boolean
	color?: 'primary' | 'neutral'
	single?: boolean // only one selected without option to deselect
}

export const ButtonGroup = ({ items, onChange, selectedIds, isLoading, color = 'neutral', single }: Props) => {
	const onClickCallback = useCallback(
		(id: any) => {
			let ids = [...selectedIds];
			const index = selectedIds.indexOf(id);
			if (index === -1) {
				if (single) {
					ids = [id];
				} else {
					ids.push(id);
				}
			} else {
				if (single) {
					return;
				} else {
					ids.splice(index, 1);
				}
			}

			onChange(ids);
		},
		[selectedIds, onChange, single]
	)

	const itemsContent = useMemo(
		() => {
			return items.map((item) => (
				<Item
					key={item.id}
					item={item}
					onClick={onClickCallback}
					isSelected={selectedIds.includes(item.id)}
					color={color}
				/>
			))
		},
		[items, onClickCallback, selectedIds, color]
	)

	return (
		<div className={styles.container}>
            <div className={styles.items}>
                {itemsContent}
            </div>
            {isLoading && <OverlaySpinner withBackground />}
		</div>
	)
}
