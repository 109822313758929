import { View, Text, StyleSheet } from '@react-pdf/renderer';

type Props = {
	label: string
	value?: string
	backgroundColor?: string
}

const TopicField = ({ label, value, backgroundColor = '#fff' }: Props) => (
	<View style={styles.container}>
		<Text style={styles.label}>
			{label}:
		</Text>
		<Text style={[styles.value, { backgroundColor: backgroundColor }]}>
			{value || '-'}
		</Text>
	</View>
);

export default TopicField;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	label: {
		paddingVertical: 2,
		marginRight: 2,
		maxWidth: 150,
		fontSize: 10,
	},
	value: {
		paddingHorizontal: 2,
		paddingVertical: 2,
		maxWidth: 150,
		fontSize: 10,
		overflow: 'hidden',
	},
});
