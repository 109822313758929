import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getRouteObject, RouterProps } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import MaintainScope from './MaintainScope/MaintainScope';
import { persistChangeRequestTypesAction } from 'containers/Configuration/SiteConfiguration/Module/Scope/action';
import { persistTicketImpactsAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/Impact/action';
import { persistTicketUrgencyAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/Urgency/action';
import { persistTicketPriorityAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/action';
import { persistChangeRequestStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/ChangeRequest/action';
import { CrudRouter } from 'features/Crud';
import CreateChangeRequest from './ChangeRequests/Crud/CreateChangeRequest';
import UpdateChangeRequest from './ChangeRequests/Crud/UpdateChangeRequest';
import ChangeRequests from './ChangeRequests/ChangeRequests';
import ReadChangeRequest from './ChangeRequests/Crud/ReadChangeRequest';
import { ProjectParamName } from 'features/Project';
import MyChangeRequests from './MyChangeRequests/MyChangeRequests';
import InitiateChangeRequest from './InitiateChangeRequest/InitiateChangeRequest';
import { ScopeDashboard } from './MyDashboard';
import { ScopeSubRoutesEnum, changeRequestsSubRoute, initiateChangeRequestSubRoute, maintainScopeSubRoute, myChangeRequestsSubRoute, myDashboardSubRoute } from './routes';

export const ScopeRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistChangeRequestTypesAction();
			persistTicketImpactsAction();
			persistTicketUrgencyAction();
			persistTicketPriorityAction();
			persistChangeRequestStatusesAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[ScopeSubRoutesEnum.MY_DASHBOARD] &&
				<Route
					path={myDashboardSubRoute.url}
					render={() => <ScopeDashboard />}
				/>
			}
			{routesMap[ScopeSubRoutesEnum.MAINTAIN_SCOPE] &&
				<Route
					path={[
						`${maintainScopeSubRoute.url}/:${ProjectParamName}`,
						maintainScopeSubRoute.url,
					]}
					render={() => <MaintainScope />}
				/>
			}
			{routesMap[ScopeSubRoutesEnum.CHANGE_REQUESTS] &&
				<Route
					// order of paths is important
					path={[
						`${changeRequestsSubRoute.url}/:${ProjectParamName}`,
						changeRequestsSubRoute.url
					]}
					render={() => (
						<CrudRouter
							ListComponent={ChangeRequests}
							CreateComponent={CreateChangeRequest}
							ReadComponent={ReadChangeRequest}
							UpdateComponent={UpdateChangeRequest}
							paramName='changeRequestId'
						/>
					)}
				/>
			}
			{routesMap[ScopeSubRoutesEnum.INITIATE_CHANGE_REQUEST] &&
				<Route
					path={[
						`${initiateChangeRequestSubRoute.url}/:${ProjectParamName}`,
						initiateChangeRequestSubRoute.url
					]}
					render={() => (
						<InitiateChangeRequest />
					)}
				/>
			}
			{routesMap[ScopeSubRoutesEnum.MY_CHANGE_REQUESTS] &&
				<Route
					path={[
						`${myChangeRequestsSubRoute.url}/:${ProjectParamName}`,
						myChangeRequestsSubRoute.url
					]}
					render={() => (
						<CrudRouter
							ListComponent={MyChangeRequests}
							ReadComponent={ReadChangeRequest}
							UpdateComponent={UpdateChangeRequest}
							paramName='changeRequestId'
						/>
					)}
				/>
			}
			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
