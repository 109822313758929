import notifications from "components/Notification/notification";
import { ContentShell } from "features/Content/ContentShell";
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from "features/Crud";
import { ProjectOrCategoryPickerParams } from "features/Project";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { InsertStatusReportRequest, StatusReportResponse } from "services/tenantManagementService";
import { EntityPrefixEnum, getFormatedId } from "utils/commonHelper";
import { convertResponseErrors, tryCatchJsonByAction } from "utils/fetchUtils";
import { createStatusReportAction } from "../action";
import { StatusReportForm } from "./StatusReportForm";

export const CreateStatusReport = ({ publishDataChanged }: CreateComponentProps) => {
	const params: ProjectOrCategoryPickerParams = useParams();
	const projectOrCategoryId = parseInt(params.projectOrCategoryId as string);
	const isProjectConnected = params.isProjectConnected === 'true';
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newStatusReport: StatusReportResponse, isRelease: boolean) => {
			const model = new InsertStatusReportRequest(newStatusReport);
			const bindedAction = createStatusReportAction.bind(null, isRelease, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.STATUS_REPORT, response.value?.id);
				notifications.success(`New status report ${id} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	);

	const statusReport = useMemo(
		() => {
			const model = new StatusReportResponse();
			model.isProjectConnected = isProjectConnected;
			model.projectOrCategoryId = projectOrCategoryId;
			return model
		},
		[isProjectConnected, projectOrCategoryId]
	)

	return (
		<ContentShell title='Create status report'>
			<StatusReportForm
				statusReport={statusReport}
				crud={CrudEnum.Create}
				onSave={handleSave}
				cancel={goBackFromCreate}
			/>
		</ContentShell>
	)
}
