import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ChangeRequestPriorityEnum, ProjectChangeRequestResponse, ChangeRequestStatusResponse, UserModel } from 'services/tenantManagementService';
import { AutoCompleteField, CheckboxField, currencyDefaultProps, DateField, Form, InputField, InputNumberField } from 'components/Form';
import { RootState } from 'base/reducer/reducer';
import { persistTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { useCurrencySuffixMemo } from 'features/Currency/useCurrencySuffixMemo';
import { ProjectPickerParams } from 'features/Project';
import { useParams } from 'react-router-dom';
import { propertyOf } from 'utils/propertyOf';
import { useActiveTeamMembersMemo } from 'features/TableColumns/persistedHooks';

type Props = {
	changeRequest?: ProjectChangeRequestResponse
	cancel(): void
	save(changeRequest: ProjectChangeRequestResponse): void
}

export const priorities: ChangeRequestPriorityEnum[] = [
	ChangeRequestPriorityEnum.Low,
	ChangeRequestPriorityEnum.Medium,
	ChangeRequestPriorityEnum.High,
	ChangeRequestPriorityEnum.Critical
]

export const ChangeRequestForm = ({ changeRequest, cancel, save }: Props) => {
	const {
		persistedUser,
		persistedTeamMember,
		persistedTenant,
		persistedCurrency,
		persistedChangeRequestStatus
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(changeRequest || new ProjectChangeRequestResponse());
	const params: ProjectPickerParams = useParams();
    const projectId = parseInt(params.projectId as string);

	const memberUsersMemo: UserModel[] = useActiveTeamMembersMemo(persistedTeamMember, persistedUser, projectId)

	useEffect(
		() => {
			if (!persistedTeamMember.projectMap[projectId]) {
				persistTeamMembersAction(projectId);
			}
		},
		[persistedTeamMember, projectId]
	)

	const onSubmitCallback = useCallback(
		async () => await save(values),
		[save, values]
	)

	const currencySymbolSuffix = useCurrencySuffixMemo(persistedCurrency, persistedTenant);

	return (
		<Form
			values={values}
			initialValues={changeRequest}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<InputField
							id={propertyOf<ProjectChangeRequestResponse>('name')}
							label='Name'
							isRequired
							maxLength={40}
						/>
						<InputField
							id={propertyOf<ProjectChangeRequestResponse>('initiatedBy')}
							label='Initiated by'
						/>
						<DateField
							id={propertyOf<ProjectChangeRequestResponse>('requestedOn')}
							label='Requested on'
						/>
						<AutoCompleteField
							id={propertyOf<ProjectChangeRequestResponse>('priority')}
							label='Priority'
							items={priorities}
							getItemId={(item: ChangeRequestPriorityEnum) => item}
							getItemText={(item: ChangeRequestPriorityEnum) => item}
						/>
						<DateField
							id={propertyOf<ProjectChangeRequestResponse>('completitionOn')}
							label='Expected completion on'
						/>
						<AutoCompleteField
							id={propertyOf<ProjectChangeRequestResponse>('statusId')}
							label='Status'
							isRequired
							items={persistedChangeRequestStatus.items}
							getItemId={(item: ChangeRequestStatusResponse) => item.id}
							getItemText={(item: ChangeRequestStatusResponse) => item.name}
							getItemDescription={(item: ChangeRequestStatusResponse) => item.description}
							loading={persistedChangeRequestStatus.fetching}
						/>
						<AutoCompleteField
							id={propertyOf<ProjectChangeRequestResponse>('approvedByUserId')}
							label='Approved by'
							items={memberUsersMemo}
							getItemId={(item: UserModel) => item.id}
							getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
							loading={persistedUser.fetching}
							sort
						/>
						<InputNumberField
							label='Effort (in hours)'
							id={propertyOf<ProjectChangeRequestResponse>('effortInHours')}
						/>
						<InputNumberField
							id={propertyOf<ProjectChangeRequestResponse>('totalCost')}
							label='Total Cost'
							{...currencyDefaultProps}
							maxLength={12}
							suffix={currencySymbolSuffix}
							loading={persistedCurrency.fetching || persistedTenant.fetching}
						/>
						<CheckboxField
							id={propertyOf<ProjectChangeRequestResponse>('goLiveCritical')}
							label='Go-live critical'
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
