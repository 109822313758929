import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import { InnerTestPlanResponse, TestStatusEnum } from 'services/tenantManagementService';
import { useTableColumnsMemo } from './Table/tableColumns';
import { CrudEnum } from 'features/Crud';
import { FailedStepTestModal } from './FailedStepTestModal';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { LocalTable } from 'components/Table';

type Props = {
	innerTestPlans: InnerTestPlanResponse[]
	onChange: (innerTestPlans: InnerTestPlanResponse[]) => void
	crud: CrudEnum
}

export const MyTestTable = ({innerTestPlans, onChange, crud} : Props) => {
	const [isFailedStepTestModalOpen, setIsFailedStepTestModalOpen] = useState(false);
	const [failedStepTest, setFailedStepTest] = useState(new InnerTestPlanResponse());

	const { persistedTestCaseStatus } = useSelector((state: RootState) => state);

	const testPlansRef = useRef<InnerTestPlanResponse[]>([]);
	testPlansRef.current = innerTestPlans;

	const tableColumns = useTableColumnsMemo(crud);

	const cellEditedCallback = useCallback(
		(cell: any) => {
			const innerTestPlan: InnerTestPlanResponse = new InnerTestPlanResponse(cell.getData());

			const property = cell.getField();
			if (property === 'stepStatusId'){
				if (isStatusBySemantic(TestStatusEnum.Failed, innerTestPlan.stepStatusId, persistedTestCaseStatus.itemsMap)) {
					setIsFailedStepTestModalOpen(true);
					setFailedStepTest(innerTestPlan);
				} else {
					innerTestPlan.stepRejectionComment = '';
				}
			}
			const newInnerTestPlans = testPlansRef.current.map(itp => itp.id === innerTestPlan.id ? new InnerTestPlanResponse(innerTestPlan) : itp);
			onChange(newInnerTestPlans)
		},
		// TODO: dependencies do not work for cell edited callback. Should we use useRef for all dependecies?
		[persistedTestCaseStatus.itemsMap, onChange]
	)

	const changeFailedStepTestCallback = useCallback(
		(innerTestPlan: InnerTestPlanResponse) => {
			const newInnerTestPlans = innerTestPlans.map(itp => itp.id === innerTestPlan.id ? new InnerTestPlanResponse(innerTestPlan) : itp);
			onChange(newInnerTestPlans)
			setIsFailedStepTestModalOpen(false);
		},
		[onChange, innerTestPlans]
	)

	const cancelChangeFailedStepTestCallback = useCallback(
		() => {
			setIsFailedStepTestModalOpen(false);
		},
		[]
	)

	return (
		<ColumnContainer margin='small'>
			<LocalTable
				columns={tableColumns}
				data={innerTestPlans}
				hasPagination
				cellEdited={cellEditedCallback}
			/>
			<FailedStepTestModal
				testPlan={failedStepTest}
				open={isFailedStepTestModalOpen}
				save={changeFailedStepTestCallback}
				cancel={cancelChangeFailedStepTestCallback}
			/>
		</ColumnContainer>
	)
}
