import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, MapMultiField, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { TenantIsActiveResponse, TicketDashboardFilterRequest, UserModel, TicketStatusResponse, TicketAssignedGroupsResponse, TicketCategoryResponse, TicketResponseGetAll, MonthEnum, TenantIsActiveResponseItemsResponseModel, ProjectStatusResponse, PriorityResponse, ProjectStatusEnum, ProjectResponse, ModuleActivityEnum, TicketPermission } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { RootState } from 'base/reducer/reducer';
import { sortByString } from 'utils/stringUtil';
import { propertyOf } from 'utils/propertyOf';
import { getAllTicketsAction } from '../ViewTickets/action';
import { getTestCyclesAction } from 'containers/Testing/TestCycles/action';
import Button from 'components/Button';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { yearOptions } from 'containers/TimeTravel/utils';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';
import { ProjectMultiSelectField, pmOrSpmOrOumPermission } from 'features/Project';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';

type Month = {
	key: MonthEnum
	value: string
}

const months: Month[] = [
	{ key: MonthEnum.January, value: 'January' },
	{ key: MonthEnum.February, value: 'February' },
	{ key: MonthEnum.March, value: 'March' },
	{ key: MonthEnum.April, value: 'April' },
	{ key: MonthEnum.May, value: 'May' },
	{ key: MonthEnum.June, value: 'June' },
	{ key: MonthEnum.July, value: 'July' },
	{ key: MonthEnum.August, value: 'August' },
	{ key: MonthEnum.September, value: 'September' },
	{ key: MonthEnum.October, value: 'October' },
	{ key: MonthEnum.November, value: 'November' },
	{ key: MonthEnum.December, value: 'December' }
]

export class FilterModel extends TicketDashboardFilterRequest {}

type Props = {
	filterFormModel?: FilterModel
	onSubmit: (request: FilterModel) => Promise<void>
	onSave: (filterFormModel: FilterModel) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const {
		persistedProjectStatus,
		persistedTicketStatus,
		persistedUser,
		persistedTicketAssignedGroup,
		persistedTicketType,
		persistedTicketCategory,
		persistedTicketPriority,
		persistedOrganizationalUnit
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(new FilterModel(filterFormModel));

	useEffect(
		() => {
			setValues(new FilterModel(filterFormModel));
		},
		[filterFormModel]
	)

	const [tickets, setTickets] = useState<TicketResponseGetAll[]>([]);
	const [isFetchingTickets, setIsFetchingTickets] = useState(false);

	const fetchTicketsCallback = useCallback(
		async () => {
			setIsFetchingTickets(true);

			const response = await tryCatchJsonByAction(getAllTicketsAction);

			setIsFetchingTickets(false);

			if (response.success) {
				setTickets(response.items || []);
			}
		},
		[]
	)

	useEffect(
		() => {
			fetchTicketsCallback();
		},
		[fetchTicketsCallback]
	)

	const filteredTickets = useMemo(
		() => {
			return tickets.filter((ticket) => {
				return (
					(values.projectIds?.length ? values.projectIds.includes(ticket.projectId) : true) &&
					(values.ticketStatusIds?.length ? values.ticketStatusIds.includes(ticket.statusId) : true) &&
					(values.ticketPriorities?.length ? values.ticketPriorities.includes(ticket.priority) : true) &&
					(values.assignedToUserIds?.length ? values.assignedToUserIds.includes(ticket.assignedToUserId) : true) &&
					(values.assignedGroupIds?.length ? values.assignedGroupIds.includes(ticket.assignedGroupId!) : true) &&
					(values.ticketTypeIds?.length ? values.ticketTypeIds.includes(ticket.typeId) : true)
				)
			});
		},
		[tickets, values.projectIds, values.ticketStatusIds, values.ticketPriorities, values.assignedToUserIds, values.assignedGroupIds, values.ticketTypeIds]
	)

	const [testCycles, setTestCycles] = useState<TenantIsActiveResponse[]>([]);
	const [isFetchingTestCycles, setIsFetchingTestCycles] = useState(false);

	const fetchTestCyclesDataCallback = useCallback(
		async () => {
			if (!values.projectIds || values.projectIds.length === 0) {
				setTestCycles([]);
				return;
			}
			setIsFetchingTestCycles(true);

			const promises: Promise<TenantIsActiveResponseItemsResponseModel>[] = [];
			for (const projectId of values.projectIds) {
				const bindedAction = getTestCyclesAction.bind(null, projectId);
				promises.push(tryCatchJsonByAction(bindedAction));
			}

			const responses = await Promise.all(promises);

			setIsFetchingTestCycles(false);

			const cycles: TenantIsActiveResponse[] = [];
			for (const response of responses) {
				if (response.success && response.items) {
					const activeItems = response.items.filter(tc => tc.isActive);
					cycles.push(...activeItems);
				}
			}

			setTestCycles(cycles);
		},
		[values.projectIds]
	)

	useEffect(
		() => {
			fetchTestCyclesDataCallback();
		},
		[fetchTestCyclesDataCallback]
	)

	useEffect(
		() => {
			if (!values.years || values.years.length === 0) {
				setValues((state: FilterModel) => new FilterModel({
					...state,
					months: [],
				}));
			}
		},
		[values.years]
	)

	const additionalFilterProjectsCallback = useCallback(
		(projects: ProjectResponse[]) => {
			const projectStatusIds = values.projectStatusIds;

			const filteredProjects = projects.filter((project) => {
				return (isUserPmorSubstitutePmOrSiteAdmin(project.roleId) || project.permissions?.ticketPermission?.dashboard || project.isOrganizationalUnitManager);
			});

			if (!projectStatusIds || projectStatusIds.length === 0) {
				return filteredProjects;
			}

			return filteredProjects.filter((project) => projectStatusIds.includes(project.statusId));
		},
		[values.projectStatusIds]
	)

	const filteredProjectStatus = useMemo(
		() => persistedProjectStatus.items.filter(status => status.semantics === ProjectStatusEnum.Completed || status.semantics === ProjectStatusEnum.Released),
		[persistedProjectStatus]
	)

	const lvl2Categories = useMemo(
		() => {
			if (!values.categoryLevel1Ids) {
				return [];
			}

			const categories: TicketCategoryResponse[] = [];

			for (const level1Id of values.categoryLevel1Ids) {
				const level1 = persistedTicketCategory.itemsMap[level1Id];
				if (level1) {
					const category = persistedTicketCategory.items.filter(item => item.levelNumber === 2 && item.parentId === level1.id)
					categories.push(...category.filter(cat => cat.isActive))
				}
			}

			return categories;
		},
		[values.categoryLevel1Ids, persistedTicketCategory]
	)

	const onProjectStatusChangeCallback = useCallback(
		(_newProjectStatusIds: number[] | undefined, _oldValues: FilterModel) => ({
			[propertyOf<FilterModel>('projectIds')]: undefined
		}),
		[]
	)

	const onProjectsChangeCallback = useCallback(
		(_newProjectIds: number[] | undefined, _oldValues: FilterModel) => ({
			[propertyOf<FilterModel>('testCycleIds')]: undefined
		}),
		[]
	)

	const onCategoryLevel1ChangeCallback = useCallback(
		(_newCategoryLevelIds: number[] | undefined, _oldValues: FilterModel) => ({
			[propertyOf<FilterModel>('categoryLevel2Ids')]: undefined
		}),
		[]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new FilterModel(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<MultiSelectField
							id={propertyOf<FilterModel>('projectStatusIds')}
							label='Project status'
							items={filteredProjectStatus}
							getItemId={(status: ProjectStatusResponse) => status.id}
							getItemText={(status: ProjectStatusResponse) => status.name}
							loading={persistedProjectStatus.fetching}
							updateDependants={onProjectStatusChangeCallback}
							isRequired
						/>
						<ProjectMultiSelectField
							id={propertyOf<FilterModel>('projectIds')}
							specificProjectsFilter={additionalFilterProjectsCallback}
							updateDependants={onProjectsChangeCallback}
							moduleEnum={ModuleActivityEnum.Ticket}
							teamMemberPermission={propertyOf<TicketPermission>('dashboard')}
							userRolePermission={pmOrSpmOrOumPermission}
							isProjectDashboard
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('ticketStatusIds')}
							label='Ticket status'
							items={sortByString(persistedTicketStatus.items, String(propertyOf<TicketStatusResponse>('name')))}
							getItemId={(item: TicketStatusResponse) => item.id}
							getItemText={(item: TicketStatusResponse) => item.name}
							loading={persistedTicketStatus.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('ticketPriorities')}
							label='Priority'
							items={persistedTicketPriority.items}
							getItemId={(item: PriorityResponse) => item.id}
							getItemText={(item: PriorityResponse) => item.name}
							loading={persistedTicketPriority.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('assignedToUserIds')}
							label='Assigned to'
							items={persistedUser.items}
							getItemId={(item: UserModel) => item.id}
							getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
							loading={persistedUser.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('assignedGroupIds')}
							label='Assigned group'
							items={sortByString(persistedTicketAssignedGroup.items, String(propertyOf<TicketAssignedGroupsResponse>('name')))}
							getItemId={(item: TicketAssignedGroupsResponse) => item.id}
							getItemText={(item: TicketAssignedGroupsResponse) => item.name}
							loading={persistedTicketAssignedGroup.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('ticketTypeIds')}
							label='Type'
							items={sortByString(persistedTicketType.items, String(propertyOf<TenantIsActiveResponse>('name')))}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							loading={persistedTicketType.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('ticketIds')}
							label='Ticket ID'
							items={filteredTickets}
							getItemId={(item: TicketResponseGetAll) => item.id}
							getItemText={(item: TicketResponseGetAll) => `${getFormatedId(EntityPrefixEnum.TICKET, item.id)} - ${item.name}`}
							loading={isFetchingTickets}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('testCycleIds')}
							label='Test cycle'
							items={testCycles}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							loading={isFetchingTestCycles}
							disabled={!values.projectIds || values.projectIds.length === 0}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('categoryLevel1Ids')}
							label='Category lvl1'
							items={sortByString(persistedTicketCategory.items.filter(item => item.levelNumber === 1), String(propertyOf<TicketCategoryResponse>('name')))}
							getItemId={(item: TicketCategoryResponse) => item.id}
							getItemText={(item: TicketCategoryResponse) => item.name}
							loading={persistedTicketCategory.fetching}
							updateDependants={onCategoryLevel1ChangeCallback}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('categoryLevel2Ids')}
							label='Category lvl2'
							items={lvl2Categories}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							disabled={!values.categoryLevel1Ids || values.categoryLevel1Ids.length === 0}
							loading={persistedTicketCategory.fetching}
						/>
						<MapMultiField
							id={propertyOf<FilterModel>('organizationalUnitIds')}
							label='Organizational Unit'
							items={convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined)}
							loading={persistedOrganizationalUnit.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('years')}
							label='Year'
							items={yearOptions}
							getItemId={(item: number) => item}
							getItemText={(item: number) => item.toString()}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('months')}
							label='Month'
							items={months}
							disabled={!values.years || values.years.length === 0}
							getItemId={(item: Month) => item.key}
							getItemText={(item: Month) => item.value}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
					disabled={!values.projectStatusIds}
				/>
			)}
		/>
	)
}
