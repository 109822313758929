import { useCallback } from 'react';
import { RowContainer } from 'components/Layout';
import Button from 'components/Button';

type Props = {
	selectedId?: number
	onAddNew: () => void
	onDelete: (id: number) => void
	disabled?: boolean
}

const TableButtons = ({
	selectedId,
	onAddNew,
	onDelete,
	disabled
}: Props) => {

	const deleteCalback = useCallback(
		() => {
			onDelete(selectedId!);
		},
		[selectedId, onDelete]
	)

	return (
		<RowContainer>
			<Button
				text='Add'
				onClick={onAddNew}
				disabled={disabled}
			/>
			<Button
				text='Delete'
				color='destructive'
				onClick={deleteCalback}
				disabled={disabled || !selectedId}
			/>
		</RowContainer>
	);
};

export default TableButtons;
