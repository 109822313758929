import { ExportDataModel, GetProjectReportRequest, InsertProjectReportRequest, ProjectReportClient, ReportStatusClient, UpdateProjectReportRequest, GenericFilterModelCollection, ExportProjectReportDataModel } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getProjectReportsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectReportClient();
	return client.getAll(tenantId, projectId, undefined, undefined, undefined);
}

export const getProjectReportsGenericAction = (projectId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectReportClient();
	return client.getAllGenericFilter(tenantId, projectId, genericFilter);
}

export const createReportAction = (projectId: number, model: InsertProjectReportRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectReportClient();
	return client.create(tenantId, projectId, model);
}

export const updateReportAction = (projectId: number,  model: UpdateProjectReportRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectReportClient();
	return client.update(tenantId, projectId, model);
}

export const getReportAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectReportClient();
	return client.get(tenantId, projectId, id);
}

export const cloneReportAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectReportClient();
	return client.clone(tenantId, projectId, id);
}

export const deleteReportAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectReportClient();
	return client.delete(tenantId, projectId, id);
}

export const releaseReportAction = (projectId: number, model: GetProjectReportRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectReportClient();
	return client.releas(tenantId, projectId, model);
}

export const getStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new ReportStatusClient();
	return client.getAll(tenantId);
}

export const exportAllAction = (projectId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectReportClient();
	return client.exportAll(tenantId, projectId, exportDataModel);
}

export const exportAction = (projectId: number, id: number, exportDataModel: ExportProjectReportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectReportClient();
	return client.export(tenantId, projectId, id, exportDataModel);
}
