import { ColumnContainer } from 'components/Layout'
import { ProcessesDashboard } from './Processes/ProcessesDashboard'
import { ChangeRequestsDashboard } from './ChangeRequests/ChangeRequestsDashboard'

export const ScopeDashboard = () => {
	return (
		<ColumnContainer>
			<ProcessesDashboard />
			<ChangeRequestsDashboard />
		</ColumnContainer>
	)
}
