import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, FormGroup, InputField } from 'components/Form';
import { ColumnContainer } from 'components/Layout';
import notifications from 'components/Notification/notification';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import WithFetch from 'features/Fetch/WithFetch';
import PriorityInfoTable from 'features/Priority/PriorityInfoTable';
import { ImpactEnum, TicketPriorityDescriptionRequest, TicketPriorityDescriptionResponse, UrgencyEnum } from 'services/tenantManagementService';
import { tryCatchJsonByAction, convertResponseErrors } from 'utils/fetchUtils';
import { getTicketPriorityDescsAction, updateTicketPriorityDescsAction } from './action';
import { RootState } from 'base/reducer/reducer';
import { getNameBySemantic } from 'features/Semantics/semanticsHelper';
import { persistTicketImpactsAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/Impact/action';
import { persistTicketUrgencyAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/Urgency/action';
import { persistTicketPriorityAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/action';

export const TicketPriority = () => {
	const {
		persistedTicketImpact,
		persistedTicketUrgency,
		persistedTicketPriority
	} = useSelector((state: RootState) => state);

	const [initialPriority, setInitialPriority] = useState(new TicketPriorityDescriptionResponse());
	const [priority, setPriority] = useState(new TicketPriorityDescriptionResponse());

	useEffect(
		() => {
			persistTicketImpactsAction();
			persistTicketUrgencyAction();
			persistTicketPriorityAction();
		},
		[]
	)

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getTicketPriorityDescsAction);
			if (response.success) {
				const value = response.value || new TicketPriorityDescriptionResponse();
				setInitialPriority(value);
				setPriority(value);
			}
		},
		[]
	)

	const cancelCallback = useCallback(
		() => setPriority(initialPriority),
		[initialPriority]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new TicketPriorityDescriptionRequest(priority);
			const bindedAction = updateTicketPriorityDescsAction.bind(null, model)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Ticket priority is updated');
			} else {
				return convertResponseErrors(response);
			}
		},
		[priority]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<Form
				values={priority}
				initialValues={initialPriority}
				onChange={setPriority}
				onSubmit={onSubmitCallback}
				onCancel={cancelCallback}
				render={() => (
					<ColumnContainer>
						<SmartContainer>
							<SmartItem size='small'>
								<FormGroup title='Impacts short descriptions'>
									<InputField
										id='impactHighDescription'
										label={getNameBySemantic(ImpactEnum.High, persistedTicketImpact.items) || 'High'}
									/>
									<InputField
										id='impactMediumDescription'
										label={getNameBySemantic(ImpactEnum.Medium, persistedTicketImpact.items) || 'Medium'}
									/>
									<InputField
										id='impactLowDescription'
										label={getNameBySemantic(ImpactEnum.Low, persistedTicketImpact.items) || 'Low'}
									/>
								</FormGroup>
							</SmartItem>
							<SmartItem size='small'>
								<FormGroup title='Urgencies short descriptions'>
									<InputField
										id='urgencyUrgentDescription'
										label={getNameBySemantic(UrgencyEnum.Urgent, persistedTicketUrgency.items) || 'Urgent'}
									/>
									<InputField
										id='urgencyNormalDescription'
										label={getNameBySemantic(UrgencyEnum.Normal, persistedTicketUrgency.items) || 'Normal'}
									/>
								</FormGroup>
							</SmartItem>
						</SmartContainer>
						<PriorityInfoTable
							impacts={persistedTicketImpact.items}
							urgencies={persistedTicketUrgency.items}
							priorities={persistedTicketPriority.items}
						/>
					</ColumnContainer>
				)}
			/>
		</WithFetch>
	)
}
