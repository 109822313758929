import { HelpShell } from 'features/Help/HelpShell';

export const BudgetConfigurationHelp = () => {
	return (
		<HelpShell title='Budget configuration'>
			<p>For each project you can configure how you will set-up your project budget and actuals.</p>
			<p>Please note that you can change configuration anytime during project execution.</p>
			<p>First, you define planning period which should represent project start and end date.</p>
			<div>
				<p>You can decide if you would like to include in your project budget:</p>
				<ul>
					<li>Calculated risk from risk module</li>
					<li>Planned Invoices entered in Invoices</li>
					<li>Approved change requests (note: if you select this option and you expect to receive or issue invoice for change request, you need to exclude invoice from budget by selecting checkbox in invoice)</li>
				</ul>
			</div>
			<div>
				<p>You can decide if you would like to include in your project actuals:</p>
				<ul>
					<li>Completed Change requests from Scope module (note: select this option if change request cost/revenues are not generated by timesheets or invoice is planned to be issued or received)</li>
					<li>Approved timesheets (this will apply only for company users - not guest users)</li>
					<li>Approved expenses and travel expenses</li>
					<li>Received/issued/payed Invoices from finance module</li>
				</ul>
			</div>
			<div>
				<p>Define if you would like to show actuals:</p>
				<ul>
					<li>Timesheets per user</li>
					<li>Expenses and travel expenses per user</li>
					<li>Invoices per partner</li>
				</ul>
			</div>
		</HelpShell>
	)
}
