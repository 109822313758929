import { useState, useCallback } from 'react'
import { Form, MultiCheckboxField, RadioField, TextareaField } from 'components/Form';
import { SurveyQuestionResponse, SurveyQuestionTypeEnum } from 'services/tenantManagementService';
import { ColumnContainer } from 'components/Layout';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { SURVEY_RATING_OPTIONS } from '../mySurveyConstants';

type TakeSurveyFormProps = {
	surveyQuestions: SurveyQuestionResponse[]
	save?(answers: { [key: string]: any }): void
	cancel?(): void
}

const TakeSurveyForm = ({ surveyQuestions, save, cancel }: TakeSurveyFormProps) => {
	const [initialValues] = useState({});
	const [values, setValues] = useState({});

	const onSubmitCallback = useCallback(
		async () => {
			if (save) {
				return await save(values)
			}
		},
		[save, values]
	);

	return (
		<Form
			values={values}
			initialValues={initialValues}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							{
								surveyQuestions.length > 0 && surveyQuestions.map((question: SurveyQuestionResponse, index: number) => {
									return (
										<SmartFormGroup key={`${question.id}`} label={`${index + 1}. ${question.questionGroup} - ${question.question}`} multiline>
											<>
												{
													question.surveyType === SurveyQuestionTypeEnum.Answer &&
													<TextareaField
														id={`${question.id}`}
														isRequired={question.answerRequired}
													/>
												}
												{
													question.surveyType === SurveyQuestionTypeEnum.SingleChoice &&
													<RadioField
														id={`${question.id}`}
														items={(question.proposedAnswers || []).map(x => ({ id: x.id, text: x.answer }))}
														isRequired={question.answerRequired}
														column
													/>
												}
												{
													question.surveyType === SurveyQuestionTypeEnum.MultipleChoice &&
													<MultiCheckboxField
														id={`${question.id}`}
														items={(question.proposedAnswers || []).map(x => ({ id: x.id, text: x.answer }))}
														isRequired={question.answerRequired}
														column
													/>
												}
												{
													question.surveyType === SurveyQuestionTypeEnum.Rating &&
													<RadioField
														id={`${question.id}`}
														items={SURVEY_RATING_OPTIONS}
														isRequired={question.answerRequired}
													/>
												}
											</>
										</SmartFormGroup>
									)
								})
							}
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}

export default TakeSurveyForm;
