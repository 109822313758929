import { useCallback, useEffect, useState } from 'react'
import { ScheduleUtilizationDashboardFilterRequest, ScheduleUtilizationRowDashboardResponse } from 'services/tenantManagementService'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getScheduleDashboardUtilizationAction } from '../action'
import { FilterForm } from './FilterForm'
import { DashboardContainer } from 'features/Dashboard/DashboardContainer/DashboardContainer'
import { useSaveFilterMemo } from 'features/Dashboard/SaveFilter/useSaveFilterMemo'
import { UtilizationView } from './UtilizationView'
import WithFetch from 'features/Fetch/WithFetch'

const viewType = 'schedule_dashboard_utilization';

export const UtilizationDashboard = () => {
	const [loading, setLoading] = useState(false);
	const { savedFilterModel, saveFilterModel, fetchFilterModel, isLoadingFilter } = useSaveFilterMemo<ScheduleUtilizationDashboardFilterRequest>(viewType);

	const [currentFilterModel, setCurrentFilterModel] = useState(new ScheduleUtilizationDashboardFilterRequest());
	const [dashboard, setDashboard] = useState<ScheduleUtilizationRowDashboardResponse[]>([])

	const fetchDashboardCallback = useCallback(
		async (model: ScheduleUtilizationDashboardFilterRequest) => {
			setLoading(true);

			setCurrentFilterModel(new ScheduleUtilizationDashboardFilterRequest(model));

			const bindedAction = getScheduleDashboardUtilizationAction.bind(null, new ScheduleUtilizationDashboardFilterRequest(model))
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setDashboard(response.items || []);
			}

			setLoading(false);
		},
		[]
	)

	useEffect(
		() => {
			if (savedFilterModel) {
				fetchDashboardCallback(savedFilterModel);
			}
		},
		[savedFilterModel, fetchDashboardCallback]
	)

	return (
		<WithFetch fetchFunction={fetchFilterModel} refetching={isLoadingFilter}>
			<DashboardContainer
				form={
					<FilterForm
						onSubmit={fetchDashboardCallback}
						filterFormModel={savedFilterModel}
						onSave={saveFilterModel}
					/>
				}
				dashboard={
					<UtilizationView
						loading={loading}
						dashboard={dashboard}
						filterModel={currentFilterModel}
					/>
				}
			/>
		</WithFetch>
	)
}
