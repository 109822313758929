import { CommunicationDashboardResponse } from 'services/tenantManagementService'
import { Layout } from 'features/Dashboard/Layout'
import { Dashboard } from 'features/Dashboard/Dashboard';
import { TextWidget } from 'features/Dashboard/Widget/TextWidget';
import { PieWidget } from 'features/Dashboard/Widget/PieWidget';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useMemo } from 'react';
import { DivergentWidget } from 'features/Dashboard/Widget/DivergentWidget';
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { errorColor } from 'features/ThemeProvider/amchartCustomTheme';

type Props = {
	loading: boolean
	dashboard: CommunicationDashboardResponse
}

const FilteredDashboard = ({ loading, dashboard }: Props) => {
	const {
		persistedTaskStatus,
		persistedUser
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const perStatusDataMemo = useMemo(
		() => {
			const data = persistedTaskStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.tasksPerStatus && dashboard.tasksPerStatus[status.id]) ? dashboard.tasksPerStatus[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTaskStatus.items]
	)

	const taskStatusPerAssignedUserDataMemo = useMemo(
		() => {
			const data: any[] = [];

			for (const userId in dashboard.tasksPerAssignedUserAndStatusCounts) {
				const object = dashboard.tasksPerAssignedUserAndStatusCounts[userId];
				const item: any = {
					username: getUserFullName(parseInt(userId)),
				}

				for (const statusId in object) {
					let count = object[statusId];
					item[statusId] = count;
				}
				data.push(item);
			}

			return data;
		},
		[dashboard, getUserFullName]
	)

	const statusFieldsMemo = useMemo(
		() => persistedTaskStatus.items.map(status => {
			return {
				value: status.id.toString(),
				name: status.name,
				color: status.color
			}

		}),
		[persistedTaskStatus]
	)

	const overdueTasksPerAssignedUserDataMemo = useMemo(
		() => {
			return Object.keys(dashboard.overdueTasksPerAssignedUser || {}).map(
				(item) => {
					return  {
						name: getUserFullName(parseInt(item)),
						value: dashboard.overdueTasksPerAssignedUser![item],
						count: dashboard.overdueTasksPerAssignedUser![item]
					}
				}
			)
		},
		[dashboard, getUserFullName]
	)

	const overdueFieldsMemo = useMemo(
		() => {
			return [{
				value: 'count',
				name: 'Overdue',
				color: errorColor
			}]
		},
		[]
	);

	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='horizontal'>
				<Layout
					orientation='vertical'
					className='col-lg-3 col-6'
				>
					<TextWidget
						title='Total number of tasks'
						value={dashboard.totalNumberOfTasks}
						loading={loading}
					/>
					<TextWidget
						title='Closed tasks'
						value={dashboard.closedTasks}
						loading={loading}
					/>
				</Layout>
				<Layout
					orientation='vertical'
					className='col-lg-3 col-6'
				>
					<TextWidget
						title='Open tasks'
						value={dashboard.openTasks}
						loading={loading}
					/>
					<TextWidget
						title='Overdue tasks'
						value={dashboard.overdueTasks}
						loading={loading}
					/>

				</Layout>
				<PieWidget
					className='col-lg-6 col-12'
					title='Tasks per Status'
					id='tasks_per_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={perStatusDataMemo}
					loading={loading}
				/>
			</Layout>

			<Layout orientation='horizontal'>
				<DivergentWidget
					className='col-lg-6 col-12'
					id='tasks_status_per_assigned_user_divergent_chart'
					title='Tasks status per assigned user'
					data={taskStatusPerAssignedUserDataMemo}
					fieldCategory='username'
					fieldValues={statusFieldsMemo}
					loading={loading}
				/>
				<RotatedColumnSeriesWidget
					className='col-lg-6 col-12'
					id='overdue_tasks_per_assigned_user_rotated_column_series_chart'
					title='Overdue tasks per assigned user'
					data={overdueTasksPerAssignedUserDataMemo}
					fieldCategory='name'
					fieldValues={overdueFieldsMemo}
					loading={loading}
					showValueOnBar
				/>
			</Layout>
		</Dashboard>
	)
}

export default FilteredDashboard
