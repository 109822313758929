import { successColor } from 'base/colors/colors'
import { RootState } from 'base/reducer/reducer'
import { ColumnContainer } from 'components/Layout'
import { Note } from 'components/Note/Note'
import { Dashboard } from 'features/Dashboard/Dashboard'
import { Layout } from 'features/Dashboard/Layout'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget'
import { XYWidget } from 'features/Dashboard/Widget/XYWidget'
import { errorColor } from 'features/ThemeProvider/amchartCustomTheme'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TabComponentProps } from '../../FilteredDashboard'

export const ProjectsDashboard = ({ loading, dashboard }: TabComponentProps) => {
	const {
		persistedProject,
		persistedProjectType,
		persistedProjectCategory
	} = useSelector((state: RootState) => state);

	const revenuePerProjectTypeDataMemo = useMemo(
		() => {
			const data: { name?: string, count?: number }[] = [];

			for (const typeId in dashboard.projectActualBudget?.incomePerProjectType) {
				data.push({
					name: persistedProjectType.itemsMap[parseInt(typeId)]?.name,
					count: (dashboard.projectActualBudget?.incomePerProjectType && dashboard.projectActualBudget?.incomePerProjectType[typeId]) || 0
				})
			}

			return data;
		},
		[dashboard, persistedProjectType]
	)

	const expensePerProjectTypeDataMemo = useMemo(
		() => {
			const data: { name?: string, count?: number }[] = [];

			for (const typeId in dashboard.projectActualBudget?.expensePerProjectType) {
				data.push({
					name: persistedProjectType.itemsMap[parseInt(typeId)]?.name,
					count: (dashboard.projectActualBudget?.expensePerProjectType && dashboard.projectActualBudget?.expensePerProjectType[typeId]) || 0
				})
			}

			return data;
		},
		[dashboard, persistedProjectType]
	)

	const profitPerProjectTypeDataMemo = useMemo(
		() => {
			const profitPerProjectType = dashboard.projectActualBudget?.profitPerProjectType || {};
			let positiveValueSum = 0;
			for (let key in profitPerProjectType) {
				const value = profitPerProjectType[key];
				if (value > 0) {
					positiveValueSum += value;
				}
			}

			return Object.keys(profitPerProjectType).map(key => {
				const value = profitPerProjectType[key] || 0;

				return  {
					name: persistedProjectType.itemsMap[parseInt(key)]?.name || '',
					value: (value / positiveValueSum),
					barColor: value < 0 ? errorColor : successColor
				}
			})
		},
		[dashboard, persistedProjectType]
	)

	const revenuePerProjectCategoryDataMemo = useMemo(
		() => {
			const data: { name?: string, count?: number }[] = [];

			for (const typeId in dashboard.projectActualBudget?.incomePerProjectCategory) {
				data.push({
					name: persistedProjectCategory.itemsMap[parseInt(typeId)]?.name,
					count: (dashboard.projectActualBudget?.incomePerProjectCategory && dashboard.projectActualBudget?.incomePerProjectCategory[typeId]) || 0
				})
			}

			return data;
		},
		[dashboard, persistedProjectCategory]
	)

	const expensePerProjectCategoryDataMemo = useMemo(
		() => {
			const data: { name?: string, count?: number }[] = [];

			for (const typeId in dashboard.projectActualBudget?.expensePerProjectCategory) {
				data.push({
					name: persistedProjectCategory.itemsMap[parseInt(typeId)]?.name,
					count: (dashboard.projectActualBudget?.expensePerProjectCategory && dashboard.projectActualBudget?.expensePerProjectCategory[typeId]) || 0
				})
			}

			return data;
		},
		[dashboard, persistedProjectCategory]
	)

	const profitPerProjectCategoryDataMemo = useMemo(
		() => {
			const profitPerProjectCategory = dashboard.projectActualBudget?.profitPerProjectCategory || {};
			let positiveValueSum = 0;
			for (let key in profitPerProjectCategory) {
				const value = profitPerProjectCategory[key];
				if (value > 0) {
					positiveValueSum += value;
				}
			}

			return Object.keys(profitPerProjectCategory).map(key => {
				const value = profitPerProjectCategory[key] || 0;

				return  {
					name: persistedProjectCategory.itemsMap[parseInt(key)]?.name || '',
					value: (value / positiveValueSum),
					barColor: value < 0 ? errorColor : successColor
				}
			})
		},
		[dashboard, persistedProjectCategory]
	)

	const actualRevenuePerProjectDataMemo = useMemo(
		() => {
			return Object.keys(dashboard.projectActualBudget?.actualBudgetPerProject || {}).map(
				(item) => {
					return  {
						name: persistedProject.itemsMap[parseInt(item)]?.name,
						count: dashboard.projectActualBudget?.actualBudgetPerProject![item].income
					}
				}
			)
		},
		[dashboard, persistedProject]
	)

	const revenueFieldsMemo = useMemo(
		() => {
			return [{
				value: 'count',
				name: 'Revenue'
			}]
		},
		[]
	);

	const actualExpensePerProjectDataMemo = useMemo(
		() => {
			return Object.keys(dashboard.projectActualBudget?.actualBudgetPerProject || {}).map(
				(item) => {
					return  {
						name: persistedProject.itemsMap[parseInt(item)]?.name,
						count: dashboard.projectActualBudget?.actualBudgetPerProject![item].expense
					}
				}
			)
		},
		[dashboard, persistedProject]
	)

	const expenseFieldsMemo = useMemo(
		() => {
			return [{
				value: 'count',
				name: 'Expense'
			}]
		},
		[]
	);

	const actualProfitPerProjectDataMemo = useMemo(
		() => {
			return Object.keys(dashboard.projectActualBudget?.actualBudgetPerProject || {}).map(
				(item) => {
					const profit = dashboard.projectActualBudget?.actualBudgetPerProject![item].profit;

					return  {
						name: persistedProject.itemsMap[parseInt(item)]?.name,
						count: profit && Math.abs(profit),
						barColor: (profit && profit < 0) ? errorColor : successColor
					}
				}
			)
		},
		[dashboard, persistedProject]
	)

	const profitFieldsMemo = useMemo(
		() => {
			return [{
				value: 'count',
				name: 'Profit'
			}]
		},
		[]
	);

	let maxValuePerProject = 0;
	const perProjectDatas = actualRevenuePerProjectDataMemo.concat(actualExpensePerProjectDataMemo, actualProfitPerProjectDataMemo);
	for (let item of perProjectDatas) {
		if (item.count && item.count > maxValuePerProject) {
			maxValuePerProject = item.count;
		}
	}

  	return (
		<ColumnContainer margin='small'>
			<Note text='Reports for multi projects' />
			<Dashboard orientation='vertical'>
				<Layout orientation='horizontal'>
					<PieWidget
						className='col-lg-4 col-md-6'
						title='Revenue per project type'
						id='revenue_per_project_type_pie_chart'
						fieldValue='count'
						fieldCategory='name'
						data={revenuePerProjectTypeDataMemo}
						loading={loading}
					/>
					<PieWidget
						className='col-lg-4 col-md-6'
						title='Expense per project type'
						id='expense_per_project_type_pie_chart'
						fieldValue='count'
						fieldCategory='name'
						data={expensePerProjectTypeDataMemo}
						loading={loading}
					/>
					<XYWidget
						className='col-lg-4 col-md-6'
						title='Profit per project type'
						id='profit_per_project_type_pie_chart'
						data={profitPerProjectTypeDataMemo}
						loading={loading}
						isPercent
						showValueOnBar
					/>
				</Layout>
				<Layout orientation='horizontal'>
					<PieWidget
						className='col-lg-4 col-md-6'
						title='Revenue per project category'
						id='revenue_per_project_category_pie_chart'
						fieldValue='count'
						fieldCategory='name'
						data={revenuePerProjectCategoryDataMemo}
						loading={loading}
					/>
					<PieWidget
						className='col-lg-4 col-md-6'
						title='Expense per project category'
						id='expense_per_project_category_pie_chart'
						fieldValue='count'
						fieldCategory='name'
						data={expensePerProjectCategoryDataMemo}
						loading={loading}
					/>
					<XYWidget
						className='col-lg-4 col-md-6'
						title='Profit per project category'
						id='profit_per_project_category_pie_chart'
						data={profitPerProjectCategoryDataMemo}
						loading={loading}
						isPercent
						showValueOnBar
					/>
				</Layout>
				<Layout orientation='horizontal'>
					<RotatedColumnSeriesWidget
						className='col-lg-4'
						title='Actual revenue per project'
						id='actual_revenue_per_project'
						data={actualRevenuePerProjectDataMemo}
						fieldCategory='name'
						fieldValues={revenueFieldsMemo}
						loading={loading}
						hideLegend
						minValue={0}
						maxValue={maxValuePerProject}
						showValueOnBar
					/>
					<RotatedColumnSeriesWidget
						className='col-lg-4'
						title='Actual expense per project'
						id='actual_expense_per_project'
						data={actualExpensePerProjectDataMemo}
						fieldCategory='name'
						fieldValues={expenseFieldsMemo}
						loading={loading}
						hideLegend
						minValue={0}
						maxValue={maxValuePerProject}
						showValueOnBar
					/>
					<RotatedColumnSeriesWidget
						className='col-lg-4'
						title='Actual profit per project'
						id='actual_profit_per_project'
						data={actualProfitPerProjectDataMemo}
						fieldCategory='name'
						fieldValues={profitFieldsMemo}
						loading={loading}
						hideLegend
						minValue={0}
						maxValue={maxValuePerProject}
						showValueOnBar
					/>
				</Layout>
			</Dashboard>
		</ColumnContainer>
  	)
}
