import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { RiskDashboardResponse, RiskResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { EntityPrefixEnum } from 'utils/commonHelper';

const defaultColumns = [
	propertyOf<RiskResponse>('id'),
	propertyOf<RiskResponse>('name'),
	propertyOf<RiskResponse>('riskForInclusionInBudget'),
	'currency'
]

export const useTableColumnsMemo = (dashboard: RiskDashboardResponse) => {
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<RiskResponse>('id')]: {
					title: 'Risk ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.RISK,
					bottomCalcFormatter: () => 'Total',
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<RiskResponse>('name')]: {
					title: 'Risk description',
					fieldType: FieldTypeEnum.String,
					minWidth: 550,
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<RiskResponse>('riskForInclusionInBudget')]: {
					title: 'Total',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: () => dashboard.totalRiskForInclusion,
					disableSort: true,
					disableFilter: true
				},
				currency: {
					title: 'Currency',
					fieldType: FieldTypeEnum.String,
					disableSort: true,
					disableFilter: true,
				}
			}
		},
		[dashboard]
	)

	return useVisibleColumns(columns, defaultColumns);
}
