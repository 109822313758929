import { HelpShell } from 'features/Help/HelpShell';

export const CompanyInfoHelp = () => {
	return (
		<HelpShell title='Company Information'>
			<p>
				Company information contains all general data related to your company, including current licensing plan, payment and billing history and Mavles General Terms and Conditions.
			</p>
			<p>
				Here you can maintain data that should appear on your invoices, but also maintain payment information such as credit card number, validity etc. Mavles is generating invoices at the end of each month and here you can maintain email address (Configuration &gt; Company information &amp; Licensing &gt; Company information &gt; Invoicing e-mail address) to which invoices will be send, but also contact person in your organization which could be contacted related to invoicing and payments. If this information is not maintained, invoices will be sent to site administrator email address.
			</p>
			<p>
				Logo in Header will add your company logo in up left corner of your Mavles site. <br />
				Logo in PDF files will add your company logo on pdf documents generated from Mavles.
			</p>
			<p>
				Mavles is licensed per number of active users in a month. Active user is user active in at least one project in Mavles. <br />
				This information is maintained by responsible project manager or site administrator. When user is assigned to project, project manager defines user validity period. After this period expires, user status is inactive for selected project. Project Manager can extend user validity at any time. Only active users can access projects for which they are assigned. If user is active for one project and inactive for another, user can access project and data only for the project where his/her status is valid. <br />
				Site administrator can add and remove users as your team changes and define in this section maximum number of users (company and guest) which can be active for period. At the end of each monthly billing cycle, you will be billed based on the exact number of active users you have had in the period. Monthly bills are payable by credit card (MasterCard, Visa, or American Express).
			</p>
		</HelpShell>
	)
}
