import { useState, useCallback, useMemo } from 'react'
import { ColumnContainer } from 'components/Layout';
import WithFetch from 'features/Fetch/WithFetch';
import { ScheduleResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { useTableColumnsMemo } from './Table/tableColumns';
import { LocalTable } from 'components/Table';
import { ComponentProps } from 'features/Project';
import { exportSchedulesAction, getAllSchedulesAction } from '../action';
import { TableButtons } from './Table/TableButtons';

const configureViewKey = 'schedule_grid_view_table';

export const GridView = ({ project }: ComponentProps) => {
	const [schedules, setSchedules] = useState<ScheduleResponse[]>([]);

	const tableColumns = useTableColumnsMemo(project.id, configureViewKey);

	const fetchCallback = useCallback(
		async () => {
			const bindedAction = getAllSchedulesAction.bind(null, project.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setSchedules(response.items || []);
			}
		},
		[project.id]
	)

	const memoExportFunction = useMemo(
		() => exportSchedulesAction.bind(null, project.id),
		[project.id]
	)

	return (
		<WithFetch fetchFunction={fetchCallback}>
			<ColumnContainer margin='medium'>
				<TableButtons
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					exportFunction={memoExportFunction}
				/>
				<LocalTable
					columns={tableColumns}
					data={schedules}
				/>
			</ColumnContainer>
		</WithFetch>
	);
}
