import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux'
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { OrganizationalUnitManagerResponse } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { AutoCompleteField, CheckboxField, DateField, Form, MapSingleField } from 'components/Form';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { propertyOf } from 'utils/propertyOf';
import { UserModel } from 'services/tenantManagementService';
import { VerticalSeparator } from 'components/Layout';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';

type Props = {
	managerAuthorization?: OrganizationalUnitManagerResponse
	crud: CrudEnum
	onSave: (partner: OrganizationalUnitManagerResponse) => void
}

export const ManagerAuthorizationForm = ({ managerAuthorization, crud, onSave }: Props) => {
	const { persistedUser, persistedOrganizationalUnit } = useSelector((state: RootState) => state);

	const [values, setValues] = useState(managerAuthorization || new OrganizationalUnitManagerResponse());
	const goBackFromCrud = useGoBackFromCrud(crud);

	const onSubmitCallback = useCallback(
		async () => onSave && await onSave(values),
		[onSave, values]
	)

	return (
		<Form
			values={values}
			initialValues={managerAuthorization}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<AutoCompleteField
							id={propertyOf<OrganizationalUnitManagerResponse>('userId')}
							label='User'
							isRequired
							items={persistedUser.items.filter(user => !user.isGuest)}
							getItemId={(item: UserModel) => item.id}
							getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
							loading={persistedUser.fetching}
							sort
						/>
						<MapSingleField
							id={propertyOf<OrganizationalUnitManagerResponse>('organizationalUnitId')}
							label='Organizational unit'
							items={convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined)}
							loading={persistedOrganizationalUnit.fetching}
							isRequired
						/>
						<DateField
							id={propertyOf<OrganizationalUnitManagerResponse>('validUntil')}
							label='Valid until'
							isRequired
						/>
						<VerticalSeparator />
						<CheckboxField
							id={propertyOf<OrganizationalUnitManagerResponse>('isResourceManager')}
							labelBefore='User is a resource manager and if required he/she can approve timesheets,
								travel requests and expenses, expenses and vacation requests for his team defined
								in Organization entry level  and below.  By default approval is done by project managers.'
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
