import { BudgetStatusClient, SimpleStatusRequestUpdateSimpleStatusRequestItems } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getBudgetStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new BudgetStatusClient();
	return client.getAll(tenantId);
}

export const updateBudgetStatusesAction = (model: SimpleStatusRequestUpdateSimpleStatusRequestItems) => {
	const tenantId = getTenantId()!;
	const client = new BudgetStatusClient();
	return client.update(tenantId, model);
}
