import { useCallback, useEffect, useState } from 'react';
import { Form } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ModuleActivityEnum, ScheduleGanttDashboardFilterRequest } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import Button from 'components/Button';
import { ProjectMultiSelectField, pmOrSpmPermission } from 'features/Project';

type Props = {
	filterFormModel?: ScheduleGanttDashboardFilterRequest
	onSubmit: (request: ScheduleGanttDashboardFilterRequest) => Promise<void>
	onSave: (filterFormModel: ScheduleGanttDashboardFilterRequest) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const [values, setValues] = useState(new ScheduleGanttDashboardFilterRequest(filterFormModel));

	useEffect(
		() => {
			setValues(new ScheduleGanttDashboardFilterRequest(filterFormModel));
		},
		[filterFormModel]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new ScheduleGanttDashboardFilterRequest(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectMultiSelectField
							id={propertyOf<ScheduleGanttDashboardFilterRequest>('projectIds')}
							isRequired
							isProjectDashboard
							moduleEnum={ModuleActivityEnum.Schedule}
							userRolePermission={pmOrSpmPermission}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
				/>
			)}
		/>
	)
}
