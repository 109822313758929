import notifications from 'components/Notification/notification';
import { ContentShell } from 'features/Content/ContentShell';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud'
import { useCallback } from 'react';
import { InsertTaskRequest, TaskResponse } from 'services/tenantManagementService';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createTaskAction } from '../action';
import { TasksForm } from './TaskForm'

export const CreateTask = ({ publishDataChanged }: CreateComponentProps) => {
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newTask: TaskResponse) => {
			const model = new InsertTaskRequest(newTask);
			const bindedAction = createTaskAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TASK, response.value?.id);
				notifications.success(`Task ${id} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	);

	return (
		<ContentShell title='Create task'>
			<TasksForm
				crud={CrudEnum.Create}
				onSave={handleSave}
				cancel={goBackFromCreate}
			/>
		</ContentShell>
	)
}
