import { useState, useCallback, useMemo, useEffect } from 'react'
import { ColumnContainer } from 'components/Layout';
import notifications from 'components/Notification/notification';
import WithFetch from 'features/Fetch/WithFetch';
import { SurveyQuestionResponse, SurveyStatusEnum } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { deleteSurveyQuestionAction, getSurveyQuestionsAction } from './actions';
import { useTableColumnsMemo } from './Table/tableColumns';
import TableButtons from './Table/TableButtons'
import { UpdateSurveyTabsProps } from '../UpdateSurveyTabs';
import QuestionsRouter from './QuestionsRouter';
import { publishSurveyQuestionsChanged, surveyQuestionsChangedTopic } from './surveyQuestionsChanged';
import { LocalTable } from 'components/Table';

const QuestionsTab = ({ project, survey }: UpdateSurveyTabsProps) => {
	const [questions, setQuestions] = useState<SurveyQuestionResponse[]>([]);
	const [selectedQuestion, setSelectedQuestion] = useState(new SurveyQuestionResponse());
	const [isDeleting, setIsDeleting] = useState(false);

	const tableColumns = useTableColumnsMemo();

	const disabledMemo = useMemo(
		() => survey.status === SurveyStatusEnum.Released,
		[survey]
	)

	const crudHistoryStateMemo = useMemo(
		() => ({ lastQuestionOrder: questions.length > 0 ? (questions[questions.length - 1].order || 0) : 0 }),
		[questions],
	)

	const fetchCallback = useCallback(
		async () => {
			const bindedAction = getSurveyQuestionsAction.bind(null, project.id, survey.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setQuestions(response.items || [])
			}
		},
		[project.id, survey.id]
	)

	useEffect(
		() => {
			const subscription = surveyQuestionsChangedTopic?.subscribe(fetchCallback);
			return () => {
				subscription?.unsubscribe();
			}
		},
		[fetchCallback]
	)

	const handleDelete = useCallback(
		async (id: number) => {
			setIsDeleting(true);
			const bindedAction = deleteSurveyQuestionAction.bind(null, project.id, survey.id, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`Survey question deleted.`);
				publishSurveyQuestionsChanged()
			}
			setIsDeleting(false);
		},
		[project.id, survey.id]
	);

	const handleRowSelectionChange = useCallback(
		(data: SurveyQuestionResponse[]) => {
			setSelectedQuestion(data[0] || new SurveyQuestionResponse());
		},
		[]
	);

	return (
		<WithFetch fetchFunction={fetchCallback} refetching={isDeleting}>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedQuestion.id}
					onDelete={handleDelete}
					disabled={disabledMemo}
					historyState={crudHistoryStateMemo}
				/>
				<LocalTable
					columns={tableColumns}
					data={questions}
					rowSelectionChanged={handleRowSelectionChange}
				/>
			</ColumnContainer>
			<QuestionsRouter />
		</WithFetch>
	);
}

export default QuestionsTab;
