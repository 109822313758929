import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { VacationRequestResponse, UpdateVacationRequestRequest } from 'services/tenantManagementService';
import { getVacationRequestAction,  updateVacationRequestAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import { ColumnContainer } from 'components/Layout';
import VacationRequestForm from './VacationRequestForm';
import VacationRequestStatusTable from '../VacationRequestStatusTable/VacationRequestStatusTable';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = {
	vacationRequestId: string
}

const UpdateMyVacationRequest = ({ publishDataChanged }: UpdateComponentProps) => {
	const params: ParamType = useParams();
	const [vacationRequest, setVacationRequest] = useState(new VacationRequestResponse());
	const goBackFromUpdate = useGoBackFromUpdate();

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getVacationRequestAction.bind(null, parseInt(params.vacationRequestId));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {

				setVacationRequest(response.value || new VacationRequestResponse());
			}
		},
		[params]
	)

	const saveCallback = useCallback(
		async (newtVacationRequest: VacationRequestResponse, isRelease: boolean) => {

			const vacationRequestForUpdate = new UpdateVacationRequestRequest({
				...newtVacationRequest,
				isRelease,
			});

			const bindedAction = updateVacationRequestAction.bind(null, vacationRequestForUpdate);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.VACATION_REQUEST, response.value?.id);
				notifications.success(`Vacation request ${id} is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`Change vacation request - ${getFormatedId(EntityPrefixEnum.TRAVEL_EXPENSE, vacationRequest.id)}`}>
				<ColumnContainer margin='medium'>
					<VacationRequestForm
						vacationRequest={vacationRequest}
						crud={CrudEnum.Update}
						onSave={saveCallback}
					/>
					<VacationRequestStatusTable
						statuses={vacationRequest.statuses || []}
					/>
				</ColumnContainer>
			</ContentShell>
		</WithFetch>
	)
}

export default UpdateMyVacationRequest;
