import { useMemo } from 'react';
import { Route } from 'features/Routes/reducer';
import { NodeItem } from './NodeItem';
import styles from '../defaultContent.module.scss'
import { Link } from 'react-router-dom';

type ItemProps = {
	route: Route
	isRoot?: boolean
}

const Item = ({ isRoot, route }: ItemProps) => {
	const content = useMemo(
		() => {
			if (route.children) {
				return <NodeItem route={route}/>
			} else {
				const leafContent = (
					<Link to={route.url}>
						<div className={styles.item}>
							{route.Icon && <route.Icon className={styles.icon} width={24} height={24} fill='currentColor' />}
							<div>{route.text}</div>
						</div>
					</Link>
				)

				if (isRoot) {
					return (
						<div className={styles.node}>
							{leafContent}
						</div>
					)
				} else {
					return leafContent;
				}
			}
		},
		[route, isRoot]
	)

	return (
		<>{content}</>
	)
}

export default Item;
