import { RiskDashboardResponse } from 'services/tenantManagementService'
import { Layout } from 'features/Dashboard/Layout'
import { Dashboard } from 'features/Dashboard/Dashboard';
import { PieWidget } from 'features/Dashboard/Widget/PieWidget';
import { XYWidget } from 'features/Dashboard/Widget/XYWidget';
import { LocalTableWidget } from 'features/Dashboard/Widget/LocalTableWidget';
import { useMemo } from 'react';
import { useTableColumnsMemo } from './tableColumns';
import { useCurrencySuffixMemo } from 'features/Currency/useCurrencySuffixMemo';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

type Props = {
	loading: boolean
	dashboard: RiskDashboardResponse
}

const FilteredDashboard = ({ loading, dashboard }: Props) => {

	const { persistedCurrency, persistedTenant } = useSelector((state: RootState) => state);
	const tableColumns = useTableColumnsMemo(dashboard);
	const currencySymbolSuffix = useCurrencySuffixMemo(persistedCurrency, persistedTenant);

	const mitigationTypeVsGrossImpactDataMemo = useMemo(
		() => {
			return Object.keys(dashboard.grossImpactPerMitigationType || {}).map(item => {
				const name = item;
				return  {
					name: name,
					value: dashboard.grossImpactPerMitigationType![item],
					count: dashboard.grossImpactPerMitigationType![item]
				}
			})
		},
		[dashboard]
	)

	const impactAndCostOverviewDataMemo = useMemo(
		() => {
			const total = [
				{
					name: 'Total Gross Impact',
					value: dashboard.totalGrossImpact
				},
				{
					name: 'Total Gross Impact After Mitigation',
					value: dashboard.totalGrossImpactAfterMitigation
				},
				{
					name: 'Sum of Net Impact',
					value: dashboard.totalNetImpact
				},
				{
					name: 'Total of risk for inclusion in report',
					value: dashboard.totalRiskForInclusion
				}
			]
			return total
		},
		[dashboard]
	)

	const totalRiskForInclusionDataMemo = useMemo(
		() => {
			const data = dashboard.risks?.map(item => {
				return {
					id: item.id,
					name: item.name,
					riskForInclusionInBudget: item.riskForInclusionInBudget,
					currency: currencySymbolSuffix,
				}
			})

			return data || [];
		},
		[dashboard, currencySymbolSuffix]
	)

	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='horizontal'>
				<XYWidget
					className='col-xl-8'
					title='Impact and cost overview'
					id='impact_and_cost_overview_xy_chart'
					data={impactAndCostOverviewDataMemo || []}
					minValue={0}
					maxValue={Math.max(...impactAndCostOverviewDataMemo.map(x => x.value)) || 1}
					loading={loading}
					showValueOnBar
				/>
				<PieWidget
					className='col-xl-4 col-md-6'
					title='Mitigation Type vs. Gross Impact'
					id='mitigation_type_vs_gross_impact_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={mitigationTypeVsGrossImpactDataMemo}
					loading={loading}
				/>
			</Layout>

			<Layout orientation='horizontal'>
				<LocalTableWidget
					className='col-xl-12'
					title='Risks for inclusion in budget'
					columns={tableColumns}
					data={totalRiskForInclusionDataMemo}
					loading={loading}
					hasPagination
					limit={5}
				/>
			</Layout>
		</Dashboard>
	)
}

export default FilteredDashboard
