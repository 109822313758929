import { useCallback, useEffect, useMemo, useState } from 'react';
import { SmartContainer, SmartFormGroup, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ProjectResponse, ProjectTeamMemberResponse, ScheduleDurationEnum, ScheduleResponse, ProjectIsActiveResponse, CalendarResponse, ModuleActivityEnum } from 'services/tenantManagementService';
import { AutoCompleteField, Checkbox, CheckboxField, DateField, Form, InputField, InputNumber, InputNumberField, MultiSelectField, percentDefaultProps, TextareaField } from 'components/Form';
import { propertyOf } from 'utils/propertyOf';
import { emptyArray } from 'utils/commonHelper';
import { ColumnContainer, RowContainer, VerticalSeparator } from 'components/Layout';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { persistTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { getDateIncrementByNumberOfHours, getDateIncrementByNumberOfDays, getDateIncrementByNumberOfMonths, getDateIncrementByNumberOfWeeks, getNumberOfDaysBetweenDates, getNumberOfHoursBetweenDates, getNumberOfMonthsBetweenDates, getNumberOfWeeksBetweenDates, convertTicksToTime, getDateIncrementByNumberOfHoursBasedOnCalendarSettingsWorkTime, getNumberOfHoursBetweenDatesBasedOnCalendarSettingsWorkTime, getNumberOfDaysBetweenDatesWithoutWeekends, getDateIncrementByNumberOfDaysWithoutWeekends, getNumberOfHoursBetweenDatesWithoutWeekends, getDateIncrementByNumberOfHoursWithoutWeekends, getNumberOfHoursBetweenDatesWithoutWeekendsBasedOnCalendarSettingsWorkTime, getDateIncrementByNumberOfHoursWithoutWeekendsBasedOnCalendarSettingsWorkTime } from 'utils/dateTimeUtils';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getCalendarAction } from 'containers/Configuration/SiteConfiguration/General/Calendar/action';
import { ProjectSelect } from 'features/Project';

type Props = {
	task: ScheduleResponse
	project: ProjectResponse
	onSave(item: ScheduleResponse): void
	onCancel(): void
}

export const UpdateTaskForm = ({ task, project, onSave, onCancel }: Props) => {
	const {
		persistedUser,
		persistedProjectRole,
		persistedTeamMember,
		persistedTenant,
		persistedScheduleConfiguration
	} = useSelector((state: RootState) => state);

	const {
		durationIn,
		skipWeekends,
		calculateDayDurationFromSettings,
		autocompleteTaskWhenSubtasksCompleted
	} = persistedScheduleConfiguration.projectMap[project.id]!.value;

	const [values, setValues] = useState(task);
	const [calendarConfigValues, setCalendarConfigValues] = useState(new CalendarResponse());
	const [duration, setDuration] = useState<number>();
	const [isCompleted, setIsCompleted] = useState<boolean>();

	const getUserFullName = useUserFullNameCallback(persistedUser);

	useEffect(
		() => {
			if (!persistedTeamMember.projectMap[project.id]) {
				persistTeamMembersAction(project.id);
			}
		},
		[project.id, persistedTeamMember.projectMap]
	)


	useEffect(
		() => {
			const fetchCalendarData = async () => {
				const bindedAction = getCalendarAction.bind(null, persistedTenant.value.countryId);
				const response = await tryCatchJsonByAction(bindedAction);

				if (response.success && response.value) {
					setCalendarConfigValues(response.value);
				}
			}
			fetchCalendarData();
		},
		[persistedTenant.value.countryId]
	)

	const workTimeInHours = useMemo(
		() => {
			const workTimeInHours = convertTicksToTime(calendarConfigValues?.workTimeTo!).hours - convertTicksToTime(calendarConfigValues?.workTimeFrom!).hours;
			return workTimeInHours;
		},
		[calendarConfigValues?.workTimeTo, calendarConfigValues?.workTimeFrom]
	)

	const onSubmitCallback = useCallback(
		async () => await onSave(values),
		[onSave, values]
	)

	useEffect(
		() => {
			setValues((state: ScheduleResponse) => new ScheduleResponse({
				...state,
				percentOfCompletion: isCompleted ? 100 : task.percentOfCompletion
			}));
		},
		[isCompleted, task]
	)

	useEffect(
		() => {
			let newFinishDate: Date;
			if (!duration) {
				return;
			}

			setValues((state: ScheduleResponse) => {
				if (!state.start) {
					return state;
				}

				if (durationIn === ScheduleDurationEnum.Hours) {
					newFinishDate =
						skipWeekends && calculateDayDurationFromSettings ?
							getDateIncrementByNumberOfHoursWithoutWeekendsBasedOnCalendarSettingsWorkTime(state.start, duration, workTimeInHours) :
						calculateDayDurationFromSettings ?
							getDateIncrementByNumberOfHoursBasedOnCalendarSettingsWorkTime(state.start, duration, workTimeInHours) :
						skipWeekends ?
							getDateIncrementByNumberOfHoursWithoutWeekends(state.start, duration) :
						getDateIncrementByNumberOfHours(state.start, duration)
					;
				} else if (durationIn === ScheduleDurationEnum.Days) {
					newFinishDate = skipWeekends ?
						getDateIncrementByNumberOfDaysWithoutWeekends(state.start, duration) :
						getDateIncrementByNumberOfDays(state.start, duration);
				} else if (durationIn === ScheduleDurationEnum.Months) {
					newFinishDate = getDateIncrementByNumberOfMonths(state.start, duration);
				} else if (durationIn === ScheduleDurationEnum.Weeks) {
					newFinishDate = getDateIncrementByNumberOfWeeks(state.start, duration);
				}

				return new ScheduleResponse({
					...state,
					finish: newFinishDate,
				});
			});
		},
		[duration, workTimeInHours, calculateDayDurationFromSettings, durationIn, skipWeekends]
	)

	useEffect(
		() => {
			if (!values.start || !values.finish || !durationIn) {
				setDuration(undefined);
				return;
			}

			if (durationIn === ScheduleDurationEnum.Hours) {
				skipWeekends && calculateDayDurationFromSettings ?
					setDuration(getNumberOfHoursBetweenDatesWithoutWeekendsBasedOnCalendarSettingsWorkTime(values.start, values.finish, workTimeInHours)) :
				calculateDayDurationFromSettings ?
					setDuration(getNumberOfHoursBetweenDatesBasedOnCalendarSettingsWorkTime(values.start, values.finish, workTimeInHours)) :
				skipWeekends ?
					setDuration(getNumberOfHoursBetweenDatesWithoutWeekends(values.start, values.finish)) :
				setDuration(getNumberOfHoursBetweenDates(values.start, values.finish))
			} else if (durationIn === ScheduleDurationEnum.Days) {
				skipWeekends ?
					setDuration(getNumberOfDaysBetweenDatesWithoutWeekends(values.start, values.finish)) :
					setDuration(getNumberOfDaysBetweenDates(values.start, values.finish));
			} else if (durationIn === ScheduleDurationEnum.Months) {
				setDuration(getNumberOfMonthsBetweenDates(values.start, values.finish));
			} else if (durationIn === ScheduleDurationEnum.Weeks) {
				setDuration(getNumberOfWeeksBetweenDates(values.start, values.finish));
			}
		},
		[values.start, values.finish, durationIn, calculateDayDurationFromSettings, skipWeekends, workTimeInHours]
	)

	const projectRoles = useMemo(
		() => ({
			items: persistedProjectRole.projectMap[project.id]?.items || emptyArray,
			getItemId: (item: ProjectIsActiveResponse) => item.id,
			getItemText: (item: ProjectIsActiveResponse) => item.name,
			loading: persistedProjectRole.projectMap[project.id]?.fetching
		}),
		[project.id, persistedProjectRole]
	)

	const teamMembers = useMemo(
		() => ({
			items: persistedTeamMember.projectMap[project.id]?.items || emptyArray,
			getItemId: (item: ProjectTeamMemberResponse) => item.userId,
			getItemText: (item: ProjectTeamMemberResponse) => getUserFullName(item.userId),
			loading: persistedTeamMember.projectMap[project.id]?.fetching
		}),
		[project.id, persistedTeamMember, getUserFullName]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={onCancel}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<ProjectSelect
								value={project.id}
								disabled
								moduleEnum={ModuleActivityEnum.Schedule}
							/>
							<VerticalSeparator margin='medium' />
							<InputField
								id={propertyOf<ScheduleResponse>('wbs')}
								label='WBS code'
								disabled
							/>
							<InputField
								id={propertyOf<ScheduleResponse>('name')}
								label='Task name'
								isRequired
								maxLength={240}
								focus
							/>
							<VerticalSeparator margin='medium' />
							<DateField
								id={propertyOf<ScheduleResponse>('start')}
								label='Task start'
								dateFormat='MM/dd/yyyy h:mm aa'
								showTime
								isRequired
							/>
							<SmartFormGroup label='Duration'>
								<InputNumber
									value={duration}
									suffix={` ${durationIn}`}
									onChange={setDuration}
								/>
							</SmartFormGroup>
							<DateField
								id={propertyOf<ScheduleResponse>('finish')}
								label='Task Finish'
								dateFormat='MM/dd/yyyy h:mm aa'
								showTime
								isRequired
							/>
							<SmartFormGroup label='Percent of completion'>
								<SmartInline>
									<InputNumberField
										id={propertyOf<ScheduleResponse>('percentOfCompletion')}
										{...percentDefaultProps}
										decimalScale={0}
										min={0}
										max={100}
									/>
									<Checkbox
										labelBefore='Completed'
										value={values.percentOfCompletion === 100}
										onChange={setIsCompleted}
									/>
								</SmartInline>
							</SmartFormGroup>
							{autocompleteTaskWhenSubtasksCompleted &&
								<InputNumberField
									id={propertyOf<ScheduleResponse>('percentOfTaskCompletion')}
									label='Percent of task completion'
									{...percentDefaultProps}
									decimalScale={0}
									min={0}
									max={100}
									disabled
								/>
							}
							<DateField
								id={propertyOf<ScheduleResponse>('achievedDate')}
								label='Achieved date'
							/>
							<VerticalSeparator margin='medium' />
							<RowContainer justifyContent='right'>
								<div style={{ padding: '10px 60px' }}>
									Project role:
								</div>
								<div style={{ padding: '10px 30px' }}>
									Project members (users):
								</div>
							</RowContainer>
							<SmartFormGroup label='Responsible'>
								<SmartInline>
									<AutoCompleteField
										id={propertyOf<ScheduleResponse>('projectRoleResponsibleId')}
										{...projectRoles}
									/>
									<MultiSelectField
										id={propertyOf<ScheduleResponse>('responsibleUserIds')}
										{...teamMembers}
									/>
								</SmartInline>
								<CheckboxField
									id={propertyOf<ScheduleResponse>('addAsTaskPerResponsibleUser')}
									labelBefore='Add as task per responsible users'
								/>
							</SmartFormGroup>
							<SmartFormGroup label='Workload'>
								<SmartInline>
									<InputNumberField
										id={propertyOf<ScheduleResponse>('workloadForResponsibleUsers')}
										{...percentDefaultProps}
										min={0}
										max={100}
									/>
									<div>Workload for responsible user/s'</div>
								</SmartInline>
							</SmartFormGroup>
							<SmartFormGroup label='Accountable'>
								<SmartInline>
									<AutoCompleteField
										id={propertyOf<ScheduleResponse>('projectRoleAccountableId')}
										{...projectRoles}
									/>
									<MultiSelectField
										id={propertyOf<ScheduleResponse>('accountableUserIds')}
										{...teamMembers}
									/>
								</SmartInline>
							</SmartFormGroup>
							<SmartFormGroup label='Consulted'>
								<SmartInline>
									<AutoCompleteField
										id={propertyOf<ScheduleResponse>('projectRoleConsultedId')}
										{...projectRoles}
									/>
									<MultiSelectField
										id={propertyOf<ScheduleResponse>('consultedUserIds')}
										{...teamMembers}
									/>
								</SmartInline>
							</SmartFormGroup>
							<SmartFormGroup label='Informed'>
								<SmartInline>
									<AutoCompleteField
										id={propertyOf<ScheduleResponse>('projectRoleInformedId')}
										{...projectRoles}
									/>
									<MultiSelectField
										id={propertyOf<ScheduleResponse>('informedUserIds')}
										{...teamMembers}
									/>
								</SmartInline>
							</SmartFormGroup>
						</SmartItem>
						<SmartItem>
							<CheckboxField
								id={propertyOf<ScheduleResponse>('markAsMilestone')}
								label='Mark as milestone'
							/>
							<CheckboxField
								id={propertyOf<ScheduleResponse>('markAsDeliverable')}
								label='Mark as deliverable'
							/>
							<TextareaField
								id={propertyOf<ScheduleResponse>('description')}
								label='Deliverable description'
							/>
							<CheckboxField
								id={propertyOf<ScheduleResponse>('markTaskForQualityGate')}
								label='Mark task for Quality gate'
							/>
							<VerticalSeparator margin='large' />
							<InputField
								id={propertyOf<ScheduleResponse>('customText1')}
								label='Text field 1'
							/>
							<InputField
								id={propertyOf<ScheduleResponse>('customText2')}
								label='Text field 2'
							/>
							<InputField
								id={propertyOf<ScheduleResponse>('customText3')}
								label='Text field 3'
							/>
							<InputField
								id={propertyOf<ScheduleResponse>('customText4')}
								label='Text field 4'
							/>
							<InputField
								id={propertyOf<ScheduleResponse>('customText5')}
								label='Text field 5'
							/>
							<InputField
								id={propertyOf<ScheduleResponse>('customText6')}
								label='Text field 6'
							/>
							<InputField
								id={propertyOf<ScheduleResponse>('customText7')}
								label='Text field 7'
							/>
							<InputField
								id={propertyOf<ScheduleResponse>('customText8')}
								label='Text field 8'
							/>
							<InputField
								id={propertyOf<ScheduleResponse>('customText9')}
								label='Text field 9'
							/>
							<InputField
								id={propertyOf<ScheduleResponse>('customText10')}
								label='Text field 10'
							/>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}
