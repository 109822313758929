import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';
import { getProjectTypesAction, updateProjectTypesAction } from './action';

export const ProjectTypes = () => {
	return (
		<TenantIsActiveTable
			getConfigsAction={getProjectTypesAction}
			updateConfigsAction={updateProjectTypesAction}
			successMessage='Project types are updated.'
			label={{ name: 'Type name' }}
		/>
	)
};
