import { ProjectQualityClient, InsertProjectQualityRequest, UpdateProjectQualityRequest, GenericFilterModelCollection, ExportDataModel } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getQualitiesAction = (reportId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectQualityClient();
	return client.getAll(tenantId, reportId, undefined, undefined, undefined);
}

export const getQualitiesGenericAction = (reportId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectQualityClient();
	return client.getAllGenericFilter(tenantId, reportId, genericFilter);
}

export const createQualityAction = (reportId: number, model: InsertProjectQualityRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectQualityClient();
	return client.create(tenantId, reportId, model);
}

export const updateQualityAction = (reportId: number, model: UpdateProjectQualityRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectQualityClient();
	return client.update(tenantId, reportId, model);
}

export const getQualityAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectQualityClient();
	return client.get(tenantId, reportId, id);
}

export const deleteQualityAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectQualityClient();
	return client.delete(tenantId, reportId, id);
}

export const exportAction = (reportId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectQualityClient();
	return client.export(tenantId, reportId, exportDataModel);
}
