import { Dialog } from 'components/Dialog';
import { SurveyReducedResponse, SurveyTypeEnum} from 'services/tenantManagementService';
import CreateSurveyForm from '../CreateSurveyForm';

type Props = {
	open: boolean
	cancel(): void
	surveyToClone?: SurveyReducedResponse | undefined
	onSave?: (survey: SurveyReducedResponse) => void
	renderClonePicker?: () => JSX.Element,
}

export const CopyModalForm = ({ open = false, cancel, surveyToClone, onSave, renderClonePicker }: Props) => {
	const title = surveyToClone?.surveyType === SurveyTypeEnum.Quiz ? 'Copy quiz' : 'Copy survey';

	return (
		<Dialog
			open={open}
			size='xlarge'
			title={title}
			onClose={cancel}
		>
			<CreateSurveyForm
				surveyToClone={surveyToClone}
				renderClonePicker={renderClonePicker}
				onSave={onSave}
				cancel={cancel}
			/>
		</Dialog>
	);
};
