import { HelpShell } from 'features/Help/HelpShell';

export const MySurveysHelp = () => {
	return (
		<HelpShell title='My survey/quiz'>
			<p>My Surveys are showing all project surveys assigned to user.</p>
			<p>Here, users can see status of his/hers surveys, review surveys and complete remaining surveys. <br />
				Based on status, reports are generated for project managers.
			</p>
		</HelpShell>
	)
}
