import path from 'path';
import { useCallback, useMemo } from 'react';
import notifications from 'components/Notification/notification';
import { TicketForm } from './TicketForm';
import { InsertTicketRequest, InsertTicketTimesheetRequest, InsertTicketTimesheetRequestUpdateTicketTimesheetRequestInt32DeltaModel, Int32Int32Int32DeltaModel, TicketResponse, TicketTimesheetResponse, UpdateTicketTimesheetRequest } from 'services/tenantManagementService';
import { createTicketAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getUserInfo } from 'utils/storageUtils';
import { createDelta, EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { ContentShell } from 'features/Content/ContentShell';
import { RootRouteEnum } from 'features/Routes';
import { ChangeRequestSubroutes } from 'containers/Scope/ChangeRequests/Crud/Tabs/ChangeRequestSubroutes';
import { useHistory } from 'react-router';
import { ScopeSubRoutesEnum } from 'containers/Scope/routes';

export const CreateTicket = ({ publishDataChanged }: CreateComponentProps) => {
	const history = useHistory();
	const goBackFromCreate = useGoBackFromCreate();

	// TODO: Error handling for testplans delta
	const handleSave = useCallback(
		async (newTicket: TicketResponse, isInitiateChangeRequest?: boolean) => {
			const timesheetsDelta = createDelta<TicketTimesheetResponse>
			(
				[],
				newTicket.timesheets || [],
				InsertTicketTimesheetRequest,
				UpdateTicketTimesheetRequest,
				InsertTicketTimesheetRequestUpdateTicketTimesheetRequestInt32DeltaModel,
				Number
			);

			const model = new InsertTicketRequest({
				...newTicket,
				newAttachments: (newTicket as InsertTicketRequest).newAttachments,
				testPlanIdsDelta: new Int32Int32Int32DeltaModel({
					insert: newTicket.testPlans?.map(tp => tp.testPlanId)
				}),
				changeRequestIdsDelta: new Int32Int32Int32DeltaModel({
					insert: newTicket.changeRequestIds
				}),
				timesheetsDelta
			});
			const bindedAction = createTicketAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				const id = getFormatedId(EntityPrefixEnum.TICKET, response.value.id);
				notifications.success(`Ticket ${id} is created.`);
				if (isInitiateChangeRequest) {
					history.push(path.join('/',RootRouteEnum.SCOPE,  ScopeSubRoutesEnum.CHANGE_REQUESTS, response.value.projectId.toString(), CrudEnum.Create, ChangeRequestSubroutes.PROBLEM_REPORT), { ticket: response.value });
				} else {
					publishDataChanged();
					goBackFromCreate();
				}
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged, history]
	);

	const newTicket = useMemo(
		() => {
			const model = new TicketResponse();
			const userInfo = getUserInfo();
			model.createdByUserId = userInfo.id;
			return model;
		},
		[]
	)

	return (
		<ContentShell title='Create ticket' >
			<TicketForm
				ticket={newTicket}
				crud={CrudEnum.Create}
				onSave={handleSave}
			/>
		</ContentShell>
	)
}
