import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectSubcontractorResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getSubcontractorsPerformanceAction, updateSubcontractorsPerformanceAction } from '../action';
import { SubcontractorPerformanceForm } from '../SubcontractorPerformanceForm';
import WithFetch from 'features/Fetch/WithFetch';
import { Dialog } from 'components/Dialog';
import { publishSubcontractorPerformancesChanged } from '../subcontractorPerformanceChanged';
import { ReportComponentParams } from '../../Table/TableButtons';
import { useGoBackFromUpdate } from 'features/Crud';

type ParamType = ReportComponentParams & {
	subcontractorPerformanceId: string;
}

export const UpdateSubContractorPerformance = () => {
	const params: ParamType = useParams();
	const reportId = parseInt(params.reportId);
	const subcontractorPerformanceId = parseInt(params.subcontractorPerformanceId);

	const [subcontractorPerformance, setSubContractorPerformance] = useState(new ProjectSubcontractorResponse ());

	const goBackFromUpdate = useGoBackFromUpdate(true);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getSubcontractorsPerformanceAction.bind(null, reportId, subcontractorPerformanceId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setSubContractorPerformance(response.value || new ProjectSubcontractorResponse ());
			}
		},
		[reportId, subcontractorPerformanceId]
	)

	const onSubmitCallback = useCallback(
		async (subcontractorPerformance: ProjectSubcontractorResponse) => {
			const bindedAction = updateSubcontractorsPerformanceAction.bind(null, reportId, subcontractorPerformance);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Subcontractor\'s performance successfully updated');
				goBackFromUpdate();
				publishSubcontractorPerformancesChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, reportId]
	);

	return (
		<Dialog
			title='Update Subcontractor performance'
			onClose={goBackFromUpdate}
			open
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<SubcontractorPerformanceForm performance={subcontractorPerformance} save={onSubmitCallback} cancel={goBackFromUpdate} />
			</WithFetch>
		</Dialog>
	)
};
