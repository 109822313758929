import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ColumnContainer } from 'components/Layout';
import notifications from 'components/Notification/notification';
import { CrudEnum } from 'features/Crud';
import WithFetch from 'features/Fetch/WithFetch';
import { Int32Int32Int32DeltaModel, TestPlanResponse, TestStatusEnum, UpdateInnerTestPlanRequest, UpdateTestPlanRequest, TicketTestPlansResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTestPlanAction, updateTestPlanAction } from './action';
import { MyTestForm } from './MyTestForm';
import { SimpleDialog } from 'components/Dialog';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { RootState } from 'base/reducer/reducer';
import { FailedTestModal}  from './FailedTestModal';
import Export from './Export/Export';
import { TestCyclePickerParams } from 'features/Testing/WithTestCyclePicker';
import { getCreateTicketRoute } from 'containers/Tickets/utils';

type ParamType = TestCyclePickerParams & {
	testPlanId: string
}

type Props = {
	crud: CrudEnum
	publishDataChanged(): void
}

export const MyTest = ({ crud, publishDataChanged }: Props) => {
	const history = useHistory();
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId as string);
	const testCycleId = parseInt(params.testCycleId as string);
	const testPlanId = parseInt(params.testPlanId);

	const [testPlan, setTestPlan] = useState(new TestPlanResponse());
	const [isSimpleModalOpen, setIsSimpleModalOpen] = useState(false);
	const [isFailedTestModalOpen, setIsFailedTestModalOpen] = useState(false);
	const [savingTestPlan, setSavingTestPlan] = useState(new TestPlanResponse());

	const { persistedUser, persistedTestCaseStatus } = useSelector((state: RootState) => state);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const fetchTestPlanCallback = useCallback(
		async () => {
			const bindedAction = getTestPlanAction.bind(null, projectId, testCycleId, testPlanId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				const testPlanResponse = response.value;
				setTestPlan(testPlanResponse);
			}
		},
		[testPlanId, projectId, testCycleId]
	)

	const saveConfirmCallback = useCallback(
		async (newTestPlan: TestPlanResponse) => {

			const updateTestPlanRequest = new UpdateTestPlanRequest({
				...newTestPlan,
				id: newTestPlan.id,
				processStatusId: newTestPlan.processStatusId,
				comments: newTestPlan.comments,
				ticketIdsDelta: new Int32Int32Int32DeltaModel({
					insert: newTestPlan.ticketIds?.filter(ticketId => !testPlan.ticketIds?.includes(ticketId)),
					delete: testPlan.ticketIds?.filter(ticketId => !newTestPlan!.ticketIds?.includes(ticketId)),
				}),
				innerTestPlans: newTestPlan.innerTestPlans?.map(itp => new UpdateInnerTestPlanRequest({
					id: itp.id,
					expectedResults: itp.stepExpectedResults,
					rejectionComment: itp.stepRejectionComment,
					stepStatusId: itp.stepStatusId,
					comment: itp.stepComment,
					documentsCreated: itp.stepDocumentsCreated,
				}))
			});
			const bindedAction = updateTestPlanAction.bind(null, projectId, testCycleId, updateTestPlanRequest);
			setIsSubmitting(true);
			const response = await tryCatchJsonByAction(bindedAction);
			setIsSubmitting(false);

			setIsFailedTestModalOpen(false);

			if (response.success) {
				setTestPlan(response.value!);
				notifications.success('Test case is updated.');
				publishDataChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[projectId, testCycleId, testPlan, publishDataChanged]
	)

	const saveCallback = useCallback(
		async (newTestPlan: TestPlanResponse) => {
			const isPassedProcess = isStatusBySemantic(TestStatusEnum.Passed, newTestPlan.processStatusId, persistedTestCaseStatus.itemsMap);
			const notPassedSteps = newTestPlan.innerTestPlans?.filter(itp => !isStatusBySemantic(TestStatusEnum.Passed, itp.stepStatusId, persistedTestCaseStatus.itemsMap));

			const isFailedProcess = isStatusBySemantic(TestStatusEnum.Failed, newTestPlan.processStatusId, persistedTestCaseStatus.itemsMap);
			const failedSteps = newTestPlan.innerTestPlans?.filter(itp => isStatusBySemantic(TestStatusEnum.Failed, itp.stepStatusId, persistedTestCaseStatus.itemsMap));

			if (isPassedProcess && notPassedSteps && notPassedSteps.length > 0) {
				setIsSimpleModalOpen(true);
				setSavingTestPlan(newTestPlan);
			} else if ((isFailedProcess || (failedSteps && failedSteps.length > 0))) {
				setIsFailedTestModalOpen(true);
				setSavingTestPlan(newTestPlan);
			} else {
				saveConfirmCallback(newTestPlan);
			}
		},
		[persistedTestCaseStatus.itemsMap, saveConfirmCallback]
	)

	const saveAndCreateTicketCallback = useCallback(
		async (newTestPlan: TestPlanResponse) => {
			await saveConfirmCallback(newTestPlan);
			const failedTestPlans = [
				new TicketTestPlansResponse({
					testPlanId: newTestPlan.id,
					testCycleId: newTestPlan.testCycleId,
					level3ProcessId: newTestPlan.level3ProcessId,
					// Mika: seems like testCycleName is not used, so commented it out
					// testCycleName: state.testCycle.name,
					projectId: projectId
				})
			]
			history.push(getCreateTicketRoute(), { failedTestPlans, projectId });
		},
		[saveConfirmCallback, history, projectId]
	)

	const confirmInvalidStateSaveCallback = useCallback(
		() => {
			setIsSimpleModalOpen(false);
			const isFailedProcess = isStatusBySemantic(TestStatusEnum.Failed, savingTestPlan.processStatusId, persistedTestCaseStatus.itemsMap);
			const failedSteps = savingTestPlan.innerTestPlans?.filter(itp => isStatusBySemantic(TestStatusEnum.Failed, itp.stepStatusId, persistedTestCaseStatus.itemsMap));

			if ((isFailedProcess || (failedSteps && failedSteps.length > 0))) {
				setIsFailedTestModalOpen(true);
			} else {
				saveConfirmCallback(savingTestPlan);
			}
		},
		[persistedTestCaseStatus.itemsMap, saveConfirmCallback, savingTestPlan]
	)

	const closeModalCallback = useCallback(
		() => {
			setIsSimpleModalOpen(false);
			setIsFailedTestModalOpen(false);
		},
		[]
	)

	return (
		<WithFetch fetchFunction={fetchTestPlanCallback}>
			<ColumnContainer>
				<Export
					persistedUser={persistedUser}
					persistedTestCaseStatus={persistedTestCaseStatus}
					projectId={projectId}
					testCycleId={testCycleId}
					testPlan={testPlan}
					loading={isSubmitting}
				/>
				<MyTestForm
					projectId={projectId}
					testPlan={testPlan}
					onSave={saveCallback}
					crud={crud}
					isSubmitting={isSubmitting}
				/>
				<SimpleDialog
					open={isSimpleModalOpen}
					title='Save'
					message='Not all test steps passed. Are you sure?'
					onConfirm={confirmInvalidStateSaveCallback}
					onCancel={closeModalCallback}
				/>
				<FailedTestModal
					open={isFailedTestModalOpen}
					cancel={closeModalCallback}
					testPlan={savingTestPlan}
					saveOnly={saveConfirmCallback}
					saveAndCreateTicket={saveAndCreateTicketCallback}
				/>
			</ColumnContainer>
		</WithFetch>
	)
}
