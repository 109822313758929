import { useEffect } from "react";
import { tryCatchJsonByAction } from "utils/fetchUtils";
import { getRefreshTokenAction, refreshTokenWithTokenAction } from "./action";
import { setToken } from "./token";

const refreshToken = async () => {
	// get refresh token
	const getRefreshTokenResponse = await tryCatchJsonByAction(getRefreshTokenAction);
	if (getRefreshTokenResponse.success) {
		const refreshToken = getRefreshTokenResponse.refreshToken || '';

		// refresh it and store it in local storage
		const bindedAction = refreshTokenWithTokenAction.bind(null, refreshToken)
		const refreshTokenResponse = await tryCatchJsonByAction(bindedAction);
		if (refreshTokenResponse.success) {
			setToken(refreshTokenResponse.token || '');
		}
	}
}

export const useRefreshTokenIntervalEffect = () => {
	useEffect(
		() => {
			const refreshTokenInterval = 30 * 60 * 1000; // 30min

			const interval = setInterval(
				() => {
					refreshToken();
				},
				refreshTokenInterval
			);

			return () => {
				clearInterval(interval);
			}
		},
		[]
	)
}
