import { ContentShell } from 'features/Content/ContentShell';
import { CrudEnum, UpdateComponentProps } from 'features/Crud';
import { MyTestPackagesHelp } from '../Help/MyTestPackagesHelp';
import { MyTest } from '../MyTest/MyTest';

const UpdateMyTest = ({ publishDataChanged }: UpdateComponentProps) => {
	return (
		<ContentShell
			title='Update test case'
			FloatingHelpComponent={MyTestPackagesHelp}
		>
			<MyTest
				crud={CrudEnum.Update}
				publishDataChanged={publishDataChanged}
			/>
		</ContentShell>
	)
}

export default UpdateMyTest;
