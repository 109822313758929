import { useMemo } from 'react';
import { TableColumnsType, useVisibleColumns } from 'components/Table';
import { InnerTestPlanResponse, TestStatusResponse, UserModel } from 'services/tenantManagementService';
import { useApplyStatusColorCallback, useStatusCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { CrudEnum } from 'features/Crud';
import { FieldTypeEnum } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const visibleColumns = [
	propertyOf<InnerTestPlanResponse>('processStepId'),
	propertyOf<InnerTestPlanResponse>('processStepName'),
	propertyOf<InnerTestPlanResponse>('stepExpectedResults'),
	propertyOf<InnerTestPlanResponse>('stepDocumentsCreated'),
	propertyOf<InnerTestPlanResponse>('stepComment'),
	propertyOf<InnerTestPlanResponse>('stepStatusId'),
	propertyOf<InnerTestPlanResponse>('stepRejectionComment'),
	propertyOf<InnerTestPlanResponse>('stepTesterUserId'),
]

export const useTableColumnsMemo = (
	crud: CrudEnum
) => {
	const {
		persistedTestCaseStatus,
		persistedUser
	} = useSelector((state: RootState) => state);
	
	const getStatusName = useStatusCallback(persistedTestCaseStatus);
	const applyTestStatusColor = useApplyStatusColorCallback(persistedTestCaseStatus);
	const getUserFullName = useUserFullNameCallback(persistedUser);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<InnerTestPlanResponse>('processStepId')]: {
					title: 'Level 4 ID',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<InnerTestPlanResponse>('processStepName')]: {
					title: 'Process Step/Transaction',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<InnerTestPlanResponse>('stepExpectedResults')]: {
					title: 'Expected result',
					fieldType: FieldTypeEnum.String,
					editor: crud === CrudEnum.Update ? 'input': false
				},
				[propertyOf<InnerTestPlanResponse>('stepDocumentsCreated')]: {
					title: 'Document created',
					fieldType: FieldTypeEnum.String,
					editor: crud === CrudEnum.Update ? 'input': false
				},
				[propertyOf<InnerTestPlanResponse>('stepComment')]: {
					title: 'Comment',
					fieldType: FieldTypeEnum.String,
					editor: crud === CrudEnum.Update ? 'input': false
				},
				[propertyOf<InnerTestPlanResponse>('stepStatusId')]: {
					title: 'Step status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyTestStatusColor(id, cell.getElement());
						return getStatusName(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTestCaseStatus.items,
					getItemId: (option: TestStatusResponse) => option.id,
					getItemText: (option: TestStatusResponse) => option.name,
					editor: crud === CrudEnum.Update ? 'list' : false,
					editorParams: {
						values: persistedTestCaseStatus.items.map(status => {
							return { label: getStatusName(status.id), value: status.id };
						})
					}
				},
				[propertyOf<InnerTestPlanResponse>('stepRejectionComment')]: {
					title: 'Rejection comment',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<InnerTestPlanResponse>('stepTesterUserId')]: {
					title: 'Step tester',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				}
			}
		},
		[getStatusName, applyTestStatusColor, persistedTestCaseStatus.items, crud, getUserFullName, persistedUser]
	)

	return useVisibleColumns(columns, visibleColumns);
}
