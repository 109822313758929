import { TenantClient, TenantResponseModelSimpleResponseModel, TenantUpdateRequestModel } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';

export const getTenantAction = (): Promise<TenantResponseModelSimpleResponseModel> => {
	const state = store.getState();

	if (state.persistedTenant.isInitialized && state.persistedTenant.value) {
		const response = new TenantResponseModelSimpleResponseModel({
			success: true,
			value: state.persistedTenant.value
		});
		return Promise.resolve(response);
	}

	const tenantId = getTenantId()!;
	const client = new TenantClient();
	return client.getTenant(tenantId);
}

export const updateTenantAction = (tenant: TenantUpdateRequestModel) => {
	const client = new TenantClient();
	return client.update(tenant.id, tenant);
}
