import { useCallback, useMemo, useState } from 'react';
import { DeleteTenantIndetifyRequest, InsertNonProjectCategoryRequest, NonProjectCategoryDeltaRequest, NonProjectCategoryResponse, UpdateNonProjectCategoryRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { ContentShell } from 'features/Content/ContentShell'
import { NonProjectCategoryHelp as SetUpCategoryHelp } from './NonProjectCategoryHelp'
import { getNonProjectCategoriesAction, updateNonProjectCategoriesAction } from './action';
import { generateNumberId } from 'base/id';
import { createDelta, unpackDelta } from 'utils/commonHelper';
import notifications from 'components/Notification/notification';
import WithFetch from 'features/Fetch/WithFetch';
import { TableField, CheckboxField, Form, InputField } from 'components/Form';
import { ColumnContainer } from 'components/Layout';
import Button from 'components/Button';
import { propertyOf } from 'utils/propertyOf';

type TableFieldObject = {
	categories: NonProjectCategoryResponse[]
}

export const NonProjectCategory = () => {
	const [values, setValues] = useState<TableFieldObject>({ categories: [] });
	const [initialValues, setInitialValues] = useState<TableFieldObject>({ categories: [] });
	const [refetching, setRefetching] = useState(false);

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getNonProjectCategoriesAction);
			if (response.success) {
				const items = response.items || [];
				const values = { categories: items };
				setValues(values);
				setInitialValues(values);
			}
		},
		[]
	);

	const refetchDataCallback = useCallback(
		async () => {
			setRefetching(true);
			await fetchDataCallback();
			setRefetching(false);
		},
		[fetchDataCallback]
	);

	const addCallback = useCallback(
		() => {
			const id = generateNumberId();

			setValues((state: TableFieldObject) => {
				const newModel = new NonProjectCategoryResponse({
					id,
				    name: '',
				    description: '',
					isActive: true,
					billable: false,
					isReadOnly: false
				});

				const newState = { ...state };
				newState.categories = [...newState.categories, newModel];

				return newState;
			})
		},
		[]
	);

	const handleCancel = useCallback(
		() => setValues(initialValues),
		[initialValues]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const delta = createDelta<NonProjectCategoryResponse>
			(
				initialValues.categories,
				values.categories,
				InsertNonProjectCategoryRequest,
				UpdateNonProjectCategoryRequest,
				NonProjectCategoryDeltaRequest,
				DeleteTenantIndetifyRequest
			);

			const bindedAction = updateNonProjectCategoriesAction.bind(null, delta)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Non project categories are updated.');
				refetchDataCallback();
				setInitialValues(values);
			} else {
				const errors = convertResponseErrors(response)
				const [categoriesErrors, categoriesGroups] = unpackDelta(errors, delta, values.categories, initialValues.categories);
				setValues({...values, categories: categoriesGroups});
				return { groups: categoriesErrors };
			}
		},
		[initialValues, values, refetchDataCallback]
	)

	const uniqueFieldNames = useMemo(
		() => [{id: 'name', label: 'Non project category'}],
		[]
	)

	return (
		<ContentShell
			InlineHelpComponent={SetUpCategoryHelp}
			showBreadcrumb={false}
		>
			<WithFetch fetchFunction={fetchDataCallback} refetching={refetching}>
				<Form
					values={values}
					initialValues={initialValues}
					onChange={setValues}
					onSubmit={onSubmitCallback}
					onCancel={handleCancel}
					render={() => (
						<ColumnContainer margin='medium'>
							<Button
								onClick={addCallback}
								text='Add'
							/>
							<TableField
								id={propertyOf<TableFieldObject>('categories')}
								uniqueFieldNames={uniqueFieldNames}
								headers={[
									{ label: 'Active', size: 1 },
									{ label: 'Non project category', size: 3, isRequired: true },
									{ label: 'Explanation', size: 6 },
									{ label: 'Productive', size: 1 }
								]}
								getRowData={(category: NonProjectCategoryResponse) => {
									return {
										isDeletable: !category.isReadOnly,
										fields: [
											<CheckboxField
												id={propertyOf<NonProjectCategoryResponse>('isActive')}
												disabled={category.isReadOnly}
											/>,
											<InputField
												id={propertyOf<NonProjectCategoryResponse>('name')}
												isRequired
												disabled={category.isReadOnly}
												maxLength={25}
											/>,
											<InputField
												id={propertyOf<NonProjectCategoryResponse>('description')}
												disabled={category.isReadOnly}
											/>,
											<CheckboxField
												id={propertyOf<NonProjectCategoryResponse>('billable')}
												disabled={category.isReadOnly}
											/>,
										]
									}
								}}
							/>
						</ColumnContainer>
					)}
				/>
			</WithFetch>
		</ContentShell>
	)
}
