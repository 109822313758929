import { ArrowDownIcon } from 'components/icons/icons';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MultiSelect as MultiSelectLib, Option } from 'react-multi-select-component';
import { ControlsCommonProps } from '../../fields';
import styles from './multiSelectLinks.module.scss';
import Spinner, { ClipSpinner } from 'components/Spinner';

export type MultiSelectLinksProps = ControlsCommonProps<Array<string | number>> & {
	items?: any[]
	getItemId(item: any): string | number
	getItemText(item: any): string
	// placeholder?: string // not supported by current library
	loading?: boolean
	onlyOneSelectable?: boolean
	hasSelectAll?: boolean
	getLinkProps: (itemId: string | number) => any
};

export const MultiSelectLinks = (props: MultiSelectLinksProps) => {
	const {
		value = [], onChange,/* onBlur,*/ disabled,
		items = [], getItemId, getItemText, loading,
		onlyOneSelectable, hasSelectAll = true,
		getLinkProps,
	} = props;

	const onChangeCallback = useCallback(
		(dataItems: any[]) => {
			let newItems = dataItems.map((item) => item.value);
			if (onlyOneSelectable && newItems.length > 1) {
				newItems = [newItems[newItems.length - 1]];
			}
			onChange && onChange(newItems);
		},
		[onChange, onlyOneSelectable]
	);

	const itemsToOptionArray = useMemo(
		() => {
			return items.map((item) => {
				return {
					value: getItemId(item),
					label: getItemText(item)
				}
			})
		},
		[items, getItemId, getItemText]
	)

	const valuesToOptionArray = useMemo(
		() => {
			return itemsToOptionArray.filter(option => value.includes(option.value));
		},
		[value, itemsToOptionArray]
	)

	return (
		<div className={`${styles.container} ${disabled || loading ? styles.disabled : ''}`}>
			<MultiSelectLib
				value={valuesToOptionArray}
				options={itemsToOptionArray}
				valueRenderer={(selected: Option[], options: Option[]) => {
					return (
						<div className={styles.valuesContainer}>
							{
								selected.map((value, index) => {
									const linkProps = getLinkProps(value.value);
									return (
										<React.Fragment
											key={`multiSelectLinks_${index}`}
										>
											<Link
												to='#'
												target={'_blank'}
												{...linkProps}
												className={styles.link}
											>
												<p className={styles.linkText}>{value.label}</p>
												{index !== selected.length - 1 && <p>,&nbsp;</p>}
											</Link>
										</React.Fragment>
									)
								})
							}
						</div>
					)
				}}
				onChange={onChangeCallback}
				disabled={disabled || loading}
				disableSearch={false}
				labelledBy=''
				hasSelectAll={hasSelectAll && !onlyOneSelectable}
			/>
			{/* arrow */}
			<div className={styles.arrow}>
				<ArrowDownIcon width={8} height={8} fill='currentColor' />
			</div>

			{/* loading */}
			{loading &&
				<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
					<Spinner>
						<ClipSpinner size={20} />
					</Spinner>
				</div>
			}
		</div>
	)
}
