import { useMemo }  from 'react';
import { ScheduleResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useTenantIsActiveCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';

const defaultVisibleColumns = [
	propertyOf<ScheduleResponse>('wbs'),
	propertyOf<ScheduleResponse>('name'),
	propertyOf<ScheduleResponse>('start'),
	propertyOf<ScheduleResponse>('finish'),
	propertyOf<ScheduleResponse>('percentOfCompletion'),
	propertyOf<ScheduleResponse>('markAsMilestone'),
	propertyOf<ScheduleResponse>('markAsDeliverable'),
	propertyOf<ScheduleResponse>('description'),
];

export const useTableColumnsMemo = (projectId: number, configureViewKey: string) => {
	const {
		persistedUser,
		persistedProjectRole,
		persistedConfigureView,
		persistedScheduleConfiguration
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getProjectRoleName = useTenantIsActiveCallback(persistedProjectRole);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ScheduleResponse>('wbs')]: {
					title: 'WBS',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('name')]: {
					title: 'Task name',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('duration')]: {
					title: 'Duration',
					formatter: (cell: any) => {
						const value: number = cell.getValue();
						const configuration = persistedScheduleConfiguration.projectMap[projectId]!.value;

						return `${value} ${configuration.durationIn}`;
					},
					fieldType: FieldTypeEnum.Number,
					disableFilter: true,
					disableSort: true,
					format: `{0} ${persistedScheduleConfiguration.value.durationIn}`,
				},
				[propertyOf<ScheduleResponse>('start')]: {
					title: 'Start',
					fieldType: FieldTypeEnum.Date,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('finish')]: {
					title: 'Finish',
					fieldType: FieldTypeEnum.Date,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('percentOfCompletion')]: {
					title: '% complete',
					formatter: (cell: any) => {
						const data = cell.getValue();
						return data ? `${data} %` : '';
					},
					fieldType: FieldTypeEnum.Number,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('percentOfTaskCompletion')]: {
					title: '% of tasks completed',
					formatter: (cell: any) => {
						const data = cell.getValue();
						return data ? `${data} %` : '';
					},
					fieldType: FieldTypeEnum.Number,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('achievedDate')]: {
					title: 'Achived date',
					fieldType: FieldTypeEnum.Date,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('markAsMilestone')]: {
					title: 'Milestone',
					fieldType: FieldTypeEnum.Boolean,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('markAsDeliverable')]: {
					title: 'Deliverable',
					fieldType: FieldTypeEnum.Boolean,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('description')]: {
					title: 'Deliverable description',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('markTaskForQualityGate')]: {
					title: 'Quality gate',
					fieldType: FieldTypeEnum.Boolean,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('projectRoleResponsibleId')]: {
					title: 'Responsible role',
					formatter: (cell: any) => getProjectRoleName(cell.getValue()),
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('responsibleUserIds')]: {
					title: 'Responsible member',
					formatter: (cell: any) => {
						const userIds: number[] = cell.getValue();
						return userIds ? userIds.map(userId => getUserFullName(userId)).join(', ') : '';
					},
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('projectRoleAccountableId')]: {
					title: 'Accountable role',
					formatter: (cell: any) => getProjectRoleName(cell.getValue()),
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('accountableUserIds')]: {
					title: 'Accountable member',
					formatter: (cell: any) => {
						const userIds: number[] = cell.getValue();
						return userIds ? userIds.map(userId => getUserFullName(userId)).join(', ') : '';
					},
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('projectRoleConsultedId')]: {
					title: 'Consulted role',
					formatter: (cell: any) => getProjectRoleName(cell.getValue()),
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('consultedUserIds')]: {
					title: 'Consulted member',
					formatter: (cell: any) => {
						const userIds: number[] = cell.getValue();
						return userIds ? userIds.map(userId => getUserFullName(userId)).join(', ') : '';
					},
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('projectRoleInformedId')]: {
					title: 'Informed role',
					formatter: (cell: any) => getProjectRoleName(cell.getValue()),
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('informedUserIds')]: {
					title: 'Informed member',
					formatter: (cell: any) => {
						const userIds: number[] = cell.getValue();
						return userIds ? userIds.map(userId => getUserFullName(userId)).join(', ') : '';
					},
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('addAsTaskPerResponsibleUser')]: {
					title: 'Added task',
					fieldType: FieldTypeEnum.Boolean,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('customText1')]: {
					title: 'Text 1',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('customText2')]: {
					title: 'Text 2',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('customText3')]: {
					title: 'Text 3',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('customText4')]: {
					title: 'Text 4',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('customText5')]: {
					title: 'Text 5',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('customText6')]: {
					title: 'Text 6',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('customText7')]: {
					title: 'Text 7',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('customText8')]: {
					title: 'Text 8',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('customText9')]: {
					title: 'Text 9',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<ScheduleResponse>('customText10')]: {
					title: 'Text 10',
					fieldType: FieldTypeEnum.String,
					disableFilter: true,
					disableSort: true
				}
			};
		},
		[getProjectRoleName, getUserFullName, persistedScheduleConfiguration, projectId]
	)

	return useVisibleColumns(columns, visibleColumns);
}
