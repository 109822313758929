import { ScopeIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";

// subroutes

export enum ScopeSubRoutesEnum {
	MY_DASHBOARD = 'scope_my_dashboard',
	MAINTAIN_SCOPE = 'maintain_scope',
	CHANGE_REQUESTS = 'scope_change_requests',
	INITIATE_CHANGE_REQUEST = 'scope_initiate_change_requests',
	MY_CHANGE_REQUESTS = 'scope_my_change_requests',
}

export const myDashboardSubRoute: Route = {
	id: ScopeSubRoutesEnum.MY_DASHBOARD,
	url: `/${RootRouteEnum.SCOPE}/${ScopeSubRoutesEnum.MY_DASHBOARD}`,
	text: 'My Dashboard',
}

export const maintainScopeSubRoute: Route = {
	id: ScopeSubRoutesEnum.MAINTAIN_SCOPE,
	url: `/${RootRouteEnum.SCOPE}/${ScopeSubRoutesEnum.MAINTAIN_SCOPE}`,
	text: 'Maintain Scope',
}

export const changeRequestsSubRoute: Route = {
	id: ScopeSubRoutesEnum.CHANGE_REQUESTS,
	url: `/${RootRouteEnum.SCOPE}/${ScopeSubRoutesEnum.CHANGE_REQUESTS}`,
	text: 'Change requests',
}

export const initiateChangeRequestSubRoute: Route = {
	id: ScopeSubRoutesEnum.INITIATE_CHANGE_REQUEST,
	url: `/${RootRouteEnum.SCOPE}/${ScopeSubRoutesEnum.INITIATE_CHANGE_REQUEST}`,
	text: 'Initiate change request',
}

export const myChangeRequestsSubRoute: Route = {
	id: ScopeSubRoutesEnum.MY_CHANGE_REQUESTS,
	url: `/${RootRouteEnum.SCOPE}/${ScopeSubRoutesEnum.MY_CHANGE_REQUESTS}`,
	text: 'My change requests',
}

// root route

export const scopeRoute: Route = {
	id: RootRouteEnum.SCOPE,
	url: `/${RootRouteEnum.SCOPE}`,
	text: 'Scope',
	Icon: ScopeIcon,
	children: [
		myDashboardSubRoute,
		maintainScopeSubRoute,
		changeRequestsSubRoute,
		initiateChangeRequestSubRoute,
		myChangeRequestsSubRoute
	]
}
