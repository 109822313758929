import { InsertOrganizationalUnitManagerRequest, OrganizationalUnitManagerClient, UpdateOrganizationalUnitManagerRequest } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';

// api

export const getAllManagerAuthorizationsAction = (userId: number | undefined) => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitManagerClient();
	return client.getAll(tenantId, userId, undefined, undefined, undefined);
}

export const getManagerAuthorizationsAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitManagerClient();
	return client.get(tenantId, id);
}

export const createManagerAuthorizationsAction = (model: InsertOrganizationalUnitManagerRequest) => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitManagerClient();
	return client.create(tenantId, model);
}

export const updateManagerAuthorizationsAction = (model: UpdateOrganizationalUnitManagerRequest) => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitManagerClient();
	return client.update(tenantId, model);
}

export const deleteManagerAuthorizationsAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitManagerClient();
	return client.delete(tenantId, id);
}

export const isUserManagerAtAnyOrganizationalUnitAction = () => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitManagerClient();
	return client.isUserManager(tenantId);
}
