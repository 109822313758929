import { useCallback, useMemo, useState } from 'react';
import styles from 'components/Map/map.module.scss'
import { InsertTicketCategoryRequest, TicketCategoryResponse } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { createTicketCategoryAction } from '../action';
import { CategoryItemForm } from './CategoryItemForm';

type Props = {
	items: TicketCategoryResponse[]
	level: number
	parentId?: number
	fetchData(): Promise<void>
}

export const CreateItem = ({ items, level, parentId, fetchData }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openDialogCallback = useCallback(
		() => setIsModalOpen(true),
		[]
	)

	const closeDialogCallback  = useCallback(
		() => setIsModalOpen(false),
		[]
	)

	const saveCallback = useCallback(
		async (newCategoryItem: TicketCategoryResponse) => {
			const model = new InsertTicketCategoryRequest(newCategoryItem);
			const bindedAction = createTicketCategoryAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`Ticket category item ${model.name} is created`);
				closeDialogCallback();
				await fetchData();
			} else {
				return convertResponseErrors(response);
			}
		},
		[fetchData, closeDialogCallback]
	)

	const newItem = useMemo(
		() => {
			const model = new TicketCategoryResponse();
			model.levelNumber = level;
			model.parentId = parentId;
			model.order = items.length + 1;
			model.isActive = true;
			return model;
		},
		[level, parentId, items]
	)

	return (
		<>
			<div className={styles.item_add} onClick={openDialogCallback}>
				Add
			</div>
			<Dialog
				open={isModalOpen}
				title={`Create category level ${level} item`}
				onClose={closeDialogCallback}
			>
				<CategoryItemForm
					item={newItem}
					onSave={saveCallback}
					onCancel={closeDialogCallback}
				/>
			</Dialog>
		</>
	)
}
