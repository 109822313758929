import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnContainer } from 'components/Layout';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { WithProjectPicker, pmOrSpmPermission } from 'features/Project';
import { GenericFilterModelCollection, ModuleActivityEnum, MyTestPlanResponse, ProjectResponse, TestingPermission } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { ListComponentProps } from 'features/Crud';
import { TestCycleComponentProps, WithTestCyclePicker } from 'features/Testing/WithTestCyclePicker';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { exportMyTestPlansAction, getMyTestPackagesGenericAction, getShowProcessStepsConfigAction, updateShowProcessStepsConfigAction } from './action';
import { defaultProcessStepColumns, useTableColumnsMemo } from './Table/tableColumns';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { ContentShell } from 'features/Content/ContentShell';
import { Checkbox } from 'components/Form';
import TableButtons from './Table/TableButtons';
import { MyTestPackagesHelp } from './Help/MyTestPackagesHelp';
import { RemoteTable } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';

type Props = ListComponentProps & TestCycleComponentProps;

const configureViewKey = 'my_test_packages_table'

const MyTestPackages = ({ project, disabledEdit, testCycle, dataChangedTopic }: Props) => {
	const [selectedTestPlan, setSelectedTestPlan] = useState(new MyTestPlanResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const [showProcessSteps, setShowProcessSteps] = useState<boolean | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { persistedConfigureView } = useSelector((state: RootState) => state);

	const tableColumns = useTableColumnsMemo(
		showProcessSteps,
		persistedConfigureView.value[configureViewKey]
	);

	const fetchShowProcessStepsCallback = useCallback(
		async () => {
			const showProcessStepsResponse = await tryCatchJsonByAction(getShowProcessStepsConfigAction);
			setIsLoading(false);
			if (showProcessStepsResponse.success && showProcessStepsResponse.value) {
				setShowProcessSteps(showProcessStepsResponse.value.content);
			} else {
				//default value
				setShowProcessSteps(false);
			}
		},
		[]
	)

	useEffect(
		() => {
			fetchShowProcessStepsCallback();
		},
		[fetchShowProcessStepsCallback]
	)

	const showProcessStepsChangedCallback = useCallback(
		async (newValue: boolean) => {
			setFiltersModel(new GenericFilterModelCollection());
			setIsLoading(true);

			// change configure view for table on checkbox change
			const visibleColumns = tableColumns.filter(column => column.visible);
			let columnFields: string[] = visibleColumns.map(column => column.field);
			if (newValue) {
				columnFields = [...columnFields, ...defaultProcessStepColumns];
			} else {
				columnFields = columnFields.filter(cf => !defaultProcessStepColumns.includes(cf as keyof MyTestPlanResponse));
			}

			setConfigureViewTableAction(configureViewKey, columnFields);

			// update show process steps configuration
			setShowProcessSteps(newValue);
			const bindedAction = updateShowProcessStepsConfigAction.bind(null, newValue);

			await tryCatchJsonByAction(bindedAction);
			setIsLoading(false);
		},
		[tableColumns]
	)

	const handleRowSelectionChange = useCallback(
		(data: MyTestPlanResponse[]) => {
			setSelectedTestPlan(data[0] || new MyTestPlanResponse());
		},
		[]
	);

	// just a quick fix, memoFetchFunction was changed after project is changed and unneeded request was send
	const [currentProject, setCurrentProject] = useState(new ProjectResponse(project));
	useEffect(
		() => {
			setCurrentProject(new ProjectResponse(project));
		},
		[project]
	)

	const memoFetchFunction = useMemo(
		() => getMyTestPackagesGenericAction.bind(null, currentProject.id, testCycle.id, !!showProcessSteps),
		[currentProject.id, testCycle.id, showProcessSteps]
	)

	const memoExportFunction = useMemo(
		() => exportMyTestPlansAction.bind(null, currentProject.id, testCycle.id, !!showProcessSteps),
		[currentProject.id, testCycle.id, showProcessSteps]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='My test packages'
			FloatingHelpComponent={MyTestPackagesHelp}
		>
			<>
				{showProcessSteps !== undefined && (
					<ColumnContainer margin='medium'>
						<SmartContainer>
							<SmartItem>
								<SmartFormGroup label='Show process steps'>
									<Checkbox
										value={showProcessSteps}
										onChange={showProcessStepsChangedCallback}
										disabled={isLoading}
									/>
								</SmartFormGroup>
							</SmartItem>
						</SmartContainer>
						<TableButtons
							selectedId={selectedTestPlan.id}
							configureViewKey={configureViewKey}
							tableColumns={tableColumns}
							isEditable={!disabledEdit}
							filtersModel={filtersModel}
							exportFunction={memoExportFunction}
						/>
						<RemoteTable
							columns={tableColumns}
							filtersModel={filtersModel}
							filtersModelChanged={setFiltersModel}
							subscriptionTopic={dataChangedTopic}
							reorderColumns={reorderColumnsCallback}
							fetchFunction={memoFetchFunction}
							rowSelectionChanged={handleRowSelectionChange}
						/>
					</ColumnContainer>
				)}
			</>
		</ContentShell>
	)
}


const withTestCycle = WithTestCyclePicker(MyTestPackages);
const withProject = WithProjectPicker(withTestCycle, ModuleActivityEnum.Testing, false, propertyOf<TestingPermission>('myTestPackages'), pmOrSpmPermission);

export default withProject;
