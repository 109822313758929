import { ExportDataModel, GenericFilterModelCollection, InsertProjectMilestoneRequest , ProjectMilestoneClient, UpdateProjectMilestoneRequest  } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getMilestonesAction = (reportId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectMilestoneClient();
	return client.getAll(tenantId, reportId, undefined, undefined);
}

export const getMilestonesGenericAction = (reportId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectMilestoneClient();
	return client.getAllGenericFilter(tenantId, reportId, genericFilter);
}

export const createMilestoneAction = (reportId: number, model: InsertProjectMilestoneRequest ) => {
	const tenantId = getTenantId()!;
	const client = new ProjectMilestoneClient();
	return client.create(tenantId, reportId, model);
}

export const updateMilestoneAction = (reportId: number, model: UpdateProjectMilestoneRequest ) => {
	const tenantId = getTenantId()!;
	const client = new ProjectMilestoneClient();
	return client.update(tenantId, reportId, model);
}

export const getMilestoneAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectMilestoneClient();
	return client.get(tenantId, reportId, id);
}

export const deleteMilestoneAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectMilestoneClient();
	return client.delete(tenantId, reportId, id);
}

export const exportAction = (reportId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectMilestoneClient();
	return client.export(tenantId, reportId, exportDataModel);
}
