import { CreatePartnerIcon, CreateProjectIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";
import { SiteConfigurationSubRoutesEnum } from "../enums";

// subroutes

export enum ProjectsSubRoutesEnum {
	CREATE_PROJECT = 'create_project',
	CREATE_PARTNER = 'create_partner'
}

export const createProjectSubRoute: Route ={
	id: ProjectsSubRoutesEnum.CREATE_PROJECT,
	url: `/${RootRouteEnum.SITE_CONFIGURATION}/${SiteConfigurationSubRoutesEnum.PROJECTS}/${ProjectsSubRoutesEnum.CREATE_PROJECT}`,
	text: 'Create Project',
	Icon: CreateProjectIcon
}

export const createPartnerSubRoute: Route = {
	id: ProjectsSubRoutesEnum.CREATE_PARTNER,
	url: `/${RootRouteEnum.SITE_CONFIGURATION}/${SiteConfigurationSubRoutesEnum.PROJECTS}/${ProjectsSubRoutesEnum.CREATE_PARTNER}`,
	text: 'Create Partner',
	Icon: CreatePartnerIcon
}

// root route

export const projectsConfigurationRoute: Route = {
	id: SiteConfigurationSubRoutesEnum.PROJECTS,
	url: `/${RootRouteEnum.SITE_CONFIGURATION}/${SiteConfigurationSubRoutesEnum.PROJECTS}`,
	text: 'Projects',
	children: [
		createProjectSubRoute,
		createPartnerSubRoute
	]
}
