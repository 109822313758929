import { HelpShell } from 'features/Help/HelpShell';

export const InvoicesHelp = () => {
	return (
		<HelpShell title='Invoices'>
			<p>You can track your project incoming and outgoing invoices using our tool.</p>
			<p>
				For each invoice you can maintain basic information, define budget category in which invoice will be shown in budget and actuals, decide if invoice should be excluded from budget or not, link invoice with change request. <br />
				Invoice amounts are entered in project currency used for reporting, but you can also enter amounts in local currency if needed. You can also enter information about invoice items. Please note that sum of items amount needs to match total invoice amount.
			</p>
			<div>
				<p>Invoices can have 3 statuses:</p>
				<ul>
					<li>Planned (default status for each new invoice created)</li>
					<li>Issued/Received</li>
					<li>Payed</li>
				</ul>
			</div>
			<p>Planned invoices are copied to project budget as budgeted items if you define so in budget configuration.</p>
			<p>Issued/received and payed invoices are shown in actuals.</p>
			<p>Please note that this functionality is used only for project reporting purposes. It is not intended to replace your accounting system.</p>
		</HelpShell>
	)
}
