import { useMemo } from 'react'
import { Route } from 'features/Routes/reducer'
import Item from './Item/Item'
import styles from './defaultContent.module.scss'
import { ContentShell } from 'features/Content/ContentShell'

type Props = {
	route: Route
}

export const DefaultContent = ({ route }: Props) => {
	const content = useMemo(
		() => {
			return route.children && route.children.map((child) => {
				return <Item key={child.id} route={child} isRoot />
			})
		},
		[route]
	)

	return (
		<ContentShell title={route.text}>
			<div className={styles.container}>
				{content}
			</div>
		</ContentShell>
	)
}
