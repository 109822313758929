import { InnerTestPlanResponse } from 'services/tenantManagementService';
import { Dialog } from 'components/Dialog';
import { FailedStepTestForm } from './FailedStepTestForm';

type Props = {
	testPlan: InnerTestPlanResponse
	open: boolean
	save(testPlan: InnerTestPlanResponse): void
	cancel(): void
}

export const FailedStepTestModal = ({ testPlan, save, cancel, open }: Props) => {
	return (
		<Dialog
			title={`${testPlan.processStepId} -> Failed`}
			onClose={cancel}
			open={open}
			size='large'
		>
			<FailedStepTestForm
				testPlan={testPlan}
				save={save}
				cancel={cancel}
			/>
		</Dialog>
	)
}
