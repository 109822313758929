import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { GenericFilterModelCollection, ReportTestPlanResponseItemsResponseModel, ShowTestStatusEnum } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { defaultProcessStepColumns, defaultTicketColumns, useTableColumnsMemo } from '../Table/tableColumns';
import { CustomTestStatusReportRowModel, mapToCustomTestStatusReportRowModel } from '../Table/tableModel';
import { exportAction, getShowTestStatusConfigAction, getTestStatusReportAction, updateShowTestStatusConfigAction } from './action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { createExportModel } from 'utils/exportUtils';
import { ExportModel } from 'features/Export/ExportForm';
import TableButtons from '../Table/TableButtons';
import FileSaver from 'file-saver';
import { tryCatchExportFileByAction } from 'utils/exportUtils';
import { ColumnContainer } from 'components/Layout';
import { RemoteTable } from 'components/Table';

type Props = {
	projectId: number
	testCycleId: number
}

const configureViewKey = 'test_status_report';

export const TestStatusTable = ({ projectId, testCycleId }: Props) => {
	const { persistedConfigureView } = useSelector((state: RootState) => state);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection())
	const [selectedReport, setSelectedReport] = useState(new CustomTestStatusReportRowModel());
	const [selectedShowTestStatusItems, setSelectedShowTestStatusItems] = useState<ShowTestStatusEnum[]>([ShowTestStatusEnum.ShowTestStatus])

	const tableColumns = useTableColumnsMemo(
		selectedShowTestStatusItems[0],
		persistedConfigureView.value[configureViewKey]
	)

	const fetchShowProcessStepsCallback = useCallback(
		async () => {
			const showProcessStepsResponse = await tryCatchJsonByAction(getShowTestStatusConfigAction);
			setIsLoading(false);
			// we need Object.values(ShowTestStatusEnum), because content before was boolean
			if (showProcessStepsResponse.success && showProcessStepsResponse.value && Object.values(ShowTestStatusEnum).includes(showProcessStepsResponse.value.content)) {
				setSelectedShowTestStatusItems([showProcessStepsResponse.value.content]);
			} else {
				//default state
				setSelectedShowTestStatusItems([ShowTestStatusEnum.ShowTestStatus]);
			}
		},
		[]
	)

	const showProcessStepsChangedCallback = useCallback(
		async (newIds: ShowTestStatusEnum[]) => {
			setIsLoading(true);

			const newValue = newIds[0];

			// change configure view for table on radio change
			const visibleColumns = tableColumns.filter(column => column.visible);
			let columnFields: string[] = visibleColumns
				.map(column => column.field)
				.filter(field => !defaultProcessStepColumns.includes(field) && !defaultTicketColumns.includes(field));

			if (newValue === ShowTestStatusEnum.ShowProcessSteps) {
				columnFields = [...columnFields, ...defaultProcessStepColumns];
			} else if (newValue === ShowTestStatusEnum.ShowMultipleTickets) {
				columnFields = [...columnFields, ...defaultTicketColumns];
			}

			setConfigureViewTableAction(configureViewKey, columnFields);

			// update show process steps configuration
			setSelectedShowTestStatusItems([newValue]);
			const bindedAction = updateShowTestStatusConfigAction.bind(null, newValue);

			await tryCatchJsonByAction(bindedAction);
			setIsLoading(false);
			setFiltersModel(new GenericFilterModelCollection());
		},
		[tableColumns]
	)

	useEffect(
		() => {
			fetchShowProcessStepsCallback();
		},
		[fetchShowProcessStepsCallback]
	)

	const exportCallback = useCallback(
		async (exportModel: ExportModel) => {
			const exportDataModel = createExportModel(tableColumns, filtersModel, exportModel);
			exportDataModel.title = 'Scope level items';

			setIsLoadingExport(true);

			const bindedAction = exportAction.bind(null, projectId, testCycleId, selectedShowTestStatusItems[0], exportDataModel);
			const response = await tryCatchExportFileByAction(bindedAction);
			if (response.success && response.value) {
				FileSaver.saveAs(response.value.data, response.value.fileName);
			}

			setIsLoadingExport(false);
		},
		[filtersModel, tableColumns, projectId, testCycleId, selectedShowTestStatusItems]
	)

	const memoFetchFunction = useMemo(
		() => {
			if (!projectId || !testCycleId) {
				return () => {
					return Promise.resolve(new ReportTestPlanResponseItemsResponseModel({
						success: true,
						count: 0,
						items: []
					}));
				}
			}

			return getTestStatusReportAction.bind(null, projectId, testCycleId, selectedShowTestStatusItems[0])
		},
		[projectId, testCycleId, selectedShowTestStatusItems]
	)

	const mapResponseMemo = useMemo(
		() => {
			return mapToCustomTestStatusReportRowModel.bind(null, selectedShowTestStatusItems[0]);
		},
		[selectedShowTestStatusItems]
	)

	const selectedReportChangeCallback = useCallback(
        (data: CustomTestStatusReportRowModel[]) => {
			setSelectedReport(data[0] || new CustomTestStatusReportRowModel());
		},
		[]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		projectId ?
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedReport.parentId}
					projectId={projectId}
					testCycleId={testCycleId}
					selectedShowTestStatusItems={selectedShowTestStatusItems}
					setSelectedShowTestStatusItems={showProcessStepsChangedCallback}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					onExport={exportCallback}
					loadingExport={isLoadingExport}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					fetchFunction={memoFetchFunction}
					reorderColumns={reorderColumnsCallback}
					mapResponse={mapResponseMemo}
					isLoading={isLoading}
					rowSelectionChanged={selectedReportChangeCallback}
				/>
			</ColumnContainer>
		: <>-</>
	)
}
