import { Dialog } from 'components/Dialog';
import { CrudEnum, useGoBackFromRead } from 'features/Crud';
import { ExpenseItemResponse } from 'services/tenantManagementService';
import ExpenseItemForm from './ExpenseItemForm';

type Props = {
	expenseItem: ExpenseItemResponse
}

const ReadExpenseItem = ({ expenseItem }: Props) => {

	const goBackFromRead = useGoBackFromRead(true);

	return (
		<Dialog
			title='Read expense'
			open
			onClose={goBackFromRead}
			size='large'
		>
			<ExpenseItemForm
				expenseItem={expenseItem}
				crud={CrudEnum.Read}
			/>
		</Dialog>
	)
}

export default ReadExpenseItem;
