import {useMemo } from 'react';
import { minutesToHm } from 'utils/dateTimeUtils';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { IdNameResponse, ModuleActivityEnum, TimeTravelStatusResponse, TravelByEnum, TravelExpenseResponse, TravelExpenseTotalsResponse, UserModel } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useApplyStatusColorCallback, useCountryCallback, useFormattedProjectIdNameCallback, useStatusCallback, useUsernameCallback } from 'features/TableColumns/persistedHooks';
import { getTravelByEnumLabel, travelByOptions } from 'containers/TimeTravel/utils';
import { IProjectOrCategory, useProjectsAndCategoriesForTableFilterCallback } from 'features/Project';

const defaultVisibleColumns = [
	propertyOf<TravelExpenseResponse>('projectOrCategoryId'),
	propertyOf<TravelExpenseResponse>('id'),
	propertyOf<TravelExpenseResponse>('leaveDepartureDate'),
	propertyOf<TravelExpenseResponse>('returnArrivalDate'),
	propertyOf<TravelExpenseResponse>('statusId'),
	propertyOf<TravelExpenseResponse>('approvedOrRejectedOn'),
	propertyOf<TravelExpenseResponse>('amount'),
]

export const useTableColumnsMemo = (travelExpenseTotals: TravelExpenseTotalsResponse, configureViewKey: string) => {
	const {
		persistedTimeTravelNonProjectCategories,
		persistedTimeAndTravelStatus,
		persistedCountry,
		persistedUser,
		persistedProject,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUsername = useUsernameCallback(persistedUser);
	const getStatusName = useStatusCallback(persistedTimeAndTravelStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedTimeAndTravelStatus);
	const getCountry = useCountryCallback(persistedCountry);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	const { projectsOrCategories } = useProjectsAndCategoriesForTableFilterCallback(ModuleActivityEnum.Time);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<TravelExpenseResponse>('id')]: {
					title: 'Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.TRAVEL_EXPENSE
				},
				[propertyOf<TravelExpenseResponse>('projectOrCategoryId')]: {
					title: 'Project Id or category',
					formatter: (cell: any) => {
						const isProject = (cell.getData() as TravelExpenseResponse).isProjectConnected;
						if (isProject) {
							return formatProjectIdName(cell.getValue());
						}
						return persistedTimeTravelNonProjectCategories.itemsMap[cell.getValue()]?.name || '';
					},
					fieldType: FieldTypeEnum.Options,
					options: projectsOrCategories,
					getItemId: (option: IProjectOrCategory) => option.projectOrCategoryId,
					getItemText: (option: IProjectOrCategory) => option.label,
					dbFilterFieldPath: 'ProjectRefId',
				},
				[propertyOf<TravelExpenseResponse>('userId')]: {
					title: 'Created by',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
				[propertyOf<TravelExpenseResponse>('leaveDepartureDate')]: {
					title: 'Departure',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TravelExpenseResponse>('leaveTravelTimeInMinutes')]: {
					title: 'Travel time departure',
					formatter: (cell: any) => cell.getValue() ? minutesToHm(cell.getValue()) : '',
					disableSort: true,
					fieldType: FieldTypeEnum.Number,
					bottomCalc: () => travelExpenseTotals.totalLeaveTravelTimeInMinutes || 0,
					bottomCalcFormatter: (cell: any) => `∑ ${minutesToHm(cell.getValue()) || ''}`
				},
				[propertyOf<TravelExpenseResponse>('returnArrivalDate')]: {
					title: 'Return',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TravelExpenseResponse>('returnTravelTimeInMinutes')]: {
					title: 'Travel time return',
					formatter: (cell: any) => cell.getValue() ? minutesToHm(cell.getValue()) : '',
					disableSort: true,
					fieldType: FieldTypeEnum.Number,
					bottomCalc: () => travelExpenseTotals.totalReturnTravelTimeInMinutes || 0,
					bottomCalcFormatter: (cell: any) => `∑ ${minutesToHm(cell.getValue()) || ''}`
				},
				[propertyOf<TravelExpenseResponse>('totalTravelDurationInMinutes')]: {
					title: 'Total travel duration',
					formatter: (cell: any) => cell.getValue() ? minutesToHm(cell.getValue()) : '',
					disableSort: true,
					fieldType: FieldTypeEnum.Number,
					bottomCalc: () => travelExpenseTotals.totalTravelDurationInMinutes,
					bottomCalcFormatter: (cell: any) => `∑ ${minutesToHm(cell.getValue()) || ''}`
				},
				[propertyOf<TravelExpenseResponse>('estimatedCosts')]: {
					title: 'Estimated costs',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: () => travelExpenseTotals.totalEstimatedCosts
				},
				[propertyOf<TravelExpenseResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatusName(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTimeAndTravelStatus.items,
					getItemId: (option: TimeTravelStatusResponse) => option.id,
					getItemText: (option: TimeTravelStatusResponse) => option.name,
					dbFilterFieldPath: 'StatusRefId',
					dbExportFieldPath: 'StatusId'
				},
				[propertyOf<TravelExpenseResponse>('destinationCountryId')]: {
					title: 'Destination Country',
					formatter: (cell: any) => getCountry(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedCountry.items,
					getItemId: (option: IdNameResponse) => option.id,
					getItemText: (option: IdNameResponse) => option.name
				},
				[propertyOf<TravelExpenseResponse>('destinationCity')]: {
					title: 'Destination city',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<TravelExpenseResponse>('customText1')]: {
					title: 'Text 1',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<TravelExpenseResponse>('customText2')]: {
					title: 'Text 2',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<TravelExpenseResponse>('travelBy')]: {
					title: 'Travel By',
					fieldType: FieldTypeEnum.Option,
					options: travelByOptions,
					getItemId: (item: TravelByEnum) => item,
					getItemText: (item: TravelByEnum) => getTravelByEnumLabel(item),
					formatter: (cell: any) => getTravelByEnumLabel(cell.getValue())
				},
				[propertyOf<TravelExpenseResponse>('amount')]: {
					title: 'Total cost',
					disableSort: true,
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: () => travelExpenseTotals.totalAmounts
				},
				[propertyOf<TravelExpenseResponse>('travelRequestId')]: {
					title: 'Travel request Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.TRAVEL_REQUEST,
					dbFilterFieldPath: 'TravelRequestRefId',
				},
				[propertyOf<TravelExpenseResponse>('description')]: {
					title: 'Purpose of travel',
					fieldType: FieldTypeEnum.String,
				},
				[propertyOf<TravelExpenseResponse>('advancePayment')]: {
					title: 'Advance payment',
					fieldType: FieldTypeEnum.Currency
				},
				[propertyOf<TravelExpenseResponse>('freeMeals')]: {
					title: 'Free meals',
					fieldType: FieldTypeEnum.Boolean,
					formatter: (cell: any) => cell.getValue() ? 'yes' : 'no',
				},
				[propertyOf<TravelExpenseResponse>('freeAccommodation')]: {
					title: 'Free accommodation',
					fieldType: FieldTypeEnum.Boolean,
					formatter: (cell: any) => cell.getValue() ? 'yes' : 'no',
				},
				[propertyOf<TravelExpenseResponse>('regNumber')]: {
					title: 'Registration',
					fieldType: FieldTypeEnum.String,
				},
				[propertyOf<TravelExpenseResponse>('approvedOrRejectedOn')]: {
					title: 'Approved/Rejected on',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TravelExpenseResponse>('approvedOrRejectedByUserId')]: {
					title: 'Approved/Rejected by',
					formatter: (cell: any) => getUsername(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => option.username
				},
			}
		},
		[
			getUsername,
			getStatusName,
			applyStatusColor,
			getCountry,
			formatProjectIdName,
			travelExpenseTotals,
			projectsOrCategories,
			persistedUser.items,
			persistedCountry.items,
			persistedTimeAndTravelStatus.items,
			persistedTimeTravelNonProjectCategories
		]
	)

	return useVisibleColumns(columns, visibleColumns);
}
