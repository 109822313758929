export const numberTotalCalculator = (value: any[], id: string) => {
	let sum = 0;

	for (const row of value) {
		if (row[id]) {
			sum += row[id];
		}
	}

	return sum;
}

export const timeSpanTotalCalculator = numberTotalCalculator;
