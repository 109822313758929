import { ControlsCommonProps } from "components/Form/fields"
import { ClipSpinner } from "components/Spinner"
import { ArrowDownIcon } from "components/icons/icons"
import styles from './autoComplete.module.scss'
import { useCallback, useEffect, useMemo, useState } from "react"
import { Input } from "../Input/Input"
import { Option } from "./Option"
import { emptyArray } from "utils/commonHelper"
import Spinner from "components/Spinner/Spinner"
import { InfoPopup, POSITION } from "components/Popup"

export const autoCompleteId = 'autoCompleteId';

// AutoComplete (like Textarea) accepts Enter
// so this method is used in Form to prevent auto save of the form via Enter key
export const isAutoCompleteHit = (target: HTMLElement) => {
	return !!target.closest(`#${autoCompleteId}`);
}

export type AutoCompleteProps = ControlsCommonProps<string | number | undefined> & {
	items: Array<any>
	getItemId(item: any): string | number
	getItemText(item: any): string | undefined
	getItemDescription?(item: any): string | undefined

	placeholder?: string
	loading?: boolean
	sort?: boolean
}

export const AutoComplete = (props: AutoCompleteProps) => {
	const {
		value, onChange, onBlur, disabled,
		items, getItemId, getItemText, getItemDescription,
		placeholder, loading, sort
	} = props;

	const [expanded, setExpanded] = useState(false);
	const [searchValue, setSearchValue] = useState<string>();
	const [filteredItems, setFilteredItems] = useState(items || emptyArray);
	const [keyboardItem, setKeyboardItem] = useState<any | undefined>();

	useEffect(
		() => {
			// filter by searchValue
			let newFilteredItems = items.filter((item) => {
				const text = getItemText(item);
				return text?.toLowerCase().includes(searchValue?.toLowerCase() || '');
			})
			// sort by alphabet
			if (sort) {
				newFilteredItems = newFilteredItems.sort((a, b) => {
					const textA = getItemText(a)?.toUpperCase() || '';
					const textB = getItemText(b)?.toUpperCase() || ''
					if (textA < textB){
						return -1;
					}
					if (textA > textB){
						return 1;
					}
					return 0;
				})
			}
			setFilteredItems(newFilteredItems);
		},
		[searchValue, items, getItemText, sort]
	)

	const onChangeCallback = useCallback(
		(item: any | undefined) => {
			let id: string | number | undefined;
			if (item) {
				id = getItemId(item);
			}

			if (id !== value) {
				onChange && onChange(id);
			}
			setSearchValue(undefined);
			setFilteredItems(items || emptyArray);
			setExpanded(false);
			setKeyboardItem(undefined);
		},
		[getItemId, onChange, items, value]
	)

	const onBlurCallback = useCallback(
		() => {
			if (searchValue === '') {
				onChangeCallback(undefined)
			}
			onBlur && onBlur();
			setSearchValue(undefined);
			setFilteredItems(items || emptyArray);
			setExpanded(false);
			setKeyboardItem(undefined);
		},
		[onBlur, items, onChangeCallback, searchValue]
	)

	const expandCallback = useCallback(
		() => !disabled && !loading && setExpanded(true),
		[disabled, loading]
	)

	const onKeyDownCallback = useCallback(
		(eventKey: string) => {
			if (!expanded) {
				return;
			}

			switch (eventKey) {
				case 'Enter':
					if (keyboardItem) {
						onChangeCallback(keyboardItem);
					} else if (searchValue === '') {
						onChangeCallback(undefined);
					}
					break;
				case 'ArrowUp':
					setKeyboardItem((state) => {
						const index = filteredItems.indexOf(state);

						if (index === -1) {
							return filteredItems.at(-1);
						} else {
							return filteredItems.at(index - 1);
						}
					})
					break;
				case 'ArrowDown':
					setKeyboardItem((state) => {
						const index = filteredItems.indexOf(state);

						if (index === filteredItems.length - 1) {
							return filteredItems.at(0);
						} else {
							return filteredItems.at(index + 1);
						}
					})
					break;
			}
		},
		[searchValue, filteredItems, keyboardItem, onChangeCallback, expanded]
	)

	const optionsContent = useMemo(
		() => {
			return filteredItems.map((item) => {
				const id = getItemId(item);

				return (
					<Option
						key={getItemId(item)}
						item={item}
						getItemText={getItemText}
						onClick={onChangeCallback}
						isSelected={value === id || keyboardItem === item}
					/>
				)
			})
		},
		[filteredItems, getItemId, getItemText, onChangeCallback, value, keyboardItem]
	)

	const selectedItem: any | undefined = useMemo(
		() => {
			if (value) {
				return items.find((item) => getItemId(item) === value);
			}
		},
		[value, items, getItemId]
	)

	const selectedItemText = selectedItem ? getItemText(selectedItem) : undefined;

	const description = useMemo(
		() => {
			if (getItemDescription && selectedItem) {
				return getItemDescription(selectedItem);
			}
			return undefined;
		},
		[selectedItem, getItemDescription]
	)

	return (
		<div id={autoCompleteId} className={styles.container}>
			<div className={styles.select_container} onClick={expandCallback}>
				<Input
					value={searchValue !== undefined ? searchValue : selectedItemText}
					onChange={setSearchValue}
					onBlur={onBlurCallback}
					onFocus={expandCallback}
					onKeyDown={onKeyDownCallback}
					placeholder={placeholder}
					disabled={disabled || loading}
					hideMaxLength
					selectAllTextOnFocus
				/>
				<div className={`${styles.dropdown} ${expanded ? styles.open : ''}`}>
					{optionsContent}
					{optionsContent.length === 0 && <div className={styles.no_options}>No options</div>}
				</div>
				{/* arrow */}
				<div className={styles.arrow}>
					<ArrowDownIcon width={8} height={8} fill='currentColor' />
				</div>
				{/* loading */}
				{loading &&
					<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
						<Spinner>
							<ClipSpinner size={20} />
						</Spinner>
					</div>
				}
			</div>
			{/* description */}
			{description &&
				<div className={styles.description_container}>
					<InfoPopup
						message={description}
						position={POSITION.TOP_CENTER}
					/>
				</div>
			}
		</div>
	)
}
