import { useMemo } from 'react';
import { IdNameResponse, UserModel } from 'services/tenantManagementService';
import { useCountryCallback, useOrganizationalUnitCallback } from 'features/TableColumns/persistedHooks';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';

const defaultVisibleColumns = [
	propertyOf<UserModel>('username'),
	'fullName',
	propertyOf<UserModel>('email'),
	propertyOf<UserModel>('officePhone'),
	propertyOf<UserModel>('mobilePhone')
]

export const useTableColumnsMemo = (configureViewKey: string) => {
	const {
		persistedCountry,
		persistedConfigureView,
		persistedOrganizationalUnit,
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getCountry = useCountryCallback(persistedCountry);
	const getOrganizationalUnit = useOrganizationalUnitCallback();

	const organizationalUnits = useMemo(
		() => convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined),
		[persistedOrganizationalUnit]
	)

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				fullName: {
					title: 'Full name',
					fieldType: FieldTypeEnum.None,
					formatter: (cell: any) => {
						const value = cell.getData() as UserModel;
						return `${value.firstName} ${value.lastName}`
					},
					disableFilter: true,
					disableSort: true
				},
				[propertyOf<UserModel>('username')]: {
					title: 'Username',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('firstName')]: {
					title: 'First name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('lastName')]: {
					title: 'Last name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('birthDate')]: {
					title: 'Birth date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<UserModel>('email')]: {
					title: 'Email',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('officePhone')]: {
					title: 'Office phone',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('mobilePhone')]: {
					title: 'Mobile phone',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('address')]: {
					title: 'Address',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('city')]: {
					title: 'City',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('countryId')]: {
					title: 'Country',
					formatter: (cell: any) => getCountry(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedCountry.items,
					getItemId: (option: IdNameResponse) => option.id,
					getItemText: (option: IdNameResponse) => option.name
				},
				[propertyOf<UserModel>('department')]: {
					title: 'Department',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<UserModel>('organizationalUnitId')]: {
					title: 'Organizational Unit',
					formatter: (cell: any) => getOrganizationalUnit(cell.getValue(), organizationalUnits),
					fieldType: FieldTypeEnum.MapOption,
					options: organizationalUnits
				}
			}
		},
		[getCountry, organizationalUnits, persistedCountry, getOrganizationalUnit]
	)

	return useVisibleColumns(columns, visibleColumns);
}
