import { getBudgetStatusesAction, updateBudgetStatusesAction } from './action';
import { BudgetStatusesHelp } from './BudgetStatusesHelp';
import { StatusResponseTable } from 'features/StatusResponse/StatusResponseTable';
import { ContentShell } from 'features/Content/ContentShell';

export const Budget = () => {
	return (
		<ContentShell
			InlineHelpComponent={BudgetStatusesHelp}
			showBreadcrumb={false}
		>
			<StatusResponseTable
				getConfigsAction={getBudgetStatusesAction}
				updateConfigsAction={updateBudgetStatusesAction}
			/>
		</ContentShell>
	)
}
