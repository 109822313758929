import { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterProps, getRouteObject } from 'features/Routes';
import { SiteSettings as SiteSettingsContainer } from './SiteSettings/SiteSettings';
import { Statuses } from './Statuses/Statuses';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { Company } from './Company/Company';
import { Calendar } from './Calendar/Calendar';
import { GeneralSubRoutesEnum, calendarSubRoute, companySubRoute, siteSettingsSubRoute, statusesSubRoute } from './routes';

export const GeneralRouter = ({ route }: RouterProps) => {
	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[GeneralSubRoutesEnum.SITE_SETTINGS] &&
				<Route
					path={siteSettingsSubRoute.url}
					render={() => <SiteSettingsContainer />}
				/>
			}
			{routesMap[GeneralSubRoutesEnum.STATUSES] &&
				<Route
					path={statusesSubRoute.url}
					render={() => <Statuses />}
				/>
			}
			{routesMap[GeneralSubRoutesEnum.COMPANY] &&
				<Route
					path={companySubRoute.url}
					render={() => <Company />}
				/>
			}
			{routesMap[GeneralSubRoutesEnum.CALENDAR] &&
				<Route
					path={calendarSubRoute.url}
					render={() => <Calendar />}
				/>
			}

			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
