import Button from 'components/Button';
import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { Export } from 'features/Export/Export';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { BaseColumnModel } from 'components/Table';
import { CrudButtons } from 'features/Crud';

type Props = {
	selectedId?: number
	isMaintainable: boolean
	releasing?: boolean
	isReleaseable?: boolean
	onReleaseClick: () => void
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

export const TableButtons = ({
	selectedId,
	isMaintainable,
	releasing,
	isReleaseable,
	onReleaseClick,
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction
}: Props) => {
	return (
		<RowContainer justifyContent='space-between'>
			<RowContainer>
				<CrudButtons
					selectedId={selectedId}
					isCreateable={false}
					isViewable={false}
					isDeleteable={false}
					labels={{
						change: 'Maintain Project',
					}}
					disabled={!isMaintainable}
				/>
				<Button
					text='Release Project'
					disabled={!isReleaseable}
					onClick={onReleaseClick}
					isLoading={releasing}
				/>
			</RowContainer>
			<RowContainer>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				<Export
					filtersModel={filtersModel}
					tableColumns={tableColumns}
					exportAction={exportFunction}
					title='Projects'
					exportTypeOptions={EXPORT_TYPE_OPTIONS}
				/>
			</RowContainer>
		</RowContainer>
	);
};
