import { useCallback, useMemo, useState } from 'react';
import notifications from 'components/Notification/notification';
import { ColumnContainer } from 'components/Layout';
import { TableButtons } from './Table/TableButtons';
import { useTableColumnsMemo } from './Table/tableColumns';
import { deleteInvoiceAction, getInvoicesAction, exportAction, getInvoicesGenericCountsAction } from './action';
import { GenericFilterModelCollection, InvoiceResponse, InvoiceCountsResponse, ModuleActivityEnum } from 'services/tenantManagementService';
import { ContentShell } from 'features/Content/ContentShell';
import { ListComponentProps } from 'features/Crud'
import { RemoteTable } from 'components/Table';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { WithProjectPicker, ComponentProps, pmOrSpmPermission } from 'features/Project';
import { InvoicesHelp } from './Help/InvoicesHelp';

const configureViewKey = 'finance_invoices_table';

type Props = ListComponentProps & ComponentProps;

export const Invoices = ({ project, disabledEdit, dataChangedTopic, publishDataChanged }: Props) => {
	const [selectedInvoice, setSelectedInvoice] = useState(new InvoiceResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());
	const [countsResponse, setCountsResponse] = useState(new InvoiceCountsResponse());

	const tableColumns = useTableColumnsMemo(countsResponse, configureViewKey);

	const fetchCountsDataCallback = useCallback(
		async () => {
			const bindedAction = getInvoicesGenericCountsAction.bind(null, project.id, filtersModel);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setCountsResponse(response.value)
			}
		},
		[project.id, filtersModel]
	)

	const memoFetchFunction = useCallback(
		async (filtersModel: GenericFilterModelCollection) => {
			const [, response] = await Promise.all([
				fetchCountsDataCallback(),
				getInvoicesAction(project.id, filtersModel)
			])

			return response;
		},
		[project.id, fetchCountsDataCallback]
	)

	const handleRowSelectionChange = useCallback(
		(data: InvoiceResponse[]) => {
			setSelectedInvoice(data[0] || new InvoiceResponse());
		},
		[]
	);

	const deleteCallback = useCallback(
		async () => {
			const bindedAction = deleteInvoiceAction.bind(null, project.id, selectedInvoice.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`Invoice ${selectedInvoice.invoiceNumber} is deleted.`);
				publishDataChanged();
			}
		},
		[selectedInvoice, project.id, publishDataChanged]
	)

	const memoExportFunction = useMemo(
		() => exportAction.bind(null, project.id),
		[project.id]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='Invoices'
			FloatingHelpComponent={InvoicesHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedInvoice.id}
					disabled={disabledEdit}
					onDelete={deleteCallback}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={dataChangedTopic}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectPicker(Invoices, ModuleActivityEnum.Finance, true, undefined, pmOrSpmPermission);
