import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectQualityResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createQualityAction } from '../action';
import { QualityManagementForm as QualityForm } from '../QualityForm';
import { Dialog } from 'components/Dialog';
import { publishQualityManagementChanged } from '../qualityManagementChanged';
import { useGoBackFromCreate } from 'features/Crud';
import { ReportComponentParams } from '../../Table/TableButtons';

export const CreateQuality = () => {
	const params: ReportComponentParams = useParams();
	const reportId = parseInt(params.reportId);

	const goBackFromCreate = useGoBackFromCreate(true);

	const onSubmitCallback = useCallback(
		async (quality: ProjectQualityResponse) => {
			const bindedAction = createQualityAction.bind(null, reportId, quality);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('New Quality data successfully created');
				goBackFromCreate();
				publishQualityManagementChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, reportId]
	);

	return(
		<Dialog
			title='Create Quality data'
			onClose={goBackFromCreate}
			open
		>
			<QualityForm save={onSubmitCallback} cancel={goBackFromCreate} />
		</Dialog>
	)
};
