import { useMemo } from 'react';
import { MeetingResponse, ProjectIsActiveResponse, MeetingStatusResponse, UserModel, TenantIsActiveResponse, AttachmentResponse } from 'services/tenantManagementService';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { useApplyStatusColorCallback, useFormattedProjectIdNameCallback, useStatusCallback, useTenantIsActiveCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { formatDateTime } from 'utils/dateTimeUtils';

const defaultVisibleColumns = [
	propertyOf<MeetingResponse>('id'),
	propertyOf<MeetingResponse>('starts'),
	propertyOf<MeetingResponse>('subject'),
	propertyOf<MeetingResponse>('projectTeamId'),
	propertyOf<MeetingResponse>('userId'),
	propertyOf<MeetingResponse>('participantUserIds'),
	propertyOf<MeetingResponse>('statusId')
]

export const useTableColumnsMemo = (configureViewKey: string) => {
	const {
		persistedUser,
		persistedConfigureView,
		persistedProjectTeam,
		persistedMeetingStatus,
		persistedMeetingType,
		persistedTimeTravelNonProjectCategories,
		persistedProject
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getProjectTeamName = useTenantIsActiveCallback(persistedProjectTeam);
	const getStatus = useStatusCallback(persistedMeetingStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedMeetingStatus);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<MeetingResponse>('projectOrCategoryId')]: {
					title: 'Project or category',
					formatter: (cell: any) => {
						const isProject = (cell.getData() as MeetingResponse).isProjectConnected;
						if (isProject) {
							return formatProjectIdName(cell.getValue());
						}
						return persistedTimeTravelNonProjectCategories.itemsMap[cell.getValue()]?.name || '';
					},
					fieldType: FieldTypeEnum.None
				},
				[propertyOf<MeetingResponse>('id')]: {
					title: 'Meeting ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.MEETING
				},
				[propertyOf<MeetingResponse>('starts')]: {
					title: 'Starts',
					fieldType: FieldTypeEnum.Date,
					formatter: (cell: any) => formatDateTime(cell.getValue()),
					format: '{0:MM/dd/yyyy hh:mm tt}',
				},
				[propertyOf<MeetingResponse>('subject')]: {
					title: 'Subject',
					fieldType: FieldTypeEnum.String,
				},
				[propertyOf<MeetingResponse>('attachments')]: {
					title: 'Attachments',
					fieldType: FieldTypeEnum.None,
					formatter: (cell: any) => {
						const values: AttachmentResponse[] = cell.getValue();
						if (!values) {
							return ''
						}
						return values.map(value => value.name).join(', ');
					},
					disableSort: true,
					disableFilter: true,
					dbExportFieldPath: 'Attachments'
				},
				[propertyOf<MeetingResponse>('meetingTypeId')]: {
					title: 'Meeting type',
					formatter: (cell: any) => {
						const id = cell.getValue();
						return persistedMeetingType.itemsMap[id]?.name || ''
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedMeetingType.items,
					getItemId: (option: TenantIsActiveResponse) => option.id,
					getItemText: (option: TenantIsActiveResponse) => option.name,
					dbFilterFieldPath: 'MeetingTypeRefId',
					dbExportFieldPath: 'MeetingTypeRefId'
				},
				[propertyOf<MeetingResponse>('projectTeamId')]: {
					title: 'Project Team',
					formatter: (cell: any) => getProjectTeamName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedProjectTeam.items,
					getItemId: (option: ProjectIsActiveResponse) => option.id,
					getItemText: (option: ProjectIsActiveResponse) => getProjectTeamName(option.id),
					dbFilterFieldPath: 'ProjectTeamRefId',
					dbExportFieldPath: 'ProjectTeamRefId'
				},
				[propertyOf<MeetingResponse>('userId')]: {
					title: 'Organizer',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
				},
				[propertyOf<MeetingResponse>('participantUserIds')]: {
					title: 'Participants',
					formatter: (cell: any) => {
						const userIds: number[] = cell.getValue();
						return userIds.map(userId => getUserFullName(userId)).join(', ');
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
					disableFilter: true,
					disableSort: true,
					dbExportFieldPath: 'Participants'
				},
				[propertyOf<MeetingResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedMeetingStatus.items,
					getItemId: (option: MeetingStatusResponse) => option.id,
					getItemText: (option: MeetingStatusResponse) => option.name,
					dbFilterFieldPath: 'StatusRefId',
					dbExportFieldPath: 'StatusRefId'
				},
				[propertyOf<MeetingResponse>('isPrivate')]: {
					title: 'Private',
					fieldType: FieldTypeEnum.Boolean
				},
			}
		},
		[persistedProjectTeam, persistedUser, formatProjectIdName, getUserFullName, getProjectTeamName, persistedMeetingStatus, getStatus, applyStatusColor, persistedMeetingType, persistedTimeTravelNonProjectCategories]
	)

	return useVisibleColumns(columns, visibleColumns);
}
