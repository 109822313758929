import { ChangeRequestApproveEnum, ChangeRequestFullResponse } from 'services/tenantManagementService';
import { CrudEnum } from 'features/Crud';
import { Dialog } from 'components/Dialog';
import ActionForm from './ActionForm';

type Props = {
	projectId: number
	changeRequest: ChangeRequestFullResponse
	approveEnum: ChangeRequestApproveEnum
	isOpen: boolean
	close(): void
	crud: CrudEnum
}

const ActionModal = ({ projectId, changeRequest, approveEnum, isOpen, close, crud }: Props) => {

	return (
		<Dialog
			title={approveEnum === ChangeRequestApproveEnum.Approve ? 'Approve change request' : 'Reject change request'}
			onClose={close}
			open={isOpen}
		>
			<ActionForm
				projectId={projectId}
				changeRequest={changeRequest}
				approveEnum={approveEnum}
				isOpen={isOpen}
				close={close}
				crud={crud}
			/>
		</Dialog>
	)
}

export default ActionModal;
