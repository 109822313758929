import { ExportDataModel, GenericFilterModelCollection, InsertInvoiceRequest, InvoiceClient, UpdateInvoiceRequest } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getInvoiceAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new InvoiceClient();
	return client.get(tenantId, projectId, id);
}

export const getInvoicesGenericCountsAction = (projectId: number, model: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new InvoiceClient();
	return client.getAllGenericFilterCounts(tenantId, projectId, model);
}

export const getInvoicesAction = (projectId: number, genericFilter: GenericFilterModelCollection | undefined) => {
	const tenantId = getTenantId()!;
	const client = new InvoiceClient();
	return client.getAllGenericFilter(tenantId, projectId, genericFilter || new GenericFilterModelCollection());
}

export const createInvoiceAction = (projectId: number, model: InsertInvoiceRequest) => {
	const tenantId = getTenantId()!;
	const client = new InvoiceClient();
	return client.create(tenantId, projectId, model);
}

export const updateInvoiceAction = (projectId: number, model: UpdateInvoiceRequest) => {
	const tenantId = getTenantId()!;
	const client = new InvoiceClient();
	return client.update(tenantId, projectId, model);
}

export const deleteInvoiceAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new InvoiceClient();
	return client.delete(tenantId, projectId, id);
}

export const exportAction = (projectId: number, model: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new InvoiceClient();
	return client.exportAll(tenantId, projectId, model);
}

export const donwloadAttachmentAction = (projectId: number, attachmentId: number) => {
	const tenantId = getTenantId()!;
	const client = new InvoiceClient();
	return client.download(tenantId, projectId, attachmentId);
}

export const removeAttachmentAction = (projectId: number, attachmentId: number) => {
	const tenantId = getTenantId()!;
	const client = new InvoiceClient();
	return client.removeAttachment(tenantId, projectId, attachmentId);
}
