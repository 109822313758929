import { useMemo } from "react";
import { useApplyStatusColorCallback, useStatusCallback } from "features/TableColumns/persistedHooks";
import { UserStatusResponse, TenantIsActiveResponse, ProjectTeamMemberUserResponse } from "services/tenantManagementService";
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from "components/Table";
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
	propertyOf<ProjectTeamMemberUserResponse>('fullName'),
	propertyOf<ProjectTeamMemberUserResponse>('projectTeamId'),
	propertyOf<ProjectTeamMemberUserResponse>('projectRoleId'),
	propertyOf<ProjectTeamMemberUserResponse>('statusId'),
	propertyOf<ProjectTeamMemberUserResponse>('validUntil'),
	propertyOf<ProjectTeamMemberUserResponse>('isPmOrSubstitutePm'),
	propertyOf<ProjectTeamMemberUserResponse>('lastCostRate'),
	propertyOf<ProjectTeamMemberUserResponse>('lastSalesRate'),
	propertyOf<ProjectTeamMemberUserResponse>('rateValidFrom'),
	propertyOf<ProjectTeamMemberUserResponse>('rateValidTo'),
]

export const useTableColumnsMemo = (
	projectTeams: TenantIsActiveResponse[],
	projectRoles: TenantIsActiveResponse[],
	visibleColumns: string[] = defaultVisibleColumns
) => {
	const {
		persistedUserStatus,
	} = useSelector((state: RootState) => state)
	const getStatus = useStatusCallback(persistedUserStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedUserStatus);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectTeamMemberUserResponse>('fullName')]: {
					title: 'Team member',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectTeamMemberUserResponse>('projectTeamId')]: {
					title: 'Project Team',
					formatter: (cell: any) => projectTeams.find(t => t.id === cell.getValue())?.name,
					fieldType: FieldTypeEnum.Options,
					options: projectTeams,
					getItemId: (option: TenantIsActiveResponse) => option.id,
					getItemText: (option: TenantIsActiveResponse) => option.name,
					dbFilterFieldPath: 'ProjectTeamRefId'
				},
				[propertyOf<ProjectTeamMemberUserResponse>('projectRoleId')]: {
					title: 'Project Role',
					formatter: (cell: any) => projectRoles.find(t => t.id === cell.getValue())?.name,
					fieldType: FieldTypeEnum.Options,
					options: projectRoles,
					getItemId: (option: TenantIsActiveResponse) => option.id,
					getItemText: (option: TenantIsActiveResponse) => option.name,
					dbFilterFieldPath: 'ProjectRoleRefId'
				},
				[propertyOf<ProjectTeamMemberUserResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedUserStatus.items,
					getItemId: (option: UserStatusResponse) => option.id,
					getItemText: (option: UserStatusResponse) => option.name,
					dbFilterFieldPath: 'StatusRefId'
				},
				[propertyOf<ProjectTeamMemberUserResponse>('validUntil')]: {
					title: 'Valid Until',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectTeamMemberUserResponse>('isSubstitutePm')]: {
					title: 'Substitute project manager',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<ProjectTeamMemberUserResponse>('isPmOrSubstitutePm')]: {
					title: 'Project manager',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<ProjectTeamMemberUserResponse>('lastCostRate')]: {
					title: 'Last cost rate',
					fieldType: FieldTypeEnum.Currency,
					disableSort: true,
					disableFilter: true
				},
				[propertyOf<ProjectTeamMemberUserResponse>('lastSalesRate')]: {
					title: 'Last billing rate',
					fieldType: FieldTypeEnum.Currency,
					disableSort: true,
					disableFilter: true
				},
				[propertyOf<ProjectTeamMemberUserResponse>('rateValidFrom')]: {
					title: 'Rates valid from',
					fieldType: FieldTypeEnum.Date,
					disableSort: true,
					disableFilter: true
				},
				[propertyOf<ProjectTeamMemberUserResponse>('rateValidTo')]: {
					title: 'Rates valid to',
					fieldType: FieldTypeEnum.Date,
					disableSort: true,
					disableFilter: true
				}
			}
		},
		[getStatus, projectTeams, projectRoles, applyStatusColor, persistedUserStatus]
	)

	return useVisibleColumns(columns, visibleColumns);
}
