import { getTenantId } from 'features/Tenant/tenantUtils';
import { ExportDataModel, GenericFilterModelCollection, InsertTaskRequest, PartialTaskRequestEnum, TaskClient, UpdateTaskRequest } from 'services/tenantManagementService';

export const getAllTasksGenericFilterAction = (projectIds: number[], nonProjectCategoryIds: number[], partalRequest: PartialTaskRequestEnum[] | undefined, genericFilter: GenericFilterModelCollection | undefined) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.getAllGenericFilter(tenantId, projectIds, nonProjectCategoryIds, partalRequest, genericFilter || new GenericFilterModelCollection());
}

export const getTaskAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.get(tenantId, id);
}

export const getAllTasksAction = (projectIds: number[], nonProjectCategoryIds: number[], statusId: number, notEqualStatusId: number) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.getAll(tenantId, projectIds, nonProjectCategoryIds, statusId, notEqualStatusId);
}

export const getAllTasksSimpleAction = () => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.getAllSimple(tenantId);
}

export const createTaskAction = (model: InsertTaskRequest) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.create(tenantId, model);
}

export const updateTaskAction = (model: UpdateTaskRequest) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.update(tenantId, model);
}

export const deleteTaskAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.delete(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const downloadAttachmentAction = (taskId: number, projectOrCategoryId: number, isProjectConnected: boolean, id: number) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.downloadAttachment(tenantId, taskId, id, projectOrCategoryId, isProjectConnected);
}

export const removeAttachmentAction = (taskId: number, id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.removeAttachment(tenantId, taskId, id, projectOrCategoryId, isProjectConnected);
}

export const getHistoryAction = (taskId: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.getHistoryFromTo(tenantId, taskId, projectOrCategoryId, isProjectConnected, undefined, undefined)
}

export const exportTasksAction = (projectIds: number[], nonProjectCategoryIds: number[], partialRequest: PartialTaskRequestEnum[], model: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.exportAll(tenantId, projectIds, nonProjectCategoryIds, partialRequest, model)
}
