import { getTenantId } from 'features/Tenant/tenantUtils';
import { ExportDataModel, GenericFilterModelCollection, ProjectTeamMemberClient } from 'services/tenantManagementService';

export const getAllTeamMembersGenericFilteringAction = (model: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamMemberClient();
	return client.getAllGenericFiltering(tenantId, model);
}

export const exportAllProjectTeamMembersAction = (filter: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamMemberClient();

	return client.exportAll(tenantId, filter);
}
