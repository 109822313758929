import styles from './logo.module.scss'

type Props = {
	src?: string
}

export const LogoImage = ({ src }: Props) => {
	return (
		<>
			{src &&
				<img
					className={styles.image}
					src={src}
					alt={'logo'}
				/>
			}
		</>
	)
}
