import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Route } from './reducer';
import { setCurrentRoutesAction } from './action';
import { getRoutesByPathname } from './RoutesHelper';

type Props = RouteComponentProps & {
	rootRoutes: Route[]
	setCurrentRoutes(routes: Route[]): void
};

class HistoryListener extends Component<Props> {
	componentDidMount() {
		const pathname = this.props.location.pathname;
		const currentRoutes = getRoutesByPathname(this.props.rootRoutes, pathname);
		this.props.setCurrentRoutes(currentRoutes);

		this.props.history.listen((location: any) => {
			const pathname = location.pathname;
			const currentRoutes = getRoutesByPathname(this.props.rootRoutes, pathname);
			this.props.setCurrentRoutes(currentRoutes);
		});
	}

	render() {
		return <></>
	}
}

// connect to redux
const mapDispatchToProps = function (dispatch: Function) {
	return {
		setCurrentRoutes: (routes: Route[]) => dispatch(setCurrentRoutesAction(routes))
	};
};

export default connect(null, mapDispatchToProps)(withRouter(HistoryListener));
