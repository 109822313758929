import TenantIsActiveTable from 'features/TenantIsActive/TenantIsActiveTable';
import { getProjectCategoriesAction, updateProjectCategoriesAction } from './action';

export const ProjectCategories = () => {
	return (
		<TenantIsActiveTable
			getConfigsAction={getProjectCategoriesAction}
			updateConfigsAction={updateProjectCategoriesAction}
			successMessage='Project categories are updated.'
			label={{ name: 'Category name' }}
		/>
	)
};
