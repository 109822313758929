import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import TravelExpenseForm from './TravelExpenseForm';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { InsertTravelExpenseRequest, InsertTravelExpenseItemRequest, TravelExpenseResponse } from 'services/tenantManagementService';
import { createTravelExpenseAction } from '../action';
import { ContentShell } from 'features/Content/ContentShell';

const CreateMyTravelExpense = ({ publishDataChanged }: CreateComponentProps) => {
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newTravelExpense: TravelExpenseResponse, isRelease: boolean) => {
			const model = new InsertTravelExpenseRequest({
				...newTravelExpense,
				isRelease,
				newTravelExpenses: newTravelExpense.travelExpenseItems?.map(tei => new InsertTravelExpenseItemRequest({
					...tei,
					newAttachments: (tei as InsertTravelExpenseItemRequest).newAttachments
				}))
			});
			const bindedAction = createTravelExpenseAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TRAVEL_EXPENSE, response.value?.id);
				notifications.success(`Travel expense ${id} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	);

	return (
		<ContentShell title='Create travel expense'>
			<TravelExpenseForm
				crud={CrudEnum.Create}
				onSave={handleSave}
			/>
		</ContentShell>
	)
}

export default CreateMyTravelExpense;
