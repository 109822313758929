import {useMemo } from 'react';
import { useActiveTeamMembersMemo, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns, dateEditor, minutesDurationEditor, timeEditor } from 'components/Table';
import { UserModel } from 'services/tenantManagementService';
import { formatTime } from 'utils/dateTimeUtils';
import { CustomTestPlanRowModel } from './tableModel';
import { sortByString } from 'utils/stringUtil';
import { editableTestPlanCheck } from './tableEditorHelper';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
	propertyOf<CustomTestPlanRowModel>('isActive'),
	propertyOf<CustomTestPlanRowModel>('level3ProcessId'),
	propertyOf<CustomTestPlanRowModel>('level3ProcessName'),
	propertyOf<CustomTestPlanRowModel>('plannedDate'),
	propertyOf<CustomTestPlanRowModel>('processTesterUserId'),
]

export const defaultProcessStepColumns = [
	propertyOf<CustomTestPlanRowModel>('processStepId'),
	propertyOf<CustomTestPlanRowModel>('processStepName'),
	propertyOf<CustomTestPlanRowModel>('stepTesterUserId'),
]

export const useTableColumnsMemo = (
	projectId: number,
	showProcessSteps?: boolean,
	visibleColumns?: string[],
	disableEditor?: boolean
) => {
	const {
		persistedTeamMember,
		persistedUser
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const memberUsersMemo: UserModel[] = useActiveTeamMembersMemo(persistedTeamMember, persistedUser, projectId)

	const defaultVisibleColumnsMemo = useMemo(
		() => {
			let result = defaultVisibleColumns;
			if (showProcessSteps) {
				result = [
					...result,
					...defaultProcessStepColumns
				]
			}
			return result;
		},
		[showProcessSteps]
	)

	const columns: TableColumnsType = useMemo(
		() => {
			let tableColumns: any = {
				[propertyOf<CustomTestPlanRowModel>('isActive')]: {
					title: 'Include in test scope',
					fieldType: FieldTypeEnum.Boolean,
					editor: "tickCross"
				},
				[propertyOf<CustomTestPlanRowModel>('level3ProcessId')]: {
					title: 'Level 3 Process ID',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<CustomTestPlanRowModel>('level3ProcessName')]: {
					title: 'Level 3 Process Name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<CustomTestPlanRowModel>('processTesterUserId')]: {
					title: 'Process tester',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					disableSort: true,
					editor: !disableEditor && 'list',
					editorParams: {
						values: sortByString(
							memberUsersMemo.map(user => {
								return { label: `${user.firstName} ${user.lastName}`, value: user.id };
							}),
							'label'
						),
						autocomplete: true,
						listOnEmpty: true,
						allowEmpty: true,
						placeholderEmpty: 'No options'
					},
					fieldType: FieldTypeEnum.Options,
					options: memberUsersMemo,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				[propertyOf<CustomTestPlanRowModel>('location')]: {
					title: 'Location',
					fieldType: FieldTypeEnum.String,
					editor: !disableEditor && 'input'
				},
				[propertyOf<CustomTestPlanRowModel>('plannedDate')]: {
					title: 'Planned date',
					fieldType: FieldTypeEnum.Date,
					editor: !disableEditor && dateEditor
				},
				[propertyOf<CustomTestPlanRowModel>('plannedTime')]: {
					title: 'Planned time (from)',
					fieldType: FieldTypeEnum.Number,
					formatter: (cell: any) => formatTime(cell.getValue()),
					format: '{0:HH:mm}',
					editor: !disableEditor && timeEditor
				},
				[propertyOf<CustomTestPlanRowModel>('plannedTimeTo')]: {
					title: 'Planned time (to)',
					fieldType: FieldTypeEnum.Number,
					formatter: (cell: any) => formatTime(cell.getValue()),
					format: '{0:HH:mm}',
					editor: !disableEditor && timeEditor
				},
				[propertyOf<CustomTestPlanRowModel>('duration')]: {
					title: 'Duration',
					formatter: (cell: any) => cell.getValue() ? `${cell.getValue() / 10000 / 1000 / 60} min`: '',
					fieldType: FieldTypeEnum.Number,
					editor: !disableEditor && minutesDurationEditor
				},
			}
			if (showProcessSteps) {
				tableColumns = {
					...tableColumns,
					[propertyOf<CustomTestPlanRowModel>('processStepId')]: {
						title: 'Process Step Id',
						fieldType: FieldTypeEnum.String,
						editor: !disableEditor && 'input',
						editable: editableTestPlanCheck,
						disableSort: true

					},
					[propertyOf<CustomTestPlanRowModel>('processStepName')]: {
						title: 'Process Step description',
						fieldType: FieldTypeEnum.String,
						editor: !disableEditor && 'input',
						editable: editableTestPlanCheck,
						disableSort: true
					},
					[propertyOf<CustomTestPlanRowModel>('stepTesterUserId')]: {
						title: 'Step Tester',
						formatter: (cell: any) => getUserFullName(cell.getValue()),
						disableSort: true,
						editor: !disableEditor && 'list',
						editorParams: {
							values: sortByString(
								memberUsersMemo.map(user => {
									return { label: `${user.firstName} ${user.lastName}`, value: user.id };
								}),
								'label'
							),
							autocomplete: true,
							listOnEmpty: true,
							allowEmpty: true,
							placeholderEmpty: 'No options'
						},
						editable: editableTestPlanCheck,
						fieldType: FieldTypeEnum.Options,
						options: memberUsersMemo,
						getItemId: (option: UserModel) => option.id,
						getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
					},
					[propertyOf<CustomTestPlanRowModel>('department')]: {
						title: 'Department',
						fieldType: FieldTypeEnum.String
					},
					[propertyOf<CustomTestPlanRowModel>('stepType')]: {
						title: 'Step type',
						fieldType: FieldTypeEnum.String
					},
				}
			}
			return tableColumns;
		},
		[getUserFullName, showProcessSteps, disableEditor, memberUsersMemo]
	)

	return useVisibleColumns(columns, visibleColumns || defaultVisibleColumnsMemo);
}
