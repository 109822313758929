import { getInvoiceStatusesAction, updateInvoiceStatusesAction } from './action';
import { InvoiceStatusesHelp } from './InvoiceStatusesHelp';
import { StatusResponseTable } from 'features/StatusResponse/StatusResponseTable';
import { ContentShell } from 'features/Content/ContentShell';

export const Invoices = () => {
	return (
		<ContentShell
			InlineHelpComponent={InvoiceStatusesHelp}
			showBreadcrumb={false}
		>
			<StatusResponseTable
				getConfigsAction={getInvoiceStatusesAction}
				updateConfigsAction={updateInvoiceStatusesAction}
			/>
		</ContentShell>
	)
}
