import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormattedProjectIdNameCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { ModuleActivityEnum, TimesheetDashboardResponse, UserModel } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { RootState } from 'base/reducer/reducer';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { IProjectOrCategory, useProjectsAndCategoriesForTableFilterCallback } from 'features/Project';

export const defaultVisibleColumns = [
	propertyOf<TimesheetDashboardResponse>('userId'),
	propertyOf<TimesheetDashboardResponse>('date'),
	propertyOf<TimesheetDashboardResponse>('projectOrCategoryId'),
	propertyOf<TimesheetDashboardResponse>('description'),
	propertyOf<TimesheetDashboardResponse>('hours'),
]

export const defaultCostRateVisibleColumns = [
	propertyOf<TimesheetDashboardResponse>('hourCostRate'),
	propertyOf<TimesheetDashboardResponse>('totalCost'),
];

export const defaultSaleRateVisibleColumns = [
	propertyOf<TimesheetDashboardResponse>('hourSalesRate'),
	propertyOf<TimesheetDashboardResponse>('totalSales'),
];

export const useTableColumnsMemo = ( configureViewKey: string, shotCostRates: boolean) => {
	const {
		persistedUser,
		persistedProject,
		persistedTimeTravelNonProjectCategories,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const defaultVisibleColumnsMemo = useMemo(
		() => shotCostRates ?
			[ ...defaultVisibleColumns, ...defaultCostRateVisibleColumns ] :
			[ ...defaultVisibleColumns, ...defaultSaleRateVisibleColumns ],
		[shotCostRates]
	)

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumnsMemo;

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	const { projectsOrCategories } = useProjectsAndCategoriesForTableFilterCallback(ModuleActivityEnum.Time);

	const columns: TableColumnsType = useMemo(
		() => {
			let result: TableColumnsType = {
				[propertyOf<TimesheetDashboardResponse>('userId')]: {
					title: 'User',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				[propertyOf<TimesheetDashboardResponse>('date')]: {
					title: 'Date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TimesheetDashboardResponse>('projectOrCategoryId')]: {
					title: 'Project Id or category',
					formatter: (cell: any) => {
						const data = (cell.getData() as TimesheetDashboardResponse);
						if (data.isProjectConnected) {
							return formatProjectIdName(data.projectOrCategoryId)
						}
						return persistedTimeTravelNonProjectCategories.itemsMap[cell.getValue()]?.name
					},
					fieldType: FieldTypeEnum.Options,
					options: projectsOrCategories,
					getItemId: (option: IProjectOrCategory) => option.projectOrCategoryId,
					getItemText: (option: IProjectOrCategory) => option.label,
					dbFilterFieldPath: 'ProjectRefId'
				},
				[propertyOf<TimesheetDashboardResponse>('description')]: {
					title: 'Activity description',
					fieldType: FieldTypeEnum.String,
					width: 200
				},
				[propertyOf<TimesheetDashboardResponse>('hours')]: {
					title: 'Hours spend',
					formatter: (cell: any) => cell.getValue().toFixed(2),
					fieldType: FieldTypeEnum.Number,
					bottomCalc: 'sum',
					bottomCalcFormatter: (cell: any) => `∑ ${cell.getValue()?.toFixed(2) || ''}`
				},
				[propertyOf<TimesheetDashboardResponse>('customText1')]: {
					title: 'Text 1',
					fieldType: FieldTypeEnum.String,
					width: 70
				},
				[propertyOf<TimesheetDashboardResponse>('customText2')]: {
					title: 'Text 2',
					fieldType: FieldTypeEnum.String,
					width: 70
				},
				[propertyOf<TimesheetDashboardResponse>('customText3')]: {
					title: 'Text 3',
					fieldType: FieldTypeEnum.String,
					width: 70
				}
			}

			if (shotCostRates) {
				result = {
					...result,
					[propertyOf<TimesheetDashboardResponse>('hourCostRate')]: {
						title: 'Hourly cost rate',
						fieldType: FieldTypeEnum.Currency
					},
					[propertyOf<TimesheetDashboardResponse>('totalCost')]: {
						title: 'Total cost',
						fieldType: FieldTypeEnum.Currency,
						bottomCalc: 'sum'
					}
				}
			} else {
				result = {
					...result,
					[propertyOf<TimesheetDashboardResponse>('hourSalesRate')]: {
						title: 'Hourly billing rate',
						fieldType: FieldTypeEnum.Currency
					},
					[propertyOf<TimesheetDashboardResponse>('totalSales')]: {
						title: 'Total billing',
						fieldType: FieldTypeEnum.Currency,
						bottomCalc: 'sum'
					}
				}
			}
			return result
		},
		[getUserFullName, formatProjectIdName, shotCostRates, persistedTimeTravelNonProjectCategories, persistedUser, projectsOrCategories]
	)

	return useVisibleColumns(columns, visibleColumns);
}
