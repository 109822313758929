import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { reportItemStatuses } from '../../../ReportItemStatuses';
import { ProjectAccomplishmentResponse, ReportItemStatusEnum } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';

const defaultVisibleColumns = [
	propertyOf<ProjectAccomplishmentResponse>('isActive'),
	propertyOf<ProjectAccomplishmentResponse>('name'),
	propertyOf<ProjectAccomplishmentResponse>('plannedDate'),
	propertyOf<ProjectAccomplishmentResponse>('status')
]

export const useTableColumnsMemo = (visibleColumns: string[] = defaultVisibleColumns) => {
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectAccomplishmentResponse>('isActive')]: {
					title: 'Active',
					fieldType: FieldTypeEnum.Boolean
				},
				[propertyOf<ProjectAccomplishmentResponse>('name')]: {
					title: 'Accomplishment',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectAccomplishmentResponse>('plannedDate')]: {
					title: 'Planned Date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectAccomplishmentResponse>('status')]: {
					title: 'Status',
					fieldType: FieldTypeEnum.Options,
					options: reportItemStatuses,
					getItemId: (option: ReportItemStatusEnum) => option.valueOf(),
					getItemText: (option: ReportItemStatusEnum) => option
				}
			}
		},
		[]
	)

	return useVisibleColumns(columns, visibleColumns);
}
