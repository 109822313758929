import { RowContainer } from 'components/Layout';
import Button from 'components/Button';
import { useCallback, useState } from 'react';
import { Export } from 'features/Export/Export';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { BaseColumnModel } from 'components/Table';
import { SimpleDialog } from 'components/Dialog';

type Props = {
	selectedId?: number
	releaseDisabled: boolean
	deleteDisabled: boolean
	saveDisabled: boolean
	disabled: boolean
	onAdd(): void
	onDelete(id: number): void
	onSave(): Promise<void>
	onRelease(): Promise<void>
	tableColumns: BaseColumnModel[]
	exportFunction(exportModel: ExportDataModel): Promise<FileResponse>
	isReleaseable: boolean
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

export const TableButtons = ({
	selectedId,
	releaseDisabled,
	deleteDisabled,
	saveDisabled,
	disabled,
	onAdd,
	onDelete,
	onSave,
	onRelease,
	tableColumns,
	exportFunction,
	isReleaseable
}: Props) => {
	const [isSaving, setIsSaving] = useState(false);
	const saveCallback = useCallback(
		async () => {
			setIsSaving(true);
			await onSave()
			setIsSaving(false);
		},
		[onSave]
	)

	const [isReleasing, setIsReleasing] = useState(false);
	const releaseCallback = useCallback(
		async () => {
			setIsReleasing(true);
			await onRelease()
			setIsReleasing(false);
		},
		[onRelease]
	)

	const [deleting, setDeleting] = useState(false);
	const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

	const openConfirmDeleteModal = useCallback(
		() => setIsConfirmDeleteModalOpen(true),
		[]
	);

	const closeConfirmDeleteModal = useCallback(
		() => setIsConfirmDeleteModalOpen(false),
		[]
	)

	const onDeleteConfirmed = useCallback(
		async () => {
			setDeleting(true);
			closeConfirmDeleteModal();
			if (selectedId) {
				onDelete && await onDelete(selectedId);
			}

			setDeleting(false);
		},
		[closeConfirmDeleteModal, selectedId, onDelete]
	);

	return (
		<div>
			<SimpleDialog
				open={isConfirmDeleteModalOpen}
				title='Delete'
				message='Are you sure that you want to delete selected element?'
				onConfirm={onDeleteConfirmed}
				onCancel={closeConfirmDeleteModal}
			/>
			<RowContainer justifyContent='space-between'>
				<RowContainer>
					<Button
						onClick={onAdd}
						text='Add new'
						disabled={disabled}
					/>
					<Button
						text='Delete'
						color='destructive'
						onClick={openConfirmDeleteModal}
						disabled={!selectedId || deleteDisabled}
						isLoading={deleting}
					/>
				</RowContainer>
				<RowContainer>
					<Button
						onClick={saveCallback}
						disabled={disabled || saveDisabled}
						text='Save'
						isLoading={isSaving}
					/>
					{isReleaseable &&
						<Button
							onClick={releaseCallback}
							disabled={releaseDisabled || disabled}
							text='Release'
							isLoading={isReleasing}
						/>
					}
				</RowContainer>
				<Export
					tableColumns={tableColumns}
					exportAction={exportFunction}
					exportTypeOptions={EXPORT_TYPE_OPTIONS}
					title='Budget'
					filtersModel={new GenericFilterModelCollection()}
					hideApplyFilters
					hideApplySort
					hideUseOnlyVisible
					hideExportOnlyCurrentPage
				/>
			</RowContainer>
		</div>
	)
}
