import { useCallback, useEffect, useState } from 'react'
import { ProjectTimeTravelDashboardFilterRequest, ProjectTimeTravelDashboardResponse } from 'services/tenantManagementService'
import FilteredDashboard from './FilteredDashboard'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getProjectTimeTravelDashboardAction } from './action'
import { FilterForm } from './FilterForm'
import { DashboardContainer } from 'features/Dashboard/DashboardContainer/DashboardContainer'
import { useSaveFilterMemo } from 'features/Dashboard/SaveFilter/useSaveFilterMemo'
import WithFetch from 'features/Fetch/WithFetch'

const viewType = 'project_time_travel_dashboard';

export const ProjectTimeTravelDashboard = () => {
	const [loading, setLoading] = useState(false);
	const { savedFilterModel, saveFilterModel, fetchFilterModel, isLoadingFilter } = useSaveFilterMemo<ProjectTimeTravelDashboardFilterRequest>(viewType, ProjectTimeTravelDashboardFilterRequest.fromJS);

	const [currentFilterModel, setCurrentFilterModel] = useState(new ProjectTimeTravelDashboardFilterRequest());

	const [dashboardResponse, setDashboardResponse] = useState<ProjectTimeTravelDashboardResponse>(new ProjectTimeTravelDashboardResponse())

	const fetchDashboardCallback = useCallback(
		async (model: ProjectTimeTravelDashboardFilterRequest) => {
			setLoading(true);

			setCurrentFilterModel(new ProjectTimeTravelDashboardFilterRequest(model));

			const bindedAction = getProjectTimeTravelDashboardAction.bind(null, new ProjectTimeTravelDashboardFilterRequest(model))
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setDashboardResponse(response.value);
			}


			setLoading(false);
		},
		[]
	)

	useEffect(
		() => {
			if (savedFilterModel) {
				fetchDashboardCallback(savedFilterModel);
			}
		},
		[savedFilterModel, fetchDashboardCallback]
	)

	return (
		<WithFetch fetchFunction={fetchFilterModel} refetching={isLoadingFilter}>
			<DashboardContainer
				form={
					<FilterForm
						onSubmit={fetchDashboardCallback}
						filterFormModel={savedFilterModel}
						onSave={saveFilterModel}
					/>
				}
				dashboard={
					<FilteredDashboard
						loading={loading}
						dashboard={dashboardResponse}
						filterModel={currentFilterModel}
					/>
				}
			/>
		</WithFetch>
	)
}
