import { useCallback, useMemo, useState } from 'react';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { InsertAttachmentRequest, TicketCommnetResponse, UpdateTicketCommentRequest } from 'services/tenantManagementService';
import { createTicketCommentAction, getTicketCommentsAction } from './action';
import WithFetch from 'features/Fetch/WithFetch';
import { Form, TextEditorField } from 'components/Form';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { TicketFormTabsProps } from '../Tabs/TicketFormTabs';
import Button from 'components/Button';
import { AddTaskButton } from './Task/AddTaskButton';
import { Task } from './Task/Task';
import { Comment } from './Comment';
import { AddAttachmentButton } from 'components/Form/controls/Attachment/AddAttachmentButton';
import { useItems } from 'components/Form/controls/Attachment/useItems';
import { noop } from 'utils/commonHelper';
import { SmartFormGroup } from 'components/SmartContainer/SmartContainer';

export const Conversation = ({ ticket }: TicketFormTabsProps) => {
	const [values, setValues] = useState(new UpdateTicketCommentRequest());
	const [comments, setComments] = useState<TicketCommnetResponse[]>([]);
	const [isFetching, setIsFetching] = useState(true);

	const fetchDataCallback = useCallback(
		async () => {
			setIsFetching(true);
			const response = await getTicketCommentsAction(ticket.id);
			setIsFetching(false);
			if (response.success && response.items) {
				setComments(response.items);
			}
		},
		[ticket.id]
	)

	const saveCallback = useCallback(
		async () => {
			const bindedAction = createTicketCommentAction.bind(null, ticket.id, values);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setValues(new UpdateTicketCommentRequest());
				fetchDataCallback();
			} else {
				return convertResponseErrors(response);
			}
		},
		[values, ticket.id, fetchDataCallback]
	)

	const cancelCallback = useCallback(
		() => {
			setValues(new UpdateTicketCommentRequest());
		},
		[]
	)

	const commentsMemo = useMemo(
		() => {
			return comments
				.sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime())
				.map((comment) => (
					<Comment key={comment.id} comment={comment} refetch={fetchDataCallback} />
				));
		},
		[comments, fetchDataCallback]
	)

	const addAttachmentCallback = useCallback(
		(newAttachments: InsertAttachmentRequest[] | undefined) => {
			setValues((state) => {
				return new UpdateTicketCommentRequest({
					...state,
					newAttachments
				})
			})
		},
		[]
	)

	const removeAttachmentCallback = useCallback(
		(item: InsertAttachmentRequest) => {
			setValues((state) => {
				if (!state.newAttachments) {
					return state;
				}

				const newAttachments = state.newAttachments.filter((attachment) => attachment !== item);
				return new UpdateTicketCommentRequest({
					...state,
					newAttachments
				})
			})
		},
		[]
	)

	const attachmentsContent = useItems(values.newAttachments, undefined, false, removeAttachmentCallback, undefined, noop);

	const submitTaskCallback = useCallback(
		(taskValues: UpdateTicketCommentRequest) => {
			setValues((state) => {
				return new UpdateTicketCommentRequest({
					...state,
					taskDate: taskValues.taskDate,
					taskStatusId: taskValues.taskStatusId,
					taskUserId: taskValues.taskUserId
				})
			})
		},
		[]
	)

	const removeTaskCallback = useCallback(
		() => {
			setValues((state) => {
				return new UpdateTicketCommentRequest({
					...state,
					taskDate: undefined,
					taskStatusId: undefined,
					taskUserId: undefined
				})
			})
		},
		[]
	)

	const updateTaskCallback = useCallback(
		(task: UpdateTicketCommentRequest) => {
			setValues((state) => {
				return new UpdateTicketCommentRequest({
					...state,
					taskDate: task.taskDate,
					taskStatusId: task.taskStatusId,
					taskUserId: task.taskUserId
				})
			})
		},
		[]
	)

	const renderFooterCallback = useCallback(
		(disabled: boolean, handleSubmitCallback: (() => void), isSubmitting: boolean) => {
			return (
				<SmartFormGroup label=' '>
					<RowContainer justifyContent='space-between'>
						<RowContainer>
							<Task
								task={values}
								updateTask={updateTaskCallback}
								removeTask={removeTaskCallback}
							/>
							{attachmentsContent}
						</RowContainer>
						<RowContainer>
							<AddTaskButton
								disabled={disabled || !!values.taskStatusId}
								addTask={submitTaskCallback}
							/>
							<AddAttachmentButton
								value={values.newAttachments}
								onChange={addAttachmentCallback}
								disabled={disabled}
								multiple
							/>
							<Button
								text='Save comment'
								onClick={handleSubmitCallback}
								isLoading={isSubmitting}
								disabled={disabled}
							/>
						</RowContainer>
					</RowContainer>
				</SmartFormGroup>
			)
		},
		[submitTaskCallback, addAttachmentCallback, attachmentsContent, removeTaskCallback, updateTaskCallback, values]
	)

	return (
		<ColumnContainer margin='xlarge'>
			<Form
				values={values}
				onChange={setValues}
				onSubmit={saveCallback}
				onCancel={cancelCallback}
				hideSubmitButton
				hideCancelButton
				render={() => (
					<TextEditorField
						id='comment'
						label='Comment'
						isRequired
					/>
				)}
				renderAdditionalButtons={renderFooterCallback}
			/>
			<WithFetch fetchFunction={fetchDataCallback} refetching={isFetching}>
				<ColumnContainer>
					{commentsMemo}
				</ColumnContainer>
			</WithFetch>
		</ColumnContainer>
	)
}
