import { TestDashboardFilterRequest } from 'services/tenantManagementService';

export class FilterModel extends TestDashboardFilterRequest {
	projectId!: number
	testCycleId!: number

	constructor(data?: FilterModel) {
		super(data);
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
    }
}
