import { useCallback, useState } from 'react';
import { getCompanyInfoAction, persistCompanyInfoAction, updateCompanyInfoAction } from './action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { CompanyRequestModel, CompanyResponseModel } from 'services/tenantManagementService';
import { CompanyInfoForm } from './CompanyInfoForm';
import notifications from 'components/Notification/notification';
import { CompanyInfoHelp } from './CompanyInfoHelp';
import WithFetch from 'features/Fetch/WithFetch';
import { ContentShell } from 'features/Content/ContentShell';

export const CompanyInfo = () => {
	const [companyInfo, setCompanyInfo] = useState(new CompanyResponseModel());

	const fetchDataCallback = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getCompanyInfoAction);
			if (response.success) {
				setCompanyInfo(response.value!);
			}
		},
		[]
	);

	const saveCallback = useCallback(
		async (newCompanyInfo: CompanyRequestModel) => {
			const bindedAction = updateCompanyInfoAction.bind(null, newCompanyInfo);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setCompanyInfo(response.value!);
				persistCompanyInfoAction();
				notifications.success('Company information is updated.');
			} else {
				return convertResponseErrors(response);
			}
		},
		[]
	);

	return (
		<ContentShell
			title='Company Informations'
			FloatingHelpComponent={CompanyInfoHelp}
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<CompanyInfoForm
					companyInfo={companyInfo}
					onSave={saveCallback}
				/>
			</WithFetch>
		</ContentShell>
	)
}
