import { HelpShell } from 'features/Help/HelpShell';

export const ExpensesGeneralHelp = () => {
	return (
		<HelpShell title='Expense General Configuration'>
			<p>In Expenses general configuration you can enable approval process. This means that expenses are approved by responsible project managers (or substitutes), but you can define if expenses can be approved also by resource manager.</p>
			<p>Expenses are approved only if they are project related.</p>
			<p><u>Notifications</u> <br />
				By enabling approval process, you will also automatically enable emails exchange between users and project managers when expenses are released for approval, rejected and approved.
			</p>
			<p>If your company would prefer not to use email exchange, you can enable Notifications in Mavles. Each user will receive notification in right upper corner of screen when expenses are released for approval, rejected or approved.</p>
		</HelpShell>
	)
}
