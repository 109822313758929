import { useCallback } from 'react';
import MenuContainer from 'containers/Menu';
import { ContentContainer } from 'features/Content';
import styles from './homePage.module.scss';
import HistoryListener from 'features/Routes/HistoryListener';
import { Route } from 'features/Routes/reducer';
import { getRoutesForCurrentUser } from 'features/Routes';
import { usePersistIntervalEffect, usePersistOnceEffect } from 'features/Persist/persistHooks';
import { persistUsersAction } from 'containers/Configuration/SiteConfiguration/UserAdministration/Users/action';
import { persistTenantAction } from 'features/Tenant/action';
import { persistCountriesAction } from 'features/Country/action';
import { persistCurrenciesAction } from 'features/Currency/action';
import { persistConfigureViewAction } from 'features/ConfigureView/action';
import { useRefreshTokenIntervalEffect } from 'features/Token/refreshToken';
import { Header } from './Header/Header';
import { persistOrganizationalUnitManagerProjectsAction, persistProjectsAction } from 'features/Project';
import { persistDefaultProjectAction } from 'containers/Configuration/MyConfiguration/DefaultProject/action';
import { persistCompanyInfoAction } from 'containers/Configuration/SiteConfiguration/CompanyInfo/action';
import { persistProjectStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Projects/action';

export const HomePage = () => {
	const persistOnceFunction = useCallback(
		() => {
			persistCountriesAction();
			persistCurrenciesAction();
			persistDefaultProjectAction();
			// needed only on initialization because those are per user, and frontend is updating changes both in redux and backend
			persistConfigureViewAction();
		},
		[]
	);

	usePersistOnceEffect(persistOnceFunction);

	const persistIntervalFunction = useCallback(
		() => {
			persistUsersAction();
			persistProjectsAction();
			persistOrganizationalUnitManagerProjectsAction();
			persistProjectStatusesAction();
			persistTenantAction();
			persistCompanyInfoAction();
		},
		[]
	);
	usePersistIntervalEffect(persistIntervalFunction);

	useRefreshTokenIntervalEffect();

	const { menuRoutes = [], headerRoutes = [] } = getRoutesForCurrentUser();

	const rootRoutes: Route[] = [
		...menuRoutes,
		...headerRoutes
	];

	return (
		<div className={styles.container}>
			<div className={styles.app_header}>
				<Header />
			</div>
			<div className={styles.app_content}>
				<MenuContainer
					items={menuRoutes}
				/>
				<ContentContainer
					rootRoutes={rootRoutes}
				/>
			</div>
			<HistoryListener rootRoutes={rootRoutes} />
		</div>
	)
}
