import { useCallback, useState } from 'react';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ScheduleResponse } from 'services/tenantManagementService';
import { Form, InputField } from 'components/Form';
import { propertyOf } from 'utils/propertyOf';

type Props = {
	item?: ScheduleResponse
	onSave(item: any): void
	onCancel(): void
}

export const ScheduleItemForm = ({ item, onSave, onCancel }: Props) => {
	const [values, setValues] = useState(item || new ScheduleResponse());

	const onSubmitCallback = useCallback(
		async () => await onSave(values),
		[onSave, values]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={onCancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<InputField
							id={propertyOf<ScheduleResponse>('wbs')}
							label='WBS'
							disabled
						/>
						<InputField
							id={propertyOf<ScheduleResponse>('name')}
							label='Task name'
							isRequired
							maxLength={240}
							focus
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
