import { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import { ColumnContainer, RowContainer } from 'components/Layout';
import VacationRequestForm from './VacationRequestForm';
import { TimeTravelStatusEnum, VacationRequestResponse } from 'services/tenantManagementService';
import { getFormatedId, EntityPrefixEnum } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, CrudSubRoutesEnum, useGoBackFromCrudAndPush } from 'features/Crud';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { getVacationRequestAction } from '../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import VacationRequestStatusTable from '../VacationRequestStatusTable/VacationRequestStatusTable';
import Export from '../Export/Export';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = {
	vacationRequestId: string;
}

const ReadMyVacationRequest = () => {
	const params: ParamType = useParams();
	const [vacationRequest, setVacationRequest] = useState(new VacationRequestResponse());
	const { persistedTimeAndTravelStatus, persistedVacationGeneralConfiguration } = useSelector((state: RootState) => state);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getVacationRequestAction.bind(null, parseInt(params.vacationRequestId));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setVacationRequest(response.value || new VacationRequestResponse());
			}
		},
		[params]
	)

	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read);

	const goUpdateCallback = useCallback(
		() => {
			goBackFromCrudAndPush(`${CrudSubRoutesEnum.Update}/${params.vacationRequestId}`);
		},
		[params, goBackFromCrudAndPush]
	)

	const showChangeMemo = useMemo(
		() => !persistedVacationGeneralConfiguration.value.enableApprovalProcess ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, vacationRequest.calculatedStatusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Rejected, vacationRequest.calculatedStatusId, persistedTimeAndTravelStatus.itemsMap),
		[persistedTimeAndTravelStatus, vacationRequest, persistedVacationGeneralConfiguration]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`View vacation request - ${getFormatedId(EntityPrefixEnum.VACATION_REQUEST, vacationRequest.id)}`}>
				<ColumnContainer margin='medium'>
					<RowContainer>
						{showChangeMemo && <Button text='Change' onClick={goUpdateCallback} />}
						<Export vacationRequest={vacationRequest} />
					</RowContainer>
					<VacationRequestForm
						vacationRequest={vacationRequest}
						crud={CrudEnum.Read}
					/>
					<VacationRequestStatusTable
						statuses={vacationRequest.statuses || []}
					/>
				</ColumnContainer>
			</ContentShell>
		</WithFetch>
	)
}

export default ReadMyVacationRequest;
