import { getTenantId } from 'features/Tenant/tenantUtils';
import { TaskClient, TaskTimesheetDeltaRequest } from 'services/tenantManagementService';

export const getTimesheetsTaskAction = (taskId: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.getTimesheets(tenantId, taskId, projectOrCategoryId, isProjectConnected)
}

export const updateTimesheetsTaskAction = (taskId: number, projectOrCategoryId: number, isProjectConnected: boolean, model: TaskTimesheetDeltaRequest ) => {
	const tenantId = getTenantId()!;
	const client = new TaskClient();
	return client.updateTimesheets(tenantId, taskId, projectOrCategoryId, isProjectConnected, model)
}
