import { getTenantId } from 'features/Tenant/tenantUtils';
import { getUserTableViewConfigAction, updateUserTableViewConfigAction } from 'features/UserTableViewConfig/action';
import { ExportDataModel, GenericFilterModelCollection, TestPlanClient } from 'services/tenantManagementService';

const viewType = 'myTestPackagesShowProcessSteps';

export const getMyTestPackagesGenericAction = (projectId: number, testCycleId: number, showProcessSteps: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.getMy(tenantId, projectId, testCycleId, showProcessSteps, genericFilter);
}

export const exportMyTestPlansAction = (projectId: number, testCycleId: number, showProcessSteps: boolean, exportModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.exportMyTestPlans(tenantId, projectId, testCycleId, showProcessSteps, exportModel);
}

export const getShowProcessStepsConfigAction = () => {
	return getUserTableViewConfigAction(viewType);
}

export const updateShowProcessStepsConfigAction = (showProcessSteps: boolean) => {
	return updateUserTableViewConfigAction(viewType, showProcessSteps);
}
