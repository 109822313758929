import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterProps, getRouteObject } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { ScheduleDashboard } from './Dashboard/ScheduleDashboard';
import Schedule from './Schedule/Schedule';
import { ProjectParamName } from 'features/Project';
import { ScheduleSubRoutesEnum, myDashboardSubRoute, scheduleSubRoute } from './routes';
import { persistOrganizationalUnitsAction } from 'containers/Configuration/SiteConfiguration/General/Company/OrganizationalUnits/action';

export const ScheduleRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistOrganizationalUnitsAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[ScheduleSubRoutesEnum.MY_DASHBOARD] &&
				<Route
					path={myDashboardSubRoute.url}
					render={() => <ScheduleDashboard />}
				/>
			}
			{routesMap[ScheduleSubRoutesEnum.SCHEDULE] &&
				<Route
					path={[
						`${scheduleSubRoute.url}/:${ProjectParamName}`,
						scheduleSubRoute.url,
					]}
					render={() => <Schedule />}
				/>
			}
			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
