export const getNumberArraysDiff = (originalArray: number[] | undefined, newArray: number[] | undefined) => {
	const createReturnValue = (added: number[], removed: number[]) => ({ added, removed })

	if (!newArray) {
		return createReturnValue([], [])
	}

	if (!originalArray) {
		return createReturnValue([...newArray], [])
	}

	const originalArrayHashSet = new Set(originalArray)
	const newArrayHashSet = new Set(newArray)

	const added: number[] = []
	const removed: number[] = []

	newArray.forEach(x => {
		if (!originalArrayHashSet.has(x)) {
			added.push(x)
		}
	})

	originalArray.forEach(x => {
		if (!newArrayHashSet.has(x)) {
			removed.push(x)
		}
	})

	return createReturnValue(added, removed)
}
