import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CrudEnum, CreateComponentProps } from 'features/Crud';
import { getUserInfo } from 'utils/storageUtils';
import { ChangeRequestFullResponse, TicketResponse } from 'services/tenantManagementService';
import { useParams, useHistory } from 'react-router-dom';
import { phasesTabs } from './Tabs/phasesTabs';
import Tabs from 'components/Tabs/Tabs';
import { getInitialStatus } from 'features/StatusResponse/statusResponse';
import { RootState } from 'base/reducer/reducer';
import { ProjectPickerParams } from 'features/Project'
import { ContentShell } from 'features/Content/ContentShell';
import ChangeRequestsHelp from '../Help/ChangeRequestsHelp';
import { ContentSubtitle } from 'features/Content';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';

export type TabProps = {
	projectId: number
	changeRequest: ChangeRequestFullResponse
	crud: CrudEnum
	publishDataChanged(): void
}

const CreateChangeRequest = ({ publishDataChanged }: CreateComponentProps) => {
	const history = useHistory();
	const ticketThatInitiated = (history.location.state as any | undefined)?.ticket as TicketResponse;
	const isInitiatedFromTicket = !!ticketThatInitiated;

	const { persistedChangeRequestStatus } = useSelector((state: RootState) => state);
	const params: ProjectPickerParams = useParams();
	const projectId = parseInt(params.projectId as string);

	const newChangeRequest = useMemo(
		() => {
			const model = new ChangeRequestFullResponse();
			const userInfo = getUserInfo();
			model.requestedByUserId = userInfo.id;
			const initialStatus = getInitialStatus(persistedChangeRequestStatus.items);
			if (initialStatus) {
				model.statusId = initialStatus.id;
			}
			if (isInitiatedFromTicket) {
				model.ticketIds = [ticketThatInitiated.id];
				model.name = ticketThatInitiated.name;
				model.requestedByUserId = ticketThatInitiated.createdByUserId;
				model.initiatedBy = ticketThatInitiated.reportedBy;
				model.requestedOn = ticketThatInitiated.reportedOn;
				model.impactId = ticketThatInitiated.impactId;
				model.urgencyId = ticketThatInitiated.urgencyId;
				model.description = ticketThatInitiated.description;
			}
			return model;
		},
		[persistedChangeRequestStatus.items, isInitiatedFromTicket, ticketThatInitiated]
	)

	return (
		<>
			<ContentSubtitle title={`Project - ${getFormatedId(EntityPrefixEnum.PROJECT, projectId)}`} />
			<ContentShell
				title='Create Change Request'
				FloatingHelpComponent={ChangeRequestsHelp}
			>
				<Tabs
					tabs={phasesTabs}
					tabComponentProps={{
						projectId,
						changeRequest: newChangeRequest,
						crud: CrudEnum.Create,
						publishDataChanged
					} as TabProps}
				/>
			</ContentShell>
		</>
	)
}

export default CreateChangeRequest;
