import { View, StyleSheet } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import { CostExpenseTypeResponse, IdNameResponse, TenantResponseModel, TravelExpenseItemResponse, TravelExpenseResponse } from 'services/tenantManagementService';
import { PersistItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import { useFormattedCurrencyValueWithArgsCallback } from 'features/TableColumns/persistedHooks';
import { FormField } from 'components/Export';

type Props = {
	travelExpense: TravelExpenseResponse;
	persistedTravelCostTypes: PersistItemsReducer<CostExpenseTypeResponse>
	persistedTenant: PersistValueReducer<TenantResponseModel>,
	persistedCurrency: PersistItemsReducer<IdNameResponse>,
	isSerbiaCountry: boolean
}

const sanitizeValue = (value: string | null | undefined) => value || '-'

const TravelExpenseDocumentTable = ({ travelExpense, persistedTravelCostTypes, persistedTenant, persistedCurrency, isSerbiaCountry}: Props) => {
	const getFormattedCurrencyValue = useFormattedCurrencyValueWithArgsCallback(persistedCurrency, persistedTenant);
	const items = travelExpense.travelExpenseItems

	if (!items || items.length === 0) {
		return null;
	}

	// Bug 295, only for serbian export
	const serbiaCurrencyId = 120
	const currencyId = persistedTenant.value.currencyId;

	const currencySum: any = {
		[serbiaCurrencyId]: 0
	};

	const formattedForeignSum: string[] = []

	if (isSerbiaCountry) {
		// if currency is not RSD, set site currency to 0
		if (currencyId !== serbiaCurrencyId) {
			currencySum[currencyId] = 0;
		}
		for (let i = 0; i < items.length; i++) {
			const current = items[i];

			if (currencyId === serbiaCurrencyId) {
				currencySum[serbiaCurrencyId] += current.amountProjectCurrency;
			} else if (current.receiptCurrencyId === serbiaCurrencyId) {
				currencySum[serbiaCurrencyId] += current.amountReceiptCurrency || 0;
			} else {
				currencySum[currencyId] += current.amountProjectCurrency;
			}
		}

		// subtract advance payment
		currencySum[currencyId] -= travelExpense.advancePayment || 0;

		Object.keys(currencySum).forEach(key => {
			if (parseInt(key) !== serbiaCurrencyId) {
				formattedForeignSum.push(`${currencySum[key].toFixed(2)} ${persistedCurrency.itemsMap[parseInt(key)]?.symbol}`);
			}
		});
	}

	return (
		<View style={styles.container} wrap={false}>
			<Table data={items}>
				<TableHeader>
					<TableCell style={styles.header}>{isSerbiaCountry ? 'Vrsta troška' : 'Cost type'}</TableCell>
					<TableCell style={styles.header}>{isSerbiaCountry ? 'Iznos u devizama' : 'Amount in project currency'}</TableCell>
					<TableCell style={styles.header}>{isSerbiaCountry ? 'Iznos na računu' : 'Amount in receipt currency'}</TableCell>
					<TableCell style={styles.header}>{isSerbiaCountry ? 'Valuta računa' : 'Receipt currency'}</TableCell>
				</TableHeader>
				<TableBody>
					<DataTableCell style={styles.text} getContent={(item: TravelExpenseItemResponse) => sanitizeValue(persistedTravelCostTypes.itemsMap[item.costTypeId]?.name)} />
					{/* ukoliko je kao valuta izabran RSD i korisnik zeli izvestaj na srpskom, ne prikazuje se iznos u valuti na tenantu */}
					<DataTableCell style={styles.text} getContent={(item: TravelExpenseItemResponse) => sanitizeValue(isSerbiaCountry && item.receiptCurrencyId === serbiaCurrencyId ? '' : getFormattedCurrencyValue(item.amountProjectCurrency))} />
					<DataTableCell style={styles.text} getContent={(item: TravelExpenseItemResponse) => sanitizeValue(item.amountReceiptCurrency?.toFixed(2))} />
					<DataTableCell style={styles.text} getContent={(item: TravelExpenseItemResponse) => sanitizeValue(item.receiptCurrencyId ? persistedCurrency.itemsMap[item.receiptCurrencyId]?.symbol : '')} />
				</TableBody>
			</Table>
			{isSerbiaCountry && (
			<View>
				<FormField
					label='Ukupno za isplatu u dinarima'
					value={`${currencySum[serbiaCurrencyId]?.toFixed(2) || 0} ${persistedCurrency.itemsMap[serbiaCurrencyId]?.symbol}`}
				/>
				{formattedForeignSum.length > 0 && (
					<FormField
						label='Ukupno za isplatu u devizama'
						value={formattedForeignSum.join(',')}
					/>
				)}
			</View>
			)}
		</View>
	);
};

export default TravelExpenseDocumentTable;

const styles = StyleSheet.create({
	container: {
		paddingVertical: 10,
	},
	header: {
		flexWrap: 'wrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: 2,
		fontSize: 10,
		fontWeight: 500
	},
	text: {
		flexWrap: 'wrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: 2,
		fontSize: 10,
	}
})
