import { WithProjectPicker, pmOrSpmPermission } from 'features/Project';
import { TrainingPlanTable } from './TrainingPlanTable';
import { TrainingCycleComponentProps, WithTrainingCyclePicker } from 'features/Training/WithTrainingCyclePicker';
import { propertyOf } from 'utils/propertyOf';
import { ModuleActivityEnum, TrainingPermission } from 'services/tenantManagementService';

const TrainingPlan = ({ trainingCycle, project, disabledEdit }: TrainingCycleComponentProps) => {
	return (
		<TrainingPlanTable
			project={project}
			disabledEdit={disabledEdit}
			trainingCycle={trainingCycle}
		/>
	)
}

const withTrainingCycle = WithTrainingCyclePicker(TrainingPlan);
const withProject = WithProjectPicker(withTrainingCycle, ModuleActivityEnum.Training, false, propertyOf<TrainingPermission>('viewTrainingPlan'), pmOrSpmPermission);

export default withProject;
