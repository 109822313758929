import { useCallback, useState } from 'react';
import { HistoryLogDialog, HistoryLogType } from "features/HistoryLog";
import { getHistoryAction } from '../action';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useUserFullNameCallback, useStatusCallback } from 'features/TableColumns/persistedHooks';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

type Props = {
	projectId: number
	testCycleId: number
	testPlanId: number
}

export const MyTestHistoryLog = ({ projectId, testCycleId, testPlanId }: Props) => {
	const { persistedUser, persistedTestCaseStatus } = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedTestCaseStatus);

	const [log, setLog] = useState<HistoryLogType[]>([]);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getHistoryAction.bind(null, projectId, testCycleId, testPlanId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				let newLog: HistoryLogType[] = [];

				const items = response.items || [];
				for (const item of items) {
					const logItem: HistoryLogType = {
						when: item.updatedOn,
						who: getUserFullName(item.userId),
						changes: []
					}

					if (item.statusId) {
						logItem.changes.push({
							title: `Status - ${item.processOrStepId}`,
							old: getStatus(item.statusId.oldValue as any),
							new: getStatus(item.statusId.newValue as any)
						});
					}

					newLog.push(logItem);
				}

				setLog(newLog.reverse());
			}
		},
		[getUserFullName, projectId, testCycleId, testPlanId, getStatus]
	)

	return (
		<HistoryLogDialog
			title='Test Plan History Log'
			log={log}
			onOpen={fetchDataCallback}
		/>
	)
}
