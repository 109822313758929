import { useMemo } from 'react';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { useApplyStatusColorCallback, useFormattedProjectIdNameCallback, useStatusCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { MeetingDecisionResponse, MeetingStatusResponse, TenantIsActiveResponse, UserModel } from 'services/tenantManagementService';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { getMeetingRoute } from 'utils/routeUtils';
import { customTextAreaFormatter } from 'components/Table/BaseTable/helpers/customTextAreaFormatter';
import { ProjectOrCategoryType } from 'features/Project';

const defaultVisibleColumns = [
	propertyOf<MeetingDecisionResponse>('meetingId'),
	propertyOf<MeetingDecisionResponse>('date'),
	propertyOf<MeetingDecisionResponse>('description'),
	propertyOf<MeetingDecisionResponse>('userId'),
	propertyOf<MeetingDecisionResponse>('statusId')
]

export const useTableColumnsMemo = (projectOrCategory: ProjectOrCategoryType, configureViewKey: string) => {
	const {
		persistedConfigureView,
		persistedUser,
		persistedMeetingStatus,
		persistedMeetingType,
		persistedTimeTravelNonProjectCategories,
		persistedProject
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedMeetingStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedMeetingStatus);
	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<MeetingDecisionResponse>('meetingId')]: {
					title: 'Meeting ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.MEETING,
					dbFilterFieldPath: 'MeetingRefId',
					dbExportFieldPath: 'MeetingRefId',
					formatter: (cell: any) => `<a style='text-decoration:underline' href="${window.location.protocol}//${window.location.host}${getMeetingRoute(cell.getValue(), projectOrCategory.projectOrCategoryId, projectOrCategory.isProjectConnected)}" target="_blank">${getFormatedId(EntityPrefixEnum.MEETING, cell.getValue())}</a>`
				},
				[propertyOf<MeetingDecisionResponse>('date')]: {
					title: 'Date',
					fieldType: FieldTypeEnum.Date,
					dbFilterFieldPath: 'Meeting.Starts',
					dbExportFieldPath: 'Meeting.Starts',
				},
				[propertyOf<MeetingDecisionResponse>('subject')]: {
					title: 'Subject',
					fieldType: FieldTypeEnum.String,
					dbFilterFieldPath: 'Meeting.Subject',
					dbExportFieldPath: 'Meeting.Subject',
				},
				[propertyOf<MeetingDecisionResponse>('description')]: {
					title: 'Decision',
					fieldType: FieldTypeEnum.String,
					formatter: customTextAreaFormatter
				},
				[propertyOf<MeetingDecisionResponse>('meetingTypeId')]: {
					title: 'Meeting type',
					formatter: (cell: any) => {
						const id = cell.getValue();
						return persistedMeetingType.itemsMap[id]?.name || '';
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedMeetingType.items,
					getItemId: (option: TenantIsActiveResponse) => option.id,
					getItemText: (option: TenantIsActiveResponse) => option.name,
					dbFilterFieldPath: 'Meeting.MeetingTypeRefId',
					dbExportFieldPath: 'Meeting.MeetingTypeRefId',
				},
				[propertyOf<MeetingDecisionResponse>('userId')]: {
					title: 'Organizer',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
					dbFilterFieldPath: 'Meeting.UserId',
					dbExportFieldPath: 'Meeting.UserId',
				},
				[propertyOf<MeetingDecisionResponse>('projectOrCategoryId')]: {
					title: 'Project or category',
					formatter: (cell: any) => {
						const isProject = (cell.getData() as MeetingDecisionResponse).isProjectConnected;
						if (isProject) {
							return formatProjectIdName(cell.getValue());
						}
						return persistedTimeTravelNonProjectCategories.itemsMap[cell.getValue()]?.name || '';
					},
					fieldType: FieldTypeEnum.None
				},
				[propertyOf<MeetingDecisionResponse>('statusId')]: {
					title: 'Meeting minutes status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedMeetingStatus.items,
					getItemId: (option: MeetingStatusResponse) => option.id,
					getItemText: (option: MeetingStatusResponse) => option.name,
					dbFilterFieldPath: 'Meeting.StatusRefId',
					dbExportFieldPath: 'Meeting.StatusRefId'
				}
			}
		},
		[projectOrCategory, formatProjectIdName, getUserFullName, getStatus, applyStatusColor, persistedUser, persistedMeetingStatus.items, persistedMeetingType, persistedTimeTravelNonProjectCategories]
	)

	return useVisibleColumns(columns, visibleColumns);
}
