import { useCallback, useMemo, useState } from 'react';
import { Title } from '../_components';
import RegistrationForm from './RegistrationForm';
import SuccessMessage from './SuccessMessage';

const Registration = () => {
	const [registered, setRegistered] = useState(false);
	const [userName, setUserName] = useState('');

	const successRegistrationCallback = useCallback(
		(registeredUserName: string) => {
			setUserName(registeredUserName);
			setRegistered(true);
		},
		[]
	);

	const content = useMemo(
		() => {
			if (registered) {
				return <SuccessMessage userName={userName} />
			} else {
				return <RegistrationForm onSuccess={successRegistrationCallback} />
			}
		},
		[registered, userName, successRegistrationCallback]
	)

	return (
		<>
			<Title text='Registration' />
			{content}
		</>
	);
};

export default Registration;
