import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { ProjectIssueResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { getIssueAction, updateIssueAction } from '../action';
import { publishMajorIssuesChanged } from '../majorIssuesChanged';
import { MajorIssuesForm } from '../MajorIssuesForm';
import { Dialog } from 'components/Dialog';
import WithFetch from 'features/Fetch/WithFetch';
import { ReportComponentParams } from '../../Table/TableButtons';
import { useGoBackFromUpdate } from 'features/Crud';

type ParamType = ReportComponentParams & {
	majorIssueId: string
}

export const UpdateMajorIssue = () => {
	const params: ParamType = useParams();
	const reportId = parseInt(params.reportId);
	const majorIssueId = parseInt(params.majorIssueId);

	const [majorIssue, setMajorIssue] = useState(new ProjectIssueResponse());

	const goBackFromUpdate = useGoBackFromUpdate(true);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getIssueAction.bind(null, reportId, majorIssueId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setMajorIssue(response.value || new ProjectIssueResponse());
			}

		},
		[reportId, majorIssueId]
	)

	const onSubmitCallback = useCallback(
		async (majorIssue: ProjectIssueResponse) => {
			const bindedAction = updateIssueAction.bind(null, reportId, majorIssue);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Major issue successfully updated');
				goBackFromUpdate();
				publishMajorIssuesChanged();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, reportId]
	);

	return (
		<Dialog
			title='Update Major issue'
			onClose={goBackFromUpdate}
			open
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<MajorIssuesForm issue={majorIssue} save={onSubmitCallback} cancel={goBackFromUpdate} />
			</WithFetch>
		</Dialog>
	)
};
