import { Dialog } from "components/Dialog";
import WithFetch from "features/Fetch/WithFetch";
import { useCallback, useState } from "react";
import { ProjectResponse, ScheduleResponse, UpdateScheduleRequest } from "services/tenantManagementService";
import { convertResponseErrors, tryCatchJsonByAction } from "utils/fetchUtils";
import { getScheduleAction, updateScheduleAction } from "../../action";
import { UpdateTaskForm } from "./UpdateTaskForm";
import { getNumberArraysDiff } from 'utils/arrayUtil';

type Props = {
	isOpen: boolean
	setIsOpen(isOpen: boolean): void
	afterBackendSave(task: ScheduleResponse): void
	ganttChartRef: React.MutableRefObject<any>
	project: ProjectResponse
}

export const UpdateTask = ({ isOpen, setIsOpen, afterBackendSave, ganttChartRef, project }: Props) => {
	const [task, setTask] = useState(new ScheduleResponse());

	const closeDialogCallback = useCallback(
		() => setIsOpen(false),
		[setIsOpen]
	);

	const fetchTaskCallback = useCallback(
		async () => {
			const task = ganttChartRef.current.getSelectedTask();
			const bindedAction = getScheduleAction.bind(null, project.id, parseInt(task!.id));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setTask(response.value || new ScheduleResponse());
			}
		},
		[project.id, ganttChartRef]
	)

	// TODO:gantt configuration autocomplete parent kada su deca complete
	// i mozda kada se bilo sta vezano za progress promeni kod dece
	const onSaveCallback = useCallback(
		async (values: ScheduleResponse) => {
			const responsibleDiff = getNumberArraysDiff(task.responsibleUserIds, values.responsibleUserIds);
			const accountableDiff = getNumberArraysDiff(task.accountableUserIds, values.accountableUserIds);
			const consultedDiff = getNumberArraysDiff(task.consultedUserIds, values.consultedUserIds);
			const informedDiff = getNumberArraysDiff(task.informedUserIds, values.informedUserIds);

			const model = new UpdateScheduleRequest({
				...values,
				insertedResponsibleUserIds: responsibleDiff.added,
				removedResponsibleUserIds: responsibleDiff.removed,
				insertedAccountableUserIds: accountableDiff.added,
				removedAccountableUserIds: accountableDiff.removed,
				insertedConsultedUserIds: consultedDiff.added,
				removedConsultedUserIds: consultedDiff.removed,
				insertedInformedUserIds: informedDiff.added,
				removedInformedUserIds: informedDiff.removed
			});

			const bindedAction = updateScheduleAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				afterBackendSave(response.value || new ScheduleResponse());
				closeDialogCallback();
			} else {
				return convertResponseErrors(response);
			}
		},
		[task, closeDialogCallback, project.id, afterBackendSave]
	)

	return (
		<Dialog
			open={isOpen}
			title='Change task'
			onClose={closeDialogCallback}
			size='xxxlarge'
		>
			<WithFetch fetchFunction={fetchTaskCallback}>
				<UpdateTaskForm
					task={task}
					onSave={onSaveCallback}
					onCancel={closeDialogCallback}
					project={project}
				/>
			</WithFetch>
		</Dialog>
	)
}
