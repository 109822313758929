import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ColumnContainer } from 'components/Layout';
import { InsertTaskCommentRequest, TaskCommentResponse } from 'services/tenantManagementService';
import { createTaskCommentAction, getTaskCommentsAction } from './action';
import WithFetch from 'features/Fetch/WithFetch';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Form, globalErrorKey, TextEditor, TextEditorField } from 'components/Form';
import { RootState } from 'base/reducer/reducer';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { formatDateTime } from 'utils/dateTimeUtils';
import { TaskTabComponentProps } from '../taskTabs';

export const TaskCommunication = ({ projectOrCategoryId, isProjectConnected, taskId, isRead }: TaskTabComponentProps) => {
	const { persistedUser } = useSelector((state: RootState) => state);

	const [values, setValues] = useState(new InsertTaskCommentRequest());
	const [comments, setComments] = useState<TaskCommentResponse[]>([]);
	const [isFetching, setIsFetching] = useState(true);

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const fetchDataCallback = useCallback(
		async () => {
			setIsFetching(true);
			const response = await getTaskCommentsAction(taskId, projectOrCategoryId, isProjectConnected);
			setIsFetching(false);
			if (response.success && response.items) {
				setComments(response.items);
			}
		},
		[projectOrCategoryId, isProjectConnected, taskId]
	)

	const saveCallback = useCallback(
		async () => {
			// when click on TextEditor, value will be empty paragraph tag
			// prevent create empty Task comment and throw error
			if (values.comment === '<p></p>\n') {
				return Promise.resolve({[globalErrorKey]: 'Comment can not be empty, this field is required'});
			}

			const modelRequest = new InsertTaskCommentRequest({
				...values,
				taskId,
				projectOrCategoryId,
				isProjectConnected
			})

			const bindedAction = createTaskCommentAction.bind(null, modelRequest);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setValues(new InsertTaskCommentRequest());
				fetchDataCallback();
			} else {
				return convertResponseErrors(response);
			}
		},
		[values, taskId, projectOrCategoryId, isProjectConnected, fetchDataCallback]
	)

	const cancelCallback = useCallback(
		() => {
			setValues(new InsertTaskCommentRequest());
		},
		[]
	)

	const commentsMemo = useMemo(
		() => {
			return comments
				.sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime())
				.map((comment) =>
					(
						<SmartFormGroup key={comment.id} label={getUserFullName(comment.userId)}>
							<TextEditor
								value={comment.comment}
								disabled
							/>
							<small>{formatDateTime(comment.createdOn)}</small>
						</SmartFormGroup>
					)
				);
		},
		[comments, getUserFullName]
	)

	return (
		<ColumnContainer>
			<Form
				values={values}
				onChange={setValues}
				onSubmit={saveCallback}
				onCancel={cancelCallback}
				submitButtonText='Add Comment'
				cancelButtonText='Clear text'
				disabled={isRead}
				hideButtons={isRead}
				render={() => (
					<SmartContainer>
						<SmartItem size='large'>
							<TextEditorField
								id='comment'
								label='Comment'
								isRequired
							/>
						</SmartItem>
					</SmartContainer>
				)}
			/>
			<WithFetch fetchFunction={fetchDataCallback} refetching={isFetching}>
				<SmartContainer>
					<SmartItem>
						{commentsMemo}
					</SmartItem>
				</SmartContainer>
			</WithFetch>
		</ColumnContainer>
	)
}
