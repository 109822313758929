import Spinner, { ClipSpinner } from 'components/Spinner';
import { useMemo } from 'react';
import styles from './widget.module.scss';

export type WidgetProps = {
	title: string
	loading?: boolean
	className?: string
	contentClassName?: string
	height?: string
	minHeight?: string
	maxHeight?: string
}

type Props = WidgetProps & {
	children: any
}

export const Widget = ({ title, loading, className, contentClassName, children, height, minHeight, maxHeight }: Props) => {
	const style = useMemo(
		() => {
			return {
				height,
				minHeight,
				maxHeight
			}
		},
		[height, minHeight, maxHeight]
	)

	return (
		<div className={`${styles.container} ${className}`} style={style}>
			<div className={styles.inner}>
				<div className={styles.header}>{title}</div>
				<div className={`${styles.content} ${contentClassName || ''}`}>
					{loading &&
						<Spinner>
							<ClipSpinner size={32} />
						</Spinner>
					}
					<div style={{ visibility: loading ? 'hidden' : 'visible', height: '100%' }}>{children}</div>
				</div>
			</div>
		</div>
	)
}
