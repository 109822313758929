import { getTenantId } from "features/Tenant/tenantUtils";
import { InsertScopeItemLevelRequest, ScopeItemLevelClient, UpdateOrderRequest, UpdateScopeItemLevelRequest } from "services/tenantManagementService";

export const createScopeItemAction = (projectId: number, model: InsertScopeItemLevelRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScopeItemLevelClient();
	return client.create(tenantId, projectId, model);
}

export const updateScopeItemsAction = (projectId: number, model: UpdateScopeItemLevelRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScopeItemLevelClient();
	return client.update(tenantId, projectId, model);
}

export const deleteScopeItemAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ScopeItemLevelClient();
	return client.delete(tenantId, projectId, id);
}

export const reorderScopeItemsAction = (projectId: number, modelOrders: UpdateOrderRequest[]) => {
	const tenantId = getTenantId()!;
	const client = new ScopeItemLevelClient();
	return client.updateOrders(tenantId, projectId, modelOrders);
}

export const removeAttachmentAction = (projectId: number, id: number ) => {
	const tenantId = getTenantId()!;
	const client = new ScopeItemLevelClient();
	return client.removeAttachment(tenantId, projectId, id);
}
