import { useCallback, useState } from 'react';
import WithFetch from 'features/Fetch/WithFetch';
import { getProjectSLAAction, updateProjectSLAAction } from './action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { ProjectResponse, UpdateProjectSLARequest, UpdateSLAResponseTimeRequest, ProjectSLAResponse, ResolutionTimeBasedOnEnum, TicketStatusEnum, TimeMeasureEnum, PriorityResponse, ResponseTimeResponse } from 'services/tenantManagementService';
import notifications from 'components/Notification/notification';
import { TableField, CheckboxField, Form, RadioField, InputNumberField, FormGroup, AutoCompleteField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ColumnContainer } from 'components/Layout';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import PriorityInfoTable from 'features/Priority/PriorityInfoTable';
import { propertyOf } from 'utils/propertyOf';
import { Calendar } from './Calendar';

type Props = {
	project: ProjectResponse
}

const timeMeasures = [
	{ id: TimeMeasureEnum.Days, text: TimeMeasureEnum.Days },
	{ id: TimeMeasureEnum.Hours, text: TimeMeasureEnum.Hours },
	{ id: TimeMeasureEnum.Minutes, text: TimeMeasureEnum.Minutes }
]

const closingStatuses = [
	{ id: TicketStatusEnum.Closed, text: TicketStatusEnum.Closed },
	{ id: TicketStatusEnum.Resolved, text: TicketStatusEnum.Resolved }
]

const resolutionTimeCalculationStatuses = [
	{ id: TicketStatusEnum.New, text: 'Time when ticket is created' },
	{ id: TicketStatusEnum.Accepted, text: 'Time when ticket is accepted' }
]

const timesBasedOn = [
	{ id: ResolutionTimeBasedOnEnum.Hours24, text: '24 Hours' },
	{ id: ResolutionTimeBasedOnEnum.RegularWorkingHours, text: 'Regular working hours' }
]

const SLA = ({ project }: Props) => {
	const [values, setValues] = useState(new ProjectSLAResponse());
	const [initialValues, setInitialValues] = useState(new ProjectSLAResponse());

	const {
		persistedTicketImpact,
		persistedTicketUrgency,
		persistedTicketPriority
	} = useSelector((state: RootState) => state);

	const fetchData = useCallback(
		async () => {
			const bindedAction = getProjectSLAAction.bind(null, project.id)
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setValues(response.value || new ProjectSLAResponse());
				setInitialValues(response.value || new ProjectSLAResponse());
			}
		},
		[project.id]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const times: UpdateSLAResponseTimeRequest[] = [];

			for (const time of values.responseTimes) {
				times.push(new UpdateSLAResponseTimeRequest(time));
			}

			const model = new UpdateProjectSLARequest({
				...values,
				times
			})
			const bindedAction = updateProjectSLAAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('SLA is updated.');
				setValues(response.value || new ProjectSLAResponse());
				setInitialValues(response.value || new ProjectSLAResponse());
			} else {
				return convertResponseErrors(response);
			}

		},
		[project.id, values]
	);

	const handleCancel = useCallback(
		() => setValues(initialValues),
		[initialValues]
	);

	return (
		<WithFetch fetchFunction={fetchData}>
			<Form
				values={values}
				initialValues={initialValues}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={handleCancel}
				render={() => (
					<SmartContainer>
						<SmartItem>
							<SmartContainer>
								<SmartItem>
									<CheckboxField
										id={propertyOf<ProjectSLAResponse>('isActive')}
										labelBefore='Activate SLA for project'
										multiline
									/>
									<RadioField
										id={propertyOf<ProjectSLAResponse>('timeMeasure')}
										label='Time measure'
										items={timeMeasures}
										multiline
									/>
									<RadioField
										id={propertyOf<ProjectSLAResponse>('closingStatus')}
										label='Calculate resolution time based on status'
										items={closingStatuses}
										multiline
									/>
									<RadioField
										id={propertyOf<ProjectSLAResponse>('resolutionTimeCalculationStatus')}
										label='Calculate resolution time from'
										items={resolutionTimeCalculationStatuses}
										multiline
									/>
									<RadioField
										id={propertyOf<ProjectSLAResponse>('resolutionTimeBasedOn')}
										label='Response and resolution time is calculated based on'
										items={timesBasedOn}
										multiline
									/>
								</SmartItem>
								<SmartItem>
									<Calendar />
								</SmartItem>
								<SmartItem>
									<CheckboxField
										id={propertyOf<ProjectSLAResponse>('excludeOnHoldTime')}
										labelBefore='Calculate resolution/response time excluding time while ticket was in On-hold status'
										multiline
									/>
								</SmartItem>
							</SmartContainer>
						</SmartItem>
						<SmartItem>
							<FormGroup title='Ticket Priority'>
								<ColumnContainer margin='xlarge'>
									<PriorityInfoTable
										impacts={persistedTicketImpact.items}
										urgencies={persistedTicketUrgency.items}
										priorities={persistedTicketPriority.items}
									/>
									<TableField
										id={propertyOf<ProjectSLAResponse>('responseTimes')}
										headers={[
											{ label: 'Priority', size: 5 },
											{ label: 'Response time', size: 3 },
											{ label: 'Resolution time', size: 3 }
										]}
										getRowData={() => {
											return {
												isDeletable: false,
												fields: [
													<AutoCompleteField
														id={propertyOf<ResponseTimeResponse>('priority')}
														disabled
														items={persistedTicketPriority.items}
														getItemId={(item: PriorityResponse) => item.semantics}
														getItemText={(item: PriorityResponse) => item.name}
														loading={persistedTicketPriority.fetching}
													/>,
													<InputNumberField
														id={propertyOf<ResponseTimeResponse>('responseTime')}
														isRequired
														suffix={` ${values.timeMeasure}`}
														allowNegative={false}
													/>,
													<InputNumberField
														id={propertyOf<ResponseTimeResponse>('resolutionTime')}
														isRequired
														suffix={` ${values.timeMeasure}`}
														allowNegative={false}
													/>
												]
											}
										}}
									/>
								</ColumnContainer>
							</FormGroup>
						</SmartItem>
					</SmartContainer>
				)}
			/>
		</WithFetch>
	);
};

export default SLA;
