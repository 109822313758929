import { ThemeEnum } from "features/Theme";

export const colorsMap = {
	green_10: '#87bc45'
}

export const successColor = '#87bc45';
export const errorColor = '#f44336'; // '#ea5545';
export const infoColor = '#27aeef';
export const warningColor = '';

	// infoColor,
	// successColor,
	// "#bdcf32",
	// "#ede15b",
	// "#ff9800", // "#edbf33",
	// "#b33dc6",
	// "#ef9b20",
	// "#f46a9b",
	// errorColor



export const getColorByTheme = (colorKey: string, theme: ThemeEnum) => {

}
