import { useState, useEffect, useCallback, useMemo } from 'react'
import { Form, InputField, TextareaField } from 'components/Form';
import { ColumnContainer } from 'components/Layout';
import notifications from 'components/Notification/notification';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { SurveyResponse, SurveyStatusEnum, UpdateSurveyRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { updateSurveyAction } from '../../action';
import { publishUpdateSurveyChanged } from '../updateSurveyChanged';
import { UpdateSurveyTabsProps } from '../UpdateSurveyTabs';

const DesignTab = ({ project, survey }: UpdateSurveyTabsProps) => {
	const [initialValue, setInitialValue] = useState(survey || new SurveyResponse());
	const [value, setValue] = useState(survey || new SurveyResponse());

	const disabledMemo = useMemo(
		() => survey.status === SurveyStatusEnum.Released,
		[survey]
	)

	useEffect(
		() => {
			setInitialValue(survey || new SurveyResponse());
			setValue(survey || new SurveyResponse());
		},
		[survey],
	)

	const onCancelCallback = useCallback(
		() => {
			setValue(initialValue);
		},
		[initialValue]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const updateSurveyRequest = new UpdateSurveyRequest(value);
			const bindedAction = updateSurveyAction.bind(null, project.id, updateSurveyRequest);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Survey updated.');
				publishUpdateSurveyChanged();
			}
			else {
				return response.errors;
			}
		},
		[value, project]
	)

	return (
		<Form
			values={value}
			initialValues={initialValue}
			onChange={setValue}
			onSubmit={onSubmitCallback}
			onCancel={onCancelCallback}
			hideButtons={disabledMemo}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<SmartFormGroup
								label={`${survey.surveyType} Name`}
								isRequired
							>
								<InputField
									id='name'
									inline
									isRequired
									maxLength={240}
									disabled={disabledMemo}
								/>
							</SmartFormGroup>
							<TextareaField
								id='description'
								label='Description (email text)'
								rows={10}
								maxLength={2000}
								disabled={disabledMemo}
							/>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}

export default DesignTab;
