import { TimeSpan } from "components/Form";
import { useCallback, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

const KEY_NAME_ESC = 'Escape';
const KEY_NAME_ENTER = 'Enter';
const KEY_EVENT_TYPE = 'keyup';

const TimeSpanEditor = ({ cell, success, cancel, initialValue }: any) => {
	const [value, setValue] = useState(initialValue);

	const onBlurCallback = useCallback(
		() => {
			success(value);
		},
		[success, value]
	)

	const handleKeyboardCallback = useCallback(
		(event) => {
			switch (event.key) {
				case KEY_NAME_ESC:
					cancel();
					break;
				case KEY_NAME_ENTER:
					onBlurCallback();
					cancel();
					break;
				default:
					return;
			}
		},
		[cancel, onBlurCallback]
	);

	const handleClickCallback = useCallback(
		() => {
			const element = cell.getElement();
			element && element.blur();
		},
		[cell]
	)

	useEffect(
		() => {
			document.addEventListener(KEY_EVENT_TYPE, handleKeyboardCallback);
			document.addEventListener('click', handleClickCallback);

			return () => {
				document.removeEventListener(KEY_EVENT_TYPE, handleKeyboardCallback);
				document.removeEventListener('click', handleClickCallback);
			};
		},
		[handleKeyboardCallback, handleClickCallback]
	);

	return (
		<TimeSpan
			value={value}
			onChange={setValue}
			onBlur={onBlurCallback}
			editor
			focus
		/>
	)
}

export const timeSpanEditor = function(cell: any, onRendered: any, success: any, cancel: any, editorParams: any){
	const editor = document.createElement("div");

	const root = createRoot(editor);
	root.render(
		<TimeSpanEditor
			initialValue={cell.getValue()}
			success={success}
			cancel={cancel}
			editor={editor}
			cell={cell}
		/>
	)

	return editor;
}
