import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectResponse, UpdateProjectRequest } from 'services/tenantManagementService';
import { getProjectAction, updateProjectAction, publishProjectsChanged } from 'features/Project';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { ProjectForm } from './ProjectForm';
import WithFetch from 'features/Fetch/WithFetch';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { CrudEnum, useGoBackFromUpdate } from 'features/Crud';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = {
	projectId: string;
}

export const UpdateProject = () => {
	const [project, setProject] = useState(new ProjectResponse());
	const params: ParamType = useParams();
	const projectId = parseInt(params.projectId);
	const goBackFromUpdate = useGoBackFromUpdate();

	// fetch project
	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getProjectAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setProject(response.value || new ProjectResponse());
			}
		},
		[projectId]
	)

	const saveCallback = useCallback(
		async (newProject: ProjectResponse) => {
			const projectForUpdate = new UpdateProjectRequest(newProject);

			const bindedAction = updateProjectAction.bind(null, projectForUpdate);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.PROJECT, response.value?.id);
				notifications.success(`Project ${id} is updated.`);
				publishProjectsChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate]
	)

	return (
		<ContentShell title={`Change Project - ${getFormatedId(EntityPrefixEnum.PROJECT, project.id)}`}>
			<WithFetch fetchFunction={fetchDataCallback}>
				<ProjectForm
					project={project}
					crud={CrudEnum.Update}
					onSave={saveCallback}
				/>
			</WithFetch>
		</ContentShell>
	)
}
