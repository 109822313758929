import { Dialog } from 'components/Dialog';
import { CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { ExpenseItemResponse } from 'services/tenantManagementService';
import ExpenseItemForm from './ExpenseItemForm';

type Props = {
	onSave: (expenseItem: ExpenseItemResponse) => void
}

const CreateExpenseItem = ({ onSave }: Props) => {

	const goBackFromCreate = useGoBackFromCreate(true);

	return (
		<Dialog
			title='Create new expense'
			open
			onClose={goBackFromCreate}
			size='large'
		>
			<ExpenseItemForm
				crud={CrudEnum.Create}
				onSave={onSave}
			/>
		</Dialog>
	)
}

export default CreateExpenseItem;
