import path from 'path';
import { useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TicketForm } from './TicketForm';
import { InsertTicketTimesheetRequest, InsertTicketTimesheetRequestUpdateTicketTimesheetRequestInt32DeltaModel, Int32Int32Int32DeltaModel, TicketResponse, TicketTimesheetResponse, UpdateTicketRequest, UpdateTicketTimesheetRequest } from 'services/tenantManagementService';
import { updateTicketAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { createDelta, EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import { useFetchTicketMemo } from './helper';
import { ColumnContainer } from 'components/Layout';
import { ContentShell } from 'features/Content/ContentShell';
import { Follow } from './Follow/Follow';
import { getNumberArraysDiff } from 'utils/arrayUtil';
import { RootRouteEnum } from 'features/Routes';
import { ChangeRequestSubroutes } from 'containers/Scope/ChangeRequests/Crud/Tabs/ChangeRequestSubroutes';
import { ScopeSubRoutesEnum } from 'containers/Scope/routes';

type ParamType = {
	ticketId: string
}

export const UpdateTicket = ({ publishDataChanged }: UpdateComponentProps) => {
	const history = useHistory();
	const params: ParamType = useParams();
	const ticketId = parseInt(params.ticketId);

	const [ticket, setTicket] = useState(new TicketResponse());
	const goBackFromUpdate = useGoBackFromUpdate(false, true);

	const fetchDataCallback = useFetchTicketMemo(ticketId, setTicket);

	const saveCallback = useCallback(
		async (newTicket: TicketResponse, isInitiateChangeRequest?: boolean) => {
			const oldTestPlanIds = ticket.testPlans?.map(tp => tp.testPlanId) || [];
			const newTestPlanIds = newTicket.testPlans?.map(tp => tp.testPlanId) || [];

			const changeRequestsDiff = getNumberArraysDiff(ticket.changeRequestIds, newTicket.changeRequestIds);

			const timesheetsDelta = createDelta<TicketTimesheetResponse>
			(
				ticket.timesheets || [],
				newTicket.timesheets || [],
				InsertTicketTimesheetRequest,
				UpdateTicketTimesheetRequest,
				InsertTicketTimesheetRequestUpdateTicketTimesheetRequestInt32DeltaModel,
				Number
			);

			const ticketForUpdate = new UpdateTicketRequest({
				...newTicket,
				newAttachments: (newTicket as UpdateTicketRequest).newAttachments,
				testPlanIdsDelta: new Int32Int32Int32DeltaModel({
					insert: newTestPlanIds.filter(ntpId => !oldTestPlanIds.includes(ntpId)),
					delete: oldTestPlanIds.filter(otpId => !newTestPlanIds.includes(otpId))
				}),
				changeRequestIdsDelta: new Int32Int32Int32DeltaModel({
					insert: changeRequestsDiff.added,
					delete: changeRequestsDiff.removed
				}),
				timesheetsDelta
			});

			const bindedAction = updateTicketAction.bind(null, ticketForUpdate);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				const id = getFormatedId(EntityPrefixEnum.TICKET, response.value.id);
				notifications.success(`Ticket ${id} is updated.`);

				if (isInitiateChangeRequest) {
					history.push(path.join('/',RootRouteEnum.SCOPE, ScopeSubRoutesEnum.CHANGE_REQUESTS, response.value.projectId.toString(), CrudEnum.Create, ChangeRequestSubroutes.PROBLEM_REPORT), { ticket: response.value });
				} else {
					publishDataChanged();
					goBackFromUpdate();
				}
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, ticket.testPlans, publishDataChanged, ticket.timesheets, ticket.changeRequestIds, history]
	)

	const renderFollowCallback = useCallback(
		() => <Follow ticket={ticket} />,
		[ticket]
	)

	return (
		<ContentShell
			title={`Change ticket - ${getFormatedId(EntityPrefixEnum.TICKET, ticketId)}`}
			renderAdditionalHeaderContent={renderFollowCallback}
		>
			<WithFetch fetchFunction={fetchDataCallback}>
				<ColumnContainer margin='medium'>
					<TicketForm
						ticket={ticket}
						crud={CrudEnum.Update}
						onSave={saveCallback}
					/>
				</ColumnContainer>
			</WithFetch>
		</ContentShell>
	)
}
