import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud';
import { CreateRisk } from './Crud/CreateRisk';
import { UpdateRisk } from './Crud/UpdateRisk';

export const RiskManagementRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/${CrudSubRoutesEnum.Create}`} component={CreateRisk} />
			<Route path={`${path}/${CrudSubRoutesEnum.Update}/:riskId`} component={UpdateRisk} />
		</Switch>
	)
}
