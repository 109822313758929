import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, TextareaField } from "components/Form";
import notifications from "components/Notification/notification";
import { CrudEnum } from "features/Crud";
import { ProjectPickerParams } from "features/Project";
import { ProjectReportResponse, UpdateProjectReportRequest } from "services/tenantManagementService";
import { convertResponseErrors, tryCatchJsonByAction } from "utils/fetchUtils";
import { updateReportAction } from "../../action";

type Props = {
	report: ProjectReportResponse
	setReport?(report: ProjectReportResponse): void
	reportCrud: CrudEnum
}

export const RelationshipStatus = ({ report, setReport, reportCrud }: Props) => {
	const [values, setValues] = useState(report || new ProjectReportResponse());
	const params: ProjectPickerParams = useParams();
    const projectId = parseInt(params.projectId as string);

	const onSubmitCallback = useCallback(
		async () => {
			const model = new UpdateProjectReportRequest({
				...report,
				relationshipStatus: values.relationshipStatus,
			});

			const bindedAction = updateReportAction.bind(null, projectId, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Report relationship status is updated.');
				setReport && setReport(response.value || new ProjectReportResponse())
			} else {
				return convertResponseErrors(response);
			}
		},
		[projectId, values.relationshipStatus, report, setReport]
	);

	const resetCallback = useCallback(
		() => {
			const newReport = new ProjectReportResponse(report);
			newReport.relationshipStatus = report.relationshipStatus;
			setValues(newReport);
		},
		[report]
	)

	return (
		<Form
			values={values}
			initialValues={report}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={resetCallback}
			disabled={reportCrud === CrudEnum.Read}
			hideButtons={reportCrud === CrudEnum.Read}
			cancelButtonText='Reset'
			render={() => (
				<>
					<h2>Relationship Status</h2>
					<TextareaField
						id='relationshipStatus'
						maxLength={1000}
					/>
				</>
			)}
		/>
	)
}
