import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ProjectResponse, ProjectStatusResponse, UserModel } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { AutoCompleteField, Form, InputField, SelectField } from 'components/Form';
import { getInitialStatus } from 'features/StatusResponse/statusResponse';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { propertyOf } from 'utils/propertyOf';

type Props = {
	project?: ProjectResponse
	crud: CrudEnum
	onSave: (project: ProjectResponse) => void
}

export const ProjectForm = ({ project, crud, onSave }: Props) => {
	const {
		persistedUser,
		persistedProjectStatus
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(project || new ProjectResponse());
	const [status, setStatus] = useState<ProjectStatusResponse>(new ProjectStatusResponse());

	const initialValues = useMemo(
		() => {
			const model = new ProjectResponse(project);
			model.statusId = model.statusId || status.id;
			return model;
		},
		[project, status]
	)
	const goBackFromCrud = useGoBackFromCrud(crud);

	useEffect(
		() => {
			if (!persistedProjectStatus.isInitialized || crud !== CrudEnum.Create) {
				return;
			}

			const status = getInitialStatus(persistedProjectStatus.items) || new ProjectStatusResponse();
			setStatus(status);

			setValues((state: any) => {
				return {
					...state,
					statusId: status.id
				}
			})
		},
		[persistedProjectStatus, crud]
	)

	const onSubmitCallback = useCallback(
		async () => onSave && await onSave(values),
		[onSave, values]
	)

	const companyUsersMemo = useMemo(
		() => persistedUser.items.filter(user => !user.isGuest),
		[persistedUser.items]
	)

	return (
		<Form
			values={values}
			initialValues={initialValues}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={goBackFromCrud}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<InputField
							id={propertyOf<ProjectResponse>('name')}
							label='Project name'
							isRequired
						/>
						<AutoCompleteField
							id={propertyOf<ProjectResponse>('pmId')}
							label='Project manager'
							isRequired
							items={companyUsersMemo}
							getItemId={(user: UserModel) => user.id}
							getItemText={(user: UserModel) => `${user.firstName} ${user.lastName}`}
							loading={persistedUser.fetching}
							sort
						/>
						<SelectField
							id={propertyOf<ProjectResponse>('statusId')}
							label='Project status'
							isRequired
							items={persistedProjectStatus.items}
							getItemId={(status: ProjectStatusResponse) => status.id}
							getItemText={(status: ProjectStatusResponse) => status.name}
							getItemDescription={(status: ProjectStatusResponse) => status.description}
							loading={persistedProjectStatus.fetching}
							disabled
						/>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
