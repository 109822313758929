import { ProjectDashboardResponse } from 'services/tenantManagementService'
import { Layout } from 'features/Dashboard/Layout'
import { Dashboard } from 'features/Dashboard/Dashboard';
import { PieWidget } from 'features/Dashboard/Widget/PieWidget';
import { LocalTableWidget } from 'features/Dashboard/Widget/LocalTableWidget';
import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { TextWidget } from 'features/Dashboard/Widget/TextWidget';
import { useFormattedCurrencyValueCallback } from 'features/TableColumns/persistedHooks';
import { useTableColumnsMemo as useProjectHoursTableColumnsMemo } from '../../Finance/Dashboard/Tabs/Timesheets/ProjectHours/tableColumns'

type Props = {
	loading: boolean
	dashboard: ProjectDashboardResponse
}

const FilteredDashboard = ({ loading, dashboard }: Props) => {

	const {
		persistedTicketStatus,
		persistedTaskStatus,
		persistedTestCaseStatus,
		persistedTrainingStatus,
	} = useSelector((state: RootState) => state);

	const useFormattedCurrencyValue = useFormattedCurrencyValueCallback();
	const projectHoursTableColumns = useProjectHoursTableColumnsMemo(dashboard.projectHoursPerPeriod);

	const revenuesDataMemo = useMemo(
		() => {
			const data = dashboard.incomeExpenseProfit;
			if (!data) {
				return [];
			}

			return [
				{
					name: 'Budget',
					count: data?.budgetIncome,
					hidden: true,
					legendItemPosition: 1
				},
				{
					name: 'Actual',
					count: data?.actualBudgetIncome,
					hidden: false,
					legendItemPosition: 2
				},
				{
					name: 'Difference',
					count: data?.differenceIncome,
					hidden: (data?.differenceIncome || 0) < 0,
					legendItemPosition: 3
				}
			]
		},
		[dashboard]
	);

	const expensesDataMemo = useMemo(
		() => {
			const data = dashboard.incomeExpenseProfit;
			if (!data) {
				return [];
			}

			return [
				{
					name: 'Budget',
                    count: data?.budgetExpense,
					hidden: true,
					legendItemPosition: 1
				},
				{
					name: 'Actual',
					count: data?.actualBudgetExpense,
					hidden: false,
					legendItemPosition: 2
				},
				{
					name: 'Difference',
					count: data?.differenceExpense,
					hidden: (data?.differenceExpense || 0) < 0,
					legendItemPosition: 3
				}
			]
		},
		[dashboard]
	);

	const profitDataMemo = useMemo(
		() => {
			const data = dashboard.incomeExpenseProfit;
			if (!data) {
				return [];
			}

			return [
				{
					name: 'Budget',
                    count: data?.budgetProfit,
					hidden: true,
					legendItemPosition: 1
				},
				{
					name: 'Actual',
					count: data?.actualBudgetProfit,
					hidden: false,
					legendItemPosition: 2
				},
				{
					name: 'Difference',
					count: data?.differenceProfit,
					hidden: (data?.differenceProfit || 0) < 0,
					legendItemPosition: 3
				}
			]
		},
		[dashboard]
	);

	const ticketsPerStatusDataMemo = useMemo(
		() => {
			const data = persistedTicketStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.ticketsPerStatusCounts && dashboard.ticketsPerStatusCounts[status.id]) ? dashboard.ticketsPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTicketStatus.items]
	)

	const tasksPerStatusDataMemo = useMemo(
		() => {
			const data = persistedTaskStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.tasksPerStatusCounts && dashboard.tasksPerStatusCounts[status.id]) ? dashboard.tasksPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTaskStatus.items]
	)
	const testStatusDataMemo = useMemo(
		() => {
			const data = persistedTestCaseStatus.items.map(testStatus => {
				return {
					id: testStatus.id.toString(),
					name: testStatus.name,
					count: 0,
					color: testStatus.color
				}
			});

			dashboard.testStatuses?.forEach(status => {
				if (!status.statusCounts) {
					return;
				}

				Object.keys(status.statusCounts).forEach(statusCountKey => {
					const current = data.find(testStatus => testStatus.id === statusCountKey);
					if (!current) {
						return;
					}
					current.count += status.statusCounts![statusCountKey] || 0;
				})
			});
			return data;
		},
		[dashboard, persistedTestCaseStatus.items]
	)

	const trainingStatusDataMemo = useMemo(
		() => {
			const data = persistedTrainingStatus.items.map(item => {
				return {
					id: item.id.toString(),
					name: item.name,
					count: (dashboard.trainingStatusCounts && dashboard.trainingStatusCounts[item.id]) ? dashboard.trainingStatusCounts[item.id] : 0
				}
			});

			return data;
		},
		[dashboard, persistedTrainingStatus]
	)

	const getDurationInDaysWeeksMonths = useCallback(
		(durationInDays: number) => {
			if (durationInDays === undefined) {
				return '-';
			}

			const days = durationInDays;
			const weeks = Math.floor(days / 7);
			const months = Math.floor(days / 30);

			const daySuffix = days > 1 ? 'days' : 'day';
			const weekSuffix = weeks > 1 ? 'weeks' : 'week';
			const monthSuffix = months > 1 ? 'months' : 'month';

			return `
				${days} ${daySuffix} /
				${weeks} ${weekSuffix} /
				${months} ${monthSuffix}
			`;
		},
		[]
	)

	const projectDuration = useMemo(
		() => getDurationInDaysWeeksMonths(dashboard.projectDurationCompletion?.projectDurationInDays || 0),
		[dashboard.projectDurationCompletion, getDurationInDaysWeeksMonths]
	)


	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='horizontal'>
				<PieWidget
					className='col-lg-4 col-md-6'
					title='Revenues'
					id='revenues_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={revenuesDataMemo}
					loading={loading}
					useValueForLegend
				/>
				<PieWidget
					className='col-lg-4 col-md-6'
					title='Expenses'
					id='expenses_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={expensesDataMemo}
					loading={loading}
					useValueForLegend
				/>
				<PieWidget
					className='col-lg-4 col-md-6'
					title='Profit'
					id='profit_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={profitDataMemo}
					loading={loading}
					useValueForLegend
				/>
			</Layout>
			<Layout orientation='horizontal'>
				<Layout orientation='vertical' className='col-lg-8 col-12'>
					<Layout orientation='horizontal'>
						<TextWidget
							className='col-lg-2 col-4'
							title='Total incoming (plan)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.totalIncomingPlanned && -dashboard.incomingOutgoingOverdue?.totalIncomingPlanned)}
							loading={loading}
							toFixed
						/>
						<TextWidget
							className='col-lg-2 col-4'
							title='Total incoming (actual)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.totalIncomingActual && -dashboard.incomingOutgoingOverdue?.totalIncomingActual)}
							loading={loading}
							toFixed
						/>
						<TextWidget
							className='col-lg-2 col-4'
							title='Overdue (1-30 days)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.overdue1DayIncoming && -dashboard.incomingOutgoingOverdue?.overdue1DayIncoming)}
							loading={loading}
							toFixed
						/>
						<TextWidget
							className='col-lg-2 col-4'
							title='Overdue (31-60 days)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.overdue30DaysIncoming && -dashboard.incomingOutgoingOverdue?.overdue30DaysIncoming)}
							loading={loading}
							toFixed
						/>
						<TextWidget
							className='col-lg-2 col-4'
							title='Overdue (61-90 days)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.overdue60DaysIncoming && -dashboard.incomingOutgoingOverdue?.overdue60DaysIncoming)}
							loading={loading}
							toFixed
						/>
						<TextWidget
							className='col-lg-2 col-4'
							title='Overdue (>90 days)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.overdue90DaysIncoming && -dashboard.incomingOutgoingOverdue?.overdue90DaysIncoming)}
							loading={loading}
							toFixed
						/>
					</Layout>
					<Layout orientation='horizontal'>
						<TextWidget
							className='col-lg-2 col-4'
							title='Total outgoing (plan)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.totalOutgoingPlanned)}
							loading={loading}
							toFixed
						/>
						<TextWidget
							className='col-lg-2 col-4'
							title='Total outgoing (actual)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.totalOutgoingActual)}
							loading={loading}
							toFixed
						/>
						<TextWidget
							className='col-lg-2 col-4'
							title='Overdue (1-30 days)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.overdue1DayOutgoing)}
							loading={loading}
							toFixed
						/>
						<TextWidget
							className='col-lg-2 col-4'
							title='Overdue (31-60 days)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.overdue30DaysOutgoing)}
							loading={loading}
							toFixed
						/>
						<TextWidget
							className='col-lg-2 col-4'
							title='Overdue (61-90 days)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.overdue60DaysOutgoing)}
							loading={loading}
							toFixed
						/>
						<TextWidget
							className='col-lg-2 col-4'
							title='Overdue (>90 days)'
							value={useFormattedCurrencyValue(dashboard.incomingOutgoingOverdue?.overdue90DaysOutgoing)}
							loading={loading}
							toFixed
						/>
					</Layout>

					<Layout orientation='horizontal'>
						<PieWidget
							className='col-md-6 col-12'
							title='Tickets per Status'
							id='tickets_per_status_pie_chart'
							fieldValue='count'
							fieldCategory='name'
							data={ticketsPerStatusDataMemo}
							loading={loading}
							usePercentAndValueForLegend
						/>

						<PieWidget
							className='col-md-6 col-12'
							title='Tasks per Status'
							id='tasks_per_status_pie_chart'
							fieldValue='count'
							fieldCategory='name'
							data={tasksPerStatusDataMemo}
							loading={loading}
						/>
					</Layout>

					<Layout orientation='horizontal'>
						<PieWidget
							className='col-md-6 col-12'
							title='Test Status'
							id='test_pie_chart'
							fieldValue='count'
							fieldCategory='name'
							data={testStatusDataMemo}
							loading={loading}
							usePercentAndValueForLegend
						/>
						<PieWidget
							className='col-md-6 col-12'
							title='Training Status'
							id='training_status_pie_chart'
							fieldValue='count'
							fieldCategory='name'
							data={trainingStatusDataMemo}
							loading={loading}
						/>
					</Layout>
				</Layout>

				<Layout orientation='vertical' className='col-lg-4 col-12'>
					<Layout orientation='horizontal'>
						<LocalTableWidget
							className='col-12'
							title='Project hours per period'
							columns={projectHoursTableColumns}
							data={dashboard.projectHoursPerPeriod || []}
							loading={loading}
							hasPagination
							limit={12}
						/>
					</Layout>

					<Layout orientation='horizontal'>
						<TextWidget
							className='col-md-6 col-12'
							title='Project duration'
							value={projectDuration}
							loading={loading}
						/>
						<TextWidget
							className='col-md-6 col-12'
							title='Percent of completion (%)'
							value={dashboard.projectDurationCompletion?.percentOfCompletion ? dashboard.projectDurationCompletion?.percentOfCompletion + '%' : '-'}
							loading={loading}
						/>
					</Layout>
				</Layout>
			</Layout>
		</Dashboard>
	)
}

export default FilteredDashboard
