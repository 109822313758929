import path from 'path'
import styles from './header.module.scss'
import { ConfigurationIcon } from 'components/icons/icons';
import { Link } from 'react-router-dom';
import { RootRouteEnum } from 'features/Routes';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { getCssVariableValue } from 'utils/cssVariablesUtils';

export const Configuration = () => {
	const currentRoutes = useSelector((state: RootState) => state.routes.currentRoutes);
	const activeItemId = currentRoutes[0] && currentRoutes[0].id;
	const isActive = activeItemId === RootRouteEnum.SITE_CONFIGURATION;

	const iconSize = getCssVariableValue('--shell-icon-size');

	return (
		<Link
			className={isActive ? `${styles.item} ${styles.active}` : styles.item}
			to={path.join('/', RootRouteEnum.SITE_CONFIGURATION)}
		>
			<ConfigurationIcon width={iconSize} height={iconSize} fill='currentColor' />
		</Link>
	)
}
