import { View, Text, StyleSheet } from '@react-pdf/renderer';

type Props = {
	title: string
}

const TitleField = ({ title}: Props) => (
	<View style={styles.container}>
		<Text style={styles.title}>
			{title}
		</Text>
	</View>
);

export default TitleField;

const styles = StyleSheet.create({
	container: {
		paddingBottom: 15,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	title: {
		fontWeight: 600,
		textTransform: 'uppercase',
		fontSize: 14,
		color: '#3D8EEF'
	}
});
