import { MeetingResponse, MeetingStatusResponse, NonProjectCategoryResponse, ProjectIsActiveResponse, ProjectResponse, TaskStatusResponse, TenantIsActiveResponse, UserModel } from 'services/tenantManagementService';
import { FormField, SubtitleField } from 'components/Export';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { formatDate, formatDateTime } from 'utils/dateTimeUtils';
import { PersistActiveItemsReducer, PersistItemsReducer, PersistProjectItemsReducer } from 'features/Persist/reducerTypes';
import { StyleSheet } from '@react-pdf/renderer';
import TextareaFormField from 'components/Export/TextareaFormField';
import { View } from '@react-pdf/renderer';
import FormGroup from 'components/Export/FormGroup';
import TopicField from 'components/Export/TopicField';
import { useMemo } from 'react';
import { useFormattedProjectIdNameCallback } from 'features/TableColumns/persistedHooks';

type Props = {
	meeting: MeetingResponse
	persistedUser: PersistItemsReducer<UserModel>
	persistedMeetingType: PersistActiveItemsReducer<TenantIsActiveResponse>
	persistedMeetingStatus: PersistItemsReducer<MeetingStatusResponse>
	persistedProjectTeam: PersistProjectItemsReducer<ProjectIsActiveResponse>
	persistedTaskStatus: PersistItemsReducer<TaskStatusResponse>
	persistedProject: PersistItemsReducer<ProjectResponse>
	persistedTimeTravelNonProjectCategories: PersistActiveItemsReducer<NonProjectCategoryResponse>
}

const MeetingDocumentForm = ({
	meeting,
	persistedUser,
	persistedMeetingType,
	persistedMeetingStatus,
	persistedProjectTeam,
	persistedTaskStatus,
	persistedProject,
	persistedTimeTravelNonProjectCategories
}: Props) => {
	const formatProjectIdName = useFormattedProjectIdNameCallback(persistedProject);

	const projectOrCategoryLabel = useMemo(
		() => {
			const isProject = meeting.isProjectConnected;
			if (isProject) {
				return formatProjectIdName(meeting.projectOrCategoryId);
			}
			return persistedTimeTravelNonProjectCategories.itemsMap[meeting.projectOrCategoryId]?.name
		},
		[meeting, persistedTimeTravelNonProjectCategories, formatProjectIdName]
	)

	return (
		<>
			<FormField
				label='Meeting Id'
				value={getFormatedId(EntityPrefixEnum.MEETING, meeting.id)}
			/>
			<FormField
				label={'Project or category'}
				value={projectOrCategoryLabel}
			/>
			<FormField
				label='Meeting minutes status'
				value={persistedMeetingStatus.itemsMap[meeting.statusId]?.name}
				valueBackgroundColor={persistedMeetingStatus.itemsMap[meeting.statusId]?.color}
			/>
			<FormField
				label='Subject'
				value={meeting.subject}
			/>
			<FormField
				label='Location'
				value={meeting.location}
			/>
			<FormField
				label='Duration'
				value={meeting.duration ? meeting.duration + ' min' : ''}
			/>
			<FormField
				label='Starts'
				value={formatDateTime(meeting.starts)}
			/>
			<FormField
				label='Ends'
				value={formatDateTime(meeting.ends)}
			/>
			<FormField
				label='Participants'
				value={meeting.participantUserIds?.map(x => `${persistedUser.itemsMap[x]?.firstName} ${persistedUser.itemsMap[x]?.lastName}`).join(',')}
			/>
			<FormField
				label='Project team'
				value={meeting.projectTeamId ? persistedProjectTeam.itemsMap[meeting.projectTeamId]?.name : ''}
			/>
			<FormField
				label='Meeting type'
				value={meeting.meetingTypeId ? persistedMeetingType.itemsMap[meeting.meetingTypeId]?.name : ''}
			/>
			<FormField
				label='Meeting agenda'
				value={meeting.agenda}
			/>
			<FormField
				label='Attachments'
				value={meeting.attachments?.map(x => x.name).join(',')}
			/>
			<SubtitleField
				title='Topics'
			/>
			{meeting.topics?.map(
				(topic, index) => {
					return (
						<FormGroup label={`Topic ${index + 1}`} key={index}>
							<View style={styles.container}>
								<TopicField
									label='Type'
									value={topic.type}
								/>
								<TopicField
									label='Assigned to'
									value={topic.userId ? `${persistedUser.itemsMap[topic.userId]?.firstName} ${persistedUser.itemsMap[topic.userId]?.lastName}` : ''}
								/>
								<TopicField
									label='Due date'
									value={formatDate(topic.due)}
								/>
								<TopicField
									label='Status'
									value={topic.statusId ? `${persistedTaskStatus.itemsMap[topic.statusId]?.name}` : ''}
									backgroundColor={topic.statusId ? persistedTaskStatus.itemsMap[topic.statusId]?.color : '#fff'}
								/>
							</View>
							<TextareaFormField
								value={convertHtmlToText(topic.description)}
							/>
						</FormGroup>
					)
				})
			}
		</>
	);
};

export default MeetingDocumentForm;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingHorizontal: 4,
	},
})

const convertHtmlToText = (html: string) => {
	const parsedString = new DOMParser().parseFromString(html, 'text/html');
	return parsedString.body.textContent || '';
}
