import { CrudRouter } from 'features/Crud/CrudRouter';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ApprovalTabSubroutes } from '../ApprovalSubroutes';
import ReadExpense from './Crud/ReadExpense';
import ExpensesTable from './ExpensesTable';

const ExpensesApprovalRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${ApprovalTabSubroutes.EXPENSES}`}
				render={() => (
					<CrudRouter
						ListComponent={ExpensesTable}
						ReadComponent={ReadExpense}
						paramName='expenseId'
					/>
				)}
			/>
		</Switch>
	)
}

export default ExpensesApprovalRouter;