import { useMemo } from 'react';
import { MitigationTypeEnum, RiskImpactEnum, RiskProbabilityEnum, RiskResponse, RiskStatusResponse, TenantIsActiveResponse, UserModel } from 'services/tenantManagementService';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { mitigationTypes } from '../../RiskPlan/Crud/RiskForm';
import { useApplyStatusColorCallback, useStatusCallback, useTenantIsActiveCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { formatDate } from 'utils/dateTimeUtils';

const defaultVisibleColumns = [
	propertyOf<RiskResponse>('id'),
	propertyOf<RiskResponse>('name'),
	propertyOf<RiskResponse>('typeId'),
	propertyOf<RiskResponse>('probabilityDescriptive'),
	propertyOf<RiskResponse>('impactDescriptive'),
	propertyOf<RiskResponse>('mitigation'),
	propertyOf<RiskResponse>('responsibleUserId'),
	propertyOf<RiskResponse>('statusId'),
	propertyOf<RiskResponse>('dueDate'),
	propertyOf<RiskResponse>('comment'),
]

export const useTableColumnsMemo = (configureViewKey: string) => {
	const {
		persistedUser,
		persistedRiskStatus,
		persistedRiskType,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getType = useTenantIsActiveCallback(persistedRiskType);
	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedRiskStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedRiskStatus);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<RiskResponse>('id')]: {
					title: 'Risk Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.RISK
				},
				[propertyOf<RiskResponse>('name')]: {
					title: 'Risk Name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<RiskResponse>('typeId')]: {
					title: 'Risk Type',
					formatter: (cell: any) => getType(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedRiskType.items,
					getItemId: (option: TenantIsActiveResponse) => option.id,
					getItemText: (option: TenantIsActiveResponse) => option.name,
					dbFilterFieldPath: 'TypeRefId',
					dbExportFieldPath: 'TypeRefId',
				},
				[propertyOf<RiskResponse>('probability')]: {
					title: 'Probability [%]',
					formatter: (cell: any) => {
						const value = cell.getValue();
						return value ? `${value}%` : '';
					},
					format: '{0} %',
					fieldType: FieldTypeEnum.Number
				},
				[propertyOf<RiskResponse>('mitigation')]: {
					title: 'Mitigation',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<RiskResponse>('mitigationType')]: {
					title: 'Mitigation Type',
					fieldType: FieldTypeEnum.Options,
					options: mitigationTypes,
					getItemId: (option: MitigationTypeEnum) => option.valueOf(),
					getItemText: (option: MitigationTypeEnum) => option
				},
				[propertyOf<RiskResponse>('responsibleUserId')]: {
					title: 'Responsible',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => getUserFullName(option.id)
				},
				[propertyOf<RiskResponse>('statusId')]: {
					title: 'Status of mitigation',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					dbFilterFieldPath: 'StatusRefId',
					dbExportFieldPath: 'StatusRefId',
					fieldType: FieldTypeEnum.Options,
					options: persistedRiskStatus.items,
					getItemId: (option: RiskStatusResponse) => option.id,
					getItemText: (option: RiskStatusResponse) => option.name,
				},
				[propertyOf<RiskResponse>('dueDate')]: {
					title: 'Due Date',
					formatter: (cell:any) => {
						const value = cell.getValue();
						const element = cell.getElement();
						if (value < new Date()) {
							const color = '#f44336';
							const style = element.style.backgroundColor = color + 'cc';
							return `<span style=${style}>${formatDate(value)}</span>`;
						} else {
							return formatDate(value);
						}
					},
					tooltip: (e: any, cell: any) => cell.getValue() ? formatDate(cell.getValue()) : '',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<RiskResponse>('comment')]: {
					title: 'Comment',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<RiskResponse>('probabilityDescriptive')]: {
					title: 'Probability (descriptive)',
					fieldType: FieldTypeEnum.Options,
					options: [RiskProbabilityEnum.Low, RiskProbabilityEnum.Medium, RiskProbabilityEnum.High],
					getItemId: (item: RiskProbabilityEnum) => item,
					getItemText: (item: RiskProbabilityEnum) => item,
				},
				[propertyOf<RiskResponse>('impactDescriptive')]: {
					title: 'Impact (descriptive)',
					fieldType: FieldTypeEnum.Options,
					options: [RiskImpactEnum.Low, RiskImpactEnum.Medium, RiskImpactEnum.High],
					getItemId: (item: RiskImpactEnum) => item,
					getItemText: (item: RiskImpactEnum) => item,
				},
			}
		},
		[getUserFullName, getType, persistedRiskType.items, persistedUser.items, persistedRiskStatus.items, getStatus, applyStatusColor]
	)

	return useVisibleColumns(columns, visibleColumns);
}
