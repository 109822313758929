import { TabType } from 'components/Tabs/Tabs';
import { DashboardTabSubroutes } from './DashboardSubroutes';
import { TeamBillabilityDashboard } from './TeamBillability/TeamBillabilityDashboard';
import { TimesheetsDashboard } from './Timesheets/TimesheetsDashboard';
import { TravelDashboard } from './Travel/TravelDashboard';
import { VacationDashboard } from './Vacation/VacationDashboard';

export const dashboardTabs: TabType[] = [
	{
		id: '1',
		title: 'Timesheets',
		component: TimesheetsDashboard,
		route: DashboardTabSubroutes.TIMESHEETS,
	},
	{
		id: '2',
		title: 'Travel',
		component: TravelDashboard,
		route: DashboardTabSubroutes.TRAVEL,
	},
	{
		id: '3',
		title: 'Vacation',
		component: VacationDashboard,
		route: DashboardTabSubroutes.VACATION,
	},
	{
		id: '4',
		title: 'Team productivity',
		component: TeamBillabilityDashboard,
		route: DashboardTabSubroutes.TEAM_BILLABILITY,
	},
];
