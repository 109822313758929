import { ContentShell } from 'features/Content/ContentShell';
import { CrudEnum } from 'features/Crud';
import { noop } from 'utils/commonHelper';
import { MyTestPackagesHelp } from '../Help/MyTestPackagesHelp';
import { MyTest } from '../MyTest/MyTest';

const ReadMyTest = () => {
	return (
		<ContentShell
			title='View test case'
			FloatingHelpComponent={MyTestPackagesHelp}
		>
			<MyTest
				crud={CrudEnum.Read}
				publishDataChanged={noop}
			/>
		</ContentShell>
	)
}

export default ReadMyTest;
