import { useCallback, useMemo } from 'react';
import { GanttChart } from 'components/GanttChart';
import { EntityPrefixEnum, emptyArray, getFormatedId, noop } from 'utils/commonHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ScheduleUtilizationDashboardFilterRequest, ScheduleUtilizationRowDashboardResponse } from 'services/tenantManagementService';
import { OverlaySpinner } from 'components/Spinner';
import { generateId } from 'base/id';

type Props = {
	loading: boolean
	dashboard: ScheduleUtilizationRowDashboardResponse[]
	filterModel: ScheduleUtilizationDashboardFilterRequest
}

export const UtilizationView = ({ loading, dashboard, filterModel }: Props) => {
	const { persistedProject, persistedUser } = useSelector((state: RootState) => state);

	const resources = useMemo(
		() => {
			const usersAsResources: { id: number | string, label: string, parent: number | undefined }[] = [];

			for (let item of dashboard) {
				const user = persistedUser.itemsMap[item.userId];
				if (user) {
					usersAsResources.push({
						id: user.id,
						userId: user.id,
						label: `${user.firstName} ${user.lastName}`,
						parent: undefined
					} as any)

					const userProjects: number[] = [];
					for (const day of item.daysHours || []) {
						for (const key in day.utilizationPerProject) {
							const projectId = parseInt(key);
							if (!userProjects.includes(projectId)) {
								userProjects.push(projectId);
							}
						}
					}

					for (const projectId of userProjects) {
						const project = persistedProject.itemsMap[projectId];

						usersAsResources.push({
							id: `project_${user.id}_${projectId}`,
							projectId: projectId,
							userId: user.id,
							label: getFormatedId(EntityPrefixEnum.PROJECT, projectId) + ' - ' + project?.name || '',
							parent: user.id
						} as any)
					}
				}
			}

			return usersAsResources;
		},
		[persistedUser, dashboard, persistedProject]
	)

	const planedUtilizationCalculatorCallback = useCallback(
		(resource: any) => {
			if (resource.projectId) {
				return ''
			}
			const user = dashboard.find((item) => item.userId === resource.id);
			return user?.actualUtilization ? user.actualUtilization.toFixed(2) + '%' : '0';
		},
		[dashboard]
	)

	const actualUtilizationCalculatorCallback = useCallback(
		(resource: any) => {
			if (resource.projectId) {
				return ''
			}
			const user = dashboard.find((item) => item.userId === resource.id);
			return user?.plannedUtilization ? user.plannedUtilization.toFixed(2) + '%' : '0';
		},
		[dashboard]
	)

	const resourceColumns = useMemo(
		() => {
			return [
				{ name: 'planedUtilization', label: 'Planned', template: planedUtilizationCalculatorCallback, width: 80 },
				{ name: 'actualUtilization', label: 'Actual', template: actualUtilizationCalculatorCallback, width: 80 }
			]
		},
		[planedUtilizationCalculatorCallback, actualUtilizationCalculatorCallback]
	)

	// created dummy tasks, just for zoom and dates on Gantt to work nice
	const tasks = useMemo(
		() => {
			const dummyTasks: any[] = [];

			for (let item of dashboard) {
				for (let dayHour of item.daysHours || []) {
					dummyTasks.push({
						id: generateId(),
						text: 'test',
						start_date: new Date(dayHour.date),
						end_date: new Date(dayHour.date),
						open: false
					})
				}
			}

			return dummyTasks
		},
		[dashboard]
	)

	if (loading || persistedProject.fetching || persistedUser.fetching) {
		return <OverlaySpinner size={60} useBrandColor />
	}

	return (
		<GanttChart
			columns={emptyArray}
			tasks={tasks}
			links={emptyArray}
			resources={resources}
			resourcesUtilization={dashboard}
			resourceColumns={resourceColumns}
			deriveTaskDatesFromSubtasks={false}

			hideGantt
			showResourcesUtilization

			onCreateTaskClick={noop}
			onUpdateTaskClick={noop}
			onTaskUpdate={noop}
			onTasksUpdate={noop}
			onTaskDelete={noop}
			onTasksReorder={noop}
			onLinkAdd={noop}
			onLinkDelete={noop}
			disabled
		/>
	)
}
