import { HelpShell } from 'features/Help/HelpShell';

export const SiteSettingsHelp = () => {
	return (
		<HelpShell title='Site Settings'>
			<p>
				<b>Site Currency</b> - It is required to set site currency before creating any project in Mavles. Currency defined in this section will be applied for all projects created on your site. <br />
				We strongly recommend using company reporting currency as currency defined for site since all reports and data will be presented in company currency. <u>Currency can be changed also later, but please note that financial data will not be converted!!!</u> We do not recommend currency change in later stage of using Mavles.
			</p>
			{/* first implement this, than uncomment */}
			{/* <p><b>Number, time and date format</b> are defining how data are viewed on site.</p> */}
			<p>
				<b>Site administrator substitutes:</b> Here you can define substitutes for site administrator. Please note that site administrators have all authorizations for your Mavles site.
			</p>
		</HelpShell>
	)
}
