import { ScheduleIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";

// subroutes

export enum ScheduleSubRoutesEnum {
    MY_DASHBOARD = 'schedule_my_dashboard',
    SCHEDULE = 'schedule',
}

export const myDashboardSubRoute: Route =  {
	id: ScheduleSubRoutesEnum.MY_DASHBOARD,
	url: `/${RootRouteEnum.SCHEDULE}/${ScheduleSubRoutesEnum.MY_DASHBOARD}`,
	text: 'My Dashboard',
}

export const scheduleSubRoute: Route = {
	id: ScheduleSubRoutesEnum.SCHEDULE,
	url: `/${RootRouteEnum.SCHEDULE}/${ScheduleSubRoutesEnum.SCHEDULE}`,
	text: 'Schedule',
}

// root route

export const scheduleRoute: Route = {
	id: RootRouteEnum.SCHEDULE,
	url: `/${RootRouteEnum.SCHEDULE}`,
	text: 'Schedule',
	Icon: ScheduleIcon,
	children: [
		myDashboardSubRoute,
		scheduleSubRoute
	]
}
