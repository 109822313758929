import { useCallback } from "react";
import { TicketResponse, TimeMeasureEnum } from "services/tenantManagementService";
import { tryCatchJsonByAction } from "utils/fetchUtils";
import { getTicketAction } from "../action";

export const useFetchTicketMemo = (ticketId: number, setTicket: (ticket: TicketResponse) => void) => {
	return useCallback(
		async () => {
			const bindedAction = getTicketAction.bind(null, ticketId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const ticket = response.value || new TicketResponse();

				// effort is stored in database in minutes, we convert it to corespond effortUnit
				if (ticket.effort) {
					let effort: number;

					switch (ticket.effortUnit) {
						case TimeMeasureEnum.Hours:
							effort = ticket.effort / 60;
							break;
						case TimeMeasureEnum.Days:
							effort = ticket.effort / 60 / 24;
							break;
						case TimeMeasureEnum.Minutes:
						default:
							effort = ticket.effort;
					}

					ticket.effort = effort;
				}

				setTicket(ticket);
			}
		},
		[ticketId, setTicket]
	)
}
