import { getUserStatusesAction, updateUserStatusesAction } from './action';
import { UserStatusesHelp } from './UserStatusesHelp';
import { StatusResponseTable } from 'features/StatusResponse/StatusResponseTable';
import { ContentShell } from 'features/Content/ContentShell';

export const Users = () => {
	return (
		<ContentShell
			InlineHelpComponent={UserStatusesHelp}
			showBreadcrumb={false}
		>
			<StatusResponseTable
				getConfigsAction={getUserStatusesAction}
				updateConfigsAction={updateUserStatusesAction}
			/>
		</ContentShell>
	)
};
