import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { UserModel, VacationRequestResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { RootState } from 'base/reducer/reducer';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';

export const useTableColumnsMemo = ( configureViewKey: string) => {
	const {
		persistedUser,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const defaultVisibleColumns = [
		propertyOf<VacationRequestResponse>('userId'),
		'year',
		propertyOf<VacationRequestResponse>('from'),
		propertyOf<VacationRequestResponse>('to'),
		propertyOf<VacationRequestResponse>('numberOfDays'),
	];

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<VacationRequestResponse>('userId')]: {
					title: 'User',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				year: {
					title: 'Year',
					fieldType: FieldTypeEnum.None,
					disableFilter: true,
					disableSort: true,
					formatter: (cell: any) => {
						const data : VacationRequestResponse = cell.getData();
						return data.from.getFullYear();
					},
					// sorter: (emptyAValue: any, emptyBValue: any, aRow: any, bRow: any) => {
					// 	const a: VacationRequestResponse = aRow.getData();
					// 	const b: VacationRequestResponse = bRow.getData();

					// 	return a.from.getTime() - b.from.getTime();
					// }
				},
				[propertyOf<VacationRequestResponse>('from')]: {
					title: 'From',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<VacationRequestResponse>('to')]: {
					title: 'To',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<VacationRequestResponse>('numberOfDays')]: {
					title: 'Number of days',
					fieldType: FieldTypeEnum.Number,
					bottomCalcFormatter: (cell: any) => `∑ ${cell.getValue() || ''}`
				},
			};
		},
		[getUserFullName, persistedUser]
	)

	return useVisibleColumns(columns, visibleColumns);
}
