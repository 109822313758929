import { getTenantId } from 'features/Tenant/tenantUtils';
import { InsertTaskCommentRequest, TaskCommentClient } from 'services/tenantManagementService';

export const getTaskCommentsAction = (taskId: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new TaskCommentClient();
	return client.getAll(tenantId, taskId, projectOrCategoryId, isProjectConnected);
}

export const createTaskCommentAction = (model: InsertTaskCommentRequest) => {
	const tenantId = getTenantId()!;
	const client = new TaskCommentClient();
	return client.create(tenantId, model);
}
