import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { CrudButtons } from 'features/Crud';
import { BaseColumnModel } from 'components/Table';
import { Export } from 'features/Export/Export';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection, StatusReportResponse } from 'services/tenantManagementService';
import Button from 'components/Button';
import { getUserInfo } from 'utils/storageUtils';
import { useMemo } from 'react';

type Props = {
	selected: StatusReportResponse
    tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
	configureViewKey: string
	onDelete?: (id: number) => Promise<void>
	onCopyClick: () => void
	copying: boolean
	isRelease?: boolean
	onReleaseClick: () => void
	releasing: boolean
	isAligned?: boolean
	onAlignClick: () => void
	aligning: boolean
	disabled: boolean
	createDisabled?: boolean
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

export const TableButtons = ({
	selected,
	tableColumns,
	filtersModel,
	exportFunction,
	configureViewKey,
	onDelete,
	onCopyClick,
	copying,
	isRelease,
	onReleaseClick,
	releasing,
	isAligned,
	onAlignClick,
	aligning,
	disabled,
	createDisabled
}: Props) => {
	const isResponsibleUserDisabledMemo = useMemo(
		() => {
			const user = getUserInfo();
			return selected.responsibleUserId === user.id;
		},
		[selected]
	);

	return (
		<RowContainer justifyContent='space-between'>
			<RowContainer>
				<CrudButtons
					selectedId={selected.id}
					onDelete={onDelete}
					specificActionDisabled={{
						create: createDisabled,
						delete: disabled,
						change: (disabled && !isResponsibleUserDisabledMemo) || (!isAligned && disabled)
					}}
				/>
				<Button
					text='Copy'
					onClick={onCopyClick}
					isLoading={copying}
					disabled={!selected.id}
				/>
			</RowContainer>
			<RowContainer>
				<Button
					text='Release'
					onClick={onReleaseClick}
					disabled={!isRelease || selected.responsibleUserId === undefined}
					isLoading={releasing}
				/>
				<Button
					text='Align'
					onClick={onAlignClick}
					disabled={!isAligned || !isResponsibleUserDisabledMemo}
					isLoading={aligning}
				/>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns || []} />
				<Export
					filtersModel={filtersModel}
					tableColumns={tableColumns}
					exportAction={exportFunction}
					exportTypeOptions={EXPORT_TYPE_OPTIONS}
					title='Status reports'
				/>
			</RowContainer>
		</RowContainer>
	)
}
