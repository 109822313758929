import { getTenantId } from 'features/Tenant/tenantUtils';
import { GenericFilterModelCollection, SurveyClient } from 'services/tenantManagementService';

export const getSurveysGenericAction = (projectId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new SurveyClient();
	return client.getAll(tenantId, projectId, undefined, genericFilter.offset, genericFilter.limit);
}

export const deleteSurveyAction = (projectId: number, surveyId: number) => {
	const tenantId = getTenantId()!;
	const client = new SurveyClient();
	return client.delete(tenantId, projectId, surveyId);
}
