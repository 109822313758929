import { EventInput, DatesSetArg, EventChangeArg, EventMountArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import "./override.css"
import { useCallback } from 'react';
import Spinner, { ClipSpinner } from 'components/Spinner';
import styles from './calendar.module.scss'
import { ResourceSourceInput } from '@fullcalendar/resource';
import { useMemo } from 'react';

const plugins = [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, resourceTimelinePlugin]

const buttonText = {
	today: 'Today',
	month: 'Monthly',
	week: 'Weekly',
	day: 'Daily',
	list: 'List',
	prev: 'Previous',
	next: 'Next'
}

export enum CalendarViewEnum {
	timeDaily = 'timeGridDay',
	timeWeekly = 'timeGridWeek',
	// dayWeekly = 'dayGridWeek',
	dayMonthly = 'dayGridMonth',
	// listDaily = 'listDay',
	listWeekly = 'listWeek',
	// listMonthly = 'listMonth',
	resourceTimelineDaily = 'resourceTimelineDay',
	resourceTimelineWeekly = 'resourceTimelineWeek',
	resourceTimelineMonthly = 'resourceTimelineMonth'
}

type Props = {
	events: EventInput[]
	resources?: ResourceSourceInput
	setDateRange(from: Date, to: Date, fromStr: string, toStr: string): void
	eventDidMount?(event: EventImpl, el: HTMLElement, view: CalendarViewEnum)
	eventChanged?(event: EventImpl, oldEvent: EventImpl): void
	loading: boolean
	showToday?: boolean
	clickableDaysAndWeeks?: boolean

	initialView: CalendarViewEnum
	views: CalendarViewEnum[]
}

export const Calendar = ({
	events,
	resources,
	setDateRange, eventDidMount, eventChanged,
	loading, showToday, clickableDaysAndWeeks,
	initialView, views
}: Props) => {
	const datesSetCallback = useCallback(
		(arg: DatesSetArg) => {
			setDateRange(arg.start, arg.end, arg.startStr, arg.endStr);
		},
		[setDateRange]
	)

	const eventChangedCallback = useCallback(
		(arg: EventChangeArg) => {
			eventChanged && eventChanged(arg.event, arg.oldEvent);
		},
		[eventChanged]
	)

	const eventDidMountCallback = useCallback(
		(arg: EventMountArg) => {
			eventDidMount && eventDidMount(arg.event, arg.el, (arg.view as any).type) // type fix will be added in fullcalendar v6.0.1
		},
		[eventDidMount]
	)

	const toolbarLeftViews = useMemo(
		() => views.join(','),
		[views]
	)

	return (
		<div style={{ position: 'relative'}}>
			<FullCalendar
				schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
	            plugins={plugins}
				initialView={initialView}
				headerToolbar={{
					left: toolbarLeftViews,
					center: 'title',
					right: `prev${showToday ? ',today' : ''},next`
				}}
				allDayText='Unassigned tasks'
				buttonText={buttonText}
				firstDay={1} // to start from Monday instead of Sunday
				height={600}
				fixedWeekCount={false} // Determines the number of weeks displayed in a month view.
				showNonCurrentDates={false} // In month view, whether dates in the previous or next month should be rendered at all.
				slotEventOverlap={false}
				navLinks={clickableDaysAndWeeks} // Determines if day names and week names are clickable.
				weekNumbers
				weekText='Week '
				nowIndicator
				editable
				// selectable
				events={events}
				resources={resources}
				resourceAreaWidth='200px' // sirina resources kolone
				datesSet={datesSetCallback}
				eventChange={eventChangedCallback}
				eventDidMount={eventDidMountCallback}
			/>
			{loading &&
				<div className={styles.spinner}>
					<Spinner>
						<ClipSpinner size={80} />
					</Spinner>
				</div>
			}
		</div>
	)
}
