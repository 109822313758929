import { useMemo, useState } from 'react';
import { XYChart } from 'features/Charts'
import { getMonthEnumByDate } from 'utils/dateTimeUtils';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Radio } from 'components/Form';

type Props = {
	hoursPerPeriod: { [key: string]: number }
	hourCostsPerPeriod: { [key: string]: number }
}

export const ProjectHoursCostPerPeriodChart = ({
	hoursPerPeriod,
	hourCostsPerPeriod,
}: Props) => {
	const [showHours, setShowHours] = useState<boolean>(true);

	const hoursPerPeriodDataMemo = useMemo(
		() => {
			return Object.keys(hoursPerPeriod).map(dateInTicks => {
				const date = new Date(parseInt(dateInTicks));
				const name = `${getMonthEnumByDate(date)} ${date.getFullYear()}`;
				const value = hoursPerPeriod[dateInTicks]
				return  {
					name,
					value,
				}
			})
		},
		[hoursPerPeriod]
	)

	const hourCostsPerPeriodDataMemo = useMemo(
		() => {
			return Object.keys(hourCostsPerPeriod).map(dateInTicks => {
				const date = new Date(parseInt(dateInTicks));
				const name = `${getMonthEnumByDate(date)} ${date.getFullYear()}`;
				const value = hourCostsPerPeriod[dateInTicks]
				return  {
					name,
					value,
				}
			})
		},
		[hourCostsPerPeriod]
	)

	return (
		<ColumnContainer margin='small'>
			<RowContainer>
				<SmartContainer>
					<SmartItem>
						<SmartFormGroup>
							<Radio
								value={showHours}
								items={[
									{ id: true, text: 'Hours' },
									{ id: false, text: 'Costs' },
								]}
								onChange={() => {setShowHours((state: boolean) => !state)}}
							/>
						</SmartFormGroup>
					</SmartItem>
				</SmartContainer>
			</RowContainer>
			<XYChart
				id='approved_project_hours_costs_per_period_xy_chart'
				ytitle={showHours ? 'Project hours' : 'Project hours cost'}
				data={showHours ? hoursPerPeriodDataMemo : hourCostsPerPeriodDataMemo}
				minValue={0}
				maxValue={Math.max(...(showHours ? hoursPerPeriodDataMemo : hourCostsPerPeriodDataMemo).map(x => x.value)) || 1}
				showValueOnBar
			/>
		</ColumnContainer>
	)
}
