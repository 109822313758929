import { getTenantId } from 'features/Tenant/tenantUtils';
import { ScopeItemLevelClient, GenericFilterModelCollection, ExportDataModel } from 'services/tenantManagementService';

export const getScopeItemsAction = (projectId: number, parentId?: number, levelNumber?: number) => {
	const tenantId = getTenantId()!;
	const client = new ScopeItemLevelClient();
	return client.getAll(tenantId, projectId, parentId, levelNumber);
}

export const getAllScopeItemsForLevelAction = (projectId: number, levelNumber: number) => {
	return getScopeItemsAction(projectId, undefined, levelNumber);
}

export const getAllReportAction = (projectId: number, filters: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ScopeItemLevelClient();
	return client.getAllReport(tenantId, projectId, filters);
}

export const downloadAttachmentAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ScopeItemLevelClient();
	return client.downloadAttachment(tenantId, projectId, id);
}

export const exportAction = (projectId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ScopeItemLevelClient();
	return client.export(tenantId, projectId, exportDataModel);
}
