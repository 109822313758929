import { ColumnContainer } from 'components/Layout';

type Props = {
	title?: string
	children: any
}

export const HelpShell = ({ title, children }: Props) => {
	return (
		<>
			{title && <h3>{title}</h3>}
			<ColumnContainer margin='medium'>
				{children}
			</ColumnContainer>
		</>
	)
}
