import { ColumnContainer } from 'components/Layout';
import { RemoteTable } from 'components/Table';
import { ContentShell } from 'features/Content/ContentShell';
import { WithProjectPicker, ComponentProps } from 'features/Project'
import { useCallback, useMemo, useState } from 'react';
import { GenericFilterModelCollection, ModuleActivityEnum, RiskResponse } from 'services/tenantManagementService';
import { exportRisksAction, getAllRisksGenericFilterAction } from '../RiskPlan/action';
import { useTableColumnsMemo } from './Table/tableColumns';
import TableButtons from './Table/TableButtons';

const configureViewKey = 'view_risk_list_table';

const ViewRiskList = ({ project }: ComponentProps) => {
	const [selectedRisk, setSelectedRisk] = useState(new RiskResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const tableColumns = useTableColumnsMemo(configureViewKey);

	const memoFetchFunction = useMemo(
		() => getAllRisksGenericFilterAction.bind(null, project.id),
		[project.id]
	)

	const memoExportFunction = useMemo(
		() => exportRisksAction.bind(null, project.id),
		[project.id]
	)

	const handleRowSelectionChange = useCallback(
		(data: RiskResponse[]) => {
			setSelectedRisk(data[0] || new RiskResponse());
		},
		[]
	)

	return (
		<ContentShell title='Risk list'>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedRisk.id}
					filtersModel={filtersModel}
					tableColumns={tableColumns}
					exportFunction={memoExportFunction}
					configureViewKey={configureViewKey}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectPicker(ViewRiskList, ModuleActivityEnum.Risk, true);
