import { View, StyleSheet } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import { CostExpenseTypeResponse, ExpenseItemResponse, ExpenseResponse, IdNameResponse, TenantResponseModel } from 'services/tenantManagementService';
import { PersistItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import { useFormattedCurrencyValueWithArgsCallback } from 'features/TableColumns/persistedHooks';

type Props = {
	expense: ExpenseResponse;
	persistedTimeTravelExpenseTypes: PersistItemsReducer<CostExpenseTypeResponse>
	persistedTenant: PersistValueReducer<TenantResponseModel>,
	persistedCurrency: PersistItemsReducer<IdNameResponse>,
}

const sanitizeValue = (value: string | null | undefined) => value || '-'

const ExpenseDocumentTable = ({ expense, persistedTimeTravelExpenseTypes, persistedTenant, persistedCurrency }: Props) => {
	const getFormattedCurrencyValue = useFormattedCurrencyValueWithArgsCallback(persistedCurrency, persistedTenant);
	const items = expense.expenseItems

	if (!items || items.length === 0) {
		return null;
	}

	return (
		<View style={styles.container} wrap={false}>
			<Table data={items}>
				<TableHeader>
					<TableCell style={styles.header}>Expense type</TableCell>
					<TableCell style={styles.header}>Amount in project currency</TableCell>
					<TableCell style={styles.header}>Amount in receipt currency</TableCell>
					<TableCell style={styles.header}>Receipt currency</TableCell>
					<TableCell style={styles.header}>Comments</TableCell>
				</TableHeader>
				<TableBody>
					<DataTableCell style={styles.text} getContent={(item: ExpenseItemResponse) => sanitizeValue(persistedTimeTravelExpenseTypes.itemsMap[item.expenseTypeId]?.name)} />
					<DataTableCell style={styles.text} getContent={(item: ExpenseItemResponse) => sanitizeValue(getFormattedCurrencyValue(item.amountProjectCurrency))} />
					<DataTableCell style={styles.text} getContent={(item: ExpenseItemResponse) => sanitizeValue(item.amountReceiptCurrency?.toString())} />
					<DataTableCell style={styles.text} getContent={(item: ExpenseItemResponse) => sanitizeValue(item.receiptCurrencyId ? persistedCurrency.itemsMap[item.receiptCurrencyId]?.symbol : '')} />
					<DataTableCell style={styles.text} getContent={(item: ExpenseItemResponse) => sanitizeValue(item.comment)} />
				</TableBody>
			</Table>
		</View>
	);
};

export default ExpenseDocumentTable;

const styles = StyleSheet.create({
	container: {
		paddingVertical: 10,
	},
	header: {
		flexWrap: 'wrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: 2,
		fontSize: 10,
		fontWeight: 500
	},
	text: {
		flexWrap: 'wrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: 2,
		fontSize: 10,
	}
})
