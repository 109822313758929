import Tabs, { TabType } from 'components/Tabs/Tabs';
import { GridView } from './GridView/GridView';
import { GanttView } from './GanttView/GanttView';
import { MapView } from './MapView/MapView';
import { ComponentProps } from 'features/Project';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { persistProjectRolesAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/Roles/action';

export const tabs: TabType[] = [
	{
		id: '1',
		title: 'Gantt view',
		route: 'gantt',
		component: GanttView,
	},
	{
		id: '2',
		title: 'Grid view',
		route: 'grid',
		component: GridView,
	},
	{
		id: '3',
		title: 'Map',
		route: 'map',
		component: MapView,
	},
];

export const ScheduleTabs = ({ project, disabledEdit }: ComponentProps) => {

	const { persistedProjectRole } = useSelector((state:RootState) => state)

	useEffect(
		() => {
			if (!persistedProjectRole.projectMap[project.id]) {
				persistProjectRolesAction(project.id);
			}
		},
		[persistedProjectRole, project.id]
	)

	return (
		<Tabs
			tabs={tabs}
			tabComponentProps={{project, disabledEdit}}
		/>
	)
}
