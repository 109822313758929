import { RowContainer } from 'components/Layout';
import Button from 'components/Button';
import ConfigureView from 'features/ConfigureView';
import { BaseColumnModel } from 'components/Table';
import { CrudButtons } from 'features/Crud';
import { useCallback, useState } from 'react';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { Export } from 'features/Export/Export';

type Props = {
	selectedId?: number
	approvalEnabled: boolean
	approveDisabled: boolean
	rejectDisabled: boolean
	onApprove(): Promise<void>
	onReject(): void
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
}

const TableButtons = ({
	selectedId,
	approveDisabled,
	rejectDisabled,
	approvalEnabled,
	onApprove,
	onReject,
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction
}: Props) => {

	const [isApproving, setIsApproving] = useState(false);
	const [isRejecting, setIsRejecting] = useState(false);

	const approveCallback = useCallback(
		async () => {
			setIsApproving(true);
			await onApprove()
			setIsApproving(false);
		},
		[onApprove]
	)

	const rejectCallback = useCallback(
		async () => {
			setIsRejecting(true);
			await onReject()
			setIsRejecting(false);
		},
		[onReject]
	)
	return (
		<RowContainer justifyContent='space-between'>
			{approvalEnabled && (
				<RowContainer>
					<Button
						onClick={approveCallback}
						disabled={approveDisabled}
						text='Approve'
						isLoading={isApproving}
					/>
					<Button
						onClick={rejectCallback}
						disabled={rejectDisabled}
						text='Reject'
						isLoading={isRejecting}
					/>
				</RowContainer>
			)}
			<CrudButtons
				selectedId={selectedId}
				labels={{view: 'View details'}}
				isChangeable={false}
				isDeleteable={false}
				isCreateable={false}
			/>
			<RowContainer>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				<Export
					filtersModel={filtersModel}
					tableColumns={tableColumns}
					exportAction={exportFunction}
					title='Travel request approval'
					exportTypeOptions={[ExportDataFileTypeEnum.XLSX, ExportDataFileTypeEnum.PDF]}
				/>
			</RowContainer>
		</RowContainer>
	)
}

export default TableButtons;
