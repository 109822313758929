import { useApplyStatusColorCallback, useStatusCallback } from 'features/TableColumns/persistedHooks';
import { useMemo } from 'react';
import { ChangeRequestResponse, ChangeRequestStatusResponse } from 'services/tenantManagementService';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
	propertyOf<ChangeRequestResponse>('id'),
	propertyOf<ChangeRequestResponse>('name'),
	propertyOf<ChangeRequestResponse>('statusId')
]

export const useTableColumnsMemo = (changeRequestIds: number[]) => {
	const {
		persistedChangeRequestStatus,
	} = useSelector((state: RootState) => state);

	const getStatus = useStatusCallback(persistedChangeRequestStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedChangeRequestStatus);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
			 	[propertyOf<ChangeRequestResponse>('id')]: {
					title: 'CR ID',
					fieldType: FieldTypeEnum.Options,
					formatter: (cell: any) => getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, cell.getValue()),
					options: changeRequestIds,
					getItemId: (option: number) => option,
					getItemText: (option: number) => getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, option),
					minWidth: 150
				},
				[propertyOf<ChangeRequestResponse>('name')]: {
					title: 'CR name',
					fieldType: FieldTypeEnum.String,
					minWidth: 400
				},
				[propertyOf<ChangeRequestResponse>('statusId')]: {
					title: 'Status',
					fieldType: FieldTypeEnum.Options,
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					options: persistedChangeRequestStatus.items,
					getItemId: (option: ChangeRequestStatusResponse) => option.id,
					getItemText: (option: ChangeRequestStatusResponse) => option.name,
					dbFilterFieldPath: 'StatusRefId',
					minWidth: 250
				}
			}
		},
		[persistedChangeRequestStatus, applyStatusColor, getStatus, changeRequestIds]
	)

	return useVisibleColumns(columns, defaultVisibleColumns);
}
