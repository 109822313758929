import { errorColor, successColor } from "base/colors/colors"
import { RootState } from "base/reducer/reducer"
import Button from "components/Button"
import { AutoCompleteField, CheckboxField, DateField, Form, SemaphoreField, TextareaField } from "components/Form"
import { ColumnContainer, RowContainer, VerticalSeparator } from "components/Layout"
import { SmartContainer, SmartFormGroup, SmartInline, SmartItem } from "components/SmartContainer/SmartContainer"
import { getAllTeamMembersAction } from "containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action"
import { CrudEnum } from "features/Crud"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { ModuleActivityEnum, ProjectIsActiveResponse, ReportStatusAreaEnum, StatusReportResponse, StatusReportStatusEnum, UserModel } from "services/tenantManagementService"
import { tryCatchJsonByAction } from "utils/fetchUtils"
import { propertyOf } from "utils/propertyOf"
import { getUserInfo } from "utils/storageUtils"
import { getStatusBySemantic } from 'features/StatusResponse/statusResponse'
import { ProjectOrCategorySelect, ProjectOrCategoryType } from "features/Project"

export type OveralStatusColor = {
	id: ReportStatusAreaEnum,
	color: string
}

export const overalStatusColors: OveralStatusColor[] = [
	{ id: ReportStatusAreaEnum.Green, color: successColor },
	{ id: ReportStatusAreaEnum.Yellow, color: '#ede15b' },
	{ id: ReportStatusAreaEnum.Red, color: errorColor }
]

type Props = {
	statusReport: StatusReportResponse
	crud?: CrudEnum
	onSave?: (newStatusReport: StatusReportResponse, isRelease: boolean, isAlign: boolean) => void
	cancel?(): void
}

export const StatusReportForm = ({
	statusReport,
	crud,
	onSave,
	cancel
}: Props) => {
	const {
		persistedUser,
		persistedProjectTeam,
		persistedCommunicationStatusReport
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(statusReport || new StatusReportResponse());

	const [projectOrCategory, setProjectOrCategory] = useState<ProjectOrCategoryType | undefined>({
		projectOrCategoryId: values.projectOrCategoryId,
		isProjectConnected: values.isProjectConnected
	});

	const isReleaseRef = useRef(false);
	const isAlignRef = useRef(false);

	const [users, setUsers] = useState<UserModel[]>([]);
	const [fetchingUsers, setFetchingUsers] = useState(false);

	const fetchUsersCallback = useCallback(
		async () => {
			if (!persistedUser.isInitialized) {
				return;
			}

			if (!values.isProjectConnected) {
				setUsers(persistedUser.items.sort((a, b) => {
					return `${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}` ? -1 : 1;
				}))
				return;
			}
			setFetchingUsers(true);

			const bindedAction = getAllTeamMembersAction.bind(null, values.projectOrCategoryId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const teamMembers = response.items || [];
				const activeTeamMembers = teamMembers.filter((item) => item.isActive);
				const activeTeamMemberUsers = activeTeamMembers.map((item) => persistedUser.itemsMap[item.userId]!)
				setUsers(activeTeamMemberUsers.sort((a, b) => {
					return `${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}` ? -1 : 1;
				}))
			}

			setFetchingUsers(false);
		},
		[values.projectOrCategoryId, values.isProjectConnected, persistedUser]
	)

	useEffect(
		() => {
			fetchUsersCallback();
		},
		[fetchUsersCallback]
	)

	const onSubmitCallback = useCallback(
		async () => {
			if (crud === CrudEnum.Update) {
				return await onSave!(values, isReleaseRef.current, isAlignRef.current)
			}
			return await onSave!(values, isReleaseRef.current, false)
		},
		[onSave, values, crud]
	)

	const isNewOrCreated = useMemo(
		() => {
			const currentStatus = persistedCommunicationStatusReport.itemsMap[values.statusId];
			const createdStatus = getStatusBySemantic(StatusReportStatusEnum.Created, persistedCommunicationStatusReport.items);

			return !currentStatus?.id || currentStatus?.id === createdStatus?.id;
		},
		[persistedCommunicationStatusReport, values.statusId]
	)

	const isRead = crud === CrudEnum.Read;
	const isUpdate = crud === CrudEnum.Update;

	const isResponsibleUserMemoAndReleased = useMemo(
		() => {
			const user = getUserInfo();
			const currentStatus = persistedCommunicationStatusReport.itemsMap[values.statusId];
			const releasedStatus = getStatusBySemantic(StatusReportStatusEnum.Released, persistedCommunicationStatusReport.items);
			return values.responsibleUserId === user.id && currentStatus?.id === releasedStatus?.id;
		},
		[values, persistedCommunicationStatusReport]
	);

	const projectTeams = useMemo(
		() => {
			if (values.isProjectConnected) {
				return persistedProjectTeam.projectMap[values.projectOrCategoryId]?.items || []
			}

			return [];
		},
		[values.isProjectConnected, values.projectOrCategoryId, persistedProjectTeam]
	)

	return (
		<Form
			values={values}
			initialValues={statusReport}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			disabled={isRead}
			hideButtons={isRead}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<ProjectOrCategorySelect
								value={projectOrCategory}
								onChange={setProjectOrCategory}
								disabled
								moduleEnum={ModuleActivityEnum.Communication}
								showCompleted={isRead}
								isRequired
							/>
							<DateField
								id={propertyOf<StatusReportResponse>('reportDate')}
								label='Report date'
								isRequired
							/>
							<SmartFormGroup label='Report period'>
								<SmartInline flex='unset'>
									<DateField
										id={propertyOf<StatusReportResponse>('from')}
									/>
									<DateField
										id={propertyOf<StatusReportResponse>('to')}
									/>
								</SmartInline>
							</SmartFormGroup>
							<AutoCompleteField
								id={propertyOf<StatusReportResponse>('projectTeamId')}
								label='Project team'
								items={projectTeams}
								getItemId={(item: ProjectIsActiveResponse) => item.id}
								getItemText={(item: ProjectIsActiveResponse) => item.name}
								loading={persistedProjectTeam.fetching}
								isRequired={values.isProjectConnected}
							/>
							<VerticalSeparator margin='medium' />
							<SmartFormGroup>
								<SmartInline>
									<CheckboxField
										id={propertyOf<StatusReportResponse>('notifyResponsibleUser')}
										labelBefore='Send email for alignment'
									/>
									<AutoCompleteField
										id={propertyOf<StatusReportResponse>('responsibleUserId')}
										label='To'
										items={users}
										getItemId={(item: UserModel) => item.id}
										getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
										loading={fetchingUsers}
										sort
									/>
								</SmartInline>
							</SmartFormGroup>
							<VerticalSeparator margin='large' />
							<SmartFormGroup label='Overall status' isRequired>
								<SemaphoreField
									id={propertyOf<StatusReportResponse>('overallStatus')}
									colors={overalStatusColors}
									explanation='Green - No significant threats to project. Yellow - Current status threatens on-time or complete delivery of project scope. The issues can be managed within project. Red - Current status will prevent on-time or complete delivery of project scope. The project is likely to require external support and will be supervised by management.'
									disabled={isRead}
								/>
							</SmartFormGroup>
						</SmartItem>
						<SmartItem>
							{(isUpdate || isRead) &&
								<>
									<AutoCompleteField
										id={propertyOf<StatusReportResponse>('createdByUserId')}
										label='Created by'
										items={persistedUser.items}
										getItemId={(user: UserModel) => user.id}
										getItemText={(user: UserModel) => `${user.firstName} ${user.lastName}`}
										disabled
									/>
									<AutoCompleteField
										id={propertyOf<StatusReportResponse>('releasedByUserId')}
										label='Released by'
										items={persistedUser.items}
										getItemId={(user: UserModel) => user.id}
										getItemText={(user: UserModel) => `${user.firstName} ${user.lastName}`}
										disabled
									/>
									<AutoCompleteField
										id={propertyOf<StatusReportResponse>('alignedByUserId')}
										label='Aligned by'
										items={persistedUser.items}
										getItemId={(user: UserModel) => user.id}
										getItemText={(user: UserModel) => `${user.firstName} ${user.lastName}`}
										disabled
									/>
								</>
							}
						</SmartItem>
					</SmartContainer>
					<SmartContainer>
						<SmartItem size='xlarge'>
							<TextareaField
								id={propertyOf<StatusReportResponse>('overallStatusAndKeyAchievements')}
								label='Overall status and key achievements'
								rows={8}
								maxLength={2500}
								isRequired
							/>
							<TextareaField
								id={propertyOf<StatusReportResponse>('openIssues')}
								label='Open issues and needed decisions'
								rows={8}
								maxLength={2500}
								isRequired
							/>
						</SmartItem>
						<SmartItem size='xlarge'>
							<TextareaField
								id={propertyOf<StatusReportResponse>('keyRisks')}
								label='Key risks'
								rows={8}
								maxLength={2500}
								isRequired
							/>
							<TextareaField
								id={propertyOf<StatusReportResponse>('nextSteps')}
								label='Next steps'
								rows={8}
								maxLength={2500}
								isRequired
							/>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
			renderAdditionalButtons={(disabled, handleSubmitCallback: () => void, isSubmitting) => (
				<RowContainer>
					{isNewOrCreated && (
						<Button
							text='Release for alignment'
							disabled={disabled}
							isLoading={isSubmitting && isReleaseRef.current === true}
							onClick={
								() => {
									isReleaseRef.current = true;
									handleSubmitCallback();
								}
							}
						/>
					)}
					{isResponsibleUserMemoAndReleased ?
						(
							<Button
								text='Align'
								disabled={disabled}
								isLoading={isSubmitting && isAlignRef.current === true}
								onClick={
									() => {
										isAlignRef.current = true;
										handleSubmitCallback();
									}
								}
							/>
						) : undefined
					}
				</RowContainer>
			)}
		/>
	)
}
