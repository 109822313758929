import { useCallback, useState } from 'react';
import styles from 'components/Map/map.module.scss';
import notifications from 'components/Notification/notification';
import { OverlaySpinner } from 'components/Spinner';
import { TicketCategoryResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { deleteTicketCategoryAction } from '../action';
import { DeleteItem } from '../Crud/DeleteItem';
import { UpdateItem } from '../Crud/UpdateItem';

type Props = {
	item: TicketCategoryResponse
	items: TicketCategoryResponse[]
	onClick(id: number): void
	isSelected: boolean
	deselect(id: number | undefined): void
	fetchData(): Promise<void>
	reorder(elementIds: number[]): Promise<void>
}

export const Item = ({ item, items, onClick, isSelected, deselect, fetchData, reorder }: Props) => {
	const [deleting, setDeleting] = useState(false);

	const onClickCallback = useCallback(
		() => onClick(item.id),
		[item, onClick]
	)

	const onDeleteCallback = useCallback(
		async () => {
			setDeleting(true);

			const bindedAction = deleteTicketCategoryAction.bind(null, item.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				// deselect only if deleted item is selected
				if (isSelected) {
					deselect(undefined);
				}

				notifications.success('Ticket category item is successfully deleted');
				const newElementIds: number[] = [];
				for (const currentItem of items) {
					if (item.id !== currentItem.id) {
						newElementIds.push(currentItem.id);
					}
				}
				// reorder is calling fetch, so no need to call fetch twice
				reorder(newElementIds);
			}

			setDeleting(false);
		},
		[item.id, items, deselect, isSelected, reorder]
	)

	const className = `${styles.item} ${isSelected ? styles.selected : ''}`;
	const itemContent = `${item.name}`

	return (
		<div className={className} onClick={onClickCallback}>
			{item.parentId !== undefined && <div className={styles.line_left} />}
			<div className={styles.item_content} title={itemContent}>
				{itemContent}
			</div>
			<>
				{/* Modal dialog for edit/update */}
				<UpdateItem
					item={item}
					fetchData={fetchData}
				/>
				{/* Modal dialog for confirm delete */}
				<DeleteItem
					onDelete={onDeleteCallback}
				/>
				{deleting && <OverlaySpinner size={24} />}
			</>
			{(item.levelNumber < 3 && isSelected) && <div className={styles.line_right} />}
		</div>
	)
}
