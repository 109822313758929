import {useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { TimeTravelStatusResponse, VacationRequestResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { RootState } from 'base/reducer/reducer';
import { useApplyStatusColorCallback, useStatusCallback } from 'features/TableColumns/persistedHooks';

const defaultVisibleColumns = [
	propertyOf<VacationRequestResponse>('id'),
	propertyOf<VacationRequestResponse>('from'),
	propertyOf<VacationRequestResponse>('to'),
	propertyOf<VacationRequestResponse>('calculatedStatusId'),
	propertyOf<VacationRequestResponse>('numberOfDays'),
	propertyOf<VacationRequestResponse>('note'),
]

export const useTableColumnsMemo = (configureViewKey: string) => {
	const {
		persistedTimeAndTravelStatus,
		persistedConfigureView
	} = useSelector((state: RootState) => state);

	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;

	const getStatusName = useStatusCallback(persistedTimeAndTravelStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedTimeAndTravelStatus);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<VacationRequestResponse>('id')]: {
					title: 'Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.VACATION_REQUEST
				},
				[propertyOf<VacationRequestResponse>('from')]: {
					title: 'From',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<VacationRequestResponse>('to')]: {
					title: 'To',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<VacationRequestResponse>('numberOfDays')]: {
					title: 'Number of days',
					fieldType: FieldTypeEnum.Number
				},
				[propertyOf<VacationRequestResponse>('calculatedStatusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatusName(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTimeAndTravelStatus.items,
					getItemId: (option: TimeTravelStatusResponse) => option.id,
					getItemText: (option: TimeTravelStatusResponse) => option.name
				},
				[propertyOf<VacationRequestResponse>('note')]: {
					title: 'Note to approver',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<VacationRequestResponse>('customText1')]: {
					title: 'Text 1',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<VacationRequestResponse>('customText2')]: {
					title: 'Text 2',
					fieldType: FieldTypeEnum.String
				},
			}
		},
		[getStatusName, applyStatusColor, persistedTimeAndTravelStatus.items]
	)

	return useVisibleColumns(columns, visibleColumns);
}
