import { useCallback } from 'react';
import styles from './menu.module.scss';
import { Route } from 'features/Routes/reducer';
import { getCssVariableValue } from 'utils/cssVariablesUtils';

type Props = {
	route: Route
    isActive?: boolean
    showText?: boolean
	color?: string
	toggleSubmenu(route: Route): void
}

export const Item = ({ route, isActive, showText, color, toggleSubmenu }: Props) => {
	const onClickCallback = useCallback(
		() => toggleSubmenu(route),
		[route, toggleSubmenu]
	)

	const className = `${styles.item} ${isActive ? styles.active : ''}`

	const iconSize = getCssVariableValue('--shell-icon-size');

    return (
        <div className={className} onClick={onClickCallback} style={{ color: isActive ? color : '' }}>
            {route.Icon && <route.Icon width={iconSize} height={iconSize} fill={color} />}
            {showText && <span className={styles.text}>{route.text}</span>}
        </div>
    )
}
