import path from 'path'
import { EntityPrefixEnum } from "utils/commonHelper";
import { Notification } from "../Notification/Notification"
import { NotificationComponentProps } from "../NotificationsPanel"
import { useFormattedIdUsernameAndTrigger } from "./useFormattedIdUsernameAndTrigger";
import styles from '../notificationsPanel.module.scss'
import { RootRouteEnum } from 'features/Routes';
import { CrudEnum } from 'features/Crud';
import { useMemo } from 'react';
import { AlertTriggersEnum } from 'services/tenantManagementService';
import { VacationRequestApprovedNotificationAdditional, VacationRequestRejectedNotificationAdditional, VacationRequestSubmittedNotificationAdditional } from './types';
import { ApprovalTabSubroutes } from 'containers/TimeTravel/Approval/Tabs/ApprovalSubroutes';
import { TimeTravelSubRoutesEnum } from 'containers/TimeTravel/routes';

export const VacationNotification = ({ notification }: NotificationComponentProps) => {
	const Component = useMemo(
		() => {
			switch (notification.trigger) {
				case AlertTriggersEnum.Submitted:
					return VacationRequestSubmittedNotification;
				case AlertTriggersEnum.Approved:
					return VacationRequestApprovedNotification;
				case AlertTriggersEnum.Rejected:
					return VacationRequestRejectedNotification;
				default:
					return;
			}
		},
		[notification]
	)

	if (!Component) {
		return <></>
	}

	return (
		<Component notification={notification}/>
	)
}

const VacationRequestSubmittedNotification = ({ notification }: NotificationComponentProps) => {
	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as VacationRequestSubmittedNotificationAdditional : undefined

	const { formattedId, username, triggerText } = useFormattedIdUsernameAndTrigger(notification, EntityPrefixEnum.VACATION_REQUEST)

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.TIME_TRAVEL,
				TimeTravelSubRoutesEnum.APPROVAL,
				ApprovalTabSubroutes.VACATION_REQUESTS,
				CrudEnum.Read,
				String(notification.entityId),
				String(additionalData?.statusId)
			)}
			content={
				<div>
					<span>Vacation request: </span>
					<span className={styles.entity}>{formattedId} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}

const VacationRequestApprovedNotification = ({ notification }: NotificationComponentProps) => {
	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as VacationRequestApprovedNotificationAdditional : undefined

	const { formattedId, username, triggerText } = useFormattedIdUsernameAndTrigger(notification, EntityPrefixEnum.VACATION_REQUEST)

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.TIME_TRAVEL,
				TimeTravelSubRoutesEnum.MY_VACATION_REQUESTS,
				CrudEnum.Read,
				String(notification.entityId),
				String(additionalData?.statusId)
			)}
			content={
				<div>
					<span>Vacation request: </span>
					<span className={styles.entity}>{formattedId} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}

const VacationRequestRejectedNotification = ({ notification }: NotificationComponentProps) => {
	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as VacationRequestRejectedNotificationAdditional : undefined

	const { formattedId, username, triggerText } = useFormattedIdUsernameAndTrigger(notification, EntityPrefixEnum.VACATION_REQUEST)

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.TIME_TRAVEL,
				TimeTravelSubRoutesEnum.MY_VACATION_REQUESTS,
				CrudEnum.Read,
				String(notification.entityId),
				String(additionalData?.statusId)
			)}
			content={
				<div>
					<span>Vacation request: </span>
					<span className={styles.entity}>{formattedId} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}
