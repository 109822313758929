import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ModuleActivityEnum, ProjectTimeTravelDashboardFilterRequest, ProjectTimeTravelDashboardResponse, TimeFrequency } from 'services/tenantManagementService'
import { RootState } from 'base/reducer/reducer'
import { Dashboard } from 'features/Dashboard/Dashboard'
import { Layout } from 'features/Dashboard/Layout'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { TextWidget } from 'features/Dashboard/Widget/TextWidget'
import { formatDate, getMonthEnumByDate } from 'utils/dateTimeUtils'
import { ProjectHoursCostPerPeriodChart } from './ProjectHoursCostPerPeriodChart'
import { ProjectHoursCostPerUserAndPeriodChart } from './ProjectHoursCostPerUserAndPeriodChart'
import { ProjectHoursCostPerUserAndProjectChart } from './ProjectHoursCostPerUserAndProjectChart'
import { LineSeriesWidget } from 'features/Dashboard/Widget/LineSeriesWidget'
import { Widget } from 'features/Dashboard/Widget/Widget'
import { TimesheetsDashboardTable } from '../../Table/Timesheets/TimesheetsDashboardTable'
import { RotatedColumnSeriesWidget } from 'features/Dashboard/Widget/RotatedColumnSeriesWidget'
import styles from './timesheetsDashboard.module.scss';
import { pmOrSpmOrOumPermission, useProjects } from 'features/Project'

type Props = {
	loading: boolean
	dashboard: ProjectTimeTravelDashboardResponse
	filterModel: ProjectTimeTravelDashboardFilterRequest
}

export const TimesheetsDashboard = ({ loading, dashboard, filterModel }: Props) => {
	const {
		persistedTimeAndTravelStatus,
		persistedTimesheetsGeneralConfiguration,
		persistedTimeTravelNonProjectCategories,
	} = useSelector((state: RootState) => state);

	const { projects } = useProjects({
		isProjectDashboard: true,
		moduleEnum: ModuleActivityEnum.Time,
		userRolePermission: pmOrSpmOrOumPermission,
		isTimeAndTravelProjectDashboard: true
	});

	const approvalFrequency = persistedTimesheetsGeneralConfiguration.value.approvalFrequency;

	const timesheetsPerStatusDataMemo = useMemo(
		() => {
			const data = persistedTimeAndTravelStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.timesheets?.timesheetsPerStatusCounts && dashboard.timesheets?.timesheetsPerStatusCounts[status.id]) ? dashboard.timesheets?.timesheetsPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTimeAndTravelStatus]
	)

	const hoursPerProjectAndNonProjectCategoryDataMemo = useMemo(
		() => {
			const projectData = Object.keys(dashboard.timesheets?.hoursPerProject || {}).map(projectKeyId => {
				const projectId: number = parseInt(projectKeyId)
				return {
					id: `project_${projectId}`,
					name: `${projects.find(x => x.id === projectId)?.name}`,
					hours: dashboard.timesheets?.hoursPerProject![projectId],
				};
			});

			const categoryData = Object.keys(dashboard.timesheets?.hoursPerNonProjectCategory || {}).map(id => {
				return {
					id: `category_${id}`,
					name: persistedTimeTravelNonProjectCategories.itemsMap[parseInt(id)]?.name || '',
					hours: dashboard.timesheets?.hoursPerNonProjectCategory![id],
				};
			});

			return [...projectData, ...categoryData];
		},
		[dashboard, persistedTimeTravelNonProjectCategories, projects]
	)

	const columnSeriesHourCostsAndBillingsPerPeriodDataMemo = useMemo(
		() => {
			const spreadObject = { ...dashboard.timesheets?.hourCostsPerPeriod, ...dashboard.timesheets?.hourBillingsPerPeriod };
			return Object.keys(spreadObject).map(dateInTicks => {
				const date = new Date(parseInt(dateInTicks));
				let period;
				if (approvalFrequency === TimeFrequency.Daily) {
					period = formatDate(date);
				} else if (approvalFrequency === TimeFrequency.Weekly) {
					const endWeek = new Date(date);
					endWeek.setDate(endWeek.getDate() + 6);
					period = `${formatDate(date)}-${formatDate(endWeek)}`
				} else {
					period = `${getMonthEnumByDate(date)} ${date.getFullYear()}`
				}

				return {
					period,
					cost: dashboard.timesheets?.hourCostsPerPeriod ? dashboard.timesheets?.hourCostsPerPeriod[dateInTicks] || 0 : 0,
					sale: dashboard.timesheets?.hourBillingsPerPeriod ? dashboard.timesheets?.hourBillingsPerPeriod[dateInTicks] || 0 : 0
				}
			});
		},
		[dashboard, approvalFrequency]
	)

	const columnSeriesHourCostsAndBillingsPerPeriodFieldsMemo = useMemo(
		() => {
			return [
				{value: 'cost', name: 'Cost'},
				{value: 'sale', name: 'Revenue'},
			]
		},
		[]
	)

	const columnSeriesFieldsMemo = useMemo(
		() => {
			return [{
				name: 'Number of hours',
				value: 'hours'
			}]
		},
		[]
	)

	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='horizontal'>
				<Layout
					orientation='vertical'
					className='col-xl-2 col-sm-4'
				>
					<Layout orientation='horizontal'>
						<TextWidget
							className='col-12'
							title='Total hours'
							value={dashboard.timesheets?.totalTimesheetHours}
							loading={loading}
							toFixed
						/>
					</Layout>
				</Layout>
				<Layout
					orientation='vertical'
					className='col-xl-4 col-sm-8'
				>
					<Layout orientation='horizontal'>
						<PieWidget
							className='col-12'
							title='Timesheets per Status'
							id='timesheets_per_status_pie_chart'
							fieldValue='count'
							fieldCategory='name'
							data={timesheetsPerStatusDataMemo}
							loading={loading}
						/>
						<PieWidget
							className='col-12'
							title='Timesheets per category'
							id='timesheets_per_category_pie_chart'
							fieldValue='hours'
							fieldCategory='name'
							data={hoursPerProjectAndNonProjectCategoryDataMemo}
							loading={loading}
						/>
					</Layout>
				</Layout>
				<RotatedColumnSeriesWidget
					className='col-xl-6 col-md-12'
					contentClassName={styles.timesheetsPerProjectContent}
					title='Timesheets per project/category'
					id='timesheets_per_project_category_column_series_chart'
					data={hoursPerProjectAndNonProjectCategoryDataMemo}
					fieldCategory='name'
					fieldValues={columnSeriesFieldsMemo}
					loading={loading}
					showValueOnBar
				/>
			</Layout>
			<Layout orientation='horizontal'>
			</Layout>
			<Widget
				title='Project hours/costs per period'
				loading={loading}
			>
				<ProjectHoursCostPerPeriodChart
					hoursPerPeriod={dashboard.timesheets?.hoursPerPeriod || {}}
					hourCostsPerPeriod={dashboard.timesheets?.hourCostsPerPeriod || {}}
				/>
			</Widget>
			<Widget
				title='Project hours/costs per user and period'
				loading={loading}
			>
				<ProjectHoursCostPerUserAndPeriodChart
					hoursPerUserAndPeriod={dashboard.timesheets?.hoursPerUserAndPeriod || {}}
					hourCostsPerUserAndPeriod={dashboard.timesheets?.hourCostsPerUserAndPeriod || {}}
				/>
			</Widget>
			<Widget
				title='Project hours/costs per user and project'
				loading={loading}
			>
				<ProjectHoursCostPerUserAndProjectChart
					hoursPerUserAndProject={dashboard.timesheets?.hoursPerUserAndProject || {}}
					hourCostsPerUserAndProject={dashboard.timesheets?.hourCostsPerUserAndProject || {}}
				/>
			</Widget>
			<LineSeriesWidget
				title='Costs vs revenue (timesheets)'
				id='approved_hour_costs_per_period_line_series_chart'
				data={columnSeriesHourCostsAndBillingsPerPeriodDataMemo}
				fieldCategory='period'
				fieldValues={columnSeriesHourCostsAndBillingsPerPeriodFieldsMemo}
				loading={loading}
			/>
			<Widget title='Time' loading={loading}>
				<TimesheetsDashboardTable
				 	data={dashboard.timesheets?.timesheets || []}
					filterModel={filterModel}
				/>
			</Widget>
		</Dashboard>
	)
}
