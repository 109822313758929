import { useMemo } from 'react';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { TimeTravelDashboardRowResponse } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';

const defaultColumns = [
	propertyOf<TimeTravelDashboardRowResponse>('projectOrCategoryId'),
	propertyOf<TimeTravelDashboardRowResponse>('year'),
	propertyOf<TimeTravelDashboardRowResponse>('month'),
]

export const useTableColumnsVacationRequestsMemo = () => {

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<TimeTravelDashboardRowResponse>('projectOrCategoryId')]: {
					title: 'Project Id',
					fieldType: FieldTypeEnum.Number,
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<TimeTravelDashboardRowResponse>('year')]: {
					title: 'Year',
					fieldType: FieldTypeEnum.Number,
					disableSort: true,
					disableFilter: true,
				},
				[propertyOf<TimeTravelDashboardRowResponse>('month')]: {
					title: 'Month',
					fieldType: FieldTypeEnum.String,
					disableSort: true,
					disableFilter: true,
				},
			}
		},
		[]
	)

	return useVisibleColumns(columns, defaultColumns);
}
