import { getTenantId } from 'features/Tenant/tenantUtils';
import { getUserTableViewConfigAction, updateUserTableViewConfigAction } from 'features/UserTableViewConfig/action';
import { ExportDataModel, GenericFilterModelCollection, ShowTestStatusEnum, TestPlanClient } from 'services/tenantManagementService'

const viewType = 'showProcessStepsTestStatusReports';

export const getShowTestStatusConfigAction = () => {
	return getUserTableViewConfigAction(viewType);
}

export const updateShowTestStatusConfigAction = (showTestStatus: ShowTestStatusEnum) => {
	return updateUserTableViewConfigAction(viewType, showTestStatus);
}

export const getTestStatusReportAction = (projectId: number, testCycleId: number, showTestStatus: ShowTestStatusEnum , genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.getAllReport(tenantId, projectId, testCycleId, showTestStatus, genericFilter);
}

export const exportAction = (projectId: number, cycleId: number, showTestStatus: ShowTestStatusEnum , exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.exportTestStatus(tenantId, projectId, cycleId, showTestStatus, exportDataModel);
}
