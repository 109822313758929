import { Document, Font, Page, StyleSheet, Image } from '@react-pdf/renderer';
import { ProjectResponse, TenantIsActiveResponse, TestPlanResponse, TestStatusResponse, UserModel } from 'services/tenantManagementService';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import MyTestForm from './MyTestForm';
import MyTestAttachments from './MyTestAttachments';
import MyTestTable from './MyTestTable';

type Props = {
	persistedUser: PersistItemsReducer<UserModel>
	persistedTestCaseStatus: PersistItemsReducer<TestStatusResponse>
	project: ProjectResponse
	testCycle: TenantIsActiveResponse
	testPlan: TestPlanResponse,
	base64Logo?: string
}

const MyTestDocument = ({ persistedUser, persistedTestCaseStatus, project, testCycle, testPlan, base64Logo }: Props) => {
	return (
		<Document>
			<Page wrap size="A4" style={styles.page}>
				{base64Logo && <Image style={{ marginLeft: 'auto', maxWidth:'100px', height: 'auto'}} src={base64Logo} /> }
				<MyTestForm
					persistedUser={persistedUser}
					persistedTestCaseStatus={persistedTestCaseStatus}
					project={project}
					testCycle={testCycle}
					testPlan={testPlan}
				/>
				<MyTestAttachments
					testPlan={testPlan}
				/>
				<MyTestTable
					persistedUser={persistedUser}
					persistedTestCaseStatus={persistedTestCaseStatus}
					testPlan={testPlan}
				/>
			</Page>
		</Document>
	);
};

export default MyTestDocument;

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})

const styles = StyleSheet.create({
	page: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingHorizontal: 10,
		fontFamily: 'Roboto'
	},
});
