import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import { RotatedColumnSeriesChart } from 'features/Charts';
import { RootState } from 'base/reducer/reducer';
import { getMonthEnumByDate } from 'utils/dateTimeUtils';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Radio } from 'components/Form';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import styles from './timesheetsDashboard.module.scss';
import { sortByString } from 'utils/stringUtil';

type Props = {
	hoursPerUserAndPeriod: { [key: string]: { [key: string]: number } }
	hourCostsPerUserAndPeriod: { [key: string]: { [key: string]: number } }
}

export const ProjectHoursCostPerUserAndPeriodChart = ({
	hoursPerUserAndPeriod,
	hourCostsPerUserAndPeriod,
}: Props) => {
	const [showHours, setShowHours] = useState<boolean>(true);

	const { persistedUser } = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const columnSeriesHoursPerUserAndPeriodDataMemo = useMemo(
		() => {
			const data: any[] = [];

			for (const userId in hoursPerUserAndPeriod) {
				const object = hoursPerUserAndPeriod[userId];
				data.push({
					username: getUserFullName(parseInt(userId)),
					...object
				});
			}

			return sortByString(data, 'username');
		},
		[hoursPerUserAndPeriod, getUserFullName]
	)

	const columnSeriesHoursPerUserAndPeriodFieldsMemo = useMemo(
		() => {
			//get all distinct dates
			const datesInTicks = Object.keys(hoursPerUserAndPeriod || {}).map(userId => {
				return Object.keys(hoursPerUserAndPeriod![parseInt(userId)])
			}).flat().filter((value, index, self) => self.indexOf(value) === index);

			return datesInTicks.map(dateInTicks => {
				const date = new Date(parseInt(dateInTicks));
				let name = `${getMonthEnumByDate(date)} ${date.getFullYear()}`
				return {
					value: dateInTicks,
					name
				}
			})
		},
		[hoursPerUserAndPeriod]
	)

	const columnSeriesHourCostsPerUserAndPeriodDataMemo = useMemo(
		() => {
			const data: any[] = [];

			for (const userId in hourCostsPerUserAndPeriod) {
				const object = hourCostsPerUserAndPeriod[userId];
				data.push({
					username: getUserFullName(parseInt(userId)),
					...object
				});
			}

			return data;
		},
		[hourCostsPerUserAndPeriod, getUserFullName]
	)

	const columnSeriesHourCostsPerUserAndPeriodFieldsMemo = useMemo(
		() => {
			//get all distinct dates
			const datesInTicks = Object.keys(hourCostsPerUserAndPeriod || {}).map(userId => {
				return Object.keys(hourCostsPerUserAndPeriod![parseInt(userId)])
			}).flat().filter((value, index, self) => self.indexOf(value) === index);

			return datesInTicks.map(dateInTicks => {
				const date = new Date(parseInt(dateInTicks));
				const name = `${getMonthEnumByDate(date)} ${date.getFullYear()}`
				return {
					value: dateInTicks,
					name
				}
			})
		},
		[hourCostsPerUserAndPeriod]
	)

	return (
		<ColumnContainer margin='small'>
			<RowContainer>
				<SmartContainer>
					<SmartItem>
						<SmartFormGroup>
							<Radio
								value={showHours}
								items={[
									{ id: true, text: 'Hours' },
									{ id: false, text: 'Costs' },
								]}
								onChange={() => {setShowHours((state: boolean) => !state)}}
							/>
						</SmartFormGroup>
					</SmartItem>
				</SmartContainer>
			</RowContainer>
			<div className={styles.projectHoursPerUser}>
				<RotatedColumnSeriesChart
					id='approved_project_hours_costs_per_user_and_period_series_chart'
					data={showHours ? columnSeriesHoursPerUserAndPeriodDataMemo : columnSeriesHourCostsPerUserAndPeriodDataMemo}
					fieldCategory='username'
					stacked
					fieldValues={showHours ? columnSeriesHoursPerUserAndPeriodFieldsMemo : columnSeriesHourCostsPerUserAndPeriodFieldsMemo}
					showValueOnBar
				/>
			</div>
		</ColumnContainer>
	)
}
