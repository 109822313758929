import { MaintainScopeTabComponentProps } from '../maintainScopeTabs'
import { LevelItems } from './LevelItems/LevelItems'
import styles from 'components/Map/map.module.scss'

export const Map = ({ project, disabledEdit }: MaintainScopeTabComponentProps) => {
	return (
		<>
			<div className={styles.header}>
				<h5 className={styles.container}>Level 1</h5>
				<div className={styles.line_vertical} />
				<h5 className={styles.container}>Level 2</h5>
				<div className={styles.line_vertical} />
				<h5 className={styles.container_wider}>Level 3 - process</h5>
				<div className={styles.line_vertical} />
				<h5 className={styles.container}>Level 4 - process steps</h5>
			</div>
			<div className={styles.content}>
				<LevelItems
					project={project}
					disabledEdit={disabledEdit}
					level={1}
					parentIndex={0}
				/>
			</div>
		</>
	)
}
