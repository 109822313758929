import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud';
import { UpdateSurveySubroutes } from '../UpdateSurveySubroutes';
import CreateQuestion from './Crud/CreateQuestion';
import UpdateQuestion from './Crud/UpdateQuestion';
import ReadQuestion from './Crud/ReadQuestion';

const QuestionsRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/${UpdateSurveySubroutes.QUESTIONS}/${CrudSubRoutesEnum.Create}`} component={CreateQuestion} />
			<Route path={`${path}/${UpdateSurveySubroutes.QUESTIONS}/${CrudSubRoutesEnum.Update}/:questionId`} component={UpdateQuestion} />
			<Route path={`${path}/${UpdateSurveySubroutes.QUESTIONS}/${CrudSubRoutesEnum.Read}/:questionId`} component={ReadQuestion} />
		</Switch>
	)
}

export default QuestionsRouter;
