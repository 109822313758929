import { useCallback, useEffect, useState } from 'react';
import { AutoCompleteField, Form } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ModuleActivityEnum, ScopeItemLevelResponse, ScopePermission, ScopeProcessesDashboardFilterRequest } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { getScopeItemsAction } from 'containers/Scope/MaintainScope/action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import Button from 'components/Button';
import { ProjectSelectField, pmOrSpmOrOumPermission } from 'features/Project';

export class FilterModel extends ScopeProcessesDashboardFilterRequest {
	projectId!: number

	constructor(data?: FilterModel) {
		super(data);
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
    }
}

type Props = {
	onSubmit: (model: FilterModel) => Promise<void>
	isFetchingLevel1: boolean
	level1Items: ScopeItemLevelResponse[]
	fetchLevel1ItemsCallback(projectId: number): void
	filterFormModel?: FilterModel
	onSave: (filterFormModel: FilterModel) => Promise<void>
}

export const FilterForm = ({ onSubmit, isFetchingLevel1, level1Items, fetchLevel1ItemsCallback, filterFormModel, onSave }: Props) => {
	const [values, setValues] = useState(new FilterModel(filterFormModel));

	useEffect(
		() => {
			setValues(new FilterModel(filterFormModel));
		},
		[filterFormModel]
	)

	useEffect(
		() => {
			if (values.projectId) {
				fetchLevel1ItemsCallback(values.projectId);
			}
			fetchLevel1ItemsCallback(values.projectId);
		},
		[fetchLevel1ItemsCallback, values.projectId]
	)

	const [isFetchingLevel2, setIsFetchingLevel2] = useState(false);
	const [level2Items, setLevel2Items] = useState<ScopeItemLevelResponse[]>([]);

	const fetchLevel2ItemsCallback = useCallback(
		async () => {
			if (!values.lvl1Id || !values.projectId) {
				setLevel2Items([]);
				return;
			}
			setIsFetchingLevel2(true);

			const bindedAction = getScopeItemsAction.bind(null, values.projectId, values.lvl1Id, 2);
			const response = await tryCatchJsonByAction(bindedAction);

			setIsFetchingLevel2(false);

			if (response.success && response.items) {
				setLevel2Items(response.items);
			}
		},
		[values.projectId, values.lvl1Id]
	)

	useEffect(
		() => {
			fetchLevel2ItemsCallback();
		},
		[fetchLevel2ItemsCallback]
	)

	const onProjectChangeCallback = useCallback(
		(_newProjectId: number | undefined, _oldValues: FilterModel) => ({
			[propertyOf<FilterModel>('lvl1Id')]: undefined,
			[propertyOf<FilterModel>('lvl2Id')]: undefined
		}),
		[]
	)

	const onLevel1ChangeCallback = useCallback(
		(_newLevelId: number | undefined, _oldValues: FilterModel) => ({
			[propertyOf<FilterModel>('lvl2Id')]: undefined
		}),
		[]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new FilterModel(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectSelectField
							id={propertyOf<FilterModel>('projectId')}
							isRequired
							updateDependants={onProjectChangeCallback}
							isProjectDashboard
							moduleEnum={ModuleActivityEnum.Scope}
							teamMemberPermission={propertyOf<ScopePermission>('dashboard')}
							userRolePermission={pmOrSpmOrOumPermission}
						/>
						<AutoCompleteField
							id={propertyOf<FilterModel>('lvl1Id')}
							label='Level 1 ID'
							items={level1Items}
							getItemId={(item: ScopeItemLevelResponse) => item.id}
							getItemText={(item: ScopeItemLevelResponse) => item.name}
							loading={isFetchingLevel1}
							disabled={!values.projectId}
							updateDependants={onLevel1ChangeCallback}
						/>
						<AutoCompleteField
							id={propertyOf<FilterModel>('lvl2Id')}
							label='Level 2 ID'
							items={level2Items}
							getItemId={(item: ScopeItemLevelResponse) => item.id}
							getItemText={(item: ScopeItemLevelResponse) => item.name}
							loading={isFetchingLevel2}
							disabled={!values.lvl1Id || !values.projectId}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
					disabled={!values.projectId}
				/>
			)}
		/>
	)
}
