import { HelpShell } from "features/Help/HelpShell"

export const RemindersHelp = () => {
	return (
		<HelpShell title='Reminders'>
			<p>
				For every project, you can create reminder for yourself, for project team or individual user. <br />
				Reminders will be shown in notification section, but also in project calendar. <br />
				They can be repetitive or one-time occasions.
			</p>
		</HelpShell>
	)
}
