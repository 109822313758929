import { useMemo } from 'react'
import { PriorityEnum, TicketDashboardResponse, TicketStatusEnum } from 'services/tenantManagementService'
import { useSelector } from 'react-redux'
import { RootState } from 'base/reducer/reducer'
import { Layout } from 'features/Dashboard/Layout'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { Dashboard } from 'features/Dashboard/Dashboard';
import { TextWidget } from 'features/Dashboard/Widget/TextWidget'
import { useCallback } from 'react';
import { DivergentWidget } from 'features/Dashboard/Widget/DivergentWidget';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { getItemBySemantic } from 'features/Semantics/semanticsHelper';
import { colorsPalleteFire, errorColor, successColor } from 'features/ThemeProvider/amchartCustomTheme';

type Props = {
	loading: boolean
	dashboard: TicketDashboardResponse
}

export const FilteredDashboard = ({ loading, dashboard }: Props) => {
	const {
		persistedTicketStatus,
		persistedTicketType,
		persistedUser,
		// persistedTicketAssignedGroup,
		persistedTicketPriority
	} = useSelector((state: RootState) => state);

	const perStatusDataMemo = useMemo(
		() => {
			const data = persistedTicketStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.ticketsPerStatusCounts && dashboard.ticketsPerStatusCounts[status.id]) ? dashboard.ticketsPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTicketStatus.items]
	)

	const perTypeDataMemo = useMemo(
		() => {
			const data = persistedTicketType.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.ticketsPerTypeCounts && dashboard.ticketsPerTypeCounts[status.id]) ? dashboard.ticketsPerTypeCounts[status.id] : 0
				}
			});

			return data;
		},
		[dashboard, persistedTicketType.items]
	)

	const perPriorityDataMemo = useMemo(
		() => {
			const perPriority: { [key: string]: number } = {};

			for (const projectId in dashboard.ticketsPerProjectAndPriorityCounts) {
				const priorityCounts = dashboard.ticketsPerProjectAndPriorityCounts[projectId];
				for (const priorityId in priorityCounts) {
					if (perPriority[priorityId] === undefined) {
						perPriority[priorityId] = 0;
					}

					perPriority[priorityId] += priorityCounts[priorityId];
				}
			}

			const data = persistedTicketPriority.items.map(status => {
				let color;
				switch (status.semantics) {
					case PriorityEnum.Critical:
						color = colorsPalleteFire[0];
						break;
					case PriorityEnum.High:
						color = colorsPalleteFire[1];
						break;
					case PriorityEnum.Medium:
						color = colorsPalleteFire[2];
						break;
					case PriorityEnum.Low:
						color = colorsPalleteFire[3];
						break;
				}
				return {
					id: status.id.toString(),
					name: status.name,
					count: (perPriority[status.semantics.toLowerCase()]) ? perPriority[status.semantics.toLowerCase()] : 0,
					color
				}
			});

			return data;
		},
		[dashboard, persistedTicketPriority]
	)

	const perUserDataMemo = useMemo(
		() => {
			const data: any[] = [];

			for (const userId in dashboard.ticketsPerAssignedUserAndStatusCounts) {
				const object = dashboard.ticketsPerAssignedUserAndStatusCounts[userId];
				const item: any = {
					username: `${persistedUser.itemsMap[parseInt(userId)]?.firstName} ${persistedUser.itemsMap[parseInt(userId)]?.lastName}`,
				}

				for (const statusId in object) {
					let count = object[statusId];
					if (!isStatusBySemantic(TicketStatusEnum.Closed, statusId, persistedTicketStatus.itemsMap)) {
						count *= -1;
					}
					item[statusId] = count;
				}
				data.push(item);
			}

			return data;
		},
		[dashboard, persistedUser, persistedTicketStatus]
	)

	// in some reason, order of statuses get changed in Redux, so sorting it manually
	const statusFieldsMemo = useMemo(
		() => {
			const sorted = [
				TicketStatusEnum.New,
				TicketStatusEnum.Accepted,
				TicketStatusEnum.InProgress,
				TicketStatusEnum.OnHold,
				TicketStatusEnum.Resolved,
				TicketStatusEnum.Rejected,
				TicketStatusEnum.Closed
			].reverse();

			return sorted.map(semantic => {
				const status = getItemBySemantic(semantic, persistedTicketStatus.items);

				return {
					value: status?.id.toString() || '',
					name: status?.name || '',
					color: status?.color || ''
				}

			})
		},
		[persistedTicketStatus.items]
	)

	const responseTimeDataMemo = useMemo(
		() => {
			return [{
				id: 'satisfied',
				name: 'Satisfied',
				count: dashboard.responseTime?.satisfied,
				color: successColor
			}, {
				id: 'breach',
				name: 'Breach',
				count: dashboard.responseTime?.breach,
				color: errorColor
			}]
		},
		[dashboard]
	)

	const resolutionTimeDataMemo = useMemo(
		() => {
			return [{
				id: 'satisfied',
				name: 'Satisfied',
				count: dashboard.resolutionTime?.satisfied,
				color: successColor
			}, {
				id: 'breach',
				name: 'Breach',
				count: dashboard.resolutionTime?.breach,
				color: errorColor
			}]
		},
		[dashboard]
	)

	const getAverageTime = useCallback(
		(timeInMinutes: number) => {
			if (timeInMinutes === undefined) {
				return '-';
			}
			const hours = Math.floor(timeInMinutes / 60);
			const minutes = timeInMinutes % 60;

			return `${hours.toLocaleString().replace(',', '.')}h ${minutes}min`
		},
		[]
	)

	const averageResponseTime = useMemo(
		() => getAverageTime(dashboard.averageResponseTimeInMinutes),
		[dashboard, getAverageTime]
	)

	const averageResolutionTime = useMemo(
		() => getAverageTime(dashboard.averageResolutionTimeInMinutes),
		[dashboard, getAverageTime]
	)

	const newTicketStatus = persistedTicketStatus.items.find((item) => item.semantics === TicketStatusEnum.New);
	const closedTicketStatus = persistedTicketStatus.items.find((item) => item.semantics === TicketStatusEnum.Closed);

	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='horizontal'>
				<Layout 
					orientation='vertical'
					className='col-xl-1 col-sm-3'
				>
					<TextWidget
						title='Total Tickets'
						value={dashboard.totalTicketCount}
						loading={loading}
					/>
					<TextWidget
						title='Open (Active) Tickets'
						value={dashboard.openTicketsCount}
						loading={loading}
					/>
				</Layout>
				<Layout 
					orientation='vertical'
					className='col-xl-1 col-sm-3'
				>
					<TextWidget
						title='New Tickets'
						color={newTicketStatus?.color}
						value={dashboard.newTicketsCount}
						loading={loading}
					/>
					<TextWidget
						title='Closed Tickets'
						color={closedTicketStatus?.color}
						value={dashboard.closedTicketsCount}
						loading={loading}
					/>
				</Layout>
				<DivergentWidget
					className='col-xl-10 col-lg-12'
					id='status_per_assigned_user_divergent_chart'
					title='Ticket Status per Assigned User'
					data={perUserDataMemo}
					fieldCategory='username'
					fieldValues={statusFieldsMemo}
					loading={loading}
				/>
			</Layout>
			<Layout orientation='horizontal'>
				<PieWidget
					className='col-xl-4 col-lg-6'
					title='Tickets per Status'
					id='tickets_per_status_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={perStatusDataMemo}
					loading={loading}
					usePercentAndValueForLegend
				/>
				<PieWidget
					className='col-xl-4 col-lg-6'
					title='Tickets per Type'
					id='tickets_per_type_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={perTypeDataMemo}
					loading={loading}
					usePercentAndValueForLegend
				/>
				<PieWidget
					className='col-xl-4 col-lg-6'
					title='Tickets per Priority'
					id='tickets_per_priority_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={perPriorityDataMemo}
					loading={loading}
					usePercentAndValueForLegend
				/>
				<Layout 
					orientation='vertical' 
					className='col-xl-4 col-md-6'
				>
					<TextWidget
						title='Average Response Time'
						value={averageResponseTime}
						loading={loading}
					/>
					<TextWidget
						title='Average Resolution Time'
						value={averageResolutionTime}
						loading={loading}
					/>
				</Layout>
				<PieWidget
					className='col-xl-4 col-md-6'
					title='Response time'
					id='tickets_response_time_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={responseTimeDataMemo}
					loading={loading}
					usePercentAndValueForLegend
				/>
				<PieWidget
					className='col-xl-4 col-lg-6'
					title='Resolution time'
					id='tickets_resolution_time_pie_chart'
					fieldValue='count'
					fieldCategory='name'
					data={resolutionTimeDataMemo}
					loading={loading}
					usePercentAndValueForLegend
				/>
			</Layout>
			{/* added height because last dropdown on FilterForm is not visible */}
			<div style={{ height: '120px' }}></div> 
		</Dashboard>
	)
}
