import { ColumnContainer } from 'components/Layout';
import { TimesheetRecordedStatusResponse } from 'services/tenantManagementService';
import { formatDate, formatTimeWithoutAmOrPm, getWeekDayEnumByDate } from 'utils/dateTimeUtils';

type Props = {
	recordStatus: TimesheetRecordedStatusResponse
}


const TimesheetTabTitle = ({ recordStatus }: Props) => {
	const redColor = '#b30000';
	const greenColor = 'green';
	const statusColor = recordStatus.isFinished ? greenColor : redColor;

	return (
		<ColumnContainer margin='small'>
			<div>{getWeekDayEnumByDate(recordStatus.date)}</div>
			<div>{formatDate(recordStatus.date)}</div>
			<div style={{ color: statusColor }}>Recorded: {formatTimeWithoutAmOrPm(recordStatus.totalEffort)}</div>
		</ColumnContainer>
	)
}

export default TimesheetTabTitle;
