import { HelpShell } from 'features/Help/HelpShell';

export const CategoriesHelp = () => {
	return (
		<HelpShell title='Categories'>
			<p>
				Mavles comes with predefined categories related to budgeting: change requests (from scope module), risks (from risk module), travel expenses, expenses and time sheets (from time&travel module). <br />
				You can define additional budget categories based on your needs such as team incentives or some other costs or revenues. For these budget and actuals needs to be entered manually.
			</p>
		</HelpShell>
	)
}
