import { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import path from 'path';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { exportAllAction, deleteReportAction, getProjectReportsGenericAction, releaseReportAction, cloneReportAction } from './action';
import { GetProjectReportRequest, ProjectReportResponse, ProjectReportStatusEnum, GenericFilterModelCollection, ModuleActivityEnum } from 'services/tenantManagementService';
import { ColumnContainer } from 'components/Layout';
import { useTableColumnsMemo } from './Table/tableColumns';
import { TableButtons } from './Table/TableButtons';
import notifications from 'components/Notification/notification';
import { getStatusBySemantic, isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { WithProjectPicker, ComponentProps, pmOrSpmPermission } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';
import { ProjectReportHelp } from './Help/ProjectReportHelp';
import { CrudSubRoutesEnum, ListComponentProps } from 'features/Crud';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';

const configureViewKey = 'project_report_table';

type Props = ComponentProps & ListComponentProps;

const ProjectReport = ({ project, disabledEdit, dataChangedTopic, publishDataChanged }: Props) => {
	const history = useHistory();
	const { persistedReportStatus } = useSelector((state: RootState) => state)

	const [selectedReport, setSelectedReport] = useState(new ProjectReportResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());
	const [releasing, setReleasing] = useState(false);
	const [copying, setCopying] = useState(false);

	const tableColumns = useTableColumnsMemo(configureViewKey);

	const memoFetchFunction = useMemo(
		() => getProjectReportsGenericAction.bind(null, project.id),
		[project.id]
	)

	const memoExportFunction = useMemo(
		() => exportAllAction.bind(null, project.id),
		[project.id]
	)

	const handleRowSelectionChange = useCallback(
		(data: ProjectReportResponse[]) => {
			setSelectedReport(data[0] || new ProjectReportResponse());
		},
		[]
	);

	const deleteReportCallback = useCallback(
		async (id: number) => {
			const bindedAction = deleteReportAction.bind(null, project.id, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const formattedId = getFormatedId(EntityPrefixEnum.REPORT, id);
				notifications.success(`Report ${formattedId} is deleted.`);
				publishDataChanged();
			}
		},
		[project.id, publishDataChanged]
	)

	const copyCallback = useCallback(
		async () => {
			setCopying(true);
			const bindedAction = cloneReportAction.bind(null, project.id, selectedReport.id);
			const response = await tryCatchJsonByAction(bindedAction);
			setCopying(false)

			if (response.success) {
				const formattedCopyId = getFormatedId(EntityPrefixEnum.REPORT, response.value?.id);
				const formattedCopiedId = getFormatedId(EntityPrefixEnum.REPORT, selectedReport.id);
				notifications.success(`Report ${formattedCopyId} is created as copy of ${formattedCopiedId}.`);
				publishDataChanged();
				history.push(path.join(history.location.pathname, CrudSubRoutesEnum.Update, String(response.value!.id)))
			}
		},
		[history, project, publishDataChanged, selectedReport]
	)

	const isReleaseable = useMemo(
		() => {
			const currentStatus = persistedReportStatus.itemsMap[selectedReport.statusId];
			const releasedStatus = getStatusBySemantic(ProjectReportStatusEnum.Released, persistedReportStatus.items);

			if (currentStatus && releasedStatus) {
				return currentStatus.nextStates?.includes(releasedStatus.id);
			}

			return false;
		},
		[persistedReportStatus, selectedReport.statusId]
	)

	const releaseCallback = useCallback(
		async () => {
			setReleasing(true);
			const model = new GetProjectReportRequest({ id: selectedReport.id });
			const bindedAction = releaseReportAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const formattedId = getFormatedId(EntityPrefixEnum.REPORT, selectedReport.id);
				notifications.success(`Report ${formattedId} is released.`);
				publishDataChanged();
			}
			setReleasing(false);
		},
		[project.id, selectedReport.id, publishDataChanged]
	)

	const isChangeDisabledMemo = useMemo(
		() => disabledEdit || isStatusBySemantic(ProjectReportStatusEnum.Released, selectedReport.statusId, persistedReportStatus.itemsMap),
		[selectedReport, persistedReportStatus, disabledEdit]
	);

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='Project Reports'
			FloatingHelpComponent={ProjectReportHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedReport.id}
					onDelete={deleteReportCallback}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					copying={copying}
					onCopyClick={copyCallback}
					isReleaseable={isReleaseable}
					onReleaseClick={releaseCallback}
					releasing={releasing}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
					changeDisabled={isChangeDisabledMemo}
					disabled={disabledEdit}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					subscriptionTopic={dataChangedTopic}
					fetchFunction={memoFetchFunction}
					rowSelectionChanged={handleRowSelectionChange}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectPicker(ProjectReport, ModuleActivityEnum.Project, true, undefined, pmOrSpmPermission);
