import { ReordableList } from 'components/DragNDrop/ReordableList/ReordableList';
import { getScopeItemsAction } from '../../action';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProjectResponse, ScopeItemLevelResponse, UpdateOrderRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { reorderScopeItemsAction } from '../action';
import { Item } from './Item';
import { OverlaySpinner } from 'components/Spinner';
import { CreateItem } from '../Crud/CreateItem';
import styles from 'components/Map/map.module.scss'
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';

type Props = {
	project: ProjectResponse
	disabledEdit?: boolean
	level: number
	parentId?: number
	parentIndex: number // index of selected element, used for lines to be drawn between levels
}

export const LevelItems = ({ project, disabledEdit, parentId, level, parentIndex }: Props) => {
	const [items, setItems] = useState<ScopeItemLevelResponse[]>([]);
	const [selectedId, setSelectedId] = useState<number>();
	const [fetching, setFetching] = useState(false);
	const [reordering, setReordering] = useState(false);

	const fetchDataCallback = useCallback(
		async () => {
			setFetching(true);

			const bindedAction = getScopeItemsAction.bind(null, project.id, parentId, level);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setItems(response.items || []);
			}

			setFetching(false);
		},
		[project.id, parentId, level]
	);

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			setSelectedId(undefined);
		},
		[parentId]
	)

	const reorderCallback = useCallback(
		async (newElementIds: number[]) => {
			setReordering(true);

			const modelOrders: UpdateOrderRequest[] = [];
			for (let i = 0; i < newElementIds.length; i++) {
				modelOrders.push(new UpdateOrderRequest({
					id: newElementIds[i],
					order: i + 1
				}))
			}

			const bindedAction = reorderScopeItemsAction.bind(null, project.id, modelOrders);
			const response = await tryCatchJsonByAction(bindedAction);

			setReordering(false);
			if (response.success) {
				await fetchDataCallback();
			}
		},
		[project.id, fetchDataCallback]
	)

	const disabledEditMemo = useMemo(
		() => {
			return disabledEdit || !(isUserPmorSubstitutePmOrSiteAdmin(project.roleId) || project.permissions?.scopePermission?.maintainScope);
		},
		[disabledEdit, project]
	)

	const renderItemCallback = useCallback(
		(id: number) => {
			const item = items.find((item) => item.id === id);

			// sometimes ReordableList has some old elementIds, and item can be undefined
			if (!item) {
				return;
			}

			return (
				<Item
					key={item.id.toString()}
					disabledEdit={disabledEditMemo}
					items={items}
					item={item}
					onClick={setSelectedId}
					isSelected={selectedId === item.id}
					deselect={setSelectedId}
					project={project}
					fetchData={fetchDataCallback}
					reorder={reorderCallback}
				/>
			)
		},
		[selectedId, items, fetchDataCallback, project, reorderCallback, disabledEditMemo]
	)

	const elementIds = useMemo(
		() => items.map((item) => item.id),
		[items]
	)

	const prevHeight = parentIndex * (32 + 8); // 32 item height, 8 margin-bottom
	const nextHeight = (items.length - 1) * (32 + 8);
	const lineHeight = Math.max(1, prevHeight, nextHeight);

	return (
		<>
			{(1 < level && level < 5) && <div className={styles.line_vertical} style={{ height: `${lineHeight}px` }} />}
			{level < 5 &&
				<div className={`${styles.container} ${level === 3 ? styles.container_wider: ''}`}>
					{items.length > 0 &&
						<ReordableList
							initialElementIds={elementIds}
							onReorder={reorderCallback}
							renderItem={renderItemCallback}
							disabled={disabledEditMemo}
						/>
					}
					{!disabledEditMemo &&
						<CreateItem
							items={items}
							level={level}
							parentId={parentId}
							project={project}
							fetchData={fetchDataCallback}
						/>
					}
					{(fetching || reordering) && <OverlaySpinner size={60} withBackground />}
				</div>
			}
			{selectedId &&
				<LevelItems
					project={project}
					disabledEdit={disabledEditMemo}
					parentId={selectedId}
					parentIndex={elementIds.indexOf(selectedId)}
					level={level + 1}
				/>
			}
		</>
	)
}
