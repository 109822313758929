import styles from './header.module.scss'
import { Logo } from 'features/Logo/Logo';
import { useMemo } from 'react';
import { Configuration } from './Configuration';
import { User } from './User';
import { isUserPMOrSiteAdmin } from 'utils/userRoleHelper';
import { getCssVariableValue } from 'utils/cssVariablesUtils';
import { Notifications } from 'features/Notifications/Notifications';
import { ThemeToggler } from './ThemeToggler';

export const Header = () => {
	const iconSize = getCssVariableValue('--shell-icon-size');

	const isUserPmOrSiteAdminMemo = useMemo(
		() => isUserPMOrSiteAdmin(),
		[]
	)

	return (
		<div className={styles.container}>
			<Logo />
			<div className={styles.separator}></div>
			<ThemeToggler iconSize={iconSize} />
			<Notifications
				itemClassName={styles.item}
				activeClassName={styles.active}
			/>
			{isUserPmOrSiteAdminMemo && <Configuration /> }
			<User />
		</div>
	)
}
