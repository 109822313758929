import { useEffect } from 'react';
// import appSettings from 'settings/appSettings';

export const usePersistIntervalEffect = (persistFunction: () => void) => {
	useEffect(
		() => {
			// const persistInterval = appSettings.getPersistInterval();

			// call it initially
			persistFunction();

			// // and then call it on every persistInterval milisecond
			// const interval = setInterval(
			// 	() => {
			// 		persistFunction();
			// 	},
			// 	[persistInterval]
			// );

			// return () => {
			// 	clearInterval(interval);
			// }
		},
		[persistFunction]
	)
}

export const usePersistOnceEffect = (persistFunction: () => void) => {
	useEffect(
		() => {
			persistFunction();
		},
		[persistFunction]
	)
}
