import { RootState } from "base/reducer/reducer";
import { Dialog } from "components/Dialog";
import { CrudEnum } from "features/Crud";
import { getInitialStatus } from "features/StatusResponse/statusResponse";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { InsertTicketCommnetRequest } from "services/tenantManagementService";
import { TaskForm } from "./TaskForm";

type Props = {
	isOpened: boolean
	setIsOpened(isOpened: boolean): void
	addTask(task: InsertTicketCommnetRequest): void
}

export const CreateTask = ({ isOpened, setIsOpened, addTask }: Props) => {
	const { persistedTaskStatus } = useSelector((state: RootState) => state);

	const closeDialogCallback = useCallback(
		() => setIsOpened(false),
		[setIsOpened]
	)

	const addTaskCallback = useCallback(
		async (values: InsertTicketCommnetRequest) => {
			addTask(values);
			closeDialogCallback()
		},
		[addTask, closeDialogCallback]
	)

	const newTask = useMemo(
		() => {
			const model = new InsertTicketCommnetRequest();
			const initialStatus = getInitialStatus(persistedTaskStatus.items);
			model.taskStatusId = initialStatus?.id;
			return model;
		},
		[persistedTaskStatus]
	)

	return (
		<Dialog
			title='Add task'
			onClose={closeDialogCallback}
			open={isOpened}
		>
			<TaskForm
				task={newTask}
				onSave={addTaskCallback}
				onCancel={closeDialogCallback}
				crud={CrudEnum.Create}
			/>
		</Dialog>
	)
}
