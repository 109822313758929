import { useCallback, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { DeleteSurveyProposedAnswerRequest, InsertSurveyProposedAnswerRequest, InsertSurveyQuestionRequest, SurveyProposedAnswerDeltaRequest, SurveyProposedAnswerResponse, SurveyQuestionResponse, SurveyResponse, UpdateSurveyProposedAnswerRequest } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { CrudEnum, useGoBackFromCreate } from 'features/Crud';
import QuestionModalForm from './QuestionModalForm';
import { createSurveyQuestionAction } from '../actions';
import { publishSurveyQuestionsChanged } from '../surveyQuestionsChanged';
import { createDelta, unpackDelta } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { getSurveyAction } from '../../../action';

type ParamType = {
	projectId: string;
	surveyId: string;
}

const CreateQuestion = () => {
	const [survey, setSurvey] = useState(new SurveyResponse());
	let history = useHistory();
	const params: ParamType = useParams();
	const goBackFromCreate = useGoBackFromCreate();
	const projectId = useMemo(() => parseInt(params.projectId), [params.projectId])
	const surveyId = useMemo(() => parseInt(params.surveyId), [params.surveyId])

	const lastQuestionOrderMemo = useMemo(
		() => history.location.state ? ((history.location.state as any).lastQuestionOrder || 0) : 0,
		[history.location.state]
	)

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getSurveyAction.bind(null, projectId, surveyId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setSurvey(response.value || new SurveyResponse());
			}
		},
		[projectId, surveyId]
	)

	const onSubmitCallback = useCallback(
		async (values: SurveyQuestionResponse) => {
			const delta = createDelta<SurveyProposedAnswerResponse>
				(
					[],
					values.proposedAnswers || [],
					InsertSurveyProposedAnswerRequest,
					UpdateSurveyProposedAnswerRequest,
					SurveyProposedAnswerDeltaRequest,
					DeleteSurveyProposedAnswerRequest
				);

			const insertQuestion = new InsertSurveyQuestionRequest({
				...values,
				order: lastQuestionOrderMemo + 1,
				proposedAnswersDelta: new SurveyProposedAnswerDeltaRequest(delta)
			})
			const bindedAction = createSurveyQuestionAction.bind(null, projectId, surveyId, insertQuestion);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`Question created.`);
				goBackFromCreate();
				publishSurveyQuestionsChanged()
			} else {
				const errors = convertResponseErrors(response)
				const [proposedAnswersErrors] = unpackDelta(errors?.proposedAnswers || {}, delta, values.proposedAnswers || [], []);
				return { ...errors, proposedAnswers: proposedAnswersErrors };
			}
		},
		[goBackFromCreate, lastQuestionOrderMemo, projectId, surveyId]
	);

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<QuestionModalForm
				survey={survey}
				crud={CrudEnum.Create}
				save={onSubmitCallback}
				cancel={goBackFromCreate}
				open
			/>
		</WithFetch>
	)
};

export default CreateQuestion;
