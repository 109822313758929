import { useCallback, useState } from 'react';
import { ColumnContainer } from 'components/Layout';
import { TableButtons } from './Table/TableButtons';
import { GenericFilterModelCollection, UpdateUserActivityRequestModel, UserModel } from 'services/tenantManagementService';
import { useTableColumnsMemo } from './Table/tableColumns';
import { getAllUsersGenericAction, exportUsersAction, updateUserActivityAction } from './action';
import { ListComponentProps } from 'features/Crud';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';

const configureViewKey = 'users_table';

export const Users = ({ dataChangedTopic, publishDataChanged }: ListComponentProps) => {
	const [selectedUser, setSelectedUser] = useState(new UserModel());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());
	const [isUpdatingActivity, setIsUpdatingActivity] = useState(false);

	const tableColumns = useTableColumnsMemo(configureViewKey);

	const handleRowSelectionChange = useCallback(
		(data: UserModel[]) => setSelectedUser(data[0] || new UserModel()),
		[]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	const updateActivityCallback = useCallback(
		async () => {
			if (!selectedUser.id) {
				return;
			}
			setIsUpdatingActivity(true);
			const model = new UpdateUserActivityRequestModel({
				id: selectedUser.id,
				isActive: !selectedUser.isActive
			})
			const bindedAction = updateUserActivityAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				if (response.value === true) {
					notifications.success(`User ${selectedUser.username} is activated.`)
				} else {
					notifications.success(`User ${selectedUser.username} is blocked.`)
				}
			}
			setIsUpdatingActivity(false);

			publishDataChanged();
		},
		[selectedUser, publishDataChanged]
	)

	return (
		<ColumnContainer margin='medium'>
			<TableButtons
				selectedUser={selectedUser}
				configureViewKey={configureViewKey}
				tableColumns={tableColumns}
				updateActivity={updateActivityCallback}
				isUpdatingActivity={isUpdatingActivity}
				filtersModel={filtersModel}
				exportFunction={exportUsersAction}
			/>
			<RemoteTable
				columns={tableColumns}
				filtersModel={filtersModel}
				filtersModelChanged={setFiltersModel}
				subscriptionTopic={dataChangedTopic}
				fetchFunction={getAllUsersGenericAction}
				rowSelectionChanged={handleRowSelectionChange}
				reorderColumns={reorderColumnsCallback}
			/>
		</ColumnContainer>
	)
}
