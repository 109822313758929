import { useCallback, useEffect, useState } from 'react';
import { ColumnContainer } from 'components/Layout';
import { Form,  TextareaField } from 'components/Form';
import { InnerTestPlanResponse } from 'services/tenantManagementService';

type Props = {
	testPlan: InnerTestPlanResponse
	save(testPlan: InnerTestPlanResponse): void
	cancel(): void
}

export const FailedStepTestForm = ({
	testPlan,
	save,
	cancel
}: Props) => {
	const [values, setValues] = useState(testPlan || new InnerTestPlanResponse());

	useEffect(
		() => {
			setValues(testPlan || new InnerTestPlanResponse())
		},
		[testPlan]
	)

	const saveCallback = useCallback(
		async () => {
			save(values);
		},
		[values, save]
	)

	return (
		<Form
			values={values}
			initialValues={testPlan}
			onChange={setValues}
			onSubmit={saveCallback}
			onCancel={cancel}
			disableUnsavedChangesGuard
			render={() => (
				<ColumnContainer margin='medium'>
					<p>Please enter comment for failed test case:</p>
					<TextareaField
						id='stepRejectionComment'
						isRequired
						maxLength={1000}
					/>
				</ColumnContainer>
			)}
		/>
	)
}
