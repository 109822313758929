import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import notifications from 'components/Notification/notification';
import { ColumnContainer } from 'components/Layout';
import TableButtons from './Table/TableButtons';
import { ApprovalTimeTravelDescriptiveRequest, GenericFilterModelCollection, TimeTravelStatusEnum, ExpenseResponse, MonthEnum, ExpenseTotalsResponse } from 'services/tenantManagementService';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { ListComponentProps } from 'features/Crud'
import { useTableColumnsMemo } from 'containers/TimeTravel/MyExpenses/Table/tableColumns';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import RejectRequestModalForm from '../../RejectRequest/RejectRequestModalForm';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { approvalExpenseAction, exportExpensesAction, getAllExpensesGenericAction, getAllExpensesGenericTotalsAction } from 'containers/TimeTravel/MyExpenses/action';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { MultiSelect } from 'components/Form';
import { monthOptions, yearOptions } from 'containers/TimeTravel/utils';
import { getMonthEnumByDate } from 'utils/dateTimeUtils';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';

const configureViewKey = 'expenses_for_approval_table';

const ExpensesTable = ({ dataChangedTopic, publishDataChanged }: ListComponentProps) => {
	const {	persistedTimeAndTravelStatus, persistedExpenseGeneralConfiguration } = useSelector((state: RootState) => state);

	const [selectedExpense, setSelectedExpense] = useState(new ExpenseResponse());
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const [selectedYears, setSelectedYears] = useState<number[]>([new Date().getFullYear()]);
	const [selectedMonths, setSelectedMonths] = useState<MonthEnum[]>([getMonthEnumByDate(new Date())]);

	const [totalsResponse, setTotalsResponse] = useState(new ExpenseTotalsResponse());

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const tableColumns = useTableColumnsMemo(totalsResponse, configureViewKey);

	const handleRowSelectionChange = useCallback(
		(data: ExpenseResponse[]) => {
			setSelectedExpense(data[0] || new ExpenseResponse());
		},
		[]
	);

	const approveDisabledMemo = useMemo(
		() => !selectedExpense.id ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, selectedExpense.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Approved, selectedExpense.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[selectedExpense, persistedTimeAndTravelStatus.itemsMap, isSubmitting]
	)

	const rejectDisabledisabledMemo = useMemo(
		() => !selectedExpense.id ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, selectedExpense.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Rejected, selectedExpense.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isSubmitting,
		[selectedExpense, persistedTimeAndTravelStatus.itemsMap, isSubmitting]
	)

	const approveCallback = useCallback(
		async () => {
			setIsSubmitting(true)
			const request = new ApprovalTimeTravelDescriptiveRequest({
				approve: true
			})
			const bindedAction = approvalExpenseAction.bind(null, selectedExpense.id, request);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`${getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, selectedExpense.id)} is approved`)
				publishDataChanged();
			}
			setIsSubmitting(false)
		},
		[selectedExpense.id, publishDataChanged]
	)

	const rejectCallback = useCallback(
		async (rejectionComment?: string) => {
			setIsSubmitting(true)
			const request = new ApprovalTimeTravelDescriptiveRequest({
				approve: false,
				rejectionText: rejectionComment
			})
			const bindedAction = approvalExpenseAction.bind(null, selectedExpense.id, request);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(`${getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, selectedExpense.id)} is rejected`)
				setIsModalOpen(false);
				publishDataChanged();
			} else {
				return convertResponseErrors(response);
			}
			setIsSubmitting(false)
		},
		[selectedExpense.id, publishDataChanged]
	)

	const fetchTotalsDataCallback = useCallback(
		async () => {
			const bindedAction = getAllExpensesGenericTotalsAction.bind(null, selectedYears, selectedMonths, filtersModel);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setTotalsResponse(response.value)
			}
		},
		[filtersModel, selectedYears, selectedMonths]
	)

	const memoFetchFunction = useCallback(
		async (filtersModel: GenericFilterModelCollection) => {
			const [, response] = await Promise.all([
				fetchTotalsDataCallback(),
				getAllExpensesGenericAction(selectedYears, selectedMonths, filtersModel)
			])

			return response;
		},
		[selectedYears, selectedMonths, fetchTotalsDataCallback]
	)

	const memoExportFunction = useMemo(
		() => exportExpensesAction.bind(null, selectedYears, selectedMonths),
		[selectedYears, selectedMonths]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ColumnContainer margin='medium'>
			<SmartContainer>
				<SmartItem>
					<SmartFormGroup label='Year'>
						<MultiSelect
							value={selectedYears}
							onChange={(newSelectedYears: number[]) => { setSelectedYears(newSelectedYears) }}
							items={yearOptions}
							getItemId={(item: number) => item}
							getItemText={(item: number) => item.toString()}
						/>
					</SmartFormGroup>
					<SmartFormGroup label='Month'>
						<MultiSelect
							value={selectedMonths}
							onChange={(newSelectedMonths: MonthEnum[]) => { setSelectedMonths(newSelectedMonths) }}
							items={monthOptions}
							getItemId={(item: MonthEnum) => item}
							getItemText={(item: MonthEnum) => item}
						/>
					</SmartFormGroup>
				</SmartItem>
			</SmartContainer>
			<TableButtons
				selectedId={selectedExpense.id}
				approveDisabled={approveDisabledMemo}
				rejectDisabled={rejectDisabledisabledMemo}
				approvalEnabled={persistedExpenseGeneralConfiguration.value.enableApprovalProcess}
				onApprove={approveCallback}
				onReject={() => {setIsModalOpen(true)}}
				tableColumns={tableColumns}
				configureViewKey={configureViewKey}
				exportFunction={memoExportFunction}
				filtersModel={filtersModel}
			/>
			<RemoteTable
				columns={tableColumns}
				filtersModel={filtersModel}
				filtersModelChanged={setFiltersModel}
				subscriptionTopic={dataChangedTopic}
				fetchFunction={memoFetchFunction}
				rowSelectionChanged={handleRowSelectionChange}
				reorderColumns={reorderColumnsCallback}
			/>
			<RejectRequestModalForm
				title={`Reject expense - ${getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, selectedExpense.id)}`}
				onCancel={() => {setIsModalOpen(false)}}
				onSave={rejectCallback}
				open={isModalOpen}
				rejectionCommentRequired={persistedExpenseGeneralConfiguration.value.requireCommentsWhenRejecting}
			/>
		</ColumnContainer>
	)
}
export default ExpensesTable;
