import { AutoCompleteField, CheckboxField, Form, FormGroup } from 'components/Form'
import { VerticalSeparator } from 'components/Layout'
import notifications from 'components/Notification/notification'
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer'
import WithFetch from 'features/Fetch/WithFetch'
import { useCallback, useState } from 'react'
import { ScheduleConfigurationRequest, ScheduleConfigurationResponse, ScheduleDurationEnum } from 'services/tenantManagementService'
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils'
import { propertyOf } from 'utils/propertyOf'
import { getScheduleConfigurationAction, persistScheduleConfigurationAction, updateScheduleConfigurationAction } from './action'

const duration = [
	ScheduleDurationEnum.Hours,
	ScheduleDurationEnum.Days,
	ScheduleDurationEnum.Weeks,
	ScheduleDurationEnum.Months
];

type Props = {
	projectId: number
	onClose: () => void
};

export const ConfigurationForm = ({ projectId, onClose }: Props) => {
	const [values, setValues] = useState(new ScheduleConfigurationResponse());
	const [initialValues, setInitialValues] = useState(new ScheduleConfigurationResponse());

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getScheduleConfigurationAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success && response.value) {
				setValues(response.value);
				setInitialValues(response.value);
			}
		},
		[projectId]
	);

	const onSubmitCallback = useCallback(
		async () => {
			const model = new ScheduleConfigurationRequest(values);
			const bindedAction = updateScheduleConfigurationAction.bind(null, projectId, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				await persistScheduleConfigurationAction(projectId);
				setValues(response.value || new ScheduleConfigurationResponse());
				setInitialValues(response.value || new ScheduleConfigurationResponse());
				notifications.success('Schedule configuration is updated.');
				onClose();
			} else {
				return convertResponseErrors(response);
			}
		},
		[values, projectId, onClose]
	)

	const cancelCallback = useCallback(
		() => {
			setValues(initialValues);
			onClose();
		},
		[initialValues, onClose]
	);

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<Form
				values={values}
				initialValues={initialValues}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={cancelCallback}
				render={() => (
					<SmartContainer>
						<SmartItem>
							<FormGroup title='Time'>
								<AutoCompleteField
									id={propertyOf<ScheduleConfigurationResponse>('durationIn')}
									label='Duration in'
									items={duration}
									getItemId={(item: ScheduleDurationEnum) => item}
									getItemText={(item: ScheduleDurationEnum) => item}
								/>
								<CheckboxField
									id={propertyOf<ScheduleConfigurationResponse>('skipWeekends')}
									labelBefore='Skip weekends when calculating duration'
								/>
								<CheckboxField
									id={propertyOf<ScheduleConfigurationResponse>('calculateDayDurationFromSettings')}
									labelBefore='If duration is measured in hours, calculate day duration as defined in calendar settings for site country (if not checked day duration is 24h)'
								/>
							</FormGroup>
							<VerticalSeparator />
							<FormGroup title='Tasks'>
								<CheckboxField
									id={propertyOf<ScheduleConfigurationResponse>('autocompleteTaskWhenSubtasksCompleted')}
									labelBefore='Autocomplete task when all subtasks are completed  (and vice versa)'
								/>
								<CheckboxField
									id={propertyOf<ScheduleConfigurationResponse>('deriveTaskDatesFromSubtasks')}
									labelBefore='Derive task dates from subtask dates'
								/>
							</FormGroup>
						</SmartItem>
					</SmartContainer>
				)}
			/>
		</WithFetch>
	)
}
