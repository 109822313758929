import { ContentShell } from 'features/Content/ContentShell'
import { StatusResponseTable } from 'features/StatusResponse/StatusResponseTable'
import { getCommunicationStatusesAction, updateCommunicationStatusesAction } from './action'
import { CommunicationStatusesHelp } from './CommunicationStatusesHelp'

export const Communication = () => {
	return (
		<ContentShell
			InlineHelpComponent={CommunicationStatusesHelp}
			showBreadcrumb={false}
		>
			<StatusResponseTable
				getConfigsAction={getCommunicationStatusesAction}
				updateConfigsAction={updateCommunicationStatusesAction}
			/>
		</ContentShell>
	)
}
