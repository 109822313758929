import { RootState } from "base/reducer/reducer"
import { HtmlSanitized } from "components/HtmlSanitized/HtmlSanitized"
import { RowContainer, VerticalSeparator } from "components/Layout"
import { SmartFormGroup } from "components/SmartContainer/SmartContainer"
import { useUserFullNameCallback } from "features/TableColumns/persistedHooks"
import { useSelector } from "react-redux"
import { UpdateTicketCommentRequest, TicketCommnetResponse } from "services/tenantManagementService"
import { formatDateTime } from "utils/dateTimeUtils"
import { EntityPrefixEnum, getFormatedId, noop } from 'utils/commonHelper';
import { useCallback, useMemo, useState } from "react"
import { Info } from "components/Form/controls/Info/Info"
import { UpdateTask } from "./Task/Crud/UpdateTask"
import { updateTicketCommentAction, downloadTicketCommentAttachmentAction } from "./action"
import { convertResponseErrors, tryCatchJsonByAction } from "utils/fetchUtils"
import { useItems } from "components/Form/controls/Attachment/useItems"

type Props = {
	comment: TicketCommnetResponse
	refetch(): Promise<void>
}

export const Comment = ({ comment, refetch }: Props) => {
	const { persistedUser } = useSelector((state: RootState) => state);
	const getUserFullName = useUserFullNameCallback(persistedUser);

	const [isUpdateOpened, setIsUpdateOpened] = useState(false);

	const openUpdateDialog = useCallback(
		() => setIsUpdateOpened(true),
		[]
	)

	const updateTaskCallback = useCallback(
		async (newComment: UpdateTicketCommentRequest) => {
			const model = new UpdateTicketCommentRequest({
				...newComment,
				newAttachments: []
			})

			const bindedAction = updateTicketCommentAction.bind(null, comment.ticketId, model);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setIsUpdateOpened(false);
				refetch();
			} else {
				return convertResponseErrors(response);
			}
		},
		[comment, refetch]
	)

	const taskContent = useMemo(
		() => {
			if (comment.taskId) {
				const formattedId = getFormatedId(EntityPrefixEnum.TASK, comment.taskId);
				return (
					<Info
						text={formattedId}
						onClick={openUpdateDialog}
						disabled
					/>
				)
			}

			return <></>
		},
		[comment.taskId, openUpdateDialog]
	)

	const downloadAttachmentMemo = useMemo(
		() => downloadTicketCommentAttachmentAction.bind(null, comment.id),
		[comment.id]
	)

	const attachmentsContent = useItems(undefined, comment.attachments, true, noop, undefined, downloadAttachmentMemo);

	return (
		<SmartFormGroup label={getUserFullName(comment.userId)}>
			<strong>
				<HtmlSanitized html={comment.comment} />
			</strong>
			<VerticalSeparator margin='xsmall' />
			<small>{formatDateTime(comment.createdOn)}</small>
			<VerticalSeparator margin='xsmall' />
			<RowContainer>
				{taskContent}
				{attachmentsContent}
			</RowContainer>
			<UpdateTask
				isOpened={isUpdateOpened}
				setIsOpened={setIsUpdateOpened}
				task={comment}
				updateTask={updateTaskCallback}
			/>
		</SmartFormGroup>
	)
}
