import { MySurveyQuestionResponse, ProjectResponse, SurveyQuestionTypeEnum, SurveyResponse } from 'services/tenantManagementService';
import { FormField } from 'components/Export';
import { getUserInfo } from 'utils/storageUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import ParagraphField from 'components/Export/ParagraphField';
import TextareaFormField from 'components/Export/TextareaFormField';
import CheckboxFormField from 'components/Export/CheckboxFormField';
import FormGroup from 'components/Export/FormGroup';
import RadioItemFormField from 'components/Export/RadioItemFormField';
import { SURVEY_RATING_OPTIONS } from '../../mySurveyConstants';
import { View } from '@react-pdf/renderer';

type Props = {
	survey: SurveyResponse
	surveyQuestions: MySurveyQuestionResponse[]
	project: ProjectResponse
}

const MySurveyDocumentForm = ({
	survey,
	surveyQuestions,
	project
}: Props) => {
	const user = getUserInfo();

	return (
		<>
			<FormField
				label='User'
				value={`${user?.firstName} ${user?.lastName}`}
			/>
			<FormField
				label='Project'
				value={`${getFormatedId(EntityPrefixEnum.PROJECT, project.id)} - ${project.name}`}
			/>
			<FormField
				label={survey.surveyType}
				value={`${getFormatedId(EntityPrefixEnum.SURVEY, survey.id)} - ${survey.name}`}
			/>
			{survey.description && (
				<ParagraphField
					text={survey.description}
				/>
			)}
			{surveyQuestions.map((question, index) => {
				switch (question.surveyType) {
					case SurveyQuestionTypeEnum.Answer:
						return (
							<FormGroup label={`${index + 1}. ${question.questionGroup} - ${question.question}`} key={index}>
								<TextareaFormField
									value={question.answers && question.answers.length > 0 ?
										(question.answers[0].answer || '') : ''}
								/>
							</FormGroup>

						)
					case SurveyQuestionTypeEnum.MultipleChoice:
						return (
							<FormGroup label={`${index + 1}. ${question.questionGroup} - ${question.question}`} key={index}>
								{question.proposedAnswers?.map((proposedAnswer, index) => (
									<CheckboxFormField
										key={index}
										label={proposedAnswer.answer}
										value={!!question.answers?.find(ans => ans.proposedAnswerId === proposedAnswer.id)}
									/>
								))}
							</FormGroup>
						)
					case SurveyQuestionTypeEnum.SingleChoice:
						return (
							<FormGroup label={`${index + 1}. ${question.questionGroup} - ${question.question}`} key={index}>
								{question.proposedAnswers?.map((proposedAnswer, index) => (
									<RadioItemFormField
										key={index}
										label={proposedAnswer.answer}
										value={!!question.answers?.find(ans => ans.proposedAnswerId === proposedAnswer.id)}
									/>
								))}
							</FormGroup>
						)
					case SurveyQuestionTypeEnum.Rating:
						return (
							<FormGroup label={`${index + 1}. ${question.questionGroup} - ${question.question}`} key={index}>
								<View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
									{SURVEY_RATING_OPTIONS.map((option, index) => (
										<RadioItemFormField
											key={index}
											label={option.text}
											value={!!question.answers?.find(ans => ans.rating === option.id)}
										/>
									))}
								</View>
							</FormGroup>
						)
				default:
					return (
						<></>
					)
				}
			})}
		</>
	);
};

export default MySurveyDocumentForm;
