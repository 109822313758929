import { getTenantId } from "features/Tenant/tenantUtils";
import { ExportDataModel, GenericFilterModelCollection, SurveyAnswerClient } from "services/tenantManagementService";

export const getSurveyResultsAction = (projectId: number, surveyId: number, model: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new SurveyAnswerClient();
	return client.getAll(tenantId, projectId, surveyId, model);
}

export const exportAction = (projectId: number, surveyId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new SurveyAnswerClient();
	return client.export(tenantId, projectId, surveyId, exportDataModel);
}
