import { HelpShell } from 'features/Help/HelpShell';

export const MyTrainingPlanHelp = () => {
	return (
		<HelpShell title='My training package'>
			<p>My Training plan shows user training plans based on processes (level 3) defined in training cycle and generated training plan for the cycle.</p>
			<p>User is maintaining training status and can assess training and video links provided, but also all attachments provided in scope module. </p>
			<p>Based on training status updated by users, reports are generated for project managers.</p>
		</HelpShell>
	)
}
