import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import notifications from 'components/Notification/notification';
import { RemoteTable } from 'components/Table';
import { persistTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { setConfigureViewTableAction } from 'features/ConfigureView';
import { ContentShell } from 'features/Content/ContentShell';
import { GenericFilterModelCollection, MultiplyUpdateTrainingPlanRequest, ProjectResponse, TenantIsActiveResponse, UpdateTrainingPlan } from 'services/tenantManagementService';
import { getNumberArraysDiff } from 'utils/arrayUtil';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { isUserPmorSubstitutePmOrSiteAdmin } from 'utils/userRoleHelper';
import { exportAction, getTrainingPlansGenericAction, setAllIsActiveAction, updateTrainingPlansAction } from './actions';
import { TrainingPlanHelp } from './Help/TrainingPlanHelp';
import TableButtons from './Table/TableButtons';
import { useTableColumnsMemo } from './Table/tableColumns';
import { onCellEdit } from './Table/tableEditorHelper';
import { CustomTrainingPlanRowModel, mapToCustomTrainingPlanRowModel } from './Table/tableModel';

type Props = {
	project: ProjectResponse
	disabledEdit?: boolean
	trainingCycle: TenantIsActiveResponse
}

const configureViewKey = 'training_plan';

export const TrainingPlanTable = ({ project, disabledEdit, trainingCycle }: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [disableSave, setDisableSave] = useState(false);
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const [updateRequest, setUpdateRequest] = useState(new MultiplyUpdateTrainingPlanRequest());
	const [originalParticipantsMap, setOriginalParticipantsMap] = useState(new Map<number, number[]>());
	const stateRef = useRef(new MultiplyUpdateTrainingPlanRequest());
	stateRef.current = updateRequest;

	const {
		persistedConfigureView,
		persistedTeamMember
	} = useSelector((state: RootState) => state);

	const disabledTrainingPlanMaintain = useMemo(
		() => {
			return disabledEdit || !(isUserPmorSubstitutePmOrSiteAdmin(project.roleId) || project.permissions?.trainingPermission?.maintainTrainingPlan);
		},
		[project, disabledEdit]
	)

	const tableColumns = useTableColumnsMemo(
		project.id,
		persistedConfigureView.value[configureViewKey],
		disabledTrainingPlanMaintain
	);

	useEffect(
		() => {
			if (!persistedTeamMember.projectMap[project.id]) {
				persistTeamMembersAction(project.id);
			}
		},
		[persistedTeamMember, project.id]
	)

	const saveCallback = useCallback(
		async () => {
			setIsLoading(true);
			const bindedAction = updateTrainingPlansAction.bind(null, project.id, trainingCycle.id, updateRequest);

			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Training plans successfully updated');
				setUpdateRequest(new MultiplyUpdateTrainingPlanRequest());
			}
			setIsLoading(false);
		},
		[updateRequest, project.id, trainingCycle.id]
	)

	// just a quick fix, memoFetchFunction was changed after project is changed and unneeded request was send
	const [currentProject, setCurrentProject] = useState(new ProjectResponse(project));
	useEffect(
		() => {
			setCurrentProject(new ProjectResponse(project));
		},
		[project]
	)

	const memoFetchFunction = useMemo(
		() => async (genericFilter: GenericFilterModelCollection) => {
			const response = await getTrainingPlansGenericAction(currentProject.id, trainingCycle.id, genericFilter)
			setOriginalParticipantsMap((state: Map<number, number[]>) => {
				if (response.success && response.items) {
					const newParticipantsMap: Map<number, number[]> = new Map<number, number[]>(state)
					response.items.forEach(x => {
						newParticipantsMap.set(x.id, (x.participants || []).map(y => y.userId))
					})
					return newParticipantsMap
				}

				return state
			})
			setIsLoading(false);
			return response
		},
		[currentProject.id, trainingCycle]
	)

	const memoExportFunction = useMemo(
		() => exportAction.bind(null, currentProject.id, trainingCycle.id),
		[currentProject.id, trainingCycle]
	)

	const validationFailedCallback = useCallback(
		(cell: any) => {
			setDisableSave(true)
		},
		[]
	)

	const cellEditCancelledCallback = useCallback(
		(cell: any) => {
			const table = cell.getTable()
			const invalidCells = table.getInvalidCells()
			const invalid = !!invalidCells && invalidCells.length > 0
			setDisableSave(invalid)
		},
		[]
	)

	const setIsActiveCallback = useCallback(
		async (isActive: boolean) => {
			setIsLoading(true);
			const bindedAction = setAllIsActiveAction.bind(null, currentProject.id, trainingCycle.id, isActive, filtersModel);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				// triggers new fetch. Stupid i know...
				setFiltersModel(new GenericFilterModelCollection(filtersModel));
			}
			setIsLoading(false);
		},
		[currentProject.id, trainingCycle.id, filtersModel]
	)

	const cellEditedCallback = useCallback(
		(cell: any) => {
			const table = cell.getTable()
			const invalidCells = table.getInvalidCells()
			if (invalidCells && invalidCells.length > 0) {
				return
			}

			setDisableSave(false)
			onCellEdit(cell);

			const data: CustomTrainingPlanRowModel = cell.getData();
			let trainingPlans = stateRef.current.trainingPlans || [];
			let found =  trainingPlans.find(tp => tp.id === data.id);
			const updatedTrainingPlan = {
				...data,
				addedParticipantUserIds: (found?.addedParticipantUserIds || []),
				removedParticipantUserIds: (found?.removedParticipantUserIds || [])
			}

			if (cell.getField() === 'participants') {
				const { added, removed } = getNumberArraysDiff(originalParticipantsMap.get(data.id), data.participants)
				updatedTrainingPlan.addedParticipantUserIds = added
				updatedTrainingPlan.removedParticipantUserIds = removed
			}

			const changedObject = new UpdateTrainingPlan(updatedTrainingPlan);

			if (!found) {
				trainingPlans = [...trainingPlans, changedObject];
			} else {
				trainingPlans = trainingPlans.map(tp => tp.id === data.id ? changedObject : tp);
			}

			const newUpdateRequest = new MultiplyUpdateTrainingPlanRequest({
				trainingPlans
			})

			setUpdateRequest(newUpdateRequest);
		},
		[originalParticipantsMap],
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ContentShell
			title='Training Plan'
			FloatingHelpComponent={TrainingPlanHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					disabled={disableSave || disabledTrainingPlanMaintain}
					onSave={saveCallback}
					onSetIsActive={setIsActiveCallback}
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					filtersModel={filtersModel}
					exportFunction={memoExportFunction}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					fetchFunction={memoFetchFunction}
					reorderColumns={reorderColumnsCallback}
					mapResponse={mapToCustomTrainingPlanRowModel}
					cellEdited={cellEditedCallback}
					validationFailed={validationFailedCallback}
					cellEditCancelled={cellEditCancelledCallback}
					isLoading={isLoading}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}
