import { getTenantId } from 'features/Tenant/tenantUtils';
import { ExportDataModel, GenericFilterModelCollection, InsertReminderRequest, ReminderClient, UpdateReminderRequest } from 'services/tenantManagementService';

export const getAllRemindersGenericFilterAction = (projectOrCategoryId: number, isProjectConnected: boolean, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ReminderClient();
	return client.getAllGenericFilter(tenantId, projectOrCategoryId, isProjectConnected, genericFilter);
}

export const getReminderAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new ReminderClient();
	return client.get(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const createReminderAction = (model: InsertReminderRequest) => {
	const tenantId = getTenantId()!;
	const client = new ReminderClient();
	return client.create(tenantId, model);
}

export const updateReminderAction = (model: UpdateReminderRequest) => {
	const tenantId = getTenantId()!;
	const client = new ReminderClient();
	return client.update(tenantId, model);
}

export const deleteReminderAction = (id: number, projectOrCategoryId: number, isProjectConnected: boolean) => {
	const tenantId = getTenantId()!;
	const client = new ReminderClient();
	return client.delete(tenantId, id, projectOrCategoryId, isProjectConnected);
}

export const exportRemindersAction = (projectOrCategoryId: number, isProjectConnected: boolean, model: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ReminderClient();
	return client.exportAll(tenantId, projectOrCategoryId, isProjectConnected, model);
}
