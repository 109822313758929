import { useCallback, useState } from 'react';
import {ExpenseItemResponse } from 'services/tenantManagementService';
import { ColumnContainer } from 'components/Layout';
import { useTableColumnsMemo } from './Table/tableColumns'
import TableButtons from './Table/TableButtons';
import ExpenseItemsCrudRouter from './ExpenseItemsCrudRouter';
import { generateNumberId } from 'base/id';
import { LocalTable } from 'components/Table';

type Props = {
	expenseItems: ExpenseItemResponse[]
	onChange: (expenseItems: ExpenseItemResponse[]) => void
	disabled: boolean
};

const ExpenseItems = ({ expenseItems, onChange, disabled }: Props) => {
	const [selectedExpenseItem, setSelectedExpenseItem] = useState(new ExpenseItemResponse());

	const tableColumns = useTableColumnsMemo();

	const selectCallback = useCallback(
		(data: ExpenseItemResponse[]) => setSelectedExpenseItem(data[0] || new ExpenseItemResponse()),
		[]
	)

	const addCallback = useCallback(
		(newExpenseItem: ExpenseItemResponse) => {
			newExpenseItem.id = generateNumberId();

			const newExpenseItems = [
				...expenseItems,
				newExpenseItem
			]

			onChange(newExpenseItems);
		},
		[expenseItems, onChange]
	)

	const updateCallback = useCallback(
		(updatedExpenseItem: ExpenseItemResponse) => {
			const newExpenseItems = expenseItems.map(expenseItem => expenseItem.id === updatedExpenseItem.id ? updatedExpenseItem : expenseItem);
			onChange(newExpenseItems);
		},
		[expenseItems, onChange]
	)

	const deleteCallback = useCallback(
		(id: number) => {
			const newActivities = expenseItems.filter(act => act.id !== id);
			onChange(newActivities);
			return Promise.resolve();
		},
		[expenseItems, onChange]
	)

	return (
		<ColumnContainer margin='medium'>
			<TableButtons
				selectedId={selectedExpenseItem.id}
				onDelete={deleteCallback}
				disabled={disabled}
			/>
			<LocalTable
				columns={tableColumns}
				data={expenseItems}
				rowSelectionChanged={selectCallback}
			/>
			<ExpenseItemsCrudRouter
				expenseItem={selectedExpenseItem}
				onAdd={addCallback}
				onUpdate={updateCallback}
			/>
		</ColumnContainer>
	)
}

export default ExpenseItems
