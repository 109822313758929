import { View, StyleSheet } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import {TimeTravelStatusResponse, UserModel, VacationRequestResponse, VacationRequestStatusResponse } from 'services/tenantManagementService';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';

type Props = {
	vacationRequest: VacationRequestResponse
	persistedTimeAndTravelStatus: PersistItemsReducer<TimeTravelStatusResponse>
	persistedUser: PersistItemsReducer<UserModel>
}

const sanitizeValue = (value: string | null | undefined) => value || '-'

const VacationRequestDocumentTable = ({ vacationRequest, persistedUser, persistedTimeAndTravelStatus}: Props) => {
	const items = vacationRequest.statuses

	return (
		<View style={styles.container} wrap={false}>
			<Table data={items}>
				<TableHeader>
					<TableCell style={styles.header}>Project Id</TableCell>
					<TableCell style={styles.header}>Project manager</TableCell>
					<TableCell style={styles.header}>Approved/Rejected by</TableCell>
					<TableCell style={styles.header}>Status</TableCell>
				</TableHeader>
				<TableBody>
					<DataTableCell style={styles.text} getContent={(item: VacationRequestStatusResponse) => sanitizeValue(getFormatedId(EntityPrefixEnum.PROJECT, item.projectId))} />
					<DataTableCell style={styles.text} getContent={(item: VacationRequestStatusResponse) => sanitizeValue(`${persistedUser.itemsMap[item.projectManagerUserId]?.firstName} ${persistedUser.itemsMap[item.projectManagerUserId]?.lastName}`)} />
					<DataTableCell style={styles.text} getContent={(item: VacationRequestStatusResponse) => sanitizeValue(item.approvedOrRejectedByUserId ? `${persistedUser.itemsMap[item.approvedOrRejectedByUserId]?.firstName} ${persistedUser.itemsMap[item.approvedOrRejectedByUserId]?.lastName}` : '')} />
					<DataTableCell style={styles.text} getContent={(item: VacationRequestStatusResponse) => sanitizeValue(persistedTimeAndTravelStatus.itemsMap[item.statusId]?.name)} />
				</TableBody>
			</Table>
		</View>
	);
};

export default VacationRequestDocumentTable;

const styles = StyleSheet.create({
	container: {
		paddingVertical: 10,
	},
	header: {
		flexWrap: 'wrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: 2,
		fontSize: 10,
		fontWeight: 500
	},
	text: {
		flexWrap: 'wrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: 2,
		fontSize: 10,
	}
})
