import { RootState } from 'base/reducer/reducer';
import Button from 'components/Button';
import { Input } from 'components/Form';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ReadComponentProps } from 'features/Crud/CrudRouter';
import { useGoBackCallback } from 'features/Routes/historyHooks';
import { useFormattedProjectIdNameCallback, useStatusCallback, useUsernameCallback } from 'features/TableColumns/persistedHooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getMonthEnumByDate } from 'utils/dateTimeUtils';
import ReadTimesheetsTable from './Table/ReadTimesheetsTable';

type ParamType = {
	projectId: string;
	statusId: string;
	userId: string;
	from: string;
	to: string;
}

const ReadTimesheets = ({publishDataChanged}: ReadComponentProps) => {
	const params: ParamType = useParams();

	const { persistedUser, persistedTimeAndTravelStatus, persistedProject } = useSelector((state: RootState) => state)

	const getUsername = useUsernameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedTimeAndTravelStatus)

	const projectIdMemo = useMemo(() => parseInt(params.projectId), [params.projectId])
	const statusIdMemo = useMemo(() => parseInt(params.statusId), [params.statusId])
	const userIdMemo = useMemo(() => parseInt(params.userId), [params.userId])
	const fromMemo = useMemo(() => new Date(parseInt(params.from)), [params.from])
	const toMemo = useMemo(() => new Date(parseInt(params.to)), [params.to])

	const goBackCallback = useGoBackCallback();

	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	return (
		<ColumnContainer>
			<RowContainer justifyContent='flex-start'>
				<Button text='Back' onClick={() => goBackCallback(6)} />
			</RowContainer>
			<SmartContainer>
				<SmartItem>
					<SmartFormGroup label='User'>
						<Input
							value={getUsername(userIdMemo)}
							disabled
						/>
					</SmartFormGroup>
					<SmartFormGroup label='Year'>
						<Input
							value={fromMemo.getFullYear().toString()}
							disabled
						/>
					</SmartFormGroup>
					<SmartFormGroup label='Month'>
						<Input
							value={getMonthEnumByDate(fromMemo)}
							disabled
						/>
					</SmartFormGroup>
				</SmartItem>
				<SmartItem>
					<SmartFormGroup label='Project'>
						<Input
							value={formatProjectIdName(projectIdMemo)}
							disabled
						/>
					</SmartFormGroup>
					<SmartFormGroup label='Status'>
						<Input
							value={getStatus(statusIdMemo)}
							disabled
						/>
					</SmartFormGroup>
				</SmartItem>
			</SmartContainer>
			<ReadTimesheetsTable
				projectId={projectIdMemo}
				statusId={statusIdMemo}
				userId={userIdMemo}
				from={fromMemo}
				to={toMemo}
				publishDataChanged={publishDataChanged}
			/>
		</ColumnContainer>
	)
}

export default ReadTimesheets;
