import styles from '../onboarding.module.scss';

type Props = {
	text: string
}

const Subtitle = ({ text }: Props) => {
	return (
		<p className={styles.subtitle}>{text}</p>
	)
}

export default Subtitle;
