import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from 'components/Map/map.module.scss'
import { ReordableList } from 'components/DragNDrop/ReordableList/ReordableList';
import { TicketCategoryResponse, UpdateOrderRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { Item } from './Item';
import { OverlaySpinner } from 'components/Spinner';
import { getAllTicketCategoriesAction, reorderTicketCategoryAction } from '../action';
import { CreateItem } from '../Crud/CreateItem';

type Props = {
	level: number
	parentId?: number
	parentIndex: number // index of selected element, used for lines to be drawn between items
}

export const CategoryItems = ({ level, parentId, parentIndex }: Props) => {
	const [items, setItems] = useState<TicketCategoryResponse[]>([]);
	const [selectedId, setSelectedId] = useState<number>();
	const [fetching, setFetching] = useState(false);
	const [reordering, setReordering] = useState(false);

	const fetchDataCallback = useCallback(
		async () => {
			setFetching(true);

			const bindedAction = getAllTicketCategoriesAction.bind(null, parentId, level);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.items) {
				setItems(response.items || []);
			}

			setFetching(false);
		},
		[level, parentId]
	);

	useEffect(
		() => {
			fetchDataCallback();
		},
		[fetchDataCallback]
	)

	useEffect(
		() => {
			setSelectedId(undefined);
		},
		[parentId]
	)

	const reorderCallback = useCallback(
		async (newElementIds: number[]) => {
			setReordering(true);

			const modelOrders: UpdateOrderRequest[] = [];
			for (let i = 0; i < newElementIds.length; i++) {
				modelOrders.push(new UpdateOrderRequest({
					id: newElementIds[i],
					order: i + 1
				}));
			}

			const bindedAction = reorderTicketCategoryAction.bind(null, modelOrders);
			const response = await tryCatchJsonByAction(bindedAction);

			setReordering(false);
			if (response.success) {
				await fetchDataCallback();
			}
		},
		[fetchDataCallback]
	)

	const renderItemCallback = useCallback(
		(id: number) => {
			const item = items.find((item) => item.id === id);

			// sometimes ReordableList has some old elementIds, and item can be undefined
			if (!item) {
				return;
			}

			return (
				<Item
					key={item.id.toString()}
					item={item}
					items={items}
					onClick={setSelectedId}
					isSelected={selectedId === item.id}
					deselect={setSelectedId}
					fetchData={fetchDataCallback}
					reorder={reorderCallback}
				/>
			)
		},
		[selectedId, items, fetchDataCallback, reorderCallback]
	)

	const elementIds = useMemo(
		() => items.map((item) => item.id),
		[items]
	)

	const prevHeight = parentIndex * (32 + 8); // 32 item height, 8 margin-bottom
	const nextHeight = (items.length - 1) * (32 + 8);
	const lineHeight = Math.max(1, prevHeight, nextHeight);

	return (
		<>
			{(parentId && level < 4) && <div className={styles.line_vertical} style={{ height: `${lineHeight}px` }} />}
			{level < 4 &&
				<div className={styles.container}>
					{items.length > 0 &&
						<ReordableList
							initialElementIds={elementIds}
							onReorder={reorderCallback}
							renderItem={renderItemCallback}
						/>
					}
					<CreateItem
						items={items}
						level={level}
						parentId={parentId}
						fetchData={fetchDataCallback}
					/>
					{(fetching || reordering) && <OverlaySpinner size={60} withBackground />}
				</div>
			}
			{selectedId &&
				<CategoryItems
					parentId={selectedId}
					parentIndex={elementIds.indexOf(selectedId)}
					level={level + 1}
				/>
			}
		</>
	)
}
