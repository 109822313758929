import { useCallback, useMemo } from 'react'
import { ColumnContainer } from 'components/Layout'
import { useTableColumnsMemo } from './tableColumns'
import { ProjectTimeTravelDashboardFilterRequest, VacationRequestResponse } from 'services/tenantManagementService'
import TableButtons from './TableButtons'
import { exportVacationRequestDashboardAction } from './action'
import { LocalTable } from 'components/Table'
import { setConfigureViewTableAction } from 'features/ConfigureView'

type Props = {
	data: VacationRequestResponse[]
	filterModel: ProjectTimeTravelDashboardFilterRequest
}

const configureViewKey = 'vacation_requests_dashboard_table';

export const VacationRequestDashboardTable = ({ data, filterModel }: Props) => {
	const tableColumns = useTableColumnsMemo(configureViewKey);

	const memoExportFunction = useMemo(
		() => exportVacationRequestDashboardAction.bind(null, filterModel),
		[filterModel]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ColumnContainer margin='small'>
			<TableButtons
				tableColumns={tableColumns}
				configureViewKey={configureViewKey}
				exportFunction={memoExportFunction}
			/>
			<LocalTable
				columns={tableColumns}
				data={data}
				hasPagination
				limit={5}
				reorderColumns={reorderColumnsCallback}
			/>
		</ColumnContainer>
	)
}
