import Tabs from 'components/Tabs/Tabs';
import { FinanceDashboardFilterRequest, FinanceDashboardResponse } from 'services/tenantManagementService';
import { dashboardTabs } from './Tabs/dashboardTabs';

export type TabComponentProps = {
	loading: boolean
	dashboard: FinanceDashboardResponse
	filterModel: FinanceDashboardFilterRequest
}

export const FilteredDashboard = ({ loading, dashboard, filterModel }: TabComponentProps) => {
	return (
		<Tabs
			tabs={dashboardTabs}
			tabComponentProps={{ loading, dashboard, filterModel }}
		/>
	)
}
