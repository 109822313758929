import { useCallback, useEffect, useMemo, useState } from 'react';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { InsertAttachmentRequest, ProjectResponse, ScopeItemLevelResponse } from 'services/tenantManagementService';
import { Attachment, Form, InputField, MultiSelectField, TextareaField } from 'components/Form';
import { propertyOf } from 'utils/propertyOf';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getSimpleChangeRequestsAction } from 'containers/Scope/ChangeRequests/action';
import { useUniqueNameValidatorCallback } from './useUniqueNameValidatorCallback';
import { downloadAttachmentAction } from '../../action';
import { removeAttachmentAction } from '../action';
import notifications from 'components/Notification/notification';

type Props = {
	item: ScopeItemLevelResponse
	items: ScopeItemLevelResponse[]
	project: ProjectResponse
	onSave(item: ScopeItemLevelResponse, newAttachments?: InsertAttachmentRequest[]): void
	onCancel(): void
}

export const Level3andLevel4Form = ({ item, items, project, onSave, onCancel }: Props) => {
	const [values, setValues] = useState(item || new ScopeItemLevelResponse());
	const [newAttachments, setNewAttachments] = useState<InsertAttachmentRequest[]>()

	const uniqueNameValidatorCallback = useUniqueNameValidatorCallback(items, item.name);

	const [changeRequestIds, setChangeRequestIds] = useState<number[]>([]);
	const [fetchingChangeRequests, setFetchingChangeRequests] = useState(true);

	const fetchChangeRequestsCallback = useCallback(
		async () => {
			setFetchingChangeRequests(true);
			const bindedAction = getSimpleChangeRequestsAction.bind(null, project.id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setChangeRequestIds(response.items?.map(cr => cr.id) || []);
			}
			setFetchingChangeRequests(false);
		},
		[project.id]
	)

	useEffect(
		() => {
			fetchChangeRequestsCallback()
		},
		[fetchChangeRequestsCallback]
	)

	const onSubmitCallback = useCallback(
		async () => await onSave(values, newAttachments),
		[onSave, values, newAttachments]
	)

	const downloadAttachmentMemo = useMemo(
		() => downloadAttachmentAction.bind(null, project.id),
		[project.id]
	)

	const removeAttachmentCallback = useCallback(
		async (id: number) => {
			const bindedAction = removeAttachmentAction.bind(null, project.id, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Attachment is successfully deleted');
				setValues((state: ScopeItemLevelResponse) =>
					new ScopeItemLevelResponse({
						...state,
						attachments: state.attachments?.filter(att => att.id !== id)
					})
				)
			}
		},
		[project.id]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={onCancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<InputField
							id={propertyOf<ScopeItemLevelResponse>('name')}
							label='ID'
							isRequired
							maxLength={values.levelNumber === 3 ? 3 : 40}
							validator={values.levelNumber === 3 ? uniqueNameValidatorCallback : undefined}
						/>
						<InputField
							id={propertyOf<ScopeItemLevelResponse>('description')}
							label={values.levelNumber === 3 ? 'Process name' : 'Process step'}
							isRequired
							maxLength={80}
						/>
						{values.levelNumber === 3 ?
							(
								<>
									<TextareaField
										id={propertyOf<ScopeItemLevelResponse>('processDescription')}
										label='Process description'
										maxLength={2000}
									/>
									<MultiSelectField
										id={propertyOf<ScopeItemLevelResponse>('changeRequestIds')}
										label='Change requests'
										items={changeRequestIds}
										getItemId={(item: number) => item}
										getItemText={(item: number) => getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, item)}
										loading={fetchingChangeRequests}
									/>
									<SmartFormGroup label='New attachment'>
										<Attachment
											value={newAttachments}
											onChange={setNewAttachments}
											oldAttachments={values.attachments}
											removeOldAttachment={removeAttachmentCallback}
											downloadOldAttachment={downloadAttachmentMemo}
										/>
									</SmartFormGroup>
								</>
							) : (
								<>
									<InputField
										id={propertyOf<ScopeItemLevelResponse>('department')}
										label='Department'
										maxLength={40}
									/>
									<InputField
										id={propertyOf<ScopeItemLevelResponse>('stepType')}
										label='Step type'
										maxLength={40}
									/>
								</>
							)
						}
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}
