import { HelpShell } from 'features/Help/HelpShell';

export const ProjectHelp = () => {
	return (
		<HelpShell title='Projects'>
			<p>New projects are always created by site administrator. <br />
				Site administrator is defining project name and assigning project manager.
			</p>
			<p>Prerequisite for this step is to create user ID for project manager.</p>
			<p>After this step, project manager can maintain project information. <br />
				Initially, project status is set on planning which means that only project manager can view this project in project module. <br />
				Upon maintaining project with all information needed and assigning users to project, project is released by responsible project manager. Only released projects can be accessible by assigned project team.
			</p>
			<p>Only here projects can be deleted by site administrator. Please note that this action is inversible and all project data is deleted.</p>
		</HelpShell>
	)
}
