import { useCallback } from 'react';
import { CrudRouter } from 'features/Crud';
import { CreateReport } from './Crud/CreateReport';
import { ReadReport } from './Crud/ReadReport';
import ProjectReport from './ProjectReport';
import { UpdateReport } from './Crud/UpdateReport';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { persistReportStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Reports/action';
import { persistBusinessPartnersAction } from 'containers/Configuration/SiteConfiguration/Projects/CreateNewPartner/action';
import { persistRiskStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Risks/action';
import { persistInvoiceStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Invoices/action';
import { persistChangeRequestStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/ChangeRequest/action';

export const ProjectReportRouter = () => {
	const persistFunction = useCallback(
		() => {
			persistReportStatusesAction();
			persistRiskStatusesAction();
			persistInvoiceStatusesAction();
			persistChangeRequestStatusesAction();
			persistBusinessPartnersAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	return (
		<>
			<CrudRouter
				ListComponent={ProjectReport}
				CreateComponent={CreateReport}
				ReadComponent={ReadReport}
				UpdateComponent={UpdateReport}
				paramName='reportId'
			/>
		</>
	)
}
