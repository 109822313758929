import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getRouteObject, RouterProps } from 'features/Routes';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { CrudRouter } from 'features/Crud';
import { ProjectParamName } from 'features/Project';
import { FinanceDashboard } from './Dashboard/FinanceDashboard';
import Invoices from './Invoices/Invoices';
import { CreateInvoice } from './Invoices/Crud/CreateInvoice';
import { ReadInvoice } from './Invoices/Crud/ReadInvoice';
import { UpdateInvoice } from './Invoices/Crud/UpdateInvoice';
import Budget from './Budget/Budget';
import { persistFinanceCategoriesAction } from 'containers/Configuration/SiteConfiguration/Module/Finance/Tabs/Categories/action';
import { persistInvoiceStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Invoices/action';
import { persistBusinessPartnersAction } from 'containers/Configuration/SiteConfiguration/Projects/CreateNewPartner/action';
import { persistExpenseTypesAction } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/ExpenseTypes/action';
import { persistProjectTypesAction } from 'containers/Configuration/SiteConfiguration/Projects/ConfigureProjects/Tabs/ProjectType/action';
import { persistProjectCategoriesAction } from 'containers/Configuration/SiteConfiguration/Projects/ConfigureProjects/Tabs/ProjectCategory/action';
import { persistTravelCostTypesAction } from 'containers/Configuration/SiteConfiguration/Module/TimeAndTravel/Tabs/TravelCostTypes/action';
import { FinanceSubRoutesEnum, budgetSubRoute, invoicesSubRoute, myDashboardSubRoute } from './routes';
import { persistOrganizationalUnitsAction } from 'containers/Configuration/SiteConfiguration/General/Company/OrganizationalUnits/action';

export const FinanceRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistFinanceCategoriesAction();
			persistInvoiceStatusesAction();
			persistBusinessPartnersAction();
			persistExpenseTypesAction();
			persistProjectTypesAction();
			persistProjectCategoriesAction();
			persistTravelCostTypesAction();
			persistOrganizationalUnitsAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[FinanceSubRoutesEnum.MY_DASHBOARD] &&
				<Route
					path={myDashboardSubRoute.url}
					render={() => <FinanceDashboard />}
				/>
			}
			{routesMap[FinanceSubRoutesEnum.INVOICES] &&
				<Route
					path={[
						`${invoicesSubRoute.url}/:${ProjectParamName}`,
						invoicesSubRoute.url,
					]}
					render={() => (
						<CrudRouter
							ListComponent={Invoices}
							CreateComponent={CreateInvoice}
							ReadComponent={ReadInvoice}
							UpdateComponent={UpdateInvoice}
							paramName='invoiceId'
						/>
					)}
				/>
			}

			{routesMap[FinanceSubRoutesEnum.BUDGET] &&
				<Route
					path={[
						`${budgetSubRoute.url}/:${ProjectParamName}`,
						budgetSubRoute.url,
					]}
					render={() => <Budget />}
				/>
			}
			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
