import { HelpShell } from 'features/Help/HelpShell';

export const ChangeRequestStatusesHelp = () => {
	return (
		<HelpShell title='Change request statuses'>
			<div>
				<p>Change Requests can have 8 statuses:</p>
				<ul>
					<li>Created</li>
					<li>Initiated</li>
					<li>Rejected before analysis</li>
					<li>Released for approval</li>
					<li>Rejected for realization</li>
					<li>Approved</li>
					<li>Realized</li>
					<li>Completed</li>
				</ul>
			</div>
			<p>These statuses cannot be changed or deleted, but you can add explanation for each status.</p>
			<p>Users can initially <b>create</b> Change request Report and save it. At this time status report is 
				not released for acceptance. When user finalizes problem report he can release it for acceptance 
				and Change request procedure is <b>initiated</b>.<br />

				Change request initially is checked and could be <b>Rejected</b> during <b>analysis</b> phase or 
				due to costs and effort estimated and <b>released for approval</b>.<br />

				After detail cost and effort estimation, impact analysis, expected completion date, change requests 
				can be <b>approved or rejected for realization</b>.<br />

				If approved for realization, change is executed, tested and test evidence provided. At that moment 
				change request can be <b>released for acceptance</b>.<br />

				After inspecting test evidence, change requests are accepted and <b>completed</b>.</p>
		</HelpShell>
	)
}
