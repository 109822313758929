import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ProjectTimeTravelDashboardFilterRequest, ProjectTimeTravelDashboardResponse, } from 'services/tenantManagementService'
import { Dashboard } from 'features/Dashboard/Dashboard'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { Widget } from 'features/Dashboard/Widget/Widget'
import { RootState } from 'base/reducer/reducer'
import { VacationRequestDashboardTable } from '../../Table/VacationRequest/VacationRequestDashboardTable'
import { Calendar, CalendarViewEnum } from 'components/Calendar'
import { emptyArray, noop } from 'utils/commonHelper'
import { ResourceSourceInput } from '@fullcalendar/resource'
import { EventInput } from '@fullcalendar/core'
import { removeZoneFromDate } from 'utils/dateTimeUtils'
import { getVacationRoute } from 'utils/routeUtils'
import { useStatusColorCallback } from 'features/TableColumns/persistedHooks'

type Props = {
	loading: boolean
	dashboard: ProjectTimeTravelDashboardResponse
	filterModel: ProjectTimeTravelDashboardFilterRequest
}

export const VacationDashboard = ({ loading, dashboard, filterModel }: Props) => {
	const { persistedTimeAndTravelStatus, persistedUser } = useSelector((state: RootState) => state);
	const getVacationStatusColor = useStatusColorCallback(persistedTimeAndTravelStatus);

	const vacationRequestsPerStatusDataMemo = useMemo(
		() => {
			const data = persistedTimeAndTravelStatus.items.map(status => {
				return {
					id: status.id.toString(),
					name: status.name,
					count: (dashboard.vacationRequests?.vacationRequestsPerStatusCounts && dashboard.vacationRequests?.vacationRequestsPerStatusCounts[status.id]) ? dashboard.vacationRequests?.vacationRequestsPerStatusCounts[status.id] : 0,
					color: status.color
				}
			});

			return data;
		},
		[dashboard, persistedTimeAndTravelStatus]
	)

	const resources: ResourceSourceInput = useMemo(
		() => {
			const userIds = [...Array.from(new Set((dashboard.vacationRequests?.calendar || []).map(x => x.userId)))]
			const users: ResourceSourceInput = userIds.map(userId => {
				const user = persistedUser.itemsMap[userId];
				return {
					id: String(userId),
					title: `${user?.firstName} ${user?.lastName}`
				}
			})

			return users;
		},
		[persistedUser, dashboard]
	)

	const events: EventInput[] = useMemo(
		() => {
			return (dashboard.vacationRequests?.calendar || []).map(
				(item) => {
					let event: EventInput = {
						id: item.id.toString(),
						// title: item.description,
						title: '',
						start: removeZoneFromDate(item.from),
						end: removeZoneFromDate(item.to),
						allDay: true,
						url: getVacationRoute(item.id),
						display: 'background',
						backgroundColor: getVacationStatusColor(item.statusId!),
						editable: false,
						resourceId: String(item.userId)
					}

					return event;
				}
			)
		},
		[dashboard, getVacationStatusColor]
	)

	return (
		<Dashboard orientation='horizontal'>
			<PieWidget
				className='col-xl-4 col-md-6'
				title='Vacation requests per Status'
				id='vacation_requests_per_status_pie_chart'
				fieldValue='count'
				fieldCategory='name'
				data={vacationRequestsPerStatusDataMemo}
				loading={loading}
			/>
			<Widget
				title='Vacations'
				loading={loading}
				className='col-xl-8'
			>
				<VacationRequestDashboardTable
					data={dashboard.vacationRequests?.vacationRequests || []}
					filterModel={filterModel}
				/>
			</Widget>
			<Widget
				title='Calendar'
				loading={loading}
				className='col-xl-12'
			>
				<Calendar
					events={events}
					resources={resources}
					setDateRange={noop}
					loading={false}
					initialView={CalendarViewEnum.resourceTimelineMonthly}
					views={emptyArray}
				/>
			</Widget>
		</Dashboard>
	)
}
