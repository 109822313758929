import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'base/reducer/reducer';
import { generateNumberId } from 'base/id';
import { SmartContainer,  SmartItem } from 'components/SmartContainer/SmartContainer';
import { ColumnContainer } from 'components/Layout';
import Button from 'components/Button';
import { TableField, Form, DateField, InputNumberField, CheckboxField, currencyDefaultProps, AutoCompleteField } from 'components/Form';
import { ModuleActivityEnum, PermissionCollectionResponse, ProjectResponse, ProjectTeamMemberResponse, ProjectTeamMemberValidityPeriodResponse, SchedulePermission, ScopePermission, TenantIsActiveResponse, TestingPermission, TicketPermissionWithProjectNo, TimeTravelPermission, TokenTypeEnum, TrainingPermission, UserModel, UserStatusResponse } from 'services/tenantManagementService';
import { getProjectTeamsAction } from '../Teams/action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getProjectRolesAction } from '../Roles/action';
import { persistTeamMembersAction } from './action';
import { useCurrencySuffixMemo } from 'features/Currency/useCurrencySuffixMemo';
import { TeamMemberCustomModel } from './teamMemberModel';
import { getProjectAction, findActiveModule, ProjectPickerParams } from 'features/Project';
import { propertyOf } from 'utils/propertyOf';

type Props = {
	teamMember?: ProjectTeamMemberResponse
	cancel(): void
	save(changeRequest: ProjectTeamMemberResponse, username: string): void
}

const AssignTeamMemberForm = ({ teamMember, save, cancel }: Props) => {
	const {
		persistedUser,
		persistedTeamMember,
		persistedUserStatus,
		persistedCurrency,
		persistedTenant
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(new TeamMemberCustomModel(teamMember));
	const [initialValues, setInitialValues] = useState(new TeamMemberCustomModel(teamMember));
	const [teams, setTeams] = useState<TenantIsActiveResponse[]>([])
	const [roles, setRoles] = useState<TenantIsActiveResponse[]>([])
	const [project, setProject] = useState<ProjectResponse>(new ProjectResponse())

	const params: ProjectPickerParams = useParams();
	const projectId = parseInt(params.projectId as string)

	const [loadingTeamsAndRoles, setLoadingTeamsAndRoles] = useState(true);

	useEffect(
		() => {
			const fetchTenantIsActiveData = async () => {
				setLoadingTeamsAndRoles(true);

				const [teamsResponse, rolesResponse, projectResponse] = await Promise.all(
					[
						tryCatchJsonByAction(getProjectTeamsAction.bind(null, projectId)),
						tryCatchJsonByAction(getProjectRolesAction.bind(null, projectId)),
						tryCatchJsonByAction(getProjectAction.bind(null, projectId))
					]
				)

				if (teamsResponse.success) {
					setTeams(teamsResponse.items?.filter(item => item.isActive) || []);
				}
				if (rolesResponse.success) {
					setRoles(rolesResponse.items?.filter(item => item.isActive) || []);
				}

				if (projectResponse.success) {
					setProject(projectResponse.value!);
				}

				setLoadingTeamsAndRoles(false);
			}

			fetchTenantIsActiveData();
		},
		[projectId]
	)


	useEffect(
		() => {
			if (!persistedTeamMember.projectMap[projectId]) {
				persistTeamMembersAction(projectId);
			}
		},
		[persistedTeamMember, projectId]
	)

	useEffect(
		() => {
			if (persistedUser.itemsMap[values.userId]?.isGuest) {
				setValues((state: TeamMemberCustomModel) => {
					const newState = new TeamMemberCustomModel(state);
					newState.isSubstitutePm = false;
					return newState;
				});

				setInitialValues((state: TeamMemberCustomModel) => {
					const newState = new TeamMemberCustomModel(state);
					newState.isSubstitutePm = false;
					return newState;
				});
			}
		},
		[values.userId, persistedUser.itemsMap]
	)

	const ticketModuleMemo = useMemo(
		() => findActiveModule(ModuleActivityEnum.Ticket, project.activeModules),
		[project]
	)
	const timeModuleMemo = useMemo(
		() => findActiveModule(ModuleActivityEnum.Time, project.activeModules),
		[project]
	)
	const scopeModuleMemo = useMemo(
		() => findActiveModule(ModuleActivityEnum.Scope, project.activeModules),
		[project]
	)
	const testingModuleMemo = useMemo(
		() => findActiveModule(ModuleActivityEnum.Testing, project.activeModules),
		[project]
	)
	const trainingModuleMemo = useMemo(
		() => findActiveModule(ModuleActivityEnum.Training, project.activeModules),
		[project]
	)
	const scheduleModuleMemo = useMemo(
		() => findActiveModule(ModuleActivityEnum.Schedule, project.activeModules),
		[project]
	)

	const isUserPmOrSubstitutePmMemo = useMemo(
		() => values.isSubstitutePm || values.roleId === TokenTypeEnum.ProjectManager,
		[values.isSubstitutePm, values.roleId]
	)

	useEffect(
		() => {
			if (isUserPmOrSubstitutePmMemo) {
				setValues((state: TeamMemberCustomModel) => new TeamMemberCustomModel({
					...state,
					ticketCheck: true,
					timeTravelCheck: true,
					scopeCheck: true,
					scopeCheckImportScope: true,
					scopeCheckViewAllChangeRequests: true,
					scopeCheckMaintainScope: true,
					testingCheck: true,
					testingCheckCreateTestCycle: true,
					testingCheckMaintainTestPlan: true,
					trainingCheck: true,
					trainingCheckCreateSurveys: true,
					trainingCheckCreateTrainingCycle: true,
					trainingCheckMaintainTrainingPlan: true,
					scheduleCheck: true,
					scheduleCheckMaintainSchedule: true
				}));

				setInitialValues((state: TeamMemberCustomModel) => new TeamMemberCustomModel({
					...state,
					ticketCheck: true,
					timeTravelCheck: true,
					scopeCheck: true,
					scopeCheckImportScope: true,
					scopeCheckViewAllChangeRequests: true,
					scopeCheckMaintainScope: true,
					testingCheck: true,
					testingCheckCreateTestCycle: true,
					testingCheckMaintainTestPlan: true,
					trainingCheck: true,
					trainingCheckCreateSurveys: true,
					trainingCheckCreateTrainingCycle: true,
					trainingCheckMaintainTrainingPlan: true,
					scheduleCheck: true,
					scheduleCheckMaintainSchedule: true
				}));
			}
		},
		[isUserPmOrSubstitutePmMemo]
	)

	const filteredUsers = useMemo(
		() => {
			if (!!teamMember) {
				return [persistedUser.itemsMap[teamMember.userId]];
			}
			return persistedUser.items.filter(user => !persistedTeamMember.projectMap[projectId]?.items.find(teamMember => teamMember.userId === user.id))
		},
		[persistedUser.items, persistedUser.itemsMap, persistedTeamMember, teamMember, projectId]
	)

	const username = useMemo(
		() => {
			if (values.userId) {
				const user = persistedUser.itemsMap[values.userId];
				return user && `${user.firstName} ${user.lastName}`;
			}
		},
		[values.userId, persistedUser]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const submitValues = new ProjectTeamMemberResponse({
				...values,
				permissions: new PermissionCollectionResponse({
					ticketPermission: new TicketPermissionWithProjectNo({
						dashboard: values.ticketCheck,
						tickets: values.ticketCheck,
						numOfProjectTicketIsActive: teamMember?.permissions?.ticketPermission?.numOfProjectTicketIsActive || 0
					}),
					scopePermission: new ScopePermission({
						dashboard: values.scopeCheck,
						importScope: values.scopeCheckImportScope,
						maintainScope: values.scopeCheckMaintainScope,
						viewAllChangeRequests: values.scopeCheckViewAllChangeRequests,
						viewScope: values.scopeCheck,
						initiateChangeRequest: values.scopeCheck
					}),
					testingPermission: new TestingPermission({
						dashboard: values.testingCheck,
						myTestPackages: values.testingCheck,
						createTestCycle: values.testingCheckCreateTestCycle,
						viewTestPlan: values.testingCheck,
						maintainTestPlan: values.testingCheckMaintainTestPlan,
						testStatus: values.testingCheck
					}),
					trainingPermission: new TrainingPermission({
						dashboard: values.trainingCheck,
						myTrainingPlan: values.trainingCheck,
						mySurveys: values.trainingCheck,
						createTrainingCycle: values.trainingCheckCreateTrainingCycle,
						viewTrainingPlan: values.trainingCheck,
						maintainTrainingPlan: values.trainingCheckMaintainTrainingPlan,
						createSurveys: values.trainingCheckCreateSurveys,
						reporting: false
					}),
					timeTravelPermission: new TimeTravelPermission({
						dashboard: values.timeTravelCheck,
						timesheets: values.timeTravelCheck,
						travelRequests: values.timeTravelCheck,
						travelExpenses: values.timeTravelCheck,
						vacationRequests: values.timeTravelCheck,
						approver: false,
					}),
					schedulePermission: new SchedulePermission({
						viewSchedule: values.scheduleCheck,
						maintainSchedule: values.scheduleCheckMaintainSchedule
					})
				})
			});
			await save(submitValues, username as string)
		},
		[save, values, username, teamMember]
	)

	const addValidityPeriodCallback = useCallback(
		() => {
			const id = generateNumberId();

			setValues((state: any) => {
				const newModel = new ProjectTeamMemberValidityPeriodResponse();
				newModel.id = id;

				const newState = { ...state };
				if (newState.validityPeriods) {
					newState.validityPeriods = [...newState.validityPeriods, newModel];
				} else {
					newState.validityPeriods = [newModel];
				}

				return newState;
			})
		},
		[]
	)

	const isCheckboxEnabled = useMemo(
		() => values.roleId === TokenTypeEnum.ProjectManager || persistedUser.itemsMap[values.userId]?.isGuest,
		[values.roleId, persistedUser.itemsMap, values.userId]
	)

	const currencySymbolSuffix = useCurrencySuffixMemo(persistedCurrency, persistedTenant);

	const validityPeriodsContent = useMemo(
		() => (
			<>
				<h5>Maintain user rates</h5>
				<ColumnContainer margin='medium'>
					<Button
						text='Add'
						onClick={addValidityPeriodCallback}
					/>
					<TableField
						id='validityPeriods'
						headers={[
							{ label: 'From', size: 2 },
							{ label: 'To', size: 2 },
							{ label: 'Cost rate (per hour)', size: 2 },
							{ label: 'Billing rate (per hour)', size: 2 }
						]}
						getRowData={() => {
							return {
								isDeletable: true,
								fields: [
									<DateField
										id='from'
										isRequired
									/>,
									<DateField
										id='to'
										isRequired
									/>,
									<InputNumberField
										id='costRate'
										suffix={currencySymbolSuffix}
										{...currencyDefaultProps}
										isRequired
									/>,
									<InputNumberField
										id='salesRate'
										suffix={currencySymbolSuffix}
										{...currencyDefaultProps}
										isRequired
									/>
								]
							}
						}}
					/>
				</ColumnContainer>
			</>
		),
		[addValidityPeriodCallback, currencySymbolSuffix]
	)

	return (
		<Form
			values={values}
			initialValues={initialValues}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem size='small'>
							<AutoCompleteField
								id={propertyOf<TeamMemberCustomModel>('userId')}
								label='User Name'
								isRequired
								disabled={!!teamMember}
								items={filteredUsers}
								getItemId={(item: UserModel) => item.id}
								getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
								sort
							/>
							<AutoCompleteField
								id={propertyOf<TeamMemberCustomModel>('projectTeamId')}
								label='Project Team'
								isRequired
								items={teams}
								getItemId={(item: TenantIsActiveResponse) => item.id}
								getItemText={(item: TenantIsActiveResponse) => item.name}
								getItemDescription={(item: TenantIsActiveResponse) => item.description}
								loading={loadingTeamsAndRoles}
							/>
							<AutoCompleteField
								id={propertyOf<TeamMemberCustomModel>('projectRoleId')}
								label='Project Role'
								isRequired
								items={roles}
								getItemId={(item: TenantIsActiveResponse) => item.id}
								getItemText={(item: TenantIsActiveResponse) => item.name}
								getItemDescription={(item: TenantIsActiveResponse) => item.description}
								loading={loadingTeamsAndRoles}
							/>
							<AutoCompleteField
								id={propertyOf<TeamMemberCustomModel>('statusId')}
								label='User Status'
								isRequired
								items={persistedUserStatus.items}
								getItemId={(item: UserStatusResponse) => item.id}
								getItemText={(item: UserStatusResponse) => item.name}
								getItemDescription={(item: UserStatusResponse) => item.description}
								loading={persistedUserStatus.fetching}
							/>
							<DateField
								id={propertyOf<TeamMemberCustomModel>('validUntil')}
								label='User valid until'
								isRequired
							/>
							<CheckboxField
								id={propertyOf<TeamMemberCustomModel>('isSubstitutePm')}
								label='Substitute project manager'
								disabled={isCheckboxEnabled}
							/>
						</SmartItem>
						<SmartItem size='xlarge'>
							<h5>Active in following modules</h5>
							<ColumnContainer margin='small'>
								{ticketModuleMemo?.isActive && (
									<ColumnContainer margin='disabled'>
										<CheckboxField
											id={propertyOf<TeamMemberCustomModel>('ticketCheck')}
											labelBefore='Tickets'
											labelBeforeAdditional='(Dashboard and Tickets)'
											disabled={isUserPmOrSubstitutePmMemo}
										/>
									</ColumnContainer>
								)}
								{timeModuleMemo?.isActive && (
									<ColumnContainer margin='small'>
										<CheckboxField
											id={propertyOf<TeamMemberCustomModel>('timeTravelCheck')}
											labelBefore='Time & Travel'
											labelBeforeAdditional='(Dashboard, Timesheets, Travel requests, Travel expenses, Vacation requests)'
											disabled={isUserPmOrSubstitutePmMemo}
										/>

									</ColumnContainer>
								)}
								{scopeModuleMemo?.isActive && (
									<ColumnContainer margin='disabled'>
										<CheckboxField
											id={propertyOf<TeamMemberCustomModel>('scopeCheck')}
											labelBefore='Scope'
											labelBeforeAdditional='(Dashboard, View Scope, Initiate Change Request)'
											disabled={isUserPmOrSubstitutePmMemo}
										/>
										<div style={{paddingLeft: '30px'}}>
											<ColumnContainer margin='disabled'>
												<CheckboxField
													id={propertyOf<TeamMemberCustomModel>('scopeCheckMaintainScope')}
													labelBefore='Maintain scope'
													disabled={isUserPmOrSubstitutePmMemo}
												/>
												<CheckboxField
													id={propertyOf<TeamMemberCustomModel>('scopeCheckImportScope')}
													labelBefore='Import scope'
													disabled={isUserPmOrSubstitutePmMemo}
												/>
												<CheckboxField
													id={propertyOf<TeamMemberCustomModel>('scopeCheckViewAllChangeRequests')}
													labelBefore='View all change requests'
													disabled={isUserPmOrSubstitutePmMemo}
												/>
											</ColumnContainer>
										</div>

									</ColumnContainer>
								)}
								{testingModuleMemo?.isActive && (
									<ColumnContainer margin='disabled'>
										<CheckboxField
											id={propertyOf<TeamMemberCustomModel>('testingCheck')}
											labelBefore='Testing'
											labelBeforeAdditional='(Dashboard, My test packages, View Test Plan, Test status)'
											disabled={isUserPmOrSubstitutePmMemo}
										/>
										<div style={{paddingLeft: '30px'}}>
											<ColumnContainer margin='disabled'>
												<CheckboxField
													id={propertyOf<TeamMemberCustomModel>('testingCheckCreateTestCycle')}
													labelBefore='Create test cycle'
													disabled={isUserPmOrSubstitutePmMemo}
												/>
												<CheckboxField
													id={propertyOf<TeamMemberCustomModel>('testingCheckMaintainTestPlan')}
													labelBefore='Maintain test plan'
													disabled={isUserPmOrSubstitutePmMemo}
												/>
											</ColumnContainer>
										</div>
									</ColumnContainer>
								)}
								{trainingModuleMemo?.isActive && (
									<ColumnContainer margin='disabled'>
										<CheckboxField
											id={propertyOf<TeamMemberCustomModel>('trainingCheck')}
											labelBefore='Training'
											labelBeforeAdditional='(Dashboard, My training plan, My surveys, View Training Plan)'
											disabled={isUserPmOrSubstitutePmMemo}
										/>
										<div style={{paddingLeft: '30px'}}>
											<ColumnContainer margin='disabled'>
												<CheckboxField
													id={propertyOf<TeamMemberCustomModel>('trainingCheckCreateTrainingCycle')}
													labelBefore='Create training cycle'
													disabled={isUserPmOrSubstitutePmMemo}
												/>
												<CheckboxField
													id={propertyOf<TeamMemberCustomModel>('trainingCheckMaintainTrainingPlan')}
													labelBefore='Maintain training plan'
													disabled={isUserPmOrSubstitutePmMemo}
												/>
												<CheckboxField
													id={propertyOf<TeamMemberCustomModel>('trainingCheckCreateSurveys')}
													labelBefore='Survey/Quiz'
													disabled={isUserPmOrSubstitutePmMemo}
												/>
											</ColumnContainer>
										</div>
									</ColumnContainer>
								)}
								{scheduleModuleMemo?.isActive && (
									<ColumnContainer margin='disabled'>
										<CheckboxField
											id={propertyOf<TeamMemberCustomModel>('scheduleCheck')}
											labelBefore='Schedule'
											labelBeforeAdditional='(View schedule)'
											disabled={isUserPmOrSubstitutePmMemo}
										/>
										<div style={{paddingLeft: '30px'}}>
											<ColumnContainer margin='disabled'>
												<CheckboxField
													id={propertyOf<TeamMemberCustomModel>('scheduleCheckMaintainSchedule')}
													labelBefore='Maintain schedule'
													disabled={isUserPmOrSubstitutePmMemo}
												/>
											</ColumnContainer>
										</div>
									</ColumnContainer>
								)}
							</ColumnContainer>
						</SmartItem>
					</SmartContainer>
					{validityPeriodsContent}
				</ColumnContainer>
			)}
		/>
	)
}

export default AssignTeamMemberForm;
