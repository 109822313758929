import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import { RotatedColumnSeriesChart } from 'features/Charts';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer, RowContainer } from 'components/Layout';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { Radio } from 'components/Form';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import styles from './timesheetsDashboard.module.scss';
import { sortByString } from 'utils/stringUtil';

type Props = {
	hoursPerUserAndProject: { [key: string]: { [key: string]: number } }
	hourCostsPerUserAndProject: { [key: string]: { [key: string]: number } }
}

export const ProjectHoursCostPerUserAndProjectChart = ({
	hoursPerUserAndProject,
	hourCostsPerUserAndProject,
}: Props) => {
	const [showHours, setShowHours] = useState<boolean>(true);

	const { persistedUser, persistedProject} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);

	const columnSeriesHoursPerUserAndProjectDataMemo = useMemo(
		() => {
			const data: any[] = [];

			for (const userId in hoursPerUserAndProject) {
				const object = hoursPerUserAndProject[userId];
				data.push({
					username: getUserFullName(parseInt(userId)),
					...object
				});
			}

			return sortByString(data, 'username');;
		},
		[hoursPerUserAndProject, getUserFullName]
	)

	const columnSeriesHoursPerUserAndProjectFieldsMemo = useMemo(
		() => {
			//get all distinct projectIds
			const projectIds = Object.keys(hoursPerUserAndProject || {}).map(userId => {
				return Object.keys(hoursPerUserAndProject![parseInt(userId)])
			}).flat().filter((value, index, self) => self.indexOf(value) === index);

			return projectIds.map(projectId => {
				return {
					value: projectId,
					name: `${getFormatedId(EntityPrefixEnum.PROJECT, parseInt(projectId))} - ${persistedProject.itemsMap[projectId]?.name}`
				}
			})
		},
		[hoursPerUserAndProject, persistedProject]
	)

	const columnSeriesHourCostsPerUserAndProjectDataMemo = useMemo(
		() => {
			const data: any[] = [];

			for (const userId in hourCostsPerUserAndProject) {
				const object = hourCostsPerUserAndProject[userId];
				data.push({
					username: getUserFullName(parseInt(userId)),
					...object
				});
			}

			return data;
		},
		[hourCostsPerUserAndProject, getUserFullName]
	)

	const columnSeriesHourCostsPerUserAndProjectFieldsMemo = useMemo(
		() => {
			//get all distinct projectIds
			const projectIds = Object.keys(hourCostsPerUserAndProject || {}).map(userId => {
				return Object.keys(hourCostsPerUserAndProject![parseInt(userId)])
			}).flat().filter((value, index, self) => self.indexOf(value) === index);

			return projectIds.map(projectId => {
				return {
					value: projectId,
					name: `${getFormatedId(EntityPrefixEnum.PROJECT, parseInt(projectId))} - ${persistedProject.itemsMap[projectId]?.name}`
				}
			})
		},
		[hourCostsPerUserAndProject, persistedProject]
	)

	return (
		<ColumnContainer margin='small'>
			<RowContainer>
				<SmartContainer>
					<SmartItem>
						<SmartFormGroup>
							<Radio
								value={showHours}
								items={[
									{ id: true, text: 'Hours' },
									{ id: false, text: 'Costs' },
								]}
								onChange={() => {setShowHours((state: boolean) => !state)}}
							/>
						</SmartFormGroup>
					</SmartItem>
				</SmartContainer>
			</RowContainer>
			<div className={styles.projectHoursPerUser}>
				<RotatedColumnSeriesChart
					id='approved_project_hours_costs_per_user_and_project_series_chart'
					data={showHours ? columnSeriesHoursPerUserAndProjectDataMemo : columnSeriesHourCostsPerUserAndProjectDataMemo}
					fieldCategory='username'
					stacked
					fieldValues={showHours ? columnSeriesHoursPerUserAndProjectFieldsMemo : columnSeriesHourCostsPerUserAndProjectFieldsMemo}
					showValueOnBar
				/>
			</div>
		</ColumnContainer>
	)
}
