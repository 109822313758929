import { useMemo } from 'react';
import { SurveyResponse, SurveyStatusEnum, SurveyTypeEnum } from 'services/tenantManagementService';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';

const defaultColumns = [
	propertyOf<SurveyResponse>('id'),
	propertyOf<SurveyResponse>('name'),
	propertyOf<SurveyResponse>('surveyType'),
	propertyOf<SurveyResponse>('status')
]

export const useTableColumnsMemo = () => {
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<SurveyResponse>('id')]: {
					title: 'Survey ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.SURVEY
				},
				[propertyOf<SurveyResponse>('name')]: {
					title: 'Survey Name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<SurveyResponse>('surveyType')]: {
					title: 'Survey Type',
					fieldType: FieldTypeEnum.Options,
					options: Object.values(SurveyTypeEnum),
					getItemId: (option: SurveyTypeEnum) => option,
					getItemText: (option: SurveyTypeEnum) => option
				},
				[propertyOf<SurveyResponse>('status')]: {
					title: 'Status',
					fieldType: FieldTypeEnum.Options,
					options: Object.values(SurveyStatusEnum),
					getItemId: (option: SurveyStatusEnum) => option,
					getItemText: (option: SurveyStatusEnum) => option
				}
			}
		},
		[]
	)

	return useVisibleColumns(columns, defaultColumns);
}
