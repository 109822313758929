import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import { InnerTestPlanResponse, TestPlanResponse, TestStatusResponse, UserModel } from 'services/tenantManagementService';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';

type Props = {
	persistedUser: PersistItemsReducer<UserModel>
	persistedTestCaseStatus: PersistItemsReducer<TestStatusResponse>
	testPlan: TestPlanResponse
}

const sanitizeValue = (value: string | null | undefined) => value || '-'

const MyTestTable = ({ persistedUser, persistedTestCaseStatus, testPlan }: Props) => {
	if (!testPlan.innerTestPlans || testPlan.innerTestPlans.length === 0) {
		return null;
	}

	return (
		<View style={styles.container}>
			<Table data={testPlan.innerTestPlans}>
				<TableHeader>
					<TableCell style={styles.text}>Level 4 ID</TableCell>
					<TableCell style={styles.text}>Process Step/Transaction</TableCell>
					<TableCell style={styles.text}>Expected result</TableCell>
					<TableCell style={styles.text}>Document created</TableCell>
					<TableCell style={styles.text}>Comment</TableCell>
					<TableCell style={styles.text}>Step status</TableCell>
					<TableCell style={styles.text}>Rejection comment</TableCell>
					<TableCell style={styles.text}>Step tester</TableCell>
				</TableHeader>
				<TableBody>
					<DataTableCell style={styles.text} getContent={(tp: InnerTestPlanResponse) => sanitizeValue(tp.processStepId)}/>
					<DataTableCell style={styles.text} getContent={(tp: InnerTestPlanResponse) => sanitizeValue(tp.processStepName)}/>
					<DataTableCell style={styles.text} getContent={(tp: InnerTestPlanResponse) => sanitizeValue(tp.stepExpectedResults)}/>
					<DataTableCell style={styles.text} getContent={(tp: InnerTestPlanResponse) => sanitizeValue(tp.stepDocumentsCreated)}/>
					<DataTableCell style={styles.text} getContent={(tp: InnerTestPlanResponse) => sanitizeValue(tp.stepComment)}/>
					<DataTableCell style={styles.text}
						getContent={(tp: InnerTestPlanResponse) => {
							const status = persistedTestCaseStatus.itemsMap[tp.stepStatusId]
							return (
								<Text style={[styles.text, { backgroundColor: status?.color || '#fff' }]}>
									{sanitizeValue(status?.name)}
								</Text>
							)
						}}
					/>
					<DataTableCell style={styles.text} getContent={(tp: InnerTestPlanResponse) => sanitizeValue(tp.stepRejectionComment)}/>
					<DataTableCell style={styles.text}
						getContent={(tp: InnerTestPlanResponse) => {
							const stepTester = tp.stepTesterUserId ? persistedUser.itemsMap[tp.stepTesterUserId] : undefined
							return sanitizeValue(stepTester ? `${stepTester?.firstName} ${stepTester?.lastName}` : '')
						}}
					/>
				</TableBody>
			</Table>
		</View>
	);
};

export default MyTestTable;

const styles = StyleSheet.create({
	container: {
		paddingTop: 10,
	},
	text: {
		flexWrap: 'wrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: 2,
		fontSize: 10,
	}
})
