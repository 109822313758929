import { getTenantId } from 'features/Tenant/tenantUtils';
import { TestPlanClient, UpdateTestPlanRequest } from 'services/tenantManagementService';

export const getTestPlanAction = (projectId: number, testCycleId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.get(tenantId, projectId, testCycleId, id);
}

export const updateTestPlanAction = (projectId: number, testCycleId: number, updateTestPlanRequest: UpdateTestPlanRequest) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.update(tenantId, projectId, testCycleId, updateTestPlanRequest);
}

export const getHistoryAction = (projectId: number, testCycleId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.getHistoryFromTo(tenantId, projectId, testCycleId, id);
}

export const downloadAttachmentAction = (projectId: number, testCycleId: number, testPlanId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.downloadAttachment(tenantId, projectId, testCycleId, testPlanId, id);
}

export const removeAttachmentAction = (projectId: number, testCycleId: number, testPlanId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new TestPlanClient();
	return client.removeAttachment(tenantId, projectId, testCycleId, testPlanId, id);
}
