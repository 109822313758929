import { ColumnContainer } from 'components/Layout';

export const ProjectStatusLegend = () => {
	return (
		<>
			<div style={{ marginBottom: '30px', fontWeight: 'bold' }}>
				Legend:
			</div>
			<ColumnContainer margin='medium'>
				<div>
					Green (G): No open issues. The risks to the project from this area are
					minimal.
				</div>
				<div>
					Yellow (Y): There are open problems or issues either awaiting resolution or
					under corrective action. The risks to the project from this area are
					moderate.
				</div>
				<div>
					Red (R): There are significant problems or issues either awaiting resolution
					or under corrective action that will jeopardize the overall success of the
					project. Serious corrective action must be taken immediately
				</div>
			</ColumnContainer>
		</>
	)
}
