import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { ColumnContainer, VerticalSeparator } from 'components/Layout';
import { TenantIsActiveResponse, ProjectResponse, ProjectStatusResponse, UpdateProjectDataRequest, UserModel } from 'services/tenantManagementService';
import { RootState } from 'base/reducer/reducer';
import { Form, InputField, TextareaField, AttachmentField, MapSingleField, AutoCompleteField } from 'components/Form';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { getNextStatuses } from 'features/StatusResponse/statusResponse';
import { ProjectActiveModulesField } from 'features/Project';
import { propertyOf } from 'utils/propertyOf';
import { downloadAttachmentAction, removeAttachmentAction } from 'containers/Projects/MyProjects/action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';

type Props = {
	project: ProjectResponse
	onSave: (project: UpdateProjectDataRequest) => void
}

const ProjectDataForm = ({ project = new ProjectResponse(), onSave }: Props) => {
	const {
		persistedProjectStatus,
		persistedProjectCategory,
		persistedProjectType,
		persistedUser,
		persistedOrganizationalUnit
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(project);

	const onSubmitCallback = useCallback(
		async () => {
			const newProject = new UpdateProjectDataRequest({
				projectId: values.id,
				name: values.name,
				projectStatusId: values.statusId!,
				projectCategoryId: values.categoryId,
				projectTypeId: values.typeId,
				description: values.description,
				activeModules: values.activeModules,
				organizationalUnitId: values.organizationalUnitId,
				newAttachments: (values as never as UpdateProjectDataRequest).newAttachments,
			});
			return await onSave(newProject);
		},
		[values, onSave]
	)

	const nextStatuses = useMemo(
		() => getNextStatuses(project.statusId, persistedProjectStatus.items),
		[project.statusId, persistedProjectStatus]
	)

	useEffect(
		() => {
			setValues(project);
		},
		[project]
	)

	const cancelCallback = useCallback(
		() => setValues(project),
		[project]
	)

	const downloadAttachmentMemo = useMemo(
		() => downloadAttachmentAction.bind(null, values.id),
		[values.id]
	)

	const removeAttachmentCallback = useCallback(
		async (id: number) => {
			const bindedAction = removeAttachmentAction.bind(null, values.id, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Attachment is successfully deleted');
				setValues((state: ProjectResponse) =>
					new ProjectResponse({
						...state,
						attachments: state.attachments?.filter(att => att.id !== id)
					})
				)
			}
		},
		[values.id]
	)

	return (
		<Form
			values={values}
			initialValues={project}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancelCallback}
			render={() => (
				<SmartContainer justifyContent='space-between'>
					<SmartItem>
						<AutoCompleteField
							id={propertyOf<ProjectResponse>('typeId')}
							label='Project Type'
							items={persistedProjectType.activeItems}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							getItemDescription={(item: TenantIsActiveResponse) => item.description}
							loading={persistedProjectType.fetching}
						/>
						<AutoCompleteField
							id={propertyOf<ProjectResponse>('categoryId')}
							label='Project Category'
							items={persistedProjectCategory.activeItems}
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							getItemDescription={(item: TenantIsActiveResponse) => item.description}
							loading={persistedProjectCategory.fetching}
						/>
						<MapSingleField
							id={propertyOf<ProjectResponse>('organizationalUnitId')}
							label='Organizational Unit'
							items={convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined)}
							loading={persistedOrganizationalUnit.fetching}
							description='When you assign project to organizational unit you will allow managers of unit and above to see dashboard reports related to project.'
							isRequired
						/>
						<AutoCompleteField
							id={propertyOf<ProjectResponse>('id')}
							label='Project ID'
							items={[values.id]}
							getItemId={(item: number) => item}
							getItemText={(item: number) => getFormatedId(EntityPrefixEnum.PROJECT, item)}
							disabled
						/>
						<InputField
							id={propertyOf<ProjectResponse>('name')}
							label='Project Name'
							isRequired
						/>
						<TextareaField
							id={propertyOf<ProjectResponse>('description')}
							label='Project Description'
							maxLength={120}
						/>
						<AutoCompleteField
							id={propertyOf<ProjectResponse>('pmId')}
							label='Project Manager'
							isRequired
							items={persistedUser.items}
							getItemId={(item: UserModel) => item.id}
							getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
							loading={persistedUser.fetching}
							disabled
						/>
						<AutoCompleteField
							id={propertyOf<ProjectResponse>('statusId')}
							label='Project Status'
							isRequired
							items={nextStatuses}
							getItemId={(item: ProjectStatusResponse) => item.id}
							getItemText={(item: ProjectStatusResponse) => item.name}
							getItemDescription={(item: ProjectStatusResponse) => item.description}
							loading={persistedProjectStatus.fetching}
						/>
						<VerticalSeparator />
						<AttachmentField
							id={propertyOf<UpdateProjectDataRequest>('newAttachments')}
							label='Attachments'
							multiple
							oldAttachments={values.attachments}
							removeOldAttachment={removeAttachmentCallback}
							downloadOldAttachment={downloadAttachmentMemo}
						/>
					</SmartItem>
					<SmartItem size='xsmall'>
						<ColumnContainer margin='medium'>
							<ProjectActiveModulesField
								id='activeModules'
							/>
						</ColumnContainer>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	)
}

export default ProjectDataForm;
