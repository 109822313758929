import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Input } from 'components/Form';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { MyTimeTravelDashboardFilterRequest } from 'services/tenantManagementService';
import Button from 'components/Button';
import { getUserInfo } from 'utils/storageUtils';
import { RangeMonthDateType, RangeMonthPicker } from 'components/Form/controls/DatePicker/RangeMonthPicker';

type Props = {
	filterFormModel?: MyTimeTravelDashboardFilterRequest
	onSubmit: (request: MyTimeTravelDashboardFilterRequest) => Promise<void>
	onSave: (filterFormModel: MyTimeTravelDashboardFilterRequest) => Promise<void>
}

export const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const [values, setValues] = useState(new MyTimeTravelDashboardFilterRequest(filterFormModel));

	useEffect(
		() => {
			setValues(new MyTimeTravelDashboardFilterRequest(filterFormModel));
		},
		[filterFormModel]
	)

	const userInfoMemo = useMemo(
		() => {
			return getUserInfo();
		},
		[]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new MyTimeTravelDashboardFilterRequest(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			const model = new MyTimeTravelDashboardFilterRequest(values);
			await onSave(model)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<SmartFormGroup label='Period' isRequired>
							<RangeMonthPicker
								value={{startDate: values.from, endDate: values.to}}
								onChange={(newPeriod: RangeMonthDateType) => {
									setValues(new MyTimeTravelDashboardFilterRequest({
										...values,
										from: newPeriod.startDate!,
										to: newPeriod.endDate!
									}))
								}}
							/>
						</SmartFormGroup>
						<SmartFormGroup label='User'>
							<Input
								value={`${userInfoMemo.firstName} ${userInfoMemo.lastName}`}
								disabled
							/>
						</SmartFormGroup>

					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
				/>
			)}
		/>
	)
}
