import { useCallback, useMemo, useState } from 'react';
import { GenericFilterModelCollection } from 'services/tenantManagementService';
import { TableButtons } from './TableButtons';
import { ColumnContainer } from 'components/Layout';
import { useTableColumnsMemo } from './tableColumns';
import { exportAction, getSurveyResultsAction } from './action'
import { UpdateSurveyTabsProps } from '../UpdateSurveyTabs';
import { RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';

const configureViewKey='survey_view_results';

export const ViewResults = ({ project, survey }: UpdateSurveyTabsProps) => {
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const tableColumns = useTableColumnsMemo(configureViewKey);

	const memoFetchFunction = useMemo(
		() => getSurveyResultsAction.bind(null, project.id, survey.id),
		[project.id, survey.id]
	)

	const memoExportFunction = useMemo(
		() => exportAction.bind(null, project.id, survey.id),
		[project.id, survey.id]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<ColumnContainer margin='medium'>
			<TableButtons
				configureViewKey={configureViewKey}
				tableColumns={tableColumns}
				filtersModel={filtersModel}
				exportFunction={memoExportFunction}
			/>
			<RemoteTable
				columns={tableColumns}
				filtersModel={filtersModel}
				filtersModelChanged={setFiltersModel}
				fetchFunction={memoFetchFunction}
				reorderColumns={reorderColumnsCallback}
			/>
		</ColumnContainer>
	)
}
