import { CellComponent } from "tabulator-tables";

export const customTextAreaFormatter = (cell: CellComponent) => {
	const value = cell.getValue();
	if (value) {
		const dummyElement = document.createElement('div');
		dummyElement.innerHTML = value;
		return dummyElement.innerText;
	}

	return ''
}
