import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { InsertRiskRequest, RiskResponse } from 'services/tenantManagementService';
import { createRiskAction } from '../action';
import { RiskForm } from './RiskForm';
import { ProjectPickerParams } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';

export const CreateRisk = ({ publishDataChanged }: CreateComponentProps) => {
	const params: ProjectPickerParams = useParams();
	const projectId = parseInt(params.projectId as string);
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newRisk: RiskResponse) => {
			const model = new InsertRiskRequest(newRisk);
			const bindedAction = createRiskAction.bind(null, projectId, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.RISK, response.value?.id);
				notifications.success(`New risk ${id} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged, projectId]
	);

	return (
		<ContentShell title='Create risk'>
			<RiskForm
				projectId={projectId}
				crud={CrudEnum.Create}
				onSave={handleSave}
				cancel={goBackFromCreate}
			/>
		</ContentShell>
	)
}
