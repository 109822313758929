import { useCallback } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { HOME_ROUTE, ONBOARDING_ROUTE } from 'base/constants/routes';
import { getIsTenantInitialized } from 'features/Tenant/tenantUtils';
import { getToken } from 'features/Token/token';
import ConfirmRegistration from './ConfirmRegistration/ConfirmRegistration';
import FinishRegistration from './FinishRegistration/FinishRegistration';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ChangePassword from './ChangePassword/ChangePassword';
import Login from './Login/Login';
import Registration from './Registration/Registration';
import { RedirectToLoginState } from '../PageRouter'

export const SUB_ROUTES = {
	REGISTRATION: 'registration',
	CONFIRM_REGISTRATION: 'confirm_registration',
	FINISH_REGISTRATION: 'finish_registration',
	FORGOT_PASSWORD: 'forgot_password',
	CHANGE_PASSWORD: 'change_password',
	LOGIN: ''
}

const OnboardingRouter = () => {
	let { path } = useRouteMatch();
	const location = useLocation();

	const isLoggedIn = useCallback(
		() => !!getToken(),
		[]
	)

	const isRegistrationFinished = useCallback(
		() => !!getIsTenantInitialized(),
		[]
	)

	const shouldSwitchToHomeRoute = useCallback(
		() => isLoggedIn() && isRegistrationFinished(),
		[isLoggedIn, isRegistrationFinished]
	)

	const shouldSwitchToFinishRegistrationRoute = useCallback(
		() => isLoggedIn() && !isRegistrationFinished(),
		[isLoggedIn, isRegistrationFinished]
	)

	const renderFinishRegistrationRoute = useCallback(
		() => {
			if (!isLoggedIn()) {
				return <Redirect to={ONBOARDING_ROUTE} />
			}
			if (shouldSwitchToHomeRoute()) {
				return <Redirect to={HOME_ROUTE} />
			}

			return <FinishRegistration />
		},
		[isLoggedIn, shouldSwitchToHomeRoute]
	)

	const renderLoginRoute = useCallback(
		() => {
			if (shouldSwitchToHomeRoute()) {
				return <Redirect to={HOME_ROUTE} />
			}
			if (shouldSwitchToFinishRegistrationRoute()) {
				return <Redirect to={`${path}/${SUB_ROUTES.FINISH_REGISTRATION}`} />
			}

			return <Login state={location.state as RedirectToLoginState}/>
		},
		[path, shouldSwitchToHomeRoute, shouldSwitchToFinishRegistrationRoute, location.state]
	)

	if (process.env.REACT_APP_TYPE === 'registration') {
		// registration routes
		return (
			<Switch>
				<Route path={`${path}/${SUB_ROUTES.CONFIRM_REGISTRATION}`} component={ConfirmRegistration} exact />
				<Route component={Registration} />
			</Switch>
		)
	} else {
		// subdomain (tenant) routes
		return (
			<Switch>
				<Route path={`${path}/${SUB_ROUTES.FINISH_REGISTRATION}`} render={renderFinishRegistrationRoute} exact />
				<Route path={`${path}/${SUB_ROUTES.FORGOT_PASSWORD}`} component={ForgotPassword} exact />
				<Route path={`${path}/${SUB_ROUTES.CHANGE_PASSWORD}`} component={ChangePassword} exact />
				<Route path={`${path}/${SUB_ROUTES.LOGIN}`} component={renderLoginRoute} />
			</Switch>
		)
	}
}

export default OnboardingRouter;
