import { TestPlanRowResponse } from 'services/tenantManagementService';

export class CustomTestPlanRowModel {
	parentId!: number;
	childId?: number;
	isActive!: boolean;
    processTesterUserId?: number | undefined;
    location?: string | undefined;
    plannedDate?: Date | undefined;
    plannedTime?: number | undefined;
    plannedTimeTo?: number | undefined;
    duration?: number | undefined;
    level1Id?: string | undefined;
    level1Name?: string | undefined;
    level2Id?: string | undefined;
    level2Name?: string | undefined;
    level3ProcessId?: string | undefined;
    level3ProcessName?: string | undefined;
    processStepId?: string | undefined;
    processStepName?: string | undefined;
    department?: string | undefined;
    stepType?: string | undefined;
    stepTesterUserId?: number | undefined;

	constructor(data?: CustomTestPlanRowModel) {
 		if (data) {
			this.parentId = data.parentId;
			this.childId = data.childId;
			this.isActive = data.isActive;
			this.processTesterUserId = data.processTesterUserId;
			this.location = data.location;
			this.plannedDate = data.plannedDate;
			this.plannedTime = data.plannedTime;
			this.plannedTimeTo = data.plannedTimeTo;
			this.duration = data.duration;
			this.level1Id = data.level1Id;
			this.level1Name = data.level1Name;
			this.level2Id = data.level2Id;
			this.level2Name = data.level2Name;
			this.level3ProcessId = data.level3ProcessId;
			this.level3ProcessName = data.level3ProcessName;
			this.processStepId = data.processStepId;
			this.processStepName = data.processStepName;
			this.department = data.department;
			this.stepType = data.stepType;
			this.stepTesterUserId = data.stepTesterUserId;
		}
	}
}

export const mapToCustomTestPlanRowModel = (showProcessSteps: boolean | undefined, testPlans: TestPlanRowResponse[]) => {
	let result: CustomTestPlanRowModel[] = [];

	testPlans.forEach(testPlan => {
		if (showProcessSteps && testPlan.innerTestPlans && testPlan.innerTestPlans.length > 0) {
			testPlan.innerTestPlans.forEach(innerTestPlan => {
				result.push(new CustomTestPlanRowModel({...testPlan, ...innerTestPlan, parentId: testPlan.id, childId: innerTestPlan.id}));
			})
		}
		else {
			result.push(new CustomTestPlanRowModel({...testPlan, parentId: testPlan.id}));
		}
	});

	return result;
}
