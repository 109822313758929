import { useCallback } from 'react';
import DatePickerLib from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from 'components/icons/icons';
import { ControlsCommonProps } from '../../fields';
import styles from './datePicker.module.scss';
import { getLastDayOfMonth } from 'utils/dateTimeUtils';

export type RangeMonthDateType = {
	startDate: Date | undefined
	endDate: Date | undefined
}

export type RangeMonthPickerProps = ControlsCommonProps<RangeMonthDateType>

export const RangeMonthPicker = ({
	value,
	onChange,
	onBlur,
	disabled,
}: ControlsCommonProps<RangeMonthDateType>) => {
	const onChangeCallback = useCallback(
		([startDate, endDate]) => {
			onChange && onChange({startDate, endDate: getLastDayOfMonth(endDate)})
		},
		[onChange]
	)

	return (
		<div className={styles.container}>
			<DatePickerLib
				className={styles.date_picker}
				selected={value?.startDate}
				onChange={onChangeCallback}
				onBlur={onBlur}
				disabled={disabled}
				selectsRange
				startDate={value?.startDate}
				endDate={value?.endDate}
				dateFormat="MM/yyyy"
				showMonthYearPicker
			/>
			<div className={styles.icon}>
				<CalendarIcon width={16} height={16} fill='currentColor' />
			</div>
		</div>
	)
}
