import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { ExportModel } from 'features/Export/ExportForm';
import ExportToFile from 'features/Export/ExportToFile';
import { CrudButtons } from 'features/Crud';
import { ExportDataFileTypeEnum, ShowTestStatusEnum } from 'services/tenantManagementService';
import { BaseColumnModel } from 'components/Table';
import { ButtonGroup } from 'components/ButtonGroup';

const showTestStatusItems = [
	{ id: ShowTestStatusEnum.ShowTestStatus, text: 'Show test status' },
	{ id: ShowTestStatusEnum.ShowProcessSteps, text: 'Show test status with process steps' },
	{ id: ShowTestStatusEnum.ShowMultipleTickets, text: 'Show tests with multiple tickets' }
]

type Props = {
	selectedId: number
	selectedShowTestStatusItems: ShowTestStatusEnum[]
	setSelectedShowTestStatusItems(enums: ShowTestStatusEnum[]): void
	onExport: (exportModel: ExportModel) => void
	loadingExport?: boolean
	configureViewKey: string
    tableColumns: BaseColumnModel[]
}

const TableButtons = ({
	selectedId,
	selectedShowTestStatusItems,
	setSelectedShowTestStatusItems,
	onExport,
	configureViewKey,
	tableColumns,
	loadingExport
}: Props) => {
	return (
		<div>
			<RowContainer justifyContent='space-between'>
				<CrudButtons
					selectedId={selectedId}
					isCreateable={false}
					isChangeable={false}
					isDeleteable={false}
					labels={{
						view: 'View test case'
					}}
				/>
				<RowContainer>
					<ButtonGroup
						items={showTestStatusItems}
						onChange={setSelectedShowTestStatusItems}
						selectedIds={selectedShowTestStatusItems}
						single
					/>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
					<ExportToFile onExport={onExport} loading={loadingExport} exportTypeOptions={[ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX]} />
				</RowContainer>
			</RowContainer>
		</div>
	)
}

export default TableButtons;
