import { useCallback, useMemo } from 'react';
import notifications from 'components/Notification/notification';
import { InvoiceForm } from './InvoiceForm';
import { InsertInvoiceRequest, InvoiceResponse, DeleteTenantIndetifyRequest } from 'services/tenantManagementService';
import { createInvoiceAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { ProjectPickerParams } from 'features/Project';
import { useParams } from 'react-router-dom';
import { InsertInvoiceItemRequestUpdateInvoiceItemRequestDeleteTenantIndetifyRequestDeltaModel } from 'services/tenantManagementService';
import { createDelta } from 'utils/commonHelper';
import { InvoiceItemResponse, InsertInvoiceItemRequest, UpdateInvoiceItemRequest } from 'services/tenantManagementService';
import { getInitialStatus } from 'features/StatusResponse/statusResponse';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ContentShell } from 'features/Content/ContentShell';

export const CreateInvoice = ({ publishDataChanged }: CreateComponentProps) => {
	const params: ProjectPickerParams = useParams();
	const projectId = parseInt(params.projectId as string);
	const goBackFromCreate = useGoBackFromCreate();

	const { persistedInvoiceStatus } = useSelector((state: RootState) => state);

	const handleSave = useCallback(
		async (newInvoice: InvoiceResponse) => {
			const invoiceItemsDelta: InsertInvoiceItemRequestUpdateInvoiceItemRequestDeleteTenantIndetifyRequestDeltaModel = createDelta<InvoiceItemResponse>
			(
				[],
				newInvoice.invoiceItems || [],
				InsertInvoiceItemRequest,
				UpdateInvoiceItemRequest,
				InsertInvoiceItemRequestUpdateInvoiceItemRequestDeleteTenantIndetifyRequestDeltaModel,
				DeleteTenantIndetifyRequest
			);

			const model = new InsertInvoiceRequest({
				...newInvoice,
				newAttachments: (newInvoice as InsertInvoiceRequest).newAttachments,
				invoiceItemsDelta
			});
			const bindedAction = createInvoiceAction.bind(null, projectId, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`Invoice ${response.value?.invoiceNumber} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged, projectId]
	);

	const status = getInitialStatus(persistedInvoiceStatus.items);

	const newInvoice = useMemo(
		() => {
			const model = new InvoiceResponse();
			model.projectId = projectId;
			if (status) {
				model.statusId = status.id;
			}
			return model;
		},
		[projectId, status]
	)

	return (
		<ContentShell title='Create invoice'>
			<InvoiceForm
				invoice={newInvoice}
				projectId={projectId}
				crud={CrudEnum.Create}
				onSave={handleSave}
				cancel={goBackFromCreate}
			/>
		</ContentShell>
	)
}
