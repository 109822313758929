import { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import { ColumnContainer, RowContainer } from 'components/Layout';
import TravelRequestForm from './TravelRequestForm';
import { TimeTravelStatusEnum, TravelRequestResponse } from 'services/tenantManagementService';
import { getFormatedId, EntityPrefixEnum } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, CrudSubRoutesEnum, useGoBackFromCrudAndPush } from 'features/Crud';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { getTravelRequestAction } from '../action';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { ContentShell } from 'features/Content/ContentShell';
import { Export } from '../Export/Export';

type ParamType = {
	travelRequestId: string;
}

const ReadMyTravelRequest = () => {
	const params: ParamType = useParams();
	const [travelRequest, setTravelRequest] = useState(new TravelRequestResponse());
	const {
		persistedTimeAndTravelStatus,
		persistedExpenseGeneralConfiguration
	} = useSelector((state: RootState) => state);

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getTravelRequestAction.bind(null, parseInt(params.travelRequestId));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {

				setTravelRequest(response.value || new TravelRequestResponse());
			}
		},
		[params.travelRequestId]
	)

	const goBackFromCrudAndPush = useGoBackFromCrudAndPush(CrudEnum.Read, undefined, undefined);

	const goUpdateCallback = useCallback(
		() => {
			goBackFromCrudAndPush(`${CrudSubRoutesEnum.Update}/${params.travelRequestId}`);
		},
		[params.travelRequestId, goBackFromCrudAndPush]
	)

	const showChangeMemo = useMemo(
		() => !persistedExpenseGeneralConfiguration.value.enableApprovalProcess ||
			isStatusBySemantic(TimeTravelStatusEnum.Created, travelRequest.statusId, persistedTimeAndTravelStatus.itemsMap) ||
			isStatusBySemantic(TimeTravelStatusEnum.Rejected, travelRequest.statusId, persistedTimeAndTravelStatus.itemsMap),
		[persistedTimeAndTravelStatus, travelRequest, persistedExpenseGeneralConfiguration]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`View travel request - ${getFormatedId(EntityPrefixEnum.TRAVEL_REQUEST, travelRequest.id)}`}>
				<ColumnContainer margin='medium'>
					<RowContainer>
						{showChangeMemo && <Button text='Change' onClick={goUpdateCallback} />}
						<Export travelRequest={travelRequest} />
					</RowContainer>
					<TravelRequestForm
						travelRequest={travelRequest}
						crud={CrudEnum.Read}
					/>
				</ColumnContainer>
			</ContentShell>
		</WithFetch>
	)
}

export default ReadMyTravelRequest;
