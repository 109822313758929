import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {  TravelRequestResponse, UpdateTravelRequestRequest } from 'services/tenantManagementService';
import { getTravelRequestAction,  updateTravelRequestAction } from '../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import WithFetch from 'features/Fetch/WithFetch';
import { CrudEnum, UpdateComponentProps, useGoBackFromUpdate } from 'features/Crud';
import TravelRequestForm from './TravelRequestForm';
import { ContentShell } from 'features/Content/ContentShell';

type ParamType = {
	travelRequestId: string
}

const UpdateMyTravelRequest = ({ publishDataChanged }: UpdateComponentProps) => {
	const params: ParamType = useParams();
	const [travelRequest, setTravelRequest] = useState(new TravelRequestResponse());
	const goBackFromUpdate = useGoBackFromUpdate();

	const fetchDataCallback = useCallback(
		async () => {
			const bindedAction = getTravelRequestAction.bind(null, parseInt(params.travelRequestId));
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {

				setTravelRequest(response.value || new TravelRequestResponse());
			}
		},
		[params.travelRequestId]
	)

	const saveCallback = useCallback(
		async (newtTravelRequest: TravelRequestResponse, isRelease: boolean) => {

			const travelRequestForUpdate = new UpdateTravelRequestRequest({...newtTravelRequest, isRelease});

			const bindedAction = updateTravelRequestAction.bind(null, travelRequestForUpdate);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.TRAVEL_REQUEST, response.value?.id);
				notifications.success(`Travel request ${id} is updated.`);
				publishDataChanged();
				goBackFromUpdate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromUpdate, publishDataChanged]
	)

	return (
		<WithFetch fetchFunction={fetchDataCallback}>
			<ContentShell title={`Change travel request - ${getFormatedId(EntityPrefixEnum.TRAVEL_REQUEST, travelRequest.id)}`}>
				<TravelRequestForm
					travelRequest={travelRequest}
					crud={CrudEnum.Update}
					onSave={saveCallback}
				/>
			</ContentShell>
		</WithFetch>
	)
}

export default UpdateMyTravelRequest;
