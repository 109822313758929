import { useMemo } from 'react';
import { useApplyStatusColorCallback, useStatusCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { ShowTestStatusEnum, TestStatusResponse, TicketStatusResponse, UserModel } from 'services/tenantManagementService';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { getReadTicketRoute } from 'containers/Tickets/utils';
import { TableColumnsType, useVisibleColumns, FieldTypeEnum } from 'components/Table';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
	'level3ProcessId',
	'level3ProcessName',
	'processTesterUserId',
	'processStatusId',
	'plannedDate'
]

export const defaultProcessStepColumns = [
	'processStepId',
	'processStepName',
	'stepStatusId',
	'stepTesterUserId',
	'ticketIds',
]

export const defaultTicketColumns = [
	'ticketId',
	'ticketStatusId',
]

export const useTableColumnsMemo = (
	showTestStatus: ShowTestStatusEnum,
	visibleColumns?: string[]
) => {
	const {
		persistedUser,
		persistedTestCaseStatus,
		persistedTicketStatus
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getStatusName = useStatusCallback(persistedTestCaseStatus);
	const getTicketStatusName = useStatusCallback(persistedTicketStatus);

	const applyTicketStatusColor = useApplyStatusColorCallback(persistedTicketStatus);
	const applyTestStatusColor = useApplyStatusColorCallback(persistedTestCaseStatus);

	const defaultVisibleColumnsMemo = useMemo(
		() => {
			let result = defaultVisibleColumns;
			if (showTestStatus === ShowTestStatusEnum.ShowProcessSteps) {
				result = [
					...result,
					...defaultProcessStepColumns
				]
			} else if (showTestStatus === ShowTestStatusEnum.ShowMultipleTickets) {
				result = [
					...result,
					...defaultTicketColumns
				]
			}
			return result;
		},
		[showTestStatus]
	)

	const columns: TableColumnsType = useMemo(
		() => {
			let tableColumns: TableColumnsType = {
				level3ProcessId: {
					title: 'Level 3 Process ID',
					fieldType: FieldTypeEnum.String
				},
				level3ProcessName: {
					title: 'Level 3 Process description',
					fieldType: FieldTypeEnum.String
				},
				processTesterUserId: {
					title: 'Process tester',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				processStatusId: {
					title: 'Process status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyTestStatusColor(id, cell.getElement());
						return getStatusName(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTestCaseStatus.items,
					getItemId: (option: TestStatusResponse) => option.id,
					getItemText: (option: TestStatusResponse) => option.name
				},
				plannedDate: {
					title: 'Planned date',
					fieldType: FieldTypeEnum.Date
				}
			}
			if (showTestStatus === ShowTestStatusEnum.ShowProcessSteps) {
				tableColumns = {
					...tableColumns,
					processStepId: {
						title: 'Process Step Id',
						fieldType: FieldTypeEnum.String,
						disableSort: true
					},
					processStepName: {
						title: 'Process Step Name',
						fieldType: FieldTypeEnum.String,
						disableSort: true
					},
					stepStatusId: {
						title: 'Step status',
						formatter: (cell: any) => {
							const id = cell.getValue();
							applyTestStatusColor(id, cell.getElement());
							return getStatusName(id);
						},
						fieldType: FieldTypeEnum.Options,
						options: persistedTestCaseStatus.items,
						getItemId: (option: TestStatusResponse) => option.id,
						getItemText: (option: TestStatusResponse) => option.name,
						disableSort: true
					},
					stepTesterUserId: {
						title: 'Step Tester',
						formatter: (cell: any) => getUserFullName(cell.getValue()),
						fieldType: FieldTypeEnum.Options,
						options: persistedUser.items,
						getItemId: (option: UserModel) => option.id,
						getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`,
						disableSort: true
					},
					ticketIds: {
						title: 'Tickets',
						fieldType: FieldTypeEnum.None,
						formatter: (cell: any) => {
							const value: number[] = cell.getValue();
							if (!value) {
								return ''
							}
							return value.map((val: number) => {
								return (
									`<a href="${window.location.protocol}//${window.location.host}${getReadTicketRoute(val)}" target="_blank">${getFormatedId(EntityPrefixEnum.TICKET, val)}</a>`
								)
							}).join(',<br />');
						},
						disableSort: true,
						dbExportFieldPath: null
					},
				}
			} else if (showTestStatus === ShowTestStatusEnum.ShowMultipleTickets) {
				tableColumns = {
					...tableColumns,
					ticketId: {
						title: 'Ticket Id',
						formatter: "link",
						fieldType: FieldTypeEnum.None,
						formatterParams: {
							target: '_blank',
							label: (cell: any) => cell.getValue() ? getFormatedId(EntityPrefixEnum.TICKET, cell.getValue()) : '',
							url: (cell: any) => cell.getValue() ? `${window.location.protocol}//${window.location.host}${getReadTicketRoute(cell.getValue())}` : '',
						},
						tooltip: (e: any, cell: any) => cell.getValue() ? getFormatedId(EntityPrefixEnum.TICKET, cell.getValue()) : '',
						disableSort: true
					},
					ticketStatusId: {
						title: 'Ticket Status',
						formatter: (cell: any) => {
							const id = cell.getValue();
							applyTicketStatusColor(id, cell.getElement());
							return getTicketStatusName(id);
						},
						fieldType: FieldTypeEnum.Options,
						options: persistedTicketStatus.items,
						getItemId: (option: TicketStatusResponse) => option.id,
						getItemText: (option: TicketStatusResponse) => option.name
					}
				}
			}
			return tableColumns;
		},
		[getUserFullName, getStatusName, getTicketStatusName, applyTicketStatusColor, applyTestStatusColor, showTestStatus, persistedTestCaseStatus.items, persistedTicketStatus.items, persistedUser.items]
	)

	return useVisibleColumns(columns, visibleColumns || defaultVisibleColumnsMemo);
}
