import { useCallback } from 'react';
import notifications from 'components/Notification/notification';
import { CreateComponentProps, CrudEnum, useGoBackFromCreate } from 'features/Crud';
import { OrganizationalUnitManagerResponse } from 'services/tenantManagementService';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { createManagerAuthorizationsAction } from '../action';
import { ManagerAuthorizationForm } from './ManagerAuthorizationForm';
import { Dialog } from 'components/Dialog';

export const CreateManagerAuthorization = ({ publishDataChanged }: CreateComponentProps) => {
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newValue: OrganizationalUnitManagerResponse) => {
			const bindedAction = createManagerAuthorizationsAction.bind(null, newValue);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success(`Manager authorization is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	);

	return (
		<Dialog
			open
			title='Add manager authorization'
			onClose={goBackFromCreate}
		>
			<ManagerAuthorizationForm
				crud={CrudEnum.Create}
				onSave={handleSave}
			/>
		</Dialog>
	)
}
