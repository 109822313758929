import { ColumnContainer } from 'components/Layout'
import { TicketFormTabsProps } from '../Tabs/TicketFormTabs'
import { EffortEstimate } from "./EffortEstimate"
import { EffortTimesheets } from './EffortTimesheets'

export const Effort = ({ ticket, addEffortTimesheet }: TicketFormTabsProps) => {
	return (
		<ColumnContainer>
			<EffortEstimate ticket={ticket} />
			<EffortTimesheets
				ticket={ticket}
				addEffortTimesheet={addEffortTimesheet}
			/>
		</ColumnContainer>
	)
}
