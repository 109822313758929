import Tabs from 'components/Tabs/Tabs';
import { ProjectResponse, SurveyResponse } from 'services/tenantManagementService';
import { maintainSurveyTabs } from './maintainSurveyTabs';

export type UpdateSurveyTabsProps = {
	project: ProjectResponse,
	survey: SurveyResponse,
}

const UpdateSurveyTabs = ({ project, survey }: UpdateSurveyTabsProps) => {
	return (
		<Tabs
			tabs={maintainSurveyTabs}
			tabComponentProps={{project, survey}}
		/>
	)
}

export default UpdateSurveyTabs;
