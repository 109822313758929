import { useCallback, useState } from 'react'
import { ArrowRightIcon } from 'components/icons/icons'
import styles from './dashboardContainer.module.scss'

type Props = {
	form: any
	dashboard: any
}

export const DashboardContainer = ({ form, dashboard }: Props) => {
	// TODO: performance improvement for transition of formVisible
	// https://www.amcharts.com/docs/v4/concepts/performance/#Disabling_autoresizing
	const [formVisible, setFormVisible] = useState(false);

	const toggleCallback = useCallback(
		() => {
			setFormVisible((state) => !state);
		},
		[]
	)

	return (
		<div className={styles.container}>
			<div className={`${styles.form} ${formVisible ? '' : styles.hidden}`}>
				<div className={styles.form_inner}>
					{form}
				</div>
				<div className={styles.toggler} onClick={toggleCallback}>
					<ArrowRightIcon width='10' height='10' fill='currentColor' className={formVisible ? styles.left : styles.right} />
				</div>
			</div>
			<div className={styles.dashboard}>
				{dashboard}
			</div>
		</div>
	)
}
