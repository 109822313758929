import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SmartContainer, SmartItem, SmartInline, SmartFormGroup } from 'components/SmartContainer/SmartContainer';
import { CrudEnum } from 'features/Crud';
import { ProjectReportResponse, ProjectResponse, UpdateProjectReportRequest } from 'services/tenantManagementService';
import { AutoCompleteField, DateField, Form, Input } from 'components/Form';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { updateReportAction } from '../../action';
import notifications from 'components/Notification/notification';
import { ReportComponentParams } from '../Table/TableButtons';
import { getProjectAction } from 'containers/Projects/MyProjects/action';
import WithFetch from 'features/Fetch/WithFetch';

type Props = {
	report: ProjectReportResponse
	setReport?(report: ProjectReportResponse): void
	reportCrud: CrudEnum
	publishDataChanged?(): void
}

export const ProjectIdentification = ({ report, setReport, reportCrud, publishDataChanged }: Props) => {
	const params: ReportComponentParams = useParams();
	const projectId = parseInt(params.projectId as string);

	const [values, setValues] = useState(report || new ProjectReportResponse());
	const [project, setProject] = useState(new ProjectResponse());

	const { persistedUser } = useSelector((state: RootState) => state);

	const fetchProjectCallback = useCallback(
		async () => {
			const bindedAction = getProjectAction.bind(null, projectId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setProject(response.value || new ProjectResponse());
			}
		},
		[projectId]
	)

	const projectManagerFullName = useMemo(
		() => {
			if (persistedUser.isInitialized && project.pmId) {
				const user = persistedUser.itemsMap[project.pmId];
				return user ? `${user.firstName} ${user.lastName}` : '';
			}
		},
		[persistedUser, project.pmId]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new UpdateProjectReportRequest({
				...report,
				projectFrom: values.projectFrom,
				projectTo: values.projectTo,
				from: values.from,
				to: values.to,
			});

			const bindedAction = updateReportAction.bind(null, project.id, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Project identification is updated.');
				publishDataChanged && publishDataChanged();
				setReport && setReport(response.value || new ProjectReportResponse())
			} else {
				return convertResponseErrors(response);
			}
		},
		[report, project.id, values, publishDataChanged, setReport]
	);

	const resetCallback = useCallback(
		() => {
			const newReport = new ProjectReportResponse(report);
			setValues(newReport);
		},
		[report]
	)

	return (
		<WithFetch fetchFunction={fetchProjectCallback}>
			<Form
				values={values}
				initialValues={report}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={resetCallback}
				disabled={reportCrud === CrudEnum.Read}
				hideButtons={reportCrud === CrudEnum.Read}
				cancelButtonText='Reset'
				render={() => (
					<>
						<h2>Project Identification</h2>
						<SmartContainer>
							<SmartItem>
								<AutoCompleteField
									id='id'
									label='Report Id'
									items={[values.id]}
									getItemId={(item: number) => item}
									getItemText={(item: number) => getFormatedId(EntityPrefixEnum.REPORT, item)}
									disabled
								/>
								<SmartFormGroup label='Project Name'>
									<Input
										value={project.name}
										disabled
									/>
								</SmartFormGroup>
								<SmartFormGroup label='Project Manager'>
									<Input
										value={projectManagerFullName}
										disabled
									/>
								</SmartFormGroup>
							</SmartItem>
							<SmartItem>
								<DateField
									id='projectFrom'
									label='Project Start Date'
								/>
								<DateField
									id='projectTo'
									label='Project End Date'
								/>
								<SmartFormGroup label='Reporting period' isRequired>
									<SmartInline>
										<DateField
											id='from'
											isRequired
											inline
										/>
										<DateField
											id='to'
											isRequired
											inline
										/>
									</SmartInline>
								</SmartFormGroup>
							</SmartItem>
						</SmartContainer>
					</>
				)}
			/>
		</WithFetch>
	)
}
