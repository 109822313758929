import { RowContainer } from 'components/Layout';
import { CrudButtons } from 'features/Crud';
import ConfigureView from 'features/ConfigureView';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { Export } from 'features/Export/Export';
import { BaseColumnModel } from 'components/Table';

type Props = {
	selectedId: number
	isDeleteable?: boolean
	onDeleteClick?: () => Promise<void>
	configureViewKey: string
    tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
	exportTitle: string
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

export const TableButtons = ({
	selectedId,
	onDeleteClick,
	isDeleteable = true,
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction,
	exportTitle
}: Props) => {
	return (
		<RowContainer justifyContent='space-between'>
			<CrudButtons
				selectedId={selectedId}
				onDelete={onDeleteClick}
				isViewable={false}
				isDeleteable={isDeleteable}
			/>
			<RowContainer>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				<Export
					filtersModel={filtersModel}
					tableColumns={tableColumns}
					exportAction={exportFunction}
					exportTypeOptions={EXPORT_TYPE_OPTIONS}
					title={exportTitle}
				/>
			</RowContainer>
		</RowContainer>
	)
}
