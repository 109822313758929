import { WithProjectPicker, ComponentProps, pmOrSpmPermission } from 'features/Project';
import Tabs from 'components/Tabs/Tabs';
import { budgetTabs } from './budgetTabs';
import { ContentShell } from 'features/Content/ContentShell';
import { ModuleActivityEnum } from 'services/tenantManagementService';

const Budget = ({ project, disabledEdit }: ComponentProps) => {
	return (
		<ContentShell
			title='Budget'
		>
			<Tabs
				tabs={budgetTabs}
				tabComponentProps={{project, disabledEdit} as ComponentProps}
			/>
		</ContentShell>
	)
}

export default WithProjectPicker(Budget, ModuleActivityEnum.Finance, true, undefined, pmOrSpmPermission)
