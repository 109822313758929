import { useMemo } from 'react'
import { SurveyDashboardResponse, SurveyTypeEnum } from 'services/tenantManagementService'
import { PieWidget } from 'features/Dashboard/Widget/PieWidget'
import { Dashboard } from 'features/Dashboard/Dashboard';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { TextWidget } from 'features/Dashboard/Widget/TextWidget';
import { Layout } from 'features/Dashboard/Layout';
import { SURVEY_RATING_OPTIONS } from 'containers/Training/MySurveys/mySurveyConstants';
import { useTenantIsActiveCallback } from 'features/TableColumns/persistedHooks';
import { XYWidget } from 'features/Dashboard/Widget/XYWidget';
import { useTableColumnsMemo } from './tableColumns';
import { useTableColumnsRatingsMemo } from './tableColumnsRatings';
import { LocalTableWidget } from 'features/Dashboard/Widget/LocalTableWidget';

type Props = {
	loading: boolean
	dashboard: SurveyDashboardResponse,
}

export const FilteredDashboard = ({ loading, dashboard }: Props) => {
	const { persistedTrainingStatus, persistedProjectTeam, persistedProjectRole } = useSelector((state: RootState) => state);
	const isQuiz = dashboard.surveyType === SurveyTypeEnum.Quiz;

	const getProjectTeamName = useTenantIsActiveCallback(persistedProjectTeam)
	const getProjectRoleName = useTenantIsActiveCallback(persistedProjectRole)

	const tableColumns = useTableColumnsMemo();
	const tableColumnsRatings = useTableColumnsRatingsMemo(dashboard);

	const statusDataMemo = useMemo(
		() => {
			const data = persistedTrainingStatus.items.map(item => {
				return {
					id: item.id.toString(),
					name: item.name,
					count: (dashboard.surveysStatusCounts && dashboard.surveysStatusCounts[item.id]) ? dashboard.surveysStatusCounts[item.id] : 0
				}
			});

			return data;
		},
		[dashboard, persistedTrainingStatus]
	)

	const chartDataPerTeamMemo = useMemo(
		() => {
		return Object.keys(dashboard.averageRatingPerTeam || {}).map(key => {
			const value = dashboard.averageRatingPerTeam![key]
				return  {
					name: getProjectTeamName(parseInt(key)) || '',
					value: isQuiz ? value*100 :  value
				}
			})
		},
		[dashboard, getProjectTeamName, isQuiz]
	)

	const chartDataPerRoleMemo = useMemo(
		() => {
			return Object.keys(dashboard.averageRatingPerRole || {}).map(key => {
				const value = dashboard.averageRatingPerRole![key];
				return {
					name: getProjectRoleName(parseInt(key)) || '',
					value: isQuiz ? value * 100 :  value
				}
			})
		},
		[dashboard, getProjectRoleName, isQuiz]
	)

	const overalRatingValue = useMemo(
		() => {
			if (isQuiz) {
				return `${(dashboard.overallRating * 100).toFixed(2)}%`
			} else {
				return (dashboard.overallRating === 0 || dashboard.overallRating === undefined) ? '-' : `${dashboard.overallRating.toFixed(2)} - ${SURVEY_RATING_OPTIONS.find((item) => item.id === Math.round(dashboard.overallRating))?.text || ''}`
			}
		},
		[dashboard, isQuiz]
	)

	return (
		<Dashboard orientation='vertical'>
			<Layout orientation='vertical'>
				<Layout orientation='horizontal'>
					<PieWidget
						className='col-xl-4 col-md-6'
						title='Survey / Quiz Response'
						id='survey_quiz_pie_chart'
						fieldValue='count'
						fieldCategory='name'
						data={statusDataMemo}
						loading={loading}
					/>
					<TextWidget
						className='col-2'
						title={isQuiz ? 'Average score' : 'Overal Rating'}
						value={overalRatingValue}
						loading={loading}
					/>
				</Layout>
				<Layout orientation='horizontal'>
					<XYWidget
						className='col-xl-4 col-md-6'
						title='Survey/quiz per project team'
						id='survey_quiz_per_project_team'
						data={chartDataPerTeamMemo}
						loading={loading}
						ytitle={isQuiz ? 'correct in %': 'average points'}
						minValue={0}
						maxValue={isQuiz ? 100 : Math.max(...chartDataPerTeamMemo.map(x => x.value)) || 1}
						showValueOnBar
					/>
					<XYWidget
						className='col-xl-4 col-md-6'
						title='Survey/quiz per project role'
						id='survey_quiz_per_project_role'
						data={chartDataPerRoleMemo}
						ytitle={isQuiz ? 'correct in %': 'average points'}
						minValue={0}
						maxValue={isQuiz ? 100 : Math.max(...chartDataPerTeamMemo.map(x => x.value)) || 1}
						loading={loading}
						showValueOnBar
					/>
					<Layout
						orientation='horizontal'
						className='col-xl-4 col-12'
					>
						<LocalTableWidget
							className='col-xl-12 col-md-6'
							title={isQuiz ? 'Score per question' : 'Ratings per Question'}
							loading={loading}
							columns={tableColumnsRatings}
							data={dashboard.averageRatings || []}
							hasPagination
							limit={4}
						/>
						<LocalTableWidget
							className='col-xl-12 col-md-6'
							title='Text questions'
							loading={loading}
							columns={tableColumns}
							data={dashboard.textAnswers || []}
							hasPagination
							limit={4}
						/>
					</Layout>
				</Layout>
			</Layout>
		</Dashboard>
	)
}
