import { WithProjectPicker, ComponentProps, pmOrSpmPermission } from 'features/Project';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import { ContentShell } from 'features/Content/ContentShell';
import { GenericFilterModelCollection, ModuleActivityEnum, ProjectResponse, SurveyResponse, TrainingPermission } from 'services/tenantManagementService';
import { deleteSurveyAction, getSurveysGenericAction } from './action';
import { persistTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action';
import { useTableColumnsMemo } from './Table/tableColumns';
import TableButtons from './Table/TableButtons';
import { publishSurveysChanged, surveysChangedTopic } from './surveysChanged';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { CreateSurveysHelp } from './Help/CreateSurveysHelp';
import { RemoteTable } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';

const Surveys = ({ project, disabledEdit }: ComponentProps) => {
	const { persistedTeamMember } = useSelector((state: RootState) => state);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const [selectedSurvey, setSelectedSurvey] = useState(new SurveyResponse());

	const tableColumns = useTableColumnsMemo();

	useEffect(
		() => {
			if (!persistedTeamMember.projectMap[project.id]) {
				persistTeamMembersAction(project.id);
			}
		},
		[persistedTeamMember, project.id]
	)

	// just a quick fix, memoFetchFunction was changed after project is changed and unneeded request was send
	const [currentProject, setCurrentProject] = useState(new ProjectResponse(project));
	useEffect(
		() => {
			setCurrentProject(new ProjectResponse(project));
		},
		[project]
	)

	const handleRowSelectionChange = useCallback(
		(data: SurveyResponse[]) => {
			setSelectedSurvey(data[0] || new SurveyResponse());
		},
		[]
	);

	const handleDelete = useCallback(
		async (id: number) => {
			const bindedAction = deleteSurveyAction.bind(null, project.id, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success('Survey is successfully deleted');
				publishSurveysChanged();
			}
		},
		[project]
	);

	const memoFetchFunction = useMemo(
		() => async (genericFilter: GenericFilterModelCollection) => {
			setIsLoading(true);
			const response = await getSurveysGenericAction(currentProject.id, genericFilter)
			setIsLoading(false);
			return response
		},
		[currentProject.id]
	)

	return (
		<ContentShell
			title='Survey and Quiz'
			FloatingHelpComponent={CreateSurveysHelp}
		>
			<ColumnContainer margin='medium'>
				<TableButtons
					selectedId={selectedSurvey.id}
					disabled={disabledEdit || isLoading}
					onDelete={handleDelete}
				/>
				<RemoteTable
					columns={tableColumns}
					filtersModel={filtersModel}
					filtersModelChanged={setFiltersModel}
					fetchFunction={memoFetchFunction}
					isLoading={isLoading}
					rowSelectionChanged={handleRowSelectionChange}
					subscriptionTopic={surveysChangedTopic}
				/>
			</ColumnContainer>
		</ContentShell>
	)
}

export default WithProjectPicker(Surveys, ModuleActivityEnum.Training, true, propertyOf<TrainingPermission>('createSurveys'), pmOrSpmPermission);
