import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { Export } from 'features/Export/Export';
import { BaseColumnModel } from 'components/Table';

type Props = {
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	exportFunction(model: ExportDataModel): Promise<FileResponse>
}

const TableButtons = ({
	configureViewKey,
	tableColumns,
	exportFunction
}: Props) => {
	return (
		<RowContainer justifyContent='flex-end'>
			<RowContainer>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				<Export
					title='Vacation'
					filtersModel={new GenericFilterModelCollection()}
					tableColumns={tableColumns}
					exportTypeOptions={[ExportDataFileTypeEnum.XLSX, ExportDataFileTypeEnum.PDF]}
					exportAction={exportFunction}
					forceApplyFilters
					hideApplySort
					hideExportOnlyCurrentPage
				/>
			</RowContainer>
		</RowContainer>
	)
}

export default TableButtons;
