import { useCallback, useEffect, useState } from 'react'
import { TestDashboardFilterRequest, TestDashboardResponse } from 'services/tenantManagementService'
import FilteredDashboard from './FilteredDashboard'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getTestDashboardAction } from './action'
import FilterForm from './FilterForm'
import WithFetch from 'features/Fetch/WithFetch'
import { DashboardContainer } from 'features/Dashboard/DashboardContainer/DashboardContainer'
import { useSaveFilterMemo } from 'features/Dashboard/SaveFilter/useSaveFilterMemo'
import { FilterModel } from './FilterModel'

const viewType = 'testing_dashboard';

export const TestingDashboard = () => {
	const [loading, setLoading] = useState(false);
	const { savedFilterModel, saveFilterModel, fetchFilterModel, isLoadingFilter } = useSaveFilterMemo<FilterModel>(viewType);

	const [currentFilterModel, setCurrentFilterModel] = useState(new FilterModel());

	const [dashboardResponse, setDashboardResponse] = useState<TestDashboardResponse>(new TestDashboardResponse())

	const fetchDashboardCallback = useCallback(
		async (model: FilterModel) => {
			setLoading(true);

			setCurrentFilterModel(new FilterModel(model));

			const bindedAction = getTestDashboardAction.bind(null, model.projectId, model.testCycleId, new TestDashboardFilterRequest(model))
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setDashboardResponse(response.value);
			}

			setLoading(false);
		},
		[]
	)

	useEffect(
		() => {
			if (savedFilterModel) {
				fetchDashboardCallback(savedFilterModel);
			}
		},
		[savedFilterModel, fetchDashboardCallback]
	)

	return (
		<WithFetch fetchFunction={fetchFilterModel} refetching={isLoadingFilter}>
			<DashboardContainer
				form={
					<FilterForm
						onSubmit={fetchDashboardCallback}
						filterFormModel={savedFilterModel}
						onSave={saveFilterModel}
					/>
				}
				dashboard={
					<FilteredDashboard
						loading={loading}
						dashboard={dashboardResponse}
						filterModel={currentFilterModel}
					/>
				}
			/>
		</WithFetch>
	)
}
