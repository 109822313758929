import { HelpShell } from 'features/Help/HelpShell';

export const RulesAndAlertsHelp = () => {
	return (
		<HelpShell title='Rules and alerts'>
			<p>Some of notifications are configured on site level by site administrator, such as notifications related to timesheet, travel and vacation approval process.</p>
			<div>
				<p>Here you can define your own Mavles and email notifications. Mavles can notify you if:</p>
				<ol>
					<li>process or process step status is changed in test case where you are assigned as tester. This could be general rule, but you can also specify concrete test case for which you want to be notified.</li>
					<li>ticket is changed where you are involved or where you mark ticked for follow up. This could be general rule, but you can also specify concrete ticket for which you want to be notified.</li>
					<li>change request is changed where you are involved in approval process. This could be general rule, but you can also specify concrete change request for which you want to be notified.</li>
					<li>task is changed where you are involved or where you mark task for follow up. This could be general rule, but you can also specify concrete task for which you want to be notified.</li>
					<li>schedule is changed.</li>
					<li>project status report where you are involved is changed.</li>
					<li>meeting minutes where you are involved is changed.</li>
				</ol>
			</div>
		</HelpShell>
	)
}

