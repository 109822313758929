import { ReportTestPlanResponse, ShowTestStatusEnum } from 'services/tenantManagementService';

export class CustomTestStatusReportRowModel {
	parentId!: number;
    level1Name?: string | undefined;
    level2Name?: string | undefined;
    level3ProcessId?: string | undefined;
    level3ProcessName?: string | undefined;
    processTesterUserId?: number | undefined;
    processStatusId!: number;
	plannedDate?: Date | undefined
	ticketId?: number;
    ticketStatusId?: number;
	processStepId?: string | undefined;
	processStepName?: string | undefined;
    stepStatusId?: number;
    stepTesterUserId?: number | undefined;
    ticketIds?: number[] | undefined;

	constructor(data?: CustomTestStatusReportRowModel) {
 		if (data) {
			 this.parentId = data.parentId;
			this.level1Name = data.level1Name;
			this.level2Name = data.level2Name;
			this.level3ProcessId = data.level3ProcessId;
			this.level3ProcessName = data.level3ProcessName;
			this.processTesterUserId = data.processTesterUserId;
			this.processStatusId = data.processStatusId;
			this.plannedDate = data.plannedDate
			this.processStepName = data.processStepName;
			this.ticketId = data.ticketId;
			this.ticketStatusId = data.ticketStatusId;
			this.processStepId = data.processStepId;
			this.stepStatusId = data.stepStatusId;
			this.stepTesterUserId = data.stepTesterUserId;
			this.ticketIds = data.ticketIds;
		}
	}
}

export const mapToCustomTestStatusReportRowModel = (showTestStatus: ShowTestStatusEnum, testStatusReports: ReportTestPlanResponse[]) => {
	let result: CustomTestStatusReportRowModel[] = [];

	testStatusReports.forEach(testStatusReport => {
		if (showTestStatus === ShowTestStatusEnum.ShowProcessSteps && testStatusReport.innerTestPlans && testStatusReport.innerTestPlans.length > 0) {
			testStatusReport.innerTestPlans.forEach(innerTestPlan => {
				result.push(new CustomTestStatusReportRowModel({...testStatusReport, ...innerTestPlan, parentId: testStatusReport.id }));
			})
		}
		else if (showTestStatus === ShowTestStatusEnum.ShowMultipleTickets && testStatusReport.tickets && testStatusReport.tickets.length > 0) {
			testStatusReport.tickets.forEach(ticket => {
				result.push(new CustomTestStatusReportRowModel({...testStatusReport, ...ticket, parentId: testStatusReport.id}));
			})
		} else {
			result.push(new CustomTestStatusReportRowModel({...testStatusReport, parentId: testStatusReport.id }));
		}
	});

	return result;
}
