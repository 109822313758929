import { useMemo } from 'react';
import { ImpactResponse, TenantIsActiveResponse, TicketAssignedGroupsResponse, TicketCategoryResponse, TicketResponseGetAll,
	UrgencyResponse, UserModel, TicketStatusResponse, TicketResponse, TimeMeasureEnum, PriorityEnum,
	GenericFilterModelCollection, ProjectResponse, SimpleScheduleResponse, ModuleActivityEnum
} from 'services/tenantManagementService';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { useApplyStatusColorCallback, useFormattedProjectIdNameCallback, useStatusCallback, useTenantIsActiveCallback,
	useTicketAssignedGroupsCallback, useTicketCategoriesCallback, useTicketImpactsCallback, useTicketUrgenciesCallback, useUserFullNameCallback
} from 'features/TableColumns/persistedHooks';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { customTextAreaFormatter } from 'components/Table/BaseTable/helpers/customTextAreaFormatter';
import { convertTicksToTime } from 'utils/dateTimeUtils';
import { useProjectsForTableFilterCallback } from 'features/Project';

const defaultVisibleColumns = [
	propertyOf<TicketResponseGetAll>('projectId'),
	propertyOf<TicketResponseGetAll>('id'),
	propertyOf<TicketResponseGetAll>('categoryLevel1Id'),
	propertyOf<TicketResponseGetAll>('categoryLevel2Id'),
	propertyOf<TicketResponseGetAll>('name'),
	propertyOf<TicketResponseGetAll>('statusId'),
	propertyOf<TicketResponseGetAll>('assignedToUserId'),
	propertyOf<TicketResponseGetAll>('priority'),
]

export const useColumnsObjectMemo = (schedules: SimpleScheduleResponse[], filtersModel?: GenericFilterModelCollection) => {
	const {
		persistedUser,
		persistedTicketType,
		persistedTicketEnvironment,
		persistedTicketAssignedGroup,
		persistedTicketCategory,
		persistedTicketStatus,
		persistedTicketImpact,
		persistedTicketUrgency,
		persistedTicketClosingId,
		persistedProject,
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getType = useTenantIsActiveCallback(persistedTicketType);
	const getEnvironment = useTenantIsActiveCallback(persistedTicketEnvironment);
	const getCategory = useTicketCategoriesCallback(persistedTicketCategory);
	const getStatus = useStatusCallback(persistedTicketStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedTicketStatus);
	const getImpact = useTicketImpactsCallback(persistedTicketImpact);
	const getUrgency = useTicketUrgenciesCallback(persistedTicketUrgency);
	const getAssignedGroup = useTicketAssignedGroupsCallback(persistedTicketAssignedGroup);
	const getClosingIdName = useTenantIsActiveCallback(persistedTicketClosingId);
	const { projects } = useProjectsForTableFilterCallback(ModuleActivityEnum.Ticket);

	const schedulesMap = useMemo(() => schedules.reduce((map: {[id: number]: SimpleScheduleResponse}, item) => {
		map[item.id] = item;
		return map;
	}, {}), [schedules])

	const formatProjectIdName =  useFormattedProjectIdNameCallback(persistedProject);

	return useMemo(
		() => {
			return {
				[propertyOf<TicketResponseGetAll>('id')]: {
					title: 'Ticket Id',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.TICKET
				},
				[propertyOf<TicketResponseGetAll>('name')]: {
					title: 'Short description',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<TicketResponseGetAll>('description')]: {
					title: 'Description',
					fieldType: FieldTypeEnum.String,
					formatter: customTextAreaFormatter
				},
				[propertyOf<TicketResponseGetAll>('projectId')]: {
					title: 'Project ID',
					formatter: (cell: any) => formatProjectIdName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: projects,
					getItemId: (option: ProjectResponse) => option.id,
					getItemText: (option: ProjectResponse) => formatProjectIdName(option.id)
				},
				[propertyOf<TicketResponseGetAll>('createdByUserId')]: {
					title: 'Created by',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				[propertyOf<TicketResponseGetAll>('reportedBy')]: {
					title: 'Reported by',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<TicketResponseGetAll>('reportedOn')]: {
					title: 'Reported on',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TicketResponseGetAll>('typeId')]: {
					title: 'Type',
					formatter: (cell: any) => getType(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketType.items,
					getItemId: (option: TenantIsActiveResponse) => option.id,
					getItemText: (option: TenantIsActiveResponse) => option.name
				},
				[propertyOf<TicketResponseGetAll>('categoryLevel1Id')]: {
					title: 'Category lvl 1',
					formatter: (cell: any) => getCategory(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketCategory.items.filter(item => item.levelNumber === 1),
					getItemId: (option: TicketCategoryResponse) => option.id,
					getItemText: (option: TicketCategoryResponse) => option.name
				},
				[propertyOf<TicketResponseGetAll>('categoryLevel2Id')]: {
					title: 'Category lvl 2',
					formatter: (cell: any) => getCategory(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketCategory.items.filter(item => item.levelNumber === 2),
					getItemId: (option: TicketCategoryResponse) => option.id,
					getItemText: (option: TicketCategoryResponse) => option.name
				},
				[propertyOf<TicketResponseGetAll>('categoryLevel3Id')]: {
					title: 'Category lvl 3',
					formatter: (cell: any) => getCategory(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketCategory.items.filter(item => item.levelNumber === 3),
					getItemId: (option: TicketCategoryResponse) => option.id,
					getItemText: (option: TicketCategoryResponse) => option.name
				},
				[propertyOf<TicketResponseGetAll>('ticketNumberExt')]: {
					title: 'Ticket number ext',
					fieldType: FieldTypeEnum.String,
				},
				[propertyOf<TicketResponseGetAll>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketStatus.items,
					getItemId: (option: TicketStatusResponse) => option.id,
					getItemText: (option: TicketStatusResponse) => option.name
				},
				[propertyOf<TicketResponseGetAll>('environmentId')]: {
					title: 'Environment',
					formatter: (cell: any) => getEnvironment(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketEnvironment.items,
					getItemId: (option: TenantIsActiveResponse) => option.id,
					getItemText: (option: TenantIsActiveResponse) => option.name
				},
				[propertyOf<TicketResponseGetAll>('scheduleId')]: {
					title: 'WBS',
					formatter: (cell: any) => {
						const id = cell.getValue();
						if (!id) {
							return '';
						}
						const schedule = schedulesMap[id];
						return schedule ? `${schedule.wbs} - ${schedule.name}` : '';
					},
					fieldType: FieldTypeEnum.Options,
					options: schedules,
					getItemId: (option: SimpleScheduleResponse) => option.id,
					getItemText: (option: SimpleScheduleResponse) => `${option.wbs} - ${option.name}`,
				},
				[propertyOf<TicketResponseGetAll>('impactId')]: {
					title: 'Impact',
					formatter: (cell: any) => getImpact(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketImpact.items,
					getItemId: (option: ImpactResponse) => option.id,
					getItemText: (option: ImpactResponse) => option.name
				},
				[propertyOf<TicketResponseGetAll>('urgencyId')]: {
					title: 'Urgency',
					formatter: (cell: any) => getUrgency(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketUrgency.items,
					getItemId: (option: UrgencyResponse) => option.id,
					getItemText: (option: UrgencyResponse) => option.name
				},
				[propertyOf<TicketResponseGetAll>('priority')]: {
					title: 'Priority',
					fieldType: FieldTypeEnum.Options,
					options: Object.values(PriorityEnum),
					getItemId: (option: PriorityEnum) => option,
					getItemText: (option: PriorityEnum) => option
				},
				[propertyOf<TicketResponseGetAll>('assignedGroupId')]: {
					title: 'Assigned group',
					formatter: (cell: any) => getAssignedGroup(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketAssignedGroup.items,
					getItemId: (option: TicketAssignedGroupsResponse) => option.id,
					getItemText: (option: TicketAssignedGroupsResponse) => option.name
				},
				[propertyOf<TicketResponseGetAll>('assignedToUserId')]: {
					title: 'Assigned to',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				[propertyOf<TicketResponseGetAll>('createdOn')]: {
					title: 'Created on',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TicketResponseGetAll>('parentTicketId')]: {
					title: 'Parent ticket',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.TICKET
				},
				[propertyOf<TicketResponseGetAll>('closingId')]: {
					title: 'Closing Id',
					formatter: (cell: any) => getClosingIdName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedTicketClosingId.items,
					getItemId: (option: TenantIsActiveResponse) => option.id,
					getItemText: (option: TenantIsActiveResponse) => option.name
				},
				[propertyOf<TicketResponseGetAll>('effort')]: {
					title: 'Estimated effort',
					fieldType: FieldTypeEnum.None,
					formatter: (cell: any) => {
						const ticket = cell.getData() as TicketResponse;
						if (!ticket.effort || !ticket.effortUnit) {
							return '';
						}

						let effort = ticket.effort || 0;
						if (ticket.effortUnit === TimeMeasureEnum.Hours) {
							effort = effort / 60;
						}

						if (ticket.effortUnit === TimeMeasureEnum.Days) {
							effort = effort / 60 / 24;
						}
						return `${effort} ${ticket.effortUnit}`;
					},
				},
				[propertyOf<TicketResponseGetAll>('timesheets')]: {
					title: 'Total effort',
					fieldType: FieldTypeEnum.None,
					formatter: (cell: any) => {
						const ticket = cell.getData() as TicketResponse;
						if (!ticket.timesheets) {
							return '';
						}

						const totalEffort = ticket.timesheets.reduce((acc, cv) => acc + cv.effort, 0)
						const { hours, minutes } = convertTicksToTime(totalEffort);

						return totalEffort > 0 ? `${hours}h ${minutes}min` : '';
					},
				},
				[propertyOf<TicketResponseGetAll>('acceptedOn')]: {
					title: 'Accepted on',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TicketResponseGetAll>('resolvedOn')]: {
					title: 'Resolved on',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TicketResponseGetAll>('closedByUserId')]: {
					title: 'Closed by',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => `${option.firstName} ${option.lastName}`
				},
				[propertyOf<TicketResponseGetAll>('closingNote')]: {
					title: 'Closing note',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<TicketResponseGetAll>('workaround')]: {
					title: 'Workaround',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<TicketResponseGetAll>('testCycleNames')]: {
					title: 'Test cycles',
					formatter: (cell: any) => cell.getValue().join(','),
					fieldType: FieldTypeEnum.None
				},
				[propertyOf<TicketResponseGetAll>('expectedResolutionDate')]: {
					title: 'Expected resolution',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<TicketResponseGetAll>('changeRequestIds')]: {
					title: 'CR IDs',
					formatter: (cell: any) => {
						const value = cell.getValue()
						if (!value) {
							return ''
						}

						return value.map(x => getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, x)).join(', ')
					},
					fieldType: FieldTypeEnum.None,
				},
			} as TableColumnsType
		},
		[
			persistedTicketAssignedGroup,
			persistedTicketCategory,
			persistedTicketStatus,
			persistedTicketType,
			persistedTicketEnvironment,
			persistedTicketUrgency,
			persistedTicketImpact,
			persistedTicketClosingId,
			persistedUser,
			schedules,
			schedulesMap,
			getUserFullName,
			getType,
			getEnvironment,
			getCategory,
			getStatus,
			getImpact,
			getUrgency,
			getAssignedGroup,
			getClosingIdName,
			applyStatusColor,
			formatProjectIdName,
			projects
		]
	)
}

export const useTableColumnsMemo = (configureViewKey: string, schedules: SimpleScheduleResponse[], filtersModel?: GenericFilterModelCollection) => {
	const { persistedConfigureView } = useSelector((state: RootState) => state)
	const visibleColumns = persistedConfigureView.value[configureViewKey] || defaultVisibleColumns;
	const columns = useColumnsObjectMemo(schedules, filtersModel);

	return useVisibleColumns(columns, visibleColumns);
}
