import { CrudSubRoutesEnum } from 'features/Crud';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ExpenseItemResponse } from 'services/tenantManagementService';
import CreateExpenseItem from './Crud/CreateExpenseItem';
import ReadExpenseItem from './Crud/ReadExpenseItem';
import UpdateExpenseItem from './Crud/UpdateExpenseItem';

export enum ExpenseSubRoutes {
	ExpenseItem = 'expense_item'
}

export const ExpenseItemParamName = 'expenseItemId';

type Props = {
	expenseItem?: ExpenseItemResponse
	onAdd: (expenseItem: ExpenseItemResponse) => void
	onUpdate: (expenseItem: ExpenseItemResponse) => void
}

const ExpenseItemsCrudRouter = ({ expenseItem, onAdd, onUpdate }: Props) => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${ExpenseSubRoutes.ExpenseItem}/${CrudSubRoutesEnum.Update}/:${ExpenseItemParamName}`}
				render={() => (
					<UpdateExpenseItem
						expenseItem={expenseItem!}
						onSave={onUpdate}
					/>
				)}
			/>
			<Route
				path={`${path}/${ExpenseSubRoutes.ExpenseItem}/${CrudSubRoutesEnum.Read}/:${ExpenseItemParamName}`}
				render={() => (
					<ReadExpenseItem
						expenseItem={expenseItem!}
					/>
				)}
			/>
			<Route
				path={`${path}/${ExpenseSubRoutes.ExpenseItem}/${CrudSubRoutesEnum.Create}`}
				render={() => (
					<CreateExpenseItem
						onSave={onAdd}
					/>
				)}
			/>
		</Switch>
	)
}

export default ExpenseItemsCrudRouter;
