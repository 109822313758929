import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useSelector } from "react-redux";
import { RootState } from "base/reducer/reducer";
import { ThemeEnum } from "features/Theme";
import { FieldType } from "./ColumnSeriesChart";
import { createLegend } from "./helpers/legend";

export type DivergentChartProps = {
	id: string
	data: any[]
	fieldValues: FieldType[]
	fieldCategory: string
	stacked?: boolean
}

export const DivergentChart = ({ id, data, fieldValues, fieldCategory, stacked = true }: DivergentChartProps) => {
	const theme = useSelector((state: RootState) => state.theme);

	let chart = am4core.create(id, am4charts.XYChart);
	chart.data = data;

	let cellSize = 26;
	chart.events.on("datavalidated", function(ev) {
		// Get objects of interest
		let chart = ev.target;
		let categoryAxis = chart.yAxes.getIndex(0)!;

		// Calculate how we need to adjust chart height
		let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

		// get current chart height
		let targetHeight = chart.pixelHeight + adjustHeight;

		// Set it on chart's container
		chart.svgContainer!.htmlElement.style.height = targetHeight + "px";
	});

	// Create axes
	let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
	categoryAxis.dataFields.category = fieldCategory;
	categoryAxis.renderer.labels.template.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
	categoryAxis.renderer.grid.template.location = 0;
	categoryAxis.renderer.inversed = true;
	categoryAxis.renderer.fixedWidthGrid = true;
	categoryAxis.renderer.axisFills.template.disabled = false;
	categoryAxis.renderer.axisFills.template.fillOpacity = 0.05;
	categoryAxis.renderer.axisFills.template.paddingBottom = 2;
	categoryAxis.renderer.minGridDistance = 20;
	categoryAxis.fontSize = 11;

	let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
	valueAxis.renderer.minGridDistance = 50;
	valueAxis.renderer.ticks.template.length = 5;
	valueAxis.renderer.ticks.template.disabled = false;
	valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
	valueAxis.renderer.labels.template.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
	valueAxis.fontSize = 11;
	// valueAxis.renderer.labels.template.adapter.add("text", function(text) {
	//   return text + "%";
	// })

	chart.legend = createLegend(theme);
	chart.legend.reverseOrder = true;

	// Use only absolute numbers
	chart.numberFormatter.numberFormat = "#.#s";

	// Create series
	function createSeries(field: FieldType) {
		let series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueX = field.value;
		series.dataFields.categoryY = fieldCategory;
		series.stacked = stacked;
		series.name = field.name;
		series.stroke = am4core.color(field.color);
		series.fill = am4core.color(field.color);
		series.columns.template.height = 18;

		let label = series.bullets.push(new am4charts.LabelBullet());
		label.label.text = "{valueX}";
		label.label.fontSize = 11;
		label.label.fill = am4core.color("#fff");
		label.label.strokeWidth = 0;
		label.label.truncate = false;
		label.label.hideOversized = true;
		label.locationX = 0.5;

		series.tooltipText = "{name}: [bold]{valueX}[/]";

		return series;
	}

	fieldValues.forEach(createSeries);

	chart.cursor = new am4charts.XYCursor();

	return <div id={id} style={{ flex: 1 }} />
}
