import { RowContainer } from 'components/Layout';
import ConfigureView from 'features/ConfigureView';
import Button from 'components/Button';
import { BaseColumnModel } from 'components/Table';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection } from 'services/tenantManagementService';
import { Export } from 'features/Export/Export';

type Props = {
	onSave: () => void
	disableSave?: boolean;
	configureViewKey: string
    tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
};

const TableButtons = ({
	onSave,
	disableSave,
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction
}: Props) => {
	return (
		<div>
			<RowContainer justifyContent='space-between'>
				<RowContainer>
					<Button
						text='Save'
						onClick={onSave}
						disabled={disableSave}
					/>
				</RowContainer>
				<RowContainer>
					<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
					<Export
						filtersModel={filtersModel}
						tableColumns={tableColumns}
						exportAction={exportFunction}
						title='My training plans'
						exportTypeOptions={[ExportDataFileTypeEnum.XLSX, ExportDataFileTypeEnum.PDF]}
					/>
				</RowContainer>
			</RowContainer>
		</div>
	);
};

export default TableButtons;
