import { useCallback, useState } from 'react';
import { ProjectStatusLegend } from './ProjectStatusLegend';
import { CheckboxField, Form, InputField, TableField, ColorField } from 'components/Form';
import { ProjectReportAreaRequest, ProjectReportAreaResponse, ProjectReportResponse, ReportStatusAreaEnum, UpdateProjectReportRequest } from 'services/tenantManagementService'
import { CrudEnum } from 'features/Crud';
import { updateReportAction } from '../../action';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { publishReportAreasChanged } from '../../reportAreasChanged';
import { useParams } from 'react-router-dom';
import { ProjectPickerParams } from 'features/Project';
import styles from './projectStatus.module.scss';

const colors = Object.values(ReportStatusAreaEnum);

type Props = {
	report: ProjectReportResponse
	setReport?(report: ProjectReportResponse): void
	reportCrud: CrudEnum
}

export const ProjectStatus = ({ report, setReport, reportCrud }: Props) => {
	const [values, setValues] = useState(report || new ProjectReportResponse());
	const params: ProjectPickerParams = useParams();
    const projectId = parseInt(params.projectId as string);

	const onSubmitCallback = useCallback(
		async () => {
			const areaStatuses = values.areaStatuses.map(status => new ProjectReportAreaRequest(status));

			const model = new UpdateProjectReportRequest({
				...report,
				areaStatuses
			});

			const bindedAction = updateReportAction.bind(null, projectId, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Project status areas are updated.');
				publishReportAreasChanged();
				setReport && setReport(response.value || new ProjectReportResponse())
			} else {
				return convertResponseErrors(response);
			}
		},
		[projectId, values.areaStatuses, report, setReport]
	);

	const resetCallback = useCallback(
		() => {
			const newReport = new ProjectReportResponse(report);
			newReport.areaStatuses = report.areaStatuses;
			setValues(newReport);
		},
		[report]
	)

	const descriptionValidator = useCallback(
		(value: string, values: ProjectReportAreaResponse, id: string) => {
			if (values.status === ReportStatusAreaEnum.Green) {
				return '';
			}

			if (value) {
				return '';
			}

			return 'This field is required because of status'
		},
		[]
	)

	const renderForm = useCallback(
		() => (
			<TableField
				id='areaStatuses'
				headers={[
					{ label: 'Include', size: 1 },
					{ label: 'Project status areas', size: 4 },
					{ label: 'Status', size: 2 },
					{ label: 'Comment (Please briefly comment if current status (Y) or (R))', size: 6 }
				]}
				getRowData={() => {
					return {
						isDeletable: false,
						fields: [
							<CheckboxField
								id='isActive'
							/>,
							<InputField
								id='name'
								disabled
								isRequired
								maxLength={25}
							/>,
							<ColorField
								id='status'
								colors={colors}
							/>,
							<InputField
								id='description'
								validator={descriptionValidator}
								maxLength={240}
							/>
						]
					}
				}}
			/>
		),
		[descriptionValidator]
	)

	return (
		<>
			<h2>Project Status</h2>
			<div className={styles.container}>
				<div className={styles.content}>
					<Form
						values={values}
						initialValues={report}
						onChange={setValues}
						onSubmit={onSubmitCallback}
						disabled={reportCrud === CrudEnum.Read}
						hideButtons={reportCrud === CrudEnum.Read}
						onCancel={resetCallback}
						cancelButtonText='Reset'
						render={renderForm}
					/>
				</div>
				<div className={styles.legend}>
					<ProjectStatusLegend />
				</div>
			</div>
		</>
	)
}
