import { TabType } from 'components/Tabs/Tabs';
import { Map } from './Map/Map';
import ListTable from './List';
import { ComponentProps } from 'features/Project';

export type MaintainScopeTabComponentProps = ComponentProps

export const maintainScopeTabs: TabType[] = [
	{
		id: '1',
		title: 'Scope',
		route: 'map_scope',
		component: Map,
	},
	{
		id: '2',
		title: 'Scope list',
		route: 'list_scope',
		component: ListTable,
	}
];
