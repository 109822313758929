import { useState, useCallback } from 'react';
import FileSaver from 'file-saver';
import { ExportDataFileTypeEnum, ExportDataModel, ExportDataPropertyModel, FileResponse, MonthEnum } from 'services/tenantManagementService';
import ExportToFile from './ExportToFile';
import { tryCatchExportFileByAction } from 'utils/exportUtils';
import { BaseColumnModel } from 'components/Table';

type Props = {
	tableColumns: BaseColumnModel[]
	exportAction(nonProjectCategoryId: number | undefined, projectId: number | undefined, years: number[] | undefined, months: MonthEnum[] | undefined, exportModel: ExportDataModel): Promise<FileResponse>
	title: string
	exportTypeOptions?: ExportDataFileTypeEnum[]
}

export const Export = ({
	tableColumns,
	exportAction,
	title,
	exportTypeOptions,
}: Props) => {
	const [loadingExport, setLoadingExport] = useState(false);

	const exportCallback = useCallback(
		async (nonProjectCategoryId: number | undefined, projectId: number | undefined, years: number[] | undefined, months: MonthEnum[] | undefined, exportType: ExportDataFileTypeEnum) => {
			const exportDataModel = new ExportDataModel();
			exportDataModel.fileType = exportType;
			let columnsForExport = tableColumns.filter(col => col.dbExportFieldPath);
			exportDataModel.properties = columnsForExport.map(column =>
				new ExportDataPropertyModel({
					name: column.dbExportFieldPath!,
					displayName: column.title,
					format: column.format,
					// formatMap: column.formatMap,
					// formats: column.formats,
					displayNames: column.displayNames,
					decoraterProperties: column.decoraterProperties
				})
			)

			// because this is not represented in table
			exportDataModel.properties.push(new ExportDataPropertyModel({
				name: 'Date',
				displayName: 'Date',
				format: '{0:MM/dd/yyyy}',
			}))

			exportDataModel.title = title;

			setLoadingExport(true);

			const action = () => exportAction(nonProjectCategoryId, projectId, years, months, exportDataModel);
			const response = await tryCatchExportFileByAction(action);
			if (response.success && response.value) {
				FileSaver.saveAs(response.value.data, response.value.fileName);
			}

			setLoadingExport(false);
		},
		[tableColumns, exportAction, title]
	)

	return (
		<ExportToFile
			onExport={exportCallback}
			loading={loadingExport}
			exportTypeOptions={exportTypeOptions}
		/>
	)
}
