import Subtitle from '../_components/Subtitle';

type Props = {
	userName: string
}

const SuccessMessage = ({ userName }: Props ) => {
	return (
		<>
			<Subtitle text={`Hi ${userName},`} />
			<Subtitle text='Thanks so much for joining Mavles! To finish signing up, you need to confirm that we got your email right.' />
		</>
	);
};

export default SuccessMessage;
