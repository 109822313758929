import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoCompleteField, Form, MultiSelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { getScopeItemsAction } from 'containers/Scope/MaintainScope/action';
import { ModuleActivityEnum, ScopeItemLevelResponse, TenantIsActiveResponse, TestingPermission, UserModel } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTestCyclesAction } from '../TestCycles/action';
import { RootState } from 'base/reducer/reducer';
import { propertyOf } from 'utils/propertyOf';
import { FilterModel } from './FilterModel';
import Button from 'components/Button';
import { ProjectSelectField, pmOrSpmOrOumPermission } from 'features/Project';

const Level2 = 2
const Level1 = 1

type Props = {
	onSubmit: (model: FilterModel) => Promise<void>
	filterFormModel?: FilterModel
	onSave: (filterFormModel: FilterModel) => Promise<void>
}

const FilterForm = ({ onSubmit, filterFormModel, onSave }: Props) => {
	const { persistedUser } = useSelector((state: RootState) => state);

	const [values, setValues] = useState(new FilterModel(filterFormModel));

	useEffect(
		() => {
			setValues(new FilterModel(filterFormModel));
		},
		[filterFormModel]
	)

	const [testCycles, setTestCycles] = useState<TenantIsActiveResponse[]>([]);
	const [isFetchingTestCycles, setIsFetchingTestCycles] = useState(false);

	const [level1Items, setLevel1Items] = useState<ScopeItemLevelResponse[]>([]);
	const [fetchingLevel1Items, setFetchingLevel1Items] = useState(false);

	const [level2Items, setLevel2Items] = useState<ScopeItemLevelResponse[]>([]);
	const [fetchingLevel2Items, setFetchingLevel2Items] = useState(false);

	const fetchTestCyclesDataCallback = useCallback(
		async () => {
			if (!values.projectId) {
				setTestCycles([]);
				return;
			}
			setIsFetchingTestCycles(true);

			const bindedAction = getTestCyclesAction.bind(null, values.projectId);
			const testCyclesResponse = await tryCatchJsonByAction(bindedAction);

			setIsFetchingTestCycles(false);

			if (testCyclesResponse.success && testCyclesResponse.items) {
				const activeItems = testCyclesResponse.items.filter(tc => tc.isActive);
				setTestCycles(activeItems);
			}
		},
		[values.projectId]
	)

	useEffect(
		() => {
			fetchTestCyclesDataCallback();
		},
		[fetchTestCyclesDataCallback]
	)

	const fetchLevel2ItemsCallback = useCallback(
		async () => {
			const selectedLevel3Ids = values.level1Ids;
			if (!selectedLevel3Ids || selectedLevel3Ids.length === 0) {
				setLevel2Items([]);
				return;
			}
			let mergedLevel2Items: ScopeItemLevelResponse[] = [];
			for(let i = 0; i < selectedLevel3Ids.length; i++) {
				const bindedAction = getScopeItemsAction.bind(null, values.projectId, selectedLevel3Ids[i], Level2);
				const response = await tryCatchJsonByAction(bindedAction);

				if (response.success) {
					mergedLevel2Items = [...mergedLevel2Items, ...(response.items || [])]
				}
			}
			setLevel2Items(mergedLevel2Items)
		},
		[values.projectId, values.level1Ids]
	)

	const refetchLevel2ItemsCallback = useCallback(
		async () => {
			setFetchingLevel2Items(true);
			await fetchLevel2ItemsCallback();
			setFetchingLevel2Items(false);
		},
		[fetchLevel2ItemsCallback]
	)

	useEffect(
		() => {
			refetchLevel2ItemsCallback();
		},
		[refetchLevel2ItemsCallback]
	)

	const fetchLevel1ItemsCallback = useCallback(
		async () => {
			if (!values.projectId) {
				return;
			}
			const bindedAction = getScopeItemsAction.bind(null, values.projectId, undefined, Level1);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setLevel1Items(response.items || []);
			}
		},
		[values.projectId]
	)

	const refetchLevel1ItemsCallback = useCallback(
		async () => {
			setFetchingLevel1Items(true);
			await fetchLevel1ItemsCallback();
			setFetchingLevel1Items(false);
		},
		[fetchLevel1ItemsCallback]
	)

	useEffect(
		() => {
			refetchLevel1ItemsCallback();
		},
		[refetchLevel1ItemsCallback]
	)

	const onProjectChangeCallback = useCallback(
		(_newProjectId: number | undefined, _oldValues: FilterModel) => ({
			[propertyOf<FilterModel>('testCycleId')]: undefined,
			[propertyOf<FilterModel>('level1Ids')]: undefined,
			[propertyOf<FilterModel>('level2Ids')]: undefined,
			[propertyOf<FilterModel>('processTesterIds')]: undefined,
			[propertyOf<FilterModel>('stepTesterIds')]: undefined
		}),
		[]
	)

	const onLevel1IdsChangeCallback = useCallback(
		(_newLevel1Ids: number[] | undefined, _oldValues: FilterModel) => ({
			[propertyOf<FilterModel>('level2Ids')]: undefined
		}),
		[]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const model = new FilterModel(values);
			await onSubmit(model)
		},
		[values, onSubmit]
	)

	const onSaveCallback = useCallback(
		async () => {
			await onSave(values)
		},
		[values, onSave]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<ProjectSelectField
							id={propertyOf<FilterModel>('projectId')}
							isRequired
							updateDependants={onProjectChangeCallback}
							isProjectDashboard
							moduleEnum={ModuleActivityEnum.Testing}
							teamMemberPermission={propertyOf<TestingPermission>('dashboard')}
							userRolePermission={pmOrSpmOrOumPermission}
						/>
						<AutoCompleteField
							id={propertyOf<FilterModel>('testCycleId')}
							label='Test cycle'
							items={testCycles}
							isRequired
							getItemId={(item: TenantIsActiveResponse) => item.id}
							getItemText={(item: TenantIsActiveResponse) => item.name}
							loading={isFetchingTestCycles}
							disabled={!values.projectId}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('level1Ids')}
							label='Level 1 Id'
							items={level1Items}
							getItemId={(item: ScopeItemLevelResponse) => item.id}
							getItemText={(item: ScopeItemLevelResponse) => `${item.name} - ${item.description}`}
							loading={fetchingLevel1Items}
							updateDependants={onLevel1IdsChangeCallback}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('level2Ids')}
							label='Level 2 Id'
							items={level2Items}
							disabled={!values.level1Ids || values.level1Ids.length === 0}
							getItemId={(item: ScopeItemLevelResponse) => item.id}
							getItemText={(item: ScopeItemLevelResponse) => `${item.name} - ${item.description}`}
							loading={fetchingLevel2Items}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('processTesterIds')}
							label='Process tester'
							items={persistedUser.items}
							getItemId={(item: UserModel) => item.id}
							getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
							loading={persistedUser.fetching}
						/>
						<MultiSelectField
							id={propertyOf<FilterModel>('stepTesterIds')}
							label='Step tester'
							items={persistedUser.items}
							getItemId={(item: UserModel) => item.id}
							getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
							loading={persistedUser.fetching}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			submitButtonText='Filter'
			hideCancelButton
			disableUnsavedChangesGuard
			renderAdditionalButtons={() => (
				<Button
					text='Save filter'
					onClick={onSaveCallback}
					disabled={!values.projectId || !values.testCycleId}
				/>
			)}
		/>
	)
}

export default FilterForm;
