import { ButtonGroup } from "components/ButtonGroup"
import { useCallback, useState, useMemo } from "react"
import { TicketResponse } from "services/tenantManagementService"
import { tryCatchJsonByAction } from "utils/fetchUtils"
import { followAction } from "./action"

const followId = 'follow';

const followItems = [
	{ id: followId, text: 'Followed' }
]

const unfollowItems = [
	{ id: followId, text: 'Unfollowed' }
]

type Props = {
	ticket: TicketResponse
}

export const Follow = ({ ticket }: Props) => {
	const [loading, setLoading] = useState(false);
	const [selectedIds, setSelectedIds] = useState<any[] | undefined>();
	const [items, setItems] = useState<any[] | undefined>();

	const selectedIdsMemo = useMemo(
		() => selectedIds !== undefined ? selectedIds : (ticket.followTicket ? [followId] : []),
		[selectedIds, ticket.followTicket],
	)

	const itemsMemo = useMemo(
		() => items !== undefined ? items : (ticket.followTicket ? followItems : unfollowItems),
		[items, ticket.followTicket],
	)

	const onChangeCallback = useCallback(
		async (ids: string[]) => {
			setLoading(true);

			const follow = ids.includes(followId);
			const bindedAction = followAction.bind(null, ticket.id, follow);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setSelectedIds(follow ? [followId] : []);
				setItems(follow ? followItems : unfollowItems)
			}

			setLoading(false);
		},
		[ticket.id]
	)

	return (
		<ButtonGroup
			items={itemsMemo}
			selectedIds={selectedIdsMemo}
			onChange={onChangeCallback}
			isLoading={loading || !ticket.id}
			color='primary'
		/>
	)
}
