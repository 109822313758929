import { getTenantId } from 'features/Tenant/tenantUtils';
import { InsertSurveyQuestionRequest, SurveyQuestionClient, UpdateSurveyQuestionRequest } from 'services/tenantManagementService';

export const getSurveyQuestionsAction = (projectId: number, surveyId: number) => {
	const tenantId = getTenantId()!;
	const client = new SurveyQuestionClient();
	return client.getAll(tenantId, surveyId, undefined, undefined, projectId?.toString());
}

export const getSurveyQuestionAction = (projectId: number, surveyId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new SurveyQuestionClient();
	return client.get(tenantId, surveyId, id, projectId?.toString());
}

export const createSurveyQuestionAction = (projectId: number, surveyId: number, insertQuestion: InsertSurveyQuestionRequest) => {
	const tenantId = getTenantId()!;
	const client = new SurveyQuestionClient();
	return client.create(tenantId, surveyId, projectId?.toString(), insertQuestion);
}

export const updateSurveyQuestionAction = (projectId: number, surveyId: number, updateQuestion: UpdateSurveyQuestionRequest) => {
	const tenantId = getTenantId()!;
	const client = new SurveyQuestionClient();
	return client.update(tenantId, surveyId, projectId?.toString(), updateQuestion);
}

export const deleteSurveyQuestionAction = (projectId: number, surveyId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new SurveyQuestionClient();
	return client.delete(tenantId, surveyId, id, projectId?.toString());
}
