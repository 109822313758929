import { useCallback, useEffect, useState } from 'react'
import { ScopeItemLevelResponse, ScopeProcessesDashboardFilterRequest, ScopeProcessesDashboardResponse } from 'services/tenantManagementService'
import FilteredDashboard from './FilteredDashboard'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getProcessesDashboardAction } from './action'
import { FilterForm, FilterModel } from './FilterForm'
import { getScopeItemsAction } from 'containers/Scope/MaintainScope/action'
import { DashboardContainer } from 'features/Dashboard/DashboardContainer/DashboardContainer'
import { useSaveFilterMemo } from 'features/Dashboard/SaveFilter/useSaveFilterMemo'
import WithFetch from 'features/Fetch/WithFetch'

const viewType = 'scope_processes_dashboard'

export const ProcessesDashboard = () => {
	const [loading, setLoading] = useState(false);
	const [dashboard, setDashboard] = useState(new ScopeProcessesDashboardResponse())
	const { savedFilterModel, saveFilterModel, fetchFilterModel, isLoadingFilter } = useSaveFilterMemo<FilterModel>(viewType);

	const [isFetchingLevel1, setIsFetchingLevel1] = useState(false);
	const [level1Items, setLevel1Items] = useState<ScopeItemLevelResponse[]>([]);

	const fetchLevel1ItemsCallback = useCallback(
		async (projectId: number) => {
			if (!projectId) {
				setLevel1Items([]);
				return;
			}
			setIsFetchingLevel1(true);

			const bindedAction = getScopeItemsAction.bind(null, projectId, undefined, 1);
			const response = await tryCatchJsonByAction(bindedAction);

			setIsFetchingLevel1(false);

			if (response.success && response.items) {
				setLevel1Items(response.items);
			}
		},
		[]
	)

	const fetchDashboardCallback = useCallback(
		async (model: FilterModel) => {
			setLoading(true);

			const bindedAction = getProcessesDashboardAction.bind(null, model.projectId, new ScopeProcessesDashboardFilterRequest(model))
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success && response.value) {
				setDashboard(response.value);
			}

			setLoading(false);
		},
		[]
	)

	useEffect(
		() => {
			if (savedFilterModel) {
				fetchDashboardCallback(savedFilterModel);
			}
		},
		[savedFilterModel, fetchDashboardCallback]
	)

	return (
		<WithFetch fetchFunction={fetchFilterModel} refetching={isLoadingFilter}>
			<DashboardContainer
				form={
					<FilterForm
						onSubmit={fetchDashboardCallback}
						isFetchingLevel1={isFetchingLevel1}
						level1Items={level1Items}
						fetchLevel1ItemsCallback={fetchLevel1ItemsCallback}
						filterFormModel={savedFilterModel}
						onSave={saveFilterModel}
					/>
				}
				dashboard={
					<FilteredDashboard
						loading={loading || isFetchingLevel1}
						dashboard={dashboard}
						level1Items={level1Items}
					/>
				}
			/>
		</WithFetch>
	)
}
