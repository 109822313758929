import { ProjectIssueClient, InsertProjectIssueRequest, UpdateProjectIssueRequest, GenericFilterModelCollection, ExportDataModel } from "services/tenantManagementService";
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getIssuesAction = (reportId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectIssueClient();
	return client.getAll(tenantId, reportId, undefined, undefined, undefined);
}

export const getIssuesGenericAction = (reportId: number, genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectIssueClient();
	return client.getAllGenericFilter(tenantId, reportId, genericFilter);
}

export const createIssueAction = (reportId: number, model: InsertProjectIssueRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectIssueClient();
	return client.create(tenantId, reportId, model);
}

export const updateIssueAction = (reportId: number, model: UpdateProjectIssueRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectIssueClient();
	return client.update(tenantId, reportId, model);
}

export const getIssueAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectIssueClient();
	return client.get(tenantId, reportId, id);
}

export const deleteIssueAction = (reportId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectIssueClient();
	return client.delete(tenantId, reportId, id);
}

export const exportAction = (reportId: number, exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectIssueClient();
	return client.export(tenantId, reportId, exportDataModel);
}
