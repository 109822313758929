import { useMemo } from 'react';
import { useApplyStatusColorCallback, useStatusCallback, useUserFullNameCallback } from 'features/TableColumns/persistedHooks';
import { ChangeRequestStatusResponse, UserModel, ProjectChangeRequestResponse, ChangeRequestPriorityEnum,
	ExportDataDecoraterPropertyModel, ProjectChangeRequestCountsResponse
} from 'services/tenantManagementService';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { useVisibleColumns, TableColumnsType, FieldTypeEnum } from 'components/Table';
import { priorities } from '../ChangeRequestForm';
import { propertyOf } from 'utils/propertyOf';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';

const defaultVisibleColumns = [
	propertyOf<ProjectChangeRequestResponse>('id'),
	propertyOf<ProjectChangeRequestResponse>('name'),
	propertyOf<ProjectChangeRequestResponse>('initiatedBy'),
	propertyOf<ProjectChangeRequestResponse>('requestedOn'),
	propertyOf<ProjectChangeRequestResponse>('goLiveCritical'),
	propertyOf<ProjectChangeRequestResponse>('priority'),
	propertyOf<ProjectChangeRequestResponse>('statusId'),
	propertyOf<ProjectChangeRequestResponse>('approvedByUserId'),
	propertyOf<ProjectChangeRequestResponse>('effortInHours'),
	propertyOf<ProjectChangeRequestResponse>('totalCost')
]

export const useTableColumnsMemo = (
	countsResponse: ProjectChangeRequestCountsResponse,
	visibleColumns: string[] = defaultVisibleColumns
) => {
	const {
		persistedUser,
		persistedChangeRequestStatus
	} = useSelector((state: RootState) => state);

	const getUserFullName = useUserFullNameCallback(persistedUser);
	const getStatus = useStatusCallback(persistedChangeRequestStatus);
	const applyStatusColor = useApplyStatusColorCallback(persistedChangeRequestStatus);

	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectChangeRequestResponse>('visibleId')]: {
					title: 'CR ID',
					fieldType: FieldTypeEnum.FormattedReference,
					entityPrefix: EntityPrefixEnum.CHANGE_REQUEST
				},
				[propertyOf<ProjectChangeRequestResponse>('name')]: {
					title: 'CR Name',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectChangeRequestResponse>('initiatedBy')]: {
					title: 'Initiated By',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectChangeRequestResponse>('requestedOn')]: {
					title: 'Requested On',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectChangeRequestResponse>('goLiveCritical')]: {
					title: 'Go-live critical',
					formatter: (cell: any) => {
						const goLiveCritical: boolean = cell.getValue();
						return goLiveCritical ? 'yes' : 'no';
					},
					fieldType: FieldTypeEnum.Boolean,
				},
				[propertyOf<ProjectChangeRequestResponse>('priority')]: {
					title: 'Priority',
					fieldType: FieldTypeEnum.Options,
					options: priorities,
					getItemId: (option: ChangeRequestPriorityEnum) => option.valueOf(),
					getItemText: (option: ChangeRequestPriorityEnum) => option
				},
				[propertyOf<ProjectChangeRequestResponse>('completitionOn')]: {
					title: 'Expected completion on',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectChangeRequestResponse>('statusId')]: {
					title: 'Status',
					formatter: (cell: any) => {
						const id = cell.getValue();
						applyStatusColor(id, cell.getElement());
						return getStatus(id);
					},
					fieldType: FieldTypeEnum.Options,
					options: persistedChangeRequestStatus.items,
					getItemId: (option: ChangeRequestStatusResponse) => option.id,
					getItemText: (option: ChangeRequestStatusResponse) => option.name,
					dbFilterFieldPath: 'StatusRefId',
					dbExportFieldPath: 'Status.Name',
					displayNames: ['Status'],
					decoraterProperties : [
						new ExportDataDecoraterPropertyModel({
							name: 'Name',
						})
					]
				},
				[propertyOf<ProjectChangeRequestResponse>('approvedByUserId')]: {
					title: 'Approved by',
					formatter: (cell: any) => getUserFullName(cell.getValue()),
					fieldType: FieldTypeEnum.Options,
					options: persistedUser.items,
					getItemId: (option: UserModel) => option.id,
					getItemText: (option: UserModel) => getUserFullName(option.id)
				},
				[propertyOf<ProjectChangeRequestResponse>('effortInHours')]: {
					title: 'Effort (in hours)',
					fieldType: FieldTypeEnum.Number,
					bottomCalc: () => countsResponse.totalEffortSum,
					bottomCalcFormatter: (cell: any) => `∑ ${cell.getValue() || ''}`
				},
				[propertyOf<ProjectChangeRequestResponse>('totalCost')]: {
					title: 'Total cost',
					fieldType: FieldTypeEnum.Currency,
					bottomCalc: () => countsResponse.totalCostSum
				},
			}
		},
		[persistedUser, getUserFullName, getStatus, applyStatusColor, persistedChangeRequestStatus.items, countsResponse]
	)

	return useVisibleColumns(columns, visibleColumns);
}
