import { useCallback, useState } from 'react';
import Button from 'components/Button';
import ExpenseDocument from './Document/ExpenseDocument';
import { ExpenseResponse } from 'services/tenantManagementService';
import { saveLocalDocumentToPDF } from 'utils/reactPDFUtils';
import notifications from 'components/Notification/notification';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { downloadAttachmentAction } from '../action';

type Props = {
	expense: ExpenseResponse
}

const Export = ({ expense }: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const {
		persistedCompanyInfo,
		persistedTimeTravelNonProjectCategories,
		persistedTimeAndTravelStatus,
		persistedUser,
		persistedProject,
		persistedTenant,
		persistedCurrency,
		persistedTimeTravelExpenseTypes,
	} = useSelector((state: RootState) => state);

	const downloadPDFCallback = useCallback(
		async () => {
			setIsLoading(true);

			const attachments = await Promise.all(
				expense.expenseItems?.map(
					item => item.attachments?.map(
						async (attachment) => await downloadAttachmentAction(item.id, attachment.id),
					) ?? [])
				.flat() ?? []
			)

			const error = await saveLocalDocumentToPDF(
				`Expense_${getFormatedId(EntityPrefixEnum.TIME_TRAVEL_EXPENSE, expense.id)}`,
				<ExpenseDocument
					expense={expense}
					persistedUser={persistedUser}
					persistedProject={persistedProject}
					persistedTimeAndTravelStatus={persistedTimeAndTravelStatus}
					persistedTimeTravelNonProjectCategories={persistedTimeTravelNonProjectCategories}
					persistedTenant={persistedTenant}
					persistedCurrency={persistedCurrency}
					persistedTimeTravelExpenseTypes={persistedTimeTravelExpenseTypes}
					attachments={attachments}
					base64Logo={persistedCompanyInfo.value?.logoInPDF}
				/>
			);

			error && notifications.error(error);

			setIsLoading(false);
		},
		[
			expense,
			persistedUser,
			persistedProject,
			persistedTimeAndTravelStatus,
			persistedTimeTravelNonProjectCategories,
			persistedCompanyInfo,
			persistedTenant,
			persistedCurrency,
			persistedTimeTravelExpenseTypes,
		]
	)

	return (
		<Button
			text='Export to PDF'
			color='secondary'
			onClick={downloadPDFCallback}
			isLoading={isLoading}
		/>
	)
}

export default Export;
