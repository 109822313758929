import { RotatedColumnSeriesChart, RotatedColumnSeriesChartProps } from "features/Charts"
import { Widget, WidgetProps } from "./Widget"

type Props = WidgetProps & RotatedColumnSeriesChartProps;

export const RotatedColumnSeriesWidget = ({ ...props }: Props) => {
	return (
		<Widget
			{...props}
		>
			<RotatedColumnSeriesChart {...props} />
		</Widget>
	)
}
