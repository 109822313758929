import { ColumnContainer } from 'components/Layout'
import { defaultCostRateVisibleColumns, defaultSaleRateVisibleColumns, useTableColumnsMemo } from './tableColumns'
import { ProjectTimeTravelDashboardFilterRequest, TimesheetDashboardResponse } from 'services/tenantManagementService'
import TableButtons from './TableButtons'
import { useCallback, useMemo, useState } from 'react'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { exportTimesheetDashboardAction, getShowTimesheetCostRatesConfigAction, updateTimesheetCostRatesConfigAction } from './action'
import WithFetch from 'features/Fetch/WithFetch'
import { setConfigureViewTableAction } from 'features/ConfigureView'
import { LocalTable } from 'components/Table'

type Props = {
	data: TimesheetDashboardResponse[]
	filterModel: ProjectTimeTravelDashboardFilterRequest
}

const configureViewKey = 'timesheets_dashboard_table';

export const TimesheetsDashboardTable = ({ data, filterModel }: Props) => {
	const [showTimesheetCostRates, setShowTimesheetCostRates] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState(false);

	const tableColumns = useTableColumnsMemo(configureViewKey, showTimesheetCostRates);

	const fetchShowTimesheetCostRatesCallback = useCallback(
		async () => {
			setIsLoading(true);
			const showPTimesheetCostRatesResponse = await tryCatchJsonByAction(getShowTimesheetCostRatesConfigAction);
			setIsLoading(false);
			if (showPTimesheetCostRatesResponse.success && showPTimesheetCostRatesResponse.value) {
				setShowTimesheetCostRates(showPTimesheetCostRatesResponse.value.content);
			} else {
				//default value
				setShowTimesheetCostRates(true);
			}
		},
		[]
	)

	const showTimesheetCostRatesChangedCallback = useCallback(
		async (newValue: boolean) => {
			setIsLoading(true);

			const costRateColumns = defaultCostRateVisibleColumns.map(x => x.toString());
			const saleRateColumns = defaultSaleRateVisibleColumns.map(x => x.toString());

			// change configure view for table on checkbox change
			const visibleColumns = tableColumns.filter(column => column.visible);
			let columnFields: string[] = visibleColumns.map(column => column.field);
			columnFields = columnFields.filter(cf => !costRateColumns.includes(cf) && !saleRateColumns.includes(cf));

			if (newValue) {
				columnFields = [...columnFields, ...costRateColumns]
			} else {
				columnFields = [...columnFields, ...saleRateColumns]
			}

			setConfigureViewTableAction(configureViewKey, columnFields);

			// update show process steps configuration
			setShowTimesheetCostRates(newValue);
			const bindedAction = updateTimesheetCostRatesConfigAction.bind(null, newValue);

			await tryCatchJsonByAction(bindedAction);
			setIsLoading(false);
		},
		[tableColumns]
	)

	const memoExportFunction = useMemo(
		() => exportTimesheetDashboardAction.bind(null, filterModel),
		[filterModel]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => setConfigureViewTableAction(configureViewKey, newColumns),
		[]
	)

	return (
		<WithFetch fetchFunction={fetchShowTimesheetCostRatesCallback} refetching={isLoading}>
			<ColumnContainer margin='small'>
				<TableButtons
					tableColumns={tableColumns}
					configureViewKey={configureViewKey}
					showTimesheetCostRates={showTimesheetCostRates}
					isLoading={isLoading}
					onShowTimesheetCostRateChange={showTimesheetCostRatesChangedCallback}
					exportFunction={memoExportFunction}
				/>
				<LocalTable
					columns={tableColumns}
					data={data}
					hasPagination
					limit={10}
					reorderColumns={reorderColumnsCallback}
				/>
			</ColumnContainer>
		</WithFetch>
	)
}
