import { RowContainer } from 'components/Layout';
import Button from 'components/Button';
import ConfigureView from 'features/ConfigureView';
import { CrudButtons } from 'features/Crud';
import { ExportDataFileTypeEnum, ExportDataModel, FileResponse, GenericFilterModelCollection, PartialTaskRequestEnum } from 'services/tenantManagementService';
import { Export } from 'features/Export/Export';
import { BaseColumnModel } from 'components/Table';
import { ButtonGroup } from 'components/ButtonGroup';

const partialTaskItems = [
	{ id: PartialTaskRequestEnum.My, text: 'My tasks' },
	{ id: PartialTaskRequestEnum.Overdue, text: 'Overdue tasks' },
	{ id: PartialTaskRequestEnum.ShowCompleted, text: 'Closed tasks' }
]

type Props = {
	selectedId?: number
	onDelete(id: number): Promise<void>
	selectedPartialTaskItems: PartialTaskRequestEnum[]
	setSelectedPartialTaskItems(enums: PartialTaskRequestEnum[]): void
	onMyCalendarClick: () => void
	configureViewKey: string
	tableColumns: BaseColumnModel[]
	filtersModel: GenericFilterModelCollection
	exportFunction(model: ExportDataModel): Promise<FileResponse>
	disabledDelete: boolean
	disabledChange: boolean
}

const EXPORT_TYPE_OPTIONS = [ExportDataFileTypeEnum.PDF, ExportDataFileTypeEnum.XLSX];

export const TableButtons = ({
	selectedId,
	onDelete,
	selectedPartialTaskItems,
	setSelectedPartialTaskItems,
	onMyCalendarClick,
	configureViewKey,
	tableColumns,
	filtersModel,
	exportFunction,
	disabledDelete,
	disabledChange
}: Props) => {
	return (
		<RowContainer justifyContent='space-between'>
			<CrudButtons
				selectedId={selectedId}
				onDelete={onDelete}
				specificActionDisabled={{
					change: disabledChange,
					delete: disabledDelete
				}}
			/>
			<RowContainer>
				<Button
					onClick={onMyCalendarClick}
					text='My Calendar'
				/>
				<ButtonGroup
					items={partialTaskItems}
					onChange={setSelectedPartialTaskItems}
					selectedIds={selectedPartialTaskItems}
				/>
				<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
				<Export
					filtersModel={filtersModel}
					tableColumns={tableColumns}
					exportAction={exportFunction}
					exportTypeOptions={EXPORT_TYPE_OPTIONS}
					title='Tasks'
				/>
			</RowContainer>
		</RowContainer>
	)
}
