import { RootState } from 'base/reducer/reducer'
import { AutoCompleteField, CheckboxField, DateField, Form, InputField, MultiSelectField } from 'components/Form'
import { ColumnContainer, VerticalSeparator } from 'components/Layout'
import { SmartContainer, SmartFormGroup, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer'
import { getAllTeamMembersAction } from 'containers/Projects/MyProjects/MaintainProject/Tabs/AssignTeamMembers/action'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReminderResponse, RepeatIntervalEnum, ProjectIsActiveResponse, UserModel, ModuleActivityEnum } from 'services/tenantManagementService'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { propertyOf } from 'utils/propertyOf'
import { ProjectOrCategorySelect, ProjectOrCategoryType } from 'features/Project'

export const clearRepeat = (reminder: ReminderResponse) => {
	(reminder as any).repeatOnMonday = false;
	(reminder as any).repeatOnTuesday = false;
	(reminder as any).repeatOnWednesday = false;
	(reminder as any).repeatOnThursday = false;
	(reminder as any).repeatOnFriday = false;
	(reminder as any).repeatOnSaturday = false;
	(reminder as any).repeatOnSunday = false;
	return reminder;
}

export const repeatInterval: RepeatIntervalEnum[] = [
	RepeatIntervalEnum.Daily,
	RepeatIntervalEnum.Weekly,
	RepeatIntervalEnum.Monthly,
	RepeatIntervalEnum.Yearly
]

type Props = {
	reminder: ReminderResponse
	onSave?: (newReminder: ReminderResponse) => void
	cancel?(): void
}

export const ReminderForm = ({
	reminder,
	onSave,
	cancel
}: Props) => {
	const {
		persistedProjectTeam,
		persistedUser
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(reminder || new ReminderResponse());
	const [users, setUsers] = useState<UserModel[]>([]);
	const [fetchingUsers, setFetchingUsers] = useState(false);

	const [projectOrCategory, setProjectOrCategory] = useState<ProjectOrCategoryType | undefined>({
		projectOrCategoryId: values.projectOrCategoryId,
		isProjectConnected: values.isProjectConnected
	});

	const fetchUsersCallback = useCallback(
		async () => {
			if (!persistedUser.isInitialized) {
				return;
			}

			if (!values.isProjectConnected) {
				setUsers(persistedUser.items)
				return;
			}
			setFetchingUsers(true);

			const bindedAction = getAllTeamMembersAction.bind(null, values.projectOrCategoryId);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				const teamMembers = response.items || [];
				const activeTeamMembers = teamMembers.filter((item) => item.isActive);
				const activeTeamMemberUsers = activeTeamMembers.map((item) => persistedUser.itemsMap[item.userId]!)
				setUsers(activeTeamMemberUsers);
			}

			setFetchingUsers(false);
		},
		[values.projectOrCategoryId, values.isProjectConnected, persistedUser]
	)

	useEffect(
		() => {
			fetchUsersCallback();
		},
		[fetchUsersCallback]
	)

	const onIsPrivateChangeCallback = useCallback(
		(_isPrivate: boolean | undefined, _oldValue: ReminderResponse) => ({
			[propertyOf<ReminderResponse>('projectTeamId')]: undefined,
			[propertyOf<ReminderResponse>('recipientUserIds')]: undefined,
		}),
		[]
	)

	useEffect(
		() => {
			 if (!values.isRepeat) {
				setValues((state: ReminderResponse) => {
					const newState = new ReminderResponse({
						...state,
						repeatInterval: undefined as any
					})
					clearRepeat(newState);
					return newState;
				})
			}
		},
		[values.isPrivate, values.isRepeat]
	)

	useEffect(
		() => {
			if (values.repeatInterval !== RepeatIntervalEnum.Weekly) {
				setValues((state: ReminderResponse) => {
					const newState = new ReminderResponse(state)
					clearRepeat(newState);
					return newState;
				})
			}
		},
		[values.repeatInterval]
	)

	const onSubmitCallback = useCallback(
		async () => await onSave!(values),
		[onSave, values]
	)

	const projectTeams = useMemo(
		() => {
			if (values.isProjectConnected) {
				return persistedProjectTeam.projectMap[values.projectOrCategoryId]?.items || []
			}

			return [];
		},
		[values.isProjectConnected, values.projectOrCategoryId, persistedProjectTeam]
	)

	return (
		<Form
			values={values}
			initialValues={reminder}
			onChange={setValues}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<ColumnContainer>
					<SmartContainer>
						<SmartItem>
							<ProjectOrCategorySelect
								value={projectOrCategory}
								onChange={setProjectOrCategory}
								disabled
								moduleEnum={ModuleActivityEnum.Communication}
								isRequired
							/>
							<InputField
								id={propertyOf<ReminderResponse>('subject')}
								label='Subject'
								isRequired
							/>
							<DateField
								id={propertyOf<ReminderResponse>('startDate')}
								label='Starts'
								showTime
								dateFormat='MM/dd/yyyy h:mm aa'
								isRequired
							/>
							<DateField
								id={propertyOf<ReminderResponse>('endDate')}
								label='Ends'
								showTime
								dateFormat='MM/dd/yyyy h:mm aa'
								isRequired
							/>
							<VerticalSeparator />
							<SmartFormGroup>
								<SmartInline>
									<CheckboxField
										id={propertyOf<ReminderResponse>('isRepeat')}
										label='Repeats'
									/>
									{values.isRepeat &&
										<AutoCompleteField
											id={propertyOf<ReminderResponse>('repeatInterval')}
											items={repeatInterval}
											getItemId={(item: RepeatIntervalEnum) => item}
											getItemText={(item: RepeatIntervalEnum) => item}
										/>
									}
								</SmartInline>
							</SmartFormGroup>
							<VerticalSeparator />
							{(values.isRepeat && values.repeatInterval === RepeatIntervalEnum.Weekly) &&
								<SmartFormGroup label='Repeat on days'>
									<SmartInline>
										<CheckboxField
											id={propertyOf<ReminderResponse>('repeatOnMonday')}
											labelBefore='Mon'
										/>
										<CheckboxField
											id={propertyOf<ReminderResponse>('repeatOnTuesday')}
											labelBefore='Tue'
										/>
										<CheckboxField
											id={propertyOf<ReminderResponse>('repeatOnWednesday')}
											labelBefore='Wed'
										/>
										<CheckboxField
											id={propertyOf<ReminderResponse>('repeatOnThursday')}
											labelBefore='Thu'
										/>
										<CheckboxField
											id={propertyOf<ReminderResponse>('repeatOnFriday')}
											labelBefore='Fri'
										/>
										<CheckboxField
											id={propertyOf<ReminderResponse>('repeatOnSaturday')}
											labelBefore='Sat'
										/>
										<CheckboxField
											id={propertyOf<ReminderResponse>('repeatOnSunday')}
											labelBefore='Sun'
										/>
									</SmartInline>
								</SmartFormGroup>
							}
						</SmartItem>

						<SmartItem>
							<CheckboxField
								id={propertyOf<ReminderResponse>('isPrivate')}
								label='Private'
								updateDependants={onIsPrivateChangeCallback}
							/>
							{!values.isPrivate &&
								<>
									<AutoCompleteField
										id={propertyOf<ReminderResponse>('projectTeamId')}
										label='Project team'
										items={projectTeams}
										getItemId={(item: ProjectIsActiveResponse) => item.id}
										getItemText={(item: ProjectIsActiveResponse) => item.name}
										loading={values.isProjectConnected && persistedProjectTeam.projectMap[values.projectOrCategoryId]?.fetching}
									/>
									<MultiSelectField
										id={propertyOf<ReminderResponse>('recipientUserIds')}
										label='Recipients'
										items={users}
										getItemId={(item: UserModel) => item.id}
										getItemText={(item: UserModel) => `${item.firstName} ${item.lastName}`}
										loading={fetchingUsers}
									/>
								</>
							}
							<CheckboxField
								id={propertyOf<ReminderResponse>('sendEmail')}
								label='Send email'
							/>
						</SmartItem>
					</SmartContainer>
				</ColumnContainer>
			)}
		/>
	)
}
