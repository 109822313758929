import styles from './note.module.scss'

type Props = {
	text: string
}

export const Note = ({ text }: Props) => {
	return (
		<div className={styles.container}>
			<small>{text}</small>
		</div>
	)
}
