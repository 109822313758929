import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { SmartContainer, SmartFormGroup, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { CheckboxField, DateField, Form, FormGroup, Input, InputField, InputNumberField, TextareaField, TimeField, currencyDefaultProps, AutoCompleteField, AutoComplete } from 'components/Form';
import { CrudEnum, useGoBackFromCrud } from 'features/Crud';
import { TravelRequestResponse, IdNameResponse, TimeTravelStatusResponse, TravelByEnum, TravelExpenseResponse, TravelExpenseItemResponse, TimeTravelPermission, ModuleActivityEnum } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { useCurrencySuffixMemo } from 'features/Currency/useCurrencySuffixMemo';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import Button from 'components/Button';
import { ColumnContainer, RowContainer, VerticalSeparator } from 'components/Layout';
import { getTravelByEnumLabel, travelByOptions } from 'containers/TimeTravel/utils';
import { minutesToDhm } from 'utils/dateTimeUtils';
import { TravelRequestChangeGuard } from './TravelRequestChangeGuard';
import TravelExpenseItems from '../TravelExpenseItems/TravelExpenseItems';
import { ProjectOrCategorySelect, ProjectOrCategoryType, pmOrSpmPermission } from 'features/Project';

type Props = {
	travelExpense?: TravelExpenseResponse
	crud: CrudEnum
	onSave?: (travelExpense: TravelExpenseResponse, isRelease: boolean, initialTravelExpense: TravelExpenseResponse) => void
}

const TravelExpenseForm = ({
	travelExpense = new TravelExpenseResponse(),
	crud,
	onSave
}: Props) => {
	const {
		persistedProject,
		persistedCountry,
		persistedTimeTravelNonProjectCategories,
		persistedTenant,
		persistedCurrency,
		persistedTimeAndTravelStatus,
		persistedTravelRequests,
		persistedExpenseGeneralConfiguration
	} = useSelector((state: RootState) => state);

	const [values, setValues] = useState(travelExpense);
	const [initialValues, setInitialValues] = useState(travelExpense);

	const [travelRequestGuardId, setTravelRequestGuardId] = useState<number | undefined>(undefined);
	const [projectOrCategoryHasError, setProjectOrCategoryHasError] = useState(false);

	const [projectOrCategory, setProjectOrCategory] = useState<ProjectOrCategoryType | undefined>({
		projectOrCategoryId: values.projectOrCategoryId,
		isProjectConnected: values.isProjectConnected
	});

	const isReleaseRef = useRef(false);

	const currencySymbolSuffix = useCurrencySuffixMemo(persistedCurrency, persistedTenant);

	const goBackFromCrud = useGoBackFromCrud(crud);

	const isRead = crud === CrudEnum.Read;
	const isUpdate = crud === CrudEnum.Update;

	useEffect(
		() => {
			if (values.travelBy !== TravelByEnum.PrivateCar && values.travelBy !== TravelByEnum.CompanyCar) {
				setValues((state: TravelExpenseResponse) => new TravelExpenseResponse({
					...state,
					distance: undefined,
					regNumber: undefined,
					startKilemeter: undefined,
					endKilometer: undefined,
				}))
			}

			setInitialValues((state: TravelExpenseResponse) => {
				return new TravelExpenseResponse({
					...state,
					projectOrCategoryId: projectOrCategory?.projectOrCategoryId!,
					isProjectConnected: projectOrCategory?.isProjectConnected!
				})
			});
		},
		[values.travelBy, projectOrCategory]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const newTravelExpense = new TravelExpenseResponse({
				...values,
				isProjectConnected: projectOrCategory?.isProjectConnected!,
				projectOrCategoryId: projectOrCategory?.projectOrCategoryId!,
			});

			if (!newTravelExpense.projectOrCategoryId) {
				// projectOrCategorySelect is required, so we return message as error if no value in select
				setProjectOrCategoryHasError(true);
			} else {
				return onSave && await onSave(newTravelExpense, isReleaseRef.current, initialValues);
			}
		},
		[onSave, values, initialValues, projectOrCategory]
	)

	const travelRequestChangeCallback = useCallback(
		(value: number | undefined) => {
			if (!value) {
				setValues((state: TravelExpenseResponse) => new TravelExpenseResponse({
					...state,
					travelRequestId: value,
				}))
			}
			else  {
				setTravelRequestGuardId(value);
			}
		},
		[]
	)

	const travelRequestChangeConfirmCallback = useCallback(
		(value: number) => {
			setTravelRequestGuardId(undefined);
			const travelRequest = persistedTravelRequests.itemsMap[value];

			setValues((state: TravelExpenseResponse) => {
				const newState = new TravelExpenseResponse({
					...state,
					travelRequestId: value,
					projectOrCategoryId: travelRequest?.projectOrCategoryId!,
					isProjectConnected: travelRequest?.isProjectConnected!,
					leaveDepartureDate: travelRequest?.departure!,
					returnArrivalDate: travelRequest?.return!,
					description: travelRequest?.description!,
					estimatedCosts: travelRequest?.estimatedCosts!,
					freeMeals: travelRequest?.freeMeals!,
					freeAccommodation: travelRequest?.freeAccommodation!,
					destinationCountryId: travelRequest?.destinationCountryId!,
					destinationCity: travelRequest?.destinationCity!,
					customText1: travelRequest?.customText1!,
					customText2: travelRequest?.customText2!,
					travelBy: travelRequest?.travelBy!,
				});

				setProjectOrCategory({
					projectOrCategoryId: travelRequest?.projectOrCategoryId!,
					isProjectConnected: travelRequest?.isProjectConnected!
				});

				return newState;
			})
		},
		[persistedTravelRequests.itemsMap]
	)

	const travelRequestChangeCancelCallback = useCallback(
		() => {
			setTravelRequestGuardId(undefined);
		},
		[]
	)

	const travelExpenseItemsChangedCallback = useCallback(
		(travelExpenseItems: TravelExpenseItemResponse[]) => {
			setValues((state) => new TravelExpenseResponse({
				...state,
				travelExpenseItems
			}))
		},
		[]
	)

	const onProjectOrCategoryChangeCallback = useCallback(
		(newProjectOrCategory: ProjectOrCategoryType | undefined) => {
			setProjectOrCategory(newProjectOrCategory);
			setProjectOrCategoryHasError(false);
		},
		[]
	)

	return (
		<>
			<Form
				values={values}
				initialValues={initialValues}
				onChange={setValues}
				onSubmit={onSubmitCallback}
				onCancel={goBackFromCrud}
				disabled={isRead}
				hideButtons={isRead}
				disableUnsavedChangesGuard
				render={() => (
					<ColumnContainer>
						<SmartContainer>
							<SmartItem size='medium'>
								<SmartFormGroup label='Travel request ID'>
									<AutoComplete
										value={values.travelRequestId}
										items={persistedTravelRequests.items}
										getItemId={(option: TravelRequestResponse) => option.id}
										getItemText={(option: TravelRequestResponse) => getFormatedId(EntityPrefixEnum.TRAVEL_REQUEST, option.id)}
										loading={persistedTimeTravelNonProjectCategories.fetching || persistedProject.fetching}
										onChange={travelRequestChangeCallback}
										disabled={isRead}
									/>
								</SmartFormGroup>
								<ProjectOrCategorySelect
									value={projectOrCategory}
									onChange={onProjectOrCategoryChangeCallback}
									disabled={isRead}
									hasError={projectOrCategoryHasError}
									isRequired
									moduleEnum={ModuleActivityEnum.Time}
									teamMemberPermission={propertyOf<TimeTravelPermission>('travelExpenses')}
									userRolePermission={pmOrSpmPermission}
								/>
								<TextareaField
									id={propertyOf<TravelExpenseResponse>('description')}
									label='Purpose of travel'
									maxLength={2000}
									rows={5}
									isRequired
								/>
								<InputNumberField
									id={propertyOf<TravelExpenseResponse>('estimatedCosts')}
									label='Estimated costs'
									{...currencyDefaultProps}
									isRequired
									suffix={currencySymbolSuffix}
								/>
								<InputNumberField
									id={propertyOf<TravelExpenseResponse>('advancePayment')}
									label='Advance payment'
									{...currencyDefaultProps}
									suffix={currencySymbolSuffix}
								/>
							</SmartItem>
							<SmartItem size='large'>
								<FormGroup title='Departure'>
									<SmartFormGroup label='Start'>
										<SmartInline>
											<DateField
												id={propertyOf<TravelExpenseResponse>('leaveDepartureDate')}
												isRequired
											/>
											<TimeField
												id={propertyOf<TravelExpenseResponse>('leaveDepartureTime')}
												isRequired
											/>
										</SmartInline>
									</SmartFormGroup>
									<SmartFormGroup label='Border cross'>
										<SmartInline>
											<DateField
												id={propertyOf<TravelExpenseResponse>('leaveBorderCrossDate')}
											/>
											<TimeField
												id={propertyOf<TravelExpenseResponse>('leaveBorderCrossTime')}
											/>
										</SmartInline>
									</SmartFormGroup>
									<SmartFormGroup label='Arrival'>
										<SmartInline>
											<DateField
												id={propertyOf<TravelExpenseResponse>('leaveArrivalDate')}
											/>
											<TimeField
												id={propertyOf<TravelExpenseResponse>('leaveArrivalTime')}
											/>
										</SmartInline>
									</SmartFormGroup>
									{((isRead || isUpdate) && values.leaveTravelTimeInMinutes) && (
										<SmartFormGroup label='Travel time'>
											<Input
												value={minutesToDhm(values.leaveTravelTimeInMinutes, 60*24)}
												disabled
											/>
										</SmartFormGroup>
									)}
								</FormGroup>
								<VerticalSeparator />
								<FormGroup title='Return'>
									<SmartFormGroup label='Start'>
										<SmartInline>
											<DateField
												id={propertyOf<TravelExpenseResponse>('returnDepartureDate')}
											/>
											<TimeField
												id={propertyOf<TravelExpenseResponse>('returnDepartureTime')}
											/>
										</SmartInline>
									</SmartFormGroup>
									<SmartFormGroup label='Border cross'>
										<SmartInline>
											<DateField
												id={propertyOf<TravelExpenseResponse>('returnBorderCrossDate')}
											/>
											<TimeField
												id={propertyOf<TravelExpenseResponse>('returnBorderCrossTime')}
											/>
										</SmartInline>
									</SmartFormGroup>
									<SmartFormGroup label='Arrival'>
										<SmartInline>
											<DateField
												id={propertyOf<TravelExpenseResponse>('returnArrivalDate')}
												isRequired
											/>
											<TimeField
												id={propertyOf<TravelExpenseResponse>('returnArrivalTime')}
												isRequired
											/>
										</SmartInline>
									</SmartFormGroup>
									{((isRead || isUpdate) && values.returnTravelTimeInMinutes) && (
										<>
											<SmartFormGroup label='Travel time'>
												<Input
													value={minutesToDhm(values.returnTravelTimeInMinutes, 60*24)}
													disabled
												/>
											</SmartFormGroup>
											<SmartFormGroup label='Total travel duration'>
												<Input
													value={minutesToDhm(values.totalTravelDurationInMinutes, 60*24)}
													disabled
												/>
											</SmartFormGroup>
										</>
									)}
								</FormGroup>
								{(isRead || isUpdate) && (
									<AutoCompleteField
										id={propertyOf<TravelExpenseResponse>('statusId')}
										label='Status'
										items={persistedTimeAndTravelStatus.items}
										getItemId={(option: TimeTravelStatusResponse) => option.id}
										getItemText={(option: TimeTravelStatusResponse) => option.name!}
										loading={persistedTimeAndTravelStatus.fetching}
										disabled
									/>
								)}
							</SmartItem>
							<SmartItem size='medium'>
								<SmartFormGroup>
									<SmartInline>
										<CheckboxField
											id={propertyOf<TravelExpenseResponse>('freeMeals')}
											labelBefore='Free Meals'
										/>
										<CheckboxField
											id={propertyOf<TravelExpenseResponse>('freeAccommodation')}
											labelBefore='Free Accommodation'
										/>
									</SmartInline>
								</SmartFormGroup>
								<AutoCompleteField
									id={propertyOf<TravelExpenseResponse>('destinationCountryId')}
									label='Destination country'
									items={persistedCountry.items}
									getItemId={(country: IdNameResponse) => country.id}
									getItemText={(country: IdNameResponse) => country.name!}
									loading={persistedCountry.fetching}
									isRequired
								/>
								<InputField
									id={propertyOf<TravelExpenseResponse>('destinationCity')}
									label='Destination city'
									isRequired
								/>
								<AutoCompleteField
									id={propertyOf<TravelExpenseResponse>('travelBy')}
									label='Travel by'
									items={travelByOptions}
									getItemId={(option: TravelByEnum) => option}
									getItemText={(option: TravelByEnum) => getTravelByEnumLabel(option)}
									isRequired
								/>
								{(values.travelBy === TravelByEnum.CompanyCar || values.travelBy === TravelByEnum.PrivateCar) && (
									<>
										<InputField
											id={propertyOf<TravelExpenseResponse>('regNumber')}
											label='Reg. number'
										/>
										<InputField
											id={propertyOf<TravelExpenseResponse>('distance')}
											label='Distance'
										/>
										<InputField
											id={propertyOf<TravelExpenseResponse>('startKilemeter')}
											label='Start mile/km'
										/>
										<InputField
											id={propertyOf<TravelExpenseResponse>('endKilometer')}
											label='End mile/km'
										/>
									</>
								)}
								<InputField
									id={propertyOf<TravelExpenseResponse>('customText1')}
									label='Text 1'
								/>
								<InputField
									id={propertyOf<TravelExpenseResponse>('customText2')}
									label='Text 2'
								/>
							</SmartItem>
						</SmartContainer>
						<TravelExpenseItems
							expenseItems={values.travelExpenseItems || []}
							onChange={travelExpenseItemsChangedCallback}
							disabled={isRead}
						/>
					</ColumnContainer>
				)}
				renderAdditionalButtons={persistedExpenseGeneralConfiguration.value.enableApprovalProcess ? (disabled, handleSubmitCallback: () => void, isSubmitting) => (
					<RowContainer>
						<Button
							text='Release for approval'
							disabled={disabled}
							isLoading={isSubmitting && isReleaseRef.current === true}
							onClick={
								() => {
									isReleaseRef.current = true;
									handleSubmitCallback();
								}
							}
						/>
					</RowContainer>
				) : undefined}
			/>
			<TravelRequestChangeGuard
				value={travelRequestGuardId}
				onConfirm={travelRequestChangeConfirmCallback}
				onCancel={travelRequestChangeCancelCallback}
			/>
		</>
	)
}

export default TravelExpenseForm;
