import { useCallback, useEffect, useState } from 'react';
import { Route } from 'features/Routes/reducer';
import styles from './menu.module.scss'
import { Item } from './Item';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { Submenu } from './Submenu/Submenu';
import { MenuToggler } from './MenuToggler';

// Andrej removed colors of menu, so this is commented out just in case
// Reporting '#6B9CCE'
// export const menuItemMap: { [key in RootRouteEnum]: { color: string }} = {
// 	[RootRouteEnum.PROJECTS]: {
// 		color: '#D82C25'
// 	},
// 	[RootRouteEnum.TICKETS]: {
// 		color: '#A1CC69'
// 	},
// 	[RootRouteEnum.SCOPE]: {
// 		color: '#E94C90'
// 	},
// 	[RootRouteEnum.TESTING]: {
// 		color: '#A5A5A5'
// 	},
// 	[RootRouteEnum.TRAINING]: {
// 		color: '#4E7EF3'
// 	},
// 	[RootRouteEnum.TIME_TRAVEL]: {
// 		color: '#B52B66'
// 	},
// 	[RootRouteEnum.RISK]: {
// 		color: '#8B56C3'
// 	},
// 	[RootRouteEnum.FINANCE]: {
// 		color: '#DD824B'
// 	},
// 	[RootRouteEnum.COMMUNICATION]: {
// 		color: '#F5C050'
// 	},
// 	[RootRouteEnum.SCHEDULE]: {
// 		color: '#7FA95A'
// 	},
// 	// Configuration is not used here, but TS complaints
// 	[RootRouteEnum.SITE_CONFIGURATION]: {
// 		color: '#fff'
// 	},
// 	// Account is not used here, but TS complaints
// 	[RootRouteEnum.ACCOUNT]: {
// 		color: '#fff'
// 	}
// }

type Props = {
	items: Route[]
}

export const Menu = ({ items }: Props) => {
	// when expanded, text is shown
	const [expanded, setExpanded] = useState(false);
	// item from menu which is currently in interaction (example: user opened submenu for that item)
	const [activeItem, setActiveItem] = useState<Route>();
	// item from URL
	const [activeRouteId, setActiveRouteId] = useState<string>();

	const currentRoutes = useSelector((state: RootState) => state.routes.currentRoutes);

	useEffect(
		() => {
			setActiveRouteId(currentRoutes[0] && currentRoutes[0].id)
			setActiveItem(undefined);
		},
		[currentRoutes]
	)

	const [submenuOpened, setSubmenuOpened] = useState(false);

	const toggleSubmenuCallback = useCallback(
		(route: Route) => {
			if (route.id === activeItem?.id) {
				setSubmenuOpened((state) => {
					setActiveItem(state ? undefined : route);
					return !state;
				});
			} else {
				setSubmenuOpened(true);
				setActiveItem(route);
			}
		},
		[activeItem]
	)

	const closeSubmenuCallback = useCallback(
		() => {
			setSubmenuOpened(false);
			setActiveItem(undefined);
		},
		[]
	)

	const menuItems = items.map((item) => {
		return (
			<Item
				key={item.id}
				route={item}
				showText={expanded}
				isActive={item.id === activeRouteId || item.id === activeItem?.id}
				toggleSubmenu={toggleSubmenuCallback}
				// {...menuItemMap[item.id as RootRouteEnum]}
			/>
		)
	})

	const className = expanded ? styles.expanded : styles.colapsed;

	return (
		<div className={className} onMouseLeave={closeSubmenuCallback}>
			{menuItems}
			<MenuToggler
				expanded={expanded}
				setExpanded={setExpanded}
			/>
			<Submenu
				items={activeItem?.children || []}
				opened={submenuOpened}
				close={closeSubmenuCallback}
			/>
		</div>
	)
}
