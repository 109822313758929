export enum RootRouteEnum {
	PROJECTS = 'projects',
	TICKETS = 'tickets',
	SCOPE = 'scope',
	TESTING = 'testing',
	TRAINING = 'training',
	TIME_TRAVEL = 'time_travel',
	RISK = 'risk',
	FINANCE = 'finance',
	COMMUNICATION = 'communication',
	SCHEDULE = 'schedule',
	SITE_CONFIGURATION = 'site_configuration',
	ACCOUNT = 'account'
}
