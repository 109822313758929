import { CrudRouter } from 'features/Crud/CrudRouter';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ApprovalTabSubroutes } from '../ApprovalSubroutes';
import ReadVacationRequest from './Crud/ReadVacationRequest';
import VacationRequestsTable from './VacationRequestsTable';

const VacationRequestsApprovalRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${ApprovalTabSubroutes.VACATION_REQUESTS}`}
				render={() => (
					<CrudRouter
						ListComponent={VacationRequestsTable}
						ReadComponent={ReadVacationRequest}
						paramName={['vacationRequestId', 'vacationRequestStatusId']}
					/>
				)}
			/>
		</Switch>
	)
}

export default VacationRequestsApprovalRouter;
