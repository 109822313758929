import { Dialog } from 'components/Dialog';
import { CrudEnum, useGoBackFromUpdate } from 'features/Crud';
import { ExpenseItemResponse } from 'services/tenantManagementService';
import ExpenseItemForm from './ExpenseItemForm';

type Props = {
	expenseItem: ExpenseItemResponse
	onSave: (expenseItem: ExpenseItemResponse) => void
}

const UpdateExpenseItem = ({ expenseItem, onSave }: Props) => {

	const goBackFromUpdate = useGoBackFromUpdate(true);

	return (
		<Dialog
			title='Change expense'
			open
			onClose={goBackFromUpdate}
			size='large'
		>
			<ExpenseItemForm
				expenseItem={expenseItem}
				crud={CrudEnum.Update}
				onSave={onSave}
			/>
		</Dialog>
	)
}

export default UpdateExpenseItem;
