import { TenantClient, IndustryClient, TenantUpdateRequestModel, CountryClient, CurrencyClient, TeamSizeClient } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';

export const getTenant = () => {
	const tenantId = getTenantId()!;
	const client = new TenantClient();
	return client.getTenant(tenantId);
}

export const updateTenant = (tenantId: number, tenant: TenantUpdateRequestModel) => {
	const client = new TenantClient();
	return client.update(tenantId, tenant);
}

export const getIndustries = () => {
	const client = new IndustryClient();
	return client.getIndustries();
}

export const getCountries = () => {
	const client = new CountryClient();
	return client.getCountries();
}

export const getCurrencies = () => {
	const client = new CurrencyClient();
	return client.getCurrencies();
}

export const getTeamSizes = () => {
	const client = new TeamSizeClient();
	return client.getTeamSizes();
}
