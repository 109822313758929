import { PieChart, PieChartProps } from "features/Charts"
import { Widget, WidgetProps } from "./Widget"

type Props = WidgetProps & PieChartProps;

export const PieWidget = (props: Props) => {
	return (
		<Widget
			{...props}
		>
			<PieChart {...props} />
		</Widget>
	)
}
