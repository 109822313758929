import { CommunicationIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";

// subroutes

export enum CommunicationSubRoutesEnum {
	MY_DASHBOARD = 'communication_my_dashboard',
	CONTACT_LIST = 'contact_list',
	CALENDAR = 'calendar',
	TASKS = 'tasks',
	PROJECT_MEETINGS = 'project_meetings',
	PROJECT_DECISIONS = 'project_decisions',
	STATUS_REPORTS = 'status_reports',
	REMINDERS = 'reminders',
}

export const myDashboardSubRoute: Route = {
	id: CommunicationSubRoutesEnum.MY_DASHBOARD,
	url: `/${RootRouteEnum.COMMUNICATION}/${CommunicationSubRoutesEnum.MY_DASHBOARD}`,
	text: 'My Dashboard',
}

export const contactListSubRoute: Route = {
	id: CommunicationSubRoutesEnum.CONTACT_LIST,
	url: `/${RootRouteEnum.COMMUNICATION}/${CommunicationSubRoutesEnum.CONTACT_LIST}`,
	text: 'Contact list',
}

export const calendarSubRoute: Route = {
	id: CommunicationSubRoutesEnum.CALENDAR,
	url: `/${RootRouteEnum.COMMUNICATION}/${CommunicationSubRoutesEnum.CALENDAR}`,
	text: 'Calendar',
}

export const tasksSubRoute: Route = {
	id: CommunicationSubRoutesEnum.TASKS,
	url: `/${RootRouteEnum.COMMUNICATION}/${CommunicationSubRoutesEnum.TASKS}`,
	text: 'Tasks',
}

export const projectMeetingsSubRoute: Route = {
	id: CommunicationSubRoutesEnum.PROJECT_MEETINGS,
	url: `/${RootRouteEnum.COMMUNICATION}/${CommunicationSubRoutesEnum.PROJECT_MEETINGS}`,
	text: 'Meetings',
}

export const projectDecisionsSubRoute: Route = {
	id: CommunicationSubRoutesEnum.PROJECT_DECISIONS,
	url: `/${RootRouteEnum.COMMUNICATION}/${CommunicationSubRoutesEnum.PROJECT_DECISIONS}`,
	text: 'Decisions',
}

export const statusReportsSubRoute: Route = {
	id: CommunicationSubRoutesEnum.STATUS_REPORTS,
	url: `/${RootRouteEnum.COMMUNICATION}/${CommunicationSubRoutesEnum.STATUS_REPORTS}`,
	text: 'Status reports',
}

export const remindersSubRoute: Route = {
	id: CommunicationSubRoutesEnum.REMINDERS,
	url: `/${RootRouteEnum.COMMUNICATION}/${CommunicationSubRoutesEnum.REMINDERS}`,
	text: 'Reminders',
}

// root route

export const communicationRoute: Route = {
	id: RootRouteEnum.COMMUNICATION,
	url: `/${RootRouteEnum.COMMUNICATION}`,
	text: 'Communication',
	Icon: CommunicationIcon,
	children: [
		myDashboardSubRoute,
		contactListSubRoute,
		calendarSubRoute,
		tasksSubRoute,
		projectMeetingsSubRoute,
		projectDecisionsSubRoute,
		statusReportsSubRoute,
		remindersSubRoute
	]
}
