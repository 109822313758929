import { useMemo } from 'react'
import { HttpTransportType } from '@microsoft/signalr';
import { createSignalRContext } from "react-signalr";
import { getToken } from 'features/Token/token';
import appSettings from 'settings/appSettings';

export const SignalRContext = createSignalRContext({ shareConnectionBetweenTab: false });

type Props = {
	children: any
}

export const NotificationsProvider  = ({ children }: Props) => {
	const token = useMemo(() => getToken(), [])
	const hubUrl = useMemo(() => `${appSettings.getAlertsApiUrl()}/hubs/alerts`, [])

	return (
		<SignalRContext.Provider
			connectEnabled={!!token}
			accessTokenFactory={() => token || ''}
			dependencies={[token]}
			url={hubUrl}
			transport={HttpTransportType.WebSockets}
			onOpen={conn => { console.log('connection onOpen: ' + conn.connectionId) }}
			onBeforeClose={conn => { console.log('connection onBeforeClose: ' + conn.connectionId) }}
			onClosed={err => { console.log(`connection onClosed ${err?.message || ''}`) }}
			onReconnect={conn => { console.log('connection onReconnect: ' + conn.connectionId) }}
		>
			{children}
		</SignalRContext.Provider>
	)
}
