import { getTenantId } from "features/Tenant/tenantUtils";
import { AddUpdateUserAlertConfigurationsRequest, UserAlertConfigurationClient } from "services/tenantManagementService";

export const getRulesAndAlertsAction = () => {
	const tenantId = getTenantId()!;
	const client = new UserAlertConfigurationClient();
	return client.getAll(tenantId);
}

export const updateRulesAndAlertsAction = (model: AddUpdateUserAlertConfigurationsRequest) => {
	const tenantId = getTenantId()!;
	const client = new UserAlertConfigurationClient();
	return client.addUpdate(tenantId, model);
}
