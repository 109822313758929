// TODO: vidi sa Brankom zasto StatusResponseTable nije iskoriscen a isReadonly setovan na false za te statuse

import { useState, useCallback, useMemo } from 'react';
import { ColumnContainer } from 'components/Layout';
import Button from 'components/Button';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import notifications from 'components/Notification/notification';
import { CheckboxField, Form, InputField, globalErrorKey, ColorField, TableField } from 'components/Form';
import { BaseResponseModel, DeleteTenantIndetifyRequest, InsertStatusRequest, StatusDeltaRequest, UpdateStatusRequest } from 'services/tenantManagementService';
import { generateNumberId } from 'base/id';
import WithFetch from 'features/Fetch/WithFetch';
import { unpackDelta, createDelta } from 'utils/commonHelper';
import { StatusResponse, StatusResponseItemsResponseModel } from 'features/StatusResponse/statusResponse';

type TableFieldObject = {
	statuses: StatusResponse[]
}

type Props = {
	getStatusesction: () => Promise<StatusResponseItemsResponseModel>
	updateStatusesAction: (statusDelta: StatusDeltaRequest) => Promise<BaseResponseModel>
	successMessage: string
	label?: {
		active?: string
		name?: string
		description?: string
		color?: string
	}
}

export const EditableStatusTable = ({ getStatusesction, updateStatusesAction, successMessage, label = {} }: Props) => {
	const [initialStatuses, setInitialStatuses] = useState<TableFieldObject>({ statuses: [] });
	const [values, setValues] = useState<TableFieldObject>({ statuses: [] });
	const [refethcing, setRefetching] = useState(false);

	const fetchData = useCallback(
		async () => {
			const response = await tryCatchJsonByAction(getStatusesction);
			if (response.success) {
				setValues({ statuses: response.items || [] });
				setInitialStatuses({ statuses: response.items || [] });
			}
		},
		[getStatusesction]
	);

	const refetchDataCallback = useCallback(
		async () => {
			setRefetching(true);
			await fetchData();
			setRefetching(false);
		},
		[fetchData]
	);

	const addCallback = useCallback(
		() => {
			const id = generateNumberId();

			setValues((state) => {
				const newModel = new StatusResponse({
					id,
					name: '',
					description: '',
					isActive: true,
					isReadonly: false,
					isInitial: true,
					color: '#808080',
					semantics: 0
				});

				const newState = { ...state }
				newState.statuses = [...newState.statuses, newModel];

				return newState;
			})
		},
		[]
	);

	const cancelCallback = useCallback(
		() => setValues({ ...initialStatuses }),
		[initialStatuses]
	)

	const onSubmitCallback = useCallback(
		async () => {
			if (!values.statuses.length) {
				return Promise.resolve({[globalErrorKey]: 'At least one item should be created' })
			}
			const statuesDelta = createDelta(
				initialStatuses.statuses,
				values.statuses,
				InsertStatusRequest,
				UpdateStatusRequest,
				StatusDeltaRequest,
				DeleteTenantIndetifyRequest
			)
			const bindedAction = updateStatusesAction.bind(null, statuesDelta);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				notifications.success(successMessage);
				refetchDataCallback();
			} else {
				const errors = convertResponseErrors(response)
				const [statusErrors, newStatuses] = unpackDelta(errors, statuesDelta, values.statuses, initialStatuses.statuses);
				setValues({...values, statuses: newStatuses});
				return { configs: statusErrors };
			}
		},
		[initialStatuses, values, updateStatusesAction, refetchDataCallback, successMessage]
	)

	const uniqueFieldNames = useMemo(
		() => [{id: 'name', label: label.name || 'Name'}],
		[label.name]
	)

	return (
		<WithFetch fetchFunction={fetchData} refetching={refethcing}>
			<ColumnContainer margin='medium'>
				<Button
					onClick={addCallback}
					text='Add'
				/>
				<Form
					values={values}
					initialValues={initialStatuses}
					onChange={setValues}
					onSubmit={onSubmitCallback}
					onCancel={cancelCallback}
					render={() => (
						<TableField
							id='statuses'
							uniqueFieldNames={uniqueFieldNames}
							headers={[
								{ label: label.active || 'Active', size: 1 },
								{ label: label.name || 'Name', size: 4, isRequired: true },
								{ label: label.description || 'Explanation', size: 6 },
								{ label: label.color || 'Color' },
							]}
							getRowData={(status: StatusResponse) => {
								return {
									isDeletable: !status.isReadonly,
									fields: [
										<CheckboxField
											id='isActive'
										/>,
										<InputField
											id='name'
											isRequired
											maxLength={25}
											disabled={status.isReadonly}
										/>,
										<InputField
											id='description'
											maxLength={80}
										/>,
										<ColorField
											id='color'
											colors={['#808080', 'green', 'yellow', 'red', '#0000ff', 'purple']}
											disabled={status.isReadonly}
										/>
									]
								}
							}}
						/>
					)}
				/>
			</ColumnContainer>
		</WithFetch>
	);
};
