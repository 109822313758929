import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CrudSubRoutesEnum } from 'features/Crud';
import { CreateFinance } from './Crud/CreateFinance';
import { UpdateFinance } from './Crud/UpdateFinance';

export const ProjectFinancesRouter = () => {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/${CrudSubRoutesEnum.Create}`} component={CreateFinance} />
			<Route path={`${path}/${CrudSubRoutesEnum.Update}/:financeId`} component={UpdateFinance} />
		</Switch>
	)
}
