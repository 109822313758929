import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import notifications from 'components/Notification/notification';
import { convertResponseErrors, tryCatchJsonByAction } from 'utils/fetchUtils';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { CreateComponentProps, useGoBackFromCreate } from 'features/Crud';
import { InsertReminderRequest, ReminderResponse } from 'services/tenantManagementService';
import { createReminderAction } from '../action';
import { clearRepeat, ReminderForm } from './ReminderForm';
import { ProjectOrCategoryPickerParams } from 'features/Project';
import { ContentShell } from 'features/Content/ContentShell';

export const CreateReminder = ({ publishDataChanged }: CreateComponentProps) => {
	const params: ProjectOrCategoryPickerParams = useParams();
	const projectOrCategoryId = parseInt(params.projectOrCategoryId as string);
	const isProjectConnected = params.isProjectConnected === 'true';
	const goBackFromCreate = useGoBackFromCreate();

	const handleSave = useCallback(
		async (newReminder: ReminderResponse) => {
			const model = new InsertReminderRequest(newReminder);
			const bindedAction = createReminderAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				const id = getFormatedId(EntityPrefixEnum.REMINDER, response.value?.id);
				notifications.success(`New reminder ${id} is created.`);
				publishDataChanged();
				goBackFromCreate();
			} else {
				return convertResponseErrors(response);
			}
		},
		[goBackFromCreate, publishDataChanged]
	);

	const reminder = useMemo(
		() => {
			const model = new ReminderResponse();
			model.isProjectConnected = isProjectConnected;
			model.projectOrCategoryId = projectOrCategoryId;

			model.repeatInterval = undefined as any;
			clearRepeat(model);

			return model
		},
		[isProjectConnected, projectOrCategoryId]
	)

	return (
		<ContentShell title='Create reminder'>
			<ReminderForm
				reminder={reminder}
				onSave={handleSave}
				cancel={goBackFromCreate}
			/>
		</ContentShell>
	)
}
