import { getTenantId } from 'features/Tenant/tenantUtils';
import { ApprovalTimeTravelDescriptiveRequest, ExportDataModel, GenericFilterModelCollection, InsertTravelExpenseRequest, MonthEnum, TravelExpenseClient, UpdateTravelExpenseRequest } from 'services/tenantManagementService';

export const getTravelExpenseAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.get(tenantId, id);
}

export const getAllMyTravelExpensesGenericAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.getAllMy(tenantId, years, months, filter);
}

export const getAllMyTravelExpensesGenericTotalsAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.getAllMyGenericTotals(tenantId, years, months, filter);
}

export const getAllTravelExpensesGenericAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.getAllGeneric(tenantId, years, months, filter);
}

export const getAllTravelExpensesGenericTotalsAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.getAllGenericTotals(tenantId, years, months, filter);
}

export const createTravelExpenseAction = (model: InsertTravelExpenseRequest) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.create(tenantId, model);
}

export const updateTravelExpenseAction = (model: UpdateTravelExpenseRequest) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.update(tenantId, model);
}

export const deleteTravelExpenseAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.delete(tenantId, id);
}

export const releaseMyTravelExpenseAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.releaseMy(tenantId, id);
}

export const cloneMyTravelExpenseAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.clone(tenantId, id);
}

export const approvalTravelExpenseAction = (id: number, model: ApprovalTimeTravelDescriptiveRequest ) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.approval(tenantId, id, model);
}

export const downloadAttachmentAction = (travelExpenseItemId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.downloadAttachment(tenantId, travelExpenseItemId, id);
}

export const exportTravelExpensesAction = (years: number[], months: MonthEnum[], body: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new TravelExpenseClient();
	return client.exportAll(tenantId, years, months, body);
}
