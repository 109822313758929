import { useMemo } from 'react';
import { ProjectQualityResponse, ReportItemStatusEnum } from 'services/tenantManagementService';
import { FieldTypeEnum, TableColumnsType, useVisibleColumns } from 'components/Table';
import { reportItemStatuses } from '../../ReportItemStatuses';
import { propertyOf } from 'utils/propertyOf';

const defaultVisibleColumns = [
	propertyOf<ProjectQualityResponse>('wbs'),
	propertyOf<ProjectQualityResponse>('name'),
	propertyOf<ProjectQualityResponse>('finish'),
	propertyOf<ProjectQualityResponse>('achievedData'),
	propertyOf<ProjectQualityResponse>('actions'),
	propertyOf<ProjectQualityResponse>('status')
]

export const useTableColumnsMemo = (visibleColumns: string[] = defaultVisibleColumns) => {
	const columns: TableColumnsType = useMemo(
		() => {
			return {
				[propertyOf<ProjectQualityResponse>('wbs')]: {
					title: 'WBS',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectQualityResponse>('name')]: {
					title: 'Task Name',
					formatter: (cell: any) => {
						const qualityTableRowData: ProjectQualityResponse = cell.getData();
						const wbs = qualityTableRowData.wbs;
						return (
							`<span style="padding-left:	${(wbs.split('.').length - 1) * 20}px">
								${qualityTableRowData.name}
							</span>`
						);
					},
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectQualityResponse>('finish')]: {
					title: 'Finish',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectQualityResponse>('achievedData')]: {
					title: 'Achieved date',
					fieldType: FieldTypeEnum.Date
				},
				[propertyOf<ProjectQualityResponse>('actions')]: {
					title: 'Corrective action',
					fieldType: FieldTypeEnum.String
				},
				[propertyOf<ProjectQualityResponse>('status')]: {
					title: 'Status',
					fieldType: FieldTypeEnum.Options,
					options: reportItemStatuses,
					getItemId: (option: ReportItemStatusEnum) => option.valueOf(),
					getItemText: (option: ReportItemStatusEnum) => option
				}
			}
		},
		[]
	)

	return useVisibleColumns(columns, visibleColumns);
}
