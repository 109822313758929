import { TimeTravelIcon } from "components/icons/icons";
import { Route } from "features/Routes/reducer";
import { RootRouteEnum } from "features/Routes";

// subroutes

export enum TimeTravelSubRoutesEnum {
	MY_DASHBOARD = 'time_travel_my_dashboard',
	PROJECT_DASHBOARD = 'time_travel_project_dashboard',
	MY_TIMESHEETS= 'my_timesheets',
	MY_TRAVEL_REQUESTS = 'my_travel_requests',
	MY_TRAVEL_EXPENSES = 'my_travel_expenses',
	MY_EXPENSES = 'my_expenses',
	MY_VACATION_REQUESTS = 'my_vacation_requests',
	APPROVAL = 'approval',
}

export const myDashboardSubRoute: Route = {
	id: TimeTravelSubRoutesEnum.MY_DASHBOARD,
	url: `/${RootRouteEnum.TIME_TRAVEL}/${TimeTravelSubRoutesEnum.MY_DASHBOARD}`,
	text: 'My Dashboard',
}

export const projectDashboardSubRoute: Route = {
	id: TimeTravelSubRoutesEnum.PROJECT_DASHBOARD,
	url: `/${RootRouteEnum.TIME_TRAVEL}/${TimeTravelSubRoutesEnum.PROJECT_DASHBOARD}`,
	text: 'Project Dashboard',
}

export const myTimesheetsSubRoute: Route = {
	id: TimeTravelSubRoutesEnum.MY_TIMESHEETS,
	url: `/${RootRouteEnum.TIME_TRAVEL}/${TimeTravelSubRoutesEnum.MY_TIMESHEETS}`,
	text: 'My timesheets',
}

export const myTravelRequestsSubRoute: Route = {
	id: TimeTravelSubRoutesEnum.MY_TRAVEL_REQUESTS,
	url: `/${RootRouteEnum.TIME_TRAVEL}/${TimeTravelSubRoutesEnum.MY_TRAVEL_REQUESTS}`,
	text: 'My travel requests',
}

export const myTravelExpensesSubRoute: Route = {
	id: TimeTravelSubRoutesEnum.MY_TRAVEL_EXPENSES,
	url: `/${RootRouteEnum.TIME_TRAVEL}/${TimeTravelSubRoutesEnum.MY_TRAVEL_EXPENSES}`,
	text: 'My travel expenses',
}

export const myExpensesSubRoute: Route = {
	id: TimeTravelSubRoutesEnum.MY_EXPENSES,
	url: `/${RootRouteEnum.TIME_TRAVEL}/${TimeTravelSubRoutesEnum.MY_EXPENSES}`,
	text: 'My expenses',
}

export const myVacationRequestsSubRoute: Route = {
	id: TimeTravelSubRoutesEnum.MY_VACATION_REQUESTS,
	url: `/${RootRouteEnum.TIME_TRAVEL}/${TimeTravelSubRoutesEnum.MY_VACATION_REQUESTS}`,
	text: 'My vacation requests',
}

export const approvalSubRoute: Route = {
	id: TimeTravelSubRoutesEnum.APPROVAL,
	url: `/${RootRouteEnum.TIME_TRAVEL}/${TimeTravelSubRoutesEnum.APPROVAL}`,
	text: 'Approval',
}

// root route

export const timeTravelRoute: Route = {
	id: RootRouteEnum.TIME_TRAVEL,
	url: `/${RootRouteEnum.TIME_TRAVEL}`,
	text: 'Time & Travel',
	Icon: TimeTravelIcon,
	children: [
		myDashboardSubRoute,
		projectDashboardSubRoute,
		myTimesheetsSubRoute,
		myTravelRequestsSubRoute,
		myTravelExpensesSubRoute,
		myExpensesSubRoute,
		myVacationRequestsSubRoute,
		approvalSubRoute
	]
}
