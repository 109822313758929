import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'base/reducer/reducer'
import { ThemeEnum } from "features/Theme";
import { createLegend } from './helpers/legend'

type FieldType = {
	value: string
	name: string
	color?: string
}

export type RotatedColumnSeriesChartProps = {
	id: string
	data: any[]
	fieldValues: FieldType[]
	fieldCategory: string
	stacked?: boolean
	categoryTitle?: string
	hideLegend?: boolean
	minValue?: number
	maxValue?: number
	showValueOnBar?: boolean
	showAxisInPercent?: boolean
	showAxisGrid?: boolean
}

export const RotatedColumnSeriesChart = ({ id, data, fieldCategory, fieldValues, categoryTitle, stacked = false, hideLegend, minValue, maxValue, showValueOnBar = false, showAxisInPercent = false, showAxisGrid = false }: RotatedColumnSeriesChartProps) => {
	const theme = useSelector((state: RootState) => state.theme)

	useEffect(
		() => {
			let chart = am4core.create(id, am4charts.XYChart);
			chart.data = data;

			let cellSize = 25;
			chart.events.on("datavalidated", function(ev) {
				// Get objects of interest
				let chart = ev.target;
				let categoryAxis = chart.yAxes.getIndex(0)!;

				// Calculate how we need to adjust chart height
				let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

				// get current chart height
				let targetHeight = chart.pixelHeight + adjustHeight;

				// Set it on chart's container
				chart.svgContainer!.htmlElement.style.height = targetHeight + "px";
			});

			let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = fieldCategory;
			categoryAxis.renderer.labels.template.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
			categoryAxis.renderer.minGridDistance = 10;
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.renderer.grid.template.disabled = !showAxisGrid;
			categoryAxis.renderer.inversed = true;
			categoryAxis.fontSize = 11;

			if (categoryTitle) {
				categoryAxis.title.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
				categoryAxis.title.text = categoryTitle;
			}

			let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
			valueAxis.renderer.labels.template.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
			valueAxis.renderer.grid.template.disabled = true;
			valueAxis.renderer.opposite = true;
			valueAxis.fontSize = 11;
			valueAxis.renderer.ticks.template.length = 5;
			valueAxis.renderer.ticks.template.disabled = false;
			valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
			valueAxis.min = minValue;
			valueAxis.max = maxValue;

			if (showAxisInPercent) {
				valueAxis.renderer.labels.template.adapter.add("text", function(text) {
					return text + "%";
				});
			}

			fieldValues.forEach(
				(fv) => {
					let series = chart.series.push(new am4charts.ColumnSeries());
					series.dataFields.valueX = fv.value;
					series.dataFields.categoryY = fieldCategory;
					series.name = fv.name;
					series.stacked = stacked;
					if (fv.color) {
						series.fill = am4core.color(fv.color);
					}
					series.strokeWidth = 0;
					series.columns.template.tooltipText = "{name}: [bold]{valueX.formatNumber('#,###.##')} [/]";
					series.columns.template.propertyFields.fill = "barColor";
					series.columns.template.propertyFields.stroke = "barColor";

					// replaced LabelBullet with just Label, because - (ne vidi se label kada X-osa ne pocinje od 0)
					// if (showValueOnBar) {
					// 	let valueLabel = series.bullets.push(new am4charts.LabelBullet());
					// 	valueLabel.label.text = showAxisInPercent ? '{valueX.formatNumber("#.##")}%' : '{valueX.formatNumber("#,###.")}';
					// 	valueLabel.label.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
					// 	valueLabel.label.fontSize = 11;
					// 	valueLabel.label.horizontalCenter = centerAlign ? 'middle' : 'left';
					// 	valueLabel.locationX = centerAlign ? 0.5 : 1;
					// 	valueLabel.dx = centerAlign ? 0 : 5;
					// 	valueLabel.label.truncate = false;
					// 	valueLabel.label.hideOversized = stacked || centerAlign ? true : false;
					// }

					if (showValueOnBar) {
						let valueLabel = series.columns.template.createChild(am4core.Label);
						valueLabel.text = showAxisInPercent ? '{valueX.formatNumber("#.##")}%' : '{valueX.formatNumber("#,###.")}';
						valueLabel.fill = am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
						valueLabel.fontSize = 11;
						valueLabel.align = 'left'
						valueLabel.valign = 'middle';
						valueLabel.dx = 5;
						valueLabel.strokeWidth = 0
						valueLabel.truncate = false;
						valueLabel.hideOversized = stacked ? true : false;
					}
				}
			)

			if (!hideLegend) {
				chart.legend = createLegend(theme);
			}

			// ne vide se dobro sve vrijednosti kada je cursor ukljucen, dolazi do preklapanja
			// chart.cursor = new am4charts.XYCursor();
			// chart.cursor.lineX.stroke= am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
			// chart.cursor.lineY.stroke= am4core.color(theme === ThemeEnum.Dark ? 'rgb(216, 216, 216)': '');
		},
		[id, data, fieldCategory, fieldValues, stacked, theme, categoryTitle, hideLegend, minValue, maxValue, showValueOnBar, showAxisInPercent, showAxisGrid]
	)

	return <div id={id} style={{ flex: 1 }}></div>
}
