import { useCallback, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getRouteObject, RouterProps } from 'features/Routes';
import { ProjectReportRouter } from './ProjectReport/ProjectReportRouter';
import { DefaultContent } from 'features/DefaultContent/DefaultContent';
import { usePersistIntervalEffect } from 'features/Persist/persistHooks';
import { persistProjectTypesAction } from 'containers/Configuration/SiteConfiguration/Projects/ConfigureProjects/Tabs/ProjectType/action';
import { persistProjectCategoriesAction } from 'containers/Configuration/SiteConfiguration/Projects/ConfigureProjects/Tabs/ProjectCategory/action';
import { persistUserStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Users/action';
import { persistTicketPriorityAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/action';
import { persistTicketImpactsAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/Impact/action';
import { persistTicketUrgencyAction } from 'containers/Configuration/SiteConfiguration/Module/Tickets/Tabs/TicketPriority/Urgency/action';
import { ProjectParamName } from 'features/Project';
import { CrudRouter } from 'features/Crud';
import { MyProjects } from './MyProjects/MyProjects';
import { MyProjectsMaintain } from './MyProjects/MaintainProject/MyProjectsMaintain';
import { ProjectSubRoutesEnum, myDashboardSubRoute, myProjectSubRoute, projectReportSubRoute } from './routes';
import { persistOrganizationalUnitsAction } from 'containers/Configuration/SiteConfiguration/General/Company/OrganizationalUnits/action';
import { ProjectDashboard } from './Dashboard/ProjectDashboard';
import { persistTicketStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Tickets/action';
import { persistTaskStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/Tasks/action';
import { persistTestCaseStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/TestCases/action';
import { persistTrainingCaseStatusesAction } from 'containers/Configuration/SiteConfiguration/General/Statuses/Tabs/TrainingStatus/action';

export const ProjectsRouter = ({ route }: RouterProps) => {
	const persistFunction = useCallback(
		() => {
			persistProjectTypesAction();
			persistProjectCategoriesAction();
			persistUserStatusesAction();
			persistTicketImpactsAction();
			persistTicketUrgencyAction();
			persistTicketPriorityAction();
			persistOrganizationalUnitsAction();
			persistTicketStatusesAction();
			persistTaskStatusesAction();
			persistTestCaseStatusesAction();
			persistTrainingCaseStatusesAction();
		},
		[]
	);
	usePersistIntervalEffect(persistFunction);

	const routesMap = useMemo(
		() => getRouteObject(route.children),
		[route]
	)

	return (
		<Switch>
			{routesMap[ProjectSubRoutesEnum.MY_DASHBOARD] &&
				<Route
					path={myDashboardSubRoute.url}
					render={() => <ProjectDashboard />}
				/>
			}

			{routesMap[ProjectSubRoutesEnum.MY_PROJECTS] &&
				<Route
					path={myProjectSubRoute.url}
					render={() => (
						<CrudRouter
							ListComponent={MyProjects}
							UpdateComponent={MyProjectsMaintain}
							paramName={ProjectParamName}
						/>
					)}
				/>
			}

			{routesMap[ProjectSubRoutesEnum.PROJECT_REPORT] &&
				<Route
					path={[
						`${projectReportSubRoute.url}/:${ProjectParamName}`,
						projectReportSubRoute.url
					]}
					render={() => <ProjectReportRouter />}
				/>
			}

			<Route
				render={() => {
					return <DefaultContent route={route} />
				}}
			/>
		</Switch>
	)
}
