import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { RowContainer } from 'components/Layout';
import { ProjectResponse, ProjectStatusEnum } from 'services/tenantManagementService';
import ConfigureView from 'features/ConfigureView';
import { isNextStatus, isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { RootState } from 'base/reducer/reducer';
import { BaseColumnModel } from 'components/Table';

type Props = {
	selectedProject: ProjectResponse
	archiving: boolean
	onArchiveClick: () => void
	restoring: boolean
	onRestoreClick: () => void
	configureViewKey: string
	tableColumns: BaseColumnModel[]
};

export const TableButtons = ({
	selectedProject,
	archiving,
	onArchiveClick,
	restoring,
	onRestoreClick,
	configureViewKey,
	tableColumns
}: Props) => {
	const { persistedProjectStatus } = useSelector((state: RootState) => state);

	const isArchiveNext = useMemo(
		() => isNextStatus(ProjectStatusEnum.Archived, selectedProject.statusId, persistedProjectStatus.itemsMap),
		[selectedProject.statusId, persistedProjectStatus.itemsMap]
	)

	const isRestorable = isStatusBySemantic(ProjectStatusEnum.Archived, selectedProject.statusId, persistedProjectStatus.itemsMap);

	return (
		<RowContainer justifyContent='space-between'>
			<RowContainer>
			<Button
				text='Archive'
				onClick={onArchiveClick}
				disabled={!selectedProject.id || !isArchiveNext}
				isLoading={persistedProjectStatus.fetching || archiving}
			/>
			<Button
				text='Restore'
				onClick={onRestoreClick}
				disabled={!selectedProject.id || !isRestorable}
				isLoading={persistedProjectStatus.fetching || restoring}
			/>
			</RowContainer>
			<ConfigureView id={configureViewKey} tableColumns={tableColumns} />
		</RowContainer>
	);
};
