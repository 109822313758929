import path from 'path'
import { RootRouteEnum } from "features/Routes";
import { useMemo } from "react";
import { AlertTriggersEnum } from "services/tenantManagementService";
import { Notification } from "../Notification/Notification"
import { NotificationComponentProps } from "../NotificationsPanel"
import { TimesheetsApprovedNotificationAdditional, TimesheetsRejectedNotificationAdditional, TimesheetsSubmittedNotificationAdditional } from "./types";
import { useFormattedIdUsernameAndTrigger } from "./useFormattedIdUsernameAndTrigger";
import styles from '../notificationsPanel.module.scss'
import { ApprovalTabSubroutes } from 'containers/TimeTravel/Approval/Tabs/ApprovalSubroutes';
import { CrudEnum } from 'features/Crud';
import { TimeTravelSubRoutesEnum } from 'containers/TimeTravel/routes';

export const TimesheetsNotification = ({ notification }: NotificationComponentProps) => {
	const Component = useMemo(
		() => {
			switch (notification.trigger) {
				case AlertTriggersEnum.Submitted:
					return TimesheetsSubmittedNotification;
				case AlertTriggersEnum.Approved:
					return TimesheetsApprovedNotification;
				case AlertTriggersEnum.Rejected:
					return TimesheetsRejectedNotification;
				default:
					return;
			}
		},
		[notification]
	)

	if (!Component) {
		return <></>
	}

	return (
		<Component notification={notification}/>
	)
}

const TimesheetsSubmittedNotification = ({ notification }: NotificationComponentProps) => {
	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as TimesheetsSubmittedNotificationAdditional : undefined

	const { username, triggerText } = useFormattedIdUsernameAndTrigger(notification)

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.TIME_TRAVEL,
				TimeTravelSubRoutesEnum.APPROVAL,
				ApprovalTabSubroutes.TIMESHEETS,
				CrudEnum.Read,
				String(additionalData?.projectId),
				String(additionalData?.statusId),
				String(additionalData?.initiatorUsername),
				String(additionalData?.fromMilliseconds),
				String(additionalData?.toMilliseconds)

			)}
			content={
				<div>
					<span>Timesheet: </span>
					<span>for period </span>
					<span className={styles.entity}>{additionalData?.period} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}

const TimesheetsApprovedNotification = ({ notification }: NotificationComponentProps) => {
	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as TimesheetsApprovedNotificationAdditional : undefined

	const { username, triggerText } = useFormattedIdUsernameAndTrigger(notification)

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.TIME_TRAVEL,
				TimeTravelSubRoutesEnum.MY_TIMESHEETS,
				String(additionalData?.milliseconds)
			)}
			content={
				<div>
					<span>Timesheet: </span>
					<span>for period </span>
					<span className={styles.entity}>{additionalData?.period} </span>
					<span>is </span>
					<span>{triggerText} </span>
					<span>user </span>
					<span>{username}.</span>
				</div>
			}
		/>
	)
}

const TimesheetsRejectedNotification = ({ notification }: NotificationComponentProps) => {
	const additionalData = notification.additionalData ? JSON.parse(notification.additionalData) as TimesheetsRejectedNotificationAdditional : undefined

	return (
		<Notification
			id={notification.id}
			route={path.join(
				'/',
				RootRouteEnum.TIME_TRAVEL,
				TimeTravelSubRoutesEnum.MY_TIMESHEETS,
				String(additionalData?.milliseconds)
			)}
			content={`${notification.entity} ${notification.entityId} ${notification.trigger} by ${notification.initiatorUserId}`}
		/>
	)
}
