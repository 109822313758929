export enum CrudEnum {
	Create = 'create',
	Read = 'read',
	Update = 'update',
	Delete = 'delete'
}

export enum CrudSubRoutesEnum {
	Create = 'create',
	Read = 'read',
	Update = 'update'
}
