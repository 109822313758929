import { useState, useCallback } from 'react';
import notifications from 'components/Notification/notification';
import { ColumnContainer, VerticalSeparator } from 'components/Layout';
import Button from 'components/Button';
import { tryCatchJsonByAction, convertResponseErrors } from 'utils/fetchUtils';
import { generateNumberId } from 'base/id';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { TableField, globalErrorKey, CheckboxField, DateField, Form, InputField, TimeField, FormGroup, AutoComplete, AutoCompleteField } from 'components/Form';
import WithFetch from 'features/Fetch/WithFetch';
import { createDelta, unpackDelta } from 'utils/commonHelper';
import { getCalendarAction, updateCalendarAction } from './action';
import { CalendarResponse, HolidayResponse, IdNameResponse, InsertHolidayRequest, InsertHolidayRequestUpdateHolidayRequestInt32DeltaModel, InsertOrUpdateCalendarRequest, UpdateHolidayRequest, WeekDayEnum } from 'services/tenantManagementService';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { propertyOf } from 'utils/propertyOf';
import { ContentShell } from 'features/Content/ContentShell';
import { CalendarHelp } from './CalendarHelp';

const daysOfWeek = Object.values(WeekDayEnum);

export const Calendar = () => {
	const [countryId, setCountryId] = useState<number | string>();
	const [values, setValues] = useState(new CalendarResponse());
	const [initialValues, setInitialValues] = useState(new CalendarResponse());
	const { persistedCountry } = useSelector((state: RootState) => state);

	const fetchData = useCallback(
		async () => {
			if (!countryId) {
				return;
			}

			const bindedAction = getCalendarAction.bind(null, countryId as number);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				setValues(response.value || new CalendarResponse());
				setInitialValues(response.value || new CalendarResponse());
			}
		},
		[countryId]
	)

	const onSubmitCallback = useCallback(
		async () => {
			const holidays = values.holidays || []
			let hasDuplicates = false;
			holidays.forEach((holiday, index) => {
				const duplicate = holidays.find(
					(h, i) => i !== index
						&& holiday.name === h.name
						&& holiday.from.getTime() === h.from.getTime()
						&& holiday.to.getTime() === h.to.getTime()
				)
				if (duplicate) {
					hasDuplicates = true;
					return;
				}
			});

			if (hasDuplicates) {
				return Promise.resolve({[globalErrorKey]: 'Holidays can\'t be repeated' })
			}
			const delta = createDelta<HolidayResponse>
			(
				initialValues.holidays || [],
				values.holidays || [],
				InsertHolidayRequest,
				UpdateHolidayRequest,
				InsertHolidayRequestUpdateHolidayRequestInt32DeltaModel
			);

			const model = new InsertOrUpdateCalendarRequest({
				...values,
				holidays: delta
			})
			const bindedAction = updateCalendarAction.bind(null, model);
			const response = await tryCatchJsonByAction(bindedAction);

			if (response.success) {
				notifications.success('Calendar is updated.');
				setValues(response.value || new CalendarResponse());
				setInitialValues(response.value || new CalendarResponse());
			} else {
				const errors = convertResponseErrors(response)
				const [holidayErrors, newHolidays] = unpackDelta(errors?.holidays || {}, delta, values.holidays || [], initialValues.holidays || []);
				const newCalendar = new CalendarResponse(values);
				newCalendar.holidays = newHolidays;
				setValues(newCalendar);
				return { ...errors, holidays: holidayErrors };
			}

		},
		[values, initialValues]
	);

	const handleCancel = useCallback(
		() => setValues(initialValues),
		[initialValues]
	);

	const addCallback = useCallback(
		() => {
			const id = generateNumberId();

			setValues((state: any) => {
				const newModel = new HolidayResponse();
				newModel.id = id;
				newModel.isActive = true;
				newModel.name = '';

				const newState = { ...state };
				if (newState.holidays) {
					newState.holidays = [...newState.holidays, newModel];
				} else {
					newState.holidays = [newModel];
				}

				return newState;
			})
		},
		[]
	);

	return (
		<ContentShell
			title='Calendar Settings'
			FloatingHelpComponent={CalendarHelp}
		>
			<ColumnContainer>
				<SmartContainer>
					<SmartItem>
						<SmartFormGroup label='Country' >
							<AutoComplete
								value={countryId}
								onChange={setCountryId}
								items={persistedCountry.items}
								getItemId={(item: IdNameResponse) => item.id}
								getItemText={(item: IdNameResponse) => item.name}
								loading={persistedCountry.fetching}
							/>
						</SmartFormGroup>
					</SmartItem>
				</SmartContainer>
				{countryId &&
					<WithFetch fetchFunction={fetchData}>
						<Form
							values={values}
							initialValues={initialValues}
							onChange={setValues}
							onSubmit={onSubmitCallback}
							onCancel={handleCancel}
							render={() => (
								<ColumnContainer>
									<FormGroup title='Working Time'>
										<SmartContainer>
											<SmartItem size='xsmall'>
												<AutoCompleteField
													id={propertyOf<CalendarResponse>('workWeekFrom')}
													label='Work week'
													items={daysOfWeek}
													getItemId={(item: WeekDayEnum) => item}
													getItemText={(item: WeekDayEnum) => item}
												/>
											</SmartItem>
											<SmartItem size='xsmall'>
												<AutoCompleteField
													id={propertyOf<CalendarResponse>('workWeekTo')}
													label='to'
													items={daysOfWeek}
													getItemId={(item: WeekDayEnum) => item}
													getItemText={(item: WeekDayEnum) => item}
												/>
											</SmartItem>
										</SmartContainer>
										<VerticalSeparator margin='small' />
										<SmartContainer>
											<SmartItem size='xsmall'>
												<TimeField
													id={propertyOf<CalendarResponse>('workTimeFrom')}
													label='Work time'
												/>
											</SmartItem>
											<SmartItem size='xsmall'>
												<TimeField
													id={propertyOf<CalendarResponse>('workTimeTo')}
													label='to'
												/>
											</SmartItem>
										</SmartContainer>
									</FormGroup>
									<ColumnContainer margin='medium'>
										<h5>Holidays</h5>
										<Button
											text='Add new holiday'
											onClick={addCallback}
										/>
										<TableField
											id='holidays'
											headers={[
												{ label: 'Active', size: 1 },
												{ label: 'Holiday Name', size: 4 },
												{ label: 'From', size: 3 },
												{ label: 'To', size: 3 },
												{ label: 'Reccuring Entry', size: 1 }
											]}
											getRowData={() => {
												return {
													isDeletable: true,
													fields: [
														<CheckboxField
															id={propertyOf<HolidayResponse>('isActive')}
															isRequired
														/>,
														<InputField
															id={propertyOf<HolidayResponse>('name')}
															isRequired
															maxLength={25}
														/>,
														<DateField
															id={propertyOf<HolidayResponse>('from')}
															isRequired
														/>,
														<DateField
															id={propertyOf<HolidayResponse>('to')}
															isRequired
														/>,
														<CheckboxField
															id={propertyOf<HolidayResponse>('reccuringEntry')}
														/>
													]
												}
											}}
										/>
									</ColumnContainer>
								</ColumnContainer>
							)}
						/>
					</WithFetch>
				}
			</ColumnContainer>
		</ContentShell>
	)
};
