import { useCallback, useMemo, useState } from 'react';
import { SmartContainer, SmartFormGroup, SmartItem } from 'components/SmartContainer/SmartContainer';
import { AutoCompleteField, currencyDefaultProps, DateField, Form, InputField, InputNumber, InputNumberField, OptionType, TextareaField } from 'components/Form';
import { BusinessPartnerResponseModel, InvoiceTypeEnum, ProjectFinanceResponse, InvoiceStatusResponse } from 'services/tenantManagementService';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { useCurrencySuffixMemo } from 'features/Currency/useCurrencySuffixMemo';

export const incomingOutgoing: OptionType[] = [
	{ id: InvoiceTypeEnum.Incoming, text: 'Incoming' },
	{ id: InvoiceTypeEnum.Outgoing, text: 'Outgoing' }
]

type Props = {
	finance?: ProjectFinanceResponse
	cancel(): void
	save(finances: ProjectFinanceResponse): void
}

export const FinanceForm = ({ finance, cancel, save }: Props) => {
	const [values, setValues] = useState(finance || new ProjectFinanceResponse());

	const {
		persistedBusinessPartner,
		persistedInvoiceStatus,
		persistedTenant,
		persistedCurrency
	} = useSelector((state: RootState) => state);

	const incomingOutgoingPrefix = values.invoiceType === InvoiceTypeEnum.Incoming ? '-' : '';
	const currencySymbolSuffix = useCurrencySuffixMemo(persistedCurrency, persistedTenant);

	const amountWithTax = useMemo(
		() => {
			return values.amount + values.taxAmount;
		},
		[values.amount, values.taxAmount]
	)

	const onChangeCallback = useCallback(
		(values: ProjectFinanceResponse) => {
			const newValues = new ProjectFinanceResponse(values);
			if (newValues.issueDate) {
				const dueDate = new Date(newValues.issueDate);
				dueDate.setDate(values.issueDate.getDate() + (values.paymentTerm || 0))
				newValues.due = dueDate;
			}

			setValues(newValues);
		},
		[]
	)

	const onSubmitCallback = useCallback(
		async () => await save(values),
		[save, values]
	)

	return (
		<Form
			values={values}
			initialValues={finance}
			onChange={onChangeCallback}
			onSubmit={onSubmitCallback}
			onCancel={cancel}
			render={() => (
				<SmartContainer>
					<SmartItem>
						<InputField
							id='invoiceNumber'
							label='Invoice number'
							isRequired
						/>
						<InputField
							id='name'
							label='Description'
							isRequired
						/>
						<AutoCompleteField
							id='invoiceType'
							label='Incoming/Outgoing'
							items={incomingOutgoing}
							getItemId={(item: OptionType) => item.id}
							getItemText={(item: OptionType) => item.text}
							isRequired
						/>
						<AutoCompleteField
							id='partnerId'
							label='Partner'
							items={persistedBusinessPartner.items}
							getItemId={(item: BusinessPartnerResponseModel) => item.id}
							getItemText={(item: BusinessPartnerResponseModel) => item.name}
							loading={persistedBusinessPartner.fetching}
							isRequired
						/>
						<DateField
							id='issueDate'
							label='Issue date'
							isRequired
						/>
						<InputNumberField
							id='paymentTerm'
							label='Payment term (Days)'
							isRequired
							min={0}
						/>
						<DateField
							id='due'
							label='Due'
							disabled
						/>
						<InputNumberField
							id='amount'
							label='Amount (Net)'
							isRequired
							{...currencyDefaultProps}
							prefix={incomingOutgoingPrefix}
							suffix={currencySymbolSuffix}
							allowNegative={false}
						/>
						<InputNumberField
							id='taxAmount'
							label='Tax amount'
							isRequired
							{...currencyDefaultProps}
							prefix={incomingOutgoingPrefix}
							suffix={currencySymbolSuffix}
							allowNegative={false}
						/>
						<AutoCompleteField
							id='statusId'
							label='Status'
							isRequired
							items={persistedInvoiceStatus.items}
							getItemId={(item: InvoiceStatusResponse) => item.id}
							getItemText={(item: InvoiceStatusResponse) => item.name}
							getItemDescription={(item: InvoiceStatusResponse) => item.description}
							loading={persistedInvoiceStatus.fetching}
						/>
						<TextareaField
							id='description'
							label='Comment'
							rows={3}
						/>
						<SmartFormGroup label='Amount with tax'>
							<InputNumber
								value={amountWithTax}
								disabled
								{...currencyDefaultProps}
								prefix={incomingOutgoingPrefix}
								suffix={currencySymbolSuffix}
								allowNegative={false}
							/>
						</SmartFormGroup>
					</SmartItem>
				</SmartContainer>
			)}
		/>
	);
};
