import path from 'path';
import { RootState } from "base/reducer/reducer";
import { MultiSelectField } from "components/Form"
import { Info } from "components/Form/controls/Info/Info";
import { ColumnContainer, RowContainer } from "components/Layout";
import { SmartFormGroup, SmartInline } from "components/SmartContainer/SmartContainer";
import { CrudEnum } from "features/Crud";
import { RootRouteEnum } from "features/Routes";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ChangeRequestResponse, ChangeRequestStatusEnum, TicketResponse } from "services/tenantManagementService";
import { EntityPrefixEnum, getFormatedId } from "utils/commonHelper"
import { tryCatchJsonByAction } from "utils/fetchUtils";
import { propertyOf } from "utils/propertyOf";
import { getChangeRequestsAction } from '../../action';
import { useHistory } from 'react-router';
import { ChangeRequestSubroutes } from 'containers/Scope/ChangeRequests/Crud/Tabs/ChangeRequestSubroutes';
import { ScopeSubRoutesEnum } from 'containers/Scope/routes';

type Props = {
	ticket: TicketResponse
}

export const ChangeRequest = ({ ticket }: Props) => {
	const history = useHistory();
	const projectId = ticket.projectId;
	const { persistedChangeRequestStatus } = useSelector((state: RootState) => state);

	const [loadingChangeRequests, setLoadingChangeRequests] = useState(true);
	const [changeRequests, setChangeRequests] = useState<ChangeRequestResponse[]>([]);

	useEffect(
		() => {
			const fetchData = async () => {
				setLoadingChangeRequests(true);

				if (projectId) {
					const bindedAction = getChangeRequestsAction.bind(null, projectId);
					const response = await tryCatchJsonByAction(bindedAction);
					if (response.success) {
						setChangeRequests(response.items || []);
					}
				}

				setLoadingChangeRequests(false);
			}

			fetchData();
		},
		[projectId]
	)

	const changeRequestsContent = useMemo(
		() => {
			return ticket.changeRequestIds?.map((id) => {
				const changeRequest = changeRequests.find((item) => item.id === id);
				if (!changeRequest) {
					return <></>;
				}
				const formatedId = getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, id);
				const status = persistedChangeRequestStatus.itemsMap[changeRequest.statusId]!;
				const semantics = status.semantics;

				let changeRequestSubRoute: ChangeRequestSubroutes;
				if (semantics === ChangeRequestStatusEnum.ApprovedForRealization) {
					changeRequestSubRoute = ChangeRequestSubroutes.REALIZATION;
				} else if (semantics === ChangeRequestStatusEnum.Completed || semantics === ChangeRequestStatusEnum.ReleasedForAcceptance) {
					changeRequestSubRoute =  ChangeRequestSubroutes.ACCEPTANCE;
				} else if (semantics === ChangeRequestStatusEnum.Created) {
					changeRequestSubRoute = ChangeRequestSubroutes.PROBLEM_REPORT;
				} else if (semantics === ChangeRequestStatusEnum.Initiated || semantics === ChangeRequestStatusEnum.RejectedAnalysis) {
					changeRequestSubRoute = ChangeRequestSubroutes.ANALYSIS;
				} else if (semantics === ChangeRequestStatusEnum.RejectedForRealization || semantics === ChangeRequestStatusEnum.ReleasedForApproval) {
					changeRequestSubRoute = ChangeRequestSubroutes.APPROVAL;
				}

				return (
					<Info
						disabled
						onClick={() => {
							history.push(path.join('/', RootRouteEnum.SCOPE, ScopeSubRoutesEnum.CHANGE_REQUESTS, ticket.projectId.toString(), CrudEnum.Read, String(id), changeRequestSubRoute));
						}}
					>
						<RowContainer>
							<div>{formatedId}</div>
							<div style={{ fontWeight: 'bold', color: status.color }}>{status.name}</div>
						</RowContainer>
					</Info>
				)
			})
		},
		[ticket.changeRequestIds, changeRequests, persistedChangeRequestStatus, ticket.projectId, history]
	)

	return (
		<SmartFormGroup label='Change requests'>
			<ColumnContainer margin='small'>
				<SmartInline>
					<MultiSelectField
						id={propertyOf<TicketResponse>('changeRequestIds')}
						items={changeRequests}
						getItemId={(item: ChangeRequestResponse) => item.id}
						getItemText={(item: ChangeRequestResponse) => `${getFormatedId(EntityPrefixEnum.CHANGE_REQUEST, item.id)} - ${item.name}`}
						loading={loadingChangeRequests}
					/>
				</SmartInline>
				{changeRequestsContent}
			</ColumnContainer>
		</SmartFormGroup>
	)
}
