import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, DropResult, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

const StyledElement = styled.div`
	margin-bottom: 8px;
`;

type Props = {
	initialElementIds: number[]
	onReorder: (elementIds: number[]) => void
	renderItem(id: number): any
	disabled?: boolean
}

export const ReordableList = ({ onReorder, initialElementIds, renderItem, disabled }: Props) => {
	const [elementIds, setElementIds] = useState(initialElementIds);

	useEffect(
		() => {
			setElementIds([...initialElementIds])
		},
		[initialElementIds]
	)
	// INFO: This function must result in the synchronous reordering of a list of Draggables
	const handleDragEnd = useCallback(
		(result: DropResult) => {
			const { destination, source, draggableId } = result;

			if (!destination) {
				return;
			}

			if (destination.index === source.index) {
				return;
			}

			const newElementIds = [...elementIds]
			newElementIds.splice(source.index, 1);
			newElementIds.splice(destination.index, 0, parseInt(draggableId));

			setElementIds(newElementIds)
			onReorder(newElementIds);
		},
		[elementIds, onReorder]
	);

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId={'1'}>
				{(provided) => (
					<div
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						{elementIds.map((id, index) => (
							<Draggable draggableId={String(id)} index={index} key={String(id)} isDragDisabled={disabled}>
								{(provided) => (
									<StyledElement
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										ref={provided.innerRef}
									>
										{renderItem(id)}
									</StyledElement>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	)
}
